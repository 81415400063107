export class EMansionHomepageCapacityConfirm {
  readonly _errorMessages!: string[];

  constructor(partial: Partial<EMansionHomepageCapacityConfirm>) {
    Object.assign(this, partial);
  }

  get errorMessages(): string[] {
    return this._errorMessages;
  }

}
