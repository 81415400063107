<template>
  <!-- main-contents -->
  <div class="e-mansion-index">
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メインメールアドレス</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- /breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>メインメールアドレス</li>
      </ul>

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->
      <!-- blc:アカウントID登録・変更-->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansionアカウントID登録・変更" />登録・変更お申し込み</h2>
        登録内容をご確認ください。
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <table class="table-type2">
          <tr>
            <th class="va-middle">現在のメールアドレス</th>
            <td>{{ mailAddress == null ? '未発行' : mailAddress }}</td>
          </tr>
          <tr>
            <th class="va-middle">新しいメールアドレス</th>
            <td>
              <font color="red">{{ newMailAddressAddDomain }}</font>
            </td>
          </tr>
        </table>
      </div>
      <!-- /blc -->

      <div class="blc">
        <ul class="ul-basic01">
          <li>登録・変更お申し込み直後は「お申込受付済み」と表示されます。 メールアドレスがご利用可能となりましたら「ご利用中」と表示されます。</li>
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」前日までにお申し込みいただくと、鍵のお引渡し日からご利用可能となります。</li>
        </ul>

        <div class="sblc">
          <div v-if="isDisplayInfo">
            <h3 class="service-h3">メールアドレス変更に伴う注意事項</h3>
            <ul class="ul-basic01">
              <li>変更前のメールアドレスに戻すことはできません。</li>
              <li>同じメールアドレスの再取得はできません。</li>
              <li>変更前メールアドレス宛のメールは、変更翌日までは受信可能です。</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
          <p class="form-btn-txt">ご確認の上、よろしければ「登録」をクリックしてください。</p>
          <br />
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goEntry()">登録<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'eMansionMailMailConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 現在のメールアドレス */
    mailAddress: '',
    /** 新しいメールアドレス */
    newMailAddress: '',
    /** 新しいメールアドレス(ドメイン付き) */
    newMailAddressAddDomain: '',
    /** ドメイン */
    mailAddressDomain: process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
    /** 独自ドメイン */
    mailAddressOriginalDomain: '',
    /** メールアドレス変更に伴う注意事項表示有無 */
    isDisplayInfo: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // メソッドが実行中かどうか
    isLoading: true,
    /** メールアドレス入力チェック結果 */
    eMansionMainMailaddressInput: EMansionMainMailAddressInput,
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    //メインメールアドレス情報をAPIより取得する
    try {
      // 現在のメールアドレスを取得(共通情報より取得)
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      this.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddress ?? '';

      // 新しいメールアドレスを取得
      this.newMailAddress = await this.$store.getters['eMansionMainMailAddressStore/newMailAddress'];

      // Storeから前提情報を取得できない場合、入力画面へ戻る（リロード対応）
      if (!this.newMailAddress) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/main-mail-address/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 新しいメールアドレス(ドメイン付き)を取得
      this.newMailAddressAddDomain = await this.$store.getters['eMansionMainMailAddressStore/newMailAddressAddDomain'];

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // メールアドレス変更に伴う注意事項表示有無を判定
      this.isDisplayInfo = this.mailAddress != undefined;
      this.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddressOriginal ?? this.mailAddress;

      // 独自ドメイン有無判定(1:あり、0:なし)
      if (hasEMansionOriginalDomain(property.em_op_mail_flag)) {
        this.mailAddressOriginalDomain = property.original_domain_name;
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },

    /** 戻るボタン押下時： メインメールアドレス登録変更：入力画面に遷移する */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //メールアドレスをstoreに保存する
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.mailAddress);
      this.$store.commit('eMansionMainMailAddressStore/newMailAddress', this.newMailAddress);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/main-mail-address/input').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** 次へボタン押下時： メインメールアドレス登録変更：お申し込み受付完了画面に遷移する */
    async goEntry() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansion_アカウントIDを取得
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      if (!accountInfo) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員種別の取得
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];

      // 現在日付の取得
      const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      try {
        let result: any;
        // 現在のメールアドレスに値があるか（ある場合、更新、ない場合、新規）
        if (this.mailAddress) {
          // AE10-004_メインメールアドレス登録・変更：確認(登録変更)_更新API
          result = await SpfApiMainMailAddressAccessor.updateMainMailAddress(
            this.newMailAddress,
            accountInfo.eMansionAccountInfo.eMansionAccountId,
            property.apartment_id,
            this.mailAddressDomain,
            this.mailAddressOriginalDomain,
            processOn
          );
        } else {
          // 新規：AE10-003_メインメールアドレス登録・変更：確認(登録変更)_登録API
          result = await SpfApiMainMailAddressAccessor.createMainMailAddress(
            this.newMailAddress,
            accountInfo.eMansionAccountInfo.eMansionAccountId,
            property.apartment_id,
            servicePlanType,
            this.mailAddressDomain,
            this.mailAddressOriginalDomain,
            processOn
          );
        }

        // API処理結果にエラーメッセージがある場合
        if (result.errorMessages) {
          this.errorMessages = result.errorMessages;
        }
        // Storeにメールアドレスを保持
        if (result.mailAddress) {
          this.$store.commit('eMansionMainMailAddressStore/newMailAddressAddDomain', result.mailAddress);
        }
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      await this.$router.push('/e-mansion/main-mail-address/complete').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
