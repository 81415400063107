import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a723166"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "platform-index" }
const _hoisted_2 = { class: "contents" }
const _hoisted_3 = { class: "blc" }
const _hoisted_4 = {
  key: 1,
  class: "blc"
}
const _hoisted_5 = {
  key: 2,
  class: "blc"
}
const _hoisted_6 = {
  key: 3,
  class: "blc"
}
const _hoisted_7 = { class: "blc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_main_swiper_component = _resolveComponent("main-swiper-component")!
  const _component_ModalDisplayTsunaguOnlineProduct = _resolveComponent("ModalDisplayTsunaguOnlineProduct")!
  const _component_PortalServiceController = _resolveComponent("PortalServiceController")!
  const _component_PortalServiceGameWith = _resolveComponent("PortalServiceGameWith")!
  const _component_SubscriptionCommonComponent = _resolveComponent("SubscriptionCommonComponent")!
  const _component_TsunaguOnlineShop = _resolveComponent("TsunaguOnlineShop")!
  const _component_McloudCommonComponent = _resolveComponent("McloudCommonComponent")!
  const _component_FrequentlyAskedQuestions = _resolveComponent("FrequentlyAskedQuestions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMounted || !_ctx.isImagesLoadedMainSwiper)
      ? (_openBlock(), _createBlock(_component_LoadingComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_main_swiper_component, {
      status: _ctx.status,
      passEventToParentComponent: _ctx.displayTsunaguOnlineProductHtmlWrapperFromSwiper,
      isDisplayConnectix: _ctx.isDisplayConnectix,
      onIsImagesLoadedMainSwiper: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeIsImagesLoadedMainSwiper()))
    }, null, 8, ["status", "passEventToParentComponent", "isDisplayConnectix"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_ModalDisplayTsunaguOnlineProduct, {
          key: 1,
          srcHtml: _ctx.mySrcHtmlFromSwiper,
          "handle-show-modal": _ctx.setShowModal,
          passProductUrl: _ctx.handlePassedProductUrl,
          ref: "modalDisplayTsunaguOnlineProduct"
        }, null, 8, ["srcHtml", "handle-show-modal", "passProductUrl"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PortalServiceController, {
        onUpdateLeftMenuLogoFromPortalServiceController: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendUpdateLeftMenuLogoAtPlatform()))
      }),
      (_ctx.isLinkedGameWithFlag)
        ? (_openBlock(), _createBlock(_component_PortalServiceGameWith, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.serviceType)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.displayPortasSubscriptionPurchaseLead)
                ? (_openBlock(), _createBlock(_component_SubscriptionCommonComponent, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.displayTsunaguOnlineShop() && _ctx.isPreviewTnshop === true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_TsunaguOnlineShop, {
                      onUpdateLeftMenuLogoFromTsunaguOnlineShop: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendUpdateLeftMenuLogoAtPlatform())),
                      ref: "tsunaguOnlineShop"
                    }, null, 512)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isLinkedToMcloud && _ctx.isPreviewMcloud)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_McloudCommonComponent)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isLinkedToMcloud && _ctx.isDisplayNotLinkedMcloud && !_ctx.isLinkedGameWithFlag)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_McloudCommonComponent, { isDisplayNotLinkedMcloud: _ctx.isDisplayNotLinkedMcloud }, null, 8, ["isDisplayNotLinkedMcloud"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_FrequentlyAskedQuestions, { serviceType: _ctx.serviceType }, null, 8, ["serviceType"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}