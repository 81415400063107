import { PERMISSION_TYPE } from '@/shared/services/permission/permission-type';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';

/**
 * メール統合 共通利用 API : サービス別認可マスタ取得 : レスポンス
 *
 */
export class ServicePermissionMaster {
  /** サービスID */
  readonly _serviceId!: string;
  /** 会員種別 */
  readonly _planType!: SERVICE_PLAN_TYPE;
  /** パーミッション */
  readonly _permission!: PERMISSION_TYPE;

  constructor(partial: Partial<ServicePermissionMaster>) {
    Object.assign(this, partial);
  }

  get serviceId(): string {
    return this._serviceId;
  }

  get planType(): SERVICE_PLAN_TYPE {
    return this._planType;
  }

  get permission(): PERMISSION_TYPE {
    return this._permission;
  }
}
