<template>
  <div class="five-a-entry-terms">
    <LoadingComponent v-if="!isMounted || isExecutingOnNext" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Connectix お申し込み（規約）</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>規約</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc -->
      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <p>Connectix ご利用にあたり、UCOM光 レジデンスISP会員規約、Connectix サービス規約に同意の上お客様情報をご登録ください。</p>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="個人情報取得に係る通知" />UCOM光 レジデンスISP会員規約</h2>
        <h3 class="">&emsp;<a href="https://www.tsunagunet.com/pdf/kiyaku_resi_03.pdf" class="link" target="_blank">UCOM光 レジデンスISP会員規約</a></h3>
      </div>
      <!-- /blc -->

      <!-- blc:Connectix サービス規約 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix サービス規約" />Connectix サービス規約</h2>
        <h3 class="">&emsp;<a :href="`${serviceTermsUrl}/${apid}/ft10`" class="link" target="_blank">Connectix サービス規約</a></h3>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <!-- 【確認】個人情報取得に係る通知 リンク先 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="個人情報取得に係る通知" />個人情報取得に係る通知</h2>
        <h3 class="">&emsp;<a href="https://www.tsunagunet.com/privacy/" class="link" target="_blank">個人情報取得に係る通知</a></h3>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc has-campaign-message">
        <label
          ><input type="checkbox" v-model="isAgreeTermsAndNotice" /> <b>「UCOM光 レジデンスISP会員規約」、「Connectixサービス規約」、「個人情報取得に係る通知」に同意する</b></label
        >
        <p class="note-on-personal-information">Connectixお申し込みに関してご登録いただいた個人情報は、株式会社つなぐネットコミュニケーションズが取得、管理します。</p>
        <div class="btn-area">
          <button type="button" class="btn btn01 bs" :class="buttonColorSet" :disabled="!isAgreed || isFailed" @click="onNext">
            規約と個人情報取得通知に同意してお申し込み<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
/*underlayer-main
 ----------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

/* お申し込みボタン 非活性時のスタイル */
button.btn01:disabled {
  opacity: 0.5;
}

div.blc {
  &.has-campaign-message {
    margin-top: 0px;
  }

  p.note-on-personal-information {
    margin-top: 2px;
    padding-left: 22px;
  }
}

.connectix-campaign-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.connectix-campaign {
  padding: 15px 0px 20px 0px;
  margin-bottom: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAEntryInputForm } from '../entry/classes/entry-input-form';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { isUnder18 } from '@/shared/util/func-is-under-18';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';

/** Terms コンポーネント */
export default defineComponent({
  name: 'terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 会員情報 */
    member: null as Member | null,
    /** 物件情報 */
    fiveAProperty: null as FiveAProperty | null,
    /** 規約と通知に同意する */
    isAgreeTermsAndNotice: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** 読み込み完了 */
    isMounted: false,
    /** サービス規約URL */
    serviceTermsUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_SERVICE_TERMS_URL,
    // onNext実行中かどうか
    isExecutingOnNext: false,
    // 18歳未満の場合,Connectix登録済み等NGフラグ
    isFailed: false,
  }),
  computed: {
    isAgreed() {
      return this.isAgreeTermsAndNotice;
    },
    buttonColorSet() {
      if (!this.isFailed && this.isAgreed) {
        return 'btn btn01 bs';
      }
      return 'btn btn04 bs';
    },
    /**9桁のランダムな数字を生成 */
    apid() {
      if (this.fiveAProperty) {
        return this.fiveAProperty.IN_APID;
      } else {
        return '';
      }
    },
  },
  async mounted(): Promise<void> {
    // エラーメッセージ格納配列初期化
    this.errorMessages = [];

    // 入力値を保持しているストアの削除
    this.$store.commit('fiveAEntryStore/entryInputForm', null);

    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報取得処理
      const member: Member = this.$store.getters['memberStore/member'];
      if (!member || !member.propertyId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | null = property.uaType ?? null;
      const propertyId: string | null = property.apartmentId ?? null;

      // 物件テーブルに「Mye物件IDが空文字」OR「UA種別が Null」の場合は e-mansion の外部 API の情報が取得できないためエラー表示
      if (!uaType || !propertyId) {
        this.errorMessages.push('Connectix のお申し込みに必要な情報が取得できませんでした。');
        return;
      }

      // 未成年のとき、会員ステータスを 5:物件紐づけ完了 に変更し、エラーメッセージを表示
      if (isUnder18(this.$store.getters['memberStore/member'].birthdate)) {
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        memberStatus.status = '5';
        await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: memberStatus.primaryKeyAuth0, memberStatus });

        this.errorMessages.push(
          '18歳未満の方の Five.Aサービス へのお申し込みには、別途「親権同意書」のご提出が必要となります。 誠に恐れ入りますが、建物専用ホームページ「よくあるご質問」からサポートセンターへご連絡ください。'
        );
        window.scrollTo(0, 0);

        this.isMounted = true;
        this.isFailed = true;
        return;
      }

      this.member = member;
      this.fiveAProperty = this.$store.getters['fiveACommonStore/property'];
      if (!this.fiveAProperty) {
        const property: Property = this.$store.getters['propertyStore/property'];
        if (!property) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
        }
        // 物件情報取得処理
        const params = { propertyId: property.apartmentId, uaType: property.uaType };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          // APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        }
        this.$store.commit('fiveACommonStore/property', fiveAProperty);
        this.fiveAProperty = fiveAProperty;
      }
    } catch (error) {
      throw error;
    }
    // 読み込み完了までボタンを非活性化
    this.isMounted = true;
    this.isFailed = false;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isExecutingOnNext = false;
    },
    async onNext() {
      if (this.isExecutingOnNext) {
        return;
      }
      this.isExecutingOnNext = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // 規約同意チェック
      if (!this.isAgreeTermsAndNotice) {
        this.errorMessageTitle = '規約・通知に同意してください。';
        this.errorMessages.push('「UCOM光 レジデンスISP会員規約」、「Connectix サービス規約」、「個人情報取得に係る通知」に同意する”にチェックを入れてください。');
        this.showErrorMessage();
        return;
      }

      try {
        const property: Property = this.$store.getters['propertyStore/property'];
        const customer = new FiveAEntryInputForm({
          /** UA 種別 */
          uaType: UA_TYPE.FIVE_A,
          /** 物件ID */
          propertyId: String(this.fiveAProperty?.apartment_id),
          /** 棟ID */
          buildingId: this.member?.buildingNumber,
          /** 部屋番号 */
          roomNumber: this.member?.roomNumber,
          /** 氏名 */
          nameSurname: this.member?.firstName,
          /** 名 */
          nameFirstName: this.member?.givenName,
          /** 姓カナ */
          kanaSurname: this.member?.firstNameKatakana,
          /** 名カナ */
          kanaFirstName: this.member?.givenNameKatakana,
          /** 生年月日 yyyy/mm/dd形式 */
          birthday: this.member?.birthdate?.replace(/-/g, '/'),
          /** 電話番号 */
          phoneNumber: this.member?.phoneNumber ? this.member?.phoneNumber : undefined,
          /** 連絡先メールアドレス */
          emailAddress: this.member?.emailAddress,
          /** 書類送付先種別（空） */
          destinationKind: '',
          /** 郵便番号 */
          destinationZipcode: property.zipcode,
          /** 住所 */
          destinationAddress1: property.address1,
          /** 連絡先電話番号（空） */
          destinationTelephone: '',
          /** 希望メールアドレス（空） */
          desiredAccount: '',
          /** ご契約内容の電子交付希望（空） */
          electronicdelivery: '',
          /** 接続コースID（空） */
          courseId: '',
          /** ご希望パスワード（空） */
          password: '',
          /** Connectix同時お申し込み (1で固定)
           * 1：お申し込み, 0：お申し込まない */
          sdwan: '1',
        });
        this.$store.commit('fiveAEntryStore/entryInputForm', customer);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/5a/entry/input').catch((error: any) => {
          checkRouterError(error);
        });
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        throw error;
      }
    },
  },
});
</script>
