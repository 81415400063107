/** Webスペース登録API レスポンス */
export class UcomWebSpaceRegisterInfo {
  readonly _errorMessage: string | undefined; // エラーメッセージ

  constructor(errorMessage: string | undefined) {
    this._errorMessage = errorMessage;
  }

  get errorMessage(): string | undefined {
    return this._errorMessage;
  }
}
