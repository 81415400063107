/** UCOM Connectix API : エラー時のレスポンス */
export class UcomConnectixErrorResponse {
  /** エラーコード */
  public readonly code!: string;

  /** エラー詳細   */
  public readonly detail!: string;

  /** エラーメッセージ   */
  public readonly message!: string;

  constructor(partial: Partial<UcomConnectixErrorResponse>) {
    Object.assign(this, partial);
  }
}
