<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Webスペース容量変更 完了</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>Webスペース容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Webスペース容量" />Webスペース容量変更 完了</h2>
        <p>お申し込みいただきました内容につきまして手続きが完了しました。<br /></p>
        <p v-if="notifyMailAddress">{{ notifyMailAddress }}宛に、「申込み完了メール」を送信しましたのでご確認ください。</p>
      </div>
      <!-- /blc -->

      <div class="sblc">
        <h3 class="service-h3">お申し込み内容</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">現在の容量</th>
              <td>{{ webSpaceCapacity }}.0GB</td>
            </tr>
            <tr>
              <th class="va-middle">追加／縮小容量</th>
              <td>
                <b>{{ afterChangeWebSpaceCapacityLabel }}</b>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>10.0MB 毎に月額{{ unitPrice }}円（税込）<br />最大{{ webSpaceMaxCapacity }}.0MBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ applicationDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td v-html="serviceDescription"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="onUcomTop()">
            UCOM光 レジデンス会員専用トップ
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'web-space-complete',
  components: {
    LoadingComponent,
  },
  data: () => ({
    accountName: '', // UCOM_アカウントID
    mailAddress: '', // メールアドレス
    notifyMailAddress: '', // 通知先メールアドレス
    homepageAddress: '', // ホームページアドレス
    webSpaceCapacity: '', // 現在のWebスペース容量
    afterChangeWebSpaceCapacityLabel: '', // 追加/縮小するWebスペース容量のLabel
    displayAfterChangeWebSpaceCapacityLabel: '', // 表示用追加/縮小するWebスペース容量のLabel
    unitPrice: '', // 月額利用料
    webSpaceMaxCapacity: '', // Webスペース最大容量
    applicationDate: '', // お申し込み日
    serviceDescription: '', // サービス説明
    isLoading: false, // ローディング
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      const accountName = await this.$store.getters['ucomWebSpaceStore/accountName'];
      if (!accountName) {
        await this.$router.push('/ucom/account-option-change/account-list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 前画面からの情報を取得し画面項目にセット
      this.setDataByStore();

      // storeをクリア
      this.deleteStore();
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    // storeから画面項目データをセット
    setDataByStore(): void {
      this.$data.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
      this.$data.notifyMailAddress = this.makeMailAddressForView(this.$store.getters['ucomWebSpaceStore/notifyMailAddress']);
      this.$data.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
      this.$data.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
      this.$data.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
      this.$data.afterChangeWebSpaceCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
      this.$data.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
      this.$data.webSpaceMaxCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceMaxCapacity'];
      this.$data.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
      this.$data.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];

      // 追加/縮小容量を表示用に加工
      if (String(this.$data.afterChangeWebSpaceCapacityLabel.toString().charAt(0)) === '-') {
        this.$data.displayAfterChangeWebSpaceCapacityLabel = this.$data.afterChangeWebSpaceCapacityLabel;
      }
      if (String(this.$data.afterChangeWebSpaceCapacityLabel.toString().charAt(0)) !== '-') {
        this.$data.displayAfterChangeWebSpaceCapacityLabel = '+' + this.$data.afterChangeWebSpaceCapacityLabel;
      }
    },
    // storeをクリア
    deleteStore(): void {
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', null);
      this.$store.commit('ucomWebSpaceStore/accountName', null);
      this.$store.commit('ucomWebSpaceStore/mailAddress', null);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', null);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', null);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', null);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', null);
      this.$store.commit('ucomWebSpaceStore/unitPrice', null);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', null);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', null);
      this.$store.commit('ucomWebSpaceStore/notifyMailAddress', null);
    },
    makeMailAddressForView(mailAddress: string[]): string {
      if (!mailAddress[0]) {
        return mailAddress[1];
      }
      if (mailAddress[0] === '') {
        return mailAddress[1];
      }
      return `${mailAddress[0]}, ${mailAddress[1]}`;
    },
    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
  },
});
</script>
