import { ExternalSearchPropertiesPropertyInfoDto } from './external-search-properties-property-info-dto';

export class ExternalSearchPropertiesSearchSuccessResponseDto {

  // 処理ステータス
  readonly status = 'success';

  // 検索結果
  readonly propertyInfoList!: ExternalSearchPropertiesPropertyInfoDto[];

  constructor(partial: Partial<ExternalSearchPropertiesSearchSuccessResponseDto>) {
    Object.assign(this, partial);
  }
}
