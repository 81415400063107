/** UCOMメールボックス容量変更 リクエスト dto */
export class UpdateUcomMbCapacityRequest {
  private readonly _portasId!: number; // Portas会員ID
  private readonly _ucomAccountMailId!: number; // UCOMアカウントメールID
  private readonly _changeCapacityLabel!: string; // 追加／縮小容量(label)
  private readonly _changeCapacityValue!: string; // 追加／縮小容量(value)
  private readonly _effectiveDate!: string; // お申し込み日

  constructor(portasId: number, ucomAccountMailId: number, changeCapacityLabel: string, changeCapacityValue: string, effectiveDate: string) {
    this._portasId = portasId;
    this._ucomAccountMailId = ucomAccountMailId;
    this._changeCapacityLabel = changeCapacityLabel;
    this._changeCapacityValue = changeCapacityValue;
    this._effectiveDate = effectiveDate;
  }

  public get portasId(): number {
    return this._portasId;
  }
  public get ucomAccountMailId(): number {
    return this._ucomAccountMailId;
  }
  public get changeCapacityLabel(): string {
    return this._changeCapacityLabel;
  }
  public get changeCapacityValue(): string {
    return this._changeCapacityValue;
  }
  public get effectiveDate(): string {
    return this._effectiveDate;
  }
}
