<template>
  <div class="ucom-entry-input">
    <LoadingComponent v-if="isCreating" />
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み 入力</h1>
    </main>
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>お客様情報入力</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid3 gap10">
        <li class="stay">情報の入力</li>
        <li>入力内容の確認</li>
        <li>登録完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:物件確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="情報の入力" />情報の入力</h2>
        <p>下記、UCOM光 レジデンスISP会員規約・UCOM光 レジデンスに関する重要事項説明の内容に同意の上、以下の項目に入力をお願いいたします。</p>
        <p>
          Portasで登録した内容に誤りがございましたら「修正」ボタンを押してください。
          <router-link class="sbtn fix-btn" tag="button" to="/platform/my-page/member-edit"><i class="material-icons link">west</i>修正</router-link>
        </p>
        <div class="my-error-messages-component-wrapper">
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <div v-if="ucomProperty" class="sblc">
          <div class="sblc">
            <h3 class="service-h3">お申し込み者情報入力</h3>
            <p>お申し込み（ご契約）をされるご本人様の情報をご入力ください。</p>
            <table v-if="member" class="table-type2">
              <tr>
                <th>お申し込み年月日</th>
                <td>{{ dispApplyDate }}</td>
              </tr>
              <tr>
                <th>お名前</th>
                <td>{{ member.firstName }}&nbsp;&nbsp;{{ member.givenName }}</td>
              </tr>
              <tr>
                <th>お名前フリガナ</th>
                <td>{{ member.firstNameKatakana }}&nbsp;&nbsp;{{ member.givenNameKatakana }}</td>
              </tr>
              <tr>
                <th>設置先ご住所</th>
                <td v-if="ucomProperty.postal_code">
                  〒{{ dispZipcode }}<br />
                  {{ ucomProperty.address_prefectures }} {{ ucomProperty.address_municipalities }} {{ ucomProperty.address_town }} {{ ucomProperty.address_street }}
                  {{ ucomProperty.address_number }} {{ ucomProperty.building_name }}
                </td>
              </tr>
              <tr>
                <th>棟/部屋番号<span class="req">必須</span></th>
                <td>
                  <span v-if="isFromRoomNumberInput">{{ inputData.roomNumber }}</span>
                  <input type="text" class="text short" placeholder="１０１" v-model="inputData.roomNumber" v-else />
                </td>
              </tr>
              <tr>
                <th>ご連絡先お電話番号(携帯番号でも可)<span class="req">必須</span></th>
                <td>
                  <input type="text" class="text middle" v-model="inputData.phoneNumber" />
                </td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>{{ member.emailAddress }}</td>
              </tr>
              <tr>
                <th>生年月日</th>
                <td>{{ dispBirthdate }}</td>
              </tr>
            </table>
            <table class="table-type2">
              <tr>
                <th>入居状況<span class="req">必須</span></th>
                <td>
                  <div class="mb10">
                    <label>
                      <input
                        type="radio"
                        name="tenancyStatusCheck"
                        v-model="inputData.tenancyStatus"
                        @input="v$.inputData.tenancyStatus.$touch()"
                        @click="tenancyStatusSelected()"
                        value="1"
                      />
                      入居済
                    </label>
                    <label>
                      <input type="radio" name="tenancyStatusCheck" v-model="inputData.tenancyStatus" @input="v$.inputData.tenancyStatus.$touch()" value="0" />
                      未入居
                    </label>
                  </div>
                  移転予定日<input
                    style="margin-left: 2%"
                    type="date"
                    class="text short display-date"
                    placeholder="例)1980/01/01"
                    v-model="inputData.expectedMoveInOn"
                    @input="v$.inputData.expectedMoveInOn.$touch()"
                    :disabled="inputData.tenancyStatus !== '0'"
                  />
                  <p class="att grey mt10">
                    ※登録完了後、UCOM光 レジデンス登録証を送付いたします。<br />入居済の場合は用意ができ次第、未入居の場合は移転予定日の前後に送付いたします。
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div
            v-if="importFormId != '2' && (getNumericPortionOfPrice(ucomProperty.monthly_basic_charge) > 0 || getNumericPortionOfPrice(ucomProperty.property_initial_cost) > 0)"
            class="sblc"
          >
            <h3 class="service-h3">料金</h3>
            <table class="table-type2">
              <tbody>
                <tr v-if="getNumericPortionOfPrice(ucomProperty.monthly_basic_charge) > 0">
                  <th>月額基本料金（税込）</th>
                  <td>{{ ucomProperty.monthly_basic_charge }}</td>
                </tr>
                <tr v-if="getNumericPortionOfPrice(ucomProperty.property_initial_cost) > 0">
                  <th>物件初期費用（税込）</th>
                  <td>{{ ucomProperty.property_initial_cost }}</td>
                </tr>
              </tbody>
            </table>
            <p>IP電話サービスの料金は、UCOM光電話もしくはGATE CALL050(M)の月額基本料金・機器使用料を確認ください。</p>
          </div>
          <div class="sblc">
            <h3 class="service-h3">IP電話サービスのお申し込み</h3>
            <!-- UCOM光、GATECALL050サービスのお申し込みの可/不可により表示を切り替える(お申し込み可の場合) -->
            <label v-if="ucomProperty.is_possible_opitcal_phone || ucomProperty.is_possible_gate_call_050"
              ><input type="checkbox" v-model="inputData.isApplyIPTelSercice" /> <b>お申し込みの場合はこちらをチェックしてください</b></label
            >
            <p class="mt10" v-if="ucomProperty.is_possible_opitcal_phone || ucomProperty.is_possible_gate_call_050">
              IP電話サービスをご利用になる場合には、クレジットカードでのお支払い情報の登録が必要です。<br />「入力内容の確認」で「お支払い情報入力」をご確認のうえ、お申し込み手続きをしてください。
            </p>
            <!-- UCOM光、GATECALL050サービスのお申し込みの可/不可により表示を切り替える(お申し込み不可の場合) -->
            <p class="mt15 red att" v-if="!ucomProperty.is_possible_opitcal_phone && !ucomProperty.is_possible_gate_call_050">お申し込み頂けません。</p>
            <table class="table-type2" v-if="inputData.isApplyIPTelSercice">
              <tr v-if="ucomProperty.is_possible_opitcal_phone">
                <th>
                  <p class="font18px mb10 link"><a :href="`${ucomUrl}/option/hikaridenwa.html`" target="_blank">UCOM光電話</a></p>
                  <p class="font13px">
                    事務手数料{{ ucomProperty.optical_phone_commission }}<br />
                    月額基本料金{{ ucomProperty.optical_phone_monthly_basic_charge }}<br />
                    月額機器利用料金{{ ucomProperty.optical_phone_monthly_usage_fee }}
                  </p>
                </th>
                <td>
                  <div>
                    <label><input type="radio" name="applyOpticalPhone" v-model="inputData.applyOpticalPhone" value="1" />申し込みする </label>
                    <label><input type="radio" name="applyOpticalPhone" v-model="inputData.applyOpticalPhone" value="0" />申し込みしない</label>
                    <p class="att grey mb10">※「UCOM光電話」サービス利用規約に同意の上、お申し込みください。</p>
                    <p><a href="https://www.arteria-net.com/files/user/mansion/hikaridenwa_misp.pdf" target="_blank" class="link">「UCOM光電話」サービス利用規約</a></p>
                    <p><a href="https://www.arteria-net.com/files/user/mansion/attention_hikaridenwa.pdf" target="_blank" class="link">ご利用上の注意</a></p>
                    <p>※利用開始日は、「開通通知書」に記載されます。</p>
                    <p>※ご不明な点がございましたら当社お問い合わせ窓口までご連絡をお願いいたします。</p>
                    <p>「UCOM光電話」をお申し込みされる場合は以下もご記入ください。</p>
                  </div>
                  <div v-if="inputData.applyOpticalPhone == '1'">
                    <div class="mt20">
                      <p class="mb05"><b>電話番号取得方法</b></p>
                      <span v-for="itemOpticalPhoneNumberGetMethod in selectListOpticalPhoneNumberGetMethods" :key="itemOpticalPhoneNumberGetMethod.id">
                        <div>
                          <label
                            ><input type="radio" name="flexRadioTelDefault" v-model="inputData.opticalPhoneNumberGetMethodId" :value="itemOpticalPhoneNumberGetMethod.id" />
                            {{ itemOpticalPhoneNumberGetMethod.value }}
                          </label>
                        </div>
                      </span>
                    </div>
                    <!-- 電話番号取得方法で番号ポータビリティを選択した場合のみ、引継電話番号～ご利用中の電話会社は表示させる -->
                    <div v-if="inputData.opticalPhoneNumberGetMethodId == OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY">
                      <div class="mt20">
                        <p class="mb05"><b>ご利用中の電話会社にご登録の住所</b></p>
                        <div class="my-address-input">
                          <label>郵便番号<span class="req">必須</span></label>
                          <div class="zip-code">
                            <input type="text" class="text short" v-model="zipCode.firstHalfOf" />
                            <span>-</span>
                            <input type="text" class="text short" v-model="zipCode.secondHalfOf" />
                            <button @click="searchAddressByZipcode()">住所検索</button>
                          </div>

                          <label>番地まで<span class="req">必須</span></label>
                          <div>
                            <input type="text" class="text middle" v-model="inputData.opticalPhoneAddress" />
                          </div>

                          <label>マンション名など</label>
                          <div>
                            <input type="text" class="text middle" v-model="inputData.opticalPhoneBuildingName" />
                          </div>
                        </div>
                      </div>

                      <div class="mt20">
                        <p class="mb05"><b>引継電話番号</b></p>
                        <input
                          class="text middle"
                          type="text"
                          v-model="inputData.opticalPhoneTakeoverNumber"
                          placeholder="例)03-1234-5678"
                          @input="v$.inputData.opticalPhoneTakeoverNumber.$touch()"
                        />
                      </div>
                      <div class="mt20">
                        <p class="mb05"><b>名義人区分</b></p>
                        <label><input type="radio" name="flexRadioNomineeDefault" v-model="inputData.opticalPhoneHolderCategory" value="1" />本人</label>
                        <label><input type="radio" name="flexRadioNomineeDefault" v-model="inputData.opticalPhoneHolderCategory" value="2" />本人以外</label>
                      </div>
                      <div class="mt20">
                        <p class="mb05"><b>ご利用中の電話会社にご登録のお名前</b></p>
                        <input
                          class="text middle"
                          type="text"
                          v-model="inputData.opticalPhoneHolderName"
                          placeholder="例)山田次郎"
                          @input="v$.inputData.opticalPhoneHolderName.$touch()"
                        />
                      </div>
                      <div class="mt20">
                        <p class="mb05"><b>ご利用中の電話会社にご登録のお名前カナ</b></p>
                        <input
                          class="text middle"
                          type="text"
                          v-model="inputData.opticalPhoneHolderNameKana"
                          placeholder="例)ヤマダジロウ"
                          @input="v$.inputData.opticalPhoneHolderNameKana.$touch()"
                        />
                      </div>
                      <div class="mt20">
                        <p class="mb05"><b>ご利用中の電話会社</b></p>
                        <select name="example" class="form_select" v-model="inputData.opticalPhoneUseCompany">
                          <option
                            v-for="itemOpticalPhoneUseCompany in selectListOpticalPhoneUseCompanies"
                            :key="itemOpticalPhoneUseCompany.id"
                            :value="itemOpticalPhoneUseCompany.id"
                          >
                            {{ itemOpticalPhoneUseCompany.value }}
                          </option>
                        </select>
                        <div v-if="inputData.opticalPhoneUseCompany === OPTICAL_PHONE_USE_COMPANY.OTHER" class="my-optical-phone-use-company-other">
                          <span class="req">必須</span>
                          <input class="text middle" type="text" v-model="inputData.opticalPhoneUseCompanyOther" />
                        </div>
                      </div>
                    </div>
                    <div class="mt20">
                      <p class="mb05"><b>光電話付加サービス（有料）</b></p>
                      <div v-for="itemOpticalPhoneImpossibleService in selectListOpticalPhoneImpossibleServices" :key="itemOpticalPhoneImpossibleService.id">
                        <label>
                          <input class="form-check-input" type="checkbox" v-model="inputData.opticalPhoneImpossibleService" :value="itemOpticalPhoneImpossibleService.id" />
                          {{ itemOpticalPhoneImpossibleService.value }}
                        </label>
                      </div>
                      <p class="att grey">※「③キャッチ通話番号表示」は「②キャッチ通話」とセットでお申し込み/ご利用ください。</p>
                      <p class="att grey">※「④オプションサービスパック」は①、②、③をすべて含んだパックメニューとなります。</p>
                    </div>
                    <div class="mt20">
                      <p class="mb05"><b>番号通知（無料）※ 必ずどちらかを選択してください。</b></p>
                      <div>
                        <label>
                          <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="inputData.opticalPhoneNumberNotificationId" value="1" />相手に通知する
                        </label>
                      </div>
                      <div>
                        <label>
                          <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="inputData.opticalPhoneNumberNotificationId" value="0" />相手に通知しない
                        </label>
                      </div>
                    </div>
                    <div class="authentication-key">
                      <!-- 光電話お申し込みコード内容表示領域 -->
                      <p class="att" style="white-space: pre-wrap; word-wrap: break-word" v-for="message in messageCheckApplicationCode" :key="message">
                        {{ message }}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="ucomProperty.is_possible_gate_call_050">
                <th>
                  <p class="font18px mb10 link"><a :href="`${ucomUrl}/option/gatecall050m.html`" target="_blank">GATE CALL050(M)</a></p>
                  <p class="font13px">
                    月額基本料金{{ ucomProperty.gate_call_050_monthly_basic_charge }}<br />
                    月額専用アダプタレンタル料{{ ucomProperty.gate_call_050_monthly_adapter_rental_fee }}
                  </p>
                </th>
                <td>
                  <div>
                    <label><input type="radio" v-model="inputData.applyGATECALL050" value="1" />申し込みする </label>
                    <label><input type="radio" v-model="inputData.applyGATECALL050" value="0" />申し込みしない</label>
                    <p class="att grey mb10">※「GATE CALL050(M)」サービス利用規約に同意の上、お申し込みください。</p>
                    <p><a href="https://www.arteria-net.com/files/user/mansion/gatecall050_m.pdf" class="link" target="_blank">「GATE CALL050(M)」サービス利用規約</a></p>
                    <p><a href="https://www.arteria-net.com/files/user/mansion/attention_gatecall050_m.pdf" class="link" target="_blank">ご利用上の注意</a></p>
                    <p class="inputUcomIpText">ご利用開始の設定時に、LANポートのあるパソコンが必要となります。</p>
                    <p class="inputUcomIpText">
                      詳しくは、<a :href="`https://ucom-r.ne.jp/${apid}/connect/gatecallta.html`" class="link" target="_blank">こちら</a>からご確認ください。
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <div v-if="inputData.isApplyIPTelSercice && ucomProperty.is_possible_opitcal_phone">
              <p>※IP電話サービスのご利用開始時期は回線提供開始日と異なります。</p>
              <p>あらかじめ上記「ご利用上の注意」をご確認のうえ、お申し込みください。</p>
              <p>&emsp;＜提供までの目安＞</p>
              <p>&emsp;新規番号の場合：お申し込みから約1～2週間</p>
              <p>&emsp;番号ポータビリティの場合：お申し込みから約2～3週間</p>
              <p>※1電話番号ごとにユニバーサルサービス料が別途かかります。ユニバーサルサービス料は変動する場合がございます。</p>
              <p>&emsp;詳しくは右記URL（<a href="https://www.arteria-net.com/uni/" class="link">https://www.arteria-net.com/uni/</a>）をご覧ください。</p>
              <p>※1電話番号ごとに電話リレーサービス料が別途かかります。電話リレーサービス料は変動する場合がございます。</p>
              <p>&emsp;詳しくは右記URL（<a :href="`${ucomUrl}/relay.html`" class="link">https://ucom-r.ne.jp/relay.html</a>）をご覧ください。</p>
              <p>※その他のオプションサービスは、開通後マイページよりお申し込みください。</p>
            </div>
          </div>

          <div class="sblc">
            <h3 class="service-h3">同意事項のご確認</h3>
            <p>
              お申し込みになる前に、「利用規約」「重要事項説明」「個人情報の取り扱いについて」をお読みください。<br />PDFをご覧になるには、<a
                href="https://get.adobe.com/reader/?loc=jp"
                class="link"
                target="_blank"
                >Adobe Acrobat Reader DC</a
              >をご利用ください。
            </p>
            <table class="table-type2">
              <tr>
                <th>利用規約<span class="req">必須</span></th>
                <td>
                  <label><input type="checkbox" v-model="isAgreeTermsOfService" /> <b>「利用規約」に同意する</b></label>
                  <p class="mt10">
                    利用規約は以下のリンクよりご確認ください。<br />
                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '1'"
                      href="https://www.tsunagunet.com/pdf/kiyaku_resi_03.pdf"
                      class="link"
                      target="_blank"
                    >
                      UCOM光 レジデンス ISP会員規約（PDF）</a
                    >
                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '2'"
                      href="https://www.arteria-net.com/files/user/mansion/kiyaku_resi_03.pdf"
                      class="link"
                      target="_blank"
                    >
                      UCOM光 レジデンス ISP会員規約（PDF）</a
                    >
                    <br />

                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '1'"
                      href="https://www.tsunagunet.com/pdf/kiyaku_resi_04.pdf"
                      class="link"
                      target="_blank"
                      >UCOM光 レジデンス ISP会員付加サービス利用規約（PDF）</a
                    >
                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '2'"
                      href="https://www.arteria-net.com/files/user/mansion/kiyaku_resi_04.pdf"
                      class="link"
                      target="_blank"
                      >UCOM光 レジデンス ISP会員付加サービス利用規約（PDF）</a
                    >
                  </p>
                </td>
              </tr>
              <tr>
                <th>重要事項説明<span class="req">必須</span></th>
                <td>
                  <label><input type="checkbox" v-model="isAgreeImportantExplanation" /> <b>「重要事項説明」に同意する</b></label>
                  <p class="mt10">
                    重要事項説明は以下のリンクよりご確認ください。<br />
                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '1'"
                      href="https://www.tsunagunet.com/pdf/attention_reji.pdf"
                      class="link"
                      target="_blank"
                    >
                      UCOM光 レジデンスに関する重要事項説明（PDF）</a
                    >
                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '2'"
                      href="https://www.arteria-net.com/files/user/mansion/attention_reji.pdf"
                      class="link"
                      target="_blank"
                    >
                      UCOM光 レジデンスに関する重要事項説明（PDF）</a
                    >
                  </p>
                </td>
              </tr>
              <tr>
                <th>個人情報の取り扱いについて<span class="req">必須</span></th>
                <td>
                  <label><input type="checkbox" v-model="isAgreePersonalInformation" /> <b>「個人情報の取り扱いについて」に同意する</b></label>
                  <p class="mt10">
                    個人情報の取り扱いについては以下のリンクよりご確認ください。<br />
                    <a
                      v-if="ucomProperty.service_provid_former.service_provid_former_id === '1'"
                      href="https://www.tsunagunet.com/inquiry/privacy2.html"
                      class="link"
                      target="_blank"
                      >個人情報の取り扱いについて
                    </a>
                    <a v-if="ucomProperty.service_provid_former.service_provid_former_id === '2'" href="https://www.arteria-net.com/privacy/policy/" class="link" target="_blank"
                      >個人情報の取り扱いについて
                    </a>
                  </p>
                </td>
              </tr>
            </table>
          </div>

          <!-- UCOM光レジデンス登録証の送付確認は Phase1 以降で実装予定 -->
          <!-- <div class="sblc" v-if="ucomProperty.contract_kind_id == '1' || ucomProperty.contract_kind_id == '2' || ucomProperty.contract_kind_id == '3'">
            <h3 class="service-h3">UCOM光レジデンス登録証のご送付</h3>
            <p>UCOM光レジデンス登録証の郵送を希望する場合は、下のチェックをお願いいたします。</p>
            <table class="table-type2">
              <tr>
                <th>登録証の郵送</th>
                <td>
                  <label><input type="checkbox" v-model="inputData.isSendRegistrationCard" /> <b>希望する</b></label>
                </td>
              </tr>
            </table>
          </div> -->

          <div class="sblc">
            <div class="btn-area" v-if="!isPossibleRegistration">
              <button type="button" class="btn bs" style="color: darkgray !important" v-on:click="onNext" disabled="true">
                確認<i class="material-icons link link-icon">east</i>
              </button>
            </div>
            <div class="btn-area" v-else>
              <button type="button" :class="getButtonClass" :disabled="!isAgreeTermsOfService || !isAgreeImportantExplanation || !isAgreePersonalInformation" v-on:click="onNext">
                確認<i class="material-icons link link-icon">east</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* underlayer-main
---------------------------------------------------- */
.underlayer-main {
  background-image: url('../../../images/ucom/main.png');
}

.underlayer-main ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  list-style-type: disc;
}

.underlayer-main ul li {
  display: inline-block;
  margin-right: 20px;
}

.underlayer-main li {
  display: inline-block;
  margin-right: 20px;
  text-align: -webkit-match-parent;
}

.underlayer-main ul li img {
  height: 40px;
}

.underlayer-main h1 {
  font-size: 24px;
  margin: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
}

/* mansion-box
---------------------------------------------------- */
.mansion-box {
  background-color: #fcfcfc;
  padding: 20px 30px;
  margin: 20px 0;
  text-align: center;
}

/* sbtn
---------------------------------------------------- */
.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  color: #cf1225;
  border: 1px solid #cf1225;
  border-radius: 2px;
  margin-top: -40px;
  padding: 0 10px;
  background-color: #fff;
}

.sbtn:hover {
  opacity: 0.7;
}

.fix-btn {
  color: #939393;
  border: 1px solid #939393;
  margin-top: 2px;
}

input[type='button']:disabled {
  background-color: #52525294;
  border: 1px solid #52525294;
  color: darkgray !important;
}

/* crecitcard
---------------------------------------------------- */
.crecitcard {
  max-width: 100%;
  margin: 10px 0;
}

/* form-box
---------------------------------------------------- */
.form-box {
  text-align: center;
}

/* form-txt
---------------------------------------------------- */
.form-txt {
  text-align: center;
}

/* form-btn-txt
---------------------------------------------------- */
.form-btn-txt {
  text-align: center;
}

/* authentication-key
---------------------------------------------------- */
.authentication-key {
  padding: 15px 30px;
  margin: 15px 0 0;
  border: 1px solid #e8e8e8;
  background-color: #fcfcfc;
}

.input {
  margin: 10px 0;
}

.input .text {
  width: 380px;
  margin: -10px 10px 0 0;
}

.input .btn {
  position: relative;
  top: 2px;
}

/* inputUcomIpText
---------------------------------------------------- */
.inputUcomIpText {
  color: #cf1225;
}

.my-address-input {
  display: grid;
  row-gap: 10px;
  grid-template-columns: 120px 1fr;
  margin-top: 16px;
  max-width: 560px;

  & div {
    display: flex;
    flex-direction: row;

    &.zip-code {
      & input {
        width: 56px;
      }

      & span {
        margin: 0 4px;
      }

      & button {
        margin-left: 4px;
      }
    }

    & input {
      width: 100%;
      height: auto;
      max-width: 350px;
      padding: 0 8px;
    }
  }
}

.my-optical-phone-use-company-other {
  position: relative;
  margin-top: 20px;

  & span.req {
    position: absolute;
    float: none;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  & input {
    margin-top: 28px;
  }
}

.my-error-messages-component-wrapper {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  /* authentication-key
  ---------------------------------------------------- */
  .authentication-key {
    padding: 15px;
  }

  .input .text {
    width: 42%;
  }

  .input .btn {
    min-width: 22%;
  }

  input.display-date {
    width: 150px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #ffffff;
  }
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';
import { UcomSharedErrorResponse } from '../../../shared/classes/external-api/ucom/shared-error-response';
import { UcomSharedExternalApiService } from '../../../shared/services/external-api/ucom-shared-external-api-service';
import { UcomAdditionalService, UcomAdditionalServicesResponse } from '../classes/external-api/additional-services-response';
import { UcomErrorResponse } from '../classes/external-api/error-response';
import { UcomNumberGetterResponse, UcomOpticalPhoneNumberGetMethod } from '../classes/external-api/number-getter-response';
import { UcomOpticalPhoneCodesResponse } from '../classes/external-api/optical-phone-codes-response';
import { UcomPhoneCompaniesResponse, UcomPhoneCompany } from '../classes/external-api/phone-companies-response';

import { UcomApplicationCodeData, UcomEntryInputForm } from './classes/entry-input-form';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { FORMAT_DATE } from '@/shared/const/format';
import { UA_TYPE } from '@/shared/const/service-type';
import { OPTICAL_PHONE_NUMBER_GET_METHOD_ID } from '@/shared/const/ucom/optical-phone';
import { OPTICAL_PHONE_USE_COMPANY } from '@/shared/const/ucom/phone-use-company';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomExternalApiService } from '@/shared/services/external-api/ucom-external-api-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { isUnder18 } from '@/shared/util/func-is-under-18';
import { processDate } from '@/shared/util/func-process-date';
import { ProcessesCalledInUcomPages } from '@/shared/util/processes-called-in-ucom-page';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { fullKana, fullWidth, phoneStyle } from '@/shared/util/validators';

/** 光電話付加サービス取得APIのID */
const ADDITIONAL_SERVICES = {
  /** ①着信番号表示 */
  S1_CALLED_NUMBER_DISPLAY: '144',
  /** ②キャッチ通話 */
  S2_CATCH_CALL: '138',
  /** ③キャッチ通話番号表示 */
  S3_CATCH_CALL_NUMBER_DISPLAY: '139',
  /** ④オプションサービスパック */
  S4_OPTIONAL_SERVICE_PACK: '137',
} as const;

/** UCOM新規会員登録 情報の入力画面 */
export default defineComponent({
  name: 'ucom-entry-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',

    /** APID */
    apid: '',

    zipCode: {
      /** ご利用中の電話会社にご登録の住所 郵便番号(ハイフンより前の3桁)  */
      firstHalfOf: '',
      /** ご利用中の電話会社にご登録の住所 郵便番号(ハイフンより後ろの4桁) */
      secondHalfOf: '',
    },
    /** 郵便番号による住所検索実行中かどうか */
    isSearchAddressByZipcodeExecuting: false,
    /** 入力情報取得用の各データ（初期値） */
    inputData: {
      applyDate: '',
      /** お名前 姓 */
      firstName: '',
      /** お名前 名 */
      givenName: '',
      /** お名前フリガナ 姓 */
      firstNameKana: '',
      /** お名前フリガナ 名 */
      givenNameKana: '',
      /** 郵便番号 */
      postalCode: '',
      /** 漢字都道府県 */
      addressPrefectures: '',
      /** 漢字市区郡町村 */
      addressMunicipalities: '',
      /** 漢字大字・通称 */
      addressTown: '',
      /** 漢字字名・丁目 */
      addressStreet: '',
      /** 漢字番地 */
      addressNumber: '',
      /** 設置先ご住所 建物名 */
      sendBuildingName: '',
      /** 棟番号・部屋番号 */
      roomNumber: '',
      /** 生年月日 */
      birthdate: '',
      /** eMail */
      eMail: '',
      /** お電話番号 */
      phoneNumber: '',
      /** 入居状況 */
      tenancyStatus: '',
      /** 移転予定日 */
      expectedMoveInOn: '',
      /** IP電話サービスのお申し込み要否 */
      isApplyIPTelSercice: false,
      /** UCOM光電話 お申し込み要否 */
      applyOpticalPhone: '0',
      /** UCOM光電話 電話番号取得方法 */
      opticalPhoneNumberGetMethodId: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（郵便番号） */
      opticalPhonePostalCode: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（番地まで） */
      opticalPhoneAddress: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（マンション名など） */
      opticalPhoneBuildingName: '',
      /** UCOM光電話 電話番号取得方法 (表示値) */
      opticalPhoneNumberGetMethodValue: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 引継電話番号 */
      opticalPhoneTakeoverNumber: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人区分 */
      opticalPhoneHolderCategory: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人 */
      opticalPhoneHolderName: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人カナ */
      opticalPhoneHolderNameKana: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社 */
      opticalPhoneUseCompany: '', // ご利用中の電話会社取得APIの {"id": "", "value": "選択してください"} を初期表示する
      /** UCOM光電話 電話番号取得方法  番号ポータビリティ ご利用中の電話会社でその他 を選んだときの テキストボックスの入力値 */
      opticalPhoneUseCompanyOther: '',
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社 (表示値) */
      opticalPhoneUseCompanyValue: '',
      /** UCOM光電話 光電話付加サービス（有料）（複数のチェックボックスなので配列） */
      opticalPhoneImpossibleService: [],
      /** UCOM光電話 光電話付加サービス (有料) (表示値) */
      opticalPhoneImpossibleServiceId: [],
      /** UCOM光電話 番号通知（無料） */
      opticalPhoneNumberNotificationId: '',
      /** GATE CALL050（M) お申し込み要否 */
      applyGATECALL050: '',
      /** 登録証の郵送（希望する/しない）「希望する」で固定 */
      isSendRegistrationCard: true,
      propertyId: '',
    } as UcomEntryInputForm,

    /** 「利用規約」に同意しているかどうか */
    isAgreeTermsOfService: false,
    /** 「重要事項説明」に同意しているかどうか */
    isAgreeImportantExplanation: false,
    /** 「個人情報の取り扱いについて」に同意しているかどうか */
    isAgreePersonalInformation: false,

    /** 電話番号取得方法取得API から取得した 電話番号の取得方法 */
    selectListOpticalPhoneNumberGetMethods: new Array<UcomOpticalPhoneNumberGetMethod>(),
    /** ご利用中の電話会社取得API から取得した ご利用中の電話会社 */
    selectListOpticalPhoneUseCompanies: new Array<UcomPhoneCompany>(),
    /** 光電話付加サービス取得API から取得した 光電話付加サービスの選択値 */
    selectListOpticalPhoneImpossibleServices: new Array<UcomAdditionalService>(),

    /** 申請年月日（表示用） */
    dispApplyDate: '',

    /** 会員情報 */
    member: null as Member | null,
    /** 物件情報 */
    property: null as Property | null,
    /** Ucom 物件基本情報 */
    ucomProperty: null as UcomPropertyResponse | null,
    /**導入形態 */
    importFormId: '',
    /** 特殊物件新規お申し込み可不可フラグ(trueでお申し込み可能) */
    isPossibleRegistration: true,

    /** 光電話お申し込み内容確認 結果メッセージ */
    messageCheckApplicationCode: [] as string[],

    /** 番号ポータビリティID（新規会員登録API: UCOM光電話 電話番号取得方法ID） */
    OPTICAL_PHONE_NUMBER_GET_METHOD_ID: OPTICAL_PHONE_NUMBER_GET_METHOD_ID,
    /** ご利用中の電話会社について、その他を選んだときの値 */
    OPTICAL_PHONE_USE_COMPANY: OPTICAL_PHONE_USE_COMPANY,
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    /** vueのcreated実行中かどうか */
    isCreating: true,
    /** 郵便番号（表示用） */
    dispZipcode: '',
    /** 生年月日（表示用） */
    dispBirthdate: '',
    /** onNext()実行中かどうか */
    isOnNextExecuting: false,
    /** ログインしているかどうか */
    isLoggedIn: false,
    /**部屋番号入力画面からの遷移か */
    isFromRoomNumberInput: false,
  }),
  validations: {
    inputData: {
      // 棟番号/部屋番号
      roomNumber: {
        required,
        fullWidth,
        maxLength: maxLength(64),
      },

      /** 入居状況 */
      tenancyStatus: {
        required,
      },
      /** 移転予定日 */
      expectedMoveInOn: {
        maxLength: maxLength(10),
      },
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 引継電話番号 */
      opticalPhoneTakeoverNumber: {
        maxLength: maxLength(14),
        phoneStyle,
      },
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人 */
      opticalPhoneHolderName: {
        maxLength: maxLength(64),
        fullWidth,
      },
      /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人カナ */
      opticalPhoneHolderNameKana: {
        maxLength: maxLength(64),
        fullKana,
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),

  async mounted(): Promise<void> {
    try {
      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];

      if (property) {
        this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
      }
    } catch (error) {
      throw error;
    }
  },
  async created(): Promise<void> {
    this.$store.commit('ucomEntryStore/hasOccurredBusinessErrorAtLinkServiceWithUcom', false);

    try {
      // 会員情報取得
      this.member = this.$store.getters['memberStore/member'];
      this.property = this.$store.getters['propertyStore/property'];

      // UCOM会員登録可能ユーザ以外は全て会員専用トップへ遷移
      if (!this.canEntry()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((e: any) => {
          checkRouterError(e);
        });
        return;
      }

      this.$store.commit('platformEditStore/beforePath', null); // 修正から戻ってきた場合は空にする

      // 物件基本情報取得API
      try {
        const result: UcomPropertyResponse | UcomSharedErrorResponse = await UcomSharedExternalApiService.findPropertyById(String(this.property?.buildingId));
        // お申し込み不可判定
        if (result instanceof UcomPropertyResponse && !result.is_possible_registration) {
          // UCOM新規会員登録時に特殊物件は情報入力画面にエラーを表示し登録不可（確認画面に遷移できない）とする
          // お申し込み不可なのでエラー表示とボタン押下不可へ
          this.isPossibleRegistration = false;
          this.errorMessages.push('お客様のお住まいでは会員登録できません。');
          window.scrollTo(0, 0);
          this.isCreating = false;
          return;
        } else if (result instanceof UcomPropertyResponse) {
          // 正常ケースの場合
          this.ucomProperty = result;
          this.$store.commit('ucomEntryStore/externalApiProperty', this.ucomProperty);

          // 表示用郵便番号取得
          this.dispZipcode = this.getDispZipcode(this.ucomProperty.postal_code);
        } else if (result instanceof UcomSharedErrorResponse) {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
        }

        // UCOM物件導入形態チェック
        const importFormId: string = this.$store.getters['importFormIdStore/importFormId'];
        this.isLoggedIn = await AuthService.isLoggedIn();

        if (importFormId) {
          if (!['2', '3', '4'].includes(importFormId)) {
            if (this.isLoggedIn) {
              await this.$router.push('/platform').catch((e: any) => {
                checkRouterError(e);
              });
            } else {
              await this.$router.push('/').catch((e: any) => {
                checkRouterError(e);
              });
            }
          } else {
            this.importFormId = importFormId;
          }
        } else if (result instanceof UcomPropertyResponse) {
          if (!['2', '3', '4'].includes(result.import_form_id)) {
            if (this.isLoggedIn) {
              await this.$router.push('/platform').catch((e: any) => {
                checkRouterError(e);
              });
            } else {
              await this.$router.push('/').catch((e: any) => {
                checkRouterError(e);
              });
            }
          } else {
            this.importFormId = result.import_form_id;
          }
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.IMPORT_FORM_ID);
        }
        this.$store.commit('importFormIdStore/importFormId', this.importFormId);
      } catch (error) {
        throw error;
      }

      // 電話番号取得方法取得
      try {
        const resultNumberGetter: UcomNumberGetterResponse | UcomErrorResponse = await UcomExternalApiService.findNumberGetter();
        if (resultNumberGetter instanceof UcomNumberGetterResponse) {
          // 正常ケースの場合
          this.selectListOpticalPhoneNumberGetMethods = resultNumberGetter.optical_phone_number_get_methods;
        } else {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.NUMBER_GETTER);
        }
      } catch (error) {
        throw error;
      }

      // ご利用中の電話会社取得
      try {
        const resultPhoneCompanies: UcomPhoneCompaniesResponse | UcomErrorResponse = await UcomExternalApiService.findPhoneCompanies();
        if (resultPhoneCompanies instanceof UcomPhoneCompaniesResponse) {
          // 正常ケースの場合
          this.selectListOpticalPhoneUseCompanies = resultPhoneCompanies.phone_company;
        } else {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PHONE_COMPANIES);
        }
      } catch (error) {
        throw error;
      }

      // 光電話付加サービス取得
      try {
        const resultDataAdditionalServices: UcomAdditionalServicesResponse | UcomErrorResponse = await UcomExternalApiService.findAdditionalServices();
        if (resultDataAdditionalServices instanceof UcomAdditionalServicesResponse) {
          // 正常ケースの場合
          this.selectListOpticalPhoneImpossibleServices = resultDataAdditionalServices.optical_phone_impossible_services;
        } else {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.ADDITIONAL_SERVICES);
        }
      } catch (error) {
        throw error;
      }

      // 光電話申込コード確認
      try {
        const resultFindOpticalPhoneCodes: UcomOpticalPhoneCodesResponse | UcomErrorResponse = await UcomExternalApiService.findOpticalPhoneCodes(
          this.property?.buildingId ? String(this.property?.buildingId) : ''
        );
        // エラーケースの場合
        if (resultFindOpticalPhoneCodes instanceof UcomErrorResponse) {
          this.messageCheckApplicationCode = resultFindOpticalPhoneCodes.errorInfo;

          // 正常ケースの場合
        } else if (resultFindOpticalPhoneCodes instanceof UcomOpticalPhoneCodesResponse) {
          this.messageCheckApplicationCode = resultFindOpticalPhoneCodes.campaign_note;
        }
      } catch (error) {
        // TODO UCOM_API一覧より、光電話申込不可の場合は、400エラーを受け取るが、どうする??
        throw error;
      }

      // 確認画面から戻って来た時の入力値の再現
      const entryInput: UcomEntryInputForm | null = this.$store.getters['ucomEntryStore/entryInputForm'];
      if (entryInput != null) {
        // 確認画面からの遷移の場合、取得した値をセットする
        this.inputData = entryInput;
        if (this.inputData.isFromRoomNumberInput) {
          this.isFromRoomNumberInput = true;
        }
        if (entryInput.expectedMoveInOn) {
          this.inputData.expectedMoveInOn = String(entryInput.expectedMoveInOn).replaceAll('/', '-');
        }
        // UCOM 光電話お申し込みコード確認API 実行結果を保存
        const applicationCodeData: UcomApplicationCodeData | null = this.$store.getters['ucomEntryStore/applicationCodeData'];
        if (applicationCodeData) {
          this.messageCheckApplicationCode = applicationCodeData.messageCheckApplicationCode;
        }
        // ご利用中の電話会社にご登録の住所 郵便番号を復元
        if (entryInput.opticalPhonePostalCode) {
          const buf = entryInput.opticalPhonePostalCode.split('-');
          this.zipCode.firstHalfOf = buf[0];
          this.zipCode.secondHalfOf = buf[1];
        }
      } else {
        // 初期値設定_画面入力項目
        // IP電話サービスのお申し込み
        this.inputData.isApplyIPTelSercice = false;
        // UCOM光電話 お申し込み要否
        this.inputData.applyOpticalPhone = '0';
        // GATE CALL050（M) お申し込み要否
        this.inputData.applyGATECALL050 = '0';
      }

      // 現在日付の取得
      this.inputData.applyDate = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH);
      this.dispApplyDate = processDate(FORMAT_DATE.YYYYMMDD_WITH_JAPANESE);

      // 表示用生年月日取得
      this.dispBirthdate = this.getDispDate(this.member?.birthdate ? this.member.birthdate : '');

      if (this.property?.buildingId) {
        this.inputData.propertyId = String(this.property.buildingId);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isCreating = false;
    }
  },
  methods: {
    async searchAddressByZipcode() {
      if (this.isSearchAddressByZipcodeExecuting) {
        return;
      }
      this.isSearchAddressByZipcodeExecuting = true;

      const zipCode = `${this.zipCode.firstHalfOf}-${this.zipCode.secondHalfOf}`;
      this.errorMessages = [];

      // 未入力時
      if (zipCode.length === 1) {
        this.errorMessages.push('UCOM光電話－ご利用中の電話会社にご登録の住所（郵便番号）を入力してください。');
      } else {
        const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;

        // 形式チェック
        if (!ZIP_CODE_REGEX.test(zipCode)) {
          this.errorMessages.push('郵便番号は数字7文字で入力してください');
        }
      }

      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isSearchAddressByZipcodeExecuting = false;
        return;
      }

      try {
        const resultList = await SpfApiService.searchAddresses(zipCode);
        if (resultList.length > 0) {
          const result = resultList[0];
          this.inputData.opticalPhoneAddress = `${result.address1}${result.address2}${result.address3}`;
        } else {
          this.errorMessages.push('該当する住所がございません。');
          window.scrollTo(0, 0);
        }
      } catch (e) {
        // エラーのときは何もしない
      }
      this.isSearchAddressByZipcodeExecuting = false;
    },
    /**
     * UCOM会員登録可能ユーザの条件
     *
     * ・ UCOM対象物件である
     * ・ UCOMお客様番号未連携
     * ・ 姓名、生年月日（18歳以上）が登録されている
     */
    canEntry(): boolean {
      if (this.property?.uaType !== UA_TYPE.UCOM) {
        return false;
      } else if (this.member?.primaryKeyUcom) {
        return false;
      } else if (this.member?.firstName && this.member.firstNameKatakana && this.member.givenName && this.member.givenNameKatakana && this.member.birthdate) {
        if (isUnder18(this.member.birthdate)) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    tenancyStatusSelected() {
      this.inputData.expectedMoveInOn = '';
    },
    /**
     * 電話番号取得方法の表示値を取得
     */
    getOpticalPhoneNumberGetMethodValue(): string {
      const selectedMethod = this.selectListOpticalPhoneNumberGetMethods.find(
        (value: UcomOpticalPhoneNumberGetMethod) => value.id === this.inputData.opticalPhoneNumberGetMethodId
      );
      return selectedMethod ? selectedMethod.value : '';
    },
    /**
     * ご利用中の電話会社の表示値を取得
     */
    getOpticalPhoneUseCompanyValue(): string {
      const selectedCompany = this.selectListOpticalPhoneUseCompanies.find((value: UcomPhoneCompany) => value.id === this.inputData.opticalPhoneUseCompany);
      return selectedCompany ? selectedCompany.value : '';
    },
    /**
     * 光電話付加サービス（有料）の表示値（複数）を取得
     */
    getAdditionalServiceMultipleValue(): string[] {
      if (this.inputData.opticalPhoneImpossibleService) {
        const selected = this.inputData.opticalPhoneImpossibleService.sort().map((id) => {
          const found = this.selectListOpticalPhoneImpossibleServices.find((value) => {
            return id === value.id;
          });
          return found ? found : undefined;
        });

        const valueList = selected.map((item) => (item ? item.value : ''));
        return valueList.filter((value) => value !== '');
      }
      return [];
    },
    /** 「確認」ボタン押下時 : 入力内容の確認画面に遷移する */
    async onNext() {
      if (this.isOnNextExecuting) {
        return;
      }

      this.isOnNextExecuting = true;

      // UCOM光電話 お申し込みをしない場合は UCOM光電話入力データを初期化
      if (this.inputData.applyOpticalPhone === '0') {
        // UCOM光電話 電話番号取得方法を初期化（番号ポータビリティの入力内容は別途初期化）
        this.inputData.opticalPhoneNumberGetMethodId = '';
        // UCOM光電話 光電話付加サービスを初期化
        this.inputData.opticalPhoneImpossibleService = [];
        // UCOM光電話 番号通知（無料）を初期化
        this.inputData.opticalPhoneNumberNotificationId = '';
      }
      // UCOM光電話 お申し込みをしない場合 または 番号ポータビリティ未選択の場合は、番号ポータビリティ入力データを初期化
      if (this.inputData.applyOpticalPhone === '0' || String(this.inputData.opticalPhoneNumberGetMethodId ?? '') !== OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY) {
        // 引継電話番号
        this.inputData.opticalPhoneTakeoverNumber = '';
        // 名義人区分
        this.inputData.opticalPhoneHolderCategory = '';
        // ご利用中の電話会社にご登録のお名前
        this.inputData.opticalPhoneHolderName = '';
        // ご利用中の電話会社にご登録のお名前カナ
        this.inputData.opticalPhoneHolderNameKana = '';
        // ご利用中の電話会社
        this.inputData.opticalPhoneUseCompany = '';
        // ご利用中の電話会社でその他を選んだときの入力される文字列
        this.inputData.opticalPhoneUseCompanyOther = '';
      }

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // 単項目チェック:
      // 18歳未満の場合は下記エラーメッセージを表示し、Portas側では紐づけなしのステータスに更新。 次回Portasにログインした際にはe-mansionに紐づいていない状態でログイン後トップが表示される
      if (!this.member?.birthdate) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((e: any) => {
          checkRouterError(e);
        });
        return;
      }

      if (isUnder18(this.member.birthdate)) {
        const updateMemberData = this.member;
        updateMemberData.propertyId = null; // 18歳以下なので物件紐付け無しで更新
        this.$store.dispatch('memberStore/updateMember', { memberId: this.member.id, memberDto: updateMemberData });
        await this.$store.dispatch('memberStore/memberStatus');
        this.errorMessages.push(
          `未成年者の場合は、親権者の方のご署名および捺印が必要となり、書面でのお申し込みとなりますので、以下窓口までお問合せください。<br>新規申込窓口&emsp;0120-955-740&emsp;営業時間12:00～21:00`
        );
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isOnNextExecuting = false;
        return;
      }

      // 棟番号/部屋番号
      if (this.v$.inputData.roomNumber?.$invalid) {
        // 必須チェック
        if (this.v$.inputData.roomNumber?.required.$invalid) {
          this.errorMessages.push(`棟番号/部屋番号を入力してください。`);
        } else if (this.v$.inputData.roomNumber?.fullWidth.$invalid) {
          this.errorMessages.push(`棟番号/部屋番号は全角64文字以内で入力してください。`);
        } else if (this.v$.inputData.roomNumber?.maxLength.$invalid) {
          this.errorMessages.push(`棟番号/部屋番号は全角64文字以内で入力してください。`);
        }
      }

      // お電話番号
      // 未入力時
      if (!this.inputData.phoneNumber) {
        this.errorMessages.push('お電話番号を入力してください。');
      } else {
        // 形式チェック
        const PHONE_NUMBER_REGEX = /^(?=.{12,13}$)[0-9]{2,4}-[0-9]{2,4}-[0-9]{4}$/;
        if (!PHONE_NUMBER_REGEX.test(this.inputData.phoneNumber)) {
          this.errorMessages.push('お電話番号は半角数字で、03-1234-5678 の形式で入力してください。');
        }
      }

      // 入居状況
      if (this.v$.inputData.tenancyStatus?.$invalid) {
        // 必須チェック
        if (this.v$.inputData.tenancyStatus?.required.$invalid) {
          this.errorMessages.push(`入居状況を入力してください。`);
        }
      }

      // 相関チェック
      if (this.inputData.tenancyStatus === '0') {
        // 形式チェック
        const DAY_REGEX =/^[12][0-9]{0,3}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        if (!this.inputData.expectedMoveInOn) {
          // 「未入居」を選択し、移転予定日が未入力だった場合
          this.errorMessages.push('移転予定日を入力してください。');
        } else if (!DAY_REGEX.test(this.inputData.expectedMoveInOn)) {
          this.errorMessages.push('正しい移転予定日を入力してください。');
        } else if (!(new Date().getTime() < Date.parse(String(this.inputData.expectedMoveInOn).replaceAll('-', '/')))) {
          this.errorMessages.push('正しい移転予定日を入力してください。');
        }
      }

      if (this.inputData.isApplyIPTelSercice) {
        // UCOM光電話 と GATE CALL050(M) を同時に申し込もうとした場合
        if (this.inputData.applyOpticalPhone === '1' && this.inputData.applyGATECALL050 === '1') {
          this.errorMessages.push('「UCOM光電話」「GATE CALL050(M)」は同時にお申し込みできません。');
        } else if (this.inputData.applyOpticalPhone === '0' && this.inputData.applyGATECALL050 === '0') {
          this.errorMessages.push('お申し込むIP電話サービスを選択してください。');
        }
      }

      if (this.inputData.isApplyIPTelSercice) {
        if (this.inputData.applyOpticalPhone === '1') {
          // 電話番号取得方法: 未選択時
          if (!this.inputData.opticalPhoneNumberGetMethodId) {
            this.errorMessages.push('電話番号取得方法を入力してください。');

            // 電話番号取得方法: 番号ポータビリティ選択時
          } else if (String(this.inputData.opticalPhoneNumberGetMethodId) === OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY) {
            // ご利用中の電話会社にご登録の住所（郵便番号）
            const myZipCode = `${this.zipCode.firstHalfOf}-${this.zipCode.secondHalfOf}`;

            // 未入力時
            if (myZipCode.length === 1) {
              this.errorMessages.push('郵便番号が入力されていません');
            } else {
              const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;

              // 形式チェック
              if (!ZIP_CODE_REGEX.test(myZipCode)) {
                this.errorMessages.push('郵便番号が不正です');
              }
            }

            // ご利用中の電話会社にご登録の住所（番地まで）
            // 未入力時
            if (!this.inputData.opticalPhoneAddress) {
              this.errorMessages.push('番地が入力されていません');
            } else {
              // 128文字より多いとき
              if (this.inputData.opticalPhoneAddress.length > 128) {
                this.errorMessages.push('番地は128文字以内で入力してください');
              }
            }

            // ご利用中の電話会社にご登録の住所（マンション名など）
            if (this.inputData.opticalPhoneBuildingName) {
              // 64文字より多いとき
              if (this.inputData.opticalPhoneBuildingName.length > 64) {
                this.errorMessages.push('マンション名などは64文字以内で入力してください。');
              }
            }

            // 引継電話番号未入力時
            if (!this.inputData.opticalPhoneTakeoverNumber) {
              this.errorMessages.push(`引継電話番号を入力してください。`);
            } else {
              if (this.v$.inputData.opticalPhoneTakeoverNumber?.maxLength.$invalid || this.v$.inputData.opticalPhoneTakeoverNumber?.phoneStyle.$invalid) {
                this.errorMessages.push(`正しい引継電話番号を入力してください。`);
              }
            }

            // 名義人区分未選択時
            if (!this.inputData.opticalPhoneHolderCategory) {
              this.errorMessages.push('名義人区分を選択してください。');
            }

            // ご利用中の電話会社にご登録のお名前: 未入力時
            if (!this.inputData.opticalPhoneHolderName) {
              this.errorMessages.push('ご利用中の電話会社にご登録のお名前を入力してください。');
            } else {
              // ご利用中の電話会社にご登録のお名前: 形式チェック
              if (this.v$.inputData.opticalPhoneHolderName?.$invalid) {
                if (this.v$.inputData.opticalPhoneHolderName?.maxLength.$invalid || this.v$.inputData.opticalPhoneHolderName?.fullWidth.$invalid) {
                  this.errorMessages.push(`正しいご利用中の電話会社にご登録のお名前を入力してください。`);
                }
              }
            }

            // ご利用中の電話会社にご登録のお名前カナ: 未入力時
            if (!this.inputData.opticalPhoneHolderNameKana) {
              this.errorMessages.push('ご利用中の電話会社にご登録のお名前カナを入力してください。');
            } else {
              //  名義人カナ形式チェック
              if (this.v$.inputData.opticalPhoneHolderNameKana?.$invalid) {
                if (this.v$.inputData.opticalPhoneHolderNameKana?.maxLength.$invalid || this.v$.inputData.opticalPhoneHolderNameKana?.fullKana.$invalid) {
                  this.errorMessages.push(`正しいご利用中の電話会社にご登録のお名前カナを入力してください。`);
                }
              }
            }

            // ご利用中の電話会社: 未選択時
            if (!this.inputData.opticalPhoneUseCompany) {
              this.errorMessages.push('UCOM光電話－ご利用中の電話会社を選択してください。');

              // ご利用中の電話会社: その他
            } else if (this.inputData.opticalPhoneUseCompany === OPTICAL_PHONE_USE_COMPANY.OTHER) {
              if (!this.inputData.opticalPhoneUseCompanyOther) {
                this.errorMessages.push('ご利用中の電話会社で「その他」選択時、入力必須となります');
              } else {
                if (this.inputData.opticalPhoneUseCompanyOther.length > 32) {
                  this.errorMessages.push('ご利用中の電話会社で「その他」選択時、32文字以内で入力してください。');
                }
              }
            }
          }

          // 光電話付加サービス（有料）
          if (this.inputData.opticalPhoneImpossibleService) {
            // UCOM光電話をお申し込み かつ 光電話付加サービス（有料） にて、 4 とそれ以外を同時に選択したとき
            if (this.inputData.opticalPhoneImpossibleService.length > 1) {
              if (this.inputData.opticalPhoneImpossibleService.includes(ADDITIONAL_SERVICES.S4_OPTIONAL_SERVICE_PACK)) {
                this.errorMessages.push('④オプションサービスパックと①、②、③は同時に選択できません。');
              }
            }

            // UCOM光電話をお申し込み かつ 光電話付加サービス（有料） にて、3 を選択したが、 2 を選択していない時
            if (!this.inputData.opticalPhoneImpossibleService.includes(ADDITIONAL_SERVICES.S2_CATCH_CALL)) {
              if (this.inputData.opticalPhoneImpossibleService.includes(ADDITIONAL_SERVICES.S3_CATCH_CALL_NUMBER_DISPLAY)) {
                this.errorMessages.push('「③キャッチ通話番号表示」をお申し込む場合は、「②キャッチ通話」を選択してください。');
              }
            }
          }

          // UCOM光電話 番号通知: 未選択時
          if (!this.inputData.opticalPhoneNumberNotificationId) {
            this.errorMessages.push('番号通知を選択してください。');
          }
        }
      }

      // 「利用規約」に同意していない場合
      if (!this.isAgreeTermsOfService) {
        this.errorMessages.push('「利用規約に同意する」にチェックを入れてください。');
      }

      // 「重要事項説明」に同意していない場合
      if (!this.isAgreeImportantExplanation) {
        this.errorMessages.push('「重要事項説明に同意する」にチェックを入れてください。');
      }

      // 「個人情報の取り扱いについて」に同意していない場合
      if (!this.isAgreePersonalInformation) {
        this.errorMessages.push('「個人情報の取り扱いについて同意する」にチェックを入れてください。');
      }

      // チェック結果確認 エラーメッセージを見せる為に画面最上部にスクロール
      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isOnNextExecuting = false;
        return;
      }

      const inputData = new UcomEntryInputForm({
        applyDate: this.inputData.applyDate,
        firstName: this.member.firstName,
        givenName: this.member.givenName,
        firstNameKana: this.member.firstNameKatakana,
        givenNameKana: this.member.givenNameKatakana,
        postalCode: this.ucomProperty?.postal_code,
        addressPrefectures: this.ucomProperty?.address_prefectures,
        addressMunicipalities: this.ucomProperty?.address_municipalities,
        addressTown: this.ucomProperty?.address_town,
        addressStreet: this.ucomProperty?.address_street,
        addressNumber: this.ucomProperty?.address_number,
        sendBuildingName: this.ucomProperty?.building_name,
        roomNumber: this.inputData.roomNumber,
        phoneNumber: this.inputData.phoneNumber,
        eMail: this.member.emailAddress,
        birthdate: String(this.member.birthdate).replaceAll('-', '/'),
        tenancyStatus: this.inputData.tenancyStatus,
        expectedMoveInOn: this.inputData.expectedMoveInOn ? String(this.inputData.expectedMoveInOn).replaceAll('-', '/') : undefined,
        isApplyIPTelSercice: this.inputData.isApplyIPTelSercice,
        applyOpticalPhone: this.inputData.applyOpticalPhone,
        opticalPhoneNumberGetMethodId: this.inputData.opticalPhoneNumberGetMethodId,
        opticalPhonePostalCode:
          this.inputData.opticalPhoneNumberGetMethodId === this.OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY
            ? `${this.zipCode.firstHalfOf}-${this.zipCode.secondHalfOf}`
            : undefined,
        opticalPhoneAddress:
          this.inputData.opticalPhoneNumberGetMethodId === this.OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY ? this.inputData.opticalPhoneAddress : undefined,
        opticalPhoneBuildingName:
          this.inputData.opticalPhoneNumberGetMethodId === this.OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY ? this.inputData.opticalPhoneBuildingName : undefined,
        opticalPhoneNumberGetMethodValue: this.getOpticalPhoneNumberGetMethodValue(),
        opticalPhoneTakeoverNumber: this.inputData.opticalPhoneTakeoverNumber,
        opticalPhoneHolderCategory: this.inputData.opticalPhoneHolderCategory,
        opticalPhoneHolderName: this.inputData.opticalPhoneHolderName,
        opticalPhoneHolderNameKana: this.inputData.opticalPhoneHolderNameKana,
        opticalPhoneUseCompany: this.inputData.opticalPhoneUseCompany,
        opticalPhoneUseCompanyOther: this.inputData.opticalPhoneUseCompany === this.OPTICAL_PHONE_USE_COMPANY.OTHER ? this.inputData.opticalPhoneUseCompanyOther : undefined,
        opticalPhoneUseCompanyValue: this.getOpticalPhoneUseCompanyValue(),
        opticalPhoneImpossibleService: this.inputData.opticalPhoneImpossibleService,
        opticalPhoneImpossibleServiceId: this.getAdditionalServiceMultipleValue(),
        opticalPhoneNumberNotificationId: this.inputData.opticalPhoneNumberNotificationId,
        applyGATECALL050: this.inputData.applyGATECALL050,
        isSendRegistrationCard: this.inputData.isSendRegistrationCard,
        propertyId: this.inputData.propertyId,
        isFromRoomNumberInput: this.isFromRoomNumberInput,
      });
      // 入力情報をストアに保存
      this.$store.commit('ucomEntryStore/entryInputForm', inputData);

      // UCOM 光電話お申し込みコード確認API 実行結果を保存
      const applicationCodeData: UcomApplicationCodeData = {
        messageCheckApplicationCode: this.messageCheckApplicationCode,
      };
      this.$store.commit('ucomEntryStore/applicationCodeData', applicationCodeData);

      // 画面遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/entry/confirm').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** 表示用郵便番号を取得する */
    getDispZipcode(zipcode: string) {
      if (zipcode.indexOf('-') > -1) {
        return zipcode;
      }
      return zipcode.slice(0, 3) + '-' + zipcode.slice(3);
    },
    /** 表示用日付を取得する */
    getDispDate(date: string) {
      return date.slice(0, 4) + '年' + date.slice(5, 7) + '月' + date.slice(8) + '日';
    },
    getNumericPortionOfPrice(priceString: string): number {
      return ProcessesCalledInUcomPages.getNumericPortionOfPrice(priceString);
    },
  },
  computed: {
    /**
     * メール送信ボタンの活性非活性に応じてクラスを変更する
     */
    getButtonClass(): string {
      return this.isAgreeTermsOfService && this.isAgreeImportantExplanation && this.isAgreePersonalInformation ? 'btn btn01 bs' : 'btn btn04 bs';
    },
    computedInputData() {
      return JSON.parse(JSON.stringify(this.inputData));
    },
  },
  watch: {
    computedInputData: {
      handler: function (newVal: UcomEntryInputForm, oldVal: UcomEntryInputForm) {
        // IP電話サービスのお申し込み要否が false になった際の制御
        if (!newVal.isApplyIPTelSercice) {
          // UCOM光電話 お申し込みをしない
          this.inputData.applyOpticalPhone = '0';
          // GATE CALL050 (M) お申し込みをしない
          this.inputData.applyGATECALL050 = '0';
        }
        // UCOM光電話－名義人 本人の場合は Portas 会員テーブルの氏名を表示
        if (newVal.opticalPhoneHolderCategory === '1' && this.member) {
          this.inputData.opticalPhoneHolderName = this.member.firstName + this.member.givenName;
          this.inputData.opticalPhoneHolderNameKana = this.member.firstNameKatakana + this.member.givenNameKatakana;
        }
        // UCOM光電話－名義人 本人以外の場合は空欄に切り替え
        if (newVal.opticalPhoneHolderCategory === '2' && oldVal.opticalPhoneHolderCategory === '1') {
          this.inputData.opticalPhoneHolderName = '';
          this.inputData.opticalPhoneHolderNameKana = '';
        }
      },
      deep: true,
    },
  },
});
</script>
