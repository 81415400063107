<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>解約</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ご解約受付完了" />ご解約受付完了</h2>
        <p class="red"><b>ホームページのご解約を受付ました。</b></p>
        <p>お手続き内容をメールにてお送りいたしました。</p>

        <div class="blc">
          <div class="sblc">
            <div class="sblc">
              <table class="table-type2">
                <tbody>
                  <tr>
                    <th><b>サービス終了日</b></th>
                    <td>
                      {{ serviceEndDate }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table-type2">
                <tr>
                  <th class="va-middle">
                    <h3 class="service-h3">ご解約に関する注意事項</h3>
                    <ul class="ul-basic01">
                      <li>1日～20日までに解約のお手続きをされた場合、当月末日をもってホームページがご利用いただけなくなります。</li>
                      <li>21日～月末までに解約のお手続きをされた場合、翌月末日をもってホームページがご利用いただけなくなります。</li>
                      <li>アップロードされているファイルは全て削除されます。</li>
                      <li>
                        ホームページ容量を追加でご利用いただいている場合は、ホームページサービス終了と同時にホームページ容量も削除されます。ホームページ容量追加分利用料は、1日～20日までにホームページの解約お手続きをされた場合は当月末まで、21日～月末までにホームページの解約お手続きをされた場合は翌月末までお支払いただきます。
                      </li>
                    </ul>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'homepage-cancel-complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    /** e-mansion サービス終了日 */
    serviceEndDate: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.isLoading = true;

      const serviceEndDate = await this.$store.getters['eMansionHomepageOpenStore/serviceEndDate'];
      // storeにデータが無ければ、利用状況/解約画面に遷移する
      if (!serviceEndDate) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage/usage').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.serviceEndDate = new Date(serviceEndDate).toLocaleDateString();

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      // e-mansion 会員専用トップページへ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
