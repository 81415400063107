import { AxiosInstance } from 'axios';
import axiosRetry, { isNetworkError } from 'axios-retry';

/**
 * axios で Network Error が発生したとき 3回リトライする設定
 * 元チケット: ARN_QA-902 ネットワークエラー発生時にmax3回リトライをする
 *
 * 開発者ツールのネットワークタブから、通信環境をオフラインにすると、ネットワークエラーを再現できる
 */
export const configureAxiosRetry = (axiosClient: AxiosInstance): void => {
  axiosRetry(axiosClient, {
    retries: 3,
    retryCondition: isNetworkError,
    retryDelay: (retryCount, error) => {
      return 500;
    },
    onRetry: (retryCount, error, config) => {
      if (isNetworkError(error)) {
        console.log([
          `NetworkErrorでaxiosのリトライを実行しました: リトライ${retryCount}回目`,
          ` isNetworkError: true`,
          ` url: ${config.baseURL}${config.url}`
        ].join('\n'));
      }

      // safari 対策の nocache を更新
      if (config.params?.nocache) {
        config.params.nocache = new Date().getTime();
      }
    }
  });
}
