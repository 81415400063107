import { GenericError } from '@auth0/auth0-spa-js';

/**
 * Auth0-spa-js が発生させるエラーにより、共通エラー画面に遷移してしまうことへの対応
 *
 * ・ ARN_QA-829: Nonce (nonce) claim mismatch in the ID token のとき、総合トップへ遷移させる
 * ・ ARN_QA-830: 特定の Auth0 認証エラーのとき、総合トップへ遷移させる
 */
export class Auth0ErrorHandler {
  /**
   * ARN_QA-830 の対応
   * 特定の Auth0 認証エラーかどうか判定する
   */
  public static isErrorAuthorizationFlow(error: Error): boolean {

    if (error instanceof GenericError) {
      const ERROR_AUTHORIZATION_FLOW = [
        'Invalid authorization code',
        'Failed to verify code verifier'
      ];

      if (ERROR_AUTHORIZATION_FLOW.includes(error.message)) {
        this.printErrorAuthorizationFlow(error);
        return true;
      }
    }

    return false;
  }

  private static printErrorAuthorizationFlow(error: GenericError): void {
    console.log([
      `auth0-spa-js の 認可コード取得フローにて エラー発生`,
      ` error: ${error.error}`,
      ` error_description: ${error.error_description}`,
      ` error.message: ${error.message}`
    ].join('\n'));
    console.log(error);
  }

  /**
   * ARN_QA-829 の対応
   * Nonce (nonce) claim mismatch in the ID token のエラーかどうか判定する
   */
  public static isErrorNonceClaimMismatchInIDToken(error: Error): boolean {
    const ERROR_NONCE_CLAIM_MISMATCH = 'Nonce (nonce) claim mismatch in the ID token';
    if (error.message.startsWith(ERROR_NONCE_CLAIM_MISMATCH)) {
      this.printError(error);
      return true;
    }
    return false;
  }

  private static printError(error: Error): void {
    console.log([
      `auth0-spa-js にて エラー発生`,
      ` error.message: ${error.message}`
    ].join('\n'));
    console.log(error);
  }
}
