import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResidenceRegistrationButton = _resolveComponent("ResidenceRegistrationButton")!
  const _component_ResidenceRegistrationSearch = _resolveComponent("ResidenceRegistrationSearch")!
  const _component_ResidenceRegistrationDisplay = _resolveComponent("ResidenceRegistrationDisplay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_ResidenceRegistrationButton, { "handle-radio-button-value": _ctx.handleRadioButtonChange }, null, 8, ["handle-radio-button-value"])
    ]),
    (!_ctx.isAfterRegister)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ResidenceRegistrationSearch, {
            "handle-has-display-property": _ctx.setHasDisplayProperty,
            "handle-update-display-property": _ctx.updateDisplayProperty,
            "handle-clear-selected-property": _ctx.clearSelectedProperty,
            onMemberEdit: _ctx.updateRadios
          }, null, 8, ["handle-has-display-property", "handle-update-display-property", "handle-clear-selected-property", "onMemberEdit"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasDisplayProperty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ResidenceRegistrationDisplay, { ref: "residenceRegistrationDisplay" }, null, 512)
        ]))
      : _createCommentVNode("", true)
  ]))
}