/** アカウントID管理：登録・更新 更新処理実行結果 */
export class UcomAccountManagementUpdateResponse {
  readonly _errorMessages!: string[]; // チェック結果メッセージ
  readonly _ucomId!: number; // UCOM_アカウントID
  readonly _accountName!: string; // アカウント名
  readonly _familyName!: string; // 姓
  readonly _firstName!: string; // 名
  readonly _familyNameKana!: string; // 姓_フリガナ
  readonly _firstNameKana!: string; // 名_フリガナ
  readonly _userName!: string; // ユーザーネーム
  readonly _password!: string; // UCOM_アカウントパスワード
  readonly _accountAuthorizationDisplay!: string; // UCOM_アカウント権限(表示用)
  readonly _isMailAddressRegistration!: boolean; // メールアドレス登録有無

  constructor(
    errorMessages: string[],
    ucomId: number,
    accountName: string,
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    userName: string,
    password: string,
    accountAuthorizationDisplay: string,
    isMailAddressRegistration: boolean
  ) {
    this._errorMessages = errorMessages;
    this._ucomId = ucomId;
    this._accountName = accountName;
    this._familyName = familyName;
    this._firstName = firstName;
    this._familyNameKana = familyNameKana;
    this._firstNameKana = firstNameKana;
    this._userName = userName;
    this._password = password;
    this._accountAuthorizationDisplay = accountAuthorizationDisplay;
    this._isMailAddressRegistration = isMailAddressRegistration;
  }

  get errorMessages(): string[] {
    return this._errorMessages;
  }

  get ucomId(): number {
    return this._ucomId;
  }

  get accountName(): string {
    return this._accountName;
  }

  get familyName(): string {
    return this._familyName;
  }

  get firstName(): string {
    return this._firstName;
  }

  get familyNameKana(): string {
    return this._familyNameKana;
  }

  get firstNameKana(): string {
    return this._firstNameKana;
  }

  get userName(): string {
    return this._userName;
  }

  get password(): string {
    return this._password;
  }

  get accountAuthorizationDisplay(): string {
    return this._accountAuthorizationDisplay;
  }
  
  get isMailAddressRegistration(): boolean {
    return this._isMailAddressRegistration;
  }
}
