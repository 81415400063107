/** アカウントID登録上限チェック結果 */
export class UcomAccountRegistrationMax {
  readonly _isAccountRegistrationMax!: boolean; //アカウントID登録数上限該否
  readonly _message!: string | undefined; //メッセージ

  constructor(isAccountRegistrationMax: boolean, message: string | undefined) {
    this._isAccountRegistrationMax = isAccountRegistrationMax;
    this._message = message;
  }

  get isAccountRegistrationMax(): boolean {
    return this._isAccountRegistrationMax;
  }

  get message(): string | undefined {
    return this._message;
  }
}
