import { SpfApiService } from '../api/spf-api-service';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import dayjs from 'dayjs';

export class PortasSubscriptionContract {
  /**
   * Portas会員の契約中商品ID(重複無し)のカンマ区切り文字列を作成し、サブスク商品コンポーネントに渡す
   * @param memberId 会員ID
   * @return 契約中商品ID(重複無し)のカンマ区切り文字列 例: '2,3'
   */
  public static async getProductIdContracting(memberId: number): Promise<string> {
    const serverDateTimeNow = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
    const contractedServiceIdSet = new Set<number>();
    const contractedServiceList = await SpfApiService.getSubscriptionList(memberId);
    contractedServiceList.contracts.map((contract) => {
      if (!contract.cancelDate || dayjs(serverDateTimeNow) < dayjs(contract.cancelDate).add(1, 'day')) {
        contractedServiceIdSet.add(contract.productsId);
      }
    });
    const contractedProductId = [...contractedServiceIdSet].join(',');
    return contractedProductId;
  }
}
