/** 同時お申し込み（UCOM）API：リクエスト */
export class LinkServiceWithUcomRequest {
  /** 申請年月日 （yyyy/mm/dd 形式） 必須 */
  public applyDate!: string;
  /** お名前 姓 必須 */
  public firstName!: string;
  /** お名前 名 任意 */
  public givenName!: string;
  /** お名前フリガナ 姓 必須 */
  public firstNameKana!: string;
  /** お名前フリガナ 名 任意 */
  public givenNameKana!: string;
  /** 設置先郵便番号 必須 */
  public postalCode!: string;
  /** 漢字都道府県 必須 */
  public addressPrefectures!: string;
  /** 漢字市区郡町村 必須 */
  public addressMunicipalities!: string;
  /** 漢字町名 必須 */
  public addressTown!: string;
  /** 漢字字名・丁目 必須 */
  public addressStreet!: string;
  /** 漢字番地 必須 */
  public addressNumber?: string;
  /** 設置先建物名 必須 */
  public sendBuildingName?: string;
  /** 棟番号/部屋番号 全角で必須 */
  public roomNumber!: string;
  /** E-Mail 必須 */
  public eMail?: string;
  /** お電話番号 必須 */
  public phoneNumber!: string;
  /** 生年月日 yyyy/mm/dd 形式 必須 */
  public birthday!: string;
  /** 入居状況 （1：入居済 0：未入居） 必須 */
  public tenancyStatus!: string;
  /** 移転予定日 （yyyy/mm/dd 形式） 任意 */
  public expectedMoveInOn?: string;
  /** 支払方法 （1：カード 5：未登録） 必須 */
  public paymentMethodId!: string;
  /** クレジットカードトークン 任意 */
  public creditCardToken?: string;
  /** カード会員ID 任意 */
  public cardAccountId?: string;
  /** UCOM 光電話 申込要否 （1：申し込む 0：申し込まない） 必須 */
  public isApplyOpticalPhone!: string;
  /**
   * UCOM 光電話 電話番号取得方法 ID （マスタ返却値.id を返却。0：新しく取得 53：番号ポータビリティ） 任意
   * UCOM 光電話 申込要否 が 「1: 申し込む」 の場合は必須
   */
  public opticalPhoneNumberGetMethodId?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（郵便番号） 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   */
  public opticalPhonePostalCode?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（番地まで） 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   */
  public opticalPhoneAddress?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（マンション名など） 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   */
  public opticalPhoneBuildingName?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ 引継電話番号 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   */
  public opticalPhoneTakeoverNumber?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ 名義人区分 （1：本人 2：本人以外） 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   * */
  public opticalPhoneHolderCategory?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ 名義人 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   */
  public opticalPhoneHolderName?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ 名義人 カナ 任意
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   * */
  public opticalPhoneHolderNameKana?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社 任意
   * マスタ返却値.id を返却(ただし「選択してください」は返却しない)
   * UCOM 光電話 電話番号取得方法 ID が 「53: 番号ポータビリティ」 の場合は必須
   */
  public opticalPhoneUseCompany?: string;
  /**
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社その他 任意
   * UCOM 光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社 が 「10: その他」 の場合は必須
   */
  public opticalPhoneUseCompanyOther?: string;

  /**
   * UCOM 光電話 光電話付加サービス（有料）ID
   * マスタ返却値.id を返却。配列。選択された項目全てを送ります
   * 「UCOM光電話 申込要否」が「1：申し込む」の場合は必須
   */
  public opticalPhoneImpossibleServices?: Array<string>;
  /**
   * UCOM 光電話 番号通知（無料）ID （1：相手に通知する 0：相手に通知しない）
   * 「UCOM光電話 申込要否」が「1：申し込む」の場合は必須
   */
  public opticalPhoneNumberNotificationId?: string;
  /** GATE CALL050（M) お申し込み要否 （1： 申し込む 0: 申し込まない） 必須 */
  public isApplyGateCall_050!: string;
  /** 登録証の郵送（希望する/しない） （1： 申し込む 0： 申し込まない） 任意 */
  public isSendRegistrationCard?: string;
  /** 物件ID */
  public propertyId!: string;

  /** 物件形態 */
  public importFormId!: string;

  constructor(partial: Partial<LinkServiceWithUcomRequest>) {
    Object.assign(this, partial);
  }
}

/** API : エラー時のレスポンス */
export class LinkServiceWithUcomErrorResponse {
  /** エラーコード */
  public readonly code!: string;

  /** エラー詳細   */
  public readonly detail!: string;

  /** エラーメッセージ   */
  public readonly message!: string;

  constructor(partial: Partial<LinkServiceWithUcomErrorResponse>) {
    Object.assign(this, partial);
  }
}
