export class EMansionMailPasswordInput {
  readonly _mailAddressPossessionCategory!: string;
  public get mailAddressPossessionCategory(): string {
    return this._mailAddressPossessionCategory;
  }
  readonly _mailAddressPossessionMessage!: string | undefined;
  public get mailAddressPossessionMessage(): string | undefined {
    return this._mailAddressPossessionMessage;
  }
  readonly _mailAddress!: string | undefined;
  public get mailAddress(): string | undefined {
    return this._mailAddress;
  }
  constructor(partial: Partial<EMansionMailPasswordInput>) {
    Object.assign(this, partial);
  }
}
