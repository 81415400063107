<template>
  <div class="during-registration-process">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み 部屋番号入力</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>部屋番号入力</li>
      </ul>
      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">部屋番号<span class="req">必須</span></th>
              <td><input type="text" class="text short" placeholder="101" v-model="roomNumber" @change="inputEventConvertToUpper" /></td>
            </tr>
          </tbody>
        </table>
        <div class="btn-area">
          <button type="button" class="btn btn01 bs" v-on:click="onNext">部屋番号を送信<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.my-font-weight-700 {
  font-weight: 700;
}
</style>

<script lang="ts">
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { ImportFormId } from '@/shared/classes/ucom/input-form-info';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { UcomEntryInputForm } from './classes/entry-input-form';
import { ProcessesCalledInUcomPages } from '@/shared/util/processes-called-in-ucom-page';

export default defineComponent({
  name: 'room-number-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    roomNumber: '',
    isLoading: false,
    importFormIdByApi: {} as ImportFormId,
    property: {} as Property,
    member: {} as Member,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    // ucom サイト URL
    ucomUrl: process.env.VUE_APP_UCOM_URL,
  }),
  async mounted(): Promise<void> {
    this.isLoading = true;
    this.member = this.$store.getters['memberStore/member'];
    this.property = this.$store.getters['propertyStore/property'];
    if (!this.member || !this.property) {
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    this.isLoading = false;
  },
  methods: {
    getForBuildingUrl(): string {
      return ProcessesCalledInUcomPages.getBuildingWebsiteUrl(this.ucomUrl, this.$store.getters['propertyStore/property']);
    },
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorMessages = [];
      //バリデーションチェック
      const regex = /^[a-zA-Z0-9-]+$/;
      if (!this.roomNumber || !regex.test(this.roomNumber)) {
        this.errorMessages.push('部屋番号は半角英数字記号(-)で入力してください。');
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      try {
        //導入形態取得処理
        if (!this.property.buildingId) {
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.UCOM.NO_BUILDING_ID);
        }
        this.importFormIdByApi = await SpfApiService.getImportFormId(this.property.buildingId, this.roomNumber);

        if (!this.importFormIdByApi || !['2', '3', '4'].includes(this.importFormIdByApi.importFormId)) {
          this.errorMessages.push(
            `ご入力いただいた部屋番号が確認できませんでした。<br/>お手数ですが、<a href="${this.getForBuildingUrl()}" target="same_tab"><span class="link red"><u>こちら</u></span></a>までお問い合わせください。`
          );

          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        //ストア保存処理
        this.$store.commit('importFormIdStore/importFormId', this.importFormIdByApi.importFormId);
        const inputData = new UcomEntryInputForm({
          roomNumber: this.zenkakuHankaku(this.roomNumber),
          isFromRoomNumberInput: true,
        });
        // 入力情報をストアに保存
        this.$store.commit('ucomEntryStore/entryInputForm', inputData);
      } catch {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      //画面遷移処理
      await this.$router.push('/ucom/entry/input').catch((e: any) => {
        checkRouterError(e);
      });
      this.isLoading = false;
    },
    /**
     * 大文字に変換する
     * @param event
     */
    inputEventConvertToUpper(event: Event) {
      this.roomNumber = inputEventConvertToUpper(event);
    },
    /**
     * 全角に変換する
     */
    zenkakuHankaku(str: string) {
      return str
        .replace(/[A-Za-z0-9-]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
        })
        .replace(/ /g, '　');
    },
  },
});
</script>
