import { Module } from 'vuex';
import { RootState } from '../index';

import { UcomConnectixEntryInputForm } from '../../pages/connectix/ucom/classes/entry-input-form';

/** UCOM Connectix お申し込み State */
const ucomConnectixEntryState: {
  entryInputForm: UcomConnectixEntryInputForm | null;
} = {
  /** UCOM Connectix お申し込み 入力フォーム */
  entryInputForm: null
};

/** State の型 */
type UcomConnectixEntryState = typeof ucomConnectixEntryState;

/** UCOM Connectix お申し込み Store */
export const ucomConnectixEntryStore: Module<UcomConnectixEntryState, RootState> = {
  namespaced: true,
  state: ucomConnectixEntryState,
  mutations: {
    entryInputForm: (state, value: UcomConnectixEntryInputForm) => (state.entryInputForm = value)
  },
  getters: {
    entryInputForm: (state): UcomConnectixEntryInputForm | null => state.entryInputForm
  }
};
