import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM アカウントID管理 登録・更新 State */
const ucomAccountManagementInputState: {
  ucomAccountId: string | undefined; //UCOMアカウントID
  accountName: string | undefined; //アカウント名
  familyName: string | undefined; //姓
  firstName: string | undefined; //名
  familyNameKana: string | undefined; //姓カナ
  firstNameKana: string | undefined; //名カナ
  userName: string | undefined; //ユーザーネーム
  password: string | undefined; //パスワード
  accountAuthorization: string | undefined; //アカウント権限
  isMailAddressRegistration: boolean | undefined; //メールアドレス登録有無
  currentMailAddress: string | undefined; //現在のメールアドレス
  mailAddress: string | undefined; //メールアドレス
  isChange: string | undefined; //登録変更フラグ
  before:string|undefined;//前画面(アカウントID管理orアカウントオプション変更)
  isUpdate: string|undefined //更新 or 登録
} = {
  ucomAccountId: undefined,
  accountName: undefined,
  familyName: undefined,
  firstName: undefined,
  familyNameKana: undefined,
  firstNameKana: undefined,
  userName: undefined,
  password: undefined,
  accountAuthorization: undefined,
  isMailAddressRegistration: undefined,
  currentMailAddress: undefined,
  mailAddress: undefined,
  isChange: undefined,
  before:undefined,
  isUpdate:undefined
};

/** State の型 */
type ucomAccountManagementInputState = typeof ucomAccountManagementInputState;

/** UCOM アカウントID管理 登録・更新 Store */
export const ucomAccountManagementInputStore: Module<ucomAccountManagementInputState, RootState> = {
  namespaced: true,
  state: ucomAccountManagementInputState,
  actions: {},
  mutations: {
    ucomAccountId: (state, value: string) => (state.ucomAccountId = value),
    accountName: (state, value: string) => (state.accountName = value),
    familyName: (state, value: string) => (state.familyName = value),
    firstName: (state, value: string) => (state.firstName = value),
    familyNameKana: (state, value: string) => (state.familyNameKana = value),
    firstNameKana: (state, value: string) => (state.firstNameKana = value),
    userName: (state, value: string) => (state.userName = value),
    password: (state, value: string) => (state.password = value),
    accountAuthorization: (state, value: string) => (state.accountAuthorization = value),
    isMailAddressRegistration: (state, value: boolean) => (state.isMailAddressRegistration = value),
    currentMailAddress: (state, value: string) => (state.currentMailAddress = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    isChange: (state, value: string) => (state.isChange = value),
    before: (state, value: string) => (state.before = value),
    isUpdate: (state, value: string) => (state.isUpdate = value),


  },
  getters: {
    ucomAccountId: (state): string | undefined => state.ucomAccountId,
    accountName: (state): string | undefined => state.accountName,
    familyName: (state): string | undefined => state.familyName,
    firstName: (state): string | undefined => state.firstName,
    familyNameKana: (state): string | undefined => state.familyNameKana,
    firstNameKana: (state): string | undefined => state.firstNameKana,
    userName: (state): string | undefined => state.userName,
    password: (state): string | undefined => state.password,
    accountAuthorization: (state): string | undefined => state.accountAuthorization,
    isMailAddressRegistration: (state): boolean | undefined => state.isMailAddressRegistration,
    currentMailAddress: (state): string | undefined => state.currentMailAddress,
    mailAddress: (state): string | undefined => state.mailAddress,
    isChange: (state): string | undefined => state.isChange,
    before: (state): string | undefined => state.before,
    isUpdate: (state): string | undefined => state.isUpdate,
  },
};
