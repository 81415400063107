export const UCOM_ACCOUNT_AUTHORITY = {
  NORMAL_ACCOUNT: 1,
  ADMIN_ACCOUNT: 2,
  CUSTOMER_NO_USER: 3,
} as const;
export type UCOM_ACCOUNT_AUTHORITY = typeof UCOM_ACCOUNT_AUTHORITY[keyof typeof UCOM_ACCOUNT_AUTHORITY];

export const convertUcomAccountAuthority = (value: number): UCOM_ACCOUNT_AUTHORITY | undefined => {
  for (const v of Object.values(UCOM_ACCOUNT_AUTHORITY)) {
    if (Number(v) == value) {
      return v;
    }
  }
};

/** アカウント権限表示用 */
export const UCOM_ACCOUNT_AUTHORITY_VALUE = {
  USER: { id: 1, value: 'ユーザー権限' },
  ADMIN: { id: 2, value: '管理者権限' },
  CUSTOMER_NUMBER: { id: 3, value: 'お客様番号権限' }
};
export type UCOM_ACCOUNT_AUTHORITY_VALUE = typeof UCOM_ACCOUNT_AUTHORITY_VALUE[keyof typeof UCOM_ACCOUNT_AUTHORITY_VALUE];

export const convertUcomAccountAuthorityValue = (id: number): UCOM_ACCOUNT_AUTHORITY_VALUE => {
  const ERROR_MESSAGE = 'Value not applicable for UCOM_ACCOUNT_AUTHORITY_VALUE';

  for (const v of Object.values(UCOM_ACCOUNT_AUTHORITY_VALUE)) {
    if (Number(v.id) == id) {
      return v;
    }
  }
  throw new Error(ERROR_MESSAGE);
};