<template>
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェックあんしんサービス plus</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ウイルスチェックあんしんサービス Plus</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェックあんしんサービス plus 確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="ウイルスチェックあんしんサービス plus" />お申し込み確認</h2>

        <ErrorMessagesComponent v-if="errorMessages" :errorMessageTitle="errorMessageTitle" :errorMessages="errorMessages" :isOneSentence="errorMessages.length == 1" />

        <!-- blc:お支払方法未登録 -->
        <div v-if="paymentStatus === '0'" class="err">
          <p class="red portal-h3">お申し込みの前に、お支払情報のご登録が必要です。</p>
          <ul class="ul-basic01">
            <li>e-mansion会員専用トップ「クレジットカード情報登録変更メニュー」からご登録ください。</li>
          </ul>
        </div>
        <!-- blc:お支払方法未登録 -->

        <div class="sblc">
          <div class="sblc">
            <p>お申し込み内容をご確認ください。</p>
            <h3 class="service-h3">お申し込み内容</h3>
            <p>「ウイルスチェックあんしんサービス plus」をお申し込みいただくメールアドレスをご確認ください。</p>
            <p class="caution">追加メールアドレスにウイルスチェックをお申し込みいただく場合は、必ず追加アカウントでPortas連携を行ってください。</p>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>お申し込み対象<br />メールアドレス</th>
                  <td>
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
                <tr>
                  <th>月額利用料</th>
                  <td>
                    <b>{{ feeInfo.fee }}円</b>
                    <span v-if="feeInfo.feeCp >= 0">
                      ⇒ <b class="font16px">{{ feeInfo.feeCp }}円キャンペーン中！</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">サービス規約への同意<span class="req">必須</span></th>
                  <td>
                    <p class="o"><a href="https://www.em-net.ne.jp/pdf/rlp01/vcplus.pdf" class="link" target="_blank">ウイルスチェックあんしんサービスplus規約</a></p>
                    <p class="o">お申し込みの前にサービス規約を必ずお読みになり、「サービス規約の内容に同意する」にチェックしてください。</p>
                    <label> <input type="checkbox" v-model="isAgreed" :disabled="paymentStatus === '0'" />サービス規約の内容に同意する </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="feeInfo.fee > 0 && paymentStatus === '1'">
          <p class="red">ご登録のお支払い方法により、お支払いいただきます。</p>
          <p>※価格はすべて税込み表示（消費税{{ $data.tax }}%）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" @click="goBack"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn bs" :class="[isAgreed ? 'btn01' : 'btn05']" :disabled="!isAgreed" @click="applyVirusCheck">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { VirusCheckFee } from '@/shared/classes/spf-api/e-mansion-mail';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'virus-check-apply-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data(): {
    isBusy: boolean;
    isAgreed: boolean;
    errorMessageTitle: string;
    errorMessages: string[];
    paymentStatus: string;
    tax: string;
    ispMemberStatusEMansion: string;
  } {
    return {
      isBusy: true,
      isAgreed: false,
      errorMessageTitle: '',
      errorMessages: [],
      paymentStatus: '',
      tax: '',
      ispMemberStatusEMansion: '',
    };
  },

  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    if (this.feeInfo.fee > 0) {
      await this.initPaymentStatus();
    }
    this.$data.tax = this.$store.getters['taxStore/tax'];
    this.isBusy = false;
  },
  methods: {
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },

    /**
     * お支払い情報登録状態を API から取得して初期化する
     */
    async initPaymentStatus(): Promise<void> {
      const memberId = await AuthService.getMemberId();
      const paymentStatusInfo = await SpfApiPaymentStatusAccessor.getPaymentStatus(Number(memberId));
      this.paymentStatus = paymentStatusInfo.status;
    },

    /**
     * 「申し込む」のクリックハンドラ
     */
    async applyVirusCheck(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 「サービス規約の内容に同意する」がチェックされていない場合、エラー表示
      if (!this.isAgreed) {
        // TODO : メッセージの内容は適当です。仕様を確認してください。
        this.errorMessages = ['お申し込みの前にサービス規約を必ずお読みになり、「サービス規約の内容に同意する」にチェックしてください。'];
        this.isBusy = false;
        return;
      }
      try {
        // ウィルスチェック申込更新 API を呼び出す
        const eMansionVirusCheckUpdateResponseDto = await SpfApiVirusCheckAccessor.updateVirusCheck(this.mailAddressId);

        // レスポンスの message に値があればエラーを表示して終了する

        if (eMansionVirusCheckUpdateResponseDto.message) {
          this.errorMessages = [eMansionVirusCheckUpdateResponseDto.message];
          this.isAgreed = false;
          this.isBusy = false;
          window.scrollTo(0, 0);
          return;
        }

        // メールアドレスを Store に保存する
        this.$store.commit('eMansionMailStore/setSelectedMailAddress', this.mailAddress);
        // 完了 (申込) 画面に遷移する
        await this.$router.push('/e-mansion/mail/virus-check-apply-complete').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },

    /**
     * 「戻る」のクリックハンドラ
     */
    async goBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      await this.$router.push('/e-mansion/mail/usage').catch((error) => {
        checkRouterError(error);
      });
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading

    /** 処理対象のメールアドレスID */
    mailAddressId(): number {
      const mailAddressId = this.$store.getters['eMansionMailStore/selectedMailAddressId'];

      if (!mailAddressId) {
        throw new Error();
      }

      return mailAddressId;
    },

    /** 処理対象のメールアドレス */
    mailAddress(): string {
      const mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];

      if (!mailAddress) {
        throw new Error();
      }

      return mailAddress;
    },

    /** 処理対象の利用料情報 */
    feeInfo(): VirusCheckFee {
      const feeInfo = this.$store.getters['eMansionMailStore/selectedFeeInfo'];
      return feeInfo;
    },
  }, // computed
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}

.caution {
  color: #cf1225;
}
</style>
