<template>
  <div class="already-linked">
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み済</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>お申し込み済</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" />UCOM光 レジデンスへの会員登録済です。</h2>
      </div>

      <div class="blc">
        <p>以下のボタンからUCOM光 レジデンス 会員専用トップページヘお進みください。</p>
        <p>会員登録時にエラーが表示されたお客様も正常に会員登録が行われておりますのでご安心ください。</p>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="goUcom()">UCOM光 レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'ucom-entry-already-linked',
  methods: {
    async goUcom() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
    }
  }
});
</script>
