<template>
  <div>
    <LoadingComponent v-if="isExecutingOnAccountlinkage" />
    <main class="underlayer-main">
      <h1>e-mansion メインアカウント連携</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion メインアカウント連携</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:e-mansion メインアカウント連携 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="e-mansion メインアカウント連携" />e-mansion メインアカウント連携</h2>
        <p>e-mansionのメインアカウント、パスワードをご入力いただき、アカウント連携をクリックしてください。</p>
        <div class="error-messages-component-container">
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component
            v-bind:errorMessages="errorMessages"
            v-bind:errorMessageTitle="errorMessageTitle"
            v-bind:isIspLinkingStyle="errorMessageTitle === auth0PropertyIdNoMatchErrorMessageTitle ? true : false"
          />
        </div>

        <p class="att red mt15"><b>※この画面ではメインアカウントのみ連携できます。追加アカウントの連携は、お住まいのマンション専用ホームページからお手続きください。</b></p>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウント<span class="req">必須</span></th>
              <td>
                <input type="text" class="text short" placeholder="tsunagu" v-model="inputData.userAccountFirst" /> -
                <input type="text" class="text short" placeholder="aa" v-model="inputData.userAccountSecond" />
              </td>
            </tr>
            <tr>
              <th class="va-middle">パスワード<span class="req">必須</span></th>
              <td><input type="password" class="text middle" v-model="inputData.password" /></td>
            </tr>
          </tbody>
        </table>

        <div class="sblc">
          <h3 class="service-h3">メインアカウントとは</h3>
          <p>
            e-mansionご入会時に発行したメインアカウントとなります。<br />
            入会時にお送りさせていただいた書類「設定情報一覧」をご覧ください。<br />
            2007年以降My e-mansion（マイページ）へログインをされていない方は、一度
            <a class="link" :href="`${eMansionMyUrl}/login/login.php?function=PASSWORDCHG`" target="_blank">My e-mansion（マイページ）</a>
            にてログインいただいた後にアカウント連携を行ってください。
          </p>
        </div>

        <div class="sblc">
          <div class="btn-area">
            <button class="btn btn01 bs" v-on:click="onAccountlinkage">アカウント連携</button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

div.sblc {
  p.note-on-id-and-password {
    margin-top: 8px;
  }
}

.error-messages-component-container {
  margin-top: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { required } from '@vuelidate/validators';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { EMansionLinkingInput } from '@/shared/classes/auth-manegement/e-mansion-linking-input';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AUTH0_PROPERTY_ID_NO_MATCH } from '@/shared/const/error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { InitEMansionMainAccount } from '@/shared/classes/spf-api/mail/model/account/init-e-mansion-main-account';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'linking',
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    auth0PropertyIdNoMatchErrorMessageTitle: AUTH0_PROPERTY_ID_NO_MATCH.displayTitle.E_MANSION,
    defaultErrorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    errorMessageTitle: '',
    /** 入力データ */
    inputData: {
      userAccountFirst: '' as string,
      userAccountSecond: '' as string,
      password: '' as string,
    },
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    service: 'Portas',
  }),
  validations: {
    inputData: {
      userAccountFirst: {
        required,
      },
      userAccountSecond: {
        required,
      },
      password: {
        required,
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていなければ トップ画面 に遷移する
    if (!isLoggedIn) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    /** 会員テーブルの e-mansion の主キーからユーザが e-mansion と連携しているかを確認する */
    const member: Member | null = this.$store.getters['memberStore/member'];
    if (member?.primaryKeyMye) {
      // 連携していれば e-mansion サービストップに遷移する
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isExecutingOnAccountlinkage = false;
    },
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.v$.inputData.$invalid) {
        if (this.v$.inputData.userAccountFirst?.required.$invalid || this.v$.inputData.userAccountSecond?.required.$invalid) {
          this.errorMessages.push('アカウントを入力してください。');
          this.showErrorMessage();
        }
        if (this.v$.inputData.password?.required.$invalid) {
          this.errorMessages.push('パスワードを入力してください。');
          this.showErrorMessage();
        }
        return false;
      }
      return true;
    },
    /** アカウント連携ボタン押下時 */
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        // 処理中フラグtrue：エラーメッセージ表示時のみ解除する
        this.isExecutingOnAccountlinkage = true;
        this.errorMessageTitle = this.defaultErrorMessageTitle;

        if (this.validate()) {
          const accountSubDomain = this.inputData.userAccountSecond;
          const eMansionLinkingInput: EMansionLinkingInput = {
            loginId: `${this.inputData.userAccountFirst}-${accountSubDomain}`,
            password: this.inputData.password,
          };
          /** e-mansion アカウント連携 API を使用して次の 3 つの処理を実行する
           * 1. e-mansion 外部 API に入力情報（アカウント・パスワード）を渡して e-mansion 登録済みか否かを確認
           * 2. Portas 会員テーブルの「e-mansion の主キー」項目にアカウントを格納
           * 3. Auth0 のプライマリにセカンダリとして e-mansion 会員 ID の紐付けを行う
           */
          const result: Promise<undefined | AuthManegementSharedErrorResponse> = await this.$store.dispatch('eMansionLinkingStore/updateLinking', eMansionLinkingInput);
          if (result instanceof AuthManegementSharedErrorResponse) {
            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              this.showErrorMessage();
              return;
            } else if (result.errors.some((value) => value === AUTH0_PROPERTY_ID_NO_MATCH.errorMessage)) {
              this.errorMessageTitle = this.auth0PropertyIdNoMatchErrorMessageTitle;

              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.E_MANSION[0]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.E_MANSION[1]);
              this.errorMessages.push(
                AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.E_MANSION[2].replace('REPLACE_URL', process.env.VUE_APP_REPLACE_URL_AUTH0_PROPERTY_ID_NO_MATCH_EMANSION)
              );

              this.showErrorMessage();
              return;
            } else if (result.status === 500) {
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.AUTH0_PORTAS.E_MANSION_ACCOUNT_LINKING);
            } else {
              this.errorMessages.push('アカウントあるいはパスワードが一致しません。');
              this.showErrorMessage();
              return;
            }
          }
          await AuthService.refresh();
          // アカウントテーブル作成APIコール
          // エラーハンドリングについては既存踏襲
          const member: Member = await this.$store.getters['memberStore/member'];
          await SpfApiAccountAccessor.insertInitEMansionMainAccount(new InitEMansionMainAccount(member.id, await AuthService.getEMansionLoginId(), accountSubDomain));
          // account 情報 を store に反映
          await this.$store.dispatch('eMansionAccountStore/eMansionAccountInfo', await AuthService.getEMansionLoginId());

          // 正常ケースの場合
          // this.$store.dispatch('eMansionLinkingStore/updateLinking', input) に成功するとバックエンドで会員ステータスが更新される
          // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
          this.$store.commit('memberStore/memberStatus', null);
          this.$store.commit('memberStore/member', null);

          // e-mansion サービストップ に遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
      } catch (error) {
        throw error;
      }
    },
  },
});
</script>
