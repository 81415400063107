<template>
  <div>
    <!-- underlayer-main -->
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>追加アカウント</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>追加アカウント</li>
      </ul>
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">ご利用状況</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:追加アカウント ご利用状況／お申し込み -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="追加アカウント" />ご利用状況</h2>
        <p>追加アカウントのお申し込みお手続きを行います。追加アカウントは9つまでお申し込みいただけます。</p>

        <div class="err" v-if="isErr">
          <p class="red">恐れ入りますが、入力内容をもう一度ご確認ください。</p>
          <ul class="ul-basic01">
            <li v-for="message in messageList" :key="message">{{ message }}</li>
            <li v-if="!isRegisterPayment">お支払情報の登録が必要です。</li>
          </ul>
        </div>

        <div class="sblc">
          <div class="sblc" v-if="isView">
            <div class="course-list" v-for="data in subAccountData" :key="data.eMansionAccountId">
              <!--件数分ループで表示-->
              <div class="grid pc-grid2 sp-grid1">
                <dl class="list-account">
                  <dt>Portas連携用アカウント</dt>
                  <dd>{{ data.eMansionAccountName }}</dd>
                </dl>
                <dl class="list-mail-account">
                  <dt>メールアカウント</dt>
                  <dd>{{ data.mailLocalPart + '-' + data.mailSubdomain }}</dd>
                </dl>
                <dl class="list-portas-pass">
                  <dt>Portas連携用パスワード</dt>
                  <dd>{{ data.initialAccountPassword }}</dd>
                </dl>
                <dl class="list-pop">
                  <dt>POPサーバー</dt>
                  <dd>{{ data.popServer }}</dd>
                </dl>
                <dl class="list-smtp">
                  <dt>SMTPサーバー</dt>
                  <dd>{{ data.smtpServer }}</dd>
                </dl>
                <dl class="list-imap">
                  <dt>IMAPサーバー</dt>
                  <dd>{{ data.imapServer }}</dd>
                </dl>
                <dl class="list-mail">
                  <dt>メールアドレス</dt>
                  <dd>{{ data.mailAddress }}</dd>
                </dl>
                <dl class="list-mail-pass">
                  <dt>メール初期パスワード</dt>
                  <dd>{{ data.initialMailPassword }}</dd>
                </dl>
                <dl class="list-empty">
                  <dt></dt>
                  <dd></dd>
                </dl>
                <dl v-if="data.canCancelBtn" class="list-cancel">
                  <dt></dt>
                  <dd>
                    <button class="btn btn01 bs" v-on:click="onCancel(data.mailLocalPart + '-' + data.mailSubdomain, data.mailAddress!, data.eMansionAccountId!)">
                      解約<i class="material-icons link link-icon">east</i>
                    </button>
                  </dd>
                </dl>
                <dl v-else class="red list-cancel">
                  <dt>{{ data.displayRegisterStatus }}</dt>
                  <dd>サービス終了日&ensp;{{ data.cancelOn }}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="blc">
            <ul class="ul-basic01">
              <li>現在ご利用中のアカウントを表示しています(登録手続き中のアカウントを含みます)。</li>
              <li>追加アカウントを解約する場合は「解約」ボタンをクリックしてください。確認画面に進みます。</li>
            </ul>
          </div>
        </div>
        <div class="blc">
          <h3 class="service-h3">追加メールアドレスのご利用開始について</h3>
          <p>e-mansionで追加メールアドレス（追加アカウント）をご利用になる場合は、追加メールアドレス毎に新たに異なるログインアカウントでのPortas登録が必要です。</p>
          <p class="emphasis"><span>●この画面から追加メールアドレスのPortas登録を行う場合 </span></p>

          <p>＞<a class="external_url" @click.prevent.stop.prevent="onLogout()" href="">「追加アカウント専用連携ページ」はこちら </a></p>
          <p class="caution">※Chromeをご利用の場合、ブラウザからログアウトする場合があるため、先に以下をお読みください</p>
          <p class="emphasis"><span>●別の端末から追加メールアドレスのPortas登録を行う場合、下記URLをコピーして、利用する端末から連携画面へお進みください。 </span></p>
          <p class="external_url">{{ additionalAccountUrl }}</p>
          <p class="emphasis">【Googleアカウント、Yahoo!アカウントでPortasにログイン中のお客様】</p>
          <p>
            「追加アカウント専用連携ページ」で同種の別アカウントで新規登録をいただく際は、<b><u>新規登録の前に</u>各アカウントのログアウト</b>を行ってください。
          </p>
          <br />
          <p>
            <a target="_blank" class="external_url" href="https://support.yahoo-net.jp/PccLogin/s/article/H000013196">＞Yahoo!アカウントをログアウトする</a
            >（別タブで表示されますので画面にしたがってログアウトしてください） <br />
          </p>
          <br />
          <p>
            <a target="_blank" class="external_url" href="https://accounts.google.com/Logout">＞Googleアカウントをログアウトする</a
            >（別タブが表示され自動的にログアウトします）<br />
          </p>
          <p class="list-indent">
            ＊GoogleChromeの場合、ブラウザにログイン中のGoogleアカウントもログアウトされます。<br />
            Googleの再ログインでお困りの場合は、お手数ですが、以下にてご確認ください。<br />
            <a target="_blank" class="external_url" href="https://support.google.com/accounts/answer/41078?hl=ja&co=GENIE.Platform%3DiOS&sjid=13800152530278787125-AP"
              >＞Googleアカウントヘルプ｜パスワードを変更または再設定する</a
            >
          </p>
        </div>
        <div v-if="isNotMaxSubAccount">
          <div class="sblc">
            <div class="sblc">
              <h3 class="service-h3">追加アカウント お申し込み</h3>

              <p>ご希望のメールアドレス(@前)を入力してください。</p>
              <table class="table-type2">
                <tbody>
                  <tr>
                    <th class="va-middle">ご希望メールアドレス(@前)<span class="req">必須</span></th>
                    <td>
                      <p class="red">半角英数小文字、-（ハイフン）、_（アンダーバー）の組み合わせで「3～12文字以内」でご指定ください。</p>
                      <input
                        v-model="inputMailAddress"
                        v-bind="{ placeholder: inputMailAddress }"
                        type="text"
                        class="text middle"
                        pattern="[a-z][a-z0-9_-]*[a-z0-9]$"
                        size="12"
                      />&nbsp;@

                      <ul class="ul-basic01">
                        <li>先頭の1文字は英字（a～z）のみ指定できます。</li>
                        <li>数字のみのアカウントは作成できません。</li>
                        <li>-（ハイフン）や_（アンダーバー）で終わるアカウントは作成できません。</li>
                        <li>.（ピリオド）,（カンマ）/（スラッシュ）&nbsp;（スペース）と、大文字（Aなど）は利用できません。</li>
                      </ul>
                    </td>
                  </tr>

                  <tr v-if="slimPlan">
                    <th class="va-middle">
                      <p>メールパックご利用料金</p>
                    </th>
                    <td>
                      <p>{{ subAccountInfo?.mailPackFee }}円</p>
                      <p>※価格は全て税込み表示(消費税{{ tax }}%)です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="isNotMaxSubAccount" class="blc">
              <ul class="ul-basic01">
                <li v-if="isViewPaymentMsg">ご登録のお支払方法によりお支払いいただきます。</li>
                <li>
                  ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」前日までに追加アカウントをお申し込みいただき、Portasにご登録いただくと、鍵のお引渡し日からご利用いただけます。
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /blc -->

        <div class="blc">
          <div class="btn-area">
            <p v-if="canApply"><b>入力内容をご確認の上、よろしければ「申し込む」をクリックしてください。</b></p>
            <!--お支払情報が未登録の場合は非活性、登録アカウントが上限の場合は非表示-->
            <button v-if="canApply" class="btn btn01 bs" v-on:click="onApply()">申し込む<i class="material-icons link link-icon">east</i></button>
            <button v-else-if="isNotMaxSubAccount" class="btn btn05 bs" disabled>申し込む<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /contents -->
</template>

<style lang="scss" scoped>
/* course-list
---------------------------------------------------- */
.course-list {
  margin: 10px 0;
  border-top: 1px solid #d3d3d3;
}

.course-list dl {
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #d3d3d3;
  word-break: break-all;
}

.course-list dl.line {
  background-color: #fbfbfb;
}

.course-list dl dt {
  font-weight: bold;
  float: left;
}

.course-list dl dd {
  padding-left: 140px;
}

.course-list .pc-grid2 dl:nth-child(odd) {
  border-right: 1px solid #d3d3d3;
}

.help-icon {
  position: relative;
  color: #cf1225;
  font-size: 1.8em !important;
}

.emphasis {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.external_url {
  color: #cf1225;
  text-decoration: underline;
}

.caution {
  color: #cf1225;
  font-weight: bold;
}
.list-indent {
  margin-left: 1rem;
  text-indent: -1rem;
}

@media only screen and (max-width: 767px) {
  .course-list {
    border-top: none;
  }
  .course-list dl dt {
    font-weight: normal;
  }
  .course-list .pc-grid2 dl:nth-child(odd) {
    border-right: none;
  }
  dl.list-mail {
    order: 1;
    background: #f2f2f2;
    font-weight: bold;
  }
  dl.list-account {
    order: 2;
  }
  dl.list-portas-pass {
    order: 3;
  }
  dl.list-mail-account {
    order: 4;
  }
  dl.list-mail-pass {
    order: 5;
  }
  dl.list-pop {
    order: 6;
  }
  dl.list-imap {
    order: 7;
  }
  dl.list-smtp {
    order: 8;
  }
  dl.list-cancel {
    order: 9;
    border-bottom: 2px solid #000;
  }
  dl.list-empty {
    display: none;
  }
  dl.list-mail > dt,
  dl.list-cancel > dt {
    font-weight: bold;
  }
}
</style>
<script lang="ts">
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { EMansionSubAccountInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-info';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { E_MANSION_SUB_ACCOUNT_LIMIT } from '@/shared/const/e-mansion/e-mansion-sub-account-limit';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { TRANSITION_STORAGE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

class SubAccountData {
  public eMansionAccountId?: string;
  public eMansionAccountName?: string;
  public initialAccountPassword?: string;
  public initialMailPassword?: string;
  public popServer?: string;
  public smtpServer?: string;
  public imapServer?: string;
  public mailAddress?: string;
  public mailLocalPart?: string;
  public mailSubdomain?: string;
  public displayRegisterStatus?: string;
  public registerStatus?: string;
  public cancelOn?: string;
  public canCancelBtn?: boolean;

  constructor(partial: Partial<SubAccountData>) {
    Object.assign(this, partial);
  }
}

export default defineComponent({
  name: 'list',
  components: {
    LoadingComponent,
  },

  data: () => ({
    /**AE17-001の返り値 */
    subAccountInfo: null as EMansionSubAccountInfo | null,
    subAccountData: new Array<SubAccountData>(),
    /**お支払情報登録状態 */
    paymentInfo: false,
    isNotMaxSubAccount: true, //追加アカウントの上限に達していると申し込みできない(上限だとfalse)
    isRegisterPayment: true, //スリムプランの時お支払登a録状態が登録されていなければfalse
    canApply: true,
    slimPlan: false,
    isLoading: true,
    isErr: false,
    isView: true,
    inputMailAddress: '',
    mailAddressDomain: '',
    mailAddress: '',
    messageList: [] as string[],
    tax: '',
    additionalAccountUrl: '',
    isViewPaymentMsg: false,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.tax = this.$store.getters['taxStore/tax'];
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM) {
        this.slimPlan = true;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        this.slimPlan = true;
      }
      const memberId = this.$store.getters['memberStore/member'].id;
      const apartmentId = this.$store.getters['propertyStore/property'].apartmentId;
      const slimPlanFlg: string = this.slimPlan ? '1' : '0';
      this.subAccountInfo = await SpfApiSubAccountAccessor.getSubAccountList(apartmentId, memberId, slimPlanFlg);
      if (this.subAccountInfo.countSubAccount === 0) {
        this.isView = false;
      }
      if ((this.subAccountInfo.countSubAccount ?? 0) >= E_MANSION_SUB_ACCOUNT_LIMIT.limit) {
        this.isNotMaxSubAccount = false;
      }

      if (!this.subAccountInfo.subAccountData) {
        this.subAccountData = [];
      } else {
        this.subAccountData = this.subAccountInfo.subAccountData?.map((value) => {
          return new SubAccountData({
            eMansionAccountId: value.eMansionAccountId,
            eMansionAccountName: value.eMansionAccountName,
            initialAccountPassword: value.initialAccountPassword,
            initialMailPassword: value.initialMailPassword,
            popServer: value.popServer,
            smtpServer: value.smtpServer,
            imapServer: value.imapServer,
            mailAddress: value.mailAddress,
            mailLocalPart: value.mailLocalPart,
            mailSubdomain: value.mailSubdomain,
            displayRegisterStatus: value.displayRegisterStatus,
            registerStatus: value.registerStatus,
            cancelOn: value.cancelOn,
            canCancelBtn: value.canCancelBtn,
          });
        });
      }

      if ((await SpfApiPaymentStatusAccessor.getPaymentStatus(memberId)).status == E_MANSION_PAYMENT_STATUS.REGISTERED) {
        this.paymentInfo = true;
      }
      // スリムプラン、且つお支払情報登録済みの場合に、お支払いいただく旨のメッセージを表示
      this.isViewPaymentMsg = this.paymentInfo && this.slimPlan;

      this.inputMailAddress = this.$store.getters['eMansionSubAccountStore/desiredAccount'];
      this.isRegisterPayment = this.checkRegisterPayment(this.paymentInfo, this.slimPlan);
      this.canApply = this.isNotMaxSubAccount && this.isRegisterPayment;
      this.isLoading = false;
      this.isErr = this.isErrMsg(this.isRegisterPayment, this.messageList);
      this.additionalAccountUrl = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_E_MANSION_SUB_ACCOUNT_LINKING_PAGE_FOR_DISPLAY}`;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**解約ボタンクリック */
    async onCancel(account: string, mailAddress: string, eMansionAccountId: string) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('eMansionSubAccountStore/account', account);
      this.$store.commit('eMansionSubAccountStore/mailAddress', mailAddress);
      this.$store.commit('eMansionSubAccountStore/eMansionAccountId', eMansionAccountId);
      await this.$router.push('/e-mansion/sub-account/cancel-confirm').catch((error) => {
        checkRouterError(error);
      });
    },
    /**申し込むボタンクリック*/
    async onApply() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.messageList = [];
      this.isErr = false;
      if (!this.inputMailAddress) {
        this.isErr = true;
        this.messageList.push('「ご希望アカウント」を入力してください。');
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      if (this.inputMailAddress.length < 3) {
        this.messageList.push('「ご希望アカウント」は、3文字以上12文字以内で入力してください。');
      }
      if (this.inputMailAddress.length > 12) {
        this.messageList.push('「ご希望アカウント」は、3文字以上12文字以内で入力してください。');
      }
      if (!this.inputMailAddress.match(/^[a-z0-9_-]+$/)) {
        this.messageList.push('「ご希望アカウント」は、半角英数小文字と「-（ハイフン）」「_（アンダーバー）」の組み合わせで指定してください。');
      }
      if (!this.inputMailAddress.charAt(0).match(/[a-z]/)) {
        this.messageList.push('「ご希望アカウント」の先頭1文字は英字（a~z）で指定してください。');
      }
      if (this.inputMailAddress.charAt(this.inputMailAddress.length - 1).match(/[_-]/)) {
        this.messageList.push('-（ハイフン）や_（アンダーバー）で終わるURLは指定できません。');
      }
      if (this.messageList.length > 0) {
        this.isErr = true;
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
      this.mailAddress = this.inputMailAddress + '@' + process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN;
      if (hasEMansionOriginalDomain(emOpMailFlag)) {
        this.mailAddressDomain = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        this.mailAddress = this.inputMailAddress + '@' + this.mailAddressDomain;
      }
      const mailAddressInputChackResult: EMansionMainMailAddressInput = await SpfApiMainMailAddressAccessor.checkMainMailAddress(this.mailAddress);

      if (!mailAddressInputChackResult.checkResult) {
        this.isErr = true;
        this.messageList = mailAddressInputChackResult.checkMessages;
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      //エラーがない場合

      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.inputMailAddress);
      this.$store.commit('eMansionSubAccountStore/usageFee', this.subAccountInfo?.mailPackFee);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/sub-account/confirm').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /**
     * スリムプランかつお支払情報が登録されていなければfalseを返す
     * @param paymentInfo お支払情報登録状態
     * @param slimPlan スリムプランフラグ
     */
    checkRegisterPayment(paymentInfo: boolean, slimPlan: boolean): boolean {
      if (paymentInfo) {
        return true;
      }
      if (!slimPlan) {
        return true;
      }
      //非活性であるという情報を保存
      this.$store.commit('eMansionSubAccountStore/canApply', false);
      return false;
    },
    isErrMsg(isRegisterPayment: boolean, messageList: string[]): boolean {
      if (!isRegisterPayment) {
        return true;
      }
      if (messageList.length > 0) {
        return true;
      }
      return false;
    },
    /** ログアウト処理 */
    async onLogout() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      localStorage.removeItem(TRANSITION_STORAGE);
      await AuthService.logout(process.env.VUE_APP_E_MANSION_LOGIN_ADDITIONAL_ACCOUNT);
      this.isLoading = false;
    },
  },
});
</script>
