<template>
  <!-- main-contents -->
  <div class="e-mansion-index">
    <LoadingComponent v-if="isOnNextExecuting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メインメールアドレス登録・変更：入力</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>メインメールアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->
      <!-- blc:アカウントID登録・変更-->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansionアカウントID登録・変更" />登録・変更お申し込み</h2>
        新しいメールアドレスを入力し、「次へ」ボタンをクリックしてください。
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <table class="table-type2">
          <tr>
            <th class="va-middle">現在のメールアドレス</th>
            <td>{{ mailAddress == null ? '未発行' : mailAddress }}</td>
          </tr>
          <tr>
            <th class="va-middle">新しいメールアドレス<span class="req">必須</span></th>
            <td>
              <p class="red">半角英数小文字、-（ハイフン）、_（アンダーバー）の組み合わせで「3～12文字以内」でご指定ください。</p>
              <ul class="ul-basic01">
                <li>先頭の1文字は英字（a～z）のみ指定できます。</li>
                <li>数字のみのアカウントは作成できません。</li>
                <li>-（ハイフン）や_（アンダーバー）で終わるアカウントは作成できません。</li>
                <li>.（ピリオド）,（カンマ）/（スラッシュ）&nbsp;（スペース）と、大文字（Aなど）は利用できません。</li>
              </ul>
              <br />
              <input type="text" v-model="newMailAddress" class="text middle" />&nbsp;@{{ mailAddressDomain }}
            </td>
          </tr>
        </table>
      </div>

      <div class="sblc">
        <ul class="ul-basic01">
          <li>登録・変更お申し込み直後は「お申込受付済み」と表示されます。 メールアドレスがご利用可能となりましたら「ご利用中」と表示されます。</li>
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」前日までにお申し込みいただくと、鍵のお引渡し日からご利用可能となります。</li>
        </ul>
      </div>
      <div v-if="isDisplayInfo">
        <h3 class="service-h3">メールアドレス変更に伴う注意事項</h3>
        <ul class="ul-basic01">
          <li>変更前のメールアドレスに戻すことはできません。</li>
          <li>同じメールアドレスの再取得はできません。</li>
          <li>変更前メールアドレス宛のメールは、変更翌日までは受信可能です。</li>
        </ul>
      </div>

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goNext()">次へ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>

    <!-- /blc -->
    <!--
    <p style="text-align: right">
    <font color="blue"><u>
        <a href="">▲ページTOPへ</a>
        </u></font>
    </p>
    -->
    <!-- footer -->
    <!-- バナーはAPIで出し分けを行う -->
    <!-- <footer class="emansion-footer">
    <ul>
      <li v-for="(banner, index) in banners" :key="index">
      <a :href="banner.url" target="My e-mansion"><img :src="banner.src" alt="" class="hover-img" /></a>
      </li>
    </ul>
    </footer> -->
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'eMansionMailMailInput',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 現在のメールアドレス */
    mailAddress: '',
    /** 新しいメールアドレス */
    newMailAddress: '',
    /** メールアドレスドメイン */
    mailAddressDomain: process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
    /** メールアドレス変更に伴う注意事項表示有無 */
    isDisplayInfo: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // onNextメソッドが実行中かどうか
    isOnNextExecuting: false,
    /** メールアドレス入力チェック結果 */
    eMansionMainMailaddressInput: null as EMansionMainMailAddressInput | null
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //メインメールアドレス情報をAPIより取得する
    try {
      // 現在のメールアドレスを取得(共通情報より取得)
      const accountInfo: EMansionAccountInfo = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      this.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddress ?? '';

      // 新しいメールアドレスを取得
      this.newMailAddress = await this.$store.getters['eMansionMainMailAddressStore/newMailAddress'];

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = await this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // メールアドレス変更に伴う注意事項表示有無を判定
      this.isDisplayInfo = this.mailAddress != undefined;
      this.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddressOriginal ?? this.mailAddress;

      // 独自ドメイン有無判定(1:あり、0:なし)
      if (hasEMansionOriginalDomain(property.em_op_mail_flag)) {
        this.mailAddressDomain = property.original_domain_name;
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /** 次へボタン押下時： メインメールアドレス登録変更：確認画面に遷移する */
    async goNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      try {
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];

        // 入力チェック
        // 新しいメールアドレス
        const newMailAddress = String(this.newMailAddress);

        if (newMailAddress == '' || newMailAddress == 'null') {
          this.errorMessages.push('「新しいメールアドレス」を入力してください。');
        } else if (!newMailAddress.match(/^[a-z0-9_-]+$/)) {
          this.errorMessages.push('「新しいメールアドレス」は、半角英数小文字と「-（ハイフン）」「_（アンダーバー）」の組み合わせで指定してください。');
        } else if (!(newMailAddress.length >= 3 && newMailAddress.length <= 12)) {
          this.errorMessages.push('「新しいメールアドレス」は、3文字以上12文字以内で入力してください。');
        }

        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isOnNextExecuting = false;
          return;
        }

        // メールアドレス入力チェックをAPIにて行う
        const newMailAddressAddDomain = newMailAddress + '@' + String(this.mailAddressDomain);
        this.eMansionMainMailaddressInput = await SpfApiMainMailAddressAccessor.checkMainMailAddress(newMailAddressAddDomain);

        // チェック結果をエラーメッセージ配列に追加
        if (!this.eMansionMainMailaddressInput.checkResult) {
          this.errorMessages.push(...this.eMansionMainMailaddressInput.checkMessages);
        }

        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isOnNextExecuting = false;
          return;
        }

        //メールアドレスをstoreに保存する
        this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.mailAddress);
        this.$store.commit('eMansionMainMailAddressStore/newMailAddress', newMailAddress);
        this.$store.commit('eMansionMainMailAddressStore/newMailAddressAddDomain', newMailAddressAddDomain);
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // メインメールアドレス登録・変更：確認画面へ遷移
      await this.$router.push('/e-mansion/main-mail-address/confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isOnNextExecuting = false;
    },
  },
});
</script>
