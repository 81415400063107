import { Module } from 'vuex';
import { RootState } from './index';
import { SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';

/** 解約対象契約 State */
const contractsSubjectToCancelState: {
  products: SubscriptionDetailsDto[] | null;
} = {
  products: null,
};

/** State の型 */
type contractsSubjectToCancelState = typeof contractsSubjectToCancelState;

/** 解約対象契約 Store */
export const contractsSubjectToCancelStore: Module<contractsSubjectToCancelState, RootState> = {
  namespaced: true,
  state: contractsSubjectToCancelState,
  mutations: {
    products: (state, value: SubscriptionDetailsDto[]) => (state.products = value),
  },
  getters: {
    products: (state): SubscriptionDetailsDto[] | null => state.products,
  },
};
