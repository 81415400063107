/** ウイルスチェック利用料 */
export interface VirusCheckFee {
  fee: number; // 月額利用料
  feeCp: number; // キャンペーン金額
}

// ウィルスチェックの登録ステータス (値) の定義
export const SUBSCRIPTION_STATUS = {
  NONE: null,
  APPLYING: '1',
  APPLIED: '2',
  CANCELLING: '3',
  CANCELLED: '4',
} as const;

export type SubscriptionStatus = typeof SUBSCRIPTION_STATUS[keyof typeof SUBSCRIPTION_STATUS];

/** メールアドレスごとのウィルスチェック利用状況 (これの配列を DTO が持つ) */
interface VirusCheckUsage {
  emailAddressId: number; // e-mansion_メールアドレスID
  emailAddress: string; // メールアドレス
  emailAddressOriginal: string; // 独自ドメインメールアドレス
  subscriptionStatusDisplay: string; // 登録ステータス (表示用)
  registerSubscriptionOn: string | null; // 申込日
  serviceInTime: string | null; // サービスイン日 (Date で受け取りたいが string で来る)
  mailSubscriptionStatus: SubscriptionStatus; // メールアドレス_登録ステータス (値)
  vckSubscriptionStatus: SubscriptionStatus; // ウイルスチェック_登録ステータス (値)
  cancelOn: string | null; // サービス終了日 (Date で受け取りたいが string で来る)
}

/** e-mansion ウィルスチェック利用状況 DTO */
export interface EMansionVirusCheckDto {
  feeInfo: VirusCheckFee; // 利用料情報
  dataCount: number; // data 件数
  data: VirusCheckUsage[]; // メールアドレスごとのウィルスチェック利用状況
  hasEmailAddress: boolean; // メールアドレス有無
  noEmailMessage: string | null; // メールアドレス有無メッセージ
  hasMainMailAddress:boolean;//メインメールアドレス有無
}

/** メールボックス容量の表示文字列の定義 */
export type MbCapacityLabel = '' | '25MB' | '40MB' | '55MB' | '70MB' | '85MB' | '100MB' | '1GB';

/** メールアドレスごとメールボックス容量 (これの配列を DTO が持つ) */
interface MbCapacity {
  emailAddressId: number; // e-mansion_メールアドレスID
  emailAddress: string; // メールアドレス
  emailCapacity: MbCapacityLabel; // メールボックス容量 (表示文字列)
  canChangeCapacity: boolean; // メールボックス容量変更可否
}

/** e-mansion メールボックス容量 DTO */
export interface EMansionMbCapacityDto {
  memberId: number; // Portas会員ID
  hasEmailAddress: boolean; // メールアドレス有無
  noEmailMessage: string | null; // メールアドレス有無メッセージ
  dataCount: number; // data 件数
  data: MbCapacity[]; // メールアドレスごとのメールボックス容量
}

/** e-mansion ウィルスチェック申込更新レスポンス DTO */
export interface EMansionVirusCheckUpdateResponseDto {
  emailAddress: string; // メールアドレス
  message: string | null; // エラーメッセージ
}

/** e-mansion ウィルスチェック解約更新レスポンス DTO */
export interface EMansionVirusCheckCancelResponseDto {
  emailAddress: string; // メールアドレス
  cancelOn: string; // サービス終了日
  message: string | null; // エラーメッセージ
}

/** e-mansion メールボックス容量更新レスポンス DTO */
export interface EMansionMbCapacityUpdateResponseDto {
  memberId: number; // Portas会員ID
  emailAddressId: string; // メールアドレスID
  errorMessage: string | null; // エラーメッセージ
  canChangeCapacity: '0' | '1'; // メールボックス容量変更可否
}
