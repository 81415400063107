import DisplayOnSpTsunaguOnlineProductHtml from '@/pages/tnos/display-on-sp-tsunagu-online-product-html.vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import store from '@/store';

const tsunaguOnlineProductRouter = [
  {
    path: '/tnos',
    name: 'つなぐオンラインショップ商品スマホ向け表示',
    component: DisplayOnSpTsunaguOnlineProductHtml,
    meta: {
      title: 'Portas | つなぐオンラインショップ商品スマホ向け表示 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'つなぐオンラインショップ商品表示',
      isPublic: true
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      await store.dispatch('memberStore/member'); // 会員情報を取得
      return next();
    }
  }
];

export default tsunaguOnlineProductRouter;
