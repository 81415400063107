import { RevisionService } from "@/shared/services/revision/revision-service";

export class NoticeRevisionUpdate {

  /**
   * 強制更新お知らせチェック
   * 強制更新フラグが立っているか確認する
   */
  public static async check(): Promise<boolean> {

    if (await RevisionService.isAlreadyRevisionUp()) {
      return true;
    }

    return false;
  }

  /**
   * 強制更新お知らせ
   * ローカルストレージのフラグが立っていた場合は強制更新後のため、お知らせを出す。
   */
  public static notice() {
    window.alert('Portasが更新されました。');
  }

}