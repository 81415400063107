<template>
  <div>
    <PortasServiceDescriptionAtPlatformEntryConfirm />

    <section>
      <h2 class="underlayer-h2 my-portas-service__title">Portasサービス お支払い方法</h2>
      <VeritransCreditCardComponentAtPlatformEntryConfirm
        :key="resetCounter"
        :reset-counter="resetCounter"
        v-on:card-token-for3d-secure-authorize="cardTokenFor3dSecureAuthorize"
        v-on:card-token-for-register="cardTokenForRegister"
      />
    </section>
  </div>
</template>

<script lang="ts">
import VeritransCreditCardComponentAtPlatformEntryConfirm from '@/shared/components/veritrans-credit-card-component-at-platform-entry-confirm.vue';
import { defineComponent } from 'vue';
import PortasServiceDescriptionAtPlatformEntryConfirm from './portas-service-description-at-platform-entry-confirm.vue';

export default defineComponent({
  name: 'portas-service-application-controller-at-platform-entry-confirm',
  components: {
    PortasServiceDescriptionAtPlatformEntryConfirm,
    VeritransCreditCardComponentAtPlatformEntryConfirm,
  },
  emits: ['cardTokenFor3dSecureAuthorize', 'cardTokenForRegister'],
  data: () => ({
    resetCounter: 0,
  }),
  methods: {
    /**
     * クレジットカードコンポーネントから受け取ったクレジットカードトークン情報を親コンポーネントにパスする
     */
    cardTokenForRegister(token: string, tokenExpireDate: string): void {
      this.$emit('cardTokenForRegister', token, tokenExpireDate);
    },
    /**
     * クレジットカードコンポーネントから受け取ったクレジットカードトークン情報を親コンポーネントにパスする(本人認証用)
     */
    cardTokenFor3dSecureAuthorize(token: string, tokenExpireDate: string): void {
      this.$emit('cardTokenFor3dSecureAuthorize', token, tokenExpireDate);
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
  },
});
</script>
