<template>
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェックあんしんサービス plus</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion会員専用トップ</router-link></li>
        <li>ウイルスチェックあんしんサービス Plus</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェックあんしんサービス plus　確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="ウイルスチェックあんしんサービス plus" />お申し込み受付完了</h2>
        <p>
          <b>
            <font color="red">ウイルスチェックあんしんサービス plusのお申し込みありがとうございました。</font>
          </b>
        </p>
        <table class="table-type2">
          <tbody>
            <tr>
              <th>お申し込み対象<br />メールアドレス</th>
              <td>
                <b>{{ mailAddress }}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <ul class="ul-basic01">
          <li>ウイルスチェックサービスの提供状況は「ご利用状況確認」をクリックしてご確認ください。</li>
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」の前日までにお申し込みいただくと、鍵のお引渡し日からご利用可能となります。</li>
        </ul>
      </div>
      <!-- /blc -->

      <!-- blc:ウイルスチェックあんしんサービス plus　ご請求内容の確認方法 -->
      <div class="br10 bs border-orange mt20 pl10 pr10 pb10">
        <h3 class="service-h3">ご請求内容の確認方法</h3>
        <p>ご請求明細は、e-mansion会員専用トップページ「ご請求明細・販売証明書表示」メニューからご確認いただけます。</p>
        <p>また、毎月のご請求確定時にメールでお知らせすることができます。</p>
        <p>メールの配信を希望される場合は「e-mansion お知らせメールの配信設定」からお申し込みください。</p>
        <h3 class="service-h4">お問い合わせ先：e-mansionサポートセンター</h3>
        <p>お住まいのマンション専用ホームページ「会員サポート」メニューよりカスタマーサポートまでお問い合わせください。</p>
      </div>
      <!-- blc -->

      <div class="blc">
        <div class="btn-area">
          <p class="form-btn-txt">続けてお申し込み・解約される場合は、「ご利用状況確認」をクリックしてください。</p>
          <button class="btn btn01 bs" @click="goToUsage">ご利用状況確認<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs mt-sp-1" @click="goToTop">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>
<style>
@media only screen and (max-width: 767px) {
  button.mt-sp-1 {
    margin: 10px 5px 0 5px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'virus-check-apply-complete',
  components: {
    LoadingComponent,
  },

  data(): {
    mailAddress: string | undefined;
    isBusy: boolean;
  } {
    return {
      mailAddress: undefined,
      isBusy: true,
    };
  },

  mounted() {
    // 前画面で保存したメールアドレスを受け取る
    this.mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];
    if (!this.mailAddress) {
      throw new Error();
    }

    // 前画面に戻れないようにストアをクリアしておく
    this.$store.commit('eMansionMailStore/setSelectedMailAddressId', null);
    this.$store.commit('eMansionMailStore/setSelectedMailAddress', null);
    this.$store.commit('eMansionMailStore/setSelectedFeeInfo', null);
    this.isBusy = false;
  },
  methods: {
    /**
     * 「ご利用状況確認」のクリックハンドラ
     */
    async goToUsage(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      await this.$router.push('/e-mansion/mail/usage').catch((error) => {
        checkRouterError(error);
      });
    },

    /**
     * 「e-mansion 会員専用トップページへ」のクリックハンドラ
     */
    async goToTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading
  }, // computed
});
</script>
