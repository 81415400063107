import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { isTransitionNoLinked } from '.';

import Completed from '@/pages/five-a/entry/completed.vue';
import Confirm from '@/pages/five-a/entry/confirm.vue';
import Input from '@/pages/five-a/entry/input.vue';
import Terms from '@/pages/five-a/entry/terms.vue';
import FiveAIndex from '@/pages/five-a/index.vue';
import LinkService from '@/pages/five-a/link-service.vue';
import fiveANotLeft from '@/pages/five-a/remaining-work/not-left.vue';
import fiveARoomNumberInput from '@/pages/five-a/remaining-work/room-number-input.vue';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Property } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { UA_TYPE } from '@/shared/const/service-type';
import store from '@/store';

/** Five.A ルーティング定義 */
const fiveARouter = [
  {
    path: '/5a',
    name: 'Five.A サービストップ',
    component: FiveAIndex,
    meta: {
      title: 'Portas | Five.A 会員専用トップページ | ポルタス',
      description: 'Portas（ポルタス）Five.A会員専用トップページになります。',
      keywords: 'Portas,ポルタス,Five.A,Connectix,コネクティクス',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (await isTransitionNoLinked(UA_TYPE.FIVE_A)) {
        return next();
      }
      return next({ path: '/login-forwarding' });
    },
  },
  {
    path: '/5a/link-service',
    name: 'Five.A Connectix お申し込み ',
    component: LinkService,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixお申し込みを行います。',
      keywords: 'Portas,ポルタス,Five.A,Connectix,コネクティクス',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      try {
        if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
          return next({ path: '/platform' });
        }

        const property: Property = store.getters['propertyStore/property'];
        const propertyParams = { propertyId: property.apartmentId, uaType: UA_TYPE.FIVE_A };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/property', propertyParams);

        let isAvailableConnectix = false;

        if (fiveAProperty instanceof FiveAProperty) {
          // 物件基本情報を使う
          // connectix 対象物件かどうか調べる
          if (fiveAProperty.op.sdwan.flag === '1') {
            isAvailableConnectix = true;
          }
        }

        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        } else if (isAvailableConnectix) {
          next(); // connectix 対象物件 のとき
          return;
        } else {
          next({ path: '/platform' });
          return;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  {
    path: '/5a/entry/terms',
    name: 'five-a 新規会員登録 サービス規約',
    component: Terms,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み サービス規約 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。サービス規約',
      keywords: 'Five.A Connectixお申し込みサービス規約',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      try {
        if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
          return next({ path: '/platform' });
        }

        const property: Property = store.getters['propertyStore/property'];
        const propertyParams = { propertyId: property.apartmentId, uaType: UA_TYPE.FIVE_A };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/property', propertyParams);

        let isAvailableConnectix = false;

        if (fiveAProperty instanceof FiveAProperty) {
          // 物件基本情報を使う
          // connectix 対象物件かどうか調べる
          if (fiveAProperty.op.sdwan.flag === '1') {
            isAvailableConnectix = true;
          }
        }

        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        } else if (isAvailableConnectix) {
          next(); // connectix 対象物件 のとき
          return;
        } else {
          next({ path: '/platform' });
          return;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  {
    path: '/5a/entry/input',
    name: 'five-a 新規会員登録 入力',
    component: Input,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み お客様情報入力 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。お客様情報入力',
      keywords: 'Five.A Connectixお申し込みお客様情報入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
        return next({ path: '/platform' });
      }

      // 直前のURLがサービス規約画面などでなければサービス規約画面に遷移する
      if (
        from.path !== '/5a/entry/terms' &&
        from.path !== '/5a/entry/confirm' &&
        from.path !== '/platform/my-page/member-edit' &&
        from.path !== '/platform/my-page/member-completed'
      ) {
        next({ path: '/5a/entry/terms' });
        return;
      }

      next();
    },
  },
  {
    path: '/5a/entry/confirm',
    name: 'five-a 新規会員登録 確認',
    component: Confirm,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。確認',
      keywords: 'Five.A Connectixお申し込み確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
        return next({ path: '/platform' });
      }

      // 直前のURLが入力画面でなければサービス規約画面に遷移する
      if (from.path !== '/5a/entry/input' && from.path !== '/platform/my-page/member-edit' && from.path !== '/platform/my-page/member-completed') {
        next({ path: '/5a/entry/terms' });
        return;
      }

      next();
    },
  },
  {
    path: '/5a/entry/completed',
    name: 'five-a 新規会員登録 完了',
    component: Completed,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。完了',
      keywords: 'Five.A Connectix申し込み完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
        return next({ path: '/platform' });
      }

      // 直前のURLが確認画面でなければサービス規約画面に遷移する
      if (from.path !== '/5a/entry/confirm') {
        next({ path: '/5a/entry/terms' });
        return;
      }

      next();
    },
  },
  {
    path: '/5a/remaining-work/room-number-input',
    name: 'Five.A 部屋番号入力',
    component: fiveARoomNumberInput,
    meta: {
      title: 'Portas | Five.A 部屋番号入力 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A 宅内工事のご確認はこちらから行ってください。入力',
      keywords: 'Five.A 部屋番号入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
        return next({ path: '/platform' });
      }
      // 会員専用トップからでなければ会員専用トップに遷移する
      if (from.path !== '/platform/' && from.path !== '/platform' && from.path !== '/select-construction-date/confirm') {
        next({ path: '/platform' });
        return;
      }

      next();

      //宅内工事入力情報のストアをリセット
      store.commit('selectedBusinessDayStateStore/selectedBusinessDayForFirst', null);
      store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForFirst', null);
      store.commit('selectedBusinessDayStateStore/selectedBusinessDayForSecond', null);
      store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForSecond', null);
      store.commit('selectedBusinessDayStateStore/selectedBusinessDayForThird', null);
      store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForThird', null);
      store.commit('selectedBusinessDayStateStore/selectedPhone', 1);
      store.commit('selectedBusinessDayStateStore/phoneNumber', '');
    },
  },
  {
    path: '/5a/remaining-work/not-left',
    name: 'Five.A 宅内工事確認結果(宅内工事無し)',
    component: fiveANotLeft,
    meta: {
      title: 'Portas | Five.A 宅内工事確認結果 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A 宅内工事のご確認はこちらから行ってください。',
      keywords: 'Five.A 宅内工事確認結果',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionNoLinked(UA_TYPE.FIVE_A))) {
        return next({ path: '/platform' });
      }

      // 直前のURLが「Five.A 部屋番号入力」でなければ会員専用トップに遷移する
      if (from.path !== '/5a/remaining-work/room-number-input') {
        next({ path: '/platform' });
        return;
      }

      next();
    },
  },
];

export default fiveARouter;
