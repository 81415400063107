<template>
  <div class="my-page-index">
    <!-- 退会の可否の判定のためローディングが必要 -->
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portasマイページ</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>マイページ</li>
      </ul>
      <div class="blc">
        <error-messages-component v-bind:isOneSentence="true" v-bind:errorMessages="errorMessages" />

        <template v-if="contractingProductList.length !== 0 || cancellingProductList.length !== 0">
          <div class="blc">
            <h2 class="underlayer-h2">ご契約サービス</h2>

            <div v-for="contractItem in displayContractList" class="mb10" v-bind:key="contractItem.productsId">
              <!-- BBSS の商品の場合 -->
              <template v-if="contractItem.contentProviderId === CONTENT_PROVIDER_ID.BBSS">
                <p class="toBold mb10"><span v-html="contractItem.productName"></span></p>
                <p class="margin-left word-break-break-all" v-if="contractItem.productsId !== getProductIds().AdGuard">
                  ・ダウンロードURL：
                  <a :href="`${contractItem.downloadUrl}`" class="link" target="_blank"> {{ contractItem.downloadUrl }}</a>
                </p>
                <p class="margin-left word-break-break-all" v-if="contractItem.productsId === getProductIds().AdGuard">
                  ・インストール方法：
                  <a :href="`${contractItem.downloadUrl}`" class="link" target="_blank"> {{ contractItem.downloadUrl }}</a>
                </p>
                <p class="margin-left">　※お問い合わせ時はライセンスキーをお伝えください。</p>
                <p class="margin-left">・ライセンスキー： {{ contractItem?.ticket }}</p>
                <p class="margin-left" v-if="contractItem.productsId !== getProductIds().AdGuard">
                  ・ダウンロード手順は
                  <a v-if="contractItem.productsId === getProductIds().NortonSecurity" class="link" :href="`${BBSSNortonLink}`" target="_blank">こちら</a>
                  <a v-if="contractItem.productsId === getProductIds().internetSagiWallForMultiDevice" class="link" :href="`${BBSSSagiWallLink}`" target="_blank">こちら</a>
                  をご覧ください。
                </p>
                <p class="margin-left" v-if="contractItem.description" v-html="sanitizeContent(contractItem.description)"></p>
              </template>
              <!-- BBSS U-NEXTの商品以外の場合 -->
              <template v-else>
                <p class="toBold mb10">{{ contractItem.productName }}</p>
                <p class="margin-left" v-if="contractItem.description" v-html="sanitizeContent(contractItem.description)"></p>
              </template>
            </div>

            <div v-if="isContractedUNext">
              <p class="toBold mb10">U-NEXT</p>
              <p class="margin-left">初期ログインID：{{ unextAccountInfo?.unextLoginId }}</p>
              <p class="margin-left">初期パスワード：{{ unextAccountInfo?.unextPassword }}</p>
              <p class="margin-left">U-NEXT ログイン：<a class="link" :href="`${unextLoginPageLink}`" target="_blank">ログイン｜U-NEXT(unext.jp)</a></p>
              <div class="margin-left red">
                ※U-NEXTへログイン後に初期ログインIDと初期パスワードの変更が可能です。変更後のログインID、パスワードはPortasでは確認できません。U-NEXTへログインのうえご確認ください。
              </div>
              <p v-if="unextAccountInfo?.giftCode" class="mt10 margin-left">ギフトコード：{{ unextAccountInfo?.giftCode }}</p>
              <p class="mt10 margin-left red">
                <a
                  class="link"
                  href="https://www.arteria-net.com/portas/service/u-next?utm_source=Portas&utm_medium=mypage&utm_campaign=u-next&utm_content=confirmation_portop#faq"
                  target="_blank"
                  >U-NEXT for Portasのよくあるご質問
                </a>
              </p>
            </div>
          </div>
        </template>
        <div class="blc">
          <h2 class="underlayer-h2">サービスご契約状況</h2>

          <div class="mb10 margin-left">
            「Portasサービス」内でお申し込みされたサービスのご契約状況をご確認いただけます。<br />
            また、サービスの解約につきましても、こちらからお申し込みください。
          </div>
          <table class="table-type2 table-adjustment-css">
            <tbody>
              <tr>
                <th>ご契約内容</th>
                <td>
                  <div v-if="cancellingProductList.length >= 1 || contractingProductList.length >= 1"><span v-html="concatenatedProductNames"></span></div>
                  <div v-else>ー</div>
                </td>
              </tr>
              <tr>
                <th>ご契約中商品数</th>
                <td>{{ totalContracts }}個</td>
              </tr>
              <div class="red margin-left">※解約処理中の商品数も含まれます</div>
            </tbody>
          </table>
        </div>
        <!-- 契約中の商品が存在しない場合 かつ Connectix契約無しの場合 は非表示 -->
        <template v-if="contractingProductList.length !== 0 || isContractedConnectix">
          <div class="blc">
            <h2 class="underlayer-h2">契約中商品一覧</h2>
            <table class="table-type2">
              <tbody>
                <tr v-for="contractingProduct in contractingProductList" :key="contractingProduct.contractId">
                  <th>
                    <label :class="{ toPointer: selectingCancelProductsButton }">
                      <input type="checkbox" v-if="selectingCancelProductsButton" :value="contractingProduct" v-model="selectedProducts" />
                      <span class="product-name" v-html="contractingProduct.products.productsName"></span>
                    </label>
                  </th>
                  <td>
                    <div>
                      お申し込み日：
                      {{ formatDate(contractingProduct.requestDateTime) }}
                    </div>
                    <!-- U-NEXTの場合は表示させないようにする -->
                    <div v-if="!(contractingProduct.productsId === getProductIds().UNextMonthly)">
                      無料期間：
                      <span v-if="checkExistFreePeriod(contractingProduct.contractStartDate, contractingProduct.billingStartDate)">
                        {{ formatDate(contractingProduct.contractStartDate) }} から {{ getFreePeriodEndDate(contractingProduct.billingStartDate) }}
                      </span>
                      <span v-else> ー </span>
                    </div>
                  </td>
                </tr>
                <tr v-if="isContractedConnectix">
                  <th>
                    <label :class="{ toPointer: selectingCancelProductsButton }">
                      <input type="checkbox" v-if="selectingCancelProductsButton" value="Connectix" v-model="selectedProducts" />
                      Connectix
                    </label>
                  </th>
                  <td>
                    <button class="btn btn01 bs connectix-btn-css" @click="onNextConnectixCancelPage()">ご利用状況確認</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="btn-area">
              <template v-if="selectingCancelProductsButton">
                <button class="btn btn05 bs" v-on:click="onChangeDisplay()">解約商品選択をやめる</button>
                <button :class="buttonColorSet" :disabled="isButtonDisabled" type="button" @click="onCancelSelectedProducts">選択した商品を解約する</button>
              </template>
              <!-- 初期表示は、「解約商品を選択する」ボタン -->
              <template v-else>
                <button class="btn btn01 bs" type="button" v-on:click="onChangeDisplay()">解約商品を選択する</button>
              </template>
            </div>
          </div>
        </template>

        <template v-if="cancellingProductList.length !== 0">
          <div class="blc">
            <h2 class="underlayer-h2">解約処理中商品一覧</h2>
            <table class="table-type2">
              <tbody>
                <tr v-for="cancellingProduct in cancellingProductList" :key="cancellingProduct.contractId">
                  <th>
                    <span v-html="cancellingProduct.products.productsName"></span>
                  </th>
                  <td>
                    <div>
                      お申し込み日：
                      {{ formatDate(cancellingProduct.requestDateTime) }}
                    </div>
                    <div>
                      解約お申し込み日：
                      {{ formatDate(cancellingProduct.cancelApplicationDate) }}
                    </div>
                    <div>
                      サービス終了日：
                      {{ formatDate(cancellingProduct.cancelDate) }}
                      <span class="red toBold" v-if="cancellingProduct.productsId === getProductIds().UNextMonthly"
                        >※ U-NEXT解約後もU-NEXT側の解約処理が完了するまでご利用いただけますが、サービス終了日は解約月の月末日が表示されます。</span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>

        <div class="blc">
          <div class="toBold red mb10">解約に関する注意事項</div>
          <div class="red margin-left">
            上記「Portasサービス」内のサービスを解約することにより、セット対象のご契約サービスが1つのみとなった場合、契約サービスは
            <span class="toBold">セット料金から通常料金に変更</span>となります。 U-NEXTの場合は<span class="toBold">Portasセット値引きが適用外</span>となります。<br />
            <span v-if="isContractedConnectix">Connectixを除く、</span>各インターネットサービスをご契約いただいているオプションはセットの対象外となります。
          </div>
        </div>
        <div class="blc">
          <div class="toBold mb10">Portasサービスに関するお問い合わせ</div>
          <div>Portasサービスに関連するご契約状況やご請求についてのお問い合わせは、以下のお問い合わせフォームからご連絡をお願いいたします。</div>
          <div>各サブスクサービスの商品に関するお問い合わせはお答えすることはできませんので、ご了承くださいますようお願いいたします。</div>
          <img class="material-icons link link-icon margin-left" src="../../../images/service-h2.svg" />
          <a href="https://support.ucom.ne.jp/contact/portas/" class="link" target="_blank">Portasサービスのお問い合わせ</a>
        </div>
        <div class="blc">
          <h2 class="underlayer-h2">ご請求明細</h2>
          <div class="mb10">Portasでお申し込みされたサービスのご請求明細をご確認いただけます。</div>
          <div>
            <img class="material-icons link link-icon margin-left" src="../../../images/service-h2.svg" />
            <router-link to="/platform/my-page/billing-history/list" class="link">ご請求明細</router-link>
          </div>
        </div>

        <div class="blc">
          <h2 class="underlayer-h2">Portas会員登録情報</h2>
          <div class="mb10">Portasに登録されている会員情報はこちらから変更いただけます。</div>
          <div>
            <img class="material-icons link link-icon margin-left" src="../../../images/service-h2.svg" />
            <router-link to="/platform/my-page/member-edit" class="link">会員情報変更</router-link>
          </div>
          <div>
            <img class="material-icons link link-icon margin-left" src="../../../images/service-h2.svg" />
            <router-link to="/platform/my-page/payment-method/list" class="link">Portasサービス お支払い方法の確認・登録・変更・削除</router-link>
          </div>
          <div>
            <img class="material-icons link link-icon margin-left" src="../../../images/service-h2.svg" />
            <a @click="onWithdrawal" class="link toPointer">Portas会員からの退会お申し込み</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.product-name::before {
  content: ' ';
}
</style>

<script lang="ts">
import dayjs from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import { defineComponent } from 'vue';

import { SpfApiService } from '../../../shared/services/api/spf-api-service';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { BBSSServiceInfo } from '@/shared/classes/spf-api/bbss-service-info';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { ProductIds } from '@/shared/classes/spf-api/portas-subscription/const';
import { SubscriptionDetailsArray, SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import { Property } from '@/shared/classes/spf-api/property';
import { UNextAccountInfo } from '@/shared/classes/spf-api/u-next/u-next-account-info';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { CONTENT_PROVIDER_ID, SERVICE_NAME, UA_TYPE } from '@/shared/const/service-type';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { DisplayContract } from '@/shared/services/portas-subscription/portas-subscription-type';
import { UnpaidBillingService } from '@/shared/services/portas-subscription/unpaid-billing-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { formatDate as utilFormatDate } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';

type DataType = {
  member: Member | null;
  memberStatus: MemberStatus | null;
  property: Property | null;
  isLoading: boolean;
  canCancelFlagUcom: boolean;
  canCancelFlagEmansion: boolean;
  canCancelFlagFiveA: boolean;
  canCancelFlagGameWith: boolean;
  isSubmitting: boolean;
  /** Connectix契約有無 */
  isContractedConnectix: boolean;
  /** U-NEXT契約有無 */
  isContractedUNext: boolean;
  /** U-NEXT_アカウント情報 */
  unextAccountInfo: UNextAccountInfo | null;
  /** BBSSサービス情報 */
  BBSSServiceInfo: BBSSServiceInfo | null;
  /** BBSSサービス情報一覧 */
  BBSSServiceInfoArray: BBSSServiceInfo[];
  /** サブスクリプション一覧 */
  subscriptionList: SubscriptionDetailsArray | null;
  /** チェックボックスで選択された商品のIDを保存 */
  selectedProducts: SubscriptionDetailsDto[];
  /** 契約中商品一覧 */
  contractingProductList: SubscriptionDetailsDto[];
  /** 解約処理中商品一覧 */
  cancellingProductList: SubscriptionDetailsDto[];
  /** ボタンの切り替え用*/
  selectingCancelProductsButton: boolean;
  gwid: string | undefined;
  errorMessages: string[];
  errorMessageTitle: string;
  /** U-NEXTのログインページリンク先 */
  unextLoginPageLink: string;
  /** BBSSのノートンセキュリティリンク先 */
  BBSSNortonLink: string;
  /** BBSSの詐欺ウォールリンク先 */
  BBSSSagiWallLink: string;
  /** コンテンツプロバイダーID */
  CONTENT_PROVIDER_ID: typeof CONTENT_PROVIDER_ID;
  /** 契約中 契約処理中の表示用 */
  displayContractList: DisplayContract[];
};

/** Index コンポーネント */
export default defineComponent({
  name: 'my-page-index',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data(): DataType {
    return {
      /** 会員情報 */
      member: null,
      memberStatus: null,
      property: null,
      isLoading: true,
      // 退会要件を満たすかどうか
      canCancelFlagUcom: true,
      canCancelFlagEmansion: true,
      canCancelFlagFiveA: true,
      canCancelFlagGameWith: true,
      // /platform/my-page/member-edit へ遷移する処理中かどうか
      isSubmitting: false,
      isContractedConnectix: false,
      isContractedUNext: false,
      unextAccountInfo: null,
      BBSSServiceInfo: null,
      BBSSServiceInfoArray: [],
      subscriptionList: null,
      selectedProducts: [],
      contractingProductList: [],
      cancellingProductList: [],
      selectingCancelProductsButton: false,
      gwid: undefined,
      errorMessages: [],
      errorMessageTitle: '',
      /**  U-NEXTのログインページリンク先 */
      unextLoginPageLink: process.env.VUE_APP_UNEXT_LOGIN_PAGE_URL,
      /**  BBSSのノートンセキュリティリンク先 */
      BBSSNortonLink: process.env.VUE_APP_BBSS_NORTON_URL,
      /**  BBSSの詐欺ウォールリンク先 */
      BBSSSagiWallLink: process.env.VUE_APP_BBSS_SAGIWALL_URL,
      /** コンテンツプロバイダーID */
      CONTENT_PROVIDER_ID: CONTENT_PROVIDER_ID,
      /** 契約中 契約処理中の表示用 */
      displayContractList: [],
    };
  },
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 会員ストアから会員ステータス情報を取得する
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.memberStatus) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 会員ストアから会員情報を取得する
      this.member = this.$store.getters['memberStore/member'];
      if (!this.member) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.property = this.$store.getters['propertyStore/property'];

      // 退会要件を満たすかどうか調べる
      const canCancelResult = await Promise.all([
        AccountUtilService.canCancelUcom(),
        AccountUtilService.canCancelEmansion(),
        AccountUtilService.canCancelFiveA(),
        AccountUtilService.canCancelGameWith(),
      ]);

      // 外部APIがエラーのとき
      const foundIndex = canCancelResult.findIndex((value) => value instanceof Error);

      if (foundIndex === 0) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      } else if (foundIndex === 1) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      } else if (foundIndex === 2) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
      } else if (foundIndex === 3) {
        if ((canCancelResult[3] as any).response?.data.status_code == '503') {
          this.errorMessages.push(
            'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
          );
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
        }
      }

      this.canCancelFlagUcom = typeof canCancelResult[0] === 'boolean' ? canCancelResult[0] : true;
      this.canCancelFlagEmansion = typeof canCancelResult[1] === 'boolean' ? canCancelResult[1] : true;
      this.canCancelFlagFiveA = typeof canCancelResult[2] === 'boolean' ? canCancelResult[2] : true;
      this.canCancelFlagGameWith = typeof canCancelResult[3] === 'boolean' ? canCancelResult[3] : true;

      // サーバー現在時刻取得 APIからサーバー側の現在日時を取得
      const serverDateTimeNow = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // 表示対象契約情報取得処理(「サブスクリプション一覧取得 API」を実行し、ログイン中のPortasユーザーに紐づく契約情報の一覧を取得する。)
      this.subscriptionList = await SpfApiService.getSubscriptionList(this.member.id);

      // Connectix契約状態取得処理
      this.isContractedConnectix = this.checkContractedConnectix();

      // 契約中商品数算出処理
      this.contractingProductList = this.getContractingProducts();

      // 解約処理中商品数算出処理
      this.cancellingProductList = this.getCancellingProducts(serverDateTimeNow);

      /**
       * 契約中商品・解約処理中商品 リストの中に U-NEXT商品 が存在するかチェックする
       * ※商品ID=4（U-NEXT月額プラン）
       * ※商品ID=5（U-NEXT月額以外の商品）
       */
      this.isContractedUNext = this.contractingProductList.concat(this.cancellingProductList).some((subscription) => {
        return subscription.productsId === this.getProductIds().UNextMonthly;
      });

      if (this.isContractedUNext) {
        this.unextAccountInfo = await this.getUNextAccountInfo(this.member.id);
      }

      // 契約中 または 契約処理中 の商品一覧
      if (this.contractingProductList.length > 0 || this.cancellingProductList.length > 0) {
        // 契約中 と 解約処理中 の配列
        const concatenated = this.contractingProductList.concat(this.cancellingProductList);
        // 契約中商品・解約処理中商品 リストの中に BBSS商品 が存在するかチェックし、BBSSサービス情報取得
        for (let i = 0; i < concatenated.length; i++) {
          if (concatenated[i].contents.contentProviderId === CONTENT_PROVIDER_ID.BBSS) {
            this.BBSSServiceInfo = await this.getBBSSServiceInfo(concatenated[i].contractId);
            this.BBSSServiceInfoArray.push(this.BBSSServiceInfo);
          }
        }
        concatenated.forEach((e) => {
          //BBSS の商品の場合
          if (e.contents.contentProviderId === CONTENT_PROVIDER_ID.BBSS) {
            const BBSSserviceInfo = this.BBSSServiceInfoArray.find((value) => {
              return value.contractId === e.contractId;
            });
            const displayContracts = new DisplayContract({
              contentProviderId: e.contents.contentProviderId,
              productsId: e.productsId,
              productName: e.products.productsName,
              description: e.products.description,
              downloadUrl: BBSSserviceInfo ? BBSSserviceInfo.downloadUrl : undefined,
              ticket: BBSSserviceInfo ? BBSSserviceInfo.ticket : undefined,
            });
            this.displayContractList.push(displayContracts);
            //BBSS と U-Nextの商品以外の場合
          } else if (e.contents.contentProviderId !== CONTENT_PROVIDER_ID.UNEXT && e.contents.contentProviderId !== CONTENT_PROVIDER_ID.BBSS) {
            const displayContracts = new DisplayContract({
              contentProviderId: e.contents.contentProviderId,
              productsId: e.productsId,
              productName: e.products.productsName,
              description: e.products.description,
            });
            this.displayContractList.push(displayContracts);
          }
        });
      }
      this.isLoading = false;
    } catch (error) {
      throw error;
    }
  },
  methods: {
    /** 「退会」ボタン押下時 : 退会手続き画面に遷移する */
    async onWithdrawal() {
      // e-mansion, five-a または UCOM の退会要件を満たさないユーザーの場合「退会不可の案内」画面に遷移する
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      const linkedServices: string[] = [];
      if (!this.canCancelFlagUcom) linkedServices.push(SERVICE_NAME.UCOM);
      if (!this.canCancelFlagEmansion) linkedServices.push(SERVICE_NAME.E_MANSION);
      if (!this.canCancelFlagFiveA) linkedServices.push(SERVICE_NAME.FIVE_A);
      if (!this.canCancelFlagGameWith) linkedServices.push(SERVICE_NAME.GAME_WITH);
      if (linkedServices.length) {
        // 退会要件を満たさないサービスをStoreに保存
        this.$store.commit('platformCancelStore/linkedServices', linkedServices);
        // 退会不可の案内画面に遷移
        await this.$router
          .push('/platform/cancel/impossible')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
        return;
      }

      // 請求結果記録バッチの実行状況を確認する
      const unpaidBillingProductNameList = await UnpaidBillingService.getUnpaidBillingProductName(this.member!.id);

      if (unpaidBillingProductNameList.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/cancel/impossible').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      if (this.subscriptionList) {
        // U-NEXT月額プランの契約について、退会要件を満たさないものがあるか確認
        if (!(await AccountUtilService.canCancelUNext(this.subscriptionList))) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform/cancel/impossible').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
      }

      // ここまでのチェックで退会条件がOKなので、退会手続き案内画面へ遷移
      await this.$router
        .push('/platform/cancel/guide')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    /**
     * Connectix契約状態取得処理
     * 1. ログイン中のPortasユーザーが連携しているISPを判定
     * 2. 各ISP 契約基本情報取得 API実行処理
     */
    checkContractedConnectix(): boolean {
      if (!this.property?.uaType) {
        return false;
      }
      // UA種別 の値が 1(e-mansion) のとき
      if (this.property?.uaType === UA_TYPE.E_MANSION && this.member?.primaryKeyMye) {
        // E_MANSION 契約基本情報取得 API
        const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
        if (eMansionCustomer instanceof EMansionCustomer) {
          // Connectix契約済みかどうか
          if (eMansionCustomer.op_connectix_contract_status === '1') {
            return true;
          }
        } else {
          // TODO: Connectix を契約しているが、何かしらの理由で 契約基本情報取得API がエラーになったときの制御について確認中
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
        }
        return false;
      }
      // UA種別 の値が 2(Five.A) のとき
      if (this.property?.uaType === UA_TYPE.FIVE_A && this.member?.primaryKeyMye) {
        // FiveA の契約基本情報をStoreから取得（e-mansion 契約基本情報取得API を使用）
        const fiveACustomer: FiveACustomer | null = this.$store.getters['fiveACommonStore/customer'];
        if (fiveACustomer instanceof FiveACustomer) {
          // Connectix契約済みかどうか
          if (fiveACustomer.op_connectix_contract_status === '1') {
            return true;
          }
        } else {
          // TODO: Connectix を契約しているが、何かしらの理由で 契約基本情報取得API がエラーになったときの制御について確認中
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
        }
        return false;
      }
      // UA種別 の値が 3(UCOM) のとき
      if (this.property?.uaType === UA_TYPE.UCOM && this.member?.primaryKeyUcom) {
        // UCOM 契約基本情報取得 API
        const ucomCustomer: UcomCustomerResponse | null = this.$store.getters['ucomCommonStore/customer'];
        if (ucomCustomer instanceof UcomCustomerResponse) {
          // Connectix契約済みかどうか
          if (ucomCustomer.connectix_status) {
            return true;
          }
        } else {
          // TODO: Connectix を契約しているが、何かしらの理由で 契約基本情報取得API がエラーになったときの制御について確認中
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
        }
        return false;
      }
      // 通常はあり得ないケース
      return false;
    },
    /**
     * 解約処理が行われていない契約のリストを取得する
     * @return 解約処理が行われていない契約のリスト
     */
    getContractingProducts(): SubscriptionDetailsDto[] {
      const contractingProducts: SubscriptionDetailsDto[] = [];
      if (this.subscriptionList && this.subscriptionList.contracts.length !== 0) {
        this.subscriptionList.contracts.forEach((e) => {
          if (e.cancelDate === null || e.cancelDate === '') {
            contractingProducts.push(e);
          }
        });
      }
      return contractingProducts;
    },
    /**
     * 解約処理中の契約のリストを取得する
     * @param serverDateTimeNow サーバー現在時刻
     * @return 解約処理中の契約のリスト
     */
    getCancellingProducts(serverDateTimeNow: Date): SubscriptionDetailsDto[] {
      const cancellingProducts: SubscriptionDetailsDto[] = [];
      if (this.subscriptionList && this.subscriptionList.contracts.length !== 0) {
        this.subscriptionList.contracts.forEach((e) => {
          if (e.cancelApplicationDate && e.cancelDate && dayjs(serverDateTimeNow) < dayjs(e.cancelDate).add(1, 'day')) {
            cancellingProducts.push(e);
          }
        });
      }
      return cancellingProducts;
    },
    /**
     * 以下を切り替える
     * - チェックボックスの表示
     * - ボタンの表示
     */
    onChangeDisplay() {
      this.selectingCancelProductsButton = !this.selectingCancelProductsButton;
    },
    /**
     * 日付を "YYYY年MM月DD日" 形式にフォーマットする
     * @param date - フォーマットする前の日付文字列
     * @return フォーマットされた日付文字列
     */
    formatDate(date: string | Date | undefined): string {
      if (date == null) {
        return '';
      }
      return utilFormatDate(date, 'YYYY年MM月DD日');
    },
    /**
     * 無料期間の終了日を取得する
     * @param billingStartDate - 課金開始日: 課金開始される月の1日の日付が入る
     * @return 無料期間の終了日 - 課金開始日の前日
     *
     * 例:
     * 課金開始日: 2022/01/01
     * 無料期間の終了日: 2021年12月31日
     */
    getFreePeriodEndDate(billingStartDate: string | Date | undefined): string {
      if (billingStartDate == null) {
        return '';
      }

      const freePeriodEndDate = dayjs(billingStartDate).subtract(1, 'day').toDate();
      return this.formatDate(freePeriodEndDate);
    },
    /**
     * 無料期間有無のチェックをする
     * 課金開始日 <= 無料期間の終了日 の場合、無料期間の表示有り
     *
     * @return true 無料期間あり
     * @return false 無料期間なし
     */
    checkExistFreePeriod(contractStartDate: string | Date | undefined, billingStartDate: string | Date | undefined): boolean {
      if (contractStartDate == null || billingStartDate == null) {
        // 通常はあり得ないパターン
        return false;
      }

      const freePeriodEndDate = dayjs(billingStartDate).subtract(1, 'day').toDate();

      return dayjs(contractStartDate).toDate() <= freePeriodEndDate;
    },

    /**
     * ご利用状況確認 ボタン の処理
     * Connectixのみが解約対象として選択されている場合 の処理
     *
     * 連携先ISPのConnectix解約画面に遷移
     *  */
    onNextConnectixCancelPage() {
      let url = '';
      switch (this.property?.uaType) {
        case UA_TYPE.E_MANSION:
          url = this.createUrl(process.env.VUE_APP_E_MANSION_CONNECTIX_CANCEL_URL, this.property.apartmentId);
          break;
        case UA_TYPE.FIVE_A:
          url = this.createUrl(process.env.VUE_APP_FIVE_A_CONNECTIX_CANCEL_URL, this.property.apartmentId);
          break;
        case UA_TYPE.UCOM:
          url = process.env.VUE_APP_UCOM_CONNECTIX_CANCEL_URL;
          break;
      }
      if (url) {
        window.open(url, '_blank');
      }
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /**
     * Connectix 解約 URL生成関数
     *
     * @param baseUrl
     * @param apartmentId
     * @return Connectix 解約 URL(env.baseUrl + '&apid=' + apid + '&rd=' + rd)
     */
    createUrl(baseUrl: string, apartmentId: string | undefined): string {
      let apid = '';
      const rd = this.createRd();
      if (apartmentId) {
        apid = convert6DigitIdTo8DigitId(apartmentId);
      }
      if (apid == null || apid === '' || rd == null || rd === '') {
        throw new Error();
      }
      return baseUrl + '&apid=' + apid + '&rd=' + rd;
    },
    /**
     * 「選択した商品を解約する」ボタン押下
     */
    async onCancelSelectedProducts() {
      // 「解約対象契約」ストアへ、解約対象として選択されたサブスク商品全ての契約情報を格納する。
      this.$store.commit('contractsSubjectToCancelStore/products', this.selectedProducts);

      // 解約対象として選択された商品がサブスク商品とConnectixの両方を含む場合、「解約対象Connectix有無」ストアへtrueを格納する。
      // Connectixが含まれているか確認
      const hasConnectix = this.selectedProducts.some((product) => {
        if (typeof product === 'string') {
          return product === 'Connectix';
        }
        return false;
      });

      if (this.selectedProducts.length === 1 && hasConnectix) {
        // 解約対象として選択された商品がConnectixのみの場合
        this.onNextConnectixCancelPage();
        return;
      } else {
        // 解約対象として選択された商品がサブスク商品とConnectixの両方を含む場合
        this.$store.commit('hasConnectixSubjectToCancelStore/hasConnectix', hasConnectix);
      }

      // 「サービス解約お申し込み」画面へ遷移する。
      await this.$router
        .push('/platform/my-page/products/cancel-confirm')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
      return;
    },
    /**
     * htmlのサニタイズ(無害化)処理
     * sanitizeHtml関数に引数としてHTMLコードを渡し、不正なスクリプトを取り除いた結果を返すmethod
     * @param content
     * @see https://qiita.com/_linkin/items/e62f80b366fa877d61f8
     */
    sanitizeContent(content: string): string {
      if (content == null) {
        return '';
      }
      const sanitizedContent = sanitizeHtml(content, {
        allowedTags: sanitizeHtml.defaults.allowedTags,
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          '*': ['class'], // 全てのタグでclass属性を許可
        },
      });
      return sanitizedContent;
    },
    /**
     * U-NEXTの会員情報を取得する
     */
    async getUNextAccountInfo(memberId: number): Promise<UNextAccountInfo> {
      return await SpfApiService.getUNextAccountInfo(memberId);
    },
    /**
     * BBSSのサービス情報を取得する
     */
    async getBBSSServiceInfo(contractId: number): Promise<BBSSServiceInfo> {
      return await SpfApiService.getBBSSServiceInfo(contractId);
    },
    /**
     * template 内でも使用したいため ProductIds を取得する
     */
    getProductIds() {
      return ProductIds;
    },
  },
  computed: {
    /**
     * 契約中または解約処理中の商品の商品名を「+」で連結して返す
     * Connectix契約有りの場合、後ろに「Connectix」を連結する
     */
    concatenatedProductNames(): string {
      const contractingNames = this.contractingProductList?.map((item) => item.products.productsName) ?? [];
      const cancellingNames = this.cancellingProductList?.map((item) => item.products.productsName) ?? [];

      const productNames = [...contractingNames, ...cancellingNames];

      if (this.isContractedConnectix) {
        productNames.push('Connectix');
      }

      return productNames.join(' + ');
    },
    /**
     * ご契約中商品数カウント
     * 契約中または解約処理中の商品の合計を返す。
     * Connectix契約 をしていたら +1
     */
    totalContracts(): number {
      let count = 0;
      if (this.contractingProductList) {
        count += this.contractingProductList.length;
      }
      if (this.cancellingProductList) {
        count += this.cancellingProductList.length;
      }
      if (this.isContractedConnectix) {
        count += 1;
      }
      return count;
    },
    isButtonDisabled(): boolean {
      return this.selectedProducts.length === 0;
    },
    buttonColorSet() {
      if (this.isButtonDisabled) {
        return 'btn btn04 bs';
      }
      return 'btn btn01 bs';
    },
  },
});
</script>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.toBold {
  font-weight: bold;
}

.connectix-btn-css {
  background-color: white;
  border: 1px solid red;
  color: black;
}

.margin-left {
  margin-left: 1em;
}

.table-adjustment-css {
  border-collapse: separate;
  border: none;
  font-weight: normal;
}

.table-type2 th {
  font-weight: normal;
}

button.btn04:disabled {
  opacity: 0.5;
}

.toPointer,
.toPointer input {
  cursor: pointer;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}

.word-break-break-all {
  word-break: break-all;
}
</style>
