export class FrontErrorRequest {
  private primaryKeyAuth0?: string;
  private errorObject?: Error;
  private errorInfo?: string;
  private errorScreenName?: string;
  private errorScreenPath?: string;
  private errorType!: string;
  private screenHistory?: string[];
  private userAgent?: string;
  private apiRequestInfo?: Object;
  private apiResponseInfo?: Object;

  constructor(partial: Partial<FrontErrorRequest>) {
    Object.assign(this, partial);
  }

}