export const MYE_REGISTER_STATUS = {
    IN_CONTRACT_APPLICATION: {id: 1, value: '登録申込中'},
    CONTRACTED: {id: 2, value: '登録済み'},
    IN_WITHDRAWAL_APPLICATION: {id: 3, value: '解約申請中'},
    CANCELLED: {id: 4, value: '解約済み'},
} as const;

export type MYE_REGISTER_STATUS = typeof MYE_REGISTER_STATUS[keyof typeof MYE_REGISTER_STATUS];

export const convertMyeRegisterStatusById = (id: number): MYE_REGISTER_STATUS | undefined => {

    for (const v of Object.values(MYE_REGISTER_STATUS)) {
        if (Number(v.id) == id) {
            return v;
        }
    }
};