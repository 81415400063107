// e-mansionとucomについて、Auth0アカウント連携時の次のエラー
// ISP(e-mansionとucom)のAuth0ユーザの物件ID と PortasのAuth0ユーザの物件IDが異なる
export const AUTH0_PROPERTY_ID_NO_MATCH = {
  errorMessage: `This User's Property Id Does Not Match Portas Auth0 User Property Id`,
  displayTitle: {
    E_MANSION: '<b>e-mansionに会員登録いただいたお住まいの建物と、Portasで登録いただいた情報が異なるためアカウント連携ができません。</b>',
    UCOM: '<b>UCOM光 レジデンスに会員登録いただいたお住まいの建物と、Portasで登録いただいた情報が異なるためお客様番号連携ができません。</b>'
  },
  displayMessage: {
    E_MANSION: [
      `<span class="red"><a href="/platform/my-page/member-edit" class="link">PortasマイページのPortas会員情報変更</a>から、Portasに登録されているお住まいの建物をご確認ください。</span>`,
      '<span class="red">Portasに登録されているお住まいの建物が誤っている場合、お手数をおかけしますが一度Portasの退会をお申し込みいただき、あらためてPortasの新規会員登録を行なってください。<br><a href="/platform/cancel/guide" class="link">Portas会員からの退会お申し込み</a></span>',
      '<span class="red">Portasに登録されているお住まいの建物が正しい場合、e-mansion会員登録時の建物に誤りがある可能性があります。<br><a href="REPLACE_URL" class="link" target="_blank">e-mansionサイトからお問い合わせください。</a></span>'
    ],
    UCOM: [
      `<span class="red"><a href="/platform/my-page/member-edit" class="link">PortasマイページのPortas会員情報変更</a>から、Portasに登録されているお住まいの建物をご確認ください。</span>`,
      '<span class="red">Portasに登録されているお住まいの建物が誤っている場合、お手数をおかけしますが一度Portasの退会をお申し込みいただき、あらためてPortasの新規会員登録を行なってください。<br><a href="/platform/cancel/guide" class="link">Portas会員からの退会お申し込み</a></span>',
      '<span class="red">Portasに登録されているお住まいの建物が正しい場合、UCOM光 レジデンス会員登録時の建物に誤りがある可能性があります。<br><a href="REPLACE_URL" class="link" target="_blank">UCOM光 レジデンス 入居者向けサービスサイトからお問い合わせください。</a></span>'
    ]
  }
} as const;
