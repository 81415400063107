import {MYE_REGISTER_STATUS} from "@/shared/const/e-mansion/mye-register-status";
import {XMAIL_PASSWORD_REGISTER_STATUS} from "@/shared/const/e-mansion/e-mansion-xmail-password-register-status";
import {XMAIL_REGISTER_STATUS} from "@/shared/const/e-mansion/e-mansion-xmail-register-status";

export class EMansionMailAddress {
    readonly _eMansionMailAddressId!: number;
    readonly _eMansionAccountId!: number;
    readonly _xMailUserId!: string | undefined;
    readonly _xMailAuthId!: string | undefined;
    readonly _myeRegisterStatus!: MYE_REGISTER_STATUS;
    readonly _xMailRegisterStatus!: XMAIL_REGISTER_STATUS;
    readonly _xMailPasswordRegisterStatus!: XMAIL_PASSWORD_REGISTER_STATUS;
    readonly _mailLocalPart!: string;
    readonly _mailSubdomain!:string;
    readonly _mailAddress!: string;
    readonly _initialMailPassword!: string;
    readonly _mailPassword!: string;
    readonly _mailAddressOriginal!: string | undefined;
    readonly _popServer!: string | undefined;
    readonly _smtpServer!: string | undefined;
    readonly _registerSubscriptionOn!: Date | undefined;
    readonly _registerOn!: Date | undefined;
    readonly _registerProcessOn!: Date | undefined;
    readonly _cancelSubscriptionOn!: Date | undefined;
    readonly _cancelOn!: Date | undefined;
    readonly _cancelProcessOn!: Date | undefined;
    readonly _deleteOn!: Date | undefined;
    readonly _isDelete!: boolean;

    constructor(partial: Partial<EMansionMailAddress>) {
        Object.assign(this, partial);
    }

    get eMansionMailAddressId(): number {
        return this._eMansionMailAddressId;
    }

    get eMansionAccountId(): number {
        return this._eMansionAccountId;
    }

    get xMailUserId(): string | undefined {
        return this._xMailUserId;
    }

    get xMailAuthId(): string | undefined {
        return this._xMailAuthId;
    }

    get myeRegisterStatus(): MYE_REGISTER_STATUS {
        return this._myeRegisterStatus;
    }

    get xMailRegisterStatus(): XMAIL_REGISTER_STATUS {
        return this._xMailRegisterStatus;
    }

    get xMailPasswordRegisterStatus(): XMAIL_PASSWORD_REGISTER_STATUS {
        return this._xMailPasswordRegisterStatus;
    }

    get mailLocalPart(): string {
        return this._mailLocalPart;
    }

    get mailSubdomain():string {
        return this._mailSubdomain;
    }

    get mailAddress(): string {
        return this._mailAddress;
    }

    get initialMailPassword(): string {
        return this._initialMailPassword;
    }

    get mailPassword(): string {
        return this._mailPassword;
    }

    get mailAddressOriginal(): string | undefined {
        return this._mailAddressOriginal;
    }

    get popServer(): string | undefined {
        return this._popServer;
    }

    get smtpServer(): string | undefined {
        return this._smtpServer;
    }

    get registerSubscriptionOn(): Date | undefined {
        return this._registerSubscriptionOn;
    }

    get registerOn(): Date | undefined {
        return this._registerOn;
    }

    get registerProcessOn(): Date | undefined {
        return this._registerProcessOn;
    }

    get cancelSubscriptionOn(): Date | undefined {
        return this._cancelSubscriptionOn;
    }

    get cancelOn(): Date | undefined {
        return this._cancelOn;
    }

    get cancelProcessOn(): Date | undefined {
        return this._cancelProcessOn;
    }

    get deleteOn(): Date | undefined {
        return this._deleteOn;
    }

    get isDelete(): boolean {
        return this._isDelete;
    }

}