<template>
  <div class="during-registration-process">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>宅内工事確認結果</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A 部屋番号入力</li>
        <li>Five.A 宅内工事確認結果</li>
      </ul>
      <div class="blc">
        <p>
          ご入力いただいたお部屋番号は宅内工事が完了している、もしくはPortasからの宅内工事申込の対象ではございません。<br />
          <br />
          <br />
          以下のいずれかの条件に該当する場合、オンラインでの工事申込を受け付けることが出来ません。<br />
          Portasから宅内工事申込の対象とならないケース<br />
        </p>
        <ul>
          <li>建物全体の工事完了後の一定期間</li>
          <li>ご入力いただいたお部屋番号と弊社の登録内容が一致しない場合</li>
          <li>その他、ご入力いただいたお部屋番号の状況確認が出来ない場合</li>
        </ul>
        <div>
          <p>
            インターネット接続が出来ない場合は、お手数ではございますが<a class="link" :href="`${domainName}/${apid}/ft5/`" target="_blank">こちら</a
            >からお問い合わせ先を確認いただき、サポートセンターまでお問い合わせください。
          </p>
        </div>
        <div class="btn-area">
          <div class="my-button">
            <button class="btn btn01 bs" @click="onTopPage()">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.application-flow {
  padding: 0;
}

.p-bold {
  font-weight: bold;
  margin-bottom: 110px;
}

@media (max-width: 720px) {
  .p-bold {
    margin-bottom: 80px;
  }
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { checkRouterError } from '@/shared/util/router-navigation-func';
export default defineComponent({
  name: 'fiveA-external-result',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
    apid: null as string | null, // apid
    fiveAUrl: process.env.VUE_APP_FIVE_A_SERVICE_DOMAIN_NAME, // fiveA URL
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '', // 「こちら」リンク用
  }),
  async mounted(): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    // apid を取得する
    this.apid = this.$store.getters['remainingWorkStore/apid'];
    try {
      const property: Property = this.$store.getters['propertyStore/property'];
      //ドメイン名の取得 */
      if (property.oemType) {
        this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(property.oemType));
        if (filtered.length >= 1) {
          let latestOem = filtered[0];
          if (filtered.length >= 2) {
            for (let i = 1; i < filtered.length; i++) {
              if (latestOem.startAt < filtered[i].startAt) {
                latestOem = filtered[i];
              }
            }
          }
          this.domainName = latestOem.domainForOsumai;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }
    } catch (error: any) {
      // 共通エラーに遷移
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    this.isLoading = false;
  },
  methods: {
    async onTopPage() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
