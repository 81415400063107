/**
 * UCOM API : 電話番号取得方法取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomNumberGetterResponseResult {
  /** 結果値 */
  public result!: UcomNumberGetterResponse;

  constructor(partial: Partial<UcomNumberGetterResponseResult>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM API : 電話番号取得方法取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomNumberGetterResponse {
  /** UCOM光電話 電話番号取得方法 */
  public optical_phone_number_get_methods!: Array<UcomOpticalPhoneNumberGetMethod>;

  constructor(partial: Partial<UcomNumberGetterResponse>) {
    Object.assign(this, partial);
  }
}

/** UCOM API : 電話番号取得方法取得 : レスポンス UCOM光電話 電話番号取得方法 */
export class UcomOpticalPhoneNumberGetMethod {
  /** 光電話取得方法ID */
  public id!: string;
  /** 光電話取得方法名 */
  public value!: string;

  constructor(partial: Partial<UcomOpticalPhoneNumberGetMethod>) {
    Object.assign(this, partial);
  }
}
