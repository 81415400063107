/** エラー区分定数_全体補足 */
export const FRONT_ERROR_TYPE_GLOBAL_CAPTURE = {
  VUE: '10',
  UNEXPECTED: '11',
  UNHANDLEDREJECTION: '12',
} as const;

/** エラー区分定数_想定内 */
export const FRONT_ERROR_TYPE_EXPECTED = {
  DATA_INCONSISTENCY: '00',
  API: '01',
} as const;