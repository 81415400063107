<template>
  <div>
    <LoadingComponent v-if="isMounting || isTransitionProcessing" />
    <main class="underlayer-main">
      <h1>ウイルスチェック</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><a href="/platform">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>ウイルスチェック</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック一時停止開始" />ウイルスチェック一時停止／開始完了</h2>
        <p>お申し込みいただきました内容につきまして手続きが完了しました。</p>
      </div>

      <div class="sblc">
        <h3 class="service-h3">お申し込み内容</h3>

        <table class="table-type2" v-if="ucomVirusCheckPauseState">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ ucomVirusCheckPauseState.accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ ucomVirusCheckPauseState.mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">ウイルスチェック設定</th>
              <td>{{ myNumberToDisplayPauseAction(ucomVirusCheckPauseState.virusCheckPauseAction) }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ ucomVirusCheckPauseState.subscriptionDate }}</td>
            </tr>
            <tr>
              <th>注意事項</th>
              <td>
                <ul class="attention-list">
                  <li>{{ ucomVirusCheckPauseState.terms }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="transitionTo()">UCOM光 レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomVirusCheckPauseState } from '@/shared/classes/ucom/ucom-virus-check-pause-state';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { convertUcomVirusCheckPauseActionById } from '@/shared/const/ucom/ucom-virus-check-pause-action';

export default defineComponent({
  name: 'virus-check-pause-completed',
  components: {
    LoadingComponent,
  },
  data(): {
    ucomVirusCheckPauseState: UcomVirusCheckPauseState | undefined;
    isTransitionProcessing: boolean;
    isMounting: boolean;
  } {
    return {
      // UCOM ウイルスチェック一時停止/開始 ストアから取得した値を入れる
      ucomVirusCheckPauseState: undefined,
      // ページ遷移処理実行中かどうか
      isTransitionProcessing: false,
      // mounted() 実行中かどうか
      isMounting: true,
    };
  },
  mounted() {
    this.ucomVirusCheckPauseState = this.$store.getters['ucomVirusCheckStore/pauseState'];
    this.isMounting = false;
  },
  methods: {
    /**
     * ucom会員専用トップへページ遷移する処理
     */
    async transitionTo() {
      if (this.isTransitionProcessing) {
        return;
      }

      this.isTransitionProcessing = true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    },
    // ウイルスチェック設定表示用
    myNumberToDisplayPauseAction(number: number): string | undefined {
      return convertUcomVirusCheckPauseActionById(number) ?? '-';
    },
  },
});
</script>

<style lang="scss" scoped>
.attention-list {
  margin: 0;
  padding: 0;
  padding-left: 16px;
}
</style>
