<template>
  <!-- Mcloudを連携している場合 -->
  <div class="blc" v-if="!isDisplayNotLinkedMcloud">
    <section>
      <img src="../../images/mcloud/logo.png" alt="Mcloud" class="mcloud_img" />
      <a :href="`${mcloudUrl}`" class="link to-mcloud" target="_blank">Mcloudへ
        <i class="material-icons link-icon">arrow_right_alt</i></a>
    </section>
  </div>
  <div class="blc" v-else-if="isDisplayNotLinkedMcloud">
      <div>
        <img src="../../images/mcloud/logo.png" alt="Mcloud" class="mcloud_img" />
      </div>
      <h2 class="portal-h2 cf">
        <img src="../../images/service-h2.svg"/>
        本登録済みの方は
        <router-link to="/mcloud/linking" class="link">こちら</router-link>
      </h2>
      <p>Mcloudをご提供していない物件にも表示されております。</p>
  </div>
</template>

<style lang="scss" scoped>
.mcloud_img {
  width: 170px;
}
.portal-h2 a {
  font-size: 24px;
  font-weight: bold;
  margin-left: 0px;
}
.to-mcloud{
  font-size: 24px;
  font-weight: bold;
  margin-left: 3%;
}

@media only screen and (max-width: 767px) {
.portal-h2{
  display: flex;
}
.portal-h2 a {
  font-size: 18px;
}
.to-mcloud{
  font-size: 18px;
}
}

</style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props:{
    // Mcloud未連携時にリンクを表示するかどうか
    isDisplayNotLinkedMcloud:{
      type:Boolean
    }
  },
  data: () => ({
    /** MCLOUD の URL */
    mcloudUrl: process.env.VUE_APP_MCLOUD_URL,
  }),
});
</script>