<template>
  <div v-if="isEnabled" class="permission-link">
    <ul>
      <li>
        <router-link v-if="!disabled" :to="to">{{ title }}</router-link>
        <span class="disabled" v-if="disabled">{{ title }}</span>
      </li>
    </ul>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script lang="ts">
import { PermissionControlService } from '@/shared/services/permission/permission-control-service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'permission-controled-router-link',
  props: {
    title: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    functionId: {
      type: String,
      default: undefined,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEnabled: false,
  }),
  async mounted(): Promise<void> {
    // メニューリンクは"R"以上のパーミッションがあれば利用可
    this.$data.isEnabled = await PermissionControlService.isReadable(this.functionId ? this.functionId : '');
  },
});
</script>

<style lang="scss" scoped>
h2 {
  font-size: 22px !important;
}

.disabled {
  color: #bdbdbd;
  text-decoration: underline;
}

.description {
  margin-left: 1em;
}

/* permission-link */
.permission-link {
  margin: 0;
}

.permission-link ul {
  padding: 0;
  list-style-type: none;
}

.permission-link ul li {
  padding: 0 0 0 10px;
  text-decoration: underline;
  position: relative;
}

.permission-link ul li a {
  color: #cf1225;
  text-decoration: underline;
}

.permission-link ul li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  box-sizing: border-box;
  width: 4px;
  height: 4px;
  border: 3px solid transparent;
  border-left: 5px solid #cf1225;
}
</style>
