import { Module } from 'vuex';
import { RootState } from './index';
import { SpfApiService } from '../shared/services/api/spf-api-service';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';

/** OEMマスタ情報 State */
const oemsState: {
  oems: GetOemsMasterResponse | null,
  foundTime: Date | null
} = {
  oems: null,
  foundTime: null
};

/** State の型 */
type OemsState = typeof oemsState;

/** OEMマスタ情報 Store */
export const oemsStore: Module<OemsState, RootState> = {
  namespaced: true,
  state: oemsState,
  mutations: {
    oems: (state, value: GetOemsMasterResponse) => (state.oems = value),
    foundTime: (state, value: Date) => (state.foundTime = value)
  },
  getters: {
    oems: (state): GetOemsMasterResponse | null => state.oems,
    foundTime: (state): Date | null => state.foundTime
  },
  actions: {
    async oems(context) {
      const lastTime = context.getters['foundTime'];
      if(lastTime) {
        // 経過時間をミリ秒単位で取得
        const elapsedMSec = new Date().getTime() - lastTime.getTime();
        // 時間単位に変換
        const elapsedTime = Math.floor(elapsedMSec/3600000);
        if(elapsedTime >= 24) {
          // API コールする
          const oemsResult =  await SpfApiService.getOemsMaster();
          // 値をコミットする
          context.commit('oems', oemsResult);
          // 最終取得時刻をコミットする
          context.commit('foundTime', new Date());
        }
        // 最終取得から24時間以内はgetterで取得して返す
      } else {
        // API コールする
        const oemsResult =  await SpfApiService.getOemsMaster();
        // 値をコミットする
        context.commit('oems', oemsResult);
        // 最終取得時刻をコミットする
        context.commit('foundTime', new Date());
      }
      // Getter から取り直して返す
      const afterOems = context.getters['oems'];
      return afterOems;
    }
  }
};
