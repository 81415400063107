import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PortalServiceEMansion = _resolveComponent("PortalServiceEMansion")!
  const _component_PortalServiceUcom = _resolveComponent("PortalServiceUcom")!
  const _component_PortalServiceFiveA = _resolveComponent("PortalServiceFiveA")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.property)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.property.uaType === _ctx.propertyUaType.E_MANSION)
            ? (_openBlock(), _createBlock(_component_PortalServiceEMansion, {
                key: 0,
                onUpdateLeftMenuLogoFromPortalServiceEMansion: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendUpdateLeftMenuLogoAtPortalServiceController()))
              }))
            : (_ctx.property.uaType === _ctx.propertyUaType.UCOM)
              ? (_openBlock(), _createBlock(_component_PortalServiceUcom, {
                  key: 1,
                  onUpdateLeftMenuLogoFromPortalServiceUcom: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendUpdateLeftMenuLogoAtPortalServiceController()))
                }))
              : (_ctx.property.uaType === _ctx.propertyUaType.FIVE_A)
                ? (_openBlock(), _createBlock(_component_PortalServiceFiveA, {
                    key: 2,
                    onUpdateLeftMenuLogoFromPortalServiceFiveA: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendUpdateLeftMenuLogoAtPortalServiceController()))
                  }))
                : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}