<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェック</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/platform">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>ウイルスチェック</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェック購入／解約確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック購入解約完了" />ウイルスチェック購入／解約完了</h2>
        <p>お申し込みいただきました内容につきまして手続きが完了しました。</p>
        <p v-if="notifyMailAddress">{{ notifyMailAddress }} 宛に、「申込み完了メール」を送信しましたのでご確認ください。</p>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th class="va-middle">お申し込み内容</th>
              <td>{{ subscriptionTypeText }}</td>
            </tr>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>
                <span v-if="purchaseState">{{ purchaseState.accountName }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>
                <span v-if="purchaseState">{{ purchaseState.mailAddress }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o" v-if="purchaseState">月額 {{ myNumberWithDelimiter(purchaseState.monthlyFee) }} 円 （税込）</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>
                <span class="o" id="current_date" v-if="purchaseState">{{ purchaseState.subscriptionDate }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <span v-if="purchaseState" v-html="purchaseState.serviceDescriptionAfterPurchase"></span>
              </td>
            </tr>
          </table>
        </div>

        <div class="blc">
          <div class="btn-area">
            <button class="btn btn01 bs" @click="goToTop">UCOM光 レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import { convertUcomVirusCheckPurchaseSubscriptionTypeById } from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'virus-check-purchase-complete',
  components: {
    LoadingComponent,
  },
  data(): {
    purchaseState?: UcomVirusCheckPurchaseState;
    notifyMailAddress?: string;
    isLoading: boolean;
  } {
    return {
      purchaseState: undefined,
      notifyMailAddress: undefined,
      isLoading: true,
    };
  },
  mounted() {
    // 前画面からの情報を取得する
    this.purchaseState = this.$store.getters['ucomVirusCheckStore/purchaseState'];
    this.notifyMailAddress = this.makeMailAddressForView(this.$store.getters['ucomVirusCheckStore/notifyMailAddress']);
    this.isLoading = false;
  },
  methods: {
    /** 「UCOM光 レジデンス会員専用トップ」のクリックハンドラ */
    async goToTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    makeMailAddressForView(mailAddress: string[]): string {
      if (!mailAddress[0]) {
        return mailAddress[1];
      }
      if (mailAddress[0] === '') {
        return mailAddress[1];
      }
      return `${mailAddress[0]}, ${mailAddress[1]}`;
    },
    myNumberWithDelimiter(value: number) {
      return numberWithDelimiter(value);
    },
  }, // methods
  computed: {
    /** お申し込み内容 に表示するテキスト */
    subscriptionTypeText(): string {
      if (this.purchaseState?.subscriptionType === undefined) {
        return '';
      }
      return convertUcomVirusCheckPurchaseSubscriptionTypeById(this.purchaseState.subscriptionType) ?? '';
    },
  },
});
</script>
