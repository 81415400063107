/**
 * 入力イベントを受け取り、値を大文字に変換して返す
 * e.g. @param tanaka takeshi
 * e.g. @return TANAKA TAKESHI
 */
export const inputEventConvertToUpper = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.toUpperCase();
  return input.value;
};
