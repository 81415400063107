import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e172ee6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = {
  key: 1,
  class: "disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isEnabled)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        (!_ctx.disabled)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.url,
              target: _ctx.tabTarget,
              class: "link"
            }, _toDisplayString(_ctx.title), 9, _hoisted_2))
          : (_ctx.disabled)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}