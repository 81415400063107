import {Module} from 'vuex';
import {RootState} from '../index';
import {EMansionAccountInfo} from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import {SpfApiAccountAccessor} from "@/infra/accessor/spf/e-mansion/spf-api-account-accessor";

/** e-mansion アカウント連携 State */
const eMansionAccountState: {
    eMansionAccountInfo: EMansionAccountInfo | null;
} = {
    /** e-mansion Account 情報*/
    eMansionAccountInfo: null,
};

/** State の型 */
type EMansionAccountState = typeof eMansionAccountState;

/** e-mansion Account共通系 Store */
export const eMansionAccountStore: Module<EMansionAccountState, RootState> = {
    namespaced: true,
    state: eMansionAccountState,

    mutations: {
        eMansionAccountInfo: (state, value: EMansionAccountInfo) => (state.eMansionAccountInfo = value),
    },
    getters: {
        eMansionAccountInfo: (state): EMansionAccountInfo | null => state.eMansionAccountInfo,
    },
    actions: {
        async eMansionAccountInfo(context, loginId: string) {
            // Api Call & commit
            context.commit('eMansionAccountInfo', await SpfApiAccountAccessor.getEMansionAccount(loginId));
            return context.getters['eMansionAccountInfo'];
        },
    },
};
