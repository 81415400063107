<template>
  <div>
    <div>
      <ResidenceRegistrationButton :handle-radio-button-value="handleRadioButtonChange" />
    </div>

    <!-- あとで登録しない場合は表示 -->
    <div v-if="!isAfterRegister">
      <ResidenceRegistrationSearch
        :handle-has-display-property="setHasDisplayProperty"
        :handle-update-display-property="updateDisplayProperty"
        :handle-clear-selected-property="clearSelectedProperty"
        @memberEdit="updateRadios"
      />
    </div>

    <div v-if="hasDisplayProperty">
      <ResidenceRegistrationDisplay ref="residenceRegistrationDisplay" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ResidenceRegistrationButton from '@/shared/components/platform/residence-registration-button.vue';
import ResidenceRegistrationDisplay from '@/shared/components/platform/residence-registration-display.vue';
import ResidenceRegistrationSearch from '@/shared/components/platform/residence-registration-search.vue';
import { INPUT_PROPERTY_FORM_INITAL_STATE } from '@/shared/const/residence-registration';
import { InputPropertyForm } from '@/store/platform/platform-edit-store';

export default defineComponent({
  name: 'residence-registration-controller',
  components: {
    ResidenceRegistrationButton,
    ResidenceRegistrationSearch,
    ResidenceRegistrationDisplay,
  },
  props: {
    handleIsAfterRegister: {
      type: Function,
      required: true,
    },
  },
  data(): {
    isAfterRegister: boolean;
    updateRadio: boolean;
    hasDisplayProperty: boolean;
    inputPropertyFormInitalState: InputPropertyForm;
  } {
    return {
      isAfterRegister: true,
      updateRadio: true,
      hasDisplayProperty: false,
      inputPropertyFormInitalState: INPUT_PROPERTY_FORM_INITAL_STATE,
    };
  },
  methods: {
    /**
     * 「お住まいの建物を登録する」・「該当の建物にお住まいでない方」ボタンがクリックされたときの処理
     *
     * 該当の建物にお住まいでない方ボタンがクリックされたときは、ストアと表示内容を更新する
     */
    handleRadioButtonChange(value: string) {
      this.setIsAfterRegister(value);
      this.updateStoreOfIsAfterResiter(this.isAfterRegister);
      this.handleIsAfterRegister(this.isAfterRegister);

      if (this.isAfterRegister) {
        this.clearSelectedProperty();
        this.setHasDisplayProperty(false);
        this.$store.commit('platformEditStore/searchPropertyForm', null); // ストアに保存した検索条件をnullにする
      }
    },
    /**
     * isAfterRegisterの値のセット
     */
    setIsAfterRegister(value: string) {
      if (value === 'is-after-register') {
        this.isAfterRegister = true;
      } else if (value === 'register-now') {
        this.isAfterRegister = false;
      }
    },
    /**
     * platformEditStore の inputPropertyForm.isAfterRegister の値の更新
     */
    updateStoreOfIsAfterResiter(isAfterRegister: boolean) {
      let inputPropertyFormValue: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (!inputPropertyFormValue) {
        inputPropertyFormValue = this.inputPropertyFormInitalState;
      }
      inputPropertyFormValue.isAfterRegister = isAfterRegister;
      this.$store.commit('platformEditStore/inputPropertyForm', inputPropertyFormValue);
    },
    updateRadios(onRadio: boolean) {
      this.updateRadio = onRadio;
      this.$emit('clickUpdateRadios', this.updateRadio);
    },
    /**
     * platformEditStore の inputPropertyForm.selectedProperty を nullにする
     *
     * ストアに保存されている、'選択している物件情報' を null にする
     */
    clearSelectedProperty() {
      let inputPropertyFormValue: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (!inputPropertyFormValue) {
        inputPropertyFormValue = this.inputPropertyFormInitalState;
      }
      inputPropertyFormValue.selectedProperty = null;
      this.$store.commit('platformEditStore/inputPropertyForm', inputPropertyFormValue);
    },
    /**
     * ResidenceRegistrationDisplay を表示する・表示しないを管理する
     */
    setHasDisplayProperty(value: boolean) {
      this.hasDisplayProperty = value;
    },
    /**
     * ResidenceRegistrationDisplay に表示する物件を更新する
     */
    updateDisplayProperty() {
      // ResidenceRegistrationDisplay が undefined でなくなってから操作するようにするために nextTick
      this.$nextTick(() => {
        const residenceRegistrationDisplay = this.$refs.residenceRegistrationDisplay as InstanceType<typeof ResidenceRegistrationDisplay>;
        residenceRegistrationDisplay.update();
        this.$emit('memberEdits');
      });
    },
  },
  async mounted() { },
});
</script>
