/** ホームページ容量 */
export const E_MANSION_HOMEPAGE_CAPACITY = {
  50: { id: '01', value: '50'},
  100: { id: '02', value: '100'},
  150: { id: '03', value: '150'},
  200: { id: '04', value: '200'},
  250: { id: '05', value: '250'},
  300: { id: '06', value: '300'},
  350: { id: '07', value: '350'},
  400: { id: '08', value: '400'},
  450: { id: '09', value: '450'},
  500: { id: '10', value: '500'},
} as const;

export type E_MANSION_HOMEPAGE_CAPACITY = typeof E_MANSION_HOMEPAGE_CAPACITY[keyof typeof E_MANSION_HOMEPAGE_CAPACITY];

export const convertHpCapacityByValue = (value: string): E_MANSION_HOMEPAGE_CAPACITY | undefined => {
  for (const v of Object.values(E_MANSION_HOMEPAGE_CAPACITY)) {
    if (v.value == value) {
      return v;
    }
  }
}

export const hasHpCapacityMb = (value: string): boolean => {
  for (const v of Object.values(E_MANSION_HOMEPAGE_CAPACITY)) {
    if (v.id == value) {
      return true;
    }
  }
  return false;
}

