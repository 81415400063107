<template>
  <div class="payment-method-list">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portasサービス お支払い方法</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li>Portasサービス お支払い方法</li>
      </ul>

      <div class="blc">
        <h3 class="service-h3">クレジットカード</h3>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>

      <div class="wrapper" v-if="hasPaymentMethod">
        <div class="blc">
          <table class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumber }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonth }}月 ／ {{ cardExpiredYear }}年</td>
              </tr>
            </tbody>
          </table>

          <div class="sblc">
            <h4>クレジットカード情報の変更</h4>
            <p>クレジットカード情報は1つのみ登録することが可能です。</p>
            <p>現在、登録されているクレジットカード情報が上書きされます。</p>

            <div class="mt20">
              <button class="btn-height btn btn01 bs" type="button" v-on:click="onEdit()">お支払い方法の変更</button>
            </div>
          </div>

          <div class="sblc">
            <h4>クレジットカード情報の削除</h4>
            <p>お申し込み中、契約中のサービスがある場合、クレジットカード情報を削除することはできません。</p>

            <div class="mt20">
              <button class="btn-height btn btn01 bs" type="button" v-on:click="onDelete()">お支払い方法の削除</button>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()">マイページへ</button>
        </div>
      </div>

      <!-- 画面表示時、お支払い方法が登録されていても下記の要素が一瞬表示されるのをv-showで防ぐ -->
      <div v-else v-show="isCreated">
        <p>お支払い方法が登録されていません。</p>
        <p>クレジットカード情報を登録してください。</p>

        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>マイページへ</button>
          <button class="btn-height btn btn01 bs" type="button" @click="onRegister()">お支払い方法の登録<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}

.card-expired {
  color: red;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { Member } from '@/shared/classes/spf-api/member';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';

type SplitCardExpireDate = {
  cardExpireMonth: string;
  cardExpireYear: string;
};

interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}

export default defineComponent({
  name: 'payment-methods-list',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    errorMessageTitle: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** ボタン押下判定 */
    isSubmitting: false,
    isLoading: true, // 読み込み状態,最初はロード中
    paymentMethodInfoList: [] as PaymentMethodInfoForDisplay[],
    isCreated: false,
    /** マスク化されたクレジットカード番号 */
    maskedCardNumber: '',
    /** クレジットカードの有効期限 */
    cardExpired: '',
    /** クレジットカードの有効期限(月) */
    cardExpiredMonth: '',
    /** クレジットカードの有効期限(年) */
    cardExpiredYear: '',
    /** 画面表示中の支払方法における支払方法 No */
    displayPaymentMethodNo: 0,
  }),
  async created(): Promise<void> {
    const member: Member = this.$store.getters['memberStore/member'];

    const paymentMethodInfoList = await this.getPaymentMethodInfoList(member.id);

    if (paymentMethodInfoList.length !== 0) {
      this.maskedCardNumber = this.addHyphenToCardNumber(paymentMethodInfoList[0].cardNumber);
      this.cardExpired = paymentMethodInfoList[0].cardExpire;
      const splitCardExpireDate = this.splitCardExpireDate(this.cardExpired);
      this.cardExpiredMonth = splitCardExpireDate.cardExpireMonth;
      this.cardExpiredYear = String(processYearNumber()).slice(0, 2) + splitCardExpireDate.cardExpireYear;
      this.displayPaymentMethodNo = paymentMethodInfoList[0].paymentMethodNo;
    }
    this.isCreated = true;
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }
    this.isLoading = false;
  },
  methods: {
    /** 「編集」ボタン押下時 : 支払方法編集 > 入力画面に遷移する */
    async onEdit() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      this.$store.commit('paymentMethodStore/editRequestedPaymentMethodNo', this.displayPaymentMethodNo);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/payment-method/edit').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    },
    async onDelete() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      this.$store.commit('paymentMethodStore/deleteRequestedPaymentMethodNo', this.displayPaymentMethodNo);

      await this.$router.push('/platform/my-page/payment-method/delete-confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    },
    async onRegister(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page/payment-method/input')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得 */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay[]> {
      const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
      const paymentMethodInfo: PaymentMethodInfoForDisplay[] = [];

      for (const eachPaymentMethod of paymentMethodList) {
        const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
          ...eachPaymentMethod,
          isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
        };
        if (!eachPaymentMethod.deleteDate) {
          paymentMethodInfo.push(eachPaymentMethodForDisplay);
        }
      }
      return paymentMethodInfo;
    },
    /*
     * MM/YY 形式の文字列で渡されたカード有効期限を、月と年の情報に分割して返却する
     */
    splitCardExpireDate(cardExpire: string): SplitCardExpireDate {
      const splitCardExpireDateArray = cardExpire.split('/');
      const splitCardExpireDate = {
        cardExpireMonth: splitCardExpireDateArray[0],
        cardExpireYear: splitCardExpireDateArray[1],
      };
      return splitCardExpireDate;
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
  },
  computed: {
    hasPaymentMethod(): boolean {
      return this.maskedCardNumber !== '' && this.cardExpired !== '';
    },
  },
});
</script>
