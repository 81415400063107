import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { UcomWebSpaceRegisterInitialDisplayInfo } from '@/shared/classes/spf-api/mail/model/ucom/web-space-register/ucom-web-space-register-initial-display-info';
import { UcomWebSpaceRegisterInfo } from '@/shared/classes/spf-api/mail/model/ucom/web-space-register/ucom-web-space-register-info';
import { UcomAccount } from '../../../../shared/classes/spf-api/mail/model/common/permission/ucom/ucom-account';
const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部API をコールする */
export class SpfApiWebSpaceRegisterAccessor {
  /**
   * アカウントID管理：登録・変更_入力画面に表示するデータを取得するAPI
   * @param ucomAccountId UCOM_アカウントID
   * @return 初期表示データ
   */
  public static async getWebSpaceInitialDisplay(ucomAccountId: number): Promise<UcomWebSpaceRegisterInitialDisplayInfo> {
    const response = await api.get<UcomWebSpaceRegisterInitialDisplayInfo>(`/account/UCOM/account-option-web/${ucomAccountId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    return await this.convertWebSpaceInitialDisplayData(response.data);
  }

  /**
   *Webスペースの初期表示レスポンスをdtoに詰替え
   * @param data Webスペースの初期表示レスポンス
   * @returns Webスペースの初期表示情報dto
   */
  private static async convertWebSpaceInitialDisplayData(data: UcomWebSpaceRegisterInitialDisplayInfo | undefined): Promise<UcomWebSpaceRegisterInitialDisplayInfo> {
    if (!data) {
      throw new Error('初期表示データ無し');
    }

    return new UcomWebSpaceRegisterInitialDisplayInfo(data.errorMessage, data.hostingServerName);
  }

  /**
   * アカウントIDオプション変更：Webスペース登録の登録処理を実行するAPI
   * @param ucomAccountId UCOM_アカウントID
   * @param homepageAddress ホームページアドレス
   * @param applicationDate 申し込み日
   */
  public static async registerWebSpace(id: number, homepageAddress: string, subscriptionOn: string): Promise<UcomWebSpaceRegisterInfo> {
    const response = await api
      .post<UcomWebSpaceRegisterInfo>('/account/UCOM/account-option/hp', {
        id: id,
        homepageAddress: homepageAddress,
        subscriptionOn: subscriptionOn,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return await this.convertWebSpaceRegisterInfo(response.data);
  }

  /**
   * Webスペースの登録処理レスポンスをdtoに詰替え
   * @param data Webスペースの登録処理レスポンス
   * @returns Webスペースの登録情報dto
   */
  private static async convertWebSpaceRegisterInfo(data: UcomWebSpaceRegisterInfo): Promise<UcomWebSpaceRegisterInfo> {
    return new UcomWebSpaceRegisterInfo(data.errorMessage);
  }
}
