<template>
  <div class="connectix-at-top-outer">
    <section class="connectix-at-top">
      <div class="my-title">
        <h2>Connectix</h2>
      </div>

      <div class="my-image">
        <a :href="`${connectixLpUrl}`" target="Five.A">
          <img src="../../images/swiper/connectix_portasbanner_PC.png" alt="" class="img-pc" />
          <img src="../../images/swiper/connectix_portasbanner_SP.png" alt="" class="img-sp" />
        </a>
      </div>

      <div class="c-box-outer">
        <p class="paragraph">
          Connectixは、ご提供している建物にお住まいのお客様のみご利用いただけるサービスです。ご利用中のサービスを選択し、建物検索ページから、建物名を入力し、ご確認ください。
        </p>
        <div class="c-box-container">
          <div class="c-box">
            <div class="isp-pc">
              <div class="c-box__item">
                <ul class="link-list">
                  <li class="link-list__item"><a :href="`${ucomUrl}/search.html`" target="UCOM">UCOM光 レジデンス</a></li>
                  <li class="link-list__item" v-for="oem1 in oemMaster1" :key="oem1.viewOrder">
                    <a :href="oem1.domainForTop" target="Five.A">{{ oem1.label }}</a>
                  </li>
                </ul>
              </div>
              <div class="c-box__item">
                <ul class="link-list">
                  <li class="link-list__item"><a :href="`${eMansionUrl}/portal/pte05/`" target="My e-mansion">e-mansion</a></li>
                  <li class="link-list__item" v-for="oem2 in oemMaster2" :key="oem2.viewOrder">
                    <a :href="oem2.domainForTop" target="Five.A">{{ oem2.label }}</a>
                  </li>
                </ul>
              </div>
              <div class="c-box__item">
                <ul class="link-list">
                  <li class="link-list__item" style="display: hidden">&nbsp;</li>
                  <li class="link-list__item" v-for="oem3 in oemMaster3" :key="oem3.viewOrder">
                    <a :href="oem3.domainForTop" target="Five.A">{{ oem3.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="c-box">
            <div class="isp-sp">
              <div class="c-box__item">
                <ul class="link-list">
                  <li class="link-list__item"><a :href="`${ucomUrl}/search.html`" target="UCOM">UCOM光 レジデンス</a></li>
                  <li class="link-list__item"><a :href="`${eMansionUrl}/portal/pte05/`" target="My e-mansion">e-mansion</a></li>
                  <li class="link-list__item" v-for="oemMaster in oemsMaster" :key="oemMaster.viewOrder">
                    <a :href="oemMaster.domainForTop" target="Five.A">{{ oemMaster.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
div.connectix-at-top-outer {
  max-width: 960px;
  margin: 0 auto;
}

section.connectix-at-top {
  & div.my-title {
    margin-bottom: 24px;
    padding-left: 16px;
    & h2 {
      display: inline-block;
      margin: 0;
      font-size: 20px;
      line-height: 1.6;
      border-bottom: 3px solid #cf1225;
    }
  }
}

div.my-image {
  & img {
    width: 100%;
    &.img-pc {
      display: none;
    }
    &.img-sp {
      display: block;
    }
  }
}

.isp-pc {
  display: none;
}

div.c-box-outer {
  max-width: 960px;
  margin: 0 auto;
  padding: 24px 0;
  background-color: #041238;
  & p.paragraph {
    margin-bottom: 24px;
    padding: 0 24px;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.8px;
    line-height: 1.6;
  }
  & div.c-box-container {
    padding: 0 24px;
    & div.c-box {
      & div.c-box__item {
        padding: 0;
        &:last-child {
          margin-bottom: 0;
        }
        & ul.link-list {
          margin: 0;
          padding: 0;
          list-style-type: none;
          text-align: center;
          & li.link-list__item {
            padding: 0;
            margin-bottom: 8px;
            letter-spacing: 0.8px;
            line-height: 2.5;
            &:last-child {
              margin-bottom: 0;
            }
            & a {
              color: #fff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  div.my-image {
    & img {
      &.img-pc {
        display: block;
      }

      &.img-sp {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1070px) {
  div.c-box-outer {
    & div.c-box-container {
      padding: 0 16px;
      & div.c-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & div.isp-sp {
          display: none;
        }
        & div.isp-pc {
          display: flex;
          width: 100%;
        }
        & div.c-box__item {
          margin-bottom: 0;
          padding-left: 24px;
          width: calc(100% / 3);
          & ul.link-list {
            text-align: left;
            & li.link-list__item {
              line-height: 1.6;
            }
          }
        }
      }
    }
  }

  .float-on-image-txt {
    bottom: 14%;
    max-width: 50%;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1069px) {
  div.c-box-outer {
    & div.c-box-container {
      padding: 0 16px;
      & div.c-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & div.isp-sp {
          display: none;
        }
        & div.isp-pc {
          display: flex;
          width: 100%;
        }
        & div.c-box__item {
          margin-bottom: 0;
          padding-left: 24px;
          width: calc(100% / 3);
          & ul.link-list {
            text-align: left;
          }
        }
      }
    }
  }

  .float-on-image-txt {
    bottom: 14%;
    max-width: 50%;
    font-size: 17px;
  }
}

@media only screen and (min-width: 881px) and (max-width: 1023px) {
  div.c-box-outer {
    & div.c-box-container {
      padding: 0 16px;
      & div.c-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & div.isp-sp {
          display: none;
        }
        & div.isp-pc {
          display: flex;
          width: 100%;
        }
        & div.c-box__item {
          margin-bottom: 0;
          padding-left: 24px;
          width: calc(100% / 3);
          & ul.link-list {
            text-align: left;
          }
        }
      }
    }
  }

  .float-on-image-txt {
    bottom: 14%;
    max-width: 50%;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1000px) {
  section.connectix-at-top {
    & div.my-title {
      & h2 {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  section.connectix-at-top {
    & div.my-title {
      padding-left: 0;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';

export default defineComponent({
  name: 'connectix-at-top',
  data: () => ({
    connectixLpUrl: process.env.VUE_APP_CONNECTIX_LP_URL,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    fiveAUrl: process.env.VUE_APP_FIVE_A_BUILDING_SEARCH_URL,
    lNetUrl: process.env.VUE_APP_FIVE_A_LIC_SERVICE_DOMAIN,
    nAssetUrl: process.env.VUE_APP_FIVE_A_N_ASSET_SERVICE_DOMAIN,
    /** 有効期間のOEMマスタ一覧 */
    oemsMaster: new Array<GetOemsMasterResponse>(),
    oemMaster1: new Array<GetOemsMasterResponse>(),
    oemMaster2: new Array<GetOemsMasterResponse>(),
    oemMaster3: new Array<GetOemsMasterResponse>(),
  }),
  mounted() {
    this.oemsMaster = this.$store.getters['oemsStore/oems'];

    for (let i = 0; i < this.$data.oemsMaster.length; i++) {
      if (i % 3 === 0) {
        this.oemMaster1.push(this.oemsMaster[i]);
      } else if (i % 3 === 1) {
        this.oemMaster2.push(this.oemsMaster[i]);
      } else {
        this.oemMaster3.push(this.oemsMaster[i]);
      }
    }
  },
});
</script>
