import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fce2b19"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "permission-link"
}
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = {
  key: 1,
  class: "disabled"
}
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            (!_ctx.disabled)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.url,
                  target: _ctx.tabTarget,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickLink && _ctx.onClickLink(...args)))
                }, _toDisplayString(_ctx.title), 9, _hoisted_2))
              : _createCommentVNode("", true),
            (_ctx.disabled)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.description), 1)
      ]))
    : _createCommentVNode("", true)
}