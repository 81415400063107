import { RevisionInfo } from '@/shared/classes/revision-info';
import axios from 'axios';
import { configureAxiosRetry } from '../../libraries/axios-retry/configure-axios-retry';

export class RevisionService {

  private static revisionClient = (() => {
    const axiosClient = axios.create({
      baseURL: process.env.VUE_APP_REVISION_JSON_URL
    });

    axiosClient.interceptors.request.use((config) => {

      /**
       * safari が APIリクエスト時にキャッシュを参照してしまうことを防ぐための処理
       * クエリパラメータを付与することで、毎回別のAPIリクエストとsafariに認識させる
       */
      const methodGetRegexp = new RegExp('GET', 'i');
      if (methodGetRegexp.test(config.method ? config.method : '')) {
        config.params = {
          nocache: new Date().getTime()
        };
      }

      return config;
    });

    configureAxiosRetry(axiosClient);
    return axiosClient;
  })();

  public static async isRevisionUp() {
    return await this.checkRevision();
  }

  private static async checkRevision(): Promise<boolean> {

    const response = await this.revisionClient.get<RevisionInfo>('');
    const userWindow = window as any;// typescriptとjavascriptで挙動が異なるためanyを許容する

    if (userWindow.userRevision !== response.data.revision) {
      localStorage.setItem('isReload', 'true');// 文字は何でもよい
      return true;
    }

    return false;

  }

  public static async isAlreadyRevisionUp(): Promise<boolean> {

    const response = await this.revisionClient.get<RevisionInfo>('');
    const userWindow = window as any;// typescriptとjavascriptで挙動が異なるためanyを許容する

    if (localStorage.getItem('isReload') && userWindow.userRevision === response.data.revision) {
      localStorage.removeItem('isReload');
      return true;
    }

    return false;
  }
}