import { UcomAccountInfo } from '@/shared/classes/spf-api/mail/model/common/permission/ucom/ucom-account-info';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { UcomAccount } from '@/shared/classes/spf-api/mail/model/common/permission/ucom/ucom-account';
import { convertUcomAccountAuthority } from '@/shared/const/ucom/ucom-account-authority';
import { convertUcomRegisterStatusById } from '@/shared/const/ucom/ucom-register-status';
import { JudgeError } from '../common/judge-error';
import { UcomMailAddress } from '@/shared/classes/spf-api/mail/model/common/permission/ucom/ucom-mail-address';
import { convertXMailRegisterStatusById } from '@/shared/const/e-mansion/e-mansion-xmail-register-status';
import { convertUcomMailAddressType } from '@/shared/const/ucom/ucom-mail-address-type';
import { InitUcomCustomerNoUser } from '@/shared/classes/spf-api/mail/model/ucom/account/init-ucom-customer-no-user';

const api = BaseApiService.api();

export class SpfApiAccountAccessor {
  /**
   * UCOMのメインアカウント（お客様番号のユーザー）を新規登録する
   *
   * @param initUcomCustomerNoUser
   */
  public static async insertInitUcomCustomerNoUser(initUcomCustomerNoUser: InitUcomCustomerNoUser): Promise<void> {
    const response = await api.post<void>('/account/ucom/create/ucomCustomerNoUser', initUcomCustomerNoUser).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    if (!response.status || !(response.status >= 200) || !(response.status <= 299)) {
      throw new Error('アカウント作成・更新 失敗');
    }
    return;
  }

  /**
   * UCOMアカウント情報取得
   * @param customerNo お客様番号
   * @returns UcomAccountInfo UCOMアカウント情報
   */
  public static async getUcomAccountByCustomerNo(customerNo: string): Promise<UcomAccountInfo> {
    const response = await api.get<UcomAccountInfo>(`/account/ucom/getAccount/${customerNo}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return this.createUcomAccountInfo(await this.convertUcomAccount(response.data.accountInfo), await this.convertUcomMailAddressDto(response.data.mailInfo), response.data.isEmailPackPurchaseEnabled);
  }

  /**
   * UCOMアカウント情報取得
   * @param accountName アカウント名
   * @returns UcomAccountInfo UCOMアカウント情報
   */
  public static async getUcomAccountByAccountName(accountName: string): Promise<UcomAccountInfo> {
    const response = await api.get<UcomAccountInfo>(`/account/ucom/getSubAccount/${accountName}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return this.createUcomAccountInfo(await this.convertUcomAccount(response.data.accountInfo), await this.convertUcomMailAddressDto(response.data.mailInfo), response.data.isEmailPackPurchaseEnabled);
  }

  // 本来はリポジトリ内でやりたい…
  private static async convertUcomAccount(response: any | undefined): Promise<UcomAccount> {
    if (!response) {
      throw new Error('アカウント情報無し');
    }

    return new UcomAccount({
      _ucomAccountId: response.ucomAccountId,
      _memberId: response.memberId,
      _ucomPrimaryKey: response.ucomPrimaryKey,
      _registerStatus: convertUcomRegisterStatusById(Number(response.registerSatus)),
      _primaryKeyAuth0: response.primaryKeyAuth0,
      _accountAuthorization: convertUcomAccountAuthority(response.accountAuthorization),
      _accountName: response.accountName,
      _password: response.password,
      _familyName: response.familyName,
      _firstName: response.firstName,
      _familyNameKana: response.familyNameKana,
      _firstNameKana: response.firstNameKana,
      _userName: response.userName,
      _subscriptionOn: response.subscriptionOn,
      _cancelSubscriptionOn: response.cancelSubscriptionOn,
      _cancelOn: response.cancelOn,
      _cancelProcessOn: response.cancelProcessOn,
      _deleteOn: response.deleteOn,
      _isDelete: response.isDelete,
      _passwordChangeOn: response._passwordChangeOn,
    });
  }

  private static async convertUcomMailAddressDto(response: any | undefined): Promise<UcomMailAddress | undefined> {
    if (!response) {
      return undefined;
    }

    return new UcomMailAddress({
      _ucomAccountMailId: response.ucomAccountMailId,
      _ucomAccountId: response.ucomAccountId,
      _xmailUserId: response.xmailUserId,
      _xmailAuthId: response.xmailAuthId,
      _ucomRegisterSatus: convertUcomRegisterStatusById(Number(response.ucomRegisterSatus)),
      _xmailRegisterStatus: convertXMailRegisterStatusById(Number(response.xmailRegisterStatus)),
      _mailAddress: response.mailAddress,
      _mailAddressType: convertUcomMailAddressType(response.mailAddressType),
      _isOldDomain: response.isOldDomain,
      _cancelSubscriptionOn: response.cancelSubscriptionOn,
      _cancelOn: response.cancelOn,
      _cancelProcessOn: response.cancelProcessOn,
      _deleteOn: response.deleteOn,
      _isDelete: response.isDelete,
    });
  }

  private static createUcomAccountInfo(ucomAccount: UcomAccount, ucomMailAddress: UcomMailAddress | undefined, isEmailPackPurchaseEnabled: boolean): UcomAccountInfo {
    return new UcomAccountInfo(ucomAccount, ucomMailAddress, isEmailPackPurchaseEnabled);
  }

}
