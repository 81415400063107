import { Module } from 'vuex';
import { RootState } from '../index';

/** ucom アカウント管理 State */
const ucomAccountManagementState: {
  ucomAccountId: string | null,//UCOMアカウントID
  currentMailAddress: string | null,//現在のメールアドレス
  mailAddress: string | null,//メールアドレス
  mailAddressType: string | null,//メールアドレス種別
  isUpdate: boolean | null,//登録変更フラグ
  userName: string | null,//ユーザーネーム
  deleteOn: Date | null,//削除クリック日
  accountName: string | null,//アカウント名
} = {
  ucomAccountId: null,
  currentMailAddress: null,
  mailAddress: null,
  mailAddressType: null,
  isUpdate: null,
  userName: null,
  deleteOn: null,
  accountName: null,
};

/** State の型 */
type ucomAccountManagementState = typeof ucomAccountManagementState;

/** e-mansion メインメール Store */
export const ucomAccountManagementStore: Module<ucomAccountManagementState, RootState> = {

  namespaced: true,
  state: ucomAccountManagementState,
  actions: {
  },
  mutations: {
    ucomAccountId: (state, value: string) => (state.ucomAccountId = value),
    currentMailAddress: (state, value: string) => (state.currentMailAddress = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    mailAddressType: (state, value: string) => (state.mailAddressType = value),
    isUpdate: (state, value: boolean) => (state.isUpdate = value),
    userName: (state, value: string) => (state.userName = value),
    deleteOn: (state, value: Date) => (state.deleteOn = value),
    accountName: (state, value: string) => (state.accountName = value),
  },
  getters: {
    ucomAccountId: (state): string | null => state.ucomAccountId,
    currentMailAddress: (state): string | null => state.currentMailAddress,
    mailAddress: (state): string | null => state.mailAddress,
    mailAddressType: (state): string | null => state.mailAddressType,
    isUpdate: (state): boolean | null => state.isUpdate,
    userName: (state): string | null => state.userName,
    deleteOn: (state): Date | null => state.deleteOn,
    accountName: (state): string | null => state.accountName,
  }
};
