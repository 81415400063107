export class BusinessdayMasterDTO {
  /** 年月日 */
  public businessDate!: Date;

  constructor(partial: Partial<BusinessdayMasterDTO>) {
    Object.assign(this, partial);
  }
}

export type BusinessdayMasterDTOArray = BusinessdayMasterDTO[];
