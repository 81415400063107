import { EMansionAccount } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account';
import { EMansionMailAddress } from '@/shared/classes/spf-api/mail/model/mail-address/e-mansion-mail-address';

export class EMansionAccountInfo {
  private readonly _eMansionAccountInfo!: EMansionAccount;
  private readonly _eMansionMailAddressInfo!: EMansionMailAddress | undefined;
  private readonly _hasMailAddressAsMember!: boolean;
  private readonly _hasMainMailAddressAsMember!: boolean;
  private readonly _hasHomepageAsLoginUser!: boolean;
  private readonly _isOpenedHomepage!: boolean;
  private readonly _serviceInTime!:Date;

  constructor(
    eMansionAccountInfo: EMansionAccount,
    eMansionMailAddressInfo: EMansionMailAddress | undefined,
    hasMailAddressAsMember: boolean,
    hasMainMailAddressAsMember: boolean,
    hasHomepageAsLoginUser: boolean,
    isOpenedHomepage: boolean,
    serviceInTime:Date,
  ) {
    this._eMansionAccountInfo = eMansionAccountInfo;
    this._eMansionMailAddressInfo = eMansionMailAddressInfo;
    this._hasMailAddressAsMember = hasMailAddressAsMember;
    this._hasMainMailAddressAsMember = hasMainMailAddressAsMember;
    this._hasHomepageAsLoginUser = hasHomepageAsLoginUser;
    this._isOpenedHomepage = isOpenedHomepage;
    this._serviceInTime = serviceInTime;
  }

  get eMansionAccountInfo(): EMansionAccount {
    return this._eMansionAccountInfo;
  }

  get eMansionMailAddressInfo(): EMansionMailAddress | undefined {
    return this._eMansionMailAddressInfo;
  }

  get hasMailAddressAsMember(): boolean {
    return this._hasMailAddressAsMember;
  }
  
  get hasMainMailAddressAsMember(): boolean {
    return this._hasMainMailAddressAsMember;
  }

  get hasHomepageAsLoginUser(): boolean {
    return this._hasHomepageAsLoginUser;
  }

  get isOpenedHomepage(): boolean {
    return this._isOpenedHomepage;
  }

  get serviceInTime():Date{
    return this._serviceInTime;
  }
}
