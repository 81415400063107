/** バッチ状況DTO */
export class BatchSituationDto {
  /** バッチID */
  public readonly batchId!: string;

  /** 実行開始日 */
  public readonly startAt!: Date;

  /** 実行終了日 */
  public readonly endAt!: Date;

  /** 実行結果 */
  public readonly value!: number;

  /** 設定値1 */
  public readonly value1?: string;

  /** 設定値2 */
  public readonly value2?: string;

  /** 設定値3 */
  public readonly value3?: string;

  /** 設定値4 */
  public readonly value4?: string;

  /** 設定値5 */
  public readonly value5?: string;

  /** 初回登録日時 */
  public readonly createdAt!: Date;

  /** 最終更新日時 */
  public readonly updatedAt!: Date;

  /** コンストラクタ */
  constructor(partial: Partial<BatchSituationDto>) {
    Object.assign(this, partial);
  }
}
