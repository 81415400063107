/**
* URL用物件ID: APID(24031315)
* 物件ID: apartment_id(NE0787)
* APID → apartment_idに変換
*
* @param apid e-tnc内部用の物件ID（8桁）
* @return 物件ID
*/
export const convert8DigitIdTo6digitId = (apid: string): string => {
  // 分解
  const str1 = apid.substring(0, 2);
  const str2 = apid.substring(6, 8);
  const str3 = apid.substring(2, 6);

  // 変換
  /**1減算→36進数に変換→大文字
   * 例（24→'N'）
  */
  const alpha1 = (Number(str1) - 1).toString(36).toUpperCase();
  const alpha2 = (Number(str2) - 1).toString(36).toUpperCase();
  /**16進数を整数に変換し、0字詰めの4桁に変換
   * 例（0313→0787）
   */
  const decNumber = String(parseInt(str3, 16)).padStart(4, '0');

  return `${alpha1}${alpha2}${decNumber}`;
};

/**
* URL用物件ID: APID(24031315)
* 物件ID: apartment_id(NE0787)
* apartment_id → APIDに変換
*
* @param apartmentId 物件ID（6桁）
* @return URL用物件ID
*/
export const convert6DigitIdTo8DigitId = (apartmentId: string): string => {
  apartmentId = String(apartmentId);
  const hexatridecimalText1 = apartmentId.substring(0, 1);
  const hexatridecimalText2 = apartmentId.substring(1, 2);
  const decimalText = apartmentId.substring(2, 6);

  /**36進数を整数変換→文字列化 */
  const numeric1 = (parseInt(hexatridecimalText1, 36) + 1).toString();
  const numeric2 = (parseInt(hexatridecimalText2, 36) + 1).toString();
  /**整数を16進数に変換し、0字詰めの4桁に変換 */
  const decNumber = parseInt(decimalText).toString(16).padStart(4, '0');

  return `${numeric1}${decNumber}${numeric2}`;
};
