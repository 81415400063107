import dayjs from 'dayjs';
import { AuthService } from '@/shared/services/auth/auth-service';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { UcomVirusCheckInfo, UcomVirusCheckUpdateResult } from '@/shared/classes/spf-api/model/ucom/ucom-virus-check-info';
import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { UcomVirusCheckPauseResponse } from '@/infra/response/spf/ucom/virus-check/ucom-virus-check-pause-response';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';

const api = BaseApiService.api();

export class SpfApiVirusCheckAccessor {
  /**
   * ウィルスチェック初期表示情報を取得する
   * @param accountId UCOM_アカウントID
   * @return UcomVirusCheckInfo ウィルスチェック初期表示情報
   */
  public static async findUcomVirusCheck(accountId: number): Promise<UcomVirusCheckInfo> {
    const memberId = await AuthService.getMemberId();
    const date = dayjs(new Date()).format('YYYY/MM/DD');
    const response = await api
      .get<UcomVirusCheckInfo>(`/virus-check/ucom/nyuuryoku/${memberId}`, {
        params: { accountId, date },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return new UcomVirusCheckInfo(response.data);
  }

  /**
   * ウイルスチェック情報更新 (一時停止/開始)
   *
   * @param request ウイルスチェック情報更新リクエスト
   * @return ucomウイルスチェック情報更新 レスポンス
   */
  public static async updateUcomVirusCheckPause(request: UpdateUcomVirusCheckRequest): Promise<UcomVirusCheckPauseResponse> {
    const response = await api
      .post('/virus-check/ucom/moushikomi_kakunin', {
        id: request.portasId,
        ucomAccountId: request.ucomAccountId,
        date: request.subscriptionDate,
        action: request.ucomVirusCheckAction,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return new UcomVirusCheckPauseResponse(response.data.errorMessage);
  }

  /**
   * ウイルスチェック情報更新 (購入/解約)
   * @param request ウイルスチェック情報更新リクエスト
   * @return ucomウイルスチェック情報更新結果
   */
  public static async updateUcomVirusCheckPurchase(request: UpdateUcomVirusCheckRequest): Promise<UcomVirusCheckUpdateResult> {
    const response = await api
      .post<UcomVirusCheckUpdateResult>('/virus-check/ucom/moushikomi_kakunin', {
        id: Number(await AuthService.getMemberId()),
        ucomAccountId: request.ucomAccountId,
        date: request.subscriptionDate,
        action: request.ucomVirusCheckAction,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return new UcomVirusCheckUpdateResult(response.data);
  }
}
