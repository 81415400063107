import axios from 'axios';

import { EMansionLinkingInput } from '@/shared/classes/auth-manegement/e-mansion-linking-input';
import { UcomLinkingInput } from '@/shared/classes/auth-manegement/ucom-linking-input';
import { UcomSubAccountLinkingInput } from '@/shared/classes/auth-manegement/ucom-sub-account-linking-input';
import { AuthService } from './auth-service';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { McloudLinkingInput } from '@/shared/classes/auth-manegement/mcloud-linking-input';
import { BaseApiService } from '../api/base-api-service';

export const api = BaseApiService.api();

/** アカウント関係のサービス */
export class AuthManagementApiService {
  /**
   * Auth0 ユーザが e-mansion とアカウント連携を行う
   *
   */
  public static async linkingToEMansion(eMansionLinkingInput: EMansionLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
    try {
      const memberId: string = await AuthService.getMemberId();
      await api.post<EMansionLinkingInput>('/auth0-management/e-mansion/linking/' + memberId, eMansionLinkingInput);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * Auth0 ユーザを e-mansion 追加アカウントと連携する
   * @param eMansionLinkingInput 連携入力情報
   * @returns
   */
  public static async linkingSubAccountToEMansion(eMansionLinkingInput: EMansionLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
    try {
      const token = await AuthService.getAuth0AccessToken();
      await api.post<EMansionLinkingInput>('/auth0-management/e-mansion/sub-account-linking', eMansionLinkingInput, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * Auth0 ユーザが UCOM とアカウント連携を行う
   *
   */
  public static async linkingToUcom(ucomLinkingInput: UcomLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
    try {
      const memberId: string = await AuthService.getMemberId();
      await api.post<UcomLinkingInput>('/auth0-management/ucom/linking/' + memberId, ucomLinkingInput);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * Auth0 ユーザを UCOM 追加アカウントと連携する
   * @param ucomSubAccountLinkingInput 連携入力情報
   * @returns エラー時のみエラーレスポンス
   */
  public static async linkingSubAccountToUcom(ucomSubAccountLinkingInput: UcomSubAccountLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
    try {
      const token = await AuthService.getAuth0AccessToken();
      await api.post<UcomSubAccountLinkingInput>('/auth0-management/ucom/sub-account-linking', ucomSubAccountLinkingInput, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * Auth0ユーザーがMCLOUDとアカウント連携を行う
   */
  public static async linkingToMcloud(mcloudLinkingInput: McloudLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
    try {
      const memberId: string = await AuthService.getMemberId();
      await api.post<McloudLinkingInput>(`/auth0-management/mcloud/linking/${memberId}`, mcloudLinkingInput);
    } catch (error) {
      return this.handleError(error);
    }
  }
  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return 共通エラーオブジェクト
   */
  private static handleError(error: unknown): AuthManegementSharedErrorResponse {
    if (axios.isAxiosError(error)) {
      const errorResponse: AuthManegementSharedErrorResponse = error.response?.data;
      errorResponse.status = error.response?.status;
      if (errorResponse) return new AuthManegementSharedErrorResponse(errorResponse);
    }
    throw error;
  }
}
