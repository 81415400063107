/**
 * UCOM API : 光電話お申し込みコード確認 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomOpticalPhoneCodesResponseResult {
  /** 結果値 */
  public result!: UcomOpticalPhoneCodesResponse;

  constructor(partial: Partial<UcomOpticalPhoneCodesResponseResult>) {
    Object.assign(this, partial);
  }
}

/** UCOM API : 光電話お申し込みコード確認 : レスポンス 本体 */
export class UcomOpticalPhoneCodesResponse {
  /** メッセージ */
  public campaign_note!: Array<string>;

  constructor(partial: Partial<UcomOpticalPhoneCodesResponse>) {
    Object.assign(this, partial);
  }
}
