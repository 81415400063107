/** GameWith 登録情報 変更フォーム */
export class GameWithEditInputForm {
  /** お名前（姓） */
  public lastName!: string;
  /** お名前（名） */
  public firstName!: string;
  /** フリガナ（姓） */
  public lastNameKana!: string;
  /** フリガナ（名） */
  public firstNameKana!: string;
  /** メールアドレス */
  public email!: string;
  /** 電話番号 */
  public phone!: string;
  /**郵便番号 */
  public postalCode!: string;
  /** 都道府県 */
  public state!: string;
  /** 市区群 */
  public city!: string;
  /** 町名 */
  public street!: string;
  /** 丁目・番地・号 */
  public banchi!: string;
  /** 建物名 */
  public tatemono!: string;
  /** 部屋番号 */
  public roomNumber!: string;
  /** 転用承諾番号 */
  public switchingNumberNTT!: string;
  /** 事業者変更承諾番号 */
  public switchingNumberCollabo!: string;

  constructor(partial: Partial<GameWithEditInputForm>) {
    Object.assign(this, partial);
  }
}
