export class UcomAccountInfoForDelete {
  readonly _accountName!: string; // アカウント名
  readonly _fullName!: string; // 氏名
  readonly _accountAuthorization!: string; // アカウント権限（表示用）
  readonly _mailAddress!: string; // メールアドレス
  readonly _mailAddressType!: string; // メールアドレス種別
  readonly _isTargetLoginUser!: boolean; // 削除対象アカウントがログインユーザーかどうか

  /** コンストラクタ */
  constructor(partial: Partial<UcomAccountInfoForDelete>) {
    Object.assign(this, partial);
  }

  get accountName(): string {
    return this._accountName;
  }

  get fullName(): string {
    return this._fullName;
  }

  get accountAuthorization(): string {
    return this._accountAuthorization;
  }

  get mailAddress(): string {
    return this._mailAddress;
  }

  get mailAddressType(): string | undefined {
    return this._mailAddressType;
  }

  get isTargetLoginUser(): boolean {
    return this._isTargetLoginUser;
  }
}
