<template>
  <div class="sub-account-linkage-stepping"></div>
</template>

<script lang="ts">
import { convertSubAccountLinkageFrom, SUB_ACCOUNT_LINKAGE_FROM } from '@/shared/const/sub-account-linkage-from';
import { defineComponent } from 'vue';
import { AuthService } from '../shared/services/auth/auth-service';

/** アカウント登録踏み台 画面 */
export default defineComponent({
  name: 'sub-account-linkage-stepping',
  async mounted() {
    const from = convertSubAccountLinkageFrom(this.$route.params.from as string);
    if (!from) {
      throw new Error(`アカウント連携パラメータ不正 from=[${this.$route.params.from}]`);
    }

    if (from === SUB_ACCOUNT_LINKAGE_FROM.E_MANSION) {
      await AuthService.logout(process.env.VUE_APP_E_MANSION_LOGIN_ADDITIONAL_ACCOUNT);
      return;
    }

    await AuthService.logout(process.env.VUE_APP_UCOM_LOGIN_ACCOUNT_ID_USER);
  },
});
</script>
