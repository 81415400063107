/** 
 * 機種依存文字チェック：入力文字全般_機種依存が含まれている場合はNG=機種依存が含まれていないことを条件に指定
 * https://opst.backlog.jp/view/ARN_QA-822#comment-1376590740
 * 上記で提供された以下の文言を元に実装（実コードも同様のため
 * ・機種依存文字（0x81AD-0x81B7, 0x81C0-0x81C7, 0x81CF-0x81D9, 0x81E9-0x81EF, 0x81F8-0x81FB
 *                 0x8240-0x824E, 0x8259-0x825F, 0x827A-0x8280, 0x829B-0x829E, 0x82F2-0x82FC
 *                 0x8397-0x839E, 0x83B7-0x83BE, 0x83D7-0x83FC,
 *                 0x8461-0x846F, 0x8492-0x849E, 0x84BF-0x84FC,
 *                 0x8540-0x889E, 0xEAA6-0xFCFC
 *                 ）
 * ※結局、機種依存文字のみのチェックをすればよい。機種依存文字に全て含まれる
 * sjisの正規表現をtypescriptに移植するのはできなかったため、sjisのコードからunicodeのコードを引いて全て記述する対応としている
 **/
export function isPlatformDependentCharacters(character: string): boolean {
  // 正規表現でundefinedではない場合はNG
  return PLATFORM_DEPENDENT_CHARACTERS.find((regExp) => regExp.test(character)) !== undefined;
}

// 正規表現だと文字が膨大で読みづらいため配列で定義
const PLATFORM_DEPENDENT_CHARACTERS = [
  /\u0027/ // '\'' 0x81ad
  , /\u0022/ // '"' 0x81ae
  , /\u002d/ // '-' 0x81af
  , /\u007e/ // '~' 0x81b0
  , /\u3033/ // '〳' 0x81b1
  , /\u3034/ // '〴' 0x81b2
  , /\u3035/ // '〵' 0x81b3
  , /\u303B/ // '〻' 0x81b4
  , /\u303C/ // '〼' 0x81b5
  , /\u30FF/ // 'ヿ' 0x81b6
  , /\u309F/ // 'ゟ' 0x81b7
  , /\u2284/ // '⊄' 0x81c0
  , /\u228a/ // '⊅' 0x81c2
  , /\u228b/ // '⊋' 0x81c3
  , /\u2209/ // '∉' 0x81c4
  , /\u2205/ // '∅' 0x81c5
  , /\u2305/ // '⌅' 0x81c6
  , /\u2306/ // '⌆' 0x81c7
  , /\u2295/ // '⊕' 0x81cf
  , /\u2296/ // '⊖' 0x81d0
  , /\u2297/ // '⊗' 0x81d1
  , /\u2225/ // '∥' 0x81d2
  , /\u2226/ // '∦' 0x81d3
  , /\uff5f/ // '｟' 0x81d4
  , /\uff60/ // '｠' 0x81d5
  , /\uff60/ // '〘' 0x81d5
  , /\u3019/ // '〙' 0x81d7
  , /\u3016/ // '〖' 0x81d8
  , /\u3017/ // '〗' 0x81d9
  , /\u2262/ // '≢' 0x81e9
  , /\u2243/ // '≃' 0x81ea
  , /\u2245/ // '≅' 0x81eb
  , /\u2248/ // '≈' 0x81ec
  , /\u2276/ // '≶' 0x81ed
  , /\u2277/ // '≷' 0x81ee
  , /\u2194/ // '↔' 0x81ef
  , /\u266e/ // '♮' 0x81f8
  , /\u266b/ // '♫' 0x81f9
  , /\u266c/ // '♬' 0x81fa
  , /\u2669/ // '♩' 0x81fb
  , /\u25b7/ // '▷' 0x8240
  , /\u25b6/ // '▶' 0x8241
  , /\u25c1/ // '◁' 0x8242
  , /\u25c0/ // '◀' 0x8243
  , /\u2197/ // '↗' 0x8244
  , /\u2198/ // '↘' 0x8245
  , /\u2196/ // '↖' 0x8246
  , /\u2199/ // '↙' 0x8247
  , /\u21c4/ // '⇄' 0x8248
  , /\u21e8/ // '⇨' 0x8249
  , /\u21e6/ // '⇦' 0x824a
  , /\u21e7/ // '⇧' 0x824b
  , /\u21e9/ // '⇩' 0x824c
  , /\u2934/ // '⤴' 0x824d
  , /\u2935/ // '⤵' 0x824e
  // /0x8259-0x825F 
  , /\u29bf/ // '⦿' 0x8259
  , /\u25c9/ // '◉' 0x825a
  , /\u303d/ // '〽' 0x825b
  , /\ufe46/ // '﹆' 0x825c
  , /\ufe45/ // '﹅' 0x825d
  , /\u25e6/ // '◦' 0x825e
  , /\u2022/ // '•' 0x825f
  // /0x827A-0x8280 
  , /\u2213/ // '∓' 0x827a
  , /\u2135/ // 'ℵ' 0x827b
  , /\u210f/ // 'ℏ' 0x827c
  , /\u33cb/ // '㏋' 0x827d
  , /\u2113/ // 'ℓ' 0x827e
  , /\u2127/ // '℧' 0x8280
  // /0x829B-0x829E 
  , /\u30a0/ // '゠' 0x829b
  , /\u2013/ // '–' 0x829c
  , /\u29fa/ // '⧺' 0x829d
  , /\u29fb/ // '⧻' 0x829e
  // /0x82F2-0x82FC 
  , /\u3094/ // 'ゔ' 0x82f2
  , /\u3095/ // 'ゕ' 0x82f3
  , /\u3096/ // 'ゖ' 0x82f4
  , /\u304b\u309a/ // 'か゚' 0x82f5
  , /\u304d\u309a/ // 'き゚' 0x82f6
  , /\u304f\u309a/ // 'く゚' 0x82f7
  , /\u3051\u309a/ // 'け゚' 0x82f8
  , /\u3053\u309a/ // 'こ゚' 0x82f9
  // /0x82FA reserved
  // /0x82FB reserved
  // /0x82FC reserved
  // /0x8397-0x839E 
  , /\u30ab\u309a/ // 'カ゚' 0x8397
  , /\u30ad\u309a/ // 'キ゚' 0x8398
  , /\u30af\u309a/ // 'ク゚' 0x8399
  , /\u30b1\u309a/ // 'ケ゚' 0x839a
  , /\u30b3\u309a/ // 'コ゚' 0x839b
  , /\u30bb\u309a/ // 'セ゚' 0x839c
  , /\u30c4\u309a/ // 'ツ゚' 0x839d
  , /\u30c8\u309a/ // 'ト゚' 0x839e
  // /0x83B7-0x83BE 
  , /\u2664/ // '♤' 0x83b7
  , /\u2660/ // '♠' 0x83b8
  , /\u2662/ // '♢' 0x83b9
  , /\u2666/ // '♦' 0x83ba
  , /\u2661/ // '♡' 0x83bb
  , /\u2665/ // '♥' 0x83bc
  , /\u2667/ // '♧' 0x83bd
  , /\u2663/ // '♣' 0x83be
  // /0x83D7-0x83FC 
  , /\u03c2/ // 'ς' 0x83d7
  , /\u24f5/ // '⓵' 0x83d8
  , /\u24f6/ // '⓶' 0x83d9
  , /\u24f7/ // '⓷' 0x83da
  , /\u24f8/ // '⓸' 0x83db
  , /\u24f9/ // '⓹' 0x83dc
  , /\u24fa/ // '⓺' 0x83dd
  , /\u24fb/ // '⓻' 0x83de
  , /\u24fc/ // '⓼' 0x83df
  , /\u24fd/ // '⓽' 0x83e0
  , /\u24fe/ // '⓾' 0x83e1
  , /\u2616/ // '☖' 0x83e2
  , /\u2617/ // '☗' 0x83e3
  , /\u3020/ // '〠' 0x83e4
  , /\u260e/ // '☎' 0x83e5
  , /\u2600/ // '☀' 0x83e6
  , /\u2601/ // '☁' 0x83e7
  , /\u2602/ // '☂' 0x83e8
  , /\u2603/ // '☃' 0x83e9
  , /\u2668/ // '♨' 0x83ea
  , /\u25b1/ // '▱' 0x83eb
  , /\u31f0/ // 'ㇰ' 0x83ec
  , /\u31f1/ // 'ㇱ' 0x83ed
  , /\u31f2/ // 'ㇲ' 0x83ee
  , /\u31f3/ // 'ㇳ' 0x83ef
  , /\u31f4/ // 'ㇴ' 0x83f0
  , /\u31f5/ // 'ㇵ' 0x83f1
  , /\u31f6/ // 'ㇶ' 0x83f2
  , /\u31f7/ // 'ㇷ' 0x83f3
  , /\u31f8/ // 'ㇸ' 0x83f4
  , /\u31f9/ // 'ㇹ' 0x83f5
  , /\u31f7\u309a/ // 'ㇷ゚' 0x83f6
  , /\u31fa/ // 'ㇺ' 0x83f7
  , /\u31fb/ // 'ㇻ' 0x83f8
  , /\u31fc/ // 'ㇼ' 0x83f9
  , /\u31fd/ // 'ㇽ' 0x83fa
  , /\u31fe/ // 'ㇾ' 0x83fb
  , /\u31ff/ // 'ㇿ' 0x83fc
  // /0x8461-0x846F 
  , /\u23be/ // '⎾' 0x8461
  , /\u23bf/ // '⎿' 0x8462
  , /\u23c0/ // '⏀' 0x8463
  , /\u23c1/ // '⏁' 0x8464
  , /\u23c2/ // '⏂' 0x8465
  , /\u23c3/ // '⏃' 0x8466
  , /\u23c4/ // '⏄' 0x8467
  , /\u23c5/ // '⏅' 0x8468
  , /\u23c6/ // '⏆' 0x8469
  , /\u23c7/ // '⏇' 0x846a
  , /\u23c8/ // '⏈' 0x846b
  , /\u23c9/ // '⏉' 0x846c
  , /\u23ca/ // '⏊' 0x846d
  , /\u23cb/ // '⏋' 0x846e
  , /\u23cc/ // '⏌' 0x846f
  // /0x8492-0x849E 
  , /\u30f7/ // 'ヷ' 0x8492
  , /\u30f8/ // 'ヸ' 0x8493
  , /\u30f9/ // 'ヹ' 0x8494
  , /\u30fa/ // 'ヺ' 0x8495
  , /\u22da/ // '⋚' 0x8496
  , /\u22db/ // '⋛' 0x8497
  , /\u2153/ // '⅓' 0x8498
  , /\u2154/ // '⅔' 0x8499
  , /\u2155/ // '⅕' 0x849a
  , /\u2713/ // '✓' 0x849b
  , /\u2318/ // '⌘' 0x849c
  , /\u2423/ // '␣' 0x849d
  , /\u23ce/ // '⏎' 0x849e
  // /0x84BF-0x84FC 
  , /\u3251/ // '㉑' 0x84bf
  , /\u3252/ // '㉒' 0x84c0
  , /\u3253/ // '㉓' 0x84c1
  , /\u3254/ // '㉔' 0x84c2
  , /\u3255/ // '㉕' 0x84c3
  , /\u3256/ // '㉖' 0x84c4
  , /\u3257/ // '㉗' 0x84c5
  , /\u3258/ // '㉘' 0x84c6
  , /\u3259/ // '㉙' 0x84c7
  , /\u325a/ // '㉚' 0x84c8
  , /\u325b/ // '㉛' 0x84c9
  , /\u325c/ // '㉜' 0x84ca
  , /\u325d/ // '㉝' 0x84cb
  , /\u325e/ // '㉞' 0x84cc
  , /\u325f/ // '㉟' 0x84cd
  , /\u32b1/ // '㊱' 0x84ce
  , /\u32b2/ // '㊲' 0x84cf
  , /\u32b3/ // '㊳' 0x84d0
  , /\u32b4/ // '㊴' 0x84d1
  , /\u32b5/ // '㊵' 0x84d2
  , /\u32b6/ // '㊶' 0x84d3
  , /\u32b7/ // '㊷' 0x84d4
  , /\u32b8/ // '㊸' 0x84d5
  , /\u32b9/ // '㊹' 0x84d6
  , /\u32ba/ // '㊺' 0x84d7
  , /\u32bb/ // '㊻' 0x84d8
  , /\u32bc/ // '㊼' 0x84d9
  , /\u32bd/ // '㊽' 0x84da
  , /\u32be/ // '㊾' 0x84db
  , /\u32bf/ // '㊿' 0x84dc
  , /\u25d0/ // '◐' 0x84e5
  , /\u25d1/ // '◑' 0x84e6
  , /\u25d2/ // '◒' 0x84e7
  , /\u25d3/ // '◓' 0x84e8
  , /\u203c/ // '‼' 0x84e9
  , /\u2047/ // '⁇' 0x84ea
  , /\u2048/ // '⁈' 0x84eb
  , /\u2049/ // '⁉' 0x84ec
  , /\u01cd/ // 'Ǎ' 0x84ed
  , /\u01ce/ // 'ǎ' 0x84ee
  , /\u01d0/ // 'ǐ' 0x84ef
  , /\u1e3e/ // 'Ḿ' 0x84f0
  , /\u1e3f/ // 'ḿ' 0x84f1
  , /\u01f8/ // 'Ǹ' 0x84f2
  , /\u01f9/ // 'ǹ' 0x84f3
  , /\u01d1/ // 'Ǒ' 0x84f4
  , /\u01d2/ // 'ǒ' 0x84f5
  , /\u01d4/ // 'ǔ' 0x84f6
  , /\u01d6/ // 'ǖ' 0x84f7
  , /\u01d8/ // 'ǘ' 0x84f8
  , /\u01da/ // 'ǚ' 0x84f9
  , /\u01dc/ // 'ǜ' 0x84fa
  // /0x84FB reserved
  // /0x84FC reserved
  // /0x8540-0x889E 
  , /\u00a1/ //¡ 0x8542
  , /\u00a4/ //¤ 0x8543
  , /\u00a6/ //¦ 0x8544
  , /\u00a9/ //© 0x8545
  , /\u00aa/ //ª 0x8546
  , /\u00ab/ //« 0x8547
  , /\u00ad/ //­ 0x8548
  , /\u00ae/ //® 0x8549
  , /\u00af/ //¯ 0x854a
  , /\u00b2/ //² 0x854b
  , /\u00b3/ //³ 0x854c
  , /\u00b7/ //· 0x854d
  , /\u00b8/ //¸ 0x854e
  , /\u00b9/ //¹ 0x854f
  , /\u00ba/ //º 0x8550
  , /\u00bb/ //» 0x8551
  , /\u00bc/ //¼ 0x8552
  , /\u00bd/ //½ 0x8553
  , /\u00be/ //¾ 0x8554
  , /\u00bf/ //¿ 0x8555
  , /\u00c0/ //À 0x8556
  , /\u00c1/ //Á 0x8557
  , /\u00c2/ //Â 0x8558
  , /\u00c3/ //Ã 0x8559
  , /\u00c4/ //Ä 0x855a
  , /\u00c5/ //Å 0x855b
  , /\u00c6/ //Æ 0x855c
  , /\u00c7/ //Ç 0x855d
  , /\u00c8/ //È 0x855e
  , /\u00c9/ //É 0x855f
  , /\u00ca/ //Ê 0x8560
  , /\u00cb/ //Ë 0x8561
  , /\u00cc/ //Ì 0x8562
  , /\u00cd/ //Í 0x8563
  , /\u00ce/ //Î 0x8564
  , /\u00cf/ //Ï 0x8565
  , /\u00d0/ //Ð 0x8566
  , /\u00d1/ //Ñ 0x8567
  , /\u00d2/ //Ò 0x8568
  , /\u00d3/ //Ó 0x8569
  , /\u00d4/ //Ô 0x856a
  , /\u00d5/ //Õ 0x856b
  , /\u00d6/ //Ö 0x856c
  , /\u00d8/ //Ø 0x856d
  , /\u00d9/ //Ù 0x856e
  , /\u00da/ //Ú 0x856f
  , /\u00db/ //Û 0x8570
  , /\u00dc/ //Ü 0x8571
  , /\u00dd/ //Ý 0x8572
  , /\u00de/ //Þ 0x8573
  , /\u00df/ //ß 0x8574
  , /\u00e0/ //à 0x8575
  , /\u00e1/ //á 0x8576
  , /\u00e2/ //â 0x8577
  , /\u00e3/ //ã 0x8578
  , /\u00e4/ //ä 0x8579
  , /\u00e5/ //å 0x857a
  , /\u00e6/ //æ 0x857b
  , /\u00e7/ //ç 0x857c
  , /\u00e8/ //è 0x857d
  , /\u00e9/ //é 0x857e
  , /\u00ea/ //ê 0x8580
  , /\u00eb/ //ë 0x8581
  , /\u00ec/ //ì 0x8582
  , /\u00ed/ //í 0x8583
  , /\u00ee/ //î 0x8584
  , /\u00ef/ //ï 0x8585
  , /\u00f0/ //ð 0x8586
  , /\u00f1/ //ñ 0x8587
  , /\u00f2/ //ò 0x8588
  , /\u00f3/ //ó 0x8589
  , /\u00f4/ //ô 0x858a
  , /\u00f5/ //õ 0x858b
  , /\u00f6/ //ö 0x858c
  , /\u00f8/ //ø 0x858d
  , /\u00f9/ //ù 0x858e
  , /\u00fa/ //ú 0x858f
  , /\u00fb/ //û 0x8590
  , /\u00fc/ //ü 0x8591
  , /\u00fd/ //ý 0x8592
  , /\u00fe/ //þ 0x8593
  , /\u00ff/ //ÿ 0x8594
  , /\u0100/ //Ā 0x8595
  , /\u012a/ //Ī 0x8596
  , /\u016a/ //Ū 0x8597
  , /\u0112/ //Ē 0x8598
  , /\u014c/ //Ō 0x8599
  , /\u0101/ //ā 0x859a
  , /\u012b/ //ī 0x859b
  , /\u016b/ //ū 0x859c
  , /\u0113/ //ē 0x859d
  , /\u014d/ //ō 0x859e
  , /\u0104/ //Ą 0x859f
  , /\u02d8/ //˘ 0x85a0
  , /\u0141/ //Ł 0x85a1
  , /\u013d/ //Ľ 0x85a2
  , /\u015a/ //Ś 0x85a3
  , /\u0160/ //Š 0x85a4
  , /\u015e/ //Ş 0x85a5
  , /\u0164/ //Ť 0x85a6
  , /\u0179/ //Ź 0x85a7
  , /\u017d/ //Ž 0x85a8
  , /\u017b/ //Ż 0x85a9
  , /\u0105/ //ą 0x85aa
  , /\u02db/ //˛ 0x85ab
  , /\u0142/ //ł 0x85ac
  , /\u013e/ //ľ 0x85ad
  , /\u015b/ //ś 0x85ae
  , /\u02c7/ //ˇ 0x85af
  , /\u0161/ //š 0x85b0
  , /\u015f/ //ş 0x85b1
  , /\u0165/ //ť 0x85b2
  , /\u017a/ //ź 0x85b3
  , /\u02dd/ //˝ 0x85b4
  , /\u017e/ //ž 0x85b5
  , /\u017c/ //ż 0x85b6
  , /\u0154/ //Ŕ 0x85b7
  , /\u0102/ //Ă 0x85b8
  , /\u0139/ //Ĺ 0x85b9
  , /\u0106/ //Ć 0x85ba
  , /\u010c/ //Č 0x85bb
  , /\u0118/ //Ę 0x85bc
  , /\u011a/ //Ě 0x85bd
  , /\u010e/ //Ď 0x85be
  , /\u0143/ //Ń 0x85bf
  , /\u0147/ //Ň 0x85c0
  , /\u0150/ //Ő 0x85c1
  , /\u0158/ //Ř 0x85c2
  , /\u016e/ //Ů 0x85c3
  , /\u0170/ //Ű 0x85c4
  , /\u0162/ //Ţ 0x85c5
  , /\u0155/ //ŕ 0x85c6
  , /\u0103/ //ă 0x85c7
  , /\u013a/ //ĺ 0x85c8
  , /\u0107/ //ć 0x85c9
  , /\u010d/ //č 0x85ca
  , /\u0119/ //ę 0x85cb
  , /\u011b/ //ě 0x85cc
  , /\u010f/ //ď 0x85cd
  , /\u0111/ //đ 0x85ce
  , /\u0144/ //ń 0x85cf
  , /\u0148/ //ň 0x85d0
  , /\u0151/ //ő 0x85d1
  , /\u0159/ //ř 0x85d2
  , /\u016f/ //ů 0x85d3
  , /\u0171/ //ű 0x85d4
  , /\u0163/ //ţ 0x85d5
  , /\u02d9/ //˙ 0x85d6
  , /\u0108/ //Ĉ 0x85d7
  , /\u011c/ //Ĝ 0x85d8
  , /\u0124/ //Ĥ 0x85d9
  , /\u0134/ //Ĵ 0x85da
  , /\u015c/ //Ŝ 0x85db
  , /\u016c/ //Ŭ 0x85dc
  , /\u0109/ //ĉ 0x85dd
  , /\u011d/ //ĝ 0x85de
  , /\u0125/ //ĥ 0x85df
  , /\u0135/ //ĵ 0x85e0
  , /\u015d/ //ŝ 0x85e1
  , /\u016d/ //ŭ 0x85e2
  , /\u0271/ //ɱ 0x85e3
  , /\u028b/ //ʋ 0x85e4
  , /\u027e/ //ɾ 0x85e5
  , /\u0283/ //ʃ 0x85e6
  , /\u0292/ //ʒ 0x85e7
  , /\u026c/ //ɬ 0x85e8
  , /\u026e/ //ɮ 0x85e9
  , /\u0279/ //ɹ 0x85ea
  , /\u0288/ //ʈ 0x85eb
  , /\u0256/ //ɖ 0x85ec
  , /\u0273/ //ɳ 0x85ed
  , /\u027d/ //ɽ 0x85ee
  , /\u0282/ //ʂ 0x85ef
  , /\u0290/ //ʐ 0x85f0
  , /\u027b/ //ɻ 0x85f1
  , /\u026d/ //ɭ 0x85f2
  , /\u025f/ //ɟ 0x85f3
  , /\u0272/ //ɲ 0x85f4
  , /\u029d/ //ʝ 0x85f5
  , /\u028e/ //ʎ 0x85f6
  , /\u0261/ //ɡ 0x85f7
  , /\u014b/ //ŋ 0x85f8
  , /\u0270/ //ɰ 0x85f9
  , /\u0281/ //ʁ 0x85fa
  , /\u0127/ //ħ 0x85fb
  , /\u0295/ //ʕ 0x85fc
  , /\u0294/ //ʔ 0x8640
  , /\u0266/ //ɦ 0x8641
  , /\u0298/ //ʘ 0x8642
  , /\u01c2/ //ǂ 0x8643
  , /\u0253/ //ɓ 0x8644
  , /\u0257/ //ɗ 0x8645
  , /\u0284/ //ʄ 0x8646
  , /\u0260/ //ɠ 0x8647
  , /\u0193/ //Ɠ 0x8648
  , /\u0153/ //œ 0x8649
  , /\u0152/ //Œ 0x864a
  , /\u0268/ //ɨ 0x864b
  , /\u0289/ //ʉ 0x864c
  , /\u0258/ //ɘ 0x864d
  , /\u0275/ //ɵ 0x864e
  , /\u0259/ //ə 0x864f
  , /\u025c/ //ɜ 0x8650
  , /\u025e/ //ɞ 0x8651
  , /\u0250/ //ɐ 0x8652
  , /\u026f/ //ɯ 0x8653
  , /\u028a/ //ʊ 0x8654
  , /\u0264/ //ɤ 0x8655
  , /\u028c/ //ʌ 0x8656
  , /\u0254/ //ɔ 0x8657
  , /\u0251/ //ɑ 0x8658
  , /\u0252/ //ɒ 0x8659
  , /\u028d/ //ʍ 0x865a
  , /\u0265/ //ɥ 0x865b
  , /\u02a2/ //ʢ 0x865c
  , /\u02a1/ //ʡ 0x865d
  , /\u0255/ //ɕ 0x865e
  , /\u0291/ //ʑ 0x865f
  , /\u027a/ //ɺ 0x8660
  , /\u0267/ //ɧ 0x8661
  , /\u025a/ //ɚ 0x8662
  , /\u00e6\u0300/ //æ̀ 0x8663
  , /\u01fd/ //ǽ 0x8664
  , /\u0251\u0300/ //ɑ̀ 0x8665
  , /\u0251\u0301/ //ɑ́ 0x8666
  , /\u0254\u0300/ //ɔ̀ 0x8667
  , /\u0254\u0301/ //ɔ́ 0x8668
  , /\u028c\u0300/ //ʌ̀ 0x8669
  , /\u028c\u0301/ //ʌ́ 0x866a
  , /\u0259\u0300/ //ə̀ 0x866b
  , /\u0259\u0301/ //ə́ 0x866c
  , /\u025a\u0300/ //ɚ̀ 0x866d
  , /\u025a\u0301/ //ɚ́ 0x866e
  , /\u025B\u0300/ //ɛ̀ 0x866f
  , /\u025B\u0301/ //ɛ́ 0x8670
  , /\u0361/ //͡ 0x8671
  , /\u02c8/ //ˈ 0x8672
  , /\u02cc/ //ˌ 0x8673
  , /\u02d0/ //ː 0x8674
  , /\u02d1/ //ˑ 0x8675
  , /\u0306/ //̆ 0x8676
  , /\u203F/ //‿ 0x8677
  , /\u030b/ //̋ 0x8678
  , /\u0301/ //́ 0x8679
  , /\u0304/ //̄ 0x867a
  , /\u0300/ //̀ 0x867b
  , /\u030f/ //̏ 0x867c
  , /\u030c/ //̌ 0x867d
  , /\u0302/ //̂ 0x867e
  , /\u02e5/ //˥ 0x8680
  , /\u02e6/ //˦ 0x8681
  , /\u02e7/ //˧ 0x8682
  , /\u02e8/ //˨ 0x8683
  , /\u02e9/ //˩ 0x8684
  , /\u02e9\u02e5/ //˩˥ 0x8685
  , /\u02e5\u02e9/ //˥˩ 0x8686
  , /\u0325/ //̥ 0x8687
  , /\u032c/ //̬ 0x8688
  , /\u0339/ //̹ 0x8689
  , /\u031c/ //̜ 0x868a
  , /\u031f/ //̟ 0x868b
  , /\u0320/ //̠ 0x868c
  , /\u0308/ //̈ 0x868d
  , /\u033d/ //̽ 0x868e
  , /\u0329/ //̩ 0x868f
  , /\u032f/ //̯ 0x8690
  , /\u02de/ //˞ 0x8691
  , /\u0324/ //̤ 0x8692
  , /\u0330/ //̰ 0x8693
  , /\u033c/ //̼ 0x8694
  , /\u0334/ //̴ 0x8695
  , /\u031d/ //̝ 0x8696
  , /\u031e/ //̞ 0x8697
  , /\u0318/ //̘ 0x8698
  , /\u0319/ //̙ 0x8699
  , /\u032a/ //̪ 0x869a
  , /\u033a/ //̺ 0x869b
  , /\u033b/ //̻ 0x869c
  , /\u0303/ //̃ 0x869d
  , /\u031a/ //̚ 0x869e
  , /\u2776/ //❶ 0x869f
  , /\u2777/ //❷ 0x86a0
  , /\u2778/ //❸ 0x86a1
  , /\u2779/ //❹ 0x86a2
  , /\u277a/ //❺ 0x86a3
  , /\u277b/ //❻ 0x86a4
  , /\u277c/ //❼ 0x86a5
  , /\u277d/ //❽ 0x86a6
  , /\u277e/ //❾ 0x86a7
  , /\u277f/ //❿ 0x86a8
  , /\u24eb/ //⓫ 0x86a9
  , /\u24ec/ //⓬ 0x86aa
  , /\u24ed/ //⓭ 0x86ab
  , /\u24ee/ //⓮ 0x86ac
  , /\u24ef/ //⓯ 0x86ad
  , /\u24f0/ //⓰ 0x86ae
  , /\u24f1/ //⓱ 0x86af
  , /\u24f2/ //⓲ 0x86b0
  , /\u24f3/ //⓳ 0x86b1
  , /\u24f4/ //⓴ 0x86b2
  , /\u2170/ //ⅰ 0x86b3
  , /\u2171/ //ⅱ 0x86b4
  , /\u2172/ //ⅲ 0x86b5
  , /\u2173/ //ⅳ 0x86b6
  , /\u2174/ //ⅴ 0x86b7
  , /\u2175/ //ⅵ 0x86b8
  , /\u2176/ //ⅶ 0x86b9
  , /\u2177/ //ⅷ 0x86ba
  , /\u2178/ //ⅸ 0x86bb
  , /\u2179/ //ⅹ 0x86bc
  , /\u217a/ //ⅺ 0x86bd
  , /\u217b/ //ⅻ 0x86be
  , /\u24d0/ //ⓐ 0x86bf
  , /\u24d1/ //ⓑ 0x86c0
  , /\u24d2/ //ⓒ 0x86c1
  , /\u24d3/ //ⓓ 0x86c2
  , /\u24d4/ //ⓔ 0x86c3
  , /\u24d5/ //ⓕ 0x86c4
  , /\u24d6/ //ⓖ 0x86c5
  , /\u24d7/ //ⓗ 0x86c6
  , /\u24d8/ //ⓘ 0x86c7
  , /\u24d9/ //ⓙ 0x86c8
  , /\u24da/ //ⓚ 0x86c9
  , /\u24db/ //ⓛ 0x86ca
  , /\u24dc/ //ⓜ 0x86cb
  , /\u24dd/ //ⓝ 0x86cc
  , /\u24de/ //ⓞ 0x86cd
  , /\u24df/ //ⓟ 0x86ce
  , /\u24e0/ //ⓠ 0x86cf
  , /\u24e1/ //ⓡ 0x86d0
  , /\u24e2/ //ⓢ 0x86d1
  , /\u24e3/ //ⓣ 0x86d2
  , /\u24e4/ //ⓤ 0x86d3
  , /\u24e5/ //ⓥ 0x86d4
  , /\u24e6/ //ⓦ 0x86d5
  , /\u24e7/ //ⓧ 0x86d6
  , /\u24e8/ //ⓨ 0x86d7
  , /\u24e9/ //ⓩ 0x86d8
  , /\u32d0/ //㋐ 0x86d9
  , /\u32d1/ //㋑ 0x86da
  , /\u32d2/ //㋒ 0x86db
  , /\u32d3/ //㋓ 0x86dc
  , /\u32d4/ //㋔ 0x86dd
  , /\u32d5/ //㋕ 0x86de
  , /\u32d6/ //㋖ 0x86df
  , /\u32d7/ //㋗ 0x86e0
  , /\u32d8/ //㋘ 0x86e1
  , /\u32d9/ //㋙ 0x86e2
  , /\u32da/ //㋚ 0x86e3
  , /\u32db/ //㋛ 0x86e4
  , /\u32dc/ //㋜ 0x86e5
  , /\u32dd/ //㋝ 0x86e6
  , /\u32de/ //㋞ 0x86e7
  , /\u32df/ //㋟ 0x86e8
  , /\u32e0/ //㋠ 0x86e9
  , /\u32e1/ //㋡ 0x86ea
  , /\u32e2/ //㋢ 0x86eb
  , /\u32e3/ //㋣ 0x86ec
  , /\u32fa/ //㋺ 0x86ed
  , /\u32e9/ //㋩ 0x86ee
  , /\u32e5/ //㋥ 0x86ef
  , /\u32ed/ //㋭ 0x86f0
  , /\u32ec/ //㋬ 0x86f1
  , /\u2051/ //⁑ 0x86fb
  , /\u2042/ //⁂ 0x86fc
  , /\u2460/ //① 0x8740
  , /\u2461/ //② 0x8741
  , /\u2462/ //③ 0x8742
  , /\u2463/ //④ 0x8743
  , /\u2464/ //⑤ 0x8744
  , /\u2465/ //⑥ 0x8745
  , /\u2466/ //⑦ 0x8746
  , /\u2467/ //⑧ 0x8747
  , /\u2468/ //⑨ 0x8748
  , /\u2469/ //⑩ 0x8749
  , /\u246a/ //⑪ 0x874a
  , /\u246b/ //⑫ 0x874b
  , /\u246c/ //⑬ 0x874c
  , /\u246d/ //⑭ 0x874d
  , /\u246e/ //⑮ 0x874e
  , /\u246f/ //⑯ 0x874f
  , /\u2470/ //⑰ 0x8750
  , /\u2471/ //⑱ 0x8751
  , /\u2472/ //⑲ 0x8752
  , /\u2473/ //⑳ 0x8753
  , /\u2160/ //Ⅰ 0x8754
  , /\u2161/ //Ⅱ 0x8755
  , /\u2162/ //Ⅲ 0x8756
  , /\u2163/ //Ⅳ 0x8757
  , /\u2164/ //Ⅴ 0x8758
  , /\u2165/ //Ⅵ 0x8759
  , /\u2166/ //Ⅶ 0x875a
  , /\u2167/ //Ⅷ 0x875b
  , /\u2168/ //Ⅸ 0x875c
  , /\u2169/ //Ⅹ 0x875d
  , /\u216a/ //Ⅺ 0x875e
  , /\u3349/ //㍉ 0x875f
  , /\u3314/ //㌔ 0x8760
  , /\u3322/ //㌢ 0x8761
  , /\u334d/ //㍍ 0x8762
  , /\u3318/ //㌘ 0x8763
  , /\u3327/ //㌧ 0x8764
  , /\u3303/ //㌃ 0x8765
  , /\u3336/ //㌶ 0x8766
  , /\u3351/ //㍑ 0x8767
  , /\u3357/ //㍗ 0x8768
  , /\u330d/ //㌍ 0x8769
  , /\u3326/ //㌦ 0x876a
  , /\u3323/ //㌣ 0x876b
  , /\u332b/ //㌫ 0x876c
  , /\u334a/ //㍊ 0x876d
  , /\u333b/ //㌻ 0x876e
  , /\u339c/ //㎜ 0x876f
  , /\u339d/ //㎝ 0x8770
  , /\u339e/ //㎞ 0x8771
  , /\u338e/ //㎎ 0x8772
  , /\u338f/ //㎏ 0x8773
  , /\u33c4/ //㏄ 0x8774
  , /\u33a1/ //㎡ 0x8775
  , /\u216b/ //Ⅻ 0x8776
  , /\u337b/ //㍻ 0x877e
  , /\u301d/ //〝 0x8780
  , /\u301f/ //〟 0x8781
  , /\u2116/ //№ 0x8782
  , /\u33cd/ //㏍ 0x8783
  , /\u2121/ //℡ 0x8784
  , /\u32a4/ //㊤ 0x8785
  , /\u32a5/ //㊥ 0x8786
  , /\u32a6/ //㊦ 0x8787
  , /\u32a7/ //㊧ 0x8788
  , /\u32a8/ //㊨ 0x8789
  , /\u3231/ //㈱ 0x878a
  , /\u3232/ //㈲ 0x878b
  , /\u3239/ //㈹ 0x878c
  , /\u337e/ //㍾ 0x878d
  , /\u337d/ //㍽ 0x878e
  , /\u337c/ //㍼ 0x878f
  , /\u222e/ //∮ 0x8793
  , /\u221f/ //∟ 0x8798
  , /\u22bf/ //⊿ 0x8799
  , /\u2756/ //❖ 0x879d
  , /\u261e/ //☞ 0x879e
  , /\u4FF1/ // 俱 0x879F
  // , /\u2000B/ // 𠀋 0x87A0
  , /\ud840\udc0b/ // 𠀋 0x87A0
  , /\u3402/ // 㐂 0x87A1
  , /\u4E28/ // 丨 0x87A2
  , /\u4E2F/ // 丯 0x87A3
  , /\u4E30/ // 丰 0x87A4
  , /\u4E8D/ // 亍 0x87A5
  , /\u4EE1/ // 仡 0x87A6
  , /\u4EFD/ // 份 0x87A7
  , /\u4EFF/ // 仿 0x87A8
  , /\u4F03/ // 伃 0x87A9
  , /\u4F0B/ // 伋 0x87AA
  , /\u4F60/ // 你 0x87AB
  , /\u4F48/ // 佈 0x87AC
  , /\u4F49/ // 佉 0x87AD
  , /\u4F56/ // 佖 0x87AE
  , /\u4F5F/ // 佟 0x87AF
  , /\u4F6A/ // 佪 0x87B0
  , /\u4F6C/ // 佬 0x87B1
  , /\u4F7E/ // 佾 0x87B2
  , /\u4F8A/ // 侊 0x87B3
  , /\u4F94/ // 侔 0x87B4
  , /\u4F97/ // 侗 0x87B5
  , /\uFA30/ // 侮 0x87B6
  , /\u4FC9/ // 俉 0x87B7
  , /\u4FE0/ // 俠 0x87B8
  , /\u5001/ // 倁 0x87B9
  , /\u5002/ // 倂 0x87BA
  , /\u500E/ // 倎 0x87BB
  , /\u5018/ // 倘 0x87BC
  , /\u5027/ // 倧 0x87BD
  , /\u502E/ // 倮 0x87BE
  , /\u5040/ // 偀 0x87BF
  , /\u503B/ // 倻 0x87C0
  , /\u5041/ // 偁 0x87C1
  , /\u5094/ // 傔 0x87C2
  , /\u50CC/ // 僌 0x87C3
  , /\u50F2/ // 僲 0x87C4
  , /\u50D0/ // 僐 0x87C5
  , /\u50E6/ // 僦 0x87C6
  , /\uFA31/ // 僧 0x87C7
  , /\u5106/ // 儆 0x87C8
  , /\u5103/ // 儃 0x87C9
  , /\u510B/ // 儋 0x87CA
  , /\u511E/ // 儞 0x87CB
  , /\u5135/ // 儵 0x87CC
  , /\u514A/ // 兊 0x87CD
  , /\uFA32/ // 免 0x87CE
  , /\u5155/ // 兕 0x87CF
  , /\u5157/ // 兗 0x87D0
  , /\u34B5/ // 㒵 0x87D1
  , /\u519D/ // 冝 0x87D2
  , /\u51C3/ // 凃 0x87D3
  , /\u51CA/ // 凊 0x87D4
  , /\u51DE/ // 凞 0x87D5
  , /\u51E2/ // 凢 0x87D6
  , /\u51EE/ // 凮 0x87D7
  , /\u5201/ // 刁 0x87D8
  , /\u34DB/ // 㓛 0x87D9
  , /\u5213/ // 刓 0x87DA
  , /\u5215/ // 刕 0x87DB
  , /\u5249/ // 剉 0x87DC
  , /\u5257/ // 剗 0x87DD
  , /\u5261/ // 剡 0x87DE
  , /\u5293/ // 劓 0x87DF
  , /\u52C8/ // 勈 0x87E0
  , /\uFA33/ // 勉 0x87E1
  , /\u52CC/ // 勌 0x87E2
  , /\u52D0/ // 勐 0x87E3
  , /\u52D6/ // 勖 0x87E4
  , /\u52DB/ // 勛 0x87E5
  , /\uFA34/ // 勤 0x87E6
  , /\u52F0/ // 勰 0x87E7
  , /\u52FB/ // 勻 0x87E8
  , /\u5300/ // 匀 0x87E9
  , /\u5307/ // 匇 0x87EA
  , /\u531C/ // 匜 0x87EB
  , /\uFA35/ // 卑 0x87EC
  , /\u5361/ // 卡 0x87ED
  , /\u5363/ // 卣 0x87EE
  , /\u537D/ // 卽 0x87EF
  , /\u5393/ // 厓 0x87F0
  , /\u539D/ // 厝 0x87F1
  , /\u53B2/ // 厲 0x87F2
  , /\u5412/ // 吒 0x87F3
  , /\u5427/ // 吧 0x87F4
  , /\u544D/ // 呍 0x87F5
  , /\u549C/ // 咜 0x87F6
  , /\u546B/ // 呫 0x87F7
  , /\u5474/ // 呴 0x87F8
  , /\u547F/ // 呿 0x87F9
  , /\u5488/ // 咈 0x87FA
  , /\u5496/ // 咖 0x87FB
  , /\u54A1/ // 咡 0x87FC
  , /\u54A9/ // 咩 0x8840
  , /\u54C6/ // 哆 0x8841
  , /\u54FF/ // 哿 0x8842
  , /\u550E/ // 唎 0x8843
  , /\u552B/ // 唫 0x8844
  , /\u5535/ // 唵 0x8845
  , /\u5550/ // 啐 0x8846
  , /\u555E/ // 啞 0x8847
  , /\u5581/ // 喁 0x8848
  , /\u5586/ // 喆 0x8849
  , /\u558E/ // 喎 0x884A
  , /\uFA36/ // 喝 0x884B
  , /\u55AD/ // 喭 0x884C
  , /\u55CE/ // 嗎 0x884D
  , /\uFA37/ // 嘆 0x884E
  , /\u5608/ // 嘈 0x884F
  , /\u560E/ // 嘎 0x8850
  , /\u563B/ // 嘻 0x8851
  , /\u5649/ // 噉 0x8852
  , /\u5676/ // 噶 0x8853
  , /\u5666/ // 噦 0x8854
  , /\uFA38/ // 器 0x8855
  , /\u566F/ // 噯 0x8856
  , /\u5671/ // 噱 0x8857
  , /\u5672/ // 噲 0x8858
  , /\u5699/ // 嚙 0x8859
  , /\u569E/ // 嚞 0x885A
  , /\u56A9/ // 嚩 0x885B
  , /\u56AC/ // 嚬 0x885C
  , /\u56B3/ // 嚳 0x885D
  , /\u56C9/ // 囉 0x885E
  , /\u56CA/ // 囊 0x885F
  , /\u570A/ // 圊 0x8860
  // , /\u2123D/ // 𡈽 0x8861
  , /\ud844\ude3d/ // 𡈽 0x8861
  , /\u5721/ // 圡 0x8862
  , /\u572F/ // 圯 0x8863
  , /\u5733/ // 圳 0x8864
  , /\u5734/ // 圴 0x8865
  , /\u5770/ // 坰 0x8866
  , /\u5777/ // 坷 0x8867
  , /\u577C/ // 坼 0x8868
  , /\u579C/ // 垜 0x8869
  , /\uFA0F/ // 﨏 0x886A
  // , /\u2131B/ // 𡌛 0x886B
  , /\ud844\udf1b/ // 𡌛 0x886B
  , /\u57B8/ // 垸 0x886C
  , /\u57C7/ // 埇 0x886D
  , /\u57C8/ // 埈 0x886E
  , /\u57CF/ // 埏 0x886F
  , /\u57E4/ // 埤 0x8870
  , /\u57ED/ // 埭 0x8871
  , /\u57F5/ // 埵 0x8872
  , /\u57F6/ // 埶 0x8873
  , /\u57FF/ // 埿 0x8874
  , /\u5809/ // 堉 0x8875
  , /\uFA10/ // 塚 0x8876
  , /\u5861/ // 塡 0x8877
  , /\u5864/ // 塤 0x8878
  , /\uFA39/ // 塀 0x8879
  , /\u587C/ // 塼 0x887A
  , /\u5889/ // 墉 0x887B
  , /\u589E/ // 增 0x887C
  , /\uFA3A/ // 墨 0x887D
  , /\u58A9/ // 墩 0x887E
  // , /\u2146E/ // 𡑮 0x8880
  , /\ud845\udc6e/ // 𡑮 0x8880
  , /\u58D2/ // 壒 0x8881
  , /\u58CE/ // 壎 0x8882
  , /\u58D4/ // 壔 0x8883
  , /\u58DA/ // 壚 0x8884
  , /\u58E0/ // 壠 0x8885
  , /\u58E9/ // 壩 0x8886
  , /\u590C/ // 夌 0x8887
  , /\u8641/ // 虁 0x8888
  , /\u595D/ // 奝 0x8889
  , /\u596D/ // 奭 0x888A
  , /\u598B/ // 妋 0x888B
  , /\u5992/ // 妒 0x888C
  , /\u59A4/ // 妤 0x888D
  , /\u59C3/ // 姃 0x888E
  , /\u59D2/ // 姒 0x888F
  , /\u59DD/ // 姝 0x8890
  , /\u5A13/ // 娓 0x8891
  , /\u5A23/ // 娣 0x8892
  , /\u5A67/ // 婧 0x8893
  , /\u5A6D/ // 婭 0x8894
  , /\u5A77/ // 婷 0x8895
  , /\u5A7E/ // 婾 0x8896
  , /\u5A84/ // 媄 0x8897
  , /\u5A9E/ // 媞 0x8898
  , /\u5AA7/ // 媧 0x8899
  , /\u5AC4/ // 嫄 0x889A
  // , /\u218BD/ // 𡢽 0x889B
  , /\ud846\udcbd/ // 𡢽 0x889B
  , /\u5B19/ // 嬙 0x889C
  , /\u5B25/ // 嬥 0x889D
  , /\u525D/ // 剝 0x889E
  // 0xEAA6-0xFCFC
  , /\u5DE2/ // ęĶ 0xEAA6
  , /\u5E14/ // ę§ 0xEAA7
  , /\u5E18/ // ęĻ 0xEAA8
  , /\u5E58/ // ęĐ 0xEAA9
  , /\u5E5E/ // ęŠ 0xEAAA
  , /\u5EBE/ // ęŦ 0xEAAB
  , /\uF928/ // ęŽ 0xEAAC
  , /\u5ECB/ // ę­ 0xEAAD
  , /\u5EF9/ // ęŪ 0xEAAE
  , /\u5F00/ // ęŊ 0xEAAF
  , /\u5F02/ // ę° 0xEAB0
  , /\u5F07/ // ęą 0xEAB1
  , /\u5F1D/ // ęē 0xEAB2
  , /\u5F23/ // ęģ 0xEAB3
  , /\u5F34/ // ęī 0xEAB4
  , /\u5F36/ // ęĩ 0xEAB5
  , /\u5F3D/ // ęķ 0xEAB6
  , /\u5F40/ // ę· 0xEAB7
  , /\u5F45/ // ęļ 0xEAB8
  , /\u5F54/ // ęđ 0xEAB9
  , /\u5F58/ // ęš 0xEABA
  , /\u5F64/ // ęŧ 0xEABB
  , /\u5F67/ // ęž 0xEABC
  , /\u5F7D/ // ę― 0xEABD
  , /\u5F89/ // ęū 0xEABE
  , /\u5F9C/ // ęŋ 0xEABF
  , /\u5FA7/ // ęĀ 0xEAC0
  , /\u5FAF/ // ęÁ 0xEAC1
  , /\u5FB5/ // ęÂ 0xEAC2
  , /\u5FB7/ // ęÃ 0xEAC3
  , /\u5FC9/ // ęÄ 0xEAC4
  , /\u5FDE/ // ęÅ 0xEAC5
  , /\u5FE1/ // ęÆ 0xEAC6
  , /\u5FE9/ // ęĮ 0xEAC7
  , /\u600D/ // ęČ 0xEAC8
  , /\u6014/ // ęÉ 0xEAC9
  , /\u6018/ // ęĘ 0xEACA
  , /\u6033/ // ęË 0xEACB
  , /\u6035/ // ęĖ 0xEACC
  , /\u6047/ // ęÍ 0xEACD
  , /\uFA3D/ // ęÎ 0xEACE
  , /\u609D/ // ęÏ 0xEACF
  , /\u609E/ // ęÐ 0xEAD0
  , /\u60CB/ // ęŅ 0xEAD1
  , /\u60D4/ // ęŌ 0xEAD2
  , /\u60D5/ // ęÓ 0xEAD3
  , /\u60DD/ // ęÔ 0xEAD4
  , /\u60F8/ // ęÕ 0xEAD5
  , /\u611C/ // ęÖ 0xEAD6
  , /\u612B/ // ęŨ 0xEAD7
  , /\u6130/ // ęØ 0xEAD8
  , /\u6137/ // ęŲ 0xEAD9
  , /\uFA3E/ // ęÚ 0xEADA
  , /\u618D/ // ęÛ 0xEADB
  , /\uFA3F/ // ęÜ 0xEADC
  , /\u61BC/ // ęÝ 0xEADD
  , /\u61B9/ // ęÞ 0xEADE
  , /\uFA40/ // ęß 0xEADF
  , /\u6222/ // ęā 0xEAE0
  , /\u623E/ // ęá 0xEAE1
  , /\u6243/ // ęâ 0xEAE2
  , /\u6256/ // ęã 0xEAE3
  , /\u625A/ // ęä 0xEAE4
  , /\u626F/ // ęå 0xEAE5
  , /\u6285/ // ęæ 0xEAE6
  , /\u62C4/ // ęį 0xEAE7
  , /\u62D6/ // ęč 0xEAE8
  , /\u62FC/ // ęé 0xEAE9
  , /\u630A/ // ęę 0xEAEA
  , /\u6318/ // ęë 0xEAEB
  , /\u6339/ // ęė 0xEAEC
  , /\u6343/ // ęí 0xEAED
  , /\u6365/ // ęî 0xEAEE
  , /\u637C/ // ęï 0xEAEF
  , /\u63E5/ // ęð 0xEAF0
  , /\u63ED/ // ęņ 0xEAF1
  , /\u63F5/ // ęō 0xEAF2
  , /\u6410/ // ęó 0xEAF3
  , /\u6414/ // ęô 0xEAF4
  , /\u6422/ // ęõ 0xEAF5
  , /\u6479/ // ęö 0xEAF6
  , /\u6451/ // ęũ 0xEAF7
  , /\u6460/ // ęø 0xEAF8
  , /\u646D/ // ęų 0xEAF9
  , /\u64CE/ // ęú 0xEAFA
  , /\u64BE/ // ęû 0xEAFB
  , /\u64BF/ // ęü 0xEAFC
  , /\u64C4/ // ë@ 0xEB40
  , /\u64CA/ // ëA 0xEB41
  , /\u64D0/ // ëB 0xEB42
  , /\u64F7/ // ëC 0xEB43
  , /\u64FB/ // ëD 0xEB44
  , /\u6522/ // ëE 0xEB45
  , /\u6529/ // ëF 0xEB46
  , /\uFA41/ // ëG 0xEB47
  , /\u6567/ // ëH 0xEB48
  , /\u659D/ // ëI 0xEB49
  , /\uFA42/ // ëJ 0xEB4A
  , /\u6600/ // ëK 0xEB4B
  , /\u6609/ // ëL 0xEB4C
  , /\u6615/ // ëM 0xEB4D
  , /\u661E/ // ëN 0xEB4E
  , /\u663A/ // ëO 0xEB4F
  , /\u6622/ // ëP 0xEB50
  , /\u6624/ // ëQ 0xEB51
  , /\u662B/ // ëR 0xEB52
  , /\u6630/ // ëS 0xEB53
  , /\u6631/ // ëT 0xEB54
  , /\u6633/ // ëU 0xEB55
  , /\u66FB/ // ëV 0xEB56
  , /\u6648/ // ëW 0xEB57
  , /\u664C/ // ëX 0xEB58
  // , /\u231C4/ // ëY 0xEB59
  , /\ud84c\uddc4/ // ëY 0xEB59
  , /\u6659/ // ëZ 0xEB5A
  , /\u665A/ // ë[ 0xEB5B
  , /\u6661/ // ë\ 0xEB5C
  , /\u6665/ // ë] 0xEB5D
  , /\u6673/ // ë^ 0xEB5E
  , /\u6677/ // ë_ 0xEB5F
  , /\u6678/ // ë` 0xEB60
  , /\u668D/ // ëa 0xEB61
  , /\uFA43/ // ëb 0xEB62
  , /\u66A0/ // ëc 0xEB63
  , /\u66B2/ // ëd 0xEB64
  , /\u66BB/ // ëe 0xEB65
  , /\u66C6/ // ëf 0xEB66
  , /\u66C8/ // ëg 0xEB67
  , /\u3B22/ // ëh 0xEB68
  , /\u66DB/ // ëi 0xEB69
  , /\u66E8/ // ëj 0xEB6A
  , /\u66FA/ // ëk 0xEB6B
  , /\u6713/ // ël 0xEB6C
  , /\uF929/ // ëm 0xEB6D
  , /\u6733/ // ën 0xEB6E
  , /\u6766/ // ëo 0xEB6F
  , /\u6747/ // ëp 0xEB70
  , /\u6748/ // ëq 0xEB71
  , /\u677B/ // ër 0xEB72
  , /\u6781/ // ës 0xEB73
  , /\u6793/ // ët 0xEB74
  , /\u6798/ // ëu 0xEB75
  , /\u679B/ // ëv 0xEB76
  , /\u67BB/ // ëw 0xEB77
  , /\u67F9/ // ëx 0xEB78
  , /\u67C0/ // ëy 0xEB79
  , /\u67D7/ // ëz 0xEB7A
  , /\u67FC/ // ë{ 0xEB7B
  , /\u6801/ // ë| 0xEB7C
  , /\u6852/ // ë} 0xEB7D
  , /\u681D/ // ë~ 0xEB7E
  , /\u682C/ // ë 0xEB80
  , /\u6831/ // ë 0xEB81
  , /\u685B/ // ë 0xEB82
  , /\u6872/ // ë 0xEB83
  , /\u6875/ // ë 0xEB84
  , /\uFA44/ // ë 0xEB85
  , /\u68A3/ // ë 0xEB86
  , /\u68A5/ // ë 0xEB87
  , /\u68B2/ // ë 0xEB88
  , /\u68C8/ // ë 0xEB89
  , /\u68D0/ // ë 0xEB8A
  , /\u68E8/ // ë 0xEB8B
  , /\u68ED/ // ë 0xEB8C
  , /\u68F0/ // ë 0xEB8D
  , /\u68F1/ // ë 0xEB8E
  , /\u68FC/ // ë 0xEB8F
  , /\u690A/ // ë 0xEB90
  , /\u6949/ // ë 0xEB91
  // , /\u235C4/ // ë 0xEB92
  , /\ud84d\uddc4/ // 𣗄 0xEB92
  , /\u6935/ // ë 0xEB93
  , /\u6942/ // ë 0xEB94
  , /\u6957/ // ë 0xEB95
  , /\u6963/ // ë 0xEB96
  , /\u6964/ // ë 0xEB97
  , /\u6968/ // ë 0xEB98
  , /\u6980/ // ë 0xEB99
  , /\uFA14/ // ë 0xEB9A
  , /\u69A5/ // ë 0xEB9B
  , /\u69AD/ // ë 0xEB9C
  , /\u69CF/ // ë 0xEB9D
  , /\u3BB6/ // ë 0xEB9E
  , /\u3BC3/ // ë 0xEB9F
  , /\u69E2/ // ë  0xEBA0
  , /\u69E9/ // ëĄ 0xEBA1
  , /\u69EA/ // ëĒ 0xEBA2
  , /\u69F5/ // ëĢ 0xEBA3
  , /\u69F6/ // ëĪ 0xEBA4
  , /\u6A0F/ // ëĨ 0xEBA5
  , /\u6A15/ // ëĶ 0xEBA6
  // , /\u2373F/ // ë§ 0xEBA7
  , /\ud84d\udf3f/ // ë§ 0xEBA7
  , /\u6A3B/ // ëĻ 0xEBA8
  , /\u6A3E/ // ëĐ 0xEBA9
  , /\u6A45/ // ëŠ 0xEBAA
  , /\u6A50/ // ëŦ 0xEBAB
  , /\u6A56/ // ëŽ 0xEBAC
  , /\u6A5B/ // ë­ 0xEBAD
  , /\u6A6B/ // ëŪ 0xEBAE
  , /\u6A73/ // ëŊ 0xEBAF
  // , /\u23763/ // ë° 0xEBB0
  , /\ud84d\udf63/ // ë° 0xEBB0
  , /\u6A89/ // ëą 0xEBB1
  , /\u6A94/ // ëē 0xEBB2
  , /\u6A9D/ // ëģ 0xEBB3
  , /\u6A9E/ // ëī 0xEBB4
  , /\u6AA5/ // ëĩ 0xEBB5
  , /\u6AE4/ // ëķ 0xEBB6
  , /\u6AE7/ // ë· 0xEBB7
  , /\u3C0F/ // ëļ 0xEBB8
  , /\uF91D/ // ëđ 0xEBB9
  , /\u6B1B/ // ëš 0xEBBA
  , /\u6B1E/ // ëŧ 0xEBBB
  , /\u6B2C/ // ëž 0xEBBC
  , /\u6B35/ // ë― 0xEBBD
  , /\u6B46/ // ëū 0xEBBE
  , /\u6B56/ // ëŋ 0xEBBF
  , /\u6B60/ // ëĀ 0xEBC0
  , /\u6B65/ // ëÁ 0xEBC1
  , /\u6B67/ // ëÂ 0xEBC2
  , /\u6B77/ // ëÃ 0xEBC3
  , /\u6B82/ // ëÄ 0xEBC4
  , /\u6BA9/ // ëÅ 0xEBC5
  , /\u6BAD/ // ëÆ 0xEBC6
  , /\uF970/ // ëĮ 0xEBC7
  , /\u6BCF/ // ëČ 0xEBC8
  , /\u6BD6/ // ëÉ 0xEBC9
  , /\u6BD7/ // ëĘ 0xEBCA
  , /\u6BFF/ // ëË 0xEBCB
  , /\u6C05/ // ëĖ 0xEBCC
  , /\u6C10/ // ëÍ 0xEBCD
  , /\u6C33/ // ëÎ 0xEBCE
  , /\u6C59/ // ëÏ 0xEBCF
  , /\u6C5C/ // ëÐ 0xEBD0
  , /\u6CAA/ // ëŅ 0xEBD1
  , /\u6C74/ // ëŌ 0xEBD2
  , /\u6C76/ // ëÓ 0xEBD3
  , /\u6C85/ // ëÔ 0xEBD4
  , /\u6C86/ // ëÕ 0xEBD5
  , /\u6C98/ // ëÖ 0xEBD6
  , /\u6C9C/ // ëŨ 0xEBD7
  , /\u6CFB/ // ëØ 0xEBD8
  , /\u6CC6/ // ëŲ 0xEBD9
  , /\u6CD4/ // ëÚ 0xEBDA
  , /\u6CE0/ // ëÛ 0xEBDB
  , /\u6CEB/ // ëÜ 0xEBDC
  , /\u6CEE/ // ëÝ 0xEBDD
  // , /\u23CFE/ // ëÞ 0xEBDE
  , /\ud84f\udcfe/ // ëÞ 0xEBDE
  , /\u6D04/ // ëß 0xEBDF
  , /\u6D0E/ // ëā 0xEBE0
  , /\u6D2E/ // ëá 0xEBE1
  , /\u6D31/ // ëâ 0xEBE2
  , /\u6D39/ // ëã 0xEBE3
  , /\u6D3F/ // ëä 0xEBE4
  , /\u6D58/ // ëå 0xEBE5
  , /\u6D65/ // ëæ 0xEBE6
  , /\uFA45/ // ëį 0xEBE7
  , /\u6D82/ // ëč 0xEBE8
  , /\u6D87/ // ëé 0xEBE9
  , /\u6D89/ // ëę 0xEBEA
  , /\u6D94/ // ëë 0xEBEB
  , /\u6DAA/ // ëė 0xEBEC
  , /\u6DAC/ // ëí 0xEBED
  , /\u6DBF/ // ëî 0xEBEE
  , /\u6DC4/ // ëï 0xEBEF
  , /\u6DD6/ // ëð 0xEBF0
  , /\u6DDA/ // ëņ 0xEBF1
  , /\u6DDB/ // ëō 0xEBF2
  , /\u6DDD/ // ëó 0xEBF3
  , /\u6DFC/ // ëô 0xEBF4
  , /\uFA46/ // ëõ 0xEBF5
  , /\u6E34/ // ëö 0xEBF6
  , /\u6E44/ // ëũ 0xEBF7
  , /\u6E5C/ // ëø 0xEBF8
  , /\u6E5E/ // ëų 0xEBF9
  , /\u6EAB/ // ëú 0xEBFA
  , /\u6EB1/ // ëû 0xEBFB
  , /\u6EC1/ // ëü 0xEBFC
  , /\u6EC7/ // ė@ 0xEC40
  , /\u6ECE/ // ėA 0xEC41
  , /\u6F10/ // ėB 0xEC42
  , /\u6F1A/ // ėC 0xEC43
  , /\uFA47/ // ėD 0xEC44
  , /\u6F2A/ // ėE 0xEC45
  , /\u6F2F/ // ėF 0xEC46
  , /\u6F33/ // ėG 0xEC47
  , /\u6F51/ // ėH 0xEC48
  , /\u6F59/ // ėI 0xEC49
  , /\u6F5E/ // ėJ 0xEC4A
  , /\u6F61/ // ėK 0xEC4B
  , /\u6F62/ // ėL 0xEC4C
  , /\u6F7E/ // ėM 0xEC4D
  , /\u6F88/ // ėN 0xEC4E
  , /\u6F8C/ // ėO 0xEC4F
  , /\u6F8D/ // ėP 0xEC50
  , /\u6F94/ // ėQ 0xEC51
  , /\u6FA0/ // ėR 0xEC52
  , /\u6FA7/ // ėS 0xEC53
  , /\u6FB6/ // ėT 0xEC54
  , /\u6FBC/ // ėU 0xEC55
  , /\u6FC7/ // ėV 0xEC56
  , /\u6FCA/ // ėW 0xEC57
  , /\u6FF9/ // ėX 0xEC58
  , /\u6FF0/ // ėY 0xEC59
  , /\u6FF5/ // ėZ 0xEC5A
  , /\u7005/ // ė[ 0xEC5B
  , /\u7006/ // ė\ 0xEC5C
  , /\u7028/ // ė] 0xEC5D
  , /\u704A/ // ė^ 0xEC5E
  , /\u705D/ // ė_ 0xEC5F
  , /\u705E/ // ė` 0xEC60
  , /\u704E/ // ėa 0xEC61
  , /\u7064/ // ėb 0xEC62
  , /\u7075/ // ėc 0xEC63
  , /\u7085/ // ėd 0xEC64
  , /\u70A4/ // ėe 0xEC65
  , /\u70AB/ // ėf 0xEC66
  , /\u70B7/ // ėg 0xEC67
  , /\u70D4/ // ėh 0xEC68
  , /\u70D8/ // ėi 0xEC69
  , /\u70E4/ // ėj 0xEC6A
  , /\u710F/ // ėk 0xEC6B
  , /\u712B/ // ėl 0xEC6C
  , /\u711E/ // ėm 0xEC6D
  , /\u7120/ // ėn 0xEC6E
  , /\u712E/ // ėo 0xEC6F
  , /\u7130/ // ėp 0xEC70
  , /\u7146/ // ėq 0xEC71
  , /\u7147/ // ėr 0xEC72
  , /\u7151/ // ės 0xEC73
  , /\uFA48/ // ėt 0xEC74
  , /\u7152/ // ėu 0xEC75
  , /\u715C/ // ėv 0xEC76
  , /\u7160/ // ėw 0xEC77
  , /\u7168/ // ėx 0xEC78
  , /\uFA15/ // ėy 0xEC79
  , /\u7185/ // ėz 0xEC7A
  , /\u7187/ // ė{ 0xEC7B
  , /\u7192/ // ė| 0xEC7C
  , /\u71C1/ // ė} 0xEC7D
  , /\u71BA/ // ė~ 0xEC7E
  , /\u71C4/ // ė 0xEC80
  , /\u71FE/ // ė 0xEC81
  , /\u7200/ // ė 0xEC82
  , /\u7215/ // ė 0xEC83
  , /\u7255/ // ė 0xEC84
  , /\u7256/ // ė 0xEC85
  , /\u3E3F/ // ė 0xEC86
  , /\u728D/ // ė 0xEC87
  , /\u729B/ // ė 0xEC88
  , /\u72BE/ // ė 0xEC89
  , /\u72C0/ // ė 0xEC8A
  , /\u72FB/ // ė 0xEC8B
  // , /\u247F1/ // ė 0xEC8C
  , /\ud851\udff1/ // ė 0xEC8C
  , /\u7327/ // ė 0xEC8D
  , /\u7328/ // ė 0xEC8E
  , /\uFA16/ // ė 0xEC8F
  , /\u7350/ // ė 0xEC90
  , /\u7366/ // ė 0xEC91
  , /\u737C/ // ė 0xEC92
  , /\u7395/ // ė 0xEC93
  , /\u739F/ // ė 0xEC94
  , /\u73A0/ // ė 0xEC95
  , /\u73A2/ // ė 0xEC96
  , /\u73A6/ // ė 0xEC97
  , /\u73AB/ // ė 0xEC98
  , /\u73C9/ // ė 0xEC99
  , /\u73CF/ // ė 0xEC9A
  , /\u73D6/ // ė 0xEC9B
  , /\u73D9/ // ė 0xEC9C
  , /\u73E3/ // ė 0xEC9D
  , /\u73E9/ // ė 0xEC9E
  , /\u7407/ // ė 0xEC9F
  , /\u740A/ // ė  0xECA0
  , /\u741A/ // ėĄ 0xECA1
  , /\u741B/ // ėĒ 0xECA2
  , /\uFA4A/ // ėĢ 0xECA3
  , /\u7426/ // ėĪ 0xECA4
  , /\u7428/ // ėĨ 0xECA5
  , /\u742A/ // ėĶ 0xECA6
  , /\u742B/ // ė§ 0xECA7
  , /\u742C/ // ėĻ 0xECA8
  , /\u742E/ // ėĐ 0xECA9
  , /\u742F/ // ėŠ 0xECAA
  , /\u7430/ // ėŦ 0xECAB
  , /\u7444/ // ėŽ 0xECAC
  , /\u7446/ // ė­ 0xECAD
  , /\u7447/ // ėŪ 0xECAE
  , /\u744B/ // ėŊ 0xECAF
  , /\u7457/ // ė° 0xECB0
  , /\u7462/ // ėą 0xECB1
  , /\u746B/ // ėē 0xECB2
  , /\u746D/ // ėģ 0xECB3
  , /\u7486/ // ėī 0xECB4
  , /\u7487/ // ėĩ 0xECB5
  , /\u7489/ // ėķ 0xECB6
  , /\u7498/ // ė· 0xECB7
  , /\u749C/ // ėļ 0xECB8
  , /\u749F/ // ėđ 0xECB9
  , /\u74A3/ // ėš 0xECBA
  , /\u7490/ // ėŧ 0xECBB
  , /\u74A6/ // ėž 0xECBC
  , /\u74A8/ // ė― 0xECBD
  , /\u74A9/ // ėū 0xECBE
  , /\u74B5/ // ėŋ 0xECBF
  , /\u74BF/ // ėĀ 0xECC0
  , /\u74C8/ // ėÁ 0xECC1
  , /\u74C9/ // ėÂ 0xECC2
  , /\u74DA/ // ėÃ 0xECC3
  , /\u74FF/ // ėÄ 0xECC4
  , /\u7501/ // ėÅ 0xECC5
  , /\u7517/ // ėÆ 0xECC6
  , /\u752F/ // ėĮ 0xECC7
  , /\u756F/ // ėČ 0xECC8
  , /\u7579/ // ėÉ 0xECC9
  , /\u7592/ // ėĘ 0xECCA
  , /\u3F72/ // ėË 0xECCB
  , /\u75CE/ // ėĖ 0xECCC
  , /\u75E4/ // ėÍ 0xECCD
  , /\u7600/ // ėÎ 0xECCE
  , /\u7602/ // ėÏ 0xECCF
  , /\u7608/ // ėÐ 0xECD0
  , /\u7615/ // ėŅ 0xECD1
  , /\u7616/ // ėŌ 0xECD2
  , /\u7619/ // ėÓ 0xECD3
  , /\u761E/ // ėÔ 0xECD4
  , /\u762D/ // ėÕ 0xECD5
  , /\u7635/ // ėÖ 0xECD6
  , /\u7643/ // ėŨ 0xECD7
  , /\u764B/ // ėØ 0xECD8
  , /\u7664/ // ėŲ 0xECD9
  , /\u7665/ // ėÚ 0xECDA
  , /\u766D/ // ėÛ 0xECDB
  , /\u766F/ // ėÜ 0xECDC
  , /\u7671/ // ėÝ 0xECDD
  , /\u7681/ // ėÞ 0xECDE
  , /\u769B/ // ėß 0xECDF
  , /\u769D/ // ėā 0xECE0
  , /\u769E/ // ėá 0xECE1
  , /\u76A6/ // ėâ 0xECE2
  , /\u76AA/ // ėã 0xECE3
  , /\u76B6/ // ėä 0xECE4
  , /\u76C5/ // ėå 0xECE5
  , /\u76CC/ // ėæ 0xECE6
  , /\u76CE/ // ėį 0xECE7
  , /\u76D4/ // ėč 0xECE8
  , /\u76E6/ // ėé 0xECE9
  , /\u76F1/ // ėę 0xECEA
  , /\u76FC/ // ėë 0xECEB
  , /\u770A/ // ėė 0xECEC
  , /\u7719/ // ėí 0xECED
  , /\u7734/ // ėî 0xECEE
  , /\u7736/ // ėï 0xECEF
  , /\u7746/ // ėð 0xECF0
  , /\u774D/ // ėņ 0xECF1
  , /\u774E/ // ėō 0xECF2
  , /\u775C/ // ėó 0xECF3
  , /\u775F/ // ėô 0xECF4
  , /\u7762/ // ėõ 0xECF5
  , /\u777A/ // ėö 0xECF6
  , /\u7780/ // ėũ 0xECF7
  , /\u7794/ // ėø 0xECF8
  , /\u77AA/ // ėų 0xECF9
  , /\u77E0/ // ėú 0xECFA
  , /\u782D/ // ėû 0xECFB
  // , /\u2548E/ // ėü 0xECFC
  , /\ud855\udc8e/ // ėü 0xECFC
  , /\u7843/ // í@ 0xED40
  , /\u784E/ // íA 0xED41
  , /\u784F/ // íB 0xED42
  , /\u7851/ // íC 0xED43
  , /\u7868/ // íD 0xED44
  , /\u786E/ // íE 0xED45
  , /\uFA4B/ // íF 0xED46
  , /\u78B0/ // íG 0xED47
  // , /\u2550E/ // íH 0xED48
  , /\ud855\udd0e/ // íH 0xED48
  , /\u78AD/ // íI 0xED49
  , /\u78E4/ // íJ 0xED4A
  , /\u78F2/ // íK 0xED4B
  , /\u7900/ // íL 0xED4C
  , /\u78F7/ // íM 0xED4D
  , /\u791C/ // íN 0xED4E
  , /\u792E/ // íO 0xED4F
  , /\u7931/ // íP 0xED50
  , /\u7934/ // íQ 0xED51
  , /\uFA4C/ // íR 0xED52
  , /\uFA4D/ // íS 0xED53
  , /\u7945/ // íT 0xED54
  , /\u7946/ // íU 0xED55
  , /\uFA4E/ // íV 0xED56
  , /\uFA4F/ // íW 0xED57
  , /\uFA50/ // íX 0xED58
  , /\u795C/ // íY 0xED59
  , /\uFA51/ // íZ 0xED5A
  , /\uFA19/ // í[ 0xED5B
  , /\uFA1A/ // í\ 0xED5C
  , /\u7979/ // í] 0xED5D
  , /\uFA52/ // í^ 0xED5E
  , /\uFA53/ // í_ 0xED5F
  , /\uFA1B/ // í` 0xED60
  , /\u7998/ // ía 0xED61
  , /\u79B1/ // íb 0xED62
  , /\u79B8/ // íc 0xED63
  , /\u79C8/ // íd 0xED64
  , /\u79CA/ // íe 0xED65
  // , /\u25771/ // íf 0xED66
  , /\ud855\udf71/ // íf 0xED66
  , /\u79D4/ // íg 0xED67
  , /\u79DE/ // íh 0xED68
  , /\u79EB/ // íi 0xED69
  , /\u79ED/ // íj 0xED6A
  , /\u7A03/ // ík 0xED6B
  , /\uFA54/ // íl 0xED6C
  , /\u7A39/ // ím 0xED6D
  , /\u7A5D/ // ín 0xED6E
  , /\u7A6D/ // ío 0xED6F
  , /\uFA55/ // íp 0xED70
  , /\u7A85/ // íq 0xED71
  , /\u7AA0/ // ír 0xED72
  // , /\u259C4/ // ís 0xED73
  , /\ud856\uddc4/ // ís 0xED73
  , /\u7AB3/ // ít 0xED74
  , /\u7ABB/ // íu 0xED75
  , /\u7ACE/ // ív 0xED76
  , /\u7AEB/ // íw 0xED77
  , /\u7AFD/ // íx 0xED78
  , /\u7B12/ // íy 0xED79
  , /\u7B2D/ // íz 0xED7A
  , /\u7B3B/ // í{ 0xED7B
  , /\u7B47/ // í| 0xED7C
  , /\u7B4E/ // í} 0xED7D
  , /\u7B60/ // í~ 0xED7E
  , /\u7B6D/ // í 0xED80
  , /\u7B6F/ // í 0xED81
  , /\u7B72/ // í 0xED82
  , /\u7B9E/ // í 0xED83
  , /\uFA56/ // í 0xED84
  , /\u7BD7/ // í 0xED85
  , /\u7BD9/ // í 0xED86
  , /\u7C01/ // í 0xED87
  , /\u7C31/ // í 0xED88
  , /\u7C1E/ // í 0xED89
  , /\u7C20/ // í 0xED8A
  , /\u7C33/ // í 0xED8B
  , /\u7C36/ // í 0xED8C
  , /\u4264/ // í 0xED8D
  // , /\u25DA1/ // í 0xED8E
  , /\ud857\udda1/ // í 0xED8E
  , /\u7C59/ // í 0xED8F
  , /\u7C6D/ // í 0xED90
  , /\u7C79/ // í 0xED91
  , /\u7C8F/ // í 0xED92
  , /\u7C94/ // í 0xED93
  , /\u7CA0/ // í 0xED94
  , /\u7CBC/ // í 0xED95
  , /\u7CD5/ // í 0xED96
  , /\u7CD9/ // í 0xED97
  , /\u7CDD/ // í 0xED98
  , /\u7D07/ // í 0xED99
  , /\u7D08/ // í 0xED9A
  , /\u7D13/ // í 0xED9B
  , /\u7D1D/ // í 0xED9C
  , /\u7D23/ // í 0xED9D
  , /\u7D31/ // í 0xED9E
  , /\u7D41/ // í 0xED9F
  , /\u7D48/ // í  0xEDA0
  , /\u7D53/ // íĄ 0xEDA1
  , /\u7D5C/ // íĒ 0xEDA2
  , /\u7D7A/ // íĢ 0xEDA3
  , /\u7D83/ // íĪ 0xEDA4
  , /\u7D8B/ // íĨ 0xEDA5
  , /\u7DA0/ // íĶ 0xEDA6
  , /\u7DA6/ // í§ 0xEDA7
  , /\u7DC2/ // íĻ 0xEDA8
  , /\u7DCC/ // íĐ 0xEDA9
  , /\u7DD6/ // íŠ 0xEDAA
  , /\u7DE3/ // íŦ 0xEDAB
  , /\uFA57/ // íŽ 0xEDAC
  , /\u7E28/ // í­ 0xEDAD
  , /\u7E08/ // íŪ 0xEDAE
  , /\u7E11/ // íŊ 0xEDAF
  , /\u7E15/ // í° 0xEDB0
  , /\uFA59/ // íą 0xEDB1
  , /\u7E47/ // íē 0xEDB2
  , /\u7E52/ // íģ 0xEDB3
  , /\u7E61/ // íī 0xEDB4
  , /\u7E8A/ // íĩ 0xEDB5
  , /\u7E8D/ // íķ 0xEDB6
  , /\u7F47/ // í· 0xEDB7
  , /\uFA5A/ // íļ 0xEDB8
  , /\u7F91/ // íđ 0xEDB9
  , /\u7F97/ // íš 0xEDBA
  , /\u7FBF/ // íŧ 0xEDBB
  , /\u7FCE/ // íž 0xEDBC
  , /\u7FDB/ // í― 0xEDBD
  , /\u7FDF/ // íū 0xEDBE
  , /\u7FEC/ // íŋ 0xEDBF
  , /\u7FEE/ // íĀ 0xEDC0
  , /\u7FFA/ // íÁ 0xEDC1
  , /\uFA5B/ // íÂ 0xEDC2
  , /\u8014/ // íÃ 0xEDC3
  , /\u8026/ // íÄ 0xEDC4
  , /\u8035/ // íÅ 0xEDC5
  , /\u8037/ // íÆ 0xEDC6
  , /\u803C/ // íĮ 0xEDC7
  , /\u80CA/ // íČ 0xEDC8
  , /\u80D7/ // íÉ 0xEDC9
  , /\u80E0/ // íĘ 0xEDCA
  , /\u80F3/ // íË 0xEDCB
  , /\u8118/ // íĖ 0xEDCC
  , /\u814A/ // íÍ 0xEDCD
  , /\u8160/ // íÎ 0xEDCE
  , /\u8167/ // íÏ 0xEDCF
  , /\u8168/ // íÐ 0xEDD0
  , /\u816D/ // íŅ 0xEDD1
  , /\u81BB/ // íŌ 0xEDD2
  , /\u81CA/ // íÓ 0xEDD3
  , /\u81CF/ // íÔ 0xEDD4
  , /\u81D7/ // íÕ 0xEDD5
  , /\uFA5C/ // íÖ 0xEDD6
  , /\u4453/ // íŨ 0xEDD7
  , /\u445B/ // íØ 0xEDD8
  , /\u8260/ // íŲ 0xEDD9
  , /\u8274/ // íÚ 0xEDDA
  // , /\u26AFF/ // íÛ 0xEDDB
  , /\ud85a\udeff/ // íÛ 0xEDDB
  , /\u828E/ // íÜ 0xEDDC
  , /\u82A1/ // íÝ 0xEDDD
  , /\u82A3/ // íÞ 0xEDDE
  , /\u82A4/ // íß 0xEDDF
  , /\u82A9/ // íā 0xEDE0
  , /\u82AE/ // íá 0xEDE1
  , /\u82B7/ // íâ 0xEDE2
  , /\u82BE/ // íã 0xEDE3
  , /\u82BF/ // íä 0xEDE4
  , /\u82C6/ // íå 0xEDE5
  , /\u82D5/ // íæ 0xEDE6
  , /\u82FD/ // íį 0xEDE7
  , /\u82FE/ // íč 0xEDE8
  , /\u8300/ // íé 0xEDE9
  , /\u8301/ // íę 0xEDEA
  , /\u8362/ // íë 0xEDEB
  , /\u8322/ // íė 0xEDEC
  , /\u832D/ // íí 0xEDED
  , /\u833A/ // íî 0xEDEE
  , /\u8343/ // íï 0xEDEF
  , /\u8347/ // íð 0xEDF0
  , /\u8351/ // íņ 0xEDF1
  , /\u8355/ // íō 0xEDF2
  , /\u837D/ // íó 0xEDF3
  , /\u8386/ // íô 0xEDF4
  , /\u8392/ // íõ 0xEDF5
  , /\u8398/ // íö 0xEDF6
  , /\u83A7/ // íũ 0xEDF7
  , /\u83A9/ // íø 0xEDF8
  , /\u83BF/ // íų 0xEDF9
  , /\u83C0/ // íú 0xEDFA
  , /\u83C7/ // íû 0xEDFB
  , /\u83CF/ // íü 0xEDFC
  , /\u83D1/ // î@ 0xEE40
  , /\u83E1/ // îA 0xEE41
  , /\u83EA/ // îB 0xEE42
  , /\u8401/ // îC 0xEE43
  , /\u8406/ // îD 0xEE44
  , /\u840A/ // îE 0xEE45
  , /\uFA5F/ // îF 0xEE46
  , /\u8448/ // îG 0xEE47
  , /\u845F/ // îH 0xEE48
  , /\u8470/ // îI 0xEE49
  , /\u8473/ // îJ 0xEE4A
  , /\u8485/ // îK 0xEE4B
  , /\u849E/ // îL 0xEE4C
  , /\u84AF/ // îM 0xEE4D
  , /\u84B4/ // îN 0xEE4E
  , /\u84BA/ // îO 0xEE4F
  , /\u84C0/ // îP 0xEE50
  , /\u84C2/ // îQ 0xEE51
  // , /\u26E40/ // îR 0xEE52
  , /\ud85b\ude40/ // îR 0xEE52
  , /\u8532/ // îS 0xEE53
  , /\u851E/ // îT 0xEE54
  , /\u8523/ // îU 0xEE55
  , /\u852F/ // îV 0xEE56
  , /\u8559/ // îW 0xEE57
  , /\u8564/ // îX 0xEE58
  , /\uFA1F/ // îY 0xEE59
  , /\u85AD/ // îZ 0xEE5A
  , /\u857A/ // î[ 0xEE5B
  , /\u858C/ // î\ 0xEE5C
  , /\u858F/ // î] 0xEE5D
  , /\u85A2/ // î^ 0xEE5E
  , /\u85B0/ // î_ 0xEE5F
  , /\u85CB/ // î` 0xEE60
  , /\u85CE/ // îa 0xEE61
  , /\u85ED/ // îb 0xEE62
  , /\u8612/ // îc 0xEE63
  , /\u85FF/ // îd 0xEE64
  , /\u8604/ // îe 0xEE65
  , /\u8605/ // îf 0xEE66
  , /\u8610/ // îg 0xEE67
  // , /\u270F4/ // îh 0xEE68
  , /\ud85c\udcf4/ // îh 0xEE68
  , /\u8618/ // îi 0xEE69
  , /\u8629/ // îj 0xEE6A
  , /\u8638/ // îk 0xEE6B
  , /\u8657/ // îl 0xEE6C
  , /\u865B/ // îm 0xEE6D
  , /\uF936/ // în 0xEE6E
  , /\u8662/ // îo 0xEE6F
  , /\u459D/ // îp 0xEE70
  , /\u866C/ // îq 0xEE71
  , /\u8675/ // îr 0xEE72
  , /\u8698/ // îs 0xEE73
  , /\u86B8/ // ît 0xEE74
  , /\u86FA/ // îu 0xEE75
  , /\u86FC/ // îv 0xEE76
  , /\u86FD/ // îw 0xEE77
  , /\u870B/ // îx 0xEE78
  , /\u8771/ // îy 0xEE79
  , /\u8787/ // îz 0xEE7A
  , /\u8788/ // î{ 0xEE7B
  , /\u87AC/ // î| 0xEE7C
  , /\u87AD/ // î} 0xEE7D
  , /\u87B5/ // î~ 0xEE7E
  , /\u45EA/ // î 0xEE80
  , /\u87D6/ // î 0xEE81
  , /\u87EC/ // î 0xEE82
  , /\u8806/ // î 0xEE83
  , /\u880A/ // î 0xEE84
  , /\u8810/ // î 0xEE85
  , /\u8814/ // î 0xEE86
  , /\u881F/ // î 0xEE87
  , /\u8898/ // î 0xEE88
  , /\u88AA/ // î 0xEE89
  , /\u88CA/ // î 0xEE8A
  , /\u88CE/ // î 0xEE8B
  // , /\u27684/ // î 0xEE8C
  , /\ud85d\ude84/ // î 0xEE8C
  , /\u88F5/ // î 0xEE8D
  , /\u891C/ // î 0xEE8E
  , /\uFA60/ // î 0xEE8F
  , /\u8918/ // î 0xEE90
  , /\u8919/ // î 0xEE91
  , /\u891A/ // î 0xEE92
  , /\u8927/ // î 0xEE93
  , /\u8930/ // î 0xEE94
  , /\u8932/ // î 0xEE95
  , /\u8939/ // î 0xEE96
  , /\u8940/ // î 0xEE97
  , /\u8994/ // î 0xEE98
  , /\uFA61/ // î 0xEE99
  , /\u89D4/ // î 0xEE9A
  , /\u89E5/ // î 0xEE9B
  , /\u89F6/ // î 0xEE9C
  , /\u8A12/ // î 0xEE9D
  , /\u8A15/ // î 0xEE9E
  , /\u8A22/ // î 0xEE9F
  , /\u8A37/ // î  0xEEA0
  , /\u8A47/ // îĄ 0xEEA1
  , /\u8A4E/ // îĒ 0xEEA2
  , /\u8A5D/ // îĢ 0xEEA3
  , /\u8A61/ // îĪ 0xEEA4
  , /\u8A75/ // îĨ 0xEEA5
  , /\u8A79/ // îĶ 0xEEA6
  , /\u8AA7/ // î§ 0xEEA7
  , /\u8AD0/ // îĻ 0xEEA8
  , /\u8ADF/ // îĐ 0xEEA9
  , /\u8AF4/ // îŠ 0xEEAA
  , /\u8AF6/ // îŦ 0xEEAB
  , /\uFA22/ // îŽ 0xEEAC
  , /\uFA62/ // î­ 0xEEAD
  , /\uFA63/ // îŪ 0xEEAE
  , /\u8B46/ // îŊ 0xEEAF
  , /\u8B54/ // î° 0xEEB0
  , /\u8B59/ // îą 0xEEB1
  , /\u8B69/ // îē 0xEEB2
  , /\u8B9D/ // îģ 0xEEB3
  , /\u8C49/ // îī 0xEEB4
  , /\u8C68/ // îĩ 0xEEB5
  , /\uFA64/ // îķ 0xEEB6
  , /\u8CE1/ // î· 0xEEB7
  , /\u8CF4/ // îļ 0xEEB8
  , /\u8CF8/ // îđ 0xEEB9
  , /\u8CFE/ // îš 0xEEBA
  , /\uFA65/ // îŧ 0xEEBB
  , /\u8D12/ // îž 0xEEBC
  , /\u8D1B/ // î― 0xEEBD
  , /\u8DAF/ // îū 0xEEBE
  , /\u8DCE/ // îŋ 0xEEBF
  , /\u8DD1/ // îĀ 0xEEC0
  , /\u8DD7/ // îÁ 0xEEC1
  , /\u8E20/ // îÂ 0xEEC2
  , /\u8E23/ // îÃ 0xEEC3
  , /\u8E3D/ // îÄ 0xEEC4
  , /\u8E70/ // îÅ 0xEEC5
  , /\u8E7B/ // îÆ 0xEEC6
  // , /\u28277/ // îĮ 0xEEC7
  , /\ud860\ude77/ // îĮ 0xEEC7
  , /\u8EC0/ // îČ 0xEEC8
  , /\u4844/ // îÉ 0xEEC9
  , /\u8EFA/ // îĘ 0xEECA
  , /\u8F1E/ // îË 0xEECB
  , /\u8F2D/ // îĖ 0xEECC
  , /\u8F36/ // îÍ 0xEECD
  , /\u8F54/ // îÎ 0xEECE
  // , /\u283CD/ // îÏ 0xEECF
  , /\ud860\udfcd/ // 𨏍 0xEECF
  , /\u8FA6/ // îÐ 0xEED0
  , /\u8FB5/ // îŅ 0xEED1
  , /\u8FE4/ // îŌ 0xEED2
  , /\u8FE8/ // îÓ 0xEED3
  , /\u8FEE/ // îÔ 0xEED4
  , /\u9008/ // îÕ 0xEED5
  , /\u902D/ // îÖ 0xEED6
  , /\uFA67/ // îŨ 0xEED7
  , /\u9088/ // îØ 0xEED8
  , /\u9095/ // îŲ 0xEED9
  , /\u9097/ // îÚ 0xEEDA
  , /\u9099/ // îÛ 0xEEDB
  , /\u909B/ // îÜ 0xEEDC
  , /\u90A2/ // îÝ 0xEEDD
  , /\u90B3/ // îÞ 0xEEDE
  , /\u90BE/ // îß 0xEEDF
  , /\u90C4/ // îā 0xEEE0
  , /\u90C5/ // îá 0xEEE1
  , /\u90C7/ // îâ 0xEEE2
  , /\u90D7/ // îã 0xEEE3
  , /\u90DD/ // îä 0xEEE4
  , /\u90DE/ // îå 0xEEE5
  , /\u90EF/ // îæ 0xEEE6
  , /\u90F4/ // îį 0xEEE7
  , /\uFA26/ // îč 0xEEE8
  , /\u9114/ // îé 0xEEE9
  , /\u9115/ // îę 0xEEEA
  , /\u9116/ // îë 0xEEEB
  , /\u9122/ // îė 0xEEEC
  , /\u9123/ // îí 0xEEED
  , /\u9127/ // îî 0xEEEE
  , /\u912F/ // îï 0xEEEF
  , /\u9131/ // îð 0xEEF0
  , /\u9134/ // îņ 0xEEF1
  , /\u913D/ // îō 0xEEF2
  , /\u9148/ // îó 0xEEF3
  , /\u915B/ // îô 0xEEF4
  , /\u9183/ // îõ 0xEEF5
  , /\u919E/ // îö 0xEEF6
  , /\u91AC/ // îũ 0xEEF7
  , /\u91B1/ // îø 0xEEF8
  , /\u91BC/ // îų 0xEEF9
  , /\u91D7/ // îú 0xEEFA
  , /\u91FB/ // îû 0xEEFB
  , /\u91E4/ // îü 0xEEFC
  , /\u91E5/ // ï@ 0xEF40
  , /\u91ED/ // ïA 0xEF41
  , /\u91F1/ // ïB 0xEF42
  , /\u9207/ // ïC 0xEF43
  , /\u9210/ // ïD 0xEF44
  , /\u9238/ // ïE 0xEF45
  , /\u9239/ // ïF 0xEF46
  , /\u923A/ // ïG 0xEF47
  , /\u923C/ // ïH 0xEF48
  , /\u9240/ // ïI 0xEF49
  , /\u9243/ // ïJ 0xEF4A
  , /\u924F/ // ïK 0xEF4B
  , /\u9278/ // ïL 0xEF4C
  , /\u9288/ // ïM 0xEF4D
  , /\u92C2/ // ïN 0xEF4E
  , /\u92CB/ // ïO 0xEF4F
  , /\u92CC/ // ïP 0xEF50
  , /\u92D3/ // ïQ 0xEF51
  , /\u92E0/ // ïR 0xEF52
  , /\u92FF/ // ïS 0xEF53
  , /\u9304/ // ïT 0xEF54
  , /\u931F/ // ïU 0xEF55
  , /\u9321/ // ïV 0xEF56
  , /\u9325/ // ïW 0xEF57
  , /\u9348/ // ïX 0xEF58
  , /\u9349/ // ïY 0xEF59
  , /\u934A/ // ïZ 0xEF5A
  , /\u9364/ // ï[ 0xEF5B
  , /\u9365/ // ï\ 0xEF5C
  , /\u936A/ // ï] 0xEF5D
  , /\u9370/ // ï^ 0xEF5E
  , /\u939B/ // ï_ 0xEF5F
  , /\u93A3/ // ï` 0xEF60
  , /\u93BA/ // ïa 0xEF61
  , /\u93C6/ // ïb 0xEF62
  , /\u93DE/ // ïc 0xEF63
  , /\u93DF/ // ïd 0xEF64
  , /\u9404/ // ïe 0xEF65
  , /\u93FD/ // ïf 0xEF66
  , /\u9433/ // ïg 0xEF67
  , /\u944A/ // ïh 0xEF68
  , /\u9463/ // ïi 0xEF69
  , /\u946B/ // ïj 0xEF6A
  , /\u9471/ // ïk 0xEF6B
  , /\u9472/ // ïl 0xEF6C
  , /\u958E/ // ïm 0xEF6D
  , /\u959F/ // ïn 0xEF6E
  , /\u95A6/ // ïo 0xEF6F
  , /\u95A9/ // ïp 0xEF70
  , /\u95AC/ // ïq 0xEF71
  , /\u95B6/ // ïr 0xEF72
  , /\u95BD/ // ïs 0xEF73
  , /\u95CB/ // ït 0xEF74
  , /\u95D0/ // ïu 0xEF75
  , /\u95D3/ // ïv 0xEF76
  , /\u49B0/ // ïw 0xEF77
  , /\u95DA/ // ïx 0xEF78
  , /\u95DE/ // ïy 0xEF79
  , /\u9658/ // ïz 0xEF7A
  , /\u9684/ // ï{ 0xEF7B
  , /\uF9DC/ // ï| 0xEF7C
  , /\u969D/ // ï} 0xEF7D
  , /\u96A4/ // ï~ 0xEF7E
  , /\u96A5/ // ï 0xEF80
  , /\u96D2/ // ï 0xEF81
  , /\u96DE/ // ï 0xEF82
  , /\uFA68/ // ï 0xEF83
  , /\u96E9/ // ï 0xEF84
  , /\u96EF/ // ï 0xEF85
  , /\u9733/ // ï 0xEF86
  , /\u973B/ // ï 0xEF87
  , /\u974D/ // ï 0xEF88
  , /\u974E/ // ï 0xEF89
  , /\u974F/ // ï 0xEF8A
  , /\u975A/ // ï 0xEF8B
  , /\u976E/ // ï 0xEF8C
  , /\u9773/ // ï 0xEF8D
  , /\u9795/ // ï 0xEF8E
  , /\u97AE/ // ï 0xEF8F
  , /\u97BA/ // ï 0xEF90
  , /\u97C1/ // ï 0xEF91
  , /\u97C9/ // ï 0xEF92
  , /\u97DE/ // ï 0xEF93
  , /\u97DB/ // ï 0xEF94
  , /\u97F4/ // ï 0xEF95
  , /\uFA69/ // ï 0xEF96
  , /\u980A/ // ï 0xEF97
  , /\u981E/ // ï 0xEF98
  , /\u982B/ // ï 0xEF99
  , /\u9830/ // ï 0xEF9A
  , /\uFA6A/ // ï 0xEF9B
  , /\u9852/ // ï 0xEF9C
  , /\u9853/ // ï 0xEF9D
  , /\u9856/ // ï 0xEF9E
  , /\u9857/ // ï 0xEF9F
  , /\u9859/ // ï  0xEFA0
  , /\u985A/ // ïĄ 0xEFA1
  , /\uF9D0/ // ïĒ 0xEFA2
  , /\u9865/ // ïĢ 0xEFA3
  , /\u986C/ // ïĪ 0xEFA4
  , /\u98BA/ // ïĨ 0xEFA5
  , /\u98C8/ // ïĶ 0xEFA6
  , /\u98E7/ // ï§ 0xEFA7
  , /\u9958/ // ïĻ 0xEFA8
  , /\u999E/ // ïĐ 0xEFA9
  , /\u9A02/ // ïŠ 0xEFAA
  , /\u9A03/ // ïŦ 0xEFAB
  , /\u9A24/ // ïŽ 0xEFAC
  , /\u9A2D/ // ï­ 0xEFAD
  , /\u9A2E/ // ïŪ 0xEFAE
  , /\u9A38/ // ïŊ 0xEFAF
  , /\u9A4A/ // ï° 0xEFB0
  , /\u9A4E/ // ïą 0xEFB1
  , /\u9A52/ // ïē 0xEFB2
  , /\u9AB6/ // ïģ 0xEFB3
  , /\u9AC1/ // ïī 0xEFB4
  , /\u9AC3/ // ïĩ 0xEFB5
  , /\u9ACE/ // ïķ 0xEFB6
  , /\u9AD6/ // ï· 0xEFB7
  , /\u9AF9/ // ïļ 0xEFB8
  , /\u9B02/ // ïđ 0xEFB9
  , /\u9B08/ // ïš 0xEFBA
  , /\u9B20/ // ïŧ 0xEFBB
  , /\u4C17/ // ïž 0xEFBC
  , /\u9B2D/ // ï― 0xEFBD
  , /\u9B5E/ // ïū 0xEFBE
  , /\u9B79/ // ïŋ 0xEFBF
  , /\u9B66/ // ïĀ 0xEFC0
  , /\u9B72/ // ïÁ 0xEFC1
  , /\u9B75/ // ïÂ 0xEFC2
  , /\u9B84/ // ïÃ 0xEFC3
  , /\u9B8A/ // ïÄ 0xEFC4
  , /\u9B8F/ // ïÅ 0xEFC5
  , /\u9B9E/ // ïÆ 0xEFC6
  , /\u9BA7/ // ïĮ 0xEFC7
  , /\u9BC1/ // ïČ 0xEFC8
  , /\u9BCE/ // ïÉ 0xEFC9
  , /\u9BE5/ // ïĘ 0xEFCA
  , /\u9BF8/ // ïË 0xEFCB
  , /\u9BFD/ // ïĖ 0xEFCC
  , /\u9C00/ // ïÍ 0xEFCD
  , /\u9C23/ // ïÎ 0xEFCE
  , /\u9C41/ // ïÏ 0xEFCF
  , /\u9C4F/ // ïÐ 0xEFD0
  , /\u9C50/ // ïŅ 0xEFD1
  , /\u9C53/ // ïŌ 0xEFD2
  , /\u9C63/ // ïÓ 0xEFD3
  , /\u9C65/ // ïÔ 0xEFD4
  , /\u9C77/ // ïÕ 0xEFD5
  , /\u9D1D/ // ïÖ 0xEFD6
  , /\u9D1E/ // ïŨ 0xEFD7
  , /\u9D43/ // ïØ 0xEFD8
  , /\u9D47/ // ïŲ 0xEFD9
  , /\u9D52/ // ïÚ 0xEFDA
  , /\u9D63/ // ïÛ 0xEFDB
  , /\u9D70/ // ïÜ 0xEFDC
  , /\u9D7C/ // ïÝ 0xEFDD
  , /\u9D8A/ // ïÞ 0xEFDE
  , /\u9D96/ // ïß 0xEFDF
  , /\u9DC0/ // ïā 0xEFE0
  , /\u9DAC/ // ïá 0xEFE1
  , /\u9DBC/ // ïâ 0xEFE2
  , /\u9DD7/ // ïã 0xEFE3
  // , /\u2A190/ // ïä 0xEFE4
  , /\ud868\udd90/ // ïä 0xEFE4
  , /\u9DE7/ // ïå 0xEFE5
  , /\u9E07/ // ïæ 0xEFE6
  , /\u9E15/ // ïį 0xEFE7
  , /\u9E7C/ // ïč 0xEFE8
  , /\u9E9E/ // ïé 0xEFE9
  , /\u9EA4/ // ïę 0xEFEA
  , /\u9EAC/ // ïë 0xEFEB
  , /\u9EAF/ // ïė 0xEFEC
  , /\u9EB4/ // ïí 0xEFED
  , /\u9EB5/ // ïî 0xEFEE
  , /\u9EC3/ // ïï 0xEFEF
  , /\u9ED1/ // ïð 0xEFF0
  , /\u9F10/ // ïņ 0xEFF1
  , /\u9F39/ // ïō 0xEFF2
  , /\u9F57/ // ïó 0xEFF3
  , /\u9F90/ // ïô 0xEFF4
  , /\u9F94/ // ïõ 0xEFF5
  , /\u9F97/ // ïö 0xEFF6
  , /\u9FA2/ // ïũ 0xEFF7
  , /\u59F8/ // ïø 0xEFF8
  , /\u5C5B/ // ïų 0xEFF9
  , /\u5E77/ // ïú 0xEFFA
  , /\u7626/ // ïû 0xEFFB
  , /\u7E6B/ // ïü 0xEFFC
  // , /\u20089/ // ð@ 0xF040
  , /\ud840\udc89/ // ð@ 0xF040
  , /\u4E02/ // ðA 0xF041
  , /\u4E0F/ // ðB 0xF042
  , /\u4E12/ // ðC 0xF043
  , /\u4E29/ // ðD 0xF044
  , /\u4E2B/ // ðE 0xF045
  , /\u4E2E/ // ðF 0xF046
  , /\u4E40/ // ðG 0xF047
  , /\u4E47/ // ðH 0xF048
  , /\u4E48/ // ðI 0xF049
  // , /\u200A2/ // ðJ 0xF04A
  , /\ud840\udca2/ // ðJ 0xF04A
  , /\u4E51/ // ðK 0xF04B
  , /\u3406/ // ðL 0xF04C
  // , /\u200A4/ // ðM 0xF04D
  , /\ud840\udca4/ // ðM 0xF04D
  , /\u4E5A/ // ðN 0xF04E
  , /\u4E69/ // ðO 0xF04F
  , /\u4E9D/ // ðP 0xF050
  , /\u342C/ // ðQ 0xF051
  , /\u342E/ // ðR 0xF052
  , /\u4EB9/ // ðS 0xF053
  , /\u4EBB/ // ðT 0xF054
  // , /\u201A2/ // ðU 0xF055
  , /\ud840\udda2/ // ðU 0xF055
  , /\u4EBC/ // ðV 0xF056
  , /\u4EC3/ // ðW 0xF057
  , /\u4EC8/ // ðX 0xF058
  , /\u4ED0/ // ðY 0xF059
  , /\u4EEB/ // ðZ 0xF05A
  , /\u4EDA/ // ð[ 0xF05B
  , /\u4EF1/ // ð\ 0xF05C
  , /\u4EF5/ // ð] 0xF05D
  , /\u4F00/ // ð^ 0xF05E
  , /\u4F16/ // ð_ 0xF05F
  , /\u4F64/ // ð` 0xF060
  , /\u4F37/ // ða 0xF061
  , /\u4F3E/ // ðb 0xF062
  , /\u4F54/ // ðc 0xF063
  , /\u4F58/ // ðd 0xF064
  // , /\u20213/ // ðe 0xF065
  , /\ud840\ude13/ // ðe 0xF065
  , /\u4F77/ // ðf 0xF066
  , /\u4F78/ // ðg 0xF067
  , /\u4F7A/ // ðh 0xF068
  , /\u4F7D/ // ði 0xF069
  , /\u4F82/ // ðj 0xF06A
  , /\u4F85/ // ðk 0xF06B
  , /\u4F92/ // ðl 0xF06C
  , /\u4F9A/ // ðm 0xF06D
  , /\u4FE6/ // ðn 0xF06E
  , /\u4FB2/ // ðo 0xF06F
  , /\u4FBE/ // ðp 0xF070
  , /\u4FC5/ // ðq 0xF071
  , /\u4FCB/ // ðr 0xF072
  , /\u4FCF/ // ðs 0xF073
  , /\u4FD2/ // ðt 0xF074
  , /\u346A/ // ðu 0xF075
  , /\u4FF2/ // ðv 0xF076
  , /\u5000/ // ðw 0xF077
  , /\u5010/ // ðx 0xF078
  , /\u5013/ // ðy 0xF079
  , /\u501C/ // ðz 0xF07A
  , /\u501E/ // ð{ 0xF07B
  , /\u5022/ // ð| 0xF07C
  , /\u3468/ // ð} 0xF07D
  , /\u5042/ // ð~ 0xF07E
  , /\u5046/ // ð 0xF080
  , /\u504E/ // ð 0xF081
  , /\u5053/ // ð 0xF082
  , /\u5057/ // ð 0xF083
  , /\u5063/ // ð 0xF084
  , /\u5066/ // ð 0xF085
  , /\u506A/ // ð 0xF086
  , /\u5070/ // ð 0xF087
  , /\u50A3/ // ð 0xF088
  , /\u5088/ // ð 0xF089
  , /\u5092/ // ð 0xF08A
  , /\u5093/ // ð 0xF08B
  , /\u5095/ // ð 0xF08C
  , /\u5096/ // ð 0xF08D
  , /\u509C/ // ð 0xF08E
  , /\u50AA/ // ð 0xF08F
  // , /\u2032B/ // ð 0xF090
  , /\ud840\udf2b/ // ð 0xF090
  , /\u50B1/ // ð 0xF091
  , /\u50BA/ // ð 0xF092
  , /\u50BB/ // ð 0xF093
  , /\u50C4/ // ð 0xF094
  , /\u50C7/ // ð 0xF095
  , /\u50F3/ // ð 0xF096
  // , /\u20381/ // ð 0xF097
  , /\ud840\udf81/ // ð 0xF097
  , /\u50CE/ // ð 0xF098
  // , /\u20371/ // ð 0xF099
  , /\ud840\udf71/ // ð 0xF099
  , /\u50D4/ // ð 0xF09A
  , /\u50D9/ // ð 0xF09B
  , /\u50E1/ // ð 0xF09C
  , /\u50E9/ // ð 0xF09D
  , /\u3492/ // ð 0xF09E
  , /\u5108/ // ņ@ 0xF140
  // , /\u203F9/ // ņA 0xF141
  , /\ud840\udff9/ // ņA 0xF141
  , /\u5117/ // ņB 0xF142
  , /\u511B/ // ņC 0xF143
  // , /\u2044A/ // ņD 0xF144
  , /\ud841\udc4a/ // ņD 0xF144
  , /\u5160/ // ņE 0xF145
  // , /\u20509/ // ņF 0xF146
  , /\ud841\udd09/ // ņF 0xF146
  , /\u5173/ // ņG 0xF147
  , /\u5183/ // ņH 0xF148
  , /\u518B/ // ņI 0xF149
  , /\u34BC/ // ņJ 0xF14A
  , /\u5198/ // ņK 0xF14B
  , /\u51A3/ // ņL 0xF14C
  , /\u51AD/ // ņM 0xF14D
  , /\u34C7/ // ņN 0xF14E
  , /\u51BC/ // ņO 0xF14F
  // , /\u205D6/ // ņP 0xF150
  , /\ud841\uddd6/ // ņP 0xF150
  // , /\u20628/ // ņQ 0xF151
  , /\ud841\ude28/ // ņQ 0xF151
  , /\u51F3/ // ņR 0xF152
  , /\u51F4/ // ņS 0xF153
  , /\u5202/ // ņT 0xF154
  , /\u5212/ // ņU 0xF155
  , /\u5216/ // ņV 0xF156
  // , /\u2074F/ // ņW 0xF157
  , /\ud841\udf4f/ // ņW 0xF157
  , /\u5255/ // ņX 0xF158
  , /\u525C/ // ņY 0xF159
  , /\u526C/ // ņZ 0xF15A
  , /\u5277/ // ņ[ 0xF15B
  , /\u5284/ // ņ\ 0xF15C
  , /\u5282/ // ņ] 0xF15D
  // , /\u20807/ // ņ^ 0xF15E
  , /\ud842\udc07/ // ņ^ 0xF15E
  , /\u5298/ // ņ_ 0xF15F
  // , /\u2083A/ // ņ` 0xF160
  , /\ud842\udc3a/ // ņ` 0xF160
  , /\u52A4/ // ņa 0xF161
  , /\u52A6/ // ņb 0xF162
  , /\u52AF/ // ņc 0xF163
  , /\u52BA/ // ņd 0xF164
  , /\u52BB/ // ņe 0xF165
  , /\u52CA/ // ņf 0xF166
  , /\u351F/ // ņg 0xF167
  , /\u52D1/ // ņh 0xF168
  // , /\u208B9/ // ņi 0xF169
  , /\ud842\udcb9/ // ņi 0xF169
  , /\u52F7/ // ņj 0xF16A
  , /\u530A/ // ņk 0xF16B
  , /\u530B/ // ņl 0xF16C
  , /\u5324/ // ņm 0xF16D
  , /\u5335/ // ņn 0xF16E
  , /\u533E/ // ņo 0xF16F
  , /\u5342/ // ņp 0xF170
  // , /\u2097C/ // ņq 0xF171
  , /\ud842\udd7c/ // ņq 0xF171
  // , /\u2099D/ // ņr 0xF172
  , /\ud842\udd9d/ // ņr 0xF172
  , /\u5367/ // ņs 0xF173
  , /\u536C/ // ņt 0xF174
  , /\u537A/ // ņu 0xF175
  , /\u53A4/ // ņv 0xF176
  , /\u53B4/ // ņw 0xF177
  , /\u20AD3/ // ņx 0xF178
  , /\u53B7/ // ņy 0xF179
  , /\u53C0/ // ņz 0xF17A
  // , /\u20B1D/ // ņ{ 0xF17B
  , /\ud842\udf1d/ // ņ{ 0xF17B
  , /\u355D/ // ņ| 0xF17C
  , /\u355E/ // ņ} 0xF17D
  , /\u53D5/ // ņ~ 0xF17E
  , /\u53DA/ // ņ 0xF180
  , /\u3563/ // ņ 0xF181
  , /\u53F4/ // ņ 0xF182
  , /\u53F5/ // ņ 0xF183
  , /\u5455/ // ņ 0xF184
  , /\u5424/ // ņ 0xF185
  , /\u5428/ // ņ 0xF186
  , /\u356E/ // ņ 0xF187
  , /\u5443/ // ņ 0xF188
  , /\u5462/ // ņ 0xF189
  , /\u5466/ // ņ 0xF18A
  , /\u546C/ // ņ 0xF18B
  , /\u548A/ // ņ 0xF18C
  , /\u548D/ // ņ 0xF18D
  , /\u5495/ // ņ 0xF18E
  , /\u54A0/ // ņ 0xF18F
  , /\u54A6/ // ņ 0xF190
  , /\u54AD/ // ņ 0xF191
  , /\u54AE/ // ņ 0xF192
  , /\u54B7/ // ņ 0xF193
  , /\u54BA/ // ņ 0xF194
  , /\u54BF/ // ņ 0xF195
  , /\u54C3/ // ņ 0xF196
  // , /\u20D45/ // ņ 0xF197
  , /\ud843\udd45/ // ņ 0xF197
  , /\u54EC/ // ņ 0xF198
  , /\u54EF/ // ņ 0xF199
  , /\u54F1/ // ņ 0xF19A
  , /\u54F3/ // ņ 0xF19B
  , /\u5500/ // ņ 0xF19C
  , /\u5501/ // ņ 0xF19D
  , /\u5509/ // ņ 0xF19E
  , /\u553C/ // ņ 0xF19F
  , /\u5541/ // ņ  0xF1A0
  , /\u35A6/ // ņĄ 0xF1A1
  , /\u5547/ // ņĒ 0xF1A2
  , /\u554A/ // ņĢ 0xF1A3
  , /\u35A8/ // ņĪ 0xF1A4
  , /\u5560/ // ņĨ 0xF1A5
  , /\u5561/ // ņĶ 0xF1A6
  , /\u5564/ // ņ§ 0xF1A7
  // , /\u20DE1/ // ņĻ 0xF1A8
  , /\ud843\udde1/ // ņĻ 0xF1A8
  , /\u557D/ // ņĐ 0xF1A9
  , /\u5582/ // ņŠ 0xF1AA
  , /\u5588/ // ņŦ 0xF1AB
  , /\u5591/ // ņŽ 0xF1AC
  , /\u35C5/ // ņ­ 0xF1AD
  , /\u55D2/ // ņŪ 0xF1AE
  // , /\u20E95/ // ņŊ 0xF1AF
  , /\ud843\ude95/ // ņŊ 0xF1AF
  // , /\u20E6D/ // ņ° 0xF1B0
  , /\ud843\ude6d/ // ņ° 0xF1B0
  , /\u55BF/ // ņą 0xF1B1
  , /\u55C9/ // ņē 0xF1B2
  , /\u55CC/ // ņģ 0xF1B3
  , /\u55D1/ // ņī 0xF1B4
  , /\u55DD/ // ņĩ 0xF1B5
  , /\u35DA/ // ņķ 0xF1B6
  , /\u55E2/ // ņ· 0xF1B7
  // , /\u20E64/ // ņļ 0xF1B8
  , /\ud843\ude64/ // ņļ 0xF1B8
  , /\u55E9/ // ņđ 0xF1B9
  , /\u5628/ // ņš 0xF1BA
  // , /\u20F5F/ // ņŧ 0xF1BB
  , /\ud843\udf5f/ // ņŧ 0xF1BB
  , /\u5607/ // ņž 0xF1BC
  , /\u5610/ // ņ― 0xF1BD
  , /\u5630/ // ņū 0xF1BE
  , /\u5637/ // ņŋ 0xF1BF
  , /\u35F4/ // ņĀ 0xF1C0
  , /\u563D/ // ņÁ 0xF1C1
  , /\u563F/ // ņÂ 0xF1C2
  , /\u5640/ // ņÃ 0xF1C3
  , /\u5647/ // ņÄ 0xF1C4
  , /\u565E/ // ņÅ 0xF1C5
  , /\u5660/ // ņÆ 0xF1C6
  , /\u566D/ // ņĮ 0xF1C7
  , /\u3605/ // ņČ 0xF1C8
  , /\u5688/ // ņÉ 0xF1C9
  , /\u568C/ // ņĘ 0xF1CA
  , /\u5695/ // ņË 0xF1CB
  , /\u569A/ // ņĖ 0xF1CC
  , /\u569D/ // ņÍ 0xF1CD
  , /\u56A8/ // ņÎ 0xF1CE
  , /\u56AD/ // ņÏ 0xF1CF
  , /\u56B2/ // ņÐ 0xF1D0
  , /\u56C5/ // ņŅ 0xF1D1
  , /\u56CD/ // ņŌ 0xF1D2
  , /\u56DF/ // ņÓ 0xF1D3
  , /\u56E8/ // ņÔ 0xF1D4
  , /\u56F6/ // ņÕ 0xF1D5
  , /\u56F7/ // ņÖ 0xF1D6
  // , /\u21201/ // ņŨ 0xF1D7
  , /\ud844\ude01/ // ņŨ 0xF1D7
  , /\u5715/ // ņØ 0xF1D8
  , /\u5723/ // ņŲ 0xF1D9
  // , /\u21255/ // ņÚ 0xF1DA
  , /\ud844\ude55/ // ņÚ 0xF1DA
  , /\u5729/ // ņÛ 0xF1DB
  // , /\u2127B/ // ņÜ 0xF1DC
  , /\ud844\ude7b/ // ņÜ 0xF1DC
  , /\u5745/ // ņÝ 0xF1DD
  , /\u5746/ // ņÞ 0xF1DE
  , /\u574C/ // ņß 0xF1DF
  , /\u574D/ // ņā 0xF1E0
  // , /\u21274/ // ņá 0xF1E1
  , /\ud844\ude74/ // ņá 0xF1E1
  , /\u5768/ // ņâ 0xF1E2
  , /\u576F/ // ņã 0xF1E3
  , /\u5773/ // ņä 0xF1E4
  , /\u5774/ // ņå 0xF1E5
  , /\u5775/ // ņæ 0xF1E6
  , /\u577B/ // ņį 0xF1E7
  // , /\u212E4/ // ņč 0xF1E8
  , /\ud844\udee4/ // ņč 0xF1E8
  , /\u212D7/ // ņé 0xF1E9
  , /\ud844\uded7/ // ņé 0xF1E9
  , /\u57AC/ // ņę 0xF1EA
  , /\u579A/ // ņë 0xF1EB
  , /\u579D/ // ņė 0xF1EC
  , /\u579E/ // ņí 0xF1ED
  , /\u57A8/ // ņî 0xF1EE
  , /\u57D7/ // ņï 0xF1EF
  // , /\u212FD/ // ņð 0xF1F0
  , /\ud844\udefd/ // ņð 0xF1F0
  , /\u57CC/ // ņņ 0xF1F1
  // , /\u21336/ // ņō 0xF1F2
  , /\ud844\udf36/ // ņō 0xF1F2
  // , /\u21344/ // ņó 0xF1F3
  , /\ud844\udf44/ // ņó 0xF1F3
  , /\u57DE/ // ņô 0xF1F4
  , /\u57E6/ // ņõ 0xF1F5
  , /\u57F0/ // ņö 0xF1F6
  , /\u364A/ // ņũ 0xF1F7
  , /\u57F8/ // ņø 0xF1F8
  , /\u57FB/ // ņų 0xF1F9
  , /\u57FD/ // ņú 0xF1FA
  , /\u5804/ // ņû 0xF1FB
  , /\u581E/ // ņü 0xF1FC
  , /\u5820/ // ō@ 0xF240
  , /\u5827/ // ōA 0xF241
  , /\u5832/ // ōB 0xF242
  , /\u5839/ // ōC 0xF243
  // , /\u213C4/ // ōD 0xF244
  , /\ud844\udfc4/ // ōD 0xF244
  , /\u5849/ // ōE 0xF245
  , /\u584C/ // ōF 0xF246
  , /\u5867/ // ōG 0xF247
  , /\u588A/ // ōH 0xF248
  , /\u588B/ // ōI 0xF249
  , /\u588D/ // ōJ 0xF24A
  , /\u588F/ // ōK 0xF24B
  , /\u5890/ // ōL 0xF24C
  , /\u5894/ // ōM 0xF24D
  , /\u589D/ // ōN 0xF24E
  , /\u58AA/ // ōO 0xF24F
  , /\u58B1/ // ōP 0xF250
  // , /\u2146D/ // ōQ 0xF251
  , /\ud844\udfc4/ // ōQ 0xF251
  , /\u58C3/ // ōR 0xF252
  , /\u58CD/ // ōS 0xF253
  , /\u58E2/ // ōT 0xF254
  , /\u58F3/ // ōU 0xF255
  , /\u58F4/ // ōV 0xF256
  , /\u5905/ // ōW 0xF257
  , /\u5906/ // ōX 0xF258
  , /\u590B/ // ōY 0xF259
  , /\u590D/ // ōZ 0xF25A
  , /\u5914/ // ō[ 0xF25B
  , /\u5924/ // ō\ 0xF25C
  // , /\u215D7/ // ō] 0xF25D
  , /\ud845\uddd7/ // ō] 0xF25D
  , /\u3691/ // ō^ 0xF25E
  , /\u593D/ // ō_ 0xF25F
  , /\u3699/ // ō` 0xF260
  , /\u5946/ // ōa 0xF261
  , /\u3696/ // ōb 0xF262
  // , /\u26C29/ // ōc 0xF263
  , /\ud85b\udc29/ // ōc 0xF263
  , /\u595B/ // ōd 0xF264
  , /\u595F/ // ōe 0xF265
  // , /\u21647/ // ōf 0xF266
  , /\ud845\ude47/ // ōf 0xF266
  , /\u5975/ // ōg 0xF267
  , /\u5976/ // ōh 0xF268
  , /\u597C/ // ōi 0xF269
  , /\u599F/ // ōj 0xF26A
  , /\u59AE/ // ōk 0xF26B
  , /\u59BC/ // ōl 0xF26C
  , /\u59C8/ // ōm 0xF26D
  , /\u59CD/ // ōn 0xF26E
  , /\u59DE/ // ōo 0xF26F
  , /\u59E3/ // ōp 0xF270
  , /\u59E4/ // ōq 0xF271
  , /\u59E7/ // ōr 0xF272
  , /\u59EE/ // ōs 0xF273
  // , /\u21706/ // ōt 0xF274
  , /\ud845\udf06/ // ōt 0xF274
  // , /\u21742/ // ōu 0xF275
  , /\ud845\udf42/ // ōu 0xF275
  , /\u36CF/ // ōv 0xF276
  , /\u5A0C/ // ōw 0xF277
  , /\u5A0D/ // ōx 0xF278
  , /\u5A17/ // ōy 0xF279
  , /\u5A27/ // ōz 0xF27A
  , /\u5A2D/ // ō{ 0xF27B
  , /\u5A55/ // ō| 0xF27C
  , /\u5A65/ // ō} 0xF27D
  , /\u5A7A/ // ō~ 0xF27E
  , /\u5A8B/ // ō 0xF280
  , /\u5A9C/ // ō 0xF281
  , /\u5A9F/ // ō 0xF282
  , /\u5AA0/ // ō 0xF283
  , /\u5AA2/ // ō 0xF284
  , /\u5AB1/ // ō 0xF285
  , /\u5AB3/ // ō 0xF286
  , /\u5AB5/ // ō 0xF287
  , /\u5ABA/ // ō 0xF288
  , /\u5ABF/ // ō 0xF289
  , /\u5ADA/ // ō 0xF28A
  , /\u5ADC/ // ō 0xF28B
  , /\u5AE0/ // ō 0xF28C
  , /\u5AE5/ // ō 0xF28D
  , /\u5AF0/ // ō 0xF28E
  , /\u5AEE/ // ō 0xF28F
  , /\u5AF5/ // ō 0xF290
  , /\u5B00/ // ō 0xF291
  , /\u5B08/ // ō 0xF292
  , /\u5B17/ // ō 0xF293
  , /\u5B34/ // ō 0xF294
  , /\u5B2D/ // ō 0xF295
  , /\u5B4C/ // ō 0xF296
  , /\u5B52/ // ō 0xF297
  , /\u5B68/ // ō 0xF298
  , /\u5B6F/ // ō 0xF299
  , /\u5B7C/ // ō 0xF29A
  , /\u5B7F/ // ō 0xF29B
  , /\u5B81/ // ō 0xF29C
  , /\u5B84/ // ō 0xF29D
  // , /\u219C3/ // ō 0xF29E
  , /\ud846\uddc3/ // ō 0xF29E
  , /\u5B96/ // ð 0xF09F
  , /\u5BAC/ // ð  0xF0A0
  , /\u3761/ // ðĄ 0xF0A1
  , /\u5BC0/ // ðĒ 0xF0A2
  , /\u3762/ // ðĢ 0xF0A3
  , /\u5BCE/ // ðĪ 0xF0A4
  , /\u5BD6/ // ðĨ 0xF0A5
  , /\u376C/ // ðĶ 0xF0A6
  , /\u376B/ // ð§ 0xF0A7
  , /\u5BF1/ // ðĻ 0xF0A8
  , /\u5BFD/ // ðĐ 0xF0A9
  , /\u3775/ // ðŠ 0xF0AA
  , /\u5C03/ // ðŦ 0xF0AB
  , /\u5C29/ // ðŽ 0xF0AC
  , /\u5C30/ // ð­ 0xF0AD
  // , /\u21C56/ // ðŪ 0xF0AE
  , /\ud847\udc56/ // ðŪ 0xF0AE
  , /\u5C5F/ // ðŊ 0xF0AF
  , /\u5C63/ // ð° 0xF0B0
  , /\u5C67/ // ðą 0xF0B1
  , /\u5C68/ // ðē 0xF0B2
  , /\u5C69/ // ðģ 0xF0B3
  , /\u5C70/ // ðī 0xF0B4
  // , /\u21D2D/ // ðĩ 0xF0B5
  , /\ud847\udd2d/ // ðĩ 0xF0B5
  // , /\u21D45/ // ðķ 0xF0B6
  , /\ud847\udd45/ // ðķ 0xF0B6
  , /\u5C7C/ // ð· 0xF0B7
  // , /\u21D78/ // ðļ 0xF0B8
  , /\ud847\udd78/ // ðļ 0xF0B8
  // , /\u21D62/ // ðđ 0xF0B9
  , /\ud847\udd62/ // ðđ 0xF0B9
  , /\u5C88/ // ðš 0xF0BA
  , /\u5C8A/ // ðŧ 0xF0BB
  , /\u37C1/ // ðž 0xF0BC
  // , /\u21DA1/ // ð― 0xF0BD
  , /\ud847\udda1/ // ð― 0xF0BD
  // , /\u21D9C/ // ðū 0xF0BE
  , /\ud847\udd9c/ // ðū 0xF0BE
  , /\u5CA0/ // ðŋ 0xF0BF
  , /\u5CA2/ // ðĀ 0xF0C0
  , /\u5CA6/ // ðÁ 0xF0C1
  , /\u5CA7/ // ðÂ 0xF0C2
  // , /\u21D92/ // ðÃ 0xF0C3
  , /\ud847\udd92/ // ðÃ 0xF0C3
  , /\u5CAD/ // ðÄ 0xF0C4
  , /\u5CB5/ // ðÅ 0xF0C5
  // , /\u21DB7/ // ðÆ 0xF0C6
  , /\ud847\uddb7/ // ðÆ 0xF0C6
  , /\u5CC9/ // ðĮ 0xF0C7
  // , /\u21DE0/ // ðČ 0xF0C8
  , /\ud847\udde0/ // ðČ 0xF0C8
  // , /\u21E33/ // ðÉ 0xF0C9
  , /\ud847\ude33/ // ðÉ 0xF0C9
  , /\u5D06/ // ðĘ 0xF0CA
  , /\u5D10/ // ðË 0xF0CB
  , /\u5D2B/ // ðĖ 0xF0CC
  , /\u5D1D/ // ðÍ 0xF0CD
  , /\u5D20/ // ðÎ 0xF0CE
  , /\u5D24/ // ðÏ 0xF0CF
  , /\u5D26/ // ðÐ 0xF0D0
  , /\u5D31/ // ðŅ 0xF0D1
  , /\u5D39/ // ðŌ 0xF0D2
  , /\u5D42/ // ðÓ 0xF0D3
  , /\u37E8/ // ðÔ 0xF0D4
  , /\u5D61/ // ðÕ 0xF0D5
  , /\u5D6A/ // ðÖ 0xF0D6
  , /\u37F4/ // ðŨ 0xF0D7
  , /\u5D70/ // ðØ 0xF0D8
  // , /\u21F1E/ // ðŲ 0xF0D9
  , /\ud847\udf1e/ // ðŲ 0xF0D9
  , /\u37FD/ // ðÚ 0xF0DA
  , /\u5D88/ // ðÛ 0xF0DB
  , /\u3800/ // ðÜ 0xF0DC
  , /\u5D92/ // ðÝ 0xF0DD
  , /\u5D94/ // ðÞ 0xF0DE
  , /\u5D97/ // ðß 0xF0DF
  , /\u5D99/ // ðā 0xF0E0
  , /\u5DB0/ // ðá 0xF0E1
  , /\u5DB2/ // ðâ 0xF0E2
  , /\u5DB4/ // ðã 0xF0E3
  // , /\u21F76/ // ðä 0xF0E4
  , /\ud847\udf76/ // ðä 0xF0E4
  , /\u5DB9/ // ðå 0xF0E5
  , /\u5DD1/ // ðæ 0xF0E6
  , /\u5DD7/ // ðį 0xF0E7
  , /\u5DD8/ // ðč 0xF0E8
  , /\u5DE0/ // ðé 0xF0E9
  // , /\u21FFA/ // ðę 0xF0EA
  , /\ud847\udffa/ // ðę 0xF0EA
  , /\u5DE4/ // ðë 0xF0EB
  , /\u5DE9/ // ðė 0xF0EC
  , /\u382F/ // ðí 0xF0ED
  , /\u5E00/ // ðî 0xF0EE
  , /\u3836/ // ðï 0xF0EF
  , /\u5E12/ // ðð 0xF0F0
  , /\u5E15/ // ðņ 0xF0F1
  , /\u3840/ // ðō 0xF0F2
  , /\u5E1F/ // ðó 0xF0F3
  , /\u5E2E/ // ðô 0xF0F4
  , /\u5E3E/ // ðõ 0xF0F5
  , /\u5E49/ // ðö 0xF0F6
  , /\u385C/ // ðũ 0xF0F7
  , /\u5E56/ // ðø 0xF0F8
  , /\u3861/ // ðų 0xF0F9
  , /\u5E6B/ // ðú 0xF0FA
  , /\u5E6C/ // ðû 0xF0FB
  , /\u5E6D/ // ðü 0xF0FC
  , /\u5E6E/ // ō 0xF29F
  // , /\u2217B/ // ō  0xF2A0
  , /\ud848\udd7b/ // ō  0xF2A0
  , /\u5EA5/ // ōĄ 0xF2A1
  , /\u5EAA/ // ōĒ 0xF2A2
  , /\u5EAC/ // ōĢ 0xF2A3
  , /\u5EB9/ // ōĪ 0xF2A4
  , /\u5EBF/ // ōĨ 0xF2A5
  , /\u5EC6/ // ōĶ 0xF2A6
  , /\u5ED2/ // ō§ 0xF2A7
  , /\u5ED9/ // ōĻ 0xF2A8
  // , /\u2231E/ // ōĐ 0xF2A9
  , /\ud848\udf1e/ // ōĐ 0xF2A9
  , /\u5EFD/ // ōŠ 0xF2AA
  , /\u5F08/ // ōŦ 0xF2AB
  , /\u5F0E/ // ōŽ 0xF2AC
  , /\u5F1C/ // ō­ 0xF2AD
  // , /\u223AD/ // ōŪ 0xF2AE
  , /\ud848\udfad/ // ōŪ 0xF2AE
  , /\u5F1E/ // ōŊ 0xF2AF
  , /\u5F47/ // ō° 0xF2B0
  , /\u5F63/ // ōą 0xF2B1
  , /\u5F72/ // ōē 0xF2B2
  , /\u5F7E/ // ōģ 0xF2B3
  , /\u5F8F/ // ōī 0xF2B4
  , /\u5FA2/ // ōĩ 0xF2B5
  , /\u5FA4/ // ōķ 0xF2B6
  , /\u5FB8/ // ō· 0xF2B7
  , /\u5FC4/ // ōļ 0xF2B8
  , /\u38FA/ // ōđ 0xF2B9
  , /\u5FC7/ // ōš 0xF2BA
  , /\u5FCB/ // ōŧ 0xF2BB
  , /\u5FD2/ // ōž 0xF2BC
  , /\u5FD3/ // ō― 0xF2BD
  , /\u5FD4/ // ōū 0xF2BE
  , /\u5FE2/ // ōŋ 0xF2BF
  , /\u5FEE/ // ōĀ 0xF2C0
  , /\u5FEF/ // ōÁ 0xF2C1
  , /\u5FF3/ // ōÂ 0xF2C2
  , /\u5FFC/ // ōÃ 0xF2C3
  , /\u3917/ // ōÄ 0xF2C4
  , /\u6017/ // ōÅ 0xF2C5
  , /\u6022/ // ōÆ 0xF2C6
  , /\u6024/ // ōĮ 0xF2C7
  , /\u391A/ // ōČ 0xF2C8
  , /\u604C/ // ōÉ 0xF2C9
  , /\u607F/ // ōĘ 0xF2CA
  , /\u608A/ // ōË 0xF2CB
  , /\u6095/ // ōĖ 0xF2CC
  , /\u60A8/ // ōÍ 0xF2CD
  // , /\u226F3/ // ōÎ 0xF2CE
  , /\ud849\udef3/ // ōÎ 0xF2CE
  , /\u60B0/ // ōÏ 0xF2CF
  , /\u60B1/ // ōÐ 0xF2D0
  , /\u60BE/ // ōŅ 0xF2D1
  , /\u60C8/ // ōŌ 0xF2D2
  , /\u60D9/ // ōÓ 0xF2D3
  , /\u60DB/ // ōÔ 0xF2D4
  , /\u60EE/ // ōÕ 0xF2D5
  , /\u60F2/ // ōÖ 0xF2D6
  , /\u60F5/ // ōŨ 0xF2D7
  , /\u6110/ // ōØ 0xF2D8
  , /\u6112/ // ōŲ 0xF2D9
  , /\u6113/ // ōÚ 0xF2DA
  , /\u6119/ // ōÛ 0xF2DB
  , /\u611E/ // ōÜ 0xF2DC
  , /\u613A/ // ōÝ 0xF2DD
  , /\u396F/ // ōÞ 0xF2DE
  , /\u6141/ // ōß 0xF2DF
  , /\u6146/ // ōā 0xF2E0
  , /\u6160/ // ōá 0xF2E1
  , /\u617C/ // ōâ 0xF2E2
  // , /\u2285B/ // ōã 0xF2E3
  , /\ud84a\udc5b/ // ōã 0xF2E3
  , /\u6192/ // ōä 0xF2E4
  , /\u6193/ // ōå 0xF2E5
  , /\u6197/ // ōæ 0xF2E6
  , /\u6198/ // ōį 0xF2E7
  , /\u61A5/ // ōč 0xF2E8
  , /\u61A8/ // ōé 0xF2E9
  , /\u61AD/ // ōę 0xF2EA
  // , /\u228AB/ // ōë 0xF2EB
  , /\ud84a\udcab/ // ōë 0xF2EB
  , /\u61D5/ // ōė 0xF2EC
  , /\u61DD/ // ōí 0xF2ED
  , /\u61DF/ // ōî 0xF2EE
  , /\u61F5/ // ōï 0xF2EF
  // , /\u2298f/ // ōð 0xF2F0
  , /\ud84a\udd8f/ // ōð 0xF2F0
  , /\u6215/ // ōņ 0xF2F1
  , /\u6223/ // ōō 0xF2F2
  , /\u6229/ // ōó 0xF2F3
  , /\u6246/ // ōô 0xF2F4
  , /\u624C/ // ōõ 0xF2F5
  , /\u6251/ // ōö 0xF2F6
  , /\u6252/ // ōũ 0xF2F7
  , /\u6261/ // ōø 0xF2F8
  , /\u6264/ // ōų 0xF2F9
  , /\u627B/ // ōú 0xF2FA
  , /\u626D/ // ōû 0xF2FB
  , /\u6273/ // ōü 0xF2FC
  , /\u6299/ // ó@ 0xF340
  , /\u62A6/ // óA 0xF341
  , /\u62D5/ // óB 0xF342
  // , /\u22AB8/ // óC 0xF343
  , /\ud84a\udeb8/ // óC 0xF343
  , /\u62FD/ // óD 0xF344
  , /\u6303/ // óE 0xF345
  , /\u630D/ // óF 0xF346
  , /\u6310/ // óG 0xF347
  // , /\u22B4F/ // óH 0xF348
  , /\ud84a\udf4f/ // óH 0xF348
  // , /\u22B50/ // óI 0xF349
  , /\ud84a\udf50/ // óI 0xF349
  , /\u6332/ // óJ 0xF34A
  , /\u6335/ // óK 0xF34B
  , /\u633B/ // óL 0xF34C
  , /\u633C/ // óM 0xF34D
  , /\u6341/ // óN 0xF34E
  , /\u6344/ // óO 0xF34F
  , /\u634E/ // óP 0xF350
  // , /\u22B46/ // óQ 0xF351
  , /\ud84a\udf46/ // óQ 0xF351
  , /\u6359/ // óR 0xF352
  // , /\u22C1D/ // óS 0xF353
  , /\ud84b\udc1d/ // óS 0xF353
  // , /\u22BA6/ // óT 0xF354
  , /\ud84a\udfa6/ // óT 0xF354
  , /\u636C/ // óU 0xF355
  , /\u6384/ // óV 0xF356
  , /\u6399/ // óW 0xF357
  // , /\u22C24/ // óX 0xF358
  , /\ud84b\udc24/ // óX 0xF358
  , /\u6394/ // óY 0xF359
  , /\u63BD/ // óZ 0xF35A
  , /\u63F7/ // ó[ 0xF35B
  , /\u63D4/ // ó\ 0xF35C
  , /\u63D5/ // ó] 0xF35D
  , /\u63DC/ // ó^ 0xF35E
  , /\u63E0/ // ó_ 0xF35F
  , /\u63EB/ // ó` 0xF360
  , /\u63EC/ // óa 0xF361
  , /\u63F2/ // ób 0xF362
  , /\u6409/ // óc 0xF363
  , /\u641E/ // ód 0xF364
  , /\u6425/ // óe 0xF365
  , /\u6429/ // óf 0xF366
  , /\u642F/ // óg 0xF367
  , /\u645A/ // óh 0xF368
  , /\u645B/ // ói 0xF369
  , /\u645D/ // ój 0xF36A
  , /\u6473/ // ók 0xF36B
  , /\u647D/ // ól 0xF36C
  , /\u6487/ // óm 0xF36D
  , /\u6491/ // ón 0xF36E
  , /\u649D/ // óo 0xF36F
  , /\u649F/ // óp 0xF370
  , /\u64CB/ // óq 0xF371
  , /\u64CC/ // ór 0xF372
  , /\u64D5/ // ós 0xF373
  , /\u64D7/ // ót 0xF374
  // , /\u22DE1/ // óu 0xF375
  , /\ud84b\udde1/ // óu 0xF375
  , /\u64E4/ // óv 0xF376
  , /\u64E5/ // ów 0xF377
  , /\u64FF/ // óx 0xF378
  , /\u6504/ // óy 0xF379
  , /\u3A6E/ // óz 0xF37A
  , /\u650F/ // ó{ 0xF37B
  , /\u6514/ // ó| 0xF37C
  , /\u6516/ // ó} 0xF37D
  , /\u3A73/ // ó~ 0xF37E
  , /\u651E/ // ó 0xF380
  , /\u6532/ // ó 0xF381
  , /\u6544/ // ó 0xF382
  , /\u6554/ // ó 0xF383
  , /\u656B/ // ó 0xF384
  , /\u657A/ // ó 0xF385
  , /\u6581/ // ó 0xF386
  , /\u6584/ // ó 0xF387
  , /\u6585/ // ó 0xF388
  , /\u658A/ // ó 0xF389
  , /\u65B2/ // ó 0xF38A
  , /\u65B5/ // ó 0xF38B
  , /\u65B8/ // ó 0xF38C
  , /\u65BF/ // ó 0xF38D
  , /\u65C2/ // ó 0xF38E
  , /\u65C9/ // ó 0xF38F
  , /\u65D4/ // ó 0xF390
  , /\u3AD6/ // ó 0xF391
  , /\u65F2/ // ó 0xF392
  , /\u65F9/ // ó 0xF393
  , /\u65FC/ // ó 0xF394
  , /\u6604/ // ó 0xF395
  , /\u6608/ // ó 0xF396
  , /\u6621/ // ó 0xF397
  , /\u662A/ // ó 0xF398
  , /\u6645/ // ó 0xF399
  , /\u6651/ // ó 0xF39A
  , /\u664E/ // ó 0xF39B
  , /\u3AEA/ // ó 0xF39C
  // , /\u231C3/ // ó 0xF39D
  , /\ud84c\uddc3/ // ó 0xF39D
  , /\u6657/ // ó 0xF39E
  , /\u665B/ // ó 0xF39F
  , /\u6663/ // ó  0xF3A0
  // , /\u231F5/ // óĄ 0xF3A1
  , /\ud84c\uddf5/ // óĄ 0xF3A1
  // , /\u231B6/ // óĒ 0xF3A2
  , /\ud84c\uddb6/ // óĒ 0xF3A2
  , /\u666A/ // óĢ 0xF3A3
  , /\u666B/ // óĪ 0xF3A4
  , /\u666C/ // óĨ 0xF3A5
  , /\u666D/ // óĶ 0xF3A6
  , /\u667B/ // ó§ 0xF3A7
  , /\u6680/ // óĻ 0xF3A8
  , /\u6690/ // óĐ 0xF3A9
  , /\u6692/ // óŠ 0xF3AA
  , /\u6699/ // óŦ 0xF3AB
  , /\u3B0E/ // óŽ 0xF3AC
  , /\u66AD/ // ó­ 0xF3AD
  , /\u66B1/ // óŪ 0xF3AE
  , /\u66B5/ // óŊ 0xF3AF
  , /\u3B1A/ // ó° 0xF3B0
  , /\u66BF/ // óą 0xF3B1
  , /\u3B1C/ // óē 0xF3B2
  , /\u66EC/ // óģ 0xF3B3
  , /\u3AD7/ // óī 0xF3B4
  , /\u6701/ // óĩ 0xF3B5
  , /\u6705/ // óķ 0xF3B6
  , /\u6712/ // ó· 0xF3B7
  // , /\u23372/ // óļ 0xF3B8
  , /\ud84c\udf72/ // óļ 0xF3B8
  , /\u6719/ // óđ 0xF3B9
  // , /\u233D3/ // óš 0xF3BA
  , /\ud84c\udfd3/ // óš 0xF3BA
  // , /\u233D2/ // óŧ 0xF3BB
  , /\ud84c\udfd2/ // óŧ 0xF3BB
  , /\u674C/ // óž 0xF3BC
  , /\u674D/ // ó― 0xF3BD
  , /\u6754/ // óū 0xF3BE
  , /\u675D/ // óŋ 0xF3BF
  // , /\u233D0/ // óĀ 0xF3C0
  , /\ud84c\udfd0/ // óĀ 0xF3C0
  // , /\u233E4/ // óÁ 0xF3C1
  , /\ud84c\udfe4/ // óÁ 0xF3C1
  // , /\u233D5/ // óÂ 0xF3C2
  , /\ud84c\udfd5/ // óÂ 0xF3C2
  , /\u6774/ // óÃ 0xF3C3
  , /\u6776/ // óÄ 0xF3C4
  // , /\u233DA/ // óÅ 0xF3C5
  , /\ud84c\udfda/ // óÅ 0xF3C5
  , /\u6792/ // óÆ 0xF3C6
  // , /\u233DF/ // óĮ 0xF3C7
  , /\ud84c\udfdf/ // óĮ 0xF3C7
  , /\u8363/ // óČ 0xF3C8
  , /\u6810/ // óÉ 0xF3C9
  , /\u67B0/ // óĘ 0xF3CA
  , /\u67B2/ // óË 0xF3CB
  , /\u67C3/ // óĖ 0xF3CC
  , /\u67C8/ // óÍ 0xF3CD
  , /\u67D2/ // óÎ 0xF3CE
  , /\u67D9/ // óÏ 0xF3CF
  , /\u67DB/ // óÐ 0xF3D0
  , /\u67F0/ // óŅ 0xF3D1
  , /\u67F7/ // óŌ 0xF3D2
  // , /\u2344A/ // óÓ 0xF3D3
  , /\ud84d\udc4a/ // óÓ 0xF3D3
  // , /\u23451/ // óÔ 0xF3D4
  , /\ud84d\udc51/ // óÔ 0xF3D4
  // , /\u2344B/ // óÕ 0xF3D5
  , /\ud84d\udc4b/ // óÕ 0xF3D5
  , /\u6818/ // óÖ 0xF3D6
  , /\u681F/ // óŨ 0xF3D7
  , /\u682D/ // óØ 0xF3D8
  // , /\u23465/ // óŲ 0xF3D9
  , /\ud84d\udc65/ // óŲ 0xF3D9
  , /\u6833/ // óÚ 0xF3DA
  , /\u683B/ // óÛ 0xF3DB
  , /\u683E/ // óÜ 0xF3DC
  , /\u6844/ // óÝ 0xF3DD
  , /\u6845/ // óÞ 0xF3DE
  , /\u6849/ // óß 0xF3DF
  , /\u684C/ // óā 0xF3E0
  , /\u6855/ // óá 0xF3E1
  , /\u6857/ // óâ 0xF3E2
  , /\u3B77/ // óã 0xF3E3
  , /\u686B/ // óä 0xF3E4
  , /\u686E/ // óå 0xF3E5
  , /\u687A/ // óæ 0xF3E6
  , /\u687C/ // óį 0xF3E7
  , /\u6882/ // óč 0xF3E8
  , /\u6890/ // óé 0xF3E9
  , /\u6896/ // óę 0xF3EA
  , /\u3B6D/ // óë 0xF3EB
  , /\u6898/ // óė 0xF3EC
  , /\u6899/ // óí 0xF3ED
  , /\u689A/ // óî 0xF3EE
  , /\u689C/ // óï 0xF3EF
  , /\u68AA/ // óð 0xF3F0
  , /\u68AB/ // óņ 0xF3F1
  , /\u68B4/ // óō 0xF3F2
  , /\u68BB/ // óó 0xF3F3
  , /\u68FB/ // óô 0xF3F4
  // , /\u234E4/ // óõ 0xF3F5
  , /\ud84d\udce4/ // óõ 0xF3F5
  // , /\u2355A/ // óö 0xF3F6
  , /\ud84d\udd5a/ // óö 0xF3F6
  , /\uFA13/ // óũ 0xF3F7
  , /\u68C3/ // óø 0xF3F8
  , /\u68C5/ // óų 0xF3F9
  , /\u68CC/ // óú 0xF3FA
  , /\u68CF/ // óû 0xF3FB
  , /\u68D6/ // óü 0xF3FC
  , /\u68D9/ // ô@ 0xF440
  , /\u68E4/ // ôA 0xF441
  , /\u68E5/ // ôB 0xF442
  , /\u68EC/ // ôC 0xF443
  , /\u68F7/ // ôD 0xF444
  , /\u6903/ // ôE 0xF445
  , /\u6907/ // ôF 0xF446
  , /\u3B87/ // ôG 0xF447
  , /\u3B88/ // ôH 0xF448
  // , /\u23594/ // ôI 0xF449
  , /\ud84d\udd94/ // ôI 0xF449
  , /\u693B/ // ôJ 0xF44A
  , /\u3B8D/ // ôK 0xF44B
  , /\u6946/ // ôL 0xF44C
  , /\u6969/ // ôM 0xF44D
  , /\u696C/ // ôN 0xF44E
  , /\u6972/ // ôO 0xF44F
  , /\u697A/ // ôP 0xF450
  , /\u697F/ // ôQ 0xF451
  , /\u6992/ // ôR 0xF452
  , /\u3BA4/ // ôS 0xF453
  , /\u6996/ // ôT 0xF454
  , /\u6998/ // ôU 0xF455
  , /\u69A6/ // ôV 0xF456
  , /\u69B0/ // ôW 0xF457
  , /\u69B7/ // ôX 0xF458
  , /\u69BA/ // ôY 0xF459
  , /\u69BC/ // ôZ 0xF45A
  , /\u69C0/ // ô[ 0xF45B
  , /\u69D1/ // ô\ 0xF45C
  , /\u69D6/ // ô] 0xF45D
  // , /\u23639/ // ô^ 0xF45E
  , /\ud84d\ude39/ // ô^ 0xF45E
  // , /\u23647/ // ô_ 0xF45F
  , /\ud84d\ude47/ // ô_ 0xF45F
  , /\u6A30/ // ô` 0xF460
  // , /\u23638/ // ôa 0xF461
  , /\ud84d\ude38/ // ôa 0xF461
  // , /\u2363A/ // ôb 0xF462
  , /\ud84d\ude3a/ // ôb 0xF462
  , /\u69E3/ // ôc 0xF463
  , /\u69EE/ // ôd 0xF464
  , /\u69EF/ // ôe 0xF465
  , /\u69F3/ // ôf 0xF466
  , /\u3BCD/ // ôg 0xF467
  , /\u69F4/ // ôh 0xF468
  , /\u69FE/ // ôi 0xF469
  , /\u6A11/ // ôj 0xF46A
  , /\u6A1A/ // ôk 0xF46B
  , /\u6A1D/ // ôl 0xF46C
  // , /\u2371C/ // ôm 0xF46D
  , /\ud84d\udf1c/ // ôm 0xF46D
  , /\u6A32/ // ôn 0xF46E
  , /\u6A33/ // ôo 0xF46F
  , /\u6A34/ // ôp 0xF470
  , /\u6A3F/ // ôq 0xF471
  , /\u6A46/ // ôr 0xF472
  , /\u6A49/ // ôs 0xF473
  , /\u6A7A/ // ôt 0xF474
  , /\u6A4E/ // ôu 0xF475
  , /\u6A52/ // ôv 0xF476
  , /\u6A64/ // ôw 0xF477
  // , /\u2370C/ // ôx 0xF478
  , /\ud84d\udf0c/ // ôx 0xF478
  , /\u6A7E/ // ôy 0xF479
  , /\u6A83/ // ôz 0xF47A
  , /\u6A8B/ // ô{ 0xF47B
  , /\u3BF0/ // ô| 0xF47C
  , /\u6A91/ // ô} 0xF47D
  , /\u6A9F/ // ô~ 0xF47E
  , /\u6AA1/ // ô 0xF480
  // , /\u23764/ // ô 0xF481
  , /\ud84d\udf64/ // ô 0xF481
  , /\u6AAB/ // ô 0xF482
  , /\u6ABD/ // ô 0xF483
  , /\u6AC6/ // ô 0xF484
  , /\u6AD4/ // ô 0xF485
  , /\u6AD0/ // ô 0xF486
  , /\u6ADC/ // ô 0xF487
  , /\u6ADD/ // ô 0xF488
  // , /\u237FF/ // ô 0xF489
  , /\ud84d\udfff/ // ô 0xF489
  // , /\u237E7/ // ô 0xF48A
  , /\ud84d\udfe7/ // ô 0xF48A
  , /\u6AEC/ // ô 0xF48B
  , /\u6AF1/ // ô 0xF48C
  , /\u6AF2/ // ô 0xF48D
  , /\u6AF3/ // ô 0xF48E
  , /\u6AFD/ // ô 0xF48F
  // , /\u23824/ // ô 0xF490
  , /\ud84e\udc24/ // ô 0xF490
  , /\u6B0B/ // ô 0xF491
  , /\u6B0F/ // ô 0xF492
  , /\u6B10/ // ô 0xF493
  , /\u6B11/ // ô 0xF494
  // , /\u2383D/ // ô 0xF495
  , /\ud84e\udc3d/ // ô 0xF495
  , /\u6B17/ // ô 0xF496
  , /\u3C26/ // ô 0xF497
  , /\u6B2F/ // ô 0xF498
  , /\u6B4A/ // ô 0xF499
  , /\u6B58/ // ô 0xF49A
  , /\u6B6C/ // ô 0xF49B
  , /\u6B75/ // ô 0xF49C
  , /\u6B7A/ // ô 0xF49D
  , /\u6B81/ // ô 0xF49E
  , /\u6B9B/ // ô 0xF49F
  , /\u6BAE/ // ô  0xF4A0
  // , /\u23A98/ // ôĄ 0xF4A1
  , /\ud84e\ude98/ // ôĄ 0xF4A1
  , /\u6BBD/ // ôĒ 0xF4A2
  , /\u6BBE/ // ôĢ 0xF4A3
  , /\u6BC7/ // ôĪ 0xF4A4
  , /\u6BC8/ // ôĨ 0xF4A5
  , /\u6BC9/ // ôĶ 0xF4A6
  , /\u6BDA/ // ô§ 0xF4A7
  , /\u6BE6/ // ôĻ 0xF4A8
  , /\u6BE7/ // ôĐ 0xF4A9
  , /\u6BEE/ // ôŠ 0xF4AA
  , /\u6BF1/ // ôŦ 0xF4AB
  , /\u6C02/ // ôŽ 0xF4AC
  , /\u6C0A/ // ô­ 0xF4AD
  , /\u6C0E/ // ôŪ 0xF4AE
  , /\u6C35/ // ôŊ 0xF4AF
  , /\u6C36/ // ô° 0xF4B0
  , /\u6C3A/ // ôą 0xF4B1
  , /\ud84f\udc7f/ // ôē 0xF4B2
  , /\u6C3F/ // ôģ 0xF4B3
  , /\u6C4D/ // ôī 0xF4B4
  , /\u6C5B/ // ôĩ 0xF4B5
  , /\u6C6D/ // ôķ 0xF4B6
  , /\u6C84/ // ô· 0xF4B7
  , /\u6C89/ // ôļ 0xF4B8
  , /\u3CC3/ // ôđ 0xF4B9
  , /\u6C94/ // ôš 0xF4BA
  , /\u6C95/ // ôŧ 0xF4BB
  , /\u6C97/ // ôž 0xF4BC
  , /\u6CAD/ // ô― 0xF4BD
  , /\u6CC2/ // ôū 0xF4BE
  , /\u6CD0/ // ôŋ 0xF4BF
  , /\u3CD2/ // ôĀ 0xF4C0
  , /\u6CD6/ // ôÁ 0xF4C1
  , /\u6CDA/ // ôÂ 0xF4C2
  , /\u6CDC/ // ôÃ 0xF4C3
  , /\u6CE9/ // ôÄ 0xF4C4
  , /\u6CEC/ // ôÅ 0xF4C5
  , /\u6CED/ // ôÆ 0xF4C6
  // , /\u23D00/ // ôĮ 0xF4C7
  , /\ud84f\udd00/ // ôĮ 0xF4C7
  , /\u6D00/ // ôČ 0xF4C8
  , /\u6D0A/ // ôÉ 0xF4C9
  , /\u6D24/ // ôĘ 0xF4CA
  , /\u6D26/ // ôË 0xF4CB
  , /\u6D27/ // ôĖ 0xF4CC
  , /\u6C67/ // ôÍ 0xF4CD
  , /\u6D2F/ // ôÎ 0xF4CE
  , /\u6D3C/ // ôÏ 0xF4CF
  , /\u6D5B/ // ôÐ 0xF4D0
  , /\u6D5E/ // ôŅ 0xF4D1
  , /\u6D60/ // ôŌ 0xF4D2
  , /\u6D70/ // ôÓ 0xF4D3
  , /\u6D80/ // ôÔ 0xF4D4
  , /\u6D81/ // ôÕ 0xF4D5
  , /\u6D8A/ // ôÖ 0xF4D6
  , /\u6D8D/ // ôŨ 0xF4D7
  , /\u6D91/ // ôØ 0xF4D8
  , /\u6D98/ // ôŲ 0xF4D9
  // , /\u23D40/ // ôÚ 0xF4DA
  , /\ud84f\udd40/ // ôÚ 0xF4DA
  , /\u6E17/ // ôÛ 0xF4DB
  // , /\u23DFA/ // ôÜ 0xF4DC
  , /\ud84f\uddfa/ // ôÜ 0xF4DC
  // , /\u23DF9/ // ôÝ 0xF4DD
  , /\ud84f\uddf9/ // ôÝ 0xF4DD
  // , /\u23DD3/ // ôÞ 0xF4DE
  , /\ud84f\uddd3/ // ôÞ 0xF4DE
  , /\u6DAB/ // ôß 0xF4DF
  , /\u6DAE/ // ôā 0xF4E0
  , /\u6DB4/ // ôá 0xF4E1
  , /\u6DC2/ // ôâ 0xF4E2
  , /\u6D34/ // ôã 0xF4E3
  , /\u6DC8/ // ôä 0xF4E4
  , /\u6DCE/ // ôå 0xF4E5
  , /\u6DCF/ // ôæ 0xF4E6
  , /\u6DD0/ // ôį 0xF4E7
  , /\u6DDF/ // ôč 0xF4E8
  , /\u6DE9/ // ôé 0xF4E9
  , /\u6DF6/ // ôę 0xF4EA
  , /\u6E36/ // ôë 0xF4EB
  , /\u6E1E/ // ôė 0xF4EC
  , /\u6E22/ // ôí 0xF4ED
  , /\u6E27/ // ôî 0xF4EE
  , /\u3D11/ // ôï 0xF4EF
  , /\u6E32/ // ôð 0xF4F0
  , /\u6E3C/ // ôņ 0xF4F1
  , /\u6E48/ // ôō 0xF4F2
  , /\u6E49/ // ôó 0xF4F3
  , /\u6E4B/ // ôô 0xF4F4
  , /\u6E4C/ // ôõ 0xF4F5
  , /\u6E4F/ // ôö 0xF4F6
  , /\u6E51/ // ôũ 0xF4F7
  , /\u6E53/ // ôø 0xF4F8
  , /\u6E54/ // ôų 0xF4F9
  , /\u6E57/ // ôú 0xF4FA
  , /\u6E63/ // ôû 0xF4FB
  , /\u3D1E/ // ôü 0xF4FC
  , /\u6E93/ // õ@ 0xF540
  , /\u6EA7/ // õA 0xF541
  , /\u6EB4/ // õB 0xF542
  , /\u6EBF/ // õC 0xF543
  , /\u6EC3/ // õD 0xF544
  , /\u6ECA/ // õE 0xF545
  , /\u6ED9/ // õF 0xF546
  , /\u6F35/ // õG 0xF547
  , /\u6EEB/ // õH 0xF548
  , /\u6EF9/ // õI 0xF549
  , /\u6EFB/ // õJ 0xF54A
  , /\u6F0A/ // õK 0xF54B
  , /\u6F0C/ // õL 0xF54C
  , /\u6F18/ // õM 0xF54D
  , /\u6F25/ // õN 0xF54E
  , /\u6F36/ // õO 0xF54F
  , /\u6F3C/ // õP 0xF550
  // , /\u23F7E/ // õQ 0xF551
  , /\ud84f\udf7e/ // õQ 0xF551
  , /\u6F52/ // õR 0xF552
  , /\u6F57/ // õS 0xF553
  , /\u6F5A/ // õT 0xF554
  , /\u6F60/ // õU 0xF555
  , /\u6F68/ // õV 0xF556
  , /\u6F98/ // õW 0xF557
  , /\u6F7D/ // õX 0xF558
  , /\u6F90/ // õY 0xF559
  , /\u6F96/ // õZ 0xF55A
  , /\u6FBE/ // õ[ 0xF55B
  , /\u6F9F/ // õ\ 0xF55C
  , /\u6FA5/ // õ] 0xF55D
  , /\u6FAF/ // õ^ 0xF55E
  , /\u3D64/ // õ_ 0xF55F
  , /\u6FB5/ // õ` 0xF560
  , /\u6FC8/ // õa 0xF561
  , /\u6FC9/ // õb 0xF562
  , /\u6FDA/ // õc 0xF563
  , /\u6FDE/ // õd 0xF564
  , /\u6FE9/ // õe 0xF565
  // , /\u24096/ // õf 0xF566
  , /\ud850\udc96/ // õf 0xF566
  , /\u6FFC/ // õg 0xF567
  , /\u7000/ // õh 0xF568
  , /\u7007/ // õi 0xF569
  , /\u700A/ // õj 0xF56A
  , /\u7023/ // õk 0xF56B
  // , /\u24103/ // õl 0xF56C
  , /\ud850\udd03/ // õl 0xF56C
  , /\u7039/ // õm 0xF56D
  , /\u703A/ // õn 0xF56E
  , /\u703C/ // õo 0xF56F
  , /\u7043/ // õp 0xF570
  , /\u7047/ // õq 0xF571
  , /\u704B/ // õr 0xF572
  , /\u3D9A/ // õs 0xF573
  , /\u7054/ // õt 0xF574
  , /\u7065/ // õu 0xF575
  , /\u7069/ // õv 0xF576
  , /\u706C/ // õw 0xF577
  , /\u706E/ // õx 0xF578
  , /\u7076/ // õy 0xF579
  , /\u707E/ // õz 0xF57A
  , /\u7081/ // õ{ 0xF57B
  , /\u7086/ // õ| 0xF57C
  , /\u7095/ // õ} 0xF57D
  , /\u7097/ // õ~ 0xF57E
  , /\u70BB/ // õ 0xF580
  // , /\u241C6/ // õ 0xF581
  , /\ud850\uddc6/ // õ 0xF581
  , /\u709F/ // õ 0xF582
  , /\u70B1/ // õ 0xF583
  // , /\u241FE/ // õ 0xF584
  , /\ud850\uddfe/ // õ 0xF584
  , /\u70EC/ // õ 0xF585
  , /\u70CA/ // õ 0xF586
  , /\u70D1/ // õ 0xF587
  , /\u70D3/ // õ 0xF588
  , /\u70DC/ // õ 0xF589
  , /\u7103/ // õ 0xF58A
  , /\u7104/ // õ 0xF58B
  , /\u7106/ // õ 0xF58C
  , /\u7107/ // õ 0xF58D
  , /\u7108/ // õ 0xF58E
  , /\u710C/ // õ 0xF58F
  , /\u3DC0/ // õ 0xF590
  , /\u712F/ // õ 0xF591
  , /\u7131/ // õ 0xF592
  , /\u7150/ // õ 0xF593
  , /\u714A/ // õ 0xF594
  , /\u7153/ // õ 0xF595
  , /\u715E/ // õ 0xF596
  , /\u3DD4/ // õ 0xF597
  , /\u7196/ // õ 0xF598
  , /\u7180/ // õ 0xF599
  , /\u719B/ // õ 0xF59A
  , /\u71A0/ // õ 0xF59B
  , /\u71A2/ // õ 0xF59C
  , /\u71AE/ // õ 0xF59D
  , /\u71AF/ // õ 0xF59E
  , /\u71B3/ // õ 0xF59F
  // , /\u243BC/ // õ  0xF5A0
  , /\ud850\udfbc/ // õ  0xF5A0
  , /\u71CB/ // õĄ 0xF5A1
  , /\u71D3/ // õĒ 0xF5A2
  , /\u71D9/ // õĢ 0xF5A3
  , /\u71DC/ // õĪ 0xF5A4
  , /\u7207/ // õĨ 0xF5A5
  , /\u3E05/ // õĶ 0xF5A6
  , /\uFA49/ // õ§ 0xF5A7
  , /\u722B/ // õĻ 0xF5A8
  , /\u7234/ // õĐ 0xF5A9
  , /\u7238/ // õŠ 0xF5AA
  , /\u7239/ // õŦ 0xF5AB
  , /\u4E2C/ // õŽ 0xF5AC
  , /\u7242/ // õ­ 0xF5AD
  , /\u7253/ // õŪ 0xF5AE
  , /\u7257/ // õŊ 0xF5AF
  , /\u7263/ // õ° 0xF5B0
  // , /\u24629/ // õą 0xF5B1
  , /\ud851\ude29/ // õą 0xF5B1
  , /\u726E/ // õē 0xF5B2
  , /\u726F/ // õģ 0xF5B3
  , /\u7278/ // õī 0xF5B4
  , /\u727F/ // õĩ 0xF5B5
  , /\u728E/ // õķ 0xF5B6
  // , /\u246A5/ // õ· 0xF5B7
  , /\ud851\udea5/ // õ· 0xF5B7
  , /\u72AD/ // õļ 0xF5B8
  , /\u72AE/ // õđ 0xF5B9
  , /\u72B0/ // õš 0xF5BA
  , /\u72B1/ // õŧ 0xF5BB
  , /\u72C1/ // õž 0xF5BC
  , /\u3E60/ // õ― 0xF5BD
  , /\u72CC/ // õū 0xF5BE
  , /\u3E66/ // õŋ 0xF5BF
  , /\u3E68/ // õĀ 0xF5C0
  , /\u72F3/ // õÁ 0xF5C1
  , /\u72FA/ // õÂ 0xF5C2
  , /\u7307/ // õÃ 0xF5C3
  , /\u7312/ // õÄ 0xF5C4
  , /\u7318/ // õÅ 0xF5C5
  , /\u7319/ // õÆ 0xF5C6
  , /\u3E83/ // õĮ 0xF5C7
  , /\u7339/ // õČ 0xF5C8
  , /\u732C/ // õÉ 0xF5C9
  , /\u7331/ // õĘ 0xF5CA
  , /\u7333/ // õË 0xF5CB
  , /\u733D/ // õĖ 0xF5CC
  , /\u7352/ // õÍ 0xF5CD
  , /\u3E94/ // õÎ 0xF5CE
  , /\u736B/ // õÏ 0xF5CF
  , /\u736C/ // õÐ 0xF5D0
  // , /\u24896/ // õŅ 0xF5D1
  , /\ud852\udc96/ // õŅ 0xF5D1
  , /\u736E/ // õŌ 0xF5D2
  , /\u736F/ // õÓ 0xF5D3
  , /\u7371/ // õÔ 0xF5D4
  , /\u7377/ // õÕ 0xF5D5
  , /\u7381/ // õÖ 0xF5D6
  , /\u7385/ // õŨ 0xF5D7
  , /\u738A/ // õØ 0xF5D8
  , /\u7394/ // õŲ 0xF5D9
  , /\u7398/ // õÚ 0xF5DA
  , /\u739C/ // õÛ 0xF5DB
  , /\u739E/ // õÜ 0xF5DC
  , /\u73A5/ // õÝ 0xF5DD
  , /\u73A8/ // õÞ 0xF5DE
  , /\u73B5/ // õß 0xF5DF
  , /\u73B7/ // õā 0xF5E0
  , /\u73B9/ // õá 0xF5E1
  , /\u73BC/ // õâ 0xF5E2
  , /\u73BF/ // õã 0xF5E3
  , /\u73C5/ // õä 0xF5E4
  , /\u73CB/ // õå 0xF5E5
  , /\u73E1/ // õæ 0xF5E6
  , /\u73E7/ // õį 0xF5E7
  , /\u73F9/ // õč 0xF5E8
  , /\u7413/ // õé 0xF5E9
  , /\u73FA/ // õę 0xF5EA
  , /\u7401/ // õë 0xF5EB
  , /\u7424/ // õė 0xF5EC
  , /\u7431/ // õí 0xF5ED
  , /\u7439/ // õî 0xF5EE
  , /\u7453/ // õï 0xF5EF
  , /\u7440/ // õð 0xF5F0
  , /\u7443/ // õņ 0xF5F1
  , /\u744D/ // õō 0xF5F2
  , /\u7452/ // õó 0xF5F3
  , /\u745D/ // õô 0xF5F4
  , /\u7471/ // õõ 0xF5F5
  , /\u7481/ // õö 0xF5F6
  , /\u7485/ // õũ 0xF5F7
  , /\u7488/ // õø 0xF5F8
  // , /\u24A4D/ // õų 0xF5F9
  , /\ud852\ude4d/ // õų 0xF5F9
  , /\u7492/ // õú 0xF5FA
  , /\u7497/ // õû 0xF5FB
  , /\u7499/ // õü 0xF5FC
  , /\u74A0/ // ö@ 0xF640
  , /\u74A1/ // öA 0xF641
  , /\u74A5/ // öB 0xF642
  , /\u74AA/ // öC 0xF643
  , /\u74AB/ // öD 0xF644
  , /\u74B9/ // öE 0xF645
  , /\u74BB/ // öF 0xF646
  , /\u74BA/ // öG 0xF647
  , /\u74D6/ // öH 0xF648
  , /\u74D8/ // öI 0xF649
  , /\u74DE/ // öJ 0xF64A
  , /\u74EF/ // öK 0xF64B
  , /\u74EB/ // öL 0xF64C
  // , /\u24B56/ // öM 0xF64D
  , /\ud852\udf56/ // öM 0xF64D
  , /\u74FA/ // öN 0xF64E
  // , /\u24B6F/ // öO 0xF64F
  , /\ud852\udf6f/ // öO 0xF64F
  , /\u7520/ // öP 0xF650
  , /\u7524/ // öQ 0xF651
  , /\u752A/ // öR 0xF652
  , /\u3F57/ // öS 0xF653
  // , /\u24C16/ // öT 0xF654
  , /\ud853\udc16/ // öT 0xF654
  , /\u753D/ // öU 0xF655
  , /\u753E/ // öV 0xF656
  , /\u7540/ // öW 0xF657
  , /\u7548/ // öX 0xF658
  , /\u754E/ // öY 0xF659
  , /\u7550/ // öZ 0xF65A
  , /\u7552/ // ö[ 0xF65B
  , /\u756C/ // ö\ 0xF65C
  , /\u7572/ // ö] 0xF65D
  , /\u7571/ // ö^ 0xF65E
  , /\u757A/ // ö_ 0xF65F
  , /\u757D/ // ö` 0xF660
  , /\u757E/ // öa 0xF661
  , /\u7581/ // öb 0xF662
  // , /\u24D14/ // öc 0xF663
  , /\ud853\udd14/ // öc 0xF663
  , /\u758C/ // öd 0xF664
  , /\u3F75/ // öe 0xF665
  , /\u75A2/ // öf 0xF666
  , /\u3F77/ // ög 0xF667
  , /\u75B0/ // öh 0xF668
  , /\u75B7/ // öi 0xF669
  , /\u75BF/ // öj 0xF66A
  , /\u75C0/ // ök 0xF66B
  , /\u75C6/ // öl 0xF66C
  , /\u75CF/ // öm 0xF66D
  , /\u75D3/ // ön 0xF66E
  , /\u75DD/ // öo 0xF66F
  , /\u75DF/ // öp 0xF670
  , /\u75E0/ // öq 0xF671
  , /\u75E7/ // ör 0xF672
  , /\u75EC/ // ös 0xF673
  , /\u75EE/ // öt 0xF674
  , /\u75F1/ // öu 0xF675
  , /\u75F9/ // öv 0xF676
  , /\u7603/ // öw 0xF677
  , /\u7618/ // öx 0xF678
  , /\u7607/ // öy 0xF679
  , /\u760F/ // öz 0xF67A
  , /\u3FAE/ // ö{ 0xF67B
  // , /\u24E0E/ // ö| 0xF67C
  , /\ud853\ude0e/ // ö| 0xF67C
  , /\u7613/ // ö} 0xF67D
  , /\u761B/ // ö~ 0xF67E
  , /\u761C/ // ö 0xF680
  // , /\u24E37/ // ö 0xF681
  , /\ud853\ude37/ // ö 0xF681
  , /\u7625/ // ö 0xF682
  , /\u7628/ // ö 0xF683
  , /\u763C/ // ö 0xF684
  , /\u7633/ // ö 0xF685
  // , /\u24E6A/ // ö 0xF686
  , /\ud853\ude6a/ // ö 0xF686
  , /\u3FC9/ // ö 0xF687
  , /\u7641/ // ö 0xF688
  // , /\u24E8B/ // ö 0xF689
  , /\ud853\ude8b/ // ö 0xF689
  , /\u7649/ // ö 0xF68A
  , /\u7655/ // ö 0xF68B
  , /\u3FD7/ // ö 0xF68C
  , /\u766E/ // ö 0xF68D
  , /\u7695/ // ö 0xF68E
  , /\u769C/ // ö 0xF68F
  , /\u76A1/ // ö 0xF690
  , /\u76A0/ // ö 0xF691
  , /\u76A7/ // ö 0xF692
  , /\u76A8/ // ö 0xF693
  , /\u76AF/ // ö 0xF694
  // , /\u2504A/ // ö 0xF695
  , /\ud854\udc4a/ // ö 0xF695
  , /\u76C9/ // ö 0xF696
  // , /\u25055/ // ö 0xF697
  , /\ud854\udc55/ // ö 0xF697
  , /\u76E8/ // ö 0xF698
  , /\u76EC/ // ö 0xF699
  // , /\u25122/ // ö 0xF69A
  , /\ud854\udd22/ // ö 0xF69A
  , /\u7717/ // ö 0xF69B
  , /\u771A/ // ö 0xF69C
  , /\u772D/ // ö 0xF69D
  , /\u7735/ // ö 0xF69E
  // , /\u251A9/ // ö 0xF69F
  , /\ud854\udda9/ // ö 0xF69F
  , /\u4039/ // ö  0xF6A0
  // , /\u251E5/ // öĄ 0xF6A1
  , /\ud854\udde5/ // öĄ 0xF6A1
  , /\u251CD/ // öĒ 0xF6A2
  , /\ud854\uddcd/ // öĒ 0xF6A2
  , /\u7758/ // öĢ 0xF6A3
  , /\u7760/ // öĪ 0xF6A4
  , /\u776A/ // öĨ 0xF6A5
  // , /\u2521E/ // öĶ 0xF6A6
  , /\ud854\ude1e/ // öĶ 0xF6A6
  , /\u7772/ // ö§ 0xF6A7
  , /\u777C/ // öĻ 0xF6A8
  , /\u777D/ // öĐ 0xF6A9
  // , /\u2524C/ // öŠ 0xF6AA
  , /\ud854\ude4c/ // öŠ 0xF6AA
  , /\u4058/ // öŦ 0xF6AB
  , /\u779A/ // öŽ 0xF6AC
  , /\u779F/ // ö­ 0xF6AD
  , /\u77A2/ // öŪ 0xF6AE
  , /\u77A4/ // öŊ 0xF6AF
  , /\u77A9/ // ö° 0xF6B0
  , /\u77DE/ // öą 0xF6B1
  , /\u77DF/ // öē 0xF6B2
  , /\u77E4/ // öģ 0xF6B3
  , /\u77E6/ // öī 0xF6B4
  , /\u77EA/ // öĩ 0xF6B5
  , /\u77EC/ // öķ 0xF6B6
  , /\u4093/ // ö· 0xF6B7
  , /\u77F0/ // öļ 0xF6B8
  , /\u77F4/ // öđ 0xF6B9
  , /\u77FB/ // öš 0xF6BA
  // , /\u2542E/ // öŧ 0xF6BB
  , /\ud855\udc2e/ // öŧ 0xF6BB
  , /\u7805/ // öž 0xF6BC
  , /\u7806/ // ö― 0xF6BD
  , /\u7809/ // öū 0xF6BE
  , /\u780D/ // öŋ 0xF6BF
  , /\u7819/ // öĀ 0xF6C0
  , /\u7821/ // öÁ 0xF6C1
  , /\u782C/ // öÂ 0xF6C2
  , /\u7847/ // öÃ 0xF6C3
  , /\u7864/ // öÄ 0xF6C4
  , /\u786A/ // öÅ 0xF6C5
  // , /\u254D9/ // öÆ 0xF6C6
  , /\ud855\udcd9/ // öÆ 0xF6C6
  , /\u788A/ // öĮ 0xF6C7
  , /\u7894/ // öČ 0xF6C8
  , /\u78A4/ // öÉ 0xF6C9
  , /\u789D/ // öĘ 0xF6CA
  , /\u789E/ // öË 0xF6CB
  , /\u789F/ // öĖ 0xF6CC
  , /\u78BB/ // öÍ 0xF6CD
  , /\u78C8/ // öÎ 0xF6CE
  , /\u78CC/ // öÏ 0xF6CF
  , /\u78CE/ // öÐ 0xF6D0
  , /\u78D5/ // öŅ 0xF6D1
  , /\u78E0/ // öŌ 0xF6D2
  , /\u78E1/ // öÓ 0xF6D3
  , /\u78E6/ // öÔ 0xF6D4
  , /\u78F9/ // öÕ 0xF6D5
  , /\u78FA/ // öÖ 0xF6D6
  , /\u78FB/ // öŨ 0xF6D7
  , /\u78FE/ // öØ 0xF6D8
  // , /\u255A7/ // öŲ 0xF6D9
  , /\ud855\udda7/ // öŲ 0xF6D9
  , /\u7910/ // öÚ 0xF6DA
  , /\u791B/ // öÛ 0xF6DB
  , /\u7930/ // öÜ 0xF6DC
  , /\u7925/ // öÝ 0xF6DD
  , /\u793B/ // öÞ 0xF6DE
  , /\u794A/ // öß 0xF6DF
  , /\u7958/ // öā 0xF6E0
  , /\u795B/ // öá 0xF6E1
  , /\u4105/ // öâ 0xF6E2
  , /\u7967/ // öã 0xF6E3
  , /\u7972/ // öä 0xF6E4
  , /\u7994/ // öå 0xF6E5
  , /\u7995/ // öæ 0xF6E6
  , /\u7996/ // öį 0xF6E7
  , /\u799B/ // öč 0xF6E8
  , /\u79A1/ // öé 0xF6E9
  , /\u79A9/ // öę 0xF6EA
  , /\u79B4/ // öë 0xF6EB
  , /\u79BB/ // öė 0xF6EC
  , /\u79C2/ // öí 0xF6ED
  , /\u79C7/ // öî 0xF6EE
  , /\u79CC/ // öï 0xF6EF
  , /\u79CD/ // öð 0xF6F0
  , /\u79D6/ // öņ 0xF6F1
  , /\u4148/ // öō 0xF6F2
  // , /\u257A9/ // öó 0xF6F3
  , /\ud855\udfa9/ // öó 0xF6F3
  // , /\u257B4/ // öô 0xF6F4
  , /\ud855\udfb4/ // öô 0xF6F4
  , /\u414F/ // öõ 0xF6F5
  , /\u7A0A/ // öö 0xF6F6
  , /\u7A11/ // öũ 0xF6F7
  , /\u7A15/ // öø 0xF6F8
  , /\u7A1B/ // öų 0xF6F9
  , /\u7A1E/ // öú 0xF6FA
  , /\u4163/ // öû 0xF6FB
  , /\u7A2D/ // öü 0xF6FC
  , /\u7A38/ // ũ@ 0xF740
  , /\u7A47/ // ũA 0xF741
  , /\u7A4C/ // ũB 0xF742
  , /\u7A56/ // ũC 0xF743
  , /\u7A59/ // ũD 0xF744
  , /\u7A5C/ // ũE 0xF745
  , /\u7A5F/ // ũF 0xF746
  , /\u7A60/ // ũG 0xF747
  , /\u7A67/ // ũH 0xF748
  , /\u7A6A/ // ũI 0xF749
  , /\u7A75/ // ũJ 0xF74A
  , /\u7A78/ // ũK 0xF74B
  , /\u7A82/ // ũL 0xF74C
  , /\u7A8A/ // ũM 0xF74D
  , /\u7A90/ // ũN 0xF74E
  , /\u7AA3/ // ũO 0xF74F
  , /\u7AAC/ // ũP 0xF750
  // , /\u259D4/ // ũQ 0xF751
  , /\ud856\uddd4/ // ũQ 0xF751
  , /\u41B4/ // ũR 0xF752
  , /\u7AB9/ // ũS 0xF753
  , /\u7ABC/ // ũT 0xF754
  , /\u7ABE/ // ũU 0xF755
  , /\u41BF/ // ũV 0xF756
  , /\u7ACC/ // ũW 0xF757
  , /\u7AD1/ // ũX 0xF758
  , /\u7AE7/ // ũY 0xF759
  , /\u7AE8/ // ũZ 0xF75A
  , /\u7AF4/ // ũ[ 0xF75B
  // , /\u25AE4/ // ũ\ 0xF75C
  , /\ud856\udee4/ // ũ\ 0xF75C
  // , /\u25AE3/ // ũ] 0xF75D
  , /\ud856\udee3/ // ũ] 0xF75D
  , /\u7B07/ // ũ^ 0xF75E
  // , /\u25AF1/ // ũ_ 0xF75F
  , /\ud856\udef1/ // ũ_ 0xF75F
  , /\u7B3D/ // ũ` 0xF760
  , /\u7B27/ // ũa 0xF761
  , /\u7B2A/ // ũb 0xF762
  , /\u7B2E/ // ũc 0xF763
  , /\u7B2F/ // ũd 0xF764
  , /\u7B31/ // ũe 0xF765
  , /\u41E6/ // ũf 0xF766
  , /\u41F3/ // ũg 0xF767
  , /\u7B7F/ // ũh 0xF768
  , /\u7B41/ // ũi 0xF769
  , /\u41EE/ // ũj 0xF76A
  , /\u7B55/ // ũk 0xF76B
  , /\u7B79/ // ũl 0xF76C
  , /\u7B64/ // ũm 0xF76D
  , /\u7B66/ // ũn 0xF76E
  , /\u7B69/ // ũo 0xF76F
  , /\u7B73/ // ũp 0xF770
  // , /\u25BB2/ // ũq 0xF771
  , /\ud856\udfb2/ // ũq 0xF771
  , /\u4207/ // ũr 0xF772
  , /\u7B90/ // ũs 0xF773
  , /\u7B91/ // ũt 0xF774
  , /\u7B9B/ // ũu 0xF775
  , /\u420E/ // ũv 0xF776
  , /\u7BAF/ // ũw 0xF777
  , /\u7BB5/ // ũx 0xF778
  , /\u7BBC/ // ũy 0xF779
  , /\u7BC5/ // ũz 0xF77A
  , /\u7BCA/ // ũ{ 0xF77B
  // , /\u25C4B/ // ũ| 0xF77C
  , /\ud857\udc4b/ // ũ| 0xF77C
  // , /\u25C64/ // ũ} 0xF77D
  , /\ud857\udc64/ // ũ} 0xF77D
  , /\u7BD4/ // ũ~ 0xF77E
  , /\u7BD6/ // ũ 0xF780
  , /\u7BDA/ // ũ 0xF781
  , /\u7BEA/ // ũ 0xF782
  , /\u7BF0/ // ũ 0xF783
  , /\u7C03/ // ũ 0xF784
  , /\u7C0B/ // ũ 0xF785
  , /\u7C0E/ // ũ 0xF786
  , /\u7C0F/ // ũ 0xF787
  , /\u7C26/ // ũ 0xF788
  , /\u7C45/ // ũ 0xF789
  , /\u7C4A/ // ũ 0xF78A
  , /\u7C51/ // ũ 0xF78B
  , /\u7C57/ // ũ 0xF78C
  , /\u7C5E/ // ũ 0xF78D
  , /\u7C61/ // ũ 0xF78E
  , /\u7C69/ // ũ 0xF78F
  , /\u7C6E/ // ũ 0xF790
  , /\u7C6F/ // ũ 0xF791
  , /\u7C70/ // ũ 0xF792
  // , /\u25E2E/ // ũ 0xF793
  , /\ud857\ude2e/ // ũ 0xF793
  // , /\u25E56/ // ũ 0xF794
  , /\ud857\ude56/ // ũ 0xF794
  // , /\u25E65/ // ũ 0xF795
  , /\ud857\ude65/ // ũ 0xF795
  , /\u7CA6/ // ũ 0xF796
  // , /\u25E62/ // ũ 0xF797
  , /\ud857\ude62/ // ũ 0xF797
  , /\u7CB6/ // ũ 0xF798
  , /\u7CB7/ // ũ 0xF799
  , /\u7CBF/ // ũ 0xF79A
  // , /\u25ED8/ // ũ 0xF79B
  , /\ud857\uded8/ // ũ 0xF79B
  , /\u7CC4/ // ũ 0xF79C
  // , /\u25EC2/ // ũ 0xF79D
  , /\ud857\udec2/ // ũ 0xF79D
  , /\u7CC8/ // ũ 0xF79E
  , /\u7CCD/ // ũ 0xF79F
  // , /\u25EE8/ // ũ  0xF7A0
  , /\ud857\udee8/ // ũ  0xF7A0
  , /\u7CD7/ // ũĄ 0xF7A1
  // , /\u25F23/ // ũĒ 0xF7A2
  , /\ud857\udf23/ // ũĒ 0xF7A2
  , /\u7CE6/ // ũĢ 0xF7A3
  , /\u7CEB/ // ũĪ 0xF7A4
  // , /\u25F5C/ // ũĨ 0xF7A5
  , /\ud857\udf5c/ // ũĨ 0xF7A5
  , /\u7CF5/ // ũĶ 0xF7A6
  , /\u7D03/ // ũ§ 0xF7A7
  , /\u7D09/ // ũĻ 0xF7A8
  , /\u42C6/ // ũĐ 0xF7A9
  , /\u7D12/ // ũŠ 0xF7AA
  , /\u7D1E/ // ũŦ 0xF7AB
  // , /\u25FE0/ // ũŽ 0xF7AC
  , /\ud857\udfe0/ // ũŽ 0xF7AC
  // , /\u25FD4/ // ũ­ 0xF7AD
  , /\ud857\udfd4/ // ũ­ 0xF7AD
  , /\u7D3D/ // ũŪ 0xF7AE
  , /\u7D3E/ // ũŊ 0xF7AF
  , /\u7D40/ // ũ° 0xF7B0
  , /\u7D47/ // ũą 0xF7B1
  // , /\u2600C/ // ũē 0xF7B2
  , /\ud858\udc0c/ // ũē 0xF7B2
  // , /\u25FFB/ // ũģ 0xF7B3
  , /\ud857\udffb/ // ũģ 0xF7B3
  , /\u42D6/ // ũī 0xF7B4
  , /\u7D59/ // ũĩ 0xF7B5
  , /\u7D5A/ // ũķ 0xF7B6
  , /\u7D6A/ // ũ· 0xF7B7
  , /\u7D70/ // ũļ 0xF7B8
  , /\u42DD/ // ũđ 0xF7B9
  , /\u7D7F/ // ũš 0xF7BA
  // , /\u26017/ // ũŧ 0xF7BB
  , /\ud858\udc17/ // ũŧ 0xF7BB
  , /\u7D86/ // ũž 0xF7BC
  , /\u7D88/ // ũ― 0xF7BD
  , /\u7D8C/ // ũū 0xF7BE
  , /\u7D97/ // ũŋ 0xF7BF
  // , /\u26060/ // ũĀ 0xF7C0
  , /\ud858\udc60/ // ũĀ 0xF7C0
  , /\u7D9D/ // ũÁ 0xF7C1
  , /\u7DA7/ // ũÂ 0xF7C2
  , /\u7DAA/ // ũÃ 0xF7C3
  , /\u7DB6/ // ũÄ 0xF7C4
  , /\u7DB7/ // ũÅ 0xF7C5
  , /\u7DC0/ // ũÆ 0xF7C6
  , /\u7DD7/ // ũĮ 0xF7C7
  , /\u7DD9/ // ũČ 0xF7C8
  , /\u7DE6/ // ũÉ 0xF7C9
  , /\u7DF1/ // ũĘ 0xF7CA
  , /\u7DF9/ // ũË 0xF7CB
  , /\u4302/ // ũĖ 0xF7CC
  // , /\u260ED/ // ũÍ 0xF7CD
  , /\ud858\udced/ // ũÍ 0xF7CD
  , /\uFA58/ // ũÎ 0xF7CE
  , /\u7E10/ // ũÏ 0xF7CF
  , /\u7E17/ // ũÐ 0xF7D0
  , /\u7E1D/ // ũŅ 0xF7D1
  , /\u7E20/ // ũŌ 0xF7D2
  , /\u7E27/ // ũÓ 0xF7D3
  , /\u7E2C/ // ũÔ 0xF7D4
  , /\u7E45/ // ũÕ 0xF7D5
  , /\u7E73/ // ũÖ 0xF7D6
  , /\u7E75/ // ũŨ 0xF7D7
  , /\u7E7E/ // ũØ 0xF7D8
  , /\u7E86/ // ũŲ 0xF7D9
  , /\u7E87/ // ũÚ 0xF7DA
  , /\u432B/ // ũÛ 0xF7DB
  , /\u7E91/ // ũÜ 0xF7DC
  , /\u7E98/ // ũÝ 0xF7DD
  , /\u7E9A/ // ũÞ 0xF7DE
  , /\u4343/ // ũß 0xF7DF
  , /\u7F3C/ // ũā 0xF7E0
  , /\u7F3B/ // ũá 0xF7E1
  , /\u7F3E/ // ũâ 0xF7E2
  , /\u7F43/ // ũã 0xF7E3
  , /\u7F44/ // ũä 0xF7E4
  , /\u7F4F/ // ũå 0xF7E5
  , /\u34C1/ // ũæ 0xF7E6
  // , /\u26270/ // ũį 0xF7E7
  , /\ud858\ude70/ // ũį 0xF7E7
  , /\u7F52/ // ũč 0xF7E8
  // , /\u26286/ // ũé 0xF7E9
  , /\ud858\ude86/ // ũé 0xF7E9
  , /\u7F61/ // ũę 0xF7EA
  , /\u7F63/ // ũë 0xF7EB
  , /\u7F64/ // ũė 0xF7EC
  , /\u7F6D/ // ũí 0xF7ED
  , /\u7F7D/ // ũî 0xF7EE
  , /\u7F7E/ // ũï 0xF7EF
  // , /\u2634C/ // ũð 0xF7F0
  , /\ud858\udf4c/ // ũð 0xF7F0
  , /\u7F90/ // ũņ 0xF7F1
  , /\u517B/ // ũō 0xF7F2
  // , /\u23D0E/ // ũó 0xF7F3
  , /\ud84f\udd0e/ // ũó 0xF7F3
  , /\u7F96/ // ũô 0xF7F4
  , /\u7F9C/ // ũõ 0xF7F5
  , /\u7FAD/ // ũö 0xF7F6
  // , /\u26402/ // ũũ 0xF7F7
  , /\ud859\udc02/ // ũũ 0xF7F7
  , /\u7FC3/ // ũø 0xF7F8
  , /\u7FCF/ // ũų 0xF7F9
  , /\u7FE3/ // ũú 0xF7FA
  , /\u7FE5/ // ũû 0xF7FB
  , /\u7FEF/ // ũü 0xF7FC
  , /\u7FF2/ // ø@ 0xF840
  , /\u8002/ // øA 0xF841
  , /\u800A/ // øB 0xF842
  , /\u8008/ // øC 0xF843
  , /\u800E/ // øD 0xF844
  , /\u8011/ // øE 0xF845
  , /\u8016/ // øF 0xF846
  , /\u8024/ // øG 0xF847
  , /\u802C/ // øH 0xF848
  , /\u8030/ // øI 0xF849
  , /\u8043/ // øJ 0xF84A
  , /\u8066/ // øK 0xF84B
  , /\u8071/ // øL 0xF84C
  , /\u8075/ // øM 0xF84D
  , /\u807B/ // øN 0xF84E
  , /\u8099/ // øO 0xF84F
  , /\u809C/ // øP 0xF850
  , /\u80A4/ // øQ 0xF851
  , /\u80A7/ // øR 0xF852
  , /\u80B8/ // øS 0xF853
  , /\u2667E/ // øT 0xF854
  , /\u80C5/ // øU 0xF855
  , /\u80D5/ // øV 0xF856
  , /\u80D8/ // øW 0xF857
  , /\u80E6/ // øX 0xF858
  // , /\u266B0/ // øY 0xF859
  , /\ud859\udeb0/ // øY 0xF859
  , /\u810D/ // øZ 0xF85A
  , /\u80F5/ // ø[ 0xF85B
  , /\u80FB/ // ø\ 0xF85C
  , /\u43EE/ // ø] 0xF85D
  , /\u8135/ // ø^ 0xF85E
  , /\u8116/ // ø_ 0xF85F
  , /\u811E/ // ø` 0xF860
  , /\u43F0/ // øa 0xF861
  , /\u8124/ // øb 0xF862
  , /\u8127/ // øc 0xF863
  , /\u812C/ // ød 0xF864
  // , /\u2671D/ // øe 0xF865
  , /\ud859\udf1d/ // øe 0xF865
  , /\u813D/ // øf 0xF866
  , /\u4408/ // øg 0xF867
  , /\u8169/ // øh 0xF868
  , /\u4417/ // øi 0xF869
  , /\u8181/ // øj 0xF86A
  , /\u441C/ // øk 0xF86B
  , /\u8184/ // øl 0xF86C
  , /\u8185/ // øm 0xF86D
  , /\u4422/ // øn 0xF86E
  , /\u8198/ // øo 0xF86F
  , /\u81B2/ // øp 0xF870
  , /\u81C1/ // øq 0xF871
  , /\u81C3/ // ør 0xF872
  , /\u81D6/ // øs 0xF873
  , /\u81DB/ // øt 0xF874
  // , /\u268DD/ // øu 0xF875
  , /\ud85a\udcdd/ // øu 0xF875
  , /\u81E4/ // øv 0xF876
  // , /\u268EA/ // øw 0xF877
  , /\ud85a\udcea/ // øw 0xF877
  , /\u81EC/ // øx 0xF878
  // , /\u26951/ // øy 0xF879
  , /\ud85a\udd51/ // øy 0xF879
  , /\u81FD/ // øz 0xF87A
  , /\u81FF/ // ø{ 0xF87B
  // , /\u2696F/ // ø| 0xF87C
  , /\ud85a\udd6f/ // ø| 0xF87C
  , /\u8204/ // ø} 0xF87D
  // , /\u269DD/ // ø~ 0xF87E
  , /\ud85a\udddd/ // ø~ 0xF87E
  , /\u8219/ // ø 0xF880
  , /\u8221/ // ø 0xF881
  , /\u8222/ // ø 0xF882
  // , /\u26A1E/ // ø 0xF883
  , /\ud85a\ude1e/ // ø 0xF883
  , /\u8232/ // ø 0xF884
  , /\u8234/ // ø 0xF885
  , /\u823C/ // ø 0xF886
  , /\u8246/ // ø 0xF887
  , /\u8249/ // ø 0xF888
  , /\u8245/ // ø 0xF889
  // , /\u26A58/ // ø 0xF88A
  , /\ud85a\ude58/ // ø 0xF88A
  , /\u824B/ // ø 0xF88B
  , /\u4476/ // ø 0xF88C
  , /\u824F/ // ø 0xF88D
  , /\u447A/ // ø 0xF88E
  , /\u8257/ // ø 0xF88F
  // , /\u26A8C/ // ø 0xF890
  , /\ud85a\ude8c/ // ø 0xF890
  , /\u825C/ // ø 0xF891
  , /\u8263/ // ø 0xF892
  // , /\u26AB7/ // ø 0xF893
  , /\ud85a\udeb7/ // ø 0xF893
  , /\uFA5D/ // ø 0xF894
  , /\uFA5E/ // ø 0xF895
  , /\u8279/ // ø 0xF896
  , /\u4491/ // ø 0xF897
  , /\u827D/ // ø 0xF898
  , /\u827F/ // ø 0xF899
  , /\u8283/ // ø 0xF89A
  , /\u828A/ // ø 0xF89B
  , /\u8293/ // ø 0xF89C
  , /\u82A7/ // ø 0xF89D
  , /\u82A8/ // ø 0xF89E
  , /\u82B2/ // ø 0xF89F
  , /\u82B4/ // ø  0xF8A0
  , /\u82BA/ // øĄ 0xF8A1
  , /\u82BC/ // øĒ 0xF8A2
  , /\u82E2/ // øĢ 0xF8A3
  , /\u82E8/ // øĪ 0xF8A4
  , /\u82F7/ // øĨ 0xF8A5
  , /\u8307/ // øĶ 0xF8A6
  , /\u8308/ // ø§ 0xF8A7
  , /\u830C/ // øĻ 0xF8A8
  , /\u8354/ // øĐ 0xF8A9
  , /\u831B/ // øŠ 0xF8AA
  , /\u831D/ // øŦ 0xF8AB
  , /\u8330/ // øŽ 0xF8AC
  , /\u833C/ // ø­ 0xF8AD
  , /\u8344/ // øŪ 0xF8AE
  , /\u8357/ // øŊ 0xF8AF
  , /\u44BE/ // ø° 0xF8B0
  , /\u837F/ // øą 0xF8B1
  , /\u44D4/ // øē 0xF8B2
  , /\u44B3/ // øģ 0xF8B3
  , /\u838D/ // øī 0xF8B4
  , /\u8394/ // øĩ 0xF8B5
  , /\u8395/ // øķ 0xF8B6
  , /\u839B/ // ø· 0xF8B7
  , /\u839D/ // øļ 0xF8B8
  , /\u83C9/ // øđ 0xF8B9
  , /\u83D0/ // øš 0xF8BA
  , /\u83D4/ // øŧ 0xF8BB
  , /\u83DD/ // øž 0xF8BC
  , /\u83E5/ // ø― 0xF8BD
  , /\u83F9/ // øū 0xF8BE
  , /\u840F/ // øŋ 0xF8BF
  , /\u8411/ // øĀ 0xF8C0
  , /\u8415/ // øÁ 0xF8C1
  // , /\u26C73/ // øÂ 0xF8C2
  , /\ud85b\udc73/ // øÂ 0xF8C2
  , /\u8417/ // øÃ 0xF8C3
  , /\u8439/ // øÄ 0xF8C4
  , /\u844A/ // øÅ 0xF8C5
  , /\u844F/ // øÆ 0xF8C6
  , /\u8451/ // øĮ 0xF8C7
  , /\u8452/ // øČ 0xF8C8
  , /\u8459/ // øÉ 0xF8C9
  , /\u845A/ // øĘ 0xF8CA
  , /\u845C/ // øË 0xF8CB
  // , /\u26CDD/ // øĖ 0xF8CC
  , /\ud85b\udcdd/ // øĖ 0xF8CC
  , /\u8465/ // øÍ 0xF8CD
  , /\u8476/ // øÎ 0xF8CE
  , /\u8478/ // øÏ 0xF8CF
  , /\u847C/ // øÐ 0xF8D0
  , /\u8481/ // øŅ 0xF8D1
  , /\u450D/ // øŌ 0xF8D2
  , /\u84DC/ // øÓ 0xF8D3
  , /\u8497/ // øÔ 0xF8D4
  , /\u84A6/ // øÕ 0xF8D5
  , /\u84BE/ // øÖ 0xF8D6
  , /\u4508/ // øŨ 0xF8D7
  , /\u84CE/ // øØ 0xF8D8
  , /\u84CF/ // øŲ 0xF8D9
  , /\u84D3/ // øÚ 0xF8DA
  // , /\u26E65/ // øÛ 0xF8DB
  , /\ud85b\ude65/ // øÛ 0xF8DB
  , /\u84E7/ // øÜ 0xF8DC
  , /\u84EA/ // øÝ 0xF8DD
  , /\u84EF/ // øÞ 0xF8DE
  , /\u84F0/ // øß 0xF8DF
  , /\u84F1/ // øā 0xF8E0
  , /\u84FA/ // øá 0xF8E1
  , /\u84FD/ // øâ 0xF8E2
  , /\u850C/ // øã 0xF8E3
  , /\u851B/ // øä 0xF8E4
  , /\u8524/ // øå 0xF8E5
  , /\u8525/ // øæ 0xF8E6
  , /\u852B/ // øį 0xF8E7
  , /\u8534/ // øč 0xF8E8
  , /\u854F/ // øé 0xF8E9
  , /\u856F/ // øę 0xF8EA
  , /\u4525/ // øë 0xF8EB
  , /\u4543/ // øė 0xF8EC
  , /\u853E/ // øí 0xF8ED
  , /\u8551/ // øî 0xF8EE
  , /\u8553/ // øï 0xF8EF
  , /\u855E/ // øð 0xF8F0
  , /\u8561/ // øņ 0xF8F1
  , /\u8562/ // øō 0xF8F2
  // , /\u26F94/ // øó 0xF8F3
  , /\ud85b\udf94/ // øó 0xF8F3
  , /\u857B/ // øô 0xF8F4
  , /\u857D/ // øõ 0xF8F5
  , /\u857F/ // øö 0xF8F6
  , /\u8581/ // øũ 0xF8F7
  , /\u8586/ // øø 0xF8F8
  , /\u8593/ // øų 0xF8F9
  , /\u859D/ // øú 0xF8FA
  , /\u859F/ // øû 0xF8FB
  // , /\u26FF8/ // øü 0xF8FC
  , /\ud85b\udff8/ // øü 0xF8FC
  // , /\u26FF6/ // ų@ 0xF940
  , /\ud85b\udff6/ // ų@ 0xF940
  // , /\u26FF7/ // ųA 0xF941
  , /\ud85b\udff7/ // ųA 0xF941
  , /\u85B7/ // ųB 0xF942
  , /\u85BC/ // ųC 0xF943
  , /\u85C7/ // ųD 0xF944
  , /\u85CA/ // ųE 0xF945
  , /\u85D8/ // ųF 0xF946
  , /\u85D9/ // ųG 0xF947
  , /\u85DF/ // ųH 0xF948
  , /\u85E1/ // ųI 0xF949
  , /\u85E6/ // ųJ 0xF94A
  , /\u85F6/ // ųK 0xF94B
  , /\u8600/ // ųL 0xF94C
  , /\u8611/ // ųM 0xF94D
  , /\u861E/ // ųN 0xF94E
  , /\u8621/ // ųO 0xF94F
  , /\u8624/ // ųP 0xF950
  , /\u8627/ // ųQ 0xF951
  // , /\u2710D/ // ųR 0xF952
  , /\ud85c\udd0d/ // ųR 0xF952
  , /\u8639/ // ųS 0xF953
  , /\u863C/ // ųT 0xF954
  // , /\u27139/ // ųU 0xF955
  , /\ud85c\udd39/ // ųU 0xF955
  , /\u8640/ // ųV 0xF956
  , /\uFA20/ // ųW 0xF957
  , /\u8653/ // ųX 0xF958
  , /\u8656/ // ųY 0xF959
  , /\u866F/ // ųZ 0xF95A
  , /\u8677/ // ų[ 0xF95B
  , /\u867A/ // ų\ 0xF95C
  , /\u8687/ // ų] 0xF95D
  , /\u8689/ // ų^ 0xF95E
  , /\u868D/ // ų_ 0xF95F
  , /\u8691/ // ų` 0xF960
  , /\u869C/ // ųa 0xF961
  , /\u869D/ // ųb 0xF962
  , /\u86A8/ // ųc 0xF963
  , /\uFA21/ // ųd 0xF964
  , /\u86B1/ // ųe 0xF965
  , /\u86B3/ // ųf 0xF966
  , /\u86C1/ // ųg 0xF967
  , /\u86C3/ // ųh 0xF968
  , /\u86D1/ // ųi 0xF969
  , /\u86D5/ // ųj 0xF96A
  , /\u86D7/ // ųk 0xF96B
  , /\u86E3/ // ųl 0xF96C
  , /\u86E6/ // ųm 0xF96D
  , /\u45B8/ // ųn 0xF96E
  , /\u8705/ // ųo 0xF96F
  , /\u8707/ // ųp 0xF970
  , /\u870E/ // ųq 0xF971
  , /\u8710/ // ųr 0xF972
  , /\u8713/ // ųs 0xF973
  , /\u8719/ // ųt 0xF974
  , /\u871F/ // ųu 0xF975
  , /\u8721/ // ųv 0xF976
  , /\u8723/ // ųw 0xF977
  , /\u8731/ // ųx 0xF978
  , /\u873A/ // ųy 0xF979
  , /\u873E/ // ųz 0xF97A
  , /\u8740/ // ų{ 0xF97B
  , /\u8743/ // ų| 0xF97C
  , /\u8751/ // ų} 0xF97D
  , /\u8758/ // ų~ 0xF97E
  , /\u8764/ // ų 0xF980
  , /\u8765/ // ų 0xF981
  , /\u8772/ // ų 0xF982
  , /\u877C/ // ų 0xF983
  // , /\u273DB/ // ų 0xF984
  , /\ud85c\udfdb/ // ų 0xF984
  // , /\u273DA/ // ų 0xF985
  , /\ud85c\udfda/ // ų 0xF985
  , /\u87A7/ // ų 0xF986
  , /\u8789/ // ų 0xF987
  , /\u878B/ // ų 0xF988
  , /\u8793/ // ų 0xF989
  , /\u87A0/ // ų 0xF98A
  // , /\u273FE/ // ų 0xF98B
  , /\ud85c\udffe/ // ų 0xF98B
  , /\u45E5/ // ų 0xF98C
  , /\u87BE/ // ų 0xF98D
  // , /\u27410/ // ų 0xF98E
  , /\ud85d\udc10/ // ų 0xF98E
  , /\u87C1/ // ų 0xF98F
  , /\u87CE/ // ų 0xF990
  , /\u87F5/ // ų 0xF991
  , /\u87DF/ // ų 0xF992
  // , /\u27449/ // ų 0xF993
  , /\ud85d\udc49/ // ų 0xF993
  , /\u87E3/ // ų 0xF994
  , /\u87E5/ // ų 0xF995
  , /\u87E6/ // ų 0xF996
  , /\u87EA/ // ų 0xF997
  , /\u87EB/ // ų 0xF998
  , /\u87ED/ // ų 0xF999
  , /\u8801/ // ų 0xF99A
  , /\u8803/ // ų 0xF99B
  , /\u880B/ // ų 0xF99C
  , /\u8813/ // ų 0xF99D
  , /\u8828/ // ų 0xF99E
  , /\u882E/ // ų 0xF99F
  , /\u8832/ // ų  0xF9A0
  , /\u883C/ // ųĄ 0xF9A1
  , /\u460F/ // ųĒ 0xF9A2
  , /\u884A/ // ųĢ 0xF9A3
  , /\u8858/ // ųĪ 0xF9A4
  , /\u885F/ // ųĨ 0xF9A5
  , /\u8864/ // ųĶ 0xF9A6
  // , /\u27615/ // ų§ 0xF9A7
  , /\ud85d\ude15/ // ų§ 0xF9A7
  // , /\u27614/ // ųĻ 0xF9A8
  , /\ud85d\ude14/ // ųĻ 0xF9A8
  , /\u8869/ // ųĐ 0xF9A9
  // , /\u27631/ // ųŠ 0xF9AA
  , /\ud85d\ude31/ // ųŠ 0xF9AA
  , /\u886F/ // ųŦ 0xF9AB
  , /\u88A0/ // ųŽ 0xF9AC
  , /\u88BC/ // ų­ 0xF9AD
  , /\u88BD/ // ųŪ 0xF9AE
  , /\u88BE/ // ųŊ 0xF9AF
  , /\u88C0/ // ų° 0xF9B0
  , /\u88D2/ // ųą 0xF9B1
  // , /\u27693/ // ųē 0xF9B2
  , /\ud85d\ude93/ // ųē 0xF9B2
  , /\u88D1/ // ųģ 0xF9B3
  , /\u88D3/ // ųī 0xF9B4
  , /\u88DB/ // ųĩ 0xF9B5
  , /\u88F0/ // ųķ 0xF9B6
  , /\u88F1/ // ų· 0xF9B7
  , /\u4641/ // ųļ 0xF9B8
  , /\u8901/ // ųđ 0xF9B9
  // , /\u2770E/ // ųš 0xF9BA
  , /\ud85d\udf0e/ // ųš 0xF9BA
  , /\u8937/ // ųŧ 0xF9BB
  // , /\u27723/ // ųž 0xF9BC
  , /\ud85d\udf23/ // ųž 0xF9BC
  , /\u8942/ // ų― 0xF9BD
  , /\u8945/ // ųū 0xF9BE
  , /\u8949/ // ųŋ 0xF9BF
  // , /\u27752/ // ųĀ 0xF9C0
  , /\ud85d\udf52/ // ųĀ 0xF9C0
  , /\u4665/ // ųÁ 0xF9C1
  , /\u8962/ // ųÂ 0xF9C2
  , /\u8980/ // ųÃ 0xF9C3
  , /\u8989/ // ųÄ 0xF9C4
  , /\u8990/ // ųÅ 0xF9C5
  , /\u899F/ // ųÆ 0xF9C6
  , /\u89B0/ // ųĮ 0xF9C7
  , /\u89B7/ // ųČ 0xF9C8
  , /\u89D6/ // ųÉ 0xF9C9
  , /\u89D8/ // ųĘ 0xF9CA
  , /\u89EB/ // ųË 0xF9CB
  , /\u46A1/ // ųĖ 0xF9CC
  , /\u89F1/ // ųÍ 0xF9CD
  , /\u89F3/ // ųÎ 0xF9CE
  , /\u89FD/ // ųÏ 0xF9CF
  , /\u89FF/ // ųÐ 0xF9D0
  , /\u46AF/ // ųŅ 0xF9D1
  , /\u8A11/ // ųŌ 0xF9D2
  , /\u8A14/ // ųÓ 0xF9D3
  // , /\u27985/ // ųÔ 0xF9D4
  , /\ud85e\udd85/ // ųÔ 0xF9D4
  , /\u8A21/ // ųÕ 0xF9D5
  , /\u8A35/ // ųÖ 0xF9D6
  , /\u8A3E/ // ųŨ 0xF9D7
  , /\u8A45/ // ųØ 0xF9D8
  , /\u8A4D/ // ųŲ 0xF9D9
  , /\u8A58/ // ųÚ 0xF9DA
  , /\u8AAE/ // ųÛ 0xF9DB
  , /\u8A90/ // ųÜ 0xF9DC
  , /\u8AB7/ // ųÝ 0xF9DD
  , /\u8ABE/ // ųÞ 0xF9DE
  , /\u8AD7/ // ųß 0xF9DF
  , /\u8AFC/ // ųā 0xF9E0
  // , /\u27A84/ // ųá 0xF9E1
  , /\ud85e\ude84/ // ųá 0xF9E1
  , /\u8B0A/ // ųâ 0xF9E2
  , /\u8B05/ // ųã 0xF9E3
  , /\u8B0D/ // ųä 0xF9E4
  , /\u8B1C/ // ųå 0xF9E5
  , /\u8B1F/ // ųæ 0xF9E6
  , /\u8B2D/ // ųį 0xF9E7
  , /\u8B43/ // ųč 0xF9E8
  , /\u470C/ // ųé 0xF9E9
  , /\u8B51/ // ųę 0xF9EA
  , /\u8B5E/ // ųë 0xF9EB
  , /\u8B76/ // ųė 0xF9EC
  , /\u8B7F/ // ųí 0xF9ED
  , /\u8B81/ // ųî 0xF9EE
  , /\u8B8B/ // ųï 0xF9EF
  , /\u8B94/ // ųð 0xF9F0
  , /\u8B95/ // ųņ 0xF9F1
  , /\u8B9C/ // ųō 0xF9F2
  , /\u8B9E/ // ųó 0xF9F3
  , /\u8C39/ // ųô 0xF9F4
  // , /\u27BB3/ // ųõ 0xF9F5
  , /\ud85e\udfb3/ // ųõ 0xF9F5
  , /\u8C3D/ // ųö 0xF9F6
  // , /\u27BBE/ // ųũ 0xF9F7
  , /\ud85e\udfbe/ // ųũ 0xF9F7
  // , /\u27BC7/ // ųø 0xF9F8
  , /\ud85e\udfc7/ // ųø 0xF9F8
  , /\u8C45/ // ųų 0xF9F9
  , /\u8C47/ // ųú 0xF9FA
  , /\u8C4F/ // ųû 0xF9FB
  , /\u8C54/ // ųü 0xF9FC
  , /\u8C57/ // ú@ 0xFA40
  , /\u8C69/ // úA 0xFA41
  , /\u8C6D/ // úB 0xFA42
  , /\u8C73/ // úC 0xFA43
  // , /\u27CB8/ // úD 0xFA44
  , /\ud85f\udcb8/ // úD 0xFA44
  , /\u8C93/ // úE 0xFA45
  , /\u8C92/ // úF 0xFA46
  , /\u8C99/ // úG 0xFA47
  , /\u4764/ // úH 0xFA48
  , /\u8C9B/ // úI 0xFA49
  , /\u8CA4/ // úJ 0xFA4A
  , /\u8CD6/ // úK 0xFA4B
  , /\u8CD5/ // úL 0xFA4C
  , /\u8CD9/ // úM 0xFA4D
  // , /\u27DA0/ // úN 0xFA4E
  , /\ud85f\udda0/ // úN 0xFA4E
  , /\u8CF0/ // úO 0xFA4F
  , /\u8CF1/ // úP 0xFA50
  // , /\u27E10/ // úQ 0xFA51
  , /\ud85f\ude10/ // úQ 0xFA51
  , /\u8D09/ // úR 0xFA52
  , /\u8D0E/ // úS 0xFA53
  , /\u8D6C/ // úT 0xFA54
  , /\u8D84/ // úU 0xFA55
  , /\u8D95/ // úV 0xFA56
  , /\u8DA6/ // úW 0xFA57
  // , /\u27FB7/ // úX 0xFA58
  , /\ud85f\udfb7/ // úX 0xFA58
  , /\u8DC6/ // úY 0xFA59
  , /\u8DC8/ // úZ 0xFA5A
  , /\u8DD9/ // ú[ 0xFA5B
  , /\u8DEC/ // ú\ 0xFA5C
  , /\u8E0C/ // ú] 0xFA5D
  , /\u47FD/ // ú^ 0xFA5E
  , /\u8DFD/ // ú_ 0xFA5F
  , /\u8E06/ // ú` 0xFA60
  // , /\u2808A/ // úa 0xFA61
  , /\ud860\udc8a/ // úa 0xFA61
  , /\u8E14/ // úb 0xFA62
  , /\u8E16/ // úc 0xFA63
  , /\u8E21/ // úd 0xFA64
  , /\u8E22/ // úe 0xFA65
  , /\u8E27/ // úf 0xFA66
  // , /\u280BB/ // úg 0xFA67
  , /\ud860\udcbb/ // úg 0xFA67
  , /\u4816/ // úh 0xFA68
  , /\u8E36/ // úi 0xFA69
  , /\u8E39/ // új 0xFA6A
  , /\u8E4B/ // úk 0xFA6B
  , /\u8E54/ // úl 0xFA6C
  , /\u8E62/ // úm 0xFA6D
  , /\u8E6C/ // ún 0xFA6E
  , /\u8E6D/ // úo 0xFA6F
  , /\u8E6F/ // úp 0xFA70
  , /\u8E98/ // úq 0xFA71
  , /\u8E9E/ // úr 0xFA72
  , /\u8EAE/ // ús 0xFA73
  , /\u8EB3/ // út 0xFA74
  , /\u8EB5/ // úu 0xFA75
  , /\u8EB6/ // úv 0xFA76
  , /\u8EBB/ // úw 0xFA77
  // , /\u28282/ // úx 0xFA78
  , /\ud860\ude82/ // úx 0xFA78
  , /\u8ED1/ // úy 0xFA79
  , /\u8ED4/ // úz 0xFA7A
  , /\u484E/ // ú{ 0xFA7B
  , /\u8EF9/ // ú| 0xFA7C
  // , /\u282F3/ // ú} 0xFA7D
  , /\ud860\udef3/ // ú} 0xFA7D
  , /\u8F00/ // ú~ 0xFA7E
  , /\u8F08/ // ú 0xFA80
  , /\u8F17/ // ú 0xFA81
  , /\u8F2B/ // ú 0xFA82
  , /\u8F40/ // ú 0xFA83
  , /\u8F4A/ // ú 0xFA84
  , /\u8F58/ // ú 0xFA85
  // , /\u2840C/ // ú 0xFA86
  , /\ud861\udc0c/ // ú 0xFA86
  , /\u8FA4/ // ú 0xFA87
  , /\u8FB4/ // ú 0xFA88
  , /\uFA66/ // ú 0xFA89
  , /\u8FB6/ // ú 0xFA8A
  // , /\u28455/ // ú 0xFA8B
  , /\ud861\udc55/ // ú 0xFA8B
  , /\u8FC1/ // ú 0xFA8C
  , /\u8FC6/ // ú 0xFA8D
  , /\uFA24/ // ú 0xFA8E
  , /\u8FCA/ // ú 0xFA8F
  , /\u8FCD/ // ú 0xFA90
  , /\u8FD3/ // ú 0xFA91
  , /\u8FD5/ // ú 0xFA92
  , /\u8FE0/ // ú 0xFA93
  , /\u8FF1/ // ú 0xFA94
  , /\u8FF5/ // ú 0xFA95
  , /\u8FFB/ // ú 0xFA96
  , /\u9002/ // ú 0xFA97
  , /\u900C/ // ú 0xFA98
  , /\u9037/ // ú 0xFA99
  // , /\u2856B/ // ú 0xFA9A
  , /\ud861\udd6b/ // ú 0xFA9A
  , /\u9043/ // ú 0xFA9B
  , /\u9044/ // ú 0xFA9C
  , /\u905D/ // ú 0xFA9D
  // , /\u285C8/ // ú 0xFA9E
  , /\ud861\uddc8/ // ú 0xFA9E
  // , /\u285C9/ // ú 0xFA9F
  , /\ud861\uddc9/ // ú 0xFA9F
  , /\u9085/ // ú  0xFAA0
  , /\u908C/ // úĄ 0xFAA1
  , /\u9090/ // úĒ 0xFAA2
  , /\u961D/ // úĢ 0xFAA3
  , /\u90A1/ // úĪ 0xFAA4
  , /\u48B5/ // úĨ 0xFAA5
  , /\u90B0/ // úĶ 0xFAA6
  , /\u90B6/ // ú§ 0xFAA7
  , /\u90C3/ // úĻ 0xFAA8
  , /\u90C8/ // úĐ 0xFAA9
  // , /\u286D7/ // úŠ 0xFAAA
  , /\ud861\uded7/ // úŠ 0xFAAA
  , /\u90DC/ // úŦ 0xFAAB
  , /\u90DF/ // úŽ 0xFAAC
  // , /\u286FA/ // ú­ 0xFAAD
  , /\ud861\udefa/ // ú­ 0xFAAD
  , /\u90F6/ // úŪ 0xFAAE
  , /\u90F2/ // úŊ 0xFAAF
  , /\u9100/ // ú° 0xFAB0
  , /\u90EB/ // úą 0xFAB1
  , /\uFA11/ // 﨑 0xFAB1 (IBM拡張文字で引いたとき)
  , /\u90FE/ // úē 0xFAB2
  , /\u90FF/ // úģ 0xFAB3
  , /\u9104/ // úī 0xFAB4
  , /\u9106/ // úĩ 0xFAB5
  , /\u9118/ // úķ 0xFAB6
  , /\u911C/ // ú· 0xFAB7
  , /\u911E/ // úļ 0xFAB8
  , /\u9137/ // úđ 0xFAB9
  , /\u9139/ // úš 0xFABA
  , /\u913A/ // úŧ 0xFABB
  , /\u9146/ // úž 0xFABC
  , /\u9147/ // ú― 0xFABD
  , /\u9157/ // úū 0xFABE
  , /\u9159/ // úŋ 0xFABF
  , /\u9161/ // úĀ 0xFAC0
  , /\u9164/ // úÁ 0xFAC1
  , /\u9174/ // úÂ 0xFAC2
  , /\u9179/ // úÃ 0xFAC3
  , /\u9185/ // úÄ 0xFAC4
  , /\u918E/ // úÅ 0xFAC5
  , /\u91A8/ // úÆ 0xFAC6
  , /\u91AE/ // úĮ 0xFAC7
  , /\u91B3/ // úČ 0xFAC8
  , /\u91B6/ // úÉ 0xFAC9
  , /\u91C3/ // úĘ 0xFACA
  , /\u91C4/ // úË 0xFACB
  , /\u91DA/ // úĖ 0xFACC
  // , /\u28949/ // úÍ 0xFACD
  , /\ud862\udd49/ // úÍ 0xFACD
  // , /\u28946/ // úÎ 0xFACE
  , /\ud862\udd46/ // úÎ 0xFACE
  , /\u91EC/ // úÏ 0xFACF
  , /\u91EE/ // úÐ 0xFAD0
  , /\u9201/ // úŅ 0xFAD1
  , /\u920A/ // úŌ 0xFAD2
  , /\u9216/ // úÓ 0xFAD3
  , /\u9217/ // úÔ 0xFAD4
  // , /\u2896B/ // úÕ 0xFAD5
  , /\ud862\udd6b/ // úÕ 0xFAD5
  , /\u9233/ // úÖ 0xFAD6
  , /\u9242/ // úŨ 0xFAD7
  , /\u9247/ // úØ 0xFAD8
  , /\u924A/ // úŲ 0xFAD9
  , /\u924E/ // úÚ 0xFADA
  , /\u9251/ // úÛ 0xFADB
  , /\u9256/ // úÜ 0xFADC
  , /\u9259/ // úÝ 0xFADD
  , /\u9260/ // úÞ 0xFADE
  , /\u9261/ // úß 0xFADF
  , /\u9265/ // úā 0xFAE0
  , /\u9267/ // úá 0xFAE1
  , /\u9268/ // úâ 0xFAE2
  // , /\u28987/ // úã 0xFAE3
  , /\ud862\udd87/ // úã 0xFAE3
  // , /\u28988/ // úä 0xFAE4
  , /\ud862\udd88/ // úä 0xFAE4
  , /\u927C/ // úå 0xFAE5
  , /\u927D/ // úæ 0xFAE6
  , /\u927F/ // úį 0xFAE7
  , /\u9289/ // úč 0xFAE8
  , /\u928D/ // úé 0xFAE9
  , /\u9297/ // úę 0xFAEA
  , /\u9299/ // úë 0xFAEB
  , /\u929F/ // úė 0xFAEC
  , /\u92A7/ // úí 0xFAED
  , /\u92AB/ // úî 0xFAEE
  // , /\u289BA/ // úï 0xFAEF
  , /\ud862\uddba/ // úï 0xFAEF
  // , /\u289BB/ // úð 0xFAF0
  , /\ud862\uddbb/ // úð 0xFAF0
  , /\u92B2/ // úņ 0xFAF1
  , /\u92BF/ // úō 0xFAF2
  , /\u92C0/ // úó 0xFAF3
  , /\u92C6/ // úô 0xFAF4
  , /\u92CE/ // úõ 0xFAF5
  , /\u92D0/ // úö 0xFAF6
  , /\u92D7/ // úũ 0xFAF7
  , /\u92D9/ // úø 0xFAF8
  , /\u92E5/ // úų 0xFAF9
  , /\u92E7/ // úú 0xFAFA
  , /\u9311/ // úû 0xFAFB
  // , /\u28A1E/ // úü 0xFAFC
  , /\ud862\ude1e/ // úü 0xFAFC
  // , /\u28A29/ // û@ 0xFB40
  , /\ud862\ude29/ // û@ 0xFB40
  , /\u92F7/ // ûA 0xFB41
  , /\u92F9/ // ûB 0xFB42
  , /\u92FB/ // ûC 0xFB43
  , /\u9302/ // ûD 0xFB44
  , /\u930D/ // ûE 0xFB45
  , /\u9315/ // ûF 0xFB46
  , /\u931D/ // ûG 0xFB47
  , /\u931E/ // ûH 0xFB48
  , /\u9327/ // ûI 0xFB49
  , /\u9329/ // ûJ 0xFB4A
  // , /\u28A71/ // ûK 0xFB4B
  , /\ud862\ude71/ // ûK 0xFB4B
  // , /\u28A43/ // ûL 0xFB4C
  , /\ud862\ude43/ // ûL 0xFB4C
  , /\u9347/ // ûM 0xFB4D
  , /\u9351/ // ûN 0xFB4E
  , /\u9357/ // ûO 0xFB4F
  , /\u935A/ // ûP 0xFB50
  , /\u936B/ // ûQ 0xFB51
  , /\u9371/ // ûR 0xFB52
  , /\u9373/ // ûS 0xFB53
  , /\u93A1/ // ûT 0xFB54
  // , /\u28A99/ // ûU 0xFB55
  , /\ud862\ude99/ // ûU 0xFB55
  // , /\u28ACD/ // ûV 0xFB56
  , /\ud862\udecd/ // ûV 0xFB56
  , /\u9388/ // ûW 0xFB57
  , /\u938B/ // ûX 0xFB58
  , /\u938F/ // ûY 0xFB59
  , /\u939E/ // ûZ 0xFB5A
  , /\u93F5/ // û[ 0xFB5B
  // , /\u28AE4/ // û\ 0xFB5C
  , /\ud862\udee4/ // û\ 0xFB5C
  // , /\u28ADD/ // û] 0xFB5D
  , /\ud862\udedd/ // û] 0xFB5D
  , /\u93F1/ // û^ 0xFB5E
  , /\u93C1/ // û_ 0xFB5F
  , /\u93C7/ // û` 0xFB60
  , /\u93DC/ // ûa 0xFB61
  , /\u93E2/ // ûb 0xFB62
  , /\u93E7/ // ûc 0xFB63
  , /\u9409/ // ûd 0xFB64
  , /\u940F/ // ûe 0xFB65
  , /\u9416/ // ûf 0xFB66
  , /\u9417/ // ûg 0xFB67
  , /\u93FB/ // ûh 0xFB68
  , /\u9432/ // ûi 0xFB69
  , /\u9434/ // ûj 0xFB6A
  , /\u943B/ // ûk 0xFB6B
  , /\u9445/ // ûl 0xFB6C
  // , /\u28BC1/ // ûm 0xFB6D
  , /\ud862\udfc1/ // ûm 0xFB6D
  // , /\u28BEF/ // ûn 0xFB6E
  , /\ud862\udfef/ // ûn 0xFB6E
  , /\u946D/ // ûo 0xFB6F
  , /\u946F/ // ûp 0xFB70
  , /\u9578/ // ûq 0xFB71
  , /\u9579/ // ûr 0xFB72
  , /\u9586/ // ûs 0xFB73
  , /\u958C/ // ût 0xFB74
  , /\u958D/ // ûu 0xFB75
  // , /\u28D10/ // ûv 0xFB76
  , /\ud863\udd10/ // ûv 0xFB76
  , /\u95AB/ // ûw 0xFB77
  , /\u95B4/ // ûx 0xFB78
  // , /\u28D71/ // ûy 0xFB79
  , /\ud863\udd71/ // ûy 0xFB79
  , /\u95C8/ // ûz 0xFB7A
  // , /\u28DFB/ // û{ 0xFB7B
  , /\ud863\uddfb/ // û{ 0xFB7B
  // , /\u28E1F/ // û| 0xFB7C
  , /\ud863\ude1f/ // û| 0xFB7C
  , /\u962C/ // û} 0xFB7D
  , /\u9633/ // û~ 0xFB7E
  , /\u9634/ // û 0xFB80
  // , /\u28E36/ // û 0xFB81
  , /\ud863\ude36/ // û 0xFB81
  , /\u963C/ // û 0xFB82
  , /\u9641/ // û 0xFB83
  , /\u9661/ // û 0xFB84
  // , /\u28E89/ // û 0xFB85
  , /\ud863\ude89/ // û 0xFB85
  , /\u9682/ // û 0xFB86
  // , /\u28EEB/ // û 0xFB87
  , /\ud863\udeeb/ // û 0xFB87
  , /\u969A/ // û 0xFB88
  // , /\u28F32/ // û 0xFB89
  , /\ud863\udf32/ // û 0xFB89
  , /\u49E7/ // û 0xFB8A
  , /\u96A9/ // û 0xFB8B
  , /\u96AF/ // û 0xFB8C
  , /\u96B3/ // û 0xFB8D
  , /\u96BA/ // û 0xFB8E
  , /\u96BD/ // û 0xFB8F
  , /\u49FA/ // û 0xFB90
  // , /\u28FF8/ // û 0xFB91
  , /\ud863\udff8/ // û 0xFB91
  , /\u96D8/ // û 0xFB92
  , /\u96DA/ // û 0xFB93
  , /\u96DD/ // û 0xFB94
  , /\u4A04/ // û 0xFB95
  , /\u9714/ // û 0xFB96
  , /\u9723/ // û 0xFB97
  , /\u4A29/ // û 0xFB98
  , /\u9736/ // û 0xFB99
  , /\u9741/ // û 0xFB9A
  , /\u9747/ // û 0xFB9B
  , /\u9755/ // û 0xFB9C
  , /\u9757/ // û 0xFB9D
  , /\u975B/ // û 0xFB9E
  , /\u976A/ // û 0xFB9F
  // , /\u292A0/ // û  0xFBA0
  , /\ud864\udea0/ // û  0xFBA0
  // , /\u292B1/ // ûĄ 0xFBA1
  , /\ud864\udeb1/ // ûĄ 0xFBA1
  , /\u9796/ // ûĒ 0xFBA2
  , /\u979A/ // ûĢ 0xFBA3
  , /\u979E/ // ûĪ 0xFBA4
  , /\u97A2/ // ûĨ 0xFBA5
  , /\u97B1/ // ûĶ 0xFBA6
  , /\u97B2/ // û§ 0xFBA7
  , /\u97BE/ // ûĻ 0xFBA8
  , /\u97CC/ // ûĐ 0xFBA9
  , /\u97D1/ // ûŠ 0xFBAA
  , /\u97D4/ // ûŦ 0xFBAB
  , /\u97D8/ // ûŽ 0xFBAC
  , /\u97D9/ // û­ 0xFBAD
  , /\u97E1/ // ûŪ 0xFBAE
  , /\u97F1/ // ûŊ 0xFBAF
  , /\u9804/ // û° 0xFBB0
  , /\u980D/ // ûą 0xFBB1
  , /\u980E/ // ûē 0xFBB2
  , /\u9814/ // ûģ 0xFBB3
  , /\u9816/ // ûī 0xFBB4
  , /\u4ABC/ // ûĩ 0xFBB5
  // , /\u29490/ // ûķ 0xFBB6
  , /\ud865\udc90/ // ûķ 0xFBB6
  , /\u9823/ // û· 0xFBB7
  , /\u9832/ // ûļ 0xFBB8
  , /\u9833/ // ûđ 0xFBB9
  , /\u9825/ // ûš 0xFBBA
  , /\u9847/ // ûŧ 0xFBBB
  , /\u9866/ // ûž 0xFBBC
  , /\u98AB/ // û― 0xFBBD
  , /\u98AD/ // ûū 0xFBBE
  , /\u98B0/ // ûŋ 0xFBBF
  // , /\u295CF/ // ûĀ 0xFBC0
  , /\ud865\uddcf/ // ûĀ 0xFBC0
  , /\u98B7/ // ûÁ 0xFBC1
  , /\u98B8/ // ûÂ 0xFBC2
  , /\u98BB/ // ûÃ 0xFBC3
  , /\u98BC/ // ûÄ 0xFBC4
  , /\u98BF/ // ûÅ 0xFBC5
  , /\u98C2/ // ûÆ 0xFBC6
  , /\u98C7/ // ûĮ 0xFBC7
  , /\u98CB/ // ûČ 0xFBC8
  , /\u98E0/ // ûÉ 0xFBC9
  // , /\u2967F/ // ûĘ 0xFBCA
  , /\ud865\ude7f/ // ûĘ 0xFBCA
  , /\u98E1/ // ûË 0xFBCB
  , /\u98E3/ // ûĖ 0xFBCC
  , /\u98E5/ // ûÍ 0xFBCD
  , /\u98EA/ // ûÎ 0xFBCE
  , /\u98F0/ // ûÏ 0xFBCF
  , /\u98F1/ // ûÐ 0xFBD0
  , /\u98F3/ // ûŅ 0xFBD1
  , /\u9908/ // ûŌ 0xFBD2
  , /\u4B3B/ // ûÓ 0xFBD3
  // , /\u296F0/ // ûÔ 0xFBD4
  , /\ud865\udef0/ // ûÔ 0xFBD4
  , /\u9916/ // ûÕ 0xFBD5
  , /\u9917/ // ûÖ 0xFBD6
  // , /\u29719/ // ûŨ 0xFBD7
  , /\ud865\udf19/ // ûŨ 0xFBD7
  , /\u991A/ // ûØ 0xFBD8
  , /\u991B/ // ûŲ 0xFBD9
  , /\u991C/ // ûÚ 0xFBDA
  // , /\u29750/ // ûÛ 0xFBDB
  , /\ud865\udf50/ // ûÛ 0xFBDB
  , /\u9931/ // ûÜ 0xFBDC
  , /\u9932/ // ûÝ 0xFBDD
  , /\u9933/ // ûÞ 0xFBDE
  , /\u993A/ // ûß 0xFBDF
  , /\u993B/ // ûā 0xFBE0
  , /\u993C/ // ûá 0xFBE1
  , /\u9940/ // ûâ 0xFBE2
  , /\u9941/ // ûã 0xFBE3
  , /\u9946/ // ûä 0xFBE4
  , /\u994D/ // ûå 0xFBE5
  , /\u994E/ // ûæ 0xFBE6
  , /\u995C/ // ûį 0xFBE7
  , /\u995F/ // ûč 0xFBE8
  , /\u9960/ // ûé 0xFBE9
  , /\u99A3/ // ûę 0xFBEA
  , /\u99A6/ // ûë 0xFBEB
  , /\u99B9/ // ûė 0xFBEC
  , /\u99BD/ // ûí 0xFBED
  , /\u99BF/ // ûî 0xFBEE
  , /\u99C3/ // ûï 0xFBEF
  , /\u99C9/ // ûð 0xFBF0
  , /\u99D4/ // ûņ 0xFBF1
  , /\u99D9/ // ûō 0xFBF2
  , /\u99DE/ // ûó 0xFBF3
  // , /\u298C6/ // ûô 0xFBF4
  , /\ud866\udcc6/ // ûô 0xFBF4
  , /\u99F0/ // ûõ 0xFBF5
  , /\u99F9/ // ûö 0xFBF6
  , /\u99FC/ // ûũ 0xFBF7
  , /\u9A0A/ // ûø 0xFBF8
  , /\u9A11/ // ûų 0xFBF9
  , /\u9A16/ // ûú 0xFBFA
  , /\u9A1A/ // ûû 0xFBFB
  , /\u9A20/ // ûü 0xFBFC
  , /\u9AD9/ // 髙 0xFBFC (IBM拡張文字で引いたとき)
  , /\u9A31/ // ü@ 0xFC40
  , /\u9A36/ // üA 0xFC41
  , /\u9A44/ // üB 0xFC42
  , /\u9A4C/ // üC 0xFC43
  , /\u9A58/ // üD 0xFC44
  , /\u4BC2/ // üE 0xFC45
  , /\u9AAF/ // üF 0xFC46
  , /\u4BCA/ // üG 0xFC47
  , /\u9AB7/ // üH 0xFC48
  , /\u4BD2/ // üI 0xFC49
  , /\u9AB9/ // üJ 0xFC4A
  // , /\u29A72/ // üK 0xFC4B
  , /\ud866\ude72/ // üK 0xFC4B
  , /\u9AC6/ // üL 0xFC4C
  , /\u9AD0/ // üM 0xFC4D
  , /\u9AD2/ // üN 0xFC4E
  , /\u9AD5/ // üO 0xFC4F
  , /\u4BE8/ // üP 0xFC50
  , /\u9ADC/ // üQ 0xFC51
  , /\u9AE0/ // üR 0xFC52
  , /\u9AE5/ // üS 0xFC53
  , /\u9AE9/ // üT 0xFC54
  , /\u9B03/ // üU 0xFC55
  , /\u9B0C/ // üV 0xFC56
  , /\u9B10/ // üW 0xFC57
  , /\u9B12/ // üX 0xFC58
  , /\u9B16/ // üY 0xFC59
  , /\u9B1C/ // üZ 0xFC5A
  , /\u9B2B/ // ü[ 0xFC5B
  , /\u9B33/ // ü\ 0xFC5C
  , /\u9B3D/ // ü] 0xFC5D
  , /\u4C20/ // ü^ 0xFC5E
  , /\u9B4B/ // ü_ 0xFC5F
  , /\u9B63/ // ü` 0xFC60
  , /\u9B65/ // üa 0xFC61
  , /\u9B6B/ // üb 0xFC62
  , /\u9B6C/ // üc 0xFC63
  , /\u9B73/ // üd 0xFC64
  , /\u9B76/ // üe 0xFC65
  , /\u9B77/ // üf 0xFC66
  , /\u9BA6/ // üg 0xFC67
  , /\u9BAC/ // üh 0xFC68
  , /\u9BB1/ // üi 0xFC69
  // , /\u29DDB/ // üj 0xFC6A
  , /\ud867\udddb/ // üj 0xFC6A
  // , /\u29E3D/ // ük 0xFC6B
  , /\ud867\ude3d/ // ük 0xFC6B
  , /\u9BB2/ // ül 0xFC6C
  , /\u9BB8/ // üm 0xFC6D
  , /\u9BBE/ // ün 0xFC6E
  , /\u9BC7/ // üo 0xFC6F
  , /\u9BF3/ // üp 0xFC70
  , /\u9BD8/ // üq 0xFC71
  , /\u9BDD/ // ür 0xFC72
  , /\u9BE7/ // üs 0xFC73
  , /\u9BEA/ // üt 0xFC74
  , /\u9BEB/ // üu 0xFC75
  , /\u9BEF/ // üv 0xFC76
  , /\u9BEE/ // üw 0xFC77
  // , /\u29E15/ // üx 0xFC78
  , /\ud867\ude15/ // üx 0xFC78
  , /\u9BFA/ // üy 0xFC79
  // , /\u29E8A/ // üz 0xFC7A
  , /\ud867\ude8a/ // üz 0xFC7A
  , /\u9BF7/ // ü{ 0xFC7B
  // , /\u29E49/ // ü| 0xFC7C
  , /\ud867\ude49/ // ü| 0xFC7C
  , /\u9C16/ // ü} 0xFC7D
  , /\u9C18/ // ü~ 0xFC7E
  , /\u9C19/ // ü 0xFC80
  , /\u9C1A/ // ü 0xFC81
  , /\u9C1D/ // ü 0xFC82
  , /\u9C22/ // ü 0xFC83
  , /\u9C27/ // ü 0xFC84
  , /\u9C29/ // ü 0xFC85
  , /\u9C2A/ // ü 0xFC86
  // , /\u29EC4/ // ü 0xFC87
  , /\ud867\udec4/ // ü 0xFC87
  , /\u9C31/ // ü 0xFC88
  , /\u9C36/ // ü 0xFC89
  , /\u9C37/ // ü 0xFC8A
  , /\u9C45/ // ü 0xFC8B
  , /\u9C5C/ // ü 0xFC8C
  // , /\u29EE9/ // ü 0xFC8D
  , /\ud867\udee9/ // ü 0xFC8D
  , /\u9C49/ // ü 0xFC8E
  , /\u9C4A/ // ü 0xFC8F
  // , /\u29EDB/ // ü 0xFC90
  , /\ud867\udedb/ // ü 0xFC90
  , /\u9C54/ // ü 0xFC91
  , /\u9C58/ // ü 0xFC92
  , /\u9C5B/ // ü 0xFC93
  , /\u9C5D/ // ü 0xFC94
  , /\u9C5F/ // ü 0xFC95
  , /\u9C69/ // ü 0xFC96
  , /\u9C6A/ // ü 0xFC97
  , /\u9C6B/ // ü 0xFC98
  , /\u9C6D/ // ü 0xFC99
  , /\u9C6E/ // ü 0xFC9A
  , /\u9C70/ // ü 0xFC9B
  , /\u9C72/ // ü 0xFC9C
  , /\u9C75/ // ü 0xFC9D
  , /\u9C7A/ // ü 0xFC9E
  , /\u9CE6/ // ü 0xFC9F
  , /\u9CF2/ // ü  0xFCA0
  , /\u9D0B/ // üĄ 0xFCA1
  , /\u9D02/ // üĒ 0xFCA2
  // , /\u29FCE/ // üĢ 0xFCA3
  , /\ud867\udfce/ // üĢ 0xFCA3
  , /\u9D11/ // üĪ 0xFCA4
  , /\u9D17/ // üĨ 0xFCA5
  , /\u9D18/ // üĶ 0xFCA6
  // , /\u2A02F/ // ü§ 0xFCA7
  , /\ud868\udc2f/ // ü§ 0xFCA7
  , /\u4CC4/ // üĻ 0xFCA8
  // , /\u2A01A/ // üĐ 0xFCA9
  , /\ud868\udc1a/ // üĐ 0xFCA9
  , /\u9D32/ // üŠ 0xFCAA
  , /\u4CD1/ // üŦ 0xFCAB
  , /\u9D42/ // üŽ 0xFCAC
  , /\u9D4A/ // ü­ 0xFCAD
  , /\u9D5F/ // üŪ 0xFCAE
  , /\u9D62/ // üŊ 0xFCAF
  // , /\u2A0F9/ // ü° 0xFCB0
  , /\ud868\udcf9/ // ü° 0xFCB0
  , /\u9D69/ // üą 0xFCB1
  , /\u9D6B/ // üē 0xFCB2
  // , /\u2A082/ // üģ 0xFCB3
  , /\ud868\udc82/ // üģ 0xFCB3
  , /\u9D73/ // üī 0xFCB4
  , /\u9D76/ // üĩ 0xFCB5
  , /\u9D77/ // üķ 0xFCB6
  , /\u9D7E/ // ü· 0xFCB7
  , /\u9D84/ // üļ 0xFCB8
  , /\u9D8D/ // üđ 0xFCB9
  , /\u9D99/ // üš 0xFCBA
  , /\u9DA1/ // üŧ 0xFCBB
  , /\u9DBF/ // üž 0xFCBC
  , /\u9DB5/ // ü― 0xFCBD
  , /\u9DB9/ // üū 0xFCBE
  , /\u9DBD/ // üŋ 0xFCBF
  , /\u9DC3/ // üĀ 0xFCC0
  , /\u9DC7/ // üÁ 0xFCC1
  , /\u9DC9/ // üÂ 0xFCC2
  , /\u9DD6/ // üÃ 0xFCC3
  , /\u9DDA/ // üÄ 0xFCC4
  , /\u9DDF/ // üÅ 0xFCC5
  , /\u9DE0/ // üÆ 0xFCC6
  , /\u9DE3/ // üĮ 0xFCC7
  , /\u9DF4/ // üČ 0xFCC8
  , /\u4D07/ // üÉ 0xFCC9
  , /\u9E0A/ // üĘ 0xFCCA
  , /\u9E02/ // üË 0xFCCB
  , /\u9E0D/ // üĖ 0xFCCC
  , /\u9E19/ // üÍ 0xFCCD
  , /\u9E1C/ // üÎ 0xFCCE
  , /\u9E1D/ // üÏ 0xFCCF
  , /\u9E7B/ // üÐ 0xFCD0
  // , /\u22218/ // üŅ 0xFCD1
  , /\ud848\ude18/ // üŅ 0xFCD1
  , /\u9E80/ // üŌ 0xFCD2
  , /\u9E85/ // üÓ 0xFCD3
  , /\u9E9B/ // üÔ 0xFCD4
  , /\u9EA8/ // üÕ 0xFCD5
  // , /\u2A38C/ // üÖ 0xFCD6
  , /\ud868\udf8c/ // üÖ 0xFCD6
  , /\u9EBD/ // üŨ 0xFCD7
  // , /\u2A437/ // üØ 0xFCD8
  , /\ud869\udc37/ // üØ 0xFCD8
  , /\u9EDF/ // üŲ 0xFCD9
  , /\u9EE7/ // üÚ 0xFCDA
  , /\u9EEE/ // üÛ 0xFCDB
  , /\u9EFF/ // üÜ 0xFCDC
  , /\u9F02/ // üÝ 0xFCDD
  , /\u4D77/ // üÞ 0xFCDE
  , /\u9F03/ // üß 0xFCDF
  , /\u9F17/ // üā 0xFCE0
  , /\u9F19/ // üá 0xFCE1
  , /\u9F2F/ // üâ 0xFCE2
  , /\u9F37/ // üã 0xFCE3
  , /\u9F3A/ // üä 0xFCE4
  , /\u9F3D/ // üå 0xFCE5
  , /\u9F41/ // üæ 0xFCE6
  , /\u9F45/ // üį 0xFCE7
  , /\u9F46/ // üč 0xFCE8
  , /\u9F53/ // üé 0xFCE9
  , /\u9F55/ // üę 0xFCEA
  , /\u9F58/ // üë 0xFCEB
  // , /\u2A5F1/ // üė 0xFCEC
  , /\ud869\uddf1/ // üė 0xFCEC
  , /\u9F5D/ // üí 0xFCED
  // , /\u2A602/ // üî 0xFCEE
  , /\ud869\ude02/ // üî 0xFCEE
  , /\u9F69/ // üï 0xFCEF
  // , /\u2A61A/ // üð 0xFCF0
  , /\ud869\ude1a/ // üð 0xFCF0
  , /\u9F6D/ // üņ 0xFCF1
  , /\u9F70/ // üō 0xFCF2
  , /\u9F75/ // üó 0xFCF3
  // , /\u2A6B2/ // üô 0xFCF4
  , /\ud869\udeb2/ // üô 0xFCF4
]