import { EMansionPaymentStatusInfo } from '@/shared/classes/spf-api/mail/model/common/e-mansion-payment-status';
import { convertEMansionPaymentStatus } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { JudgeError } from '../common/judge-error';

const api = BaseApiService.api();

/** 支払状態登録API をコールするサービス */
export class SpfApiPaymentStatusAccessor {
  /**
   * 支払情報登録状態取得
   * @param memberId Portas会員ID
   * @return 支払情報登録状態
   */
  public static async getPaymentStatus(memberId: number): Promise<EMansionPaymentStatusInfo> {
    const response = await api.get<any>(`/common/e-mansion/oshiharai-touroku-joutai/${memberId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    if (!response) {
      throw new Error('支払情報登録状態なし');
    }

    return new EMansionPaymentStatusInfo({
      _status: convertEMansionPaymentStatus(response.data.status),
    });
  }
}
