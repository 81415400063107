<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ容量 変更</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ容量</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li class="stay">変更</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ホームページ容量 変更 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページ容量変更" />ホームページ容量変更</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">ホームページ容量を変更する FTPアカウント</h3>
            <p>「ホームページ容量」を変更する FTPアカウントと、現在のホームページ容量をご確認ください。</p>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>変更対象 FTPアカウント</th>
                  <td>
                    <b>{{ ftpAccount }}</b>
                  </td>
                </tr>
                <tr>
                  <th>現在のホームページ容量</th>
                  <td>
                    <b>{{ eMansionHomepageCapacity }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 class="service-h3">ホームページ容量の変更</h3>
            <p>50MB単位で容量を変更することができます。</p>
            <p>変更後のホームページ容量を選んでチェックを入れてください。</p>

            <table class="table-type2">
              <tbody>
                <tr>
                  <th>月額利用料</th>
                  <td>{{ fee }}円／50MBごと</td>
                </tr>
                <tr>
                  <th>事務手数料</th>
                  <td>{{ jimFee }}円／変更時</td>
                </tr>
                <tr>
                  <th>変更可能な<br />ホームページ容量<span class="req">必須</span></th>
                  <td>
                    <div v-for="homepageCapacity in homepageCapacityList" :key="homepageCapacity.key">
                      <label>
                        <input type="radio" name="homepageCapacity" v-model="eMansionHomepageCapacitySelected" :value="homepageCapacity" />
                        {{ homepageCapacity.value }}
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="blc">
          <ul class="ul-basic01">
            <li>容量変更お申し込み後、同月内は再変更できませんので予めご了承ください。</li>
          </ul>
        </div>
      </div>

      <!-- sblc -->
      <div class="sblc">
        <p>※ 価格は全て税込表示（消費税{{ tax }}％）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
      </div>
      <!-- /sblc -->
      <!-- blc -->
      <div class="blc">
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goEntry()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /contents -->
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'homepageCapacityInput',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    eMansionHomepageCapacitySelected: '', // 選択したホームページ容量
    homepageCapacityListCount: 0, // ホームページ容量追加要素数
    homepageCapacityList: new Array<{ key: string; value: string }>(), // ホームページ容量配列
    eMansionAccountId: -1, // e-mansion アカウントID
    eMansionHomepageId: -1, // e-mansion ホームページID
    fee: '', // 月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    hpQuota: '', //基本容量(MB)
    tax: '', // 消費税率
    errorMessages: new Array<string>(), // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
    isCampaign: false,
    NOT_SELECTED_MESSAGE: 'ホームページ容量が選択されていません。',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.isLoading = true;

      // 前画面からの情報をStoreから取得
      if (!this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId']) {
        // e-mansionアカウントID(Store)を取得できない場合、前画面へ戻る
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage-capacity/list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // 前画面情報の取得
      this.eMansionAccountId = this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId'];
      this.eMansionHomepageId = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageId'];
      this.ftpAccount = this.$store.getters['eMansionHomepageCapacityStore/ftpAccount'];
      this.eMansionHomepageCapacity = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacity'];
      this.fee = this.$store.getters['eMansionHomepageCapacityStore/fee'];
      this.jimFee = this.$store.getters['eMansionHomepageCapacityStore/jimFee'];
      this.maxQuota = this.$store.getters['eMansionHomepageCapacityStore/maxQuota'];
      this.hpQuota = this.$store.getters['eMansionHomepageCapacityStore/hpQuota'];
      this.isCampaign = this.$store.getters['eMansionHomepageCapacityStore/isCampaign'];

      this.tax = this.$store.getters['taxStore/tax'];
      // 現在日付の取得
      const currentDateObject = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // 初期表示APIよりデータを取得する
      const response = await SpfApiHomepageCapacityAccessor.getEMansionHomepageCapacityInput(
        this.eMansionAccountId,
        this.eMansionHomepageId,
        this.maxQuota,
        this.hpQuota,
        currentDateObject
      );
      // API処理結果にエラーメッセージがある場合
      if (response.errorMessages.length != 0) {
        for (const errMsg of response.errorMessages) {
          this.errorMessages.push(errMsg);
        }
      }

      // 画面項目セット
      this.homepageCapacityListCount = response.hpCapacityListCount;
      this.homepageCapacityList = response.hpCapacityList;

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    // 戻るボタンクリック
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion/homepage-capacity/list').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // 申し込みボタンクリック
    async goEntry() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // ホームページ容量の選択がない場合
      if (!this.eMansionHomepageCapacitySelected) {
        this.errorMessages.push(this.NOT_SELECTED_MESSAGE);
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.eMansionHomepageCapacitySelected);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.jimFee);
      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.maxQuota);
      this.$store.commit('eMansionHomepageCapacityStore/hpQuota', this.hpQuota);
      this.$store.commit('eMansionHomepageCapacityStore/isCampaign', this.isCampaign);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage-capacity/confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
