<template>
  <div class="platform-cancel-impossible">
    <main class="underlayer-main">
      <h1>Portas会員退会 ご案内</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>会員退会 ご案内</li>
      </ul>

      <div class="blc" v-if="errorStatus">
        <error-messages-component v-if="errorMessages.length > 0" :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />
      </div>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Portas会員からの退会に関するご注意" />Portas会員からの退会に関するご注意</h2>

        <div v-if="isMounted">
          <div v-if="activeOrUnpaidBillingProductNameList.length !== 0">
            <p>
              現在ご契約中、または解約のお申し込みをいただいたサービスにつきまして、お支払いが完了していないサービスがあります。<br />
              サービス解約、お支払い完了後、あらためて退会手続きを行ってください。
            </p>

            <div>
              <ul>
                <li class="pd10 mb10" v-for="(activeOrUnpaidBillingProductName, index) in activeOrUnpaidBillingProductNameList" :key="index">
                  <span v-html="activeOrUnpaidBillingProductName"></span>
                </li>
              </ul>
            </div>

            <div>
              <span>
                <p>
                  ※ Portasサービス内のご契約で、ご請求が残っている場合、Portas会員からの退会はできません。<br />
                  ※ Portas退会後、Portasにログインできなくなります。Portasサービスのご請求明細等が確認できなくなりますので、ご注意ください。<br />
                </p>
              </span>
            </div>
          </div>

          <div v-bind:class="{ mt20: hasMultipleFactorsNotAbleToCancel }" v-if="linkedServices.length !== 0 && !linkedServiceGameWith">
            <p>Portas（ポルタス）を退会するためには、先に以下のサービスを退会する必要があります。</p>

            <div v-if="linkedServices.length !== 0">
              <ul>
                <li v-for="(linkedService, index) in linkedServices" :key="index">
                  <router-link class="link" target="_self" v-if="linkedService === SERVICE_NAME.E_MANSION" to="/e-mansion">{{ SERVICE_NAME.E_MANSION }}</router-link>
                  <router-link class="link" target="_self" v-if="linkedService === SERVICE_NAME.UCOM" to="/ucom">{{ SERVICE_NAME.UCOM }}</router-link>
                  <router-link class="link" target="_self" v-if="linkedService === SERVICE_NAME.FIVE_A" to="/5a">{{ SERVICE_NAME.FIVE_A }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="linkedServices.length !== 0">
          <span v-for="(linkedService, index) in linkedServices" :key="index">
            <p v-if="linkedService !== SERVICE_NAME.GAME_WITH">上記のサービス退会後、あらためてPortas退会のお申し込みをお願いいたします。</p>
            <p v-else>GameWith光を解約後、あらためてPortas退会のお手続きをお願いいたします。</p>
          </span>
        </div>

        <div v-if="linkedServices.length !== 0">
          <span v-for="(linkedService, index) in linkedServices" :key="index">
            <p v-if="linkedService === SERVICE_NAME.E_MANSION">
              ※ e-mansion退会後、13か月間はPortasにログインし、ご請求明細をご確認いただけます。<br />
              ※ Portas退会のお申し込みは別途必要です。e-mansionの退会お申し込み後、再度Portasマイページからお申し込みください。<br />
              ※ Portas退会後、Portasにログインできなくなります。e-mansion退会後13か月間以内の場合、以下の「My e-mansion」にログインしてご請求明細を確認いただけます。<br />
              ログインには、e-mansionのアカウントとパスワードを入力してください。
            </p>
            <p v-if="linkedService === SERVICE_NAME.UCOM">
              ※ UCOM光 レジデンス退会後、13か月間はPortasにログインし、ご請求明細をご確認いただけます。<br />
              ※ Portas退会のお申し込みは別途必要です。UCOM光レジデンスの退会お申し込み後、再度Portasマイページからお申し込みください。<br />
              ※ Portas退会後、Portasにログインできなくなります。UCOM光 レジデンス退会後13か月間以内の場合、<br />
              以下の「UCOM光 レジデンス マイページ」にログインしてご請求明細を確認いただけます。<br />
              ログインには、UCOM光 レジデンスのお客様番号とパスワードを入力してください。
            </p>
            <p v-if="linkedService === SERVICE_NAME.FIVE_A">
              ※ Five.A退会後、13か月間はPortasにログインし、ご請求明細をご確認いただけます。<br />
              ※ Portas退会のお申し込みは別途必要です。Five.Aの退会お申し込み後、再度Portasマイページからお申し込みください。<br />
              ※ Portas退会後、Portasにログインできなくなります。Five.A退会後13か月間以内で請求明細を確認したい場合、建物専用サイトからお問い合わせください。
            </p>
            <p v-if="linkedService === SERVICE_NAME.GAME_WITH">
              ※Portas退会のお申し込みは別途必要です。GameWith光インターネットサービスの解約手続き完了後、再度Portasマイページからお申し込みください。<br />
              ※Portas退会後、Portas内のGameWith光マイページにログインできなくなります。GameWith光インターネットサービスのご請求明細も表示できなくなりますので予めご了承ください。
            </p>
          </span>
          <div v-if="!linkedServiceGameWith">
            <ul>
              <li v-for="(linkedService, index) in linkedServices" :key="index">
                <a
                  class="link"
                  :href="`${eMansionMyUrl}/login/login.php?function=INVOICE_DETAIL_INFO&apid=${apid}&rd=${rd}`"
                  v-if="linkedService === SERVICE_NAME.E_MANSION"
                  target="_blank"
                  >{{ 'My e-mansion' }}</a
                >
                <a class="link" :href="`${ucomMyUrl}`" v-if="linkedService === SERVICE_NAME.UCOM" target="_blank">{{ 'UCOM光 レジデンス マイページ' }}</a>
                <a class="link" :href="`${fiveAUrl}/${apid}`" v-if="linkedService === SERVICE_NAME.FIVE_A" target="_blank">{{ '建物専用サイト' }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiService } from '../../../shared/services/api/spf-api-service';

import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_NAME, UNEXT_CONTENTS_ID } from '@/shared/const/service-type';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UnpaidBillingService } from '@/shared/services/portas-subscription/unpaid-billing-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Impossible コンポーネント */
export default defineComponent({
  name: 'platform-cancel-impossible',
  components: {
    ErrorMessagesComponent,
  },
  data: () => ({
    /** 連携されているサービス情報 */
    linkedServices: [] as string[],
    /** 利用中、または請求未実施の契約に紐づく商品名一覧 */
    activeOrUnpaidBillingProductNameList: [] as string[],
    /** スタイル制御用 利用中または請求未実施の契約が存在するかつ連携中のサービスが存在するか */
    hasMultipleFactorsNotAbleToCancel: false,
    SERVICE_NAME,
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    ucomMyUrl: process.env.VUE_APP_UCOM_MY_URL,
    fiveAUrl: process.env.VUE_APP_FIVE_A_SERVICE_DOMAIN_NAME,
    apid: '',
    rd: '',
    property: null as Property | null,
    isSubmitting: false,
    memberStatus: null as MemberStatus | null,
    member: null as Member | null,
    isMounted: false as boolean,
    gwid: '',
    linkedServiceGameWith: false,
    errorMessages: [] as string[],
    errorStatus: false,
    errorMessageTitle: '',
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    this.memberStatus = this.$store.getters['memberStore/memberStatus'];
    // 直リンク対策
    if (!this.memberStatus) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // GW光会員のステータスチェック
    if (this.memberStatus.encryptedPrimaryKeyGw) {
      await this.checkGWStatus();
    }

    this.member = this.$store.getters['memberStore/member'];
    if (!this.member) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    this.property = this.$store.getters['propertyStore/property'];
    if (this.property?.apartmentId) {
      this.apid = convert6DigitIdTo8DigitId(this.property?.apartmentId);
    }

    // 連携されているサービス情報をStoreから取得
    this.linkedServices = this.$store.getters['platformCancelStore/linkedServices'];
    if (!this.linkedServices.length) {
      // 退会要件を満たすかどうか調べる
      const canCancelResult = await Promise.all([
        AccountUtilService.canCancelUcom(),
        AccountUtilService.canCancelEmansion(),
        AccountUtilService.canCancelFiveA(),
        AccountUtilService.canCancelGameWith(),
      ]);

      // 外部APIがエラーのとき
      const foundIndex = canCancelResult.findIndex((value) => value instanceof Error);

      if (foundIndex === 0) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      } else if (foundIndex === 1) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      } else if (foundIndex === 2) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
      } else if (foundIndex === 3) {
        if ((canCancelResult[3] as any).response?.data.status_code == '503') {
          this.errorMessages.push(
            'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
          );
          this.errorMessageTitle = '';
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
        }
      }

      const canCancelFlagUcom = typeof canCancelResult[0] === 'boolean' ? canCancelResult[0] : true;
      const canCancelFlagEmansion = typeof canCancelResult[1] === 'boolean' ? canCancelResult[1] : true;
      const canCancelFlagFiveA = typeof canCancelResult[2] === 'boolean' ? canCancelResult[2] : true;
      const canCancelGameWith = typeof canCancelResult[3] === 'boolean' ? canCancelResult[3] : true;

      if (!canCancelFlagUcom) this.linkedServices.push(SERVICE_NAME.UCOM);
      if (!canCancelFlagEmansion) this.linkedServices.push(SERVICE_NAME.E_MANSION);
      if (!canCancelFlagFiveA) this.linkedServices.push(SERVICE_NAME.FIVE_A);
      if (!canCancelGameWith) this.linkedServices.push(SERVICE_NAME.GAME_WITH);
    }
    if (this.linkedServices.includes(SERVICE_NAME.GAME_WITH)) {
      this.linkedServiceGameWith = true;
    }

    // サブスクリプション一覧取得APIを実行
    const subscriptionList = await SpfApiService.getSubscriptionList(this.member!.id);

    if (subscriptionList.contracts.length > 0) {
      /** 利用中(解約日が未設定)のサブスクリプション情報 */
      const activeSubscriptionDetailList: SubscriptionDetailsDto[] = [];

      subscriptionList.contracts.forEach((e) => {
        if (e.cancelDate === null || e.cancelDate === '') {
          activeSubscriptionDetailList.push(e);
        }
      });

      /** 請求未実施のサブスクリプション情報 */
      const unpaidBillingSubscriptionList = await UnpaidBillingService.filterUnpaidBillingSubscription(subscriptionList);

      this.activeOrUnpaidBillingProductNameList = activeSubscriptionDetailList
        .concat(unpaidBillingSubscriptionList)
        .filter((subscriptionDetail) => subscriptionDetail.contents.contentsId !== UNEXT_CONTENTS_ID.MONTHLY_PLAN)
        .sort((a, b) => a.productsId - b.productsId)
        .map((activeOrUnpaidBillingSubscriptionDetail) => activeOrUnpaidBillingSubscriptionDetail.products.productsName ?? '');

      if (this.activeOrUnpaidBillingProductNameList.length !== 0 && this.linkedServices.length !== 0) {
        this.hasMultipleFactorsNotAbleToCancel = true;
      }
    }

    // U-NEXT月額プランの契約について、退会要件を満たさないものがあるとき、U-NEXT月額プランの商品名を表示する
    if (!(await AccountUtilService.canCancelUNext(subscriptionList))) {
      const uNextMonthlyProductList = subscriptionList.contracts.filter((contract) => {
        return contract.contents.contentsId === UNEXT_CONTENTS_ID.MONTHLY_PLAN;
      });

      // すべてU-NEXT月額プランの契約なので、すべての要素の商品名が同じ想定
      this.activeOrUnpaidBillingProductNameList.push(uNextMonthlyProductList[0].products.productsName ?? '');
    }

    // 退会要件を満たさないサービスが無い、かつ利用中または請求未実施の契約が存在しない場合は退会の案内画面へ遷移する
    if (this.linkedServices.length === 0 && this.activeOrUnpaidBillingProductNameList.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/cancel/guide').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    this.rd = this.createRd();

    this.isMounted = true;
  },
  methods: {
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    /**
     *GameWith会員ステータスチェック
     */
    async checkGWStatus() {
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!memberStatus.encryptedPrimaryKeyGw) {
        //データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }
      try {
        const gamewithCustomer = await SpfApiExternalGamewithAccessor.getCustomer(memberStatus.encryptedPrimaryKeyGw);
        if (gamewithCustomer == null) {
          //503以外は共通エラーに遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/error').catch((error: any) => {
            checkRouterError(error);
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response?.status == '503') {
          this.errorMessages.push(
            'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
          );
          this.errorStatus = true;
          return;
        } else {
          //503以外は共通エラーに遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/error').catch((error: any) => {
            checkRouterError(error);
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
          });
          return;
        }
      }
    },

    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
  },
});
</script>
