<template>
  <div class="linking">
    <LoadingComponent v-if="isExecutingOnAccountlinkage" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス アカウントID連携</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス アカウントID連携</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:UCOM アカウントID連携 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="UCOM光 レジデンス アカウントID連携" />UCOM光 レジデンス アカウントID連携</h2>
        <p>
          <span>アカウントID　登録・変更入力画面でご入力いただいた、アカウントID・パスワードをご入力いただき、「UCOM光レジデンス アカウントID連携」ボタンを押してください。</span
          ><br />
          <span>※お客様番号・お客様番号パスワードでの連携ではございませんのでご注意ください。</span>
        </p>
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <table class="table-type2">
          <tr>
            <th class="va-middle">アカウントID<span class="req">必須</span></th>
            <td><input type="text" class="text middle" v-model="inputData.accountName" /></td>
          </tr>
          <tr>
            <th class="va-middle">パスワード<span class="req">必須</span></th>
            <td><input type="password" class="text middle" v-model="inputData.password" /></td>
          </tr>
        </table>

        <p>アカウントID・パスワードがご不明な場合にはアカウントID管理　一覧画面からご確認ください。</p>

        <div class="sblc">
          <div class="btn-area">
            <button class="btn btn01 bs" v-on:click="onAccountlinkage">UCOM光 レジデンス アカウントID連携</button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

div.sblc {
  p.note-on-id-and-password {
    margin-top: 8px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { required } from '@vuelidate/validators';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { UcomSubAccountLinkingInput } from '@/shared/classes/auth-manegement/ucom-sub-account-linking-input';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'sub-account-linking',
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。' as string,
    /** 入力データ */
    inputData: {
      accountName: '',
      password: '',
    },
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    ucomCustomerRegistrationUrl: process.env.VUE_APP_UCOM_CUSTOMER_REGISTRATION_URL,
  }),
  validations: {
    inputData: {
      accountName: {
        required,
      },
      password: {
        required,
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていなければ エラー画面 に遷移する
    if (!isLoggedIn) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });

      return;
    }
  },
  methods: {
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.v$.inputData.$invalid) {
        if (this.v$.inputData.accountName?.required.$invalid) {
          this.errorMessages.push('アカウントIDを入力してください。');
        }
        if (this.v$.inputData.password?.required.$invalid) {
          this.errorMessages.push('パスワードを入力してください。');
        }
        return false;
      }
      return true;
    },
    /** アカウント連携ボタン押下時 */
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isExecutingOnAccountlinkage = false;
    },
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        this.isExecutingOnAccountlinkage = true;

        if (this.validate()) {
          const ucomSubAccountLinkingInput: UcomSubAccountLinkingInput = {
            accountName: this.inputData.accountName,
            password: ObfuscationService.encode(this.inputData.password),
          };

          const result: undefined | AuthManegementSharedErrorResponse = await AuthManagementApiService.linkingSubAccountToUcom(ucomSubAccountLinkingInput);
          if (result instanceof AuthManegementSharedErrorResponse) {
            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              this.showErrorMessage();
              return;
            } else {
              this.errorMessages.push('アカウントあるいはパスワードが一致しません。');
              this.showErrorMessage();
              return;
            }
          }
          await AuthService.refresh();

          // e-mansion サービストップ に遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/ucom').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
      } catch (error) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },
  },
});
</script>
