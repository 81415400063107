/**
 * UCOM API : ご利用中の電話会社取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomPhoneCompaniesResponseResult {
  /** 結果値 */
  public result!: UcomPhoneCompaniesResponse;

  constructor(partial: Partial<UcomPhoneCompaniesResponseResult>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM API : ご利用中の電話会社取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomPhoneCompaniesResponse {
  /** ご利用中の電話会社 */
  public phone_company!: Array<UcomPhoneCompany>;

  constructor(partial: Partial<UcomPhoneCompaniesResponse>) {
    Object.assign(this, partial);
  }
}

/** UCOM API : ご利用中の電話会社取得 : レスポンス ご利用中の電話会社 */
export class UcomPhoneCompany {
  /** ご利用中の電話会社ID */
  public id!: string;
  /** ご利用中の電話会社名 */
  public value!: string;

  constructor(partial: Partial<UcomPhoneCompany>) {
    Object.assign(this, partial);
  }
}
