export const UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE = {
  PURCHASE: 1, // 購入
  CANCEL: 2, // 解約
} as const;

export type UcomVirusCheckPurchaseSubscriptionType = typeof UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE[keyof typeof UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE];

// 表示用 value変換用
const _UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE = {
  PURCHASE: { id: UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.PURCHASE, value: '購入する' },
  CANCEL: { id: UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.CANCEL, value: '解約する' },
};

// idから valueに変換
export const convertUcomVirusCheckPurchaseSubscriptionTypeById = (id: number): string | undefined => {
  for (const v of Object.values(_UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE)) {
    if (v.id === id) {
      return v.value;
    }
  }
};
