export const UCOM_MAIL_ADDRESS_TYPE = {
  UCOM_HIKARI: 1, // UCOM光
  OTHER_COMPANY: 2, // 他社メール
} as const;
export type UCOM_MAIL_ADDRESS_TYPE = typeof UCOM_MAIL_ADDRESS_TYPE[keyof typeof UCOM_MAIL_ADDRESS_TYPE];

export const convertUcomMailAddressType = (value: number): UCOM_MAIL_ADDRESS_TYPE | undefined => {
  for (const v of Object.values(UCOM_MAIL_ADDRESS_TYPE)) {
    if (Number(v) == value) {
      return v;
    }
  }
};
