import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import store from '@/store';

/**
 * ISP(e-mansion) の VeriTransに関わる操作を司る
 */
export class EMansionVeriTransService {
  /**
   * e-mansionに登録されたVeriTransカードIDを取得する
   *
   * @returns 空文字列 or Portas会員テーブル.e-TNC用会員ID
   */
  public static getVeriTransCardIdOnEMansion(): string {
    //e-mansion-common-data-load-service.tsで契約基本情報を読み込んでいるのでgettersでよい
    const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = store.getters['eMansionCommonStore/customer'];
    const member: Member | null = store.getters['memberStore/member'];

    if (eMansionCustomer instanceof EMansionCustomer && eMansionCustomer.payment_id === '8') {
      if (member && member.eTncMemberId) {
        return member.eTncMemberId;
      }
    }
    return '';
  }
}
