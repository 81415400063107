import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { Module } from 'vuex';
import { RootState } from './index';
import { MaintenanceScreenInfo } from '@/shared/classes/spf-api/maintenance-screen';
import { MScreenInfo } from '@/shared/classes/spf-api/m-screen';

const maintenanceScreenState: {
  maintenanceScreen: MaintenanceScreenInfo | null;
  mScreen: MScreenInfo | null;
} = {
  maintenanceScreen: null,
  mScreen: null,
};

/** State の型 */
type MaintenanceScreenState = typeof maintenanceScreenState;

/** MaintenanceScreenStore */
export const maintenanceScreenStore: Module<MaintenanceScreenState, RootState> = {
  namespaced: true,
  state: maintenanceScreenState,
  mutations: {
    maintenanceScreen: (state, value: MaintenanceScreenInfo) => (state.maintenanceScreen = value),
    mScreen: (state, value: MScreenInfo) => (state.mScreen = value),
  },
  getters: {
    maintenanceScreen: (state): MaintenanceScreenInfo | null => state.maintenanceScreen,
    mScreen: (state): MScreenInfo | null => state.mScreen,
  },
  actions: {
    async maintenanceScreen(context, screenId: string) {
      const maintenanceScreen = await SpfApiService.getMaintenanceScreen(screenId);
      // 値をコミットする
      context.commit('maintenanceScreen', maintenanceScreen);
      // Getter から取り直して返す
      const afterMaintenanceScreen = context.getters['maintenanceScreen'];
      return afterMaintenanceScreen;
    },
    async mScreen(context, toPath: string) {
      const mScreen = await SpfApiService.getMScreen(toPath);
      // 値をコミットする
      context.commit('mScreen', mScreen);
      // Getter から取り直して返す
      const afterMScreen = context.getters['mScreen'];
      return afterMScreen;
    },
  },
};
