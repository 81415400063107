export const UCOM_ACCOUNT_OPTION_QUERY_TYPE = {
  INSERT: '1',//登録
  UPDATE: '2',//更新
} as const;
export type UCOM_ACCOUNT_OPTION_QUERY_TYPE = typeof UCOM_ACCOUNT_OPTION_QUERY_TYPE[keyof typeof UCOM_ACCOUNT_OPTION_QUERY_TYPE];

export const convertUcomAccountOptionQueryType = (value: number): UCOM_ACCOUNT_OPTION_QUERY_TYPE | undefined => {
  for (const v of Object.values(UCOM_ACCOUNT_OPTION_QUERY_TYPE)) {
      if (Number(v) == value) {
          return v;
      }
  }
}