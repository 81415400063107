<template>
  <div class="platform-cancel-terms">
    <main class="underlayer-main">
      <h1>Portas会員退会お申し込み</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>会員退会お申し込み</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">ご案内</li>
        <li>確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <error-messages-component v-bind:isOneSentence="true" v-bind:errorMessages="errorMessages" />

        <h2 class="portal-h2 cf">
          <img src="../../../images/service-h2.svg" alt="Portas会員からの退会 ご案内" />
          Portas会員からの退会お申し込み ご案内
        </h2>
        <p>
          Portas（ポルタス）会員としての登録を解除し退会します。<br />
          1日から20日まで（21日午前0時より翌月扱い）に退会お申し込みをされた場合、当月末での退会となります。<br />
          21日から月末までに退会お申し込みをされた場合、翌月末での退会となります。<br />
          Portas退会後は、Portasサイトへのログインがご利用できなくなります。
        </p>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button :class="getApplyButtonCancel" v-on:click="onApply()" :disabled="!isMounted || isInCancelApplication">
            次へ<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms-box {
  height: 240px;
  overflow: auto;
}
button.btn01:disabled {
  opacity: 0.5;
}
</style>

<script lang="ts">
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UnpaidBillingService } from '@/shared/services/portas-subscription/unpaid-billing-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

/** Guide コンポーネント */
export default defineComponent({
  name: 'platform-cancel-guide',
  components: {
    ErrorMessagesComponent,
  },
  data: () => ({
    /** データ読込み終了 */
    isMounted: false,
    // Portas会員退会申し込み済みかどうか
    isInCancelApplication: false,
    // エラーメッセージ
    errorMessages: new Array<string>(),
    isSubmitting: false,
    memberStatus: null as MemberStatus | null,
  }),
  async mounted() {
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    // 直リンク対策 連携済みのサービスがある場合は退会不可の案内画面へ遷移させる
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];
    if (!this.memberStatus) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    const member: Member = this.$store.getters['memberStore/member'];
    if (!member) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 退会要件を満たすかどうか調べる
    const canCancelResult = await Promise.all([
      AccountUtilService.canCancelUcom(),
      AccountUtilService.canCancelEmansion(),
      AccountUtilService.canCancelFiveA(),
      AccountUtilService.canCancelGameWith(),
    ]);

    // 外部APIがエラーのとき
    const foundIndex = canCancelResult.findIndex((value) => value instanceof Error);

    if (foundIndex === 0) {
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
    } else if (foundIndex === 1) {
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
    } else if (foundIndex === 2) {
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
    } else if (foundIndex === 3) {
      if ((canCancelResult[3] as any).response?.data.status_code == '503') {
        this.errorMessages.push(
          'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
        );
        return;
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
      }
    }

    // 退会要件を満たさないサービスがあるとき、退会不可の案内画面へ遷移
    if (canCancelResult.some((value) => value === false)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/cancel/impossible').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // Portasサブスク契約について、退会可能か確認
    const isExistUnpaidBilling = await UnpaidBillingService.getUnpaidBillingProductName(member.id);
    if (isExistUnpaidBilling.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/cancel/impossible').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    const subscriptionList = await SpfApiService.getSubscriptionList(member.id);

    // U-NEXT月額プランの契約について、退会要件を満たさないものがあるか確認
    if (!(await AccountUtilService.canCancelUNext(subscriptionList))) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/cancel/impossible').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 既にPortas会員退会申し込み済みの場合、エラーメッセージを表示 & ボタンを非活性にする
    if (this.memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.isInCancelApplication = true;
      this.errorMessages.push('既にPortas会員退会のお申し込みをいただいております。現在退会手続き中です。');
    }

    this.isMounted = true;
  },
  methods: {
    /** 「同意する ➡」ボタン押下時 : 退会の確認画面に遷移する */
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;
      await this.$router
        .push('/platform/cancel/confirm')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  computed: {
    /**
     * 活性非活性に応じてクラスを変更する
     */
    getApplyButtonCancel(): string {
      return this.isMounted && !this.isInCancelApplication ? 'btn btn01 bs' : 'btn btn04 bs';
    },
  },
});
</script>
