import { Module } from 'vuex';
import { RootState } from '../index';

import { EMansionConnectixEntryInputForm, EMansionConnectixPropertyRoom } from '../../pages/connectix/e-mansion/classes/entry-input-form';
import { EMansionConnectixPropertyRoomsResponseResult } from '@/pages/connectix/e-mansion/classes/external-api/property-rooms-response';
import { EMansionConnectixErrorResponse } from '@/pages/connectix/e-mansion/classes/external-api/connectix-error-response';
import { EMansionConnectixExternalApiService } from '@/shared/services/external-api/connectix/e-mansion/e-mansion-connectix-external-api-service';
import { EMansionConnectixRoomsRequest } from '@/shared/classes/external-api/e-mansion/connectix-room-request';

/** e-mansion Connectix お申し込み State */
const eMansionConnectixEntryState: {
  entryInputForm: EMansionConnectixEntryInputForm | null;
  propertyRooms: EMansionConnectixPropertyRoom | null;
} = {
  /** e-mansion Connectix お申し込み 入力フォーム */
  entryInputForm: null,
  propertyRooms: null
};

/** State の型 */
type EMansionConnectixEntryState = typeof eMansionConnectixEntryState;

/** e-mansion Connectix お申し込み Store */
export const eMansionConnectixEntryStore: Module<EMansionConnectixEntryState, RootState> = {
  namespaced: true,
  state: eMansionConnectixEntryState,
  actions: {
    /**
     * e-mansion 部屋番号一覧取得 action
     * @param context
     * @param propertyId string 物件 ID
     * @return EMansionConnectixPropertyRoomsResponseResult 部屋番号一覧
     * @return EMansionConnectixErrorResponse 共通エラークラス
     */
    async propertyRooms(context, eMansionConnectixRoomsRequest: EMansionConnectixRoomsRequest) {
      // API コールする
      const FindPropertyRoomsResult: EMansionConnectixPropertyRoomsResponseResult | EMansionConnectixErrorResponse = await EMansionConnectixExternalApiService.findPropertyRooms(
        eMansionConnectixRoomsRequest.propertyId,
        eMansionConnectixRoomsRequest.uaType
      );
      // 値をコミットする
      context.commit('propertyRooms', FindPropertyRoomsResult);
      // Getter から取り直して返す
      const afterPropertyRoomsValue = context.getters['propertyRooms'];
      return afterPropertyRoomsValue;
    }
  },
  mutations: {
    entryInputForm: (state, value: EMansionConnectixEntryInputForm) => (state.entryInputForm = value),
    propertyRooms: (state, value: EMansionConnectixPropertyRoom) => (state.propertyRooms = value)
  },
  getters: {
    entryInputForm: (state): EMansionConnectixEntryInputForm | null => state.entryInputForm,
    propertyRooms: (state): EMansionConnectixPropertyRoom | null => state.propertyRooms
  }
};
