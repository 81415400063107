/**
 * 支払方法登録API のレスポンスを扱うクラス
 */
export class PlatformPaymentMethodRegisterResponse {
  /** Portas会員ID */
  public readonly memberId!: number;
  /** 支払方法No */
  public readonly paymentMethodNo!: number;
  /** VeriTransカードID */
  public readonly veritransCardId!: string;

  constructor(partial: Partial<PlatformPaymentMethodRegisterResponse>) {
    Object.assign(this, partial);
  }
}
