<template>
  <div>
    <LoadingComponent v-if="isSubmitting" />
    <div class="left-menu-logo" v-if="isSubAccount">
      <img src="../../images/logo.svg" alt="" class="hover-img">
    </div>
    <div class="left-menu-logo" v-else>
      <router-link to="" v-on:click="onTop()"><img src="../../images/logo.svg" alt="" class="hover-img"></router-link>
    </div>

    <div class="inner">
      <LeftMenuCommonLink
        :isLinkedEMansion="isLinkedEMansion"
        :isLinkedFiveA="isLinkedFiveA"
        :isLinkedUcom="isLinkedUcom"
        :isLinkedMcloud="isLinkedMcloud"
        :isUcomOrEmansionToppage="isUcomOrEmansionToppage"
        :isUcomParentAccountFlag="isUcomParentAccountFlag"
        :isLinkedToTsunaguOnlineShop="isLinkedToTsunaguOnlineShop"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { hasAdditionalAccount } from '@/shared/const/service-plan-type';
import { defineComponent } from 'vue';
import LeftMenuCommonLink from '../components/left-menu-common-link.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UCOM_ACCOUNT_AUTHORITY } from '../../shared/const/ucom/ucom-account-authority';

export default defineComponent({
  name: 'ucom-left-menu',
  props: {
    isLinkedEMansion: {
      type: Boolean
    },
    isLinkedUcom: {
      type: Boolean
    },
    isLinkedMcloud: {
      type: Boolean
    },
    isUcomOrEmansionToppage: {
      type: Boolean
    },
    isLinkedFiveA: {
      type: Boolean
    },
    isLinkedToTsunaguOnlineShop: {
      type: Boolean
    }
  },
  components: {
    LeftMenuCommonLink,
    LoadingComponent
  },
  data: () => ({
    // サブアカウント用表示判定
    isSubAccount: false,
    // ボタン押下中かどうか
    isSubmitting: false,
    // 親アカウントかどうか
    isUcomParentAccountFlag: false,
    }),
  async mounted() {
    this.$data.isSubAccount = await this.isSubAccountFunc();
    this.$data.isUcomParentAccountFlag = this.isUcomParentAccount();
  },
  methods: {
    async isSubAccountFunc() {
      const planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      return hasAdditionalAccount(planType);
    },
    /**
     * UCOM
     * お客様番号ユーザー(親アカウント)の場合、trueを返す
     */
    isUcomParentAccount(): boolean {
      const loginUserAuthority: UCOM_ACCOUNT_AUTHORITY = this.$store.getters['ucomAccountStore/ucomAccount']?.accountInfo.accountAuthorization;
      // お客様番号ユーザー(親アカウント)の場合のみ、true
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER) {
        return true;
      }
      return false;
    },
    async onTop(){
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  }
});
</script>
