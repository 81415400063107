import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

/**
 * システム日付を返す(デフォルトは、YYYY-MM-DD)
 * タイムゾーン アジア / 東京
 *
 * @param format YYYY/MM/DDなどのフォーマット
 * @return フォーマットされたシステム日付を返す
 */
export function processDate(format?: string): string {
  const yyyymmdd = format ? format : 'YYYY-MM-DD';
  return dayjs(new Date()).tz().format(yyyymmdd);
}

/**
 * システム日付の年を返す
 * タイムゾーン アジア / 東京
 *
 * @return {number} システム日付の年を返す
 */
export function processYearNumber(): number {
  return dayjs(new Date()).tz().year();
}

/**
 * 日付を受け取り、指定されたフォーマットで返す（デフォルトは、YYYY-MM-DD）
 *
 * @param dateStr 日付
 * @param format YYYY/MM/DDなどのフォーマット
 * @return フォーマットされた日付文字列を返す
 */
export function formatDate(dateStr: string | Date, format?: string): string {
  const dateFormat = format ? format : 'YYYY-MM-DD';
  return dayjs(dateStr).tz().format(dateFormat);
}
