<template>
  <div class="billing-history-list">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>ご請求明細</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li>ご請求明細</li>
      </ul>

      <div class="blc">
        <h2 class="underlayer-h2">ご請求明細</h2>
        <div>Portas（ポルタス）からのご請求につき、明細をご確認いただけます。</div>
        <div v-if="firstName && givenName">{{ firstName }} {{ givenName }} 様</div>
      </div>

      <div class="blc">
        <div class="right-aligned">
          アルテリア・ネットワークス株式会社<br />
          登録番号：T8010401123151
        </div>

        <div class="disp-middle">
          <div class="months-control">
            <button class="control-button" @click="increaseMonth" :disabled="targetMonth === dispMonthList[0]">＜</button>
            <div
              class="month"
              v-for="n in 3"
              :key="n"
              @click="selectMonth(dispMonthList[currentMonthIndex + n - 1])"
              :class="{ 'selected-month': targetMonth === dispMonthList[currentMonthIndex + n - 1] }"
            >
              <!-- memo: YYYYMM型 の値を MM型に変換する。 -->
              {{ dispMonthList[currentMonthIndex + n - 1] ? Number(dispMonthList[currentMonthIndex + n - 1].slice(-2)) : '' }}月
            </div>
            <button class="control-button" @click="decreaseMonth" :disabled="targetMonth === dispMonthList[dispMonthList.length - 1]">＞</button>
          </div>
          <!-- memo: YYYYMM型 の値を YYYY年 MM月に変換する。 -->
          <div>{{ targetMonth ? Number(targetMonth.slice(0, 4)) : '' }}年{{ targetMonth ? Number(targetMonth.slice(-2)) : '' }}月 ご請求分</div>
        </div>

        <!-- 請求明細がある場合の表示 -->
        <div v-if="isExistBill">
          <table class="billing-table blc">
            <thead>
              <tr>
                <th>内容</th>
                <th>利用期間</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="invoiceHistoryList">
                <tr v-for="info in invoiceHistoryList?.invoices" :key="info.detailNo">
                  <td v-html="info.invoiceDetailName"></td>
                  <template v-if="info.invoiceDetailNameId !== INVOICE_DETAIL_NAME_MASTER.U_NEXT.SET_DISCOUNT">
                    <td>{{ formatServiceDate(info.serviceStartDate) }}～{{ formatServiceDate(info.serviceEndDate) }}</td>
                  </template>
                  <template v-else-if="info.invoiceDetailNameId === 6">
                    <td></td>
                  </template>
                  <td class="right-aligned">{{ formatMoney(info.invoiceTaxExcluded) }}円</td>
                </tr>
                <tr>
                  <td>合計（税抜）</td>
                  <td></td>
                  <td class="right-aligned">{{ formatMoney(invoiceHistoryList.totalInvoiceTaxExcluded) }}円</td>
                </tr>
                <tr>
                  <td>消費税</td>
                  <td></td>
                  <td class="right-aligned">{{ formatMoney(invoiceHistoryList.totalTax) }}円</td>
                </tr>
                <tr>
                  <td>合計（税込）</td>
                  <td></td>
                  <td class="right-aligned">{{ formatMoney(invoiceHistoryList.totalInvoiceTaxIncluded) }}円</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>10%対象</td>
                  <td></td>
                  <td class="right-aligned">{{ formatMoney(total10PercentProducts) }}円（内消費税：{{ formatMoney(total10PercentConsumptionTax) }}円）</td>
                </tr>
                <tr>
                  <td>8%対象</td>
                  <td></td>
                  <td class="right-aligned">{{ formatMoney(total8PercentProducts) }}円（内消費税：{{ formatMoney(total8PercentConsumptionTax) }}円）</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div>*は軽減税率対象商品</div>
          <br />
          <div>※本請求明細ではインボイス制度の定める消費税の端数処理方式にしたがって金額を算出するため、商品毎の税別金額と税別合計金額にて数円の差額が生じる場合がございます。</div>
        </div>

        <!-- 請求明細がない場合の表示 -->
        <div class="disp-middle" v-else>請求明細はございません。</div>
      </div>

      <div class="blc" v-if="isExistUnextBills">
        <div>
          ※U-NEXTのご請求明細「U-NEXT　その他利用料（都度課金など）」の内訳は、U-NEXTへログインのうえご確認をお願いいたします。（U-NEXT解約月の翌月以降は、U-NEXTにログイン不可となります）
        </div>
        <img class="material-icons link link-icon" src="../../../../images/service-h2.svg" />
        <a class="link" :href="`${unextLoginPageLink}`" target="_blank">U-NEXT ログインページ</a>
        <div class="mt10">
          ※U-NEXT解約後に再度お申し込みされた場合、再申し込み月の月額料金は無料です。<br />
          ただし再申し込み月と同月に解約された場合は、解約手数料の請求が発生します。<br />
          なお、U-NEXTの解約手数料は、ご請求明細上は「内容：U-NEXT　月額プラン」と表示されます。
        </div>
      </div>

      <div class="blc" v-if="dispIspName !== '' && isCanConnectixApply">
        <div>※Connectixのご請求明細は、［{{ dispIspName }}］会員専用トップページの[ご請求明細・販売証明書表示]からご確認をお願いいたします。</div>
        <img class="material-icons link link-icon" src="../../../../images/service-h2.svg" />
        <router-link :to="ispTopPageLink" class="link">［{{ dispIspName }}］会員専用トップページ</router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { InvoiceHistoryListDto, InvoicesListDto } from '@/shared/classes/spf-api/invoice-history-list-dto';
import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionConnectixStatus } from '@/shared/services/e-mansion/e-mansion-connectix-status';
import { FiveAConnectixStatus } from '@/shared/services/five-a/five-a-connectix-status';
import { UcomConnectixStatus } from '@/shared/services/ucom/ucom-connectix-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import dayjs from 'dayjs';
import { Property } from '../../../../shared/classes/spf-api/property';
import { CONTENT_PROVIDER_ID, INVOICE_DETAIL_NAME_MASTER, UA_TYPE } from '../../../../shared/const/service-type';

export default defineComponent({
  name: 'billing-history-list',
  components: {
    LoadingComponent,
  },
  data: () => ({
    // 会員情報
    member: null as Member | null,
    // 物件情報
    property: null as Property | null,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** 請求明細有無 */
    isExistBill: false,
    /** 13カ月間の月のリスト */
    dispMonthList: [] as string[],
    /** 表示する月の選択のため */
    currentMonthIndex: 0,
    /** 選択・表示する月の値 YYYYMM型 */
    targetMonth: null as string | null,
    /** ISP名 */
    dispIspName: '',
    /** 会員専用トップページのリンク先 */
    ispTopPageLink: '',
    /** Connectix提供有無 */
    isCanConnectixApply: false,
    /** 表示する請求明細 */
    invoiceHistoryList: null as InvoiceHistoryListDto | null,
    /** 日付 */
    currentTimeWhenDisplayed: null as Date | null,
    /** 10%対象商品の合計料金 */
    total10PercentProducts: 0,
    /** 8%対象商品の合計料金 */
    total8PercentProducts: 0,
    /** 10%対象の内消費税 */
    total10PercentConsumptionTax: 0,
    /** 8%対象の内消費税 */
    total8PercentConsumptionTax: 0,
    /** 姓 */
    firstName: undefined as string | undefined,
    /** 名 */
    givenName: undefined as string | undefined,
    /**  U-NEXTのログインページリンク先 */
    unextLoginPageLink: process.env.VUE_APP_UNEXT_LOGIN_PAGE_URL,
    /** U-NEXTの商品明細有無 */
    isExistUnextBills: false,
    INVOICE_DETAIL_NAME_MASTER,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 会員情報取得
    this.member = this.$store.getters['memberStore/member'];

    // 物件情報取得
    this.property = this.$store.getters['propertyStore/property'];

    // 請求明細初期表示月取得処理
    const batchSituationData = await SpfApiService.findBatchSituationByBatchId('BS01-002');

    // 請求明細初期表示月を設定する
    if (!batchSituationData.value1) {
      throw new Error('請求明細初期表示月.設定値1に値がない');
    }

    this.targetMonth = String(batchSituationData.value1);

    // 請求履歴取得処理
    await this.getInvoiceHistoryList(this.targetMonth);

    // ISP判別 と Connectix提供物件かどうかの判定
    this.dispIspNameAndConnectixApplyCheck();

    // 選択する月の初期値は請求明細初期表示月取得処理で取得した月
    this.dispMonthList = this.getLastTenMonths(this.targetMonth);

    this.isLoading = false;
  },
  methods: {
    /**
     * @param 年
     * @param 月
     * @return paramの月末をformatして返す
     */
    getEndOfMonth(year: number, month: number): string {
      // dayjsは月を0から11で扱うため、1減らす。
      const date = dayjs()
        .year(year)
        .month(month - 1)
        .endOf('month');
      return date.format('YYYY年MM月DD日');
    },

    /**
     * @param nowMonth 直近の年月（YYYYMM形式）。
     * @return 直近の年月を含めた過去13ヶ月間のリスト
     */
    getLastTenMonths(nowMonth: string): string[] {
      let year = parseInt(nowMonth.slice(0, 4));
      let month = parseInt(nowMonth.slice(4, 6));

      // 長さ13の新しい配列を生成し、各要素に年月を割り当てる
      return Array.from({ length: 13 }, () => {
        if (month === 0) {
          year--;
          month = 12;
        }
        const ym = `${year.toString().padStart(4, '0')}${month.toString().padStart(2, '0')}`;
        month--;
        return ym;
      });
    },
    /**
     * 「＞」ボタン押下時のmethod
     * 1ヵ月分、過去月にする
     */
    decreaseMonth() {
      if (this.currentMonthIndex < this.dispMonthList.length - 3) {
        this.currentMonthIndex++;
      }
      this.calculateMonthAndGetInvoiceHistoryList('decrease');
    },
    /**
     * 「＜」ボタン押下時のmethod
     * 1ヵ月分、最新月にする
     */
    increaseMonth() {
      if (this.currentMonthIndex > 0) {
        this.currentMonthIndex--;
      }
      this.calculateMonthAndGetInvoiceHistoryList('increase');
    },
    /**
     * 「＞」「＜」ボタン押下時に、1ヵ月分最新にしたり過去にしたりしてから 請求履歴取得 するmethod
     * @param increaseOrDecrease "increase" or "decrease"
     * @return newMonth YYYYMM型
     *
     * increase: 1ヵ月分、最新月にする
     * decrease: 1ヵ月分、過去月にする
     */
    async calculateMonthAndGetInvoiceHistoryList(increaseOrDecrease: string) {
      if (this.targetMonth) {
        // 1. this.targetMonthをDate型にする
        const nowYear = parseInt(this.targetMonth.slice(0, 4));
        const nowMonth = parseInt(this.targetMonth.slice(4, 6)) - 1;
        const nowDateObj = new Date(nowYear, nowMonth);

        //  * increase: 1ヵ月分、最新月にする
        if (increaseOrDecrease === 'increase') {
          // 2. this.targetMonthを+1ヵ月する
          nowDateObj.setMonth(nowDateObj.getMonth() + 1);
        }
        //  * decrease: 1ヵ月分、過去月にする
        if (increaseOrDecrease === 'decrease') {
          // 2. this.targetMonthを-1ヵ月する
          nowDateObj.setMonth(nowDateObj.getMonth() - 1);
        }

        // 3. 再びstring型に戻す
        const newYear = nowDateObj.getFullYear().toString();
        const newMonth = (nowDateObj.getMonth() + 1).toString().padStart(2, '0'); // 1-9は01-09にする
        this.targetMonth = `${newYear}${newMonth}`;

        // 4. 請求履歴取得処理
        await this.getInvoiceHistoryList(this.targetMonth);
      }
    },
    /**
     * 表示されている月の表示をクリックした際に値を更新する
     * 同じ月をクリックした場合は更新しない
     *
     * @param month YYYYMM型
     */
    async selectMonth(month: string) {
      if (this.targetMonth !== month) {
        this.targetMonth = month;
        // 請求履歴取得
        await this.getInvoiceHistoryList(month);
      }
    },
    /**
     * ISP判別 と Connectix提供物件かどうかの判定
     *
     * 判別したISPに応じて画面下部のISPへのリンクの内容を変更する。
     */
    dispIspNameAndConnectixApplyCheck() {
      if (!this.property?.uaType) {
        return;
      }

      if (this.member?.primaryKeyMye) {
        if (this.property?.uaType === UA_TYPE.E_MANSION) {
          this.dispIspName = 'e-mansion';
          this.ispTopPageLink = '/e-mansion';

          const isProvidedConnectixEMansion = EMansionConnectixStatus.isProvided();
          if (isProvidedConnectixEMansion) {
            this.isCanConnectixApply = true;
          }
        } else if (this.property?.uaType === UA_TYPE.FIVE_A) {
          this.dispIspName = 'Five.A';
          this.ispTopPageLink = '/5a';

          const isProvidedConnectixFiveA = FiveAConnectixStatus.isProvided();
          if (isProvidedConnectixFiveA) {
            this.isCanConnectixApply = true;
          }
        }
      } else if (this.member?.primaryKeyUcom) {
        if (this.property?.uaType === UA_TYPE.UCOM) {
          this.dispIspName = 'UCOM';
          this.ispTopPageLink = '/ucom';

          const isProvidedConnectixUcom = UcomConnectixStatus.isProvided();
          if (isProvidedConnectixUcom) {
            this.isCanConnectixApply = true;
          }
        }
      }
    },
    /**
     * 請求履歴取得
     * @param targetMonth 請求年月 YYYYMM型
     */
    async getInvoiceHistoryList(targetMonth: string): Promise<void> {
      this.isExistUnextBills = false;
      // 請求履歴一覧取得ストア から 請求履歴一覧 を取得し invoices 配列を取得する
      this.invoiceHistoryList = await this.$store.dispatch('invoiceHistoryListDtoStore/invoiceHistoryListDto', { memberId: this.member?.id, targetMonth: parseInt(targetMonth) });

      if (this.invoiceHistoryList && this.invoiceHistoryList.invoices.length > 0) {
        // 請求明細データを明細名称 ID順の昇順かつ利用開始日の昇順にソート
        this.invoiceHistoryList.invoices.sort((a, b) => {
          if (a.invoiceDetailNameId < b.invoiceDetailNameId) return -1;
          if (a.invoiceDetailNameId > b.invoiceDetailNameId) return 1;

          if (a.serviceStartDate < b.serviceStartDate) return -1;
          if (a.serviceStartDate > b.serviceStartDate) return 1;

          return 0;
        });

        // U-NEXTの商品があればフラグをtrueにする
        const unextMonthlyInvoiceHistoryList = this.invoiceHistoryList.invoices.filter((info: InvoicesListDto) => info.contentProviderId === CONTENT_PROVIDER_ID.UNEXT);
        if (unextMonthlyInvoiceHistoryList.length > 0) {
          this.isExistUnextBills = true;
        }

        // 金額の計算
        this.calculateTotalAmount();

        this.firstName = this.invoiceHistoryList.firstName;
        this.givenName = this.invoiceHistoryList.givenName;

        this.isExistBill = true;
      } else {
        // 請求履歴一覧画存在しない時

        this.firstName = undefined;
        this.givenName = undefined;

        this.isExistBill = false;
      }
    },

    /**
     * 以下の計算をする
     * 10%対象
     * 8%対象
     *
     * ※ 消費税の小数は、バッチですべて切り捨て処理される
     */
    calculateTotalAmount(): void {
      // 前の表示があるかもしれないので初期化
      this.total10PercentProducts = 0;
      this.total10PercentConsumptionTax = 0;
      this.total8PercentProducts = 0;
      this.total8PercentConsumptionTax = 0;

      if (this.invoiceHistoryList) {
        const invoiceTaxes = this.invoiceHistoryList.invoiceTaxRelated;

        // 10%対象の商品
        const total10PercentAmount = invoiceTaxes.filter((info) => info.taxRate === 10.0);

        if (total10PercentAmount.length === 1) {
          // 10%対象の内消費税
          this.total10PercentConsumptionTax = total10PercentAmount[0].totalTax;

          // 10%対象商品の合計料金
          this.total10PercentProducts = total10PercentAmount[0].totalInvoiceTaxIncluded;
        }

        // 8%対象の商品
        const total8PercentAmount = invoiceTaxes.filter((info) => info.taxRate === 8.0);

        if (total8PercentAmount.length === 1) {
          // 10%対象の内消費税
          this.total8PercentConsumptionTax = total8PercentAmount[0].totalTax;

          // 10%対象商品の合計料金
          this.total8PercentProducts = total8PercentAmount[0].totalInvoiceTaxIncluded;
        }
      }
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      let formattedValue = formatter.format(billing);
      // U-NEXTセット割用
      if (Math.sign(billing) === -1) {
        formattedValue = formattedValue.replace('-', '▲');
      }
      return formattedValue;
    },
    /** Date 型を YYYY年MM月DD日 に変換する */
    formatServiceDate(serviceDate: Date | null): string {
      return serviceDate ? dayjs(serviceDate).tz().format('YYYY年MM月DD日') : '';
    },
  },
});
</script>

<style lang="scss" scoped>
.billing-table {
  border-collapse: collapse;
  border: 1px solid lightpink;
  width: 100%;
}

.billing-table th,
.billing-table td {
  border-bottom: 1px solid lightpink;
  padding: 10px;
}

.billing-table th {
  background-color: lightpink;
  color: white;
}

.right-aligned {
  text-align: right;
}

.disp-middle {
  text-align: center;
}

.months-control {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
}

.control-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;

  &:disabled {
    color: #ccc;
  }
}

.month {
  padding: 20px;
  cursor: pointer;
}

.selected-month {
  background-color: red;
  color: white;
  border-radius: 50%;
  border: 1px solid black;
}
</style>
