import dayjs from 'dayjs';
import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { GamewithCustomerInfoResponse } from '../../../response/spf/gamewith/gamewith-customer-info-response';
import { UpdateGamewithCustomerRequest } from '@/infra/request/spf/gamewith/update-gamewith-customer-request';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 外部 API をコールするサービス */
export class SpfApiExternalGamewithAccessor {
  /**
   * GW光会員情報取得API
   * @return GameWithCustomerInfoDto GW光会員情報
   */
  public static async getCustomer(gwid: string): Promise<GamewithCustomerInfoResponse> {
    //スラッシュなど記号が入る場合があるのでエスケープする
    //プラス記号がスペースに変わってしまうため置き換える
    const escapedGwid = encodeURIComponent(gwid.replace(/\s/g, '+'));
    const response = await api.get<GamewithCustomerInfoResponse>(`/external/game-with-hikari/customers/${escapedGwid}`).catch(async (error) => {
      throw error;
    });
    return response.data;
  } // findEMansionVirusCheck

  public static async updateCustomer(updateGamewithCustomerRequestDto: UpdateGamewithCustomerRequest, gwid: string): Promise<void> {
    const escapedGwid = encodeURIComponent(gwid.replace(/\s/g, '+'));
    await api
      .patch<void>(`/external/game-with-hikari/customers/${escapedGwid}`, {
        lastName: updateGamewithCustomerRequestDto.lastName,
        firstName: updateGamewithCustomerRequestDto.firstName,
        lastNameKana: updateGamewithCustomerRequestDto.lastNameKana,
        firstNameKana: updateGamewithCustomerRequestDto.firstNameKana,
        postalCode: updateGamewithCustomerRequestDto.postalCode,
        state: updateGamewithCustomerRequestDto.state,
        city: updateGamewithCustomerRequestDto.city,
        street: updateGamewithCustomerRequestDto.street,
        banchi: updateGamewithCustomerRequestDto.banchi,
        tatemono: updateGamewithCustomerRequestDto.tatemono,
        roomNumber: updateGamewithCustomerRequestDto.roomNumber,
        phone: updateGamewithCustomerRequestDto.phone,
        email: updateGamewithCustomerRequestDto.email,
        switchingNumberNTT: updateGamewithCustomerRequestDto.switchingNumberNTT,
        switchingNumberCollabo: updateGamewithCustomerRequestDto.switchingNumberCollabo,
        portasFlag: updateGamewithCustomerRequestDto.portasFlag,
      })
      .catch(async (error) => {
        throw error;
      });
  }
}
