/** 残工事受付記録登録API リクエスト用 */
export class RemainingWorkReceptionRecord {
  /** 会員 ID */
  public memberId!: number;
  /** 部屋番号 */
  public roomNumber!: string;
  /** UA種別 */
  public uaType!: string;
  /** 工事日程 連絡先 電話番号 */
  public phoneNumber!: string;
  /** 第一希望日 */
  public firstPreferredDate?: string | null;
  /** 第一希望日種別 */
  public firstPreferredDateType?: string | null;
  /** 第二希望日 */
  public secondPreferredDate?: string | null;
  /** 第二希望日種別 */
  public secondPreferredDateType?: string | null;
  /** 第三希望日 */
  public thirdPreferredDate?: string | null;
  /** 第三希望日種別 */
  public thirdPreferredDateType?: string | null;
  /** 電話希望有無 */
  public isPhoneRequest?: string | null;
  /** ステータス */
  public status!: string;

  constructor(partial: Partial<RemainingWorkReceptionRecord>) {
    Object.assign(this, partial);
  }
}
