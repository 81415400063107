<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールパスワード変更</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion/mail-password/input">メールパスワード変更</router-link>
        </li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールパスワード変更" />変更完了</h2>
        <p>
          <b>
            <font color="red">メールパスワードが変更されました。</font>
          </b>
        </p>
        お手続き内容をメールにてお送りいたしました。
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">変更対象メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
          </tbody>
        </table>
        <ul class="ul-basic01">
          <li>ただいまより新しいパスワードをご利用ください。</li>
        </ul>
      </div>
      <!-- /blc -->
      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    mailAddress: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.mailAddress = this.$store.getters['eMansionMailPasswordStore/mailAddress'];
      if (!this.mailAddress) {
        const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
        this.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddress ?? '';
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
