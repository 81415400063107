<template>
  <LoadingComponent v-if="isLoading" />
  <!-- main-contents -->
  <div>
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>機器レンタルサービスお申し込み 完了</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">

      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>機器レンタルサービスお申し込み 完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:機器レンタルサービスお申し込み 完了 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="機器レンタルサービス お申し込み" />機器レンタルサービス お申し込み</h2>
        <p>お申込みいただきました内容につきまして手続きが完了しました。<br>{{ mailAddress }}宛てに、「申込み完了メール」を送信しましたのでご確認ください。</p>

        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th class="va-middle">利用機器</th>
              <td>{{ bbrName }}</td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o">{{ bbrPrice }} 円</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>
                <span class="o">{{ applyDate }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <p>本サービスのお申し込みをいただくことにより無線LANルータを1台お貸し出しいたします。</p>
                <ul>
                  <li>1台のみお申し込みいただけます。</li>
                  <li>機器送付先はUCOM光 レジデンスご契約住所に限ります。</li>
                  <li>本サービスのお申し込みは、当社インターネット回線が開通していることが条件となります。</li>
                  <li>お届けする機器については、在庫状況により同等の別機種に変更となる場合がございます。</li>
                </ul>
                <br>
                <p>機器レンタルサービス契約条項については<a :href="termsUrl" target="_blank" class="link">こちらから</a>ご確認ください。</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc:機器レンタルサービスお申し込み 完了 -->

      <!-- blc: ボタンエリア -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="goNext()">UCOM光レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc: ボタンエリア -->

    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { RENTAL_BBR_PATHES } from '@/shared/const/ucom/ucom-rental-bbr-pathes';
import { RENTAL_BBR_LABELS } from '@/shared/const/ucom/ucom-rental-bbr-labels';

export default defineComponent({
  name: 'completeRentalService',
  data: () => ({
    // APIを伴う処理を実行中か
    isLoading: false,
    // 契約条項PDF URL
    termsUrl: process.env.VUE_APP_RENTAL_BBR_TERMS_URL,
    // お申し込み日
    applyDate: '',
    // 申し込み完了メール送信先アドレス
    mailAddress: '',
    // 機器名
    bbrName: RENTAL_BBR_LABELS.BBR_NAME,
    // 機器利用料
    bbrPrice: RENTAL_BBR_LABELS.BBR_PRICE
  }),
  mounted() {
    this.applyDate = this.$store.getters['ucomRentalServiceStore/applyDate'];
    const mailAddressArray: string[] = this.$store.getters['ucomRentalServiceStore/mailAddress'];
    this.mailAddress = mailAddressArray[1];
  },
  methods: {
    goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ucomRentalServiceStoreを解放する
      this.$store.commit('ucomRentalServiceStore/applyDate', undefined);
      this.$store.commit('ucomRentalServiceStore/mailAddress', undefined);
      this.isLoading = false;
      this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
    }
  },
});
</script>