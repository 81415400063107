import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion 追加アカウント State */
const eMansionSubAccountState: {
  account: string | undefined; //アカウント
  desiredAccount: string | undefined; //ご希望アカウント
  mailAddress: string | undefined; //メールアドレス
  eMansionAccountId: string | undefined; //e-mansion_アカウントID
  usageFee: string | undefined; //ご利用料金
  mailPassword: string | undefined; //メールパスワード
  popServer: string | undefined;
  smtpServer: string | undefined;
  imapServer:string|undefined;
  mailSubdomain: string | undefined;
  canApply: boolean;
  cancelOn: string | undefined;
} = {
  account: undefined,
  desiredAccount: undefined,
  mailAddress: undefined,
  eMansionAccountId: undefined,
  usageFee: undefined,
  mailPassword: undefined,
  popServer: undefined,
  smtpServer: undefined,
  imapServer:undefined,
  mailSubdomain:undefined,
  canApply: true,
  cancelOn: undefined,
};

/** State の型 */
type EMansionSubAccountState = typeof eMansionSubAccountState;

/** e-mansion 追加アカウント Store */
export const eMansionSubAccountStore: Module<EMansionSubAccountState, RootState> = {
  namespaced: true,
  state: eMansionSubAccountState,
  actions: {},
  mutations: {
    account: (state, value: string) => (state.account = value),
    desiredAccount: (state, value: string) => (state.desiredAccount = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    usageFee: (state, value: string) => (state.usageFee = value),
    eMansionAccountId: (state, value: string) => (state.eMansionAccountId = value),
    mailPassword: (state, value: string) => (state.mailPassword = value),
    popServer: (state, value: string) => (state.popServer = value),
    smtpServer: (state, value: string) => (state.smtpServer = value),
    imapServer:(state,value:string)=>(state.imapServer = value),
    mailSubdomain:(state,value:string)=>(state.mailSubdomain = value),
    canApply: (state, value: boolean) => (state.canApply = value),
    cancelOn: (state, value: string) => (state.cancelOn = value),
  },
  getters: {
    account: (state): string | undefined => state.account,
    desiredAccount: (state): string | undefined => state.desiredAccount,
    mailAddress: (state): string | undefined => state.mailAddress,
    usageFee: (state): string | undefined => state.usageFee,
    eMansionAccountId: (state): string | undefined => state.eMansionAccountId,
    mailPassword: (state): string | undefined => state.mailPassword,
    popServer: (state): string | undefined => state.popServer,
    smtpServer: (state): string | undefined => state.smtpServer,
    imapServer: (state): string | undefined => state.imapServer,
    mailSubdomain: (state): string | undefined => state.mailSubdomain,
    canApply: (state): boolean => state.canApply,
    cancelOn: (state): string | undefined => state.cancelOn,
  },
};
