import { Module } from 'vuex';
import { RootState } from './index';
import { CancellationInfo } from '@/shared/classes/spf-api/cancellation-info';

/** 商品解約結果 State */
const productCancelResultState: {
  productCancelResult: CancellationInfo[] | null;
} = {
  productCancelResult: null,
};

/** State の型 */
type productCancelResultState = typeof productCancelResultState;

/** 商品解約結果 Store */
export const productCancelResultStore: Module<productCancelResultState, RootState> = {
  namespaced: true,
  state: productCancelResultState,
  mutations: {
    productCancelResult: (state, value: CancellationInfo[]) => (state.productCancelResult = value),
  },
  getters: {
    productCancelResult: (state): CancellationInfo[] | null => state.productCancelResult,
  },
};
