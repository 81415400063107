/**
 * ベリトランスのエラーコードを処理するためのクラス
 * ARN_QA-936 の関係で新規作成
 *
 * 次の2箇所で使われる
 * ・ src/shared/components/veritrans-credit-card-component.vue
 * ・ src/shared/components/ucom/veritrans-credit-card-ucom-component.vue
 */
export class VeritransErrorService {
  /**
   * カード番号、セキュリティコード、有効期限など、入力された情報に起因するエラー
   */
  public static isErrorDueToInputValue(errorCode: string): boolean {
    const ERROR_CODE_DUE_TO_USER_INPUT = [
      'missing_card_number',
      'invalid_card_number',
      'digit_check_error',
      'missing_card_expire',
      'invalid_card_expire',
      'missing_security_code',
      'invalid_security_code',
      'invalid_cardholder_name',
    ];
    return ERROR_CODE_DUE_TO_USER_INPUT.includes(errorCode);
  }

  private static ERROR_CODE_DUE_TO_CONFIG = [
    {
      errorCode: 'missing_token_api_key',
      errorCodeInNumericFormat: '01'
    },
    {
      errorCode: 'invalid_token_api_key',
      errorCodeInNumericFormat: '02'
    },
    {
      errorCode: 'unauthorized_merchant',
      errorCodeInNumericFormat: '03'
    },
    {
      errorCode: 'invalid_lang',
      errorCodeInNumericFormat: '04'
    },
    {
      errorCode: 'invalid_request_format',
      errorCodeInNumericFormat: '05'
    },
    {
      errorCode: 'system_error',
      errorCodeInNumericFormat: '06'
    }
  ];

  /**
   * ベリトランスのAPIを利用するために必要なAPIトークンが設定されていないなど、設定に起因するエラー
   */
  public static isErrorDueToConfig(errorCode: string): boolean {
    return VeritransErrorService.ERROR_CODE_DUE_TO_CONFIG.some((value: { errorCode: string; errorCodeInNumericFormat: string }) => {
      return value.errorCode === errorCode;
    });
  }

  public static getErrorCodeInNumericFormat(errorCode: string): string {
    const found = VeritransErrorService.ERROR_CODE_DUE_TO_CONFIG.find((value: { errorCode: string; errorCodeInNumericFormat: string }) => {
      return value.errorCode === errorCode;
    });

    if (found) {
      return found.errorCodeInNumericFormat;
    }
    return errorCode;
  }
}
