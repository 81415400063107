import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { processDate } from './func-process-date';
import { FORMAT_DATE } from '../const/format';

/**
 * ISP会員ステータスを取得する(Five.Aのとき)
 *
 * ISP会員ステータスは正式に仕様として存在・認知されていない可能性があるもので、
 * 20221101の退会要件を記載したエクセルに定義されている内容を落とし込んだもの
 *
 * @param memberStatus 会員ステータス
 * @param fiveACustomer Five.Aの契約基本情報取得APIの取得結果
 *
 * | No | isp-member-status-five-a | condition                                                       |
 * | 1  | cancelAndLoginNG         | portas-member-status === 3                                      |
 * | 2  | cancelAndLoginOK         | fiveACustomer.quit_data < now                                   |
 * | 3  | inCancelApplication      | fiveACustomer.quit_data >= now && fiveACustomer.status === '01' |
 * | 4  | member                   | Not(No.2) or Not(No.3)                                          |
 */
export function getIspMemberStatusFiveA(memberStatus: MemberStatus, fiveACustomer: FiveACustomer) {
  if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
    return ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_NG;
  }

  if (fiveACustomer.quit_date) {
    const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH); // yyyy/mm/dd

    if (fiveACustomer.quit_date < formattedToday) {
      return ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK;
    }
    if (fiveACustomer.quit_date >= formattedToday && fiveACustomer.status === '01') {
      return ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION;
    }
  }
  return ISP_MEMBER_STATUS.FIVE_A.MEMBER;
}

/**
 * ISP会員ステータスを取得する(ucomのとき)
 *
 * ISP会員ステータスは正式に仕様として存在・認知されていない可能性があるもので、
 * 20221101の退会要件を記載したエクセルに定義されている内容を落とし込んだもの
 *
 * @param memberStatus 会員ステータス
 * @param ucomCustomer ucomの契約基本情報取得APIの取得結果
 * 
 * | No | isp-member-status-ucom | condition                     |
 * | 1  | cancelAndLoginNG       | portas-member-status === 3    |
 * | 2  | cancelAndLoginOK       | ucomCustomer.quit_data < now  |
 * | 3  | inCancelApplication    | ucomCustomer.quit_data >= now |
 * | 4  | member                 | ucomCustomer.quit_data === '' |
 */
export function getIspMemberStatusUcom(memberStatus: MemberStatus, ucomCustomer: UcomCustomerResponse) {
  if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
    return ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_NG;
  }

  if (ucomCustomer.quit_date) {
    const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH); // yyyy/mm/dd

    if (ucomCustomer.quit_date < formattedToday) {
      return ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK;
    }
    if (ucomCustomer.quit_date >= formattedToday) {
      return ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION;
    }
  }
  return ISP_MEMBER_STATUS.UCOM.MEMBER;
}

/**
 * ISP会員ステータスを取得する(e-mansionのとき)
 *
 * ISP会員ステータスは正式に仕様として存在・認知されていない可能性があるもので、
 * 20221101の退会要件を記載したエクセルに定義されている内容を落とし込んだもの
 *
 * @param memberStatus 会員ステータス
 * @param eMansionCustomer e-mansionの契約基本情報取得APIの取得結果
 *
 * | No | isp-member-status-ucom | condition                                                             |
 * | 1  | cancelAndLoginNG       | portas-member-status === 3                                            |
 * | 2  | cancelAndLoginOK       | eMansioncustomer.quit_data < now                                      |
 * | 3  | transferAndCancel      | eMansioncustomer.quit_data >= now && eMansioncustomer.status === '03' |
 * | 4  | inCancelApplication    | eMansioncustomer.quit_data >= now && eMansioncustomer.status === '01' |
 * | 5  | member                 | Not(No.2) or Not(No.3) or Not(No.4)                                   |
 */
export function getIspMemberStatusEMansion(memberStatus: MemberStatus, eMansionCustomer: EMansionCustomer) {
  if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
    return ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_NG;
  }

  if (eMansionCustomer.quit_date) {
    const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH); // yyyy/mm/dd

    if (eMansionCustomer.quit_date < formattedToday) {
      return ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK;
    }
    if (eMansionCustomer.quit_date >= formattedToday && eMansionCustomer.status === '03') {
      return ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL;
    }
    if (eMansionCustomer.quit_date >= formattedToday && eMansionCustomer.status === '01') {
      return ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION;
    }
  }
  return ISP_MEMBER_STATUS.E_MANSION.MEMBER;
}
