import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../images/service-h2.svg'


const _withScopeId = n => (_pushScopeId("data-v-508d27aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "blc"
}
const _hoisted_2 = { class: "portal-h2-exclusion cf" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["href", "target"]
const _hoisted_5 = {
  key: 1,
  class: "disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: _ctx.alt
          }, null, 8, _hoisted_3),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_ctx.sentence) + " ", 1),
            (!_ctx.disabled)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.url,
                  target: _ctx.tabTarget,
                  class: "link"
                }, _toDisplayString(_ctx.linkTitle), 9, _hoisted_4))
              : (_ctx.disabled)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.linkTitle), 1))
                : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}