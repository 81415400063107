import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM 導入形態 State */
const importFormIdState: {
  importFormId: string | undefined; // 導入形態
} = {
  importFormId: undefined,
};

type ImportFormIdState = typeof importFormIdState;

/** UCOM 導入形態 State */
export const importFormIdStore: Module<ImportFormIdState, RootState> = {
  namespaced: true,
  state: importFormIdState,
  actions: {},
  mutations: {
    importFormId: (state, value: string) => (state.importFormId = value),
  },
  getters: {
    importFormId: (state): string | undefined => state.importFormId,
  },
};
