/**
 * Mcloud 共通利用 API : 施設詳細取得 : レスポンス 本体 -> response.data
 *
 * ※使用するプロパティのみ定義（用途が限られているため）
 */
export class McloudFacilityServicesDetailsResponse {
  public data!: {
    attributes: {
      name: string;
    };
  };

  constructor(partial: Partial<McloudFacilityServicesDetailsResponse>) {
    Object.assign(this, partial);
  }
}
