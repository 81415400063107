export class EMansionMailPasswordUpdate {
  readonly _mailAddress!: string | undefined;
  public get mailAddress(): string | undefined {
    return this._mailAddress;
  }
  readonly _message!: string[] ;
  public get message(): string[]  {
    return this._message;
  }
  constructor(partial: Partial<EMansionMailPasswordUpdate>) {
    Object.assign(this, partial);
  }
}
