import { UserScreenTransitionInfo } from '@/shared/classes/user/user-screen-transition';
import store from '@/store';
import { RouteLocationNormalized } from 'vue-router';

export class UserScreenTransitionHistoryWithStore {
  public static addHistory(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    store.dispatch(
      'userScreenTransitionStore/addHistory',
      new UserScreenTransitionInfo({
        toPath: to.path,
        toScreenName: to.meta?.title as string,
        fromPath: from.path,
        fromScreenName: from.meta?.title as string
      })
    );
  }
}
