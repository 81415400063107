import { UCOM_PURCHASE_STATUS } from "@/shared/const/ucom/ucom-purchase-status";

export class UcomMailPackInfo{
    readonly _memberId: string | undefined;
    public get memberId(): string | undefined {
      return this._memberId;
    }
    readonly _contractStatus: UCOM_PURCHASE_STATUS | undefined;
    public get contractStatus(): UCOM_PURCHASE_STATUS | undefined {
      return this._contractStatus;
    }
    readonly _paymentStatus: boolean | undefined;
    public get paymentStatus(): boolean | undefined {
      return this._paymentStatus;
    }
    readonly _isMax: boolean | undefined;
    public get isMax(): boolean | undefined {
      return this._isMax;
    }
    readonly _maxMessage: string|undefined;
    public get maxMessage():string|undefined{
      return this._maxMessage;
    }
    readonly _errorMessage: string | undefined;
    public get errorMessage(): string | undefined {
      return this._errorMessage;
    }
    readonly _monthlyFee: string | undefined;
    public get monthlyFee(): string | undefined {
      return this._monthlyFee;
    }
    readonly _serviceDescription: string | undefined;
    public get serviceDescription(): string | undefined {
      return this._serviceDescription;
    }
    readonly _productName: string | undefined;
    public get productName(): string | undefined {
      return this._productName;
    }
    constructor(partial: Partial<UcomMailPackInfo>) {
        Object.assign(this, partial);
      }
}