import store from '@/store';
import router from '@/router';

export class JudgeError {
    public static async judgeError(error: any) {
        if (error.response?.status === 503 && error.response.data) {
            await store.dispatch('maintenanceStore/maintenance', error.response.data);
            await router.push('/maintenance');
        }
    }
}