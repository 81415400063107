<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ容量 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ容量</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li>変更</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ホームページ容量 変更 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページ容量確認" />ホームページ容量確認</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <p>お申し込み内容をご確認ください。</p>
            <h3 class="service-h3">変更内容の確認</h3>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>変更対象 FTPアカウント</th>
                  <td>
                    <b>{{ ftpAccount }}</b>
                  </td>
                </tr>
                <tr>
                  <th>変更後のホームページ容量</th>
                  <td class="red">
                    <b>{{ eMansionHomepageCapacitySelectedValue }}</b>
                  </td>
                </tr>
                <tr>
                  <th>月額利用料</th>
                  <td>{{ myNumberWithDelimiter(monthlyFee) }}円</td>
                </tr>
                <tr>
                  <th>事務手数料</th>
                  <td>{{ jimFee }}円</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <p>※価格は全て税込表示（消費税{{ tax }}％）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
      <!-- blc -->
      <div class="blc">
        <p class="red" v-if="canApply && isNeedPayment">ご登録のお支払い方法により、お支払いいただきます。</p>
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn bs" :class="[canApply ? 'btn01' : 'btn05']" :disabled="!canApply" @click="goEntry">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>

      <!-- /blc -->
    </div>
  </div>
  <!-- /contents -->
  <!-- /main-contents -->
</template>

<script lang="ts">
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionPaymentStatusInfo } from '@/shared/classes/spf-api/mail/model/common/e-mansion-payment-status';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';

export default defineComponent({
  name: 'homepageCapacityConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    /** 選択したホームページ容量 */
    eMansionHomepageCapacitySelected: undefined as { key: string; value: string } | undefined,
    eMansionHomepageCapacitySelectedValue: '', // 選択したホームページ容量
    homepageCapacityListCount: 0, // ホームページ容量追加要素数
    homepageCapacityList: [], // ホームページ容量配列
    eMansionAccountId: -1, // e-mansion アカウントID
    eMansionHomepageId: -1, // e-mansion ホームページID
    fee: '', // 月額利用料 (50MBごと)
    monthlyFee: 0, // 表示する月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    hpQuota: '',
    tax: '', // 消費税率
    paymentInfo: null as EMansionPaymentStatusInfo | null,
    errorMessages: new Array<string>(), // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
    canApply: false, //お支払情報登録有無
    isCampaign: false,
    isNeedPayment: true,
    member: null as Member | null,
    CANT_APPLY_MESSAGE: 'お支払情報のご登録が必要です',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    this.isLoading = true;

    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      // 前画面からの情報をStoreから取得
      if (!this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId']) {
        // e-mansionアカウントID(Store)を取得できない場合、前画面へ戻る
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage-capacity/list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // 会員情報をStoreから取得
      this.member = this.$store.getters['memberStore/member'];

      // 前画面情報の取得
      this.eMansionAccountId = this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId'];
      this.eMansionHomepageId = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageId'];
      this.ftpAccount = this.$store.getters['eMansionHomepageCapacityStore/ftpAccount'];
      this.eMansionHomepageCapacity = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacity'];
      this.eMansionHomepageCapacitySelected = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected'];
      this.eMansionHomepageCapacitySelectedValue = this.eMansionHomepageCapacitySelected?.value ?? '';
      this.fee = this.$store.getters['eMansionHomepageCapacityStore/fee'];
      this.jimFee = this.$store.getters['eMansionHomepageCapacityStore/jimFee'];
      this.maxQuota = this.$store.getters['eMansionHomepageCapacityStore/maxQuota'];
      this.hpQuota = this.$store.getters['eMansionHomepageCapacityStore/hpQuota'];
      this.isCampaign = this.$store.getters['eMansionHomepageCapacityStore/isCampaign'];
      this.tax = this.$store.getters['taxStore/tax'];

      // お支払い情報登録状態を取得
      this.paymentInfo = await SpfApiPaymentStatusAccessor.getPaymentStatus(this.member?.id ?? -1);
      if (!this.paymentInfo) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      // お支払い情報登録状態チェック
      this.checkPaymentInfo(this.paymentInfo.status);

      // お支払い情報登録状態が未登録の場合のエラーメッセージ
      if (!this.canApply) {
        this.errorMessages.push(this.CANT_APPLY_MESSAGE);
        this.isLoading = false;
        return;
      }

      // 表示する月額利用料の計算
      const unitFee = Number(this.fee.replace(',', ''));
      const selectedCapacity = Number(this.eMansionHomepageCapacitySelectedValue.replace('MB', ''));
      this.monthlyFee = unitFee * ((selectedCapacity - Number(this.hpQuota)) / 50);

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    // 戻るボタンクリック
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.eMansionAccountId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.jimFee);

      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.maxQuota);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage-capacity/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // 申し込みボタンクリック
    async goEntry() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // ホームページ容量情報をstoreに保存する
        this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.eMansionAccountId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.eMansionHomepageId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.eMansionHomepageCapacitySelected?.key);

        // 現在日付の取得
        const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

        // ホームページ容量を更新する
        const response = await SpfApiHomepageCapacityAccessor.updateEMansionHomepageCapacity(
          this.eMansionAccountId,
          this.eMansionHomepageId,
          this.eMansionHomepageCapacitySelected?.key ?? '',
          processOn
        );
        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage-capacity/complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    checkPaymentInfo(paymentInfoStatus: E_MANSION_PAYMENT_STATUS): boolean {
      // お支払情報登録があった場合、活性化などを設定
      this.canApply = paymentInfoStatus == E_MANSION_PAYMENT_STATUS.REGISTERED;
      if (Number(this.fee) === 0 && Number(this.jimFee) === 0 && !this.isCampaign) {
        //利用料・事務手数料の料金（定価）がともに0円の場合は支払情報の登録は不要のため、trueを登録
        this.canApply = true;
        this.isNeedPayment = false;
      }
      // お支払情報登録有無の保持
      this.$store.commit('eMansionHomepageCapacityStore/canApply', this.canApply);
      return false;
    },
    myNumberWithDelimiter(value: number) {
      return numberWithDelimiter(value);
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
