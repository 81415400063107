<template>
  <div>
    <div class="modal micromodal-slide" id="modal-1" aria-hidden="true">
      <div class="modal__overlay" tabindex="-1">
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <main class="modal__content" id="modal-1-content">
            <div id="modal__message">U-NEXTは、お申し込み当日の解約はできません。お申し込みを進めてよろしいですか？</div>
            <div class="btn-area">
              <button class="btn-height btn btn05 bs" @click="onBack()">戻る</button>
              <button class="btn-height btn btn01 bs" type="button" v-on:click="onApply()">お申し込みを進める</button>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}
.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2003;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    background-color: #fff;
    max-height: 100vh;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
  }
  &__content {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    width: 600px;
    height: 150px;
    text-align: center;
    padding: 20px;
    margin: 20px;
    font-size: 14px;
  }
}
.btn-height {
  width: 185px;
}
@media only screen and (max-width: 767px) {
  .modal {
    &__content {
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.8);
      width: 95%;
      height: auto;
      text-align: center;
      padding: 5px;
      margin: 5px;
      font-size: 14px;
    }
  }
  .btn-area {
    display: flex;
    margin: 15px auto 0;
  }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}
.micromodal-slide.is-open {
  display: block;
}
.micromodal-slide[aria-hidden='false'] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden='false'] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden='true'] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden='true'] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'modal-display-entry-unext-product-confirm',
  data: () => ({
    micromodal: require('../../libraries/micromodal/micromodal.min.js'),
    currentPosition: 0,
  }),
  mounted(): void {
    this.$nextTick(() => {
      this.micromodal.init({
        disableScroll: true,
      });
    });
  },
  methods: {
    openModal(): void {
      this.micromodal.show('modal-1');
    },
    /**
     * ・ モーダルを閉じる処理
     * ・ モーダルコンポーネントを非表示にする
     * ・ 押下したボタン別でboolean値を返す（お申し込みに進む：true,戻る:false)
     */
    onBack(): void {
      this.currentPosition = window.scrollY;
      this.micromodal.close('modal-1');
      window.scrollTo({ top: this.currentPosition });
      this.$emit('catchResult', false);
    },
    onApply(): void {
      this.currentPosition = window.scrollY;
      this.micromodal.close('modal-1');
      window.scrollTo({ top: this.currentPosition });
      this.$emit('catchResult', true);
    },
  },
});
</script>
