import { UcomConnectixErrorResponse } from '@/pages/connectix/ucom/classes/external-api/connectix-error-response';
import {
  UCOM_CONNECTIX_BUSINESS_ERROR_CODE as BUSINESS_ERROR_CODE,
  UCOM_CONNECTIX_NOT_YET_OPEN_ERROR_CODE as NOT_YET_OPEN_ERROR_CODE,
  UCOM_CONNECTIX_CREDIT_CARD_ERROR_CODE as CREDIT_CARD_ERROR_CODE,
} from '@/shared/const/ucom/ucom-connectix-error-code';

export class UcomConnectixErrorService {
  /**
   * UCOM Connectix 申込API のエラーが、業務エラーにしたいエラーかどうか調べる
   * @returns true: 業務エラーにしたいエラー
   * @returns false: 業務エラーにしたくないエラー
   */
  public static isBusinessError(response: UcomConnectixErrorResponse): boolean {
    return BUSINESS_ERROR_CODE.includes(response.code);
  }

  /**
   * UCOM Connectix 申込API のエラーが、未開通エラーかどうか調べる
   */
  public static isNotYesOpenError(response: UcomConnectixErrorResponse): boolean {
    return NOT_YET_OPEN_ERROR_CODE.includes(response.code);
  }

  /**
   * UCOM Connectix 申込API のエラーが、クレジットカード起因のエラーかどうか調べる
   */
  public static isCreditCardError(response: UcomConnectixErrorResponse): boolean {
    return CREDIT_CARD_ERROR_CODE.includes(response.code);
  }
}
