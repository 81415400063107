<template>
  <div class="left-menu-service-link">
    <LoadingComponent v-if="isSubmitting" />
    <div class="left-menu-blc left-menu-service1">
      <ul>
        <li v-if="isLinkedEMansion" class="left-menu-company">
          <router-link to="" v-on:click="onEmansion()">
            <img src="../../images/logo-emansion.png" alt="e-mansion" class="hover-img e-mansion-link-img" />
          </router-link>
        </li>
        <li v-if="isLinkedFiveA" class="left-menu-company">
          <router-link to="" v-on:click="onFiveA()">
            <img src="../../images/logo_fivea.png" alt="fivea" class="hover-img fivea-link-img" />
          </router-link>
        </li>
        <li v-if="isLinkedUcom" class="left-menu-company">
          <router-link to="" v-on:click="onUcom()" class="left-ucom-link">
            <img src="../../images/logo-ucom.png" alt="UCOM光" class="hover-img ucom-link-img" />
          </router-link>
        </li>
        <li v-if="isLinkedGamewith" class="left-menu-company">
          <router-link to="" v-on:click="onGamewith()" class="left-gamewith-link">
            <img src="../../images/logo-gamewith.png" alt="GameWith" class="hover-img gamewith-link-img" />
          </router-link>
        </li>
        <!-- Mcloudの場合 -->
        <template v-if="isLinkedMcloud">
          <!-- UCOMの場合 -->
          <template v-if="isLinkedUcom">
            <li v-if="isUcomParentAccountFlag" class="left-menu-company">
              <div class="left-mcloud">
                <img src="../../images/mcloud/logo.png" alt="Mcloud光" class="hover-img mcloud-link-img" />
              </div>
            </li>
          </template>
          <!-- e-mansionの場合 -->
          <template v-else-if="isLinkedEMansion">
            <li v-if="isEmansionParentAccountFlag" class="left-menu-company">
              <div class="left-mcloud">
                <img src="../../images/mcloud/logo.png" alt="Mcloud光" class="hover-img mcloud-link-img" />
              </div>
            </li>
          </template>
          <template v-else>
            <li class="left-menu-company">
              <div class="left-mcloud">
                <img src="../../images/mcloud/logo.png" alt="Mcloud光" class="hover-img mcloud-link-img" />
              </div>
            </li>
          </template>
        </template>
        <!--オンラインショップの場合 -->
        <template v-if="isLinkedToTsunaguOnlineShop">
          <!-- UCOMの場合 -->
          <template v-if="isLinkedUcom">
            <li v-if="isUcomParentAccountFlag" class="left-menu-company">
              <a :href="getUrlLoginToTsunaguOnlineShopWithSso()" class="left-tnshop-link" target="tsunagu">
                <img src="../../images/sogo/tnshop.png" alt="つなぐオンラインショップ" class="hover-img tsunagu-online-shop-link-img" />
              </a>
            </li>
          </template>
          <!-- e-mansionの場合 -->
          <template v-else-if="isLinkedEMansion">
            <li v-if="isEmansionParentAccountFlag" class="left-menu-company">
              <a :href="getUrlLoginToTsunaguOnlineShopWithSso()" class="left-tnshop-link" target="tsunagu">
                <img src="../../images/sogo/tnshop.png" alt="つなぐオンラインショップ" class="hover-img tsunagu-online-shop-link-img" />
              </a>
            </li>
          </template>
          <template v-else>
            <li class="left-menu-company">
              <a :href="getUrlLoginToTsunaguOnlineShopWithSso()" class="left-tnshop-link" target="tsunagu">
                <img src="../../images/sogo/tnshop.png" alt="つなぐオンラインショップ" class="hover-img tsunagu-online-shop-link-img" />
              </a>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.left-menu-service-link .left-menu-blc ul {
  padding: 0;
  margin: 0;
}

.left-menu-blc .left-menu-company {
  height: 55px;
  text-align: center;
  border-bottom: 1px solid #cf1225;
}

.left-menu-blc .left-menu-company .e-mansion-link-img {
  height: 30px;
}

.left-menu-blc .left-menu-company .fivea-link-img {
  height: 40px;
}

.left-menu-blc .left-menu-company .ucom-link-img {
  height: 45px;
}

.left-menu-blc .left-menu-company .mcloud-link-img {
  height: 35px;
}
.left-menu-blc .left-menu-company .gamewith-link-img {
  height: 35px;
}
.tsunagu-online-shop-link-img {
  width: 170px;
  height: auto;
}

.left-menu-blc .left-tnshop-link {
  padding: 10px;
}

.left-menu-blc .left-ucom-link {
  padding: 5px 15px;
}
.left-menu-blc .left-mcloud {
  padding: 10px 15px;
}
</style>

<script lang="ts">
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { defineComponent } from 'vue';
import { TsunaguOnlineProductUrlService } from '@/shared/services/tnos/tsunagu-online-product-url-service';

/** LinkService コンポーネント */
export default defineComponent({
  name: 'left-menu-common-link',
  props: {
    isLinkedEMansion: {
      type: Boolean,
    },
    isLinkedUcom: {
      type: Boolean,
    },
    isLinkedMcloud: {
      type: Boolean,
    },
    isUcomOrEmansionToppage: {
      type: Boolean,
    },
    isLinkedFiveA: {
      type: Boolean,
    },
    isLinkedGamewith: {
      type: Boolean,
    },
    isUcomParentAccountFlag: {
      type: Boolean,
    },
    isEmansionParentAccountFlag: {
      type: Boolean,
    },
    isLinkedToTsunaguOnlineShop: {
      type: Boolean,
    },
  },
  components: {
    LoadingComponent,
  },
  data: () => ({
    // ボタン押下中かどうか
    isSubmitting: false,
  }),
  emits: ['clickContacts'],
  methods: {
    async onContacts() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/contacts').catch((error) => {
        checkRouterError(error);
      });
      return this.$emit('clickContacts', false);
    },
    async onEmansion() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onFiveA() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/5a')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onUcom() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/ucom')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onGamewith() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/gamewith')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    getUrlLoginToTsunaguOnlineShopWithSso(): string {
      return TsunaguOnlineProductUrlService.buildLoginUrlWithSso();
    },
  },
});
</script>
