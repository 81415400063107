import { Module } from 'vuex';
import { RootState } from './index';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { AuthorizationService } from '@/shared/services/authorization/authorization-service';

/** 会員種別 State */
const servicePlanTypeState: {
  servicePlanType: SERVICE_PLAN_TYPE | undefined;
} = {
  servicePlanType: undefined,
};
/** State の型 */
type ServicePlanTypeState = typeof servicePlanTypeState;

/** 会員種別 Store */
export const servicePlanTypeStore: Module<ServicePlanTypeState, RootState> = {
  namespaced: true,
  state: servicePlanTypeState,

  actions: {
    async servicePlanType(context) {
      const planType = await AuthorizationService.getPlanType();
      context.commit('setServicePlanType', planType);

      return context.getters['servicePlanType'];
    },
  },
  mutations: {
    setServicePlanType: (state, payload: SERVICE_PLAN_TYPE) => {
      state.servicePlanType = payload;
    },
  },
  getters: {
    servicePlanType: (state): SERVICE_PLAN_TYPE | undefined => state.servicePlanType,
  },
};
