import { stripHtml } from 'string-strip-html';

/**
 * HTMLタグのエスケープ処理をする関数
 * 置換するのは、 < と > だけ
 */
export function escapeHtmlTag(htmlTag: string): string {
  return htmlTag.replace('<', '&lt;').replace('>', '&gt;');
}

/**
 * <font color=red></font> と <br> 以外の HTMLタグについて、エスケープ処理をする
 * e-mansionからのお知らせ、 UCOMからのお知らせ、 Five.Aからのお知らせ の部分で使う
 * 対応するチケットは、ARN-967
 */
export function leaveFontRedAndBrTag(str: string): string {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const my = stripHtml(str, {
    cb: ({ tag, deleteFrom, deleteTo, rangesArr }) => {
      if (tag.name === 'font') {
        // </font> のとき
        // rangesArr の中で、最後に出現する font タグが、</font>でない場合は、追加する
        if (typeof tag.slashPresent === 'number') {
          // npm install で入ってくる型定義ファイルに誤りがあるため、(rangesArr as any).ranges して値を取得している
          const rangesLength = (rangesArr as any).ranges.length as number;

          if (rangesLength > 0) {
            const tagsToBeRemoved: Array<string> = (rangesArr as any).ranges.map((value: number[]) => str.slice(value[0], value[1]));

            const lastFontTagIndex = tagsToBeRemoved.reduce((acc, value, index) => {
              if (value.indexOf('font') !== -1) {
                return index;
              }
              return acc;
            }, -1);

            if (lastFontTagIndex !== -1) {
              if (tagsToBeRemoved[lastFontTagIndex] !== '</font>') {
                const escaped = escapeHtmlTag('</font>');
                rangesArr.push([deleteFrom!, deleteTo!, escaped]);
              }
            }
          }
        } else {
          const fontTag = str.slice(deleteFrom!, deleteTo!);
          if (fontTag !== '<font color=red>') {
            const escaped = escapeHtmlTag(fontTag);
            rangesArr.push([deleteFrom!, deleteTo!, escaped]);
          }
        }
      } else if (tag.name === 'br') {
        // 何もしない
      } else {
        const htmlTag = str.slice(deleteFrom!, deleteTo!);
        const escaped = escapeHtmlTag(htmlTag);
        rangesArr.push([deleteFrom!, deleteTo!, escaped]);
      }
    }
  });
  return my.result;
}
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
