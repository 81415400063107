/** Webスペース容量 入力(サービス) */
export class UcomWebSpaceServiceInfo {
  readonly _changeCapacityList!: WebSpaceChangeCapacity[]; // 追加/縮小容量リスト
  readonly _unitPrice: number; // 月額利用料
  readonly _serviceDescription: string; // サービス説明
  readonly _webSpaceMaxCapacity: number; // Webスペース最大容量

  constructor(changeCapacityList: WebSpaceChangeCapacity[], unitPrice: number, serviceDescription: string, webSpaceMaxCapacity: number) {
    this._changeCapacityList = changeCapacityList;
    this._unitPrice = unitPrice;
    this._serviceDescription = serviceDescription;
    this._webSpaceMaxCapacity = webSpaceMaxCapacity;
  }

  get changeCapacityList(): WebSpaceChangeCapacity[] {
    return this._changeCapacityList;
  }
  get unitPrice(): number {
    return this._unitPrice;
  }
  get serviceDescription(): string {
    return this._serviceDescription;
  }
  get webSpaceMaxCapacity(): number {
    return this._webSpaceMaxCapacity;
  }
}

export class WebSpaceChangeCapacity {
  public changeCapacityLabel!: string;
  public changeCapacityValue!: number;
}
