<template>
  <div class="privacy-policy">
    <LoadingComponent v-if="isRouting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Portasにおける個人情報の取り扱いについて</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb" v-if="$route.query.gamewith != 'true'">
        <li><router-link to="/">トップページ</router-link></li>
        <li>個人情報の取り扱い</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:個人情報保護方針 -->
      <div class="blc">
        <h2 class="txt-center">Portasにおける個人情報の取り扱いについて</h2>
        <p>
          アルテリア・ネットワークス株式会社（以下「当社」といいます。）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律(以下「個人情報保護法」といいます)を遵守すると共に、当社が提供するPortas
          (以下「本サービス」といいます)の利用にあたってお客様の個人情報の取扱いについて、以下のとおり定めます。<br />
        </p>
        <p><br /></p>
        <h4 class="underlayer-h4">１．個人情報の定義</h4>
        <p>本規定において、「個人情報」とは、個人情報保護法第2条第1項により定義された個人情報を意味するものとします。</p>
        <p><br /></p>
        <h4 class="underlayer-h4">２．利用目的</h4>
        <p>
          利用者が本サービスを利用するにあたり、当社へ登録、開示いただいた個人情報（氏名、住所、電話番号、FAX番号、メールアドレスその他特定の個人を識別することができる情報をいいます。）について、以下の利用目的で利用します。
        </p>
        <p>(1) 本サービスの提供のため</p>
        <p>(2) 本サービスに係る連絡、通知等を行うため</p>
        <p>(3) 障害、保守、サポートの対応その他問い合わせ対応のため</p>
        <p>(4) 当社グループ、提携企業のマーケティング、商品開発に係る調査、分析のため</p>
        <p>(5) メールマガジンの配信のため</p>
        <p>(6) アンケート、モニター募集、キャンペーン等の通知のため</p>
        <p>(7) 当社グループの商品、サービス、並びに提携企業の商品、サービスのご案内、紹介、広告、宣伝、取次のため</p>
        <p>(8) セミナー、展示会、イベントのご案内のため</p>
        <p>(9) 有料サービスの料金請求及び債権管理のため</p>
        <p><br /></p>
        <h4 class="underlayer-h4">3．第三者への提供</h4>
        <p>当社は、利用者が当社へ登録、開示いただいた個人情報について、法令等に基づく場合を除き、本人の同意なく第三者へ提供することはありません。</p>
        <p><br /></p>
        <h4 class="underlayer-h4">4．個人情報の取り扱いの委託</h4>
        <p>
          当社は、本サービスに係る業務その他第2項に定める利用目的を遂行するために、外部業者に個人情報の一部または全部の処理を委託することがあります。この場合、安全管理対策の充実した委託先を選定し、かつ安全管理対策を契約によって委託先に義務付けます。
        </p>
        <p><br /></p>
        <h4 class="underlayer-h4">5．Cookieについて</h4>
        <p>
          当社が運営、公開する本サービスのウェブサイト（以下「本ウェブサイト」といいます。）では、クッキー（Cookie）を使用しております。クッキーとは、ウェブサイトにアクセスした際に、当該サイトがコンピュータまたはモバイルデバイスに保存する小さなテキストファイルのことです。
        </p>
        <p><br /></p>
        <h4 class="underlayer-h4"><b>クッキーの使用</b></h4>
        <p>
          当社は、本ウェブサイトの利便性および品質維持・向上を目的に、クッキーを使用しております。これらのクッキーは、ブラウザを閉じると無効化されます。また、クッキーにより収集した情報から、個人が識別されることはありません。
        </p>
        <p>
          利用者は、ブラウザの設定により、クッキーの受け取りを拒否しまたは制限することができます。ただし、その場合、本ウェブサイトの機能が一部ご利用になれない場合があります。ブラウザの設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p><br /></p>
        <h4 class="underlayer-h4"><b>Googleアナリティクスの利用について</b></h4>
        <p>
          本ウェブサイトでは、利用者の本ウェブサイトの訪問状況を把握するためにGoogle 社のサービスであるGoogle アナリティクスを利用しています。本ウェブサイトでGoogle
          アナリティクスを利用しますと、当社が発行するクッキーをもとにして、Google 社が利用者の本ウェブサイトの訪問履歴を収集、記録、分析します。当社は、Google
          社からその分析結果を受け取り、利用者の本ウェブサイトの訪問状況を把握します。Googleアナリティクスにより収集、記録、分析された利用者の情報には、特定の個人を識別する情報は一切含まれません。また、それらの情報は、Google社により同社のプライバシーポリシーに基づいて管理されます。
        </p>
        <p><br /></p>
        <p>
          利用者は、ブラウザのアドオン設定でGoogle アナリティクスを無効にすることにより、当社のGoogle
          アナリティクス利用による利用者の情報の収集を停止することも可能です。Googleアナリティクスの無効設定は、Google社によるオプトアウトアドオンのダウンロードページで 「Google
          アナリティクス
          オプトアウトアドオン」をダウンロードおよびインストールし、ブラウザのアドオン設定を変更することで実施することができます。なお、利用者がGoogleアナリティクスを無効設定した場合、利用者が訪問する本ウェブサイト以外のウェブサイトでもGoogleアナリティクスは無効になりますが、利用者がブラウザのアドオンを再設定することにより、再度Google
          アナリティクスを有効にすることも可能です。
        </p>
        <p><br /></p>
        <p>Google アナリティクスの利用規約に関する説明についてはGoogle アナリティクスのサイトを、Google社のプライバシーポリシーに関する説明については同社のサイトをご覧下さい。</p>
        <p><br /></p>
        <p>Google アナリティクス利用規約：</p>
        <p><a href="http://www.google.com/analytics/terms/jp.html" class="link" target="_blank">http://www.google.com/analytics/terms/jp.html</a></p>
        <p>Googleプライバシーポリシー：</p>
        <p><a href="http://www.google.com/intl/ja/policies/privacy/" class="link" target="_blank">http://www.google.com/intl/ja/policies/privacy/</a></p>
        <p>Google アナリティクス オプトアウトアドオン：</p>
        <p><a href="https://tools.google.com/dlpage/gaoptout?hl=ja" class="link" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=ja</a></p>
        <p><br /></p>
        <h4 class="underlayer-h4">6. 継続的改善</h4>
        <p>当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本規定を変更することがあります。</p>
        <p><br /></p>
        <h4 class="underlayer-h4">7．個人情報に関するお問合せ対応</h4>
        <p>本規定に関してご不明な点がある場合、本サービスにおける個人情報の取り扱いに関するご質問・苦情・ご相談等があります場合は以下よりご連絡ください。</p>
        <p><br /></p>
        <p>【アルテリア・ネットワークス個人情報お問合せ窓口】</p>
        <p>※お問合せは、メールまたは郵便にての受付になります。</p>
        <p>E-mail：<a href="mailto:privacy-policy@arteria-net.com" class="link">privacy-policy@arteria-net.com</a></p>
        <p>〒105-0004　東京都港区新橋六丁目9番8号　住友不動産新橋ビル</p>
        <p><br /></p>
        <p>2022年6月24日制定</p>
        <p>2024年8月22日改訂</p>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc" v-if="$route.query.gamewith != 'true'">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onTopPage()">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** PrivacyPolicy コンポーネント */
export default defineComponent({
  name: 'privacy-policy',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isRouting: false, // onTopPage()が実行された後、router.pushが実行されるまで用
  }),
  methods: {
    async onTopPage() {
      if (this.isRouting) {
        return;
      }

      this.isRouting = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
