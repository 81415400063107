/** U-NEXT アカウント情報 */
export class UNextAccountInfo {
  /** Portas会員ID */
  public memberId!: string;

  /** U-NEXT会員ID */
  public unextId!: number;

  /**
   * 無料期間種別
   * 0: 無料期間あり、1: 無料期間なし
   */
  public freeType!: string;

  /**
   * ステータス
   * 0: 申し込み中、1: 契約中、2: 解約申し込み中、3: 解約
   */
  public status!: string;

  /** U-NEXTログインID */
  public unextLoginId!: string;

  /** U-NEXTパスワード */
  public unextPassword!: string;

  /** ギフトコード */
  public giftCode?: string | undefined;

  constructor(partial: Partial<UNextAccountInfo>) {
    Object.assign(this, partial);
  }
}
