<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページお申し込み受付完了" />お申し込み受付完了</h2>

        <div class="blc">
          <p class="red"><b>ホームページのお申し込みありがとうございました。</b></p>
          <p>下記の設定情報でホームページをご利用いただけます。</p>
          <p>お手続き内容をメールにてお送りいたしました。</p>
        </div>
        <table class="table-type1">
          <tr>
            <th class="va-middle">
              <p>FTPアカウント</p>
            </th>
            <td>
              <span class="red"
                ><b>{{ ftpAccount }}</b></span
              >
            </td>
          </tr>
          <tr>
            <th class="va-middle">
              <p>FTPパスワード</p>
            </th>
            <td>
              <span class="red"
                ><b>{{ ftpPassword }}</b></span
              >
            </td>
          </tr>
          <tr>
            <th class="va-middle">
              <p>FTPサーバー名</p>
            </th>
            <td>{{ ftpServerName }}</td>
          </tr>
          <tr>
            <th class="va-middle">
              <p>ファイル転送先</p>
            </th>
            <td>{{ fileDest }}</td>
          </tr>
          <tr>
            <th class="va-middle">
              <p>ホームページのURL</p>
            </th>
            <td>{{ homepageUrl }}</td>
          </tr>
        </table>
        <ul class="ul-basic01">
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」の前日までにお申し込みいただくと、鍵のお引渡し日からご利用可能となります。</li>
        </ul>
      </div>

      <div class="blc">
        <p>設定情報は「ホームページご利用状況」からもご確認いただけます。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goUsageCancel()">ご利用状況確認<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs mt-sp-1" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { EMansionHomepageCancelInitialDisplayRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-request';

export default defineComponent({
  name: 'homepage-application-complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    ftpAccount: '' as undefined | string,
    ftpPassword: '',
    ftpServerName: '' as undefined | string,
    fileDest: '' as undefined | string,
    homepageUrl: '' as undefined | string,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const ftpAccount = await this.$store.getters['eMansionHomepageOpenStore/ftpAccount'];

      if (!ftpAccount) {
        await this.reload();
      }

      // 確認画面から遷移した場合は、完了画面にデータを表示する
      await this.setData();
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async reload() {
      // リロードや戻るボタンを押下された場合は、ホームページ開設有無によって表示する画面を切り替える
      const eMansionAccountInfo = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // AE05-003.ホームページ：ご利用状況／ご解約_初期表示API呼び出し
      const response = await SpfApiHomepageAccessor.callHomepageCancelInitialDisplay(
        new EMansionHomepageCancelInitialDisplayRequest({
          _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
          _date: now,
        })
      );

      // 開設状態の場合はご利用状況/解約画面へ
      if (response.isHomepageOpened) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage/usage').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // それ以外は会員専用TOP画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });

      return;
    },
    async setData() {
      this.isLoading = true;

      this.ftpAccount = await this.$store.getters['eMansionHomepageOpenStore/ftpAccount'];
      // パスワードは解読して表示
      const password = await this.$store.getters['eMansionHomepageOpenStore/ftpPassword'];
      if (password) {
        this.ftpPassword = ObfuscationService.decode(password);
      }
      this.ftpServerName = await this.$store.getters['eMansionHomepageOpenStore/ftpServerName'];
      this.fileDest = await this.$store.getters['eMansionHomepageOpenStore/fileDest'];
      this.homepageUrl = await this.$store.getters['eMansionHomepageOpenStore/homepageUrl'];

      this.isLoading = false;
    },
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページURLをstoreに保存する
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    async goUsageCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページURLをstoreに保存する
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage/usage').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
