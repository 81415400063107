export class ExternalSearchPropertiesSearchQuasiNormalResponseDto {

  // 処理結果のステータス
  readonly status = 'fail';

  // エラーコード
  readonly code!: string;

  // メッセージ
  readonly message!: string;

  constructor(partial: Partial<ExternalSearchPropertiesSearchQuasiNormalResponseDto>) {
    Object.assign(this, partial);
  }
}
