import { Member } from "../spf-api/member";

/** Platform Portas新規会員登録・サービス同時お申し込み API送信用 */
export class ContractFromEntryPortasRequest {
  /** 新規登録会員情報 */
  public readonly member!: Omit<Member, 'id'>;
  /** クレジットカードトークン */
  public readonly creditCardToken!: string;
  /** クレジットカードトークン有効期限 */
  public readonly tokenExpireDate!: string;
  /** 申込対象商品 IDの配列 */
  public readonly productIdArray!: string[];
  /** お申し込みボタンが押下された日時 */
  public readonly contractAppliedAt!: string;

  constructor(fields: Required<ContractFromEntryPortasRequest>) {
    Object.assign(this, fields);
  }
}
