<template>
  <div>
    <LoadingComponent v-if="isSubmitting" />
    <div class="left-menu-logo" v-if="isSubAccount">
      <img src="../../images/logo.svg" alt="" class="hover-img" />
    </div>
    <div class="left-menu-logo" v-else>
      <router-link to="" v-on:click="onTop()"><img src="../../images/logo.svg" alt="" class="hover-img" /></router-link>
    </div>
    <div class="inner">
      <LeftMenuCommonLink
        :isLinkedEMansion="isLinkedEMansion"
        :isLinkedFiveA="isLinkedFiveA"
        :isLinkedUcom="isLinkedUcom"
        :isLinkedMcloud="isLinkedMcloud"
        :isUcomOrEmansionToppage="isUcomOrEmansionToppage"
        :isEmansionParentAccountFlag="isEmansionParentAccountFlag"
        :isLinkedToTsunaguOnlineShop="isLinkedToTsunaguOnlineShop"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { hasAdditionalAccount } from '@/shared/const/service-plan-type';
import { defineComponent } from 'vue';
import LeftMenuCommonLink from '../components/left-menu-common-link.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_ACCOUNT_AUTHORITY } from '@/shared/const/e-mansion';

/** LinkService コンポーネント */
export default defineComponent({
  name: 'e-mansion-my-page-menu',
  props: {
    isLinkedEMansion: {
      type: Boolean,
    },
    isLinkedUcom: {
      type: Boolean,
    },
    isLinkedMcloud: {
      type: Boolean,
    },
    isUcomOrEmansionToppage: {
      type: Boolean,
    },
    isLinkedFiveA: {
      type: Boolean,
    },
    isLinkedToTsunaguOnlineShop: {
      type: Boolean,
    },
  },
  data: () => ({
    // ボタン押下中かどうか
    isSubmitting: false,
    // サブアカウント用表示判定
    isSubAccount: false,
    // メインアカウント(親アカウントかどうか)
    isEmansionParentAccountFlag: false,
  }),
  components: {
    LeftMenuCommonLink,
    LoadingComponent,
  },
  emits: ['clickContacts'],
  async mounted() {
    this.$data.isSubAccount = this.isSubAccountFunc();
    this.$data.isEmansionParentAccountFlag = this.isEmansionParentAccount();
  },
  methods: {
    async onContacts() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/contacts').catch((error: any) => {
        checkRouterError(error);
      });
      return this.$emit('clickContacts', false);
    },
    /**
     * e-mansion
     * メインアカウント(親アカウント)の場合、trueを返す
     */
    isEmansionParentAccount(): boolean {
      const loginUserAuthority: E_MANSION_ACCOUNT_AUTHORITY = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']?.eMansionAccountInfo.accountAuthorization;
      // メインアカウント(親アカウント)の場合のみ、true
      if (loginUserAuthority === E_MANSION_ACCOUNT_AUTHORITY.MAIN_ACCOUNT) {
        return true;
      }
      return false;
    },
    isSubAccountFunc() {
      const planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      return hasAdditionalAccount(planType);
    },
    async onTop() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
});
</script>
