<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェック</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/platform">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>ウイルスチェック</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェック購入／解約確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック購入解約確認" />ウイルスチェック購入／解約確認</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「決定」ボタンを押してください。</p>

        <ErrorMessagesComponent errorMessageTitle="恐れ入りますが、内容をもう一度ご確認ください。" :errorMessages="errorMessages" />

        <div class="sblc">
          <table class="table-type2">
            <tr>
              <th class="va-middle">お申し込み内容</th>
              <td>
                <b>{{ subscriptionTypeText }}</b>
              </td>
            </tr>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>
                <span v-if="purchaseState">{{ purchaseState.accountName }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>
                <span v-if="purchaseState">{{ purchaseState.mailAddress }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o" v-if="purchaseState">月額 {{ myNumberWithDelimiter(purchaseState.monthlyFee) }} 円 （税込）</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>
                <span class="o" id="current_date" v-if="purchaseState">{{ purchaseState.subscriptionDate }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <span v-if="purchaseState" v-html="purchaseState.serviceDescriptionBeforePurchase"></span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" @click="goBack"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="apply">決定<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_VIRUS_CHECK_ACTION, UcomVirusCheckAction } from '@/shared/const/ucom/ucom-virus-check-action';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { SpfApiVirusCheckAccessor } from '../../../infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import {
  UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE,
  UcomVirusCheckPurchaseSubscriptionType,
  convertUcomVirusCheckPurchaseSubscriptionTypeById,
} from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';

export default defineComponent({
  name: 'virus-check-purchase-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    isMounted: boolean;
    isBusy: boolean;
    errorMessages: string[];
    accountId?: number;
    purchaseState?: UcomVirusCheckPurchaseState;
  } {
    return {
      isMounted: false,
      isBusy: false,
      errorMessages: [],
      accountId: undefined,
      purchaseState: undefined,
    };
  },
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    const ucomAccountId: string = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.accountId = Number(ucomAccountId);

    // 前画面からの情報を取得する
    this.purchaseState = this.$store.getters['ucomVirusCheckStore/purchaseState'];

    // 戻るボタン押下時にstoreのsetPauseStateに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
    this.$store.commit('ucomVirusCheckStore/setPurchaseState', undefined);
    this.isMounted = true;
  },
  methods: {
    /** 「戻る」のクリックハンドラ */
    async goBack(): Promise<void> {
      this.$store.commit('ucomVirusCheckStore/setPurchaseState', this.$data.purchaseState);
      await this.$router.push('/ucom/virus-check/input').catch((error) => {
        checkRouterError(error);
      });
    },

    /** 「決定」のクリックハンドラ */
    async apply(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // 型ガード
        if (this.accountId === undefined) {
          throw new Error();
        }
        if (this.purchaseState === undefined) {
          throw new Error();
        }
        const action = this.convertPurchaseAction(this.purchaseState.subscriptionType);
        if (!action) {
          throw new Error();
        }
        // ウイルスチェック情報更新 (購入/解約) API 呼出し
        const virusCheckUpdateResult = await SpfApiVirusCheckAccessor.updateUcomVirusCheckPurchase(
          new UpdateUcomVirusCheckRequest(Number(await AuthService.getMemberId()), this.accountId, this.purchaseState.subscriptionDate, action)
        );
        // レスポンスにエラーメッセージがあればエラーメッセージを表示して処理を終了する
        if (virusCheckUpdateResult.errorMessage) {
          this.errorMessages = [virusCheckUpdateResult.errorMessage];
          this.isBusy = false;
          return;
        }
        // 購入/解約完了 画面に渡す情報を Store に保存する
        this.$store.commit('ucomVirusCheckStore/setPurchaseState', this.$data.purchaseState);
        this.$store.commit('ucomVirusCheckStore/setNotifyMailAddress', virusCheckUpdateResult.mailAddress);

        // 購入/解約完了 画面に遷移する。catch() はナビゲーションガードでエラー画面へ行くのを防ぐために必要。
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/virus-check/purchase-complete').catch((e: any) => {
          checkRouterError(e);
        });
        this.isBusy = false;
      } catch (error) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },

    /**
     * 「購入」または「解約」の値を API に渡すパラメータに変換する
     */
    convertPurchaseAction(purchaseAction: UcomVirusCheckPurchaseSubscriptionType | undefined): UcomVirusCheckAction | undefined {
      if (purchaseAction === UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.PURCHASE) {
        return UCOM_VIRUS_CHECK_ACTION.PURCHASE;
      }
      if (purchaseAction === UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.CANCEL) {
        return UCOM_VIRUS_CHECK_ACTION.CANCEL;
      }
    },
    myNumberWithDelimiter(value: number) {
      return numberWithDelimiter(value);
    },

    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
  computed: {
    /** ローダーを表示するかどうか */
    isLoading(): boolean {
      if (!this.isMounted) {
        return true;
      }
      return this.isBusy;
    },
    /** お申し込み内容 に表示するテキスト */
    subscriptionTypeText(): string {
      if (this.purchaseState?.subscriptionType === undefined) {
        return '';
      }
      return convertUcomVirusCheckPurchaseSubscriptionTypeById(this.purchaseState.subscriptionType) ?? '';
    },
  },
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}
</style>
