<template>
  <div class="back-div">
    <div class="loading-component">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// スピナーアイコンのサイズ
$spinner-size: 40px;
// スピナーのボーダーの太さ
$spinner-border-size: 10px;
// スピナーのボーダーの色・薄い背景色はこの色を半透明にして薄めて利用する
$spinner-border-rgb-color: rgb(0, 0, 0);

//
.loading-component {
  opacity: 0;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  animation: 0.5s linear 0.5s 1 forwards fadein; // 2秒後に、0.5秒かけたアニメーションを、1回だけ実行し、`to` の状態で止める
  width: 0;
  height: 0;
  transition: all 1s;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

.back-div {
  background-color: rgba(75, 75, 75, 0.322);
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100%;
  z-index: 9998;
}

@keyframes fadein {
  from {
    opacity: 0;
    width: $spinner-size;
    height: $spinner-size;
  }
  to {
    opacity: 1;
    width: $spinner-size;
    height: $spinner-size;
  }
}

// スピナーアイコン
.spinner {
  width: $spinner-size;
  height: $spinner-size;
  border: $spinner-border-size solid rgba($spinner-border-rgb-color, 0.2);
  border-left-color: $spinner-border-rgb-color;
  border-radius: 50%;
  transform: translateZ(0);
  animation: 1s infinite linear spinner;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** ロード中を表現するスピナーのコンポーネント */
export default defineComponent({
  name: 'loading-component'
});
</script>
