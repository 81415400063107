/**
 * UCOM API : 光電話付加サービス取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomAdditionalServicesResponseResult {
  /** 結果値 */
  public result!: UcomAdditionalServicesResponse;

  constructor(partial: Partial<UcomAdditionalServicesResponseResult>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM API : 光電話付加サービス取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomAdditionalServicesResponse {
  /** UCOM光電話 光電話付加サービス (有料) */
  public optical_phone_impossible_services!: Array<UcomAdditionalService>;

  constructor(partial: Partial<UcomAdditionalServicesResponse>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM API : 光電話付加サービス取得 : レスポンス UCOM光電話 光電話付加サービス (有料)
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomAdditionalService {
  /** 光電話付加サービスID */
  public id!: string;
  /** 光電話付加サービス名 */
  public value!: string;

  constructor(partial: Partial<UcomAdditionalService>) {
    Object.assign(this, partial);
  }
}
