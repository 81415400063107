import { FiveACustomerQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import store from '@/store';

/**
 * ISP(Five.A) の VeriTransに関わる操作を司る
 */
export class FiveAVeriTransService {
  /**
   * Five.Aに登録されたVeriTransカードIDを取得する
   *
   * @returns 空文字列 or Portas会員テーブル.e-TNC用会員ID
   */
  public static async getVeriTransCardIdOnFiveA(): Promise<string> {
    const member: Member | null = store.getters['memberStore/member'];
    const property: Property | null = store.getters['propertyStore/property'];
    if (!member || !property) {
      return '';
    }
    const fiveACustomerQueryDto = new FiveACustomerQueryDto({
      ua_type: UA_TYPE.FIVE_A,
      apartment_id: property.apartmentId,
    });

    const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/customer', {
      memberId: member!.primaryKeyMye,
      query: fiveACustomerQueryDto,
    });

    if (fiveACustomer instanceof FiveACustomer && fiveACustomer.payment_id === '8') {
      if (member && member.eTncMemberId) {
        return member.eTncMemberId;
      }
    }
    return '';
  }
}
