<template>
  <div class="payment-method-delete-completed">
    <LoadingComponent v-if="isRouting" />
    <main class="underlayer-main">
      <h1>Portasサービス お支払い方法</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li><router-link to="/platform/my-page/payment-method/list">Portasサービス お支払い方法</router-link></li>
        <li>お支払い方法の削除完了</li>
      </ul>

      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li>お支払い方法の削除</li>
        <li class="stay">完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="お支払い方法削除完了" />お支払い方法の削除完了</h2>
        <p>Portas（ポルタス）に登録されているクレジットカード情報が正常に削除されました。</p>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onMyPage()">マイページへ<i
              class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs" @click="onTopPage()">トップページへ<i
              class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.img-size {
  height: 345px;
  width: 320px;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>

<script lang="ts">
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'payment-method-delete-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isRouting: false,
  }),
  async mounted(): Promise<void> {
    const isLoggedIn = await AuthService.isLoggedIn();
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async onTopPage() {
      if (this.isRouting) {
        return;
      }

      this.isRouting = true;

      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isRouting = false;
        });
    },
    async onMyPage() {
      if (this.isRouting) {
        return;
      }

      this.isRouting = true;

      await this.$router
        .push('/platform/my-page')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isRouting = false;
        });
    },
  },
});
</script>
