<template>
  <div>
    <LoadingComponent v-if="isExecutingOnAccountlinkage" />
    <main class="underlayer-main">
      <h1>e-mansion 追加アカウント連携</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion 追加アカウント連携</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:e-mansion 追加アカウント連携 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="e-mansion 追加アカウント連携" />e-mansion 追加アカウント連携</h2>
        <p>アカウント、パスワードをご入力していただき、アカウント連携をクリックしてください。</p>
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">Portas連携用アカウント<span class="req">必須</span></th>
              <td>
                <input type="text" class="text short" placeholder="tsunagu" v-model="inputData.userAccountFirst" /> -
                <input type="text" class="text short" placeholder="aa" v-model="inputData.userAccountSecond" />
              </td>
            </tr>
            <tr>
              <th class="va-middle">Portas連携用パスワード<span class="req">必須</span></th>
              <td><input type="password" class="text middle" v-model="inputData.password" /></td>
            </tr>
          </tbody>
        </table>

        <div class="sblc">
          <h3 class="service-h3">Portas連携用アカウント、Portas連携用パスワードについて</h3>
          <p>メインアカウントで Portas にログイン後、e-mansion 会員専用トップページ「追加アカウント」メニューでご確認いただけます。</p>
        </div>

        <div class="sblc">
          <div class="btn-area">
            <button class="btn btn01 bs" v-on:click="onAccountlinkage">アカウント連携</button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

div.sblc {
  p.note-on-id-and-password {
    margin-top: 8px;
  }
}

.table-type2 th {
  padding: 10px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { required } from '@vuelidate/validators';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { EMansionLinkingInput } from '@/shared/classes/auth-manegement/e-mansion-linking-input';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'sub-account-linking',
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。' as string,
    /** 入力データ */
    inputData: {
      userAccountFirst: '' as string,
      userAccountSecond: '' as string,
      password: '' as string,
    },
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    service: 'Portas',
  }),
  validations: {
    inputData: {
      userAccountFirst: {
        required,
      },
      userAccountSecond: {
        required,
      },
      password: {
        required,
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていなければ エラー画面 に遷移する
    if (!isLoggedIn) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.v$.inputData.$invalid) {
        if (this.v$.inputData.userAccountFirst?.required.$invalid || this.v$.inputData.userAccountSecond?.required.$invalid) {
          this.errorMessages.push('アカウントを入力してください。');
          window.scrollTo(0, 0);
        }
        if (this.v$.inputData.password?.required.$invalid) {
          this.errorMessages.push('パスワードを入力してください。');
          window.scrollTo(0, 0);
        }
        return false;
      }
      return true;
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isExecutingOnAccountlinkage = false;
    },
    /** アカウント連携ボタン押下時 */
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        // 処理中フラグtrue：エラーメッセージ表示時のみ解除する
        this.isExecutingOnAccountlinkage = true;
        if (this.validate()) {
          const eMansionLinkingInput: EMansionLinkingInput = {
            loginId: `${this.inputData.userAccountFirst}-${this.inputData.userAccountSecond}`,
            password: ObfuscationService.encode(this.inputData.password),
          };

          const result: undefined | AuthManegementSharedErrorResponse = await AuthManagementApiService.linkingSubAccountToEMansion(eMansionLinkingInput);
          if (result instanceof AuthManegementSharedErrorResponse) {
            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              this.showErrorMessage();
              return;
            } else {
              this.errorMessages.push('アカウントあるいはパスワードが一致しません。');
              this.showErrorMessage();
              return;
            }
          }
          await AuthService.refresh();

          // e-mansion サービストップ に遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },
  },
});
</script>
