<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ容量 完了</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ容量</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li>変更</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ホームページ容量　変更受付完了 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページ容量変更完了" />ホームページ容量変更完了</h2>
        <p>
          <b>
            <font color="red">ホームページ容量の変更お申し込みを受付けました。</font>
          </b>
        </p>
        お手続き内容をメールにてお送りいたしました。
        <br />
        <ul class="ul-basic01 separate">
          <li>容量変更お申し込み後、同月内は再変更できませんので予めご了承ください。</li>
        </ul>
      </div>
      <!-- /blc -->
      <br />

      <!-- blc:ホームページ容量　ご請求内容の確認方法 -->
      <div class="border-orange">
        <h3 class="service-h3">ご請求内容の確認方法</h3>
        <p>ご請求明細は、e-mansion会員専用トップページ「ご請求明細・販売証明書表示」メニューからご確認いただけます。</p>
        <div v-if="!isSlimPlan">
          <p>また、毎月のご請求確定時にメールでお知らせすることができます。</p>
          <p>メールの配信を希望される場合は「e-mansion お知らせメールの配信設定」からお申し込みください。</p>
        </div>
        <h3 class="service-h4">お問い合わせ先：e-mansionサポートセンター</h3>
        <p>お住まいのマンション専用ホームページ「会員サポート」メニューよりカスタマーサポートまでお問い合わせください。</p>
      </div>
      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->

      <!-- /blc -->
    </div>
  </div>
  <!-- /contents -->
  <!-- /main-contents -->
</template>

<style>
.border-orange {
  border-radius: 10px;
  border: 3px solid #f80;
  background-color: #ffffff;
  width: 900px;
  height: 215px;
  padding: 10px;
  padding-top: 0px;
}

.separate {
  margin-top: 1em;
}

@media only screen and (max-width: 767px) {
  .border-orange {
    width: auto;
    height: auto;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'homepageCapacityConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    isLoading: false,
    isSlimPlan: false, // スリムプラン該否
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 会員種別の取得
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];

      // スリムプラン会員か否か
      this.$data.isSlimPlan = this.checkSlimPlan(servicePlanType);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }

      this.$data.isLoading = true;
      // e-mansion 会員専用トップページへ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // スリムプラン会員か否かのチェック
    checkSlimPlan(servicePlanType: SERVICE_PLAN_TYPE): boolean {
      return servicePlanType == SERVICE_PLAN_TYPE.EM_SLIM || servicePlanType == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL;
    },
  },
});
</script>
