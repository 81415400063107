import { Module } from 'vuex';
import { RootState } from './index';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { ProductsDto } from '@/shared/classes/spf-api/portas-subscription/products-dto';

/** 商品一覧取得 State */
const productsState: {
  products: ProductsDto | null;
} = {
  products: null,
};

/** State の型 */
type ProductsState = typeof productsState;

/** 商品取得 Store */
export const productsStore: Module<ProductsState, RootState> = {
  namespaced: true,
  state: productsState,
  mutations: {
    products: (state, value: ProductsDto) => (state.products = value),
  },
  getters: {
    products: (state): ProductsDto | null => state.products,
  },

  actions: {
    async products(context, params: { productId: number }) {
      // APIコール
      const productsResult = await SpfApiService.getProductDetail(params.productId);
      // 値をコミットする
      context.commit('products', productsResult);
      const IfterProducts = context.getters['products'];
      return IfterProducts;
    },
  },
};
