export class EMansionWebMailLoginDto {
  /** 遷移先URL */
  readonly _redirectUrl!: string | undefined;
  public get redirectUrl(): string | undefined {
    return this._redirectUrl;
  }

  /** パスワード未設定エラーのメッセージ */
  readonly _errorMessages!: string[] | undefined;
  public get errorMessages(): string[] | undefined {
    return this._errorMessages;
  }

  constructor(redirectUrl: string | undefined, errorMessages: string[] | undefined) {
    this._redirectUrl = redirectUrl;
    this._errorMessages = errorMessages;
  }
}
