import { Member } from '@/shared/classes/spf-api/member';
import { Module } from 'vuex';
import { RootState } from './index';
import { MemberStatus } from '../shared/classes/spf-api/member-status';
import { AuthService } from '../shared/services/auth/auth-service';
import { SpfApiService } from '../shared/services/api/spf-api-service';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import axios, { AxiosError } from 'axios';

/** 会員情報 State */
const memberState: {
  member: Member | null;
  memberStatus: MemberStatus | null;
  eMansionMemberId: string | null;
  fiveAMemberId: string | null;
  ucomMemberId: string | null;
} = {
  member: null,
  memberStatus: null,
  eMansionMemberId: null,
  fiveAMemberId: null,
  ucomMemberId: null,
};

/** State の型 */
type MemberState = typeof memberState;

/** 会員情報 Store */
export const memberStore: Module<MemberState, RootState> = {
  namespaced: true,
  state: memberState,
  mutations: {
    member: (state, value: Member) => (state.member = value),
    updateMember: (state, value: Member) => (state.member = value),
    memberStatus: (state, value: MemberStatus) => (state.memberStatus = value),
    updateMemberStatus: (state, value: MemberStatus) => (state.memberStatus = value),
    eMansionMemberId: (state, value: string) => (state.eMansionMemberId = value),
    fiveAMemberId: (state, value: string) => (state.fiveAMemberId = value),
    ucomMemberId: (state, value: string) => (state.ucomMemberId = value),
  },
  getters: {
    member: (state): Member | null => state.member,
    memberStatus: (state): MemberStatus | null => state.memberStatus,
    eMansionMemberId: (state): string | null => state.eMansionMemberId,
    fiveAMemberId: (state): string | null => state.fiveAMemberId,
    ucomMemberId: (state): string | null => state.ucomMemberId,
  },
  actions: {
    async updateMember(context, params: { memberId: string; memberDto: Omit<Member, 'id'> }) {
      await SpfApiService.updateMember(params.memberId, params.memberDto);
      context.commit('member', null);
      return null;
    },
    async updateMemberStatus(context, params: { primaryKeyAuth0: string; memberStatus: MemberStatus }) {
      await SpfApiService.updateMemberStatus(params.primaryKeyAuth0, params.memberStatus);
      context.commit('memberStatus', null);
      return null;
    },
    async member(context) {
      // API コールする
      let memberResult = null;

      try {
        memberResult = await SpfApiService.findMember();
      } catch (error: any) {
        if (error.response?.status === 503 && error.response.data) {
          throw error;
        } else if (axios.isAxiosError(error)) {
          if (error.code === AxiosError.ERR_NETWORK) {
            throw error;
          }
        }
        return memberResult;
      }

      // 値をコミットする
      context.commit('member', memberResult);
      // Getter から取り直して返す
      const afterMemberValue = context.getters['member'];
      return afterMemberValue;
    },
    async memberStatus(context) {
      let statusResult = null;
      if (!(await AccountUtilService.needMemberStatus())) {
        // 会員ステータスが不要な場合、ダミーデータをセットしておく
        statusResult = new MemberStatus({});
      } else {
        try {
          const sub = await AuthService.getSub();

          statusResult = await SpfApiService.findMemberStatusByPrimaryKeyAuth0(sub);

        } catch (error: any) {

          if (error.response?.status === 503 && error.response.data) {
            throw error;
          } else if (axios.isAxiosError(error)) {
            if (error.code === AxiosError.ERR_NETWORK) {
              throw error;
            }
          }
        }
      }

      context.commit('memberStatus', statusResult);

      const afterStatusValue = context.getters['memberStatus'];
      return afterStatusValue;
    },
    async eMansionMemberId(context) {
      const memberId = await AuthService.getEMansionMemberId();
      context.commit('eMansionMemberId', memberId);
      return memberId;
    },
    async fiveAMemberId(context) {
      const memberId = await AuthService.getFiveAMemberId();
      context.commit('fiveAMemberId', memberId);
      return memberId;
    },
    async ucomMemberId(context) {
      const memberId = await AuthService.getUcomMemberId();
      context.commit('ucomMemberId', memberId);
      return memberId;
    },
    /** ユーザが e-mansion と連携しているか */
    async isLinkedToEMansion(context): Promise<boolean> {
      const member: Member = await context.dispatch('member');
      if (!member || !member.propertyId) return false;
      const property: Property = await context.dispatch('propertyStore/property', member.propertyId, { root: true });
      if (!property) return false;
      if (member.primaryKeyMye && property.uaType === UA_TYPE.E_MANSION) {
        return true;
      } else {
        return false;
      }
    },
    /** ユーザが UCOM と連携しているか */
    async isLinkedToUcom(context): Promise<boolean> {
      const member: Member = await context.dispatch('member');
      if (!member) return false;
      if (member.primaryKeyUcom) {
        return true;
      } else {
        return false;
      }
    },
    /** ユーザが Mcloud と連携しているか */
    async isLinkedToMcloud(context): Promise<boolean> {
      const member: Member = await context.dispatch('member');
      if (!member) return false;
      if (member.primaryKeyMcloud) {
        return true;
      } else {
        return false;
      }
    },
    /** ユーザが Five.A と連携しているか */
    async isLinkedToFiveA(context): Promise<boolean> {
      const member: Member = await context.dispatch('member');
      if (!member || !member.propertyId) return false;
      const property: Property = await context.dispatch('propertyStore/property', member.propertyId, { root: true });
      if (!property) return false;
      if (member.primaryKeyMye && property.uaType === UA_TYPE.FIVE_A) {
        return true;
      } else {
        return false;
      }
    },
  },
};
