/**
 * five-a Connectix API : Connectix お申し込み可能な部屋番号一覧 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class FiveAConnectixPropertyRoomsResponseResult {
  /** 結果値 */
  public result!: FiveAConnectixPropertyRoomsResponse;

  constructor(partial: Partial<FiveAConnectixPropertyRoomsResponseResult>) {
    Object.assign(this, partial);
  }
}

export class FiveAConnectixPropertyRoomsResponse {
  /** 結果値 */
  public rooms!: FiveAConnectixPropertyRooms[];

  constructor(partial: Partial<FiveAConnectixPropertyRoomsResponse>) {
    Object.assign(this, partial);
  }
}

/**
 * five-a API : Connectix お申し込み可能な部屋番号一覧 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class FiveAConnectixPropertyRooms {
  /** お申し込み可能な部屋一覧 */
  /** 棟ID */
  public building_id!: string;
  /** 棟名 */
  public building_name!: string;
  /** 部屋番号 */
  public room_number!: string;
  /** Connectix申し込みの可/不可 */
  public apply_connectix_id!: string;
  /** 入会の可/不可 */
  public join_id!: string;
  /** 物件_id */
  public property_id!: string;

  constructor(partial: Partial<FiveAConnectixPropertyRooms>) {
    Object.assign(this, partial);
  }
}
