<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>ウイルスチェック</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents" v-if="virusCheck">
      <ul class="breadcrumb">
        <li><a href="/platform">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>ウイルスチェック</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック一時停止開始" />ウイルスチェック一時停止／開始</h2>
        <p>設定内容をご確認いただき、問題ない場合は「上記内容に設定する」ボタンを押してください。</p>

        <ErrorMessagesComponent errorMessageTitle="恐れ入りますが、内容をもう一度ご確認ください。" :errorMessages="errorMessages" />

        <div class="sblc">
          <table class="table-type2">
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ virusCheck.accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ virusCheck.mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">ウイルスチェック設定</th>
              <td>{{ myNumberToDisplayPauseAction(virusCheck.virusCheckPauseAction) }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ virusCheck.subscriptionDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">注意事項</th>
              <td>
                <ul class="ul-basic01">
                  <li>{{ virusCheck.terms }}</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="onBack()">
            <i class="material-icons link link-icon">west</i>
            戻る
          </button>
          <button class="btn btn01 bs button-bottom" v-on:click="onChange()">
            上記内容に設定する
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { UcomVirusCheckPauseState } from '@/shared/classes/ucom/ucom-virus-check-pause-state';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_VIRUS_CHECK_ACTION, UcomVirusCheckAction } from '@/shared/const/ucom/ucom-virus-check-action';
import { convertUcomVirusCheckPauseActionById, UCOM_VIRUS_CHECK_PAUSE_ACTION, UcomVirusCheckPauseAction } from '@/shared/const/ucom/ucom-virus-check-pause-action';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'virus-check-pause-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data(): {
    isMounted: boolean;
    isBusy: boolean;
    virusCheck: UcomVirusCheckPauseState | undefined;
    errorMessages: string[];
  } {
    return {
      isMounted: false,
      isBusy: false,
      virusCheck: undefined,
      errorMessages: [],
    };
  },
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // No.1 初期表示
    // 1. お申し込み内容表示
    // 1.1. 前画面の情報を受け取り、表示制御を行う
    // 画面項目定義の書式／設定値と参照元に記載の内容で、各項目の値を加工し表示する。
    try {
      this.virusCheck = this.$store.getters['ucomVirusCheckStore/pauseState'];
      if (!this.virusCheck) {
        throw new Error('this.virusCheck is undefined');
      }
      // 戻るボタン押下時にstoreのsetPauseStateに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomVirusCheckStore/setPauseState', undefined);
    } catch (e) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
    }

    this.isMounted = true;
  },
  methods: {
    // No.2 戻る ボタン クリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      await this.storePauseState();

      // 1.1.ウイルスチェック: 入力画面に遷移する
      await this.$router.push('/ucom/virus-check/input').catch((error) => {
        checkRouterError(error);
      });
    },
    // No.3 上記内容に設定する ボタン クリック
    async onChange(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      if (!this.virusCheck) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      const ucomVirusCheckAction = this.convertPauseAction(this.virusCheck.virusCheckPauseAction);
      if (!ucomVirusCheckAction) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      this.isBusy = true;
      // エラーメッセージ初期化
      this.errorMessages = [];

      // 1. ウイルスチェック情報更新
      // 1.1. ウイルスチェック情報をAPIより更新する(正常時、お申し込み完了メールの送信も行う)
      try {
        const response = await SpfApiVirusCheckAccessor.updateUcomVirusCheckPause(
          new UpdateUcomVirusCheckRequest(
            Number(await AuthService.getMemberId()),
            Number(this.$store.getters['ucomAccountOptionStore/ucomAccountId']),
            this.virusCheck.subscriptionDate,
            ucomVirusCheckAction
          )
        );
        // 1.2. APIのレスポンスを元に、エラー表示部にエラー内容を設定する。
        const errorMessage = response.errorMessage && response.errorMessage.length ? response.errorMessage : undefined;
        if (errorMessage) {
          // エラーがある場合、エラー表示部にエラー内容を設定し、表示する
          this.errorMessages.push(errorMessage);
          this.isBusy = false;
          return;
        }
        // エラーが無い場合のみ、次の 2. 画面遷移処理 を行う
        // 2. 画面遷移処理
        // 画面項目の内容を、次画面に引き渡す
        await this.storePauseState();

        // 2.1. ウイルスチェック：一時停止／開始完了画面に遷移する
        await this.$router.push('/ucom/virus-check/pause-completed').catch((error) => {
          checkRouterError(error);
        });
      } catch (e) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
      }

      this.isBusy = false;
    },
    /**
     * 一時停止/開始関連の情報を Store に保存する
     */
    async storePauseState(): Promise<void> {
      if (!this.virusCheck) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      /**
       * 以下の画面項目の内容を、次画面に引き渡す
       * アカウントID: 画面保持値
       * メールアドレス: 画面保持値
       * ウイルスチェック設定: 画面保持値
       * お申し込み日: 画面保持値
       * 注意事項: 画面保持値
       */
      this.$store.commit('ucomVirusCheckStore/setPauseState', {
        accountName: this.virusCheck.accountName,
        mailAddress: this.virusCheck.mailAddress,
        virusCheckPauseAction: this.virusCheck.virusCheckPauseAction,
        subscriptionDate: this.virusCheck.subscriptionDate,
        terms: this.virusCheck.terms,
      } as UcomVirusCheckPauseState);
    },
    convertPauseAction(pauseAction: UcomVirusCheckPauseAction | undefined): UcomVirusCheckAction | undefined {
      if (pauseAction === UCOM_VIRUS_CHECK_PAUSE_ACTION.PAUSE) {
        return UCOM_VIRUS_CHECK_ACTION.PAUSE;
      }
      if (pauseAction === UCOM_VIRUS_CHECK_PAUSE_ACTION.UNPAUSE) {
        return UCOM_VIRUS_CHECK_ACTION.UNPAUSE;
      }
    },
    // ウイルスチェック設定表示用
    myNumberToDisplayPauseAction(number: number): string | undefined {
      return convertUcomVirusCheckPauseActionById(number) ?? '-';
    },
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
  computed: {
    // ローダー表示
    isLoading(): boolean {
      return !this.isMounted || this.isBusy;
    },
  },
});
</script>
<style>
@media only screen and (max-width: 767px) {
  button.button-bottom {
    margin-top: 10px;
  }
}
</style>
