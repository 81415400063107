<template>
  <div class="link-service">
    <LoadingComponent v-if="isLoading || isExecutingLinkAccount || isExecutingNavigateToLocation" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Mcloud アカウント連携</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Mcloud アカウント連携</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:Mcloud アカウント連携 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="Mcloud アカウント連携" />Mcloud アカウント連携</h2>
        <p>
          ログインID、パスワードをご入力していただき、Mcloud アカウント連携を押してください。<br />
          アカウント連携を行うと、次回以降PortasからMcloudへログインが可能となります。
        </p>
        <p>※アカウント連携には居住者権限のMcloudアカウントをご利用ください。</p>
        <div>
          <ErrorMessagesComponent v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <table class="table-type2">
          <tr>
            <th class="va-middle">ログインID<span class="req">必須</span></th>
            <td><input v-model="inputData.loginId" type="text" class="text middle" /></td>
          </tr>
          <tr>
            <th class="va-middle">パスワード<span class="req">必須</span></th>
            <td><input v-model="inputData.password" type="password" class="text middle" /></td>
          </tr>
        </table>
        <div class="sblc">
          <div class="btn-area">
            <button @click="linkAccount()" class="btn btn01 bs">Mcloud アカウント連携</button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { required } from '@vuelidate/validators';
import { AuthService } from '@/shared/services/auth/auth-service';
import { Member } from '@/shared/classes/spf-api/member';
import { McloudLinkingInput } from '@/shared/classes/auth-manegement/mcloud-linking-input';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { Property } from '@/shared/classes/spf-api/property';
import { ISP_TYPE_REGEXP } from '@/shared/const/service-type';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { UA_TYPE } from '@/shared/const/service-type';
import useVuelidate from '@vuelidate/core';

/** dataオブジェクトの型 */
type DataType = {
  inputData: McloudLinkingInput;
  errorMessages: string[];
  isLoggedIn: boolean;
  isExecutingLinkAccount: boolean;
  member: Member | null;
  isExecutingNavigateToLocation: boolean;
  errorMessageTitle: string;
  property: Property | null;
  isLoading: boolean;
};

/** LinkService コンポーネント */
export default defineComponent({
  name: 'link-service',
  data(): DataType {
    return {
      inputData: {
        loginId: '',
        password: '',
      },
      errorMessages: [],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
      isLoggedIn: false,
      isExecutingLinkAccount: false,
      member: null,
      isExecutingNavigateToLocation: false,
      property: null,
      isLoading: true, // 読み込み状態,最初はロード中
    };
  },
  validations: {
    inputData: {
      loginId: {
        required,
      },
      password: {
        required,
      },
    },
  },
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    try {
      /** ログインを判定する */
      this.isLoggedIn = await AuthService.isLoggedIn();

      // ログインしていなければ トップ画面 に遷移する
      if (!this.isLoggedIn) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      /** Auth0ユーザーがmcloudと連携済みかを確認する */
      this.member = this.$store.getters['memberStore/member'];

      if (this.member?.primaryKeyMcloud) {
        this.property = this.$store.getters['propertyStore/property'];

        if (this.property?.uaType === UA_TYPE.E_MANSION && this.member?.primaryKeyMye) {
          /** e-mansionと連携済の場合 e-mansion会員専用トップ に遷移させる */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        } else if (this.property?.uaType === UA_TYPE.UCOM && this.member?.primaryKeyUcom) {
          /** UCOMと連携済の場合 UCOM会員専用トップ に遷移させる */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/ucom').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        } else if (this.property?.uaType === UA_TYPE.FIVE_A && this.member?.primaryKeyMye) {
          /** Five.Aと連携済の場合 Five.A会員専用トップ に遷移させる */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/5a').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }

        /** Mcloudのみと連携済の場合 会員専用トップ に遷移させる */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      throw error;
    }
    this.$data.isLoading = false;
  },
  methods: {
    /** バリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.v$.inputData.$invalid) {
        if (!this.inputData.loginId) {
          this.errorMessages.push('ログインIDを入力してください。');
        }
        if (!this.inputData.password) {
          this.errorMessages.push('パスワードを入力してください。');
        }
        return false;
      }
      return true;
    },
    /** アカウント連携を行う */
    async linkAccount() {
      try {
        if (this.isExecutingLinkAccount) {
          return;
        }
        this.isExecutingLinkAccount = true;

        if (!this.validate()) {
          this.isExecutingLinkAccount = false;
          return;
        }
        /** mcloud連携で必要な会員IDを取得する */
        const mcloudLinkingInput: McloudLinkingInput = {
          loginId: this.inputData.loginId,
          password: this.inputData.password,
        };

        /** mcloudとの連携を行う */
        const isLinkedToMcloud: Promise<undefined | AuthManegementSharedErrorResponse> = await this.$store.dispatch('mcloudLinkingStore/updateLinking', mcloudLinkingInput);
        if (isLinkedToMcloud instanceof AuthManegementSharedErrorResponse) {
          if (isLinkedToMcloud.errors[0] === 'This User Cannot Mcloud Linking') {
            /** ログインIDかパスワードが間違えている場合 */
            this.errorMessages.push('ログインIDあるいはパスワードが一致しません。');
          } else if (isLinkedToMcloud.errors[0] === 'This Mcloud User Already Linking') {
            /** すでにアカウントが連携済の場合 */
            this.errorMessages.push('アカウント連携に失敗しました。');
          } else if (isLinkedToMcloud.errors[0] === 'This User is board member' || isLinkedToMcloud.errors[0] === 'This User is not resident') {
            /** 理事会フラグが立っているアカウント もしくは 管理者アカウント の場合 */
            this.errorMessages.push('アカウント連携に失敗しました。');
          } else {
            // エラーケースの場合
            this.errorMessages.push('アカウント連携に失敗しました。');
            return;
          }
          this.isExecutingLinkAccount = false;
          return;
        }

        await AuthService.refresh();
        this.$store.commit('memberStore/member', null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const status: number = error.response?.status;
        /** httpステータスコードが500番台またはタイムアウトなどによってレスポンスを返さない場合は、エラー画面へ遷移させる */
        if (!status || status >= 500) {
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
        /** 400番台の場合はエラーメッセージを表示させる */
        this.errorMessages.push('アカウント連携に失敗しました。');
        this.isExecutingLinkAccount = false;
      }
    },
    navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      this.$router
        .push(path)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isExecutingNavigateToLocation = false;
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}
</style>
