import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { AuthService } from '../auth/auth-service';
import { EMansionPlanTypeService } from './e-mansion-plan-type-service';
import { UcomPlanTypeService } from './ucom-plan-type-service';

/**
 * 会員種別取得サービス
 */
export class AuthorizationService {
  /**
   * 会員種別取得
   * @returns SERVICE_PLAN_TYPE 会員種別
   */
  public static async getPlanType(): Promise<SERVICE_PLAN_TYPE> {
    if (await AuthService.isLinkedToEMansion()) {
      return EMansionPlanTypeService.getAccountPlanType();
    }
    if (await AuthService.isLinkedToUcom()) {
      return UcomPlanTypeService.getAccountPlanType();
    }
    return SERVICE_PLAN_TYPE.DEFAULT;
  }
}
