<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Webスペース容量変更 入力</h1>
    </main>
    <!-- /underlayer-main -->

    <div v-if="paymentStatus" class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>Webスペース容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Webスペース容量変更" />Webスペース容量変更</h2>
        <p>追加／縮小する容量を選択し、「次へ進む」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <h3 class="service-h3">サービス内容</h3>
      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">ホームページアドレス</th>
              <td>{{ homepageAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">現在の容量</th>
              <td>{{ webSpaceCapacity }}.0MB</td>
            </tr>
            <tr>
              <th class="va-middle">追加／縮小容量<span class="req">必須</span></th>
              <td>
                <select v-model="changeCapacity" class="form-select">
                  <option :value="null" disabled>選択してください。</option>
                  <option v-for="WebSpaceChangeCapacity in changeCapacityList" v-bind:value="WebSpaceChangeCapacity" v-bind:key="WebSpaceChangeCapacity.changeCapacityValue">
                    {{ WebSpaceChangeCapacity.changeCapacityLabel }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>10.0MB 毎に月額{{ unitPrice }}円（税込）<br />最大{{ webSpaceMaxCapacity }}.0MBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <span v-html="serviceDescription"></span><br />UCOM 光 レジデンス ISP 会員付加サービス利用規約については<a :href="termsUrl" target="_blank" class="link">こちら</a
                >からご確認ください。
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明への同意<span class="req">必須</span></th>
              <td>
                お申し込み前にサービス説明を必ずお読みになり、「上記の内容に同意する」にチェックしてください。<br />
                <Label>
                  <input type="checkbox" v-model="isAgreeTerms" />
                  <b>上記の内容に同意する</b>
                </Label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <p class="form-btn-txt">「次へ進む」を押してください。確認画面へ進みます。</p>
        <div class="btn-area">
          <button class="btn bs" :class="[isAgreeTerms ? 'btn01' : 'btn05']" :disabled="!isAgreeTerms" @click="onNext">
            次へ進む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->

    <div v-else class="contents">
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お支払い方法未登録" />お支払い方法未登録</h2>
        <p>
          お支払方法が未登録のため、サービスの購入ができません。<br />
          <u>
            <a :href="paymentUrl" class="payment-url" target="_blank" rel="noopener noreferrer"> こちら </a>
          </u>
          からお支払方法の登録を行ってください。
        </p>
        <p>
          お支払い方法登録後、ブラウザの戻るボタンでUCOM光レジデンス会員専用トップに戻り<br />
          再度購入ご希望のサービスをご選択ください。
        </p>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiWebSpaceAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-accessor';
import dayjs from 'dayjs';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UCOM_PROPERTY_SERVICE_PROVID_FORMER } from '@/shared/const/ucom/ucom-property-service-provid-former';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { WebSpaceChangeCapacity } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-service-info';

export default defineComponent({
  name: 'web-space-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    /** 会員ID */
    memberId: '',
    /** UCOM_アカウントメールID */
    ucomAccountMailId: '',
    /** UCOM_アカウントID */
    accountName: '',
    /** メールアドレス */
    mailAddress: '',
    /** ホームページアドレス */
    homepageAddress: '',
    /** 現在のWebスペース容量 */
    webSpaceCapacity: '',
    /** 追加/縮小するwebスペース容量 */
    changeCapacity: null as null | WebSpaceChangeCapacity,
    /** Webスペース最大容量 */
    webSpaceMaxCapacity: '',
    /** 申し込み日 */
    applicationDate: '',
    /** 追加/縮小容量(Label) */
    changeCapacityLabel: '',
    /** 追加/縮小容量(Value) */
    changeCapacityValue: '',
    /** 追加/縮小容量リスト */
    changeCapacityList: new Array<WebSpaceChangeCapacity>(),
    /** 月額利用料 */
    unitPrice: 0,
    /** サービス説明 */
    serviceDescription: '',
    /** サービス説明への同意 */
    serviceConsent: false,
    /** お支払情報登録有無 */
    paymentStatus: true,
    /** ローディング */
    isLoading: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部タイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    isAgreeTerms: false,
    NOT_SELECTED_MESSAGE: '追加／縮小容量が選択されていません。',
    paymentUrl: process.env.VUE_APP_UCOM_MY_URL + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
    termsUrl: '',
  }),
  async mounted() {
    try {
      this.isLoading = true;

      // UCOM_アカウントメールIDをStoreから取得
      this.ucomAccountMailId = this.$store.getters['ucomAccountOptionStore/ucomAccountMailId'];

      // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
      if (!this.ucomAccountMailId) {
        await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // 画面項目セット
      await this.setData(this.ucomAccountMailId);
      //規約URLセット
      this.termsUrl = this.setTermsUrl();
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
    }
  },

  methods: {
    async setData(ucomAccountMailId: string): Promise<void> {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      if (this.$store.getters['ucomWebSpaceStore/paymentStatus']) {
        await this.setDataByStore();
        return;
      }

      // 会員IDを共通情報より取得
      this.memberId = this.$store.getters['memberStore/member'].id;
      if (!this.memberId) {
        await this.$router.push('/error').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      try {
        // 現在日付の取得
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const targetDate = this.formatDate(now);
        // Webスペース容量変更：入力(ユーザー)_初期表示API 呼び出し
        const userResponse = await SpfApiWebSpaceAccessor.findUcomWebSpaceUserInfo(this.memberId, ucomAccountMailId, String(targetDate));
        // Webスペース容量変更：入力(サービス)_初期表示API 呼び出し
        const serviceResponse = await SpfApiWebSpaceAccessor.findUcomWebSpaceServiceInfo(this.memberId, ucomAccountMailId, String(targetDate));

        // APIからの取得結果を反映
        this.paymentStatus = userResponse.paymentStatus;
        this.accountName = userResponse.accountName;
        this.mailAddress = userResponse.mailAddress;
        this.homepageAddress = userResponse.homepageAddress;
        this.webSpaceCapacity = userResponse.webSpaceCapacity;
        this.changeCapacityList = serviceResponse.changeCapacityList;
        this.unitPrice = serviceResponse.unitPrice;
        this.webSpaceMaxCapacity = String(serviceResponse.webSpaceMaxCapacity);
        this.serviceDescription = serviceResponse.serviceDescription;
      } catch (e) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    },
    /**
     * サービス規約のURLをセット
     */
    setTermsUrl(): string {
      //物件基本情報
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty?.service_provid_former.service_provid_former_id == UCOM_PROPERTY_SERVICE_PROVID_FORMER.TNC.id) {
        return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_TNC;
      }
      return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_ARTERIA;
    },

    // storeからデータをセット
    async setDataByStore() {
      this.paymentStatus = this.$store.getters['ucomWebSpaceStore/paymentStatus'];
      this.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
      this.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
      this.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
      this.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
      this.changeCapacityList = this.$store.getters['ucomWebSpaceStore/changeCapacityList'];
      this.changeCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
      this.changeCapacityValue = this.$store.getters['ucomWebSpaceStore/changeCapacityValue'];
      this.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
      this.webSpaceMaxCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceMaxCapacity'];
      this.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
      this.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];
    },

    // 「次へ進む」ボタンクリック
    async onNext() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.errorMessages = [];

      if (!this.changeCapacity) {
        this.errorMessages.push(this.NOT_SELECTED_MESSAGE);
        await this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      // Webスペース容量情報をStoreへ保存
      await this.saveStore();

      // 確認画面へ遷移
      await this.$router.push('/ucom/web-space/confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },

    // Webスペース容量情報をstoreに保存
    async saveStore(): Promise<void> {
      this.$store.commit('ucomWebSpaceStore/paymentStatus', this.paymentStatus);
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', this.ucomAccountMailId);
      this.$store.commit('ucomWebSpaceStore/accountName', this.accountName);
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', this.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', this.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityList', this.changeCapacityList);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', this.changeCapacity?.changeCapacityLabel ? this.changeCapacity?.changeCapacityLabel : undefined);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', this.changeCapacity?.changeCapacityValue ? this.changeCapacity?.changeCapacityValue : undefined);
      this.$store.commit('ucomWebSpaceStore/unitPrice', this.unitPrice);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', this.webSpaceMaxCapacity);
      this.$store.commit('ucomWebSpaceStore/applicationDate', dayjs(new Date()).format('YYYY/MM/DD'));
      this.$store.commit('ucomWebSpaceStore/serviceDescription', this.serviceDescription);
    },

    // ページTOPへ
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isAgreeTerms,
        'btn btn01 bs': this.isAgreeTerms,
      };
    },
  },
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}
.payment-url {
  color: red;
  text-decoration: underline;
}
</style>
