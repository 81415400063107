/** お問い合わせ情報DTO */
export class ContactsDto {
  /** 姓 */
  public firstName!: string;

  /** 名 */
  public givenName!: string;
  
  /** 住所 */
  public address!: string;
  
  /** マンション名 */
  public propertyName!: string;

  /** 棟名 */
  public buildingName!: string;
  
  /** お部屋番号 */
  public roomNumber!: string;
  
  /** 電話番号 */
  public phoneNumber!: string;
  
  /** メールアドレス */
  public emailAddress!: string;

  /** お問い合わせの種類 */
  public contactCategory!: string;
  
  /** お問い合わせ内容 */
  public contactDetail!: string;

  /** 個人情報取り扱い同意 */
  public agree!: boolean;

  /** ログイン済みの場合:true 未ログインの場合:false */
  public isLogin!: boolean;

  /** PortasID (isLoginがtrueの場合は必須) */
  public portasId?: number;

  /** 会員情報より取得したUCOMの主キー */
  public primaryKeyUcom?: number;

  /** e-mansion 会員番号 */
  public primaryKeyMye?: string;
  
  /** Mcloud ログインID */
  public primaryKeyMcloud?: string;

  /** コンストラクタ */
  constructor(partial: Partial<ContactsDto>) {
    Object.assign(this, partial);
  }
}