import { Module } from 'vuex';
import { RootState } from '../index';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-accessor';
import { UcomAccountInfo } from '@/shared/classes/spf-api/mail/model/common/permission/ucom/ucom-account-info';

/** UCOM アカウント連携 State */
const ucomAccountState: {
  ucomAccount: UcomAccountInfo | undefined;
} = {
  /** UCOM Account 情報*/
  ucomAccount: undefined,
};

/** State の型 */
type UcomAccountState = typeof ucomAccountState;

/** UCOM Account共通系 Store */
export const ucomAccountStore: Module<UcomAccountState, RootState> = {
  namespaced: true,
  state: ucomAccountState,

  mutations: {
    ucomAccount: (state, value: UcomAccountInfo) => (state.ucomAccount = value),
  },
  getters: {
    ucomAccount: (state): UcomAccountInfo | undefined => state.ucomAccount,
  },
  actions: {
    async ucomAccount(context, customerNo: string) {
      context.commit('ucomAccount', await SpfApiAccountAccessor.getUcomAccountByCustomerNo(customerNo));
      return context.getters['ucomAccount'];
    },
    async ucomSubAccount(context, accountName: string) {
      context.commit('ucomAccount', await SpfApiAccountAccessor.getUcomAccountByAccountName(accountName));
      return context.getters['ucomAccount'];
    },
  },
};
