/** Platform 支払方法編集 API送信用 */
export class PlatformPaymentMethodEditRequest {
  /** Portas会員ID */
  public readonly memberId!: number;
  /** 支払方法 No */
  public readonly paymentMethodNo!: number;
  /** クレジットカードトークン */
  public readonly creditCardToken!: string;
  /** クレジットカードトークン有効期限 */
  public readonly tokenExpireDate!: string;

  constructor(fields: Required<PlatformPaymentMethodEditRequest>) {
    Object.assign(this, fields);
  }
}
