import { Module } from 'vuex';
import { RootState } from '@/store';
import { VirusCheckFee } from '@/shared/classes/spf-api/e-mansion-mail';

/** e-mansion メール基盤 State */
const eMansionMailState: {
  selectedMailAddressId: number | null;
  selectedMailAddress: string | null;
  selectedFeeInfo: VirusCheckFee | null;
  selectedCancelOn: Date | null;
} = {
  selectedMailAddressId: null,
  selectedMailAddress: null,
  selectedFeeInfo: null,
  selectedCancelOn: null,
};

/** State の型 */
type EMansionMailState = typeof eMansionMailState;

/** e-mansion メール基盤 Store */
export const eMansionMailStore: Module<EMansionMailState, RootState> = {
  namespaced: true,
  state: eMansionMailState,

  actions: {},

  mutations: {
    setSelectedMailAddressId: (state, mailAddressId: number) => {
      state.selectedMailAddressId = mailAddressId;
    },
    setSelectedMailAddress: (state, mailAddress: string) => {
      state.selectedMailAddress = mailAddress;
    },
    setSelectedFeeInfo: (state, feeInfo: VirusCheckFee) => {
      state.selectedFeeInfo = feeInfo;
    },
    setSelectedCancelOn: (state, cancelOn: Date) => {
      state.selectedCancelOn = cancelOn;
    },
  },

  getters: {
    selectedMailAddressId: (state): number | null => state.selectedMailAddressId,
    selectedMailAddress: (state): string | null => state.selectedMailAddress,
    selectedFeeInfo: (state): VirusCheckFee | null => state.selectedFeeInfo,
    selectedCancelOn: (state): Date | null => state.selectedCancelOn,
  },
};
