/**
 * e-mansion 共通利用 API : 部屋番号一覧取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export interface EMansionRoomsDetail {
  /** 棟ID
   * 棟のない物件で部屋番号の設定がある場合、「棟ID:"0000"」となり、その場合棟名は空となる
  */
  readonly building_id: string;
  /** 棟名 */
  readonly building_name: string;
  /** 部屋番号 */
  readonly room_number: string;
  /** Connectixお申し込みの可/不可
   * 1: お申し込み可能 0:お申し込み不可
  */
  readonly apply_connectix_id: string;
  /** 物件ID */
  readonly property_id: string;
}

/**
 * e-mansion 共通利用 API : 部屋番号一覧取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionRoomsResponse {
  public result!: {
    rooms: EMansionRoomsDetail[];
  };

  constructor(partial: Partial<EMansionRoomsDetail>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 部屋番号一覧取得 : レスポンス
 *
 * 外部APIのレスポンスを使用しやすい形に変換している
 */
export class EMansionRoomsResponseResult {
  public rooms!: Array<EMansionRoomsDetail>;

  constructor(partial: Partial<EMansionRoomsResponseResult>) {
    Object.assign(this, partial);
  }
}
