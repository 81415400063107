<template>
  <div class="member-completed">
    <LoadingComponent v-if="isRouting || isMounting" />
    <main class="underlayer-main">
      <h1>Portas会員情報変更 完了</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li><router-link to="/platform/my-page/member-edit">会員情報変更</router-link></li>
        <li>完了</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>会員情報変更 入力</li>
        <li>会員情報変更 確認</li>
        <li class="stay">会員情報変更 完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="会員情報変更" />Portas会員情報変更 完了</h2>
        <p>Portas（ポルタス）会員情報変更が完了しました。</p>
        <img src="../../../images/image.png" alt="新規利用者登録完了" class="img-center img-complete" />
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onNext()" v-if="!isFromISPEntry">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs" @click="onNext()" v-if="isFromISPEntry">お客様情報入力画面に戻る<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.img-size {
  height: 345px;
  width: 320px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** MemberCompleted コンポーネント */
export default defineComponent({
  name: 'member-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    // 遷移元パス
    path: '',
    isRouting: false, // onNext()が実行された後、router.pushが実行されるまで用
    isMounting: false,
  }),
  async mounted(): Promise<void> {
    this.isMounting = true;
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    /** 遷移元パスをストアから呼び出してストアを初期化 */
    this.path = this.$store.getters['platformEditStore/beforePath'] ?? '';
    this.$store.commit('platformEditStore/beforePath', '');
    this.isMounting = false;

    // 遷移元画面がISP新規会員登録入力・確認か会員情報変更＞入力確認画面以外からの遷移ならに会員トップ画面に飛ばす
    if (
      !(
        this.path === '/platform/my-page/member-confirm' ||
        this.path === '/e-mansion/entry/input' ||
        this.path === '/e-mansion/entry/confirm' ||
        this.path === '/ucom/entry/input' ||
        this.path === '/5a/entry/input' ||
        this.path === '/5a/entry/confirm'
      )
    ) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async onNext() {
      if (this.isRouting) {
        return;
      }

      this.isRouting = true;

      /** 遷移元パスがISP新規会員登録入力・確認画面の場合は該当画面に遷移する */
      if (
        this.path === '/e-mansion/entry/input' ||
        this.path === '/e-mansion/entry/confirm' ||
        this.path === '/ucom/entry/input' ||
        this.path === '/5a/entry/input' ||
        this.path === '/5a/entry/confirm'
      ) {
        // confirmは結局規約に戻される
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push(this.path).catch((error: any) => {
          checkRouterError(error);
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },
  },
  computed: {
    isFromISPEntry(): boolean {
      return (
        this.path === '/e-mansion/entry/input' ||
        this.path === '/e-mansion/entry/confirm' ||
        this.path === '/ucom/entry/input' ||
        this.path === '/5a/entry/input' ||
        this.path === '/5a/entry/confirm'
      );
    },
  },
});
</script>
