<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メインメールアドレス</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>メインメールアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li class="stay">ご利用状況</li>
        <li>入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メインメールアドレスご利用状況" />ご利用状況</h2>
      <div class="blc" v-if="mainMailList?.hasMailAddress">
        <p>メインメールアドレスを変更したい場合は、「変更」をクリックしてください。入力画面に進みます。</p>
        <p>ご解約の場合は、「解約」をクリックしてください。</p>

        <table class="table-type2">
          <tr>
            <th class="va-middle">現在のメールアドレス</th>
            <td>{{ mainMailList.mailAddress }}</td>
          </tr>
          <tr>
            <th class="va-middle">ステータス</th>
            <td>{{ mainMailList.registerStatusView }}</td>
          </tr>
          <tr>
            <th class="va-middle">メールアカウント</th>
            <td>{{ mailAccount }}</td>
          </tr>
          <tr>
            <th class="va-middle">メール初期パスワード</th>
            <td>{{ mainMailList.initialMailPassword }}</td>
          </tr>
          <tr>
            <th class="va-middle">POPサーバー</th>
            <td>{{ mainMailList.popServer }}</td>
          </tr>
          <tr>
            <th class="va-middle">SMTPサーバー</th>
            <td>{{ mainMailList.smtpServer }}</td>
          </tr>
          <tr>
            <th class="va-middle">IMAPサーバー</th>
            <td>{{ mainMailList.imapServer }}</td>
          </tr>
          <!-- ステータスが解約申請中なら -->
          <tr v-if="mainMailList.registerStatus == '3'">
            <th class="va-middle">サービス終了日</th>
            <td>{{ cancelOn }}</td>
          </tr>
          <tr>
            <th class="va-middle">お申し込み</th>
            <td>
              <div>
                <p>
                  <button v-if="isChangeBtn" class="btn btn01 bs" v-on:click="onChange()">変更<i class="material-icons link link-icon">east</i></button>
                  <button v-else class="btn btn05 bs" disabled>変更<i class="material-icons link link-icon">east</i></button>
                </p>
              </div>
              <br />
              <div v-if="isCancelBtn">
                <p>
                  <button class="btn btn04 bs" v-on:click="onCancel()">解約<i class="material-icons link link-icon">east</i></button>
                </p>
              </div>
              <div v-else>
                <p>
                  <button class="btn btn05 bs" disabled>解約<i class="material-icons link link-icon">east</i></button>
                </p>
                <div v-if="isCancelErrMsg">
                  <p>このメールアドレスは、e-mansion からのお知らせ配信先に登録されているため解約できません。</p>
                  <p>e-mansion 会員専用トップ「e-mansion お知らせメールの配信設定」メニューで配信先メールアドレスをご登録ください。</p>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <p>登録・変更お申し込み直後は「お申し込み受付済」と表示されます。</p>
        <p>メールアドレスがご利用可能となりましたら「ご利用中」と表示されます。</p>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionMainMailAddressList } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'list',
  components: {
    LoadingComponent,
  },
  data: () => ({
    mainMailList: null as EMansionMainMailAddressList | null, //利用状況情報
    cancelOn: '',
    isLoading: true,
    isBeforeSin: false, // サービスイン日前ならtrue
    isCancelBtn: false, //解約ボタン活性化するか
    isChangeBtn: false, //変更ボタン活性化するか
    isCancelErrMsg: false, //解約ボタンの非活性時にメッセージを表示するか
    mailAccount: '', //メールアカウント
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const accountInfo: EMansionAccountInfo | undefined = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      if (!accountInfo) {
        throw new Error('エラーが発生しました');
      }
      //メインメールアドレス情報をAPIより取得する
      this.mainMailList = await SpfApiMainMailAddressAccessor.getMainMailAddressList(accountInfo.eMansionAccountInfo.eMansionAccountId);
      //メールアドレスが無い場合はTOPページへ(リンク直打ち対策)
      if (!this.mainMailList.hasMailAddress) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.mailAccount = this.mainMailList.mailLocalPart + '-' + this.mainMailList.mailSubdomain;
      // 現在日付の取得
      const currentDateObject: Date = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      if (this.mainMailList.serviceInTime) {
        const sin = new Date(this.mainMailList.serviceInTime);
        this.isBeforeSin = this.isDateBeforeSinDay(currentDateObject, sin);
      }
      /**ボタンを活性化するかどうかを判断 */
      this.isCancelBtn = this.isViewCancelBtn(
        this.mainMailList.shouldNotificationMailAddress!,
        this.mainMailList.canMainMailAddressCancel!,
        this.mainMailList.registerStatus!,
        this.isBeforeSin
      );
      this.isChangeBtn = this.isViewChangeBtn(this.mainMailList.registerStatus!, this.isBeforeSin);
      //日付の形式に変換
      if (this.mainMailList.cancelOn) {
        this.cancelOn = new Date(this.mainMailList.cancelOn).toLocaleDateString();
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**サービスイン日が未来→true */
    isDateBeforeSinDay(date: Date, sin: Date): boolean {
      const n = process.env.VUE_APP_E_MANSION_DAYS_TO_START_SERVICE;
      if (!n) {
        throw new Error('process.env.VUE_APP_E_MANSION_DAYS_TO_START_SERVICEがありません');
      }
      const marginedDate = new Date(sin.getTime());
      marginedDate.setDate(marginedDate.getDate() - Number(n));

      return date < marginedDate;
    },
    /**解約ボタンを活性化するかの判断を行う */
    isViewCancelBtn(shouldNotificationMailAddress: boolean, canMainMailAddressCancel: boolean, registerStatus: string, isBeforeSin: boolean): boolean {
      if (registerStatus == '3') {
        return false;
      }
      if (registerStatus == '4') {
        return false;
      }
      if (registerStatus == '1') {
        if (!isBeforeSin) {
          return false;
        }
      }
      if (shouldNotificationMailAddress) {
        if (!canMainMailAddressCancel) {
          this.isCancelErrMsg = true;
          return false;
        }
      }
      return true;
    },
    /**変更ボタンを活性化するかの判断を行う */
    isViewChangeBtn(registerStatus: string, isBeforeSin: boolean): boolean {
      if (registerStatus == '3') {
        return false;
      }
      if (registerStatus == '4') {
        return false;
      }
      if (registerStatus == '1') {
        if (!isBeforeSin) {
          return false;
        }
      }
      return true;
    },
    /**変更ボタンクリック */
    async onChange() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //メールアドレスをstoreに保存する
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.mainMailList?.mailAddress);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/main-mail-address/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**解約ボタンクリック */
    async onCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //メールアドレスをstoreに保存する
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.mainMailList?.mailAddress);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/main-mail-address/cancel-confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
