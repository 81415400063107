const SHIFT_DIRECTION = {
  RIGHT: 1,
  LEFT: -1,
} as const;
type SHIFT_DIRECTION = typeof SHIFT_DIRECTION[keyof typeof SHIFT_DIRECTION];

/**
 * 難読化サービス
 */
export class ObfuscationService {
  /**
   * 難読化
   * @param target 平文
   * @returns 難読化文字列
   */
  public static encode(target: string): string {
    if (!target) {
      throw new Error('暗号対象文字列なし');
    }
    const shiftWidth = target.length;

    return window.btoa(
      target
        .split('')
        .map((c) => {
          return String.fromCharCode(this.shiftCharCode(c.charCodeAt(0), shiftWidth, SHIFT_DIRECTION.RIGHT));
        })
        .join('')
    );
  }

  /**
   * 解読
   * @param target 難読化文字列
   * @returns 平文
   */
  public static decode(target: string): string {
    if (!target) {
      throw new Error('復号対象文字列なし');
    }
    const base64decoded = window.atob(target);
    const shiftWidth = base64decoded.length;

    return base64decoded
      .split('')
      .map((c) => {
        return String.fromCharCode(this.shiftCharCode(c.charCodeAt(0), shiftWidth, SHIFT_DIRECTION.LEFT));
      })
      .join('');
  }

  /**
   * 文字コードをシフトする
   *
   * @param targetCharCode シフト対象文字コード
   * @param shiftWidth シフト幅（桁数）
   * @param shiftDirection シフト方向
   * @returns シフト後の文字コード
   */
  private static shiftCharCode(targetCharCode: number, shiftWidth: number, shiftDirection: SHIFT_DIRECTION): number {
    // 指定方向に文字コードをシフトする
    const shiftCode = targetCharCode + shiftWidth * shiftDirection;

    const START_CODE = 0x21;
    const END_CODE = 0x7e;

    if (START_CODE <= shiftCode && shiftCode <= END_CODE) {
      return shiftCode;
    }
    // シフトした結果、画面で文字として入力可能なASCIIコードの範囲（0x21～0x7e）外になるものは
    // 補正を加えて範囲内の値に戻す
    // この補正を加えても文字コード重複が発生することはない為、逆変換により復元可能（=全単射）
    return shiftCode - END_CODE * shiftDirection;
  }
}
