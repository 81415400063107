import store from '@/store';
import { TsunaguOnlineProductHtmlDto, TsunaguOnlineProductHtmlDtoList } from '@/shared/classes/spf-api/tsunagu-online-product-html';
import { Member } from '@/shared/classes/spf-api/member';

/**
 * つなぐオンラインショップのスクレイピング処理をしたスマホで表示する用のHTMLを提供するサービスクラス
 */
export class TsunaguOnlineProductHtmlDisplayOnSpService {
  /**
   * 引数に与えられたURLから、スクレイピング処理をした商品ページを返却する関数
   *
   * @param myUrl スクレイピング処理したい商品ページのURL
   * @returns スクレイピング処理した商品ページ
   */
  public static async fetchHtml(myUrl: string): Promise<TsunaguOnlineProductHtmlDto | undefined> {
    // つなぐオンラインショップ連携済みのとき
    const member: Member | null = store.getters['memberStore/member'];

    if (member && member.primaryKeyTnshop) {
      const dtoList: TsunaguOnlineProductHtmlDtoList | null = store.getters['tsunaguOnlineProductHtmlDisplayOnSpStore/hasShoppingCartButtonHtml'];

      // ストアに既に保存されているとき
      if (dtoList?.existsUrl(myUrl)) {
        return dtoList.getItem(myUrl);
      } else {
        const dtoList: TsunaguOnlineProductHtmlDtoList = await store.dispatch('tsunaguOnlineProductHtmlDisplayOnSpStore/hasShoppingCartButtonHtml', myUrl);
        return dtoList.getItem(myUrl);
      }

      // Portas会員未登録 or 未ログイン or つなぐオンラインショップ未連携 のとき
    } else {
      const dtoList: TsunaguOnlineProductHtmlDtoList | null = store.getters['tsunaguOnlineProductHtmlDisplayOnSpStore/noShoppingCartButtonHtml'];

      // ストアに既に保存されているとき
      if (dtoList?.existsUrl(myUrl)) {
        return dtoList.getItem(myUrl);
      } else {
        const dtoList: TsunaguOnlineProductHtmlDtoList = await store.dispatch('tsunaguOnlineProductHtmlDisplayOnSpStore/noShoppingCartButtonHtml', myUrl);
        return dtoList.getItem(myUrl);
      }
    }
  }
}
