export const MYE_REGISTER_STATUS = {
  IN_CONTRACT_APPLICATION: { id: 1, value: '登録申込中' },
  CONTRACTED: { id: 2, value: '登録済み' },
  IN_WITHDRAWAL_APPLICATION: { id: 3, value: '解約申請中' },
  CANCELLED: { id: 4, value: '解約済み' },
} as const;
export type MYE_REGISTER_STATUS = typeof MYE_REGISTER_STATUS[keyof typeof MYE_REGISTER_STATUS];

export const E_MANSION_ACCOUNT_SUB_DOMAIN = {
  MAIL_SUB_DOMAIN: 'ea',
  HOME_PAGE_SUBDOMAIN: 'zk',
  ACCOUNT_SUB_DOMAIN: 'ec',
} as const;
export type E_MANSION_ACCOUNT_SUB_DOMAIN = typeof E_MANSION_ACCOUNT_SUB_DOMAIN[keyof typeof E_MANSION_ACCOUNT_SUB_DOMAIN];

export const E_MANSION_ACCOUNT_AUTHORITY = {
  MAIN_ACCOUNT: 1,
  ADDITIONAL_ACCOUNT: 2,
} as const;
export type E_MANSION_ACCOUNT_AUTHORITY = typeof E_MANSION_ACCOUNT_AUTHORITY[keyof typeof E_MANSION_ACCOUNT_AUTHORITY];

export const E_MANSION_ORIGINAL_DOMAIN = {
  NOT_ORIGINAL_DOMAIN: '0',
  ORIGINAL_DOMAIN: '1',
} as const;
export type E_MANSION_ORIGINAL_DOMAIN = typeof E_MANSION_ORIGINAL_DOMAIN[keyof typeof E_MANSION_ORIGINAL_DOMAIN];
export const hasEMansionOriginalDomain = (id: string): boolean => {
  return E_MANSION_ORIGINAL_DOMAIN.ORIGINAL_DOMAIN === id ? true : false;
};

export const E_MANSION_CAN_CHANGE_CAPACITY = {
  CAN: '0', // 変更可能
  CAN_NOT: '1', // 変更不可
} as const;
export const canChangeCapacity = (id: string): boolean => {
  return E_MANSION_CAN_CHANGE_CAPACITY.CAN === id ? true : false;
};

export const E_MANSION_SAME_MONTH_CHANGE = {
  NOT: '0', // 同月内変更なし
  SAME_MONTH_CHANGE: '1', // 同月内変更あり
} as const;
export const isSameMonthChange = (id: string): boolean => {
  return E_MANSION_SAME_MONTH_CHANGE.SAME_MONTH_CHANGE === id ? true : false;
};
