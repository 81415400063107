import { subAccountData } from './e-mansion-sub-account-data';

export class EMansionSubAccountInfo {
  readonly _mailPackFee!: number | undefined;
  readonly _countSubAccount!: number | undefined;
  readonly _subAccountData!: subAccountData[] | undefined;
  public get countSubAccount(): number | undefined {
    return this._countSubAccount;
  }
  public get mailPackFee(): number | undefined {
    return this._mailPackFee;
  }
  public get subAccountData(): subAccountData[] | undefined {
    return this._subAccountData;
  }
  constructor(partial: Partial<EMansionSubAccountInfo>) {
    Object.assign(this, partial);
  }
}
