/** Webスペース登録 初期表示 */
export class UcomWebSpaceRegisterInitialDisplayInfo {
  readonly _errorMessage: string; // エラーメッセージ
  readonly _hostingServerName: string; // ホスティングサーバー名

  constructor(errorMessage: string, hostingServerName: string) {
    this._errorMessage = errorMessage;
    this._hostingServerName = hostingServerName;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }
  get hostingServerName(): string {
    return this._hostingServerName;
  }
}
