export interface PostMessageResponseData {
  type: string;
  url: string;
  selectedInfo: {
    color?: string;
    amount: string;
  };
}

export class TsunaguOnlineProductUrlService {
  /**
   * スマホでつなぐオンラインショップのサイトを別タブ表示するために、クエリパラメータで商品IDを送る
   * 商品IDを送るクエリパラメータを組み立てる
   *
   * @param url Portas上で押下された商品のリンク (ex. https://www.tnshop.jp/item/LB1T1B44230613.html)
   * @returns クエリパラメータにできる文字列 (ex. productId=LB1T1B44230613) または 空文字列
   */
  public static buildQueryParametersDisplayOnSp(url: string): string {
    const item = TsunaguOnlineProductUrlService.getProdeuctId(url);

    if (item) {
      return new URLSearchParams({ productId: item }).toString();
    }
    return '';
  }

  /**
   * つなぐオンラインショップのURLから商品IDを返却する
   *
   * @param url Portas上で押下された商品のリンク (ex. https://www.tnshop.jp/item/LB1T1B44230613.html)
   * @returns 商品ID (ex. LB1T1B44230613) または 空文字列
   */
  private static getProdeuctId(url: string): string {
    const REGEXP_ITEM_URL = new RegExp([`(${process.env.VUE_APP_TNOS_BASE_URL}/item/)`, '(.*)', '(.html)'].join(''));
    const buf = REGEXP_ITEM_URL.exec(url);

    if (buf && buf.length > 2) {
      return buf[2];
    }
    return '';
  }

  /**
   * スクレイピング表示しているページ内の「買い物カゴに入れる」が押下された情報から
   * つなぐオンラインショップにSSOするURLを組み立てる
   * 詳しい仕様は、ARN_QA-1151
   *
   * @param data 「買い物カゴに入れる」が押下されたときの情報
   * @returns SSO用のURL ex. ${つなぐのベースURL}/ext/ssologin.html?redirectTo=つなぐ側から指定されたクエリパラメータの組み立て
   */
  public static buildUrlAddToCart(url: string, data: PostMessageResponseData): string {
    const productId = TsunaguOnlineProductUrlService.getProdeuctId(url);

    const params = {
      request: 'insert',
      CART_AMOUNT: data.selectedInfo.amount,
      item_cd: data.selectedInfo.color ? `${productId}-${data.selectedInfo.color}` : productId
    } as { [key: string]: string };

    const beforeEncode = `cart_index.html?${new URLSearchParams(params).toString()}`;
    const encoded = encodeURIComponent(beforeEncode);

    // つなぐ側からの指定でURLエンコードを2回する
    return `${process.env.VUE_APP_TNOS_BASE_URL}/ext/ssologin.html?redirectTo=${encodeURIComponent(encoded)}`;
  }

  public static buildLoginUrlWithSso(): string {
    return `${process.env.VUE_APP_TNOS_BASE_URL}/ext/ssologin.html`;
  }
}
