export class UpdateGamewithCustomerRequest {
  //TODO:物理名はSaleceforceの仕様に合わせる
  // 姓
  readonly _lastName?: string | undefined;

  // 名
  readonly _firstName?: string | undefined;

  // 姓(カナ)
  readonly _lastNameKana?: string | undefined;

  // 名(カナ)
  readonly _firstNameKana?: string | undefined;

  // 郵便番号
  readonly _postalCode?: string | undefined;

  // 都道府県
  readonly _state?: string | undefined;

  // 市区群
  readonly _city?: string | undefined;

  // 町名
  readonly _street?: string | undefined;

  // 丁目・番地・号
  readonly _banchi?: string | undefined;

  // 建物名
  readonly _tatemono?: string | undefined | null;

  // 部屋番号
  readonly _roomNumber?: string | undefined | null;

  // 電話番号
  readonly _phone?: string | undefined;

  // メールアドレス
  readonly _email?: string | undefined;

  // 転用承諾番号
  readonly _switchingNumberNTT?: string | undefined | null;

  // 事業者変更承諾番号
  readonly _switchingNumberCollabo?: string | undefined | null;

  //Portas登録ステータス
  readonly _portasFlag: string | undefined;

  get lastName(): string | undefined {
    return this._lastName;
  }
  get firstName(): string | undefined {
    return this._firstName;
  }
  get lastNameKana(): string | undefined {
    return this._lastNameKana;
  }
  get firstNameKana(): string | undefined {
    return this._firstNameKana;
  }
  get postalCode(): string | undefined {
    return this._postalCode;
  }
  get state(): string | undefined {
    return this._state;
  }
  get city(): string | undefined {
    return this._city;
  }
  get street(): string | undefined {
    return this._street;
  }
  get banchi(): string | undefined {
    return this._banchi;
  }
  get tatemono(): string | undefined | null {
    return this._tatemono;
  }
  get roomNumber(): string | undefined | null {
    return this._roomNumber;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  get email(): string | undefined {
    return this._email;
  }
  get switchingNumberNTT(): string | undefined | null {
    return this._switchingNumberNTT;
  }
  get switchingNumberCollabo(): string | undefined | null {
    return this._switchingNumberCollabo;
  }

  get portasFlag(): string | undefined {
    return this._portasFlag;
  }
  constructor(partial: Partial<UpdateGamewithCustomerRequest>) {
    Object.assign(this, partial);
  }
}
