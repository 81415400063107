import store from '@/store';
import { TSUNAGU_ONLINE_PRODUCT_LIST as PRODUCT_LIST } from '@/shared/const/tnos/tsunagu-online-product-list';
import { TsunaguOnlineProduct } from '../../classes/tnos/tsunagu-online-product';
import { AuthService } from '../auth/auth-service';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';

/**
 * 総合トップ、Portas会員専用トップにて、表示する商品にまつわる処理をする(スクレイピング処理を除く)
 */
export class TsunaguOnlineProductDisplayService {
  /**
   * 総合トップ、Portas会員専用トップにて、表示する商品のリストを返却する
   */
  public static async getDisplayProductList(): Promise<TsunaguOnlineProduct[] | undefined> {
    // 未ログイン時
    if ((await AuthService.isLoggedIn()) === false) {
      return PRODUCT_LIST.filter((value) => value.dispalyAtUnLoggedInOrUnregisteredResidence === true);
    }

    // ログイン済みのとき
    const member: Member | null = store.getters['memberStore/member'];

    // Portas会員未登録 または お住まい未登録のとき
    if (!member || !member.propertyId) {
      return PRODUCT_LIST.filter((value) => value.dispalyAtUnLoggedInOrUnregisteredResidence === true);
    }

    // お住まい登録済みのとき
    const propery: Property = store.getters['propertyStore/property'];
    if (propery.uaType === UA_TYPE.E_MANSION) {
      return PRODUCT_LIST.filter((value) => value.dispalyAtEMansion === true);
    } else if (propery.uaType === UA_TYPE.FIVE_A) {
      return PRODUCT_LIST.filter((value) => value.dispalyAtFiveA === true);
    } else if (propery.uaType === UA_TYPE.UCOM) {
      return PRODUCT_LIST.filter((value) => value.dispalyAtUcom === true);
    }
  }

  /**
   * 1000 を 1,000円 のような形式に変換する
   */
  public static convertPriceForDisplay(product: TsunaguOnlineProduct): string {
    const formatter = new Intl.NumberFormat('ja-JP');
    return `${formatter.format(parseInt(product.price, 10))}円`;
  }
}
