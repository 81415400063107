import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { BatchSituationDto } from '@/shared/classes/spf-api/batch-situation-dto';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import axios from 'axios';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiBatchSituationAccessor {
  /**
   * バッチ状況取得API
   * @return BatchSituationDto
   */
  public static async getBatchSituation(batchId: String): Promise<BatchSituationDto> {
    const response = await api.get<BatchSituationDto>(`/batch-situation/${batchId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return new BatchSituationDto(response.data);
  }
}
