<template>
  <div class="error">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ただいまメンテナンス中です</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- blc -->
      <div class="blc" style="text-align: center">
        <h1 class="cf" style="text-align: center !important; font-size: 24px; font-weight: bold">ただいまメンテナンス中です</h1>
        <br />
        Portas（ポルタス）をご利用いただき、誠にありがとうございます。<br />
        ただいまシステムメンテナンス中のため、お手続きいただくことができません。<br />
        ご不便をおかけいたしますが、何卒ご理解いただきますようお願い申し上げます。<br />
        <br />
        <div v-if="displayDate">
          ■メンテナンス予定時間<br />
          <span style="margin-top: 5px" v-if="isSameDate"> {{ startDate }}{{ startTime }}～{{ endTime }}（24時間表記） </span>
          <span style="margin-top: 5px" v-else-if="!isSameDate"> {{ startDate }}{{ startTime }}～{{ endDate }}{{ endTime }}（24時間表記） </span>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <router-link to="/" custom v-slot="{ navigate }">
            <button class="btn btn01 bs" @click="navigate">トップページ<i class="material-icons link link-icon">east</i></button>
          </router-link>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MaintenanceScreenInfo } from '@/shared/classes/spf-api/maintenance-screen';

type DataType = {
  maintenanceScreenInfo: MaintenanceScreenInfo | undefined;
  startDate: string | undefined;
  startTime: string | undefined;
  endDate: string | undefined;
  endTime: string | undefined;
  isSameDate: boolean;
  displayDate: boolean;
};

/** エラー画面 */
export default defineComponent({
  name: 'connectix-maintenace',
  data(): DataType {
    return {
      maintenanceScreenInfo: undefined,
      startDate: undefined,
      startTime: undefined,
      endDate: undefined,
      endTime: undefined,
      isSameDate: false,
      displayDate: true,
    };
  },
  mounted() {
    const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'];

    // メンテナンス情報取得
    this.maintenanceScreenInfo = this.$store.getters['maintenanceScreenStore/maintenanceScreen'];
    const startAt = this.maintenanceScreenInfo?.startMaintenanceAt;
    const endAt = this.maintenanceScreenInfo?.endMaintenanceAt;

    // displayDate
    // 終了時間がundefinedの場合、メンテナンス予定時間を表示しない
    if (!endAt) {
      this.displayDate = false;
      return;
    }

    // 日本時間等の変換が手間なので文字列から取得
    // 2023-10-23T18:10:00.000Z

    if (startAt && endAt) {
      // Start Date
      const stDate = new Date(`${startAt.substring(0, 4)}/${startAt.substring(5, 7)}/${startAt.substring(8, 10)}`);
      const stDayOfWeek = stDate.getDay(); // 曜日(数値)
      this.startDate = `${startAt.substring(0, 4)}年${startAt.substring(5, 7)}月${startAt.substring(8, 10)}日(${dayOfWeekStr[stDayOfWeek]})`;

      // Start Time
      this.startTime = `${startAt.substring(11, 13)}時${startAt.substring(14, 16)}分`;

      // End Date
      const edDate = new Date(`${endAt.substring(0, 4)}/${endAt.substring(5, 7)}/${endAt.substring(8, 10)}`);
      const edDayOfWeek = edDate.getDay(); // 曜日(数値)
      this.endDate = `${endAt.substring(0, 4)}年${endAt.substring(5, 7)}月${endAt.substring(8, 10)}日(${dayOfWeekStr[edDayOfWeek]})`;

      // End Time
      this.endTime = `${endAt.substring(11, 13)}時${endAt.substring(14, 16)}分`;

      // isSameDate
      if (this.startDate === this.endDate) {
        this.isSameDate = true;
      }
    }
  },
});
</script>
