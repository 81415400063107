<template>
  <div class="completed">
    <LoadingComponent v-if="isOnNextExecuting" />
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み 完了</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>お申し込み完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid3 gap10">
        <li>情報の入力</li>
        <li>入力内容の確認</li>
        <li class="stay">登録完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:「UCOM光 レジデンス」新規登録が完了いたしました。 -->
      <div class="blc">
        <h2 class="portal-h2 cf">
          <img src="../../../images/service-h2.svg" alt="「UCOM光 レジデンス」新規登録が完了いたしました。" />「UCOM光 レジデンス」新規登録が完了いたしました。
        </h2>
        <p>
          お客様情報登録が完了しました。<br />ご入居済みのお客様には、ご入力いただいたメールアドレス宛てに、「お客様番号」、「お客様番号パスワード」を記載したメールをお送りいたします。
        </p>
        <p class="att">
          ※ご登録内容の確認等メール到着には数日間お待たせする場合がございますので予めご了承ください。<br />1週間経ってもメールが届かない場合は、
          <a class="link" :href="getBuildingWebsiteUrl()" target="UCOM">「UCOM光 レジデンス 建物専用サイト」</a
          >をご確認いただき、インフォメーションセンターまでお問い合わせください。
        </p>
        <p>※ご入居前のお客様には、移転予定日前日の19時以降に順次お送りいたします。（メンテナンス等により前後する可能性がございます）</p>
        <p class="red mt15">「お客様番号」、「お客様番号パスワード」は、UCOM光 レジデンスのマイページでオプションサービスのお申し込みや、会員特典の利用時に必要となります。</p>
        <!-- このエリア（登録証発送欄）は、登録証の発送を希望したお客様のみに出す -->
        <div v-if="isSendRegistrationCard" class="sblc">
          <h3 class="service-h3">登録証発送目安</h3>
          <table class="table-type2">
            <tr>
              <th>入居済みのお客様</th>
              <td>一週間前後で送付させていただきます。</td>
            </tr>
            <tr>
              <th>未入居のお客様</th>
              <td>移転予定日前後に発送させていただきます。</td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <div class="btn-area">
            <div v-if="isRemainingWork == '0' || !isUcomLinked">
              <button type="button" class="btn btn01 bs" v-on:click="onNext">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
            </div>
            <div v-else>
              <p class="form-btn-txt">
                お客様のお部屋のインターネットご利用には、宅内工事のお申し込みが必要です。<br />
                「宅内工事のお申込みへ→」をクリックしてください。
              </p>
              <button type="button" class="btn btn01 bs" v-on:click="onSelectConstruction">宅内工事のお申し込みへ<i class="material-icons link link-icon">east</i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* h
---------------------------------------------------- */

/* h2 */
.portal-h2 {
  font-size: 24px;
  display: flex;
}

.portal-h2 img {
  position: relative;
  top: -0.125em;
  width: auto;
  height: 1.2em;
  margin-right: 0.3em;
}

.portal-h2 a {
  font-size: 13px;
  font-weight: normal;
  margin-left: 20px;
}

.portal-h2 p {
  font-size: 16px;
  font-weight: normal;
  margin-left: 20px;
  display: inline-block;
  background-image: none;
}

/* h3 */
.service-h3 {
  font-size: 18px;
  padding-left: 15px;
  border-left: 3px solid #cf1225;
}

/* underlayer */
.underlayer-h2 {
  font-size: 18px;
  border-left: 2px solid #cf1225;
  padding: 0 0 0 15px;
  margin: 0 0 20px;
}

.underlayer-h3 {
  font-size: 16px;
  padding: 0 0 0 20px;
  margin: 0 0 20px;
  position: relative;
}

.underlayer-h3::before {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  left: 0;
  top: 3px;
  border: 4px solid #cf1225;
  border-radius: 50%;
}

.underlayer-h4 {
  margin: 0 0 5px;
  padding: 0;
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main {
  background-image: url('../../../images/ucom/main.png');
}

.underlayer-main h1 {
  font-size: 24px;
  margin: 0;
}

/* service-btn
---------------------------------------------------- */
.service-btn {
  font-size: 0;
}

.service-btn a {
  font-size: 13px;
  border: 1px solid #d11c2e;
  color: #d11c2e;
  text-decoration: none;
  text-align: center;
  border-radius: 3px;
}

.service-btn a:hover {
  opacity: 0.7;
}

.service-btn a:last-child {
  margin-right: 0;
}

.service-btn a.cancel {
  border: 1px solid #424242;
  color: #424242;
}

/* application-flow
---------------------------------------------------- */
.application-flow {
  padding: 30px 0 0;
}

.application-flow li {
  list-style: none;
  color: #939393;
  padding-bottom: 10px;
  border-bottom: 1px solid #939393;
  position: relative;
}

.application-flow li.stay {
  color: #cf1225;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #cf1225;
}

.application-flow li::before {
  position: absolute;
  right: -2px;
  bottom: 5px;
  content: '';
  height: 1px;
  width: 16px;
  background-color: #939393;
  transform: rotate(45deg);
}

.application-flow li.stay::before {
  right: -1px;
  bottom: 4px;
  height: 2px;
  background-color: #cf1225;
}

.application-flow li:last-child::before {
  display: none;
}

.form-btn-txt {
  text-align: center;
  color: #d11c2e;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  /* h
---------------------------------------------------- */

  /* h2 */
  .portal-h2 {
    font-size: 18px;
    display: block;
  }

  .portal-h2 img {
    top: 0.1em;
  }

  .portal-h2 a {
    font-size: 12px;
    margin-left: 15px;
    float: right;
  }

  .portal-h2 p {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0 0;
  }

  /* underlayer-main
---------------------------------------------------- */
  .underlayer-main {
    padding: 40px 20px;
  }

  .underlayer-main h1 {
    font-size: 20px;
  }

  /* service-btn
---------------------------------------------------- */
  .service-btn {
    font-size: 0;
  }

  .service-btn a {
    font-size: 13px;
    border: 1px solid #d11c2e;
    color: #d11c2e;
    text-decoration: none;
    text-align: center;
    border-radius: 3px;
  }

  .service-btn a:hover {
    opacity: 0.7;
  }

  .service-btn a.cancel {
    border: 1px solid #424242;
    color: #424242;
  }

  .form-btn-txt {
    text-align: center;
    color: #d11c2e;
    font-weight: bold;
  }
}
</style>

<script lang="ts">
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ProcessesCalledInUcomPages } from '@/shared/util/processes-called-in-ucom-page';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { UcomEntryInputForm } from './classes/entry-input-form';

/** UCOM新規会員登録 登録完了画面 */
export default defineComponent({
  name: 'completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** 登録証の郵送（希望する/しない） */
    isSendRegistrationCard: false,
    /** 入力画面で取得した物件情報 */
    entryProperty: null as null | UcomPropertyResponse,
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    // onNext()を実行中かどうか
    isOnNextExecuting: false,
    isRemainingWork: '0',
    /**UCOM連携済みかどうか（保留会員の場合false） */
    isUcomLinked: false,
  }),
  /** 画面初期表示時 */
  async mounted(): Promise<void> {
    // 入力画面での入力値を取得
    const entryInput: UcomEntryInputForm | null = this.$store.getters['ucomEntryStore/entryInputForm'];

    // 入力画面で取得した物件情報を持ってくる
    const entryProperty: UcomPropertyResponse = this.$store.getters['ucomEntryStore/externalApiProperty'];
    this.entryProperty = entryProperty;

    if (entryInput != null) {
      // 登録証の郵送（希望する/しない）
      this.isSendRegistrationCard = entryInput.isSendRegistrationCard;
    } else {
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
    }
    this.isRemainingWork = this.$store.getters['remainingWorkStore/remainingWork'];
    // 入力情報を保持しているストアをクリア
    this.$store.commit('ucomEntryStore/entryInputForm', null);
    // 物件情報取得APIの戻り値を保持しているストアをクリア
    this.$store.commit('ucomEntryStore/externalApiProperty', null);
    const member: Member = this.$store.getters['memberStore/member'];
    this.isUcomLinked = member.primaryKeyUcom ? true : false;
  },
  methods: {
    async onNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      const member: Member = this.$store.getters['memberStore/member'];
      // お客様番号未連携であれば、beforeEachで再取得できるようにストアを空にする
      if (!member.primaryKeyUcom) {
        this.$store.commit('memberStore/memberStatus', null);
        this.$store.commit('memberStore/member', null);
      }

      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isOnNextExecuting = false;
        });
    },
    /**宅内工事お申し込みへ遷移 */
    async onSelectConstruction() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;
      await this.$router
        .push('/select-construction-date/input')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isOnNextExecuting = false;
        });
    },
    /**
     * 「UCOM光 レジデンス 建物専用サイト」のURL
     */
    getBuildingWebsiteUrl(): string {
      return ProcessesCalledInUcomPages.getBuildingWebsiteUrl(this.ucomUrl, this.$store.getters['propertyStore/property']);
    },
  },
});
</script>
