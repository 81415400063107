<template>
  <div class="input-email">
    <LoadingComponent v-if="isSubmitting || isMounting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Portas新規会員登録 お申し込み受付</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb" v-if="!isGameWith">
        <li><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>お申し込み受付</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid5 gap10" v-if="!(existsInParameterDecisionTable && (isPropertyUaTypeEmansion || isPropertyUaTypeUcom))">
        <li class="stay">お申し込み受付</li>
        <li>お客様情報入力</li>
        <li>Portas会員限定 お得なサービスのご案内</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc -->
      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages.length > 0" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールアドレスの入力" />メールアドレスの入力</h2>
        <p>
          メールアドレスはご本人様確認のため利用します。<br />
          会員登録方法として選択されたソーシャルアカウントにメールアドレスが登録されている場合、ご登録済みのメールアドレスが表示されます。<br />
          表示されているメールアドレス以外のメールアドレスでの会員登録をご希望の場合は、この画面下の「戻る」を押して、別の会員登録方法をご選択ください。
        </p>

        <table class="table-type2" v-if="hasEmailAddress">
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">メールアドレス（半角英数）</th>
            <td>
              <label>
                {{ form.mailAddress }}
              </label>
            </td>
          </tr>
        </table>

        <table class="table-type2" v-if="!hasEmailAddress">
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">メールアドレス（半角英数）</th>
            <td>
              <input
                type="text"
                class="text middle"
                placeholder="example@yourdomain.com"
                pattern="^[a-z0-9\._%+\-]+@[0-9A-Za-z]+([\-\.][0-9A-Za-z]+)*\.[a-zA-Z]{2,}$"
                size="40"
                v-model="form.mailAddress"
              />
              <p class="att grey mt10">※確認のため、もう一度入力してください</p>
              <input
                type="text"
                class="text middle"
                placeholder="example@yourdomain.com"
                pattern="^[a-z0-9\._%+\-]+@[0-9A-Za-z]+([\-\.][0-9A-Za-z]+)*\.[a-zA-Z]{2,}$"
                size="40"
                v-model="form.confirm"
              />
            </td>
          </tr>
        </table>
        <p>
          ＜ご確認事項＞<br />迷惑メール対策としてドメイン指定受信を設定されている場合、メールが届かない場合があります。<br />次のドメインを受信できるように設定してください。（@portas.jp）
        </p>
      </div>
      <!-- /blc -->

      <!-- blc:ご利用にあたっての同意事項 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ご利用にあたっての同意事項" />ご利用にあたっての同意事項</h2>
        <div class="sblc">
          <h3 class="service-h3">Portas利用規約</h3>
          <div class="terms-box pd15 mb15 border-grey">
            <h4 class="underlayer-h4">（規約の適用）</h4>
            <p>
              第1条　Portas利用規約（以下「本規約」といいます。）は、アルテリア・ネットワークス株式会社（以下「当社」といいます。）が運営、公開するサービスプラットフォーム「Portas」（以下「本サービス」といいます。）の利用に関し適用されるものとします。
            </p>
            <p>2 本サービスに関し、本規約に定める内容と当社が別途定める個別規定の内容が異なる場合は、当該個別規定の内容が適用されるものとします。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（規約の変更）</h4>
            <p>
              第2条　当社は、当社所定の方法にて通知または公表することにより、本規約の全部または一部を変更することができるものとします。この場合、変更後の規約が適用されるものとします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（本サービス）</h4>
            <p>
              第3条　本サービスは、当社または当社指定の事業者が提供するサービス、コンテンツ（以下「対象コンテンツ」といいます。）の利用、閲覧、視聴、申込等を行うことが出来る専用webサイト、アプリケーション等を当社より提供するものであり、本サービスの会員登録（以下「本契約」といいます）をした会員にのみ、本サービスは利用することが出来るものとします。
            </p>
            <p>2　当社は、本サービスの提供に係る全部または一部を当社指定の事業者に委託するものとします。</p>
            <p>3　当社は、対象コンテンツの種類、内容等につき、当社の判断において追加、削除、変更することができるものとします。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（利用者について）</h4>
            <p>
              第4条　前条の定めにかかわらず、本サービスにおいて、本契約を締結せずに利用することができるサービスがある場合、本利用規約にご同意いただく手続に代えて、実際にご利用いただくことで、利用者は本規約に同意したものとみなします。この場合、利用者は本規約の定めを遵守するものとします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（本契約の単位）</h4>
            <p>第5条　当社は、会員一人ごとに1つの本契約を締結します。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（本契約の申し込み）</h4>
            <p>第6条　本契約の申し込みは、本規約を承諾の上、当社所定の方法により行うものとし、これにより本契約は成立するものとします。</p>
            <p>
              2　本契約の申し込み者（以下「会員申込者」といいます。）が未成年の個人である場合には、本契約の申し込みにあたり法定代理人の同意を得ていただく必要があります。この場合、法定代理人は、本規約に定める会員申込者の義務につき、会員申込者と連帯して保証するものとします。
            </p>
            <p>3　本契約の申し込みに際しては、会員申込者の氏名、住所等の情報のほか、本人確認資料を求める場合があります。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（アカウントについて）</h4>
            <p>第7条　当社は、本サービス、対象コンテンツを適切に管理、運用するため、当社指定のアカウントを設定することがあります。</p>
            <p>2 当社は、アカウントを設定した場合、速やかに当社所定の方法で会員に付与します。</p>
            <p>3 会員は、当社が別途定める場合を除き、アカウントを、第三者に使用させ、または、売買、譲渡もしくは貸与等してはならないものとします。</p>
            <p>4 アカウントの管理および使用は会員の責任とし、使用上の過誤または第三者による不正使用等について、当社は一切その責を負わないものとします。</p>
            <p>5 会員のアカウントにより本サービスが利用されたときには、その会員自身の利用とみなされるものとします。</p>
            <p>
              6
              当社は、本サービスに関する当社の業務の遂行もしくは対象コンテンツの運用に著しい支障を及ぼし、または及ぼす恐れのある行為と判断したとき、当該会員に係るアカウントの一部または全部の利用を停止し、変更することがあります。
            </p>
            <p>7 当社は、前項の規定により、アカウントの一部または全部の利用を停止する場合、会員へ何ら催告等を行うことなく行うものとします。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（料金）</h4>
            <p>第8条　本サービスは、無償とします。但し、対象コンテンツにおいては、対象コンテンツにおいて定められた各種料金および支払方法が適用されます。</p>
            <p>
              2　会員は、対象コンテンツに係る料金のお支払いのためにクレジットカードの利用を希望する場合は、本契約の申込その他の場面で、当社所定の方法により、会員が保有するクレジットカードの会員番号、その他当社が指定する事項（以下「クレジットカード情報」といいます。）を登録いただく場合があります。この場合、第11条（本サービスの中止）、第12条（免責）その他本規約に基づき対応が必要になる場合を除き、第14条に基づき本契約の解除がなされるまでの間、クレジットカード情報は本サービス上において登録が維持されます。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（会員情報の変更）</h4>
            <p>
              第9条　会員は、本契約の申込の場面その他サービス上で登録いただいた情報に誤りを見つけた場合、またはその情報の変更を要する場合、速やかに当社指定の方法により修正、変更し、常に登録情報を正確かつ最新の状態にしておくものとします。
              会員が誤った情報を登録したこと、または登録情報の更新を適時に行わなかったことにより会員に不利益が生じた場合でも、当社は一切責任を負いません。
            </p>
            <p>
              2　前項に違反する場合、当社は、当該会員の本サービスの利用を停止、制限することがあります。また、当該違反が解消されないものと当社で判断した場合は、当社は本契約を解除できるものとします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（遵守事項）</h4>
            <p>第10条　会員、利用者（以下「会員等」といいます。）は、本サービスおよび対象コンテンツの利用にあたって次の行為を行わないものとします。</p>
            <p>(1) 法令（法律、政令などをいいます。）その他ガイドライン等の定めに違反する行為。</p>
            <p>(2) 本規約または対象コンテンツに適用される約定に違反する行為。</p>
            <p>(3) 公序良俗に違反し、当社または他人の権利を著しく侵害すると当社が判断した行為。</p>
            <p>(4) 本サービス、対象コンテンツの利用、運営を妨害、阻害すると当社が判断した行為。</p>
            <p>2　当社は、前項に違反する会員等、または違反する虞がある会員等につき、本サービスの利用を停止、制限することがあります。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（本サービスの中止）</h4>
            <p>第11条　当社は、以下のいずれかの事由が生じた場合、何ら会員等に通知することなく、本サービス、対象コンテンツの提供を中止、制限することがあります。</p>
            <p>(1) 当社設備または他社の設備の保守または工事によりやむを得ない場合。</p>
            <p>(2) 当社設備または他社の設備上一時的な使用制限が必要と判断された場合。</p>
            <p>(3) 天災、事変その他の非常事態が発生した場合。</p>
            <p>(4) 第10条（遵守事項）の規定その他本規約の定めに違反したと当社が認めたとき。</p>
            <p>
              2
              当社は、前項に基づく本サービス、対象コンテンツの提供の中止、制限の影響または、本サービス、対象コンテンツの運営、提供を正常化する目的で、当社の判断により、会員等が本サービス、対象コンテンツにおいて登録した情報、データ、記録その他の履歴、ログ等を削除する場合があります。
            </p>
            <p>3 当社は、前2項による措置につき、何ら責任を負わないものとします。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（免責）</h4>
            <p>
              第12条　当社は、本サービスの運営に要するサーバ、通信設備、ソフトウェア等およびこれらによる通信環境、利用環境について、その完全性、正確性、可用性、有用性等を一切保証するものではなく、責任は負いません。
            </p>
            <p>
              2
              当社は、会員等が本サービスを利用することにより得られる情報またはソフトウェア等について、その完全性、正確性、可用性、有用性または適法性を一切保証するものではなく、責任は負いません。
            </p>
            <p>
              3
              当社は、会員等が本サービスを利用するにあたり、接続されるコンピュータ機器、通信機器、端末機器等の動作、機能、設定等については保証しません。また、本サービスの利用に伴う当該端末機器等による通信、または本サービスの利用に伴う当該端末機器等の仕様変更、更新、アップデート等に要した費用は会員等にて負担いただきます。
            </p>
            <p>
              4
              当社は、本サービス及び対象コンテンツが、会員が本契約を行った目的を達成することを保証するものではありません。また、本サービスについて、第三者による権利侵害がないこと、第三者の権利を侵害していないことにつき何ら保証するものではありません。
            </p>
            <p>
              5
              当社は、本サービス上の対象コンテンツ、会員情報その他公開、閲覧できる情報、提供される情報等の一切において、その全部または一部が破損、毀損、滅失した場合においても何ら責任を負わないものとします。
            </p>
            <p>6 当社は、会員等が本サービスを利用することにより他人との間で生じた紛争等に関して、一切責任を負わないものとします。</p>
            <p>7 天災、事変その他の非常事態により会員等に生じた損害につき、当社は一切責任を負わないものとします。</p>
            <p>
              8
              当社は、本規約に定めるほか、当社の責に帰すべき事由により、本サービスの提供において会員等が損害を被った場合、直接かつ通常生ずべき損害の範囲に限り、その損害を賠償するものとし、間接損害、特別損害（予見すべき事情の有無を問わない）、逸失利益、機会損失その他の損害に係る一切の賠償責任を当社は負わないものとします。
            </p>
            <p>
              9
              本条その他本規約の規定において、当社の責に帰すべき事由における当社の損害賠償責任の全部または一部を免責する規定は、当社の故意又は重過失による場合には、適用されません。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（知的財産権）</h4>
            <p>第13条 本サービスに係る知的財産権は、当社または権利者である第三者に帰属するものであり、会員等に一切の権利、使用許諾権その他権利の付与を行うものではありません。</p>
            <p>
              2
              本サービスにおける会員等の書き込み、添付データ等に係る文書、写真、画像、音声その他の情報に係る知的財産権は当該会員等に帰属するものとし、会員等はこれらの知的財産権につき、別途権利者がいる場合、会員等の責任と負担において必要な使用許諾、手続き、処理を行っていただきます。なお、本サービスの運営、管理、更新、編集、利用等において当社が行う一切の行為につき、会員等、権利者は自己の権利を行使（要求、請求、クレーム等を含む）してはならないものとします。
            </p>
            <p>
              3
              会員等に帰属する知的財産権または会員等が管理者より同意得るべき知的財産権について、第三者との間で紛争、クレーム、交渉、協議等が乗じた場合においても、当社はこれらについて一切関知せず、責任を負わないものとします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（解除）</h4>
            <p>
              第14条　会員は、当社指定の方法より、本契約を解約することができます。ただし、会員が対象コンテンツについて、対象コンテンツの提供に係る契約を提供事業者との間で締結し、有効に存続している期間については、本契約は解約することができません。全ての対象コンテンツに係る契約を解約した後、別途当社が定める期間を経過した後に、当社は本項に基づく本契約の解約を受け付けるものとします。
            </p>
            <p>2 当社は、会員が以下のいずれかの事由に該当する場合、何ら催告を要さずに本契約を解除することができます。</p>
            <p>(1) 第10条（遵守事項）に違反した場合。</p>
            <p>(2) 対象コンテンツの料金その他債務の支払を現に怠り、または怠る虞があると当社が判断したとき。</p>
            <p>(3) その他本規約の定めに違反し、当該違反事由につき、解消されないものと当社で判断した場合。</p>
            <p>(4) 対象コンテンツに適用される契約内容に違反したことがあり、またはその虞があり、当社が本サービスの提供が望ましくないものと判断したとき。</p>
            <p>(5) 本契約の申し込みの際に虚偽の事実を通知したことが判明したとき。</p>
            <p>(6) 第11条（本サービスの中止）に基づく本サービスに係る制限、中止が回復しない場合。</p>
            <p>(7) 一定の期間、本サービス、対象コンテンツの利用がないものと当社にて判断した場合。</p>
            <p>
              (8)
              会員が暴力団、暴力団員、暴力団関係者、暴力団関係団体、関係者、その他反社会的勢力、公共の福祉に反する活動を行う団体、およびその行為者である場合、または、反社会的勢力であったと判明した場合。
            </p>
            <p>(9) 経営上、技術上その他の理由で本サービスを提供することが著しく困難になった場合</p>
            <p>
              3
              当社は、本契約が解約、解除その他の理由により終了した場合、会員に係る一切の情報、データ、記録等につき、すべて削除するものとし、会員はこれに一切異議を唱えないものとします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（秘密保持）</h4>
            <p>
              第15条　当社および会員は、本サービスの提供または本契約の締結により知り得た当社または会員の機密情報につき、本規約に特段の定めがある場合を除き、第三者（対象コンテンツの提供事業者を除く）に提供、開示、漏洩等してはならず、目的外の利用をしてはならないものとします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（個人情報の取扱い）</h4>
            <p>
              第16条　当社は、個人情報等（本契約の締結、本サービスの提供に関連して知り得た会員の個人情報であって、氏名、住所、電話番号、ＦＡＸ番号、メールアドレスその他特定の個人を識別することができる情報をいいます。会員が利用するサービスの情報を含みます。）を、本サービスの提供に利用するほか、別途当社が掲げる利用目的に記載の範囲で利用します。
            </p>
            <p>2 当社は個人情報等の扱いにつき、本規約にて特段の定めがあるほか、法令の定めを遵守して取り扱うものとします。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">（準拠法、管轄）</h4>
            <p>
              第17条
              本サービス、本規約に基づく取引は、日本法を準拠法として適用するものとし、本サービス、本規約に係る一切の紛争につき、訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">（附則）</h4>
            <p>1.本規約は2022年8月29日より効力を生じます。</p>
            <p>2.本規約は2023年11月8日より改正します。</p>
          </div>
        </div>
        <div class="sblc">
          <h3 class="service-h3">Portasにおける個人情報の取り扱いについて</h3>
          <div class="terms-box pd15 mb15 border-grey">
            <p>
              アルテリア・ネットワークス株式会社（以下「当社」といいます。）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律(以下「個人情報保護法」といいます)を遵守すると共に、当社が提供するPortas
              (以下「本サービス」といいます)の利用にあたってお客様の個人情報の取扱いについて、以下のとおり定めます。<br />
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">１．個人情報の定義</h4>
            <p>本規定において、「個人情報」とは、個人情報保護法第2条第1項により定義された個人情報を意味するものとします。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">２．利用目的</h4>
            <p>
              利用者が本サービスを利用するにあたり、当社へ登録、開示いただいた個人情報（氏名、住所、電話番号、FAX番号、メールアドレスその他特定の個人を識別することができる情報をいいます。）について、以下の利用目的で利用します。
            </p>
            <p>(1) 本サービスの提供のため</p>
            <p>(2) 本サービスに係る連絡、通知等を行うため</p>
            <p>(3) 障害、保守、サポートの対応その他問い合わせ対応のため</p>
            <p>(4) 当社グループ、提携企業のマーケティング、商品開発に係る調査、分析のため</p>
            <p>(5) メールマガジンの配信のため</p>
            <p>(6) アンケート、モニター募集、キャンペーン等の通知のため</p>
            <p>(7) 当社グループの商品、サービス、並びに提携企業の商品、サービスのご案内、紹介、広告、宣伝、取次のため</p>
            <p>(8) セミナー、展示会、イベントのご案内のため</p>
            <p>(9) 有料サービスの料金請求及び債権管理のため</p>
            <p><br /></p>
            <h4 class="underlayer-h4">3．第三者への提供</h4>
            <p>当社は、利用者が当社へ登録、開示いただいた個人情報について、法令等に基づく場合を除き、本人の同意なく第三者へ提供することはありません。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">4．個人情報の取り扱いの委託</h4>
            <p>
              当社は、本サービスに係る業務その他第2項に定める利用目的を遂行するために、外部業者に個人情報の一部または全部の処理を委託することがあります。この場合、安全管理対策の充実した委託先を選定し、かつ安全管理対策を契約によって委託先に義務付けます。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4">5．Cookieについて</h4>
            <p>
              当社が運営、公開する本サービスのウェブサイト（以下「本ウェブサイト」といいます。）では、クッキー（Cookie）を使用しております。クッキーとは、ウェブサイトにアクセスした際に、当該サイトがコンピュータまたはモバイルデバイスに保存する小さなテキストファイルのことです。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4"><b>クッキーの使用</b></h4>
            <p>
              当社は、本ウェブサイトの利便性および品質維持・向上を目的に、クッキーを使用しております。これらのクッキーは、ブラウザを閉じると無効化されます。また、クッキーにより収集した情報から、個人が識別されることはありません。
            </p>
            <p>
              利用者は、ブラウザの設定により、クッキーの受け取りを拒否しまたは制限することができます。ただし、その場合、本ウェブサイトの機能が一部ご利用になれない場合があります。ブラウザの設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
            </p>
            <p><br /></p>
            <h4 class="underlayer-h4"><b>Googleアナリティクスの利用について</b></h4>
            <p>
              本ウェブサイトでは、利用者の本ウェブサイトの訪問状況を把握するためにGoogle 社のサービスであるGoogle アナリティクスを利用しています。本ウェブサイトでGoogle
              アナリティクスを利用しますと、当社が発行するクッキーをもとにして、Google 社が利用者の本ウェブサイトの訪問履歴を収集、記録、分析します。当社は、Google
              社からその分析結果を受け取り、利用者の本ウェブサイトの訪問状況を把握します。Googleアナリティクスにより収集、記録、分析された利用者の情報には、特定の個人を識別する情報は一切含まれません。また、それらの情報は、Google社により同社のプライバシーポリシーに基づいて管理されます。
            </p>
            <p><br /></p>
            <p>
              利用者は、ブラウザのアドオン設定でGoogle アナリティクスを無効にすることにより、当社のGoogle
              アナリティクス利用による利用者の情報の収集を停止することも可能です。Googleアナリティクスの無効設定は、Google社によるオプトアウトアドオンのダウンロードページで
              「Google アナリティクス
              オプトアウトアドオン」をダウンロードおよびインストールし、ブラウザのアドオン設定を変更することで実施することができます。なお、利用者がGoogleアナリティクスを無効設定した場合、利用者が訪問する本ウェブサイト以外のウェブサイトでもGoogleアナリティクスは無効になりますが、利用者がブラウザのアドオンを再設定することにより、再度Google
              アナリティクスを有効にすることも可能です。
            </p>
            <p><br /></p>
            <p>
              Google アナリティクスの利用規約に関する説明についてはGoogle アナリティクスのサイトを、Google社のプライバシーポリシーに関する説明については同社のサイトをご覧下さい。
            </p>
            <p><br /></p>
            <p>Google アナリティクス利用規約：</p>
            <p><a href="http://www.google.com/analytics/terms/jp.html" class="link" target="_blank">http://www.google.com/analytics/terms/jp.html</a></p>
            <p>Googleプライバシーポリシー：</p>
            <p><a href="http://www.google.com/intl/ja/policies/privacy/" class="link" target="_blank">http://www.google.com/intl/ja/policies/privacy/</a></p>
            <p>Google アナリティクス オプトアウトアドオン：</p>
            <p><a href="https://tools.google.com/dlpage/gaoptout?hl=ja" class="link" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=ja</a></p>
            <p><br /></p>
            <h4 class="underlayer-h4">6. 継続的改善</h4>
            <p>当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本規定を変更することがあります。</p>
            <p><br /></p>
            <h4 class="underlayer-h4">7．個人情報に関するお問合せ対応</h4>
            <p>本規定に関してご不明な点がある場合、本サービスにおける個人情報の取り扱いに関するご質問・苦情・ご相談等があります場合は以下よりご連絡ください。</p>
            <p><br /></p>
            <p>【アルテリア・ネットワークス個人情報お問合せ窓口】</p>
            <p>※お問合せは、メールまたは郵便にての受付になります。</p>
            <p>E-mail：<a href="mailto:privacy-policy@arteria-net.com" class="link">privacy-policy@arteria-net.com</a></p>
            <p>〒105-0004　東京都港区新橋六丁目9番8号　住友不動産新橋ビル</p>
            <p><br /></p>
            <p>2022年6月24日制定</p>
            <p>2024年8月22日改訂</p>
          </div>
        </div>
        <h3>
          <label
            ><input id="agreement" type="checkbox" style="transform: scale(1.3)" v-model="form.termsAndPrivacyPolicy" />
            「Portas利用規約」、「Portasにおける個人情報の取り扱いについて」に同意する</label
          >
          <p class="note-on-personal-information">ご登録いただいた個人情報は、アルテリア・ネットワークス株式会社が取得、管理します。</p>
        </h3>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="form-btn-txt">新規登録方法を変更する場合は「戻る」を押してください。<br /></div>
        <div v-if="(existsInParameterDecisionTable && (isPropertyUaTypeEmansion || isPropertyUaTypeUcom)) || hasEmailAddress">
          <div class="form-btn-txt">問題なければ「規約に同意してPortasの新規会員登録」を押してください。</div>
        </div>
        <div v-else>
          <div class="form-btn-txt">
            問題なければ「規約に同意してメール送信」を押し、Portasから送信されたメールに記載のURLにアクセスしてお客様情報入力フォームへ進んでください。
          </div>
        </div>
        <div class="btn-area">
          <div v-if="(existsInParameterDecisionTable && (isPropertyUaTypeEmansion || isPropertyUaTypeUcom)) || hasEmailAddress">
            <button class="btn-height btn btn05 bs" v-on:click="backBtnToRegist()"><i class="material-icons link link-icon">west</i>戻る</button>
            <button class="btn btn01 bs" :class="getSendButtonClass" :disabled="!isActivatedSendButton" v-on:click="onSendMail()">
              規約に同意してPortasの新規会員登録<i class="material-icons link link-icon">east</i>
            </button>
          </div>
          <div v-else>
            <button class="btn-height btn btn05 bs" v-on:click="backBtnToRegist()"><i class="material-icons link link-icon">west</i>戻る</button>
            <button class="btn btn01 bs" :class="getSendButtonClass" :disabled="!isActivatedSendButton" v-on:click="onSendMail()">
              規約に同意してメール送信<i class="material-icons link link-icon">east</i>
            </button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { UpdateGamewithCustomerRequest } from '@/infra/request/spf/gamewith/update-gamewith-customer-request';
import { GamewithCustomerInfoResponse } from '@/infra/response/spf/gamewith/gamewith-customer-info-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { CONTRACT_STATUS } from '@/shared/const/gamewith/contract-status';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { PROPERTY_SEARCH_TYPE, TRANSITION_SOURCE_SERVICE, TRANSITION_STORAGE, TransitionSourceServiceType, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert8DigitIdTo6digitId } from '@/shared/util/convert';
import { JudgeParameterDecisionProperty } from '@/shared/util/judge-parameter-decision-property';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { emailAddressIllegalChar, emailAddressIllegalCharMark, emailAddressMaxLength } from '@/shared/util/validators';

/** InputEmail コンポーネント */
export default defineComponent({
  name: 'input-email',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
    form: {
      mailAddress: '', // メールアドレス
      confirm: '', //確認用メールアドレス
      termsAndPrivacyPolicy: false, // 利用規約同意チェック
    },
    transitionSourceService: null as TransitionSourceServiceType | null,
    transitionSourceServiceId: null as string | null,
    isDisabled: false, //ボタン非活性
    isSubmitting: false, // 同意してメール送信ボタン押下判定
    isMounting: true, // 画面表示処理中
    /** 物件基本情報が存在するとき */
    existPropertyInfo: false,
    /** パラメータ判定テーブルの物件か */
    existsInParameterDecisionTable: false,
    /** Auth0にメールアドレスが含まれているか */
    hasEmailAddress: false,
    memberProperty: {} as SearchPropertyDetail, //新規登録時に物件情報を格納する
    /** 物件基本情報が存在する かつ e-mansion */
    isPropertyUaTypeEmansion: false,
    /** 物件基本情報が存在する かつ Ucom */
    isPropertyUaTypeUcom: false,
    /**GameWith */
    isGameWith: false,
    gwid: '',
  }),
  setup: () => ({ v$: useVuelidate() }),
  /** コンポーネント生成時の処理 */
  async mounted() {
    let from = '';
    if (!this.$route.query?.from && localStorage.getItem(TRANSITION_STORAGE)) {
      // LINEの場合と判断
      const transitionInfo = JSON.parse(localStorage.getItem(TRANSITION_STORAGE)!);
      if (transitionInfo.gwid) {
        from = 'GameWith';
        this.gwid = transitionInfo.gwid;
        this.isGameWith = true;
      } else {
        this.transitionSourceServiceId = transitionInfo.apid;
        from = transitionInfo.from;
      }
    } else {
      /** 遷移元サービス物件 ID をクエリパラメータから取得 */
      this.transitionSourceServiceId = this.$route.query?.apid as string | null;
      from = String(this.$route.query?.from);
    }

    /**
     * 以下パラメータありで、判定テーブルの物件と一致したの場合のみ有効
     * 上記以外はメールアドレスの有り無しでフロー変更（メール認証ありかなしかが異なる）
     *
     * 遷移元サービス、 遷移元サービス物件ID をクエリパラメータから取得
     * 遷移元サービス: 1: e-mansion, 2: UCOM, 3: Five.A
     *
     * | ISP      | URL                                        | convert8DigitIdTo6digitId | searchPropertyType |
     * | e-manion | input-email?from=emansion&apid=240ef415    | require                   | 3 (E_MANSION)      |
     * | ucom     | input-email?from=ucom-r&apid=31114C11      | require                   | 3 (E_MANSION)      |
     * | ucom     | input-email?from=ucom-r&apid=5130000000001 | x                         | 4 (UCOM)           |
     * | Five.A   | input-email?from=Five.A&apid=AA0146        | x                         | 3 (E_MANSION)      |
     *
     * ただし、リンク直打ちの場合は、次のように入力してauth0新規会員登録をする
     * {VUE_APP_BASE_URL}/login-forwarding?from=Five.A&apid=AA0146&processtype=regist
     * そうすると、次のURLへと遷移する
     * {VUE_APP_BASE_URL}/input-email?from=Five.A&apid=AA0146&processtype=regist
     */
    const FROM_REGEXP = {
      E_MANSION: /^emansion$/,
      UCOM: /^ucom-r$/,
      FIVE_A: /^Five\.A$/,
      GAME_WITH: /^GameWith$/,
    } as const;

    const APID_REGEXP = {
      ID_6_DIGIT: /^[A-Z]{2}[0-9]{4}$/,
      ID_8_DIGIT: /^[0-9a-zA-Z]{8}$/,
      ID_13_DIGIT: /^[0-9]{13}$/,
    } as const;

    if (FROM_REGEXP.FIVE_A.test(from)) {
      if (APID_REGEXP.ID_6_DIGIT.test(this.transitionSourceServiceId ?? '')) {
        // Five.A 物件基本情報取得APIで物件存在有無を確認
        this.transitionSourceService = TRANSITION_SOURCE_SERVICE.FIVE_A;

        const params = { propertyId: this.transitionSourceServiceId, uaType: UA_TYPE.FIVE_A };
        // 物件基本情報を取得
        const fiveAProperty = await this.$store.dispatch('fiveACommonStore/property', params);

        if (fiveAProperty instanceof FiveAProperty) {
          // 物件の存在がある時
          this.existPropertyInfo = true;
          // パラメータ判定テーブルにあるか確認する
          if (this.transitionSourceServiceId) {
            if (await JudgeParameterDecisionProperty.existsOnPortasDB(this.transitionSourceServiceId)) {
              // ある場合
              this.existsInParameterDecisionTable = true;
            } else {
              this.transitionSourceService = null;
              this.transitionSourceServiceId = null;
            }
          } else {
            this.transitionSourceService = null;
            this.transitionSourceServiceId = null;
          }
        } else {
          // ない場合, transitionSourceService/transitionSourceServiceId 削除
          this.transitionSourceService = null;
          this.transitionSourceServiceId = null;
        }
      }
    } else if (FROM_REGEXP.UCOM.test(from)) {
      if (APID_REGEXP.ID_13_DIGIT.test(this.transitionSourceServiceId ?? '')) {
        this.transitionSourceService = TRANSITION_SOURCE_SERVICE.UCOM;

        // UCOM 物件基本情報取得APIで物件存在有無を確認
        const propertyInfo: UcomPropertyResponse | UcomSharedErrorResponse = await this.$store.dispatch('ucomCommonStore/property', this.transitionSourceServiceId);
        if (propertyInfo instanceof UcomPropertyResponse) {
          // 物件の存在がある時
          this.existPropertyInfo = true;
          const propertyId = await this.findPropertyId(PROPERTY_SEARCH_TYPE.UCOM, UA_TYPE.UCOM);
          this.memberProperty.id = propertyId;
          this.isPropertyUaTypeUcom = true;
          // パラメータ判定テーブルにあるか確認する
          if (this.transitionSourceServiceId) {
            if (await JudgeParameterDecisionProperty.existsOnPortasDB(this.transitionSourceServiceId)) {
              // ある場合
              this.existsInParameterDecisionTable = true;
            } else {
              // ない場合, transitionSourceService/transitionSourceServiceId の値削除
              this.transitionSourceService = null;
              this.transitionSourceServiceId = null;
            }
          } else {
            // ない場合, transitionSourceService/transitionSourceServiceId の値削除
            this.transitionSourceService = null;
            this.transitionSourceServiceId = null;
          }
        } else {
          // ない場合, transitionSourceService/transitionSourceServiceId の値削除
          this.transitionSourceService = null;
          this.transitionSourceServiceId = null;
        }
      }
    } else if (FROM_REGEXP.E_MANSION.test(from)) {
      if (APID_REGEXP.ID_8_DIGIT.test(this.transitionSourceServiceId ?? '')) {
        this.transitionSourceService = TRANSITION_SOURCE_SERVICE.E_MANSION;

        // E-MANSION 物件基本情報取得APIで物件存在有無を確認
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const apartmentId = convert8DigitIdTo6digitId(this.transitionSourceServiceId!);
        const propertyParams = { apartmentId: apartmentId, uaType: UA_TYPE.E_MANSION };

        const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', propertyParams);
        if (eMansionProperty instanceof EMansionProperty) {
          // 物件の存在がある時
          this.existPropertyInfo = true;
          this.transitionSourceServiceId = convert8DigitIdTo6digitId(String(this.transitionSourceServiceId));
          const propertyId = await this.findPropertyId(PROPERTY_SEARCH_TYPE.E_MANSION, UA_TYPE.E_MANSION);
          this.memberProperty.id = propertyId;
          this.isPropertyUaTypeEmansion = true;

          if (this.transitionSourceServiceId) {
            // パラメータ判定テーブルにあるか確認する
            if (await JudgeParameterDecisionProperty.existsOnPortasDB(this.transitionSourceServiceId)) {
              // ある場合
              this.existsInParameterDecisionTable = true;
            } else {
              // ない場合, transitionSourceService/transitionSourceServiceId の値削除
              this.transitionSourceService = null;
              this.transitionSourceServiceId = null;
            }
          } else {
            // ない場合, transitionSourceService/transitionSourceServiceId の値削除
            this.transitionSourceService = null;
            this.transitionSourceServiceId = null;
          }
        } else {
          // ない場合, transitionSourceService/transitionSourceServiceId の値削除
          this.transitionSourceService = null;
          this.transitionSourceServiceId = null;
        }
      }
    } else if (FROM_REGEXP.GAME_WITH.test(from)) {
      //GW光のAPIを実行して正常レスポンスが返って来るか確認
      let gwCustomer: GamewithCustomerInfoResponse;
      try {
        gwCustomer = await SpfApiExternalGamewithAccessor.getCustomer(this.gwid);
      } catch (e: any) {
        if (e.response?.status == '500') {
          this.errorMessages.push(
            'アクセスいただいたURLに不備があります。GameWith光インターネットサービスお申し込み後にGameWithより送信されたメールをご確認の上、メールに記載のURLにアクセスしてください。'
          );
        } else if (e.response?.status == '503') {
          this.errorMessages.push(
            'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
          );
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
        }
        this.errorMessageTitle = '';
        this.isDisabled = true;
        window.scrollTo(0, 0);
        this.isMounting = false;
        return;
      }
      if (this.isCancelGamewith(gwCustomer.contractStatus, gwCustomer.portasCloseFlag)) {
        this.errorMessages.push('この「GameWith光顧客番号」は既に解約済みですので、Portasは登録できません。');
        this.errorMessageTitle = '';
        this.isDisabled = true;
        window.scrollTo(0, 0);
        this.isMounting = false;
        return;
      }
      try {
        const memberStatus = await SpfApiService.findMemberStatusByPrimaryKeyGw(this.gwid);
        if (memberStatus && this.isActiveMemberStatus(memberStatus.status)) {
          this.errorMessages.push(
            'この「GameWith光顧客番号」は既にPortas会員登録済みです。恐れ入りますが、下記へお問い合わせください。<br/>GameWith光窓口<br/>gamewith-hikari@gamewith.co.jp<br/>受付時間　10:00-18:00（13:00-14:00は除く）<br/>※土日・祝日・年末年始を除く平日'
          );
          this.errorMessageTitle = '';
          this.isDisabled = true;
          window.scrollTo(0, 0);
          this.isMounting = false;
          return;
        }
      } catch (e: any) {
        checkRouterError(e);
      }
    }
    // auth0にメールアドレスがあるかチェック
    const userEmail: string = await AuthService.getAuth0Email();

    if (userEmail) {
      this.form.mailAddress = userEmail;
      this.form.confirm = userEmail;
      this.hasEmailAddress = true;
    }

    this.isMounting = false;
  },
  validations() {
    return {
      form: {
        mailAddress: {
          required,
          emailAddressMaxLength,
          emailAddressIllegalChar,
          emailAddressIllegalCharMark,
        },
        confirm: {
          sameAsMailAddress: sameAs(this.form.mailAddress),
        },
        termsAndPrivacyPolicy: {
          isChecked: sameAs(true),
        },
      },
    };
  },
  methods: {
    async findPropertyId(searchType: number, uaType: string) {
      // eslint-disable-next-line no-useless-catch
      try {
        const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(searchType, this.transitionSourceServiceId ?? '', false);
        if ('errorMessage' in propertyList) {
          throw new Error();
        }
        this.transitionSourceServiceId = String(this.transitionSourceServiceId);
        return propertyList[0].id;
      } catch (e) {
        throw e;
      }
    },
    async onSendMail(): Promise<void> {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      this.errorMessages = [];
      if (this.v$.form.$invalid) {
        if (this.v$.form.termsAndPrivacyPolicy?.isChecked.$invalid) {
          this.errorMessages.push('「Portas利用規約」「Portasにおける個人情報の取り扱いについて」に同意する」にチェックを入れてください。');
        }
        if (this.v$.form.mailAddress?.required.$invalid) {
          this.errorMessages.push('メールアドレスを入力してください。');
        }
        if (this.v$.form.mailAddress?.emailAddressMaxLength.$invalid) {
          this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
        }
        if (this.v$.form.mailAddress?.emailAddressIllegalChar.$invalid || this.v$.form.mailAddress?.emailAddressIllegalCharMark.$invalid) {
          this.errorMessages.push('メールアドレスの形式が正しくありません。');
        }
        if (this.v$.form.confirm?.sameAsMailAddress.$invalid) {
          this.errorMessages.push('メールアドレスが確認用メールアドレスと一致しません。');
        }
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isSubmitting = false; // ボタン押下解除
        return;
      }
      try {
        // 会員ストアから会員ステータス情報を取得する
        let memberStatus: MemberStatus = await this.$store.dispatch('memberStore/memberStatus');
        //ステータスが0or1以外の場合→共通エラー画面
        if (memberStatus && !(memberStatus?.status === MEMBER_STATUS.ACCOUNT_CREATED || memberStatus?.status === MEMBER_STATUS.MAIL_SENT)) {
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.INPUT_MAIL_MEMBER_STATUS_ERROR);
        }
        // 会員ステータス無しの場合はテーブルへ新規作成
        if (!memberStatus) {
          const newMemberStatus: MemberStatus = new MemberStatus({
            primaryKeyAuth0: await AuthService.getSub(),
            status: MEMBER_STATUS.ACCOUNT_CREATED,
            emailAddress: this.form.mailAddress,
            transitionSourceService: this.transitionSourceService ?? null,
            transitionSourceServiceId: this.transitionSourceServiceId ?? null,
            encryptedPrimaryKeyGw: this.gwid.replace(/\s/g, '+') ?? null,
          });

          // 新規会員ステータス作成
          if (!(await SpfApiService.createMemberStatus(newMemberStatus))) {
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_CREATE_MEMBER_STATUS);
          }

          //会員ステータスのストアの情報を更新
          this.$store.commit('memberStore/memberStatus', newMemberStatus);
          //会員ステータスのストアの情報を再取得・格納
          memberStatus = this.$store.getters['memberStore/memberStatus'];
        } else {
          // 会員ステータス存在するので更新
          memberStatus.emailAddress = this.form.mailAddress;
          // 退会から再度入会のケースを想定
          memberStatus.status = MEMBER_STATUS.ACCOUNT_CREATED;
          memberStatus.transitionSourceService = this.transitionSourceService ?? null;
          memberStatus.transitionSourceServiceId = this.transitionSourceServiceId ?? null;
          await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: await AuthService.getSub(), memberStatus });
        }

        //パラメータ判定テーブルにある物件の場合
        if (this.existsInParameterDecisionTable) {
          // 物件情報が存在する場合かつUCOMとe-mansionの場合は、メアド初期表示関係なく会員新規登録の処理を実行して完了画面に遷移
          if (this.existPropertyInfo) {
            if (memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION || memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM) {
              const sub = await AuthService.getSub();

              const member: Member = new Member({
                emailAddress: memberStatus.emailAddress,
                memberStatus: 0,
                primaryKeyAuth0: sub,
                propertyId: this.memberProperty?.id,
              });

              // 会員登録処理
              const resultMember = await SpfApiService.createMember(member);

              // あり得ないけど念のため確認
              if (!resultMember) {
                throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
              }

              await AuthService.refresh();

              // 正常ケースの場合
              // バックエンドで会員ステータスが更新される
              // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
              this.$store.commit('memberStore/memberStatus', null);

              await this.$router
                .push('/platform/entry/completed')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                  checkRouterError(error);
                })
                .finally(() => {
                  this.isSubmitting = false;
                });
              return;
            } else if (memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.FIVE_A) {
              // Five.Aの場合
              if (this.hasEmailAddress) {
                const sub = await AuthService.getSub();

                const sendMemberStatus: MemberStatus = new MemberStatus({
                  primaryKeyAuth0: memberStatus.primaryKeyAuth0,
                  emailAddress: memberStatus.emailAddress,
                  status: MEMBER_STATUS.MAIL_VALIDATED,
                  token: memberStatus.token,
                  tokenExpireTime: memberStatus.tokenExpireTime,
                  propertyId: memberStatus.propertyId,
                  transitionSourceService: memberStatus.transitionSourceService,
                  transitionSourceServiceId: memberStatus.transitionSourceServiceId,
                  serviceEntry: memberStatus.serviceEntry,
                  serviceLink: memberStatus.serviceLink,
                });

                await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: sub, memberStatus: sendMemberStatus });

                await AuthService.refresh();

                localStorage.removeItem(TRANSITION_STORAGE); // localstrage削除

                // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
                this.$store.commit('memberStore/memberStatus', null);

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                await this.$router.push('/platform/entry/input').catch((error: any) => {
                  checkRouterError(error);
                });
                return;
              }
              //Five.Aでメアド初期表示ない時は、パラメータ判定テーブルにない物件の場合のメアド初期表示ない時の処理へ進む
            }
          }
        }
        //GW光の登録の場合
        if (this.isGameWith) {
          const updateGamewithCustomerRequest = new UpdateGamewithCustomerRequest({ _portasFlag: '登録中' });
          try {
            await SpfApiExternalGamewithAccessor.updateCustomer(updateGamewithCustomerRequest, this.gwid);
          } catch (e: any) {
            if (e.response?.status == '503') {
              this.errorMessages.push(
                'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
              );
              this.errorMessageTitle = '';
              this.isDisabled = true;
              window.scrollTo(0, 0);
              this.isSubmitting = false; // ボタン押下解除
              return;
            } else {
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
            }
          }
        }
        // パラメータ判定テーブルにない物件の場合
        if (this.hasEmailAddress) {
          // メアド初期表示ある時、/platform/entry/inputに遷移
          localStorage.removeItem(TRANSITION_STORAGE); // localstrage削除

          memberStatus.status = MEMBER_STATUS.MAIL_VALIDATED;
          await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: memberStatus.primaryKeyAuth0, memberStatus });

          // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
          this.$store.commit('memberStore/memberStatus', null);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform/entry/input').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        } else {
          // メアド初期表示ない時、/platform/entry/accept-emailに遷移
          const result = await SpfApiService.createToken(this.form.mailAddress);

          localStorage.removeItem(TRANSITION_STORAGE); // localstrage削除

          // SpfApiService.createToken(this.form.mailAddress) に成功するとバックエンドで会員ステータスが更新される
          // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
          this.$store.commit('memberStore/memberStatus', null);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform/entry/accept-email').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
      } catch (error: any) {
        const status: number = error.response?.status;
        /** httpステータスコードが500番台またはタイムアウトなどによってレスポンスを返さない場合は、エラー画面へ遷移させる */
        if (!status || status >= 500) {
          throw error;
        }
        // メアド重複
        if (status === 409) {
          this.errorMessages.push('ご入力いただいたメールアドレスは既に登録されています。');
          window.scrollTo(0, 0);
          this.isSubmitting = false; // ボタン押下解除
          return;
        }
        /** 400番台の場合はエラーメッセージを表示させる */
        throw error;
      }
    },
    /**
     * 新規登録画面（SMS/SNS選択）に遷移する
     */
    async backBtnToRegist() {
      return await AuthService.regist();
    },
    /**
     * 会員ステータスが「２、3、４、５、７」の場合true
     */
    isActiveMemberStatus(status: string): boolean {
      if (status === MEMBER_STATUS.MAIL_VALIDATED) {
        return true;
      }
      if (status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
        return true;
      }
      if (status === MEMBER_STATUS.ACCOUNT_REGISTERED) {
        return true;
      }
      if (status === MEMBER_STATUS.PROPERTY_LINKED) {
        return true;
      }
      if (status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        return true;
      }
      return false;
    },
    /**
     * GW会員の契約状態チェック
     * 「キャンセル」、「廃止」/「解約」＆閉鎖フラグがTRUEの場合true
     */
    isCancelGamewith(status: string, closedFlag: boolean): boolean {
      if (status == CONTRACT_STATUS.APPLICATION_CANCELED) {
        return true;
      }
      if (status == CONTRACT_STATUS.ABOLITION || status == CONTRACT_STATUS.CANCELED) {
        if (closedFlag == true) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    /**
     * メール送信ボタンの活性非活性を判断する
     */
    isActivatedSendButton(): boolean {
      if (!this.form.termsAndPrivacyPolicy) {
        return false;
      }
      if (this.isDisabled) {
        return false;
      }
      return true;
    },
    /**
     * メール送信ボタンの活性非活性に応じてクラスを変更する
     */
    getSendButtonClass(): string {
      return this.isActivatedSendButton ? 'btn btn01 bs' : 'btn btn04 bs';
    },
  },
});
</script>

<style lang="scss" scoped>
.customer-center-info {
  padding-left: 25px;
}

h3 {
  p.note-on-personal-information {
    font-weight: 400;
    font-size: 13px;
    margin-top: 4px;
    padding-left: 22px;
  }
}
</style>
