export class UserScreenTransitionInfo {
  private readonly _toPath!: string;
  private readonly _toScreenName!: string;// 画面名
  private readonly _fromPath!: string;
  private readonly _fromScreenName!: string;// 画面名
  private readonly _transitionDateTime!: Date;

  constructor(userScreenTransitionInfo: { toPath: string, toScreenName: string, fromPath: string, fromScreenName: string }) {
    this._toPath = userScreenTransitionInfo.toPath;
    this._toScreenName = userScreenTransitionInfo.toScreenName;
    this._fromPath = userScreenTransitionInfo.fromPath;
    this._fromScreenName = userScreenTransitionInfo.fromScreenName;
    this._transitionDateTime = new Date();
  }

  get toPath() {
    return this._toPath;
  }

  get toScreenName() {
    return this._toScreenName;
  }

  get fromPath() {
    return this._fromPath;
  }

  get fromScreenName() {
    return this._fromScreenName;
  }

  get transitionDateTime() {
    return this._transitionDateTime;
  }

}