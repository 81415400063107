import { ApiFrontError } from "@/shared/classes/error/api-front-error";
import { DataInconsistencyFrontError } from "@/shared/classes/error/data-inconsistency-front-error";
import { FrontErrorRequest } from "@/shared/classes/spf-api/front-error-request";
import { FRONT_ERROR_TYPE_EXPECTED, FRONT_ERROR_TYPE_GLOBAL_CAPTURE } from "@/shared/const/error/error-handle";
import { SpfApiService } from "@/shared/services/api/spf-api-service";
import { AuthService } from "@/shared/services/auth/auth-service";
import { AxiosErrorFormatService } from '@/shared/services/front-error/axios-error-format-service';
import store from '@/store';
import router from '@/router';
import { datadogLogs } from '@datadog/browser-logs';

/**
 * vueで発生したエラーをバックエンドへ送信するクラス
 */
export class ReportVueErrorHandler {
  /**
   * vueで発生したエラーをバックエンドへ送信する
   *
   * @param error エラーオブジェクト
   * @param vm vueオブジェクト。画面名取得に使用する
   * @param info 提供されるエラー情報文字列がある場合は使用する
   */
  public static async reportErrorToVueErrorHandler(error: Error, info?: string): Promise<void> {
    const route = router.currentRoute;

    const errorScreenName = route.value.meta.title as string;
    const errorScreenPath = route.value.path;

    const request = await this.createVueFrontErrorRequest(error, errorScreenName, errorScreenPath, info);
    try {
      datadogLogs.logger.error('@VueErrorHandler.handle', request);
      await SpfApiService.postReportFrontError(request);
    } catch (error) {
      // 握りつぶす
    }
  }

  /**
   * バックエンドへエラー情報を送信する際のリクエスト情報を作成する
   * 
   * @param error エラーオブジェクト
   * @param vm vueオブジェクト。画面名取得に使用する
   * @param errorScreenName エラーが発生した画面名
   * @param errorScreenPath エラーが発生した画面のパス
   * @param info 提供されるエラー情報文字列がある場合は使用する
   */
  private static async createVueFrontErrorRequest(error: Error, errorScreenName: string, errorScreenPath: string, info?: string): Promise<FrontErrorRequest> {
    const primaryKeyAuth0 = (await AuthService.isLoggedIn())
      ? await AuthService.getSub().catch(() => {
          // エラー: subがありません。 を握り潰す ARN_QA-825 の対応
          return '';
        })
      : undefined;

    const errorType = this.getVueErrorType(error);
    const formattedAxiosError = AxiosErrorFormatService.format(error);

    const request = new FrontErrorRequest({
      primaryKeyAuth0: primaryKeyAuth0,
      errorObject: formattedAxiosError ? formattedAxiosError : JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))), // エラーオブジェクト出力のための措置
      errorInfo: info,
      errorScreenName: errorScreenName,
      errorScreenPath: errorScreenPath,
      errorType: errorType,
      screenHistory: store.getters['userScreenTransitionStore/history'],
      userAgent: navigator.userAgent,
    });

    return request;
  }

  /**
   * エラーオブジェクトからエラーの種類を特定する
   *
   * @param error エラーオブジェクト
   */
  private static getVueErrorType(error: Error): string {
    if (error instanceof DataInconsistencyFrontError) {
      return FRONT_ERROR_TYPE_EXPECTED.DATA_INCONSISTENCY;
    }

    if (error instanceof ApiFrontError) {
      return FRONT_ERROR_TYPE_EXPECTED.API;
    }

    return FRONT_ERROR_TYPE_GLOBAL_CAPTURE.VUE;
  }

}