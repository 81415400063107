import axios from 'axios';
import { AuthService } from '../auth/auth-service';
import qs from 'qs';
import { RevisionService } from '../revision/revision-service';
import { REVISION_UP_ERROR } from '@/shared/const/error/error-info';
import { configureAxiosRetry } from '../../libraries/axios-retry/configure-axios-retry';

// anyにしないと怒られるので許容
let store: any;
export const injectStore = (_store: any) => {
  store = _store
}

const SERVICE_TYPE = {
  UCOM: 0,
  E_MANSION: 1,
  MCLOUD: 2
} as const;

type ServiceType = typeof SERVICE_TYPE[keyof typeof SERVICE_TYPE];

export class BaseExternalApiService {

  public static api(serviceType: ServiceType) {
    const isLocal = process.env['VUE_APP_IS_LOCAL'];
    if (!isLocal) {
      if (serviceType === SERVICE_TYPE.E_MANSION) {
        const axiosClient = axios.create({ baseURL: process.env['VUE_APP_EXTERNAL_API_ENDPOINT_E_MANSION_URL'] });
        axiosClient.interceptors.request.use(async (config) => {

          // リビジョンアップの場合はエラーを投げる
          if (await RevisionService.isRevisionUp()) {
            throw new axios.Cancel(REVISION_UP_ERROR.CANCEL);
          }

          const isLoggedIn = await AuthService.isLoggedIn();
          if (!isLoggedIn) {
            window.location = process.env.VUE_APP_BASE_URL;
            return config;
          }

          const token = await this.getBearerToken(serviceType);
          config.headers.Authorization = token;
          return config;
        });

        configureAxiosRetry(axiosClient);
        return axiosClient;
      }

      if (serviceType === SERVICE_TYPE.MCLOUD) {
        const axiosClient = axios.create({ baseURL: process.env['VUE_APP_EXTERNAL_API_ENDPOINT_MCLOUD_URL'] });
        axiosClient.interceptors.request.use(async (config) => {

          // リビジョンアップの場合はエラーを投げる
          if (await RevisionService.isRevisionUp()) {
            throw new axios.Cancel(REVISION_UP_ERROR.CANCEL);
          }

          const isLoggedIn = await AuthService.isLoggedIn();
          if (!isLoggedIn) {
            window.location = process.env.VUE_APP_BASE_URL;
            return config;
          }

          const token = await this.getBearerToken(serviceType);
          config.headers.Authorization = token;
          // クエリパラメータのパーサ
          config.paramsSerializer = {
            serialize: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
          }

          return config;
        });

        axiosClient.interceptors.response.use(async (value) => {
          if (value.status === 200 && (!Object.keys(value).length || !Object.keys(value.data).length)) {
            throw Error('Nothing Result');
          }
          return value;
        });

        configureAxiosRetry(axiosClient);
        return axiosClient;
      }
      /**ここには到達しない */
      return axios.create();
    } else {
      /**開発環境用 */
      return this.createAxiosForLocal(serviceType);
    }
  }

  private static async getBearerToken(serviceType: ServiceType) {
    if (serviceType === SERVICE_TYPE.E_MANSION) {
      return this.getEMansionBearer();
    }
    if (serviceType === SERVICE_TYPE.MCLOUD) {
      return await this.getMCloudBearer();
    }
  }

  private static async getMCloudBearer() {
    const token = await AuthService.getAuth0AccessToken();
    return `Bearer ${token}`;
  }

  private static getEMansionBearer() {
    const token: string = process.env['VUE_APP_EXTERNAL_API_E_MANSION_BEARER'] ?? '';
    return `Bearer ${token}`;
  }

  private static createAxiosForLocal(serviceType: ServiceType) {
    if (serviceType === SERVICE_TYPE.E_MANSION) {
      return axios.create({ baseURL: process.env['VUE_APP_EXTERNAL_API_ENDPOINT_E_MANSION_URL'] });
    }
    if (serviceType === SERVICE_TYPE.MCLOUD) {
      const axiosClient = axios.create({ baseURL: process.env['VUE_APP_EXTERNAL_API_ENDPOINT_MCLOUD_URL'] });
      axiosClient.interceptors.request.use(async (config) => {
        const token = await this.getBearerToken(serviceType);
        config.headers.Authorization = token;
        // クエリパラメータのパーサ
        config.paramsSerializer = {
          serialize: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
        }
        return config;
      });
      return axiosClient;
    }
    /**ここには到達しない */
    return axios.create();
  }
}
