<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>解約</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ご解約確認" />ご解約確認</h2>
        <p class="red"><b>ホームページを解約します。</b></p>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="blc">
          <div class="sblc">
            <div class="sblc">
              <table class="table-type2">
                <tr>
                  <th class="va-middle">
                    <h3 class="service-h3">ご解約に関する注意事項</h3>
                    <ul class="ul-basic01">
                      <li>1日～20日までに解約のお手続きをされた場合、当月末日をもってホームページがご利用いただけなくなります。</li>
                      <li>21日～月末までに解約のお手続きをされた場合、翌月末日をもってホームページがご利用いただけなくなります。</li>
                      <li>アップロードされているファイルは全て削除されます。</li>
                      <li>
                        ホームページ容量を追加でご利用いただいている場合は、ホームページサービス終了と同時にホームページ容量も削除されます。ホームページ容量追加分利用料は、1日～20日までにホームページの解約お手続きをされた場合は当月末まで、21日～月末までにホームページの解約お手続きをされた場合は翌月末までお支払いただきます。
                      </li>
                    </ul>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt" style="text-align: center">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt" style="text-align: center">ご確認の上、よろしければ「解約」をクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goNext()">解約<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { EMansionHomepageCancelUpdateRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-update-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'homepage-cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    /** e-mansion ホームページID */
    eMansionHomepageId: undefined,
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      this.isLoading = true;

      this.eMansionHomepageId = await this.$store.getters['eMansionHomepageOpenStore/eMansionHomepageId'];

      // storeにデータが無ければ、利用状況/解約画面に遷移する
      if (!this.eMansionHomepageId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage/usage/').catch((error: any) => {
          checkRouterError(error);
        });
      }
      this.isLoading = false;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goBack() {
      if (this.isLoading) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage/usage').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = true;
    },
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
        const eMansionHomepageId = this.$store.getters['eMansionHomepageOpenStore/eMansionHomepageId']; // e-mansion ホームページID
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow(); // 現在日付

        // AE05-005 ホームページ：ご解約確認_更新APIの呼び出し
        const response = await SpfApiHomepageAccessor.callHomepageCancelUpdate(
          new EMansionHomepageCancelUpdateRequest({
            _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
            _eMansionHomepageId: eMansionHomepageId,
            _processingDate: now,
          })
        );

        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }

        // サービス終了日を設定
        this.$store.commit('eMansionHomepageOpenStore/serviceEndDate', response.serviceEndDate);

        // ホームページ：ご解約完了画面に遷移する
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage/cancel-complete').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response?.status !== 503) {
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
