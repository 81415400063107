export const UCOM_PROPERTY_SERVICE_PROVID_FORMER = {
    TNC: { id: '1', value: '株式会社つなぐネットコミュニケーションズ' },
    ARTERIA: { id: '2', value: 'アルテリア・ネットワークス株式会社' }
  } as const;
  export type UCOM_MAIL_TEPLATE_CORP_NAME = typeof UCOM_PROPERTY_SERVICE_PROVID_FORMER[keyof typeof UCOM_PROPERTY_SERVICE_PROVID_FORMER];
  
  export const convertCorpNameById = (id: number): string | undefined => {
    for (const v of Object.values(UCOM_PROPERTY_SERVICE_PROVID_FORMER)) {
      if (Number(v.id) == id) {
        return v.value;
      }
    }
  };
  