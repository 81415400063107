export const UCOM_VIRUS_CHECK_PAUSE_SETTING = {
  PAUSED: 1, // 一時停止
  UNPAUSED: 2, // 実施
  APPLY_PAUSE:3,//一時停止申込中
  APPLY_UNPAUSE:4//実施申込中
} as const;

export type UcomVirusCheckPauseSetting = typeof UCOM_VIRUS_CHECK_PAUSE_SETTING[keyof typeof UCOM_VIRUS_CHECK_PAUSE_SETTING];

// 表示用 value変換用
const _UCOM_VIRUS_CHECK_PAUSE_SETTING = {
  PAUSED: { id: UCOM_VIRUS_CHECK_PAUSE_SETTING.PAUSED, value: '一時停止' },
  UNPAUSED: { id: UCOM_VIRUS_CHECK_PAUSE_SETTING.UNPAUSED, value: '実施' },
};

// idから valueに変換
export const convertUcomVirusCheckPauseSettingById = (id: number): string | undefined => {
  for (const v of Object.values(_UCOM_VIRUS_CHECK_PAUSE_SETTING)) {
    if (v.id === id) {
      return v.value;
    }
  }
};
