import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { GameWithInvoiceResponseDto } from '@/shared/classes/spf-api/gw-invoice-history-list-dto';
import { BatchSituationDto } from '@/shared/classes/spf-api/batch-situation-dto';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiInternalGamewithAccessor {
  /**
   * GW請求明細取得API
   * @return GameWithInvoiceResponseDto
   */
  public static async getGWInvoice(gwid: string, targetMonth: number): Promise<GameWithInvoiceResponseDto> {
    const response = await api.get<GameWithInvoiceResponseDto>(`/gamewith/billing-history/${gwid}?invoiceMonth=${targetMonth}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return response.data;
  }
}
