<template>
  <div class="error">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>エラーが発生しました</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- blc -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../images/service-h2.svg" alt="申し訳ございません。エラーが発生しました。" />予期しないエラーが発生しました。</h2>
        <p>ご迷惑をおかけし申し訳ございません。<br />大変お手数をおかけしますが、トップページから再度お手続きをしていただきますようお願いいたします。</p>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <router-link to="/platform" custom v-slot="{ navigate }" v-if="isLoggedIn">
            <button class="btn btn01 bs" @click="navigate">トップページ<i class="material-icons link link-icon">east</i></button>
          </router-link>
          <router-link to="/" custom v-slot="{ navigate }" v-if="!isLoggedIn">
            <button class="btn btn01 bs" @click="navigate">トップページ<i class="material-icons link link-icon">east</i></button>
          </router-link>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { AuthService } from '@/shared/services/auth/auth-service';
import { defineComponent } from 'vue';

/** エラー画面 */
export default defineComponent({
  name: 'error',
  data: () => ({
    isLoggedIn: false,
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
  },
});
</script>
