/** アカウントID管理：登録・変更_入力_初期表示用のアカウント情報 */
export class UcomAccountManagementInputInitInfo {
  readonly _accountName!: string;// アカウント名
  readonly _password!: string;// UCOM_アカウントパスワード
  readonly _accountAuthorization!: string;// UCOM_アカウント権限
  readonly _familyName!: string;// 姓
  readonly _firstName!: string;// 名
  readonly _familyNameKana!: string;// 姓_フリガナ
  readonly _firstNameKana!: string;// 名_フリガナ
  readonly _userName!: string;// ユーザーネーム

  constructor(
    accountName:string,
    password:string,
    accountAuthorization:string,
    familyName:string,
    firstName:string,
    familyNameKana:string,
    firstNameKana:string,
    userName:string,
  ) {
    this._accountName = accountName;
    this._password = password;
    this._accountAuthorization = accountAuthorization;
    this._familyName = familyName;
    this._firstName = firstName;
    this._familyNameKana = familyNameKana;
    this._firstNameKana = firstNameKana;
    this._userName = userName;
  }

  get accountName(): string {
    return this._accountName;
  }

  get password(): string {
    return this._password;
  }

  get accountAuthorization(): string {
    return this._accountAuthorization;
  }

  get familyName(): string {
    return this._familyName;
  }

  get firstName(): string {
    return this._firstName;
  }

  get familyNameKana(): string {
    return this._familyNameKana;
  }

  get firstNameKana(): string {
    return this._firstNameKana;
  }

  get userName(): string {
    return this._userName;
  }
}
