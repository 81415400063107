import { UcomVirusCheckAction } from '@/shared/const/ucom/ucom-virus-check-action';

/** UCOMウイルスチェック情報更新 リクエスト dto */
export class UpdateUcomVirusCheckRequest {
  private readonly _portasId!: number;
  private readonly _ucomAccountId!: number;
  private readonly _subscriptionDate!: string;
  private readonly _ucomVirusCheckAction!: UcomVirusCheckAction;

  constructor(portasId: number, ucomAccountId: number, subscriptionDate: string, ucomVirusCheckAction: UcomVirusCheckAction) {
    this._portasId = portasId;
    this._ucomAccountId = ucomAccountId;
    this._subscriptionDate = subscriptionDate;
    this._ucomVirusCheckAction = ucomVirusCheckAction;
  }

  public get portasId(): number {
    return this._portasId;
  }
  public get ucomAccountId(): number {
    return this._ucomAccountId;
  }
  public get subscriptionDate(): string {
    return this._subscriptionDate;
  }
  public get ucomVirusCheckAction(): UcomVirusCheckAction {
    return this._ucomVirusCheckAction;
  }
}
