import { MEMBER_STATUS } from '../const/member-status';
import { ISP_MEMBER_STATUS } from '../const/service-type';
import { MemberStatus } from '../classes/spf-api/member-status';

/**
 * /platform で使われる
 * swiperコンポーネントに渡すconnectix画像表示判定フラグ(e-mansion物件のとき実行する)
 * eMansionPropertyは、e-mansion 物件基本情報取得API
 *
 * | isDisplayConnectix | isp-member-status-ucom | portas-member-status | eMansionProperty.op.sdwan.flag |
 * | false              | cancelAndLoginNG       | 3                    | all                            |
 * | false              | cancelAndLoginNG       | 7                    | all                            |
 * | false              | cancelAndLoginOK       | 5                    | all                            |
 * | false              | cancelAndLoginOK       | 7                    | all                            |
 * | false              | transferAndCancel      | 5                    | all                            |
 * | false              | transferAndCancel      | 7                    | all                            |
 * | false              | inCancelApplication    | 5                    | all                            |
 * | false              | inCancelApplication    | 7                    | all                            |
 * | true               | others                 | others               | '1'                            |
 */
export function isDisplayConnectixInPortalCaseEMansion(
  memberStatus: MemberStatus,
  ispMemberStatus: string,
  isProvidedConnectix: boolean) {

  if (ispMemberStatus === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_NG) {
    if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      return false;
    }
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      return false;
    }
  }

  const isExpectedIspMemberStatus = [
    ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION,
    ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL,
    ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
  ].some((value) => value === ispMemberStatus);

  const isExpectedPortasMemberStatus = [
    MEMBER_STATUS.PROPERTY_LINKED,
    MEMBER_STATUS.IN_CANCEL_APPLICATION
  ].some((value) => value === memberStatus.status);

  if (isExpectedIspMemberStatus && isExpectedPortasMemberStatus) {
    return false;
  }

  if (isProvidedConnectix) {
    return true;
  }
  return false;
}

/**
 * swiperコンポーネントに渡すconnectix画像表示判定フラグ(ucom物件のとき実行する)
 *
 * | isDisplayConnectix | isp-member-status-ucom | portas-member-status | ucomProperty.connectix_start_date |
 * | false              | cancelAndLoginNG       | 3                    | true or false                     |
 * | false              | cancelAndLoginNG       | 7                    | true or false                     |
 * | false              | cancelAndLoginOK       | 5                    | true or false                     |
 * | false              | cancelAndLoginOK       | 7                    | true or false                     |
 * | true               | all                    | all                  | true                              |
 */
export function isDisplayConnectixInPortalCaseUcom(
  memberStatus: MemberStatus,
  ispMemberStatus: string,
  isProvidedConnectix: boolean) {

  if (ispMemberStatus === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_NG) {
    if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      return false;
    }
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      return false;
    }
  }

  const isExpectedPortasMemberStatus = [
    MEMBER_STATUS.PROPERTY_LINKED,
    MEMBER_STATUS.IN_CANCEL_APPLICATION
  ].some((value) => value === memberStatus.status)

  if (isExpectedPortasMemberStatus
      && ispMemberStatus === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
    return false;
  }

  if (isProvidedConnectix) {
    return true;
  }
  return false;
}

/**
 * swiperコンポーネントに渡すconnectix画像表示判定フラグ(Five.A物件のとき実行する)
 *
 * | isDisplayConnectix | isp-member-status   | portas-member-status |
 * | false              | cancelAndLoginNG    | 3                    |
 * | false              | inCancelApplication | 5                    |
 * | false              | inCancelApplication | 7                    |
 * | false              | cancelAndLoginOK    | 5                    |
 * | false              | cancelAndLoginOK    | 7                    |
 */
export function isDisplayConnectixInPortalCaseFiveA(
  memberStatus: MemberStatus,
  ispMemberStatus: string,
  isProvidedConnectix: boolean) {

  if (ispMemberStatus === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_NG) {
    if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      return false;
    }
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      return false;
    }
  }

  const isExpectedIspMemberStatus = [
    ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION,
    ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK
  ].some((value) => value === ispMemberStatus);

  const isExpectedPortasMemberStatus = [
    MEMBER_STATUS.PROPERTY_LINKED,
    MEMBER_STATUS.IN_CANCEL_APPLICATION
  ].some((value) => value === memberStatus.status);

  if (isExpectedIspMemberStatus && isExpectedPortasMemberStatus) {
    return false;
  }

  if (!isProvidedConnectix) {
    return false;
  }

  return true;
}
