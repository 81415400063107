import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { UcomAccountInfo, UcomAccountInfoList } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-info-list';
import { UcomAccountOptionDomain } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-option-domain';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiAccountOptionChangeAccessor {
  /**
   * アカウントID情報一覧取得
   * @param id 会員ID
   * @param ucomId UCOMアカウントID
   * @return アカウントID情報一覧
   */
  public static async getAccountInfoList(id: number, ucomId: number): Promise<UcomAccountInfoList> {
    const response = await api.get<UcomAccountInfoList>(`/account/UCOM/account-option/list/${id}?ucomId=${ucomId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return await this.convertAccountInfoList(response.data);
  }

  /**
   * メールアドレス登録_初期表示API
   * @param ucomAccountId UCOM_アカウントID
   * @returns ドメイン名
   */
  public static async getUcomDomain(ucomAccountId: number): Promise<UcomAccountOptionDomain> {
    const response = await api.get<UcomAccountOptionDomain>(`/account/UCOM/account-option/domain/${ucomAccountId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * メールアドレス登録_登録API
   * @param ucomAccountId ucom_アカウントID
   * @param mailAddressType ご利用メール種別
   * @param mailAddress メールアドレス
   * @returns エラーの場合のみエラーメッセージを返却する
   */
  public static async registerMailAddress(
    memberId: number,
    ucomAccountId: number,
    mailAddressType: number,
    mailAddress: string,
    subscriptionOn: string
  ): Promise<string | undefined> {
    const params = { memberId: memberId, ucomAccountId: ucomAccountId, mailAddressType: mailAddressType, mailAddress: mailAddress, subscriptionOn: subscriptionOn };
    const response = await api.post<string | undefined>('/common/UCOM/account-option/mailaddress', params).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * メールアドレス登録_更新API
   * @param ucomAccountId ucom_アカウントID
   * @param mailAddressType ご利用メール種別
   * @param mailAddress メールアドレス
   * @param processOn システム日付
   * @returns エラーの場合のみエラーメッセージを返却する
   */
  public static async updateMailAddress(ucomAccountId: number, mailAddressType: number, mailAddress: string, processOn: string): Promise<string | undefined> {
    const params = { ucomAccountId: ucomAccountId, mailAddressType: mailAddressType, mailAddress: mailAddress, processOn: processOn };
    const response = await api.patch<string | undefined>('/common/UCOM/account-option/mailaddress', params).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return response.data;
  }

  private static async convertAccountInfoList(response: UcomAccountInfoList | undefined): Promise<UcomAccountInfoList> {
    if (!response) {
      throw new Error('アカウントID情報一覧無し');
    }

    const ucomAccountInfoList: UcomAccountInfo[] = response.accountInfoList.map((info) => {
      return new UcomAccountInfo(
        info.accountName,
        info.familyName,
        info.firstName,
        info.familyNameKana,
        info.firstNameKana,
        info.userName,
        info.password.match(/[^*]/) ? ObfuscationService.decode(info.password) : info.password,
        info.accountAuthorization,
        info.mailAddress,
        info.isOldDomain,
        info.popServer,
        info.smtpServer,
        info.imapServer,
        info.mailboxDefaultCapacity,
        info.mailboxAddCapacity,
        info.mailSumCappacity,
        info.virusCheckId,
        info.mailAddressType,
        info.ucomAccountMailId,
        info.ucomWebSpaceId,
        info.homepageAddress,
        info.ftpServer,
        info.webSpaceDefaultCapacity,
        info.webspaceAddCapacity,
        info.webSpaceSumCapacity,
        info.ucomAccountId,
        info.mailForwardStatus,
        info.mailReplyStatus
      );
    });
    return new UcomAccountInfoList(ucomAccountInfoList);
  }
}
