export const UCOM_PAYMENT_METHOD_ID = {
  /** クレジットカード */
  CREDIT_CARD: '1',
  /** 口座振替 */
  ACCOUNT_TRANSFER: '2',
  /** 口座振込 */
  ACCOUNT_WIRE_TRANSFER: '3',
  /** 郵便局 */
  POST_OFFICE: '4',
  /** 未登録 */
  NOT_REGISTERED: '5',
} as const;
export type UCOM_PAYMENT_METHOD_ID = typeof UCOM_PAYMENT_METHOD_ID[keyof typeof UCOM_PAYMENT_METHOD_ID];

export const convertUcomPaymentMethodId = (value: string): UCOM_PAYMENT_METHOD_ID | undefined => {
  for (const v of Object.values(UCOM_PAYMENT_METHOD_ID)) {
    if (String(v) == value) {
      return v;
    }
  }
};
