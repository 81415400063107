<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>メールボックス容量変更 入力</h1>
    </main>
    <!-- /underlayer-main -->

    <div v-if="isInput" class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>メールボックス容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールボックス容量変更" />メールボックス容量変更</h2>
        <p>追加／縮小する容量を選択し、「次へ進む」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <div class="blc">
        <error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th>アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th>現在の容量</th>
              <td>{{ mailBoxCapacity }}.0GB</td>
            </tr>
            <tr>
              <th>追加／縮小容量<span class="req">必須</span></th>
              <td>
                <select v-model="changeCapacity" class="form-select">
                  <option disabled value="">選択してください</option>
                  <option :value="changeCapacityValue">{{ changeCapacityLabel }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>月額利用料</th>
              <td>9.0GB 毎に月額{{ price }}円（税込）<br />最大10.0GBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th>サービス説明</th>
              <td>
                <span v-html="serviceDescription"></span><br />UCOM 光 レジデンス ISP 会員付加サービス利用規約については<a :href="termsUrl" target="_blank" class="link">こちら</a
                >からご確認ください。
              </td>
            </tr>
            <tr>
              <th>サービス説明への同意<span class="req">必須</span></th>
              <td>
                お申し込み前にサービス説明を必ずお読みになり、「上記の内容に同意する」にチェックしてください。<br />
                <label
                  ><input type="checkbox" v-model="isAgreeTerms" />
                  <b>上記の内容に同意する</b>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" type="button" v-on:click="onNext()" :class="buttonColorSet" :disabled="!isAgreeTerms">
            次へ進む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->

    <div v-else class="contents">
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お支払い方法未登録" />お支払い方法未登録</h2>
        <p>
          お支払方法が未登録のため、サービスの購入ができません。<br />
          <u>
            <a :href="paymentUrl" class="payment-url" target="_blank" rel="noopener noreferrer"> こちら </a>
          </u>
          からお支払方法の登録を行ってください。
        </p>
        <p>
          お支払い方法登録後、ブラウザの戻るボタンでUCOM光レジデンス会員専用トップに戻り<br />
          再度購入ご希望のサービスをご選択ください。
        </p>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMailboxCapacityAccessor } from '@/infra/accessor/spf/ucom/spf-api-mailbox-capacity-accessor';
import { FindUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/find-ucom-mb-capacity-request';
import { UCOM_REGISTERED_PAYMENT } from '@/shared/const/ucom/ucom-registered-payment';
import { processDate } from '@/shared/util/func-process-date';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UCOM_PROPERTY_SERVICE_PROVID_FORMER } from '@/shared/const/ucom/ucom-property-service-provid-former';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'mailbox-capacity-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** UCOM_アカウントメールID */
    ucomAccountMailId: null as null | number,
    /** UCOMメールor他社メール */
    mailAddressType: '',
    /** アカウントID */
    accountName: '',
    /** メールアドレス */
    mailAddress: '',
    /** メールボックス容量 */
    mailBoxCapacity: undefined as undefined | number,
    /** 追加／縮小容量プルダウン */
    changeCapacity: '',
    /** 追加／縮小容量ラベル */
    changeCapacityLabel: '',
    /** 追加／縮小容量バリュー */
    changeCapacityValue: '',
    /** お支払情報登録状態 */
    isRegisteredPayment: '',
    /** サービス説明 */
    serviceDescription: '',
    /** 追加／縮小 単価(9.0GB 毎) */
    price: undefined as undefined | number,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部タイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    /** ローディング */
    isLoading: false,
    /** サービス説明同意 */
    isAgreeTerms: false,
    /** お支払い情報登録が無い場合はfalseにする */
    isInput: true,
    NOT_SELECTED_MESSAGE: '追加／縮小容量が選択されていません。',
    OTHER_COMPANY_MAIL_MESSAGE: 'UCOM光の提供するメールサービスを利用されていないため、メールボックス容量変更はご利用になれません。',
    paymentUrl: process.env.VUE_APP_UCOM_MY_URL + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
    termsUrl: '',
  }),
  async mounted() {
    try {
      this.isLoading = true;
      this.errorMessages = [];
      // 前画面からの情報をStoreから取得
      this.ucomAccountMailId = this.$store.getters['ucomAccountOptionStore/ucomAccountMailId'];
      // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
      if (!this.ucomAccountMailId) {
        await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      this.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
      if (this.mailAddressType == UCOM_MAIL_ADDRESS_TYPE.OTHER_COMPANY.toString()) {
        this.errorMessages.push(this.OTHER_COMPANY_MAIL_MESSAGE);
      }
      // 画面項目データセット
      await this.setData(this.ucomAccountMailId);
      //規約URLセット
      this.termsUrl = this.setTermsUrl();
      // お支払方法が未登録の場合
      if (this.isRegisteredPayment === UCOM_REGISTERED_PAYMENT.NOT_REGISTERED.id.toString()) {
        this.isInput = false;
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async setData(ucomAccountMailId: number): Promise<void> {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      if (this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId']) {
        this.setDataByStore();
        return;
      }
      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];
      // メールボックス容量情報(ユーザ、サービス)取得
      const requestDto = new FindUcomMbCapacityRequest(member.id, ucomAccountMailId);
      try {
        const mbCapacityUser = await SpfApiMailboxCapacityAccessor.findUcomMbCapacityUser(requestDto);
        const mbCapacityService = await SpfApiMailboxCapacityAccessor.findUcomMbCapacityService(requestDto);
        // 画面項目データをセット
        this.accountName = mbCapacityUser.accountName;
        this.mailAddress = mbCapacityUser.mailAddress;
        this.mailBoxCapacity = mbCapacityUser.mailBoxCapacity;
        this.changeCapacityLabel = mbCapacityService.changeCapacityLabel;
        this.changeCapacityValue = mbCapacityService.changeCapacityValue;
        this.price = mbCapacityService.price;
        this.isRegisteredPayment = mbCapacityUser.isRegisteredPayment;
        this.serviceDescription = mbCapacityService.serviceDescription;
      } catch (e) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    },
    // storeからデータをセット
    setDataByStore(): void {
      this.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.changeCapacityValue = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
      this.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.isRegisteredPayment = this.$store.getters['ucomMailboxCapacityStore/isRegisteredPayment'];
      this.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
      // 追加／縮小容量 初期表示セット
      this.changeCapacity = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
    },
    /**
     * サービス規約のURLをセット
     */
    setTermsUrl(): string {
      //物件基本情報
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty?.service_provid_former.service_provid_former_id == UCOM_PROPERTY_SERVICE_PROVID_FORMER.TNC.id) {
        return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_TNC;
      }
      return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_ARTERIA;
    },
    // 次へボタンクリック
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ初期化
      this.errorMessages = [];

      // 追加／縮小容量の選択がない場合
      if (!this.changeCapacity) {
        this.errorMessages.push(this.NOT_SELECTED_MESSAGE);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      // メールボックス容量情報をstoreに保存
      this.saveStore();

      // 確認画面へ遷移
      await this.$router.push('/ucom/mailbox-capacity/confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // storeへ保存
    saveStore(): void {
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.ucomAccountMailId);
      this.$store.commit('ucomMailboxCapacityStore/accountName', this.accountName);
      this.$store.commit('ucomMailboxCapacityStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', this.mailBoxCapacity);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', this.changeCapacityLabel);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityValue', this.changeCapacityValue);
      this.$store.commit('ucomMailboxCapacityStore/price', this.price);
      this.$store.commit('ucomMailboxCapacityStore/effectiveDate', processDate('YYYY/MM/DD'));
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.serviceDescription);
      this.$store.commit('ucomMailboxCapacityStore/isRegisteredPayment', this.isRegisteredPayment);
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isAgreeTerms || !this.changeCapacity,
        'btn btn01 bs': this.isAgreeTerms && this.changeCapacity,
      };
    },
  },
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn04:hover {
  opacity: 1;
}
.payment-url {
  color: red;
  text-decoration: underline;
}
</style>
