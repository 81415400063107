<template>
  <div class="error">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>エラーが発生しました</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- blc -->
      <div class="blc" v-if="isSpecialErrorMessage()">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="申し訳ございません。エラーが発生しました。" />エラーが発生しました。</h2>
        <p class="err" v-for="(msg, index) in specialMessage" :key="index">{{ msg }}</p>
      </div>
      <div class="blc" v-else>
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="エラー" />エラー</h2>
        <p class="err">
          大変申し訳ございません。<br />
          ただいまシステムが混みあっております。<br />
          恐れ入りますが、しばらく時間をおいた後、再度ご登録をお願いいたします。
        </p>
      </div>
      <!-- /blc -->

      <!-- sblc -->
      <div class="sblc">
        <h3 class="service-h3">e-mansionサポートセンター カスタマーサポート</h3>
        <p>▼問題が解決しない場合は、e-mansionサポートセンターへご連絡ください。</p>
        <div v-if="isTowaApartment">
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>フリーコール（無料）</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.TOWA].contactTel1 }}</td>
              </tr>
              <tr>
                <th><b>IPフォン専用</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.TOWA].contactTel2 }}</td>
              </tr>
              <tr>
                <th><b>メールアドレス</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.TOWA].contactMail }}</td>
              </tr>
              <tr>
                <td><b>受付時間</b></td>
                <td v-if="eMansionErrorDisplayInfoList">
                  {{ eMansionErrorDisplayInfoList[contactInfo.TOWA].contactOfficeHours }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="isSlimPlan">
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>フリーコール（無料）</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.SLIM].contactTel1 }}</td>
              </tr>
              <tr>
                <th><b>メールアドレス</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.SLIM].contactMail }}</td>
              </tr>
              <tr>
                <td><b>受付時間</b></td>
                <td v-if="eMansionErrorDisplayInfoList">
                  {{ eMansionErrorDisplayInfoList[contactInfo.SLIM].contactOfficeHours }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="isNormal">
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>フリーコール（無料）</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.NORMAL].contactTel1 }}</td>
              </tr>
              <tr>
                <th><b>IPフォン専用</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.NORMAL].contactTel2 }}</td>
              </tr>
              <tr>
                <th><b>メールアドレス</b></th>
                <td v-if="eMansionErrorDisplayInfoList">{{ eMansionErrorDisplayInfoList[contactInfo.NORMAL].contactMail }}</td>
              </tr>
              <tr>
                <td><b>受付時間</b></td>
                <td v-if="eMansionErrorDisplayInfoList">
                  {{ eMansionErrorDisplayInfoList[contactInfo.NORMAL].contactOfficeHours }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /sblc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export const E_MANSION_SUPPORT_CONTACT = {
  TOWA: 0,
  NORMAL: 1,
  SLIM: 2,
} as const;

/** エラー画面 */
export default defineComponent({
  name: 'e-mansion-error',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoggedIn: false,
    contactInfo: E_MANSION_SUPPORT_CONTACT,
    isTowaApartment: false,
    isSlimPlan: false,
    isNormal: false,
    eMansionErrorDisplayInfoList: undefined,
    specialMessage: '',
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
    try {
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      //storeから取得
      this.specialMessage = this.$store.getters['errorStore/messages'];
      //Storeをリセット
      this.$store.commit('errorStore/messages', undefined);
      this.contactInfo = E_MANSION_SUPPORT_CONTACT;
      this.isTowaApartment = isTowaApartment(property.apartment_id);
      this.isSlimPlan = property.slim_plan_flag == '1';
      this.isNormal = isNormal(this.isTowaApartment, this.isSlimPlan);
      this.eMansionErrorDisplayInfoList = await this.$store.dispatch('eMansionErrorDisplayInfoStore/eMansionErrorDisplayInfoList');
      if (!this.eMansionErrorDisplayInfoList) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (e) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    isSpecialErrorMessage(): boolean {
      if (!this.specialMessage) {
        return false;
      }
      if (this.specialMessage.length > 0) {
        return true;
      }
      return false;
    },
  },
});

function isTowaApartment(apartment_id: string) {
  if (apartment_id.match(/^T/)) {
    return true;
  }
  return !!apartment_id.match(/^W/);
}
function isNormal(isTowaApartment: boolean, isSlimPlan: boolean): boolean {
  if (isTowaApartment) {
    return false;
  }
  return !isSlimPlan;
}
</script>
