<template>
  <div class="not-found">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>エラーが発生しました</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">

      <!-- blc -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../images/service-h2.svg" alt="お探しのページは見つかりませんでした。">お探しのページは見つかりませんでした。</h2>
        <p>アクセスしようとしたページは削除されたかURLが変更されているため、見つけることができません。<br>お手数ですが、もう一度URLをご確認ください。</p>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <router-link to="/" custom v-slot="{ navigate }">
            <button class="btn btn01 bs" @click="navigate">トップページへ<i class="material-icons link link-icon">east</i></button>
          </router-link>
        </div>
      </div>
      <!-- /blc -->

    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/** Not Found 画面 */
export default defineComponent({
  name: 'not-found'
});
</script>