<template>
  <div class="billing-history-list">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>GameWith光 ご請求明細</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/gamewith">GameWith光マイページ</router-link></li>
        <li>ご請求明細</li>
      </ul>
      <div class="blc">
        <p>GameWith光は、株式会社GameWithが提供する光ファイバーインターネットサービスです。</p>
      </div>
      <div class="blc">
        <h2 class="underlayer-h2">ご請求明細</h2>
        <div>株式会社GameWithからの請求につき、明細をご確認いただけます。</div>
      </div>

      <div class="blc">
        <div class="right-aligned">
          株式会社GameWith<br />
          登録番号：T2010401106079
        </div>

        <div class="disp-middle">
          <div class="months-control">
            <button class="control-button" @click="increaseMonth" :disabled="selectedMonthValue === dispMonthList[0]">＜</button>
            <div
              class="month"
              v-for="n in 3"
              :key="n"
              @click="selectMonth(dispMonthList[currentMonthIndex + n - 1])"
              :class="{ 'selected-month': selectedMonthValue === dispMonthList[currentMonthIndex + n - 1] }"
            >
              <!-- memo: YYYYMM型 の値を MM型に変換する。 -->
              {{ dispMonthList[currentMonthIndex + n - 1] ? Number(dispMonthList[currentMonthIndex + n - 1].slice(-2)) : '' }}月
            </div>
            <button class="control-button" @click="decreaseMonth" :disabled="selectedMonthValue === dispMonthList[dispMonthList.length - 1]">＞</button>
          </div>
          <div>
            {{ selectedMonthValue ? Number(selectedMonthValue.slice(0, 4)) + '年' + Number(selectedMonthValue.slice(-2)) + '月 ご請求分' : '' }}
          </div>
        </div>

        <!-- 請求明細がある場合の表示 -->
        <div v-if="checkInvoiceMonth">
          <table class="billing-table blc">
            <thead>
              <tr>
                <th>内容</th>
                <th>利用期間</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody v-if="gwInvoiceHistoryList != null">
              <tr v-if="gwInvoiceHistoryList.constructionFee">
                <td>【税別】工事費</td>
                <td></td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.constructionFee) }}円</td>
              </tr>
              <tr v-if="gwInvoiceHistoryList.contractFee">
                <td>【税別】契約手数料</td>
                <td></td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.contractFee) }}円</td>
              </tr>
              <tr v-if="gwInvoiceHistoryList.monthlyUsageFee">
                <td>【税別】月額利用料</td>
                <td td class="disp-middle">{{ startDate }}～{{ finishDate }}</td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.monthlyUsageFee) }}円</td>
              </tr>
              <tr v-if="gwInvoiceHistoryList.otherFee">
                <td>【非課税】その他*</td>
                <td></td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.otherFee) }}円</td>
              </tr>
              <tr>
                <td>合計（税別）</td>
                <td></td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.totalFeeTaxExcluded) }}円</td>
              </tr>
              <tr>
                <td>消費税</td>
                <td></td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.taxTotal) }}円</td>
              </tr>
              <tr>
                <td>合計（税込）</td>
                <td></td>
                <td class="right-aligned">{{ formatMoney(gwInvoiceHistoryList.totalFeeTaxIncluded) }}円</td>
              </tr>
              <tr>
                <td>10％対象</td>
                <td></td>
                <td class="right-aligned" v-if="currentTaxRate === true">
                  {{ formatMoney(total10PercentProducts) }}円 (内消費税:{{ formatMoney(gwInvoiceHistoryList.taxTotal) }}円)
                </td>
                <td class="right-aligned" v-else>0円 (内消費税:0円)</td>
              </tr>
              <tr>
                <td>8％対象</td>
                <td></td>
                <td class="right-aligned" v-if="currentTaxRate === false">
                  {{ formatMoney(total8PercentProducts) }}円 (内消費税:{{ formatMoney(gwInvoiceHistoryList.taxTotal) }}円)
                </td>
                <td class="right-aligned" v-else>0円 (内消費税:0円)</td>
              </tr>
            </tbody>
          </table>
          <div>*は非課税</div>
        </div>

        <!-- 請求明細がない場合の表示 -->
        <div class="disp-middle" v-else>請求明細はございません。</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import dayjs from 'dayjs';
import { GameWithInvoiceResponseDto } from '@/shared/classes/spf-api/gw-invoice-history-list-dto';
import { SpfApiInternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-internal-gamewith-accessor';
import { BatchSituationDto } from '@/shared/classes/spf-api/batch-situation-dto';
import { SpfApiBatchSituationAccessor } from '@/infra/accessor/spf/common/spf-api-batchsituation-accessor';
import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { GamewithCustomerInfoResponse } from '@/infra/response/spf/gamewith/gamewith-customer-info-response';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { CONTRACT_STATUS } from '@/shared/const/gamewith/contract-status';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';

export default defineComponent({
  name: 'billing-history-list',
  components: {
    LoadingComponent,
  },
  data: () => ({
    // 会員情報
    member: null as Member | null,
    //会員ステータス
    memberStatus: null as MemberStatus | null,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** 13カ月間の月のリスト */
    dispMonthList: [] as string[],
    /** 表示する月の選択のため */
    currentMonthIndex: 0,
    /** 選択・表示する月の値 YYYYMM型 */
    selectedMonthValue: null as string | null,
    /** Connectix提供有無 */
    isCanConnectixApply: false,
    /** 請求年月の初日 */
    firstDayOfTheBilling: null as string | null,
    /** 請求年月の月末 */
    lastDayOfTheBilling: null as string | null,
    /** GameWith光会員 */
    isLinkedGW: false,
    /** GameWith光での表示する請求明細 */
    gwInvoiceHistoryList: null as GameWithInvoiceResponseDto | null,
    /** バッチ状況を取得 */
    GetBatchSituation: null as BatchSituationDto | null,
    /** 月額利用料での開始年月日の値を取得*/
    startDate: '',
    /** 月額利用料での終了年月日の値を取得*/
    finishDate: '',
    /** 消費税のだし分け*/
    currentTaxRate: false,
    /** 対象月の請求明細の存在チェック*/
    checkInvoiceMonth: false,
    /** バッチ状況での月を取得*/
    checkoutBatchSituation: false,
    /** 10%対象商品の合計料金 */
    total10PercentProducts: 0,
    /** 8%対象商品の合計料金 */
    total8PercentProducts: 0,
  }),

  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 会員情報取得
    this.member = this.$store.getters['memberStore/member'];
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];

    this.isLinkedGameWith();

    // バッチ状況取得
    const batchId = 'BG01-001';
    await this.getBatchSituation(batchId);

    // GW請求明細取得
    this.getGWInvoiceHistoryList(this.selectedMonthValue!);

    // 表示月を取得
    this.dispMonthList = this.getLastTenMonths(this.selectedMonthValue!);

    this.isLoading = false;
  },

  methods: {
    /**
     * @param 年
     * @param 月
     * @return paramの月末をformatして返す
     */
    getEndOfMonth(year: number, month: number): string {
      // dayjsは月を0から11で扱うため、1減らす。
      const date = dayjs()
        .year(year)
        .month(month - 1)
        .endOf('month');
      return date.format('YYYY年MM月DD日');
    },

    /**
     * 未開通、キャンセル、解約・廃止&閉鎖フラグ=trueの場合、GW光マイページへ遷移
     */
    async isLinkedGameWith() {
      let gwCustomer: GamewithCustomerInfoResponse;
      const gwid = String(this.memberStatus?.encryptedPrimaryKeyGw);
      try {
        gwCustomer = await SpfApiExternalGamewithAccessor.getCustomer(gwid);
      } catch (e: any) {
        if (e.response?.status == '503') {
          await this.$router.push('/gamewith').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
      }
      if (this.isCancelGamewith(gwCustomer.contractStatus, gwCustomer.portasCloseFlag)) {
        await this.$router.push('/gamewith').catch((error: any) => {
          checkRouterError(error);
        });
      }
      if (gwCustomer.contractStatus == CONTRACT_STATUS.APPLICATION_NOT_OPEN) {
        await this.$router.push('/gamewith').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },
    /**
     * GW会員の契約状態チェック
     * 「キャンセル」、「廃止」/「解約」＆閉鎖フラグがTRUEの場合true
     */
    isCancelGamewith(status: string, closedFlag: boolean): boolean {
      if (status == CONTRACT_STATUS.APPLICATION_CANCELED) {
        return true;
      }
      if (status == CONTRACT_STATUS.ABOLITION || status == CONTRACT_STATUS.CANCELED) {
        if (closedFlag == true) {
          return true;
        }
      }
      return false;
    },
    /**
     * @param nowMonth 直近の年月（YYYYMM形式）。
     * @return 直近の年月を含めた過去13ヶ月間のリスト
     */
    getLastTenMonths(nowMonth: string): string[] {
      let year = parseInt(nowMonth.slice(0, 4));
      let month = parseInt(nowMonth.slice(4, 6));

      // 長さ10の新しい配列を生成し、各要素に年月を割り当てる
      return Array.from({ length: 13 }, () => {
        if (month === 0) {
          year--;
          month = 12;
        }
        const ym = `${year.toString().padStart(4, '0')}${month.toString().padStart(2, '0')}`;
        month--;
        return ym;
      });
    },
    /**
     * 「＞」ボタン押下時のmethod
     * 1ヵ月分、過去月にする
     */
    decreaseMonth() {
      if (this.currentMonthIndex < this.dispMonthList.length - 3) {
        this.currentMonthIndex++;
      }
      this.calculateMonthAndGetInvoiceHistoryList('decrease');
    },
    /**
     * 「＜」ボタン押下時のmethod
     * 1ヵ月分、最新月にする
     */
    increaseMonth() {
      if (this.currentMonthIndex > 0) {
        this.currentMonthIndex--;
      }
      this.calculateMonthAndGetInvoiceHistoryList('increase');
    },
    /**
     * 「＞」「＜」ボタン押下時に、1ヵ月分最新にしたり過去にしたりしてから 請求履歴取得 するmethod
     * @param increaseOrDecrease "increase" or "decrease"
     * @return newMonth YYYYMM型
     *
     * increase: 1ヵ月分、最新月にする
     * decrease: 1ヵ月分、過去月にする
     */
    calculateMonthAndGetInvoiceHistoryList(increaseOrDecrease: string) {
      if (this.selectedMonthValue) {
        // 1. this.selectedMonthValueをDate型にする
        const nowYear = parseInt(this.selectedMonthValue.slice(0, 4));
        const nowMonth = parseInt(this.selectedMonthValue.slice(4, 6)) - 1;
        const nowDateObj = new Date(nowYear, nowMonth);

        //  * increase: 1ヵ月分、最新月にする
        if (increaseOrDecrease === 'increase') {
          // 2. this.selectedMonthValueを+1ヵ月する
          nowDateObj.setMonth(nowDateObj.getMonth() + 1);
        }
        //  * decrease: 1ヵ月分、過去月にする
        if (increaseOrDecrease === 'decrease') {
          // 2. this.selectedMonthValueを-1ヵ月する
          nowDateObj.setMonth(nowDateObj.getMonth() - 1);
        }
        // 3. 再びstring型に戻す
        const newYear = nowDateObj.getFullYear().toString();
        const newMonth = (nowDateObj.getMonth() + 1).toString().padStart(2, '0'); // 1-9は01-09にする
        this.selectedMonthValue = `${newYear}${newMonth}`;

        // 4. 請求履歴取得処理
        this.getGWInvoiceHistoryList(this.selectedMonthValue);
      }
    },
    /**
     * 表示されている月の表示をクリックした際に値を更新する
     * 同じ月をクリックした場合は更新しない
     *
     * @param month YYYYMM型
     */
    selectMonth(month: string) {
      if (this.selectedMonthValue !== month) {
        this.selectedMonthValue = month;
        // 請求履歴取得
        this.getGWInvoiceHistoryList(month);

        this.checkoutBatchSituation = false;
      }
    },
    /**
     * バッチ状況取得
     * @param batchId
     */
    async getBatchSituation(batchId: String) {
      this.GetBatchSituation = await SpfApiBatchSituationAccessor.getBatchSituation(batchId);
      this.selectedMonthValue = await String(this.GetBatchSituation.value1);
      this.checkoutBatchSituation = true;
      return;
    },

    /**
     * GW請求明細取得
     * @param targetMonth 請求年月 YYYYMM型
     */
    async getGWInvoiceHistoryList(targetMonth: String) {
      // GW請求明細 を取得し配列を取得する
      const gwid = String(this.member?.primaryKeyGW);
      const invoiceMonth = Number(targetMonth);
      this.gwInvoiceHistoryList = await SpfApiInternalGamewithAccessor.getGWInvoice(gwid, invoiceMonth);
      let checkMonth = await Number(String(this.gwInvoiceHistoryList.invoiceMonth).substring(4, 6));

      // 月額利用料での年月取得
      this.startDate = this.formatDate(this.gwInvoiceHistoryList.startDate);
      this.finishDate = this.formatDate(this.gwInvoiceHistoryList.finishDate);

      if (new Date(this.gwInvoiceHistoryList.startDate!).getMonth() + 1 === Number(checkMonth)) {
        this.checkInvoiceMonth = true;
      } else {
        this.checkInvoiceMonth = false;
      }
      // 消費税のだし分け
      if (this.gwInvoiceHistoryList.taxRate != null) {
        if (this.gwInvoiceHistoryList.taxRate == Number('10.00')) {
          this.total10PercentProducts = this.gwInvoiceHistoryList.totalFeeTaxIncluded - this.gwInvoiceHistoryList.otherFee;
          this.currentTaxRate = true;
          return;
        } else if (this.gwInvoiceHistoryList.taxRate == Number('8.00')) {
          this.total8PercentProducts = this.gwInvoiceHistoryList.totalFeeTaxIncluded - this.gwInvoiceHistoryList.otherFee;
          this.currentTaxRate = false;
          return;
        }
      }
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(billing);
      return formattedValue;
    },
    formatDate(date: Date | undefined): string {
      return date ? dayjs(date).format('YYYY年MM月DD日') : '';
    },
  },
});
</script>

<style lang="scss" scoped>
.billing-table {
  border-collapse: collapse;
  border: 1px solid lightpink;
  width: 100%;
}

.billing-table th,
.billing-table td {
  border-bottom: 1px solid lightpink;
  padding: 10px;
}

.billing-table th {
  background-color: lightpink;
  color: white;
}

.right-aligned {
  text-align: right;
}

.disp-middle {
  text-align: center;
}
.months-control {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
}

.control-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
  &:disabled {
    color: #ccc;
  }
}

.month {
  padding: 20px;
  cursor: pointer;
}

.selected-month {
  background-color: red;
  color: white;
  border-radius: 50%;
  border: 1px solid black;
}
</style>
