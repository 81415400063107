import { ReportVueErrorHandler } from "./reporter/report-vue-error-handler";
import { RevisionHandler } from "./revision/revision-handler";
import { Auth0ErrorHandler } from "./auth0-error-handler";
import router from '@/router';

export class VueErrorHandler {

  public static async handle(error: Error, info: string) {
    try {

      const isRevisionError = await RevisionHandler.checkRevisionError(error);

      if (isRevisionError) {
        // リビジョンエラーは何もしない
        return;
      }

      // ARN_QA-829, 830 の対応: 特定の Auth0 エラーのとき、総合トップへ遷移させる
      if (Auth0ErrorHandler.isErrorAuthorizationFlow(error)
          || Auth0ErrorHandler.isErrorNonceClaimMismatchInIDToken(error)) {
        await router.push('/');
        return;
      }

      this.report(error, info);
      await router.push('/error');
    } catch (error) {
      // 握りつぶす
    }
  }

  private static async report(error: Error, info: string) {

    // Vueが検知したバックエンドへ送るべきエラーは全てここで吸収する
    ReportVueErrorHandler.reportErrorToVueErrorHandler(error, info);

  }

  
}