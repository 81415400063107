/** 3Dセキュア本人認証(トークン使用)API リクエスト */
export class Create3dSecureAuthStartInfoWithCardTokenRequest {
  /** uuid */
  public readonly uuid!: string;
  /** クレジットカードトークン */
  public readonly creditCardToken!: string;

  constructor(fields: Required<Create3dSecureAuthStartInfoWithCardTokenRequest>) {
    Object.assign(this, fields);
  }
}
