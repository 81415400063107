export enum ProductIds {
  /** ノートンセキュリティ */
  NortonSecurity = 1,
  /** Internet SagiWall for マルチデバイス */
  internetSagiWallForMultiDevice = 2,
  /** Wi-Fi&Device Support */
  wiFiDeviceSupport = 3,
  /** U-NEXT月額プラン */
  UNextMonthly = 4,
  /** AdGuard */
  AdGuard = 5,
}
