import { Module } from 'vuex';
import { RootState } from '../index';
import { EMansionHomepageCancelInitialDisplayResponse } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-response';

/** e-mansion ホームページ開設 State */
const eMansionHomepageOpenState: {
  requestUrl: string | undefined;
  homepageUrl: string | undefined;
  ftpAccount: string | undefined;
  ftpPassword: string | undefined;
  ftpServerName: string | undefined;
  fileDest: string | undefined;
  eMansionHomepageId: number| undefined;
  serviceEndDate: Date | undefined;
} = {
  /** e-mansion ホームページ情報 */
  requestUrl: undefined,
  homepageUrl: undefined,
  ftpAccount: undefined,
  ftpPassword: undefined,
  ftpServerName: undefined,
  fileDest: undefined,
  eMansionHomepageId: undefined,
  serviceEndDate: undefined
};

/** State の型 */
type EMansionHomepageOpenState = typeof eMansionHomepageOpenState;

/** e-mansion Homepage共通系 Store */
export const eMansionHomepageOpenStore: Module<EMansionHomepageOpenState, RootState> = {
  namespaced: true,
  state: eMansionHomepageOpenState,

  mutations: {
    requestUrl: (state, value: string) => (state.requestUrl = value),
    homepageUrl: (state, value: string) => (state.homepageUrl = value),
    ftpAccount: (state, value: string) => (state.ftpAccount = value),
    ftpPassword: (state, value: string) => (state.ftpPassword = value),
    ftpServerName: (state, value: string) => (state.ftpServerName = value),
    fileDest: (state, value: string) => (state.fileDest = value),
    eMansionHomepageId: (state, value: number) => (state.eMansionHomepageId = value),
    serviceEndDate: (state, value: Date) => (state.serviceEndDate = value),
  },
  getters: {
    requestUrl: (state): string | undefined => state.requestUrl,
    homepageUrl: (state): string | undefined => state.homepageUrl,
    ftpAccount: (state): string | undefined => state.ftpAccount,
    ftpPassword: (state): string | undefined => state.ftpPassword,
    ftpServerName: (state): string | undefined => state.ftpServerName,
    fileDest: (state): string | undefined => state.fileDest,
    eMansionHomepageId: (state): number | undefined => state.eMansionHomepageId,
    serviceEndDate: (state): Date | undefined => state.serviceEndDate,
  },
};
