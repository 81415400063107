/** 同時お申し込み（FiveA）API：リクエスト */
export class LinkServiceWithFiveARequest {
  /** UA種別 */
  public uaType!: string;
  /** 物件_id */
  public propertyId!: string;
  /** 棟ID */
  public buildingId!: string;
  /** 部屋番号 */
  public roomNumber!: string;
  /** 姓 */
  public nameSurname!: string;
  /** 名 */
  public nameFirstName!: string;
  /** 姓カナ */
  public kanaSurname!: string;
  /** 名カナ */
  public kanaFirstName!: string;
  /** 性別 */
  public sex!: string;
  /** 誕生日 */
  public birthday!: string;
  /** 電話番号 */
  public phoneNumber!: string;
  /** 緊急連絡先 */
  public emergencyPhoneNumber?: string;
  /** FAX番号 */
  public faxNumber?: string;
  /** 連絡先メールアドレス */
  public emailAddress!: string;
  /** 入居予定日 */
  public scheduledMoveInOn?: string;
  /** 書類送付先種別 */
  public destinationKind?: string;
  /** 郵便番号（ハイフン無し） */
  public destinationZipcode?: string;
  /** 住所１ */
  public destinationAddress1?: string;
  /** 住所２ */
  public destinationAddress2?: string;
  /** 連絡先電話番号 */
  public destinationTelephone?: string;
  /** 希望メールアドレス */
  public desiredAccount?: string;
  /** ウイルスチェック安心サービス */
  public virusCheckService?: string;
  /** e-mansion IPフォン */
  public ipPhone?: string;
  /** TA配送日（自動計算） */
  public deliveryDate?: string;
  /** TA配送時間帯 */
  public deliveryTimeZone?: string;
  /** マルチデバイスセキュリティ */
  public multiDeviceSecurity?: string;
  /** iフィルター */
  public iFilter?: string;
  /** クレジットカードトークン */
  public creditCardToken?: string;
  /** トークン有効期限 */
  public tokenExpireDate?: string;
  /** ご契約内容の電子交付希望 */
  public electronicdelivery?: string;
  /** 電子交付用メールアドレス */
  public electronicdeliveryEmailAddress?: string;
  /** （契約書面の送付先郵便番号）スリムプラン用 */
  public contractDestinationZipcode?: string;
  /** （契約書面の送付先住所指定１）スリムプラン用 */
  public contractDestinationAddress1?: string;
  /** （契約書面の送付先住所指定２）スリムプラン用 */
  public contractDestinationAddress2?: string;
  /** 接続コースID */
  public courseId?: string;
  /** アダプタ */
  public adapter?: string;
  /** ご希望パスワード */
  public password?: string;
  /** UCOM光電話 */
  public hikariphone?: string;
  /** 開通希望日 */
  public uhPreferredDate?: string;
  /** 番号ポータビリティお申し込み */
  public uhNumberPortabilityFlag?: string;
  /** 番号ポータビリティ電話番号 */
  public uhPortabilityNumber?: string;
  /** 名義人氏名 */
  public uhName?: string;
  /** 名義人氏名カナ */
  public uhKana?: string;
  /** 続柄 */
  public uhRelationshipType?: string;
  /** 電話会社種別 */
  public uhTelephoneCompanyType?: string;
  /** 電話会社 */
  public uhTelephoneCompanyOther?: string;
  /** 登録住所郵便番号（ハイフン無し） */
  public uhDestinationZipcode?: string;
  /** 登録住所１ */
  public uhDestinationAddress1?: string;
  /** 登録住所２ */
  public uhDestinationAddress2?: string;
  /** 着信番号表示 */
  public uhOption1?: string;
  /** キャッチ通話 */
  public uhOption2?: string;
  /** キャッチ通話番号表示 */
  public uhOption3?: string;
  /** オプションサービスパック */
  public uhOption4?: string;
  /** 番号通知 */
  public uhNotificationFlag?: string;
  /** Connectix同時お申し込み */
  public sdwan?: string;
  /** カード会員 ID */
  public cardAccountId?: string;

  constructor(partial: Partial<LinkServiceWithFiveARequest>) {
    Object.assign(this, partial);
  }
}

export class LinkServiceWithFiveAResponse {
  /** エラーメッセージ */
  public errors?: Array<string>;
  /** メールアドレス */
  public mailaddress?: string;

  constructor(partial: Partial<LinkServiceWithFiveAResponse>) {
    Object.assign(this, partial);
  }
}
