import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM 機器レンタルサービス State */
const ucomRentalServiceState: {
  applyDate: string,
  mailAddress: string[]
} = {
  applyDate: '',
  mailAddress: []
};

type UcomRentalServiceState = typeof ucomRentalServiceState;

/** UCOM 機器レンタルサービス State */
export const ucomRentalServiceStore: Module<UcomRentalServiceState, RootState> = {
  namespaced: true,
  state: ucomRentalServiceState,
  actions: {},
  mutations: {
    applyDate: (state, value: string) => (state.applyDate = value),
    mailAddress: (state, value: string[]) => (state.mailAddress = value)
  },
  getters: {
    applyDate: (state): string | undefined => state.applyDate,
    mailAddress: (state): string[] | undefined => state.mailAddress
  },
};
