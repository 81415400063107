import { UaTypeValue } from "@/shared/const/service-type";

export class FiveACustomerQueryDto {
  public ua_type!: UaTypeValue;
  public apartment_id!: string;

  constructor(partial: Partial<FiveACustomerQueryDto>) {
    Object.assign(this, partial);
  }
}
