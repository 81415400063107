/** サブスクリプション詳細DTO */
export class SubscriptionDetailsRequestDto {
  /** 契約 ID */
  public contractId!: number;

  /** コンテンツプロバイダー ID */
  public contentProviderId!: number;

  /** 申込日時 */
  public requestDateTime!: string;
  /** コンストラクタ */
  constructor(partial: Partial<SubscriptionDetailsRequestDto>) {
    Object.assign(this, partial);
  }
}
