export const UCOM_PAYMENT_STATUS = {
  NOT_REGISTERED: '0', // 未登録
  REGISTERED: '1', // 登録済
} as const;
export type UCOM_PAYMENT_STATUS = typeof UCOM_PAYMENT_STATUS[keyof typeof UCOM_PAYMENT_STATUS];

export const convertUcomPaymentStatus = (value: string): UCOM_PAYMENT_STATUS | undefined => {
  for (const v of Object.values(UCOM_PAYMENT_STATUS)) {
    if (String(v) == value) {
      return v;
    }
  }
};
