import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import store from '@/store';

export class EMansionConnectixStatus {
  /**
   * Connectix契約済みかどうか
   */
  public static isContracted(): boolean {
    const eMansionCustomer: EMansionCustomer | null = store.getters['eMansionCommonStore/customer'];

    if (eMansionCustomer instanceof EMansionCustomer) {
      if (eMansionCustomer.op_connectix_contract_status === '1') {
        return true;
      }
    }
    return false;
  }

  /**
   * Connectix提供物件かどうか
   */
  public static isProvided(): boolean {
    const eMansionProperty: EMansionProperty | null = store.getters['eMansionCommonStore/property'];

    if (eMansionProperty instanceof EMansionProperty) {
      if (eMansionProperty.op.sdwan.flag === '1') {
        return true;
      }
    }
    return false;
  }

  /**
   * Connectix申し込み可能かどうか
   *
   * Connectix申し込み可能なとき
   *  ・ e-mansionの物件と紐づいている
   *  ・ e-mansionアカウント連携済み
   *  ・ Connectix提供物件である
   *  ・ Connectix未契約である
   *  ・ e-mansion会員ステータス: 会員
   *
   * 補足
   *  ・ e-mansion会員ステータスが会員以外のステータス(退会申し込み、移転退会など)のとき、Connectix申込できない
   */
  public static canApply(): boolean {
    const member: Member = store.getters['memberStore/member'];
    const property: Property | null = store.getters['propertyStore/property'];

    if (property?.uaType === UA_TYPE.E_MANSION && member.primaryKeyMye) {
      if (this.isProvided() && !this.isContracted()) {
        const memberStatus: MemberStatus = store.getters['memberStore/memberStatus'];
        const eMansionCustomer: EMansionCustomer | null = store.getters['eMansionCommonStore/customer'];

        if (eMansionCustomer instanceof EMansionCustomer) {
          const ispMemberStatus = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
          if (ispMemberStatus === ISP_MEMBER_STATUS.E_MANSION.MEMBER) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
