import { UCOM_ACCOUNT_AUTHORITY } from '../../../../../shared/const/ucom/ucom-account-authority';
import { UCOM_PURCHASE_STATUS } from '../../../../../shared/const/ucom/ucom-purchase-status';

/** ウイルスチェック初期表示情報 */
export class UcomVirusCheckInfo {
  readonly _memberId: number; // Portas会員ID
  readonly _accountAuthorization: string; // UCOM_アカウント権限
  readonly _accountName: string; // UCOM_アカウント名
  readonly _mailAddress: string; // メールアドレス
  readonly _virusCheckRegisterStatus: string; // ウイルスチェックご契約状態
  readonly _paymentRegisterStatus: string; // お支払情報登録状態
  readonly _paymentUnregisteredMessage: string | undefined; // お支払情報登録未設定時メッセージ
  readonly _virusCheckPauseSetting: number; // ウイルスチェック設定
  readonly _monthlyFee: number; // 月額利用料
  readonly _serviceDescriptionBeforePurchase: string; // サービス説明 (購入前)
  readonly _serviceDescriptionAfterPurchase: string; // サービス説明 (購入後)

  constructor(params: {
    memberId: number;
    accountAuthorization: string;
    accountName: string;
    mailAddress: string;
    virusCheckRegisterStatus: string;
    paymentRegisterStatus: string;
    paymentUnregisteredMessage: string | undefined;
    virusCheckPauseSetting: number;
    monthlyFee: number;
    serviceDescriptionBeforePurchase: string;
    serviceDescriptionAfterPurchase: string;
  }) {
    this._memberId = params.memberId;
    this._accountAuthorization = params.accountAuthorization;
    this._accountName = params.accountName;
    this._mailAddress = params.mailAddress;
    this._virusCheckRegisterStatus = params.virusCheckRegisterStatus;
    this._paymentRegisterStatus = params.paymentRegisterStatus;
    this._paymentUnregisteredMessage = params.paymentUnregisteredMessage;
    this._virusCheckPauseSetting = params.virusCheckPauseSetting;
    this._monthlyFee = params.monthlyFee;
    this._serviceDescriptionBeforePurchase = params.serviceDescriptionBeforePurchase;
    this._serviceDescriptionAfterPurchase = params.serviceDescriptionAfterPurchase;
  }

  get memberId(): number {
    return this._memberId;
  }
  get accountAuthorization(): string {
    return this._accountAuthorization;
  }
  get accountName(): string {
    return this._accountName;
  }
  get mailAddress(): string {
    return this._mailAddress;
  }
  get virusCheckRegisterStatus(): string {
    return this._virusCheckRegisterStatus;
  }
  get paymentRegisterStatus(): string {
    return this._paymentRegisterStatus;
  }
  get paymentUnregisteredMessage(): string | undefined {
    return this._paymentUnregisteredMessage;
  }
  get virusCheckPauseSetting(): number {
    return this._virusCheckPauseSetting;
  }
  get monthlyFee(): number {
    return this._monthlyFee;
  }
  get serviceDescriptionBeforePurchase(): string {
    return this._serviceDescriptionBeforePurchase;
  }
  get serviceDescriptionAfterPurchase(): string {
    return this._serviceDescriptionAfterPurchase;
  }

  /** ウイルスチェックの一時停止/実施設定をできる状態かどうかを判定する */
  public canChangePauseSetting(): boolean {
    if (this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.IN_CONTRACT_APPLICATION.id.toString()) {
      return true;
    }
    if (this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.CONTRACTED.id.toString()) {
      return true;
    }
    return this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.IN_WITHDRAWAL_APPLICATION.id.toString();
  }

  /** ウイルスチェックのサービスを購入できる状態かどうかを判定する */
  public canPurchase(): boolean {
    if (this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.CANCELLED.id.toString()) {
      return true;
    }
    return this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.UNPURCHASED.id.toString();
  }

  /** ウイルスチェックのサービスを解約できる状態かどうかを判定する */
  public canCancel(): boolean {
    if (this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.IN_CONTRACT_APPLICATION.id.toString()) {
      return true;
    }
    if (this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.CONTRACTED.id.toString()) {
      return true;
    }
    return this.virusCheckRegisterStatus === UCOM_PURCHASE_STATUS.IN_WITHDRAWAL_APPLICATION.id.toString();
  }
}

export class UcomVirusCheckUpdateResult {
  readonly _mailAddress: string | undefined; // 通知先メールアドレス
  readonly _errorMessage: string | undefined; // エラーメッセージ

  constructor(params: { mailAddress: string | undefined; errorMessage: string | undefined }) {
    this._mailAddress = params.mailAddress;
    this._errorMessage = params.errorMessage;
  }

  get mailAddress(): string | undefined {
    return this._mailAddress;
  }
  get errorMessage(): string | undefined {
    return this._errorMessage;
  }
}
