<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>宅内工事日選択</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>宅内工事日選択 入力</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="宅内工事日選択入力" />宅内工事日選択 入力</h2>
      </div>

      <ErrorMessagesComponent v-if="errorMessages" :errorMessages="errorMessages" />

      <div class="sblc">
        <div>
          <div>
            宅内工事のご希望日をご選択ください。時間帯は午前/午後/指定なしからご選択いただけます。<br />
            希望日の選択が難しい場合は、電話での調整を希望をご選択ください。
          </div>
          <div class="preferenceForPhone">
            <input type="radio" class="mailTypeRadio selectedPhoneOptions" v-model="selectedPhone" :value="1" name="selectedPhoneOptions" />　
            <span class="preferenceForPhoneInput" :class="{ 'disabled-background': selectedPhone === 2 }">希望日を選択</span>
          </div>
          <table class="table-type2 billing-table">
            <tbody :class="{ 'disabled-background': selectedPhone === 2 }">
              <tr>
                <th class="va-middle">第一希望:</th>
                <td>
                  <div>
                    <select v-model="selectedBusinessDayForFirst" class="select" :disabled="selectedPhone === 2">
                      <option value="">選択してください</option>
                      <option v-for="(day, index) in businessdayList" :key="index" :value="day">
                        {{ formatDateJapanese(day.businessDate) }}
                      </option>
                    </select>
                  </div>
                </td>

                <td>
                  <div>
                    <select v-model="selectedTimeOfDayForFirst" class="select" :disabled="selectedPhone === 2">
                      <option value="0">指定なし</option>
                      <option value="1">午前</option>
                      <option value="2">午後</option>
                    </select>
                  </div>
                </td>
              </tr>

              <tr>
                <th class="va-middle">第二希望:</th>
                <td>
                  <div v-if="businessdayList.length > 0">
                    <select v-model="selectedBusinessDayForSecond" class="select" :disabled="selectedPhone === 2">
                      <option value="">選択してください</option>
                      <option v-for="(day, index) in businessdayList" :key="index" :value="day">
                        {{ formatDateJapanese(day.businessDate) }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div>
                    <select v-model="selectedTimeOfDayForSecond" class="select" :disabled="selectedPhone === 2">
                      <option value="0">指定なし</option>
                      <option value="1">午前</option>
                      <option value="2">午後</option>
                    </select>
                  </div>
                </td>
              </tr>

              <tr>
                <th class="va-middle">第三希望:</th>
                <td>
                  <div>
                    <div v-if="businessdayList.length > 0">
                      <select v-model="selectedBusinessDayForThird" class="select" :disabled="selectedPhone === 2">
                        <option value="">選択してください</option>
                        <option v-for="(day, index) in businessdayList" :key="index" :value="day">
                          {{ formatDateJapanese(day.businessDate) }}
                        </option>
                      </select>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <select v-model="selectedTimeOfDayForThird" class="select" :disabled="selectedPhone === 2">
                      <option value="0">指定なし</option>
                      <option value="1">午前</option>
                      <option value="2">午後</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="preferenceForPhone">
            <input type="radio" class="mailTypeRadio" v-model="selectedPhone" :value="2" name="selectedPhoneOptions" />　
            <span class="preferenceForPhoneInput" :class="{ 'disabled-background': selectedPhone === 1 }">電話での調整を希望</span>
            <ul class="">
              <li class="">
                選択可能な日時は工事の確定をお約束するものではございません。日程は、後日弊社からの電話連絡時に確定となります。<br />
                建物の状況、予約状況、その他の要因により、ご希望に添えない場合がございます。
              </li>
              <li>
                以下の地域では土曜日の宅内工事をお受けすることが出来ません。<br />
                希望日にご登録いただきましても、他の曜日でのご提案となりますことご了承ください。<br />
                <span class="infomation-for-phone">土曜日対応不可地域：北海道、東海、九州</span>
              </li>
              <li>
                建物全体の開通工事日前の日程で宅内工事をお申し込みの場合、共用部での工事完了まで弊社インターネット接続はご利用いただけません。<br />
                ご利用開始につきましては、別途ご案内の資料をご確認ください。
              </li>
            </ul>
          </div>

          <div class="preferenceForPhone">
            <div class="preferenceForPhoneInput">宅内工事日の調整結果は、お電話でご連絡いたします。電話番号をご入力ください。</div>
          </div>
          <div class="inputFormForPhone">
            <span class="infomation-for-phone-need">(必須)　</span><input type="tel" class="phone-text" v-model="phoneNumber" maxlength="11" />　　<span
              class="preferenceForPhoneInput"
              >ハイフンなし</span
            >
          </div>
          <div class="preferenceForPhone">
            <div>
              <span class="infomation-for-phone"> ※平日9時から17時半の間にご連絡いたします。留守番電話にメッセージを録音する場合もございますので、ご確認ください。</span>
            </div>
          </div>
        </div>
      </div>
      <div class="blc">
        <div class="btn-areaR">
          <button class="btn bs" v-on:click="onNext()" :class="[isButtonActive ? 'btn01' : 'btn05']" :disabled="!isButtonActive">
            次へ進む
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.ul li {
  margin-bottom: 8px;
  text-indent: -1em;
  padding-left: 1em;
}
</style>

<script lang="ts">
import { BusinessdayMasterDTO, BusinessdayMasterDTOArray } from '@/shared/classes/spf-api/available-business-days-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { parsePhoneNumber } from 'libphonenumber-js';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'platform-cancel-impossible',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data() {
    return {
      //選べる希望日APIからの配列
      businessdayList: [] as BusinessdayMasterDTOArray,

      //第一希望 年月日
      selectedBusinessDayForFirst: null as BusinessdayMasterDTO | '' | null,
      //第二希望 年月日
      selectedBusinessDayForSecond: null as BusinessdayMasterDTO | '' | null,
      //第三希望 年月日
      selectedBusinessDayForThird: null as BusinessdayMasterDTO | '' | null,

      //第一希望 時間
      selectedTimeOfDayForFirst: null,
      //第二希望 時間
      selectedTimeOfDayForSecond: null,
      //第三希望 時間
      selectedTimeOfDayForThird: null,

      //電話番号での調整を希望かどうか 1:希望日を選択,2:電話での調整を希望を選択
      selectedPhone: 1,
      //電話番号
      phoneNumber: '',
      //第一希望から第三希望まで満たされているかのフラグ
      selectedDayFlag: false,
      // 物件情報
      property: null as Property | null,
      // ローディング
      isLoading: false,
      //エラー
      errorMessages: [] as string[],
    };
  },

  computed: {
    firstBusinessDay(): BusinessdayMasterDTO | null {
      return this.businessdayList.length > 0 ? this.businessdayList[0] : null;
    },

    //希望年月日と希望時間の入力チェック
    allFieldsSelected() {
      const isAnyTimeSelected = this.selectedTimeOfDayForFirst !== '0' || this.selectedTimeOfDayForSecond !== '0' || this.selectedTimeOfDayForThird !== '0';
      const isAnyBusinessDaySelected = this.selectedBusinessDayForFirst !== '' || this.selectedBusinessDayForSecond !== '' || this.selectedBusinessDayForThird !== '';

      // 条件を満たす場合にのみtrueを返す
      if (isAnyTimeSelected) {
        return isAnyBusinessDaySelected;
      } else {
        return isAnyBusinessDaySelected;
      }
    },

    checkDuplicateSelections() {
      const selections = [
        { day: this.selectedBusinessDayForFirst, time: this.selectedTimeOfDayForFirst },
        { day: this.selectedBusinessDayForSecond, time: this.selectedTimeOfDayForSecond },
        { day: this.selectedBusinessDayForThird, time: this.selectedTimeOfDayForThird },
      ].filter((selection) => selection.day !== null && selection.day !== '');

      const uniqueSelections = new Set();

      //第一希望日から第三希望日の入力に、同様の年月日と時間を許容しない
      for (const selection of selections) {
        let dayString;
        if (typeof selection.day === 'object' && selection.day?.businessDate) {
          //日付を年-月-日の形式で取得し、日付で比較
          dayString = new Date(selection.day.businessDate).toISOString().split('T')[0];
        } else {
          // 予期しない型の場合はスキップ
          continue;
        }

        const selectionString = `${dayString}-${selection.time}`;

        // 重複がある場合はtrueを返す
        if (uniqueSelections.has(selectionString)) {
          this.errorMessages = ['同じ工事日時は選択出来ません'];

          return true;
        } else {
          this.errorMessages = [];
        }

        uniqueSelections.add(selectionString);
      }

      return false;
    },

    //次へボタン活性化の制御
    isButtonActive() {
      if (this.selectedPhone == 2 && !this.phoneNumber) {
        this.errorMessages = [];
        return false;
      } else if (this.selectedPhone == 2 && this.phoneNumber) {
        this.errorMessages = [];
        return true;
      }

      if (this.checkDuplicateSelections) {
        this.errorMessages = ['同じ工事日時は選択出来ません'];
        return false;
      }

      if (!this.phoneNumber) {
        return false;
      }

      if (this.allFieldsSelected) {
        return true;
      }

      return false;
    },
  },

  watch: {
    allFieldsSelected(newValue) {
      this.selectedDayFlag = newValue;
    },
    selectedPhone: {
      handler(newValue) {
        if (newValue) {
          this.selectedDayFlag = false;
        }
      },
    },
  },

  /** 画面初期表示時の処理 */
  async mounted() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    //ログインチェック
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件情報取得
    this.property = this.$store.getters['propertyStore/property'];

    // UatypeがUCOMとfive.Aでない場合、Portas会員専用トップページに遷移
    if (this.property?.uaType !== UA_TYPE.FIVE_A && this.property?.uaType !== UA_TYPE.UCOM) {
      this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    //UatypeがUCOMかつUCOM連携済みでない場合、Portas会員専用トップページに遷移
    const member: Member = this.$store.getters['memberStore/member'];
    if (this.property?.uaType === UA_TYPE.UCOM && !member.primaryKeyUcom) {
      this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    // 初期表示 ストアから希望年月日を取得
    await this.setDataByStore();

    //初期表示 選べる日付けAPIから年月日を取得
    try {
      this.businessdayList = await this.getBusinessdayMaster();
    } catch {
      this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 選択された日付が存在する
    if (this.selectedBusinessDayForFirst && typeof this.selectedBusinessDayForFirst === 'string') {
      const selectedDateForFirst = new Date(this.selectedBusinessDayForFirst);

      // businessdayListから一致する日付を持つ要素を検索
      const matchingForFirst = this.businessdayList.find((day) => {
        const businessDate = new Date(day.businessDate);
        return businessDate.getTime() === selectedDateForFirst.getTime();
      });

      // 日時の完全一致を確認
      if (matchingForFirst) {
        this.selectedBusinessDayForFirst = matchingForFirst;
      }
    }

    if (this.selectedBusinessDayForSecond && typeof this.selectedBusinessDayForSecond === 'string') {
      const selectedDateForSecond = new Date(this.selectedBusinessDayForSecond);
      const matchingForSecond = this.businessdayList.find((day) => {
        const businessDateSecond = new Date(day.businessDate);
        return businessDateSecond.getTime() === selectedDateForSecond.getTime();
      });
      if (matchingForSecond) {
        this.selectedBusinessDayForSecond = matchingForSecond;
      }
    }

    if (this.selectedBusinessDayForThird && typeof this.selectedBusinessDayForThird === 'string') {
      const selectedDateForThird = new Date(this.selectedBusinessDayForThird);
      const matchingForThird = this.businessdayList.find((day) => {
        const businessDateThird = new Date(day.businessDate);
        return businessDateThird.getTime() === selectedDateForThird.getTime();
      });
      if (matchingForThird) {
        this.selectedBusinessDayForThird = matchingForThird;
      }
    }

    this.isLoading = false;
  },
  methods: {
    //ストアに保存されている年月日を取得
    async setDataByStore() {
      this.selectedBusinessDayForFirst = this.$store.getters['selectedBusinessDayStateStore/selectedBusinessDayForFirst'] || '';
      this.selectedBusinessDayForSecond = this.$store.getters['selectedBusinessDayStateStore/selectedBusinessDayForSecond'] || '';
      this.selectedBusinessDayForThird = this.$store.getters['selectedBusinessDayStateStore/selectedBusinessDayForThird'] || '';
      this.selectedTimeOfDayForFirst = this.$store.getters['selectedBusinessDayStateStore/selectedTimeOfDayForFirst'] || '0';
      this.selectedTimeOfDayForSecond = this.$store.getters['selectedBusinessDayStateStore/selectedTimeOfDayForSecond'] || '0';
      this.selectedTimeOfDayForThird = this.$store.getters['selectedBusinessDayStateStore/selectedTimeOfDayForThird'] || '0';
      this.selectedPhone = this.$store.getters['selectedBusinessDayStateStore/selectedPhone'];
      this.phoneNumber = this.$store.getters['selectedBusinessDayStateStore/phoneNumber'];

      if (!this.selectedPhone) {
        this.selectedPhone = 1;
      }
    },

    /** 営業日マスタから営業日を取得 */
    async getBusinessdayMaster(): Promise<BusinessdayMasterDTOArray> {
      return await SpfApiService.getBusinessdayList();
    },

    /** 営業日マスタから取得した年月日を＊年＊月＊日 */
    formatDateJapanese(date: Date): string {
      const d = new Date(date);
      return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
    },

    /**　次へボタン押下 */
    async onNext(): Promise<void> {
      // 電話番号のバリデーションチェックとエラーハンドリング
      this.errorMessages = [];

      // 開発者ツールなどで不正に非活性にされた際の対策
      if (!this.isButtonActive) {
        return;
      }

      try {
        if (!parsePhoneNumber(this.phoneNumber, 'JP')?.isValid()) {
          this.errorMessages = ['電話番号はハイフンなし半角数字で入力してください'];
          return;
        } else if (!/^0\d{9,10}$/.test(this.phoneNumber)) {
          this.errorMessages = ['電話番号はハイフンなし半角数字で入力してください'];
          return;
        }
      } catch {
        this.errorMessages = ['電話番号はハイフンなし半角数字で入力してください'];
        return;
      }

      // 希望年月日をStoreへ保存
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.$store.commit('selectedBusinessDayStateStore/phoneNumber', this.phoneNumber);
      this.$store.commit('selectedBusinessDayStateStore/selectedPhone', this.selectedPhone);

      if (this.selectedBusinessDayForFirst != '' && this.selectedTimeOfDayForFirst) {
        this.$store.commit('selectedBusinessDayStateStore/selectedBusinessDayForFirst', (this.selectedBusinessDayForFirst as BusinessdayMasterDTO)?.businessDate || null);
        this.$store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForFirst', this.selectedTimeOfDayForFirst || null);
      } else if (this.selectedBusinessDayForFirst == '' && this.selectedTimeOfDayForFirst) {
        this.$store.commit('selectedBusinessDayStateStore/selectedBusinessDayForFirst', null);
        this.$store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForFirst', null);
      }

      if (this.selectedBusinessDayForSecond != '' && this.selectedTimeOfDayForSecond) {
        this.$store.commit('selectedBusinessDayStateStore/selectedBusinessDayForSecond', (this.selectedBusinessDayForSecond as BusinessdayMasterDTO)?.businessDate || null);
        this.$store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForSecond', this.selectedTimeOfDayForSecond || null);
      } else if (this.selectedBusinessDayForSecond == '' && this.selectedTimeOfDayForSecond) {
        this.$store.commit('selectedBusinessDayStateStore/selectedBusinessDayForSecond', null);
        this.$store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForSecond', null);
      }

      if (this.selectedBusinessDayForThird != '' && this.selectedTimeOfDayForThird) {
        this.$store.commit('selectedBusinessDayStateStore/selectedBusinessDayForThird', (this.selectedBusinessDayForThird as BusinessdayMasterDTO)?.businessDate || null);
        this.$store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForThird', this.selectedTimeOfDayForThird || null);
      } else if (this.selectedBusinessDayForThird == '' && this.selectedTimeOfDayForThird) {
        this.$store.commit('selectedBusinessDayStateStore/selectedBusinessDayForThird', null);
        this.$store.commit('selectedBusinessDayStateStore/selectedTimeOfDayForThird', null);
      }

      // 確認画面へ遷移
      await this.$router.push('confirm').catch((error) => {
        checkRouterError(error);
      });

      this.isLoading = false;
    },
  },
});
</script>

<style scoped>
.btn-areaR {
  margin: 100px auto 0;
  text-align: center;
}

.preference {
  text-align: left;
  padding: 0 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.preferenceForPhone {
  padding: 0 20px;
  margin-top: 15px;
  margin-bottom: 0;
  text-align: left;
}
.preferenceForPhoneInput {
  color: #333333;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 15px;
  font-weight: bold;
}
.inputFormForPhone {
  padding: 0 20px;
  margin-bottom: 0;
  text-align: left;
  margin-top: 10px;
}

.table-type2 td {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  margin-bottom: 5px;
}

/* セレクトボックス　オプション */
.select option {
  background-color: white; /* オプションの背景色 */
  color: #333333;
  font-family: 'Noto Sans JP', sans-serif;
  border: none;

  position: absolute;
  overflow-y: auto;
  top: 50%;
}

.checkbox-text {
  margin-right: 10px;
}

.mailTypeRadio,
.mailCheckBox {
  accent-color: #007aff;
}

select {
  width: 100%;
  height: 38px;
  padding: 0 30px 0 10px;
  /* 初期化 */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  background: url(../../../images/arrow-bottom.png) no-repeat right 0px center / 40px auto !important;
  background-color: transparent;
  outline: none !important;
  border: solid 0.5px #939393;
}

.disabled-background {
  color: #939393 !important;
}

.phone-text {
  width: 180px;
  height: 28px;
}

.infomation-for-phone {
  color: #ef3333;
  font-family: 'Noto Sans JP', sans-serif;
}

.infomation-for-phone-need {
  color: #ef3333;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 15px;
  font-weight: bold;
}
.selectedPhoneOptions {
  padding: 0 20px;
}
</style>
