<template>
  <div class="during-registration-process">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>部屋番号入力</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A 部屋番号入力</li>
      </ul>
      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <p class="p-bold">お住まいのお部屋番号を入力してください。</p>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">部屋番号<span class="req">必須</span></th>
              <td>
                <input type="text" class="text short" placeholder="101" v-model="roomNumber" />
                <p class="room-unit">号室</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onNext">次へ進む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.application-flow {
  padding: 0;
}

.p-bold {
  font-weight: bold;
  margin-bottom: 30px;
}

.room-unit {
  display: inline;
  margin-left: 5px;
}

.btn-area {
  margin: 100px auto 0;
  text-align: center;
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { RemainingWorkConfirmDto } from '@/shared/classes/spf-api/remaining-work-confirm-dto';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { halfWidth } from '@/shared/util/validators';
export default defineComponent({
  name: 'fiveA-room-number-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    member: null as Member | null, // 会員情報
    property: null as Property | null, // 物件情報
    apartmentId: '', // 物件ID
    roomNumber: '', // 部屋番号
    isLoading: false, // ローディング
    remainingWorkResult: {} as RemainingWorkConfirmDto, // 残工事の有無
    isLogin: true, // ログイン状態
    apid: null as string | null, // apid
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
  }),
  /** バリデーション定義 */
  validations: {
    /** 部屋番号 */
    roomNumber: {
      required, //必須
      halfWidth, // 半角英数字
      maxLength: maxLength(8), // 8文字以内
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      // 会員情報を取得
      this.member = this.$store.getters['memberStore/member'];
      // 物件情報を取得
      this.property = this.$store.getters['propertyStore/property'];
      if (this.property && this.property.apartmentId) {
        this.apartmentId = this.property.apartmentId;
        // apartment_id → APID に変換
        this.apid = convert6DigitIdTo8DigitId(String(this.apartmentId));
      }
    } catch (e) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  methods: {
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorMessages = [];
      // 部屋番号バリデーションチェック
      if (this.v$.roomNumber?.$invalid) {
        // 必須チェック
        if (this.v$.roomNumber.required.$invalid) {
          this.errorMessages.push(`お部屋番号を入力してください。`);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        // 形式 / 桁数 チェック
        if (this.v$.roomNumber.halfWidth.$invalid || this.v$.roomNumber.maxLength.$invalid) {
          this.errorMessages.push(`お部屋番号を半角英数で入力してください。`);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      }
      try {
        // 残工事有無確認・受付ステータス更新 APIを実行する
        this.remainingWorkResult = await SpfApiService.remainingWorkConfirm(UA_TYPE.FIVE_A, this.apartmentId, this.roomNumber, this.isLogin);
        if (this.remainingWorkResult.isRemainingWork == null) {
          this.errorMessages.push('システムエラーが発生いたしました。お手数ではございますが、時間をおいて再度お試しください。');
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } catch {
        // 共通エラーに遷移
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      console.log(this.roomNumber);
      // apid をストアに保存
      this.$store.commit('remainingWorkStore/apid', this.apid);
      this.$store.commit('remainingWorkStore/RoomNumber', this.roomNumber);

      //画面遷移処理

      if (this.remainingWorkResult.isRemainingWork === '1') {
        await this.$router.push('/select-construction-date/input').catch((e: any) => {
          checkRouterError(e);
        });
      } else {
        // 残工事確認結果（残工事なし）画面へ
        await this.$router.push('/5a/remaining-work/not-left').catch((e: any) => {
          checkRouterError(e);
        });
      }
      this.isLoading = false;
    },
  },
});
</script>
