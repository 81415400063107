import { UcomAccountInfo } from '@/shared/classes/spf-api/mail/model/common/permission/ucom/ucom-account-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';

import store from '@/store';

/**
 * UCOM 会員種別決定サービス
 */
export class UcomPlanTypeService {
  /**
   * UCOMアカウントの会員種別取得
   * @returns 会員種別
   */
  public static getAccountPlanType(): SERVICE_PLAN_TYPE {
    const accountManagementInfo: UcomAccountInfo = store.getters['ucomAccountStore/ucomAccount'];

    switch (accountManagementInfo.accountInfo.accountAuthorization) {
      case UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER:
        return SERVICE_PLAN_TYPE.UC_CUSTOMER_NO;
      case UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT:
        return SERVICE_PLAN_TYPE.UC_ADMIN;
      case UCOM_ACCOUNT_AUTHORITY.NORMAL_ACCOUNT:
        return SERVICE_PLAN_TYPE.UC_USER;
      default:
        break;
    }

    return SERVICE_PLAN_TYPE.DEFAULT;
  }
}
