<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>アカウントID&emsp;登録・変更&emsp;確認</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>アカウントID 登録・変更 確認</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID登録変更" />アカウントID&emsp;登録・変更&emsp;確認</h2>
        <p>登録内容をご確認いただき、問題ない場合は「登録」ボタンを押してください。</p>
        <error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" :isOneSentence="isOneSentence" />
      </div>
      <!-- /blc -->

      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">氏名</th>
              <td>（姓）{{ familyName }}&emsp;（名）{{ firstName }}</td>
            </tr>
            <tr>
              <th class="va-middle">氏名カナ</th>
              <td>（姓カナ）{{ familyNameKana }}&emsp;（名カナ）{{ firstNameKana }}</td>
            </tr>
            <tr>
              <th class="va-middle">ユーザーネーム</th>
              <td>{{ userName }}</td>
            </tr>
            <tr>
              <th class="va-middle">パスワード</th>
              <td>{{ password }}</td>
            </tr>
            <tr>
              <th class="va-middle">アカウント権限種別</th>
              <td>{{ accountAuthorizationDisplay }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="onNext()">登録<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountManagementAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-management-accessor';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { UCOM_ACCOUNT_AUTHORITY, convertUcomAccountAuthorityValue } from '@/shared/const/ucom/ucom-account-authority';
import { UcomAccountManagementUpdateResponse } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-update-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'account-management-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
    errorMessages: new Array<string>(), // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isOneSentence: false, // エラータイトルのみ表示かどうか

    /* 各種パラメータ */
    accountName: '',
    familyName: '',
    firstName: '',
    familyNameKana: '',
    firstNameKana: '',
    userName: '',
    password: '',
    accountAuthorization: '',
    accountAuthorizationDisplay: '',
    ucomAccountId: '',
    isMailAddressRegistration: false,
    loginUserAuthority: '',
    before: '',
    isUpdate: '',
  }),
  async mounted() {
    try {
      this.isLoading = true;

      // 前画面(アカウントID管理：登録・変更 入力)からの情報をStoreから取得
      this.setDataByStore();
      this.before = this.$store.getters['ucomAccountManagementInputStore/before'];

      //ログインしているアカウントの権限
      this.loginUserAuthority = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountAuthorization;

      // 戻るボタン押下時にstoreのaccountNameに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);

      // 完了画面からブラウザバックで戻ってきた場合
      if (!this.isAuthorization()) {
        // ストアを削除
        this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);
        this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
        await this.$router.push('/ucom/account-management/list').catch((error) => {
          checkRouterError(error);
        });

        return;
      }

      // アカウント権限種別を画面表示用に加工
      this.setAccountAuthorizationDisplay(this.accountAuthorization);

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });

      return;
    }
  },
  methods: {
    // storeからデータをセット
    setDataByStore(): void {
      this.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.familyName = this.$store.getters['ucomAccountManagementInputStore/familyName'];
      this.firstName = this.$store.getters['ucomAccountManagementInputStore/firstName'];
      this.familyNameKana = this.$store.getters['ucomAccountManagementInputStore/familyNameKana'];
      this.firstNameKana = this.$store.getters['ucomAccountManagementInputStore/firstNameKana'];
      this.userName = this.$store.getters['ucomAccountManagementInputStore/userName'];
      this.password = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.accountAuthorization = this.$store.getters['ucomAccountManagementInputStore/accountAuthorization'];
      this.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      this.isUpdate = this.$store.getters['ucomAccountManagementInputStore/isUpdate'];
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.$store.commit('ucomAccountManagementInputStore/accountName', this.accountName);
      this.isLoading = true;

      this.saveStore();

      // アカウントID管理：登録・変更 入力画面へ遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/input').catch((error: any) => {
        checkRouterError(error);
      });

      this.isLoading = false;
    },
    // 登録ボタンクリック
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ初期化
      this.errorMessages = [];

      try {
        // 更新処理
        if (this.ucomAccountId) {
          await this.updateAccount();
        }

        // 登録処理
        if (!this.ucomAccountId) {
          await this.registAccount();
        }
      } catch (error) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });

        return;
      }

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      // アカウントID 登録・変更情報をstoreに保存
      this.saveStore();

      // 完了画面へ遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/completed').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // storeへ保存
    saveStore(): void {
      this.$store.commit('ucomAccountManagementInputStore/accountName', this.accountName);
      this.$store.commit('ucomAccountManagementInputStore/familyName', this.familyName);
      this.$store.commit('ucomAccountManagementInputStore/firstName', this.firstName);
      this.$store.commit('ucomAccountManagementInputStore/familyNameKana', this.familyNameKana);
      this.$store.commit('ucomAccountManagementInputStore/firstNameKana', this.firstNameKana);
      this.$store.commit('ucomAccountManagementInputStore/userName', this.userName);
      this.$store.commit('ucomAccountManagementInputStore/password', ObfuscationService.encode(this.password));
      this.$store.commit('ucomAccountManagementInputStore/accountAuthorization', this.accountAuthorization);
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', this.ucomAccountId);
      this.$store.commit('ucomAccountManagementInputStore/before', this.before);
      this.$store.commit('ucomAccountManagementInputStore/isUpdate', this.isUpdate);

      if (this.isMailAddressRegistration) {
        this.$store.commit('ucomAccountManagementInputStore/isMailAddressRegistration', this.isMailAddressRegistration);
      }
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
    // アカウント権限種別を画面表示用文字列に加工
    setAccountAuthorizationDisplay(accountAuthorization: string): void {
      this.accountAuthorizationDisplay = convertUcomAccountAuthorityValue(Number(accountAuthorization)).value;
    },
    // 登録処理
    async registAccount(): Promise<void> {
      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];

      try {
        // アカウントID管理：登録・変更_確認_登録API実行
        const accountRegistResponse = await SpfApiAccountManagementAccessor.registerAccount(
          member.id,
          this.accountName,
          this.familyName,
          this.firstName,
          this.familyNameKana,
          this.firstNameKana,
          this.userName,
          ObfuscationService.encode(this.password),
          String(this.accountAuthorization)
        );

        // エラーメッセージがあった場合
        if (accountRegistResponse.errorMessages.length > 0) {
          this.errorMessages = accountRegistResponse.errorMessages;
          return;
        }

        // 次の画面に渡すためのデータをレスポンスから設定
        this.ucomAccountId = accountRegistResponse.ucomId.toString();
        this.accountName = accountRegistResponse.accountName;
        this.familyName = accountRegistResponse.familyName;
        this.firstName = accountRegistResponse.firstName;
        this.familyNameKana = accountRegistResponse.familyNameKana;
        this.firstNameKana = accountRegistResponse.firstNameKana;
        this.userName = accountRegistResponse.userName;
        this.password = ObfuscationService.decode(accountRegistResponse.password);
        this.accountAuthorization = accountRegistResponse.accountAuthorizationDisplay;
      } catch (error) {
        throw error;
      }
    },
    // 更新処理
    async updateAccount(): Promise<void> {
      try {
        const accountUpdateResponse: UcomAccountManagementUpdateResponse = await this.callUpdateAccountAPI();

        // エラーメッセージがあった場合
        if (accountUpdateResponse.errorMessages.length > 0) {
          this.errorMessages = accountUpdateResponse.errorMessages;
          return;
        }

        // 次の画面に渡すためのデータをレスポンスから設定
        this.ucomAccountId = accountUpdateResponse.ucomId.toString();
        this.accountName = accountUpdateResponse.accountName;
        this.familyName = accountUpdateResponse.familyName;
        this.firstName = accountUpdateResponse.firstName;
        this.familyNameKana = accountUpdateResponse.familyNameKana;
        this.firstNameKana = accountUpdateResponse.firstNameKana;
        this.userName = accountUpdateResponse.userName;
        this.password = ObfuscationService.decode(accountUpdateResponse.password);
        this.accountAuthorization = accountUpdateResponse.accountAuthorizationDisplay;
        this.isMailAddressRegistration = accountUpdateResponse.isMailAddressRegistration;
      } catch (error) {
        throw error;
      }
    },
    async callUpdateAccountAPI() {
      if (this.loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.NORMAL_ACCOUNT.toString()) {
        return await SpfApiAccountManagementAccessor.updateUserAccount(
          Number(this.ucomAccountId),
          this.accountName,
          this.familyName,
          this.firstName,
          this.familyNameKana,
          this.firstNameKana,
          this.userName,
          ObfuscationService.encode(this.password)
        );
      }
      return await SpfApiAccountManagementAccessor.updateAccount(
        Number(this.ucomAccountId),
        this.accountName,
        this.familyName,
        this.firstName,
        this.familyNameKana,
        this.firstNameKana,
        this.userName,
        ObfuscationService.encode(this.password),
        String(this.accountAuthorization)
      );
    },
    isAuthorization(): boolean {
      if (this.accountAuthorization === UCOM_ACCOUNT_AUTHORITY.NORMAL_ACCOUNT.toString()) {
        return true;
      }
      if (this.accountAuthorization === UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT.toString()) {
        return true;
      }
      if (this.accountAuthorization === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER.toString()) {
        return true;
      }
      return false;
    },
  },
});
</script>

<style scoped></style>
