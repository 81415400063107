import { Module } from 'vuex';
import { RootState } from '@/store';
import { UcomVirusCheckPurchaseState } from '@/shared/classes/ucom/ucom-virus-check-purchase-state';
import { UcomVirusCheckPauseState } from '@/shared/classes/ucom/ucom-virus-check-pause-state';

/** UCOM ウイルスチェック State */
const ucomVirusCheckState: {
  purchaseState?: UcomVirusCheckPurchaseState;
  pauseState?: UcomVirusCheckPauseState;
  notifyMailAddress?: string;
} = {
  purchaseState: undefined,
  pauseState: undefined,
  notifyMailAddress: undefined,
};

/** State の型 */
type UcomVirusCheckState = typeof ucomVirusCheckState;

/** UCOM ウイルスチェック Store */
export const ucomVirusCheckStore: Module<UcomVirusCheckState, RootState> = {
  namespaced: true,
  state: ucomVirusCheckState,

  actions: {},

  mutations: {
    setPurchaseState: (state, purchaseState: UcomVirusCheckPurchaseState) => {
      state.purchaseState = purchaseState;
    },
    setPauseState: (state, pauseState: UcomVirusCheckPauseState) => {
      state.pauseState = pauseState;
    },
    setNotifyMailAddress: (state, notifyMailAddress: string) => {
      state.notifyMailAddress = notifyMailAddress;
    },
  },

  getters: {
    purchaseState: (state): UcomVirusCheckPurchaseState | undefined => state.purchaseState,
    pauseState: (state): UcomVirusCheckPauseState | undefined => state.pauseState,
    notifyMailAddress: (state): string | undefined => state.notifyMailAddress,
  },
};
