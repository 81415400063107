<template>
  <div>
    <label v-for="(option, index) in options" :key="index">
      <input
        :disabled="!canUpdate || isDisabled"
        :v-show="canRead"
        type="radio"
        :value="option.value"
        :name="name"
        :checked="option.value === value"
        @change="updateRadio(option.value)"
      />
      {{ option.label }}</label
    >
  </div>
</template>

<script lang="ts">
import { PermissionControlService } from '@/shared/services/permission/permission-control-service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'permission-controled-input-radio',
  props: {
    itemId: {
      type: String,
      default: '',
      required: true,
    },
    screenId: { type: String, default: '', required: true },
    functionId: {
      type: String,
      default: '',
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data: () => ({
    canRead: true,
    canUpdate: true,
  }),
  async mounted(): Promise<void> {
    if (this.isDisabled) {
      this.$data.canRead = true;
      this.$data.canUpdate = false;
      return;
    }
    this.$data.canRead = await PermissionControlService.isItemReadable(this.functionId, this.screenId, this.itemId);
    this.$data.canUpdate = await PermissionControlService.isItemUpdatable(this.functionId, this.screenId, this.itemId);
  },
  methods: {
    updateRadio(value: string) {
      this.$emit('update:modelValue', value);
    },
  },
});
</script>

