import { XMAIL_REGISTER_STATUS } from '@/shared/const/e-mansion/e-mansion-xmail-register-status';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';
import { UCOM_REGISTER_STATUS } from '@/shared/const/ucom/ucom-register-status';

export class UcomMailAddress {
  readonly _ucomAccountMailId!: number;
  readonly _ucomAccountId!: number;
  readonly _xmailUserId?: string | undefined;
  readonly _xmailAuthId?: string | undefined;
  readonly _ucomRegisterSatus!: UCOM_REGISTER_STATUS;
  readonly _xmailRegisterStatus!: XMAIL_REGISTER_STATUS;
  readonly _mailAddress!: string;
  readonly _mailAddressType!: UCOM_MAIL_ADDRESS_TYPE;
  readonly _isOldDomain!: boolean;
  readonly _cancelSubscriptionOn?: Date | undefined;
  readonly _cancelOn?: Date | undefined;
  readonly _cancelProcessOn?: Date | undefined;
  readonly _deleteOn?: Date | undefined;
  readonly _isDelete!: boolean;

  constructor(partial: Partial<UcomMailAddress>) {
    Object.assign(this, partial);
  }

  get ucomAccountMailId(): number {
    return this._ucomAccountMailId;
  }

  get ucomAccountId(): number {
    return this._ucomAccountMailId;
  }

  get xmailUserId(): string | undefined {
    return this._xmailUserId;
  }

  get xmailAuthId(): string | undefined {
    return this._xmailAuthId;
  }

  get ucomRegisterSatus(): UCOM_REGISTER_STATUS {
    return this._ucomRegisterSatus;
  }

  get xmailRegisterStatus(): XMAIL_REGISTER_STATUS {
    return this._xmailRegisterStatus;
  }

  get mailAddress(): string {
    return this._mailAddress;
  }

  get mailAddressType(): UCOM_MAIL_ADDRESS_TYPE {
    return this._mailAddressType;
  }

  get isOldDomain(): boolean {
    return this._isOldDomain;
  }

  get cancelSubscriptionOn(): Date | undefined {
    return this._cancelSubscriptionOn;
  }

  get cancelOn(): Date | undefined {
    return this._cancelOn;
  }

  get cancelProcessOn(): Date | undefined {
    return this._cancelProcessOn;
  }

  get deleteOn(): Date | undefined {
    return this._deleteOn;
  }

  get isDelete(): boolean {
    return this._isDelete;
  }
}
