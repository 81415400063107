import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["disabled", "v-show", "value", "name", "checked", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("label", { key: index }, [
        _createElementVNode("input", {
          disabled: !_ctx.canUpdate || _ctx.isDisabled,
          "v-show": _ctx.canRead,
          type: "radio",
          value: option.value,
          name: _ctx.name,
          checked: option.value === _ctx.value,
          onChange: ($event: any) => (_ctx.updateRadio(option.value))
        }, null, 40, _hoisted_1),
        _createTextVNode(" " + _toDisplayString(option.label), 1)
      ]))
    }), 128))
  ]))
}