import { Module } from 'vuex';
import { RootState } from '../index';

import { FiveAConnectixEntryInputForm, FiveAConnectixPropertyRoom } from '../../pages/connectix/five-a/classes/entry-input-form';
import { FiveAConnectixPropertyRoomsResponse } from '@/pages/connectix/five-a/classes/external-api/property-rooms-response';
import { FiveAConnectixErrorResponse } from '@/pages/connectix/five-a/classes/external-api/connectix-error-response';
import { FiveAConnectixExternalApiService } from '@/shared/services/external-api/connectix/five-a/five-a-connectix-external-api-service';
import { FiveAConnectixRoomsRequest } from '@/shared/classes/external-api/five-a/connectix-room-request';

/** five-a Connectix お申し込み State */
const fiveAConnectixEntryState: {
  entryInputForm: FiveAConnectixEntryInputForm | null;
  propertyRooms: FiveAConnectixPropertyRoom | null;
} = {
  /** five-a Connectix お申し込み 入力フォーム */
  entryInputForm: null,
  propertyRooms: null
};

/** State の型 */
type FiveAConnectixEntryState = typeof fiveAConnectixEntryState;

/** five-a Connectix お申し込み Store */
export const fiveAConnectixEntryStore: Module<FiveAConnectixEntryState, RootState> = {
  namespaced: true,
  state: fiveAConnectixEntryState,
  actions: {
    /**
     * five-a 部屋番号一覧取得 action
     * @param context
     * @param propertyId string 物件 ID
     * @return FiveAConnectixPropertyRoomsResponse 部屋番号一覧
     * @return FiveAConnectixErrorResponse 共通エラークラス
     */
    async propertyRooms(context, params: { propertyId: string; fiveAConnectixRoomsRequest: FiveAConnectixRoomsRequest }) {
      // API コールする
      const findPropertyRoomsResult: FiveAConnectixPropertyRoomsResponse | FiveAConnectixErrorResponse = await FiveAConnectixExternalApiService.findPropertyRooms(
        params.propertyId,
        params.fiveAConnectixRoomsRequest
      );
      // 値をコミットする
      if (findPropertyRoomsResult instanceof FiveAConnectixPropertyRoomsResponse) context.commit('propertyRooms', findPropertyRoomsResult);
      // Getter から取り直して返す
      const afterPropertyRoomsValue = context.getters['propertyRooms'];
      return afterPropertyRoomsValue;
    }
  },
  mutations: {
    entryInputForm: (state, value: FiveAConnectixEntryInputForm) => (state.entryInputForm = value),
    propertyRooms: (state, value: FiveAConnectixPropertyRoom) => (state.propertyRooms = value)
  },
  getters: {
    entryInputForm: (state): FiveAConnectixEntryInputForm | null => state.entryInputForm,
    propertyRooms: (state): FiveAConnectixPropertyRoom | null => state.propertyRooms
  }
};
