import { Module } from 'vuex';
import { RootState } from './index';

/** 支払方法 State */
const paymentMethodState: {
  editRequestedPaymentMethodNo: number | null;
  deleteRequestedPaymentMethodNo: number | null;
} = {
  editRequestedPaymentMethodNo: null,
  deleteRequestedPaymentMethodNo: null,
};

/** State の型 */
type PaymentMethodState = typeof paymentMethodState;

/** 支払方法 Store */
export const paymentMethodStore: Module<PaymentMethodState, RootState> = {
  namespaced: true,
  state: paymentMethodState,
  mutations: {
    editRequestedPaymentMethodNo: (state, value: number) => (state.editRequestedPaymentMethodNo = value),
    deleteRequestedPaymentMethodNo: (state, value: number) => (state.deleteRequestedPaymentMethodNo = value),
  },
  getters: {
    editRequestedPaymentMethodNo: (state): number | null => state.editRequestedPaymentMethodNo,
    deleteRequestedPaymentMethodNo: (state): number | null => state.deleteRequestedPaymentMethodNo,
  },
};
