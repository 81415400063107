<template>
  <div>
    <LoadingComponent v-if="isSubmitting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールアドレス登録／変更</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>メールアドレス登録／変更</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールアドレス登録／変更 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="メールアドレス登録／変更" />メールアドレス登録／変更</h2>
        <p>メールアドレスを入力し、「次へ進む」ボタンを押してください。</p>
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <div class="mail-address-type-title">
          <label>
            <b><input type="radio" class="mailTypeRadio" name="mailAddressType" value="1" v-model="inputData.mailAddressType" />UCOM光の提供するメールアドレスを取得する</b>
          </label>
        </div>
        <table class="table-type2">
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">アカウントID</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ accountName }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">現在のメールアドレス</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ currentMailAddress }}</span>
            </td>
          </tr>
        </table>
        <table class="table-type2">
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">メールアドレス</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <input type="text" class="text short" v-model="inputData.mailAddressUcom" />
              @{{ domainName }}
              <p>※半角英小文字、半角数字および記号(-_.)がご利用いただけます。</p>
              <p>※3文字以上15文字以内で入力してください。</p>
              <p>※記号の連続使用、先頭と末尾での使用はできません。</p>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p class="caution-title"><b>■サービスについて</b></p>
              <p>UCOM光の提供するメールアドレスでは、1GBのメールボックスをご利用いただくことができ<br />有料で10GBに容量を増設することができます。</p>
              <p>また、Webスペース/メール転送サービス/ウイルスチェックサービスをご利用いただくことができます。</p>
              <ul>
                <li>お申し込み後のキャンセルは行えません。</li>
                <li>お申し込み後、すぐにご利用いただけます。</li>
                <li>利用料金は購入翌月から発生いたします。</li>
                <li>
                  メールアドレスはアカウントIDに付随し、単体での解約手続きは行えません。<br />
                  アカウントIDを残したままメールアドレスを解約されたい場合は、<br />
                  「他社のメールアドレスを利用する」設定にご変更ください。
                </li>
                <li>メールアドレスの変更に費用は発生しませんが、同月内に、取得・解約・取得を繰り返された場合でも<br />メールサービス購入毎の費用が発生いたします。</li>
                <li>当月末日までにマイページにて解約されたものに対し、当月での解約を行います。</li>
                <li>解約時は、解約月の当月分の料金が発生いたします。</li>
                <li>利用開始および解約時の日割り計算は行いません。</li>
              </ul>
              <p>UCOM 光 レジデンス ISP 会員付加サービス利用規約については<a :href="termsUrl" target="_blank" class="link">こちら</a>からご確認ください。</p>
              <p><font color="blue">▶</font><a :href="`${ucomUrl}`" class="url" target="UCOM">メールアドレス変更時の注意事項</a></p>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項への同意<span class="required">必須</span></th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p>お申し込みの前に注意事項を必ずお読みになり、「上記の内容に同意する」にチェックしてください。</p>
              <label><input type="checkbox" class="mailCheckBox" v-model="isAgreeUcom" /> 上記の内容に同意する</label>
            </td>
          </tr>
        </table>
        <div class="mail-address-type-title">
          <label>
            <b><input type="radio" class="mailTypeRadio" name="mailAddressType" value="2" v-model="inputData.mailAddressType" />他社のメールを利用する</b>
          </label>
        </div>
        <table class="table-type2">
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">メールアドレス</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <input type="text" class="text middle" v-model="inputData.mailAddressOther" />
              <p>※128文字以内で入力してください。</p>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p class="caution-title"><b>■サービスについて</b></p>
              <p>
                セキュリティオプション等、アカウントIDへのメールアドレスの設定が必要で、<br />
                UCOM光のメールアドレスが不要なお客様については、既にご利用頂いているフリーメールや携帯電話のメールアドレス、他社のメールアドレスを設定いただくことができます。
              </p>
              <p>受信の文字数に制限があるアドレスの場合、必要情報が確認できない場合がありますのでご注意ください。</p>
              <p class="margin-vertical-12"><b>■メールアドレス変更時の注意事項</b></p>
              <ul>
                <li>UCOM光メールアドレスから他社のメールアドレスへ変更された場合、今まで登録されていたメール、Webスペースのご利用ができなくなります。</li>
                <li>その他注意事項は、「UCOM光の提供するメールアドレスを取得する」の「■メールアドレス変更時の注意事項」もあわせてご覧ください。</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項への同意<span class="required">必須</span></th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p>お申し込みの前に注意事項を必ずお読みになり、「上記の内容に同意する」にチェックしてください。</p>
              <label><input type="checkbox" class="mailCheckBox" v-model="isAgreeOther" /> 上記の内容に同意する</label>
            </td>
          </tr>
        </table>
      </div>
      <!-- /blc:メールアドレス登録／変更 -->

      <!-- sblc -->
      <div class="sblc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onNext" :class="isButtonActive ? 'btn btn01 bs' : 'btn btn04 bs'" :disabled="!isButtonActive">
            次へ進む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /sblc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
/* Required
---------------------------------------------------- */
.required {
  padding: 0 4px;
  margin-left: 20px;
  border-radius: 3px;
  background: #e42727;
  color: #ffffff;
  font-size: 10px;
}

.mail-address-type-title {
  position: relative;
  margin: 60px 0 20px;
  font-size: 16px;
}

.caution-title {
  margin-bottom: 12px;
}

.margin-vertical-12 {
  margin: 12px 0;
}
.mailTypeRadio,
.mailCheckBox {
  accent-color: #007aff;
}
.url {
  text-decoration: underline;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { SpfApiAccountOptionChangeAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-option-change-accessor';
import { SpfApiMailAddressAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-address-accessor';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UCOM_PROPERTY_SERVICE_PROVID_FORMER } from '@/shared/const/ucom/ucom-property-service-provid-former';

export default defineComponent({
  name: 'ucom-mail-address-register-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** UCOM.上記の内容に同意する */
    isAgreeUcom: false,
    /** 他社.上記の内容に同意する */
    isAgreeOther: false,
    /** ボタンダブルクリック防止用 */
    isSubmitting: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    termsUrl: '',
    ucomAccountId: '',
    accountName: '',
    currentMailAddress: '',
    mailAddress: '',
    domainName: '',
    mailAddressType: {
      UCOM: '1',
      OTHER: '2',
    },
    /** 登録変更フラグ */
    isChange: '',
    /** 画面入力値用 */
    inputData: {
      mailAddressType: '',
      mailAddressUcom: '',
      mailAddressOther: '',
    },
    /** 次画面へ渡すデータ */
    confirmData: {
      displayMailAddressType: '',
      mailAddress: '',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_UPDATE_MAIL_CAUTION_URL,
    /** 次画面で表示用のメールアドレス種別(UCOM) */
    displayMailAddressUcom: 'UCOM光の提供するメールアドレスを利用する',
    /** 次画面で表示用のメールアドレス種別(他社) */
    displayMailAddressOther: '他社のメールアドレスを利用する',
    checkResult: {
      VALID: '0',
      INVALID: '1',
    },
    errorMessage: {
      AGREE: '注意事項を確認し、同意してください',
      MU00_0002: '「メールアドレス」を入力してください。',
    },
    FROM_LINK: {
      MANAGEMENT_LIST: '/ucom/account-management/list',
      MANAGEMENT_COMPLETED: '/ucom/account-management/completed',
      OPTION_CHANGE_LIST: '/ucom/account-option-change/account-list',
      OPTION_CHANGE_COMFIRM: '/ucom/account-option-change/mail-confirm',
      DELETE_CONFIRMATION: '/ucom/account-management/delete-confirmation',
    },
    // 入力値チェック用の配列
    inputErrorMessages: new Array<string>()
  }),
  computed: {
    isButtonActive() {
      if (
        (this.inputData.mailAddressType == this.mailAddressType.UCOM && this.isAgreeUcom) ||
        (this.inputData.mailAddressType == this.mailAddressType.OTHER && this.isAgreeOther)
      ) {
        return true;
      }

      return false;
    },
  },
  async mounted(): Promise<void> {
    this.isSubmitting = true;
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      // エラー画面に遷移
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // Storeから情報を取得する
    const from = this.$store.getters['ucomAccountOptionStore/beforePath'];
    if (from === this.FROM_LINK.MANAGEMENT_LIST) {
      this.accountName = this.$store.getters['ucomAccountManagementStore/accountName'];
      this.ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
      this.currentMailAddress = this.$store.getters['ucomAccountManagementStore/currentMailAddress'];
      this.mailAddress = this.$store.getters['ucomAccountManagementStore/mailAddress'];
      this.inputData.mailAddressType = this.$store.getters['ucomAccountManagementStore/mailAddressType'];
      const isUpdate: boolean = this.$store.getters['ucomAccountManagementStore/isUpdate'];
      if (isUpdate === true) {
        this.isChange = UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE;
      }
      if (isUpdate === false) {
        this.isChange = UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT;
      }
    }
    if (from === this.FROM_LINK.OPTION_CHANGE_LIST || from === this.FROM_LINK.OPTION_CHANGE_COMFIRM || from === this.FROM_LINK.DELETE_CONFIRMATION) {
      this.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
      this.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
      this.currentMailAddress = this.$store.getters['ucomAccountOptionStore/currentMailAddress'];
      this.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
      this.inputData.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
      this.isChange = this.$store.getters['ucomAccountOptionStore/isChange'];
    }

    if (from === this.FROM_LINK.MANAGEMENT_COMPLETED) {
      this.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      this.currentMailAddress = this.$store.getters['ucomAccountManagementInputStore/currentMailAddress'];
      this.mailAddress = this.$store.getters['ucomAccountManagementInputStore/mailAddress'];
      this.isChange = this.$store.getters['ucomAccountManagementInputStore/isChange'];
    }

    if (!this.inputData.mailAddressType) {
      this.inputData.mailAddressType = this.mailAddressType.UCOM;
    }

    // 存在しない場合APIでエラーが発生し無限ロードが発生するため回避するための分岐
    if (!this.ucomAccountId) {
      await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 確認画面から戻った際に入力した値を表示するようにする、同意部分のみ意図的にチェックを外している
    if (this.mailAddress) {
      if (this.inputData.mailAddressType === this.mailAddressType.UCOM) {
        if (this.mailAddress.split('@').length === 2) {
          this.inputData.mailAddressUcom = this.mailAddress.split('@')[0];
        }
      }
      if (this.inputData.mailAddressType === this.mailAddressType.OTHER) {
        this.inputData.mailAddressOther = this.mailAddress;
      }
    }
    // ドメイン取得(メールアドレス登録_初期表示API)
    try {
      const getUcomDomainResult = await SpfApiAccountOptionChangeAccessor.getUcomDomain(Number(this.ucomAccountId));
      this.domainName = getUcomDomainResult.domainName;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
    }
    //規約URLセット
    this.termsUrl = this.setTermsUrl();
    this.isSubmitting = false;
  },
  methods: {
    /**
     * サービス規約のURLをセット
     */
    setTermsUrl(): string {
      //物件基本情報
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty?.service_provid_former.service_provid_former_id == UCOM_PROPERTY_SERVICE_PROVID_FORMER.TNC.id) {
        return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_TNC;
      }
      return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_ARTERIA;
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) return;

      // ボタン押下中とする
      this.isSubmitting = true;

      try {
        // エラーメッセージを保存していた配列の初期化
        this.errorMessages = [];
        this.inputErrorMessages = [];

        // 入力値チェック
        if (this.inputData.mailAddressType === this.mailAddressType.UCOM) {
          this.inputErrorMessages = await this.inputCheckUcom(this.inputData.mailAddressType, this.inputData.mailAddressUcom, this.isAgreeUcom);
        }
        if (this.inputData.mailAddressType === this.mailAddressType.OTHER) {
          this.inputErrorMessages = await this.inputCheckOther(this.inputData.mailAddressType, this.inputData.mailAddressOther, this.isAgreeOther);
        }
        this.inputErrorMessages.map((inputErrorMessage: string) => this.errorMessages.push(inputErrorMessage));
        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          this.showErrorMessage();
          return;
        }

        if (this.inputData.mailAddressType == this.mailAddressType.UCOM) {
          this.confirmData.displayMailAddressType = this.displayMailAddressUcom;
          this.confirmData.mailAddress = `${this.inputData.mailAddressUcom}@${this.domainName}`;
        }
        if (this.inputData.mailAddressType == this.mailAddressType.OTHER) {
          this.confirmData.displayMailAddressType = this.displayMailAddressOther;
          this.confirmData.mailAddress = this.inputData.mailAddressOther;
        }
        // 画面入力値をStoreに保存する
        this.$store.commit('ucomAccountOptionStore/accountName', this.accountName);
        this.$store.commit('ucomAccountOptionStore/ucomAccountId', this.ucomAccountId);
        this.$store.commit('ucomAccountOptionStore/displayMailAddressType', this.confirmData.displayMailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddressType', this.inputData.mailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddress', this.confirmData.mailAddress);
        this.$store.commit('ucomAccountOptionStore/isChange', this.isChange);
        this.$store.commit('ucomAccountOptionStore/currentMailAddress', this.currentMailAddress);
        // 確認画面に遷移
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-option-change/mail-confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },
    async inputCheckUcom(mailAddressType: string, mailAddress: string, isAgree: boolean): Promise<string[]> {
      const errorMessages: string[] = [];

      if (!isAgree) {
        errorMessages.push(this.errorMessage.AGREE);
      }
      const checkMailAddress = `${mailAddress}@${this.domainName}`;
      const checkMailResult = await SpfApiMailAddressAccessor.checkMailAddress(checkMailAddress, mailAddressType);
      if (checkMailResult.checkResult === this.checkResult.INVALID) {
        checkMailResult.checkMessages.map((errorMessage) => errorMessages.push(errorMessage));
      }
      return errorMessages;
    },
    async inputCheckOther(mailAddressType: string, mailAddress: string, isAgree: boolean): Promise<string[]> {
      const errorMessages: string[] = [];

      if (!mailAddress) {
        errorMessages.push(this.errorMessage.MU00_0002);
        return errorMessages;
      }
      if (!isAgree) {
        errorMessages.push(this.errorMessage.AGREE);
      }
      const checkMailResult = await SpfApiMailAddressAccessor.checkMailAddress(mailAddress, mailAddressType);
      if (checkMailResult.checkResult === this.checkResult.INVALID) {
        checkMailResult.checkMessages.map((errorMessage) => errorMessages.push(errorMessage));
      }
      return errorMessages;
    },
  },
});
</script>
