import { Module } from 'vuex';
import { RootState } from '../index';


/** e-mansion メインメール State */
const eMansionMainMailAddressState: {
    mailAddress: string | null,//現在のメールアドレス
    newMailAddress: string | null,//新しいメールアドレス
    cancelOn: Date | null, //サービス終了日
    newMailAddressAddDomain: string | null,//新しいメールアドレス(ドメイン付き)
} = {
    mailAddress: null,
    newMailAddress: null,
    cancelOn: null,
    newMailAddressAddDomain: null,
};

/** State の型 */
type EMansionMainMailAddressState = typeof eMansionMainMailAddressState;

/** e-mansion メインメール Store */
export const eMansionMainMailAddressStore: Module<EMansionMainMailAddressState, RootState> = {

    namespaced: true,
    state: eMansionMainMailAddressState,
    actions: {
    },
    mutations: {
        mailAddress: (state, value: string) => (state.mailAddress = value),
        newMailAddress: (state, value: string) => (state.newMailAddress = value),
        newMailAddressAddDomain: (state, value: string) => (state.newMailAddressAddDomain = value),
        cancelOn: (state, value: Date) => (state.cancelOn = value),
    },
    getters: {
        mailAddress: (state): string | null => state.mailAddress,
        newMailAddress: (state): string | null => state.newMailAddress,
        newMailAddressAddDomain: (state): string | null => state.newMailAddressAddDomain,
        cancelOn: (state): Date | null => state.cancelOn,
    }
};
