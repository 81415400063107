<template>
  <div class="ucom-entry-guide">
    <!-- underlayer-main -->
		<main class="underlayer-main">
			<h1>UCOM光 レジデンス 会員登録手順</h1>
		</main>
		<!-- /underlayer-main -->

		<!-- contents -->
		<div class="contents">

			<!-- breadcrumb -->
			<ul class="breadcrumb">
				<li><router-link to="/">トップページ</router-link></li>
				<li>UCOM光 レジデンス 会員登録手順</li>
			</ul>
			<!-- /breadcrumb -->

			<!-- blc:UCOM光 レジデンス 会員登録手順 -->
			<div class="blc gray-container">
				<p class="mb30">UCOM光レジデンスの会員登録方法は、2023年8月より、登録手順が変更となりました。<br/>各種ご登録方法については、以下をご参照ください。
					<ul class="register-list">
						<li>①<b>「UCOM光 レジデンス」会員登録方法</b>は<a :href="ucomRegistGuideUrl" class="link" target="_blank">こちら</a>　（「Portas」登録と「UCOM光 レジデンス」会員登録の両方が必要です）</li>
						<li>②「UCOM光 レジデンス」会員登録済みで、<b>「Portas」のご登録方法</b>は<a :href="ucomLinkingGuideUrl" class="link" target="_blank">こちら</a>　※</li>
					</ul>
					※「UCOM光 レジデンス」マイページは、「Portas」内へ移行いたしました。 そのため、「UCOM光レジデンス」会員登録済みのお客様もメールサービス等の各種オプションサービスのお申込み・変更手続きには、Portasへのご登録・ログインが必要となりました。
				</p>
				<div class="sblc">
					<div class="btn-area">
						<button v-on:click="onRegist()" class="btn btn01 bs">Portasの登録はこちら<i class="material-icons link link-icon">east</i></button>
					</div>
				</div>
			</div>
			<!-- /blc -->
		</div>
		<!-- /contents -->
  </div>
</template>

<style>
.indent {
  text-indent: 1em;
}
.gray-container {
  padding: 32px 16px;
  background-color: #efefef;
}
.register-list {
  background-color: #fff;
  padding: 16px;
}
.register-list > li {
  list-style: none;
}
</style>

<script lang="ts">
import { AuthService } from '@/shared/services/auth/auth-service';
import { defineComponent } from 'vue';
import { _RouteLocationBase } from 'vue-router';

type DataType = {
  query: {
    apid?: string;
    from?: string;
  } | null;
  platformUrl: string;
  ucomRegistGuideUrl: string;
  ucomLinkingGuideUrl: string;
  isLoading: boolean;
};

export default defineComponent({
  name: 'register-ucom',
  data(): DataType {
    return {
      query: null,
      platformUrl: process.env.VUE_APP_BASE_URL,
      ucomRegistGuideUrl: process.env.VUE_APP_PORTAS_REGIST_GUIDE_URL,
      ucomLinkingGuideUrl: process.env.VUE_APP_UCOM_LINK_GUIDE_URL,
      isLoading: false,
    };
  },
  mounted() {
    const query = this.$route.query;
    if (typeof query.from === 'string' && String(query.from) === 'ucom-r') {
      this.query = typeof query.apid === 'string' ? { apid: String(query.apid), from: String(query.from) } : { from: String(query.from) };
    }
  },
  methods: {
    /** 「ログイン」画面に遷移 */
    async onLogin() {
      if (this.query) {
        await AuthService.login({ query: this.query } as _RouteLocationBase);
      } else {
        await AuthService.login();
      }
    },
    /** 「新規会員登録」画面に遷移 */
    async onRegist() {
      await AuthService.regist();
    },
    async onPortasLinking() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // アカウントID専用連携ページを別タブで開く
      await AuthService.logout(process.env.VUE_APP_UCOM_LOGIN_ACCOUNT_ID_USER);

      this.isLoading = false;
    },
  },
});
</script>
