import axios, { AxiosError } from 'axios';

class FrontErrorObjectFromAxiosError {
  private readonly isAxiosError!: boolean;
  private readonly axiosErrorMessage?: string;
  private readonly axiosErrorCode?: string;
  private readonly httpMethod?: string;
  private readonly url!: string;

  /**
   * json構造だが中身の形は不明なのでanyで
   */
  private readonly requestBody?: any; // eslint-disable-line @typescript-eslint/no-explicit-any

  /**
   * json構造だが中身の形は不明なのでanyで
   */
  private readonly queryParameter!: any; // eslint-disable-line @typescript-eslint/no-explicit-any

  constructor(e: AxiosError) {
    this.isAxiosError = true;
    this.axiosErrorMessage = e.message;
    this.axiosErrorCode = e.code;
    this.httpMethod = e.config?.method;
    this.url = `${e.config?.baseURL}${e.config?.url}`;
    this.requestBody = e.config?.data ? JSON.parse(e.config?.data) : undefined;
    this.queryParameter = e.config?.params;
  }
}

/**
 * フロントエラーがaxiosエラーのとき、リクエストの内容をバックエンドに渡すようにする
 */
export class AxiosErrorFormatService {
  public static format(e: Error) {
    if (axios.isAxiosError(e)) {
      return new FrontErrorObjectFromAxiosError(e);
    }
    return undefined;
  }
}
