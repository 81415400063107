<template>
  <div>
    <table class="table-type2 border-top-none">
      <tbody>
        <tr class="border">
          <th>お住まいの建物</th>
          <td v-if="property">
            <p class="font18px mb10">
              <b>{{ property.apartmentNameDisplay }}</b>
            </p>
            <p>{{ property.apartmentAddress }}</p>
            <p class="att red mt10">※お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。</p>
            <p class="att red mt10">※お住まいの建物は登録を変更することができません。</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
tr.border {
  border-bottom: 0px;
  border-top: 1px solid #d3d3d3;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { ExternalSearchPropertiesPropertyInfoDto } from '@/shared/classes/external-api/search-properties/external-search-properties-property-info-dto';
import { InputPropertyForm } from '@/store/platform/platform-edit-store';

export default defineComponent({
  name: 'residence-registration-display',
  data(): {
    property: ExternalSearchPropertiesPropertyInfoDto | null | undefined;
  } {
    return {
      property: null,
    };
  },
  async mounted() { },
  methods: {
    update() {
      const inputPropertyFormValue: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      this.property = inputPropertyFormValue?.selectedProperty;
    },
  },
});
</script>

<style lang="scss" scoped>
.border-top-none {
  border-top: none;
}
</style>
