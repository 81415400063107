/** BBSS アカウント情報 */
export class BBSSServiceInfo {
  /** 契約ID */
  public contractId!: number;

  /** チケット */
  public ticket!: string;

  /** ダウンロードURL */
  public downloadUrl!: string;

  /** サブスクリプションID */
  public subscriptionId!: number;

  /** BBSS申込日時 */
  public signupDate!: Date;

  constructor(partial: Partial<BBSSServiceInfo>) {
    Object.assign(this, partial);
  }
}
