import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';
import { UCOM_REGISTER_STATUS } from '@/shared/const/ucom/ucom-register-status';

export class UcomAccount {
  readonly _ucomAccountId!: number;
  readonly _memberId!: number;
  readonly _ucomPrimaryKey!: string;
  readonly _registerStatus!: UCOM_REGISTER_STATUS;
  readonly _primaryKeyAuth0!: string;
  readonly _accountName!: string;
  readonly _password!: string;
  readonly _accountAuthorization!: UCOM_ACCOUNT_AUTHORITY;
  readonly _familyName!: string;
  readonly _firstName!: string;
  readonly _familyNameKana!: string;
  readonly _firstNameKana!: string;
  readonly _userName!: string;
  readonly _subscriptionOn!: Date | undefined;
  readonly _cancelSubscriptionOn!: Date | undefined;
  readonly _cancelOn!: Date | undefined;
  readonly _cancelProcessOn!: Date | undefined;
  readonly _passwordChangeOn!: Date | undefined;
  readonly _deleteOn!: Date | undefined;
  readonly _isDelete!: boolean;

  constructor(partial: Partial<UcomAccount>) {
    Object.assign(this, partial);
  }

  get ucomAccountId(): number {
    return this._ucomAccountId;
  }

  get memberId(): number {
    return this._memberId;
  }

  get ucomPrimaryKey(): string {
    return this._ucomPrimaryKey;
  }

  get registerSatus(): UCOM_REGISTER_STATUS {
    return this._registerStatus;
  }

  get primaryKeyAuth0(): string {
    return this._primaryKeyAuth0;
  }

  get accountName(): string {
    return this._accountName;
  }

  get password(): string {
    return this._password;
  }

  get accountAuthorization(): UCOM_ACCOUNT_AUTHORITY {
    return this._accountAuthorization;
  }

  get familyName(): string {
    return this._familyName;
  }

  get firstName(): string {
    return this._firstName;
  }

  get familyNameKana(): string {
    return this._familyNameKana;
  }

  get firstNameKana(): string {
    return this._firstNameKana;
  }

  get userName(): string {
    return this._userName;
  }

  get subscriptionOn(): Date | undefined {
    return this._subscriptionOn;
  }

  get cancelSubscriptionOn(): Date | undefined {
    return this._cancelSubscriptionOn;
  }

  get cancelOn(): Date | undefined {
    return this._cancelOn;
  }

  get cancelProcessOn(): Date | undefined {
    return this._cancelProcessOn;
  }

  get deleteOn(): Date | undefined {
    return this._deleteOn;
  }

  get isDelete(): boolean {
    return this._isDelete;
  }
}
