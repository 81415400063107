import {JudgeError} from "@/infra/accessor/spf/common/judge-error";
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { UcomRentalServiceUpdateInfo } from '@/shared/classes/spf-api/model/ucom/ucom-rental-service-update-info';

const api = BaseApiService.api();

/** UCOM 機器レンタルサービスに関わる 内部 API をコールする */
export class SpfApiRentalServiceAccessor {

 /**
  * レンタルBBRお申し込みAPIを実行
  * @param memberId Portas会員ID
  * @param applicationDate お申し込み日
  * @returns 
  */
  public static async updateUcomRentalService(memberId: number, applicationDate: string) {
    const response = await api.post<UcomRentalServiceUpdateInfo>(`/rental-service/UCOM/confirm`, {
      "memberId": memberId,
      "applicationDate": applicationDate,
    }).catch(async (error) => {
      if (typeof error.response.data.errors[0] === 'string') {
        return error.response.data.errors[0];
      }
      await JudgeError.judgeError(error);
      throw error;
    });
    if (typeof response === 'string') {
      return response;
    }
    return this.convertUcomRentalServiceUpdateInfoDto(response);
  }

  /**
   * レンタルBBR申し込みAPI(外部 UCOM)からのレスポンスを整形する
   * @param response
   * @returns 
   */
  private static async convertUcomRentalServiceUpdateInfoDto(response: any | undefined): Promise<UcomRentalServiceUpdateInfo> {
    if (!response) {
      throw new Error('convertUcomRentalServiceUpdateInfoDtoでエラーが発生しました');
    }
    return new UcomRentalServiceUpdateInfo({
      _notificationMailAddress:response.data.mailAddress,
      _message:response.data.message
    });
  }
}