<template>
  <div class="veritrans-credit-card-component-on-portas">
    <div>
      <div class="pd10 ml10" v-if="maskedCardNumberOnPortas && cardExpiredOnPortas">
        <p>Portas（ポルタス）にご登録されている以下のお支払い方法により、お支払いいただきます。</p>
        <h3>クレジットカード</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">カード番号</th>
              <td>{{ maskedCardNumberOnPortasWithHyphen }}</td>
            </tr>
            <tr>
              <th class="va-middle">カード期限</th>
              <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
              <td v-if="isExpiredPortasCreditCard"><b class="red">ご利用になれないクレジットカードです。</b></td>
            </tr>
            <tr v-if="!cardholderNameOnPortas && isNeedAuthorization">
              <td>
                <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
              </td>
              <td>
                <div class="mt10">
                  <input type="text" class="text card-name" minlength="2" maxlength="45" required @change="inputCardHolderNameAtCopy" v-model.trim="cardholderNameAtCopy" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else-if="externalVeriTransId">
        <div class="pd10 ml10">
          <p>
            {{ ispProviderCompanyName ? ispProviderCompanyName : '株式会社つなぐネットコミュニケーションズ' }}が提供する{{
              ispName
            }}にご登録されているお支払い方法を、<br />アルテリア・ネットワークス株式会社が提供するPortas（ポルタス）にコピーして、お支払いいただくことができます。
          </p>
        </div>

        <div class="pd10 ml10 mt10">
          <p>
            <b>
              {{ ispProviderCompanyName ? ispProviderCompanyName : '株式会社つなぐネットコミュニケーションズ' }}
              {{ ispName }}でご登録いただいているクレジットカード情報をPortas（ポルタス）に登録しますか。
            </b>
          </p>
        </div>

        <div class="ml10 mt20 mb30">
          <input id="cardCopyYes" :disabled="isCreatedToken" v-model="isSelectedCopy" type="radio" name="isRequiredCopyCard" value="yes" v-on:change="isRequiredCardCopy" /><label
            for="cardCopyYes"
            >はい</label
          >
          <span v-if="isCreatedToken"> {{ ispName }}に登録されているカード情報をコピーしたい場合は「戻る」ボタンを押してもう一度登録手続きをして頂くようお願いいたします。 </span>
          <div class="mt20">
            <input id="cardCopyNo" :disabled="isAgreedCardInfoCopy" v-model="isSelectedCopy" type="radio" name="isRequiredCopyCard" value="no" v-on:change="isRequiredCardCopy" />
            <label for="cardCopyNo">いいえ</label>
            <span v-if="isAgreedCardInfoCopy">
              クレジットカード情報の新規登録を行いたい場合は「クレジットカード情報をコピーすることに同意する」チェックボックスのチェックを外してください。
            </span>
          </div>
        </div>

        <div v-if="isSelectedCopy === 'yes'" class="pd10 ml20">
          <button
            v-bind:class="{ ' btn01': !isRequestedDisplayCardInfo, ' btn04': isRequestedDisplayCardInfo }"
            :disabled="isRequestedDisplayCardInfo"
            v-on:click="onRequiredDisplayCardInfo"
            class="display-isp-card-info-btn btn bs"
          >
            {{ ispName }}に登録されているクレジットカード情報を表示する
          </button>
        </div>

        <div v-if="isRequestedDisplayCardInfo && isSelectedCopy === 'yes'" class="ml40">
          <table class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumberFromIsp }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonthFromIsp }}月 ／ {{ cardExpiredYearFromIsp }}年</td>
              </tr>
              <tr v-if="!isIspCardHolderName && isNeedAuthorization">
                <td>
                  <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                </td>
                <td>
                  <div class="mt10">
                    <input type="text" class="text card-name" required minlength="2" maxlength="45" @change="inputCardHolderNameAtCopy" v-model.trim="cardholderNameAtCopy" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mt20">
            <p>
              ※お申し込み完了までの間に{{
                ispName
              }}のお支払い方法を変更された場合、変更後のクレジットカードが登録される場合がございます。<br />※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。
            </p>
          </div>

          <div class="pd20">
            <label>
              <input v-model="isAgreedCardInfoCopy" v-on:change="sendIsAgreeCardInfoCopy" type="checkbox" />
              クレジットカード情報をコピーすることに同意する
            </label>
          </div>
        </div>
      </div>
    </div>

    <h3 v-if="isSelectedCopy === 'no'">新規にクレジットカードを登録する</h3>
    <div v-if="isRequiredDisplayCreditCardInputForm()">
      <p class="mb15"><b>ご利用可能なクレジットカードの種類</b></p>
      <img src="../../images/ucom/crecitcard.png" alt="ご利用いただけるカードの種類" class="crecitcard" />
      <p>Visa（ビザ）、Mastercard（マスターカード）、JCB（ジェーシービー）、American Express（アメリカン・エキスプレス）、Diners Club（ダイナースクラブ）</p>
      <h4>クレジットカード情報の入力</h4>
      <p>登録したいクレジットカード情報を入力してください。</p>
      <p>複数回入力エラーを繰り返した場合、一定期間ご入力を受け付けられなくなります。</p>
      <p>お手元にクレジットカードをご用意のうえ、正確にご入力ください。</p>
      <p>
        クレジットカード会社での本人認証のため、ご利用のブラウザのCookieを許可していただき<br />
        トラッキング防止が有効になっている場合には、無効に変更をお願いいたします。<br />
        設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
      </p>
      <table class="table-type2">
        <tr>
          <td><b>カード番号（半角数字）</b><span class="req">必須</span></td>
          <td>
            <p class="mb10">半角数字で1マスに4桁ずつ、左詰めでご入力ください</p>
            <input type="text" v-model.trim="form.number1" @input="v$.form.number1.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number2" @input="v$.form.number2.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number3" @input="v$.form.number3.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number4" @input="v$.form.number4.$touch()" class="text short" pattern="\d{2,4}" />
          </td>
        </tr>
        <tr>
          <td>
            <b>セキュリティコード<br />（半角数字）</b><span class="req">必須</span>
          </td>
          <td>
            <p class="mb10"></p>
            <div class="mt10 security-code">
              <input type="text" class="text short" v-model.trim="form.securityCode" />
              <span class="tooltip">
                ?<span class="description-right">
                  セキュリティコードとはクレジットカード番号とは別にカード裏面署名欄に記載された3桁の番号です。American Expressは表面に4桁で記載されていることもあります。</span
                >
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td><b>カード期限</b><span class="req">必須</span></td>
          <td>
            <p class="mb10">クレジットカードに記載の年月をご入力ください</p>
            <select v-model="form.expiryMonth" @change="v$.form.expiryMonth.$touch()" class="form-select">
              <option value="">--</option>
              <option v-for="n in 12" :key="n" :value="String(n).padStart(2, '0')">
                {{ String(n).padStart(2, '0') }}
              </option>
            </select>
            月&emsp;
            <select v-model="form.expiryYear" @change="v$.form.expiryYear.$touch()" class="form-select">
              <option value="">--</option>
              <option :value="String(currentYear).slice(-2)">{{ String(currentYear).slice(-2) }}</option>
              <option v-for="n in 9" :key="n" :value="String(n + currentYear).slice(-2)">
                {{ String(n + currentYear).slice(-2) }}
              </option>
            </select>
            年（西暦下2桁）
          </td>
        </tr>
        <tr>
          <td>
            <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
          </td>
          <td>
            <div class="mt10">
              <input type="text" class="text card-name" @change="inputEventConvertToUpper" v-model.trim="form.cardholderName" />
            </div>
          </td>
        </tr>
      </table>
      <div class="btn-area">
        <button class="btn-height btn btn01 bs" v-on:click="onPostCreditCard">クレジットカード情報を送信<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>

    <div v-if="isCreatedToken" class="mt20 ml40 red">
      <p><b>ご入力いただいたクレジットカード情報が株式会社DGフィナンシャルテクノロジーに送信されました。</b></p>
      <p>
        <b>{{ successMessage }}</b>
      </p>
    </div>

    <div v-if="isRequiredDisplayCreditCardNotice()" class="border-grey bg-grey pd20 mt40 ml40">
      <div v-if="isCreatedToken">
        <p class="red"><b>※「クレジットカード情報を送信」ボタンをクリック後、15分以内にお申し込みを完了させてください。</b></p>
      </div>
      <div v-if="entryInput">
        <p>
          上記Portasサービスへのお申し込みを行う場合、ご入力いただいたクレジットカード情報が有効に利用できるか、ご確認させていただきますので、情報入力後「クレジットカード情報を送信」のボタンを押してください。
        </p>
        <p>なお、Portas新規会員登録とサービス申し込みが確定するまで、当該入力情報は登録されません。</p>
        <p>クレジットカード情報は、サービス料金決済の目的で利用します。</p>
        <p>Portas会員登録及びサービスお申し込みの完了後、入力情報は会員情報として管理されます。</p>

        <p>ご入力いただいたクレジットカード情報は、当社で保持せず直接「株式会社DGフィナンシャルテクノロジー」へ送信され、管理されます。</p>
        <p>※登録のタイミングによっては、前月分のご請求から変更後のクレジットカードでのお支払いとなることがございます。</p>
        <p>&emsp;予めご了承ください。</p>
        <p>
          ※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。
        </p>
      </div>
      <div v-else>
        <p>クレジットカード情報は、サービス料金決済の目的で利用します。</p>
        <p>ご入力いただいたクレジットカード情報は、当社で保持せず直接「株式会社DGフィナンシャルテクノロジー」へ送信され、管理されます。</p>
        <p>※登録のタイミングによっては、前月分のご請求から変更後のクレジットカードでのお支払いとなることがございます。</p>
        <p>&emsp;予めご了承ください。</p>
        <p>
          ※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。
        </p>
      </div>
    </div>
    <div v-if="isRequiredDisplayCreditCardNotice()" class="border-grey bg-grey pd20 mt40 ml40">
      <p>
        ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-height {
  height: 50px;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

img.crecitcard {
  max-width: 640px;
  width: 100%;
}

button[disabled] {
  pointer-events: none;
}

.tooltip {
  background: #666666;
  margin: 5px;
  font-size: 12px;
  border-radius: 100%;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  left: 155px;
}

.security-code {
  display: flex;
  position: relative;
  align-items: center;
}

.description-right {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translateY(-50%);
  padding: 8px;
  border-radius: 10px;
  background-color: #666;
  font-size: 12px;
  color: #fff;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  transition: 0.5s all;
}

.tooltip:hover .description-right {
  left: 100%;
  visibility: visible;
  opacity: 0.8;
}

.card-name {
  width: 100%;
  max-width: 360px;
}

@media screen and (max-width: 767px) {
  .tooltip {
    left: 85px;
  }
}

.display-isp-card-info-btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 1em;
  padding-right: 1em;
  min-height: 50px;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, numeric, required } from '@vuelidate/validators';
import axios from 'axios';
import { defineComponent } from 'vue';

import { VeritransCreditCardTokenRequest } from '../classes/veritrans-credit-card-token-request';
import { VeritransApiService } from '../services/api/veritrans-api-service';
import { VeritransErrorService } from '../services/veritrans/veritrans-error-service';
import { VeritransRequestStatusService } from '../services/veritrans/veritrans-request-status-service';
import { isCreditCardExpired } from '../util/func-is-credit-card-expired';
import { processYearNumber } from '../util/func-process-date';
import { inputEventConvertToUpper } from '../util/inputEventConvertToUpper';

import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { SpfApiService } from '@/shared/services/api/spf-api-service';

type SplitCardExpireDate = {
  cardExpireMonth: string;
  cardExpireYear: string;
};

/** Veritrans クレジットカード情報入力コンポーネント(カード名義人記入欄付き) */
export default defineComponent({
  name: 'veritrans-credit-card-component-include-card-holder',
  // 親コンポーネントから受け取るデータ
  props: {
    /** API トークンキー */
    apiTokenKey: {
      type: String,
      default: '',
    },
    /** トークン取得成功後に表示するメッセージ */
    successMessage: {
      type: String,
      default: '',
    },
    /** 連携先ISPにおけるVeriTrans 会員 ID */
    externalVeriTransId: {
      type: String,
      default: '',
    },
    /** Portasに登録されている支払方法のマスク済クレジットカード番号 */
    maskedCardNumberOnPortas: {
      type: String,
      default: '',
    },
    /** Portasに登録されている支払方法の有効期限 */
    cardExpiredOnPortas: {
      type: String,
      default: '',
    },
    /** Portasに登録されている支払方法のカード保有者名 */
    cardholderNameOnPortas: {
      type: String,
      default: '',
    },
    /** ログイン中のPortasユーザーが連携中のISP名 */
    ispName: {
      type: String,
      default: '',
    },
    /** ログイン中のPortasユーザーがUCOMと連携している場合サービス提供元企業名 */
    ispProviderCompanyName: {
      type: String,
      default: '',
    },
    /**3Dセキュアを実施するか */
    isNeedAuthorization: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    /** 入力値 */
    form: {
      /** クレジットカード番号1 */
      number1: '',
      /** クレジットカード番号2 */
      number2: '',
      /** クレジットカード番号3 */
      number3: '',
      /** クレジットカード番号4 */
      number4: '',
      /** クレジットカード有効期限月 (ゼロパディング2桁) */
      expiryMonth: '',
      /** クレジットカード有効期限年 (西暦下2桁) */
      expiryYear: '',
      /** クレジットカード名義人 */
      cardholderName: '',
      /** セキュリティコード */
      securityCode: '',
    },
    /** 入力フォームを表示するか否か */
    isInput: true,
    /** 西暦の入力欄を組み立てるための現在年 */
    currentYear: 0,
    /** onPostCreditCard()が実行中かどうか */
    isOnPostCreditCardExecuting: false,
    /** コンポーネントが支払方法登録画面に埋め込まれているか、編集画面に埋め込まれているかの判定結果 */
    processType: '',
    /** カードコピーしますか「はい」ラジオボタンが選択されているかどうか */
    isSelectedCopy: '',
    /** 連携先ISPに登録されているカード情報を表示するボタン押下ステータス */
    isRequestedDisplayCardInfo: false,
    /** クレジットカード情報送信用トークンが作成されているか */
    isCreatedToken: false,
    /** ユーザーがカード情報をコピーすることに同意しているかどうか */
    isAgreedCardInfoCopy: false,
    /** 支払方法をコピーするか確認する部品の表示が必要かどうか */
    isRequiredDisplayCardCopyFunction: false,
    /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** 連携先ISPで登録されたクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** 連携先ISPで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthFromIsp: '',
    /** 連携先ISPで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearFromIsp: '',
    /** 連携先ISPで登録されたカード保有者名（ない場合に入力する） */
    cardholderNameAtCopy: '',
    /** ISPにカード保有者名が登録されているか */
    isIspCardHolderName: false,
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: '',
    /** Portasで登録されたクレジットカード番号(ハイフン付き) */
    maskedCardNumberOnPortasWithHyphen: '',
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** サービス申込画面から呼ばれているかどうか */
    entryInput: false,
  }),
  validations: {
    form: {
      number1: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number2: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number3: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number4: {
        required,
        numeric,
        minLength: minLength(2),
        maxLength: maxLength(4),
      },
      expiryMonth: {
        required,
      },
      expiryYear: {
        required,
      },
      cardholderName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      securityCode: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4),
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  emits: [
    'isExpiredPortasCreditCard',
    'onPostCreditCardError',
    'cardTokenForRegister',
    'cardTokenExpireDateForRegister',
    'cardTokenFor3dSecureAuthorize',
    'cardholderNameAtCopy',
    'isNeedCardholderName',
    'getCreditEndNumber',
    'change',
    'radioValueRequiringCardCopy',
  ],
  mounted(): void {
    // computed() では西暦が正しく取得できない・mounted() で処理する必要あり
    this.currentYear = processYearNumber();

    if (location.pathname === '/platform/my-page/payment-method/edit') {
      this.processType = '編集';
      this.isRequiredDisplayCardCopyFunction = false;
    } else if (location.pathname === '/platform/my-page/payment-method/input') {
      this.processType = '登録';
    }
    if (this.maskedCardNumberOnPortas) {
      this.maskedCardNumberOnPortasWithHyphen = this.addHyphenToCardNumber(this.maskedCardNumberOnPortas);
      if (this.isNeedAuthorization && !this.cardholderNameOnPortas) {
        this.$emit('isNeedCardholderName', true);
      }
    }

    if (this.cardExpiredOnPortas) {
      const splitCardExpireDate = this.splitCardExpireDate(this.cardExpiredOnPortas);
      this.cardExpiredMonthOnPortas = splitCardExpireDate.cardExpireMonth;
      this.cardExpiredYearOnPortas = String(processYearNumber()).slice(0, 2) + splitCardExpireDate.cardExpireYear;

      if (isCreditCardExpired(this.cardExpiredMonthOnPortas, this.cardExpiredYearOnPortas)) {
        this.isExpiredPortasCreditCard = true;
        this.$emit('isExpiredPortasCreditCard', true);
      }
    }
    /** サービス申込画面から呼ばれているかどうか判定 */
    if (this.$route.path.includes('entry-input')) {
      this.entryInput = true;
    }
  },
  methods: {
    /**カード番号バリデーション */
    validateCardNumberInput(): boolean {
      if (this.v$.form.number1?.maxLength.$invalid || this.v$.form.number1?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number2?.maxLength.$invalid || this.v$.form.number2?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number3?.maxLength.$invalid || this.v$.form.number3?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number4?.maxLength.$invalid || this.v$.form.number4?.minLength.$invalid) {
        return false;
      }
      return true;
    },
    /** 「クレジットカード情報を送信」ボタン押下時 */
    async onPostCreditCard(): Promise<void> {
      // 回数制限に引っかかっていないか確認
      if (!VeritransRequestStatusService.canSendPostRequest()) {
        this.$emit(
          'onPostCreditCardError',
          [
            '入力上限回数に達しましたのでロックされました。しばらくしてから再度ご登録ください。',
            'なお、弊社ではセキュリティ上、ロックを解除することはできませんので、ご了承ください。',
          ].join('<br>')
        );
        return;
      }

      if (this.isOnPostCreditCardExecuting) {
        return;
      }

      // 3Dセキュアサービス未契約の場合、VeriTransのトークン作成APIはカード名義人が未指定でもエラーを返さない。
      // このため、カード名義人が空欄の場合はVeriTransへのカード情報送信を行わず、Portasフロント側でエラーを返して回数制限をカウントする。
      if (!this.form.cardholderName) {
        VeritransRequestStatusService.addFailureCount();
        this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        return;
      }
      if (!this.validateCardNumberInput()) {
        VeritransRequestStatusService.addFailureCount();
        this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        return;
      }
      if (this.form.cardholderName.length < 2 || this.form.cardholderName.length > 45) {
        VeritransRequestStatusService.addFailureCount();
        this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        return;
      }

      this.isOnPostCreditCardExecuting = true;

      try {
        /** API をコールしてトークンを取得する
         * テスト環境ではセキュリティコードが必要なのでテスト用セキュリティコードを送信している
         */
        const tokenRequest = new VeritransCreditCardTokenRequest({
          card_number: `${this.form.number1}${this.form.number2}${this.form.number3}${this.form.number4}`,
          card_expire: `${this.form.expiryMonth}/${this.form.expiryYear}`,
          token_api_key: this.apiTokenKey,
          security_code: this.form.securityCode,
          cardholder_name: this.form.cardholderName,
          lang: 'ja',
        });

        const fetchCreditCardTokenResponseForRegisterCard = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const cardTokenForRegister = fetchCreditCardTokenResponseForRegisterCard.token;
        const cardTokenExpireDateForRegister = fetchCreditCardTokenResponseForRegisterCard.token_expire_date;

        const cardTokenFor3dSecureAuthorizeResponse = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorizeResponse.token;

        // 入力エリアを非表示にする
        this.isInput = false;

        // 親コンポーネントにクレジットカード登録用カードトークンを渡す
        this.$emit('cardTokenForRegister', cardTokenForRegister);

        // 親コンポーネントにクレジットカード登録用カードトークン有効期限を渡す
        this.$emit('cardTokenExpireDateForRegister', cardTokenExpireDateForRegister);

        // 親コンポーネントに3Dセキュア本人認証用カードトークンを渡す
        this.$emit('cardTokenFor3dSecureAuthorize', cardTokenFor3dSecureAuthorize);

        // 親コンポーネントにクレジットカード番号の下4桁を渡す
        this.$emit('getCreditEndNumber', this.form.number4);

        // 失敗回数カウントを0回にする
        VeritransRequestStatusService.setFailureCount0();

        this.isCreatedToken = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // エラーレスポンスに応じた、表示するエラーメッセージの場合分け
        if (axios.isAxiosError(error)) {
          const status: number = error.response?.status ? error.response?.status : -1;
          const errorCode: string = error.response?.data.code;

          if (VeritransErrorService.isErrorDueToInputValue(errorCode)) {
            VeritransRequestStatusService.addFailureCount();
            this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
          } else if (VeritransErrorService.isErrorDueToConfig(errorCode)) {
            const errorCodeInNumericFormat = VeritransErrorService.getErrorCodeInNumericFormat(errorCode);

            this.$emit(
              'onPostCreditCardError',
              [
                '処理が正常に完了できませんでした。しばらく時間をおいてから再度お試しください。',
                '再度お試しいただいても正常に完了しない場合、大変お手数ですがサポートセンターまでエラー番号をお知らせください。',
                '<エラー番号: ' + errorCodeInNumericFormat + '>',
              ].join('</br>')
            );

            // 通信エラーのとき
          } else if (status >= 500) {
            this.$emit('onPostCreditCardError', 'クレジットカード情報の送信に失敗しました。');
          }
        } else {
          throw error;
        }
      } finally {
        this.isOnPostCreditCardExecuting = false;
      }
      this.isOnPostCreditCardExecuting = false;
    },
    /** ISP(e-mansion, Five.A, ucom) の VeriTrans会員ID を使って、カード情報を取得する
     * 「クレジットカード情報を表示する」ボタン押下時、取得したカード情報を表示する
     */
    async onRequiredDisplayCardInfo() {
      const ispCardInfo: PaymentMethodDetailResponse = await SpfApiService.getDefaultPaymentMethod(this.externalVeriTransId);

      const splitCardExpireDate = this.splitCardExpireDate(ispCardInfo.cardExpire);

      this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(ispCardInfo.cardNumber);

      this.cardExpiredMonthFromIsp = splitCardExpireDate.cardExpireMonth;
      this.cardExpiredYearFromIsp = String(processYearNumber()).slice(0, 2) + splitCardExpireDate.cardExpireYear;
      if (ispCardInfo.cardholderName) {
        this.isIspCardHolderName = true;
      } else {
        this.isIspCardHolderName = false;
        if (this.isNeedAuthorization) {
          this.$emit('isNeedCardholderName', true);
        }
      }
      this.isRequestedDisplayCardInfo = true;
    },
    /*
     * MM/YY 形式の文字列で渡されたカード有効期限を、月と年の情報に分割して返却する
     */
    splitCardExpireDate(cardExpire: string): SplitCardExpireDate {
      const splitCardExpireDateArray = cardExpire.split('/');
      const splitCardExpireDate = {
        cardExpireMonth: splitCardExpireDateArray[0],
        cardExpireYear: splitCardExpireDateArray[1],
      };
      return splitCardExpireDate;
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    /*
     * クレジットカード情報入力フォームの表示要否を判定する
     */
    isRequiredDisplayCreditCardInputForm(): boolean {
      // Portasに支払方法が登録されているときは表示しない
      if (this.maskedCardNumberOnPortas && this.cardExpiredOnPortas) {
        return false;

        // Portasに支払方法が登録されていないが、連携先のISPに支払方法が登録されている場合
      } else if (this.externalVeriTransId) {
        // 「支払方法をコピーしない」ラジオボタンが選択されている、かつカード情報送信用トークンの生成が完了していない場合は表示
        if (this.isSelectedCopy === 'no' && !this.isCreatedToken) {
          return true;
        } else {
          return false;
        }

        // Portasにも連携先外部ISPにも支払方法が登録されていない、かつカード情報送信用トークンの生成が完了していない場合は表示
      } else if (!this.isCreatedToken) {
        return true;
      } else {
        return false;
      }
    },
    /*
     * クレジットカード情報の扱いについての説明ブロック表示要否を判定する
     */
    isRequiredDisplayCreditCardNotice(): boolean {
      // Portasに支払方法が登録されているときは表示しない
      if (this.maskedCardNumberOnPortas && this.cardExpiredOnPortas) {
        return false;

        // Portasに支払方法が登録されていないが、連携先のISPに支払方法が登録されている場合
      } else if (this.externalVeriTransId) {
        // 「支払方法をコピーしない」ラジオボタンが選択されている場合は表示
        if (this.isSelectedCopy === 'no') {
          return true;
        } else {
          return false;
        }
        // Portasにも連携先外部ISPにも支払方法が登録されていない場合は表示
      } else {
        return true;
      }
    },
    sendIsAgreeCardInfoCopy(evnet: Event) {
      const target = evnet.target as HTMLInputElement | null;
      this.$emit('change', target?.checked ?? false);
    },
    inputEventConvertToUpper(event: Event) {
      this.form.cardholderName = inputEventConvertToUpper(event);
    },
    inputCardHolderNameAtCopy(event: Event) {
      this.cardholderNameAtCopy = inputEventConvertToUpper(event);
      this.$emit('cardholderNameAtCopy', this.cardholderNameAtCopy);
    },
    /** ISPで登録済のクレジットカードコピーするかどうか「はい」「いいえ」ラジオボタン選択状態 */
    isRequiredCardCopy(event: Event) {
      const target = event.target as HTMLInputElement | null;
      this.$emit('radioValueRequiringCardCopy', target?.value ?? '');
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computedForm(): any {
      return JSON.parse(JSON.stringify(this.form));
    },
  },
  watch: {
    computedForm: {
      handler: function (newVal, oldVal) {
        if (newVal.number1 && !newVal.number1.match(/^\d{1,4}$/)) {
          this.form.number1 = oldVal.number1;
        }
        if (newVal.number2 && !newVal.number2.match(/^\d{1,4}$/)) {
          this.form.number2 = oldVal.number2;
        }
        if (newVal.number3 && !newVal.number3.match(/^\d{1,4}$/)) {
          this.form.number3 = oldVal.number3;
        }
        if (newVal.number4 && !newVal.number4.match(/^\d{1,4}$/)) {
          this.form.number4 = oldVal.number4;
        }
        if (newVal.cardholderName && !newVal.cardholderName.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.form.cardholderName = oldVal.cardholderName;
        }
        // セキュリティコードは3桁・4桁の半角英数字に制限
        if (newVal.securityCode && !newVal.securityCode.match(/^\d{1,4}$/)) {
          this.form.securityCode = oldVal.securityCode;
        }
      },
      deep: true,
    },
  },
});
</script>
