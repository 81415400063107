export const UCOM_PURCHASE_STATUS = {
  IN_CONTRACT_APPLICATION: { id: 1, value: '購入申請中' },
  CONTRACTED: { id: 2, value: '購入済み' },
  IN_WITHDRAWAL_APPLICATION: { id: 3, value: '解約申請中' },
  CANCELLED: { id: 4, value: '解約済み' },
  UNPURCHASED: { id: 5, value: '未購入' },
} as const;
export type UCOM_PURCHASE_STATUS = typeof UCOM_PURCHASE_STATUS[keyof typeof UCOM_PURCHASE_STATUS];

export const convertUcomPurchaseStatusById = (id: number): UCOM_PURCHASE_STATUS | undefined => {
  for (const v of Object.values(UCOM_PURCHASE_STATUS)) {
    if (Number(v.id) == id) {
      return v;
    }
  }
};
