/**
 * UCOM Connectix API : Connectix お申し込み可能な部屋番号一覧 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomConnectixPropertyRoomsResponseResult {
  /** 結果値 */
  public result!: UcomConnectixPropertyRoomsResponse;

  constructor(partial: Partial<UcomConnectixPropertyRoomsResponseResult>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM API : Connectix お申し込み可能な部屋番号一覧 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomConnectixPropertyRoomsResponse {
  /** お申し込み可能な部屋一覧 */
  public room_numbers!: Array<string>;

  constructor(partial: Partial<UcomConnectixPropertyRoomsResponse>) {
    Object.assign(this, partial);
  }
}
