<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>アカウントID メールサービス解約確認</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>アカウントID削除</li>
      </ul>
      <!-- /breadcrumb -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <!-- blc:アカウントID情報 -->
      <div class="blc">
        <div class="blc">
          <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールサービス解約確認" />アカウントID メールサービス解約確認</h2>

          <p class="red">アカウントIDの削除により、メールサービスが<b>自動的に解約</b>されます。</p>
          <p class="red">再度購入する場合、<b>購入延べ数にて料金が発生</b>します(当月にメールサービスが自動解約と再購入を繰り返した場合、解約分の1月分が発生)。</p>
          <p>解約しても問題ない場合は「アカウントIDの削除とメールサービスの解約を行う」ボタンを押してください。</p>
          <br />
          <p>アカウントID情報、メールアドレスの変更を行いたい場合は、以下のページからお手続きを行ってください。</p>
          <ul class="ul-basic02">
            <li>
              <a @click.prevent v-on:click="onAccountId()" href=""><u class="red">アカウントID情報変更</u></a>
            </li>
            <li>
              <a @click.prevent v-on:click="onMailAddress()" href=""><u class="red">メールアドレス変更</u></a>
            </li>
          </ul>
        </div>
      </div>
      <!-- /blc:アカウントID情報 -->
      <!-- /contents -->
      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs button-bottom" v-on:click="goDelete()">
            アカウントIDの削除とメールサービスの解約を行う<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiAccountInfoForDeleteAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-info-for-delete-accessor';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'account-delete-confirmation',
  components: {
    LoadingComponent,
  },
  data: () => ({
    ucomAccountId: '',
    isLoading: true,
  }),
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    //前画面よりアカウント情報を取得する
    this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
    if (!this.$data.ucomAccountId) {
      this.$store.commit('ucomAccountManagementDeleteStore/clear');
      //アカウントID管理一覧画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
      return;
    }

    // 戻るボタン押下時にstoreのucomAccountIdに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
    this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);

    this.$data.isLoading = false;
  },

  methods: {
    /** 戻るボタン押下時 */
    async goBack() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', this.$data.ucomAccountId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/delete').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**削除 確認ボタン押下時 */
    async goDelete() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** SU14-014 削除完了画面 */
      //アカウントID情報を$storeより取得する
      try {
        const ucomAccountInfo = await SpfApiAccountInfoForDeleteAccessor.deleteAccountInfo(this.$data.ucomAccountId);
        this.$store.commit('ucomAccountManagementDeleteStore/accountName', ucomAccountInfo.accountName);
        this.$store.commit('ucomAccountManagementDeleteStore/fullName', ucomAccountInfo.fullName);
        this.$store.commit('ucomAccountManagementDeleteStore/mailAddress', ucomAccountInfo.mailAddress);
        this.$store.commit('ucomAccountManagementDeleteStore/accountAuthorization', ucomAccountInfo.accountAuthorization);
        this.$store.commit('ucomAccountManagementDeleteStore/isTargetLoginUser', ucomAccountInfo.isTargetLoginUser);
      } catch (error) {
        // 削除関連store情報の削除
        this.$store.commit('ucomAccountManagementDeleteStore/clear');
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });

        this.$data.isLoading = false;
        return;
      }

      /** SU14-014 削除処理 */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/delete-complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    async onAccountId() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');
      //次画面で必要な情報なのでucomAccountIdを渡す
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', this.$data.ucomAccountId);
      //次画面で戻るボタンを押したときの遷移先をstoreに保存する
      this.$store.commit('ucomAccountManagementInputStore/before', '/ucom/account-management/list');
      /** アカウントID管理：登録・変更_入力画面に遷移 */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    async onMailAddress() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      const accountName = this.$store.getters['ucomAccountManagementDeleteStore/accountName'];
      const currentMailAddress = this.$store.getters['ucomAccountManagementDeleteStore/mailAddress'];
      const mailAddress = this.$store.getters['ucomAccountManagementDeleteStore/mailAddress'];
      const mailAddressType = this.$store.getters['ucomAccountManagementDeleteStore/mailAddressType'];

      this.$store.commit('ucomAccountOptionStore/accountName', accountName);
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', this.$data.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/currentMailAddress', currentMailAddress);
      this.$store.commit('ucomAccountOptionStore/mailAddress', mailAddress);
      this.$store.commit('ucomAccountOptionStore/mailAddressType', mailAddressType);
      // isChangeとあるがbooleanではないので注意
      this.$store.commit('ucomAccountOptionStore/isChange', UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE);

      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');
      /** アカウントID管理：メールアドレス登録・変更_入力画面に遷移 */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-option-change/mail-input').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**
     *  UCOMを退会状態ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
});
</script>

<style scoped>
u:hover {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  button.button-bottom {
    margin-top: 10px;
  }
}
</style>
