import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

import { AuthService } from '@/shared/services/auth/auth-service';

import Error from '@/pages/error.vue';
import Index from '@/pages/index.vue';
import LoginCallback from '@/pages/login-callback.vue';
import LoginForwarding from '@/pages/login-forwarding.vue';
import Maintenance from '@/pages/maintenance.vue';
import RegisterUcom from '@/pages/register-ucom.vue';
import SubAccountLinkageStepping from '@/pages/sub-account-linkage-stepping.vue';
import Top from '@/pages/top.vue';

import connectixRouter from './connectix';
import eMansionRouter from './e-mansion';
import fiveARouter from './five-a';
import mcloudRouter from './mcloud';
import platformRouter from './platform';
import ucomRouter from './ucom';

import NotFound from '@/pages/not-found.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import store from '@/store';
// import LoginCallbackSub from '@/pages/login-callback-sub.vue';
import RemainingWorkExternalResult from '@/pages/remaining-work-external-result.vue';
import RemainingWorkExternalRoomNumberInput from '@/pages/remaining-work-external-room-number-input.vue';
import { PortasDBCacheWithStore } from '@/router/before/portas-db-cache-with-store';
import { DataDogRealUserMonitoring } from '@/shared/classes/data-dog/data-dog-real-user-monitoring';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { MScreenInfo } from '@/shared/classes/spf-api/m-screen';
import { MaintenanceScreenInfo } from '@/shared/classes/spf-api/maintenance-screen';
import { Property } from '@/shared/classes/spf-api/property';
import { NoticeRevisionUpdate } from './before/notice-update';
import { UserScreenTransitionHistoryWithStore } from './before/user-screen-transition-history-with-store';
import gamewithRouter from './gamewith';
import tsunaguOnlineProductRouter from './tnos';

const dataDogRealUserMonitoring = new DataDogRealUserMonitoring();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '/',
        name: '総合トップ',
        component: Top,
        meta: {
          title: 'Portas | 総合トップページ | ポルタス',
          description:
            'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
          keywords: '総合トップページ',
          isPublic: true,
        },
        beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
          const isLoggedIn = await AuthService.isLoggedIn();

          // meta.isPublic: true なので、beforeEach内で PortasDBCacheWithStore.main() が実行されない
          await store.dispatch('memberStore/member'); // 会員情報を取得
          await store.dispatch('memberStore/memberStatus'); // 会員ステータス情報を取得
          await store.dispatch('oemsStore/oems');

          const member: Member | null = store.getters['memberStore/member'];
          const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];

          if (isLoggedIn && memberStatus && member) {
            return next({ path: '/platform' });
          } else {
            return next();
          }
        },
      },
      {
        path: '/error',
        name: 'エラー画面',
        component: Error,
        meta: {
          title: 'Portas | エラー | ポルタス',
          description: '申し訳ございません。エラーが発生しました。',
          keywords: 'エラー画面',
          isPublic: true,
        },
        beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
          // URLに#と=が同時に入るとvue-routerのquerySelectorでエラーが出てしまうため
          // それを歯止めるためのチェック処理を追加 ARN_QA-928
          if (to.hash.indexOf('=') != -1) {
            next({ path: '/' });
          }
          next();
        },
      },
      // {
      //   path: '/register-portas',
      //   name: 'Portas 会員登録手順',
      //   component: RegisterPortas,
      //   meta: {
      //     title: 'Portas | Portas 会員登録手順 | ポルタス',
      //     description: 'Portas（ポルタス）の会員登録は、こちらの手順に従って行ってください。',
      //     keywords: 'Portas 会員登録手順',
      //     isPublic: true
      //   }
      // },
      // {
      //   path: '/register-e-mansion',
      //   name: 'Portasおよびe-mansion 入会登録手順',
      //   component: RegisterEMansion,
      //   meta: {
      //     title: 'Portas | Portasおよびe-mansion 入会登録手順 | ポルタス',
      //     description: 'Portas（ポルタス）でのe-mansionの入会登録は、こちらの手順に従って行ってください。',
      //     keywords: 'e-mansion 会員登録手順',
      //     isPublic: true
      //   }
      // },
      {
        path: '/register-ucom',
        name: 'UCOM光 レジデンス 会員登録手順',
        component: RegisterUcom,
        meta: {
          title: 'Portas | PortasおよびUCOM光 レジデンス 会員登録手順 | ポルタス',
          description: 'Portas（ポルタス）でのUCOM光 レジデンスの会員登録は、こちらの手順に従って行ってください。',
          keywords: 'UCOM光 レジデンス 会員登録手順',
          isPublic: true,
        },
      },
      {
        path: '/remaining-work/external/room-number-input',
        name: '外部用 宅内工事確認 部屋番号入力',
        component: RemainingWorkExternalRoomNumberInput,
        meta: {
          title: 'Portas | 宅内工事確認 部屋番号入力 | ポルタス',
          description: 'Portas（ポルタス）での宅内工事確認結果のご確認はこちらから行ってください。入力',
          keywords: '宅内工事確認 部屋番号入力',
          isPublic: true,
        },
        beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
          // 外部サイトからの遷移許可のため、直打ち可能な状態
          next();
        },
      },
      {
        path: '/remaining-work/external/result',
        name: '外部用 宅内工事確認結果',
        component: RemainingWorkExternalResult,
        meta: {
          title: 'Portas | 宅内工事確認結果 | ポルタス',
          description: 'Portas（ポルタス）での宅内工事確認結果のご確認はこちらから行ってください。確認',
          keywords: '宅内工事確認結果',
          isPublic: true,
        },
        beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
          // 直前のURLが「外部用 宅内工事確認 部屋番号入力」でなければ総合トップに遷移する
          if (from.path !== '/remaining-work/external/room-number-input') {
            next({ path: '/' });

            return;
          }

          next();
        },
      },

      ...platformRouter,
      ...eMansionRouter,
      ...mcloudRouter,
      ...ucomRouter,
      ...connectixRouter,
      ...fiveARouter,
      ...tsunaguOnlineProductRouter,
      ...gamewithRouter,
    ],
  },
  {
    path: '/login-callback',
    name: 'ログイン後コールバック',
    component: LoginCallback,
    meta: {
      isPublic: true,
      title: 'ログイン後コールバック',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      /**
       * Auth0ログインに必要な情報をクエリパラメータで持って遷移してくるが、クエリパラメータがないとき、総合トップへ遷移させる
       * 具体的には、login-callback?code=... のように ?code=... 以降の文字列を伴って遷移してくる
       * auth0Client.loginWithRedirect による一連の画面遷移を経て、 login-callback にやってくる
       */
      if (to.fullPath.indexOf('?') === -1) {
        next({ path: '/' });
      }
      // URLに#と=が同時に入るとvue-routerのquerySelectorでエラーが出てしまうため
      // それを歯止めるためのチェック処理を追加 ARN_QA-928
      if (to.hash.indexOf('=') != -1) {
        next({ path: '/' });
      }
      next();
    },
  },
  {
    path: '/login-forwarding',
    name: 'アカウント登録踏み台',
    component: LoginForwarding,
    meta: {
      isPublic: true,
      title: 'アカウント登録踏み台',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // URLに#と=が同時に入るとvue-routerのquerySelectorでエラーが出てしまうため
      // それを歯止めるためのチェック処理を追加 ARN_QA-928
      if (to.hash.indexOf('=') != -1) {
        next({ path: '/' });
      }
      next();
    },
  },
  {
    path: '/sub-account-linkage/stepping/:from',
    name: 'sub-account-linkage-stepping',
    component: SubAccountLinkageStepping,
    meta: {
      isPublic: true,
      title: 'アカウント連携',
    },
  },
  {
    path: '/maintenance',
    name: 'メンテナンス',
    component: Maintenance,
    meta: {
      title: 'Portas | メンテナンス | ポルタス',
      description: 'メンテナンス中',
      keywords: 'メンテナンス',
      isPublic: true,
    },
  },
  {
    path: '/',
    component: Index,
    children: [
      // 存在しない URL に遷移した場合 : 必ず Router の最後に記述すること
      {
        path: '/:pathMatch(.*)',
        name: 'Not Found',
        component: NotFound,
        meta: {
          title: 'Portas | Not Found | ポルタス',
          description: 'お探しのページは見つかりませんでした。',
          keywords: 'Not Found',
          isPublic: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  /** スクロールの振る舞いを指定 https://router.vuejs.org/ja/guide/advanced/scroll-behavior.html */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { top: savedPosition.top, left: savedPosition.left };
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { top: 0, left: 0 };
  },
});

/** カードなし滞納有りの場合 */
async function isNoCardInArrears(to: RouteLocationNormalized): Promise<boolean> {
  if (to.path.endsWith('/e-mansion/error')) {
    return false;
  }

  const NO_CARD_IN_ARREARS = '2'; // カード登録なし滞納
  const customer: EMansionCustomer = await store.getters['eMansionCommonStore/customer'];

  //初回ログイン時等ISPにたどり付けていないユーザは空のため、支払のチェックは出来ない
  if (customer) {
    return customer.defaulter_status === NO_CARD_IN_ARREARS;
  }
  return false;
}

router.beforeEach(async (to: RouteLocationNormalized, _from, next) => {
  if (!dataDogRealUserMonitoring.isAlreadySetUser()) {
    dataDogRealUserMonitoring.setUser();
  }
  // ユーザの画面遷移履歴を蓄積する
  UserScreenTransitionHistoryWithStore.addHistory(to, _from);

  // エラー画面、メンテナンス画面、ただいまメンテナンス中です画面は未ログインでも表示させる
  const PAGES = ['/error', '/maintenance', '/platform/maintenance'];
  if (PAGES.indexOf(to.path) !== -1) {
    return next();
  }

  if (await NoticeRevisionUpdate.check()) {
    NoticeRevisionUpdate.notice();
  }

  // 共通メンテナンス画面表示判定
  // Portas画面マスタ取得（結果、取得できない場合はマスタ不備のためエラー）
  const mScreen: MScreenInfo = await store.dispatch('maintenanceScreenStore/mScreen', to.path);

  // Portas画面マスタを取得できた場合、メンテナンス判定を行う
  // ※取得できなかった場合、メンテナンス判定を行わない
  if (mScreen) {
    // メンテナンス判定
    // メンテナンス情報を取得できた場合、メンテナンス扱いとする
    const maintenanceScreen: MaintenanceScreenInfo = await store.dispatch('maintenanceScreenStore/maintenanceScreen', mScreen.screenId);
    if (maintenanceScreen) {
      // メンテナンス扱い（ただいまメンテナンス中です画面に遷移）
      return next('/platform/maintenance');
    }
  }

  // ログイン済なら通常どおり遷移させる
  const isLoggedIn = await AuthService.isLoggedIn();

  // 公開ページなら通常どおり遷移させる
  if (to.meta?.isPublic) {
    return next();
  }

  if (isLoggedIn) {
    await PortasDBCacheWithStore.main(to);
    if (to.path !== '/platform' && to.path !== '/platform/my-page') {
      if (await isNoCardInArrears(to)) {
        store.commit('errorStore/messages', ['大変申し訳ございません。現在 e-mansionメニューをご利用いただけません。']);
        return next('/e-mansion/error');
      }
    }
    return next();
  }

  // その他の場合、総合トップへ遷移させる
  return next({ path: '/' });
});

export default router;

// e-mansionユーザでない場合画面遷移を制御する
export async function isTransitionEmansion(): Promise<boolean> {
  if (!(await AuthService.getEMansionMemberId())) {
    return false;
  }
  return true;
}

// UCOMユーザでない場合画面遷移を制御する
export async function isTransitionUcom(): Promise<boolean> {
  if (!(await AuthService.getUcomMemberId())) {
    return false;
  }
  return true;
}

// Mcloudユーザでない場合画面遷移を制御する
export async function isTransitionMcloud(): Promise<boolean> {
  if (!(await AuthService.getMcloudMemberId())) {
    return false;
  }
  return true;
}

// 各種サービスが紐づいている場合画面遷移を制御する
export async function isTransitionNoLinked(uaType: string): Promise<boolean> {
  const property: Property = store.getters['propertyStore/property'];

  //propertyがない場合（物件情報の紐づけがない状態で遷移）とuatypeがDBと合わない場合false
  if (!property || property.uaType !== uaType) {
    return false;
  }
  if (!(await AuthService.getEMansionMemberId()) && !(await AuthService.getUcomMemberId())) {
    return true;
  }
  return false;
}
