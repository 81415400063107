<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールパスワード変更</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>メールパスワード変更</li>
      </ul>
      <!-- /breadcrumb -->
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->
      <!-- blc:アカウントID登録変更-->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールパスワード変更" />変更お申し込み</h2>
        変更後のパスワードを入力し、「登録」ボタンをクリックしてください。

        <div class="err" v-if="message">
          <p class="red">恐れ入りますが、入力内容をもう一度ご確認ください。</p>
          <ul class="ul-basic01">
            <li>
              {{ message }}
            </li>
          </ul>
        </div>

        <table class="table-type2">
          <tr>
            <th class="va-middle">変更対象メールアドレス</th>
            <td>{{ mailAddress }}</td>
          </tr>
          <tr>
            <th class="va-middle">パスワード<span class="req">必須</span></th>
            <td>
              <p class="att red mt10">【半角英数小文字】「4～12文字以内」でご指定ください。</p>
              <p class="att grey mt10">※記号や大文字(Aなど)は利用できません。</p>

              <div class="formItem">
                <input :type="inputType" class="input-pass short" placeholder="" id="password" v-model="newPassword" />
                <span v-if="!isPasswordView" class="material-icons material-symbols-outlined" v-on:click="onPasswordView()"> visibility </span>
                <span v-if="isPasswordView" class="material-icons material-symbols-outlined" v-on:click="onPasswordView()"> visibility_off </span>
              </div>
              <p class="att mt10">確認のためもう一度ご入力ください</p>

              <div class="formItem">
                <input :type="inputTypeConf" class="input-pass short" placeholder="" id="password2" v-model="newPasswordConf" />
                <span v-if="!isPasswordViewConf" class="material-icons material-symbols-outlined" v-on:click="onPasswordViewConf()"> visibility </span>
                <span v-if="isPasswordViewConf" class="material-icons material-symbols-outlined" v-on:click="onPasswordViewConf()"> visibility_off </span>
                <span class="field-icon">
                  <i toggle="#password-field" class="mdi mdi-eye toggle-password"></i>
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button v-if="canChange" class="btn btn01 bs" v-on:click="onChange()">登録<i class="material-icons link link-icon">east</i></button>
          <button v-else class="btn btn05 bs" disabled>登録<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>
<style>
.input-pass {
  border: none;
  outline: none;
}
.formItem {
  width: fit-content;
  border: #cacaca solid 1px;
}
::-ms-reveal {
  display: none;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMailPasswordAccessor } from '../../../infra/accessor/spf/e-mansion/spf-api-mail-password-accessor';
import { EMansionMailPasswordInput } from '@/shared/classes/spf-api/mail/model/mail-pw/e-mansion-mail-password-input';
import { EMansionMailPasswordUpdate } from '@/shared/classes/spf-api/mail/model/mail-pw/e-mansion-mail-password-update';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import store from '@/store';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
export default defineComponent({
  name: 'eMansionPasswordInput',
  components: {
    LoadingComponent,
  },
  data: () => ({
    accountInfo: null as EMansionAccountInfo | null,
    mailAddress: '',
    message: '',
    isPasswordView: false,
    isPasswordViewConf: false,
    canChange: false,
    isLoading: true,
    isChecked: false,
    newPassword: '',
    newPasswordConf: '',
  }),
  computed: {
    inputType: function () {
      return this.isPasswordView ? 'text' : 'password';
    },
    inputTypeConf: function () {
      return this.isPasswordViewConf ? 'text' : 'password';
    },
  },
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //e-mansion_アカウントIDを取得
      this.accountInfo = store.getters['eMansionAccountStore/eMansionAccountInfo'];
      //AE11-001を呼ぶ
      const eMansionMailPasswordInputInfo: EMansionMailPasswordInput = await SpfApiMailPasswordAccessor.getMailPasswordInputInfo(
        this.accountInfo?.eMansionAccountInfo.eMansionAccountId ?? -1
      );
      if (eMansionMailPasswordInputInfo.mailAddressPossessionMessage) {
        this.message = eMansionMailPasswordInputInfo.mailAddressPossessionMessage;
        this.isLoading = false;
        return;
      }
      this.canChange = true;
      this.mailAddress = eMansionMailPasswordInputInfo.mailAddress ?? '';
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**登録ボタンクリック */
    async onChange() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //バリデーションチェック
      if (!this.newPassword) {
        this.message = '「パスワード」を入力してください。';
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      if (this.newPassword.length < 4) {
        this.message = '「パスワード」は4文字以上12文字以内で入力してください。';
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      if (this.newPassword.length > 12) {
        this.message = '「パスワード」は4文字以上12文字以内で入力してください。';
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      if (!this.newPassword.match(/^[0-9a-z]*$/)) {
        this.message = '「パスワード」は半角英数小文字で指定してください。';
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      if (this.newPassword != this.newPasswordConf) {
        this.message = '「パスワード」の入力と、確認用に入力された内容が一致しません。もう一度新たに入力しなおしてください。';
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      try {
        const date = this.formatDate(await SpfApiServerCommonUtilAccessor.getServerTimeNow());
        //パスワードを暗号化
        const obfuscationPassword = ObfuscationService.encode(this.newPassword);
        //AE11-002を呼ぶ
        const mailPasswordUpdateResponse: EMansionMailPasswordUpdate = await SpfApiMailPasswordAccessor.mailPasswordUpdate(
          this.accountInfo?.eMansionAccountInfo.eMansionAccountId ?? -1,
          obfuscationPassword,
          date
        );

        if (mailPasswordUpdateResponse.message.length > 0) {
          this.message = mailPasswordUpdateResponse.message[0];
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } catch (error) {
        // エラー画面に遷移
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      this.$store.commit('eMansionMailPasswordStore/newPassword', this.newPassword);
      this.$store.commit('eMansionMailPasswordStore/mailAddress', this.mailAddress);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/mail-password/complete').catch((error: any) => {
        checkRouterError(error);
      });

      this.isLoading = false;
      return;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    onPasswordView() {
      this.isPasswordView = !this.isPasswordView;
    },
    onPasswordViewConf() {
      this.isPasswordViewConf = !this.isPasswordViewConf;
    },
  },
});
</script>
