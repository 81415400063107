/** API : エラー時のレスポンス */
export class AuthManegementSharedErrorResponse {
  /** エラーメッセージ */
  public errors!: Array<string>;
  public errorInfo!: Array<string>;
  public status?: number;

  constructor(partial: Partial<AuthManegementSharedErrorResponse>) {
    Object.assign(this, partial);
  }
}
