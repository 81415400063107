import { MEMBER_STATUS } from '../const/member-status';
import { DISPLAY_PORTAL_PATTERN, ISP_MEMBER_STATUS } from '../const/service-type';
import { MemberStatus } from '../classes/spf-api/member-status';

/**
 * /platform で使われる
 * ISP退会が関係するときの portal-service-e-mansion の表示パターン
 *
 * | type                         | isp-member-status-ucom | portas-member-status |
 * | none                         | cancelAndLoginNG       | 3                    |
 * | none                         | cancelAndLoginNG       | 7                    |
 * | logo-and-link-to-member-page | cancelAndLoginOK       | 5                    |
 * | logo-and-link-to-member-page | cancelAndLoginOK       | 7                    |
 * | logo-and-link-to-member-page | transferAndCancel      | 5                    |
 * | logo-and-link-to-member-page | transferAndCancel      | 7                    |
 * | logo-and-link-to-member-page | inCancelApplication    | 5                    |
 * | logo-and-link-to-member-page | inCancelApplication    | 7                    |
 * | normal                       |                        |                      |
 */
export function getDisplayPortalPatternCaseEMansion(memberStatus: MemberStatus, ispMemberStatus: string) {
  if (ispMemberStatus === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_NG) {
    if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      return DISPLAY_PORTAL_PATTERN.E_MANSION.NONE;
    }
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      return DISPLAY_PORTAL_PATTERN.E_MANSION.NONE;
    }
  }

  const isExpectedIspMemberStatus = [
    ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION,
    ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL,
    ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
  ].some((value) => value === ispMemberStatus);

  const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === memberStatus.status);

  if (isExpectedIspMemberStatus && isExpectedPortasMemberStatus) {
    return DISPLAY_PORTAL_PATTERN.E_MANSION.LOGO_AND_LINK_TO_MEMBER_PAGE;
  }

  return DISPLAY_PORTAL_PATTERN.E_MANSION.NORMAL;
}

/**
 * /platform で使われる
 * ISP退会が関係するときの portal-service-ucom の表示パターン
 *
 * | type                         | isp-member-status-ucom | portas-member-status |
 * | none                         | cancelAndLoginNG       | 3                    |
 * | none                         | cancelAndLoginNG       | 7                    |
 * | logo-and-link-to-member-page | cancelAndLoginOK       | 5                    |
 * | logo-and-link-to-member-page | cancelAndLoginOK       | 7                    |
 * | normal                       |                        |                      |
 */
export function getDisplayPortalPatternCaseUcom(memberStatus: MemberStatus, ispMemberStatus: string) {
  if (ispMemberStatus === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_NG) {
    if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      return DISPLAY_PORTAL_PATTERN.UCOM.NONE;
    }
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      return DISPLAY_PORTAL_PATTERN.UCOM.NONE;
    }
  }

  const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === memberStatus.status);

  if (isExpectedPortasMemberStatus && ispMemberStatus === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
    return DISPLAY_PORTAL_PATTERN.UCOM.LOGO_AND_LINK_TO_MEMBER_PAGE;
  }

  return DISPLAY_PORTAL_PATTERN.UCOM.NORMAL;
}

/**
 * /platform で使われる
 * ISP退会が関係するときの portal-service-ucom の表示パターン
 * 
 * | type                         | isp-member-status-ucom | portas-member-status |
 * | none                         | cancelAndLoginNG       | 3                    |
 * | none                         | cancelAndLoginNG       | 7                    |
 * | logo-and-link-to-member-page | cancelAndLoginOK       | 5                    |
 * | logo-and-link-to-member-page | cancelAndLoginOK       | 7                    |
 * | logo-and-link-to-member-page | inCancelApplication    | 5                    |
 * | logo-and-link-to-member-page | inCancelApplication    | 7                    |
 * | normal                       |                        |                      |
 */
export function getDisplayPortalPatternCaseFiveA(memberStatus: MemberStatus, ispMemberStatus: string) {
  if (ispMemberStatus === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_NG) {
    if (memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      return DISPLAY_PORTAL_PATTERN.FIVE_A.NONE;
    }
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      return DISPLAY_PORTAL_PATTERN.FIVE_A.NONE;
    }
  }

  const isExpectedIspMemberStatus = [ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION, ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK].some((value) => value === ispMemberStatus);
  const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === memberStatus.status);

  if (isExpectedIspMemberStatus && isExpectedPortasMemberStatus) {
    return DISPLAY_PORTAL_PATTERN.FIVE_A.LOGO_AND_LINK_TO_MEMBER_PAGE;
  }

  return DISPLAY_PORTAL_PATTERN.FIVE_A.NORMAL;
}
