/** アカウントID管理：登録・更新 入力チェック結果 */
export class UcomAccountManagementInputCheck {
  readonly _errorMessages!: string[]; //チェック結果メッセージ
  
  constructor(errorMessages: string[]) {
    this._errorMessages = errorMessages;
  }

  get errorMessages(): string[] {
    return this._errorMessages;
  }
}
