<template>
  <div class="products-cancel-completed">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>サービス解約お申し込み 完了</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li>サービス解約お申し込み完了</li>
      </ul>

      <!-- 商品解約確認画面(Connectixが解約対象に含まれるとき) -->
      <template v-if="hasConnectix">
        <ul class="application-flow grid pc-grid5 sp-grid3 gap10">
          <li>解約商品選択</li>
          <li>確認</li>
          <li class="stay">サブスク商品解約完了</li>
          <li>Connectix解約</li>
          <li>Connectix解約完了</li>
        </ul>
      </template>
      <!-- 商品解約確認画面(Connectixが解約対象に含まれないとき) -->
      <template v-else>
        <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
          <li>解約商品選択</li>
          <li>確認</li>
          <li class="stay">完了</li>
        </ul>
      </template>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="商品解約 完了" />ご解約受付完了</h2>
        <div class="att red mt10 toBold">下記商品のご解約受け付けました。</div>
        <div class="mt10">お手続き内容をメールにてお送りしました。</div>

        <div v-if="hasConnectix">
          <div class="att red mt10 toBold">Connectixのご解約はまだ実施されていません。</div>
          <div class="att red toBold">「Connectixの解約に進む」をクリックし、Connectixの解約に進んでください。（Connectix提供元サイトへ遷移します）</div>
        </div>
      </div>

      <table class="table-type2">
        <tbody>
          <tr v-for="dispContractSubscription in dispCancelSubscriptionList" :key="dispContractSubscription.contractId">
            <th><span v-html="dispContractSubscription.products.productsName"></span></th>
            <td>
              <div>解約お申し込み日：{{ formatDate(dispContractSubscription.cancelApplicationDate) }}</div>
              <div>サービス終了日：{{ formatDate(dispContractSubscription.cancelDate) }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blc">
        <template v-if="hasConnectix">
          <p class="form-btn-txt">上記内容をご確認の上、「Connectixの解約に進む」ボタンをクリックしてください。</p>
          <div class="btn-area">
            <button class="btn btn01 bs" @click="onNextConnectixCancelPage()">Connectixの解約に進む<i class="material-icons link link-icon">east</i></button>
          </div>
        </template>
        <div class="btn-area">
          <button class="btn btn01 bs disp-sp-button-mt10" @click="onBack()">マイページへ<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs disp-sp-button-mt10" @click="onNextServiceCancelComplete()">トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { CancellationInfo } from '@/shared/classes/spf-api/cancellation-info';
import { SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { formatDate } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Property } from '../../../../shared/classes/spf-api/property';

export default defineComponent({
  name: 'cancel-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** ボタン押下判定 */
    isSubmitting: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** 表示用リスト */
    dispCancelSubscriptionList: [] as SubscriptionDetailsDto[],
    /** 解約対象Connectix有無 */
    hasConnectix: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 解約結果取得処理
    // 解約完了契約 Store から 情報を取得
    const productCancelResultList: CancellationInfo[] = this.$store.getters['productCancelResultStore/productCancelResult'];

    // Connectix解約要否取得処理
    // 解約対象Connectix有無 Store から 情報を取得
    this.hasConnectix = this.$store.getters['hasConnectixSubjectToCancelStore/hasConnectix'];

    // 解約対象契約情報取得処理
    // 解約対象契約ストア から 解約対象契約情報を取得する
    const contractsSubjectToCancelInfoList: SubscriptionDetailsDto[] = this.$store.getters['contractsSubjectToCancelStore/products'];

    // 画面表示用変数を取得する
    this.dispCancelSubscriptionList = this.getDisplaySubscriptionList(contractsSubjectToCancelInfoList, productCancelResultList);

    // 「解約対象契約」ストア初期化処理
    this.$store.commit('contractsSubjectToCancelStore/products', null);

    this.isLoading = false;
  },
  methods: {
    /**
     * 取得した解約対象契約情報から 解約完了契約の取得結果.各契約 ID に紐づく契約の商品情報を取得し、画面表示用変数に保持する
     *
     * @param contractsSubjectToCancelInfo 解約対象契約情報
     * @param cancellationInfoList 解約完了契約の取得結果
     * @return 画面に表示する商品情報リスト
     */
    getDisplaySubscriptionList(contractsSubjectToCancelInfoList: SubscriptionDetailsDto[], cancellationInfoList: CancellationInfo[]): SubscriptionDetailsDto[] {
      let displaySubscriptionList: SubscriptionDetailsDto[] = [];

      for (const contractsSubjectToCancelInfo of contractsSubjectToCancelInfoList) {
        for (const cancellationInfo of cancellationInfoList) {
          if (contractsSubjectToCancelInfo.contractId === cancellationInfo.contractId) {
            // 解約完了契約 ストア の値、解約受付日時, 解約予定日 を画面表示用変数に渡す
            contractsSubjectToCancelInfo.cancelApplicationDate = new Date(cancellationInfo.cancellationAcceptedAt);
            contractsSubjectToCancelInfo.cancelDate = cancellationInfo.cancellationScheduledDate;

            displaySubscriptionList.push(contractsSubjectToCancelInfo);
            break;
          }
        }
      }
      return displaySubscriptionList;
    },
    /* *マイページへ ボタン */
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/platform/my-page')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** トップページへ ボタン */
    async onNextServiceCancelComplete() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** Connectixの解約に進む ボタン */
    onNextConnectixCancelPage() {
      // 物件情報取得
      const property: Property = this.$store.getters['propertyStore/property'];

      let url = '';

      switch (property?.uaType) {
        case UA_TYPE.E_MANSION:
          url = this.createUrl(process.env.VUE_APP_E_MANSION_CONNECTIX_CANCEL_URL, property.apartmentId);
          break;
        case UA_TYPE.FIVE_A:
          url = this.createUrl(process.env.VUE_APP_FIVE_A_CONNECTIX_CANCEL_URL, property.apartmentId);
          break;
        case UA_TYPE.UCOM:
          url = process.env.VUE_APP_UCOM_CONNECTIX_CANCEL_URL;
          break;
      }

      if (url) {
        window.open(url, '_blank');
      }
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /**
     * Connectix 解約 URL生成関数
     *
     * @param baseUrl
     * @param apartmentId
     * @return Connectix 解約 URL(env.baseUrl + '&apid=' + apid + '&rd=' + rd)
     */
    createUrl(baseUrl: string, apartmentId: string | undefined): string {
      let apid = '';
      const rd = this.createRd();

      if (apartmentId) {
        apid = convert6DigitIdTo8DigitId(apartmentId);
      }

      if (apid == null || apid === '' || rd == null || rd === '') {
        throw new Error();
      }

      return baseUrl + '&apid=' + apid + '&rd=' + rd;
    },
    /**
     * 日付を "YYYY年M月D日" 形式にフォーマットする
     * @param date - フォーマットする前の日付文字列
     * @return フォーマットされた日付文字列
     */
    formatDate(date: string | Date | undefined): string {
      if (date == null) {
        return '';
      }
      return formatDate(date, 'YYYY年M月D日');
    },
  },
});
</script>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.toBold {
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .disp-sp-button-mt10 {
    margin-top: 10px;
  }
  .btn-area {
    margin: 0;
  }
}
</style>
