<template>
  <div>
    <LoadingComponent v-if="isMounting" />

    <!-- ISP退会後13ヶ月間の表示: ロゴとISP会員専用トップ -->
    <template v-if="displayPortalPattern === DISPLAY_PORTAL_PATTERN.LOGO_AND_LINK_TO_MEMBER_PAGE">
      <div class="blc">
        <!-- サブスク商品 Portas会員ステータスが退会申し込みのとき表示しない -->
        <template v-if="displayPortasSubscriptionPurchaseLead">
          <SubscriptionCommonComponent />
        </template>
      </div>

      <div class="blc">
        <div>
          <section class="my-portal-service">
            <h2 class="my-portal-service__title">インターネットサービス</h2>
            <div class="my-portal-service__image">
              <img src="../../../images/logo-ucom.png" alt="UCOM光 レジデンス" />
            </div>
            <p class="my-portal-service__description">
              お住まいの建物には、UCOM光 レジデンス インターネットサービスが提供されております。サービス内容やお問い合わせ先はお住まいの建物専用サイトをご覧ください。
            </p>
            <a class="link" :href="getForBuildingUrl()" target="UCOM">＞ お住まいの建物専用サイトはこちら</a>
          </section>
        </div>
      </div>

      <!-- UCOM光 レジデンス 会員専用トップページ -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光 レジデンス会員専用トップページ" />UCOM光 レジデンス会員専用トップページ</h2>
        <p class="my-description">UCOM光 レジデンスご契約情報の登録変更やオプションサービスのお申し込み・変更は<router-link to="/ucom" class="link">こちら</router-link></p>
      </div>

      <!-- つなぐオンラインショップ  -->
      <template v-if="displayTsunaguOnlineShop()">
        <div class="blc">
          <TsunaguOnlineShop
            @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPortalServiceUcom()"
            @updateDisplayPortalPatternFromTsunaguOnlineShop="updateDisplayPortalPatternAtUcom()"
          />
        </div>
      </template>

      <!-- Mcloudを連携している場合 -->
      <template v-if="isLinkedToMcloud">
        <div class="blc">
          <McloudCommonComponent />
        </div>
      </template>

      <!-- Mcloudを連携していないかつportas退会申し込み中でない場合 -->
      <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud">
        <div class="blc">
          <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
        </div>
      </template>
    </template>

    <!-- ISP会員のときの表示: 通常表示 -->
    <template v-else-if="displayPortalPattern === DISPLAY_PORTAL_PATTERN.NORMAL">
      <div class="blc">
        <!-- サブスク商品 Portas会員ステータスが退会申し込みのとき表示しない -->
        <template v-if="displayPortasSubscriptionPurchaseLead">
          <SubscriptionCommonComponent />
        </template>
      </div>
      <div class="blc">
        <div>
          <section class="my-portal-service">
            <h2 class="my-portal-service__title">インターネットサービス</h2>
            <div class="my-portal-service__image">
              <img src="../../../images/logo-ucom.png" alt="UCOM光 レジデンス" />
            </div>
            <p class="my-portal-service__description">
              お住まいの建物には、UCOM光 レジデンス インターネットサービスが提供されております。サービス内容やお問い合わせ先はお住まいの建物専用サイトをご覧ください。
            </p>
            <a class="link" :href="getForBuildingUrl()" target="UCOM">＞ お住まいの建物専用サイトはこちら</a>
          </section>

          <!-- UCOM会員登録完了画面から遷移したきたときの表示 -->
          <template v-if="transitionViaUcomEntryCompleted">
            <div class="my-display-message-when-transition-via-ucom-entry-completed">
              <p class="my-description">※UCOM光 レジデンス会員登録完了後、以下のリンクを選択してください。UCOM光 レジデンス会員専用トップページへ移動します。</p>
              <p class="my-description red"><a class="link" @click.prevent="clickedLinkToUcom()">▶UCOM光 レジデンス会員専用トップページへ</a></p>
              <p class="my-description">会員情報の連携まで数分かかる場合がございます。</p>

              <!-- UCOM会員専用トップへ遷移するリンクをクリック後、UCOMお客様番号未連携のときの表示 -->
              <template v-if="displayMessageWhenNotLinkToUcom">
                <div>
                  <p class="my-description"><span class="red">【UCOM光 レジデンス】インターネット接続サービス登録証のお知らせ　</span>メールを受信されている場合</p>
                  <p class="my-description">少し時間をおいて再度UCOM光 レジデンス会員専用トップページへをご選択ください。</p>
                </div>
                <div>
                  <p class="my-description"><span class="red">【UCOM光 レジデンス】お客様情報登録　受付完了のお知らせ　</span>メールを受信されている場合</p>
                  <p class="my-description">
                    ご登録いただいた内容を確認しております。登録が完了次第、インターネット接続サービス登録証のお知らせをお送りいたします。お手数ですがインターネット接続サービス登録証のお知らせを受信後Portas連携を行ってください。
                  </p>
                </div>
              </template>
            </div>
          </template>

          <!-- 未完了の残工事があった場合の表示 -->
          <div v-if="isConstructionUnfinished" class="blc">
            <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="宅内工事お申し込み状況" />宅内工事お申し込み状況</h2>
            <p>宅内工事のお申し込みを受け付けました。担当部署より順次、ご入力いただいたお電話番号へご連絡いたします。</p>
            <p>
              宅内工事完了後もインターネット接続が出来ない場合、お手数ですが<a class="link" :href="`${ucomLink}`" target="_blank">こちら</a
              >からお問い合わせ先を確認いただきサポートセンターまでお問い合わせください。
            </p>
            <p>この表示は、宅内工事完了後も表示される場合がございます。</p>
          </div>

          <!-- UCOM光からのお知らせ -->
          <div class="blc">
            <h2 class="portal-h2 cf">
              <img src="../../../images/service-h2.svg" alt="UCOM光からのお知らせ" />UCOM光 レジデンスからのお知らせ
              <a href="https://help.ucom.ne.jp/trouble/index.html" target="UCOM" class="link">広域での障害はこちら<i class="material-icons link link-icon">arrow_right_alt</i></a>
            </h2>

            <template v-if="emInfoMessages.length">
              <div class="service-news border-grey">
                <ul>
                  <li class="list-none" v-for="(message, index) in emInfoMessages" :key="index">
                    <span class="noticelist">{{ getKindString(message.message_kind) }}</span>
                    <b>{{ message.message_date }} </b>
                    <span v-if="message.message_link">
                      <a :href="message.message_link" target="UCOM" v-html="message.message_label"></a>
                    </span>
                    <span v-if="!message.message_link" v-html="message.message_label"></span>
                  </li>
                </ul>
              </div>
            </template>
          </div>

          <template v-if="member && !member.primaryKeyUcom">
            <!-- UCOM光 レジデンス 会員登録: 入会可能物件の場合 -->
            <template v-if="property && property.is_possible_registration">
              <div class="blc">
                <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光 レジデンス 会員登録" />UCOM光 レジデンス 会員登録</h2>

                <!-- Portas会員情報.生年月日が登録されていない場合 -->
                <template v-if="isEmptyBirthdate()">
                  <p class="my-description">Portasマイページから生年月日をご登録いただいた後、UCOM光 レジデンス会員登録のお手続きをお願いいたします。</p>
                  <p class="my-description"><router-link to="/platform/my-page" class="link">Portasマイページはこちら</router-link></p>
                  <p class="my-description margin-top-8">UCOM光 レジデンスの会員契約は、1住戸に1名となります。</p>
                </template>

                <!-- Portas会員情報.生年月日が登録されているが、18歳未満の場合 -->
                <template v-else-if="isUnder18()">
                  <p class="my-description">オプションサービスご利用にはUCOM光 レジデンス会員登録が必要です。</p>
                  <p class="my-description">
                    <a class="link" :href="getForBuildingUrl()" target="UCOM">建物専用サイト（こちら）</a
                    >から、サポートセンターのお問い合わせ先をご確認いただき、新規登録のお申し込みをお願いいたします。
                  </p>
                  <p class="my-description margin-top-8">UCOM光 レジデンスの会員契約は、1住戸に1名となります。</p>
                </template>

                <!-- Connectix提供物件の場合 -->
                <template v-else-if="isProvidedConnectix">
                  <p class="my-description">
                    Connectixなどオプションサービスご利用にはUCOM光 レジデンス会員登録が必要です。<br />
                    お済みでない場合は<a href="#" class="link" @click="importFromIdCheck">こちらから</a>ご登録ください。
                  </p>
                  <p class="my-description margin-top-8">UCOM光 レジデンスの会員契約は、1住戸に1名となります。</p>
                </template>

                <template v-else>
                  <p class="my-description">
                    オプションサービスご利用にはUCOM光 レジデンス会員登録が必要です。<br />
                    お済みでない場合は<a href="#" class="link" @click="importFromIdCheck">こちらから</a>ご登録ください。
                  </p>
                  <p class="my-description margin-top-8">UCOM光 レジデンスの会員契約は、1住戸に1名となります。</p>
                </template>
              </div>
            </template>
            <div v-if="fraudImportFormId" class="err err-margin">
              <div>
                システムエラーが発生いたしました。お手数ですが、<a :href="getForBuildingUrl()" target="same_tab"
                  ><span class="link red"><u>こちら</u></span> </a
                >からお問い合わせ先を確認いただきサポートセンターまでお問い合わせください。
              </div>
            </div>

            <!-- UCOM光 レジデンス 会員登録がお済みの場合 -->
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光 レジデンス 会員登録がお済みの場合" />UCOM光 レジデンス 会員登録がお済みの場合</h2>
              <p>UCOM光 レジデンスオプションサービスご利用前に、<router-link to="/ucom/linking" class="link">こちらから</router-link>お客様番号をPortasに連携してください。</p>
            </div>
          </template>

          <template v-else>
            <!-- UCOM光 レジデンス 会員専用トップページ -->
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光 レジデンス会員専用トップページ" />UCOM光 レジデンス会員専用トップページ</h2>
              <p class="my-description">UCOM光 レジデンスご契約情報の登録変更やオプションサービスのお申し込み・変更は<router-link to="/ucom" class="link">こちら</router-link></p>
            </div>
          </template>

          <!-- Connectixお申し込み: Connectix提供物件 かつ Connectix未契約 -->
          <template v-if="isProvidedConnectix && !isContractedConnectix && customer">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectixお申し込み" />Connectixお申し込み</h2>
              <div class="table-scroll js-scrollable">
                <table class="table">
                  <tr>
                    <th colspan="2">サービス</th>
                    <th>料金</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td colspan="2"><p>Connectix</p></td>
                    <td>{{ customer.connectix_price }}</td>
                    <td colspan="2">
                      <div class="connectix-link">
                        <router-link to="/connectix/ucom/terms">お申し込み</router-link>
                      </div>
                    </td>
                  </tr>
                </table>
                <p class="att grey">
                  ※価格は（税抜）の記載がないものは全て、新税率に基づく税込表示（消費税10%）です。今後消費税が改正された場合は、改正後の税率による価格に変更となります。
                </p>
              </div>
            </div>
          </template>

          <!-- インターネット通信速度にお困りの方へ -->
          <template v-if="isProvidedConnectix">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
              <ul class="my-card-list grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a href="https://speedtest.tsunagunet.com/u/" target="UCOM">
                        <img src="../../../images/ico01.png" alt="スピードテスト" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">自宅の通信環境をチェック</h4>
                      <p class="my-card-content__description">現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                      <p class="my-card-content__link-text">
                        <a href="https://speedtest.tsunagunet.com/u/" target="UCOM" class="link"> スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i> </a>
                      </p>
                    </div>
                  </section>
                </li>
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a href="https://www.tsunagunet.com/internet/kaizen.html" target="UCOM">
                        <img src="../../../images/ico02.png" alt="通信の改善" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">接続速度が遅いと感じた場合</h4>
                      <p class="my-card-content__description">
                        モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                      </p>
                      <p class="my-card-content__link-text">
                        <a href="https://www.tsunagunet.com/internet/kaizen.html" target="UCOM" class="link">
                          改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                        </a>
                      </p>
                    </div>
                  </section>
                </li>
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a :href="connectixLpUrl" target="UCOM">
                        <img src="../../../images/ico03.png" alt="オプション" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">オンライン会議や動画などもっと快適なインターネット環境を試したい方は…</h4>
                      <p class="my-card-content__description">インターネットオプションサービス「Connectix」お申し込みのお客様専用の優先ルートで快適インターネット</p>
                      <p class="my-card-content__link-text">
                        <a :href="connectixLpUrl" class="link" target="UCOM">詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                      </p>
                    </div>
                  </section>
                </li>
              </ul>
            </div>
          </template>

          <!-- インターネット通信速度にお困りの方へ: Connectix提供不可物件の場合 -->
          <template v-else>
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
              <ul class="my-card-list grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a href="https://speedtest.tsunagunet.com/ucom/" target="UCOM">
                        <img src="../../../images/ico01.png" alt="スピードテスト" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">自宅の通信環境をチェック</h4>
                      <p class="my-card-content__description">現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                      <p class="my-card-content__link-text">
                        <a href="https://speedtest.tsunagunet.com/ucom/" target="UCOM" class="link">
                          スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i>
                        </a>
                      </p>
                    </div>
                  </section>
                </li>
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a href="https://www.tsunagunet.com/internet/kaizen_u.html" target="UCOM">
                        <img src="../../../images/ico02.png" alt="通信の改善" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">接続速度が遅いと感じた場合</h4>
                      <p class="my-card-content__description">
                        モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                      </p>
                      <p class="my-card-content__link-text">
                        <a href="https://www.tsunagunet.com/internet/kaizen_u.html" target="UCOM" class="link">
                          改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                        </a>
                      </p>
                    </div>
                  </section>
                </li>
              </ul>
            </div>
          </template>

          <!-- つなぐオンラインショップ  -->
          <template v-if="displayTsunaguOnlineShop()">
            <div class="blc">
              <TsunaguOnlineShop
                @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPortalServiceUcom()"
                @updateDisplayPortalPatternFromTsunaguOnlineShop="updateDisplayPortalPatternAtUcom()"
              />
            </div>
          </template>

          <!-- Mcloudを連携している場合 -->
          <template v-if="isLinkedToMcloud">
            <div class="blc">
              <McloudCommonComponent />
            </div>
          </template>

          <!-- Mcloudを連携していないかつportas退会申し込み中でない場合 -->
          <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud">
            <div class="blc">
              <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
            </div>
          </template>
        </div>
      </div>
    </template>

    <template v-if="isDisplayFAQComponent()">
      <div class="blc">
        <FrequentlyAskedQuestions :serviceType="getServiceType()" :isDisplayConnectixFAQ="getDisplayConnectixFAQFlag()" :ucom-building-url="getForBuildingUrl()" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.my-portal-service {
  &__title {
    display: inline-block;
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.6;
    border-bottom: 3px solid #0097e0;
  }

  &__image {
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 10px;
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
}

p.my-description {
  line-height: 1.6;
  letter-spacing: 0.8px;

  &.margin-top-8 {
    margin-top: 8px;
  }
}

ul.my-card-list {
  list-style: none;
  margin: 0;
  padding: 0;

  & li.my-card-list__item {
    border: 1px solid #d3d3d3;
    text-align: center;
  }
}

section.my-card-content {
  & div.my-card-content__image {
    & img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  & div.my-card-content__text {
    padding: 10px 30px 20px;
    text-align: left;

    & h4.my-card-content__title {
      margin: 0;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 25px;
    }

    & p.my-card-content__description {
      line-height: 1.6;
      letter-spacing: 0.8;
    }

    & p.my-card-content__link-text {
      text-align: right;
    }
  }
}

.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;

  & ul {
    padding: 0;

    & li {
      padding: 15px 0 15px 20px;
      border-bottom: 1px solid #cf1225;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        box-sizing: border-box;
        border: 6px solid transparent;
        border-left: 10px solid #cf1225;
      }
    }
  }
}

.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

table.table {
  margin: 40px 0 10px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;

  & th {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    background-color: #fbfbfb;
  }

  & td {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;

    & div.connectix-link {
      display: flex;
      justify-content: center;

      & a {
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
        color: #d11c2e;
        border: 1px solid #d11c2e;
        border-radius: 3px;
      }
    }
  }
}

.my-display-message-when-transition-via-ucom-entry-completed {
  margin-top: 24px;

  & a.link {
    cursor: pointer;
  }

  & div {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .my-portal-service {
    &__title {
      font-size: 18px;
    }
  }

  .service-news {
    padding: 0 20px 15px;
  }

  section.my-card-content {
    & div.my-card-content__text {
      padding: 10px 15px 20px;
    }
  }

  div.table-scroll {
    & table {
      width: 780px;
    }
  }
}

.err-margin {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import FrequentlyAskedQuestions from '../../components/frequently-asked-questions.vue';
import McloudCommonComponent from '../../components/mcloud-common.vue';

import SubscriptionCommonComponent from './subscription-common.vue';

import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty, EmInfoMessage } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UcomAccountInfo } from '@/shared/classes/spf-api/mail/model/common/permission/ucom/ucom-account-info';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { UserScreenTransitionInfo } from '@/shared/classes/user/user-screen-transition';
import LoadingComponent from '@/shared/components/loading-component.vue';
import TsunaguOnlineShop from '@/shared/components/tnos/tsunagu-online-shop.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { DISPLAY_PORTAL_PATTERN, ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getDisplayPortalPatternCaseUcom } from '@/shared/util/func-get-display-portal-pattern-isp-withdrawal';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { isEmptyBirthdate } from '@/shared/util/func-is-empty-birthdate';
import { isUnder18 } from '@/shared/util/func-is-under-18';
import { leaveFontRedAndBrTag } from '@/shared/util/func-leave-font-red-and-br-tag';
import { ProcessesCalledInUcomPages } from '@/shared/util/processes-called-in-ucom-page';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'portal-service-ucom',
  components: {
    LoadingComponent,
    FrequentlyAskedQuestions,
    McloudCommonComponent,
    TsunaguOnlineShop,
    SubscriptionCommonComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    // ISP会員ステータス
    ispMemberStatus: string;
    // portal表示パターン
    displayPortalPattern: string;
    // Portalの表示パターン定数
    DISPLAY_PORTAL_PATTERN: typeof DISPLAY_PORTAL_PATTERN.UCOM;
    // ucom物件基本情報
    property: UcomPropertyResponse | null;
    // ucom契約基本情報
    customer: UcomCustomerResponse | null;
    // Connectix提供物件かどうか
    isProvidedConnectix: boolean;
    // Connectix契約済みかどうか
    isContractedConnectix: boolean;
    // Connectix URL
    connectixLpUrl: string;
    // ucom サイト URL
    ucomUrl: string;
    // ucom 会員登録 URL
    ucomMemberRegistrationUrl: string;
    // お知らせ用メッセージ
    emInfoMessages: Array<EmInfoMessage>;
    // scroll-hint モジュール
    ScrollHint: any;
    // mounted() 実行中かどうか
    isMounting: boolean;
    // Mcloudと連携しているかどうか
    isLinkedToMcloud: boolean;
    // Mcloud未連携時にリンクを表示するかどうか
    isDisplayNotLinkedMcloud: boolean;
    // ucom新規会員登録完了から遷移してきたとき
    transitionViaUcomEntryCompleted: boolean;
    // ucom会員専用トップへのリンクをクリック後の処理を実行中かどうか
    isExecutingClickedLinkToUcom: boolean;
    // ucom会員専用トップへのリンクをクリック後、ucom未連携時のメッセージを表示するかどうか
    displayMessageWhenNotLinkToUcom: boolean;
    /** Portasサブスク商材の購入導線を表示するかどうか */
    displayPortasSubscriptionPurchaseLead: boolean;
    // 物件基本情報
    getUcomProperty: UcomPropertyResponse | null;
    // 物件の導入形態が不正ではないか
    fraudImportFormId: boolean;
    // 未完了の残工事があるかどうか
    isConstructionUnfinished: boolean;
    // apid
    apid: null | string;
    // UCOM「こちら」リンク
    ucomLink: string;
  } {
    return {
      member: null,
      memberStatus: null,
      ispMemberStatus: '',
      displayPortalPattern: DISPLAY_PORTAL_PATTERN.UCOM.NORMAL,
      DISPLAY_PORTAL_PATTERN: DISPLAY_PORTAL_PATTERN.UCOM,
      property: null,
      customer: null,
      isProvidedConnectix: false,
      isContractedConnectix: false,
      connectixLpUrl: process.env.VUE_APP_UCOM_LP_URL,
      ucomUrl: process.env.VUE_APP_UCOM_URL,
      ucomMemberRegistrationUrl: process.env.VUE_APP_UCOM_MEMBER_REGISTRATION_URL,
      emInfoMessages: [],
      ScrollHint: require('../../../shared/libraries/scroll-hint/scroll-hint'),
      isMounting: true,
      isLinkedToMcloud: false,
      isDisplayNotLinkedMcloud: true,
      transitionViaUcomEntryCompleted: false,
      isExecutingClickedLinkToUcom: false,
      displayMessageWhenNotLinkToUcom: false,
      displayPortasSubscriptionPurchaseLead: true,
      getUcomProperty: null,
      fraudImportFormId: false,
      isConstructionUnfinished: false,
      apid: null,
      ucomLink: '',
    };
  },
  emits: ['updateLeftMenuLogoFromPortalServiceUcom'],
  async mounted(): Promise<void> {
    try {
      this.member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // Mcloud連携の判定
      if (this.member?.primaryKeyMcloud) {
        this.isLinkedToMcloud = true;
      } else if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        // Portas退会申し込み中 の場合Mcloud登録リンクは表示しない
        this.isDisplayNotLinkedMcloud = false;
      }

      const property: Property | null = this.$store.getters['propertyStore/property'];

      if (property?.buildingId) {
        this.processPropertyApiResponse();
      }

      if (this.member.primaryKeyUcom) {
        this.processCustomerApiResponse();
      }

      if (property?.apartmentId) {
        // apartment_id → APID に変換
        this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
        this.ucomLink = `${process.env.VUE_APP_UCOM_URL}/${this.apid}/`;
        await this.setUcomInformation(property.apartmentId);
      }

      // 残工事の受付記録があるかどうか判定する
      const remainingWorkReceptionRecord = await SpfApiService.getRemainingWorkReceptionRecord(this.member.id);
      // 残工事があった場合、ISP側の残工事の有無を確認する
      if (remainingWorkReceptionRecord?.status === '1' && property?.uaType && property?.buildingId) {
        try {
          // 残工事有無確認・受付ステータス更新API
          const confirmRemainingWork = await SpfApiService.remainingWorkConfirm(property.uaType, property.buildingId.toString(), remainingWorkReceptionRecord.roomNumber, true);
          if (confirmRemainingWork.isRemainingWork === '1') {
            this.isConstructionUnfinished = true;
          }
        } catch (error) {
          this.isConstructionUnfinished = false;
        }
      }
    } catch (error) {
      throw error;
    }

    this.$nextTick(() => {
      this.jsScrollable();
    });

    // ISP会員ステータスが退会済みログイン不可となるとき
    if (this.memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      if (!this.member.primaryKeyUcom) {
        this.displayPortalPattern = DISPLAY_PORTAL_PATTERN.UCOM.NONE;
      }
    }

    // ISP会員ステータスが退会済みログイン不可となるとき
    if (this.memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.displayPortasSubscriptionPurchaseLead = false;
      if (!this.member.primaryKeyUcom) {
        this.displayPortalPattern = DISPLAY_PORTAL_PATTERN.UCOM.NONE;
      }
    }

    // UCOM 新規会員登録完了画面 から遷移してきたかどうか判定する
    const historyList: UserScreenTransitionInfo[] | null = this.$store.getters['userScreenTransitionStore/history'];

    if (historyList && historyList.length > 0) {
      const latestHistory = historyList[historyList.length - 1];
      if (latestHistory.fromPath === '/ucom/entry/completed') {
        this.transitionViaUcomEntryCompleted = true;
      }
    }
    this.isMounting = false;
  },
  methods: {
    /**
     * 物件の導入形態の確認
     */
    importFromIdCheck(event: { preventDefault: () => void }): void {
      event.preventDefault();
      if (this.getUcomProperty && ['2', '3', '4'].includes(this.getUcomProperty.import_form_id)) {
        this.$router.push('/ucom/entry/input').catch((error: any) => {
          checkRouterError(error);
        });
      } else if (this.getUcomProperty && ['5'].includes(this.getUcomProperty.import_form_id)) {
        this.$router.push('/ucom/entry/room-number-input').catch((error: any) => {
          checkRouterError(error);
        });
      } else {
        this.fraudImportFormId = true;
      }
    },
    /**
     * 物件基本情報を使う処理
     */
    processPropertyApiResponse(): void {
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      this.getUcomProperty = ucomProperty;
      if (ucomProperty instanceof UcomPropertyResponse) {
        // Connectix提供物件かどうか (UCOM物件基本情報APIのレスポンス.connectix_start_date に日付が存在するとき、Connectix提供物件と判定する)
        if (ucomProperty.connectix_start_date) {
          this.isProvidedConnectix = true;
        }

        this.property = ucomProperty;
      }
    },
    /**
     * 契約基本情報を使う処理
     */
    processCustomerApiResponse(): void {
      const ucomCustomer: UcomCustomerResponse | null = this.$store.getters['ucomCommonStore/customer'];

      if (ucomCustomer instanceof UcomCustomerResponse) {
        // Connectix契約済みかどうか
        this.isContractedConnectix = ucomCustomer.connectix_status;
        this.customer = ucomCustomer;

        this.ispMemberStatus = getIspMemberStatusUcom(this.memberStatus!, ucomCustomer);
        this.displayPortalPattern = getDisplayPortalPatternCaseUcom(this.memberStatus!, this.ispMemberStatus);
      }
    },
    async setUcomInformation(apartmentId: string): Promise<void> {
      // e-mansion 物件基本情報 API からを Store から取得（重複利用は生じないので e-mansion の Store をそのまま使用する）
      const propertyParams = { apartmentId: apartmentId, uaType: UA_TYPE.UCOM };
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', propertyParams);

      if (eMansionProperty instanceof EMansionSharedErrorResponse) {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.E_MANSION_API_PROPERTY_INFO);
      } else {
        if (eMansionProperty.em_info_message !== null) {
          this.emInfoMessages = eMansionProperty.em_info_message.map((message) => {
            message.message_label = leaveFontRedAndBrTag(message.message_label);
            return message;
          });
        }
      }
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
          kindStr = '障害';
          break;
        case '05':
          kindStr = '障害「マンション全体」';
          break;
        case '06':
          kindStr = '障害「マンション一部」';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollable(): void {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * 「お住まいの建物専用サイトはこちら」のURL
     */
    getForBuildingUrl(): string {
      return ProcessesCalledInUcomPages.getBuildingWebsiteUrl(this.ucomUrl, this.$store.getters['propertyStore/property']);
    },
    /**
     * ucom会員登録セクションの「こちらから」のURL
     */
    getRegisterUrl(): string {
      const property: Property | null = this.$store.getters['propertyStore/property'];
      const zipCode = property?.zipcode ? property.zipcode.replace(/\-/g, '') : '';
      return `${this.ucomMemberRegistrationUrl}/?building_id=${property?.buildingId}&zipcode=${zipCode}`;
    },
    getServiceType(): string {
      return UA_TYPE.UCOM;
    },
    getDisplayConnectixFAQFlag(): boolean {
      if (this.displayPortalPattern !== DISPLAY_PORTAL_PATTERN.UCOM.NORMAL) {
        return false;
      }

      // ucom対象物件, Connectix提供物件, ISP紐づけ無し, 入会可能物件 のときのみ表示
      if (this.isProvidedConnectix && !this.member?.primaryKeyUcom && this.property?.is_possible_registration) {
        return true;
      }

      return false;
    },
    isDisplayFAQComponent() {
      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED || this.memberStatus?.status === MEMBER_STATUS.PROPERTY_LINKED) {
        return true;
      }

      // ucom物件 かつ Portas会員ステータス: 7 かつ ISP会員ステータス: 解約申し込み中
      // Connectix用質問表示制御があるので、 portal-service-ucom コンポーネントで質問表示
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && this.ispMemberStatus === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION) {
        return true;
      }

      return false;
    },
    isUnder18() {
      if (this.member?.birthdate) {
        return isUnder18(this.member.birthdate);
      }
      return false;
    },
    /**
     * UCOM新規会員登録完了画面から遷移してきたとき、UCOM会員専用トップへのリンクがクリックされた後に表示するメッセージの表示制御
     */
    async clickedLinkToUcom() {
      if (this.isExecutingClickedLinkToUcom) {
        return;
      }

      this.isExecutingClickedLinkToUcom = true;

      // ucomアカウントテーブルまで更新済みかどうか
      await AuthService.refresh();
      const customerNo = await AuthService.getUcomMemberId();

      if (customerNo) {
        const ucomAccount: UcomAccountInfo | undefined = await this.$store.dispatch('ucomAccountStore/ucomAccount', customerNo);
        if (ucomAccount?.accountInfo.primaryKeyAuth0) {
          // ページ遷移時に beforeEach で取得できるように null にする
          this.$store.commit('memberStore/member', null);
          this.$store.commit('memberStore/memberStatus', null);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/ucom').catch((error: any) => {
            checkRouterError(error);
          });
        } else {
          this.displayMessageWhenNotLinkToUcom = true;
        }
      } else {
        this.displayMessageWhenNotLinkToUcom = true;
      }
      this.isExecutingClickedLinkToUcom = false;
    },
    isEmptyBirthdate() {
      if (this.member?.birthdate) {
        return isEmptyBirthdate(this.member.birthdate);
      }
      return true;
    },
    displayTsunaguOnlineShop(): boolean {
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && !this.member?.primaryKeyTnshop) {
        return false;
      }
      return true;
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにする(下の階層のコンポーネントから伝播されてくる)
     */
    sendUpdateLeftMenuLogoAtPortalServiceUcom(): void {
      this.$emit('updateLeftMenuLogoFromPortalServiceUcom', true);
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * displayPortalPatternを更新する
     */
    async updateDisplayPortalPatternAtUcom(): Promise<void> {
      this.member = this.$store.getters['memberStore/member'];

      await StoreExternalApiResponse.main();
      if (this.member?.primaryKeyUcom) {
        this.processCustomerApiResponse();
      }
    },
  },
});
</script>
