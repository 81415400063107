<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>追加アカウント</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>追加アカウント</li>
      </ul>

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご利用状況</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:追加アカウント 確認(お申し込み) -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="追加アカウント" />ご解約確認</h2>

        <p>お申し込み内容をご確認ください。</p>
        <error-messages-component v-bind:errorMessages="messageList" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">解約対象アカウント</h3>

            <p>このアカウント・メールアドレスを解約してよろしいですか？</p>

            <table class="table-type1">
              <tbody>
                <tr>
                  <th><b>解約対象アカウント</b></th>
                  <td class="red">
                    <b>{{ accountName }}</b>
                  </td>
                </tr>
                <tr>
                  <th><b>解約対象メールアドレス</b></th>
                  <td class="red">
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">ご解約に関する注意事項</h3>
            <ul class="ul-basic01">
              <li>アカウントは、下記のタイミングで解約されます。同時にメールアドレスもご利用できなくなります。</li>
              <ul class="ul-basic01">
                <li>1日～20日までに解約登録された場合：当月末日で解約</li>
                <li>21日～月末までに解約登録された場合：翌月末日で解約</li>
              </ul>
              <li class="red">解約を実行したアカウント・メールアドレスは元に戻すことができません。</li>
              <li>解約したアカウントに付随しているサービスは、アカウント解約と同時にサービスが終了します。</li>
              <li>
                <font class="red">【重要！】アカウントが解約される前に、必ず解約対象アカウントのメール受信を行ってください。</font>
                解約前に届いていて受信しなかったメールは、解約後に確認できなくなります。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /blc -->

      <div class="blc">
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt"><b>ご確認の上、よろしければ「解約」をクリックしてください。</b></p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goCancel()">解約<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

class SubAccountCancelInfo {
  public accountName!: string;
  public mailAddress!: string;
  public cancelOn!: Date;
  public errorMessage!: string[];

  constructor(partial: Partial<SubAccountCancelInfo>) {
    Object.assign(this, partial);
  }
}

export default defineComponent({
  name: 'cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    accountName: '',
    mailAddress: '',
    accountId: '',
    messageList: new Array<string>(),
    subAccountCancelInfo: null as SubAccountCancelInfo | null,
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      this.accountName = this.$store.getters['eMansionSubAccountStore/account'];
      this.mailAddress = this.$store.getters['eMansionSubAccountStore/mailAddress'];
      this.accountId = this.$store.getters['eMansionSubAccountStore/eMansionAccountId'];

      //リロードで表示する値がなくなったときは利用状況・入力画面に戻す
      if (!this.accountName) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.mailAddress) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.accountId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.isLoading = false;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /** 戻るボタン押下時 */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /** 次へボタン押下時 */
    async goCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      //AE17-003を呼ぶ
      try {
        const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const subAccountCancelInfo = await SpfApiSubAccountAccessor.cancelSubAccount(Number(this.accountId), processOn);

        this.subAccountCancelInfo = new SubAccountCancelInfo({
          accountName: subAccountCancelInfo.accountName,
          mailAddress: subAccountCancelInfo.mailAddress,
          cancelOn: subAccountCancelInfo.cancelOn,
          errorMessage: subAccountCancelInfo.errorMessage,
        });

        //エラーがある場合は表示
        if (this.subAccountCancelInfo.errorMessage.length > 0) {
          this.messageList = this.subAccountCancelInfo.errorMessage;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }

        //Storeに保存
        this.$store.commit('eMansionSubAccountStore/account', this.accountName);
        this.$store.commit('eMansionSubAccountStore/mailAddress', this.subAccountCancelInfo.mailAddress);
        this.$store.commit('eMansionSubAccountStore/cancelOn', this.formatDate(new Date(this.subAccountCancelInfo.cancelOn)));
        //完了画面へ遷移
        await this.$router.push('/e-mansion/sub-account/cancel-complete').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    },
    formatDate(date: Date): string {
      const y = date.getFullYear();
      const m = ('00' + (date.getMonth() + 1)).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
