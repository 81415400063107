<template>
  <div class="platform-cancel-completed">
    <main class="underlayer-main">
      <h1>Portas会員退会お申し込み 完了</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li>会員退会お申し込み</li>
        <li>完了</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご案内</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Portas会員からの退会 完了">Portas会員からの退会お申し込み 完了</h2>
        <p>
          Portas（ポルタス）会員からの退会お申し込みが完了しました。<br>
          1日から20日まで（21日午前0時より翌月扱い）に退会お申し込みをされた場合、当月末での退会となります。<br>
          21日から月末までに退会お申し込みをされた場合、翌月末での退会となります。<br>
          Portas退会後は、Portasサイトへのログインがご利用できなくなります。
        </p>
      </div>

      <div class="blc">
        <p class="form-btn-txt">またのご登録をお待ちしております。</p>
        <div class="btn-area">
          <router-link to="/" custom v-slot="{ navigate }">
            <button class="btn btn01 bs" @click="navigate">Portas トップページへ<i class="material-icons link link-icon">east</i></button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/** Completed コンポーネント */
export default defineComponent({
  name: 'platform-cancel-completed'
});
</script>
