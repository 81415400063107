import { Module } from 'vuex';
import { RootState } from '../index';

/** ucom アカウントID管理 State */
const ucomAccountManagementDeleteState: {
  ucomAccountId: string | null; //UCOMアカウントID
  accountName: string | null; //アカウント名
  fullName: string | null; //氏名
  mailAddress: string | null; //メールアドレス
  accountAuthorization: string | null; //アカウント権限種別
  mailAddressType: string | null;
  isTargetLoginUser: boolean;
} = {
  ucomAccountId: null,
  accountName: null,
  fullName: null,
  mailAddress: null,
  accountAuthorization: null,
  mailAddressType: null,
  isTargetLoginUser: false,
};

/** State の型 */
type ucomAccountManagementDeleteState = typeof ucomAccountManagementDeleteState;

/** UCOM アカウントID情報 Store */
export const ucomAccountManagementDeleteStore: Module<ucomAccountManagementDeleteState, RootState> = {
  namespaced: true,
  state: ucomAccountManagementDeleteState,
  actions: {},
  mutations: {
    ucomAccountId: (state, value: string) => (state.ucomAccountId = value),
    accountName: (state, value: string) => (state.accountName = value),
    fullName: (state, value: string) => (state.fullName = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    accountAuthorization: (state, value: string) => (state.accountAuthorization = value),
    mailAddressType: (state, value: string) => (state.mailAddressType = value),
    isTargetLoginUser: (state, value: boolean) => (state.isTargetLoginUser = value),
    clear: (state) => {
      state.ucomAccountId = null;
      state.accountName = null;
      state.fullName = null;
      state.mailAddress = null;
      state.accountAuthorization = null;
      state.mailAddressType = null;
      state.isTargetLoginUser = false;
    },
  },
  getters: {
    ucomAccountId: (state): string | null => state.ucomAccountId,
    accountName: (state): string | null => state.accountName,
    fullName: (state): string | null => state.fullName,
    mailAddress: (state): string | null => state.mailAddress,
    accountAuthorization: (state): string | null => state.accountAuthorization,
    mailAddressType: (state): string | null => state.mailAddressType,
    isTargetLoginUser: (state): boolean => state.isTargetLoginUser,
  },
};
