<template>
  <div class="mail-usage">
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールサービスご利用状況</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>メールサービスご利用状況</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li class="stay">ご利用状況</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェックあんしんサービス plus　ご利用状況 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="メールボックス容量変更（１ＧＢ）／ウイルスチェックサービスお申し込み" />ご利用状況</h2>

        <ErrorMessagesComponent v-if="errorMessages" :errorMessageTitle="errorMessageTitle" :errorMessages="errorMessages" :isOneSentence="errorMessages.length == 1" />

        <p>ウイルスチェックをお申し込みいただくメールアドレスの「申し込む」をクリックしてください。確認画面に進みます。</p>
        <p>ご解約の場合は、「解約」をクリックしてください。</p>
        <p>メールボックス容量を1GBに変更したいメールアドレスの「申し込む」ボタンをクリックしてください。お申し込み直後から、メールボックス容量が1GBに変更されます。</p>
        <p>
          ご利用中メールアドレスの初期パスワードなどメールの設定情報は下記よりご確認いただけます。<br />
          <PermissionControledRouterLink
            v-if="hasMainMailAddress"
            class="service-item-link"
            to="/e-mansion/main-mail-address/list"
            title="メインメールアドレスのご利用状況"
            :functionId="functionIdMainMail"
          />
          <PermissionControledRouterLink
            v-else-if="!hasMainMailAddress"
            class="service-item-link"
            to="/e-mansion/main-mail-address/input"
            title="メインメールアドレスのご利用状況"
            :functionId="functionIdMainMail"
          />
          <PermissionControledRouterLink class="service-item-link" to="/e-mansion/sub-account/list" title="追加メールアドレスのご利用状況" :functionId="functionIdAddAccount" />
          <!-- <router-link to="/e-mansion/main-mail-address/list" class="link">＞メインメールアドレスのご利用状況</router-link><br />
          <router-link to="/e-mansion/sub-account/list" class="link">＞追加メールアドレスのご利用状況</router-link> -->
        </p>

        <div class="sblc">
          <div v-if="!hasMailAddresses && !isLoading" class="sblc">
            <b class="red">ご利用中のメールアドレスはありません。</b>
          </div>
          <div v-if="hasMailAddresses" class="sblc">
            <div v-if="hasMailAddresses" class="sblc table-pc">
              <table class="table-type1">
                <tbody>
                  <tr>
                    <th rowspan="2"><b>メールアドレス</b></th>
                    <th :colspan="isTtpOrSlimPlan ? 1 : 3" class="border-left"><b>ウイルスチェック</b></th>
                    <th :colspan="2" class="border-left"><b>メールボックス</b></th>
                  </tr>
                  <tr>
                    <th class="border-left"><b>ご利用状況</b></th>
                    <th v-if="!isTtpOrSlimPlan"><b>お申し込み日</b></th>
                    <th v-if="!isTtpOrSlimPlan"><b>お申し込み／解約</b></th>
                    <th class="border-left"><b>現在の容量</b></th>
                    <th><b>お申し込み</b></th>
                  </tr>
                  <!-- TODO : 各列のスタイルが、モックで頂いている画像の見た目と異なる -->
                  <tr v-for="mailAddressInfo in mailAddressInfos" :key="mailAddressInfo.mailAddressId">
                    <td>{{ mailAddressInfo.mailAddress }}</td>
                    <td class="border-left">{{ mailAddressInfo.virusCheck.vckSubscriptionStatusDisplay }}</td>
                    <td v-if="!isTtpOrSlimPlan">{{ formatDateToYyyymmdd(mailAddressInfo.virusCheck.registerSubscriptionOn) }}</td>
                    <td v-if="!isTtpOrSlimPlan">
                      <button v-if="shouldShowApplyButton(mailAddressInfo)" class="btn btn01 bs" @click="applyVirusCheck(mailAddressInfo)">
                        申し込む<i class="material-icons link link-icon">east</i>
                      </button>
                      <button v-if="shouldShowInvisibleApplyButton(mailAddressInfo)" class="btn btn04 bs" style="display: none">
                        申し込む<i class="material-icons link link-icon">east</i>
                      </button>
                      <button v-if="shouldShowCancelButton(mailAddressInfo)" class="btn btn04 bs" @click="cancelVirusCheck(mailAddressInfo)">
                        解約<i class="material-icons link link-icon">east</i>
                      </button>
                      <template v-if="shouldShowExpiration(mailAddressInfo)">
                        <p><b>サービス終了日:</b></p>
                        <p>
                          <b>{{ formatDateToYyyymmdd(mailAddressInfo.virusCheck.cancelOn) }}</b>
                        </p>
                      </template>
                    </td>
                    <td class="border-left">{{ mailAddressInfo.capacity.amount }}</td>
                    <td>
                      <button v-if="mailAddressInfo.capacity.canChange" class="btn btn01 bs" @click="applyMbCapacityChange(mailAddressInfo)">
                        申し込む<i class="material-icons link link-icon">east</i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-sp" v-for="mailAddressInfo in mailAddressInfos" :key="mailAddressInfo.mailAddressId">
              <div class="mail-usage-table-container">
                <table class="table-type1 mail-usage-table">
                  <tbody>
                    <tr class="mail-usage-thred-sp-main">
                      <th><b>メールアドレス</b></th>
                    </tr>
                    <tr class="mail-usage-thred-sp-sub mail-usage-sp-mail">
                      <th>{{ mailAddressInfo.mailAddress }}</th>
                    </tr>
                    <!-- TODO : 各列のスタイルが、モックで頂いている画像の見た目と異なる -->
                  </tbody>
                </table>
                <table class="table-type1 mail-usage-table">
                  <tbody>
                    <tr class="mail-usage-thred-sp-main">
                      <th><b>ウイルスチェック</b></th>
                    </tr>
                    <tr class="mail-usage-thred-sp-sub">
                      <th><b>ご利用状況</b></th>
                      <th v-if="!isTtpOrSlimPlan"><b>お申し込み日</b></th>
                      <th v-if="!isTtpOrSlimPlan"><b>お申し込み／解約</b></th>
                    </tr>
                    <tr class="mail-usage-body">
                      <td class="mail-usage-status">{{ mailAddressInfo.virusCheck.vckSubscriptionStatusDisplay }}</td>
                      <td v-if="!isTtpOrSlimPlan" class="mail-usage-subscription">{{ formatDateToYyyymmdd(mailAddressInfo.virusCheck.registerSubscriptionOn) }}</td>
                      <td v-if="!isTtpOrSlimPlan">
                        <button v-if="shouldShowApplyButton(mailAddressInfo)" class="btn btn01 bs" @click="applyVirusCheck(mailAddressInfo)">
                          申し込む<i class="material-icons link link-icon">east</i>
                        </button>
                        <button v-if="shouldShowInvisibleApplyButton(mailAddressInfo)" class="btn btn04 bs" style="display: none">
                          申し込む<i class="material-icons link link-icon">east</i>
                        </button>
                        <button v-if="shouldShowCancelButton(mailAddressInfo)" class="btn btn04 bs" @click="cancelVirusCheck(mailAddressInfo)">
                          解約<i class="material-icons link link-icon">east</i>
                        </button>
                        <template v-if="shouldShowExpiration(mailAddressInfo)">
                          <p><b>サービス終了日:</b></p>
                          <p>
                            <b>{{ formatDateToYyyymmdd(mailAddressInfo.virusCheck.cancelOn) }}</b>
                          </p>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table-type1 mail-usage-table">
                  <tbody>
                    <tr class="mail-usage-thred-sp-main">
                      <th><b>メールボックス</b></th>
                    </tr>
                    <tr class="mail-usage-thred-sp-sub">
                      <th><b>現在の容量</b></th>
                      <th class="mail-usage-thred-apply"><b>お申し込み</b></th>
                    </tr>
                    <tr class="mail-usage-body">
                      <td class="mail-usage-capacity">{{ mailAddressInfo.capacity.amount }}</td>
                      <td class="mail-usage-cap-apply">
                        <button v-if="mailAddressInfo.capacity.canChange" class="btn btn01 bs" @click="applyMbCapacityChange(mailAddressInfo)">
                          申し込む<i class="material-icons link link-icon">east</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /blc -->

      <div class="blc">
        <h3 class="service-h3">「ウイルスチェックあんしんサービス plus」について</h3>
        <ul class="ul-basic01">
          <li>ウイルスチェックお申し込み直後は「お申込受付済み」と表示されます。</li>
          <li>ウイルスチェックのご利用状況が「ご利用中」と表示されているメールアドレスは、ウイルスチェックが適用されています。</li>
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」の前日までにお申し込みいただくと、鍵のお引越し日からご利用可能となります。</li>
          <li>
            <span class="caution">追加メールアドレスにウイルスチェックをお申し込みいただく場合は、必ず追加アカウントでPortas連携を行ってください。</span>
          </li>
        </ul>
        <p class="caution">
          ※追加メールアドレスに「ウイルスチェックあんしんサービス
          plus」をお申込みいただき、追加アカウントでのPortas登録がお済みでない場合もオプション料金が発生いたしますのでご注意ください。
        </p>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>
<style>
.table-pc {
  display: block;
}
.table-sp {
  display: none;
}
.mail-usage-table {
  margin: 0;
  width: auto;
}
.mail-usage-table-container {
  display: flex;
}
.border-left {
  border-left: 1px solid #d3d3d3;
}
.caution {
  color: #cf1225;
}
p.caution {
  margin-top: 0.5em;
}

@media only screen and (max-width: 767px) {
  .table-pc {
    display: none;
  }
  .table-sp {
    display: block;
  }
  .mail-usage-table-container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
  }
  .mail-usage-table,
  .mail-usage-table tr {
    border: none;
  }
  .mail-usage-thred-sp-main,
  .mail-usage-thred-sp-sub {
    display: block;
    width: 140px;
    overflow: visible;
    white-space: nowrap;
  }
  .mail-usage-table tbody {
    display: flex;
    justify-content: flex-start;
  }
  .mail-usage-thred-sp-main {
    margin-right: -100px;
  }
  .mail-usage-thred-sp-sub {
    padding-top: 2rem;
  }
  .mail-usage-thred-sp-sub th {
    padding: 15px 10px;
    background-color: transparent;
  }
  .mail-usage-body {
    padding-top: 2rem;
  }
  .mail-usage-body td {
    padding: 15px 10px;
    overflow: visible;
    white-space: nowrap;
    background-color: transparent;
  }
}
</style>
<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import PermissionControledRouterLink from '@/shared/components/permission-controled-router-link.vue';

import { EMansionMbCapacityDto, EMansionVirusCheckDto, MbCapacityLabel, SUBSCRIPTION_STATUS, SubscriptionStatus } from '@/shared/classes/spf-api/e-mansion-mail';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { SpfApiMailBoxAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-mail-box-accessor';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** メールアドレスごとの情報を表示用にまとめたもの */
interface MailAddressInfo {
  mailAddressId: number; // e-mansion_メールアドレスID
  mailAddress: string; // メールアドレス
  virusCheck: {
    // ウィルスチェックに関する情報
    vckSubscriptionStatusDisplay: string; // 契約ステータス (表示用)
    registerSubscriptionOn: Date | null; // 申込日
    serviceInTime: Date | null; // サービスイン日
    mailSubscriptionStatus: SubscriptionStatus; //メールアドレス登録ステータス（値）
    vckSubscriptionStatus: SubscriptionStatus; // ウイルスチェック契約ステータス (値)
    cancelOn: Date | null; // サービス終了日
  };
  capacity: {
    // メールボックス容量に関する情報
    amount: MbCapacityLabel; // メールボックス容量 (表示文字列)
    canChange: boolean; // 変更可否
  };
}

export default defineComponent({
  name: 'mail-usage',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
    PermissionControledRouterLink,
  },

  data(): {
    isBusy: boolean;
    virusCheck: EMansionVirusCheckDto | undefined;
    mbCapacity: EMansionMbCapacityDto | undefined;
    errorMessageTitle: string;
    errorMessages: string[];
    planType: SERVICE_PLAN_TYPE | undefined;
    isMailMember: boolean;
    functionIdMainMail: string;
    functionIdAddAccount: string;
  } {
    return {
      isBusy: false,
      virusCheck: undefined,
      mbCapacity: undefined,
      errorMessageTitle: '',
      errorMessages: [],
      planType: undefined,
      isMailMember: false,
      functionIdMainMail: SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE,
      functionIdAddAccount: SERVICE_ID.MAIL_ACCOUNT_ADDITION,
    };
  },

  async mounted() {
    // 会員種別を取得する
    this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
    if (this.planType === undefined) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    // メール会員フラグを取得する
    // TODO : 取得の仕方はこれでよいか確認が必要 (会員専用トップと同様)
    const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
    if (!(customer instanceof EMansionCustomer)) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    this.isMailMember = (customer as EMansionCustomer).email_member_flag === '1';
    this.isMailMember = false; // TODO : true になってしまうのでダミー値をセットしている。この行は削除すること

    await this.initVirusCheck();
    await this.initMbCapacity();
  },

  methods: {
    /**
     * ウィルスチェック利用状況を API から取得して初期化する
     */
    async initVirusCheck(): Promise<void> {
      try {
        this.virusCheck = await SpfApiVirusCheckAccessor.findEMansionVirusCheck();
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    }, // initVirusCheck

    /**
     * メールボックス容量を API から取得して初期化する
     */
    async initMbCapacity() {
      try {
        this.mbCapacity = await SpfApiMailBoxAccessor.findEMansionMbCapacity();
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    }, // initMbCapacity

    /**
     * ウィルスチェックの「申し込む」ボタンを表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return 「申し込む」ボタンを表示するかどうか
     */
    shouldShowApplyButton(mailAddressInfo: MailAddressInfo): boolean {
      if (mailAddressInfo.virusCheck.mailSubscriptionStatus === SUBSCRIPTION_STATUS.CANCELLING) {
        return false;
      }
      return !mailAddressInfo.virusCheck.vckSubscriptionStatus;
    }, // shouldShowApplyButton

    /**
     * ウィルスチェックの「申し込む」(非表示) ボタンを表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return 「申し込む」(非表示) ボタンを表示するかどうか
     */
    shouldShowInvisibleApplyButton(mailAddressInfo: MailAddressInfo): boolean {
      if (this.shouldShowCancelButton(mailAddressInfo)) {
        return false;
      }
      return mailAddressInfo.virusCheck.vckSubscriptionStatus === SUBSCRIPTION_STATUS.APPLYING;
    }, // shouldShowInvisibleApplyButton

    /**
     * ウィルスチェックの「解約」ボタンを表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return 「解約」ボタンを表示するかどうか
     */
    shouldShowCancelButton(mailAddressInfo: MailAddressInfo): boolean {
      // ウィルスチェックが契約済みの場合
      if (mailAddressInfo.virusCheck.vckSubscriptionStatus === SUBSCRIPTION_STATUS.APPLIED) {
        return true;
      }
      // ウィルスチェックが契約申し込み中でサービスイン日が未来日の場合
      const serviceInTime = mailAddressInfo.virusCheck.serviceInTime;
      const now = new Date();
      if (serviceInTime && mailAddressInfo.virusCheck.vckSubscriptionStatus === SUBSCRIPTION_STATUS.APPLYING && serviceInTime.getTime() > now.getTime()) {
        return true;
      }
      return false;
    }, // shouldShowCancelButton

    /**
     * ウィルスチェックのサービス終了日を表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return サービス終了日を表示するかどうか
     */
    shouldShowExpiration(mailAddressInfo: MailAddressInfo): boolean {
      return mailAddressInfo.virusCheck.vckSubscriptionStatus === SUBSCRIPTION_STATUS.CANCELLING;
    }, // shouldShowExpiration

    /**
     * Date 型を YYYY/MM/DD に変換する
     * @param date 変換元の日時
     * @return 変換先の文字列
     */
    formatDateToYyyymmdd(date: Date | null): string {
      return date ? dayjs(date).format('YYYY/MM/DD') : '';
    },

    /**
     * ウィルスチェックの「申し込む」のクリックハンドラ
     * @param mailAddressInfo メールアドレスごとの情報
     */
    async applyVirusCheck(mailAddressInfo: MailAddressInfo): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      // 処理対象のメールアドレスID, メールアドレス, 利用料情報を Store に保存する
      this.$store.commit('eMansionMailStore/setSelectedMailAddressId', mailAddressInfo.mailAddressId);
      this.$store.commit('eMansionMailStore/setSelectedMailAddress', mailAddressInfo.mailAddress);
      this.$store.commit('eMansionMailStore/setSelectedFeeInfo', this.virusCheck?.feeInfo);
      // 確認 (申込) 画面に遷移する
      await this.$router.push('/e-mansion/mail/virus-check-apply-confirm').catch((error) => {
        checkRouterError(error);
      });
    },

    /**
     * ウィルスチェックの「解約」のクリックハンドラ
     * @param mailAddressInfo メールアドレスごとの情報
     */
    async cancelVirusCheck(mailAddressInfo: MailAddressInfo): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      // メールアドレスID, メールアドレスを Store に保存する
      this.$store.commit('eMansionMailStore/setSelectedMailAddressId', mailAddressInfo.mailAddressId);
      this.$store.commit('eMansionMailStore/setSelectedMailAddress', mailAddressInfo.mailAddress);
      // 確認 (解約) 画面に遷移する
      await this.$router.push('/e-mansion/mail/virus-check-cancel-confirm').catch((error) => {
        checkRouterError(error);
      });
    },

    /**
     * メールボックス容量変更の「申し込む」のクリックハンドラ
     * @param mailAddressInfo メールアドレスごとの情報
     */
    async applyMbCapacityChange(mailAddressInfo: MailAddressInfo): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      try {
        const response = await SpfApiMailBoxAccessor.updateMbCapacity(mailAddressInfo.mailAddressId);
        if (response.errorMessage) {
          this.errorMessages = [response.errorMessage];
          window.scrollTo(0, 0);
          this.isBusy = false;
          return;
        } else {
          await this.initMbCapacity();
        }
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
      this.isBusy = false;
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.virusCheck === undefined || this.mbCapacity === undefined || this.isBusy;
    },

    /** 表示するメールアドレスがあるかどうかの判定 */
    hasMailAddresses(): boolean {
      if (this.mailAddressInfos?.length) {
        return true;
      }
      return false;
    },
    hasMainMailAddress(): boolean {
      if (this.virusCheck?.hasMainMailAddress) {
        return true;
      }
      return false;
    },
    /** メールアドレスごとの情報を表示用にまとめたもの */
    mailAddressInfos(): MailAddressInfo[] | null {
      const infos: MailAddressInfo[] | null =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.virusCheck?.data.map((virusCheck: any) => {
          // ウィルスチェック利用状況と同じ emailAddressId の メールボックス容量
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const mbCapacity = this.mbCapacity?.data.find((mbCapacity: any) => {
            return mbCapacity.emailAddressId === virusCheck.emailAddressId;
          });
          // ウィルスチェック利用状況とメールボックス容量を合わせて1個の要素にする
          const mailAddressInfo: MailAddressInfo = {
            mailAddressId: virusCheck.emailAddressId,
            mailAddress: virusCheck.emailAddressOriginal ?? virusCheck.emailAddress,
            virusCheck: {
              vckSubscriptionStatusDisplay: virusCheck.subscriptionStatusDisplay,
              registerSubscriptionOn: virusCheck.registerSubscriptionOn ? new Date(virusCheck.registerSubscriptionOn) : null,
              serviceInTime: virusCheck.serviceInTime ? new Date(virusCheck.serviceInTime) : null,
              mailSubscriptionStatus: virusCheck.mailSubscriptionStatus,
              vckSubscriptionStatus: virusCheck.vckSubscriptionStatus,
              cancelOn: virusCheck.cancelOn ? new Date(virusCheck.cancelOn) : null,
            },
            capacity: {
              amount: mbCapacity?.emailCapacity ?? '',
              canChange: mbCapacity?.canChangeCapacity ?? false,
            },
          };
          return mailAddressInfo;
        }) ?? null;
      return infos;
    }, // mailAddressInfos

    /** TTP またはスリムプラン (特例型を含む) であることを判定する */
    isTtpOrSlimPlan(): boolean {
      return this.planType === SERVICE_PLAN_TYPE.EM_TTP || this.planType === SERVICE_PLAN_TYPE.EM_SLIM || this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL;
    },
  }, // computed
});
</script>
