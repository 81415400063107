<template>
<li v-if="isEnabled">
  <a v-if="!disabled" :href="url" :target="tabTarget" class="link">{{ title }}</a>
  <span class="disabled" v-else-if="disabled">{{ title }}</span>
</li>
</template>

<script lang="ts">
import { PermissionControlService } from "@/shared/services/permission/permission-control-service";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'permission-controled-list-link',
  props: {
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    functionId: {
      type: String,
      default: undefined,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabTarget: {
      type: String,
      default: '_blank',
    },
  },
  data: () => ({
    isEnabled: false
  }),
  async mounted(): Promise<void> {
    // メニューリンクは"R"以上のパーミッションがあれば利用可
    this.$data.isEnabled = await PermissionControlService.isReadable(this.functionId ? this.functionId : '');
  }
});
</script>
<style lang="scss" scoped>
.disabled {
  color: #bdbdbd;
  text-decoration: underline;
}
</style>