export class EMansionMainMailAddressCreate {
  readonly _mailAddress!: string | undefined; // メールアドレス
  readonly _errorMessages!: string[] | undefined; // エラーメッセージ

  constructor(partial: Partial<EMansionMainMailAddressCreate>) {
    Object.assign(this, partial);
  }

  get mailAddress(): string | undefined {
    return this._mailAddress;
  }

  get errorMessages(): string[] | undefined {
    return this._errorMessages;
  }
}
