export const E_MANSION_ACCOUNT_AUTHORITY = {
    MAIN_ACCOUNT: 1,
    ADDITIONAL_ACCOUNT: 2
} as const;
export type E_MANSION_ACCOUNT_AUTHORITY = typeof E_MANSION_ACCOUNT_AUTHORITY[keyof typeof E_MANSION_ACCOUNT_AUTHORITY];

export const convertEMansionAccountAuthority = (value: number): E_MANSION_ACCOUNT_AUTHORITY | undefined => {
    for (const v of Object.values(E_MANSION_ACCOUNT_AUTHORITY)) {
        if (Number(v) == value) {
            return v;
        }
    }
}