import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { FindUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/find-ucom-mb-capacity-request';
import { UpdateUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/update-ucom-mb-capacity-request';
import { UcomMailboxCapacityUserInfo } from '@/shared/classes/spf-api/mail/model/ucom/mailbox-capacity/ucom-mailbox-capacity-user-info';
import { UcomMailboxCapacityServiceInfo } from '@/shared/classes/spf-api/mail/model/ucom/mailbox-capacity/ucom-mailbox-capacity-service-info';
import { UcomMailboxCapacityConfirmInfo } from '@/shared/classes/spf-api/mail/model/ucom/mailbox-capacity/ucom-mailbox-capacity-confirm-info';
import { processDate } from '@/shared/util/func-process-date';

/** 内部 API をコールするサービス */
export class SpfApiMailboxCapacityAccessor {
  /**
   * メールボックスの容量情報(ユーザ)を取得
   *
   * @param request リクエスト dto
   * @return メールボックス容量情報(ユーザ)
   */
  public static async findUcomMbCapacityUser(request: FindUcomMbCapacityRequest): Promise<UcomMailboxCapacityUserInfo> {
    const response = await BaseApiService.api()
      .get(`/mb-youryou/UCOM/nyuuryoku-user/${request.portasId}`, {
        params: {
          ucomAccountMailId: request.ucomAccountMailId,
          effectiveDate: processDate(),
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomMailboxCapacityUserInfo(response.data);
  }

  /**
   * メールボックスの容量情報(ユーザ)レスポンスをdtoに詰替え
   *
   * @param data メールボックスの容量情報(ユーザ)レスポンス
   * @return メールボックスの容量情報(ユーザ)dto
   */
  private static convertUcomMailboxCapacityUserInfo(data: UcomMailboxCapacityUserInfo | undefined): UcomMailboxCapacityUserInfo {
    if (!data) {
      throw new Error('メールボックス容量 ユーザ情報無し');
    }
    return new UcomMailboxCapacityUserInfo(data.isRegisteredPayment, data.accountName, data.mailAddress, data.mailBoxCapacity);
  }

  /**
   * メールボックスの容量情報(サービス)を取得
   *
   * @param request リクエスト dto
   * @return メールボックス容量情報(サービス)
   */
  public static async findUcomMbCapacityService(request: FindUcomMbCapacityRequest): Promise<UcomMailboxCapacityServiceInfo> {
    const response = await BaseApiService.api()
      .get(`/mb-youryou/UCOM/nyuuryoku-service/${request.portasId}`, {
        params: {
          ucomAccountMailId: request.ucomAccountMailId,
          effectiveDate: processDate(),
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomMailboxCapacityServiceInfo(response.data);
  }

  /**
   * メールボックスの容量情報(サービス)レスポンスをdtoに詰替え
   *
   * @param data メールボックスの容量情報(サービス)レスポンス
   * @return メールボックスの容量情報(サービス)dto
   */
  private static convertUcomMailboxCapacityServiceInfo(data: UcomMailboxCapacityServiceInfo | undefined): UcomMailboxCapacityServiceInfo {
    if (!data) {
      throw new Error('メールボックス容量 サービス情報無し');
    }
    return new UcomMailboxCapacityServiceInfo(data.changeCapacityLabel, data.changeCapacityValue, data.price, data.serviceDescription);
  }

  /**
   * メールボックス容量変更 確認_更新API
   *
   * @param request リクエスト dto
   * @return 確認_更新API レスポンス
   */
  public static async updateUcomMbCapacity(request: UpdateUcomMbCapacityRequest): Promise<UcomMailboxCapacityConfirmInfo> {
    const response = await BaseApiService.api()
      .post(`/mb-youryou/UCOM/kakunin`, {
        id: request.portasId,
        ucomAccountMailId: request.ucomAccountMailId,
        changeCapacityLabel: request.changeCapacityLabel,
        changeCapacityValue: request.changeCapacityValue,
        effectiveDate: request.effectiveDate,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomMailboxCapacityConfirmInfo(response.data);
  }

  /**
   * メールボックス容量変更 レスポンスをdtoに詰替え
   *
   * @param data メールボックス容量変更 レスポンス
   * @return メールボックス容量変更 dto
   */
  private static convertUcomMailboxCapacityConfirmInfo(data: UcomMailboxCapacityConfirmInfo | undefined): UcomMailboxCapacityConfirmInfo {
    if (!data) {
      throw new Error('メールボックス容量 更新失敗');
    }
    return new UcomMailboxCapacityConfirmInfo(
      data.notifyMailAddress,
      data.accountName,
      data.mailCapacity,
      data.changeCapacityLabel,
      data.price,
      data.subscriptionDate,
      data.serviceDescription,
      data.errorMessages
    );
  }
}
