import axios from 'axios';

import { McloudSharedErrorResponse } from '../../classes/external-api/mcloud/shared-error-response';
import { McloudUserSessionResponse, McloudUserSessionResponseData } from '../../classes/external-api/mcloud/user-session-response';
import { McloudUserResponse, McloudUserResponseData } from '../../classes/external-api/mcloud/user-response';
import { McloudApartmentResponse, McloudApartmentResponseData } from '../../classes/external-api/mcloud/apartment-response';
import { McloudAdminNoticesResponse, McloudAdminNoticesResponseData } from '../../classes/external-api/mcloud/admin-notices-response';
import { McloudNoticesResponse, McloudNoticesResponseData } from '../../classes/external-api/mcloud/notices-response';
import { McloudNoticesRequest } from '../../classes/external-api/mcloud/notices-request';
import { McloudScheduleEventsRequest } from '../../classes/external-api/mcloud/schedule-events-request';
import { McloudScheduleEventsResponse, McloudScheduleEventsResponseData } from '../../classes/external-api/mcloud/schedule-events-response';
import { McloudMineReservationsResponse } from '../../classes/external-api/mcloud/mine-reservations-response';
import { BaseExternalApiService } from './base-external-api-service';
import { McloudFacilityServicesDetailsResponse } from '@/shared/classes/external-api/mcloud/facility-services-details-response';
import { AuthService } from '../auth/auth-service';

const api = BaseExternalApiService.api(2);

/** 共通利用する Mcloud 外部 API をコールするサービス */
export class McloudSharedExternalApiService {
  /**
   * ログインユーザー情報取得
   *
   * @return ログインユーザー情報
   */
  public static async findUserSession(): Promise<McloudUserSessionResponse | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudUserSessionResponseData>(`/user_session`);
      return new McloudUserSessionResponse(response.data.data);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * ユーザー情報取得
   * 使用箇所なし（2020/04時点）
   *
   * @param id ユーザーID
   * @return ユーザー情報
   */
  public static async findUserById(id: number): Promise<McloudUserResponse[] | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudUserResponseData>(`/users/${id}`);
      return response.data.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * マンション情報取得
   *
   * @param id マンションID
   * @return マンション情報
   */
  public static async findApartmentById(id: number): Promise<McloudApartmentResponse | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudApartmentResponseData>(`/apartments/${id}`);
      return new McloudApartmentResponse(response.data.data);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * つなぐネットからのお知らせ取得
   *
   * @param targetDisplayIn 検索条件 配信先 0: PC 1: スマートフォン
   * @return つなぐネットからのお知らせリスト
   */
  public static async findAdminNotices(targetDisplayIn: Array<string>): Promise<McloudAdminNoticesResponse[] | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudAdminNoticesResponseData>(`/admin_notices`, { params: { ...targetDisplayIn } });
      return response.data.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * お知らせ一覧取得
   *
   * @param noticesQuery 検索条件
   * @return お知らせリスト
   */
  public static async findNotices(noticesQuery: McloudNoticesRequest): Promise<McloudNoticesResponse[] | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudNoticesResponseData>(`/notices`, { params: { ...noticesQuery } });
      return response.data.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 自身の予約一覧取得
   *
   * @param limit 最大件数
   * @return 自身の予約一覧
   */
  public static async findMineReservations(limit: number): Promise<McloudMineReservationsResponse | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudMineReservationsResponse>(`/facility/reservations/mine`, { params: { limit } });
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 予約施設情報取得
   *
   * @param reservationId 予約施設詳細ID
   * @return 予約施設詳細
   */
   public static async findServices(serviceId: number): Promise<McloudFacilityServicesDetailsResponse | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudFacilityServicesDetailsResponse>(`/facility/services/${serviceId}`);
      // return response.data;
      return new McloudFacilityServicesDetailsResponse(response.data);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 予定一覧取得
   *
   * @param scheduleEventsQuery 検索条件
   * @return 予定一覧
   */
  public static async findScheduleEvents(scheduleEventsQuery: McloudScheduleEventsRequest): Promise<McloudScheduleEventsResponse[] | McloudSharedErrorResponse> {
    try {
      const response = await api.get<McloudScheduleEventsResponseData>(`/schedule/events`, { params: { ...scheduleEventsQuery } });
      return response.data.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return Mcloud API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): McloudSharedErrorResponse {
    if (axios.isAxiosError(error)) {
      const errorResponse: McloudSharedErrorResponse = error.response?.data;
      if (errorResponse) return new McloudSharedErrorResponse(errorResponse);
    }
    throw error;
  }
}
