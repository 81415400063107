import McloudIndex from '@/pages/mcloud/index.vue';
import LinkService from '@/pages/mcloud/link-service.vue';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { isTransitionMcloud, isTransitionUcom } from '.';

/** Mcloud ルーティング定義 */
const mcloudRouter = [
  // {
  //   path: '/mcloud',
  //   name: 'Mcloud サービストップ',
  //   component: McloudIndex,
  //   meta: {
  //     title: 'Portas | Mcloud 会員専用トップページ | ポルタス',
  //     description: 'Portas（ポルタス）Mcloud会員専用トップページになります。',
  //     keywords: 'Mcloud 会員専用トップページ'
  //   },
  //   beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  //     if(await isTransitionMcloud()){
  //       return next();
  //     }
  //     return next({ path: '/platform' });
  //   }
  // },
  {
    path: '/mcloud/linking',
    name: 'Mcloud 既存サービスとの紐づけ',
    component: LinkService,
    meta: {
      title: 'Portas | Mcloud アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とMcloudのアカウント連携を行います。',
      keywords: 'Mcloud アカウント連携'
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if(await isTransitionMcloud()){
        return next({ path: '/platform' });
      }

      return next();// McloudはUCOM+Mcloud等ありえるのでMcloud連携済みだけ弾く
    }
  }
];

export default mcloudRouter;
