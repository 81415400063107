import { UserScreenTransitionInfo } from "@/shared/classes/user/user-screen-transition";
import { Module } from "vuex";
import { RootState } from "..";

/** ユーザ画面遷移 State */
const userScreenTransitionState: {
  history: UserScreenTransitionInfo[];
} = {
  history: [],// 暗黙でanyへ。この形にしないと怒られる
};

/** State の型 */
type UserScreenTransitionState = typeof userScreenTransitionState;

/** ユーザ画面遷移 Store */
export const userScreenTransitionStore: Module<UserScreenTransitionState, RootState> = {
  namespaced: true,
  state: userScreenTransitionState,
  mutations: {
    history: (state, value: UserScreenTransitionInfo[]) => (state.history = value),
  },
  getters: {
    history: (state): UserScreenTransitionInfo[] => state.history,
  },
  actions: {
    addHistory(context, screenInfo: UserScreenTransitionInfo) {

      const history: UserScreenTransitionInfo[] = context.getters['history'];

      history.push(screenInfo);// add

      if (history.length > 10) {// 無限に蓄積しないようにする
        history.shift();
      }

      context.commit('history', history);// added and commit

    },
  }
}