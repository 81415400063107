/**
 * UCOM Connectix API : Connectix お申し込み : リクエスト
 *
 * 外部 API のリクエスト仕様に合わせてスネークケースで命名している
 * 本 API (POST /ucom/connectix) はレスポンスデータなし
 */
export class UcomConnectixNewConnectixRequest {
  /** 物件ID */
  public property_id!: string;
  /** 顧客ID */
  public customer_id!: string;
  /** 部屋番号 */
  public room_number!: string;
  /** クレジットカード登録必要有無 */
  public is_register_credit_card!: boolean;
  /** クレジットカードトークン */
  public credit_card_token?: string;
  /** カード会員 ID */
  public card_account_id?: string;

  constructor(partial: Partial<UcomConnectixNewConnectixRequest>) {
    Object.assign(this, partial);
  }
}
