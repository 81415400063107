/**開通ステータス(コラボ) */
export const STATUS_COLLAB_PORTAS = {
  APPLICATION: { id: 1, value: 'お申し込み' },
  PAYMENT_REGISTRATION: { id: 2, value: 'お支払い情報登録' },
  NTT_EQUIPMENT_CONFIRMATION: { id: 3, value: 'NTT設備確認' },
  OPENING_WORK_SCHEDULE_ADJUSTMENT: { id: 4, value: '開通工事日程調整' },
  OPENING_WORK: { id: 5, value: '開通工事' },
  OPENED: { id: 6, value: '開通' },
} as const;
export const convertStatusCollabPortasByValue = (value: string): STATUS_COLLAB_PORTAS | undefined => {
  for (const v of Object.values(STATUS_COLLAB_PORTAS)) {
    if (v.value == value) {
      return v;
    }
  }
};
export type STATUS_COLLAB_PORTAS = typeof STATUS_COLLAB_PORTAS[keyof typeof STATUS_COLLAB_PORTAS];
/**開通ステータス(Pro) */
export const STATUS_PRO_PORTAS = {
  APPLICATION: { id: 1, value: 'お申し込み' },
  PAYMENT_REGISTRATION: { id: 2, value: 'お支払情報登録' },
  MANAGEMENT_COMPANY_REGISTRATION: { id: 3, value: '管理会社情報登録' },
  FIELD_SURVEY_SCHEDULE_ADJUSTMENT: { id: 4, value: '現地調査日程調整' },
  FIELD_SURVEY: { id: 5, value: '現地調査' },
  CONTINUITY_WORK_SCHEDULE_ADJUSTMENT: { id: 6, value: '導通工事・開通工事日程調整' },
  CONTINUITY_WORK: { id: 7, value: '導通工事' },
  OPENING_WORK: { id: 8, value: '開通工事' },
  OPENED: { id: 9, value: '開通' },
} as const;
export type STATUS_PRO_PORTAS = typeof STATUS_PRO_PORTAS[keyof typeof STATUS_PRO_PORTAS];
export const convertStatusProPortasByValue = (value: string): STATUS_PRO_PORTAS | undefined => {
  for (const v of Object.values(STATUS_PRO_PORTAS)) {
    if (v.value == value) {
      return v;
    }
  }
};
