import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import store from '@/store';

export class FiveAConnectixStatus {
  /**
   * Connectix契約済みかどうか
   */
  public static isContracted(): boolean {
    const fiveACustomer: FiveACustomer | null = store.getters['fiveACommonStore/customer'];

    if (fiveACustomer instanceof FiveACustomer) {
      if (fiveACustomer.op_connectix_contract_status === '1') {
        return true;
      }
    }
    return false;
  }

  /**
   * Connectix提供物件かどうか
   */
  public static isProvided(): boolean {
    const fiveAProperty: FiveAProperty | null = store.getters['fiveACommonStore/property'];
    if (fiveAProperty instanceof FiveAProperty) {
      if (fiveAProperty.op.sdwan.flag === '1') {
        return true;
      }
    }

    return false;
  }

  /**
   * Connectix申し込み可能かどうか
   *
   * Connectix申し込み可能なとき
   *  ・ Five.Aの物件と紐づいている
   *  ・ Five.Aアカウント連携済み
   *  ・ Connectix提供物件である
   *  ・ Connectix未契約である
   *  ・ Five.A会員ステータス: 会員
   */
  public static canApply(): boolean {
    const member: Member = store.getters['memberStore/member'];
    const property: Property | null = store.getters['propertyStore/property'];

    if (property?.uaType === UA_TYPE.FIVE_A && member.primaryKeyMye) {
      if (this.isProvided() && !this.isContracted()) {
        const memberStatus: MemberStatus = store.getters['memberStore/memberStatus'];
        const fiveACustomer: FiveACustomer | null = store.getters['fiveACommonStore/customer'];

        if (fiveACustomer instanceof FiveACustomer) {
          const ispMemberStatus = getIspMemberStatusFiveA(memberStatus, fiveACustomer);
          if (ispMemberStatus === ISP_MEMBER_STATUS.FIVE_A.MEMBER) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
