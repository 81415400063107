/** 申込内容一時保存API リクエストパラメータ */
export class TemporarySavingApplicationDataRequest {
  /** uuid */
  public readonly uuid!: string;
  /** 申込内容(JSON文字列) */
  public readonly applicationDataJson!: string;
  /** 後続処理内容 */
  public readonly subsequentProcess!: string;

  constructor(fields: Required<TemporarySavingApplicationDataRequest>) {
    Object.assign(this, fields);
  }
}
