/** UCOMメールボックス容量 入力(サービス) */
export class UcomMailboxCapacityServiceInfo {
  private readonly _changeCapacityLabel!: string; // 追加/縮小容量(label)
  private readonly _changeCapacityValue!: string; // 追加/縮小容量(value)
  private readonly _price!: number; // 単価
  private readonly _serviceDescription!: string; // サービス説明

  constructor(changeCapacityLabel: string, changeCapacityValue: string, price: number, serviceDescription: string) {
    this._changeCapacityLabel = changeCapacityLabel;
    this._changeCapacityValue = changeCapacityValue;
    this._price = price;
    this._serviceDescription = serviceDescription;
  }

  get changeCapacityLabel(): string {
    return this._changeCapacityLabel;
  }
  get changeCapacityValue(): string {
    return this._changeCapacityValue;
  }
  get price(): number {
    return this._price;
  }
  get serviceDescription(): string {
    return this._serviceDescription;
  }
}
