/** UCOMメールボックス容量情報取得 リクエストdto */
export class FindUcomMbCapacityRequest {
  private readonly _portasId!: number;
  private readonly _ucomAccountMailId!: number;

  constructor(portasId: number, ucomAccountMailId: number) {
    this._portasId = portasId;
    this._ucomAccountMailId = ucomAccountMailId;
  }

  public get portasId(): number {
    return this._portasId;
  }
  public get ucomAccountMailId(): number {
    return this._ucomAccountMailId;
  }
}
