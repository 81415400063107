import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { UcomAccountManagementList } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-list';
import { UcomAccountManagementInputInitInfo } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-input-init-info';
import { UcomAccountRegistrationMax } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-registration-max';
import { UcomAccountManagementInputCheck } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-input-check';
import { UcomAccountManagementRegisterResponse } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-register-response';
import { UcomAccountManagementUpdateResponse } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-update-response';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiAccountManagementAccessor {
  /**
   * アカウントID一覧取得
   * @param id 会員ID
   * @return アカウントID一覧
   */
  public static async getAccountManagementList(id: number): Promise<UcomAccountManagementList> {
    const response = await api.get<any>(`/account/UCOM/account-ichiran/${id}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    return await this.convertAccountManagementList(response);
  }

  private static async convertAccountManagementList(response: any | undefined): Promise<UcomAccountManagementList> {
    if (!response) {
      throw new Error('アカウントID管理一覧無し');
    }

    return new UcomAccountManagementList({
      _isFullAccount: response.data.isFullAccount, //アカウントID登録数上限該否
      _hasAccount: response.data.hasAccount, //アカウント有無
      _accountCount: response.data.accountCount, //アカウント件数
      _accountInfoList: response.data.accountInfoList, //アカウント情報
    });
  }

  /**
   * アカウントID管理：登録・変更_入力_初期表示用のアカウント情報を取得
   * @param ucomAccountId UCOMアカウントID
   * @return アカウントID管理：登録・変更_入力_初期表示用のアカウント情報
   */
  public static async getAccountInputInitInfo(ucomAccountId: string): Promise<UcomAccountManagementInputInitInfo> {
    const response = await api.get(`/account/UCOM/account-nyuuryoku/${ucomAccountId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    return this.convertUcomAccountManagementInputInitInfo(response.data);
  }

  private static convertUcomAccountManagementInputInitInfo(data: UcomAccountManagementInputInitInfo | undefined): UcomAccountManagementInputInitInfo {
    if (!data) {
      throw new Error('アカウントID情報無し');
    }
    return new UcomAccountManagementInputInitInfo(
      data.accountName,
      data.password,
      data.accountAuthorization,
      data.familyName,
      data.firstName,
      data.familyNameKana,
      data.firstNameKana,
      data.userName
    );
  }

  /**
   * アカウントID登録上限チェック実施
   * @param memberId 会員ID
   * @return アカウントID登録上限結果
   */
  public static async checkAccountRegistrationMax(memberId: string): Promise<UcomAccountRegistrationMax> {
    const response = await api.get(`/common/UCOM/account-max-check/${memberId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    return this.convertUcomAccountRegistrationMaxInfo(response.data);
  }

  private static convertUcomAccountRegistrationMaxInfo(data: UcomAccountRegistrationMax | undefined): UcomAccountRegistrationMax {
    if (!data) {
      throw new Error('アカウント登録上限チェック結果無し');
    }
    return new UcomAccountRegistrationMax(data.isAccountRegistrationMax, data.message);
  }

  /**
   * アカウントID管理：登録・変更_入力_入力チェック実施
   * @param accountName アカウント名
   * @param familyName 姓
   * @param firstName 名
   * @param familyNameKana 姓_フリガナ
   * @param firstNameKana 名_フリガナ
   * @param userName ユーザーネーム
   * @param password UCOM_アカウントパスワード
   * @param passwordConfirm UCOM_アカウントパスワード(確認用)
   * @return アカウントID管理：登録・変更_入力_入力チェック結果
   */
  public static async checkAccountManagementInput(
    accountName: string,
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    userName: string,
    password: string,
    passwordConfirm: string,
    accountAuthorization: string,
    isUpdate: string
  ): Promise<UcomAccountManagementInputCheck> {
    const response = await api
      .get(`/account/UCOM/account-nyuuryoku-check/`, {
        params: {
          accountName: accountName,
          familyName: familyName,
          firstName: firstName,
          familyNameKana: familyNameKana,
          firstNameKana: firstNameKana,
          userName: userName,
          password: password,
          passwordConfirm: passwordConfirm,
          accountAuthorization: accountAuthorization,
          isUpdate: isUpdate,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomAccountManagementInputCheck(response.data);
  }

  private static convertUcomAccountManagementInputCheck(data: UcomAccountManagementInputCheck | undefined): UcomAccountManagementInputCheck {
    if (!data) {
      throw new Error('アカウント入力チェック結果無し');
    }
    return new UcomAccountManagementInputCheck(data.errorMessages);
  }

  /**
   * アカウントの新規登録
   * @param id 会員ID
   * @param accountName アカウント名
   * @param familyName 姓
   * @param firstName 名
   * @param familyNameKana 姓_フリガナ
   * @param firstNameKana 名_フリガナ
   * @param userName ユーザーネーム
   * @param password UCOM_アカウントパスワード
   * @param accountAuthorization UCOM_アカウント権限
   * @returns アカウントID管理：登録・変更_入力_初期表示用のアカウント情報
   */
  public static async registerAccount(
    id: number,
    accountName: string,
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    userName: string,
    password: string,
    accountAuthorization: string
  ): Promise<UcomAccountManagementRegisterResponse> {
    const response = await api
      .post(`/account/UCOM/account-kakunin-touroku/`, {
        id: id,
        accountName: accountName,
        familyName: familyName,
        firstName: firstName,
        familyNameKana: familyNameKana,
        firstNameKana: firstNameKana,
        userName: userName,
        password: password,
        accountAuthorization: accountAuthorization,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomAccountManagementRegisterResponse(response.data);
  }

  private static convertUcomAccountManagementRegisterResponse(data: UcomAccountManagementRegisterResponse | undefined): UcomAccountManagementRegisterResponse {
    if (!data) {
      throw new Error('アカウント登録API結果無し');
    }
    return new UcomAccountManagementRegisterResponse(
      data.errorMessages,
      data.ucomId,
      data.accountName,
      data.familyName,
      data.firstName,
      data.familyNameKana,
      data.firstNameKana,
      data.userName,
      data.password,
      data.accountAuthorizationDisplay
    );
  }

  /**
   * アカウントの更新
   * @param ucomId UCOM_アカウントID
   * @param accountName アカウント名
   * @param familyName 姓
   * @param firstName 名
   * @param familyNameKana 姓_フリガナ
   * @param firstNameKana 名_フリガナ
   * @param userName ユーザーネーム
   * @param password UCOM_アカウントパスワード
   * @param accountAuthorization UCOM_アカウント権限
   * @returns アカウントID管理：登録・変更_入力_初期表示用のアカウント情報
   */
  public static async updateAccount(
    ucomId: number,
    accountName: string,
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    userName: string,
    password: string,
    accountAuthorization: string
  ): Promise<UcomAccountManagementUpdateResponse> {
    const response = await api
      .post(`/account/UCOM/account-kakunin-koushin/`, {
        ucomId: ucomId,
        accountName: accountName,
        familyName: familyName,
        firstName: firstName,
        familyNameKana: familyNameKana,
        firstNameKana: firstNameKana,
        userName: userName,
        password: password,
        accountAuthorization: accountAuthorization,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomAccountManagementUpdateResponse(response.data);
  }

  /**
   * アカウントの更新(ユーザー権限)
   * @param ucomId UCOM_アカウントID
   * @param accountName アカウント名
   * @param familyName 姓
   * @param firstName 名
   * @param familyNameKana 姓_フリガナ
   * @param firstNameKana 名_フリガナ
   * @param userName ユーザーネーム
   * @param password UCOM_アカウントパスワード
   * @returns アカウントID管理：登録・変更_入力_初期表示用のアカウント情報
   */
  public static async updateUserAccount(
    ucomId: number,
    accountName: string,
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    userName: string,
    password: string
  ): Promise<UcomAccountManagementUpdateResponse> {
    const response = await api
      .post(`/account/UCOM/account-kakunin-koushin-user/`, {
        ucomId: ucomId,
        accountName: accountName,
        familyName: familyName,
        firstName: firstName,
        familyNameKana: familyNameKana,
        firstNameKana: firstNameKana,
        userName: userName,
        password: password,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomAccountManagementUpdateResponse(response.data);
  }

  private static convertUcomAccountManagementUpdateResponse(data: UcomAccountManagementUpdateResponse | undefined): UcomAccountManagementUpdateResponse {
    if (!data) {
      throw new Error('アカウント更新API結果無し');
    }
    return new UcomAccountManagementUpdateResponse(
      data.errorMessages,
      data.ucomId,
      data.accountName,
      data.familyName,
      data.firstName,
      data.familyNameKana,
      data.firstNameKana,
      data.userName,
      data.password,
      data.accountAuthorizationDisplay,
      data.isMailAddressRegistration
    );
  }
}
