export const XMAIL_PASSWORD_REGISTER_STATUS = {
  IN_CONTRACT_APPLICATION: { id: 1, value: '登録申請中' },
  CONTRACTED: { id: 2, value: '登録済み' },
  IN_WITHDRAWAL_APPLICATION: { id: 3, value: '解約申請中' },
  CANCELLED: { id: 4, value: '解約済み' },
} as const;

export type XMAIL_PASSWORD_REGISTER_STATUS = typeof XMAIL_PASSWORD_REGISTER_STATUS[keyof typeof XMAIL_PASSWORD_REGISTER_STATUS];

export const convertXMailPasswordRegisterStatusById = (id: number): XMAIL_PASSWORD_REGISTER_STATUS | undefined => {

  for (const v of Object.values(XMAIL_PASSWORD_REGISTER_STATUS)) {
    if (Number(v.id) == id) {
      return v;
    }
  }
};
