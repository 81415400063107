<template>
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェックあんしんサービス plus</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion会員専用トップ</router-link></li>
        <li>ウイルスチェックあんしんサービス Plus</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェックあんしんサービス plus　変更受付完了 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="ウイルスチェックあんしんサービス plus" />ご解約受付完了</h2>
        <p>
          <b>
            <font color="red">ウイルスチェックあんしんサービス plusのご解約を受け付けました。</font>
          </b>
        </p>
        <table class="table-type2">
          <tbody>
            <tr>
              <th>ご解約対象<br />メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th>サービス終了日</th>
              <td>{{ cancelOn }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /blc -->

      <!-- blc:ウイルスチェックあんしんサービス plus　ご請求内容の確認方法 -->
      <!-- /blc -->
      <div class="blc">
        <div class="btn-area">
          <p class="form-btn-txt">続けてお申し込み・解約される場合は、「ご利用状況確認」をクリックしてください。</p>
          <button class="btn btn01 bs" @click="goToUsage">ご利用状況確認<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs mt-sp-1" @click="goToTop">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'virus-check-cancel-complete',
  components: {
    LoadingComponent,
  },

  data(): {
    mailAddress: string | undefined;
    cancelOn: string | undefined;
    isBusy: boolean;
  } {
    return {
      mailAddress: undefined,
      cancelOn: undefined,
      isBusy: true,
    };
  },

  mounted() {
    // 前画面で保存したメールアドレスとサービス終了日を受け取る
    this.mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];
    if (!this.mailAddress) {
      throw new Error();
    }
    const cancelOn = this.$store.getters['eMansionMailStore/selectedCancelOn'];
    if (!cancelOn) {
      throw new Error();
    }
    this.cancelOn = this.formatDateToYyyymmdd(cancelOn);

    // 前画面に戻れないようにストアをクリアしておく
    this.$store.commit('eMansionMailStore/setSelectedMailAddressId', null);
    this.$store.commit('eMansionMailStore/setSelectedMailAddress', null);
    this.$store.commit('eMansionMailStore/setSelectedMailAddress', null);
    this.$store.commit('eMansionMailStore/setSelectedCancelOn', null);
    this.isBusy = false;
  },
  methods: {
    /**
     * 「ご利用状況確認」のクリックハンドラ
     */
    async goToUsage(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      this.goToTop();
      await this.$router.push('/e-mansion/mail/usage').catch((error) => {
        checkRouterError(error);
      });
    },

    /**
     * 「e-mansion 会員専用トップページへ」のクリックハンドラ
     */
    async goToTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      this.goToUsage();
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
    },

    /**
     * Date 型を YYYY/MM/DD に変換する
     * @param date 変換元の日時
     * @return 変換先の文字列
     */
    formatDateToYyyymmdd(date: Date | null): string {
      return date ? dayjs(date).format('YYYY/MM/DD') : '';
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading
  }, // computed
});
</script>

<style scoped></style>
