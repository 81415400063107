<!--GameWith光マイページ-->
<template>
  <div>
    <!-- underlayer-main -->
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>GameWith光マイページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/gamewith">GameWith光マイページ</router-link>
        </li>
      </ul>
      <div class="blc">
        <p>GameWith光は、株式会社GameWithが提供する光ファイバーインターネットサービスです。</p>
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages.length > 0" :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />
        </div>
        <div class="blc">
          <div v-if="isShowContentsWithoutContactFlag">
            <div v-if="!isCancelGamewithFlag">
              <h3 class="service-h3">ご契約サービス</h3>
              <div>
                <h4>{{ planFix }}</h4>
                <p>※建物設備上、手配できないプランがございます。お申し込みいただいたプランと実際にご提供するプランが異なる場合がありますので予めご了承ください。</p>
              </div>
              <div v-if="!isOpenedFlag">
                <h3 class="service-h3">インターネットサービス開通状況</h3>
                <gamewith-internet-service-status-component :planFix="planFix" :contractStatus="statusPortas" :description="description" />
                <div v-if="constructionConfirmationDate">
                  <h4 class="red">
                    インターネット開通工事日が決定しました。ご在宅をお願いいたします。<br />
                    また、開通工事日までに別途送信しておりますメールに記載のURLより、お支払情報（クレジットカードまたは引き落とし口座）をご登録ください。<br />
                    {{ constructionConfirmationDate }}
                  </h4>
                </div>
              </div>
              <div>
                <h3 class="service-h3">GameWith光　お申し込み時ご登録情報</h3>
                <p>GameWith光インターネットお申し込み時にご登録いただいた住所や氏名等を確認、変更できます。</p>
                <img class="material-icons link link-icon margin-left" src="../../images/service-h2.svg" alt="GameWith光登録情報" /><router-link
                  class="link"
                  to="/gamewith/member-edit"
                  >GameWith光　登録情報</router-link
                >
              </div>

              <div v-if="isOpenedFlag">
                <h3 class="service-h3">ご請求明細</h3>
                <p>GameWith光インターネット利用料金のご請求明細をご確認いただけます。</p>
                <img class="material-icons link link-icon margin-left" src="../../images/service-h2.svg" alt="GameWith光ご請求明細" /><router-link
                  class="link"
                  to="/gamewith/billing-history"
                  >GameWith光　ご請求明細</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <h3 class="service-h3">GameWith光に関するお問い合わせ</h3>
            <p>
              GameWith光窓口<br />
              gamewith-hikari@gamewith.co.jp<br />
              受付時間　10:00-18:00（13:00-14:00は除く）<br />
              ※土日・祝日・年末年始を除く平日
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { GamewithCustomerInfoResponse } from '@/infra/response/spf/gamewith/gamewith-customer-info-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import GamewithInternetServiceStatusComponent from '@/shared/components/gamewith/gamewith-internet-service-status-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { CONTRACT_STATUS } from '@/shared/const/gamewith/contract-status';
import { PLAN_FIX } from '@/shared/const/gamewith/plan-fix';
import { formatDate } from '@/shared/util/func-process-date';
import { defineComponent } from 'vue';
type DataType = {
  gamewithCustomer: GamewithCustomerInfoResponse | undefined;
  isLoading: boolean;
  errorMessages: string[];
  errorMessageTitle: string;
  planFix: string;
  contractType: string;
  step: number;
  description: string;
  constructionConfirmationDate: string;
  statusPortas: string;
  isShowContentsWithoutContactFlag: boolean;
  isCancelGamewithFlag: boolean;
  isOpenedFlag: boolean;
};
export default defineComponent({
  name: 'platform-index',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    GamewithInternetServiceStatusComponent,
  },
  data(): DataType {
    return {
      gamewithCustomer: undefined,
      isLoading: true,
      errorMessages: [],
      errorMessageTitle: '',
      planFix: '',
      contractType: '',
      step: 0,
      description: '',
      constructionConfirmationDate: '',
      statusPortas: '',
      isShowContentsWithoutContactFlag: false,
      isCancelGamewithFlag: false,
      isOpenedFlag: false,
    };
  },
  async mounted() {
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    if (!memberStatus.encryptedPrimaryKeyGw) {
      //データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    }
    try {
      this.gamewithCustomer = await SpfApiExternalGamewithAccessor.getCustomer(memberStatus.encryptedPrimaryKeyGw);
    } catch (error: any) {
      if (error.response?.status == '503') {
        this.errorMessages.push(
          'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
        );
        this.isLoading = false;
        return;
      }
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
    }
    this.convertGamewithCustomerData(this.gamewithCustomer);

    this.isLoading = false;
  },
  methods: {
    /**
     * ステータスが解約または廃止ならtrue
     * @param status
     */
    isCancelGamewith(status: string, closeFlag: boolean): boolean {
      if (status == CONTRACT_STATUS.APPLICATION_CANCELED) {
        return true;
      }
      if (status == CONTRACT_STATUS.ABOLITION || status == CONTRACT_STATUS.CANCELED) {
        if (closeFlag == true) {
          return true;
        }
      }
      return false;
    },
    /**
     * 開通済みかどうか(未開通ならfalse)
     * @param contractStatus 契約ステータス
     */
    isOpened(contractStatus: string) {
      if (contractStatus == CONTRACT_STATUS.APPLICATION_NOT_OPEN) {
        return false;
      }
      return true;
    },
    /**
     * 画面用にデータを格納する
     * @param gamewithCustomer
     */
    convertGamewithCustomerData(gamewithCustomer: GamewithCustomerInfoResponse): void {
      this.planFix = gamewithCustomer.planFix;
      if (this.planFix == PLAN_FIX.PRO) {
        this.statusPortas = gamewithCustomer.statusProPortas;
        this.description = gamewithCustomer.statusCaptionProPortas;
      } else {
        this.statusPortas = gamewithCustomer.statusCollabPortas;
        this.description = gamewithCustomer.statusCaptionCollabPortas;
      }
      this.isShowContentsWithoutContactFlag = gamewithCustomer.contractStatus != CONTRACT_STATUS.APPLICATION_CANCELED;
      this.isCancelGamewithFlag = this.isCancelGamewith(gamewithCustomer.contractStatus, gamewithCustomer.portasCloseFlag);

      this.isOpenedFlag = this.isOpened(gamewithCustomer.contractStatus);

      if (gamewithCustomer.scheduledDate) {
        this.constructionConfirmationDate = formatDate(new Date(gamewithCustomer.scheduledDate), 'YYYY年MM月DD日');
      }
    },
  },
});
</script>