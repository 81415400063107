<template>
  <LoadingComponent v-if="isLoading" />
  <!-- main-contents -->
  <div>
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>機器レンタルサービスお申し込み 入力</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">

      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>機器レンタルサービスお申し込み 入力</li>
      </ul>
      <!-- /breadcrumb -->
      
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:機器レンタルサービスお申し込み 入力 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="機器レンタルサービス お申し込み" />機器レンタルサービス お申し込み</h2>
        <p>サービス内容をご確認いただき、「次へ進む」ボタンを押してください。</p>
        <error-messages-component v-bind:errorMessages="messageList" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <table class="table-type2">
            <tr>
              <th class="va-middle">利用機器</th>
              <td>{{ bbrName }}</td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o">{{ bbrPrice }} 円</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <p>本サービスのお申し込みをいただくことにより無線LANルータを1台お貸し出しいたします。</p>
                <ul>
                  <li>1台のみお申し込みいただけます。</li>
                  <li>機器送付先はUCOM光 レジデンスご契約住所に限ります。</li>
                  <li>本サービスのお申し込みは、当社インターネット回線が開通していることが条件となります。</li>
                  <li>お届けする機器については、在庫状況により同等の別機種に変更となる場合がございます。</li>
                </ul>
                <br>
                <p>機器レンタルサービス契約条項については<a :href="termsUrl" target="_blank" class="link">こちらから</a>ご確認ください。</p>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明への同意<span class="req">必須</span></th>
              <td>
                <p class="o">お申し込みの前にサービス説明を必ずお読みになり、「上記の内容に同意する」にチェックしてください。</p>
                <label> <input type="checkbox" required v-model="isCheckedAgree" @change="judgeIsActiveButton(isCheckedAgree)" /> <b>上記の内容に同意する</b></label>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc:機器レンタルサービスお申し込み 入力 -->

      <!-- blc: 次へ進む -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-if="isActiveButton" @click="onClick()">次へ進む<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn04 bs" v-else @click="onClick()">次へ進む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc: 次へ進む -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { RENTAL_BBR_PATHES } from '@/shared/const/ucom/ucom-rental-bbr-pathes';
import { RENTAL_BBR_LABELS } from '@/shared/const/ucom/ucom-rental-bbr-labels';

export default defineComponent({
  name: 'inputRentalService',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // APIなどを伴う処理を実行中か否か
    isLoading: false,
    // 契約条項PDF URL
    termsUrl: process.env.VUE_APP_RENTAL_BBR_TERMS_URL,
    // エラーメッセージリスト
    messageList: [] as string[],
    // エラーメッセージ タイトル
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    // 「次へ進む」ボタンを活性化するか否か
    isActiveButton: false,
    // 「上記の内容に同意する」チェックボックスがチェックされているか否か
    isCheckedAgree: false,
    // 機器名称
    bbrName: RENTAL_BBR_LABELS.BBR_NAME,
    // 機器利用料
    bbrPrice: RENTAL_BBR_LABELS.BBR_PRICE
  }),
  methods: {
    judgeIsActiveButton(isChecked: boolean) {
      if (!isChecked) {
        this.isActiveButton = false;
        return;
      }
      this.isActiveButton = true;
    },
    onClick() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // バリデーションチェック
      if (!this.isCheckedAgree) {
        this.messageList = ['「上記の内容に同意する」にチェックを入れてください。'];
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      // バリデーションチェックで問題なき場合、確認画面に遷移
      this.isLoading = false;
      this.$router.push('/ucom/rental-service/confirm').catch((error) => {
        checkRouterError(error);
      });
    }
  },
});
</script>