<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>アカウントID 削除 完了</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>アカウントID削除</li>
      </ul>
      <!-- /breadcrumb -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>

      <!-- blc:アカウントID情報 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="アカウントID削除完了" />アカウントID 削除 完了</h2>

        <div class="sblc">
          <div class="sblc">
            <p>アカウントIDの削除が完了しました。</p>

            <table class="table-type1">
              <tr>
                <th class="va-middle">アカウントID</th>
                <td>{{ accountName }}</td>
              </tr>
              <tr>
                <th class="va-middle">氏名</th>
                <td>{{ fullName }}</td>
              </tr>
              <tr>
                <th class="va-middle">メールアドレス</th>
                <td>{{ mailAddress }}</td>
              </tr>
              <tr>
                <th class="va-middle">アカウント権限種別</th>
                <td>{{ accountAuthorization }}</td>
              </tr>
              <tr>
                <th class="va-middle">注意事項</th>
                <td>
                  <ul class="ul-basic01">
                    <li>ログイン中のアカウントIDを削除した場合、削除完了時にログアウトされます。</li>
                    <li>アカウントIDを削除すると、同じアカウントID名での再登録はできません。</li>
                    <li>削除のキャンセルは行えません。</li>
                    <li>アカウントIDの削除は、即時行われます。</li>
                    <li>
                      該当のアカウントIDで利用していたメールアドレス、セキュリティオプション等、アカウントIDで設定されたオプションサービスも同時にご利用いただくことができなくなりますのでご注意ください。
                    </li>
                    <li>アカウントIDを削除された場合、各オプションサービスの当月月額利用料金は発生いたします。日割り計算は行われません。</li>
                    <li>同月にアカウントIDの登録・削除及びそれぞれにオプションサービスの申し込みを繰り返した場合、申し込みの回数ごとの月額利用料が発生いたします。</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- /blc:アカウントID情報 -->
      <!-- /contents -->
      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goManagement()">アカウントID管理一覧画面へ<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs button-bottom" v-on:click="goTop()">UCOM光 レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AuthService } from '@/shared/services/auth/auth-service';

export default defineComponent({
  name: 'account-delete-complete',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    accountName: '',
    fullName: '',
    mailAddress: '',
    accountAuthorization: '',
    isTargetLoginUser: false,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //前画面よりアカウント情報を取得する
    this.$data.accountName = this.$store.getters['ucomAccountManagementDeleteStore/accountName'];
    this.$data.fullName = this.$store.getters['ucomAccountManagementDeleteStore/fullName'];
    this.$data.mailAddress = this.$store.getters['ucomAccountManagementDeleteStore/mailAddress'];
    this.$data.accountAuthorization = this.$store.getters['ucomAccountManagementDeleteStore/accountAuthorization'];
    this.$data.isTargetLoginUser = this.$store.getters['ucomAccountManagementDeleteStore/isTargetLoginUser'];

    if (!this.$data.accountName || !this.$data.fullName || !this.$data.accountAuthorization) {
      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      //アカウントID管理一覧画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
      return;
    }
    this.$data.isLoading = false;
  },

  // 削除対象アカウントがログインユーザの場合、ログアウトする
  // ボタンのイベントハンドラでストアをクリアしているので、チェックデータはあらかじめ取得している
  async destroyed() {
    if (this.$data.isTargetLoginUser) {
      await AuthService.logout();
    }
  },

  methods: {
    /** アカウントID管理一覧画面へ */
    async goManagement() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      if (this.$data.isTargetLoginUser) {
        await AuthService.logout();
        this.isLoading = false;
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**UCOM光 レジデンス会員専用トップ押下時 */
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      if (this.$data.isTargetLoginUser) {
        await AuthService.logout();
        this.isLoading = false;
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>

<style scoped>
u:hover {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  button.button-bottom {
    margin-top: 10px;
  }
}
</style>
