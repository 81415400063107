import axios from 'axios';
import {BaseApiService} from '@/shared/services/api/base-api-service';
import {JudgeError} from '@/infra/accessor/spf/common/judge-error';
import {EMansionMailPasswordInput} from '@/shared/classes/spf-api/mail/model/mail-pw/e-mansion-mail-password-input';
import {EMansionMailPasswordUpdate} from '@/shared/classes/spf-api/mail/model/mail-pw/e-mansion-mail-password-update';

const api = BaseApiService.api();
const rawApi = axios.create({baseURL: process.env.VUE_APP_API_ENDPOINT_URL});

/** 内部 API をコールするサービス */
export class SpfApiMailPasswordAccessor {
    /**
     * メールパスワード利用状況取得
     * @param id e-mansion_アカウントID
     * @return メールアドレス利用状況
     */
    public static async getMailPasswordInputInfo(accountId: number): Promise<EMansionMailPasswordInput> {
        const response = await api.get<any>(`/mail-pw/e-mansion/nyuuryoku/${accountId}`)
            .catch(async (error) => {
                await JudgeError.judgeError(error);
                throw error;
            });
        return this.convertEMansionMailPasswordInputDto(response);
    }

    private static async convertEMansionMailPasswordInputDto(response: any | undefined): Promise<EMansionMailPasswordInput> {
        if (!response) {
            throw new Error('getMailPasswordInputInfoでエラーが発生しました');
        }
        return new EMansionMailPasswordInput({
            _mailAddress: response.data.mailAddress,
            _mailAddressPossessionCategory: response.data.mailAddressPossessionCategory,
            _mailAddressPossessionMessage: response.data.mailAddressPossessionMessage,
        });
    }

    /**
     * メールパスワード変更：更新API
     * @param eMansionAccountId
     * @param mailPassword
     * @param date
     * @returns
     */
    public static async mailPasswordUpdate(eMansionAccountId: number, mailPassword: string, date: string): Promise<EMansionMailPasswordUpdate> {
        const response = await api.post<EMansionMailPasswordUpdate>(`/mail-pw/e-mansion/kakunin`, {
            eMansionAccountId,
            mailPassword,
            date,
        }).catch(async (error) => {
            await JudgeError.judgeError(error);
            throw error;
        });

        return this.convertEMansionMailPasswordUpdatetDto(response);
    }

    private static async convertEMansionMailPasswordUpdatetDto(response: any | undefined): Promise<EMansionMailPasswordUpdate> {
        if (!response) {
            throw new Error('mailPasswordUpdateでエラーが発生しました');
        }
        return new EMansionMailPasswordUpdate({
            _mailAddress: response.data.mailAddress,
            _message: response.data.message,
        });
    }
}
