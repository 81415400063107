<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>追加アカウント</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>追加アカウント</li>
      </ul>
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご利用状況</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:追加アカウント 確認(お申し込み) -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="追加アカウント" />お申し込み確認</h2>
        <p>お申し込み内容をご確認ください。</p>

        <error-messages-component v-bind:errorMessages="messageList" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">お申し込み内容</h3>

            <table class="table-type1">
              <tbody>
                <tr>
                  <th><b>ご希望メールアドレス</b></th>
                  <td class="red">
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
                <tr v-if="slimPlan">
                  <th><b>ご利用料金</b></th>
                  <td>
                    <b>{{ usageFee }}円</b><br />
                    ※価格は全て税込み表示(消費税{{ tax }}%)です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。<br />
                    ご登録のお支払い方法により、お支払いいただきます。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /blc -->

      <div class="blc">
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt"><b>ご確認の上、よろしければ「申し込む」をクリックしてください。</b></p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="onApply()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
  <!-- /contents -->
</template>

<script lang="ts">
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

class SubAccountAddInfo {
  public mailAddress?: string;
  public mailPassword?: string;
  public popServer?: string;
  public smtpServer?: string;
  public imapServer?: string;
  public mailSubdomain?: string;
  public resultMessage?: Array<string>;

  constructor(partial: Partial<SubAccountAddInfo>) {
    Object.assign(this, partial);
  }
}

export default defineComponent({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    desiredAccount: '',
    usageFee: '',
    subAccountAddInfo: null as SubAccountAddInfo | null,
    messageList: new Array<string>(),
    slimPlan: false,
    tax: '',
    mailAddress: '',
    mailAddressDomain: '',
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      this.tax = this.$store.getters['taxStore/tax'];

      //不正な遷移

      if (!this.$store.getters['eMansionSubAccountStore/canApply']) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.desiredAccount = this.$store.getters['eMansionSubAccountStore/desiredAccount'];
      //リロードで表示する値がなくなったときは利用状況・入力画面に戻す
      if (!this.desiredAccount) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM) {
        this.slimPlan = true;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        this.slimPlan = true;
      }
      const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
      this.mailAddress = this.desiredAccount + '@' + process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN;
      if (hasEMansionOriginalDomain(emOpMailFlag)) {
        this.mailAddressDomain = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        this.mailAddress = this.desiredAccount + '@' + this.mailAddressDomain;
      }

      if (this.slimPlan) {
        this.usageFee = this.$store.getters['eMansionSubAccountStore/usageFee'];
        // usageFeeは0円が有り得る為、未設定であることはundefinedで判定する
        if (this.usageFee === undefined) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
            checkRouterError(error);
          });
        }
      }
      this.isLoading = false;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /** 戻るボタン押下時： 追加アカウント利用状況画面に遷移する */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //Storeにご希望アカウントを保存
      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.desiredAccount);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /** 申し込むボタン押下時：追加アカウント完了(お申し込み)画面に遷移する */
    async onApply() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        const memberId = this.$store.getters['memberStore/member'].id;
        const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
        const originalDomainName = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
        const processDate = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const subAccountAddInfo = await SpfApiSubAccountAccessor.addSubAccount(
          memberId,
          this.desiredAccount,
          process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
          servicePlanType,
          emOpMailFlag,
          originalDomainName,
          processDate
        );

        this.subAccountAddInfo = new SubAccountAddInfo({
          mailAddress: subAccountAddInfo.mailAddress,
          mailPassword: subAccountAddInfo.mailPassword,
          popServer: subAccountAddInfo.popServer,
          smtpServer: subAccountAddInfo.smtpServer,
          imapServer: subAccountAddInfo.imapServer,
          mailSubdomain: subAccountAddInfo.mailSubdomain,
          resultMessage: subAccountAddInfo.resultMessage,
        });
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      if (this.subAccountAddInfo && this.subAccountAddInfo.resultMessage && this.subAccountAddInfo.resultMessage.length > 0) {
        this.messageList = this.subAccountAddInfo.resultMessage;
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      /**次画面に渡すために保存*/
      //ご希望アカウント
      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.desiredAccount);
      //追加メールアドレス
      this.$store.commit('eMansionSubAccountStore/mailAddress', this.subAccountAddInfo.mailAddress);
      //メールパスワード
      this.$store.commit('eMansionSubAccountStore/mailPassword', this.subAccountAddInfo.mailPassword);
      //POPサーバー
      this.$store.commit('eMansionSubAccountStore/popServer', this.subAccountAddInfo.popServer);
      //SMTPサーバー
      this.$store.commit('eMansionSubAccountStore/smtpServer', this.subAccountAddInfo.smtpServer);
      //IMAPサーバー
      this.$store.commit('eMansionSubAccountStore/imapServer', this.subAccountAddInfo.imapServer);
      //メールサブドメイン
      this.$store.commit('eMansionSubAccountStore/mailSubdomain', this.subAccountAddInfo.mailSubdomain);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/sub-account/complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
