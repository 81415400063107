<template>
  <div class="platform-cancel-confirm">
    <LoadingComponent v-if="isMounting || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portas会員退会お申し込み 確認</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>会員退会お申し込み</li>
        <li>確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご案内</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Portas会員からの退会 退会内容の確認" />Portas会員からの退会お申し込み 確認</h2>
        <p>退会されるお客様の情報をご確認ください。</p>
        <table v-if="member" class="table-type2">
          <tr v-if="member.firstName && member.givenName">
            <th class="va-middle">お名前</th>
            <td>{{ member.firstName }}&emsp;{{ member.givenName }}</td>
          </tr>
          <tr v-if="member.firstNameKatakana && member.givenNameKatakana">
            <th class="va-middle">お名前（フリガナ）</th>
            <td>{{ member.firstNameKatakana }}&emsp;{{ member.givenNameKatakana }}</td>
          </tr>
          <tr>
            <th class="va-middle">メールアドレス</th>
            <td>{{ member.emailAddress }}</td>
          </tr>
          <tr v-if="member.phoneNumber">
            <th class="va-middle">電話番号</th>
            <td>{{ member.phoneNumber }}</td>
          </tr>
          <tr v-if="member.birthdate">
            <th>生年月日</th>
            <td>{{ new Date(member.birthdate).getFullYear() }}年{{ new Date(member.birthdate).getMonth() + 1 }}月{{ new Date(member.birthdate).getDate() }}日</td>
          </tr>
        </table>
      </div>

      <div class="blc">
        <p class="form-btn-txt">
          ご確認の上、よろしければ「退会お申し込み」ボタンを押してください。<br />
          Portas（ポルタス）の退会お申し込みが完了します。
        </p>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="onApply()">退会お申し込み<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { SpfApiService } from '../../../shared/services/api/spf-api-service';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { UnpaidBillingService } from '@/shared/services/portas-subscription/unpaid-billing-service';

type DataType = {
  member: Member | null;
  isSubmitting: boolean;
  isMounting: boolean;
};

/** Confirm コンポーネント */
export default defineComponent({
  name: 'platform-cancel-confirm',
  components: {
    LoadingComponent,
  },
  data(): DataType {
    return {
      /** 会員情報 */
      member: null,
      /** ボタン押下判定 */
      isSubmitting: false,
      isMounting: true, // mountedが終わったかどうか
    };
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 会員情報をStoreから取得
      const member = this.$store.getters['memberStore/member'];
      this.member = member;

      if (this.member?.birthdate) {
        if (this.member?.birthdate === '0000-00-00') {
          this.member.birthdate = null;
        }
      }
      if (!member) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      this.isMounting = false;
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }
  },
  methods: {
    /** 「退会する ➡」ボタン押下時 : 退会の完了画面に遷移する */
    async onApply() {
      if (!this.member) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_MEMBER);
      }
      try {
        // ボタン押下中は何もしない
        if (this.isSubmitting) {
          return;
        }
        // ボタン押下中扱いとする
        this.isSubmitting = true;
        
        if (!this.member) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.$router.push('/').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }

        // 請求処理が未実施の契約が存在するか確認する
        const isExistUnpaidBilling = await UnpaidBillingService.getUnpaidBillingProductName(this.member.id);
        
        // 請求処理が未実施の契約が存在する場合は退会不可の案内画面に遷移する
        if (isExistUnpaidBilling.length !== 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.$router.push('/platform/cancel/impossible').catch((error: any) => {
            checkRouterError(error);
          });
          return;

          // 請求処理が未実施の契約が存在しない場合は退会を実施する
        } else {
          // 退会処理
          await this.deleteMember(this.member.id);
          
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform/cancel/completed').catch((error: any) => {
            checkRouterError(error);
          });
          return;

        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw error;
      }

      // isSubmitting: true の間がスピナー表示される
      // AuthService.logout() によるリダイレクトの間もスピナー表示させるため、isSubmitting: false にしない
      // エラー時は、エラー画面に遷移するので、isSubmitting: true のままで問題ない
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/cancel/guide')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**会員削除API実行 */
    async deleteMember(memberId: number) {
      try {
        await SpfApiService.deleteMember(memberId);
        /**storeの会員情報を削除 */
        this.$store.commit('memberStore/member', null);

        // SpfApiService.deleteMember(memberId) に成功するとバックエンドで会員ステータスが更新される
        // 会員ステータスが更新されるときは、null にするよう統一している関係で記述
        this.$store.commit('memberStore/memberStatus', null);
      } catch (error) {
        throw error;
      }
    },
  },
});
</script>
