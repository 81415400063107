// import createAuth0Client, { Auth0Client, User, RedirectLoginResult } from '@auth0/auth0-spa-js';
import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js';
import { _RouteLocationBase } from 'vue-router';

/** 認証サービス */
export class AuthService {
  /** Auth0 の SDK クライアント */
  private static auth0Client: Auth0Client | null = null;
  private static readonly LOCALSTORAGE_ISLOGOUT = 'Portas.isLogout';

  /**
   * ログインしているかどうかを確認する
   *
   * @return ログインしていれば `true`・ログインしていなければ `false` を返す
   */
  public static async isLoggedIn(): Promise<boolean> {
    const auth0Client = await this.getAuth0Client();
    return (await auth0Client.isAuthenticated()) && !localStorage.getItem(this.LOCALSTORAGE_ISLOGOUT);
  }

  /**
   * ログインする
   *
   * @param rawLocation appStateに渡すパラメータ
   */
  public static async login(rawLocation?: _RouteLocationBase): Promise<void> {
    // Auth0 認証後、コールバックURLにリダイレクトする
    const auth0Client = await this.getAuth0Client();
    await auth0Client.loginWithRedirect({
      redirect_uri: `${process.env.VUE_APP_BASE_URL}/login-callback`,
      appState: rawLocation,
      // キャストで無理やりprocesstypeを取り出している(login-callbackと違ってややこしいキャストになっている)
      processtype: JSON.stringify(rawLocation instanceof Object && 'query' in rawLocation ? (<{ query: any }>rawLocation).query.processtype : undefined),
      gwid: JSON.stringify(rawLocation instanceof Object && 'query' in rawLocation ? (<{ query: any }>rawLocation).query.gwid : undefined),
    });
    localStorage.removeItem(this.LOCALSTORAGE_ISLOGOUT);
  }

  /**
   * 新規登録画面（SMS/SNS選択）に遷移する
   *
   */
  public static async regist(): Promise<void> {
    const auth0Client = await this.getAuth0Client();
    // Auth0 ログアウト処理後は新規登録画面（SMS/SNS選択）に遷移するにリダイレクトする。
    await auth0Client.logout({
      returnTo: `${process.env.VUE_APP_BASE_URL}/login-forwarding?processtype=regist`,
    });
    localStorage.setItem(this.LOCALSTORAGE_ISLOGOUT, 'true'); // 文字の内容はなんでもいい
  }

  /**
   * ログアウトする
   */
  public static async logout(path?: string): Promise<void> {
    const auth0Client = await this.getAuth0Client();
    // Auth0 ログアウト処理後は総合トップにリダイレクトする。リダイレクト先の指定があればそちらへ行く
    await auth0Client.logout({
      returnTo: `${process.env.VUE_APP_BASE_URL}${path ? path : ''}`,
    });
    localStorage.setItem(this.LOCALSTORAGE_ISLOGOUT, 'true'); // 文字の内容はなんでもいい
  }

  /**
   * ログイン後のコールバックパラメータをハンドリングする
   *
   * @returns 遷移先情報
   */
  public static async handleRedirectCallback(): Promise<_RouteLocationBase> {
    const auth0Client = await this.getAuth0Client();
    const { appState } = await auth0Client.handleRedirectCallback();
    if (appState == null) return { path: '/platform' } as _RouteLocationBase;
    return appState;
  }

  /**
   * Auth0 アクセストークン (JWT) を取得する
   *
   * @return Auth0 アクセストークン (JWT)
   */
  public static async getAuth0AccessToken(): Promise<string> {
    if (process.env.VUE_APP_IS_USE_JWT_MOCK === 'true') {
      console.log('AuthService#getAuth0AccessToken() : モック JWT を使用する');
      // 次の内容で生成 : btoa(JSON.stringify({ "alg": "RS256", "typ": "JWT", "kid": "dummy" }))
      // const mockHeader = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImR1bW15In0=';
      // // 次の内容で生成 : btoa(JSON.stringify({ "iss": "", "sub": "auth0|dummy", "aud": "", "iat": 0, "exp": 0, "azp": "", "scope": "" }))
      // const mockPayload = 'eyJpc3MiOiIiLCJzdWIiOiJhdXRoMHxkdW1teSIsImF1ZCI6IiIsImlhdCI6MCwiZXhwIjowLCJhenAiOiIiLCJzY29wZSI6IiJ9';
      // const mockVerifySignature = 'mock';
      // const mockJwt = `${mockHeader}.${mockPayload}.${mockVerifySignature}`;
      const mockJwt =
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdyRHhIWHdRY0ZCRE1uUFRRNjF0eCJ9.eyJodHRwczovL3RzdWppdGEudGVzdC5jb20vbXlfYXBwX21ldGFkYXRhIjp7Im1lbWJlcklkIjoiZS1tYW5zaW9uLWlkIiwicHJvcGVydHlJZCI6ImJ1a2tlbi0wMSJ9LCJodHRwczovL3RzdWppdGEudGVzdC5jb20vbXlfdXNlcl9tZXRhZGF0YSI6e30sImlzcyI6Imh0dHBzOi8vc3BmLXNwZi1kZXYudXMuYXV0aDAuY29tLyIsInN1YiI6Imdvb2dsZS1vYXV0aDJ8MTExMjgxOTc4NzkyMTI1NTA1MzQ2IiwiYXVkIjpbImh0dHBzOi8vbmptdW5zb2c2bC5leGVjdXRlLWFwaS5hcC1ub3J0aGVhc3QtMS5hbWF6b25hd3MuY29tL3NlcnZpY2UtcGxhdGZvcm0tYmFja2VuZC8iLCJodHRwczovL3NwZi1zcGYtZGV2LnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2Mzg3ODY2MTUsImV4cCI6MTYzODg3MzAxNSwiYXpwIjoibmEwQUxNdndQREkwTDVFSmk2VWNPdlcwZUNScUZHbHAiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIHVwZGF0ZTpzcGZfdXNlcnMgZGVsZXRlOnNwZl91c2VycyBvZmZsaW5lX2FjY2VzcyIsInBlcm1pc3Npb25zIjpbImRlbGV0ZTpzcGZfdXNlcnMiLCJyZWFkOnNwZl91c2VycyIsInVwZGF0ZTpzcGZfdXNlcnMiXX0.X0IRnMki96KjAgmtBugIl9TesE0MNNpD0LZLKthh6UbxhxJR7YMdlWMZAEhDFq0l-usDSp8UCqredDeRwT9zt5bJQdS2F-ZldPIvkhevESuMGkepHgZGrTpummndxbdB2IC6R0Mh7Ht6u3iEMBBa_4kThqd2ofGEq8c6JEbfVpd1bcQX1OTe-J-BEduXZFadCGfxYGpOMZsVckK1aUnYHZiSRrZt5adCmmkh47UCX5XELosuhrQaxrUyunLBE7ynXoYAw3jwLsVwGmHIHn8OTmFyCv3bbBAcGmNvxqUpcgaBFvSV5512Co_0NYSu3vae17gDnqL1UGezudsXPzprXA';
      return mockJwt;
    }

    const auth0Client = await this.getAuth0Client();
    const token = await this.getTokenWithRetry(auth0Client)
      .then((token) => {
        return token;
      })
      .catch((error) => {
        throw error;
      });

    return token;
  }

  /**
   * Auth0 ユーザの sub を取得する
   *
   * @return Auth0 ユーザの sub
   */
  public static async getSub(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();
    const sub = user?.sub;
    if (sub == null) {
      throw new Error('subがありません。');
    }
    return sub;
  }

  /**
   * Auth0 ユーザの メールアドレス を取得する
   *
   * @return Auth0 ユーザの メールアドレス
   */
  public static async getAuth0Email(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();
    if (user?.email) {
      const email: string = user?.email?.toString();
      return email;
    }
    return '';
  }

  /**
   * Googleの場合、'google'を返し、Appleの場合、'apple'を返し、それ以外の場合、'other'を返す
   */
  public static async getKindOfAuth(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();

    if (user?.sub?.startsWith('google')) {
      return 'google';
    } else if (user?.sub?.startsWith('apple')) {
      return 'apple';
    } else {
      return 'other';
    }
  }

  /**
   * Auth0ユーザの苗字を取得する
   */
  public static async getFamilyNameWhenGoogle(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();

    if (user?.sub?.startsWith('google')) {
      return user.family_name ? user.family_name : '';
    }
    return '';
  }

  /**
   * Auth0アップル(Apple)ユーザの苗字を取得する
   */
  public static async getFamilyNameWhenApple(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();

    if (!user?.sub?.startsWith('apple')) {
      return '';
    } else {
      if (user.name === undefined) {
        return '';
      } else {
        const appleUserName = user.name.split(' ');
        return appleUserName[1];
      }
    }
  }

  /**
   * Auth0アップル(Apple)ユーザの名を取得する
   */
  public static async getFirstNameWhenApple(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();

    if (!user?.sub?.startsWith('apple')) {
      return '';
    } else {
      if (user.name === undefined) {
        return '';
      } else {
        const appleUserName = user.name.split(' ');
        return appleUserName[0];
      }
    }
  }

  /**
   * Auth0ユーザの名前を取得する
   */
  public static async getGivenNameWhenGoogle(): Promise<string> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();

    if (user?.sub?.startsWith('google')) {
      return user.given_name ? user.given_name : '';
    }
    return '';
  }

  /**
   * Auth0 ユーザの app_metadata から初期化フラグを取得する
   *
   * @return Auth0 ユーザの Portas メンバー ID
   */
  public static async isInitialized(): Promise<boolean> {
    const initialized = await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_INITIALIZED);
    if (initialized == null) return false;
    return initialized.toLowerCase() === 'true';
  }

  /**
   * Auth0 ユーザの app_metadata から Portas のメンバー ID を取得する
   *
   * @return Auth0 ユーザの Portas メンバー ID
   */
  public static async getMemberId(): Promise<string> {
    const id = await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_PORTAS_MEMBER_ID);
    if (id == null) throw new Error('app_metadataにメンバーIDがありません。');
    return id;
  }

  /**
   * Auth0 ユーザが e-mansion と連携しているかを確認する
   *
   * @return 連携していれば `true`・連携していなければ `false` を返す
   */
  public static async isLinkedToEMansion(): Promise<boolean> {
    return (await this.getAppMetadataBoolean(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_E_MANSION_LINKED)) ?? false;
  }

  /**
   * Auth0 ユーザの app_metadata から e-mansion のメンバー ID を取得する
   *
   * @return Auth0 ユーザの e-mansion メンバー ID
   */
  public static async getEMansionMemberId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_E_MANSION_MEMBER_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から e-mansion のメンバー ID を取得する
   *
   * @return Auth0 ユーザの e-mansion メンバー ID
   */
  public static async getEMansionLoginId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_E_MANSION_LOGIN_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から e-mansion のメンバー ID を取得する (e-tnc)
   *
   * @return Auth0 ユーザの e-mansion メンバー ID (e-tnc)
   */
  public static async getEMansionMemberIdETnc(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_E_MANSION_MEMBER_ID_E_TNC)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から e-mansion の物件 ID を取得する
   *
   * @return Auth0 ユーザの e-mansion 物件 ID
   */
  public static async getEMansionPropertyId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_E_MANSION_PROPERTY_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から 追加アカウントフラグを取得する
   *
   * @returns true の場合、追加アカウント
   */
  public static async isEMansionAdditionalAccount(): Promise<boolean> {
    return (await this.getAppMetadataBoolean(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_E_MANSION_IS_SUB_ACCOUNT)) ?? false;
  }

  /**
   * Auth0 ユーザが five.A と連携しているかを確認する
   *
   * @return 連携していれば `true`・連携していなければ `false` を返す
   */
  public static async isLinkedToFiveA(): Promise<boolean> {
    return (await this.getAppMetadataBoolean(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_FIVE_A_LINKED)) ?? false;
  }

  /**
   * Auth0 ユーザの app_metadata から five.A のメンバー ID を取得する
   *
   * @return Auth0 ユーザの five.A メンバー ID
   */
  public static async getFiveAMemberId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_FIVE_A_MEMBER_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から five.A のメンバー ID を取得する (e-tnc)
   *
   * @return Auth0 ユーザの five.A メンバー ID (e-tnc)
   */
  public static async getFiveAMemberIdETnc(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_FIVE_A_MEMBER_ID_E_TNC)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から five.A の物件 ID を取得する
   *
   * @return Auth0 ユーザの five.A 物件 ID
   */
  public static async getFiveAPropertyId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_FIVE_A_PROPERTY_ID)) ?? '';
  }

  /**
   * Auth0 ユーザが UCOM と連携しているかを確認する
   *
   * @return 連携していれば `true`・連携していなければ `false` を返す
   */
  public static async isLinkedToUcom(): Promise<boolean> {
    return (await this.getAppMetadataBoolean(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_UCOM_LINKED)) ?? false;
  }

  /**
   * Auth0 ユーザの app_metadata から UCOM のメンバー ID を取得する
   *
   * @return Auth0 ユーザの UCOM メンバー ID
   */
  public static async getUcomMemberId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_UCOM_MEMBER_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から UCOM の物件 ID を取得する
   *
   * @return Auth0 ユーザの UCOM 物件 ID
   */
  public static async getUcomPropertyId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_UCOM_PROPERTY_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から UCOMアカウント名 を取得する
   *
   * @return Auth0 ユーザの UCOM アカウント名
   */
  public static async getUcomAccountName(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_UCOM_ACCOUNT_NAME)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から UCOMアカウントフラグを取得する
   *
   * @returns true の場合、UCOMアカウント
   */
  public static async isUcomAdditionalAccount(): Promise<boolean> {
    return (await this.getAppMetadataBoolean(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_UCOM_IS_SUB_ACCOUNT)) ?? false;
  }

  /**
   * Auth0 ユーザが Mcloud と連携しているかを確認する
   *
   * @return 連携していれば `true`・連携していなければ `false` を返す
   */
  public static async isLinkedToMcloud(): Promise<boolean> {
    return (await this.getAppMetadataBoolean(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_MCLOUD_LINKED)) ?? false;
  }

  /**
   * Auth0 ユーザの app_metadata から Mcloud のメンバー ID を取得する
   *
   * @return Auth0 ユーザの Mcloud メンバー ID
   */
  public static async getMcloudMemberId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_MCLOUD_MEMBER_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata から Mcloud の物件 ID を取得する
   *
   * @return Auth0 ユーザの Mcloud 物件 ID
   */
  public static async getMcloudPropertyId(): Promise<string> {
    return (await this.getAppMetadataString(process.env.VUE_APP_AUTH0_APP_METADATA_KEY_MCLOUD_PROPERTY_ID)) ?? '';
  }

  /**
   * Auth0 ユーザの app_metadata のboolean値をキー指定で取得する
   *
   * @param key 取得対象の app_metadata キー
   * @returns app_metadata boolean値
   */
  private static async getAppMetadataBoolean(key: string): Promise<boolean | undefined> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();
    return user?.[process.env.VUE_APP_AUTH0_APP_METADATA_NAME_SPACE]?.[key];
  }

  /**
   * Auth0 ユーザの app_metadata のstring値をキー指定で取得する
   *
   * @param key 取得対象の app_metadata キー
   * @returns app_metadata string値
   */
  private static async getAppMetadataString(key: string): Promise<string | undefined> {
    const auth0Client = await this.getAuth0Client();
    const user = await auth0Client.getUser<User>();
    return user?.[process.env.VUE_APP_AUTH0_APP_METADATA_NAME_SPACE]?.[key];
  }

  /**
   * Auth0 ユーザの app_metadataなどの値の最新をとれるようにする
   *
   */
  public static async refresh(): Promise<void> {
    const auth0Client = await this.getAuth0Client();
    //ignoreCache: true トークンを強制再発行
    const token = await this.getTokenWithRetry(auth0Client, true)
      .then((token) => {
        return token;
      })
      .catch((error) => {
        throw error;
      });
  }

  /**
   * Auth0 クライアントを返す
   *
   * @return Auth0 クライアント
   */
  private static async getAuth0Client(): Promise<Auth0Client> {
    if (this.auth0Client == null) {
      this.auth0Client = await createAuth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email',
        useRefreshTokens: true,
      });
    }
    return this.auth0Client;
  }

  /**
   * Auth0 アクセストークン (JWT) を取得する(リトライ処理付き)
   *
   * @return Auth0 アクセストークン (JWT)
   */
  public static async getTokenWithRetry(auth0Client: Auth0Client, ignoreCache: boolean = false): Promise<string> {
    const maxRetries = 5;
    const retryInterval = 100;
    let retries = 0;
    let token = null;
    while (retries < maxRetries) {
      try {
        token = await auth0Client.getTokenSilently({ ignoreCache: ignoreCache });
        break; // トークンの取得に成功した場合はループを終了
      } catch (error: any) {
        //Timeoutエラーだった場合はリトライ。それ以外はエラースロー
        if (error.message.indexOf('Timeout') != -1) {
          retries++;
          if (retries < maxRetries) {
            // リトライまでの待機時間を設定
            await new Promise((resolve) => setTimeout(resolve, retryInterval));
          } else {
            throw new Error('Max retries reached. Unable to get token.');
          }
        } else {
          throw error;
        }
      }
    }
    //トークン取得できない場合はエラーになるため、空値が返されることはありえない
    return token ? token : '';
  }
}
