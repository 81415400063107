import { Module } from 'vuex';
import { RootState } from '../index';

import { UcomLinkingInput } from '@/shared/classes/auth-manegement/ucom-linking-input';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';

/** UCOM アカウント連携 State */
const ucomLinkingState: {
  ucomLinkingInput: UcomLinkingInput | null;
} = {
  ucomLinkingInput: null
};

/** State の型 */
type UcomLinkingState = typeof ucomLinkingState;

/** UCOM 共通系 Store */
export const ucomLinkingStore: Module<UcomLinkingState, RootState> = {
  namespaced: true,
  state: ucomLinkingState,
  actions: {
    /**
     * アカウント連携 actions
     * @param context
     * @return UcomLinkingInput アカウント情報
     */
    async updateLinking(context, ucomLinkingInput: UcomLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
      context.commit('updateLinking', ucomLinkingInput);
      // API コールする
      return await AuthManagementApiService.linkingToUcom(ucomLinkingInput);
    }
  },
  mutations: {
    updateLinking: (state, value: UcomLinkingInput) => (state.ucomLinkingInput = value)
  },
  getters: {
    updateLinking: (state): UcomLinkingInput | null => state.ucomLinkingInput
  }
};
