export class EMansionMainMailAddressCancel {
  public _mailAddress!: string | null; //メールアドレス
  public _finishDate!: Date | null; //サービス終了日
  public _message!: string[] | null; //エラーメッセージ

  constructor(partial: Partial<EMansionMainMailAddressCancel>) {
    Object.assign(this, partial);
  }

  get mailAddress(): string | null {
    return this._mailAddress;
  }

  get finishDate(): Date | null {
    return this._finishDate;
  }

  get message(): string[] | null {
    return this._message;
  }
}
