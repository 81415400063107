<template>
  <div class="index">
    <LoadingComponent
      v-if="
        (this.$route.path === '/e-mansion' && isMemberTopMountingEmansion) ||
        (this.$route.path === '/ucom' && isMemberTopMountingUcom) ||
        (this.$route.path === '/mcloud' && isMemberTopMountingMcloud) ||
        isSubmitting
      "
    />

    <!-- スマホ用につなぐオンラインショップの商品を別タブで開くとき left-menu 非表示にする -->
    <div class="left-menu" v-if="this.$route.path !== '/tnos'">
      <div class="cp-offcm04">
        <input type="checkbox" class="cp-toggle04" id="cp-toggle04" v-model="isHamburgerChecked" />
        <label for="cp-toggle04"><span></span></label>
        <div class="cp-menu">
          <component
            v-if="isExistLeftMenuComponent"
            :is="leftMenuComponent"
            :isLinkedEMansion="isLinkedEMansion"
            :isLinkedFiveA="isLinkedFiveA"
            :isLinkedUcom="isLinkedUcom"
            :isLinkedMcloud="isLinkedMcloud"
            :isUcomOrEmansionToppage="isUcomOrEmansionToppage"
            :isLinkedToTsunaguOnlineShop="isLinkedToTsunaguOnlineShop"
            v-on:clickContacts="clickContacts"
          />
          <div v-else>
            <div v-if="isSubAccount || isGamewithRegisterProcess">
              <div class="left-menu-logo">
                <img src="../images/index-logo.svg" alt="" class="hover-img" />
              </div>
            </div>
            <div v-else>
              <router-link class="left-menu-logo" to="" v-on:click="onIndex()"><img src="../images/index-logo.svg" alt="" class="hover-img" /></router-link>
            </div>
            <div class="inner">
              <LeftMenuCommonLink
                :isLinkedEMansion="isLinkedEMansion"
                :isLinkedFiveA="isLinkedFiveA"
                :isLinkedUcom="isLinkedUcom"
                :isLinkedMcloud="isLinkedMcloud"
                :isUcomOrEmansionToppage="isUcomOrEmansionToppage"
                :isUcomParentAccountFlag="isUcomParentAccountFlag"
                :isEmansionParentAccountFlag="isEmansionParentAccountFlag"
                :isLinkedToTsunaguOnlineShop="isLinkedToTsunaguOnlineShop"
                :isLinkedGamewith="isLinkedGamewith"
                v-on:clickContacts="clickContacts"
              />
              <div v-if="!isGamewithRegisterProcess">
                <div v-if="!isLoggedIn && isDisplayedLoggedInButton" class="left-menu-blc">
                  <ul>
                    <li>
                      <button class="login-btn bs left-login" v-on:click="onLogin()">ログイン</button>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <button class="login-btn bs left-regist" v-on:click="onRegist()">新規会員登録</button>
                    </li>
                  </ul>
                </div>
                <div v-else-if="isLogginedDisplayedLoggedInButton" class="left-menu-blc">
                  <ul>
                    <li>
                      <button class="login-btn bs left-login" v-on:click="onLogin()">ログイン</button>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <button class="login-btn bs left-regist" v-on:click="onRegist()">新規会員登録</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-contents">
      <!-- スマホ用につなぐオンラインショップの商品を別タブで開くときの固定メニュー -->
      <template v-if="this.$route.path === '/tnos'">
        <header class="my-header display-tsunagu-online-product">
          <div class="header-info">
            <img src="../images/index-logo.svg" alt="" class="hover-img" />
            <span class="close-button" @click="closeTabTsunaguOnlineProductSizeSp"></span>
          </div>
        </header>
      </template>

      <template v-else>
        <header class="my-header" :class="{ 'is-display-my-page': isDispMyPage && isLoggedIn }">
          <div class="inner cf header-inner-sp">
            <router-link v-if="!isGamewithRegisterProcess" to="/" class="logo-sp"><img src="../images/logo-sp.svg" alt="" /></router-link>
            <div v-else class="logo-sp"><img src="../images/logo-sp.svg" alt="" /></div>

            <div v-if="isSubAccount">
              <div class="header-info" v-if="isLoggedIn">
                <p class="mypage">
                  <a>&nbsp;</a>
                </p>
                <p class="logout">
                  <a href="#" class="mypage" @click.prevent.stop.prevent="onLogout()"><span class="material-icons">logout</span>ログアウト</a>
                </p>
              </div>
            </div>

            <div v-else>
              <div class="header-info" v-if="isLoggedIn && isDispMyPage">
                <p class="mypage" v-if="isDispMyPage">
                  <a href="#" class="mypage" @click.prevent.stop.prevent="onMyPage()"><span class="material-icons">person</span>Portas マイページ</a>
                </p>
                <p class="mypage" v-else>
                  <a>&nbsp;</a>
                </p>
                <p class="logout">
                  <a href="#" class="mypage" @click.prevent.stop.prevent="onLogout()"><span class="material-icons">logout</span>ログアウト</a>
                </p>
              </div>
            </div>
            <p class="header-txt login" v-if="isLoggedIn && isDispMyPage">
              <login-user-info v-if="showUserInfo"></login-user-info>
              <span>ログイン中</span>
            </p>
            <div class="header-info" v-if="!isLoggedIn || !isDispMyPage">
              <p class="mypage fixed-menu-height"></p>
            </div>
          </div>
        </header>
      </template>

      <router-view
        @isLoggedIn="cancelLoggedIn()"
        @isMemberTopMountingEmansion="changeIsMemberTopMountingEmansion()"
        @isMemberTopMountingUcom="changeIsMemberTopMountingUcom()"
        @isMemberTopMountingMcloud="changeIsMemberTopMountingMcloud()"
        @updateLeftMenuLogoFromPlatform="updateLeftMenuLogo()"
      />

      <!-- footer -->
      <footer class="my-footer">
        <div v-if="isGamewithScreen" class="logo-gamewith-green">
          <img src="../images/logo-gamewith-green.png" alt="Gamewithロゴ" />
        </div>
        <ul>
          <li><a href="https://www.arteria-net.com/company/information/" target="_blank">会社概要</a></li>
          <li>
            <a v-if="isGamewithRegisterProcess" href="/platform/terms?gamewith=true" target="terms-privacy-policy-and-commerce">Portas利用規約</a>
            <a v-else href="/platform/terms" target="terms-privacy-policy-and-commerce">Portas利用規約</a>
          </li>
          <li>
            <a v-if="isGamewithRegisterProcess" href="/platform/privacy-policy?gamewith=true" target="terms-privacy-policy-and-commercey"
              >Portasにおける個人情報の取り扱いについて</a
            >
            <a v-else href="/platform/privacy-policy" target="terms-privacy-policy-and-commercey">Portasにおける個人情報の取り扱いについて</a>
          </li>
          <li><a href="/platform/commerce.pdf" target="terms-privacy-policy-and-commercey">特定商取引法に基づく表記</a></li>
        </ul>
        <div v-if="!isLoggedIn && isDisplayedLoggedInButton" class="copy">
          <img src="../images/footer-logo.svg" alt="" class="copy_portas" />
          <img src="../images/logo-arteria-networks.png" class="copy_arteria" />
        </div>
        <div v-else class="copy">
          <img src="../images/footer-logo.svg" alt="" class="copy_portas" />
        </div>
      </footer>
      <!-- /footer -->
    </div>
    <!-- /main-contents -->

    <p id="page-top" class="page-top" v-bind:class="{ 'is-shown-to-top': isShownToTop }">
      <a href="#" v-scroll-to="{ element: '#app' }"><img src="../images/pagetop.png" alt="PAGETOP" class="hover-img" /></a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
/* グローバルにスタイルを読み込む */
@import url('../styles/normalize.css');
@import url('../styles/common.css');
@import url('../styles/style.css');
@import url('../styles/template.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
/* button:ボタン */
button.login-btn {
  background-color: #cf1225;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  padding: 12px 25px;
  line-height: 24px;
  font-size: 15px;
  min-width: 160px;
  margin: 0 10px;
  top: 2px;
  box-sizing: border-box;
  position: relative;
}
/* left-menu*/
.left-menu .left-menu-logo {
  position: static;
  display: block;
  width: auto;
  top: auto;
  left: auto;
  z-index: auto;
  padding: 40px 40px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #cf1225;
  background-image: url('../images/left-menu-bg.png');
  background-position: center top;
  background-size: 100% auto;
  background-color: #fff;
}
.left-menu .inner {
  overflow: auto;
  height: auto;
  padding-top: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.left-menu .inner::-webkit-scrollbar {
  display: none;
}
.left-menu-blc {
  border-bottom: 1px solid #cf1225;
}
.left-menu-blc ul li {
  list-style: none;
}
.left-menu-blc .left-menu-company {
  min-height: 40px;
  line-height: 40px;
}
.left-menu-blc .left-menu-ucom {
  padding-left: 10px !important;
}
.left-menu-blc .material-icons {
  position: relative;
  top: 0.2em;
  color: #cf1225;
  font-size: 20px !important;
  margin-right: 0.1em;
}
.left-menu-company img {
  max-width: 160px;
  max-height: 45px;
}
.left-menu-service1 .left-menu-service1-ttl {
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  border-bottom: 1px solid #cf1225;
}
.left-menu-service1 ul {
  padding: 0;
  margin: 0;
}
.left-menu-service1 ul li {
  list-style: none;
  font-size: 14px;
  border-bottom: 1px solid #eeeaeb;
}
.left-menu-service1 ul li:last-child {
  border-bottom: none;
}
.left-menu-service1 ul li a {
  display: block;
  padding: 10px 15px;
}
/* footer */
.my-footer {
  padding: 30px 0 50px;
  border-top: 1px solid #e6e6e6;
  &.display-on-sp-tsunagu-online-product-html {
    position: fixed;
    bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #fff;
    & div.copy {
      margin-bottom: 0;
      & img.copy_arteria {
        display: none;
      }
    }
  }
}
.logo-gamewith-green {
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 30px;
}
.logo-gamewith-green > img {
  width: 300px;
}
.my-footer ul {
  text-align: center;
  padding: 0;
}
.my-footer ul li {
  margin: 0 5px;
  display: inline-block;
}
.my-footer ul li a {
  color: #292929;
  text-decoration: none;
}
.my-footer ul li a .material-icons {
  position: relative;
  top: 0.2em;
  font-size: 1.2em;
}
.my-footer ul li a:hover {
  color: #cf1225;
  text-decoration: underline;
}
.my-footer .copy {
  position: relative;
  display: flex;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 100px;
  // font-size: 10px;
  // color: #707070;
}
.my-footer .copy_portas {
  margin: auto;
  width: 200px;
  padding: 10px 0 20px;
}
.my-footer .copy_arteria {
  position: absolute;
  right: 5%;
  width: 200px;
  padding: 5px 0 20px;
}
/* 画面右下に表示する「↑」(画面上部に戻る) ボタン */
.page-top {
  bottom: -100px;
  transition: bottom 0.2s;
  &.is-shown-to-top {
    bottom: 0;
  }
}
div.header-info {
  &:after {
    clear: both;
  }
}
.mypage {
  &.fixed-menu-height {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .copy_arteria {
    position: absolute;
    margin: 0;
    top: 140%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 767px) {
  .index {
    overflow-x: hidden !important;
  }
  .my-header {
    margin-bottom: 56px;
    &.is-display-my-page {
      margin-bottom: 0;
    }
    &.display-tsunagu-online-product {
      margin-bottom: 0;
      & div.header-info {
        text-align: left;
        padding: 8px 12px;
        & img {
          width: 160px;
        }
      }
      & span.close-button {
        $mySize: 50px;
        position: absolute;
        display: block;
        top: 50%;
        right: 16px;
        width: $mySize;
        height: $mySize;
        transform: translateY(-50%);
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: $mySize;
          background: #c31926;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: $mySize;
          background: #c31926;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
  .mypage-sp {
    position: relative;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .material-icons-sp {
    position: absolute !important;
    left: 30%;
    margin-top: 4px;
  }
  .header-inner-sp {
    background-color: #fff !important;
  }
  .mypage {
    &.fixed-menu-height {
      display: inline-block;
      height: 50px;
    }
  }
  .cp-offcm04 {
    & .cp-menu {
      bottom: 0;
      height: auto;
      cursor: auto;
    }
  }
}
</style>

<script lang="ts">
import { Member } from '@/shared/classes/spf-api/member';
import { defineComponent } from 'vue';
import { AuthService } from '../shared/services/auth/auth-service';
import EMansionLeftMenu from './e-mansion/left-menu.vue';
import UcomLeftMenu from './ucom/left-menu.vue';
import LeftMenuCommonLink from './components/left-menu-common-link.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Property } from '@/shared/classes/spf-api/property';
import { TRANSITION_STORAGE, UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { hasAdditionalAccount } from '@/shared/const/service-plan-type';
import LoginUserInfo from '@/shared/components/login-user-info-component.vue';
import { UCOM_ACCOUNT_AUTHORITY } from '../shared/const/ucom/ucom-account-authority';
import { E_MANSION_ACCOUNT_AUTHORITY } from '@/shared/const/e-mansion/e-mansion-account-authority';
import { datadogLogs } from '@datadog/browser-logs';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';

/** Index コンポーネント */
export default defineComponent({
  name: 'index',
  components: {
    UcomLeftMenu,
    EMansionLeftMenu,
    LeftMenuCommonLink,
    LoadingComponent,
    LoginUserInfo,
  },
  data: () => ({
    /** 画面右下に表示する「↑」(画面上部に戻る) ボタンが表示されていれば true */
    isShownToTop: false,
    /** ログインしているかどうか */
    isLoggedIn: false,
    /** ヘッダに表示する物件名 */
    apartmentName: '',
    /** ヘッダに表示する部屋番号 */
    roomNumber: '',
    /** e-mansionとの紐づけがあるか */
    isLinkedEMansion: false,
    /** Five.Aとの紐づけがあるか */
    isLinkedFiveA: false,
    /** ucomとの紐づけがあるか */
    isLinkedUcom: false,
    /** mcloudとの紐づけがあるか */
    isLinkedMcloud: false,
    /** マイページ表示してよいか */
    isDispMyPage: false,
    /** ucomまたはe-mansionトップか */
    isUcomOrEmansionToppage: false,
    // e-mansion会員専用Topでvueのmounted実行中かどうか
    isMemberTopMountingEmansion: false,
    // ucom会員専用Topでvueのmounted実行中かどうか
    isMemberTopMountingUcom: false,
    // mcloud会員専用Topでvueのmounted実行中かどうか
    isMemberTopMountingMcloud: false,
    // ハンバーガーメニューのアイコンのcheckboxがcheckedかどうか
    isHamburgerChecked: false,
    // ログインボタンを表示する画面かどうか
    isDisplayedLoggedInButton: true,
    // ログインボタンを表示する画面かどうか
    isLogginedDisplayedLoggedInButton: false,
    // サブアカウント用表示判定
    isSubAccount: false,
    showUserInfo: true,
    // ボタン押下中かどうか
    isSubmitting: false,
    // 親アカウントかどうか
    isUcomParentAccountFlag: false,
    // メインアカウント(親アカウントかどうか)
    isEmansionParentAccountFlag: false,
    // つなぐオンラインショップアカウント連携済みかどうか
    isLinkedToTsunaguOnlineShop: false,
    //GW光と連携済みかどうか
    isLinkedGamewith: false,
  }),
  /** コンポーネント生成時の処理 */
  async mounted() {
    // ボタン押下中扱いの時は処理を抜ける
    if (this.isSubmitting) {
      return;
    }

    // ボタン押下中扱いとする
    this.isSubmitting = true;
    this.isLoggedIn = await AuthService.isLoggedIn();
    // e-mansion | ucom | mloud 会員専用トップのときスピナー表示させる用
    if (this.$route.path === '/e-mansion') {
      this.isMemberTopMountingEmansion = true;
    } else if (this.$route.path === '/ucom') {
      this.isMemberTopMountingUcom = true;
      // } else if (this.$route.path === '/mcloud') {
      //   this.isMemberTopMountingMcloud = true;
    }
    // スクロールを監視する
    window.addEventListener('scroll', this.onScroll);
    // 紐づけがされているサービスを確認（マイページ表示要否も確認）
    this.isLinkedService();
    this.disableDisplayedLoggedInButton();
    // ログインボタンの表示制御
    // 画面を新しく開く場合
    if (this.$route.path === '/error') {
      this.$data.isDisplayedLoggedInButton = false;
    } else if (this.$route.path === '/platform/cancel/completed') {
      this.$data.isDisplayedLoggedInButton = false;
    } else if (this.$route.name === 'Not Found') {
      this.$data.isDisplayedLoggedInButton = false;
    } else {
      this.$data.isDisplayedLoggedInButton = true;
    }

    // UCOM会員の場合は、親アカウントかどうか確認する
    if (this.isLinkedUcom) {
      this.isUcomParentAccountFlag = this.isUcomParentAccount();
    }

    // e-mansion会員の場合は、メインアカウント(親アカウント)かどうか確認する
    if (this.isLinkedEMansion) {
      this.isEmansionParentAccountFlag = this.isEmansionParentAccount();
    }

    this.isSubmitting = false;
    this.isSubAccount = await this.isSubAccountFunc();
  },
  /** コンポーネント破棄時の処理 */
  beforeUnmount() {
    // スクロールの監視を止める
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    isExistLeftMenuComponent() {
      const path = this.$route.path;
      if (path === '/e-mansion' || path === '/ucom') {
        return true;
      } else {
        return false;
      }
    },
    leftMenuComponent() {
      switch (this.$route.path) {
        case '/e-mansion':
          return EMansionLeftMenu;
        case '/ucom':
          return UcomLeftMenu;
        default:
          return '';
      }
    },
    /**
     * GW光会員登録中の画面かどうかを判定する
     */
    isGamewithRegisterProcess(): boolean {
      const member: Member | null = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus | null = this.$store.getters['memberStore/memberStatus'];
      //会員登録が完了している場合
      if (member && memberStatus) {
        return false;
      }
      //会員登録前 かつ 会員ステータスにGW顧客番号がある場合⇒GW会員登録中
      if (!member && memberStatus && memberStatus.encryptedPrimaryKeyGw) {
        return true;
      }
      //URLにgamewith=trueがある場合(規約・プライバシーポリシー)
      if (this.$route.query.gamewith && this.$route.query.gamewith == 'true') {
        return true;
      }
      const transitionInfo = JSON.parse(localStorage.getItem(TRANSITION_STORAGE)!);
      //メールアドレス入力画面で、transitionInfoに「gwid」がある場合
      if (this.$route.path.includes('input-email') && transitionInfo && transitionInfo.gwid) {
        return true;
      }
      return false;
    },
    /**GameWithの画面かどうか(URLにgamewithが含まれるならtrue) */
    isGamewithScreen(): boolean {
      return this.$route.path.includes('gamewith');
    },
  },
  watch: {
    async $route(to, from) {
      this.isHamburgerChecked = false; // ハンバーガーメニューを閉じる
      this.isLoggedIn = await AuthService.isLoggedIn();
      if (!this.$data.isDispMyPage) {
        await this.isDispMyPageFunc();
      }
      this.displayedLoggedInButton(); // 通常の画面遷移用
      this.disableDisplayedLoggedInButton();
      this.isLinkedService();
      this.isSubAccount = await this.isSubAccountFunc();
      if (to.path === '/e-mansion' || to.path === '/ucom') {
        this.$data.isUcomOrEmansionToppage = true;
      } else {
        this.$data.isUcomOrEmansionToppage = false;
      }
      if (to.path === '/e-mansion') {
        this.isMemberTopMountingEmansion = true;
      } else if (to.path === '/ucom') {
        this.isMemberTopMountingUcom = true;
        // } else if (to.path === '/mcloud') {
        //   this.isMemberTopMountingMcloud = true;
      }
      this.forceComponentReload();

      // UCOM会員の場合は、親アカウントかどうか確認する
      if (this.isLinkedUcom) {
        this.isUcomParentAccountFlag = this.isUcomParentAccount();
      }

      // e-mansion会員の場合は、メインアカウント(親アカウント)かどうか確認する
      if (this.isLinkedEMansion) {
        this.isEmansionParentAccountFlag = this.isEmansionParentAccount();
      }
    },
  },
  methods: {
    /** スクロールを検知し、画面最上部から 100px 以上スクロールしていたら「↑」ボタンを表示させる */
    onScroll() {
      this.$data.isShownToTop = window.scrollY > 100;
    },
    /** 「ログイン」ボタン押下時 : ログイン処理を行い「ログイン後トップ」画面に遷移する */
    async onLogin() {
      if (await AuthService.isLoggedIn()) {
        localStorage.removeItem(TRANSITION_STORAGE);
        return await AuthService.logout('/login-forwarding');
      } else {
        return await AuthService.login();
      }
    },
    /** 新規会員に遷移する */
    async onRegist() {
      if (await AuthService.isLoggedIn()) {
        localStorage.removeItem(TRANSITION_STORAGE);
        return await AuthService.logout('/login-forwarding');
      } else {
        return await AuthService.regist();
      }
    },
    /** ログアウト処理 */
    async onLogout() {
      await AuthService.logout();
    },
    /** マイページ遷移処理 */
    async onMyPage() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/platform/my-page')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** Connectix遷移処理 */
    async onIndex() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
      this.isHamburgerChecked = false; // ハンバーガーメニューを閉じる
    },
    /** マイページ表示してよいか */
    async isDispMyPageFunc() {
      if (this.$store.getters['memberStore/member']) {
        this.$data.isDispMyPage = true;
        return this.$data.isDispMyPage;
      } else {
        this.$data.isDispMyPage = false;
        return this.$data.isDispMyPage;
      }
    },
    /** サービス連携しているか */
    isLinkedService() {
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (this.isLoggedIn && member) {
        this.isDispMyPage = true;
        if (member.primaryKeyMye && member.propertyId) {
          const property: Property | null = this.$store.getters['propertyStore/property'];
          if (property) {
            if (property.uaType === UA_TYPE.E_MANSION) {
              this.isLinkedEMansion = true;
            } else {
              this.isLinkedEMansion = false;
            }
            if (property.uaType === UA_TYPE.FIVE_A) {
              this.isLinkedFiveA = true;
            } else {
              this.isLinkedFiveA = false;
            }
          }
        } else {
          this.isLinkedEMansion = false;
          this.isLinkedFiveA = false;
        }
        this.isLinkedUcom = member.primaryKeyUcom ? true : false;
        this.isLinkedMcloud = member.primaryKeyMcloud ? true : false;
        this.isLinkedToTsunaguOnlineShop = member.primaryKeyTnshop ? true : false;
        this.isLinkedGamewith = member.primaryKeyGW ? true : false;
      } else {
        this.isLinkedEMansion = false;
        this.isLinkedUcom = false;
        this.isLinkedMcloud = false;
        this.isLinkedFiveA = false;
        this.isLinkedToTsunaguOnlineShop = false;
        this.isLinkedGamewith = false;
      }
    },
    changeIsMemberTopMountingEmansion() {
      this.isMemberTopMountingEmansion = false;
    },
    changeIsMemberTopMountingUcom() {
      this.isMemberTopMountingUcom = false;
    },
    changeIsMemberTopMountingMcloud() {
      this.isMemberTopMountingMcloud = false;
    },
    clickContacts(value: boolean) {
      this.isHamburgerChecked = value;
    },
    cancelLoggedIn() {
      this.isLoggedIn = false;
    },
    displayedLoggedInButton() {
      if (this.$route.path === '/error') {
        this.$data.isDisplayedLoggedInButton = false;
      } else if (this.$route.path === '/platform/cancel/completed') {
        this.$data.isDisplayedLoggedInButton = false;
      } else if (this.$route.name === 'Not Found') {
        this.$data.isDisplayedLoggedInButton = false;
      } else {
        this.$data.isDisplayedLoggedInButton = true;
      }
    },
    disableDisplayedLoggedInButton() {
      if (this.$route.path === '/') {
        this.isLogginedDisplayedLoggedInButton = true;
      } else {
        this.isLogginedDisplayedLoggedInButton = false;
      }
    },
    async isSubAccountFunc() {
      const planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      return hasAdditionalAccount(planType);
    },
    forceComponentReload() {
      this.showUserInfo = false;
      this.$nextTick(() => {
        this.showUserInfo = true;
      });
    },
    /**
     * UCOM
     * お客様番号ユーザー(親アカウント)の場合、trueを返す
     */
    isUcomParentAccount(): boolean {
      const loginUserAuthority: UCOM_ACCOUNT_AUTHORITY = this.$store.getters['ucomAccountStore/ucomAccount']?.accountInfo.accountAuthorization;
      // お客様番号ユーザー(親アカウント)の場合のみ、true
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER) {
        return true;
      }
      return false;
    },
    /**
     * e-mansion
     * メインアカウント(親アカウント)の場合、trueを返す
     */
    isEmansionParentAccount(): boolean {
      const loginUserAuthority: E_MANSION_ACCOUNT_AUTHORITY = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']?.eMansionAccountInfo.accountAuthorization;
      // メインアカウント(親アカウント)の場合のみ、true
      if (loginUserAuthority === E_MANSION_ACCOUNT_AUTHORITY.MAIN_ACCOUNT) {
        return true;
      }
      return false;
    },
    /**
     * 別タブで開いたつなぐオンラインショップの商品のタブを閉じる処理
     */
    closeTabTsunaguOnlineProductSizeSp(): void {
      window.close();
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにする(下の階層のコンポーネントから伝播されてくる)
     */
    updateLeftMenuLogo(): void {
      this.isLinkedService();
    },
  },
});
</script>
