<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>アカウントID&emsp;登録・変更&emsp;入力</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>アカウントID 登録・変更 入力</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID登録変更" />アカウントID&emsp;登録・変更&emsp;入力</h2>
        <p v-if="!isAccountRegistrationMax">アカウントIDの情報を入力し、「次へ進む」ボタンを押してください。</p>
        <error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" :isOneSentence="isOneSentence" />
      </div>
      <!-- /blc -->

      <div v-if="!isAccountRegistrationMax" class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID<span class="req">必須</span></th>
              <td>
                <input v-bind:disabled="isDisabled" type="text" class="text middle" placeholder="アカウントID" v-model="accountName" /><br />
                <p class="character-adjustment grey mt10">
                  ※半角英小文字数字記号(-)がご利用いただけます。<br />
                  ※3文字以上、15文字以内でご入力ください。<br />
                  ※先頭は英小文字のみ、末尾は英小文字または数字を使用してください。
                </p>
              </td>
            </tr>
            <tr>
              <th class="va-middle">氏名<span class="req">必須</span></th>
              <td>
                （姓）
                <input type="text" class="text middle" placeholder="姓" v-model="familyName" />&emsp;（名）<input
                  type="text"
                  class="text middle sp-mt10"
                  placeholder="名"
                  v-model="firstName"
                />
              </td>
            </tr>
            <tr>
              <th class="va-middle">氏名カナ<span class="req">必須</span></th>
              <td>
                （姓カナ）<input type="text" class="text middle" placeholder="セイ" v-model="familyNameKana" />&emsp;（名カナ）<input
                  type="text"
                  class="text middle sp-mt10"
                  placeholder="メイ"
                  v-model="firstNameKana"
                /><br />
                <p class="att grey mt10">&emsp;※全角カナ</p>
              </td>
            </tr>
            <tr>
              <th class="va-middle">ユーザーネーム<span class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="" v-model="userName" />
                <br />
                <p class="character-adjustment grey mt10">
                  ※表示用の名称です。(例)お父さん、お姉ちゃん など<br />
                  ※全角、半角英数字、半角記号(-_.)がご利用いただけます。<br />
                  ※32文字以内で入力してください。<br />
                </p>
              </td>
            </tr>
            <tr>
              <th class="va-middle">パスワード<span class="req">必須</span></th>
              <td>
                <div class="passwordForm">
                  <input :type="inputType" class="input-pass text short" placeholder="" id="password" v-model="password" />
                  <span v-if="!isPasswordView" class="material-icons material-symbols-outlined" v-on:click="onPasswordView()"> visibility </span>
                  <span v-if="isPasswordView" class="material-icons material-symbols-outlined" v-on:click="onPasswordView()"> visibility_off </span>
                </div>
                <br />
                <p class="att grey mt10">&emsp;※確認のため、再度ご入力ください</p>
                <br />
                <div class="passwordForm">
                  <input :type="inputTypeConf" class="input-pass text short" placeholder="" id="password2" v-model="passwordConf" />
                  <span v-if="!isPasswordViewConf" class="material-icons material-symbols-outlined" v-on:click="onPasswordViewConf()"> visibility </span>
                  <span v-if="isPasswordViewConf" class="material-icons material-symbols-outlined" v-on:click="onPasswordViewConf()"> visibility_off </span>
                  <span class="field-icon">
                    <i toggle="#password-field" class="mdi mdi-eye toggle-password"></i>
                  </span>
                </div>
                <p class="character-adjustment grey mt10">
                  ※半角英数字、半角記号(-_.)がご利用いただけます。<br />
                  ※8文字以上、16文字以内で入力してください<br />
                  ※数字・アルファベットをそれぞれ1文字以上使用してください。<br />
                  ※アカウントIDと同じパスワードはご利用になれません
                </p>
              </td>
            </tr>
            <tr>
              <th class="va-middle">アカウント権限種別<span class="req">必須</span></th>
              <td>
                <PermissionControledInputRadio
                  :options="radioOptions"
                  name="category"
                  v-model="accountAuthorization"
                  itemId="04"
                  screenId="003"
                  :functionId="functionIdAccountList"
                  :isDisabled="isDisabledAuthorization"
                ></PermissionControledInputRadio>
                <br />
                <p class="att grey mt10">
                  &emsp;※権限については<a :href="`${ucomMemberSupportUrl}`" target="_blank"
                    ><font color="blue"><u>こちら</u></font></a
                  >をご覧ください。
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button v-if="!isAccountRegistrationMax" class="btn btn01 bs" v-on:click="onNext()">次へ進む<i class="material-icons link link-icon">east</i></button>
          <button v-if="isAccountRegistrationMax" class="btn btn05 bs">次へ進む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.character-adjustment {
  font-size: 13px;
  padding-left: 1em;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountManagementAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-management-accessor';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import Encoding from 'encoding-japanese';
import { SERVICE_ID } from '@/shared/const/service-ids';
import PermissionControledInputRadio from '@/shared/components/permission-controled-input-radio.vue';
import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';
import { checkRouterError } from '@/shared/util/router-navigation-func';

const ACCOUNT_AUTHORIZATION_USER = '1';
const ACCOUNT_AUTHORIZATION_MANAGER = '2';

export default defineComponent({
  name: 'account-management-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    PermissionControledInputRadio,
  },
  data: () => ({
    isAccountRegistrationMax: false, // アカウント登録上限の場合true
    isLoading: false, // ローディング
    errorMessages: new Array<string>(), // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isOneSentence: false, // エラータイトルのみ表示かどうか
    isPasswordView: false, // パスワード表示制御
    isPasswordViewConf: false, // 確認用パスワード表示制御
    isDisabled: false, // アカウントID入力制御
    registerOrUpdate: 'N',
    ucomMemberSupportUrl: process.env.VUE_APP_UCOM_MEMBER_SUPPORT_MY_PAGE_FUNCTION_URL, // UCOM光：会員サポート：マイページの機能画面
    isUpdate: '0', //更新か登録かを判断するフラグ
    /* 各種パラメータ */
    accountName: '',
    familyName: '',
    firstName: '',
    familyNameKana: '',
    firstNameKana: '',
    userName: '',
    password: '',
    passwordConf: '',
    accountAuthorization: '',
    ucomAccountId: '',
    loginUserAuthority: '',
    loginUserAccountId: '',
    isDisabledAuthorization: false,
    before: '',
    functionIdAccountList: SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT,
    radioOptions: [
      { label: 'ユーザー権限', value: ACCOUNT_AUTHORIZATION_USER },
      { label: '管理者権限', value: ACCOUNT_AUTHORIZATION_MANAGER },
    ],
  }),
  computed: {
    inputType: function () {
      return this.isPasswordView ? 'text' : 'password';
    },
    inputTypeConf: function () {
      return this.isPasswordViewConf ? 'text' : 'password';
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      // 前画面(アカウントID管理：一覧)からの情報をStoreから取得
      this.ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
      this.before = this.$store.getters['ucomAccountManagementInputStore/before'];

      //ログインしているアカウントの権限
      this.loginUserAuthority = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountAuthorization;
      //ログインしているアカウントID
      this.loginUserAccountId = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountName;

      // 画面項目データセット
      await this.setData(this.ucomAccountId);
      //管理者アカウントかつ自身のアカウントの場合はアカウント権限を非活性にする
      this.isDisabledAuthorization = this.setDisabledAccountAuthorization();

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async setData(ucomAccountId: string): Promise<void> {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      if (this.$store.getters['ucomAccountManagementInputStore/accountName']) {
        this.setDataByStore();
        this.setDisabledAccountNameForm(this.ucomAccountId);
        return;
      }
      this.registerOrUpdate = 'R';
      // 更新の場合、値を取得して設定
      if (ucomAccountId) {
        // アカウントID管理 入力・更新 初期表示用 アカウント情報取得
        const accountInputInitInfo = await SpfApiAccountManagementAccessor.getAccountInputInitInfo(ucomAccountId);
        // 画面項目データをセット
        this.accountName = accountInputInitInfo.accountName;
        this.familyName = accountInputInitInfo.familyName;
        this.firstName = accountInputInitInfo.firstName;
        this.familyNameKana = accountInputInitInfo.familyNameKana;
        this.firstNameKana = accountInputInitInfo.firstNameKana;
        this.userName = accountInputInitInfo.userName;
        this.password = ObfuscationService.decode(accountInputInitInfo.password);
        this.passwordConf = ObfuscationService.decode(accountInputInitInfo.password);
        this.accountAuthorization = accountInputInitInfo.accountAuthorization;
        this.isUpdate = '1';
        this.registerOrUpdate = 'U';
        this.setDisabledAccountNameForm(ucomAccountId);
        return;
      }

      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];
      // 登録の場合、アカウント上限チェックを実行
      const accountRegistrationMax = await SpfApiAccountManagementAccessor.checkAccountRegistrationMax(member.id);
      // アカウント登録上限の場合
      if (accountRegistrationMax.isAccountRegistrationMax) {
        this.isAccountRegistrationMax = accountRegistrationMax.isAccountRegistrationMax;
        this.errorMessages.push(accountRegistrationMax.message ? accountRegistrationMax.message : '');
        this.isOneSentence = true;
      }
      // 登録の場合 ユーザ権限にチェック
      this.accountAuthorization = ACCOUNT_AUTHORIZATION_USER;
    },
    // storeからデータをセット
    setDataByStore(): void {
      this.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.familyName = this.$store.getters['ucomAccountManagementInputStore/familyName'];
      this.firstName = this.$store.getters['ucomAccountManagementInputStore/firstName'];
      this.familyNameKana = this.$store.getters['ucomAccountManagementInputStore/familyNameKana'];
      this.firstNameKana = this.$store.getters['ucomAccountManagementInputStore/firstNameKana'];
      this.userName = this.$store.getters['ucomAccountManagementInputStore/userName'];
      this.password = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.passwordConf = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.accountAuthorization = this.$store.getters['ucomAccountManagementInputStore/accountAuthorization'];
      this.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      this.isUpdate = this.$store.getters['ucomAccountManagementInputStore/isUpdate'];
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // 前画面から保持している値を削除
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      if (this.loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.NORMAL_ACCOUNT.toString()) {
        // アカウントIDユーザーの場合アカウントID情報一覧画面へ遷移
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // お客様番号ユーザーの場合前画面へ遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push(this.before).catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // 次へボタンクリック
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // エラーメッセージ初期化
      this.errorMessages = [];

      // バリデーションチェック
      if (this.hasValidationError(this.isUpdate)) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      try {
        // APIチェック(登録時)
        const accountManagementInputCheck = await SpfApiAccountManagementAccessor.checkAccountManagementInput(
          this.accountName,
          this.familyName,
          this.firstName,
          this.familyNameKana,
          this.firstNameKana,
          this.userName,
          ObfuscationService.encode(this.password),
          ObfuscationService.encode(this.passwordConf),
          this.accountAuthorization,
          this.isUpdate
        );

        // エラーメッセージがあった場合
        if (accountManagementInputCheck.errorMessages.length > 0) {
          this.errorMessages = accountManagementInputCheck.errorMessages;
          // エラーメッセージを見せるために画面最上部にスクロール
          this.scrollPageTop();
          this.isLoading = false;
          return;
        }
      } catch (error) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // メールボックス容量情報をstoreに保存
      this.saveStore();

      // 確認画面へ遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // storeへ保存
    saveStore(): void {
      this.$store.commit('ucomAccountManagementInputStore/accountName', this.accountName);
      this.$store.commit('ucomAccountManagementInputStore/familyName', this.familyName);
      this.$store.commit('ucomAccountManagementInputStore/firstName', this.firstName);
      this.$store.commit('ucomAccountManagementInputStore/familyNameKana', this.familyNameKana);
      this.$store.commit('ucomAccountManagementInputStore/firstNameKana', this.firstNameKana);
      this.$store.commit('ucomAccountManagementInputStore/userName', this.userName);
      this.$store.commit('ucomAccountManagementInputStore/password', (this.password = ObfuscationService.encode(this.password)));
      this.$store.commit('ucomAccountManagementInputStore/accountAuthorization', this.accountAuthorization);
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', this.ucomAccountId);
      this.$store.commit('ucomAccountManagementInputStore/before', this.before);
      this.$store.commit('ucomAccountManagementInputStore/isUpdate', this.isUpdate);
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
    onPasswordView() {
      this.isPasswordView = !this.isPasswordView;
    },
    onPasswordViewConf() {
      this.isPasswordViewConf = !this.isPasswordViewConf;
    },
    // 更新の場合アカウントIDの入力欄をdisabled制御
    setDisabledAccountNameForm(accountId: string) {
      if (accountId) {
        this.isDisabled = true;
      }
    },
    //管理者アカウントかつ自身のアカウントの場合、権限を変更できない
    setDisabledAccountAuthorization() {
      if (this.loginUserAuthority !== '2') {
        return false;
      }
      if (this.loginUserAccountId != this.accountName) {
        return false;
      }
      return true;
    },
    hasValidationError(isUpdate: string): boolean {
      const ACCOUNT_NAME_MIN_LENGTH = 3;
      const ACCOUNT_NAME_MAX_LENGTH = 15;
      const USER_NAME_MAX_LENGTH = 32;
      const PASSWORD_MIN_LENGTH = 8;
      const PASSWORD_MAX_LENGTH = 16;
      const PASSWORD_CONF_MIN_LENGTH = 8;
      const PASSWORD_CONF_MAX_LENGTH = 16;

      const ERROR_ACCOUNT_NAME_REQUIRED = '「アカウントID」を入力してください。';
      const ERROR_FAMILY_NAME_REQUIRED = '「氏名（姓）」を入力してください。';
      const ERROR_FIRST_NAME_REQUIRED = '「氏名（名）」を入力してください。';
      const ERROR_FAMILY_NAME_KANA_REQUIRED = '「氏名カナ（姓）」を入力してください。';
      const ERROR_FIRST_NAME_KANA_REQUIRED = '「氏名カナ（名）」を入力してください。';
      const ERROR_USER_NAME_REQUIRED = '「ユーザーネーム」を入力してください。';
      const ERROR_PASSWORD_REQUIRED = '「パスワード」を入力してください。';
      const ERROR_PASSWORD_CONF_REQUIRED = '「パスワード（確認用）」を入力してください。';
      const ERROR_ACCOUNT_AUTHORIZATION_REQUIRED = '「アカウント権限種別」を入力してください。';

      const ERROR_ACCOUNT_NAME_LENGTH = '「アカウントID」は' + ACCOUNT_NAME_MIN_LENGTH + '文字以上' + ACCOUNT_NAME_MAX_LENGTH + '文字以下で入力してください。';
      const ERROR_USER_NAME_LENGTH = '「ユーザーネーム」は' + USER_NAME_MAX_LENGTH + '文字以下で入力してください。';
      const ERROR_PASSWORD_LENGTH = '「パスワード」は' + PASSWORD_MIN_LENGTH + '文字以上、' + PASSWORD_MAX_LENGTH + '文字以下で入力してください。';
      const ERROR_PASSWORD_CONF_LENGTH = '「パスワード（確認用）」は' + PASSWORD_CONF_MIN_LENGTH + '文字以上、' + PASSWORD_CONF_MAX_LENGTH + '文字以下で入力してください。';

      const ERROR_ACCOUNT_NAME_FORMAT = '「アカウントID」は半角英字小文字、半角数字、半角記号(-)で入力してください。';
      const ERROR_FAMILY_NAME_FORMAT = '「氏名（姓）」に使用できない文字が含まれています。';
      const ERROR_FIRST_NAME_FORMAT = '「氏名（名）」に使用できない文字が含まれています。';
      const ERROR_FAMILY_NAME_KANA_FORMAT = '「氏名カナ（姓）」は全角カナで入力してください。';
      const ERROR_FIRST_NAME_KANA_FORMAT = '「氏名カナ（名）」は全角カナで入力してください。';
      const ERROR_USER_NAME_FORMAT = '「ユーザーネーム」は全角、半角英数字、半角記号(-_.)で入力してください。';
      const ERROR_PASSWORD_FORMAT = '「パスワード」は半角英数字、半角記号(-_.)で入力してください。';
      const ERROR_PASSWORD_CONF_FORMAT = '「パスワード（確認用）」は半角英数字、半角記号(-_.)で入力してください。';

      // 必須チェック
      if (!this.accountName) {
        this.errorMessages.push(ERROR_ACCOUNT_NAME_REQUIRED);
      }
      if (!this.familyName) {
        this.errorMessages.push(ERROR_FAMILY_NAME_REQUIRED);
      }
      if (!this.firstName) {
        this.errorMessages.push(ERROR_FIRST_NAME_REQUIRED);
      }
      if (!this.familyNameKana) {
        this.errorMessages.push(ERROR_FAMILY_NAME_KANA_REQUIRED);
      }
      if (!this.firstNameKana) {
        this.errorMessages.push(ERROR_FIRST_NAME_KANA_REQUIRED);
      }
      if (!this.userName) {
        this.errorMessages.push(ERROR_USER_NAME_REQUIRED);
      }
      if (!this.password) {
        this.errorMessages.push(ERROR_PASSWORD_REQUIRED);
      }
      if (!this.passwordConf) {
        this.errorMessages.push(ERROR_PASSWORD_CONF_REQUIRED);
      }
      if (!this.accountAuthorization) {
        this.errorMessages.push(ERROR_ACCOUNT_AUTHORIZATION_REQUIRED);
      }

      // 桁数チェック
      // アカウントIDは登録時のみ
      if (isUpdate !== '1') {
        if (this.accountName.length < ACCOUNT_NAME_MIN_LENGTH || ACCOUNT_NAME_MAX_LENGTH < this.accountName.length) {
          this.errorMessages.push(ERROR_ACCOUNT_NAME_LENGTH);
        }
      }
      if (USER_NAME_MAX_LENGTH < this.userName.length) {
        this.errorMessages.push(ERROR_USER_NAME_LENGTH);
      }
      if (PASSWORD_MAX_LENGTH < this.password.length) {
        this.errorMessages.push(ERROR_PASSWORD_LENGTH);
      }
      if (PASSWORD_CONF_MAX_LENGTH < this.passwordConf.length) {
        this.errorMessages.push(ERROR_PASSWORD_CONF_LENGTH);
      }
      if (PASSWORD_MIN_LENGTH > this.password.length) {
        this.errorMessages.push(ERROR_PASSWORD_LENGTH);
      }
      if (PASSWORD_CONF_MIN_LENGTH > this.passwordConf.length) {
        this.errorMessages.push(ERROR_PASSWORD_CONF_LENGTH);
      }
      if (this.errorMessages.length > 0) {
        //ここまでにエラーがあった場合はフォーマットチェックを行わずに返す
        return true;
      }

      // フォーマットチェック
      //半角はNG・全角数字、全角英字、全角記号はOK
      if (this.isIncludeGaiji(this.familyName, false)) {
        this.errorMessages.push(ERROR_FAMILY_NAME_FORMAT);
      }
      if (this.isIncludeGaiji(this.firstName, false)) {
        this.errorMessages.push(ERROR_FIRST_NAME_FORMAT);
      }

      // アカウントIDは登録時のみ
      if (isUpdate !== '1') {
        if (!this.accountName.match(/^[a-z][-a-z0-9]{1,13}[a-z0-9]$/)) {
          this.errorMessages.push(ERROR_ACCOUNT_NAME_FORMAT);
        }
      }

      if (!this.familyNameKana.match(/^[ァ-ヴー]+$/)) {
        this.errorMessages.push(ERROR_FAMILY_NAME_KANA_FORMAT);
      }
      if (!this.firstNameKana.match(/^[ァ-ヴー]+$/)) {
        this.errorMessages.push(ERROR_FIRST_NAME_KANA_FORMAT);
      }
      if (!this.isUserName(this.userName)) {
        this.errorMessages.push(ERROR_USER_NAME_FORMAT);
      }
      if (this.password.match(/[^a-zA-Z0-9\-_.]+/)) {
        this.errorMessages.push(ERROR_PASSWORD_FORMAT);
      }
      if (this.passwordConf.match(/[^a-zA-Z0-9\-_.]+/)) {
        this.errorMessages.push(ERROR_PASSWORD_CONF_FORMAT);
      }
      if (!this.isValidAccountAuthorization(this.accountAuthorization)) {
        this.errorMessages.push(ERROR_ACCOUNT_AUTHORIZATION_REQUIRED);
      }

      return this.errorMessages.length > 0 ? true : false;
    },
    isUserName(value: string) {
      // 許容する半角文字(半角英数字記号[-_.])をパラメータから削除
      const removeValue = value.replace(/[a-zA-Z0-9\-_.]/g, '');

      // 値が半角文字のみの場合
      if (removeValue === '' && value.match(/[a-zA-Z0-9\-_.]+/)) {
        return true;
      }
      if (this.isIncludeGaiji(value, true)) {
        return false;
      }
      // ' ' -> x20, '~' -> x7f, '｡' -> U+FF61, 'ﾟ' -> UFF9F
      // " -~" 表示可能な半角文字、"｡-" -> 半角カナ
      return removeValue.match(/^[-_.a-zA-Z0-9\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]{1,32}$/) ? true : false;
    },
    isIncludeGaiji(value: string, acceptHankakuEiSuu: boolean): boolean {
      for (let i = 0; i < value.length; i++) {
        //入力値から1文字取り出す
        const shiftArray = Encoding.convert([value.charCodeAt(i)], {
          to: 'SJIS',
          from: 'UNICODE',
        });
        let result: boolean;
        //長さが1の場合→半角文字
        if (shiftArray.length < 2) {
          const shiftStr16 = shiftArray[0].toString(16);
          const shiftNum10 = parseInt(shiftStr16, 16);
          result = this.checkHalf(shiftNum10, acceptHankakuEiSuu);
        } else {
          const shiftStr16 = shiftArray[0].toString(16) + shiftArray[1].toString(16);
          const shiftNum10 = parseInt(shiftStr16, 16);
          result = this.isGaiji(shiftNum10);
        }
        if (result) {
          return true;
        }
      }
      return false;
    },
    /**
     * 許可されない文字が含まれているかどうかチェック
     * @param shiftNum
     * @param acceptHankakuEiSuu true:半角を許可
     */
    checkHalf(shiftNum: number, acceptHankakuEiSuu: boolean): boolean {
      const hankakuCharFr = 0x20; //半角英数許可用
      const hankakuCharTo = 0x7f;
      if (!acceptHankakuEiSuu) {
        return true;
      }
      if (shiftNum < hankakuCharFr) {
        return true;
      }
      if (shiftNum > hankakuCharTo) {
        return true;
      }
      return false;
    },
    /**
     * 外字が含まれているかどうかチェック
     * @param shiftNum
     */
    isGaiji(shiftNum: number): boolean {
      const necCharFr = 0xed40;
      const necCharTo = 0xeefc;
      const ibmCharFr = 0xfa40;
      const ibmCharTo = 0xfcee;
      if (shiftNum >= necCharFr && shiftNum <= necCharTo) {
        return true;
      }
      if (shiftNum >= ibmCharFr && shiftNum <= ibmCharTo) {
        return true;
      }
      return false;
    },
    /**
     * 妥当なユーザ権限か
     * @param value 入力値
     */
    isValidAccountAuthorization(value: string): boolean {
      return value === ACCOUNT_AUTHORIZATION_USER || value === ACCOUNT_AUTHORIZATION_MANAGER;
    },
  },
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn04:hover {
  opacity: 1;
}
.input-pass {
  border: none;
  outline: none;
}
.passwordForm {
  width: fit-content;
  border: #cacaca solid 1px;
  border-radius: 2px;
}
::-ms-reveal {
  display: none;
}
</style>
