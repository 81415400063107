<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>追加アカウント</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>追加アカウント</li>
      </ul>

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご利用状況</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:追加アカウント　確認(お申し込み) -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="追加アカウント" />ご解約受付完了</h2>

        <div class="sblc">
          <div class="sblc">
            <p class="red"><b>アカウントの解約を受付けました。</b></p>
            お手続き内容をメールにてお送りいたしました。
            <table class="table-type2">
              <tbody>
                <tr>
                  <th><b>解約対象アカウント</b></th>
                  <td class="red">
                    <b>{{ accountName }}</b>
                  </td>
                </tr>
                <tr>
                  <th><b>解約対象メールアドレス</b></th>
                  <td class="red">
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
                <tr>
                  <th>サービス終了日</th>
                  <td>{{ cancelOn }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="sblc">
          <div class="sblc">
            <ul class="ul-basic01">
              <li>アカウントは、下記のタイミングで解約されます。同時にメールアドレスもご利用できなくなります。</li>
              <ul class="ul-basic01">
                <li>1日～20日までに解約登録された場合：当月末日で解約</li>
                <li>21日～月末までに解約登録された場合：翌月末日で解約</li>
              </ul>
              <li class="red">解約を実行したアカウント・メールアドレスは元に戻すことができません。</li>
              <li>解約したアカウントに付随しているサービスは、アカウント解約と同時にサービスが終了します。</li>
              <li>
                <font class="red">【重要！】アカウントが解約される前に、必ず解約対象アカウントのメール受信を行ってください。</font>
                解約前に届いていて受信しなかったメールは、解約後に確認できなくなります。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /blc -->

      <div class="blc">
        <p class="form-btn-txt"><b>続けてお申し込み・解約される場合は、「ご利用状況確認」をクリックしてください。</b></p>
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goList()">ご利用状況確認<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs mt-sp-1" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'cancel-complete',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    accountName: '',
    mailAddress: '',
    cancelOn: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //メインメールアドレス情報をAPIより取得する
    try {
      this.$data.accountName = this.$store.getters['eMansionSubAccountStore/account'];
      this.$data.mailAddress = this.$store.getters['eMansionSubAccountStore/mailAddress'];
      this.$data.cancelOn = this.$store.getters['eMansionSubAccountStore/cancelOn'];
      if (!this.$data.accountName) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.$data.mailAddress) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.$data.cancelOn) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('eMansionSubAccountStore/desiredAccount', undefined);
      await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },

    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
  },
});
</script>
