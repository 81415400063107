<template>
  <div class="member-confirm">
    <LoadingComponent v-if="isSubmitting" />
    <main class="underlayer-main">
      <h1>GameWith光 登録情報変更</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/gamewith">GameWith光マイページ</router-link></li>
        <li>登録情報変更</li>
        <li>確認</li>
      </ul>
      <div class="blc">
        <p>GameWith光は、株式会社GameWithが提供する光ファイバーインターネットサービスです。</p>
      </div>
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>登録情報変更 入力</li>
        <li class="stay">登録情報変更 確認</li>
        <li>登録情報変更 完了</li>
      </ul>

      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages.length > 0" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="会員情報変更" />GameWith光 登録情報変更 確認</h2>
      </div>
      <table class="table-type2">
        <tbody v-if="form">
          <tr>
            <th class="va-middle">お名前</th>
            <td class="td">{{ form.lastName }} {{ form.firstName }}</td>
          </tr>
          <tr>
            <th class="va-middle">フリガナ</th>
            <td class="td">{{ form.lastNameKana }} {{ form.firstNameKana }}</td>
          </tr>
          <tr>
            <th class="va-middle">メールアドレス</th>
            <td class="td">{{ form.email }}</td>
          </tr>
          <tr>
            <th class="va-middle">電話番号</th>
            <td class="td">{{ form.phone }}</td>
          </tr>
          <tr>
            <th class="va-middle">郵便番号</th>
            <td class="td">{{ form.postalCode }}</td>
          </tr>
          <tr>
            <th class="va-middle">都道府県</th>
            <td class="td">{{ form.state }}</td>
          </tr>
          <tr>
            <th class="va-middle">市区郡</th>
            <td class="td">{{ form.city }}</td>
          </tr>
          <tr>
            <th class="va-middle">町名</th>
            <td class="td">{{ form.street }}</td>
          </tr>
          <tr>
            <th class="va-middle">丁目・番地・号</th>
            <td class="td">{{ form.banchi }}</td>
          </tr>
          <tr>
            <th class="va-middle">建物名</th>
            <td class="td">{{ form.tatemono }}</td>
          </tr>
          <tr>
            <th class="va-middle">部屋番号</th>
            <td class="td">{{ form.roomNumber }}</td>
          </tr>
          <tr>
            <th class="va-middle">転用承諾番号</th>
            <td class="td">{{ form.switchingNumberNTT }}</td>
          </tr>
          <tr>
            <th class="va-middle">事業者変更承諾番号</th>
            <td class="td">{{ form.switchingNumberCollabo }}</td>
          </tr>
        </tbody>
      </table>
      <div class="blc">
        <p class="form-btn-txt">変更内容をご確認の上、よろしければ「変更」ボタンを押してください。</p>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn bs" :class="{ btn01: !canNotEdit, btn04: canNotEdit }" :disabled="canNotEdit" @click="onNext()">
            変更<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
td {
  word-wrap: break-word; /** 画面に応じた文字の改行 */
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { GameWithEditInputForm } from '@/shared/classes/gamewith/edit-input-form';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { UpdateGamewithCustomerRequest } from '@/infra/request/spf/gamewith/update-gamewith-customer-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';

type DataType = {
  form: GameWithEditInputForm | null;
  errorMessages: string[];
  errorMessageTitle: string;
  isSubmitting: boolean;
  canNotEdit: boolean;
};

/** MemberConfirm コンポーネント */
export default defineComponent({
  name: 'member-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): DataType {
    return {
      form: null,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      isSubmitting: false,
      canNotEdit: false,
    };
  },
  async mounted(): Promise<void> {
    this.form = this.$store.getters['gamewithMemberEditStore/gamewithEditInputForm'];
    if (!this.form) {
      //リロードや直打ちの場合入力画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/gamewith/member-edit').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    async onBack() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      this.$store.commit('gamewithMemberEditStore/gamewithEditInputForm', this.form);
      await this.$router.push('/gamewith/member-edit').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    },
    async onNext() {
      if (this.isSubmitting) {
        return;
      }
      if (!this.form) {
        return;
      }
      this.isSubmitting = true;
      //更新APIを実行
      const primaryKeyGW = this.$store.getters['memberStore/memberStatus'].encryptedPrimaryKeyGw;
      //必須でない項目は未入力の場合nullで更新する
      const updateRequest = new UpdateGamewithCustomerRequest({
        _lastName: this.form.lastName,
        _firstName: this.form.firstName,
        _lastNameKana: this.form.lastNameKana,
        _firstNameKana: this.form.firstNameKana,
        _postalCode: this.form.postalCode,
        _state: this.form.state,
        _city: this.form.city,
        _street: this.form.street,
        _banchi: this.form.banchi,
        _tatemono: this.form.tatemono || null,
        _roomNumber: this.form.roomNumber || null,
        _phone: this.form.phone,
        _email: this.form.email,
        _switchingNumberNTT: this.form.switchingNumberNTT || null,
        _switchingNumberCollabo: this.form.switchingNumberCollabo || null,
      });
      try {
        await SpfApiExternalGamewithAccessor.updateCustomer(updateRequest, primaryKeyGW);
      } catch (error: any) {
        if (error.response?.status == '503') {
          this.errorMessages.push(
            'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
          );
          this.canNotEdit = true;
          this.errorMessageTitle = '';
          this.showErrorMessage();
          return;
        }
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.UPDATE_CUSTOMER_NAME);
      }
      //ストアに保存
      this.$store.commit('gamewithMemberEditStore/gamewithEditInputForm', this.form);
      //完了画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/gamewith/member-completed').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    },
  },
});
</script>
