import axios from 'axios';

import { UcomErrorResponse } from '@/pages/ucom/classes/external-api/error-response';
import { UcomOpticalPhoneCodesResponseResult, UcomOpticalPhoneCodesResponse } from '@/pages/ucom/classes/external-api/optical-phone-codes-response';
import { UcomNumberGetterResponseResult, UcomNumberGetterResponse } from '@/pages/ucom/classes/external-api/number-getter-response';
import { UcomAdditionalServicesResponseResult, UcomAdditionalServicesResponse } from '@/pages/ucom/classes/external-api/additional-services-response';
import { UcomPhoneCompaniesResponseResult, UcomPhoneCompaniesResponse } from '@/pages/ucom/classes/external-api/phone-companies-response';
import { UcomPaymentStatusResponse, UcomPaymentStatusResponseResult } from '@/pages/ucom/classes/external-api/payment-status-response';
import { BaseApiService } from '../api/base-api-service';

const portasApi = BaseApiService.api();

/** UCOM 外部 API をコールするサービス */
export class UcomExternalApiService {
  /**
   * 光電話申込コード確認
   *
   * @param id UCOM建物ID
   * @return 光電話申込みコード
   */
  public static async findOpticalPhoneCodes(id: string): Promise<UcomOpticalPhoneCodesResponse | UcomErrorResponse> {
    try {
      const response = await portasApi.get<UcomOpticalPhoneCodesResponseResult>(`/external/ucom/optical-phone-codes/${id}`);
      return new UcomOpticalPhoneCodesResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 電話番号取得方法取得
   *
   * @return 電話番号取得方法
   */
  public static async findNumberGetter(): Promise<UcomNumberGetterResponse | UcomErrorResponse> {
    try {
      const response = await portasApi.get<UcomNumberGetterResponseResult>('/external/ucom/number-getter');
      return new UcomNumberGetterResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 光電話付加サービス取得
   *
   * @return 光電話付加サービス
   */
  public static async findAdditionalServices(): Promise<UcomAdditionalServicesResponse | UcomErrorResponse> {
    try {
      const response = await portasApi.get<UcomAdditionalServicesResponseResult>('/external/ucom/additional-services');
      return new UcomAdditionalServicesResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * ご利用中の電話会社取得
   *
   * @return ご利用中の電話会社
   */
  public static async findPhoneCompanies(): Promise<UcomPhoneCompaniesResponse | UcomErrorResponse> {
    try {
      const response = await portasApi.get<UcomPhoneCompaniesResponseResult>('/external/ucom/phone-companies');
      return new UcomPhoneCompaniesResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * UCOM お支払い情報登録状態取得API
   *
   * @param customerId 顧客 ID
   * @return 結果値
   */
  public static async getPaymentStatus(customerId: string): Promise<UcomPaymentStatusResponse | UcomErrorResponse> {
    try {
      const response = await portasApi.get<UcomPaymentStatusResponseResult>(`/external/ucom/payment-status/${customerId}`);
      return new UcomPaymentStatusResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return UCOM API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): UcomErrorResponse {
    if (error instanceof Error && error.message === 'Nothing Result') {
      return new UcomErrorResponse({ errorInfo: [error.message] });
    }

    if (error instanceof axios.Cancel) {
      throw error;
    }

    if (axios.isAxiosError(error)) {
      const errorResponse: UcomErrorResponse = error.response?.data;
      if (errorResponse) return new UcomErrorResponse(errorResponse);
    }
    throw error;
  }
}
