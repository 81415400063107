<template>
  <div class="ucom-connectix-apply">
    <LoadingComponent v-if="isMounting || isOnApplyExecuting" />
    <main class="underlayer-main">
      <h1>Connectix サービスお申し込み</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス Connectix お申し込み</li>
        <li>サービスお申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:Connectix お申し込み -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix&thinsp;お申し込み</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p>お客様のお部屋番号を入力してください。</p>
          <table class="table-type2">
            <tr>
              <th>マンション名</th>
              <td>{{ builingName }}</td>
            </tr>
            <tr>
              <th class="va-middle">部屋番号（半角英数字）</th>
              <td>
                <input type="text" class="text short" v-model="inputData.roomNumber" @input="v$.inputData.roomNumber.$touch()" placeholder="例）101" />
              </td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込みサービス内容</h3>
          <table class="table-type2">
            <tr>
              <th>サービス名</th>
              <td>Connectix</td>
            </tr>
            <tr>
              <th>料金（月額）</th>
              <td>
                <span class="o">{{ connectixUsageFee }}</span>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て新税率に基づく税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          <p class="att grey">※お申し込み後のキャンセルは行えません。</p>
          <p class="att grey">※お申し込み完了後、数分程度でサービスが適用されます。</p>
          <p class="att grey">※利用料金は、購入翌月分から発生いたします。</p>
          <p class="att grey">※マイページにて解約された日が解約日となります。</p>
          <p class="att grey">※利用開始および解約時の日割り計算は行いません。</p>
          <p class="att grey">
            ※ベストエフォートサービスのため、通信速度を保障するものではございません。<br />本サービスに使用する設備に過大な負荷を生じさせる状況が確認された場合、通信速度及び通信の制限を行う場合があります。
          </p>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <p class="form-btn-txt">「お申し込み」ボタンを押してください。確認ページへ進みます。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" type="button" v-on:click="onApply()" :disabled="disableApply">お申し込み<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}

.underlayer-main h1 {
  padding: 10px 0 10px 160px;
  display: inline;
  background-image: url('../../../images/logo-ucom.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** 使用するバリデーションの定義 */
import { required, maxLength } from '@vuelidate/validators';
import { halfWidth } from '@/shared/util/validators';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomConnectixExternalApiService } from '@/shared/services/external-api/connectix/ucom/ucom-connectix-external-api-service';
import { UcomConnectixPropertyRoomsResponse } from '../ucom/classes/external-api/property-rooms-response';
import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';
import { UcomCustomerResponse } from '../../../shared/classes/external-api/ucom/customer-response';
import { UcomConnectixErrorResponse } from '../ucom/classes/external-api/connectix-error-response';
import { UcomConnectixEntryInputForm } from '../ucom/classes/entry-input-form';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import useVuelidate from '@vuelidate/core';

/** UCOM光レジデンス Connectixお申し込み お申し込み画面 */
export default defineComponent({
  name: 'ucom-connectix-apply',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件名 */
    builingName: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** 画面入力項目 */
    inputData: {
      /** 部屋番号 */
      roomNumber: '',
    },
    /** エラーの発生有無 部屋番号 */
    isInvalidRoomNumber: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** お申し込みボタン活性/非活性判定 */
    disableApply: false,
    /** UCOM 物件 ID */
    ucomPropertyId: '',
    /** UCOM 会員 ID */
    ucomMemberId: '',
    // vueのmounted実行中かどうか
    isMounting: true,
    // onApply()の実行中かどうか
    isOnApplyExecuting: false,
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      /** 部屋番号 */
      roomNumber: {
        required,
        halfWidth,
        maxLength: maxLength(50),
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // エラーメッセージ格納配列初期化
    this.errorMessages = [];

    // UCOM 物件基本情報をStoreから取得
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // Auth0ユーザのapp_metadataからUCOMの物件IDを取得する
      this.ucomPropertyId = await AuthService.getUcomPropertyId();

      const property: Promise<UcomPropertyResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/property', this.ucomPropertyId);
      if (property instanceof UcomPropertyResponse) {
        // 物件名
        this.builingName = property.building_name;
      } else if (property instanceof UcomSharedErrorResponse) {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    }

    /** UCOM 契約基本情報を取得 */
    try {
      // UCOM のメンバー ID をStoreから取得する
      this.ucomMemberId = await AuthService.getUcomMemberId();
      const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.ucomMemberId);
      if (customer instanceof UcomCustomerResponse) {
        // 正常ケースの場合
        if (customer.connectix_status) {
          this.errorMessages.push('お客様は既にConnectixを契約しています。');
          return;
        }
        // Connectix利用金額
        this.connectixUsageFee = customer.connectix_price;
        // 部屋番号はUCOM側のAPIで取得したものが空白以外であればデフォルトで表示
        if (customer.room_number !== '') {
          // 部屋番号
          this.inputData.roomNumber = customer.room_number;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }

    this.isMounting = true;

    // 入力画面から戻って来た場合は、事前に入力されていた情報を再現する
    const inputApply = this.$store.getters['ucomConnectixEntryStore/entryInputForm'];
    if (inputApply != null) {
      // 部屋番号
      this.inputData.roomNumber = inputApply.roomNumber;
    }

    this.isMounting = false;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isOnApplyExecuting = false;
    },
    /** 「お申し込み ➡」ボタン押下時 : Connectixお申し込みチェックAPIを実行し 「お申し込み確認」画面に遷移する */
    async onApply() {
      if (this.isOnApplyExecuting) {
        return;
      }
      this.isOnApplyExecuting = true;
      // エラーの発生有無 部屋番号初期化
      this.isInvalidRoomNumber = false;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      // 入力チェック
      if (this.v$.inputData.$invalid) {
        // 部屋番号
        if (this.v$.inputData.roomNumber?.$invalid) {
          this.isInvalidRoomNumber = true;
          // 必須チェック
          if (this.v$.inputData.roomNumber?.required.$invalid) {
            this.errorMessages.push(`部屋番号を入力してください。`);
          }
          // 形式チェック
          if (this.v$.inputData.roomNumber?.halfWidth.$invalid || this.v$.inputData.roomNumber?.maxLength.$invalid) {
            this.errorMessages.push(`正しい部屋番号を入力してください。`);
          }
        }
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }
      // Connectixお申し込みチェックAPIを実行する
      try {
        const resultFindPropertyRooms: UcomConnectixPropertyRoomsResponse | UcomConnectixErrorResponse = await UcomConnectixExternalApiService.findPropertyRooms(
          this.ucomMemberId
        );
        if (resultFindPropertyRooms instanceof UcomConnectixErrorResponse) {
          // エラーケースの場合
          // API側から返されたエラーメッセージを表示
          this.errorMessages.push(resultFindPropertyRooms.detail);
          this.showErrorMessage();
          return;
        } else if (resultFindPropertyRooms instanceof UcomConnectixPropertyRoomsResponse) {
          // 正常ケースの場合
          const roomNumbers: string[] | undefined = resultFindPropertyRooms.room_numbers;
          if (!roomNumbers || roomNumbers.length === 0) {
            // 物件内にConnectixお申し込みが出来る部屋が無い場合はエラー
            this.errorMessages.push(`該当の物件にはConnectixのお申し込みが出来る部屋がありません。`);
            this.isInvalidRoomNumber = true;
            this.showErrorMessage();
            return;
          }
          // お申し込み可能な部屋の中に自部屋があるかチェック
          const applyRooms: string | undefined = roomNumbers.find((room) => room === this.inputData.roomNumber);
          if (!applyRooms) {
            // 入力した部屋がお申し込み可能な部屋ではなかった場合はエラー
            this.errorMessages.push(`この部屋は現在お手続きが出来ない状態です。`);
            this.isInvalidRoomNumber = true;
            this.showErrorMessage();
            return;
          }
        } else {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.ROOM_INFO);
        }
      } catch (error) {
        throw error;
      }

      // 入力情報をストアに保存
      const inputData = new UcomConnectixEntryInputForm({
        // 部屋番号
        roomNumber: this.inputData.roomNumber,
      });
      this.$store.commit('ucomConnectixEntryStore/entryInputForm', inputData);
      await this.$router
        .push('/connectix/ucom/confirm')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isOnApplyExecuting = false;
        });
    },
  },
});
</script>
