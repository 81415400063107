<template>
  <span class="login-user-info" v-if="isUserInfoShowable">
    {{ idTypeString }} :
    {{ userIdString }}
  </span>
</template>

<style lang="scss" scoped>
.login-user-info {
  margin-right: 10px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { AuthService } from '@/shared/services/auth/auth-service';

/** ヘッダ部のログインユーザー情報表示用 */
export default defineComponent({
  name: 'login-user-info',
  data: () => ({
    isUserInfoShowable: false,
    idTypeString: '',
    userIdString: ''
  }),
  async created() {
    this.isUserInfoShowable = await this.isUserInfoShowableAsync();
    if (!this.isUserInfoShowable) {
      return;
    }
    await this.setUserInfoAsync();
  },
  methods: {
    async isUserInfoShowableAsync(): Promise<boolean> {
      return await AuthService.isLinkedToUcom();
    },
    async setUserInfoAsync(): Promise<void> {
      if ((await AuthService.isUcomAdditionalAccount())) {
        this.idTypeString = 'アカウントID'; // お客様からのご要望により表示タイトルは「アカウント名」ではなく「アカウントID」とする
        this.userIdString = await AuthService.getUcomAccountName();
        return;
      }
      this.idTypeString = 'お客様番号';
      this.userIdString = await AuthService.getUcomMemberId();
      return;
    }
  }
});
</script>
