/** Veritrans クレジットカードトークン取得 API : リクエスト */
export class VeritransCreditCardTokenRequest {
  /** カード番号 */
  public card_number!: string;
  /** カード有効期限 */
  public card_expire!: string;
  /** セキュリティコード */
  public security_code!: string;
  /** カード保有者名(名義人) */
  public cardholder_name!: string;
  /** トークン API キー */
  public token_api_key!: string;
  /** 言語 */
  public lang!: string;

  constructor(partial: Partial<VeritransCreditCardTokenRequest>) {
    Object.assign(this, partial);
  }
}
