import { Module } from 'vuex';
import { RootState } from './index';
import { SpfApiService } from '../shared/services/api/spf-api-service';
import { Property } from '@/shared/classes/spf-api/property';

/** 物件情報 State */
const propertyState: {
  property: Property | null;
} = {
  property: null
};

/** State の型 */
type PropertyState = typeof propertyState;

/** 物件情報 Store */
export const propertyStore: Module<PropertyState, RootState> = {
  namespaced: true,
  state: propertyState,
  mutations: {
    property: (state, value: Property) => (state.property = value)
  },
  getters: {
    property: (state): Property | null => state.property
  },
  actions: {
    async property(context, propertyId: number) {
      // API コールする
      const propertyResult = await SpfApiService.findPropertyById(propertyId); // ここで例外がスローされうるので、この場か、dispatch() の呼び出し元でエラーハンドリングする
      // 値をコミットする
      context.commit('property', propertyResult);
      // Getter から取り直して返す
      const afterProperty = context.getters['property'];
      return afterProperty;
    }
  }
};
