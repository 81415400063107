<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メインメールアドレス</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion/main-mail-address/list">メインメールアドレス</router-link>
        </li>
        <li>解約完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:アカウントID登録・変更-->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID登録・変更" />ご解約受付完了</h2>
        <p class="red">ご解約を受け付けました。</p>
        <p>お手続き内容をメールにてお送りいたしました。</p>
      </div>
      <table class="table-type1">
        <tr>
          <th class="va-middle">
            <p>
              ご解約対象<br />
              メールアドレス
            </p>
          </th>
          <td>{{ mailAddress }}</td>
        </tr>
        <tr>
          <th class="va-middle">
            <p>サービス終了日</p>
          </th>
          <td>{{ cancelOn }}</td>
        </tr>
      </table>

      <div class="sblc">
        <div class="sblc">
          <ul class="ul-basic01">
            <li>メールアドレスは下記タイミングでご利用できなくなります。</li>
            <ul class="ul-basic01">
              <li>1日～20日までに解約登録された場合:当月末日で解約</li>
              <li>21日～月末までに解約登録された場合:翌月末日で解約</li>
            </ul>
            <li>
              <font class="red"> 解約したメールアドレスは元に戻すことができません。</font>
            </li>
            <li>メールアドレスに付随しているサービスは同時にサービスが終了します。</li>
            <li>
              <font class="red">【重要！】メールアドレス終了までに必ずメール受信を行ってください。</font>解約前に届いていて受信しなかったメールは、解約後に確認できなくなります。
            </li>
          </ul>
        </div>
      </div>

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'cancel-complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    mailAddress: '',
    cancelOn: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //Storeからメールアドレスを取得
      this.mailAddress = this.$store.getters['eMansionMainMailAddressStore/mailAddress'];
      if (!this.mailAddress) {
        const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
        this.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddressOriginal
          ? accountInfo.eMansionMailAddressInfo?.mailAddressOriginal
          : accountInfo.eMansionMailAddressInfo?.mailAddress ?? '';
      }
      //Storeからサービス終了日を取得
      this.cancelOn = new Date(this.$store.getters['eMansionMainMailAddressStore/cancelOn']).toLocaleDateString();
      if (!this.cancelOn) {
        const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
        const cancelOn = accountInfo.eMansionMailAddressInfo?.cancelOn;

        //日付の形式に変換
        if (cancelOn) {
          const createdCancelOn = new Date(cancelOn);
          this.cancelOn = createdCancelOn.toLocaleDateString();
        }
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
