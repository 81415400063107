<template>
  <div class="registration-error">
    <LoadingComponent v-if="isExecutingGoPlatform" />
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み エラー</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>エラー</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="入力内容の確認" />エラー発生</h2>
      </div>
      <section class="my-error-section">
        <h3 class="my-error-section__title">大変申し訳ございません、システムエラーが発生いたしました。</h3>
        <div class="my-error-section__description">
          <p>
            Portasにご登録いただきましたメールアドレス宛に<span class="my-font-weight-700">【UCOM光 レジデンス】インターネット接続サービス登録証のお知らせ</span> もしくは<span
              class="my-font-weight-700"
              >【UCOM光 レジデンス】お客様情報登録 受付完了のお知らせ</span
            >
            のいずれかが届いていない場合、お手数ではございますが再度、UCOM光 レジデンス会員登録よりご登録をお願いいたします。
          </p>
          <p class="my-supplement">
            エラーの一因として、クレジットカード情報の入力誤りなどが多く発生しております。再度、ご登録の際にご確認をお願いいたします。繰り返しエラーが発生する場合、<a
              :href="getUrl()"
              class="link"
              target="UCOM"
              >こちら</a
            >からサポートセンターへお問い合わせください。
          </p>
        </div>
      </section>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="goPlatform()">Portas トップへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.my-error-section {
  margin: 50px auto 0;
  &__title {
    text-align: center;
  }
  &__description {
    margin-top: 40px;
    line-height: 1.6;
    & p.my-supplement {
      margin-top: 16px;
    }
  }
}

.my-font-weight-700 {
  font-weight: 700;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ProcessesCalledInUcomPages } from '@/shared/util/processes-called-in-ucom-page';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'ucom-entry-registration-error',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isExecutingGoPlatform: false,
  }),
  methods: {
    getUrl(): string {
      const property = this.$store.getters['propertyStore/property'];
      return ProcessesCalledInUcomPages.getBuildingWebsiteUrl(undefined, property);
    },
    async goPlatform(): Promise<void> {
      if (this.isExecutingGoPlatform) {
        return;
      }

      this.isExecutingGoPlatform = true;
      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          checkRouterError(e);
        })
        .finally(() => {
          this.isExecutingGoPlatform = false;
        });
    },
  },
});
</script>
