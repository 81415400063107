import { Module } from 'vuex';
import { RootState } from '../index';
import { GameWithEditInputForm } from '@/shared/classes/gamewith/edit-input-form';

/** contactsState  State */
const gamewithMemberEditState: {
  gamewithEditInputForm: GameWithEditInputForm | null; // お問い合わせ入力情報
} = {
  gamewithEditInputForm: null,
};

/** State の型 */
type GamewithMemberEditState = typeof gamewithMemberEditState;

/** Platform 新規会員登録 Store */
export const gamewithMemberEditStore: Module<GamewithMemberEditState, RootState> = {
  namespaced: true,
  state: gamewithMemberEditState,
  mutations: {
    gamewithEditInputForm: (state, value: GameWithEditInputForm) => (state.gamewithEditInputForm = value),
  },
  getters: {
    gamewithEditInputForm: (state): GameWithEditInputForm | null => state.gamewithEditInputForm,
  },
};
