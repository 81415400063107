<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>追加アカウント</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>追加アカウント</li>
      </ul>

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご利用状況</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:追加アカウント　確認(お申し込み) -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="追加アカウント" />お申し込み受付完了</h2>
        <!-- 
        <p>お申し込み内容をご確認ください。</p>
        <div class="err">
          <p class="red">恐れ入りますが、入力内容をもう一度ご確認ください。</p>
          <ul class="ul-basic01">
            <li>「ご希望アカウント」を入力してください。</li>
            <li>「ご希望アカウント」は、3文字以上12文字以内で入力してください。</li>
            <li>「ご希望アカウント」は、半角英数小文字と「-（ハイフン）」「_（アンダーバー）」の組み合わせで指定してください。</li>
            <li>「ご希望アカウント」の先頭1文字は英字（a～z）で指定してください。</li>
            <li> -（ハイフン）や_（アンダーバー）で終わるURLは指定できません。</li>
            <li>この「ご希望アカウント」は指定できません。</li>
          </ul>
        </div>
-->
        <div class="sblc">
          <div class="sblc">
            <p class="red"><b>追加アカウントのお申し込みを受付けました。書類はお送りしておりません。このページは印刷して保管していただくことをお勧めします。</b></p>
            お手続き内容をメールにてお送りいたしました。

            <!-- <table class="table-type1">
              <tbody>
                <tr>
                  <th><b>追加アカウント</b></th>
                  <td class="red"><b>ne5074mail2-ec</b></td>
                </tr>
                <tr>
                  <th><b>パスワード</b></th>
                  <td class="red"><b>ekysxdtm</b></td>
                </tr>
              </tbody>
            </table> -->

            <br />

            <table class="table-type1">
              <tbody>
                <tr>
                  <th><b>追加メールアドレス</b></th>
                  <td>
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
                <tr>
                  <th><b>メールアカウント</b></th>
                  <td>
                    <b>{{ desiredAccount + '-' + subdomain }}</b>
                  </td>
                </tr>
                <tr>
                  <th><b>メール初期パスワード</b></th>
                  <td>
                    <b>{{ mailPassword }}</b>
                  </td>
                </tr>
                <tr>
                  <th><b>SMTPサーバー</b></th>
                  <td>{{ smtpServer }}</td>
                </tr>
                <tr>
                  <th><b>POPサーバー</b></th>
                  <td>{{ popServer }}</td>
                </tr>
                <tr>
                  <th><b>IMAPサーバー</b></th>
                  <td>{{ imapServer }}</td>
                </tr>
              </tbody>
            </table>
            <p>※このパスワードは初期値です。追加アカウントでPortasにログイン後、パスワードを変更してご利用ください。</p>

            <div class="blc">
              <h3 class="service-h3">追加メールアドレスのご利用開始について</h3>
              <p>
                追加メールアドレスのご利用前に、追加アカウントでPortasへのご登録が必要です。<br />
                「ご利用状況確認」をクリックし、Portas連携用アカウントとPortas連携用パスワードをご確認ください。
              </p>
              <ul class="ul-basic01">
                <li>
                  ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」前日までに追加アカウントでPortasにご登録いただくと、鍵のお引渡し日からご利用いただけます。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- /blc -->

      <div class="blc">
        <p class="form-btn-txt"><b>続けて追加アカウントのお申し込み・ご解約をされる場合は「ご利用状況確認」をクリックしてください。</b></p>
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goList()">ご利用状況確認<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs mt-sp-1" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'complete',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    desiredAccount: '',
    mailAddress: '',
    mailPassword: '',
    smtpServer: '',
    popServer: '',
    imapServer: '',
    subdomain: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //前画面の情報を受け取る
    try {
      this.$data.desiredAccount = this.$store.getters['eMansionSubAccountStore/desiredAccount'];
      this.$data.mailAddress = this.$store.getters['eMansionSubAccountStore/mailAddress'];
      this.$data.mailPassword = this.$store.getters['eMansionSubAccountStore/mailPassword'];
      this.$data.smtpServer = this.$store.getters['eMansionSubAccountStore/smtpServer'];
      this.$data.popServer = this.$store.getters['eMansionSubAccountStore/popServer'];
      this.$data.imapServer = this.$store.getters['eMansionSubAccountStore/imapServer'];
      this.$data.subdomain = this.$store.getters['eMansionSubAccountStore/mailSubdomain'];
      //リロードで値がなくなったら一覧画面に飛ばす？
      if (!this.$data.mailAddress) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.$data.mailPassword) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.$data.smtpServer) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      if (!this.$data.popServer) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
    },
    async goList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('eMansionSubAccountStore/desiredAccount', undefined);
      await this.$router.push('/e-mansion/sub-account/list').catch((error) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
