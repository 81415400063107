import { FrontErrorRequest } from "@/shared/classes/spf-api/front-error-request";
import { FRONT_ERROR_TYPE_GLOBAL_CAPTURE } from "@/shared/const/error/error-handle";
import { SpfApiService } from "@/shared/services/api/spf-api-service";
import { AuthService } from "@/shared/services/auth/auth-service";
import { AxiosErrorFormatService } from '@/shared/services/front-error/axios-error-format-service';
import store from '@/store';
import router from '@/router';
import { datadogLogs } from '@datadog/browser-logs';

export class ReportUnhandledrejectionErrorHandler {

  public static async reportErrorToUnhandledrejectionErrorHandler(error: Error, info?: string): Promise<void> {
    const route = router.currentRoute;

    const errorScreenName = route.value.meta.title as string;
    const errorScreenPath = route.value.path;

    const request = await this.createUnhandledrejectionFrontErrorRequest(error, errorScreenName, errorScreenPath, info);

    try {
      datadogLogs.logger.error('@UnhandledredrejectionErrorHandler.handle', request);
      await SpfApiService.postReportFrontError(request);
    } catch (error) {
      // 握りつぶす
    }
  }

  private static async createUnhandledrejectionFrontErrorRequest(error: Error, errorScreenName: string, errorScreenPath: string, info?: string): Promise<FrontErrorRequest> {
    const primaryKeyAuth0 = (await AuthService.isLoggedIn())
      ? await AuthService.getSub().catch(() => {
          // エラー: subがありません。 を握り潰す ARN_QA-825 の対応
          return '';
        })
      : undefined;

    const errorType = FRONT_ERROR_TYPE_GLOBAL_CAPTURE.UNHANDLEDREJECTION;
    const formattedAxiosError = AxiosErrorFormatService.format(error);

    const request = new FrontErrorRequest({
      primaryKeyAuth0: primaryKeyAuth0,
      errorObject: formattedAxiosError ? formattedAxiosError : JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))), // エラーオブジェクト出力のための措置
      errorInfo: info,
      errorScreenName: errorScreenName,
      errorScreenPath: errorScreenPath,
      errorType: errorType,
      screenHistory: store.getters['userScreenTransitionStore/history'],
      userAgent: navigator.userAgent,
    });

    return request;
  }

}