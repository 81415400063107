import { Module } from 'vuex';
import { SelectedBusinessDayInputForm } from '@/pages/platform/select-construction-date/classes/select-construction-form';
import { RootState } from '.';

/** お申し込み State */
const selectedBusinessDayState = {
  /** お申し込み 入力フォーム */
  entryInputForm: undefined as SelectedBusinessDayInputForm | undefined,
  selectedBusinessDayForFirst: null as string | null,
  selectedBusinessDayForSecond: null as string | null,
  selectedBusinessDayForThird: null as string | null,
  selectedTimeOfDayForFirst: null as string | null,
  selectedTimeOfDayForSecond: null as string | null,
  selectedTimeOfDayForThird: null as string | null,
  selectedPhone: null as number | null,
  phoneNumber: null as string | null,
};

/** State の型 */
type SelectedBusinessDayState = typeof selectedBusinessDayState;

/** お申し込み Store */
export const selectedBusinessDayStateStore: Module<SelectedBusinessDayState, RootState> = {
  namespaced: true,
  state: selectedBusinessDayState,
  mutations: {
    setEntryInputForm: (state, value: SelectedBusinessDayInputForm | undefined) => {
      state.entryInputForm = value;
    },
    selectedBusinessDayForFirst: (state, value: string | null) => {
      state.selectedBusinessDayForFirst = value;
    },
    selectedBusinessDayForSecond: (state, value: string | null) => {
      state.selectedBusinessDayForSecond = value;
    },
    selectedBusinessDayForThird: (state, value: string | null) => {
      state.selectedBusinessDayForThird = value;
    },
    selectedTimeOfDayForFirst: (state, value: string | null) => {
      state.selectedTimeOfDayForFirst = value;
    },
    selectedTimeOfDayForSecond: (state, value: string | null) => {
      state.selectedTimeOfDayForSecond = value;
    },
    selectedTimeOfDayForThird: (state, value: string | null) => {
      state.selectedTimeOfDayForThird = value;
    },
    selectedPhone: (state, value: number | null) => {
      state.selectedPhone = value;
    },
    phoneNumber: (state, value: string | null) => {
      state.phoneNumber = value;
    },
  },
  getters: {
    entryInputForm: (state): SelectedBusinessDayInputForm | undefined => state.entryInputForm,
    selectedBusinessDayForFirst: (state): string | null => state.selectedBusinessDayForFirst,
    selectedBusinessDayForSecond: (state): string | null => state.selectedBusinessDayForSecond,
    selectedBusinessDayForThird: (state): string | null => state.selectedBusinessDayForThird,
    selectedTimeOfDayForFirst: (state): string | null => state.selectedTimeOfDayForFirst,
    selectedTimeOfDayForSecond: (state): string | null => state.selectedTimeOfDayForSecond,
    selectedTimeOfDayForThird: (state): string | null => state.selectedTimeOfDayForThird,
    selectedPhone: (state): number | null => state.selectedPhone,
    phoneNumber: (state): string | null => state.phoneNumber,
  },
};
