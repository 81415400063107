<template>
  <div class="sblc">
    <h3 class="service-h3">お住まいの建物を検索してください</h3>
    <p>
      アルテリア・ネットワークス、つなぐネットコミュニケーションズ提供のインターネットサービスが導入されている建物名を表示します。<br />
      それ以外の建物にお住まいの方は、「登録しない　または　あとで登録する」をお選びください。<br />
      ※お住まいの建物登録は、インターネットサービスの会員登録ではございません。ご希望の場合は、Portas会員登録完了後、会員専用トップページからお手続きにお進みください。
    </p>
    <form class="course-select grid pc-grid2 sp-grid1 gap10">
      <input ref="radioButtonRegisterNow" id="radio1" class="radiobutton" name="お住まいの建物を検索してください" type="radio" value="register-now" @change="onChange" />
      <label for="radio1" class="br5 shadow">
        <p class="font18px"><b>お住まいの建物を登録する</b></p>
      </label>
      <input ref="radioButtonIsAfterRegister" id="radio2" class="radiobutton" name="お住まいの建物を検索してください" type="radio" value="is-after-register" @change="onChange" />
      <label for="radio2" class="br5 shadow">
        <p class="font14px"><b>登録しない　または　あとで登録する</b></p>
      </label>
    </form>
  </div>
</template>

<style lang="scss">
.course-select {
  & label.br5.shadow {
    &::before {
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.text-bold {
  font-weight: bold;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { InputPropertyForm } from '@/store/platform/platform-edit-store';

export default defineComponent({
  name: 'residence-registration-button',
  methods: {
    onChange(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        this.handleRadioButtonValue(event.target.value);
      }
    },
    /**
     * あとで登録する のボタンにチェックをつける
     */
    checkButtonOnIsAfterRegister() {
      this.$nextTick(() => {
        const radioButtonIsAfterRegister = this.$refs.radioButtonIsAfterRegister as HTMLInputElement;

        if (radioButtonIsAfterRegister) {
          radioButtonIsAfterRegister.checked = true; // ラジオボタンにチェックを付ける
          this.handleRadioButtonValue('is-after-register');
        }
      });
    },
    /**
     * お住まいの建物を登録する のボタンにチェックをつける
     */
    checkButtonOnRegisterNow() {
      this.$nextTick(() => {
        const radioButtonRegisterNow = this.$refs.radioButtonRegisterNow as HTMLInputElement;

        if (radioButtonRegisterNow) {
          radioButtonRegisterNow.checked = true; // ラジオボタンにチェックを付ける
          this.handleRadioButtonValue('register-now');
        }
      });
    },
  },
  props: {
    handleRadioButtonValue: {
      type: Function,
      required: true,
    },
  },
  /**
   * ・ /platform/entry/input にて初期表示するとき、 お住まいの建物を登録するにチェックを付ける
   * ・ /platform/my-page/member-confirm にて初期表示するとき、 該当の建物にお住まいでない方にチェックを付ける
   * ・ /platform/entry/confirm から戻ってきたとき、ストアの 'isAfterRegister' の値を反映する
   * ・ /platform/my-page/member-confirm から戻ってきたとき、ストアの 'isAfterRegister' の値を反映する
   */
  mounted() {
    const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];

    // 初期表示のとき
    if (inputPropertyForm === null) {
      if (this.$route.path === '/platform/entry/input') {
        this.checkButtonOnRegisterNow();
      } else if (this.$route.path === '/platform/my-page/member-edit') {
        this.checkButtonOnIsAfterRegister();
      }

      // ストア の isAfterRegister に値が存在するとき
    } else if (typeof inputPropertyForm.isAfterRegister === 'boolean') {
      if (inputPropertyForm.isAfterRegister) {
        this.checkButtonOnIsAfterRegister();
      } else {
        this.checkButtonOnRegisterNow();
      }
    }
  },
});
</script>
