<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ容量</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ容量</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li class="stay">ご利用状況</li>
        <li>変更</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページ容量ご利用状況" />ご利用状況</h2>
      <p>ホームページ容量の変更お手続きを行います。</p>
      <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

      <div class="sblc">
        <div class="sblc">
          <h3 class="service-h3">ホームページ容量のご利用状況／変更</h3>
          <p>ホームページ容量を変更したい FTPアカウントの「変更」ボタンをクリックしてください。</p>
          <table class="table-type1 hp-cap-table">
            <tbody>
              <tr>
                <th><b>FTPアカウント</b></th>
                <th><b>現在のホームページ容量</b></th>
                <th><b>変更</b></th>
              </tr>
              <tr>
                <td>{{ ftpAccount }}</td>
                <td>
                  <b>{{ eMansionHomepageCapacity }}</b>
                </td>
                <td>
                  <div v-if="isChangeBtn" class="hp-cap-change-btn">
                    <button class="btn btn01 bs" v-on:click="onChange()">変更<i class="material-icons link link-icon">east</i></button>
                  </div>
                  <div v-else class="hp-cap-change-btn">
                    <button class="btn btn05 bs" disabled>変更<i class="material-icons link link-icon">east</i></button>
                    <div v-if="isSameMonthChange">
                      <p class="red">同月内の再変更はできません。</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="blc">
        <ul class="ul-basic01">
          <li>容量変更お申し込み後、同月内は再変更できませんので予めご了承ください。</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /contents -->
  <!-- /main-contents -->
</template>

<style>
@media only screen and (max-width: 767px) {
  .hp-cap-table {
    border-bottom: 1px solid #d3d3d3;
  }
  .hp-cap-table tbody {
    display: flex;
    flex-wrap: wrap;
  }
  .hp-cap-table tr {
    width: 50%;
  }
  .hp-cap-table tbody > :last-child {
    margin: 25px 0 0 -25%;
  }
  .hp-cap-table td,
  .hp-cap-table th {
    padding: 15px;
    overflow: visible;
    white-space: nowrap;
    background-color: transparent;
  }
  .hp-cap-change-btn {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { EMansionHomepageCapacityList } from '@/shared/classes/spf-api/homepage/model/e-mansion-homepage-capacity-list';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'homepageCapacityList',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    eMansionAccountId: -1, // e-mansion アカウントID
    eMansionHomepageId: -1, // e-mansion ホームページID
    fee: '', // 月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    hpQuota: '', //基本容量(MB)
    errorMessages: new Array<string>(), // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
    isCampaign: false,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.isLoading = true;

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // 月額利用料
      this.fee = property.op.hp_quota.fee_cp ? property.op.hp_quota.fee_cp : property.op.hp_quota.fee;
      // 事務手数料
      this.jimFee = property.op.hp_quota.jimu_fee_cp ? property.op.hp_quota.jimu_fee_cp : property.op.hp_quota.jimu_fee;
      // キャンペーン価格かどうか
      this.isCampaign = property.op.hp_quota.fee_cp ? true : false;
      // 最大容量
      this.maxQuota = property.op.hp_quota.max_quota ?? '';
      //基本容量
      this.hpQuota = property.op.hp.hp_quota ?? '';
      // e-mansion_アカウントIDを取得(共通情報より取得)
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      if (!accountInfo) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansion_アカウントID
      this.eMansionAccountId = accountInfo.eMansionAccountInfo.eMansionAccountId;

      // 現在日付の取得
      const currentDateObject = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // ホームページ情報をAPIより取得する
      const response = await SpfApiHomepageCapacityAccessor.getEMansionHomepageCapacityList(this.eMansionAccountId, currentDateObject);
      // API処理結果にエラーメッセージがある場合
      if (response.errorMessages.length != 0) {
        for (const errMsg of response.errorMessages) {
          this.errorMessages.push(errMsg);
        }
      }

      // 画面項目セット
      this.ftpAccount = response.ftpAccount;
      this.eMansionHomepageCapacity = response.homepageCapacity;

      // ホームページID(Hidden)
      this.eMansionHomepageId = response.eMansionHomepageId;

      // ボタンを活性化するかどうかを判断
      this.isChangeBtn = this.isViewChangeBtn(response);

      // 同月内変更チェック
      this.isSameMonthChange = response.isSameMonthChange;

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    // 変更ボタンを活性化するかの判断を行う
    isViewChangeBtn(response: EMansionHomepageCapacityList): boolean {
      // 同月内変更なし、且つ、容量変更可否：可
      if (!response.isSameMonthChange && response.canChangeCapacity) {
        return true;
      } else {
        return false;
      }
    },
    // 変更ボタンクリック
    async onChange() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.eMansionAccountId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.jimFee);
      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.maxQuota);
      this.$store.commit('eMansionHomepageCapacityStore/hpQuota', this.hpQuota);
      this.$store.commit('eMansionHomepageCapacityStore/isCampaign', this.isCampaign);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage-capacity/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
