<template>
  <div v-html="html">
  </div>
</template>

<style lang="scss" scoped>
</style>
<script lang="ts">
import { checkRouterError } from '@/shared/util/router-navigation-func';
import axios from 'axios';
import { defineComponent } from 'vue';
import { ContactsDto } from './platform/contacts/classes/contacts-dto';

/** メンテナンス画面 */
export default defineComponent({
  name: 'maintenance',
  data: () => ({
    html: '',
  }),
  async mounted() {
    this.$data.html = this.$store.getters['maintenanceStore/html'];
    if(!this.$data.html){
      // エラー確認
      const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });
      try {
        await rawApi.post<string>('/contacts/not-login', new ContactsDto({}));// 絶対エラーが起こる
      } catch(error: any) {
        if(error.response?.status === 503 && error.response.data){
          this.$data.html = error.response.data;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return await this.$router.push('/platform').catch((error: any) => {
            checkRouterError(error);
          });
        }
      }
    }
  }
});
</script>