<template>
  <div>
    <article>
      <section v-if="serviceType === 'url-platform-no-property'"></section>

      <section v-if="serviceType === 'top'">
        <h2 class="my-title-at-top">よくあるご質問</h2>
        <!-- <dl class="qa">
          <dt>
            <p>Portas（ポルタス）とは？</p>
          </dt>
          <dd>
            <p>
              Portas（ポルタス）は、株式会社つなぐネットコミュニケーションズのグループ会社、アルテリア・ネットワークス株式会社が運営する総合プラットフォームです。<br />
              ご登録いただくと、LINE、Google、Yahoo!、Apple等のソーシャルアカウント、ご利用中の携帯電話番号（SMS）で、お客様専用のマイページにログインでき、お客様番号・パスワード管理が不要になる便利で安心なサービスです。
            </p>
          </dd>
        </dl> -->

        <dl class="qa">
          <dt>
            <p>Portasに会員登録をするには何が必要ですか？</p>
          </dt>
          <dd class="dd-margin-top">
            <p>Portasに会員登録するには、LINE、Google、Yahoo!、Apple等のソーシャルアカウントや、ご利用中の携帯電話番号（SMS）が必要になります。</p>
          </dd>
        </dl>

        <dl class="qa">
          <dt>
            <p>Portasに会員登録をすると、何ができるようになりますか？</p>
          </dt>
          <dd class="dd-margin-top">
            <p>
              Portasに会員登録いただくと、株式会社つなぐネットコミュニケーションズが提供するサービス（UCOM光
              レジデンス、e-mansion、Five.A、Mcloud、つなぐネットオンラインショップ）と連携ができ、Portasと各サービスのお客様専用のマイページが、ご登録いただいたソーシャルアカウント、SMSでご利用いただけます。
            </p>
            <p class="topqa">また、Portasに会員登録いただくことで利用できるクーポンのご提供等がございます。</p>
            <p class="topqa">あわせて、Portasサイト内でご案内している各種オプションサービスをお申し込み・ご利用もいただけます。<br /></p>
            <p>複数のオプションサービスをご利用になる場合、セット料金が適用されお安くご利用いただけますのでご検討ください。<br /></p>
            <p class="topqa">今後もPortasで新たなサービスをご提供する予定ですのでご期待ください。</p>
          </dd>
        </dl>

        <dl class="qa">
          <dt>
            <p>Portasの会員登録は無料と聞いていましたが、登録の際、クレジットカード情報を入力する画面が表示されました。何か料金はかかりますか？</p>
          </dt>
          <dd class="dd-margin-top">
            <p>Portasの会員登録は無料です（入会金・年会費は無料）。<br /></p>
            <p>Portasサービスをお申し込みの場合は、Portas会員登録画面でクレジットカード情報のご登録をお願いします。<br /></p>
            <p>Portasサービスをお申し込みされない場合は、Portas新規会員登録完了後、「今回はスキップ」をお選びください。<br /></p>
            <p class="topqa">また、各サービス(※)とPortasの連携は無料です。（連携先の各サービスで料金が発生する場合はございます）<br /></p>
            <p>※UCOM光 レジデンス、e-mansion、Five.A、Mcloud、つなぐネットオンラインショップ<br /></p>
          </dd>
        </dl>

        <dl class="qa">
          <dt>
            <p>Portasのお問い合わせフォームから、インターネットサービス（UCOM光 レジデンス、e-mansion、Five.A）に関するお問い合わせはできますか？</p>
          </dt>
          <dd class="dd-margin-top">
            <p>
              インターネットサービスに関するお問い合わせは、各サービスブランド毎に専用窓口でのご案内となります。<br />
              下記に改めてお問い合わせをいただきますよう、お願い申し上げます。<br />
            </p>
            <div class="c-link-list-container">
              <div class="c-link-list-outer">
                <ul class="c-link-list">
                  <li class="c-link-list__item"><a :href="`${eMansionNetUrl}`" target="searchproperty-emansion">e-mansion 物件検索サイト</a></li>
                </ul>
              </div>
              <div class="c-link-list-outer">
                <ul class="c-link-list">
                  <li class="c-link-list__item"><a :href="`${ucomSearchproPertyUrl}`" target="searchproperty-ucom">UCOM光 建物検索サイト</a></li>
                </ul>
              </div>
              <div class="c-link-list-outer">
                <ul class="c-link-list">
                  <li class="c-link-list__item"><a :href="`${fiveaSearchproPertyUrl}`" target="searchproperty-fiveA">Five.A 物件検索サイト</a></li>
                </ul>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="qa">
          <dt>
            <p>Portasサービスとはなんですか？</p>
          </dt>
          <dd class="dd-margin-top">
            <p>Portas会員向けに提供している月額定額制サービスで、初回お申し込み時は無料期間が適用されお試しいただけます。</p>
            <p>複数購入でセット料金が適用されます。</p>
          </dd>
        </dl>

        <!-- <dl class="qa">
          <dt>
            <p>「Connectix」のお申し込み方法について</p>
          </dt>
          <dd>
            <p>
              Connectixを提供している建物にお住まいのお客様のみご利用いただけるサービスです。<br />
              下記ご利用のサービスを選択し、建物検索ページから、建物名を入力しご確認ください。
            </p>
            <div class="c-link-list-container"> -->
        <!-- PC用ISP表示 -->
        <!-- <div class="isp-pc">
                <div class="c-link-list-outer">
                  <ul class="c-link-list">
                    <li class="c-link-list__item"><a :href="`${ucomUrl}/search.html`" target="UCOM">UCOM光 レジデンス</a></li>
                    <li class="c-link-list__item" v-for="oem1 in oemMaster1" :key="oem1.viewOrder">
                      <a :href="oem1.domainForTop" target="Five.A">{{ oem1.label }}</a>
                    </li>
                  </ul>
                </div>
                <div class="c-link-list-outer">
                  <ul class="c-link-list">
                    <li class="c-link-list__item"><a :href="`${eMansionUrl}/portal/pte05/`" target="MY e-mansion">e-mansion</a></li>
                    <li class="c-link-list__item" v-for="oem2 in oemMaster2" :key="oem2.viewOrder">
                      <a :href="oem2.domainForTop" target="Five.A">{{ oem2.label }}</a>
                    </li>
                  </ul>
                </div>
                <div class="c-link-list-outer">
                  <ul class="c-link-list">
                    <li style="display: hidden; letter-spacing: 0.8px; line-height: 1.6; margin-bottom: 5px">&nbsp;</li>
                    <li class="c-link-list__item" v-for="oem3 in oemMaster3" :key="oem3.viewOrder">
                      <a :href="oem3.domainForTop" target="Five.A">{{ oem3.label }}</a>
                    </li>
                  </ul>
                </div>
              </div> -->
        <!-- スマホ用ISP表示 -->
        <!-- <div class="isp-sp">
                <div class="c-link-list-outer">
                  <ul class="c-link-list">
                    <li class="c-link-list__item"><a :href="`${ucomUrl}/search.html`" target="UCOM">UCOM光 レジデンス</a></li>
                    <li class="c-link-list__item"><a :href="`${eMansionUrl}/portal/pte05/`" target="MY e-mansion">e-mansion</a></li>
                    <li class="c-link-list__item" v-for="oemMaster in oemsMaster" :key="oemMaster.viewOrder">
                      <a :href="oemMaster.domainForTop" target="Five.A">{{ oemMaster.label }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </dd>
        </dl> -->

        <dl class="qa">
          <dt>
            <p>Portasの会員登録の際に、間違ってPortasサービス（月額定額制サービス）に、申し込みしてしまったので、解約の方法を知りたいです。</p>
          </dt>
          <dd class="dd-margin-top">
            <p>
              Portasサービス（「Wi-Fi ＆Device Supoort」、「Internet SagiWall for マルチデバイス」、「ノートン&trade;
              セキュリティ」、「AdGuard」）につきましては、Portasマイページより解約が可能でございます。<br />
            </p>
            <p>&lt;Portas トップページ&gt; <br /></p>
            <div style="margin-top: 0px">
              <div class="c-link-list-topqa">
                <div class="c-link-list-outer">
                  <div style="margin-bottom: 0">
                    <ul class="c-link-list">
                      <li class="c-link-list__item"><a :href="`${portasTop}`">https://portas.jp/</a><br /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p class="qa-border"><br /></p>
            <p>【Portasサブスクリプションサービス解約手順】<br /></p>
            <p>(1)上記URLにアクセスし、「ログイン」をクリック<br /></p>
            <p>(2)画面右上の「Portas マイページ」をクリック<br /></p>
            <p>(3)「サービスご契約情報」の「解約商品を選択する」をクリック<br /></p>
            <p>(4)ご解約いただくサービスをクリックし解約手続きをお願いいたします<br /></p>
            <p class="qa-border"><br /></p>
            <p class="topqa">Portasサービス（月額定額制サービス）に再度お申し込みの際は、初回無料特典の適用は対象外となります。<br /></p>
          </dd>
        </dl>

        <dl class="qa">
          <dt>
            <p>インターネットサービスの会員登録手順が知りたいです。</p>
          </dt>
          <dd class="dd-margin-top">
            <p>インターネットサービスの会員登録手順は下記をご確認ください。<br /></p>
            <div class="c-link-list-topqa">
              <div class="c-link-list-outer">
                <ul class="c-link-list">
                  <li class="c-link-list__item"><a href="/register-ucom" target="MY ucom">UCOM光 レジデンスの方はこちら</a></li>
                </ul>
              </div>
              <div class="c-link-list-outer">
                <ul class="c-link-list">
                  <li class="c-link-list__item"><a :href="`${eMansionRegistGuideUrl}`" target="MY e-mansion">e-mansionの方はこちら</a></li>
                </ul>
              </div>
            </div>
          </dd>
        </dl>
        <!-- <dl class="qa">
          <dt>
            <p>「つなぐネットオンラインショップ」について</p>
          </dt>
          <dd>
            <p>
              「つなぐネットオンラインショップ」の商品や購入方法についてはこちらからお問い合わせください。<br />
              <a :href="`${tnosBaseUrl}/apply.html?id=APPLY1`" target="tsunagu">＞つなぐネットオンラインショップお問い合わせ</a>
            </p>
          </dd>
        </dl> -->

        <div class="top-link-to-another-faq">
          <a :href="faqUrl" target="_blank">
            <p>Portas新規会員登録後、ログイン後の「よくあるご質問」については、こちらからご確認ください<i class="material-icons link link-icon">east</i></p>
          </a>
        </div>
      </section>
      <section v-else-if="serviceType === uaType.E_MANSION">
        <div v-if="isDisplayConnectixFAQ">
          <h2>よくあるご質問</h2>
          <dl class="qa" v-if="isDisplayConnectixFAQ">
            <dt>
              <p>「Connectix」のお申し込み方法について</p>
            </dt>
            <dd class="dd-margin-top">
              <p v-if="isEmptyBirthdate()">
                Connectixのご利用には、e-mansion会員登録が必要です。<br />
                e-mansionの会員登録がお済みの場合は、<router-link to="/e-mansion/linking" class="link no-icon">こちらから</router-link
                >Portasとのアカウント連携を行ってください。<br />
                お済みでない場合、Portasマイページから生年月日をご登録いただいた後、e-mansion会員登録のお手続きをお願いいたします。<br />
                <router-link to="/platform/my-page" class="link no-icon">Portasマイページはこちら</router-link>
              </p>
              <p v-else-if="isUnder18()">
                Connectixのご利用には、e-mansion会員登録が必要です。<br />
                e-mansionの会員登録がお済みの場合は、<router-link to="/e-mansion/linking" class="link no-icon">こちらから</router-link
                >Portasとのアカウント連携を行ってください。<br />
                お済みでない場合、18歳未満の方のe-mansionへのご入会には別途「親権者同意書」のご提出が必要となります。<br />
                誠に恐れ入りますが、<a class="link no-icon" :href="eMansionForMansionUrl" target="My e-mansion">お住まいのマンション専用ホームページ（こちら）</a
                >よりe-mansionサポートセンターへご連絡いただき、必要書類をお取り寄せください。
              </p>
              <p v-else>
                Connectixのご利用には、e-mansion 会員登録が必要です。お済みでない場合は<a class="link no-icon" :href="eMansionMemberRegistrationUrl" target="My e-mansion"
                  >こちらから</a
                >ご登録ください。<br />
                e-mansion会員登録がお済みの場合は、<router-link to="/e-mansion/linking" class="link no-icon">こちらから</router-link>Portasとのアカウント連携を行ってください。
              </p>
            </dd>
          </dl>
        </div>
      </section>
      <!--  NPF_RESEARCH-1892 対応で、以下内容を非表示
      <section v-else-if="serviceType === uaType.FIVE_A">
        <div v-if = "isDisplayConnectixFAQ">
          <h2>よくあるご質問</h2>
        </div>
      </section>
         -->

      <section v-else-if="serviceType === uaType.UCOM">
        <div v-if="isDisplayConnectixFAQ">
          <h2>よくあるご質問</h2>

          <dl class="qa" v-if="isDisplayConnectixFAQ">
            <dt>
              <p>「Connectix」のお申し込み方法について</p>
            </dt>
            <dd class="dd-margin-top">
              <p v-if="isEmptyBirthdate()">
                Connectixのご利用には、UCOM光 レジデンス会員登録が必要です。<br />
                UCOM光 レジデンス会員登録がお済みの場合は、<router-link to="/ucom/linking" class="link no-icon">こちら</router-link
                >からPortasとのお客様番号連携を行ってください。<br />
                お済みでない場合、Portasマイページから生年月日をご登録いただいた後、UCOM光 レジデンス会員登録のお手続きをお願いいたします。<br />
                <router-link to="/platform/my-page" class="link no-icon">Portasマイページはこちら</router-link>
              </p>
              <p v-else-if="isUnder18()">
                Connectixのご利用には、UCOM光 レジデンス会員登録が必要です。<br />
                UCOM光レジデンス会員登録がお済みの場合は、<router-link to="/ucom/linking" class="link no-icon">こちら</router-link
                >からPortasとのお客様番号連携を行ってください。<br />
                お済みでない場合、<a class="link no-icon" :href="ucomBuildingUrl" target="UCOM">建物専用サイト（こちら）</a
                >から、サポートセンターのお問合せ先をご確認いただき、新規登録のお申し込みをお願いいたします。
              </p>
              <p v-else>
                Connectixのご利用には、UCOM光 レジデンス会員登録が必要です。お済みでない場合は<router-link to="/ucom/entry/input" class="link no-icon">こちらから</router-link
                >ご登録ください。<br />
                UCOM光 レジデンス会員登録がお済みの場合は、<router-link to="/ucom/linking" class="link no-icon">こちらから</router-link>Portasとのお客様番号連携を行ってください。
              </p>
            </dd>
          </dl>
        </div>
      </section>
    </article>
  </div>
</template>
<style lang="scss" scoped>
@charset "UTF-8";
/* CSS Document */
/* A Modern CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}

.topqa {
  margin-top: 8px;
}

.link-container {
  display: flex;
  flex-direction: column;
}

.hidden-li {
  display: hidden;
}

.qa-border {
  border-bottom: 1px solid #a2a2a2;
  margin-top: 0;
  margin-bottom: 8px;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
#content {
  padding: 40px 50px 0;
  min-height: 500px;
}
section {
  margin: 0 auto 50px;
}
h2 {
  border-bottom: 3px solid #0097e0;
  display: inline-block;
  line-height: 1.6;
  margin: 0 auto 20px;
  font-size: 22px;
  &.my-title-at-top {
    border-bottom-color: #cf1225;
    font-size: 20px;
  }
}
h3 {
  border-left: 5px solid #eee63b;
  font-size: 20px;
  line-height: 20px;
  padding-left: 10px;
  margin: 30px auto 20px;
}
h4 {
  font-size: 18px;
  margin: 30px auto 20px;
}
h5 {
  font-size: 14px;
  margin: 20px 0 10px;
  font-weight: normal;
}
a {
  color: #222;
  text-decoration: underline;
}
a.link.red {
  position: relative;
  display: inline-block;
  padding: 0 0 0 20px;
  vertical-align: middle;
  text-decoration: underline;
  color: #cf1225;
}
a.link {
  position: relative;
  display: inline-block;
  padding: 0 0 0 20px;
  vertical-align: middle;
  text-decoration: underline;
  color: #222;
}

a.link::before,
a.link::after {
  position: absolute;
  left: 0;
  margin: auto;
  content: '';
  vertical-align: middle;
}
a.link::before {
  top: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #222;
}
a.link::after {
  top: 10px;
  left: 6px;
  box-sizing: border-box;
  width: 8px;
  height: 4px;
  border: 3px solid transparent;
  border-left: 4px solid #fff;
}

a.link {
  &.no-icon {
    display: inline;
    padding: 0;
    vertical-align: unset;
    &::before {
      display: none;
      padding: 0;
    }
    &::after {
      display: none;
      padding: 0;
    }
  }
}

dl.qa {
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  background: #eee;
  border-radius: 8px;
  line-height: 1.6;
  letter-spacing: 0.8px;
}
dl.qa dt {
  position: relative;
  padding-left: 50px;
  // & p {
  //   line-height: 40px;
  // }
}
dl.qa dt::before {
  content: 'Q';
  position: absolute;
  top: 0;
  left: 0px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 40px;
  border-radius: 8px;
  display: table-cell;
  vertical-align: middle;
  font-family: Helvetica;
  font-weight: bold;
  color: #fff;
  background: #222;
  text-align: center;
}
dl.qa dd {
  position: relative;
  display: block;
  min-height: 40px;
  margin-top: 15px;
  padding-left: 50px;
}
dl.qa dd::before {
  content: 'A';
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 40px;
  border-radius: 8px;
  display: block;
  font-family: Helvetica;
  font-weight: bold;
  background: #fff;
  text-align: center;
}

.qa-line-height {
  line-height: 20px !important;
}
.dd-margin-top {
  margin-top: 35px !important;
}

div.c-link-list-container {
  margin-top: 8px;
  & div.c-link-list-outer {
    & ul.c-link-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      & li.c-link-list__item {
        letter-spacing: 0.8px;
        line-height: 2.5;
        &:before {
          font-family: 'Material Icons';
          content: '\e409';
          position: relative;
          top: 3px;
          font-size: 16px;
        }
        & a {
          text-decoration: underline;
        }
      }
    }
  }
}

div.c-link-list-topqa {
  margin-top: 0px;
  & div.c-link-list-outer {
    & ul.c-link-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      & li.c-link-list__item {
        letter-spacing: 0.8px;
        &:before {
          font-family: 'Material Icons';
          content: '\e409';
          position: relative;
          top: 3px;
          font-size: 16px;
        }
        & a {
          text-decoration: underline;
        }
      }
    }
  }
}

.isp-pc {
  display: none;
}

@media screen and (max-width: 768px) {
  #content {
    padding: 10px 10px 0;
  }
  section {
    margin: 0 auto 20px;
  }
  section.message {
    background: #fae7e8;
    border-radius: 8px;
    padding: 10px;
    color: #d0121b;
  }
  section#apartment {
    text-align: center;
    border-bottom: none;
    margin: 0 auto 50px;
  }
  h2 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  dl.qa dt {
    padding-left: 40px;
    // & p {
    //   line-height: 30px;
    // }
  }
  dl.qa dt::before {
    top: 0;
    width: 30px;
    height: 30px;
    font-size: 24px;
    line-height: 30px;
    border-radius: 4px;
  }
  dl.qa dd {
    margin-top: 10px;
    min-height: 30px;
    padding-left: 40px;
  }
  dl.qa dd::before {
    width: 30px;
    height: 30px;
    font-size: 25px;
    line-height: 30px;
    border-radius: 4px;
  }
  div.top-link-to-another-faq {
    text-align: center;
    padding: 5px;
    & p {
      padding: 0;
      line-height: 0;
    }
  }
}
@media screen and (max-width: 550px) {
  #bnr .bnr_cx a.btn {
    margin: 10px 0 0 0 !important;
    background: #e4c115 !important;
    border-radius: 50px;
    padding: 5px 20px !important;
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }
  div.top-link-to-another-faq {
    text-align: center;
    padding: 5px;
    & p {
      padding: 0;
      line-height: 0;
    }
  }
}

@media only screen and (min-width: 1000px) {
  h2 {
    &.my-title-at-top {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .isp-pc {
    display: flex;
    width: 100%;
  }

  div.c-link-list-container {
    // display: flex;
    flex-direction: row;
    & div.isp-sp {
      display: none;
    }
    & div.c-link-list-outer {
      width: calc(100% / 3);
      & ul.c-link-list {
        & li.c-link-list__item {
          line-height: 1.6;
          margin-bottom: 0px;
          &:last-child {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
div.top-link-to-another-faq {
  text-align: center;
  padding: 5px;
  & a {
    display: inline-block;
    text-align: center;
    color: #fff;
    background-color: #cf1225;
    text-decoration: none;
    border-radius: 2px;
    padding: 5px;
    & p {
      padding: 10px 25px;
      line-height: 24px;
      & i {
        color: #fff;
      }
    }
  }
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';

import { Member } from '@/shared/classes/spf-api/member';
import { UA_TYPE } from '@/shared/const/service-type';
import { isEmptyBirthdate } from '@/shared/util/func-is-empty-birthdate';
import { isUnder18 } from '@/shared/util/func-is-under-18';
export default defineComponent({
  data: () => ({
    member: null as Member | null,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    fiveAUrl: process.env.VUE_APP_FIVE_A_BUILDING_SEARCH_URL,
    lNetUrl: process.env.VUE_APP_FIVE_A_LIC_SERVICE_DOMAIN,
    nAssetUrl: process.env.VUE_APP_FIVE_A_N_ASSET_SERVICE_DOMAIN,
    eMansionRegistGuideUrl: process.env.VUE_APP_E_MANSION_REGIST_GUIDE_URL,
    tnosBaseUrl: process.env.VUE_APP_TNOS_BASE_URL,
    eMansionNetUrl: process.env.VUE_APP_E_MANSION_FIBER_URL,
    ucomSearchproPertyUrl: process.env.VUE_APP_UCOM_PROPERTY_SEARCH_URL,
    fiveaSearchproPertyUrl: process.env.VUE_APP_FiveA_PROPERTY_SEARCH_URL,
    portasTop: process.env.VUE_APP_PORTAS_TOP_URL,
    faqUrl: process.env.VUE_APP_FAQ_URL,
    uaType: UA_TYPE,
    oemsMaster: [],
    // OEM一覧表示用配列
    oemMaster1: [],
    oemMaster2: [],
    oemMaster3: [],
  }),
  // 親コンポーネントから受け取るデータ
  props: {
    serviceType: {
      type: String,
    },
    isDisplayConnectixFAQ: {
      type: Boolean,
    },
    eMansionMemberRegistrationUrl: {
      type: String,
    },
    eMansionForMansionUrl: {
      type: String,
    },
    ucomBuildingUrl: {
      type: String,
    },
  },
  async mounted() {
    this.uaType.UCOM;
    this.$data.oemsMaster = this.$store.getters['oemsStore/oems'];
    for (let i = 0; i < this.$data.oemsMaster.length; i++) {
      if (i % 3 === 0) {
        this.oemMaster1.push(this.oemsMaster[i]);
      } else if (i % 3 === 1) {
        this.oemMaster2.push(this.oemsMaster[i]);
      } else {
        this.oemMaster3.push(this.oemsMaster[i]);
      }
    }
    this.member = this.$store.getters['memberStore/member'];
  },
  methods: {
    isEmptyBirthdate() {
      if (this.member?.birthdate) {
        return isEmptyBirthdate(this.member.birthdate);
      }
      return true;
    },
    isUnder18() {
      if (this.member?.birthdate) {
        return isUnder18(this.member.birthdate);
      }
      return false;
    },
  },
});
</script>
