/**
 * Mcloud 共通利用 API : マンション情報取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class McloudApartmentResponseData {
  public data!: McloudApartmentResponse;
  public included!: McloudApartmentResponseIncluded[];

  constructor(partial: Partial<McloudApartmentResponseData>) {
    Object.assign(this, partial);
  }
}

/** Data クラス */
export class McloudApartmentResponse {
  public id!: string;
  public type!: string;
  public attributes!: McloudApartmentResponseAttributes;
  public relationships!: McloudApartmentResponseRelationships;

  constructor(partial: Partial<McloudApartmentResponse>) {
    Object.assign(this, partial);
  }
}

export class McloudApartmentResponseAttributes {
  /** マンションID */
  public id!: number;
  /** マンション名 */
  public name!: string;
  /** 郵便番号 */
  public zipcode!: string;
  /** 都道府県ID */
  public prefecture_id!: number;
  /** 都道府県名 */
  public prefecture_name!: string;
  /** 市区町村・番地 */
  public city!: string;
  /** プラン 1：フリープラン 2：プレミアムプラン */
  public plan!: number;
  /** 運用タイプ 1：Type-A  2：Type-B  3：Type-C  4：Type-D  5：Type-E */
  public operation_type!: number;
  /** マンションロゴ画像ID */
  public logo_file_id!: number;
  /** マンションロゴ画像URL */
  public image_url!: string;
  /** リンクのカテゴリー名称 */
  public link_category_name!: string;
  /** 理事会グループ利用 true：利用する false：利用しない */
  public use_board_group!: boolean;
  /** 種別グループ利用 true：利用する false：利用しない */
  public use_kind_group!: boolean;
  /** 棟グループ利用 true：利用する false：利用しない */
  public use_building_group!: boolean;
  /** 会議管理利用 true：利用する false：利用しない */
  public use_board_discussion!: boolean;
  /** 契約容量 */
  public capacity!: string;
  /** 使用済み容量 */
  public used_capacity!: string;
  /** 容量使用率 */
  public used_capacity_percentage!: number;
  /** 残容量 */
  public residual_capacity!: string;
  /** お知らせ機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_notice!: boolean;
  /** 共用部モニターでのお知らせ機能の利用 true：利用する false：利用しない ※フリープランの場合またはお知らせ機能を利用しない場合返却しない */
  public use_notice_signage!: boolean;
  /** 共用部モニターでのお知らせテキスト長 ※フリープランの場合またはお知らせ機能を利用しない場合返却しない */
  public notice_signage_text_character_limit!: number;
  /** インターホンでのお知らせ機能の利用 true：利用する false：利用しない ※フリープランの場合またはお知らせ機能を利用しない場合返却しない */
  public use_notice_at_intercom!: boolean;
  /** インターホンでのお知らせテキスト長 ※フリープランの場合またはお知らせ機能を利用しない場合返却しない */
  public notice_intercom_body_character_limit!: number;
  /** インターホンでの防災機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_disaster_at_intercom!: boolean;
  /** 施設予約機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_reservation!: boolean;
  /** カレンダー機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_calendar!: boolean;
  /** 共用部モニターでのカレンダー機能の利用 true：利用する false：利用しない ※フリープランの場合またはカレンダー機能を利用しない場合返却しない */
  public use_calendar_signage!: boolean;
  /** 共用部モニターでのカレンダーテキスト長 true：利用する false：利用しない ※フリープランの場合またはカレンダー機能を利用しない場合返却しない */
  public calendar_signage_text_character_limit!: number;
  /** インターホンでのカレンダー機能の利用 true：利用する false：利用しない ※フリープランの場合またはカレンダー機能を利用しない場合返却しない */
  public use_calendar_at_intercom!: boolean;
  /** インターホンでのカレンダーテキスト長 ※フリープランの場合またはカレンダー機能を利用しない場合返却しない */
  public calendar_intercom_body_character_limit!: number;
  /** 掲示板機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_bbs!: boolean;
  /** 承認機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_accepted!: boolean;
  /** トピック作成権限 true：理事会メンバーのみ false：居住者も自由に ※フリープランの場合返却しない */
  public use_regident!: boolean;
  /** トピック、コメントの投稿表示設定 true：匿名も可能 false：実名のみ ※フリープランの場合返却しない */
  public use_anonymous!: boolean;
  /** 掲示板書き込み不可メンバー ※フリープランの場合返却しない */
  public deny_users!: string;
  /** 出欠確認機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_attendance!: boolean;
  /** ご意見箱機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_opinion_box!: boolean;
  /** メールアドレス 0：必須 1：任意 2：非表示 ※フリープランの場合返却しない */
  public opinion_box_email!: number;
  /** 電話番号 0：必須 1：任意 2：非表示 ※フリープランの場合返却しない */
  public opinion_box_tel!: number;
  /** 説明文 */
  public opinion_box_description!: boolean;
  /** ご意見箱を公開する権限ID ※フリープランの場合返却しない */
  public opinion_box_role_ids!: Array<number>;
  /** アンケート機能の利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_questionnaire!: boolean;
  /** 防災機能の利用許可※管理側設定項目 true：利用する false：利用しない ※フリープランの場合返却しない */
  public allow_disaster!: boolean;
  /** 防災機能の利用※マンション側設定項目 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_disaster!: boolean;
  /** 防災機能利用可否 true：利用する false：利用しない ※フリープランの場合返却しない */
  public disaster_available!: boolean;
  /** 専有部サービスの利用 true：利用する false：利用しない ※フリープランの場合返却しない */
  public use_housekeeping!: boolean;
  /** 専有部サービスのトップページ表示画像URL */
  public housekeeping_top_image_url!: string;
  /** 専有部サービスのトップページ表示画像URL（スマートフォン用） */
  public housekeeping_smartphone_top_image_url!: string;
  /** 要援護設定の公開 true：利用する false：利用しない ※フリープランの場合返却しない */
  public open_need_help!: boolean;
  /** ISP契約種別 0：未確認 1：UCOM光レジデンス 全戸 2：e-mansion 全戸 3：他社 全戸 4：UCOM光レジデンス 受益 5：e-mansion 受益 6：他社 受益 7：不明 ※フリープランの場合返却しない */
  public isp_type!: number;
  /** 販促バナーの利用 true：利用する false：利用しない */
  public use_promotion_banner!: boolean;
  /** 電子錠連携 0：利用しない 1：美和ロック磯子 2：美和ロックVERSA 3：シブタニ 4：GOAL */
  public ekey_type!: number;
  /** 帰宅・外出通知利用可否※電子錠連携を利用する場合のみ */
  public use_ekey_notify!: boolean;
  /** 電子錠の参考資料用の画像URL※電子鍵設定が利用可能な電子鍵の場合のみ */
  public ekey_image_url!: string;
  /** レンタサイクル空き状況表示可否 true：表示する false：表示しない */
  public use_rental_cycle_usage_status!: boolean;

  constructor(partial: Partial<McloudApartmentResponseAttributes>) {
    Object.assign(this, partial);
  }
}

export class McloudApartmentResponseRelationships {
  public apartment_ekeys!: McloudApartmentResponseSharedProperties;
  public pos_setting!: McloudApartmentResponseSharedProperty;

  constructor(partial: Partial<McloudApartmentResponseRelationships>) {
    Object.assign(this, partial);
  }
}

export class McloudApartmentResponseSharedProperty {
  public data!: McloudApartmentResponseSharedData;

  constructor(partial: Partial<McloudApartmentResponseSharedProperty>) {
    Object.assign(this, partial);
  }
}

export class McloudApartmentResponseSharedProperties {
  public data!: McloudApartmentResponseSharedData[];

  constructor(partial: Partial<McloudApartmentResponseSharedProperties>) {
    Object.assign(this, partial);
  }
}

export class McloudApartmentResponseSharedData {
  public id!: string;
  public type!: string;

  constructor(partial: Partial<McloudApartmentResponseSharedData>) {
    Object.assign(this, partial);
  }
}

/** Included クラス */
export class McloudApartmentResponseIncluded {
  public id!: string;
  public type!: string;
  public attributes!: McloudApartmentResponseIncludedAttributes;

  constructor(partial: Partial<McloudApartmentResponseIncluded>) {
    Object.assign(this, partial);
  }
}

export class McloudApartmentResponseIncludedAttributes {
  public id?: string;
  public ekey_type?: number;
  public ekey_no?: string;
  public use_ekey?: boolean;
  public pay_room?: boolean;
  public pay_cash?: boolean;
  public pay_emoney?: boolean;
  public pay_card?: boolean;
  public pay_other?: boolean;
  public use_mck?: boolean;
  public sales_note?: boolean;
  public use_pos_bind_keyword?: boolean;
  public use_pos_bind_account_create?: boolean;
  public room_number_compare_type?: boolean;
  public destroy_relate_user?: boolean;

  constructor(partial: Partial<McloudApartmentResponseIncludedAttributes>) {
    Object.assign(this, partial);
  }
}
