/**
 * 未成年かどうかを判定する
 *
 * @param birthDateValue: 会員テーブルから取得する誕生日(形式: "yyyy-mm-dd")
 * @return 未成年である: true / 未成年でない: false
 */
export const isUnder18 = (birthDateValue: string): boolean => {
  const birthDate = new Date(birthDateValue);
  // 18歳になる年月日
  const birthDateAt18 = Date.UTC(birthDate.getFullYear() + 18, birthDate.getMonth(), birthDate.getDate());

  if (new Date().getTime() < birthDateAt18) {
    return true;
  }
  return false;
}
