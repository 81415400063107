/**
 * five-a Connectix API : Connectix お申し込み : レスポンス
 *
 * 外部 API のリクエスト仕様に合わせてスネークケースで命名している
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class FiveAConnectixNewConnectixResponse {
  public result!: {
    processing_result: boolean;
  };

  constructor(partial: Partial<FiveAConnectixNewConnectixResponse>) {
    Object.assign(this, partial);
  }
}
