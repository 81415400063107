export const UA_TYPE = {
  PORTAS: '0',
  E_MANSION: '1',
  FIVE_A: '2',
  UCOM: '3',
  GAME_WITH: '4',
} as const;

export const OEM_TYPE = {
  FIVE_A: '00',
  L_NET: '01',
  N_ASSET: '02',
} as const;

export const TRANSITION_SOURCE_SERVICE = {
  E_MANSION: '1',
  FIVE_A: '3',
  UCOM: '2',
} as const;

export const SERVICE_TYPE = TRANSITION_SOURCE_SERVICE;

export const PROPERTY_SEARCH_TYPE = {
  E_MANSION: 3,
  UCOM: 4,
  MCLOUD: 5,
} as const;

export const ISP_TYPE_REGEXP = {
  ALL: /^[01234567]{1}$/,
  MCLOUD: /^[0367]{1}$/,
  E_MANSION_MCLOUD: /^[25]{1}$/,
  UCOM_MCLOUD: /^[14]{1}$/,
} as const;

export const SERVICE_NAME = {
  E_MANSION: 'e-mansion',
  FIVE_A: 'Five.A',
  UCOM: 'UCOM光 レジデンス',
  GAME_WITH: 'GameWith光',
} as const;

// ISPの会員ステータスを利用する関係で追加
export const ISP_MEMBER_STATUS = {
  // Five.A外部APIの問い合わせ結果を用いて定義されるISP(Five.A)会員ステータス
  FIVE_A: {
    MEMBER: '会員',
    IN_CANCEL_APPLICATION: '退会申し込み中',
    CANCEL_AND_LOGIN_OK: '退会済みログイン可能',
    CANCEL_AND_LOGIN_NG: '退会済みログイン不可',
  },
  UCOM: {
    MEMBER: '会員',
    IN_CANCEL_APPLICATION: '退会申し込み中',
    CANCEL_AND_LOGIN_OK: '退会済みログイン可能',
    CANCEL_AND_LOGIN_NG: '退会済みログイン不可',
  },
  E_MANSION: {
    MEMBER: '会員',
    IN_CANCEL_APPLICATION: '退会申し込み中',
    TRANSFER_AND_CANCEL: '移転退会',
    CANCEL_AND_LOGIN_OK: '退会済みログイン可能',
    CANCEL_AND_LOGIN_NG: '退会済みログイン不可',
  },
} as const;

// ISP会員ステータスに応じて、Portas会員専用トップの表示を切り替える関係で追加
export const DISPLAY_PORTAL_PATTERN = {
  FIVE_A: {
    NONE: '何も表示しない',
    LOGO_AND_LINK_TO_MEMBER_PAGE: 'ロゴとISP会員専用トップページへのリンク',
    NORMAL: '通常表示',
  },
  UCOM: {
    NONE: '何も表示しない',
    LOGO_AND_LINK_TO_MEMBER_PAGE: 'ロゴとISP会員専用トップページへのリンク',
    NORMAL: '通常表示',
  },
  E_MANSION: {
    NONE: '何も表示しない',
    LOGO_AND_LINK_TO_MEMBER_PAGE: 'ロゴとISP会員専用トップページへのリンク',
    NORMAL: '通常表示',
  },
} as const;
/** U-NEXTのコンテンツID */
export const UNEXT_CONTENTS_ID = {
  MONTHLY_PLAN: 4,
  OTHER_PLAN: 5,
} as const;
/** コンテンツプロバイダーID */
export const CONTENT_PROVIDER_ID = {
  BBSS: 1,
  JAPAN_PC_SERVICE: 2,
  UNEXT: 3,
} as const;
/** 明細名称 ID */
export const INVOICE_DETAIL_NAME_MASTER = {
  PORTAS: {
    NORTON: 1,
    SAGI_WALL: 2,
    WIFI_DEVICE_SUPPORT: 3,
  },
  U_NEXT: {
    MONTHLY_PLAN: 4,
    OTHER_PLAN: 5,
    SET_DISCOUNT: 6,
  },
} as const;

/** 商品価格.購入種別 */
export const PRODUCT_PRICE_TYPE = {
  REGULAR_PRICE: '0',
  DISCOUNTED_PRICE: '1',
} as const;

export const TRANSITION_STORAGE = 'transition';
export const TRANSITION_COOKIE = 'transition';

export type TransitionSourceServiceType = typeof TRANSITION_SOURCE_SERVICE[keyof typeof TRANSITION_SOURCE_SERVICE];
export type UaTypeValue = typeof UA_TYPE[keyof typeof UA_TYPE];
