<template>
  <div class="member-confirm">
    <LoadingComponent v-if="isSubmitting" />
    <main class="underlayer-main">
      <h1>GameWith光 登録情報変更</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/gamewith">GameWith光マイページ</router-link></li>
        <li>登録情報変更</li>
        <li>完了</li>
      </ul>
      <div class="blc">
        <p>GameWith光は、株式会社GameWithが提供する光ファイバーインターネットサービスです。</p>
      </div>
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>登録情報変更 入力</li>
        <li>登録情報変更 確認</li>
        <li class="stay">登録情報変更 完了</li>
      </ul>

      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages.length > 0" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="会員情報変更" />GameWith光 登録情報変更 完了</h2>
      </div>
      <p>GameWith光 登録情報変更が完了しました。</p>
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onNext()">GameWith光 マイページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { GameWithEditInputForm } from '@/shared/classes/gamewith/edit-input-form';
import { checkRouterError } from '@/shared/util/router-navigation-func';

type DataType = {
  form: GameWithEditInputForm | null;
  errorMessages: string[];
  errorMessageTitle: string;
  isSubmitting: boolean;
};

/** MemberConfirm コンポーネント */
export default defineComponent({
  name: 'member-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): DataType {
    return {
      form: null,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      isSubmitting: false,
    };
  },
  async mounted(): Promise<void> {
    this.form = this.$store.getters['gamewithMemberEditStore/gamewithEditInputForm'];
    if (!this.form) {
      //リロードや直打ちの場合入力画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/gamewith/member-edit').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    onBack() {},
    async onNext() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      //マイページへ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/gamewith').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    },
  },
});
</script>
