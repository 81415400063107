import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { UcomMailAddressCheckDto } from '@/shared/classes/spf-api/mail/model/ucom/mail-address/ucom-mail-address-check-dto';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiMailAddressAccessor {
  /**
   * AU00-002_メールアドレス入力チェックAPI
   * @param mailAddress メールアドレス
   * @return チェック結果
   */
  public static async checkMailAddress(mailAddress: string, mailAddressType: string): Promise<UcomMailAddressCheckDto> {
    const params = { mailAddress: mailAddress, mailAddressType: mailAddressType };
    // TODO: APIで使用しないパスパラメータが存在するため、ダミーで設定（パラメータの値は何でも問題ない）
    const response = await api.get<UcomMailAddressCheckDto>(`/common/UCOM/mailAddress-check/1`, { params: params }).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return response.data;
  }
}
