<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールサービス購入 完了</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>メールサービス購入</li>
      </ul>

      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールサービス購入 完了 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールサービス購入" />メールサービス購入 完了</h2>
        <p>メールサービスの購入が完了しました。</p>
        <p v-if="contactMailAddress">{{ contactMailAddress }}宛に、「申込み完了メール」を送信しましたのでご確認ください。</p>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th class="va-middle">商品名</th>
              <td>{{ $data.mailPackInfo.productName }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み内容</th>
              <td>購入する</td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o">{{ Number($data.mailPackInfo.monthlyFee).toLocaleString() }}円 （税込）</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ $data.processDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td v-html="$data.mailPackInfo.serviceDescription"></td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc -->

      <div class="sblc">
        <p class="form-btn-txt">メールアドレスの登録には、アカウントIDが必要となります。</p>
        <p class="form-btn-txt">アカウント情報登録変更画面にて、アカウントIDを登録の後、メールアドレスの登録を行ってください。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" @click="goChange()">アカウント情報登録変更画面へ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>

      <div class="sblc">
        <p class="form-btn-txt">既にアカウントIDをお持ちの方は、契約アカウント情報一覧画面で確認できます。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" @click="goList()">契約アカウント情報一覧画面へ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="goTop()">UCOM光 レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'complete',
  components: {
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: {
      productName: '',
      monthlyFee: '',
      serviceDescription: '',
    },
    contactMailAddress: '',
    messageList: [] as string[],
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    processDate: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.mailPackInfo.productName = this.$store.getters['ucomMailPackStore/productName'];
      if (!this.$data.mailPackInfo.productName) {
        await this.$router.push('/ucom/mail-pack/input').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.$data.mailPackInfo.monthlyFee = this.$store.getters['ucomMailPackStore/monthlyFee'];
      if (!this.$data.mailPackInfo.monthlyFee) {
        await this.$router.push('/ucom/mail-pack/input').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.$data.processDate = this.formatDate(this.$store.getters['ucomMailPackStore/processDate']);
      if (!this.$data.processDate) {
        await this.$router.push('/ucom/mail-pack/input').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.$data.contactMailAddress = this.$store.getters['ucomMailPackStore/contactMailAddress'];

      this.$data.mailPackInfo.serviceDescription = this.$store.getters['ucomMailPackStore/serviceDescription'];
      if (!this.$data.mailPackInfo.serviceDescription) {
        await this.$router.push('/ucom/mail-pack/input').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    //アカウント情報登録変更画面へボタン
    async goChange() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom/account-management/list').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    //契約アカウント情報一覧画面へボタン
    async goList() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom/account-option-change/account-list').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    //トップへボタン
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },
});
</script>
