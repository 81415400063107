import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "error-messages-component" }
const _hoisted_2 = { class: "err" }
const _hoisted_3 = { class: "red" }
const _hoisted_4 = { class: "ul-basic01" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.errorMessageInquiry), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessagesInquiry, (errorMessage) => {
            return (_openBlock(), _createElementBlock("li", {
              key: errorMessage.header
            }, [
              _createTextVNode(_toDisplayString(errorMessage.header) + " ", 1),
              _createElementVNode("p", null, _toDisplayString(errorMessage.message1), 1),
              (errorMessage.message2IsLink)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: errorMessage.message2
                  }, _toDisplayString(errorMessage.message2), 9, _hoisted_5))
                : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(errorMessage.message2), 1))
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}