import { Module } from 'vuex';
import { RootState } from '../index';
import { ContactsInputForm } from '../../pages/platform/contacts/classes/contacts-input-form';

/** contactsState  State */
const contactsState: {
  contactsInputForm: ContactsInputForm | null; // お問い合わせ入力情報
} = {
  contactsInputForm: null
};

/** State の型 */
type ContactsState = typeof contactsState;

/** Platform 新規会員登録 Store */
export const contactsStore: Module<ContactsState, RootState> = {
  namespaced: true,
  state: contactsState,
  mutations: {
    contactsInputForm: (state, value: ContactsInputForm) => (state.contactsInputForm = value)
  },
  getters: {
    contactsInputForm: (state): ContactsInputForm | null => state.contactsInputForm,
  }
};
