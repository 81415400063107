import { Member } from '@/shared/classes/spf-api/member';

export class InitEMansionMainAccount {
  private readonly _memberId!: number;
  private readonly _accountName!: string;
  private readonly _accountSubDomain!: string;

  constructor(memberId: number, accountName: string, accountSubDomain: string) {
    this._memberId = memberId;
    this._accountName = accountName;
    this._accountSubDomain = accountSubDomain;
  }

  get memberId(): number {
    return this._memberId;
  }

  get accountSubDomain(): string {
    return this._accountSubDomain;
  }

  get accountName(): string {
    return this._accountName;
  }
}
