<template>
  <div class="terms">
    <LoadingComponent v-if="isRouting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Portas利用規約</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb" v-if="$route.query.gamewith!='true'">
        <li><router-link to="/">トップページ</router-link></li>
        <li>利用規約</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:利用規約 -->
      <div class="blc">
				<h2 class="txt-center">Portas利用規約</h2>
				<h4 class="underlayer-h4">（規約の適用）</h4>
				<p>第1条　Portas利用規約（以下「本規約」といいます。）は、アルテリア・ネットワークス株式会社（以下「当社」といいます。）が運営、公開するサービスプラットフォーム「Portas」（以下「本サービス」といいます。）の利用に関し適用されるものとします。</p>
				<p>2 本サービスに関し、本規約に定める内容と当社が別途定める個別規定の内容が異なる場合は、当該個別規定の内容が適用されるものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（規約の変更）</h4>
				<p>第2条　当社は、当社所定の方法にて通知または公表することにより、本規約の全部または一部を変更することができるものとします。この場合、変更後の規約が適用されるものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（本サービス）</h4>
				<p>第3条　本サービスは、当社または当社指定の事業者が提供するサービス、コンテンツ（以下「対象コンテンツ」といいます。）の利用、閲覧、視聴、申込等を行うことが出来る専用webサイト、アプリケーション等を当社より提供するものであり、本サービスの会員登録（以下「本契約」といいます）をした会員にのみ、本サービスは利用することが出来るものとします。</p>
				<p>2　当社は、本サービスの提供に係る全部または一部を当社指定の事業者に委託するものとします。</p>
				<p>3　当社は、対象コンテンツの種類、内容等につき、当社の判断において追加、削除、変更することができるものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（利用者について）</h4>
				<p>第4条　前条の定めにかかわらず、本サービスにおいて、本契約を締結せずに利用することができるサービスがある場合、本利用規約にご同意いただく手続に代えて、実際にご利用いただくことで、利用者は本規約に同意したものとみなします。この場合、利用者は本規約の定めを遵守するものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（本契約の単位）</h4>
				<p>第5条　当社は、会員一人ごとに1つの本契約を締結します。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（本契約の申し込み）</h4>
				<p>第6条　本契約の申し込みは、本規約を承諾の上、当社所定の方法により行うものとし、これにより本契約は成立するものとします。</p>
				<p>2　本契約の申し込み者（以下「会員申込者」といいます。）が未成年の個人である場合には、本契約の申し込みにあたり法定代理人の同意を得ていただく必要があります。この場合、法定代理人は、本規約に定める会員申込者の義務につき、会員申込者と連帯して保証するものとします。</p>
				<p>3　本契約の申し込みに際しては、会員申込者の氏名、住所等の情報のほか、本人確認資料を求める場合があります。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（アカウントについて）</h4>
				<p>第7条　当社は、本サービス、対象コンテンツを適切に管理、運用するため、当社指定のアカウントを設定することがあります。</p>
				<p>2 当社は、アカウントを設定した場合、速やかに当社所定の方法で会員に付与します。</p>
				<p>3  会員は、当社が別途定める場合を除き、アカウントを、第三者に使用させ、または、売買、譲渡もしくは貸与等してはならないものとします。</p>
				<p>4 アカウントの管理および使用は会員の責任とし、使用上の過誤または第三者による不正使用等について、当社は一切その責を負わないものとします。</p>
				<p>5 会員のアカウントにより本サービスが利用されたときには、その会員自身の利用とみなされるものとします。</p>
				<p>6 当社は、本サービスに関する当社の業務の遂行もしくは対象コンテンツの運用に著しい支障を及ぼし、または及ぼす恐れのある行為と判断したとき、当該会員に係るアカウントの一部または全部の利用を停止し、変更することがあります。</p>
				<p>7 当社は、前項の規定により、アカウントの一部または全部の利用を停止する場合、会員へ何ら催告等を行うことなく行うものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（料金）</h4>
				<p>第8条　本サービスは、無償とします。但し、対象コンテンツにおいては、対象コンテンツにおいて定められた各種料金および支払方法が適用されます。</p>
				<p>2　会員は、対象コンテンツに係る料金のお支払いのためにクレジットカードの利用を希望する場合は、本契約の申込その他の場面で、当社所定の方法により、会員が保有するクレジットカードの会員番号、その他当社が指定する事項（以下「クレジットカード情報」といいます。）を登録いただく場合があります。この場合、第11条（本サービスの中止）、第12条（免責）その他本規約に基づき対応が必要になる場合を除き、第14条に基づき本契約の解除がなされるまでの間、クレジットカード情報は本サービス上において登録が維持されます。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（会員情報の変更）</h4>
				<p>第9条　会員は、本契約の申込の場面その他サービス上で登録いただいた情報に誤りを見つけた場合、またはその情報の変更を要する場合、速やかに当社指定の方法により修正、変更し、常に登録情報を正確かつ最新の状態にしておくものとします。
					会員が誤った情報を登録したこと、または登録情報の更新を適時に行わなかったことにより会員に不利益が生じた場合でも、当社は一切責任を負いません。</p>
				<p>2　前項に違反する場合、当社は、当該会員の本サービスの利用を停止、制限することがあります。また、当該違反が解消されないものと当社で判断した場合は、当社は本契約を解除できるものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（遵守事項）</h4>
				<p>第10条　会員、利用者（以下「会員等」といいます。）は、本サービスおよび対象コンテンツの利用にあたって次の行為を行わないものとします。</p>
				<p>(1) 法令（法律、政令などをいいます。）その他ガイドライン等の定めに違反する行為。</p>
				<p>(2) 本規約または対象コンテンツに適用される約定に違反する行為。</p>
				<p>(3) 公序良俗に違反し、当社または他人の権利を著しく侵害すると当社が判断した行為。</p>
				<p>(4) 本サービス、対象コンテンツの利用、運営を妨害、阻害すると当社が判断した行為。</p>
				<p>2　当社は、前項に違反する会員等、または違反する虞がある会員等につき、本サービスの利用を停止、制限することがあります。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（本サービスの中止）</h4>
				<p>第11条　当社は、以下のいずれかの事由が生じた場合、何ら会員等に通知することなく、本サービス、対象コンテンツの提供を中止、制限することがあります。</p>
				<p>(1) 当社設備または他社の設備の保守または工事によりやむを得ない場合。</p>
				<p>(2) 当社設備または他社の設備上一時的な使用制限が必要と判断された場合。</p>
				<p>(3) 天災、事変その他の非常事態が発生した場合。</p>
				<p>(4) 第10条（遵守事項）の規定その他本規約の定めに違反したと当社が認めたとき。</p>
				<p>2 当社は、前項に基づく本サービス、対象コンテンツの提供の中止、制限の影響または、本サービス、対象コンテンツの運営、提供を正常化する目的で、当社の判断により、会員等が本サービス、対象コンテンツにおいて登録した情報、データ、記録その他の履歴、ログ等を削除する場合があります。</p>
				<p>3 当社は、前2項による措置につき、何ら責任を負わないものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（免責）</h4>
				<p>第12条　当社は、本サービスの運営に要するサーバ、通信設備、ソフトウェア等およびこれらによる通信環境、利用環境について、その完全性、正確性、可用性、有用性等を一切保証するものではなく、責任は負いません。</p>
				<p>2 当社は、会員等が本サービスを利用することにより得られる情報またはソフトウェア等について、その完全性、正確性、可用性、有用性または適法性を一切保証するものではなく、責任は負いません。</p>
				<p>3 当社は、会員等が本サービスを利用するにあたり、接続されるコンピュータ機器、通信機器、端末機器等の動作、機能、設定等については保証しません。また、本サービスの利用に伴う当該端末機器等による通信、または本サービスの利用に伴う当該端末機器等の仕様変更、更新、アップデート等に要した費用は会員等にて負担いただきます。</p>
				<p>4 当社は、本サービス及び対象コンテンツが、会員が本契約を行った目的を達成することを保証するものではありません。また、本サービスについて、第三者による権利侵害がないこと、第三者の権利を侵害していないことにつき何ら保証するものではありません。</p>
				<p>5 当社は、本サービス上の対象コンテンツ、会員情報その他公開、閲覧できる情報、提供される情報等の一切において、その全部または一部が破損、毀損、滅失した場合においても何ら責任を負わないものとします。</p>
				<p>6 当社は、会員等が本サービスを利用することにより他人との間で生じた紛争等に関して、一切責任を負わないものとします。</p>
				<p>7 天災、事変その他の非常事態により会員等に生じた損害につき、当社は一切責任を負わないものとします。</p>
				<p>8 当社は、本規約に定めるほか、当社の責に帰すべき事由により、本サービスの提供において会員等が損害を被った場合、直接かつ通常生ずべき損害の範囲に限り、その損害を賠償するものとし、間接損害、特別損害（予見すべき事情の有無を問わない）、逸失利益、機会損失その他の損害に係る一切の賠償責任を当社は負わないものとします。</p>
				<p>9 本条その他本規約の規定において、当社の責に帰すべき事由における当社の損害賠償責任の全部または一部を免責する規定は、当社の故意又は重過失による場合には、適用されません。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（知的財産権）</h4>
				<p>第13条 本サービスに係る知的財産権は、当社または権利者である第三者に帰属するものであり、会員等に一切の権利、使用許諾権その他権利の付与を行うものではありません。</p>
				<p>2 本サービスにおける会員等の書き込み、添付データ等に係る文書、写真、画像、音声その他の情報に係る知的財産権は当該会員等に帰属するものとし、会員等はこれらの知的財産権につき、別途権利者がいる場合、会員等の責任と負担において必要な使用許諾、手続き、処理を行っていただきます。なお、本サービスの運営、管理、更新、編集、利用等において当社が行う一切の行為につき、会員等、権利者は自己の権利を行使（要求、請求、クレーム等を含む）してはならないものとします。</p>
				<p>3 会員等に帰属する知的財産権または会員等が管理者より同意得るべき知的財産権について、第三者との間で紛争、クレーム、交渉、協議等が乗じた場合においても、当社はこれらについて一切関知せず、責任を負わないものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（解除）</h4>
				<p>第14条　会員は、当社指定の方法より、本契約を解約することができます。ただし、会員が対象コンテンツについて、対象コンテンツの提供に係る契約を提供事業者との間で締結し、有効に存続している期間については、本契約は解約することができません。全ての対象コンテンツに係る契約を解約した後、別途当社が定める期間を経過した後に、当社は本項に基づく本契約の解約を受け付けるものとします。</p>
				<p>2 当社は、会員が以下のいずれかの事由に該当する場合、何ら催告を要さずに本契約を解除することができます。</p>
				<p>(1) 第10条（遵守事項）に違反した場合。</p>
				<p>(2) 対象コンテンツの料金その他債務の支払を現に怠り、または怠る虞があると当社が判断したとき。</p>
				<p>(3) その他本規約の定めに違反し、当該違反事由につき、解消されないものと当社で判断した場合。</p>
				<p>(4) 対象コンテンツに適用される契約内容に違反したことがあり、またはその虞があり、当社が本サービスの提供が望ましくないものと判断したとき。</p>
				<p>(5) 本契約の申し込みの際に虚偽の事実を通知したことが判明したとき。</p>
				<p>(6) 第11条（本サービスの中止）に基づく本サービスに係る制限、中止が回復しない場合。</p>
				<p>(7) 一定の期間、本サービス、対象コンテンツの利用がないものと当社にて判断した場合。</p>
				<p>(8) 会員が暴力団、暴力団員、暴力団関係者、暴力団関係団体、関係者、その他反社会的勢力、公共の福祉に反する活動を行う団体、およびその行為者である場合、または、反社会的勢力であったと判明した場合。</p>
				<p>(9) 経営上、技術上その他の理由で本サービスを提供することが著しく困難になった場合</p>
				<p>3 当社は、本契約が解約、解除その他の理由により終了した場合、会員に係る一切の情報、データ、記録等につき、すべて削除するものとし、会員はこれに一切異議を唱えないものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（秘密保持）</h4>
				<p>第15条　当社および会員は、本サービスの提供または本契約の締結により知り得た当社または会員の機密情報につき、本規約に特段の定めがある場合を除き、第三者（対象コンテンツの提供事業者を除く）に提供、開示、漏洩等してはならず、目的外の利用をしてはならないものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（個人情報の取扱い）</h4>
				<p>第16条　当社は、個人情報等（本契約の締結、本サービスの提供に関連して知り得た会員の個人情報であって、氏名、住所、電話番号、ＦＡＸ番号、メールアドレスその他特定の個人を識別することができる情報をいいます。会員が利用するサービスの情報を含みます。）を、本サービスの提供に利用するほか、別途当社が掲げる利用目的に記載の範囲で利用します。</p>
				<p>2 当社は個人情報等の扱いにつき、本規約にて特段の定めがあるほか、法令の定めを遵守して取り扱うものとします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（準拠法、管轄）</h4>
				<p>第17条 本サービス、本規約に基づく取引は、日本法を準拠法として適用するものとし、本サービス、本規約に係る一切の紛争につき、訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
				<p><br /></p>
				<h4 class="underlayer-h4">（附則）</h4>
				<p>1.本規約は2022年8月29日より効力を生じます。</p>
				<p>2.本規約は2023年11月8日より改正します。</p>
			</div>
      <!-- /blc -->
      <!-- blc -->
      <div class="blc" v-if="$route.query.gamewith!='true'">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onTopPage()">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Terms コンポーネント */
export default defineComponent({
  name: 'terms',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isRouting: false, // onTopPage()が実行された後、router.pushが実行されるまで用
  }),
  methods: {
    async onTopPage() {
      if (this.isRouting) {
        return;
      }

	  this.isRouting = true;
      await this.$router
        .push('/')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isRouting = false;
        });
    }
  }
});
</script>
