import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import store from '@/store';

export class UcomConnectixStatus {
  /**
   * Connectix契約済みかどうか
   */
  public static isContracted(): boolean {
    const ucomCustomer: UcomCustomerResponse | null = store.getters['ucomCommonStore/customer'];
    if (ucomCustomer instanceof UcomCustomerResponse) {
      if (ucomCustomer.connectix_status) {
        return true;
      }
    }
    return false;
  }

  /**
   * Connectix提供物件かどうか
   */
  public static isProvided(): boolean {
    const ucomProperty: UcomPropertyResponse | null = store.getters['ucomCommonStore/property'];

    if (ucomProperty instanceof UcomPropertyResponse) {
      // Connectix提供物件かどうか (UCOM物件基本情報APIのレスポンス.connectix_start_date に日付が存在するとき、Connectix提供物件と判定する)
      if (ucomProperty.connectix_start_date) {
        return true;
      }
    }
    return false;
  }

  /**
   * Connectix申し込み可能かどうか
   *
   * Connectix申し込み可能なとき
   *  ・ UCOMの物件と紐づいている
   *  ・ UCOMお客様番号連携連携済み
   *  ・ Connectix提供物件である
   *  ・ Connectix未契約である
   *  ・ UCOM会員ステータス: 会員 or 退会申し込み中
   */
  public static canApply(): boolean {
    const member: Member = store.getters['memberStore/member'];
    const property: Property | null = store.getters['propertyStore/property'];

    if (property?.uaType === UA_TYPE.UCOM && member.primaryKeyUcom) {
      if (this.isProvided() && !this.isContracted()) {
        const memberStatus: MemberStatus = store.getters['memberStore/memberStatus'];
        const ucomCustomer: UcomCustomerResponse | null = store.getters['ucomCommonStore/customer'];

        if (ucomCustomer instanceof UcomCustomerResponse) {
          const ispMemberStatus = getIspMemberStatusUcom(memberStatus, ucomCustomer);
          if (ispMemberStatus === ISP_MEMBER_STATUS.UCOM.MEMBER || ispMemberStatus === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
