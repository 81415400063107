import { McloudLinkingInput } from '../../shared/classes/auth-manegement/mcloud-linking-input';
import { Module } from 'vuex';
import { RootState } from '../index';
import { AuthManegementSharedErrorResponse } from '../../shared/classes/auth-manegement/shared-error-response';
import { AuthManagementApiService } from '../../shared/services/auth/auth-management-api-service';
/** Mcloud アカウント連携 State */
const mcloudLinkingState: {
  mcloudLinkingInput: McloudLinkingInput | null;
} = {
  mcloudLinkingInput: null
};

/** State の型 */
type McloudLinkingState = typeof mcloudLinkingState;

/** Mcloud 共通系 Store */
export const mcloudLinkingStore: Module<McloudLinkingState, RootState> = {
  namespaced: true,
  state: mcloudLinkingState,
  actions: {
    /**
     * アカウント連携 actions
     * @param context
     * @return mcloudLinkingInput アカウント情報
     */
    async updateLinking(context, mcloudLinkingInput: McloudLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
      context.commit('updateLinking', mcloudLinkingInput);
      /** API コールする */
      return await AuthManagementApiService.linkingToMcloud(mcloudLinkingInput);
    }
  },
  mutations: {
    updateLinking: (state, value: McloudLinkingInput) => (state.mcloudLinkingInput = value)
  },
  getters: {
    updateLinking: (state): McloudLinkingInput | null => state.mcloudLinkingInput
  }
}
