import { UCOM_PAYMENT_STATUS } from '@/shared/const/ucom/ucom-payment-status';
import { UCOM_PAYMENT_METHOD_ID } from '@/shared/const/ucom/ucom-payment-method-id';

/**
 * UCOM API : お支払い情報登録状態取得API : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomPaymentStatusResponseResult {
  /** 結果値 */
  public result!: UcomPaymentStatusResponse;

  constructor(fields: Partial<UcomPaymentStatusResponseResult>) {
    Object.assign(this, fields);
  }
}

/** UCOM API : お支払い情報登録状態取得API : レスポンス 本体 */
export class UcomPaymentStatusResponse {
  /** お支払方法登録状態 */
  public readonly payment_status!: UCOM_PAYMENT_STATUS;
  /** 支払方法ID */
  public readonly payment_method_id!: UCOM_PAYMENT_METHOD_ID;
  /** カード会員ID */
  public readonly card_account_id!: string;

  constructor(fields: Partial<UcomPaymentStatusResponse>) {
    Object.assign(this, fields);
  }
}
