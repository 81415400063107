import dayjs from 'dayjs';
import axios from 'axios';
import {AuthService} from '@/shared/services/auth/auth-service';
import {EMansionMbCapacityDto, EMansionMbCapacityUpdateResponseDto,} from '@/shared/classes/spf-api/e-mansion-mail';
import {BaseApiService} from '@/shared/services/api/base-api-service';
import {JudgeError} from "@/infra/accessor/spf/common/judge-error";

const api = BaseApiService.api();
const rawApi = axios.create({baseURL: process.env.VUE_APP_API_ENDPOINT_URL});

/** 内部 API をコールするサービス */
export class SpfApiMailBoxAccessor {

    /**
     * e-mansion メールボックス容量を取得する
     * @return EMansionMbCapacityDto メールボックス容量
     */
    public static async findEMansionMbCapacity(): Promise<EMansionMbCapacityDto> {
        const id = await AuthService.getMemberId();
        const date = dayjs(new Date()).format('YYYY/MM/DD');
        const response = await api.get<EMansionMbCapacityDto>(`/mb-youryou/e-mansion/joukyou/${id}`, {params: {date}})
            .catch(async (error) => {
                await JudgeError.judgeError(error);
                throw error;
            });
        return response.data;
    } // findEMansionMbCapacity

    /**
     * メールボックス容量を更新する
     * @return メールアドレス, メッセージ
     */
    public static async updateMbCapacity(emailAddressId: number): Promise<EMansionMbCapacityUpdateResponseDto> {
        const id = await AuthService.getMemberId();
        const date = dayjs(new Date()).format('YYYY/MM/DD');
        const response = await api.post<EMansionMbCapacityUpdateResponseDto>(`/mb-youryou/e-mansion/koushin`, {
            id,
            emailAddressId,
            date
        }).catch(async (error) => {
            await JudgeError.judgeError(error);
            throw error;
        });
        return response.data;
    } // updateMbCapacity

}
