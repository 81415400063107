<template>
  <div class="ucom-connectix-confirm">
    <LoadingComponent v-if="isMounting || isSubmitting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み 確認</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス Connectix お申し込み</li>
        <li>お申し込み内容の確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix&thinsp;お申し込み内容の確認</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" v-bind:isOneSentence="isErrorMessageOneSentence" />

        <!-- Connectix申し込みAPIエラー時メッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-inquiry-component v-if="showConnectixApiError" v-bind:errorMessagesInquiry="errorMessagesInquiry" v-bind:errorMessageInquiry="errorMessageInquiry" />
        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p class="red">ご指定のお部屋番号に本サービスを適用します。</p>
          <table class="table-type2">
            <tr>
              <th>マンション名</th>
              <td>{{ buildingName }}</td>
            </tr>
            <tr>
              <th>部屋番号</th>
              <td>{{ roomNumber }}</td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th>料金（月額）</th>
              <td>
                <span class="o">{{ connectixUsageFee }}</span>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て新税率に基づく税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お支払い方法</h3>

          <div v-if="isRegisteredOtherPaymentMethod">
            <!-- クレジットカード以外のお支払い情報が登録済の場合 -->
            <p>ご登録のお支払い方法により、お支払いいただきます。</p>
          </div>

          <div v-else>
            <credit-card-component
              v-if="isMounted"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:ispProviderCompanyName="ispProviderCompanyName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
            />
          </div>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt">ご確認の上、よろしければ「お申し込みを確定する」ボタンを押してください。完了ページに進みます。修正がある場合は、「戻る」ボタンを押してください。</p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" type="button" v-on:click="onApply()" :disabled="isDisabledButton">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

div.btn-area {
  margin: 30px auto 0;
  text-align: center;
  & button:last-child {
    margin-top: 16px;
  }
}

button.btn05 {
  border: 1px solid #cacaca;
  background-color: #ffffff;
  color: #cacaca;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
.underlayer-main h1 {
  padding: 10px 0 10px 160px;
  display: inline;
  background-image: url('../../../images/logo-ucom.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** Connectixお申し込みAPIエラー時メッセージ用コンポーネント */
import ErrorMessagesInquiryComponent from '@/shared/components/error-messages-inquiry-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
/** サービス UCOM Connectix 外部 API をコールするサービス */
import { UcomConnectixExternalApiService } from '@/shared/services/external-api/connectix/ucom/ucom-connectix-external-api-service';
/** Entity UCOM Connectix API : Connectix お申し込み : リクエスト */
import { UcomConnectixNewConnectixRequest } from './classes/external-api/new-connectix-request';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { UcomConnectixErrorResponse } from './classes/external-api/connectix-error-response';
/** Entity UCOM 共通利用 API : 契約基本情報取得 : レスポンス */
import { UcomCustomerResponse } from '../../../shared/classes/external-api/ucom/customer-response';
/** Entity UCOM 共通利用 API : 物件基本情報取得 : レスポンス */
import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { UcomExternalApiService } from '@/shared/services/external-api/ucom-external-api-service';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { Member } from '@/shared/classes/spf-api/member';
import { UcomPaymentStatusResponse } from '@/pages/ucom/classes/external-api/payment-status-response';
import { UcomErrorResponse } from '@/pages/ucom/classes/external-api/error-response';
import { UCOM_PAYMENT_METHOD_ID } from '@/shared/const/ucom/ucom-payment-method-id';
import { UCOM_PAYMENT_STATUS } from '@/shared/const/ucom/ucom-payment-status';
import { UcomConnectixErrorService } from '@/shared/services/ucom/ucom-connectix-error-service';
const defaultErrorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

/** UCOM光レジデンス Connectixお申し込み お申し込み確認画面 */
export default defineComponent({
  name: 'ucom-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** Connectixお申し込みAPIエラー時メッセージコンポーネント */
    ErrorMessagesInquiryComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件名 */
    buildingName: '',
    /** 部屋番号 */
    roomNumber: '',
    /** 物件ID */
    propertyId: '',
    /** 顧客ID */
    ucomMemberId: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** クレジットカード以外のお支払い方法登録有無(登録済か否か) */
    isRegisteredOtherPaymentMethod: false,
    /** クレジットカード情報の登録の必要有無 */
    needRegisteredCreditCard: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** Connectixお申し込みAPIエラー時メッセージ部に表示するタイトル */
    errorMessageInquiry: 'お申し込みが正常に完了しませんでした。恐れ入りますが、サポートセンターへお問合せください。',
    /** Connectixお申し込みAPIエラー時メッセージ */
    errorMessagesInquiry: [
      {
        header: 'UCOM光レジデンスサポートセンター',
        message1: '電話番号：0120-286-645/03-6823-0368',
        message2: '営業時間：年中無休　9:00-21:00',
        message2IsLink: false,
      },
      {
        header: '「UCOM光 レジデンス 建物専用サイト」でお問い合わせフォームからも承っております',
        message1: '▼お住いの建物名を検索',
        message2: `${process.env.VUE_APP_UCOM_URL}/search.html`,
        message2IsLink: true,
      },
    ],
    /** Connectixお申し込みAPIエラー時メッセージの表示/非表示 */
    showConnectixApiError: false,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンを押してください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** ボタン活性/非活性判定 */
    disableButton: false,
    /** クレジットカード分けのため */
    creditCardType: '',
    /** vueのmounted実行中かどうか */
    isMounting: true,
    /** ISP(ucom)会員ID */
    primaryKeyUcom: '',
    /** ISP(ucom)会員ステータス */
    ispMemberStatusUcom: '',
    /** Connectixサービスイン前エラー用に1文エラーにするかどうか */
    isErrorMessageOneSentence: false,
    /** ユーザーがUCOMへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面では UCOM光 レジデンス固定) */
    ispName: 'UCOM光 レジデンス',
    /** UCOMの提供元会社名を登録する。空文字の場合は"株式会社つなぐネットコミュニケーションズ"が表示される */
    ispProviderCompanyName: '',
    /** UCOMから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** UCOMから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // 物件基本情報をStoreから取得
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      this.primaryKeyUcom = member.primaryKeyUcom ?? '';

      // UCOMへのお支払い方法登録状態取得
      const paymentStatus: UcomPaymentStatusResponse | UcomErrorResponse = await UcomExternalApiService.getPaymentStatus(this.primaryKeyUcom);

      if (paymentStatus instanceof UcomErrorResponse) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PAYMENT_STATUS);
      }

      if (paymentStatus.payment_status === UCOM_PAYMENT_STATUS.REGISTERED) {
        await this.handleUcomRegisteredPaymentMethodInfo(paymentStatus);
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }

      // Auth0ユーザのapp_metadataからUCOMの物件IDを取得する
      this.propertyId = await AuthService.getUcomPropertyId();

      const property: UcomPropertyResponse | UcomSharedErrorResponse = this.$store.getters['ucomCommonStore/property'];
      if (property instanceof UcomPropertyResponse) {
        // 物件名
        this.buildingName = property.building_name;

        // サービス提供企業を設定
        this.creditCardType = property.service_provid_former.service_provid_former_id;
      } else if (property instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    }
    // 契約基本情報をStoreから所得
    try {
      // UCOM のメンバー ID をStoreから取得する
      this.ucomMemberId = await AuthService.getUcomMemberId();
      const customer: UcomCustomerResponse | UcomSharedErrorResponse = this.$store.getters['ucomCommonStore/customer'];
      if (customer instanceof UcomCustomerResponse) {
        // 正常ケースの場合
        // Connectixにお申し込み済みかどうかのチェック
        if (customer.connectix_status) {
          this.errorMessages.push('お客様は既にConnectixを契約しています。');
          return;
        }
        // Connectix利用金額
        this.connectixUsageFee = customer.connectix_price;
        // クレジットカード情報の登録有無(登録済みか否か)
        if (customer.is_register_credit_card) {
          // 登録済みの場合はConnectixお申し込みAPI実行時に登録不要とする
          this.needRegisteredCreditCard = false;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }

    this.isMounting = true;

    /**
     * .env.development, .env.staging, .env.production それぞれに次の環境変数を追加
     * 1. VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_TNC
     * 2. VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_ARTE
     */
    // サービス提供企業ID:1（株式会社つなぐネットコミュニケーションズ） => TNCのトークンAPIキーを利用
    if (this.creditCardType === '1') {
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_TNC;
    }

    // サービス提供企業ID:2（アルテリア・ネットワークス株式会社） => ARTE(大和)のトークンAPIキーを利用
    if (this.creditCardType === '2') {
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_ARTE;
      this.ispProviderCompanyName = 'アルテリア・ネットワークス株式会社';
    }

    // 入力画面の情報をStoreから取得
    const inputApply = this.$store.getters['ucomConnectixEntryStore/entryInputForm'];
    if (inputApply != null) {
      // 部屋番号
      this.roomNumber = inputApply.roomNumber;
    } else {
      // データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
    }
    this.isMounting = false;
    this.isMounted = true;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    /** 「お申し込み ➡」ボタン押下時 :  Connectixお申し込みAPIを実行し お申し込み完了画面に遷移する */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.isErrorMessageOneSentence = false;
      // Connectixお申し込みAPIエラー時メッセージ非表示
      this.showConnectixApiError = false;
      /**
       * 下記全ての条件を同時に満たす場合はエラー
       *
       * ・UCOMへクレジットカード以外のお支払い方法が登録されていない
       * ・クレジットカードトークンが生成されていない
       * ・UCOMに登録されたクレジットカード情報が存在しない
       * ・Portasからカード情報をコピーして申し込む同意がされていない
       **/
      if (
        !this.isRegisteredOtherPaymentMethod &&
        this.creditCardAccessToken === '' &&
        !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) &&
        !this.isAgreedCopyCardToIsp
      ) {
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }

      // ISP(ucom)側で退会申し込み中のとき、エラーメッセージを表示させる
      if (await this.isInCancelApplication()) {
        this.isErrorMessageOneSentence = true;
        this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        this.showErrorMessage();
        return;
      }

      // Connectixお申し込みAPIを実行
      const request = new UcomConnectixNewConnectixRequest({
        /** 物件ID */
        property_id: this.propertyId,
        /** 顧客ID */
        customer_id: this.ucomMemberId,
        /** 部屋番号 */
        room_number: this.roomNumber,
        /** クレジットカード登録有無(true:登録必要あり/false:登録必要なし) */
        is_register_credit_card: this.needRegisteredCreditCard,
        /** クレジットカードトークン */
        credit_card_token: this.creditCardAccessToken,
      });

      if (this.isAgreedCopyCardToIsp) {
        /** カードコピーに同意している場合のみ、カード会員IDを渡す */
        request.card_account_id = this.portasVeriTransId;
      }

      const resultCreateConnectix: undefined | UcomConnectixErrorResponse = await UcomConnectixExternalApiService.createConnectix(request);
      if (resultCreateConnectix instanceof UcomConnectixErrorResponse) {
        // UCOM Connectix申込API の エラーについて、業務エラーにしたいエラーだったとき
        if (UcomConnectixErrorService.isBusinessError(resultCreateConnectix)) {
          // 未開通エラー
          if (UcomConnectixErrorService.isNotYesOpenError(resultCreateConnectix)) {
            this.errorMessageTitle = defaultErrorMessageTitle;
            this.errorMessages.push(
              '只今、UCOM光 レジデンス会員登録のお手続き中のため、お申し込みいただけません。<br>\
              お客様情報の反映まで1時間～半日かかる可能性がございます。恐れ入りますがお時間を空けて再度お試しいただきますようお願いいたします。'
            );

            // クレジットカード起因のエラー
          } else if (UcomConnectixErrorService.isCreditCardError(resultCreateConnectix)) {
            this.errorMessageTitle = 'お支払い方法登録で問題が発生しました。';
            // クレカコピーのとき
            if (this.isAgreedCopyCardToIsp) {
              this.errorMessages.push('恐れ入りますがクレジットカードのご利用状況を確認の上、再度お試しいただきますようお願いいたします。');
            } else {
              this.errorMessages.push(
                '恐れ入りますが、入力されたクレジットカード詳細情報（クレジットカード番号・有効期限・カード名義人・セキュリティコード）をお確かめのうえ、再度お試しいただくか、別のクレジットカードでお試しください。'
              );
            }
          }
          this.showErrorMessage();
          return;
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONNECTIX);
        }
      } else {
        // 正常ケースの場合
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/connectix/ucom/completed').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/connectix/ucom/apply')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    async isInCancelApplication() {
      const customer: UcomCustomerResponse | UcomSharedErrorResponse = this.$store.getters['ucomCommonStore/customer'];

      if (customer instanceof UcomCustomerResponse) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

        /**
         * ISP(ucom)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
         * 1. ISP(ucom)会員ステータス: 退会申し込み
         * 2. ISP(ucom)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }

      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /**
     * UCOMに登録されたお支払い方法の種類に応じて、クレジットカードコンポーネントへ渡すお支払い方法情報を処理する
     */
    async handleUcomRegisteredPaymentMethodInfo(paymentStatus: UcomPaymentStatusResponse) {
      const paymentIdsWithoutCreditCard = [UCOM_PAYMENT_METHOD_ID.ACCOUNT_TRANSFER, UCOM_PAYMENT_METHOD_ID.ACCOUNT_WIRE_TRANSFER, UCOM_PAYMENT_METHOD_ID.POST_OFFICE];

      if (paymentStatus.payment_method_id === UCOM_PAYMENT_METHOD_ID.CREDIT_CARD && paymentStatus.card_account_id) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(paymentStatus.card_account_id);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;

        /**
         * 支払方法IDが以下いずれかの場合、クレジットカード申請フォームおよびUCOMで登録されたカード情報を表示しない
         * 2: 口座振替
         * 3: 口座振込
         * 4: 郵便局
         */
      } else if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === paymentStatus.payment_method_id)) {
        this.isRegisteredOtherPaymentMethod = true;
      } else if (paymentStatus.payment_method_id === UCOM_PAYMENT_METHOD_ID.NOT_REGISTERED) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PAYMENT_STATUS_DATA_INCONSISTENT);
      }
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.isRegisteredOtherPaymentMethod) {
        return false;
      }
      if (this.maskedCardNumberFromIsp && this.cardExpiredFromIsp) {
        return false;
      }
      if (!this.isExpiredPortasCreditCard && this.isAgreedCopyCardToIsp) {
        return false;
      }
      return this.creditCardAccessToken === '';
    },
  },
});
</script>
