import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78b84746"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "permission-link"
}
const _hoisted_2 = {
  key: 1,
  class: "disabled"
}
const _hoisted_3 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            (!_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.to
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true),
            (_ctx.disabled)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ]))
    : _createCommentVNode("", true)
}