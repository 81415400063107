// 残工事有無確認APIレスポンス
export class RemainingWorkConfirmDto {
  /** 残工事有無
   * 0:なし 1:あり
   */
  public isRemainingWork!: string | null;

  constructor(partial: Partial<RemainingWorkConfirmDto>) {
    Object.assign(this, partial);
  }
}
