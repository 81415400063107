/** Platform 支払方法登録・サービス同時お申し込み API送信用 */
export class ContractFromCreatePaymentMethodRequest {
  /** クレジットカードトークン */
  public readonly creditCardToken!: string;
  /** クレジットカードトークン有効期限 */
  public readonly tokenExpireDate!: string;
  /** 申込対象商品 IDの配列 */
  public readonly productIdArray!: string[];
  /** お申し込みボタンが押下された日時 */
  public readonly contractAppliedAt!: string;
  /** 商品テーブルにIDが存在しない商品名の配列（ex. Connectix） */
  public readonly productNameArray!: string[];

  constructor(fields: Required<ContractFromCreatePaymentMethodRequest>) {
    Object.assign(this, fields);
  }
}
