import { TransitionSourceServiceType } from '@/shared/const/service-type';

/** 物件 */
export class ParameterDecisionProperty {
  /** 遷移元サービス物件ID */
  public transitionSourceServiceId?: string;
  /** 遷移元サービス */
  public transitionSourceService?: TransitionSourceServiceType;
  /* 初回登録日時 */
  public createdAt?: Date;
  /* 最終更新日時 */
  public updatedAt?: Date;

  constructor(partial: Partial<ParameterDecisionProperty>) {
    Object.assign(this, partial);
  }
}
