/** AE05-005 ホームページ：ご解約確認_更新API リクエスト */
export class EMansionHomepageCancelUpdateRequest {
  readonly _eMansionAccountId!: number | undefined;
  readonly _eMansionHomepageId!: number | undefined;
  readonly _processingDate!: Date | undefined;

  /** コンストラクタ */
  constructor(partial: Partial<EMansionHomepageCancelUpdateRequest>) {
    Object.assign(this, partial);
  }

  public get eMansionAccountId(): number | undefined {
    return this._eMansionAccountId;
  }

  public get eMansionHomepageId(): number | undefined {
    return this._eMansionHomepageId;
  }

  public get processingDate(): Date | undefined {
    return this._processingDate;
  }
}
