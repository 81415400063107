import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { MemberStatus } from '../spf-api/member-status';

import store from '@/store';

export class DataDogRealUserMonitoring {
  private readonly environment: string;
  private readonly applicationId: string;
  private readonly clientToken: string;

  constructor() {
    this.environment = process.env.VUE_APP_ENVIRONMENT_FOR_DATA_DOG ?? '';
    this.applicationId = process.env.VUE_APP_APPLICATION_ID_FOR_DATA_DOG ?? '';
    this.clientToken = process.env.VUE_APP_CLIENT_TOKEN_FOR_DATA_DOG ?? '';
  }

  /**
   * 本番環境のみDataDog用の設定をONにする
   */
  public init(): void {
    if (this.environment === 'prod' || this.environment === 'mail-stg') {
      datadogRum.init({
        applicationId: this.applicationId,
        clientToken: this.clientToken,
        site: 'ap1.datadoghq.com',
        // service: 'portas-service-platform-frontend',
        env: this.environment,
        // version: 'Portasのアプリケーションのバージョン',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogLogs.init({
        clientToken: this.clientToken,
        site: 'ap1.datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
    }
  }

  public setUser(): void {
    if (datadogRum.getInternalContext()) {
      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];

      if (memberStatus) {
        datadogRum.setUser({
          id: memberStatus.primaryKeyAuth0,
          email: memberStatus.emailAddress,
        });
      }
    }
  }

  public isAlreadySetUser(): boolean {
    if (datadogRum.getInternalContext()) {
      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];

      if (memberStatus) {
        const user = datadogRum.getUser();

        if (user?.id === memberStatus.primaryKeyAuth0) {
          return true;
        }
      }
    }
    return false;
  }
}
