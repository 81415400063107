/** UCOMメールボックス容量変更 確認_更新 */
export class UcomMailboxCapacityConfirmInfo {
  private readonly _notifyMailAddress!: string; // 通知先メールアドレス
  private readonly _accountName!: string; // アカウント名
  private readonly _mailCapacity!: number; // メールボックス容量
  private readonly _changeCapacityLabel!: string; // 追加／縮小容量(label)
  private readonly _price!: number; // 単価
  private readonly _subscriptionDate!: Date; // お申し込み日(システム日付 YYYY/MM/DD)
  private readonly _serviceDescription!: string; // サービス説明
  private readonly _errorMessages: string[] | undefined; // エラーメッセージ

  constructor(
    notifyMailAddress: string,
    accountName: string,
    mailCapacity: number,
    changeCapacityLabel: string,
    price: number,
    subscriptionDate: Date,
    serviceDescription: string,
    errorMessages: string[] | undefined
  ) {
    this._notifyMailAddress = notifyMailAddress;
    this._accountName = accountName;
    this._mailCapacity = mailCapacity;
    this._changeCapacityLabel = changeCapacityLabel;
    this._price = price;
    this._subscriptionDate = subscriptionDate;
    this._serviceDescription = serviceDescription;
    this._errorMessages = errorMessages;
  }

  get notifyMailAddress(): string {
    return this._notifyMailAddress;
  }
  get accountName(): string {
    return this._accountName;
  }
  get mailCapacity(): number {
    return this._mailCapacity;
  }
  get changeCapacityLabel(): string {
    return this._changeCapacityLabel;
  }
  get price(): number {
    return this._price;
  }
  get subscriptionDate(): Date {
    return this._subscriptionDate;
  }
  get serviceDescription(): string {
    return this._serviceDescription;
  }
  get errorMessages(): string[] | undefined {
    return this._errorMessages;
  }
}
