import { SpfApiWebMailAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-web-mail-login-accessor';
import { EMansionWebMailLoginDto } from '@/shared/classes/spf-api/mail/model/e-mansion/web-mail/e-mansion-web-mail-login-dto';
/**
 * Webメールのリンククリック時の処理
 */
export class XmailEMansionWebMailLoginService {
    public static async xmailEMansionWebMailLogin(eMansionMailAddressId:number):Promise<EMansionWebMailLoginDto>{
        // Webメールにログインする内部手続きを行い、ログイン用のURLを取得する
        const response:EMansionWebMailLoginDto = await SpfApiWebMailAccessor.webMailLogin(eMansionMailAddressId);
        return response;
    }
}