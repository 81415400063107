export const UCOM_IS_OLD_DOMAIN = {
  NEW_DOMAIN: '0',//新ドメイン
  OLD_DOMAIN: '1',//旧ドメイン
} as const;
export type UCOM_IS_OLD_DOMAIN = typeof UCOM_IS_OLD_DOMAIN[keyof typeof UCOM_IS_OLD_DOMAIN];

export const convertUcomIsOldDomain = (value: number): UCOM_IS_OLD_DOMAIN | undefined => {
  for (const v of Object.values(UCOM_IS_OLD_DOMAIN)) {
      if (Number(v) == value) {
          return v;
      }
  }
}