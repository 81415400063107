export class UcomMailPackUpdateInfo{
    readonly _mailAddress: string | undefined;
    get mailAddress(): string | undefined {
        return this._mailAddress;
    }
    readonly _message: string | undefined;
    get message(): string | undefined {
        return this._message;
    }
    
  constructor(partial: Partial<UcomMailPackUpdateInfo>) {
    Object.assign(this, partial);
  }
}