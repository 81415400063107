/**
 * レンタルBBR申し込みAPIのレスポンスクラス
 */
export class UcomRentalServiceUpdateInfo{

  /** 送信先メールアドレス */
  readonly _notificationMailAddress: string[] | undefined;
  /** エラーメッセージ */
  readonly _message: string | undefined;

  get mailAddress(): string[] | undefined {
      return this._notificationMailAddress;
  }
  get message(): string | undefined {
      return this._message;
  }
  
  constructor(partial: Partial<UcomRentalServiceUpdateInfo>) {
    Object.assign(this, partial);
  }
}