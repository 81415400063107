export class subAccountCancelInfo {
  readonly _accountName!: string | undefined;
  public get accountName(): string | undefined {
    return this._accountName;
  }
  readonly _mailAddress!: string | undefined;
  public get mailAddress(): string | undefined {
    return this._mailAddress;
  }
  readonly _cancelOn!: Date | undefined;
  public get cancelOn(): Date | undefined {
    return this._cancelOn;
  }
  readonly _errorMessage!: string[] | undefined;
  public get errorMessage(): string[] | undefined {
    return this._errorMessage;
  }
  constructor(partial: Partial<subAccountCancelInfo>) {
    Object.assign(this, partial);
  }
}
