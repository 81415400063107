<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールサービス購入 入力</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>メールサービス購入</li>
      </ul>

      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールパック購入 入力 -->
      <div v-if="isPayMent">
        <div class="blc">
          <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールパック購入" />メールサービス購入</h2>
          <p>サービス内容をご確認いただき、「購入」ボタンを押してください。</p>
          <error-messages-component v-bind:errorMessages="messageList" v-bind:errorMessageTitle="errorMessageTitle" />

          <div class="sblc">
            <h3 class="service-h3">サービス内容</h3>
            <table class="table-type2">
              <tr>
                <th class="va-middle">商品名</th>
                <td>{{ mailPackInfo?.productName }}</td>
              </tr>
              <tr>
                <th class="va-middle">現在のご契約状態</th>
                <td>
                  <span>{{ strContractStatus }}</span>
                  <p class="red" v-if="mailPackInfo?.isMax">{{ mailPackInfo.maxMessage }}</p>
                </td>
              </tr>
              <tr>
                <th class="va-middle">月額利用料</th>
                <td>
                  <span class="o" v-if="monthlyFee">{{ monthlyFee }} 円 （税込）</span>
                </td>
              </tr>
              <tr>
                <th class="va-middle">サービス説明</th>
                <td>
                  <span v-html="mailPackInfo?.serviceDescription"></span><br />UCOM 光 レジデンス ISP 会員付加サービス利用規約については<a
                    :href="termsUrl"
                    target="_blank"
                    class="link"
                    >こちら</a
                  >からご確認ください。
                </td>
              </tr>
              <tr>
                <th class="va-middle">サービス説明への同意<span class="req">必須</span></th>
                <td>
                  <p class="o">お申し込みの前にサービス説明を必ずお読みになり、「上記の内容に同意する」にチェックしてください。</p>
                  <label> <input type="checkbox" required v-model="check" @change="judgeIsActiveButton(check)" />上記の内容に同意する </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- /blc -->

        <!-- blc -->
        <div class="blc">
          <p class="form-btn-txt">「購入」を押してください。確認画面へ進みます。</p>
          <div class="btn-area">
            <button class="btn btn01 bs" v-if="isActiveButton" @click="onClick()">購入<i class="material-icons link link-icon">east</i></button>
            <button class="btn btn05 bs" v-else disabled>購入<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="blc">
          <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お支払い方法未登録" />お支払い方法未登録</h2>
          <p>
            お支払方法が未登録のため、サービスの購入ができません。<br />
            <u>
              <a :href="paymentUrl" class="payment-url" target="_blank" rel="noopener noreferrer"> こちら </a>
            </u>
            からお支払方法の登録を行ってください。
          </p>
          <p>
            お支払い方法登録後、ブラウザの戻るボタンでUCOM光レジデンス会員専用トップに戻り<br />
            再度購入ご希望のサービスをご選択ください。
          </p>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiMailPackAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-pack-accessor';
import { UcomMailPackInfo } from '@/shared/classes/spf-api/model/ucom/ucom-mail-pack-info';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UCOM_PROPERTY_SERVICE_PROVID_FORMER } from '@/shared/const/ucom/ucom-property-service-provid-former';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'inputMailPack',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: null as null | UcomMailPackInfo,
    check: false,
    strContractStatus: '',
    messageList: [] as string[],
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    monthlyFee: '',
    isPayMent: true,
    isActiveButton: false,
    paymentUrl: process.env.VUE_APP_UCOM_MY_URL + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
    termsUrl: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //Portas会員IDを取得
      const memberId = this.$store.getters['memberStore/member'].id;
      //メールサービスのご契約状況をAPI(AU08-001)より取得する
      this.mailPackInfo = await SpfApiMailPackAccessor.getUcomMailPackInfo(memberId);
      if (this.mailPackInfo.paymentStatus == false) {
        this.isPayMent = false;
        this.isLoading = false;
        return;
      }
      this.monthlyFee = Number(this.mailPackInfo.monthlyFee).toLocaleString();
      if (this.mailPackInfo.errorMessage) {
        this.messageList.push(this.mailPackInfo.errorMessage);
      }
      //表示するための文言に変換
      this.strContractStatus = this.mailPackInfo.contractStatus?.value ? this.mailPackInfo.contractStatus?.value : '';
      //規約URLセット
      this.termsUrl = this.setTermsUrl();
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    //購入ボタン
    async onClick() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //バリデーションチェック
      if (!this.check) {
        this.messageList = ['「サービス説明への同意」の「上記の内容に同意する」をチェックしてください。'];
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      //次画面への項目受け渡し
      this.$store.commit('ucomMailPackStore/productName', this.mailPackInfo?.productName);
      this.$store.commit('ucomMailPackStore/monthlyFee', this.mailPackInfo?.monthlyFee);
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      this.$store.commit('ucomMailPackStore/processDate', now);
      this.$store.commit('ucomMailPackStore/serviceDescription', this.mailPackInfo?.serviceDescription);
      this.$store.commit('ucomMailPackStore/isActiveButton', this.isActiveButton);
      //次画面に遷移
      await this.$router.push('/ucom/mail-pack/confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    judgeIsActiveButton(isCheck: boolean) {
      if (this.mailPackInfo?.isMax) {
        this.isActiveButton = false;
        return;
      }
      if (!isCheck) {
        this.isActiveButton = false;
        return;
      }
      this.isActiveButton = true;
    },
    /**
     * サービス規約のURLをセット
     */
    setTermsUrl(): string {
      //物件基本情報
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty?.service_provid_former.service_provid_former_id == UCOM_PROPERTY_SERVICE_PROVID_FORMER.TNC.id) {
        return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_TNC;
      }
      return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_ARTERIA;
    },
  },
});
</script>
<style>
.payment-url {
  color: red;
  text-decoration: underline;
}
</style>
