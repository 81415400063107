<template>
  <div class="error-messages-component">
    <div class="err">
      <div>
        <p class="red">{{ errorMessageInquiry }}</p>
      </div>
      <div>        
        <ul class="ul-basic01">
            <li v-for="errorMessage in errorMessagesInquiry" :key="errorMessage.header">
              {{ errorMessage.header }}
              <p>{{ errorMessage.message1 }}</p>
              <a v-if="errorMessage.message2IsLink" :href="errorMessage.message2">{{ errorMessage.message2 }}</a>
              <p v-else>{{ errorMessage.message2 }}</p>
            </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/** エラーメッセージ表示エリアコンポーネント */
export default defineComponent({
  name: 'error-messages-inquiry-component',
  // 親コンポーネントから受け取るデータ
  props: {
    /** エラーメッセージ部に表示するタイトル */
    errorMessageInquiry: {
      type: String,
      default: ''
    },
    /** 表示したいエラーメッセージを格納した配列 */
    errorMessagesInquiry: {
      type: Array,
      default: Array
    }
  }
});
</script>
