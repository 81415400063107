<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メインメールアドレス</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion/main-mail-address/list">メインメールアドレス</router-link>
        </li>
        <li>解約確認</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メインメールアドレス解約" />ご解約確認</h2>
        解約するメールアドレスをご確認ください。
        <error-messages-component v-bind:errorMessages="errMsg" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <table class="table-type1">
        <tr>
          <th class="va-middle">
            <p>
              ご解約対象<br />
              メールアドレス
            </p>
          </th>
          <td>{{ mailAddress }}</td>
        </tr>
      </table>

      <div class="sblc">
        <div class="sblc">
          <h3 class="service-h3">ご解約に関する注意事項</h3>
          <ul class="ul-basic01">
            <li>メールアドレスは下記タイミングでご利用できなくなります。</li>
            <ul class="ul-basic01">
              <li>1日～20日までに解約登録された場合:当月末日で解約</li>
              <li>21日～月末までに解約登録された場合:翌月末日で解約</li>
            </ul>
            <li>
              <font class="red"> 解約したメールアドレスは元に戻すことができません。</font>
            </li>
            <li>メールアドレスに付随しているサービスは同時にサービスが終了します。</li>
            <li>
              <font class="red">【重要！】メールアドレス終了までに必ずメール受信を行ってください。</font>解約前に届いていて受信しなかったメールは、解約後に確認できなくなります。
            </li>
          </ul>
        </div>
      </div>

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
          <p class="form-btn-txt">ご確認の上、よろしければ「解約」をクリックしてください。</p>
          <br />
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goNext()">解約<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionAccount } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account';
import { EMansionMainMailAddressCancel } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-cancel';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'cancel-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    isLoading: true,
    accountInfo: null as EMansionAccount | null,
    mailAddress: '',
    err: false,
    errMsg: new Array<string>(),
    memberInfo: null,
    propertyInfo: null as Property | null,
    date: '',
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      //Storeからメールアドレスを取得
      this.mailAddress = this.$store.getters['eMansionMainMailAddressStore/mailAddress'];
      if (!this.mailAddress) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/main-mail-address/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      //StoreからアカウントIDを取得
      this.accountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'].eMansionAccountInfo;
      //Storeから物件IDを取得
      this.propertyInfo = this.$store.getters['propertyStore/property'];

      this.isLoading = false;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**日付をyyyy/mm/ddの形式にする
     * @param date Date型の日付
     * @return yyyy/mm/ddの形式の文字列
     */
    formatDate(date: Date): string {
      const y = date.getFullYear();
      const m = ('00' + (date.getMonth() + 1)).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },

    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },

    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/main-mail-address/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },

    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        this.date = this.formatDate(await SpfApiServerCommonUtilAccessor.getServerTimeNow());

        //AE10-005を実行
        const mainMailCancelResponse: EMansionMainMailAddressCancel = await SpfApiMainMailAddressAccessor.cancelMainMail(
          this.mailAddress,
          this.accountInfo?.eMansionAccountId ? String(this.accountInfo?.eMansionAccountId) : '',
          this.propertyInfo?.id ? String(this.propertyInfo?.id) : '',
          this.date
        );

        if (mainMailCancelResponse.message) {
          this.errMsg = mainMailCancelResponse.message;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        //解約対象メールアドレスをStoreに登録
        this.$store.commit('eMansionMainMailAddressStore/mailAddress', mainMailCancelResponse.mailAddress);
        //サービス終了日をStoreに登録
        this.$store.commit('eMansionMainMailAddressStore/cancelOn', mainMailCancelResponse.finishDate);
        //解約完了ページへ移動
        await this.$router.push('/e-mansion/main-mail-address/cancel-complete').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.isLoading = false;
    },
  },
});
</script>
