<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>アカウントID管理　一覧</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>アカウントID管理　一覧</li>
      </ul>
      <!-- /breadcrumb -->
      <div class="blc" v-if="!$data.accountManagementList.hasAccount">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID一覧" />アカウントID管理　一覧</h2>
        アカウントIDの登録／変更／削除が行えます。<br /><br />
        アカウントIDが登録されていません。<br />
        <button class="btn btn01 bs" v-on:click="onCreate()">新規登録<i class="material-icons link link-icon">east</i></button>
      </div>
      <div class="blc" v-if="$data.accountManagementList.hasAccount">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID一覧" />アカウントID管理　一覧</h2>
        <p>アカウントIDの登録／変更／削除が行えます。</p>
        <br />
        <div v-if="!$data.accountManagementList.isFullAccount">
          <button class="btn btn01 bs" v-on:click="onCreate()">新規登録<i class="material-icons link link-icon">east</i></button>
        </div>
        <div v-if="$data.accountManagementList.isFullAccount">
          <button class="btn btn05 bs" disabled>新規登録<i class="material-icons link link-icon">east</i></button>
          <p class="red mt20">アカウントIDの登録数が上限に達しているため、登録できません。</p>
        </div>

        <table class="table-type2">
          <tr class="account-info-title">
            <th class="va-middle">アカウントID</th>
            <th class="va-middle">氏名</th>
            <th class="va-middle">メールアドレス</th>
            <th class="va-middle">アカウント権限種別</th>
            <th class="va-middle">　</th>
          </tr>
          <tr v-for="info in $data.accountManagementList.accountInfoList" class="account-info-list">
            <td class="account-name">
              <font class="link-color"
                ><u v-on:click="onAccountId(info)">{{ info.accountName }}</u></font
              >
            </td>
            <td class="name">{{ info.familyName }}　{{ info.firstName }}</td>
            <td class="mail-address">
              <font class="link-color"
                ><u v-on:click="onMailAddress(info)">{{ info.mailAddress }}</u></font
              >
            </td>
            <td class="authorization">{{ info.accountAuthorization }}</td>
            <td class="account-delete-btn">
              <button class="btn btn04 bs" v-on:click="onDelete(info)">削除<i class="material-icons link link-icon">east</i></button>
            </td>
          </tr>
        </table>
        <div class="sblc">
          <h3 class="service-h3">アカウントIDでのPortas利用について</h3>

          <p>
            追加した「アカウントID」で、Portas登録も可能です。<br />
            「アカウントID」のPortas登録は、すでにご登録済みのPortasアカウントとは異なるログインアカウントでのPortas登録が必要です。<br />
            また、必ず、以下の「アカウントID専用連携ページ」からお手続きください。<br />
            <br />
            <a class="external_url" @click.prevent.stop.prevent="onPortasLinking()"><u>「アカウントID専用連携ページ」はこちら</u></a>
            <br />
          </p>
          <p class="emphasis">●「アカウントID専用連携ページ」に遷移する際は以下にご注意ください。</p>
          <p class="emphasis">【Googleアカウント、Yahoo!アカウントでPortasにログイン中のお客様】</p>
          「アカウントID専用連携ページ」で同種の別アカウントで新規登録をいただく際は、<b><u>新規登録の前に</u>各アカウントのログアウト</b>を行ってください。<br />
          <p>
            <a target="_blank" class="external_url" href="https://support.yahoo-net.jp/PccLogin/s/article/H000013196">＞Yahoo!アカウントをログアウトする</a
            >（別タブで表示されますので画面にしたがってログアウトしてください） <br />
          </p>
          <p>
            <a target="_blank" class="external_url" href="https://accounts.google.com/Logout">＞Googleアカウントをログアウトする</a
            >（別タブが表示され自動的にログアウトします）<br />
          </p>
          <p class="list-indent">
            GoogleChromeの場合、ブラウザにログイン中のGoogleアカウントもログアウトされます。<br />
            Googleの再ログインでお困りの場合は、お手数ですが、以下にてご確認ください。<br />
            <a target="_blank" class="external_url" href="https://support.google.com/accounts/answer/41078?hl=ja&co=GENIE.Platform%3DiOS&sjid=13800152530278787125-AP"
              >＞Googleアカウントヘルプ｜パスワードを変更または再設定する</a
            >
          </p>
          <br />
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { SpfApiAccountManagementAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-management-accessor';
import { UcomAccountManagementAccountList } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-account-list';
import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { TRANSITION_STORAGE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'list',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    accountManagementList: {},
    UNREGISTERED: '未登録',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const member: Member = this.$store.getters['memberStore/member'];

      //アカウントID管理情報をAPIより取得する
      this.$data.accountManagementList = await SpfApiAccountManagementAccessor.getAccountManagementList(member.id);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**新規登録ボタンクリック */
    async onCreate() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // 入力画面で入力中や既存アカウントの更新中であることの判定に使われているステートをストアから削除する
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);
      //前画面の情報をstoreに保存する
      this.$store.commit('ucomAccountManagementInputStore/before', '/ucom/account-management/list');

      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-management/input').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**アカウントID リンククリック */
    async onAccountId(accountInfo: UcomAccountManagementAccountList) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      /** 入力中判定となるステートをストアから削除する */
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      //押下された行のアカウントIDをstoreに保存する
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      //前画面の情報をstoreに保存する
      this.$store.commit('ucomAccountManagementInputStore/before', '/ucom/account-management/list');
      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-management/input').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**メールアドレス リンククリック */
    async onMailAddress(accountInfo: UcomAccountManagementAccountList) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountManagementStore/currentMailAddress', accountInfo.mailAddress);
      this.$store.commit('ucomAccountManagementStore/mailAddress', accountInfo.mailAddress != this.$data.UNREGISTERED ? accountInfo.mailAddress : '');
      this.$store.commit('ucomAccountManagementStore/mailAddressType', accountInfo.mailAddressType);
      this.$store.commit('ucomAccountManagementStore/isUpdate', accountInfo.mailAddress != this.$data.UNREGISTERED);
      this.$store.commit('ucomAccountManagementStore/accountName', accountInfo.accountName);

      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-option-change/mail-input').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**削除 ボタンクリック */
    async onDelete(accountInfo: UcomAccountManagementAccountList) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountManagementStore/deleteOn', new Date());
      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-management/delete').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**こちらリンククリック */
    async onPortasLinking() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      localStorage.removeItem(TRANSITION_STORAGE);
      await AuthService.logout(process.env.VUE_APP_UCOM_LOGIN_ACCOUNT_ID_USER);
      this.$data.isLoading = false;
    },
  },
});
</script>

<style scoped>
u:hover {
  cursor: pointer;
}
.link-color {
  color: #cf1225;
  text-decoration: underline;
  text-decoration-color: #cf1225;
}
.external_url {
  color: #cf1225;
  text-decoration: underline;
}
.emphasis {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.list-indent {
  padding-left: 1em;
  position: relative;
}
.list-indent::before {
  content: '＊';
  position: absolute;
  left: 0;
}
@media only screen and (max-width: 767px) {
  .account-info-title {
    display: none;
  }
  .account-info-list > td {
    display: flex;
  }
  .account-info-list > *::before {
    width: 50%;
    font-weight: bold;
  }
  .account-info-list > .account-name::before {
    content: 'アカウントID';
  }
  .account-info-list > .name::before {
    content: '氏名';
  }
  .account-info-list > .mail-address::before {
    content: 'メールアドレス';
  }
  .account-info-list > .authorization::before {
    content: 'アカウント権限種別';
  }
  .account-info-list > .account-delete-btn::before {
    content: '';
  }
}
</style>
