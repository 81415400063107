import { isNavigationFailure, NavigationFailureType } from 'vue-router';

/**
 * 遷移時のエラー判定をする
 * NavigationGuard系統は許容してエラーにしない（もみ消す）
 *
 * @param error: エラーオブジェクト
 * @throw NavigationFailureType.redirected/NavigationFailureType.duplicatedではない場合
 */
export function checkRouterError(error: unknown) {
  // ErrorTypes.NAVIGATION_GUARD_REDIRECT が 2 にあたる
  if (isNavigationFailure(error, 2) || isNavigationFailure(error, NavigationFailureType.duplicated)) {
    // 握りつぶす
    return;
  }
  throw error;
}
