export class TsunaguOnlineProductHtmlRequestDto {
  /**
   * スクレイピング処理するつなぐオンラインショップの商品のURL
   */
  public url!: string;

  /**
   * スクレイピング処理したつなぐオンラインショップの商品を表示するHTMLにて、
   * 買い物カゴを表示するかどうか
   *
   * 買い物カゴを表示しない: noDisplay
   * 買い物カゴを表示する: display
   */
  public displayShoppingCartButton!: string;

  constructor(partial: Partial<TsunaguOnlineProductHtmlRequestDto>) {
    Object.assign(this, partial);
  }
}

export class TsunaguOnlineProductHtmlResponseDto {
  public formattedHtml?: string;

  constructor(partial: Partial<TsunaguOnlineProductHtmlResponseDto>) {
    Object.assign(this, partial);
  }
}

export class TsunaguOnlineProductHtmlDto {
  private _url?: string;
  private _formattedHtml?: string;

  constructor(url: string, formattedHtml: string | undefined) {
    this._url = url;
    this._formattedHtml = formattedHtml;
  }

  get url(): string {
    if (this._url) {
      return this._url;
    }
    return '';
  }

  get formattedHtml(): string {
    if (this._formattedHtml) {
      return this._formattedHtml;
    }
    return '';
  }
}

export class TsunaguOnlineProductHtmlDtoList {
  public list?: TsunaguOnlineProductHtmlDto[];

  constructor() {
    this.list = new Array<TsunaguOnlineProductHtmlDto>();
  }

  public existsUrl(url: string): boolean {
    const found = this.list?.find((value) => value.url === url);

    if (found) {
      return true;
    }
    return false;
  }

  public addItem(item: TsunaguOnlineProductHtmlDto): void {
    this.list?.push(item);
  }

  public getItem(url: string): TsunaguOnlineProductHtmlDto | undefined {
    return this.list?.find((value) => value.url === url);
  }
}
