/**
 * UCOM Connectix 申込API を実行してエラーを受け取ったとき、業務エラーにしたい対象のエラーコード
 */
export const UCOM_CONNECTIX_BUSINESS_ERROR_CODE = ['UMPG211', 'UMPG216', 'UMPG217'];

/**
 * UCOM Connectix 申込API を実行してエラーを受け取ったとき、未開通エラーのコード
 */
export const UCOM_CONNECTIX_NOT_YET_OPEN_ERROR_CODE = ['UMPG211'];

/**
 * UCOM Connectix 申込API を実行してエラーを受け取ったとき、クレジットカード起因エラーのコード
 */
export const UCOM_CONNECTIX_CREDIT_CARD_ERROR_CODE = ['UMPG216', 'UMPG217'];
