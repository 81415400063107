<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>e-mansion ホームページ お申し込み確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion ホームページ" />ホームページ</h2>
        お申し込み内容をご確認ください。
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">ご希望URL</h3>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th><b>ご希望URL</b></th>
                  <td>
                    http://www.**.em-net.ne.jp/~<span class="red mb15"
                      ><b>{{ requestUrl }}</b></span
                    >/
                  </td>
                </tr>
              </tbody>
            </table>
            ※「**」部分は自動的に決まります。
          </div>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt" style="text-align: center">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt" style="text-align: center">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goApply()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { EMansionHomepageApplicationUpdateRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-application-update-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { convertHpCapacityByValue } from '@/shared/const/e-mansion/e-mansion-homepage-capacity';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'homepage-application-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    requestUrl: '' as undefined | string,
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    this.isLoading = true;

    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      this.requestUrl = await this.$store.getters['eMansionHomepageOpenStore/requestUrl'];

      // storeに情報が無ければ入力画面に遷移
      if (!this.requestUrl) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage/application/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページURLをstoreに保存する
      this.$store.commit('eMansionHomepageOpenStore/requestUrl', this.requestUrl);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage/application').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    async goApply() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
        const hpQuota = this.$store.getters['eMansionCommonStore/property'].op.hp.hp_quota; // ホームページ基本容量（50～500）
        // ホームページ容量を容量からID値に変換
        const homepageCapacity = convertHpCapacityByValue(hpQuota)?.id;
        if (!homepageCapacity) {
          throw new Error();
        }
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

        // AE05-001 ホームページ：お申し込み_入力チェックAPIの呼び出し
        const response = await SpfApiHomepageAccessor.callHomepageApplicationUpdate(
          new EMansionHomepageApplicationUpdateRequest({
            _portasId: this.$store.getters['memberStore/member'].id,
            _requestUrl: this.requestUrl,
            _processingDate: now.toLocaleDateString(),
            _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
            _homepageCapacity: homepageCapacity,
          })
        );
        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }

        // 次画面に値を設定
        this.$store.commit('eMansionHomepageOpenStore/ftpAccount', response.ftpAccount);
        this.$store.commit('eMansionHomepageOpenStore/ftpPassword', response.ftpPassword);
        this.$store.commit('eMansionHomepageOpenStore/ftpServerName', response.ftpServerName);
        this.$store.commit('eMansionHomepageOpenStore/fileDest', response.fileDest);
        this.$store.commit('eMansionHomepageOpenStore/homepageUrl', response.homepageUrl);

        // ホームページ：お申し込み完了画面に遷移する
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage/application-complete').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response?.status !== 503) {
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
