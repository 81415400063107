import store from '@/store';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { RouteLocationNormalized } from 'vue-router';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionCommonDataLoadService } from '@/shared/services/load/e-mansion-common-data-load-service';
import { UcomCommonDataLoadService } from '@/shared/services/load/ucom-common-data-load-service';

/**
 * PortasDBから取得する情報のうち、使用頻度が高い情報についてStoreへ保存する
 * Storeへ保存することで、DBからの取得回数を削減することを目指す
 */
export class PortasDBCacheWithStore {
  /**
   * PortasDBから取得する、会員情報, 会員ステータス情報, 物件情報 について store に保存する
   *
   * o: 既にストアに値が存在する
   * x: ストアの値がnull
   *
   * | member | memberStatus | property | dispatch                       |
   * | ------ | ------------ | -------- | ------------------------------ |
   * | o      | o            | o        | none                           |
   * | o      | o            | x        | property                       |
   * | o      | x            | o        | memberStatus                   |
   * | o      | x            | x        | memberStatus, property         |
   * | x      | o            | o        | member, property               |
   * | x      | o            | x        | member, property               |
   * | x      | x            | o        | member, memberStatus, property |
   * | x      | x            | x        | member, memberStatus, property |
   *
   * vue-router の beforeEach で呼び出して使う想定
   * 会員情報が変更されたとき、ストアの値を null にして、beforeEach で取得するように実装してください
   * 会員ステータスが変更されたとき、ストアの値を null にして、beforeEach で取得するに実装してください
   */
  public static async main(to: RouteLocationNormalized): Promise<void> {
    if (!this.hasCommonData(to.path)) {
      return;
    }

    const member: Member | null = store.getters['memberStore/member'];
    const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];
    const property: Property | null = store.getters['propertyStore/property'];

    // 特殊ケース-/platform/entry/input-emailへの遷移かつ、会員ステータスにレコードが無ければなにもしない
    if (to.path === '/platform/entry/input-email' && !memberStatus) {
      return;
    }

    // 会員の登録状況に合わせてPortasで使用する共通データをロードする
    await this.dispatchPortasCommon(member, memberStatus, property);

    if (await AuthService.isLinkedToEMansion()) {
      // e-mansionユーザーの共通情報をストア
      await EMansionCommonDataLoadService.loadInitialData(to);
      return;
    }
    if (await AuthService.isLinkedToUcom()) {
      // UCOMユーザーの共通情報をストア
      await UcomCommonDataLoadService.loadInitialData(to);
      return;
    }
  }

  private static hasCommonData(path: string): boolean {
    return !path.endsWith('/sub-account-linking');
  }

  /**
   * Portas共通のデータロード
   * @param member 会員情報
   * @param memberStatus 会員ステータス
   * @param property 物件情報
   * @returns 無し
   */
  private static async dispatchPortasCommon(member: Member | null, memberStatus: MemberStatus | null, property: Property | null): Promise<void> {
    if (member && memberStatus && property) {
      // 何もしない
      return;
    } else if (member && memberStatus && !property) {
      // 2. 物件
      await this.dispatchProperty();
    } else if (member && !memberStatus && property) {
      // 3. 会員ステータス
      await store.dispatch('memberStore/memberStatus');
    } else if (member && !memberStatus && !property) {
      // 4. 会員ステータス、物件
      await Promise.all([store.dispatch('memberStore/memberStatus'), this.dispatchProperty()]);
    } else if (!member && memberStatus && property) {
      // 5. 会員、物件
      await store.dispatch('memberStore/member').then(async () => {
        await this.dispatchProperty();
      });
    } else if (!member && memberStatus && !property) {
      // 6. 会員、物件
      await store.dispatch('memberStore/member').then(async () => {
        await this.dispatchProperty();
      });
    } else if (!member && !memberStatus && property) {
      // 7. 会員、会員ステータス、物件
      await this.dispatchAll();
    } else if (!member && !memberStatus && !property) {
      // 8. 会員、会員ステータス、物件
      await this.dispatchAll();
    }
  }

  private static async dispatchAll(): Promise<void> {
    await store.dispatch('memberStore/memberStatus');
    await store.dispatch('memberStore/member').then(async () => {
      await this.dispatchProperty();
    });
  }

  private static async dispatchProperty(): Promise<void> {
    const propertyId = store.getters['memberStore/member'] ? store.getters['memberStore/member'].propertyId : null;
    if (propertyId) {
      await store.dispatch('propertyStore/property', propertyId);
    } else {
      store.commit('propertyStore/property', null);
    }
  }
}
