/** UCOMウイルスチェック情報更新 一時停止 開始 レスポンス */
export class UcomVirusCheckPauseResponse {
  private readonly _errorMessage: string | undefined;

  constructor(errorMessage: string | undefined) {
    this._errorMessage = errorMessage;
  }

  public get errorMessage(): string | undefined {
    return this._errorMessage;
  }
}
