import { Module } from 'vuex';
import { RootState } from '../index';

import { McloudUserSessionResponse } from '../../shared/classes/external-api/mcloud/user-session-response';
import { McloudUserResponse } from '../../shared/classes/external-api/mcloud/user-response';
import { McloudApartmentResponse } from '../../shared/classes/external-api/mcloud/apartment-response';
import { McloudAdminNoticesResponse } from '../../shared/classes/external-api/mcloud/admin-notices-response';
import { McloudNoticesResponse } from '../../shared/classes/external-api/mcloud/notices-response';
import { McloudNoticesRequest } from '../../shared/classes/external-api/mcloud/notices-request';
import { McloudMineReservationsResponse, McloudMineReservationsResponseData } from '../../shared/classes/external-api/mcloud/mine-reservations-response';
import { McloudScheduleEventsRequest } from '../../shared/classes/external-api/mcloud/schedule-events-request';
import { McloudScheduleEventsResponse } from '../../shared/classes/external-api/mcloud/schedule-events-response';
import { McloudSharedExternalApiService } from '@/shared/services/external-api/mcloud-shared-external-api-service';
import { McloudSharedErrorResponse } from '../../shared/classes/external-api/mcloud/shared-error-response';

/** Mcloud 共通系 State */
const mcloudCommonState: {
  userSession: McloudUserSessionResponse | null;
  user: McloudUserResponse | null;
  apartment: McloudApartmentResponse | null;
  adminNotices: McloudAdminNoticesResponse[] | null;
  notices: McloudNoticesResponse[] | null;
  mineReservations: McloudMineReservationsResponseData[] | null;
  scheduleEvents: McloudScheduleEventsResponse[] | null;
} = {
  /** Mcloud ログインユーザー情報取得 */
  userSession: null,
  /** Mcloud ユーザー情報取得 */
  user: null,
  /** Mcloud マンション情報取得 */
  apartment: null,
  /** Mcloud つなぐネットからのお知らせ取得 */
  adminNotices: null,
  /** Mcloud お知らせ一覧取得 */
  notices: null,
  /** Mcloud 自身の予約一覧 */
  mineReservations: null,
  /** Mcloud 予定一覧 */
  scheduleEvents: null
};

/** State の型 */
type McloudCommonState = typeof mcloudCommonState;

/** Mcloud 共通系 Store */
export const mcloudCommonStore: Module<McloudCommonState, RootState> = {
  namespaced: true,
  state: mcloudCommonState,
  actions: {
    /**
     * ログインユーザー情報取得 actions
     * @param context
     * @return McloudUserSessionResponse ログインユーザー情報
     */
    async userSession(context): Promise<McloudUserSessionResponse | null> {
      // API コールする
      const userSessionValue: McloudUserSessionResponse | McloudSharedErrorResponse = await McloudSharedExternalApiService.findUserSession();
      // 値をコミットする
      if (!(userSessionValue instanceof McloudSharedErrorResponse)) context.commit('userSession', userSessionValue);
      // Getterから取り直して返す
      const afterUserSessionrValue = context.getters['userSession'];
      return afterUserSessionrValue;
    },
    /**
     * マンション情報取得 actions
     * @param context
     * @param id number マンションID
     * @return McloudApartmentResponse マンション情報
     */
    async apartment(context, id: number): Promise<McloudApartmentResponse | null> {
      // API コールする
      const apartmentValue: McloudApartmentResponse | McloudSharedErrorResponse = await McloudSharedExternalApiService.findApartmentById(id);
      // 値をコミットする
      if (!(apartmentValue instanceof McloudSharedErrorResponse)) context.commit('apartment', apartmentValue);
      // Getterから取り直して返す
      const afterApartmentValue = context.getters['apartment'];
      return afterApartmentValue;
    },

    /**
     * ユーザー情報取得 actions
     * @param context
     * @param id number ユーザーID
     * @return McloudUserResponse ユーザー情報
     */
    async user(context, id: number): Promise<McloudUserResponse[] | null> {
      // API コールする
      const userValue: McloudUserResponse[] | McloudSharedErrorResponse = await McloudSharedExternalApiService.findUserById(id);
      // 値をコミットする
      if (!(userValue instanceof McloudSharedErrorResponse)) context.commit('user', userValue);
      // Getterから取り直して返す
      const afterUserValue = context.getters['user'];
      return afterUserValue;
    },

    /**
     * つなぐネットからのお知らせ取得 actions
     * @param context
     * @param targetDisplayIn Array<string> 検索条件 配信先 0: PC 1: スマートフォン
     * @return McloudAdminNoticesResponse[] つなぐネットからのお知らせリスト
     */
    async adminNotices(context, targetDisplayIn: Array<string>): Promise<McloudAdminNoticesResponse[] | null> {
      // API コールする
      const adminNoticesValue: McloudAdminNoticesResponse[] | McloudSharedErrorResponse = await McloudSharedExternalApiService.findAdminNotices(targetDisplayIn);
      // 値をコミットする
      if (!(adminNoticesValue instanceof McloudSharedErrorResponse)) context.commit('adminNotices', adminNoticesValue);
      // Getterから取り直して返す
      const afterAdminNoticesValuee = context.getters['adminNotices'];
      return afterAdminNoticesValuee;
    },

    /**
     * お知らせ一覧取得 actions
     * @param context
     * @param noticesQuery McloudNoticesRequest 検索条件
     * @return McloudNoticesResponse[] お知らせリスト
     */
    async notices(context, noticesQuery: McloudNoticesRequest): Promise<McloudNoticesResponse[] | null> {
      // API コールする
      const noticesValue: McloudNoticesResponse[] | McloudSharedErrorResponse = await McloudSharedExternalApiService.findNotices(noticesQuery);
      // 値をコミットする
      if (!(noticesValue instanceof McloudSharedErrorResponse)) context.commit('notices', noticesValue);
      // Getterから取り直して返す
      const afterNoticesValue = context.getters['notices'];
      return afterNoticesValue;
    },

    /**
     * 自身の予約一覧取得 actions
     * @param context
     * @param limit number 最大件数
     * @return McloudMineReservationsResponse 自身の予約一覧
     */
    async mineReservations(context, limit: number): Promise<McloudMineReservationsResponse | null> {
      // API コールする
      const mineReservationsValue: McloudMineReservationsResponse | McloudSharedErrorResponse = await McloudSharedExternalApiService.findMineReservations(limit);
      // 値をコミットする
      if (!(mineReservationsValue instanceof McloudSharedErrorResponse)) context.commit('mineReservations', mineReservationsValue);
      // Getterから取り直して返す
      const afterMineReservationsValue = context.getters['mineReservations'];
      return afterMineReservationsValue;
    },

    /**
     * 予定一覧取得 actions
     * @param context
     * @param scheduleEventsQuery McloudScheduleEventsRequest 検索条件
     * @return McloudScheduleEventsResponse[] 予定一覧
     */
    async scheduleEvents(context, scheduleEventsQuery: McloudScheduleEventsRequest): Promise<McloudScheduleEventsResponse[] | null> {
      // API コールする
      const scheduleEventsValue: McloudScheduleEventsResponse[] | McloudSharedErrorResponse = await McloudSharedExternalApiService.findScheduleEvents(scheduleEventsQuery);
      // 値をコミットする
      if (!(scheduleEventsValue instanceof McloudSharedErrorResponse)) context.commit('scheduleEvents', scheduleEventsValue);
      // Getterから取り直して返す
      const afterScheduleEventsValue = context.getters['scheduleEvents'];
      return afterScheduleEventsValue;
    }
  },
  mutations: {
    userSession: (state, value: McloudUserSessionResponse) => (state.userSession = value),
    user: (state, value: McloudUserResponse) => (state.user = value),
    apartment: (state, value: McloudApartmentResponse) => (state.apartment = value),
    adminNotices: (state, value: McloudAdminNoticesResponse[]) => (state.adminNotices = value),
    notices: (state, value: McloudNoticesResponse[]) => (state.notices = value),
    mineReservations: (state, value: McloudMineReservationsResponseData[]) => (state.mineReservations = value),
    scheduleEvents: (state, value: McloudScheduleEventsResponse[]) => (state.scheduleEvents = value)
  },
  getters: {
    userSession: (state): McloudUserSessionResponse | null => state.userSession,
    user: (state): McloudUserResponse | null => state.user,
    apartment: (state): McloudApartmentResponse | null => state.apartment,
    adminNotices: (state): McloudAdminNoticesResponse[] | null => state.adminNotices,
    notices: (state): McloudNoticesResponse[] | null => state.notices,
    mineReservations: (state): McloudMineReservationsResponseData[] | null => state.mineReservations,
    scheduleEvents: (state): McloudScheduleEventsResponse[] | null => state.scheduleEvents
  }
};
