import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM アカウントIDオプション登録/変更 State */
const ucomAccountOptionState: {
  ucomAccountId: string | null; //UCOMアカウントID
  currentMailAddress: string | null; //現在のメールアドレス
  mailAddress: string | null; //メールアドレス
  mailAddressType: string | null; //メールアドレス種別
  isChange: string | null; //登録変更フラグ
  accountName: string | null; //ユーザーネーム
  memberId: string | null; //会員ID
  ucomAccountMailId: string | null; //UCOMアカウントメールID
  displayMailAddressType: string | null; //表示用メールアドレス種別
  registerSubscriptionOn: string | null; // お申し込み日
  beforePath: string | null; // メールアドレス登録_入力画面の遷移元
} = {
  ucomAccountId: null,
  currentMailAddress: null,
  mailAddress: null,
  mailAddressType: null,
  isChange: null,
  accountName: null,
  memberId: null,
  ucomAccountMailId: null,
  displayMailAddressType: null,
  registerSubscriptionOn: null,
  beforePath: null,
};

/** State の型 */
type UcomAccountOptionState = typeof ucomAccountOptionState;

/** UCOM アカウントIDオプション登録/変更 Store */
export const ucomAccountOptionStore: Module<UcomAccountOptionState, RootState> = {
  namespaced: true,
  state: ucomAccountOptionState,
  actions: {},
  mutations: {
    ucomAccountId: (state, value: string) => (state.ucomAccountId = value),
    currentMailAddress: (state, value: string) => (state.currentMailAddress = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    mailAddressType: (state, value: string) => (state.mailAddressType = value),
    isChange: (state, value: string) => (state.isChange = value),
    accountName: (state, value: string) => (state.accountName = value),
    memberId: (state, value: string) => (state.memberId = value),
    ucomAccountMailId: (state, value: string) => (state.ucomAccountMailId = value),
    displayMailAddressType: (state, value: string) => (state.displayMailAddressType = value),
    registerSubscriptionOn: (state, value: string) => (state.registerSubscriptionOn = value),
    beforePath: (state, value: string) => (state.beforePath = value),
  },
  getters: {
    ucomAccountId: (state): string | null => state.ucomAccountId,
    currentMailAddress: (state): string | null => state.currentMailAddress,
    mailAddress: (state): string | null => state.mailAddress,
    mailAddressType: (state): string | null => state.mailAddressType,
    isChange: (state): string | null => state.isChange,
    accountName: (state): string | null => state.accountName,
    memberId: (state): string | null => state.memberId,
    ucomAccountMailId: (state): string | null => state.ucomAccountMailId,
    displayMailAddressType: (state): string | null => state.displayMailAddressType,
    registerSubscriptionOn: (state): string | null => state.registerSubscriptionOn,
    beforePath: (state): string | null => state.beforePath,
  },
};
