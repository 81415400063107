import { Module } from 'vuex';
import { RootState } from '../index';
import { TsunaguOnlineProductHtmlDto, TsunaguOnlineProductHtmlDtoList, TsunaguOnlineProductHtmlRequestDto } from '@/shared/classes/spf-api/tsunagu-online-product-html';
import { SpfApiService } from '@/shared/services/api/spf-api-service';

/**
 * スクレイピング処理したつなぐオンラインショップのHTML State
 */
const tsunaguOnlineProductHtmlDisplayOnPcState: {
  // 1番最近遷移したURL
  recentlyTransitionUrl: string | null;
  noShoppingCartButtonHtml: TsunaguOnlineProductHtmlDtoList | null;
  hasShoppingCartButtonHtml: TsunaguOnlineProductHtmlDtoList | null;
} = {
  recentlyTransitionUrl: null,
  noShoppingCartButtonHtml: null,
  hasShoppingCartButtonHtml: null
};

type tsunaguOnlineProductHtmlDisplayOnPcState = typeof tsunaguOnlineProductHtmlDisplayOnPcState;

export const tsunaguOnlineProductHtmlDisplayOnPcStore: Module<tsunaguOnlineProductHtmlDisplayOnPcState, RootState> = {
  namespaced: true,
  state: tsunaguOnlineProductHtmlDisplayOnPcState,
  mutations: {
    recentlyTransitionUrl: (state, value: string) => (state.recentlyTransitionUrl = value),
    noShoppingCartButtonHtml: (state, value: TsunaguOnlineProductHtmlDto) => {
      if (!state.noShoppingCartButtonHtml) {
        state.noShoppingCartButtonHtml = new TsunaguOnlineProductHtmlDtoList();
      }
      if (!state.noShoppingCartButtonHtml?.existsUrl(value.url)) {
        state.noShoppingCartButtonHtml?.addItem(value);
      }
    },
    hasShoppingCartButtonHtml: (state, value: TsunaguOnlineProductHtmlDto) => {
      if (!state.hasShoppingCartButtonHtml) {
        state.hasShoppingCartButtonHtml = new TsunaguOnlineProductHtmlDtoList();
      }
      if (!state.hasShoppingCartButtonHtml?.existsUrl(value.url)) {
        state.hasShoppingCartButtonHtml?.addItem(value);
      }
    }
  },
  getters: {
    recentlyTransitionUrl: (state): string | null => state.recentlyTransitionUrl,
    noShoppingCartButtonHtml: (state): TsunaguOnlineProductHtmlDtoList | null => state.noShoppingCartButtonHtml,
    hasShoppingCartButtonHtml: (state): TsunaguOnlineProductHtmlDtoList | null => state.hasShoppingCartButtonHtml
  },
  actions: {
    async noShoppingCartButtonHtml(context, myUrl: string) {
      const requestDto = new TsunaguOnlineProductHtmlRequestDto({
        url: myUrl,
        displayShoppingCartButton: 'noDisplay'
      });

      const result = await SpfApiService.fetchTsunaguOnlineProductHtmlDisplayOnPc(requestDto);
      const tsunaguOnlineProductHtmlDto = new TsunaguOnlineProductHtmlDto(myUrl, result.formattedHtml);
      context.commit('noShoppingCartButtonHtml', tsunaguOnlineProductHtmlDto);
      const afterTsunaguOnlineProductHtmlDtoList = context.getters['noShoppingCartButtonHtml'];

      return afterTsunaguOnlineProductHtmlDtoList;
    },
    async hasShoppingCartButtonHtml(context, myUrl: string) {
      const requestDto = new TsunaguOnlineProductHtmlRequestDto({
        url: myUrl,
        displayShoppingCartButton: 'display'
      });

      const result = await SpfApiService.fetchTsunaguOnlineProductHtmlDisplayOnPc(requestDto);
      const tsunaguOnlineProductHtmlDto = new TsunaguOnlineProductHtmlDto(myUrl, result.formattedHtml);
      context.commit('hasShoppingCartButtonHtml', tsunaguOnlineProductHtmlDto);
      const afterTsunaguOnlineProductHtmlDtoList = context.getters['hasShoppingCartButtonHtml'];

      return afterTsunaguOnlineProductHtmlDtoList;
    }
  }
};
