import { UcomErrorResponse } from '@/pages/ucom/classes/external-api/error-response';
import { UcomPaymentStatusResponse } from '@/pages/ucom/classes/external-api/payment-status-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import store from '@/store';
import { UcomExternalApiService } from '../external-api/ucom-external-api-service';

/**
 * ISP(UCOM) の VeriTransに関わる操作を司る
 */
export class UcomVeriTransService {
  /**
   * UCOMに登録されたVeriTransカードIDを取得する
   *
   * @returns 空文字列 or UCOM お支払い情報登録状態取得API.カード会員ID
   */
  public static async getVeriTransCardIdOnUcom(): Promise<string> {
    const member: Member | null = store.getters['memberStore/member'];
    if (!member) {
      return '';
    }
    const ucomCustomer: UcomCustomerResponse | UcomSharedErrorResponse = await store.dispatch('ucomCommonStore/customer', member!.primaryKeyUcom);

    if (ucomCustomer instanceof UcomCustomerResponse && ucomCustomer.is_register_credit_card) {
      if (member && member.primaryKeyUcom) {
        const paymentStatus: UcomPaymentStatusResponse | UcomErrorResponse = await UcomExternalApiService.getPaymentStatus(member.primaryKeyUcom);
        if (paymentStatus instanceof UcomPaymentStatusResponse && paymentStatus.payment_method_id === '1' && paymentStatus.card_account_id) {
          return paymentStatus.card_account_id;
        }
      }
    }
    return '';
  }
}
