import { BaseApiService } from '@/shared/services/api/base-api-service';
import { UcomWebSpaceUserInfo } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-user-info';
import { UcomWebSpaceServiceInfo } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-service-info';
import { UcomWebSpaceCapacityUpdate } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-capacity-update';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';

const api = BaseApiService.api();

/** 内部APIをコールするサービス */
export class SpfApiWebSpaceAccessor {
  /**
   * Webスペースの容量情報(ユーザ)を取得
   * @param portasId 会員ID
   * @param ucomAccountMailId UCOM_アカウントメールID
   * @param targetDate 対象日
   */
  public static async findUcomWebSpaceUserInfo(portasId: string, ucomAccountMailId: string, targetDate: string): Promise<UcomWebSpaceUserInfo> {
    const response = await api
      .get('/hp-youryou/UCOM/nyuuryoku-user', {
        params: {
          portasId: portasId,
          ucomAccountMailId: ucomAccountMailId,
          targetDate: targetDate,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return this.convertUcomWebSpaceUserInfo(response.data);
  }

  /**
   * Webスペースの容量情報(ユーザ)レスポンスをdtoに詰替え
   * @param data Webスペースの容量情報(ユーザ)レスポンス
   * @returns Webスペースの容量情報(ユーザ)dto
   */
  private static convertUcomWebSpaceUserInfo(data: UcomWebSpaceUserInfo | undefined): UcomWebSpaceUserInfo {
    if (!data) {
      throw new Error('Webスペース容量 ユーザ情報無し');
    }
    return new UcomWebSpaceUserInfo(data.paymentStatus, data.accountName, data.mailAddress, data.homepageAddress, data.webSpaceCapacity);
  }

  /**
   * Webスペースの容量情報(サービス)を取得
   * @param portasId 会員ID
   * @param ucomAccountMailId UCOM_アカウントメールID
   * @param targetDate 対象日
   */
  public static async findUcomWebSpaceServiceInfo(portasId: string, ucomAccountMailId: string, targetDate: string): Promise<UcomWebSpaceServiceInfo> {
    const response = await api
      .get('/hp-youryou/UCOM/nyuuryoku-service', {
        params: {
          portasId: portasId,
          ucomAccountMailId: ucomAccountMailId,
          targetDate: targetDate,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomWebSpaceServiceInfo(response.data);
  }

  /**
   * Webスペースの容量情報(サービス)レスポンスをdtoに詰替え
   * @param data Webスペースの容量情報(サービス)レスポンス
   * @returns Webスペースの容量情報(サービス)dto
   */
  private static convertUcomWebSpaceServiceInfo(data: UcomWebSpaceServiceInfo | undefined): UcomWebSpaceServiceInfo {
    if (!data) {
      throw new Error('Webスペース容量 サービス情報無し');
    }
    return new UcomWebSpaceServiceInfo(data.changeCapacityList, data.unitPrice, data.serviceDescription, data.webSpaceMaxCapacity);
  }

  /**
   * Webスペースの更新API呼出し
   * @param portasId 会員ID
   * @param ucomAccountMailId UCOM_アカウントメールID
   * @param changeCapacityLabel 追加／縮小容量(label)
   * @param changeCapacityValue 追加／縮小容量(Value)
   * @param targetDate 対象日
   */
  public static async updateUcomWebSpaceCapacity(
    portasId: number,
    ucomAccountMailId: string,
    changeCapacityLabel: string,
    changeCapacityValue: string,
    targetDate: string
  ): Promise<UcomWebSpaceCapacityUpdate> {
    const response = await api
      .post(`/hp-youryou/UCOM/kakunin`, {
        portasId: portasId,
        ucomAccountMailId: ucomAccountMailId,
        changeCapacityLabel: changeCapacityLabel,
        changeCapacityValue: changeCapacityValue,
        targetDate: targetDate,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return this.convertUcomWebSpaceCapacityUpdate(response.data);
  }

  /**
   * Webスペースの更新処理レスポンスをdtoに詰替え
   * @param data Webスペースの更新処理レスポンス
   * @returns Webスペースの更新情報dto
   */
  private static async convertUcomWebSpaceCapacityUpdate(data: UcomWebSpaceCapacityUpdate | undefined): Promise<UcomWebSpaceCapacityUpdate> {
    if (!data) {
      throw new Error('Webスペース容量 更新処理失敗');
    }
    return new UcomWebSpaceCapacityUpdate(
      data.notifyMailAddress,
      data.accountName,
      data.homepageAddress,
      data.webSpaceCapacity,
      data.changeCapacityLabel,
      data.price,
      data.subscriptionDate,
      data.serviceDescription,
      data.errorMessages
    );
  }
}
