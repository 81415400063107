import { SpfApiBatchSituationAccessor } from '@/infra/accessor/spf/common/spf-api-batchsituation-accessor';
import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { FiveACustomerQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { SubscriptionDetailsArray, SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import { CONTRACT_STATUS } from '@/shared/const/gamewith/contract-status';
import { CONTENT_PROVIDER_ID, ISP_MEMBER_STATUS, UA_TYPE, UNEXT_CONTENTS_ID, UaTypeValue } from '@/shared/const/service-type';
import { getIspMemberStatusEMansion, getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import store from '@/store';
import dayjs from 'dayjs';
import { SpfApiService } from '../api/spf-api-service';
import { AuthService } from '../auth/auth-service';

export class AccountUtilService {
  /**
   * 会員ステータスが必要なログインユーザーかをチェックする
   * @returns trueの場合、必要
   */
  public static async needMemberStatus(): Promise<boolean> {
    // 会員ステータスは、Portasに会員登録する際、その状態を管理するために使用する。
    // 追加アカウントのユーザーは、自身をPortas会員として登録することが無い為、
    // 会員ステータスは不要となる。
    return !(await this.isSubAccount());
  }

  /**
   * ログインユーザーが追加アカウント（=会員情報を持たない）か否かをチェックする
   * @returns trueの場合、追加アカウント
   */
  public static async isSubAccount(): Promise<boolean | Error> {
    return (await AuthService.isEMansionAdditionalAccount()) || (await AuthService.isUcomAdditionalAccount());
  }

  /**
   * 退会可能なPortasアカウントかどうか判定する(UCOM)
   * @returns trueの場合、退会可能
   */
  public static async canCancelUcom(): Promise<boolean | Error> {
    // 会員ストアから会員情報を取得する
    const member = store.getters['memberStore/member'];
    if (!member) {
      throw new ApiFrontError('canCancelUcom(): memberがありません');
    }

    const property = store.getters['propertyStore/property'];
    const ucomMemberId = member.primaryKeyUcom;
    const uaType: UaTypeValue | undefined = property?.uaType;

    if (!ucomMemberId || (ucomMemberId && uaType !== UA_TYPE.UCOM)) {
      return true;
    }

    try {
      // ISP未連携の場合 (members.primaryKeyMye=null)、false
      if (!ucomMemberId) {
        return true;
      }

      // ucomの契約基本情報をStoreから取得
      const result: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await store.dispatch('ucomCommonStore/customer', ucomMemberId);

      if (result instanceof UcomCustomerResponse) {
        if (result.quit_date) {
          return true;
        }
      }
    } catch (error) {
      return new Promise((resolve) => {
        resolve(error as Error);
      });
    }

    return false;
  }

  /**
   * 退会可能なPortasアカウントかどうか判定する(e-mansion)
   * @returns trueの場合、退会可能
   */
  public static async canCancelEmansion(): Promise<boolean | Error> {
    // 会員ストアから会員情報を取得する
    const member = store.getters['memberStore/member'];
    if (!member) {
      throw new ApiFrontError('canCancelEMansion(): memberがありません');
    }
    // 会員ストアから会員ステータス情報を取得する
    const memberStatus = store.getters['memberStore/memberStatus'];
    if (!memberStatus) {
      throw new ApiFrontError('canCancelEMansion(): memberStatusがありません');
    }
    const property = store.getters['propertyStore/property'];
    const emansionMemberId = member.primaryKeyMye;
    const uaType: UaTypeValue | undefined = property?.uaType;

    if (!emansionMemberId || (emansionMemberId && uaType !== UA_TYPE.E_MANSION)) {
      return true;
    }

    const propertyId: string | undefined = property?.apartmentId;
    const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      ua_type: uaType,
      apartment_id: propertyId,
    });

    try {
      // ISP未連携の場合 (members.primaryKeyMye=null)、false
      if (!emansionMemberId) {
        return true;
      }

      // 契約基本情報取得
      const customer: EMansionCustomer | EMansionSharedErrorResponse = await store.dispatch('eMansionCommonStore/customer', {
        memberId: emansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (customer instanceof EMansionCustomer) {
        if (memberStatus) {
          const ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, customer);

          if (ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK) {
            return true;
          } else if (ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION) {
            return true;
          }
        }
      }
    } catch (error) {
      return new Promise((resolve) => {
        resolve(error as Error);
      });
    }

    return false;
  }

  /**
   * 退会可能なPortasアカウントかどうか判定する(Fiva.A)
   * @returns trueの場合、退会可能
   */
  public static async canCancelFiveA(): Promise<boolean | Error> {
    // 会員ストアから会員情報を取得する
    const member = store.getters['memberStore/member'];
    if (!member) {
      throw new ApiFrontError('canCancelFiveA(): memberがありません');
    }
    // 会員ストアから会員ステータス情報を取得する
    const memberStatus = store.getters['memberStore/memberStatus'];
    if (!memberStatus) {
      throw new ApiFrontError('canCancelFiveA(): memberStatusがありません');
    }
    const property = store.getters['propertyStore/property'];
    const fiveAMemberId = member.primaryKeyMye;
    const uaType: UaTypeValue | undefined = property?.uaType;

    if (!fiveAMemberId || (fiveAMemberId && uaType !== UA_TYPE.FIVE_A)) {
      return true;
    }

    const propertyId: string | undefined = property?.apartmentId;

    const fiveACustomerQueryDto = new FiveACustomerQueryDto({
      ua_type: uaType,
      apartment_id: propertyId,
    });

    try {
      // ISP未連携の場合 (members.primaryKeyMye=null)、false
      if (!fiveAMemberId) {
        return true;
      }

      // 契約基本情報をStoreから取得
      const customer: FiveACustomer | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: fiveACustomerQueryDto,
      });

      if (customer instanceof FiveACustomer) {
        if (memberStatus) {
          const ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, customer);

          if (ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          } else if (ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION) {
            return true;
          }
        }
      }
    } catch (error) {
      return new Promise((resolve) => {
        resolve(error as Error);
      });
    }

    return false;
  }

  /**
   * 退会可能なPortasアカウントかどうか判定する(GameWith)
   * @returns trueの場合、退会可能
   */
  public static async canCancelGameWith(): Promise<boolean | Error> {
    // 会員ストアから会員情報を取得する
    const member = store.getters['memberStore/member'];
    if (!member) {
      throw new ApiFrontError('canCancelGameWith(): memberがありません');
    }
    // 会員ストアから会員ステータス情報を取得する
    const memberStatus = store.getters['memberStore/memberStatus'];
    if (!memberStatus) {
      throw new ApiFrontError('canCancelGameWith(): memberStatusがありません');
    }
    try {
      const gameWithMemberId = member.primaryKeyGW;
      // ISP未連携の場合 (members.primaryKeyGW=null)、false
      if (!gameWithMemberId) {
        return true;
      }

      if (memberStatus.encryptedPrimaryKeyGw) {
        const gwCustomer = await SpfApiExternalGamewithAccessor.getCustomer(memberStatus.encryptedPrimaryKeyGw);

        if (!gwCustomer && !gameWithMemberId) {
          return true;
        }
        if (
          gwCustomer.contractStatus == CONTRACT_STATUS.OPENED ||
          gwCustomer.contractStatus == CONTRACT_STATUS.IN_USE ||
          gwCustomer.contractStatus == CONTRACT_STATUS.SUSPENSION_OF_USE
        ) {
          return false;
        } else {
          return true;
        }
      }
    } catch (error: any) {
      return new Promise((resolve) => {
        resolve(error as Error);
      });
    }

    return false;
  }

  /**
   * // Portasユーザのサブスクリプションで退会不可のものが存在するか確認を行う
   * U-NEXT商品※1以外は利用中以外（解約期間中、解約済み）は退会申込可
   * U-NEXT商品は退会可能日※2以降は退会申込可
   * ※1 U-NEXT商品：コンテンツ(contents)テーブル.コンテンツプロバイダー IDが3
   * ※2 退会可能：請求結果記録バッチの実施月の年月がU－NEXT月額商品.解約日(cancelDate) の月から３か月後以降
   * @param memberId 会員ID
   * @returns trueの場合、退会不可のサブスクリプションが存在する
   */
  public static async hasActiveSubscription(memberId: number): Promise<boolean> {
    const subscriptionList = await SpfApiService.getSubscriptionList(memberId);

    // サブスクリプションが無い（取得件数が0件）の場合、そのまま次の処理へ
    // サブスクリプションの取得件数が１件以上の場合
    if (subscriptionList.contracts.length !== 0) {
      // 利用中のサブスクリプションがあるか、もしくはU-NEXTの商品があれば退会不可期間内じゃないか確認

      // 利用中のサブスクリプションがあるか確認する
      const productListWithoutUnext = subscriptionList.contracts.filter(function (e) {
        return e.contents.contentProviderId !== CONTENT_PROVIDER_ID.UNEXT;
      });
      if (productListWithoutUnext.length !== 0) {
        //　U-NEXT以外の商品で解約日が入ってないものがあるか確認する
        //　あればtrue(退会不可)を返却
        for (const contract of productListWithoutUnext) {
          if (!contract.cancelDate) {
            return true;
          }
        }
      }

      const isExistCantCancelUnext = await this.canCancelUNext(subscriptionList);

      if (!isExistCantCancelUnext) {
        return true;
      }
    }
    return false;
  }
  /**
   * // Portasユーザのサブスクリプションで退会不可のものが存在するか確認を行う(退会不可画面用)
   * U-NEXT商品※1以外は利用中以外（解約期間中、解約済み）は退会申込可
   * U-NEXT商品は退会可能日※2以降は退会申込可
   * ※1 U-NEXT商品：コンテンツ(contents)テーブル.コンテンツプロバイダー IDが3
   * ※2 退会可能：請求結果記録バッチの実施月の年月がU－NEXT月額商品.解約日(cancelDate) の月から３か月後以降
   * @param memberId 会員ID
   * @returns trueの場合、退会不可のサブスクリプションが存在する
   */
  public static async hasActiveSubscriptionOnImpossible(memberId: number): Promise<string[]> {
    /** 退会不可のサブスクリプション一覧 */
    let productsNames: string[] = [];
    // サブスクリプション一覧取得APIを実行
    const subscriptionList = await SpfApiService.getSubscriptionList(memberId);

    // サブスクリプションが無い（取得件数が0件）の場合、そのまま次の処理へ
    // サブスクリプションの取得件数が１件以上の場合、商品ID順に商品名を表示用リストに追加
    if (subscriptionList.contracts.length !== 0) {
      const contractsSortByProductsId = [...subscriptionList.contracts].sort((a, b) => a.productsId - b.productsId);

      //　U-NEXT以外の商品で解約日が入ってないものがあるか確認する
      //　あれば表示用リストに商品名追加
      for (const contract of contractsSortByProductsId) {
        if (!contract.cancelDate && contract.contents.contentProviderId !== CONTENT_PROVIDER_ID.UNEXT) {
          productsNames.push(contract.products.productsName!);
        }
      }

      // U-NEXTの商品があれば退会不可期間内じゃないか確認
      // U-NEXT月額を判定基準にしているため、falseの場合はU-NEXT月額の商品名を表示用リストに追加
      // U-NEXT単品(電子書籍、映像作品?) はPortas退会判定に関わらない(表示もしない）
      const isExistCantCancelUnext = await this.canCancelUNext(subscriptionList);
      if (!isExistCantCancelUnext) {
        const uNextMonthlyProductList = subscriptionList.contracts.filter(function (e) {
          return e.contents.contentsId === UNEXT_CONTENTS_ID.MONTHLY_PLAN;
        });
        productsNames.push(uNextMonthlyProductList[0].products.productsName!);
      }
    }
    return productsNames;
  }
  /**
   * U-NEXTの商品への処理
   * 1.U-NEXTの商品があるか確認する
   * 2.退会不可の商品があるか調べる。一つでもあればfalse
   * 請求結果記録バッチの実施月の年月が月額プランの解約日(cancelDate) の月から３か月後場合（退会可能）: true
   * 上記以外の場合: false
   */
  public static async canCancelUNext(subscriptionList: SubscriptionDetailsArray): Promise<boolean | Error> {
    // 1.U-NEXTの商品があるか確認する()
    const uNextProductList = subscriptionList.contracts.filter(function (e) {
      return e.contents.contentProviderId === CONTENT_PROVIDER_ID.UNEXT;
    });

    if (uNextProductList.length > 0) {
      // 2.U-NEXTの月額商品を確認する
      // 請求明細初期表示月取得 APIの取得結果.設定値1（バッチ実行年月）がU-NEXTの月額商品の解約月から３か月後の月以降か
      // 一度解約後、再契約があった場合はレコードが２件以上になるため、その場合は契約日が最も大きいレコードを確認する
      // 以降であれば退会可能

      // 請求明細初期表示月取得 APIから請求結果記録バッチが正常に実施された対象月を取得
      const batchSituationRecord = await SpfApiBatchSituationAccessor.getBatchSituation('BS01-002');
      if (!batchSituationRecord.value1) {
        throw new Error('請求明細初期表示月取得API.設定値1に値がない');
      }
      const batchFinishedMonth = batchSituationRecord.value1;

      // U-NEXT商品があれば月額プランのレコードが必ずあるため、月額プランの商品を抜き出す
      const uNextMonthlyProductList = subscriptionList.contracts.filter(function (e) {
        return e.contents.contentsId === UNEXT_CONTENTS_ID.MONTHLY_PLAN;
      });

      //複数ある場合は、契約IDが大きいレコードを取得するためソート
      uNextMonthlyProductList.sort((a, b) => (a.contents.contentsId > b.contents.contentsId ? 1 : -1));

      const isAfterCancelValiableDate = await this.isUNextCancelableDate(uNextMonthlyProductList[0], batchFinishedMonth!);
      if (!isAfterCancelValiableDate) {
        //退会不可ならfalseを返す
        return false;
      }
    }
    return true;
  }
  /**
   * U-NEXTの商品用の処理
   * 退会可能期間か確認する
   * 請求明細初期表示月取得 APIの取得結果.設定値1（バッチ実行年月）がU-NEXTの解約月から３か月後の1日以降の場合（退会可能）: true
   * 上記以外の場合: false
   */
  private static async isUNextCancelableDate(subscription: SubscriptionDetailsDto, batchFinishedMonth: string): Promise<boolean> {
    // 請求明細初期表示月取得 APIの取得結果.設定値1（バッチ実行年月）がU-NEXTの解約月から３か月後の月以降か確認
    // 解約日: 2023/09/30 のとき バッチ実行年月:202312 以降であればok

    if (subscription.cancelDate) {
      const canCancelMonth = dayjs(subscription.cancelDate).add(3, 'month').format('YYYYMM');

      if (batchFinishedMonth >= canCancelMonth) {
        return true;
      }
    }
    return false;
  }
}
