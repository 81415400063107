import { LinkServiceWithUcomErrorResponse } from '@/shared/classes/spf-api/link-service-with-ucom';
import { UCOM_CUSTOMERS_BUSINESS_ERROR_CODE } from '@/shared/const/ucom/ucom-customers-error-code';

export class LinkServiceWithUcomErrorService {
  /**
   * UCOM 同時申込API のエラーが、業務エラーにしたいエラーかどうか調べる
   *
   * @returns true: 業務エラーにしたいエラー
   * @returns false: 業務エラーにしたくないエラー
   */
  public static isBusinessError(response: LinkServiceWithUcomErrorResponse): boolean {
    return UCOM_CUSTOMERS_BUSINESS_ERROR_CODE.includes(response.code);
  }
}
