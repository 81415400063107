import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM Webスペース登録 State */
const UcomWebSpaceRegisterStore: {
  accountName: string | undefined; // アカウント名
  mailAddress: string | undefined; // メールアドレス
  homepageAddress: string | undefined; // ホームページアドレス
  applicationDate: Date | undefined; // 申し込み日
} = {
  accountName: undefined,
  mailAddress: undefined,
  homepageAddress: undefined,
  applicationDate: undefined,
};

type UcomWebSpaceRegisterStore = typeof UcomWebSpaceRegisterStore;

/** UCOM Webスペース登録 State */
export const ucomWebSpaceRegisterStore: Module<UcomWebSpaceRegisterStore, RootState> = {
  namespaced: true,
  state: UcomWebSpaceRegisterStore,
  actions: {},
  mutations: {
    accountName: (state, value: string) => (state.accountName = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    homepageAddress: (state, value: string) => (state.homepageAddress = value),
    applicationDate: (state, value: Date) => (state.applicationDate = value),
  },
  getters: {
    accountName: (state): string | undefined => state.accountName,
    mailAddress: (state): string | undefined => state.mailAddress,
    homepageAddress: (state): string | undefined => state.homepageAddress,
    applicationDate: (state): Date | undefined => state.applicationDate,
  },
};
