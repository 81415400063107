<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Webスペース登録</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>Webスペース登録</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="Webスペース登録" />Webスペース登録</h2>
        <p>
          お申し込みいただきました内容につきまして手続きが完了しました。<br />
          FTP設定情報は<a @click.prevent href="" v-on:click="ftpSettingInfro()"
            ><font color="blue"><u>「こちら」</u></font></a
          >
          をご参照ください。
        </p>
      </div>
      <!-- /blc -->

      <div class="sblc">
        <h3 class="service-h3">お申し込み内容</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">ホームページアドレス</th>
              <td>{{ homepageAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ applicationDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">注意事項</th>
              <td>
                ホームページを開設できるWebスペースを取得できるサービスです。<br />
                ホームページのURLは以下のようになります。 <br />
                「{{ homepageUrlScheme }}(1)ホスティングサーバー名/(2)XXX/」 <br />
                (1)ホスティングサーバー名 <br />
                ご登録されたメールアドレスの@より右部分によって決まります。 <br />
                (2)XXX部分 <br />
                お客様のメールアドレスの@より左側部分です。<br />
                ・申し込み後、すぐにご利用いただけます。<br />
                ・お申し込み後のキャンセルは行えません。<br />
                ・Webスペース単体での解約は行えません。不要な場合はアカウントIDを削除するか、<br />
                メールサービスを他社アドレスに変更してください。 <br />
                ・WebスペースのURLを変更することはできません。<br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="onUcomTop()">
            UCOM光 レジデンス会員専用トップ
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'web-space-register-completed',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: false, // ローディング

    /** 各種パラメータ */
    accountName: '', // アカウントID
    mailAddress: '', // メールアドレス
    applicationDate: Date, // お申し込み日
    homepageAddress: '', // ホームページアドレス
    homepageUrlScheme: process.env.VUE_APP_UCOM_HOMEPAGE_URL_SCHEME, // UCOM ホームページURIスキーム
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;
      const accountName = this.$store.getters['ucomWebSpaceRegisterStore/accountName'];
      if (!accountName) {
        await this.$router.push('/ucom/account-option-change/account-list').catch((error) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
      }

      // 前画面からのデータを画面項目にセット
      await this.setDataByStore();
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    // 前画面からのデータを画面項目にセット
    async setDataByStore() {
      this.$data.accountName = this.$store.getters['ucomWebSpaceRegisterStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomWebSpaceRegisterStore/mailAddress'];
      this.$data.homepageAddress = this.$store.getters['ucomWebSpaceRegisterStore/homepageAddress'];
      this.$data.applicationDate = this.$store.getters['ucomWebSpaceRegisterStore/applicationDate'];
    },

    //FTP設定画面へ遷移
    async ftpSettingInfro(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      await this.$router.push('/ucom/account-option-change/account-list').catch((error) => {
        checkRouterError(error);
      });

      this.$data.isLoading = false;
    },

    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });

      this.$data.isLoading = false;
    },
  },
});
</script>
