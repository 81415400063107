/** サービスプラン種類 */
export const SERVICE_PLAN_TYPE = {
  DEFAULT: 'N', // e-mansion/UCOM以外のユーザー向け
  EM_NORMAL: 'E1', // e-mansion 通常会員
  EM_SLIM: 'E2', // e-mansion スリムプラン
  EM_SLIM_SPECIAL: 'E7', // e-mansion スリムプラン(特例型)
  EM_TTP: 'E3', // e-mansion TTP会員
  EM_MAIL_FREE: 'E4', // e-mansion メール会員(無償)
  EM_MAIL_CHARGE: 'E5', // e-mansion メール会員(有償)
  EM_ADDITIONAL: 'E6', // e-mansion 追加アカウント
  UC_CUSTOMER_NO: 'U1', // UCOM お客様番号
  UC_ADMIN: 'U2', // UCOM 管理者アカウント
  UC_USER: 'U4', // UCOM 一般ユーザーアカウント
} as const;

export type SERVICE_PLAN_TYPE = typeof SERVICE_PLAN_TYPE[keyof typeof SERVICE_PLAN_TYPE];

export const hasAdditionalAccount = (id: string): boolean => {
  return [SERVICE_PLAN_TYPE.EM_ADDITIONAL, SERVICE_PLAN_TYPE.UC_ADMIN, SERVICE_PLAN_TYPE.UC_USER].some((x) => String(x) === id);
};
