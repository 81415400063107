<template>
  <div class="login-forwarding">
    <LoadingComponent v-if="isLoading"/>
  </div>
</template>

<style>
.login-forwarding {
  opacity: 0;
  animation: fade-in 0.5s ease 3s 1 normal;
  animation-fill-mode: forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { AuthService } from '../shared/services/auth/auth-service';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { TRANSITION_STORAGE } from '@/shared/const/service-type';
import { _RouteLocationBase } from 'vue-router';

/** アカウント登録踏み台 画面 */
export default defineComponent({
  name: 'login-forwarding',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async created() {
    if (Object.keys(this.$route.query).length) {
      // LINE対策
      localStorage.setItem(TRANSITION_STORAGE, JSON.stringify(this.$route.query));
    }
    await AuthService.login({ path: this.$route.path, query: this.$route.query } as _RouteLocationBase);
  }
});
</script>
