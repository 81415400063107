import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion メールパスワード変更 State */
const eMansionMailPassword: {
  newPassword: string | null;
  mailAddress: string | null;
} = {
  newPassword: null,
  mailAddress: null,
};

/** State の型 */
type EMansionMailPasswordState = typeof eMansionMailPassword;

/** e-mansion メールパスワード変更 Store */
export const eMansionMailPasswordStore: Module<EMansionMailPasswordState, RootState> = {
  namespaced: true,
  state: eMansionMailPassword,
  actions: {},
  mutations: {
    newPassword: (state, value: string) => (state.newPassword = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
  },
  getters: {
    newPassword: (state): string | null => state.newPassword,
    mailAddress: (state): string | null => state.mailAddress,
  },
};
