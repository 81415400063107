import {UcomAccountManagementAccountList} from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-account-list';

/** アカウントID管理：一覧 アカウント情報一覧 */
export class UcomAccountManagementList {
  readonly _isFullAccount!: boolean; //アカウントID登録数上限該否
  readonly _hasAccount!: boolean; //アカウント有無
  readonly _accountCount!: number; //アカウント件数
  readonly _accountInfoList!: UcomAccountManagementAccountList[]; //アカウント情報

  constructor(partial: Partial<UcomAccountManagementList>) {
    Object.assign(this, partial);
  }

  get isFullAccount(): boolean {
    return this._isFullAccount;
  }

  get hasAccount(): boolean {
    return this._hasAccount;
  }

  get accountCount(): number {
    return this._accountCount;
  }

  get accountInfoList(): UcomAccountManagementAccountList[] {
    return this._accountInfoList;
  }
}
