<template>
  <div class="login-and-register-guide-at-top">
    <div class="my-guide-wrapper">
      <div class="my-logo">
        <img src="../../images/index-logo.svg" alt="Portasロゴ" />
        <div class="login-url-sp">
          <u class="login-u"> <a href="/login" class="responsive-link" v-on:click="onLogin">ログイン</a></u>
        </div>
      </div>

      <div class="my-section-outer">
        <section class="my-section">
          <div class="c-content">
            <div class="c-content__item display-flex">
              <div class="description">
                <div class="Portasection">
                  <h2>初めてPortasをご利用の方</h2>
                </div>
                <ul class="c-list">
                  <li class="c-list__item red font-weight-700"><h4 style="margin: 0">UCOM光 レジデンス、e-mansion、Five.Aの会員登録もこちらから</h4></li>
                  <li class="c-list__item"><h4 style="margin: 0">Portas新規会員登録後、各インターネットサービスの会員登録へお進みください</h4></li>
                </ul>
              </div>
              <div class="my-button">
                <button class="topbtn btn01 bs main-login" v-on:click="onRegister">Portas新規会員登録</button>
              </div>
            </div>

            <div class="c-content__item">
              <div class="description">
                <p class="font-weight-700">「UCOM光 レジデンス」「e-mansion」の会員登録方法は以下を参照ください</p>
              </div>
              <div class="description">
                <ul class="c-list display-flex">
                  <li class="c-list__item c-link-list__item"><a class="link" href="/register-ucom" target="MY ucom">UCOM光 レジデンス</a></li>
                  <li class="c-list__item c-link-list__item"><a class="link" :href="`${eMansionRegisterGuideUrl}`" target="MY e-mansion">e-mansion</a></li>
                </ul>
              </div>
            </div>

            <div class="c-content__item">
              <div class="description">
                <div class="PortasQAsection">
                  <p class="c-link-list__item"><a class="link" href="#" v-scroll-to="{ element: '#faq-at-top' }">Portasに関するよくあるご質問はこちら</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="my-section-outer">
        <section class="my-section">
          <div class="c-content">
            <div class="c-content__item display-flex">
              <div class="description">
                <div class="Portasection">
                  <h2>既にPortas会員の方</h2>
                </div>
                <ul class="c-list">
                  <li class="c-list__item">Portasの会員登録がお済みの方はこちらから</li>
                  <li class="c-list__item">Connectixのお申し込み・解約をご希望の方</li>
                </ul>
              </div>
              <div class="my-button">
                <button class="topbtn btn01 bs main-login" v-on:click="onLogin">Portasログイン</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.my-guide-wrapper {
  padding: 0 16px;
}

div.my-section-outer {
  margin-top: 16px;
}

div.my-logo {
  width: 100%;
  padding-top: 16px;
  & img {
    width: 160px;
  }
}

.login-u {
  color: #cf1225;
}

.login-url-sp {
  margin-bottom: 10px;
}

button.topbtn {
  appearance: none;
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  padding: 12px 25px;
  line-height: 24px;
  font-size: 15px;
  min-width: 250px;
  margin: 0 10px;
  top: 29px;
  box-sizing: border-box;
  position: relative;
}

.Portasection {
  margin-bottom: 10px;
}

.PortasQAsection {
  padding-left: 16px;
}

.c-link-list__item {
  letter-spacing: 0.8px;
  &:before {
    font-family: 'Material Icons';
    content: '\e409';
    position: relative;
    top: 3px;
    font-size: 16px;
  }
  & a {
    text-decoration: underline;
  }
}

.my-section {
  padding: 32px 16px;
  background-color: #efefef;
  & h2 {
    margin: 0;
    font-size: 20px;
  }
}

.c-content {
  margin-top: 0;
  &__item {
    padding-bottom: 32px;
    margin-bottom: 16px;
    border-bottom: 1px solid #a2a2a2;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    & div.description {
      text-align: left;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      & p {
        &.font-weight-700 {
          font-weight: 700;
        }
      }
    }
    & div.my-button {
      margin-top: 24px;
      text-align: center;
    }
  }
}

.c-list {
  margin: 0;
  padding: 0;
  padding-left: 32px;
  &.display-flex {
    display: flex;
    list-style-type: none;
    padding-left: 16px;
    & .c-list__item {
      padding-right: 40px;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  &__item {
    padding: 0;
  }
}

.responsive-link {
  display: none;
}

@media (max-width: 450px) {
  .my-logo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .responsive-link {
    display: block;
    justify-content: flex-start;
    color: #cf1225;
  }
}

@media only screen and (min-width: 1000px) {
  div.my-guide-wrapper {
    max-width: 992px;
    margin: 0 auto;
  }

  .c-content {
    margin-top: 0;
    &__item {
      &.display-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin & div.description {
          margin: 0;
        }
        & div.my-button {
          margin: 0;
          padding-left: 0px;
          padding-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  button.topbtn {
    appearance: none;
    border: 0;
    border-radius: 2px;
    color: #ffffff;
    padding: 12px 25px;
    line-height: 24px;
    font-size: 15px;
    width: 100%;
    margin: 0;
    top: 2px;
    box-sizing: border-box;
    position: relative;
  }
}

@media only screen and (min-width: 769px) and (max-width: 999px) {
  button.topbtn {
    appearance: none;
    border: 0;
    border-radius: 2px;
    color: #ffffff;
    padding: 12px 25px;
    line-height: 24px;
    font-size: 15px;
    min-width: 250px;
    margin: 0;
    top: 2px;
    box-sizing: border-box;
    position: relative;
  }
}

// @media only screen and (max-width: 320px) {
//   button.topbtn {
//     appearance: none;
//     border: 0;
//     border-radius: 2px;
//     color: #ffffff;
//     padding: 12px 25px;
//     line-height: 24px;
//     font-size: 15px;
//     width: 100%;
//     margin: 0;
//     top: 2px;
//     box-sizing: border-box;
//     position: relative;
//   }
// }

@media only screen and (min-width: 1200px) {
  div.my-section-outer {
    margin-top: 32px;
  }
  div.my-logo {
    padding-top: 40px;
    & img {
      width: 200px;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { AuthService } from '@/shared/services/auth/auth-service';

export default defineComponent({
  name: 'login-and-register-guide-at-top',
  data: () => ({
    eMansionRegisterGuideUrl: process.env.VUE_APP_E_MANSION_REGIST_GUIDE_URL,
  }),
  methods: {
    /** ログイン処理を行い、ログインしていなければログイン画面に遷移する */
    async onLogin() {
      await AuthService.login();
    },
    /** 新規会員登録画面に遷移する */
    async onRegister() {
      await AuthService.regist();
    },
  },
});
</script>
