<template>
  <div>
    <template v-if="property">
      <template v-if="property.uaType === propertyUaType.E_MANSION">
        <PortalServiceEMansion @updateLeftMenuLogoFromPortalServiceEMansion="sendUpdateLeftMenuLogoAtPortalServiceController()" />
      </template>

      <template v-else-if="property.uaType === propertyUaType.UCOM">
        <PortalServiceUcom @updateLeftMenuLogoFromPortalServiceUcom="sendUpdateLeftMenuLogoAtPortalServiceController()" />
      </template>

      <template v-else-if="property.uaType === propertyUaType.FIVE_A">
        <PortalServiceFiveA @updateLeftMenuLogoFromPortalServiceFiveA="sendUpdateLeftMenuLogoAtPortalServiceController()" />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PortalServiceEMansion from './portal-service-e-mansion.vue';
import PortalServiceUcom from './portal-service-ucom.vue';
import PortalServiceFiveA from './portal-service-five-a.vue';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '../../../shared/const/service-type';

export default defineComponent({
  components: {
    PortalServiceEMansion,
    PortalServiceUcom,
    PortalServiceFiveA
  },
  name: 'portal-service-controller',
  data(): {
    property: Property | null;
    propertyUaType: typeof UA_TYPE;
  } {
    return {
      property: null,
      propertyUaType: UA_TYPE
    }
  },
  emits: ['updateLeftMenuLogoFromPortalServiceController'],
  mounted() {
    this.property = this.$store.getters['propertyStore/property'];
  },
  methods: {
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにする(下の階層のコンポーネントから伝播されてくる)
     */
    sendUpdateLeftMenuLogoAtPortalServiceController(): void {
      this.$emit('updateLeftMenuLogoFromPortalServiceController', true);
    }
  }
});
</script>
