import { Module } from 'vuex';

import { RootState } from '../index';

const platformProductEntryState: {
  isConnectix: boolean; // Connectixの契約要否
  subscriptionCreateResult: []; // サブスクリプション契約申込APIの実行結果
  productNameArray: string[]; // 商品名配列
  productIdArray: string[]; // 商品ID配列
  ispName: string; // ISP名
} = {
  isConnectix: false,
  subscriptionCreateResult: [],
  productNameArray: [],
  productIdArray: [],
  ispName: '',
};

/** State の型 */
type PlatformProductEntryState = typeof platformProductEntryState;

/** サービス契約 Store */
export const platformProductEntryStore: Module<PlatformProductEntryState, RootState> = {
  namespaced: true,
  state: platformProductEntryState,
  mutations: {
    setIsConnectix(state, isConnectix) {
      state.isConnectix = isConnectix;
    },
    setSubscriptionCreateResult(state, productResult) {
      state.subscriptionCreateResult = productResult;
    },
    setProductNameArray(state, productNameArray) {
      state.productNameArray = productNameArray;
    },
    setProductIdArray(state, productIdArray) {
      state.productIdArray = productIdArray;
    },
    setIspName(state, ispName) {
      state.ispName = ispName;
    },
    clearPlatformProductsEntryStore(state) {
      state.isConnectix = false;
      state.subscriptionCreateResult = [];
      state.productNameArray = [];
      state.productIdArray = [];
      state.ispName = '';
    },
  },
  getters: {
    isConnectix: (state): boolean => state.isConnectix,
    subscriptionCreateResult: (state): [] => state.subscriptionCreateResult,
    productNameArray: (state): string[] => state.productNameArray,
    productIdArray: (state): string[] => state.productIdArray,
    ispName: (state): string => state.ispName,
  },
};
