<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>宅内工事日選択</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/select-construction-date/input">宅内工事日選択入力</router-link></li>
        <li>宅内工事日選択確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="宅内工事日選択 確認" />宅内工事日選択 確認</h2>
      </div>

      <div class="sblc">
        <p>ご選択いただきました日程は</p>
        <div>
          <table class="table-type2 billing-table">
            <tbody v-if="selectedPhone == 1">
              <tr>
                <th class="va-middle">第一希望:</th>
                <td>{{ firstPreferredDate }}　{{ firstPreferredDateType }}</td>
              </tr>
              <tr>
                <th class="va-middle">第二希望:</th>
                <td>{{ secondPreferredDate }}　{{ secondPreferredDateType }}</td>
              </tr>
              <tr>
                <th class="va-middle">第三希望:</th>
                <td>{{ thirdPreferredDate }}　{{ thirdPreferredDateType }}</td>
              </tr>
            </tbody>
            <tbody v-if="selectedPhone == 2">
              <tr>
                <th class="checkbox-th">
                  <label>電話での調整を希望 </label>
                </th>
                <td></td>
              </tr>
            </tbody>
          </table>

          <div class="preferenceForPhone">
            <div class="preferenceForPhoneInput">宅内工事日のご連絡先（電話番号）</div>
          </div>
          <div class="inputFormForPhone">
            {{ phoneNumber }}
          </div>
        </div>
        <p class="confirm-order-contents-text">です。<br />間違いがなければ申し込むを選択してください。</p>
      </div>

      <div class="blc">
        <div class="btn-areaR">
          <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="onNext">
            申し込む
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkbox {
  margin-right: 10px;
}

.btn-areaR {
  margin: 100px auto 0;
  text-align: center;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { RemainingWorkReceptionRecord } from '@/shared/classes/platform/remaining-work-reception-record';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { PreferredDate } from '@/shared/classes/spf-api/remaining-work-preferred-date';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { REMSINING_WORK_STATUS } from '@/shared/const/remainingWork-status';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'platform-cancel-impossible',
  data: () => ({
    property: null as Property | null, // 物件情報
    member: null as Member | null, // 会員情報
    isLoading: false, // ローディング
    roomNumber: '', // 部屋番号
    isPhoneRequest: '', // 電話希望
    firstPreferredDate: null as string | null, // 第一希望日
    secondPreferredDate: null as string | null, // 第二希望日
    thirdPreferredDate: null as string | null, // 第三希望日
    firstPreferredDateForDb: null as string | null, // 第一希望日(DB用)
    secondPreferredDateForDb: null as string | null, // 第二希望日(DB用)
    thirdPreferredDateForDb: null as string | null, // 第三希望日(DB用)
    firstPreferredDateType: null as string | null, // 第一希望日種別 (指定なし,午前,午後)
    secondPreferredDateType: null as string | null, // 第二希望日種別 (指定なし,午前,午後)
    thirdPreferredDateType: null as string | null, // 第三希望日種別 (指定なし,午前,午後)
    firstPreferredDateTypeNum: null as string | null, // 第一希望日種別 ('"0","1","2")
    secondPreferredDateTypeNum: null as string | null, // 第二希望日種別 ('"0","1","2")
    thirdPreferredDateTypeNum: null as string | null, // 第三希望日種別 ('"0","1","2")
    selectedPhone: null, // 電話希望の有無
    preferredDates: null as PreferredDate[] | null, //希望日昇華処理用
    phoneNumber: '',
  }),
  components: {
    LoadingComponent,
  },

  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    // 電話希望の有無をストアから取得
    this.selectedPhone = this.$store.getters['selectedBusinessDayStateStore/selectedPhone'];

    // 会員情報を取得（UCOM新規登録後の情報が反映されるようにdispatchにする）
    this.member = await this.$store.dispatch('memberStore/member');
    // 物件情報取得
    this.property = this.$store.getters['propertyStore/property'];

    //電話番号の取得
    this.phoneNumber = this.$store.getters['selectedBusinessDayStateStore/phoneNumber'];

    //電話が希望されていない場合は、希望日を取得
    if (this.selectedPhone == 1) {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      this.firstPreferredDate = this.$store.getters['selectedBusinessDayStateStore/selectedBusinessDayForFirst'];
      this.secondPreferredDate = this.$store.getters['selectedBusinessDayStateStore/selectedBusinessDayForSecond'];
      this.thirdPreferredDate = this.$store.getters['selectedBusinessDayStateStore/selectedBusinessDayForThird'];
      this.firstPreferredDateTypeNum = this.$store.getters['selectedBusinessDayStateStore/selectedTimeOfDayForFirst'];
      this.secondPreferredDateTypeNum = this.$store.getters['selectedBusinessDayStateStore/selectedTimeOfDayForSecond'];
      this.thirdPreferredDateTypeNum = this.$store.getters['selectedBusinessDayStateStore/selectedTimeOfDayForThird'];

      // YYYY年MM月DD日 に変換 YYYY/MM/DD に変換
      if (this.firstPreferredDate != null) {
        this.firstPreferredDate = this.japaneseformatDate(this.firstPreferredDate);
        this.firstPreferredDateForDb = this.formatDateForDb(this.firstPreferredDate);
      }
      if (this.secondPreferredDate != null) {
        this.secondPreferredDate = this.japaneseformatDate(this.secondPreferredDate);
        this.secondPreferredDateForDb = this.formatDateForDb(this.secondPreferredDate);
      }
      if (this.thirdPreferredDate != null) {
        this.thirdPreferredDate = this.japaneseformatDate(this.thirdPreferredDate);
        this.thirdPreferredDateForDb = this.formatDateForDb(this.thirdPreferredDate);
      }

      // 指定なし,午前,午後 に変換
      if (this.firstPreferredDateTypeNum != null) {
        this.firstPreferredDateType = this.formatTimeOfDay(this.firstPreferredDateTypeNum);
      }
      if (this.secondPreferredDateTypeNum != null) {
        this.secondPreferredDateType = this.formatTimeOfDay(this.secondPreferredDateTypeNum);
      }
      if (this.thirdPreferredDateTypeNum != null) {
        this.thirdPreferredDateType = this.formatTimeOfDay(this.thirdPreferredDateTypeNum);
      }
    }

    // データ不整合エラーは総合トップ(会員専用トップ)へ
    if (
      !this.member ||
      !this.property ||
      !this.phoneNumber ||
      (this.selectedPhone === null && this.firstPreferredDate == null && this.secondPreferredDate == null && this.thirdPreferredDate == null)
    ) {
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
    }

    // 部屋番号をストアから取得
    // five.Aの場合
    if (this.property?.uaType === UA_TYPE.FIVE_A) {
      this.roomNumber = this.$store.getters['remainingWorkStore/RoomNumber'];
      if (!this.roomNumber) {
        // ストアに部屋番号がなければ、Five.A 部屋番号入力に遷移
        await this.$router.push('/5a/remaining-work/room-number-input').catch((error: any) => {
          checkRouterError(error);
        });
      }
      // UCOMの場合
    } else if (this.property?.uaType === UA_TYPE.UCOM) {
      // 契約基本情報取得API(外部 UCOM)を実行
      const customer = await this.$store.dispatch('ucomCommonStore/customer', this.member!.primaryKeyUcom);
      this.roomNumber = customer.room_number;
      if (!this.roomNumber) {
        // ストアに部屋番号がなければ、総合トップに遷移
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
      }
    }
    this.isLoading = false;
  },

  methods: {
    // 日付を "YYYY年MM月DD日" 形式に変換
    japaneseformatDate(date: string) {
      const [year, month, day] = date.split('-');
      return `${year}年${month}月${day}日`;
    },

    // 日付を "YYYY/MM/D" 形式に変換(DB用)
    formatDateForDb(date: string) {
      return date.replace(/年|月/g, '/').replace('日', '');
    },

    // 指定なし,午前,午後 に変換
    formatTimeOfDay(value: string) {
      if (value === '0') {
        return '指定なし';
      } else if (value === '1') {
        return '午前';
      } else if (value === '2') {
        return '午後';
      }
      return value;
    },

    // 申し込むを押下
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        // 電話希望かどうか
        if (this.selectedPhone == 2) {
          this.isPhoneRequest = '1';
        } else {
          this.isPhoneRequest = '0';
        }

        // 第一希望（第二希望）がNULLの場合、第二希望(第三希望)を昇華させる
        // 希望日のデータをセット
        this.preferredDates = [
          { date: this.firstPreferredDateForDb, type: this.firstPreferredDateTypeNum },
          { date: this.secondPreferredDateForDb, type: this.secondPreferredDateTypeNum },
          { date: this.thirdPreferredDateForDb, type: this.thirdPreferredDateTypeNum },
        ];

        // 昇華処理：空の要素を取り除いて昇華させる
        this.preferredDates = this.preferredDates.filter((item) => item.date != null);

        // 必要に応じてNULLを埋める
        while (this.preferredDates.length < 3) {
          this.preferredDates.push({ date: null, type: null });
        }

        // 最終的な結果オブジェクトを作成
        const preferredDatesResult = {
          firstPreferredDate: this.preferredDates[0].date,
          firstPreferredDateType: this.preferredDates[0].type,
          secondPreferredDate: this.preferredDates[1].date,
          secondPreferredDateType: this.preferredDates[1].type,
          thirdPreferredDate: this.preferredDates[2].date,
          thirdPreferredDateType: this.preferredDates[2].type,
        };

        // 残工事受付記録登録API用のデータを格納
        const remainingWorkReceptionRecord = new RemainingWorkReceptionRecord({
          memberId: this.member!.id,
          roomNumber: this.roomNumber,
          uaType: this.property?.uaType,
          firstPreferredDate: preferredDatesResult.firstPreferredDate,
          firstPreferredDateType: preferredDatesResult.firstPreferredDateType,
          secondPreferredDate: preferredDatesResult.secondPreferredDate,
          secondPreferredDateType: preferredDatesResult.secondPreferredDateType,
          thirdPreferredDate: preferredDatesResult.thirdPreferredDate,
          thirdPreferredDateType: preferredDatesResult.thirdPreferredDateType,
          isPhoneRequest: this.isPhoneRequest,
          phoneNumber: this.phoneNumber,
          status: REMSINING_WORK_STATUS.RECEPTION,
        });

        // 残工事受付記録登録API
        await SpfApiService.remainingWorkReceptionRecord(remainingWorkReceptionRecord);

        // 宅内日選択 完了画面へ
        await this.$router.push('/select-construction-date/complete').catch((e: any) => {
          checkRouterError(e);
        });
      } catch {
        // 共通エラーに遷移
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.isLoading = false;
    },
    // 戻るを押下時
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // 宅内工事日選択 入力画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return await this.$router.push('/select-construction-date/input').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
<style>
.preferenceForPhone {
  padding: 0 15px;
  margin-top: 5px;
  margin-bottom: 0;
  text-align: left;
}

.preferenceForPhoneInput {
  color: #333333;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.preferenceForPhoneInput {
  color: #333333;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.inputFormForPhone {
  padding: 0 15px;
  margin-bottom: 0;
  text-align: left;
  margin-top: 5px;
}
.confirm-order-contents-text {
  margin-top: 30px;
}
</style>
