<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>メールボックス容量変更 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>メールボックス容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールボックス容量" />メールボックス容量</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <div class="blc">
        <error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th>アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th>現在の容量</th>
              <td>{{ mailBoxCapacity }}.0GB</td>
            </tr>
            <tr>
              <th>追加／縮小容量</th>
              <td>
                <b>{{ changeCapacityLabel }}</b>
                <input type="hidden" :value="changeCapacityValue" />
              </td>
            </tr>
            <tr>
              <th>月額利用料</th>
              <td>9.0GB 毎に月額{{ price }}円（税込）<br />最大10.0GBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th>お申し込み日</th>
              <td>{{ effectiveDate }}</td>
            </tr>
            <tr>
              <th>サービス説明</th>
              <td v-html="serviceDescription"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="onBack()">
            <i class="material-icons link link-icon">west</i>
            戻る
          </button>
          <button class="btn btn01 bs" v-on:click="onApply()">
            申し込む
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiMailboxCapacityAccessor } from '@/infra/accessor/spf/ucom/spf-api-mailbox-capacity-accessor';
import { UpdateUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/update-ucom-mb-capacity-request';
import { UcomMailboxCapacityConfirmInfo } from '@/shared/classes/spf-api/mail/model/ucom/mailbox-capacity/ucom-mailbox-capacity-confirm-info';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'mailbox-capacity-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** UCOM_アカウントメールID */
    ucomAccountMailId: undefined as undefined | number,
    /** アカウントID */
    accountName: '',
    /** メールアドレス */
    mailAddress: '',
    /** メールボックス容量 */
    mailBoxCapacity: '',
    /** 追加／縮小容量ラベル */
    changeCapacityLabel: '',
    /** 追加／縮小容量バリュー */
    changeCapacityValue: '',
    /** 単価 */
    price: '',
    /** 申込日 */
    effectiveDate: '',
    /** サービス説明 */
    serviceDescription: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部タイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    /** ローディング */
    isLoading: false,
  }),
  async mounted() {
    this.isLoading = true;

    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      // 前画面からの情報をStoreから取得し画面データをセット
      await this.setDataByStore();

      // 戻るボタン押下時にstoreのucomAccountMailIdに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', undefined);
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },

    // storeから画面項目データをセット
    async setDataByStore(): Promise<void> {
      this.ucomAccountMailId = this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId'];
      this.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.changeCapacityValue = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
      this.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.effectiveDate = this.$store.getters['ucomMailboxCapacityStore/effectiveDate'];
      this.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
    },
    // 申し込むボタンクリック
    async onApply(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ初期化
      this.errorMessages = [];

      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];

      // ログイン確認処理
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 確認_更新API
      try {
        const requestDto = new UpdateUcomMbCapacityRequest(member.id, this.ucomAccountMailId!, this.changeCapacityLabel, this.changeCapacityValue, this.effectiveDate);
        const response = await SpfApiMailboxCapacityAccessor.updateUcomMbCapacity(requestDto);
        // エラー処理
        if (response.errorMessages && response.errorMessages.length > 0) {
          this.errorMessages = response.errorMessages;
          // エラーメッセージを見せるために画面最上部にスクロール
          this.scrollPageTop();
          this.isLoading = false;
          return;
        }
        // 確認更新API レスポンスをstoreに保存
        this.saveStore(response);
      } catch (e) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 完了画面へ遷移
      await this.$router.push('/ucom/mailbox-capacity/completed').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // storeに保存
    saveStore(response: UcomMailboxCapacityConfirmInfo): void {
      this.$store.commit('ucomMailboxCapacityStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.ucomAccountMailId);
      this.$store.commit('ucomMailboxCapacityStore/accountName', this.accountName);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', response.mailCapacity);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomMailboxCapacityStore/price', response.price);
      this.$store.commit('ucomMailboxCapacityStore/subscriptionDate', response.subscriptionDate);
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.serviceDescription);
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.ucomAccountMailId);
      await this.$router.push('/ucom/mailbox-capacity/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // ページトップへスクロール
    scrollPageTop(): void {
      window.scrollTo(0, 0);
    },
  },
});
</script>
