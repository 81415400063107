export const SUB_ACCOUNT_LINKAGE_FROM = {
    E_MANSION: 'emansion',
    UCOM: 'ucom-r',
} as const;
export type SUB_ACCOUNT_LINKAGE_FROM = typeof SUB_ACCOUNT_LINKAGE_FROM[keyof typeof SUB_ACCOUNT_LINKAGE_FROM];

export const convertSubAccountLinkageFrom = (value: string): SUB_ACCOUNT_LINKAGE_FROM | undefined => {
    for (const v of Object.values(SUB_ACCOUNT_LINKAGE_FROM)) {
        if (String(v) == value) {
            return v;
        }
    }
};
