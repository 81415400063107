import { Module } from 'vuex';
import { RootState } from '../index';

/** Platform 退会 State */
const platformCancelState: {
  linkedServices: string[]; // 連携サービス情報
} = {
  /** Platform 退会 連携サービス情報 */
  linkedServices: [],
};

/** State の型 */
type PlatformCancelState = typeof platformCancelState;

/** Platform 退会 Store */
export const platformCancelStore: Module<PlatformCancelState, RootState> = {
  namespaced: true,
  state: platformCancelState,
  mutations: {
    linkedServices: (state, value: string[]) => (state.linkedServices = value)
  },
  getters: {
    linkedServices: (state): string[] => state.linkedServices
  }
};
