/** UCOM お支払情報登録状態 */
export const UCOM_REGISTERED_PAYMENT = {
  NOT_REGISTERED: { id: 0, value: '未登録' },
  REGISTERED: { id: 1, value: '登録済み' },
} as const;

export type UCOM_REGISTERED_PAYMENT = typeof UCOM_REGISTERED_PAYMENT[keyof typeof UCOM_REGISTERED_PAYMENT];

export const convertUcomRegisteredPaymentById = (id: number): UCOM_REGISTERED_PAYMENT | undefined => {
  for (const v of Object.values(UCOM_REGISTERED_PAYMENT)) {
    if (Number(v.id) == id) {
      return v;
    }
  }
};
