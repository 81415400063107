/** e-mansion Connectix 申し込み 入力フォーム */
export class EMansionConnectixEntryInputForm {
  /** 部屋番号 */
  public roomNumber!: string;
  /** 棟ID */
  public buildingId!: string;
  /** 棟名表示用 */
  public buildingName!: string;
  /** 物件ID */
  public propertyId!: string;

  constructor(partial: Partial<EMansionConnectixEntryInputForm>) {
    Object.assign(this, partial);
  }
}

/** e-mansion API : Connectix 部屋番号一覧 */
export class EMansionConnectixPropertyRooms {
  /** 結果値 */
  public rooms!: Array<EMansionConnectixPropertyRoom>;

  constructor(partial: Partial<EMansionConnectixPropertyRooms>) {
    Object.assign(this, partial);
  }
}
export class EMansionConnectixPropertyRoom {
  /** お申し込み可能な部屋一覧 */
  /** 棟ID */
  public building_id!: string;
  /** 棟名 */
  public building_name!: string;
  /** 部屋番号 */
  public room_number!: string;
  /** Connectixお申し込みの可/不可 */
  public apply_connectix_id!: string;
  /** 入会の可/不可 */
  public join_id!: string;
  /** 物件_id */
  public property_id!: string;

  constructor(partial: Partial<EMansionConnectixPropertyRoom>) {
    Object.assign(this, partial);
  }
}
