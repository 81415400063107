import { REVISION_UP_ERROR } from '@/shared/const/error/error-info';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import axios from 'axios';
import router from '@/router';

export class RevisionHandler {

  public static async checkRevisionError(error: Error) {

    if (RevisionHandler.isRevisionError(error) && await AuthService.isLoggedIn()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await router.push('/login-forwarding').catch((error: any) => {
        checkRouterError(error);
      });
      return true;
    }

    // ログインしていないパターン
    if (RevisionHandler.isRevisionError(error)) {
      location.reload();
      return true;
    }

    return false;
  }

  private static isRevisionError(error: Error): boolean {
    if (error instanceof axios.Cancel) {
      return true;
    }
    return error.message.indexOf(REVISION_UP_ERROR.CANCEL) > -1;// ダサいがUnhandledredrejectionの場合、型で判定できないためこの形になる
  }

}