import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8ea8f4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-messages-component"
}
const _hoisted_2 = { class: "err" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "error-messages-component"
}
const _hoisted_7 = { class: "err" }
const _hoisted_8 = { class: "red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isOneSentence && _ctx.errorMessages.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.errorMessageTitle)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("p", {
                    class: "red",
                    innerHTML: _ctx.errorMessageTitle
                  }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessages, (errorMessage, index) => {
              return (_openBlock(), _createElementBlock("div", { key: errorMessage }, [
                _createElementVNode("ul", {
                  class: _normalizeClass(["ul-basic01", { 'is-isp-linking-style': _ctx.isIspLinkingStyle, 'margin-top-0': _ctx.isIspLinkingStyle && index === 1 }])
                }, [
                  _createElementVNode("li", { innerHTML: errorMessage }, null, 8, _hoisted_5)
                ], 2)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isOneSentence && _ctx.errorMessages.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.errorMessages[0]), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}