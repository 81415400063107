/**
 * レンタルBBR申し込みにて使用するラベルの定数
 */
export const RENTAL_BBR_LABELS = {
  /** 機器名 */
  BBR_NAME: 'WSR-1500AX2S',
  /** 価格 */
  BBR_PRICE: '0'
} as const;

export type RENTAL_BBR_LABELS = typeof RENTAL_BBR_LABELS[keyof typeof RENTAL_BBR_LABELS];