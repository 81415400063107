import gamewithIndex from '@/pages/gamewith/index.vue';
import gamewithMemberEdit from '@/pages/gamewith/member-edit.vue';
import gamewithMemberConfirm from '@/pages/gamewith/member-confirm.vue';
import gamewithMemberCompleted from '@/pages/gamewith/member-completed.vue';
import gamewithBillingHistory from '@/pages/gamewith/billing-history.vue';

const gamewithRouter = [
  {
    path: '/gamewith',
    name: 'GameWith光 会員専用トップ',
    component: gamewithIndex,
    meta: {
      title: 'Portas | GameWith光 会員専用トップ | ポルタス',
      description: 'Portas（ポルタス）GameWith光 会員専用トップになります。',
      keywords: 'GameWith光 会員専用トップ',
    },
  },
  {
    path: '/gamewith/member-edit',
    name: 'GameWith光 登録情報変更',
    component: gamewithMemberEdit,
    meta: {
      title: 'Portas | GameWith光 登録情報変更 | ポルタス',
      description: 'Portas（ポルタス）GameWith光 登録情報変更になります。',
      keywords: 'GameWith光 登録情報変更',
    },
  },
  {
    path: '/gamewith/member-confirm',
    name: 'GameWith光 登録情報変更確認',
    component: gamewithMemberConfirm,
    meta: {
      title: 'Portas | GameWith光 登録情報変更確認 | ポルタス',
      description: 'Portas（ポルタス）GameWith光 登録情報変更確認になります。',
      keywords: 'GameWith光 登録情報変更確認',
    },
  },
  {
    path: '/gamewith/member-completed',
    name: 'GameWith光 登録情報変更完了',
    component: gamewithMemberCompleted,
    meta: {
      title: 'Portas | GameWith光 登録情報変更完了 | ポルタス',
      description: 'Portas（ポルタス）GameWith光 登録情報変更完了になります。',
      keywords: 'GameWith光 登録情報変更完了',
    },
  },
  {
    path: '/gamewith/billing-history',
    name: 'GameWith光 請求明細',
    component: gamewithBillingHistory,
    meta: {
      title: 'Portas | GameWith光 請求明細 | ポルタス',
      description: 'Portas（ポルタス）GameWith光 請求明細になります。',
      keywords: 'GameWith光 請求明細',
    },
  },
];
export default gamewithRouter;
