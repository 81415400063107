<template>
  <div class="remaining-work-external-result">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>宅内工事確認結果</h1>
    </main>

    <div class="contents">
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li>入力</li>
        <li class="stay">確認結果</li>
      </ul>
      <!-- /application-flow -->
      <div class="blc">
        <div class="p-bold">
          <div v-if="isRemainingWork === '1' && uaType === '2'">
            <p>
              インターネット接続のための工事が必要です。<br />
              Portasに新規会員登録をしていただき、会員専用TOPから工事をお申し込みください。
            </p>
          </div>
          <div v-if="isRemainingWork === '1' && uaType === '3'">
            <p>
              インターネット接続のための宅内工事が必要です。<br />
              Portasに新規会員登録後、UCOM光レジデンスへ会員登録いただき宅内工事をお申し込みください。
            </p>
          </div>
          <div v-if="isRemainingWork === '0'">
            <p>
              ご入力いただいたお部屋番号は宅内工事が完了している、もしくはPortasからの宅内工事申込の対象ではございません。<br />
              <br />
              <br />
              以下のいずれかの条件に該当する場合、オンラインでの工事申込を受け付けることが出来ません。<br />
              Portasから宅内工事申込の対象とならないケース<br />
            </p>
            <ul>
              <li>建物全体の工事完了後の一定期間</li>
              <li>ご入力いただいたお部屋番号と弊社の登録内容が一致しない場合</li>
              <li>その他、ご入力いただいたお部屋番号の状況確認が出来ない場合</li>
            </ul>
            <div v-if="uaType === '2'">
              <p>
                インターネット接続が出来ない場合は、お手数ではございますが<a class="link" :href="`${domainName}/${apid}/ft5/`" target="_blank">こちら</a
                >からお問い合わせ先を確認いただき、サポートセンターまでお問い合わせください。
              </p>
            </div>
            <div v-else-if="uaType === '3'">
              <p>
                インターネット接続が出来ない場合は、お手数ではございますが<a class="link" :href="`${ucomLink}`" target="_blank">こちら</a
                >からお問い合わせ先を確認いただき、サポートセンターまでお問い合わせください。
              </p>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="my-button">
            <button class="topbtn btn01 bs main-login" v-on:click="onRegister" v-if="isRemainingWork === '1'">
              Portas新規会員登録へ<i class="material-icons link link-icon">east</i>
            </button>
            <button @click="onBack()" class="btn btn05" v-if="isRemainingWork === '0'">戻る<i class="material-icons link link-icon">west</i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.application-flow {
  padding: 0;
}

.p-bold {
  font-weight: bold;
  margin-bottom: 110px;
}

@media (max-width: 720px) {
  .p-bold {
    margin-bottom: 80px;
  }
}

button.topbtn {
  appearance: none;
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  padding: 12px 25px;
  line-height: 24px;
  font-size: 15px;
  min-width: 250px;
  margin: 0 10px;
  top: 29px;
  box-sizing: border-box;
  position: relative;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { PROPERTY_SEARCH_TYPE, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId, convert8DigitIdTo6digitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
export default defineComponent({
  name: 'external-remaining-work-result',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
    isRemainingWork: '', // 残工事の有無
    apid: null as string | null, // apid
    transitionSourceServiceId: null as string | null, // apidを変換した物件ID
    buildingId: null as string | null, // 取得した物件ID
    uaType: null as string | null, // UA種別
    fiveAUrl: process.env.VUE_APP_FIVE_A_SERVICE_DOMAIN_NAME, // fiveA URL
    ucomUrl: process.env.VUE_APP_UCOM_URL, // UCOM URL
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '', // 「こちら」リンク用
    ucomLink: '', // UCOM「こちら」リンク
  }),
  async mounted(): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    // 残工事の有無 と apid を取得する
    this.isRemainingWork = this.$store.getters['remainingWorkStore/remainingWork'];
    this.apid = this.$store.getters['remainingWorkStore/apid'];
    this.buildingId = this.$store.getters['remainingWorkStore/buildingId'];

    try {
      let searchId: string;
      let propertyType: number | undefined;

      if (this.apid) {
        this.uaType = UA_TYPE.FIVE_A;
        // APID → apartment_idに変換
        this.transitionSourceServiceId = convert8DigitIdTo6digitId(String(this.apid));
        searchId = this.transitionSourceServiceId;
        propertyType = PROPERTY_SEARCH_TYPE.E_MANSION;
      } else if (this.buildingId) {
        this.uaType = UA_TYPE.UCOM;
        searchId = this.buildingId;
        propertyType = PROPERTY_SEARCH_TYPE.UCOM;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }

      // 物件検索API実行
      const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(propertyType, searchId, false);

      //ドメイン名の取得
      if (propertyList instanceof SearchResultOver || propertyList.length === 0 || propertyList.length >= 2) {
        // 検索結果が11件以上 || 取得件数がl件でない場合 でありえないエラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      } else {
        if (this.uaType === UA_TYPE.UCOM) {
          this.ucomLink = `${process.env.VUE_APP_UCOM_URL}/${convert6DigitIdTo8DigitId(String(propertyList[0].apartmentId))}/`;
        }

        if (this.uaType === UA_TYPE.FIVE_A && propertyList[0].oemType) {
          this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(propertyList[0].oemType));
          if (filtered.length >= 1) {
            let latestOem = filtered[0];
            if (filtered.length >= 2) {
              for (let i = 1; i < filtered.length; i++) {
                if (latestOem.startAt < filtered[i].startAt) {
                  latestOem = filtered[i];
                }
              }
            }
            this.domainName = latestOem.domainForOsumai;
          }
        } else if (this.uaType === UA_TYPE.FIVE_A) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
        }
      }
    } catch (error: any) {
      // 共通エラーに遷移
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    this.isLoading = false;
  },
  methods: {
    // 新規会員登録へ
    async onRegister() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await AuthService.regist();
      this.isLoading = false;
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      if (this.uaType === UA_TYPE.UCOM) {
        await this.$router
          .push(`/remaining-work/external/room-number-input?apid=${this.buildingId}`)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (this.uaType === UA_TYPE.FIVE_A) {
        await this.$router
          .push(`/remaining-work/external/room-number-input?apid=${this.apid}`)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
});
</script>
