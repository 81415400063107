<template>
  <div class="my-page-member-edit">
    <LoadingComponent v-if="isMounting || isSubmitting" />
    <main class="underlayer-main">
      <h1>GameWith光 登録情報変更</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/gamewith">GameWith光マイページ</router-link></li>
        <li>登録情報変更</li>
      </ul>
      <div class="blc">
        <p>GameWith光は、株式会社GameWithが提供する光ファイバーインターネットサービスです。</p>
      </div>
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">登録情報変更 入力</li>
        <li>登録情報変更 確認</li>
        <li>登録情報変更 完了</li>
      </ul>

      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages.length > 0" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="会員情報変更" />GameWith光 登録情報変更</h2>
        <p class="att red mt10 delete-indent">
          GameWith光インターネットお申し込み時にご登録いただいた情報を変更します。「Portas」の会員情報には反映されません。Portasの会員情報変更は「Portas
          マイページ」よりお手続きください。
        </p>

        <table class="table-type2">
          <tbody>
            <tr class="border">
              <th class="va-middle">お名前<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="姓" v-model="form.lastName" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.lastName }}</span
                >&emsp;<input type="text" class="text middle sp-mt10" placeholder="名" v-model="form.firstName" v-if="!gamewithCustomer?.scheduledDate" />
                <span v-else>{{ form.firstName }}</span>
              </td>
            </tr>
            <tr class="border">
              <th class="va-middle">フリガナ<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="セイ" v-model="form.lastNameKana" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{
                  form.lastNameKana
                }}</span
                >&emsp;<input type="text" class="text middle sp-mt10" placeholder="メイ" v-model="form.firstNameKana" v-if="!gamewithCustomer?.scheduledDate" />
                <span v-else>{{ form.firstNameKana }}</span>
              </td>
            </tr>
            <tr class="border">
              <th class="va-middle">メールアドレス<span v-if="!canNotEdit" class="req">必須</span></th>
              <td>
                <input
                  type="text"
                  class="text middle"
                  placeholder="example@yourdomain.com"
                  pattern="^([a-zA-Z0-9])+([a-zA-Z0-9\._\-])*@[0-9A-Za-z]+([\-\.][0-9A-Za-z]+)*\.[a-zA-Z]{2,}$"
                  v-model="form.email"
                  v-if="!canNotEdit"
                />
                <span v-else>{{ form.email }}</span>
              </td>
            </tr>
            <tr class="border">
              <th class="va-middle">電話番号<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="000-0000-0000" v-model="form.phone" v-if="!gamewithCustomer?.scheduledDate" />
                <span v-else>{{ form.phone }}</span>
              </td>
            </tr>
            <tr>
              <th>郵便番号<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="000-0000" v-model="form.postalCode" v-if="!gamewithCustomer?.scheduledDate" />
                <span v-else>{{ form.postalCode }}</span>
              </td>
            </tr>
            <tr>
              <th>都道府県<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" v-model="form.state" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.state }}</span>
              </td>
            </tr>
            <tr>
              <th>市区郡<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" v-model="form.city" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.city }}</span>
              </td>
            </tr>
            <tr>
              <th>町名<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" v-model="form.street" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.street }}</span>
              </td>
            </tr>
            <tr>
              <th>丁目・番地・号<span v-if="!gamewithCustomer?.scheduledDate" class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" v-model="form.banchi" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.banchi }}</span>
              </td>
            </tr>
            <tr>
              <th>建物名</th>
              <td>
                <input type="text" class="text middle" v-model="form.tatemono" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.tatemono }}</span>
              </td>
            </tr>
            <tr class="border">
              <th>部屋番号</th>
              <td>
                <input type="text" class="text middle" v-model="form.roomNumber" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.roomNumber }}</span>
              </td>
            </tr>
            <tr>
              <th>転用承諾番号</th>
              <td>
                <input type="text" class="text middle" v-model="form.switchingNumberNTT" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{ form.switchingNumberNTT }}</span
                ><br />
                <b>例:E0315000100(E/Wから始まる10桁)</b>
                <p>※現在フレッツ光回線をご利用中の方はご入力ください。</p>
              </td>
            </tr>
            <tr class="border">
              <th>事業者変更承諾番号</th>
              <td>
                <input type="text" class="text middle" v-model="form.switchingNumberCollabo" v-if="!gamewithCustomer?.scheduledDate" /><span v-else>{{
                  form.switchingNumberCollabo
                }}</span
                ><br />
                <b>例:F0315000100(F/Tから始まる10桁)</b>
                <p>※現在他社光コラボ回線をご利用中の方はご入力ください。</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="blc">
        <p class="form-btn-txt">変更内容をご確認の上、よろしければ「次へ」ボタンを押してください。</p>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()">戻る<i class="material-icons link link-icon">west</i></button>
          <button class="btn bs" :class="{ btn01: !canNotEdit, btn04: canNotEdit }" @click="onNext()" :disabled="canNotEdit">
            次へ<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-type2 tr {
  border-bottom: none;
}
.table-type2 tr.border {
  border-bottom: 1px solid #d3d3d3;
}
.underlayer-main {
  background-image: url('../../images/main.png');
}

.search-button {
  position: relative;
  top: 2px;
  left: 2px;
}

.search-error-text {
  background-color: #ebecf177;
  padding: 10px;
  width: 580px;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

.delete-indent {
  text-indent: 0em;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { required } from '@vuelidate/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { emailAddressForGamewith } from '@/shared/util/validators';

import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import ResidenceRegistrationController from '@/shared/components/platform/residence-registration-controller.vue';
import useVuelidate from '@vuelidate/core';
import { GameWithEditInputForm } from '@/shared/classes/gamewith/edit-input-form';
import { Property } from '@/shared/classes/spf-api/property';
import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT, FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { GamewithCustomerInfoResponse } from '@/infra/response/spf/gamewith/gamewith-customer-info-response';
import { PLAN_FIX } from '@/shared/const/gamewith/plan-fix';
import { CONTRACT_STATUS } from '@/shared/const/gamewith/contract-status';

/** dataオブジェクトの型  */
type DataType = {
  form: GameWithEditInputForm; // 会員情報変更フォーム
  property: Property | null;
  displayProperty: string; // 物件の表示用文字列
  searchKey: string; // 名称検索フォーム
  isPerfectMatch: boolean; //完全一致検索か
  errorMessages: string[];
  errorMessageTitle: string;
  eMansionName: string;
  isSubmitting: boolean; //ボタン押下対応
  isMounting: boolean; // vueのmounted実行中
  member: Member | null;
  memberStatus: MemberStatus | null;
  memberStatusType: typeof MEMBER_STATUS;
  existPropertyIdTiedToMember: boolean;
  isLinkedToGameWithH: boolean; //GameWith光と連携しているか。
  isCanceled: boolean; //ISP退会済み（退会日（quit_date）<現在日時）か
  gamewithCustomer: GamewithCustomerInfoResponse | undefined;
  canNotEdit: boolean; //編集不可（Proかつ工事日確定済みの場合）
};

/** MemberEdit コンポーネント */
export default defineComponent({
  name: 'my-page-member-edit',
  components: {
    ErrorMessagesComponent,
    ResidenceRegistrationController,
    LoadingComponent,
  },
  data(): DataType {
    return {
      form: {
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        email: '',
        phone: '',
        postalCode: '',
        state: '',
        city: '',
        street: '',
        banchi: '',
        tatemono: '',
        roomNumber: '',
        switchingNumberNTT: '',
        switchingNumberCollabo: '',
      },
      property: null,
      displayProperty: '',
      searchKey: '',
      isPerfectMatch: false,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      eMansionName: '',
      isSubmitting: false,
      isMounting: true,
      member: null,
      memberStatus: {} as MemberStatus,
      memberStatusType: MEMBER_STATUS,
      existPropertyIdTiedToMember: false,
      isLinkedToGameWithH: false,
      isCanceled: false,
      gamewithCustomer: undefined,
      canNotEdit: false,
    };
  },
  validations: {
    form: {
      // 姓 + '空白' + 名 が 30文字以内
      firstName: { required },
      lastName: { required },
      firstNameKana: { required },
      lastNameKana: { required },
      email: { required, emailAddressForGamewith },
      phone: { required },
      postalCode: { required },
      state: { required },
      city: { required },
      street: { required },
      banchi: { required },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    window.scrollTo(0, 0);
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    if (!memberStatus.encryptedPrimaryKeyGw) {
      //データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    }
    try {
      this.gamewithCustomer = await SpfApiExternalGamewithAccessor.getCustomer(memberStatus.encryptedPrimaryKeyGw);
      if (!this.gamewithCustomer) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
      }
    } catch (error: any) {
      if (error.response?.status == '503') {
        this.errorMessages.push(
          'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
        );
        this.errorMessageTitle = '';
        this.canNotEdit = true;
        this.showErrorMessage();
        return;
      }
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
    }
    //キャンセル、解約/廃止&閉鎖フラグ=TRUEの時GW光マイページへ移動
    if (this.isCancelGamewith(this.gamewithCustomer.contractStatus, this.gamewithCustomer.portasCloseFlag)) {
      await this.$router.push('/gamewith').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    this.form = new GameWithEditInputForm(this.gamewithCustomer);
    // 確認画面から戻った際にstoreに会員情報が残っていれば表示する
    // vue-routerのbeforeEnterにて、ストアの値保持する/しないの処理
    if (this.$store.getters['gamewithMemberEditStore/gamewithEditInputForm']) {
      this.form = this.$store.getters['gamewithMemberEditStore/gamewithEditInputForm'];
    }
    if (this.gamewithCustomer.planFix == PLAN_FIX.PRO && this.gamewithCustomer.scheduledDate) {
      this.errorMessages.push('工事日確定後は登録情報を編集することはできません');
      this.errorMessageTitle = '';
      this.canNotEdit = true;
      this.showErrorMessage();
      return;
    }
    this.isMounting = false;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isMounting = false;
      this.isSubmitting = false;
    },

    async onBack(): Promise<void> {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      await this.$router
        .push('/gamewith')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**
     * ステータスが解約または廃止ならtrue
     * @param status
     */
    isCancelGamewith(status: string, closedFlag: boolean): boolean {
      if (status == CONTRACT_STATUS.APPLICATION_CANCELED) {
        return true;
      }
      if (status == CONTRACT_STATUS.ABOLITION || status == CONTRACT_STATUS.CANCELED) {
        if (closedFlag == true) {
          return true;
        }
      }
      return false;
    },
    validateForm() {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      // 氏名欄チェック
      if (this.v$.form.firstName?.required.$invalid || this.v$.form.lastName?.required.$invalid) {
        this.errorMessages.push(`お名前を入力してください。`);
      } else {
        if (this.form.lastName.length > 80) {
          this.errorMessages.push('お名前(姓)は80文字以内で入力してください');
        }
        if (this.form.firstName.length > 40) {
          this.errorMessages.push('お名前(名)は40文字以内で入力してください');
        }
      }
      // フリガナ欄チェック
      if (this.v$.form.firstNameKana?.required.$invalid || this.v$.form.lastNameKana?.required.$invalid) {
        this.errorMessages.push(`フリガナを入力してください。`);
      } else {
        if (this.form.lastNameKana.length > 80) {
          this.errorMessages.push('フリガナ(セイ)は80文字以内で入力してください');
        }
        if (this.form.firstNameKana.length > 80) {
          this.errorMessages.push('フリガナ(メイ)は80文字以内で入力してください');
        }
      }
      // メールアドレスチェック
      if (this.v$.form.email?.required.$invalid) {
        this.errorMessages.push(`メールアドレスを入力してください。`);
      } else if (this.v$.form.email?.emailAddressForGamewith.$invalid) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      } else if (this.form.email.length > 80) {
        this.errorMessages.push('メールアドレスは80文字以内で入力してください');
      }
      // 電話番号欄チェック
      if (this.v$.form.phone?.required.$invalid) {
        this.errorMessages.push(`電話番号を入力してください。`);
      } else if (this.form.phone.length > 40) {
        this.errorMessages.push('電話番号は40文字以内で入力してください');
      }
      // 建物名欄チェック
      if (this.form.tatemono?.length > 80) {
        this.errorMessages.push('建物名は80文字以内で入力してください');
      }

      // 部屋番号欄チェック
      if (this.form.roomNumber?.length > 80) {
        this.errorMessages.push('部屋番号は80文字以内で入力してください');
      }

      //郵便番号欄チェック
      if (this.v$.form.postalCode?.required.$invalid) {
        this.errorMessages.push(`郵便番号を入力してください。`);
      } else if (this.form.postalCode.length > 20) {
        this.errorMessages.push(`郵便番号は20文字以内で入力してください`);
      }
      //都道府県チェック
      if (this.v$.form.state?.required.$invalid) {
        this.errorMessages.push(`都道府県を入力してください。`);
      } else if (this.form.state.length > 80) {
        this.errorMessages.push(`都道府県は80文字以内で入力してください`);
      }
      //市区郡チェック
      if (this.v$.form.city?.required.$invalid) {
        this.errorMessages.push(`市区郡を入力してください。`);
      } else if (this.form.city.length > 40) {
        this.errorMessages.push(`市区郡は40文字以内で入力してください`);
      }
      //町名チェック
      if (this.v$.form.street?.required.$invalid) {
        this.errorMessages.push(`町名を入力してください。`);
      } else if (this.form.street.length > 255) {
        this.errorMessages.push(`町名は255文字以内で入力してください`);
      }
      //丁目・番地・号チェック
      if (this.v$.form.banchi?.required.$invalid) {
        this.errorMessages.push(`丁目・番地・号を入力してください。`);
      } else if (this.form.banchi.length > 80) {
        this.errorMessages.push(`丁目・番地・号は80文字以内で入力してください`);
      }
      //転用承諾番号チェック
      if (this.form.switchingNumberNTT?.length > 80) {
        this.errorMessages.push(`転用承諾番号は80文字以内で入力してください`);
      }
      //事業者変更承諾番号チェック
      if (this.form.switchingNumberCollabo?.length > 80) {
        this.errorMessages.push(`事業者変更承諾番号は80文字以内で入力してください`);
      }
    },
    async onNext(): Promise<void> {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      this.validateForm();
      if (this.errorMessages.length !== 0) {
        this.showErrorMessage();
        return;
      }
      //ストアに保存
      this.$store.commit('gamewithMemberEditStore/gamewithEditInputForm', this.form);
      //確認画面へ
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/gamewith/member-confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isSubmitting = false;
    },
  },
});
</script>
