<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>アカウントID 削除 確認</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>アカウントID削除</li>
      </ul>
      <!-- /breadcrumb -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <!-- blc:アカウントID情報 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="アカウントID削除確認" />アカウントID 削除 確認</h2>

        <div class="sblc">
          <div class="sblc">
            <p>削除するアカウントをご確認いただき、問題ない場合は「削除」ボタンを押してください。</p>

            <table class="table-type1" v-if="ucomAccountInfo">
              <tr>
                <th class="va-middle">アカウントID</th>
                <td>{{ ucomAccountInfo.accountName }}</td>
              </tr>
              <tr>
                <th class="va-middle">氏名</th>
                <td>{{ ucomAccountInfo.fullName }}</td>
              </tr>
              <tr>
                <th class="va-middle">メールアドレス</th>
                <td>{{ ucomAccountInfo.mailAddress }}</td>
              </tr>
              <tr>
                <th class="va-middle">アカウント権限種別</th>
                <td>{{ ucomAccountInfo.accountAuthorization }}</td>
              </tr>
              <tr>
                <th class="va-middle">注意事項</th>
                <td>
                  <ul class="ul-basic01">
                    <li>ログイン中のアカウントIDを削除した場合、削除完了時にログアウトされます。</li>
                    <li>アカウントIDを削除すると、同じアカウントID名での再登録はできません。</li>
                    <li>削除のキャンセルは行えません。</li>
                    <li>アカウントIDの削除は、即時行われます。</li>
                    <li>
                      該当のアカウントIDで利用していたメールアドレス、セキュリティオプション等、アカウントIDで設定されたオプションサービスも同時にご利用いただくことができなくなりますのでご注意ください。
                    </li>
                    <li>アカウントIDを削除された場合、各オプションサービスの当月月額利用料金は発生いたします。日割り計算は行われません。</li>
                    <li>同月にアカウントIDの登録・削除及びそれぞれにオプションサービスの申し込みを繰り返した場合、申し込みの回数ごとの月額利用料が発生いたします。</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- /blc:アカウントID情報 -->
      <!-- /contents -->
      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" v-on:click="goDelete()">削除<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import { SpfApiAccountInfoForDeleteAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-info-for-delete-accessor';
import { UcomAccountInfoForDelete } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-info-for-delete';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

type DataType = { isLoading: boolean; ucomAccountInfo: UcomAccountInfoForDelete | undefined };

export default defineComponent({
  name: 'account-info-for-delete',
  components: {
    LoadingComponent,
  },
  data: (): DataType => ({
    isLoading: true,
    ucomAccountInfo: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
      const date = this.$store.getters['ucomAccountManagementStore/deleteOn'];
      if (!ucomAccountId || !date) {
        //アカウントID管理一覧画面へ
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-management/list').catch((error: any) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
        return;
      }
      const formedDate = dayjs(date).format('YYYY-MM-DD');
      this.$data.ucomAccountInfo = await SpfApiAccountInfoForDeleteAccessor.getAccountInfo(ucomAccountId, formedDate);
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });

      this.$data.isLoading = false;
      return;
    }
  },

  methods: {
    /** 戻るボタン押下時 */
    async goBack() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**削除 確認ボタン押下時 */
    async goDelete() {
      if (this.$data.isLoading) {
        return;
      }
      if (this.$data.ucomAccountInfo == undefined) {
        return;
      }
      this.$data.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      if (this.$data.ucomAccountInfo.mailAddressType == UCOM_MAIL_ADDRESS_TYPE.UCOM_HIKARI.toString()) {
        const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];

        // 解約確認画面からメールアドレス変更画面へのリンク遷移で必要となる
        this.$store.commit('ucomAccountManagementDeleteStore/ucomAccountId', ucomAccountId);
        this.$store.commit('ucomAccountManagementDeleteStore/accountName', this.$data.ucomAccountInfo.accountName);
        this.$store.commit('ucomAccountManagementDeleteStore/mailAddress', this.$data.ucomAccountInfo.mailAddress);
        this.$store.commit('ucomAccountManagementDeleteStore/accountAuthorization', this.$data.ucomAccountInfo.accountAuthorization);
        this.$store.commit('ucomAccountManagementDeleteStore/mailAddressType', this.$data.ucomAccountInfo.mailAddressType);

        /** SU14-013 メールサービス解約確認画面 */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-management/delete-confirmation').catch((error: any) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
      } else {
        /** SU14-014 削除完了画面 */
        const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];

        try {
          const deletedUcomAccountInfo = await SpfApiAccountInfoForDeleteAccessor.deleteAccountInfo(ucomAccountId);

          this.$store.commit('ucomAccountManagementDeleteStore/ucomAccountId', ucomAccountId);
          this.$store.commit('ucomAccountManagementDeleteStore/accountName', deletedUcomAccountInfo.accountName);
          this.$store.commit('ucomAccountManagementDeleteStore/fullName', deletedUcomAccountInfo.fullName);
          this.$store.commit('ucomAccountManagementDeleteStore/mailAddress', deletedUcomAccountInfo.mailAddress);
          this.$store.commit('ucomAccountManagementDeleteStore/accountAuthorization', deletedUcomAccountInfo.accountAuthorization);
          this.$store.commit('ucomAccountManagementDeleteStore/isTargetLoginUser', deletedUcomAccountInfo.isTargetLoginUser);
        } catch (error) {
          // 削除関連store情報の削除
          this.$store.commit('ucomAccountManagementDeleteStore/clear');
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });

          this.$data.isLoading = false;
          return;
        }

        /** SU14-014 削除処理 */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-management/delete-complete').catch((error: any) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
      }
    },
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
});
</script>

<style scoped>
u:hover {
  cursor: pointer;
}
</style>
