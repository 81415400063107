export const E_MANSION_PAYMENT_STATUS = {
  NOT_REGISTERED: '0', // 未登録
  REGISTERED: '1', // 登録済
} as const;
export type E_MANSION_PAYMENT_STATUS = typeof E_MANSION_PAYMENT_STATUS[keyof typeof E_MANSION_PAYMENT_STATUS];

export const convertEMansionPaymentStatus = (value: string): E_MANSION_PAYMENT_STATUS | undefined => {
  for (const v of Object.values(E_MANSION_PAYMENT_STATUS)) {
    if (String(v) == value) {
      return v;
    }
  }
};
