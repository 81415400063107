<template>
  <div class="payment-method-identity-verification-result">
    <LoadingComponent v-if="isLoading" />
  </div>
</template>

<style>
.payment-method-identity-verification-result {
  opacity: 0;
  animation: fade-in 0.5s ease 3s 1 normal;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { ApplicationContentTemporaryStorageResponse } from '@/shared/classes/platform/application-content-temporary-storage-response';
import { ContractFromCopyPaymentMethodRequest } from '@/shared/classes/platform/contract-from-copy-payment-method-request';
import { ContractFromCreatePaymentMethodRequest } from '@/shared/classes/platform/contract-from-create-payment-method-request';
import { ContractFromEntryPortasRequest } from '@/shared/classes/platform/contract-from-entry-portas-request';
import { PlatformPaymentMethodEditRequest } from '@/shared/classes/platform/edit-payment-method-request';
import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { PlatformPaymentMethodRegisterRequest } from '@/shared/classes/platform/register-payment-method-request';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
/** 支払方法 */
interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}
/** お支払方法本人認証結果取得画面 */
export default defineComponent({
  name: 'payment-method-identity-verification-result',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    isLoggedIn: false,
    tempStorage: '' as any,
    process: '',
    tempStorages: null as ApplicationContentTemporaryStorageResponse | null,
    errorMessageTitle: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async created() {
    //ログイン状態取得
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
    //ログインしていない場合統合トップへ遷移
    if (!this.$data.isLoggedIn) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    const uuid = localStorage.getItem('uuidForTemporarySavingApplicationData');
    //LocalStorageからUUID取得
    if (!uuid) {
      throw new Error('ローカルストレージにUUIDがありません');
    }

    //申込内容一時保存データ取得API呼出（リクエストパラメータ:UUID）
    try {
      this.tempStorages = await SpfApiService.getApplicationContentTemporaryStorage(uuid);
      this.tempStorage = JSON.parse(this.tempStorages.applicationDataJson);
      this.process = this.tempStorages.subsequentProcess;
    } catch (e) {
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
    }

    //本人認証結果判定
    if (this.$route.query.result !== 'success') {
      if (this.$route.query.result === 'FailureByUser') {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認のいただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて本人認証（3Dセキュア）の設定はお済みでしょうか<br/>・ご利用のカードは本人認証（3Dセキュア）に対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お手数ですがクレジットカード会社へ本人認証が失敗する旨のお問い合わせをお願いいたします。<br/>※当サイトでは失敗の理由はわかりかねます。`
        );
      }
      if (this.$route.query.result === 'FailureByError') {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認のいただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
        );
      }
      if (this.$route.query.result === 'FailureByFalsification') {
        // 本人認証結果取得API データの改ざんが見られた場合、共通エラー画面に遷移する
        throw new ApiFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.VERIFICATION_RESULT_FALSIFICATION_CONFIRMED);
      }
      this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
      this.backToScreen();
      return;
    }

    if (this.process === 'payment_methods_input') {
      /**支払方法登録処理*/
      const memberId = this.$store.getters['memberStore/member'].id;

      try {
        const paymentMethodRegisterRequest = new PlatformPaymentMethodRegisterRequest({
          creditCardToken: this.tempStorage.creditCardAccessToken,
          tokenExpireDate: this.tempStorage.creditTokenExpireDate,
        });

        await SpfApiService.createPaymentMethod(memberId, paymentMethodRegisterRequest);
      } catch {
        this.errorMessages.push(
          `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
        );
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }
      // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
      this.$store.commit('memberStore/member', null);
      //支払方法登録完了画面遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/payment-method/input-completed').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    } else if (this.process === 'payment_methods_edit') {
      /**支払方法編集*/
      const memberId = this.$store.getters['memberStore/member'].id;
      const paymentMethodEditRequest = new PlatformPaymentMethodEditRequest({
        // 会員IDは会員ストアから取得する
        memberId: memberId,
        paymentMethodNo: this.tempStorage.editRequestedPaymentMethodNo,
        creditCardToken: this.tempStorage.creditCardAccessToken,
        tokenExpireDate: this.tempStorage.creditTokenExpireDate,
      });

      try {
        await SpfApiService.updatePaymentMethod(paymentMethodEditRequest);
      } catch {
        this.errorMessages.push(
          `お支払い方法の編集が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
        );
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }
      //支払方法登録完了画面遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/payment-method/input-completed').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    } else if (this.process === 'service-entry-regist') {
      /**サービス申し込み処理(カード新規登録)*/
      try {
        const contractFromCreatePaymentMethodRequest = new ContractFromCreatePaymentMethodRequest({
          creditCardToken: this.tempStorage.creditCardAccessToken,
          tokenExpireDate: this.tempStorage.creditTokenExpireDate,
          productIdArray: this.tempStorage.productIdArray,
          productNameArray: this.tempStorage.notExistIdProductNameArray,
          contractAppliedAt: this.tempStorage.contractAppliedAt,
        });

        await SpfApiService.contractFromCreatePaymentMethod(this.tempStorage.memberId, contractFromCreatePaymentMethodRequest);
        //サービス申し込み完了画面遷移
        await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
        return;
      } catch (error: any) {
        if (error.response.data.errors.includes('cannot create payment method')) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages.push(`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`);
          //前の画面に戻る
          this.backToScreen();
          return;
          // サービス申し込み手続き自体には成功したが、手続完了メールの送信処理に失敗した場合は申し込み完了画面に遷移する
        } else if (error.response.status === 500 && error.response.data.errors.includes('Sending Contract Completion Mail is Failed')) {
          await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
          return;
        } else {
          throw error;
        }
      }
    } else if (this.process === 'service-entry-copy') {
      /**サービス申し込み(カードコピー)*/
      try {
        let uaTypeValue = '';
        if (this.tempStorage.ispName === 'e-mansion') {
          uaTypeValue = UA_TYPE.E_MANSION;
        } else if (this.tempStorage.ispName === 'Five.A') {
          uaTypeValue = UA_TYPE.FIVE_A;
        } else if (this.tempStorage.ispName === 'UCOM光 レジデンス') {
          uaTypeValue = UA_TYPE.UCOM;
        }

        const contractFromCopyPaymentMethodRequest = new ContractFromCopyPaymentMethodRequest({
          creditCardToken: this.tempStorage.creditCardAccessToken,
          tokenExpireDate: this.tempStorage.creditTokenExpireDate,
          uaType: uaTypeValue,
          originalAccountId: this.tempStorage.externalVeriTransId,
          productIdArray: this.tempStorage.productIdArray,
          productNameArray: this.tempStorage.notExistIdProductNameArray,
          contractAppliedAt: this.tempStorage.contractAppliedAt,
        });

        await SpfApiService.contractFromCopyPaymentMethod(this.tempStorage.memberId, contractFromCopyPaymentMethodRequest);
        await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
        return;
      } catch (error: any) {
        // 支払方法のコピーに失敗した場合は支払方法コンポーネントの状態を初期化する
        // お客様の要望により、支払方法のコピーに失敗時は支払方法コンポーネントの初期化を行わない
        if (error.response.status === 500 && error.response.data.errors.includes('cannot copied payment method')) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages.push(
            `クレジットカード情報のコピーに失敗しました。<br>
                インターネットサービスにご登録されているお支払情報をご確認いただくか、画面下の「戻る」ボタンを押して新規にクレジットカード情報をご登録ください。`
          );
          this.backToScreen();
          return;
          // サービス申し込み手続き自体には成功したが、手続完了メールの送信処理に失敗した場合は申し込み完了画面に遷移する
        } else if (error.response.status === 500 && error.response.data.errors.includes('Sending Contract Completion Mail is Failed')) {
          await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
          return;
        } else {
          throw error;
        }
      }
    } else if (this.process === 'service-entry-registed') {
      /**サービス申し込み（カード登録済み)*/
      // 支払方法取得
      let paymentMethodNo = '';
      const paymentMethodOnPortas = await this.getPaymentMethodInfoList(this.tempStorage.memberId);
      if (paymentMethodOnPortas) {
        paymentMethodNo = paymentMethodOnPortas.paymentMethodNo.toString();
      }

      // サブスクリプション契約申込API実行処理
      await SpfApiService.subscriptionCreateRequest(
        this.tempStorage.memberId,
        paymentMethodNo,
        this.tempStorage.productIdArray,
        this.tempStorage.notExistIdProductNameArray,
        this.tempStorage.contractAppliedAt
      );

      await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
      return;
    } else if (this.process === 'service-entry-regist-member') {
      /**新規会員登録でサービス申し込み*/
      const createdContract = await this.contractFromEntryPortas();

      this.tempStorage.form.contractedProductIdList = createdContract;
      this.$store.commit('platformEntryStore/entryInputForm', this.tempStorage.form);
      this.$store.commit('platformEntryStore/portasServiceKind', this.tempStorage.selectedServiceOption);

      if (createdContract) {
        await this.$router
          .push('/platform/entry/completed')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
        return;
      }
      return;
    }
    await this.$router.push('/error').catch((error: any) => {
      checkRouterError(error);
    });
    this.isLoading = false;
  },
  methods: {
    /** 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得する */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay | null> {
      try {
        const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
        let paymentMethodInfo: PaymentMethodInfoForDisplay | null = null;

        for (const eachPaymentMethod of paymentMethodList) {
          const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
            ...eachPaymentMethod,
            isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
          };
          if (!eachPaymentMethod.deleteDate) {
            paymentMethodInfo = eachPaymentMethodForDisplay;
            return paymentMethodInfo;
          }
        }
        return paymentMethodInfo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          // 404 エラーの場合、空を返す
          return null;
        } else {
          throw error; // エラーを再スローするか、適切な処理を行う
        }
      }
    },
    /** サービスお申し込み完了 画面への遷移処理 */
    async transitEntryCompletedScreen(productNameArray: Array<string | undefined>, productIdArray: Array<string | undefined>): Promise<void> {
      // 支払方法登録に成功すると、会員.VeriTrans 会員ID に値が登録される
      // ページ遷移時に beforeEach で会員情報取得できるように null にする
      this.$store.commit('memberStore/member', null);

      // サービス契約申込ストアにConnectixの契約要否を保存
      this.$store.commit('platformProductEntryStore/setIsConnectix', this.tempStorage.isConnectix);
      // サービス契約申込ストアに商品名配列を保存
      this.$store.commit('platformProductEntryStore/setProductNameArray', productNameArray);
      // サービス契約申込ストアに商品ID配列を保存
      this.$store.commit('platformProductEntryStore/setProductIdArray', productIdArray);
      // サービス契約申込ストアにUA種別を保存
      this.$store.commit('platformProductEntryStore/setIspName', this.tempStorage.ispName);

      // 終了処理
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/products/entry-completed').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /**
     * 新規会員登録・サービス同時申し込みAPIを実行する
     */
    async contractFromEntryPortas(): Promise<number[] | undefined> {
      // 申込日時
      const serverDateTimeNow = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      const contractAppliedAt: string = dayjs(serverDateTimeNow).format('YYYY-MM-DDTHH:mm:ssZ');

      if (this.tempStorage.form) {
        try {
          if (this.tempStorage.form.wishToContractProductIdList) {
            // 新規会員登録・サービス同時お申し込み処理

            // 商品 ID 配列
            const productIdArray = this.tempStorage.form.wishToContractProductIdList.map((product: { toString: () => any }) => product.toString());

            const contractFromEntryPortasRequest = new ContractFromEntryPortasRequest({
              member: this.tempStorage.member,
              creditCardToken: this.tempStorage.cardTokenForRegister.token,
              tokenExpireDate: this.tempStorage.cardTokenForRegister.tokenExpireDate,
              productIdArray: productIdArray,
              contractAppliedAt: contractAppliedAt,
            });

            // 新規会員登録・サービス同時お申し込みAPI実行
            const createdContract = await SpfApiService.contractFromEntryPortas(contractFromEntryPortasRequest);
            await AuthService.refresh();
            // 正常ケースの場合
            // バックエンドで会員ステータスが更新される
            // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
            this.$store.commit('memberStore/memberStatus', null);

            return createdContract;
          } else {
            throw new Error('お申し込み対象のサービスが存在しません');
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (axios.isAxiosError(e)) {
            // メールアドレスが重複した場合、 /platform/entry/input に遷移してエラーメッセージ表示
            if (e.response?.status === 409) {
              this.errorMessages = ['ご入力いただいたメールアドレスは既に登録されています。'];
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);
              this.$store.commit('errorMessageStore/errorMessageTitle', this.errorMessageTitle);
              await this.$router.push('/platform/entry/input').catch((error: any) => {
                checkRouterError(error);
              });
              return;
            } else if (e.response?.status === 500 && e.response?.data.errors.includes('cannot create payment method')) {
              this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
              this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];

              this.$store.commit('platformEntryStore/entryInputForm', this.tempStorage.form);
              this.backToScreen();
              return;
              // サービス申し込み手続き自体には成功したが、手続完了メールの送信処理に失敗した場合は申し込み完了画面に遷移する
            } else if (e.response?.status === 500 && e.response?.data.errors.includes('Sending Contract Completion Mail is Failed')) {
              await AuthService.refresh();

              // 正常ケースの場合
              // バックエンドで会員ステータスが更新される
              // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
              this.$store.commit('memberStore/memberStatus', null);

              return this.tempStorage.form.wishToContractProductIdList;
            } else {
              throw e;
            }
          }
        }
      }
    },
    /**
     * エラーメッセージを保存して
     * 前の画面に戻る
     * */
    async backToScreen(): Promise<void> {
      this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);
      this.$store.commit('errorMessageStore/errorMessageTitle', this.errorMessageTitle);
      // サービスお申し込み画面へ遷移する場合 クエリパラメータを作成する
      const connectingProducts = (): string => {
        // notExistIdProductNameArray(connectix等)があれば、配列を連結する
        if (this.tempStorage.notExistIdProductNameArray) {
          const combinedArray = [...this.tempStorage.notExistIdProductNameArray, ...this.tempStorage.productIdArray];
          return combinedArray.map((productId, productIndex) => `product${productIndex + 1}=${productId}`).join('&');
        }
        return this.tempStorage.productIdArray.map((productId: string, productIndex: string) => `product${productIndex + 1}=${productId}`).join('&');
      };
      if (this.process === 'payment_methods_input') {
        await this.$router.push('/platform/my-page/payment-method/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'payment_methods_edit') {
        await this.$router.push('/platform/my-page/payment-method/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-regist') {
        await this.$router.push(`/platform/my-page/products/entry-input?${connectingProducts()}`).catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-copy') {
        await this.$router.push(`/platform/my-page/products/entry-input?${connectingProducts()}`).catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-registed') {
        await this.$router.push(`/platform/my-page/products/entry-input?${connectingProducts()}`).catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-regist-member') {
        this.$store.commit('platformEntryStore/entryInputForm', this.tempStorage.form);
        await this.$router.push('/platform/entry/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    },
  },
});
</script>
