/**
 * UCOM 共通利用 API : 契約基本情報取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomCustomerResponseResult {
  /** 結果値 */
  public result!: UcomCustomerResponse;

  constructor(partial: Partial<UcomCustomerResponse>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM 共通利用 API : 契約基本情報取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomCustomerResponse {
  /** リモートサポート契約状態 */
  public remote_support_status!: boolean;
  /** UCOM光電話契約状態 */
  public ucom_optical_phone_status!: boolean;
  /** GATE CALL050契約状態 */
  public gate_call050_status!: boolean;
  /** クレジットカードの登録有無 */
  public is_register_credit_card!: boolean;
  /** Connectix契約状態 */
  public connectix_status!: boolean;
  /** Connectix提供有無 */
  public connectix_offer!: boolean;
  /** Connectix料金 */
  public connectix_price!: string;
  /** メールサービス有無 */
  public email_service!: boolean;
  /** リモートサポート料金 */
  public remote_support_price!: string;
  /** リモートサポート有無 */
  public remote_support!: boolean;
  /** U-NEXT with ギガシネマ表示有無 */
  public giga_cinema!: boolean;
  /** 雑誌読み放題ポイントサービス表示有無 */
  public magazine_point_service!: boolean;
  /** 建物専用サイトURL */
  public building_site!: string;
  /** 部屋番号 */
  public room_number!: string;
  /** 退会日 */
  public quit_date?: string;
  /** 入居予定日 */
  public move_into_date!: string;
  /** 保留日 */
  public pending_date!: string;
  /** レンタルBBR提供有無 */
  public rental_bbr_offer!: boolean;
  /** レンタルBBR申込受付可否 */
  public is_possible_rental_bbr_apply!: boolean;
  /** レンタルBBRの提供開始日 */
  public rental_bbr_start_date!: string;

  constructor(partial: Partial<UcomCustomerResponse>) {
    Object.assign(this, partial);
  }
}
