<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールサービス購入 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>メールサービス購入</li>
      </ul>

      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールサービス購入 確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールサービス購入" />メールサービス購入 確認</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンを押してください。</p>
        <error-messages-component v-bind:errorMessages="messageList" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th class="va-middle">商品名</th>
              <td>{{ mailPackInfo.productName }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み内容</th>
              <td><b>購入する</b></td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o">{{ Number(mailPackInfo.monthlyFee).toLocaleString() }} 円 （税込）</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ formattedProcessDate }}</td>
            </tr>

            <tr>
              <th class="va-middle">サービス説明</th>
              <td v-html="mailPackInfo.serviceDescription"></td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <p class="form-btn-txt" v-if="!hasError">「申し込む」を押してください。完了画面へ進みます。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" @click="goBack()" v-if="!hasError"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="goNext()" v-if="!hasError">申し込む<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs" @click="goTop()" v-if="hasError">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { SpfApiMailPackAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-pack-accessor';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FORMAT_DATE } from '@/shared/const/format';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: {
      productName: '',
      monthlyFee: '',
      serviceDescription: '',
    },
    check: false,
    messageList: [] as string[],
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    hasError: false,
    processDate: null as Date | null,
    formattedProcessDate: '',
    isActiveButton: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      //不正な遷移
      this.isActiveButton = this.$store.getters['ucomMailPackStore/isActiveButton'];
      if (!this.isActiveButton) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      //リロードでデータが消えたとき
      this.mailPackInfo.productName = this.$store.getters['ucomMailPackStore/productName'];
      if (!this.mailPackInfo.productName) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 戻るボタン押下時にstoreのproductNameに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomMailPackStore/productName', undefined);

      this.mailPackInfo.monthlyFee = this.$store.getters['ucomMailPackStore/monthlyFee'];
      if (!this.mailPackInfo.monthlyFee) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.processDate = this.$store.getters['ucomMailPackStore/processDate'];
      if (!this.processDate) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.formattedProcessDate = this.formatDate(this.processDate);
      this.mailPackInfo.serviceDescription = this.$store.getters['ucomMailPackStore/serviceDescription'];
      if (!this.mailPackInfo.serviceDescription) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      if (this.messageList.length > 0) {
        this.hasError = true;
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    //戻るボタン
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('ucomMailPackStore/productName', this.mailPackInfo.productName);
      await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    //申し込むボタン
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.messageList = [];
      const memberId = this.$store.getters['memberStore/member'].id;
      const ucomAccountId = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.ucomAccountId;
      try {
        //AU08-003により購入情報を更新
        const updateUcomMailPack = await SpfApiMailPackAccessor.updateUcomMailPackInfo(
          memberId,
          ucomAccountId,
          dayjs(this.processDate!).format(FORMAT_DATE.YYYYMMDD_WITH_SLASH),
          this.mailPackInfo.monthlyFee,
          this.mailPackInfo.productName
        );

        if (updateUcomMailPack.message) {
          this.hasError = true;
          this.messageList.push(updateUcomMailPack.message);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        this.$store.commit('ucomMailPackStore/contactMailAddress', updateUcomMailPack.mailAddress);
        this.$store.commit('ucomMailPackStore/productName', this.mailPackInfo.productName);
        this.$store.commit('ucomMailPackStore/monthlyFee', this.mailPackInfo.monthlyFee);
        this.$store.commit('ucomMailPackStore/processDate', this.processDate);
        this.$store.commit('ucomMailPackStore/serviceDescription', this.mailPackInfo.serviceDescription);
        await this.$router.push('/ucom/mail-pack/complete');
        this.isLoading = false;
      } catch (error) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    },
    //Topへボタン
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
});
</script>
