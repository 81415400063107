/** UCOM 新規会員登録 入力フォーム */
export class UcomEntryInputForm {
  /** 申請年月日 */
  public applyDate!: string;
  /** お名前 姓 */
  public firstName!: string;
  /** お名前 名 */
  public givenName!: string;
  /** お名前フリガナ 姓 */
  public firstNameKana!: string;
  /** お名前フリガナ 名 */
  public givenNameKana!: string;
  /** 郵便番号 */
  public postalCode!: string;
  /** 漢字都道府県 */
  public addressPrefectures!: string;
  /** 漢字市区郡町村 */
  public addressMunicipalities!: string;
  /** 漢字大字・通称 */
  public addressTown!: string;
  /** 漢字字名・丁目 */
  public addressStreet!: string;
  /** 漢字番地 */
  public addressNumber!: string;
  /** 設置先ご住所 建物名 */
  public sendBuildingName?: string;
  /** 棟番号・部屋番号 */
  public roomNumber!: string;
  /** 生年月日 */
  public birthdate!: string;
  /** eMail */
  public eMail?: string;
  /** お電話番号 */
  public phoneNumber!: string;
  /** 入居状況 */
  public tenancyStatus!: string;
  /** 移転予定日 */
  public expectedMoveInOn?: string;
  /** IP電話サービスのお申し込み要否 */
  public isApplyIPTelSercice!: boolean;
  /** UCOM光電話 お申し込み要否 */
  public applyOpticalPhone?: string;
  /** UCOM光電話 電話番号取得方法 */
  public opticalPhoneNumberGetMethodId?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（郵便番号） */
  public opticalPhonePostalCode?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（番地まで） */
  public opticalPhoneAddress?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社にご登録の住所（マンション名など） */
  public opticalPhoneBuildingName?: string;
  /** UCOM光電話 電話番号取得方法 (表示値) */
  public opticalPhoneNumberGetMethodValue?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ 引継電話番号 */
  public opticalPhoneTakeoverNumber?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人区分 */
  public opticalPhoneHolderCategory?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人 */
  public opticalPhoneHolderName?: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ 名義人カナ */
  public opticalPhoneHolderNameKana!: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社 */
  public opticalPhoneUseCompany!: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社でその他を選んだときの入力される文字列 */
  public opticalPhoneUseCompanyOther!: string;
  /** UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社 (表示値) */
  public opticalPhoneUseCompanyValue!: string;
  /** UCOM光電話 光電話付加サービス (有料) */
  public opticalPhoneImpossibleService?: Array<string>;
  /** UCOM光電話 光電話付加サービス (有料) (表示値) */
  public opticalPhoneImpossibleServiceId?: Array<string>;
  /** UCOM光電話 番号通知 (無料) */
  public opticalPhoneNumberNotificationId!: string;
  /** GATE CALL050 (M) お申し込み要否 */
  public applyGATECALL050?: string;
  /** 登録証の郵送 (希望する・しない) */
  public isSendRegistrationCard!: boolean;
  /**物件ID */
  public propertyId!: string;
  /**部屋番号入力画面からの遷移か */
  public isFromRoomNumberInput?: boolean;

  constructor(partial: Partial<UcomEntryInputForm>) {
    Object.assign(this, partial);
  }
}

/** UCOM 光電話お申し込みコード確認API 実行結果 */
export class UcomApplicationCodeData {
  /** 光電話お申し込みコード確認API成功時のコード */
  public messageCheckApplicationCode!: string[];

  constructor(partial: Partial<UcomApplicationCodeData>) {
    Object.assign(this, partial);
  }
}
