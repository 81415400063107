import { Module } from 'vuex';
import { RootState } from '../index';

import { UcomCustomerResponse } from '../../shared/classes/external-api/ucom/customer-response';
import { UcomPropertyResponse } from '../../shared/classes/external-api/ucom/property-response';
import { UcomSharedExternalApiService } from '@/shared/services/external-api/ucom-shared-external-api-service';
import { UcomSharedErrorResponse } from '../../shared/classes/external-api/ucom/shared-error-response';

/** UCOM 共通系 State */
const ucomCommonState: {
  customer: UcomCustomerResponse | UcomSharedErrorResponse | null;
  property: UcomPropertyResponse | UcomSharedErrorResponse | null;
} = {
  /** UCOM 契約基本情報 */
  customer: null,
  /** UCOM 物件基本情報 */
  property: null
};

/** State の型 */
type UcomCommonState = typeof ucomCommonState;

/** UCOM 共通系 Store */
export const ucomCommonStore: Module<UcomCommonState, RootState> = {
  namespaced: true,
  state: ucomCommonState,
  actions: {
    /**
     * 契約基本情報取得 actions
     * @param context
     * @param coustomerId string 顧客 ID
     * @return UcomCustomerResponse 契約基本情報
     */
    async customer(context, coustomerId: string): Promise<UcomCustomerResponse | UcomSharedErrorResponse> {
      // API コールする
      const customerValue: UcomCustomerResponse | UcomSharedErrorResponse = await UcomSharedExternalApiService.findCustomerById(coustomerId);
      // 値をコミットする
      context.commit('customer', customerValue);
      // Getterから取り直して返す
      const afterCustomerValue = context.getters['customer'];
      return afterCustomerValue;
    },
    /**
     * 物件基本情報取得 actions
     * @param context
     * @param propertyrId string 物件 ID
     * @return UcomPropertyResponse 物件基本情報
     */
    async property(context, propertyrId: string): Promise<UcomPropertyResponse | UcomSharedErrorResponse> {
      // API コールする
      const propertyrValue: UcomPropertyResponse | UcomSharedErrorResponse = await UcomSharedExternalApiService.findPropertyById(propertyrId);
      // 値をコミットする
      context.commit('property', propertyrValue);
      // Getterから取り直して返す
      const afterPropertyValue = context.getters['property'];
      return afterPropertyValue;
    }
  },
  mutations: {
    customer: (state, value: UcomCustomerResponse) => (state.customer = value),
    property: (state, value: UcomPropertyResponse) => (state.property = value)
  },
  getters: {
    customer: (state): UcomCustomerResponse | UcomSharedErrorResponse | null => state.customer,
    property: (state): UcomPropertyResponse | UcomSharedErrorResponse | null => state.property
  }
};
