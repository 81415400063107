import { UaTypeValue } from "@/shared/const/service-type";

/**
 * UCOM 共通利用 API : Connectix お申し込み : リクエスト
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionConnectixNewConnectixRequestResult {
  /**結果値 */
  public request!: EMansionConnectixNewConnectixRequest;

  constructor(partial: Partial<EMansionConnectixNewConnectixRequest>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion Connectix API : Connectix お申し込み : リクエスト
 *
 * 外部 API のリクエスト仕様に合わせてスネークケースで命名している
 *
 */
export class EMansionConnectixNewConnectixRequest {
  /** UA種別 */
  public ua_type!: UaTypeValue;
  /** 会員ID */
  public member_id!: string;
  /** 部屋番号 */
  public room_number!: string;
  /** 棟名 */
  public building_id?: string;
  /** クレジットカードトークン */
  public credit_card_token?: string;
  /** トークン有効期限 */
  public token_expire_date?: string;
  /** 物件_id */
  public property_id!: string;
  /** カード会員 ID */
  public card_account_id?: string;

  constructor(partial: Partial<EMansionConnectixNewConnectixRequest>) {
    Object.assign(this, partial);
  }
}
