<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>メールボックス容量変更 完了</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>メールボックス容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールボックス容量" />メールボックス容量</h2>
        <p>お申し込みいただきました内容につきまして手続きが完了しました。<br /></p>
        <p v-if="notifyMailAddress">{{ notifyMailAddress }}宛に、「申込み完了メール」を送信しましたのでご確認ください。</p>
      </div>
      <!-- /blc -->

      <div class="sblc">
        <h3 class="service-h3">お申し込み内容</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th>アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th>現在の容量</th>
              <td>{{ mailBoxCapacity }}.0GB</td>
            </tr>
            <tr>
              <th>追加／縮小容量</th>
              <td>{{ changeCapacityLabel }}</td>
            </tr>
            <tr>
              <th>月額利用料</th>
              <td>9.0GB 毎に月額{{ price }}円（税込）<br />最大10.0GBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th>お申し込み日</th>
              <td>{{ effectiveDate }}</td>
            </tr>
            <tr>
              <th>サービス説明</th>
              <td v-html="serviceDescription"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="onUcomTop()">
            UCOM光 レジデンス会員専用トップ
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'mailbox-capacity-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    notifyMailAddress: '', // 通知先メールアドレス
    accountName: '', // アカウントID
    mailAddress: '', // メールアドレス
    mailBoxCapacity: '', // メールボックス容量
    changeCapacityLabel: '', // 追加／縮小容量ラベル
    price: '', // 単価
    effectiveDate: '', // 申込日
    serviceDescription: '', // サービス説明
    isLoading: false, //ローディング
  }),
  async mounted() {
    // リロード、ブラウザバックした場合は会員専用トップページへ
    if (!this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId']) {
      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      this.$data.isLoading = true;

      // 前画面からの情報をStoreから取得し画面項目データをセット
      await this.setDataByStore();

      // storeをクリア
      this.deleteStore();
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    // storeから画面項目データをセット
    async setDataByStore(): Promise<void> {
      this.$data.notifyMailAddress = this.makeMailAddressForView(this.$store.getters['ucomMailboxCapacityStore/notifyMailAddress']);
      this.$data.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.$data.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.$data.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.$data.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.$data.effectiveDate = this.$store.getters['ucomMailboxCapacityStore/effectiveDate'];
      this.$data.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
    },
    // storeをクリア
    deleteStore(): void {
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', undefined);
      this.$store.commit('ucomMailboxCapacityStore/accountName', undefined);
      this.$store.commit('ucomMailboxCapacityStore/mailAddress', undefined);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', undefined);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', undefined);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityValue', undefined);
      this.$store.commit('ucomMailboxCapacityStore/price', undefined);
      this.$store.commit('ucomMailboxCapacityStore/effectiveDate', undefined);
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', undefined);
      this.$store.commit('ucomMailboxCapacityStore/isRegisteredPayment', undefined);
      this.$store.commit('ucomMailboxCapacityStore/subscriptionDate', undefined);
      this.$store.commit('ucomMailboxCapacityStore/notifyMailAddress', undefined);
    },
    makeMailAddressForView(mailAddress: string[]): string {
      if (!mailAddress[0]) {
        return mailAddress[1];
      }
      if (mailAddress[0] === '') {
        return mailAddress[1];
      }
      return `${mailAddress[0]}, ${mailAddress[1]}`;
    },
    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
  },
});
</script>
