/** 3Dセキュア本人認証(VeriTrans会員ID使用)API リクエスト */
export class Create3dSecureAuthStartInfoWithVeritransAccountIdRequest {
  /** uuid */
  public readonly uuid!: string;
  /** ISPの会員ID(コピーの時だけ渡す) */
  public readonly externalVeritransAccountId: string | undefined;
  /** カード保有者名（入力された場合のみ渡す） */
  public readonly cardholderName: string | undefined;
  constructor(fields: Required<Create3dSecureAuthStartInfoWithVeritransAccountIdRequest>) {
    Object.assign(this, fields);
  }
}
