import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { PERMISSION_TYPE } from '@/shared/services/permission/permission-type';

/**
 * メール統合 共通利用 API : 入力項目別認可マスタ取得 : レスポンス
 *
 */
export class InputPermissionMaster {
  /** サービスID */
  readonly _serviceId!: string;
  /** 入力項目ID */
  readonly _inputId!: string;
  /** 会員種別 */
  readonly _planType!: SERVICE_PLAN_TYPE;
  /** パーミッション */
  readonly _permission!: PERMISSION_TYPE;

  constructor(partial: Partial<InputPermissionMaster>) {
    Object.assign(this, partial);
  }

  /** サービスID */
  get serviceId(): string {
    return this._serviceId;
  }
  /** 入力項目ID */
  get inputId(): string {
    return this._inputId;
  }
  /** 会員種別 */
  get planType(): SERVICE_PLAN_TYPE {
    return this._planType;
  }
  /** パーミッション */
  get permission(): PERMISSION_TYPE {
    return this._permission;
  }
}
