<template>
  <div class="error">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>エラーが発生しました</h1>
    </main>
    <!-- /underlayer-main -->


    <!-- contents -->
    <div class="contents">
      <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="ログインエラー">ログインエラー</h2>
      <p class="err">大変申し訳ございませんが、こちらのアカウント・パスワードでは現在ご利用頂けません。</p>


      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <router-link to="/platform" custom v-slot="{ navigate }" v-if="isLoggedIn">
            <button class="btn btn01 bs" @click="navigate">トップページ<i class="material-icons link link-icon">east</i></button>
          </router-link>
          <router-link to="/" custom v-slot="{ navigate }" v-if="!isLoggedIn">
            <button class="btn btn01 bs" @click="navigate">トップページ<i class="material-icons link link-icon">east</i></button>
          </router-link>
        </div>
      </div>
      <!-- /blc -->

    </div>
    <!-- /contents -->

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {AuthService} from "@/shared/services/auth/auth-service";

/** エラー画面 */
export default defineComponent({
  name: 'error',
  data: () => ({
    isLoggedIn: false,
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
  }
});

</script>