import { ParameterDecisionProperty } from '../classes/spf-api/parameter-decision-property';
import { SpfApiService } from '../services/api/spf-api-service';

/**
 * パラメーター判定テーブルに合致する物件データがあるか確認する
 * ある場合はtrue,なければfalseを返す
 */
export class JudgeParameterDecisionProperty {
  public static async existsOnPortasDB(transitionSourceServiceId: string): Promise<boolean> {
    const result = await SpfApiService.findParameterDecisionPropertyById(transitionSourceServiceId);

    if (result instanceof ParameterDecisionProperty) {
      return true;
    }
    return false;
  }
}