<template>
  <div class="during-registration-process">
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み 登録中</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>登録中</li>
      </ul>

      <div class="blc">
        <p>現在登録中です。登録には数分かかかる場合がございます。</p>
        <p class="my-font-weight-700">ブラウザの更新や戻るボタンを押さずこのままお待ち下さい。</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.my-font-weight-700 {
  font-weight: 700;
}
</style>

<script lang="ts">
import { LinkServiceWithUcomErrorResponse, LinkServiceWithUcomRequest } from '@/shared/classes/spf-api/link-service-with-ucom';
import { Member } from '@/shared/classes/spf-api/member';
import { RemainingWorkConfirmDto } from '@/shared/classes/spf-api/remaining-work-confirm-dto';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { LinkServiceWithUcomErrorService } from '@/shared/services/ucom/link-service-with-ucom-error-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'during-registration-process',
  data: () => ({
    linkServiceWithUcomRequest: null as LinkServiceWithUcomRequest | null,
    remainingWorkResult: {} as RemainingWorkConfirmDto,
  }),
  async mounted(): Promise<void> {
    this.linkServiceWithUcomRequest = this.$store.getters['ucomEntryStore/linkServiceWithUcomRequest'];
    this.$store.commit('ucomEntryStore/hasOccurredBusinessErrorAtLinkServiceWithUcom', false);

    if (this.linkServiceWithUcomRequest) {
      await this.execLinkServiceWithUcom();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    async execLinkServiceWithUcom() {
      try {
        const member: Member = this.$store.getters['memberStore/member'];

        // 登録処理 成功時は空200OK が返却される
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await SpfApiService.linkServiceWithUcom(member.id, this.linkServiceWithUcomRequest!);
        await AuthService.refresh();

        this.$store.commit('memberStore/memberStatus', null);
        this.$store.commit('memberStore/member', null);
      } catch (e) {
        if (e instanceof LinkServiceWithUcomErrorResponse) {
          // 業務エラーのときは、確認画面に戻り、エラーメッセージを表示する
          if (LinkServiceWithUcomErrorService.isBusinessError(e)) {
            this.$store.commit('ucomEntryStore/hasOccurredBusinessErrorAtLinkServiceWithUcom', true);
            await this.$router.push('/ucom/entry/confirm').catch((error: any) => {
              checkRouterError(error);
            });
            return;
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/entry/registration-error').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      try {
        // 残工事有無確認・受付ステータス更新 APIを実行する
        this.remainingWorkResult = await SpfApiService.remainingWorkConfirm(
          UA_TYPE.UCOM,
          this.linkServiceWithUcomRequest!.propertyId,
          this.linkServiceWithUcomRequest!.roomNumber,
          true
        );
      } catch (error: any) {
        //エラーの場合は残工事なし
        this.remainingWorkResult = new RemainingWorkConfirmDto({ isRemainingWork: '0' });
      }

      // 残工事の有無をストアに保存(nullの場合は残工事無しとして扱う)
      this.$store.commit('remainingWorkStore/remainingWork', this.remainingWorkResult.isRemainingWork ? this.remainingWorkResult.isRemainingWork : '0');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/entry/completed').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
