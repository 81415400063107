import { TransitionSourceServiceType } from "@/shared/const/service-type";

/** 会員ステータス */
export class MemberStatus {
  /** Portas テナントの Auth0 ID */
  public primaryKeyAuth0!: string;

  /** メールアドレス */
  public emailAddress!: string;
  /** 会員ステータス */
  public status!: string;
  /** トークン */
  public token!: string;
  /** トークン有効期限 */
  public tokenExpireTime!: string;
  /** Portas物件ID */
  public propertyId!: number;
  /** 遷移元サービス */
  public transitionSourceService!: TransitionSourceServiceType | null;
  /** 遷移元サービス物件ID */
  public transitionSourceServiceId!: string | null;
  /** GW光顧客番号 */
  public encryptedPrimaryKeyGw!: string | null;
  /** サービスお申し込み情報 */
  public serviceEntry!: string;
  /** サービス紐づけ */
  public serviceLink!: string;

  constructor(partial: Partial<MemberStatus>) {
    Object.assign(this, partial);
  }
}
