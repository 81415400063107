<template>
  <div>
    <LoadingComponent v-if="isMounting" />
    <section class="my-portal-service">
      <h2 class="my-portal-service__title">インターネットサービス</h2>
      <div class="my-portal-service__image">
        <img class="logo-gamewith" src="../../../images/logo-gamewith.png" alt="GameWith光" />
      </div>
      <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="GameWith光マイページ" />GameWith光マイページ</h2>
      <p class="my-description">GameWith光インターネットサービスお申込内容やご請求明細は<router-link to="/gamewith" class="link">こちらから</router-link>ご確認ください。</p>
    </section>
  </div>
</template>
  
  <style lang="scss" scoped>
.my-portal-service {
  &__title {
    display: inline-block;
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.6;
    border-bottom: 3px solid #0097e0;
  }
  &__image {
    margin-bottom: 10px;
  }
  &__description {
    margin-bottom: 10px;
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
}
.logo-gamewith {
  width: 180px;
}

@media only screen and (max-width: 767px) {
  .my-portal-service {
    &__title {
      font-size: 18px;
    }
  }
}
</style>
  
  <script lang="ts">
import { defineComponent } from 'vue';

import { Member } from '@/shared/classes/spf-api/member';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import FrequentlyAskedQuestions from '../../components/frequently-asked-questions.vue';
import SubscriptionCommonComponent from './subscription-common.vue';
import TsunaguOnlineShop from '@/shared/components/tnos/tsunagu-online-shop.vue';

export default defineComponent({
  name: 'portal-service-gamewith',
  components: {
    LoadingComponent,
    FrequentlyAskedQuestions,
    TsunaguOnlineShop,
    SubscriptionCommonComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    isMounting: boolean;
  } {
    return {
      member: null,
      memberStatus: null,
      isMounting: true,
    };
  },
  emits: ['updateLeftMenuLogoFromPortalServiceUcom'],
  async mounted(): Promise<void> {
    try {
      this.member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }
      if (!this.member.primaryKeyGW || !this.memberStatus.encryptedPrimaryKeyGw) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }
    } catch (error) {
      throw error;
    }
    this.isMounting = false;
  },
});
</script>
  