import MobileDetect from 'mobile-detect';
import { TsunaguOnlineProductUrlService } from './tsunagu-online-product-url-service';
import { TsunaguOnlineProductHtmlDisplayOnPcService } from './tsunagu-online-product-html-display-on-pc-service';
import store from '@/store';

export class TsunaguOnlineProductHtmlDisplay {
  public isUserAgentMobile!: boolean;
  public spUrl?: string;
  public srcHtml?: string;

  constructor(partial: Partial<TsunaguOnlineProductHtmlDisplay>) {
    Object.assign(this, partial);
  }
}

/**
 * つなぐオンラインショップの商品をスクレイピング表示するために必要な情報を用意する
 */
export class TsunaguOnlineProductHtmlDisplayService {
  /**
   * イベントで受け取る
   */
  public static async makeWrapper(event: Event): Promise<TsunaguOnlineProductHtmlDisplay | undefined> {
    const anchor = event.currentTarget as HTMLAnchorElement;
    // google-analyticsのクエリパラメータ付与されることがあるので、取り除く
    const myUrl = anchor.getAttribute('href')?.split('?')[0];

    if (myUrl) {
      event.preventDefault();
      return this.make(myUrl);
    }
  }

  /**
   * つなぐオンラインショップの商品について、
   * 1. PCのとき: スクレイピング処理をしたHTMLを提供する
   * 2. スマホのとき: スマホのときスクレイピング表示をする用のページへのURLを提供する
   */
  public static async make(myUrl: string): Promise<TsunaguOnlineProductHtmlDisplay> {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);

    if (mobileDetect.mobile()) {
      const queryParameter = TsunaguOnlineProductUrlService.buildQueryParametersDisplayOnSp(myUrl);

      return new TsunaguOnlineProductHtmlDisplay({
        isUserAgentMobile: true,
        spUrl: `${process.env.VUE_APP_BASE_URL}/tnos?${queryParameter}`,
        srcHtml: undefined
      });
    } else {
      store.commit('tsunaguOnlineProductHtmlDisplayOnPcStore/recentlyTransitionUrl', myUrl);
      const dto = await TsunaguOnlineProductHtmlDisplayOnPcService.fetchHtml(myUrl);

      return new TsunaguOnlineProductHtmlDisplay({
        isUserAgentMobile: false,
        spUrl: undefined,
        srcHtml: dto?.formattedHtml ? dto?.formattedHtml : ''
      });
    }
  }
}
