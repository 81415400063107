export const UCOM_VIRUS_CHECK_PAUSE_ACTION = {
  PAUSE: 1, // 一時停止する
  UNPAUSE: 2, // 実施する
} as const;

export type UcomVirusCheckPauseAction = typeof UCOM_VIRUS_CHECK_PAUSE_ACTION[keyof typeof UCOM_VIRUS_CHECK_PAUSE_ACTION];

// 表示用 value変換用
const _UCOM_VIRUS_CHECK_PAUSE_ACTION = {
  PAUSE: { id: UCOM_VIRUS_CHECK_PAUSE_ACTION.PAUSE, value: '一時停止する' },
  UNPAUSE: { id: UCOM_VIRUS_CHECK_PAUSE_ACTION.UNPAUSE, value: '実施する' },
};

// idから valueに変換
export const convertUcomVirusCheckPauseActionById = (id: number): string | undefined => {
  for (const v of Object.values(_UCOM_VIRUS_CHECK_PAUSE_ACTION)) {
    if (v.id === id) {
      return v.value;
    }
  }
};
