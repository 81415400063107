/**
 * 月と日付の組み合わせが有効かどうか判定する
 *
 * 1. 30日まである月で、日付が30までかどうか
 * 2. 2月について、うるう年のとき、日付が29までかどうか
 * 3. 2月について、うるう年でないとき、日付が28までかどうか
 *
 * @param year: 西暦
 * @param month: 月
 * @param date: 日
 * @return 有効な日付である: true / 有効な日付でない: false
 */
export const isValidMonthAndDatePairs = (year: number, month: number, date: number): boolean => {
  const MAX31: number[] = [1, 3, 5, 7, 8, 10, 12]; // 7コ
  const MAX30: number[] = [4, 6, 9, 11]; // 4コ

  // 31日まである月なら問題ない
  if (MAX31.includes(month)) {
    return true;

  // 30日までの月について、30までの日付であれば問題ない
  } else if (MAX30.includes(month)) {
    if (date <= 30) {
      return true;
    }

  // 2月はうるう年を考慮する
  } else if (month === 2) {

    let isLeapYear = false;
    if (year % 4 === 0 && !(year % 100 === 0 && year % 400 !== 0)) {
      isLeapYear = true;
    }

    // うるう年は29日まで / うるう年でない年は28日まで
    if ((isLeapYear && date <= 29) || (!isLeapYear && date <= 28)) {
      return true;
    }
  }
  return false;
}
