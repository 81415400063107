<template>
  <div>
    <ul class="contractStatusper-list">
      <li v-for="num in statusList.length" :key="num" :class="{ active: inputStatus == num }">
        STEP<br /><b>{{ num }}</b>
      </li>
    </ul>
    <div class="contractStatus-description-container">
      <ul class="contractStatus-description">
        <li :class="{ active: inputStatus == status.id }" v-for="status in statusList" :key="status.id">
          STEP{{ status.id }}:　{{ status.value }}<br />
          <span class="description">{{ description }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script lang="ts">
import { defineComponent } from 'vue';
import { PLAN_FIX } from '../../const/gamewith/plan-fix';
import { STATUS_COLLAB_PORTAS, convertStatusCollabPortasByValue, convertStatusProPortasByValue, STATUS_PRO_PORTAS } from '../../const/gamewith/status_portas';
export default defineComponent({
  name: 'gamewith-internet-service-status-component',
  data(): {
    proStr: string;
    inputStatus: number;
  } {
    return { proStr: PLAN_FIX.PRO, inputStatus: 0 };
  },
  props: {
    contractStatus: {
      type: String,
      required: true,
    },
    planFix: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
  },
  async mounted() {
    if (this.planFix == PLAN_FIX.PRO) {
      this.inputStatus = convertStatusProPortasByValue(this.contractStatus)!.id;
    } else {
      this.inputStatus = convertStatusCollabPortasByValue(this.contractStatus)!.id;
    }
  },
  computed: {
    statusList() {
      if (this.planFix == PLAN_FIX.PRO) {
        return Object.values(STATUS_PRO_PORTAS);
      } else {
        return Object.values(STATUS_COLLAB_PORTAS);
      }
    },
  },
  methods: {
    update() {},
  },
});
</script>
  
  <style lang="scss" scoped>
.contractStatusper-list {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}
.contractStatusper-list > li {
  list-style-type: none;
  padding: 15px 18px;
  border-radius: 50%;
  color: black;
  background-color: #d3d3d3;
  text-align: center;
}
.contractStatusper-list > li.active {
  color: white;
  background-color: rgb(0, 204, 153);
}
.contractStatusper-list::before {
  content: '';
  width: 100%;
  height: 2px;
  top: 50%;
  display: block;
  position: absolute;
  background-color: rgb(0, 204, 153);
  z-index: -1;
}

.contractStatus-description-container {
  border: solid 1px #d3d3d3;
}
.contractStatus-description {
  padding: 10px;
}
.contractStatus-description > li {
  list-style-type: none;
}
.contractStatus-description > .active {
  color: rgb(0, 204, 153);
}
.description {
  display: none;
}
.contractStatus-description > .active > .description {
  display: inline-block;
}
</style>
  