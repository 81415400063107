import { Module } from 'vuex';
import { RootState } from '../index';

import { UcomApplicationCodeData, UcomEntryInputForm } from '../../pages/ucom/entry/classes/entry-input-form';
import { UcomPropertyResponse } from '../../shared/classes/external-api/ucom/property-response';
import { LinkServiceWithUcomRequest } from '@/shared/classes/spf-api/link-service-with-ucom';

/** UCOM 新規会員登録 State */
const ucomEntryState: {
  entryInputForm: UcomEntryInputForm | null;
  externalApiProperty: UcomPropertyResponse | null;
  applicationCodeData: UcomApplicationCodeData | null;
  linkServiceWithUcomRequest: LinkServiceWithUcomRequest | null;
  hasOccurredBusinessErrorAtLinkServiceWithUcom: boolean;
} = {
  /** UCOM 新規会員登録 入力フォーム */
  entryInputForm: null,
  /** UCOM 新規会員登録 物件情報 (外部 API より取得した値) */
  externalApiProperty: null,
  /** UCOM 光電話お申し込みコード確認API 実行結果 */
  applicationCodeData: null,
  /** UCOM 同時申し込みリクエスト */
  linkServiceWithUcomRequest: null,
  /** UCOM 同時申し込みAPI で業務エラー */
  hasOccurredBusinessErrorAtLinkServiceWithUcom: false,
};

/** State の型 */
type UcomEntryState = typeof ucomEntryState;

/** UCOM 新規会員登録用 Store */
export const ucomEntryStore: Module<UcomEntryState, RootState> = {
  namespaced: true,
  state: ucomEntryState,
  mutations: {
    entryInputForm: (state, value: UcomEntryInputForm) => (state.entryInputForm = value),
    externalApiProperty: (state, value: UcomPropertyResponse) => (state.externalApiProperty = value),
    applicationCodeData: (state, value: UcomApplicationCodeData) => (state.applicationCodeData = value),
    linkServiceWithUcomRequest: (state, value: LinkServiceWithUcomRequest) => (state.linkServiceWithUcomRequest = value),
    hasOccurredBusinessErrorAtLinkServiceWithUcom: (state, value: boolean) => (state.hasOccurredBusinessErrorAtLinkServiceWithUcom = value),
  },
  getters: {
    entryInputForm: (state): UcomEntryInputForm | null => state.entryInputForm,
    externalApiProperty: (state): UcomPropertyResponse | null => state.externalApiProperty,
    applicationCodeData: (state): UcomApplicationCodeData | null => state.applicationCodeData,
    linkServiceWithUcomRequest: (state): LinkServiceWithUcomRequest | null => state.linkServiceWithUcomRequest,
    hasOccurredBusinessErrorAtLinkServiceWithUcom: (state): boolean => state.hasOccurredBusinessErrorAtLinkServiceWithUcom,
    /**
     * UCOM同時申込APIについて、クレジットカードコピーに同意して申し込んだかどうか判定する
     * @returns true: クレジットカードコピーに同意して申し込んだ
     * @returns false: クレジットカードコピーではなく、クレジットカード情報を入力して申し込んだ
     */
    requestedWithAgreeToCreditCardCopy: (state): boolean => {
      return state.linkServiceWithUcomRequest ? state.linkServiceWithUcomRequest.cardAccountId !== '' && !state.linkServiceWithUcomRequest.creditCardToken : false;
    },
  },
};
