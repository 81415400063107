import { Property as PortasProperty } from '../classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from './convert';

/**
 * UCOMのページで共通で使われる処理
 */
export class ProcessesCalledInUcomPages {
  private static _ucomUrl = process.env.VUE_APP_UCOM_URL as string;
  private static _ucomMyPageUrl = process.env.VUE_APP_UCOM_MY_URL as string;

  /**
   * 「お住まいの建物専用サイトはこちら」のURL
   */
  public static getBuildingWebsiteUrl(ucomUrl: string = this._ucomUrl, property: PortasProperty): string {
    if (property?.apartmentId) {
      const apid = convert6DigitIdTo8DigitId(property.apartmentId);
      return `${ucomUrl}/${apid}`;
    }
    return ucomUrl;
  }

  /**
   * UCOM既存マイページのログイン後トップページへSSOで遷移するURL
   */
  public static getUcomMyPgeUrl(): string {
    return `${this._ucomMyPageUrl}/member/ContractAccountInfo.aspx`;
  }

  /**
   * UCOM物件基本情報.物件初期費用 などが 12,342円（税込） の形式で渡されてくる
   * 12,342円（税込） を 12342 に変換する関数
   */
  public static getNumericPortionOfPrice(priceString: string): number {
    const PRICE_REGEXP = new RegExp(/^([0-9,]+)(円（税込）)$/);

    const result = PRICE_REGEXP.exec(priceString);
    if (result) {
      const replaced = result[1].replace(/,/g, '');
      return Number(replaced);
    }
    return 0;
  }
}
