export class EMansionHomepageCapacityInput {
  readonly _hpCapacityListCount!: number;
  readonly _hpCapacityList!: { key: string; value: string }[];
  readonly _errorMessages!: string[];

  constructor(partial: Partial<EMansionHomepageCapacityInput>) {
    Object.assign(this, partial);
  }

  get hpCapacityListCount(): number {
    return this._hpCapacityListCount;
  }
  get hpCapacityList(): { key: string; value: string }[] {
    return this._hpCapacityList;
  }
  get errorMessages(): string[] {
    return this._errorMessages;
  }

}
