<template>
  <div class="e-mansion-connectix-completed">
    <LoadingComponent v-if="isLoading || isOnNextExecuting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み 完了</h1>
    </main>
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>e-mansion Connectixお申し込み</li>
        <li>お申し込み完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:Connectix　確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix　お申し込み" />Connectix　お申し込み完了</h2>
        <p>お申し込みありがとうございます。<br />数分程度でサービスをご利用いただけます。</p>
        <p class="mt20">Connectixでより快適なインターネットライフをお楽しみください。</p>
        <!--U-NEXT申し込み可能な場合-->
        <div v-if="canApplyUnextFlag">
          <router-link
            to="/platform/my-page/products/entry-input?product1=4&utm_source=Portas&utm_medium=completed&utm_campaign=eman&utm_content=connectix_completed_u-next_banner"
          >
            <img class="img-pc" src="../../../images/connectixToUnext_pc.png" />
            <img class="img-sp" src="../../../images/connectixToUnext_sp.png" />
          </router-link>
        </div>
      </div>

      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button type="button" class="btn-height btn btn01 bs" v-on:click="onNext()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo-emansion.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.btn-height {
  height: 50px;
}

.connectix-campaign-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.connectix-campaign {
  padding: 15px 0px 20px 0px;
  margin-bottom: 20px;
}
.img-pc {
  display: block;
  width: 100%;
}
.img-sp {
  display: none;
}
@media only screen and (max-width: 767px) {
  .img-pc {
    display: none;
  }
  .img-sp {
    display: block;
    width: 100%;
  }
}
</style>

<script lang="ts">
import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

/** Completed コンポーネント */
export default defineComponent({
  name: 'e-mansion-connectix-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true, // 読み込み状態,最初はロード中
    isOnNextExecuting: false, // onNext()実行中かどうか
    canApplyUnextFlag: false, //U-NEXT申し込み可能かどうか
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    const member: Member = this.$store.getters['memberStore/member'];
    //U-NEXTの商品契約可否を確認
    this.canApplyUnextFlag = await SpfApiService.isProductsContractable(member.id.toString(), [4]);
    // 入力値を保持しているストアの削除
    this.$store.commit('eMansionConnectixEntryStore/entryInputForm', null);
    this.$data.isLoading = false;
  },
  methods: {
    async onNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isOnNextExecuting = false;
        });
    },
  },
});
</script>
