<template>
  <div v-if="isEnabled" class="blc">
    <h2 class="portal-h2-exclusion cf">
      <img src="../../images/service-h2.svg" :alt="alt" />
      <span
        >{{ sentence }}
        <a v-if="!disabled" :href="url" :target="tabTarget" class="link">{{ linkTitle }}</a>
        <span v-else-if="disabled" class="disabled">{{ linkTitle }}</span>
      </span>
    </h2>
  </div>
</template>

<script lang="ts">
import { PermissionControlService } from '@/shared/services/permission/permission-control-service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'permission-controled-span-link',
  props: {
    sentence: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    functionId: {
      type: String,
      default: undefined,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    linkTitle: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    tabTarget: {
      type: String,
      default: '_blank',
    },
  },
  data: () => ({
    isEnabled: false,
  }),
  async mounted(): Promise<void> {
    // メニューリンクは"R"以上のパーミッションがあれば利用可
    this.$data.isEnabled = await PermissionControlService.isReadable(this.functionId ? this.functionId : '');
  },
});
</script>
<style lang="scss" scoped>
.disabled {
  color: #bdbdbd;
  text-decoration: underline;
}

.portal-h2-exclusion {
  font-size: 24px;
  display: flex;
  & img {
    position: relative;
    top: -0.125em;
    width: auto;
    height: 1.2em;
    margin-right: 0.3em;
  }
}

@media only screen and (max-width: 767px) {
  .portal-h2-exclusion {
    font-size: 18px;
    display: block;
    & img {
      top: 0.1em;
    }
  }
}
</style>
