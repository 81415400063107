import { Module } from 'vuex';

import { RootState } from './index';

/** エラーメッセージ State */
const errorMessageState: {
  errorMessageTitle: string | null;
  errorMessages: string[] | null;
} = {
  errorMessageTitle: null,
  errorMessages: [],
};

/** State の型 */
type ErrorMessageState = typeof errorMessageState;

/** エラーメッセージ Store */
export const errorMessageStore: Module<ErrorMessageState, RootState> = {
  namespaced: true,
  state: errorMessageState,
  mutations: {
    errorMessageTitle: (state, value: string | null) => (state.errorMessageTitle = value),
    errorMessages: (state, value: string[] | null) => (state.errorMessages = value),
  },
  getters: {
    errorMessageTitle: (state): string | null => state.errorMessageTitle,
    errorMessages: (state): string[] | null => state.errorMessages,
  },
};
