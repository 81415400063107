import { Module } from 'vuex';
import { RootState } from './index';

/** エラー画面 State */
const Error: {
  messages: string[] | undefined;
} = {
  messages: undefined,
};

/** State の型 */
type ErrorState = typeof Error;

/** エラー画面 Store */
export const errorStore: Module<ErrorState, RootState> = {
  namespaced: true,
  state: Error,
  actions: {},
  mutations: {
    messages: (state, value: string[]) => (state.messages = value),
  },
  getters: {
    messages: (state): string[] | undefined => state.messages,
  },
};
