<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Webスペース登録</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>Webスペース登録</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="Webスペース登録" />Webスペース登録</h2>
        <p>以下の内容でWebスペースを登録します。<br />登録内容をご確認いただき、問題ない場合は「登録」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr v-if="homepageAddress">
              <th class="va-middle">ホームページアドレス</th>
              <td>{{ homepageAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ applicationDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">注意事項</th>
              <td>
                ホームページを開設できるWebスペースを取得できるサービスです。<br />
                ホームページのURLは以下のようになります。 <br />
                「{{ homepageUrlScheme }}(1)ホスティングサーバー名/(2)XXX/」 <br />
                (1)ホスティングサーバー名 <br />
                ご登録されたメールアドレスの@より右部分によって決まります。 <br />
                (2)XXX部分 <br />
                お客様のメールアドレスの@より左側部分です。<br />
                ・申し込み後、すぐにご利用いただけます。<br />
                ・お申し込み後のキャンセルは行えません。<br />
                ・Webスペース単体での解約は行えません。不要な場合はアカウントIDを削除するか、<br />
                メールサービスを他社アドレスに変更してください。 <br />
                ・WebスペースのURLを変更することはできません。<br />
                UCOM 光 レジデンス ISP 会員付加サービス利用規約については<a :href="termsUrl" target="_blank" class="link">こちら</a>からご確認ください。
              </td>
            </tr>
            <tr>
              <th class="va-middle">注意事項への同意<span class="req">必須</span></th>
              <td>
                お申し込みの前に注意事項を必ずお読みになり、「上記の内容に同意する」にチェックしてください。<br />
                <label><input type="checkbox" v-on:click="buttonStatusJudgement()" v-model="consentJudgement" /> 上記の内容に同意する</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->
      <div class="blc">
        <div class="btn-area">
          <button class="material-icons link link-icon" v-on:click="onRegister()" :class="buttonColorSet" :disabled="!conditionJudgment">
            登録
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiWebSpaceRegisterAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-register-accessor';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UcomWebSpaceRegisterInfo } from '@/shared/classes/spf-api/mail/model/ucom/web-space-register/ucom-web-space-register-info';
import { UcomWebSpaceRegisterInitialDisplayInfo } from '@/shared/classes/spf-api/mail/model/ucom/web-space-register/ucom-web-space-register-initial-display-info';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_PROPERTY_SERVICE_PROVID_FORMER } from '@/shared/const/ucom/ucom-property-service-provid-former';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'web-space-register-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    isLoading: false, // ローディング
    errorMessages: new Array<string>(), // エラーメッセージ
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isOneSentence: false, // エラータイトルのみ表示かどうか

    /** 各種パラメータ */
    ucomAccountId: 0, // UCOM_アカウントID
    accountName: '', // アカウントID
    mailAddress: '', // メールアドレス
    hostingServerName: '', // ホスティングサーバー名
    applicationDate: '', // お申し込み日
    consentJudgement: false, // 注意事項への同意
    errorJudgement: true, // エラー文判定
    conditionJudgment: false, // エラー文が無く注意事項へ同意がされている場合のみtrueにする
    homepageAddress: '', // ホームページアドレス
    homepageUrlScheme: process.env.VUE_APP_UCOM_HOMEPAGE_URL_SCHEME, // UCOM ホームページURIスキーム
    termsUrl: '',
  }),
  async mounted() {
    this.isLoading = true;

    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      // 前画面からデータを渡されているかのチェック処理
      const ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
      if (!ucomAccountId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // 完了画面からのブラウザバック対応
      const accountName = this.$store.getters['ucomWebSpaceRegisterStore/accountName'];
      if (accountName) {
        this.deleteStore();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // Webスペース登録_確認_初期表示API呼出し
      const WebSpaceRegisterInfo: UcomWebSpaceRegisterInitialDisplayInfo = await this.getWebSpaceInitialDisplay(ucomAccountId);

      // 初期表示APIのレスポンスを画面項目にセット
      await this.responseDataSet(WebSpaceRegisterInfo);

      // 前画面からのデータを画面項目にセット
      await this.setDataByStore();
      //規約URLセット
      this.termsUrl = this.setTermsUrl();
      // 申し込み日をセット
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      this.applicationDate = this.formatDate(now);
      // ホームページアドレス組み立て(登録時にAPIに渡す)
      if (this.hostingServerName) {
        this.homepageAddress = await this.createHomepageAddress(this.homepageUrlScheme, this.hostingServerName, this.mailAddress);
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },

    // Webスペース登録_確認_初期表示APIからデータを取得
    async getWebSpaceInitialDisplay(ucomAccountId: number) {
      return await SpfApiWebSpaceRegisterAccessor.getWebSpaceInitialDisplay(ucomAccountId);
    },

    // 初期表示APIのレスポンスを画面項目にセット
    async responseDataSet(responseData: UcomWebSpaceRegisterInitialDisplayInfo) {
      if (responseData.errorMessage) {
        this.errorMessages.push(responseData.errorMessage);
        this.errorJudgement = false;
      }
      this.hostingServerName = responseData.hostingServerName;
    },

    // 前画面からのデータを各データにセット
    async setDataByStore() {
      this.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
      this.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
      this.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
    },

    // 「登録」ボタンの活性非活性を判断する処理
    async buttonStatusJudgement() {
      // エラーメッセージがある場合ボタンを活性化しない
      if (!this.errorJudgement) {
        this.conditionJudgment = false;
        return;
      }
      // 注意事項への同意がtrueじゃない場合ボタンを活性化しない
      if (this.consentJudgement) {
        this.conditionJudgment = false;
        return;
      }
      // 「登録」ボタン活性化
      this.conditionJudgment = true;
    },
    /**
     * サービス規約のURLをセット
     */
    setTermsUrl(): string {
      //物件基本情報
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty?.service_provid_former.service_provid_former_id == UCOM_PROPERTY_SERVICE_PROVID_FORMER.TNC.id) {
        return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_TNC;
      }
      return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_ARTERIA;
    },
    // 「登録」ボタン押下時の処理
    async onRegister(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // Webスペース登録_確認_登録API呼出し
        const responseRegisterWebSpace: UcomWebSpaceRegisterInfo = await this.registerWebSpaceCall();
        if (responseRegisterWebSpace.errorMessage) {
          this.errorMessages = [];
          this.errorMessages.push(responseRegisterWebSpace.errorMessage);

          await this.scrollPageTop();
          this.isLoading = false;
          return;
        }

        // 完了画面に渡すデータをstoreに保存
        this.saveStore();

        // 完了画面へ遷移
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-option-change/web-space/completed').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/error').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },

    // Webスペース登録_確認_登録API呼出し
    async registerWebSpaceCall() {
      return await SpfApiWebSpaceRegisterAccessor.registerWebSpace(this.ucomAccountId, this.homepageAddress, this.applicationDate);
    },

    /**ホームページアドレス組み立て */
    async createHomepageAddress(homepageUrlScheme: string, hostingServerName: string, mailAddress: string): Promise<string> {
      if (mailAddress.split('@').length !== 2) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
      }
      const localPart = mailAddress.split('@')[0];
      return homepageUrlScheme + hostingServerName + '/' + localPart + '/';
    },

    // storeにデータを保存
    saveStore() {
      this.$store.commit('ucomWebSpaceRegisterStore/accountName', this.accountName);
      this.$store.commit('ucomWebSpaceRegisterStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomWebSpaceRegisterStore/homepageAddress', this.homepageAddress);
      this.$store.commit('ucomWebSpaceRegisterStore/applicationDate', this.applicationDate);
    },

    // storeのデータを破棄
    deleteStore() {
      this.$store.commit('ucomWebSpaceRegisterStore/accountName', null);
      this.$store.commit('ucomWebSpaceRegisterStore/mailAddress', null);
      this.$store.commit('ucomWebSpaceRegisterStore/homepageAddress', null);
      this.$store.commit('ucomWebSpaceRegisterStore/applicationDate', null);
    },

    // ページトップへスクロール
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },

    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },

  computed: {
    buttonColorSet() {
      // エラーメッセージがある場合ボタンを活性化しない
      if (!this.errorJudgement) {
        return 'btn btn04 bs';
      }
      // 注意事項への同意がtrueじゃない場合ボタンを活性化しない
      if (!this.consentJudgement) {
        return 'btn btn04 bs';
      }
      return 'btn btn01 bs';
    },
  },
});
</script>
