/**
 * Portas会員情報.誕生日が未登録かどうか
 */
export function isEmptyBirthdate(birthdate: string): boolean {
  if (birthdate) {
    if (birthdate === '0000-00-00') {
      return true;
    } else {
      return false;
    }
  }
  return true;
}
