<template>
  <div>
    <LoadingComponent v-if="isMounting" />

    <!-- ISP退会後13ヶ月間の表示: ロゴとISP会員専用トップ -->
    <template v-if="displayPortalPattern === DISPLAY_PORTAL_PATTERN.LOGO_AND_LINK_TO_MEMBER_PAGE">
      <!-- Portasサブスク商材 -->
      <div class="blc">
        <template v-if="displayPortasSubscriptionPurchaseLead">
          <SubscriptionCommonComponent />
        </template>
      </div>

      <div class="blc">
        <div>
          <section class="my-portal-service">
            <h2 class="my-portal-service__title">インターネットサービス</h2>
            <div class="my-portal-service__image">
              <img src="../../../images/logo-emansion.png" alt="e-mansion" />
            </div>
            <p class="my-portal-service__description">
              お住まいの建物には、e-mansion インターネットサービスが提供されております。サービス内容やお問い合わせ先はお住まいのマンション専用ホームページをご覧ください。
            </p>
            <a class="link" :href="getForMansionUrl()" target="My e-mansion">＞ お住まいのマンション専用ホームページはこちら</a>
          </section>
        </div>
      </div>

      <!-- e-mansion会員専用トップページ -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion 会員専用トップページ" />e-mansion 会員専用トップページ</h2>
        <p class="my-description">e-mansionご契約情報の登録変更やオプションサービスのお申し込み・変更は<router-link to="/e-mansion" class="link">こちら</router-link></p>
      </div>

      <!-- つなぐオンラインショップ  -->
      <template v-if="displayTsunaguOnlineShop()">
        <div class="blc">
          <TsunaguOnlineShop
            @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPortalServiceEMansion()"
            @updateDisplayPortalPatternFromTsunaguOnlineShop="updateDisplayPortalPatternAtEMansion()"
          />
        </div>
      </template>

      <!-- Mcloudを連携している場合 -->
      <template v-if="isLinkedToMcloud">
        <div class="blc">
          <McloudCommonComponent />
        </div>
      </template>

      <!-- Mcloudを連携していないかつportas退会申し込み中でない場合 -->
      <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud">
        <div class="blc">
          <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
        </div>
      </template>
    </template>

    <!-- ISP会員のときの表示: 通常表示 -->
    <template v-else-if="displayPortalPattern === DISPLAY_PORTAL_PATTERN.NORMAL">
      <div class="blc">
        <!-- サブスク商品ブロック -->
        <SubscriptionCommonComponent />
      </div>

      <div class="blc">
        <div>
          <div v-if="!isMailPlan">
            <section class="my-portal-service">
              <h2 class="my-portal-service__title">インターネットサービス</h2>
              <div class="my-portal-service__image">
                <img src="../../../images/logo-emansion.png" alt="e-mansion" />
              </div>
              <p class="my-portal-service__description">
                お住まいの建物には、e-mansion インターネットサービスが提供されております。サービス内容やお問い合わせ先はお住まいのマンション専用ホームページをご覧ください。
              </p>
              <a class="link" :href="getForMansionUrl()" target="My e-mansion">＞ お住まいのマンション専用ホームページはこちら</a>
            </section>
          </div>
          <!--バナー表示-->
          <div class="blc banners">
            <div v-if="isCommonAreaWifi && !isTtp">
              <!--e-mansionアカウント未連携の場合-->
              <a v-if="member && !member.primaryKeyMye" :href="`${eMansionUrl}/${apid}/dln00`" target="_blank"
                ><img src="../../../images/e-mansion/side-banner_13.gif" alt="共用部無線LANバナー"
              /></a>
              <!--e-mansionアカウント連携済みの場合-->
              <a v-else :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/dln00`" target="_blank"
                ><img src="../../../images/e-mansion/side-banner_13.gif" alt="共用部無線LANバナー"
              /></a>
            </div>
            <div v-if="isTabho">
              <!--e-mansionアカウント未連携の場合-->
              <a v-if="member && !member.primaryKeyMye" :href="`${eMansionUrl}/${apid}/dts00/dts00_${apid}`" target="_blank"
                ><img src="../../../images/e-mansion/side-banner_24.gif" alt="タブホバナー"
              /></a>
              <!--e-mansionアカウント連携済みの場合-->
              <a v-else :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/dts00/dts00_${apid}`" target="_blank"
                ><img src="../../../images/e-mansion/side-banner_24.gif" alt="タブホバナー"
              /></a>
            </div>
          </div>
          <!-- e-mansionからのお知らせ -->
          <div class="blc" v-if="property && property.em_info_message ? property.em_info_message.length : false">
            <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansionからのお知らせ" />e-mansionからのお知らせ</h2>
            <!-- 表示0件の場合、【e-mansionからのお知らせ】一覧を非表示(タイトルも非表示) -->
            <div class="service-news border-grey" v-if="property && property.em_info_message ? property.em_info_message.length : false">
              <ul>
                <li class="list-none" v-for="(message, index) in emInfoMessages" :key="index">
                  <span class="noticelist">{{ getKindString(message.message_kind) }}</span>
                  <b>{{ message.message_date }} </b>
                  <span v-if="message.message_link">
                    <a :href="message.message_link" target="My e-mansion" v-html="message.message_label"></a>
                  </span>
                  <span v-if="!message.message_link" v-html="message.message_label"></span>
                </li>
              </ul>
            </div>
          </div>

          <template v-if="member && !member.primaryKeyMye">
            <!-- e-mansion会員登録: 入会可能物件の場合 -->
            <template v-if="property && property.em_adm_flag === '1'">
              <div class="blc">
                <h2 class="my-portal-service__title">インターネット　オプションサービス</h2>
                <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion会員登録" />e-mansion 会員登録</h2>

                <!-- Portas会員情報.生年月日が登録されていない場合 -->
                <template v-if="isEmptyBirthdate()">
                  <p class="my-description">Portasマイページから生年月日をご登録いただいた後、e-mansion会員登録のお手続きをお願いいたします。</p>
                  <p class="my-description"><router-link to="/platform/my-page" class="link">Portasマイページはこちら</router-link></p>
                </template>

                <!-- Portas会員情報.生年月日が登録されているが、18歳未満の場合 -->
                <template v-else-if="isUnder18()">
                  <p class="my-description">18歳未満の方のe-mansionへのご入会には、別途「親権同意書」のご提出が必要となります。</p>
                  <p class="my-description">
                    誠に恐れ入りますが、<a class="link" :href="getForMansionUrl()" target="My e-mansion">お住まいのマンション専用ホームページ（こちら）</a
                    >よりe-mansionサポートセンターへご連絡いただき、必要書類をお取り寄せください。
                  </p>
                </template>

                <!-- Connectix提供物件の場合 -->
                <template v-else-if="isProvidedConnectix">
                  <p class="my-description">
                    Connectixなどオプションサービスご利用にはe-mansion会員登録が必要です。<br />
                    お済みでない場合は<a class="link" :href="getRegisterUrl()" target="My e-mansion" @click="clickedEMansionRegisterLink()">こちらから</a>ご登録ください。
                  </p>
                </template>

                <template v-else>
                  <p class="my-description">
                    オプションサービスご利用にはe-mansion会員登録が必要です。<br />
                    お済みでない場合は<a class="link" :href="getRegisterUrl()" target="My e-mansion" @click="clickedEMansionRegisterLink()">こちらから</a>ご登録ください。
                  </p>
                </template>

                <!-- e-mansion 新規会員登録リンクがクリックされた後表示する内容 -->
                <template v-if="displayMessageWhenClickedEMansionRegisterLink">
                  <div class="my-display-message-when-clicked-e-mansion-register-link">
                    <p class="my-description">※e-mansion会員登録完了後、下記のリンクを選択してください。e-mansion会員専用トップページへ移動します。</p>
                    <p class="my-description"><a class="link" @click.prevent="clickedLinkToEMansion()">▶e-mansion会員専用トップページへ</a></p>
                    <template v-if="displayMessageWhenNotLinkToEMansion">
                      <p class="my-description red">e-mansion会員登録が完了しておりません。少し時間をおいて、再度上記リンクを選択してください。</p>
                    </template>
                  </div>
                </template>
              </div>
            </template>

            <!-- e-mansion会員登録がお済みの場合 -->
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion会員登録がお済みの場合" />e-mansion 会員登録がお済みの場合</h2>
              <p class="my-description">
                e-mansionオプションサービスご利用前に、<router-link to="/e-mansion/linking" class="link">こちらから</router-link>メインアカウントをPortasに連携してください。
              </p>
            </div>
          </template>

          <template v-else>
            <!-- e-mansion会員専用トップページ -->
            <div class="blc">
              <h2 class="my-portal-service__title">インターネット　オプションサービス</h2>
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion 会員専用トップページ" />e-mansion 会員専用トップページ</h2>
              <p class="my-description">e-mansionご契約情報の登録変更やオプションサービスのお申し込み・変更は<router-link to="/e-mansion" class="link">こちら</router-link></p>
            </div>
          </template>

          <!-- Connectixお申し込み: Connectix提供物件 かつ Connectix未契約 -->
          <template v-if="isProvidedConnectix && !isContractedConnectix && customer && property">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectixお申し込み" />Connectixお申し込み</h2>
              <div class="table-scroll js-scrollable">
                <table class="table">
                  <tr>
                    <th colspan="2">サービス</th>
                    <th>料金</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td colspan="2"><p>Connectix</p></td>

                    <!-- キャンペーン中のときは、表示を変更する -->
                    <td v-bind:class="{ 'is-during-campaign': property.op.sdwan.fee_cp }">
                      <span class="red">キャンペーン中</span>
                      <span class="default-fee">{{ property.op.sdwan.fee }}円/月</span>
                      <span class="red">→ {{ property.op.sdwan.fee_cp }}円／月</span>
                    </td>

                    <td>
                      <div class="connectix-link">
                        <router-link to="/connectix/e-mansion/terms">お申し込み</router-link>
                      </div>
                    </td>
                  </tr>
                </table>
                <p class="att grey">
                  ※価格は（税抜）の記載がないものは全て、新税率に基づく税込表示（消費税10%）です。今後消費税が改正された場合は、改正後の税率による価格に変更となります。
                </p>
              </div>
            </div>
          </template>

          <div v-if="!isMailPlan">
            <!-- インターネット通信速度にお困りの方へ -->
            <template v-if="isProvidedConnectix">
              <div class="blc">
                <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
                <ul class="my-card-list grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
                  <li class="my-card-list__item">
                    <section class="my-card-content">
                      <div class="my-card-content__image">
                        <a href="https://speedtest.tsunagunet.com/e/" target="My e-mansion">
                          <img src="../../../images/ico01.png" alt="スピードテスト" class="mt20" />
                        </a>
                      </div>
                      <div class="my-card-content__text">
                        <h4 class="my-card-content__title">自宅の通信環境をチェック</h4>
                        <p class="my-card-content__description">現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                        <p class="my-card-content__link-text">
                          <a href="https://speedtest.tsunagunet.com/e/" target="My e-mansion" class="link">
                            スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i>
                          </a>
                        </p>
                      </div>
                    </section>
                  </li>
                  <li class="my-card-list__item">
                    <section class="my-card-content">
                      <div class="my-card-content__image">
                        <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My e-mansion">
                          <img src="../../../images/ico02.png" alt="通信の改善" class="mt20" />
                        </a>
                      </div>
                      <div class="my-card-content__text">
                        <h4 class="my-card-content__title">接続速度が遅いと感じた場合</h4>
                        <p class="my-card-content__description">
                          モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                        </p>
                        <p class="my-card-content__link-text">
                          <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My e-mansion" class="link">
                            改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                          </a>
                        </p>
                      </div>
                    </section>
                  </li>
                  <li class="my-card-list__item">
                    <section class="my-card-content">
                      <div class="my-card-content__image">
                        <a :href="connectixLpUrl" target="My e-mansion">
                          <img src="../../../images/ico03.png" alt="オプション" class="mt20" />
                        </a>
                      </div>
                      <div class="my-card-content__text">
                        <h4 class="my-card-content__title">オンライン会議や動画などもっと快適なインターネット環境を試したい方は…</h4>
                        <p class="my-card-content__description">インターネットオプションサービス「Connectix」お申し込みのお客様専用の優先ルートで快適インターネット</p>
                        <p class="my-card-content__link-text">
                          <a :href="connectixLpUrl" class="link" target="My e-mansion">詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                        </p>
                      </div>
                    </section>
                  </li>
                </ul>
              </div>
            </template>

            <!-- インターネット通信速度にお困りの方へ: Connectix提供不可物件の場合 -->
            <template v-else>
              <div class="blc">
                <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
                <ul class="my-card-list grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
                  <li class="my-card-list__item">
                    <section class="my-card-content">
                      <div class="my-card-content__image">
                        <a href="https://www.tsunagunet.com/internet/kaizen_e.html" target="My e-mansion">
                          <img src="../../../images/ico02.png" alt="通信の改善" class="mt20" />
                        </a>
                      </div>
                      <div class="my-card-content__text">
                        <h4 class="my-card-content__title">接続速度が遅いと感じた場合</h4>
                        <p class="my-card-content__description">
                          モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                        </p>
                        <p class="my-card-content__link-text">
                          <a href="https://www.tsunagunet.com/internet/kaizen_e.html" target="My e-mansion" class="link">
                            改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                          </a>
                        </p>
                      </div>
                    </section>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>

        <!-- つなぐオンラインショップ  -->
        <template v-if="displayTsunaguOnlineShop()">
          <div class="blc">
            <TsunaguOnlineShop
              @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPortalServiceEMansion()"
              @updateDisplayPortalPatternFromTsunaguOnlineShop="updateDisplayPortalPatternAtEMansion()"
            />
          </div>
        </template>

        <!-- Mcloudを連携している場合 -->
        <template v-if="isLinkedToMcloud">
          <div class="blc">
            <McloudCommonComponent />
          </div>
        </template>

        <!-- Mcloudを連携していないかつportas退会申し込み中でない場合 -->
        <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud">
          <div class="blc">
            <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
          </div>
        </template>
      </div>
    </template>

    <template v-if="isDisplayFAQComponent()">
      <div class="blc">
        <FrequentlyAskedQuestions
          :serviceType="getServiceType()"
          :isDisplayConnectixFAQ="getDisplayConnectixFAQFlag()"
          :eMansionMemberRegistrationUrl="getRegisterUrl()"
          :e-mansion-for-mansion-url="getForMansionUrl()"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.my-portal-service {
  &__title {
    display: inline-block;
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.6;
    border-bottom: 3px solid #0097e0;
  }
  &__image {
    margin-bottom: 10px;
  }
  &__description {
    margin-bottom: 10px;
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
}

p.my-description {
  line-height: 1.6;
  letter-spacing: 0.8px;
}

ul.my-card-list {
  list-style: none;
  margin: 0;
  padding: 0;
  & li.my-card-list__item {
    border: 1px solid #d3d3d3;
    text-align: center;
  }
}

section.my-card-content {
  & div.my-card-content__image {
    & img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  & div.my-card-content__text {
    padding: 10px 30px 20px;
    text-align: left;
    & h4.my-card-content__title {
      margin: 0;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 25px;
    }
    & p.my-card-content__description {
      line-height: 1.6;
      letter-spacing: 0.8;
    }
    & p.my-card-content__link-text {
      text-align: right;
    }
  }
}

.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;
  & ul {
    padding: 0;
    & li {
      padding: 15px 0 15px 20px;
      border-bottom: 1px solid #cf1225;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        box-sizing: border-box;
        border: 6px solid transparent;
        border-left: 10px solid #cf1225;
      }
    }
  }
}
.banners {
  display: flex;
  & img {
    margin: 8px;
  }
}
.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

table.table {
  margin: 40px 0 10px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
  & th {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    background-color: #fbfbfb;
  }
  & td {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    & span {
      display: none;
      &.default-fee {
        display: inline;
      }
    }
    &.is-during-campaign {
      & span {
        display: block;
      }
    }
    & div.connectix-link {
      display: flex;
      justify-content: center;
      & a {
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
        color: #d11c2e;
        border: 1px solid #d11c2e;
        border-radius: 3px;
      }
    }
  }
}

.my-display-message-when-clicked-e-mansion-register-link {
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #d3d3d3;
  & p.red {
    margin-top: 8px;
  }
  & a.link {
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  .my-portal-service {
    &__title {
      font-size: 18px;
    }
  }

  section.my-card-content {
    & div.my-card-content__text {
      padding: 10px 15px 20px;
    }
  }

  div.table-scroll {
    & table {
      width: 780px;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { DISPLAY_PORTAL_PATTERN, UA_TYPE } from '@/shared/const/service-type';
import { EMansionProperty, EmInfoMessage } from '@/shared/classes/external-api/e-mansion/property-response';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import FrequentlyAskedQuestions from '../../components/frequently-asked-questions.vue';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { getDisplayPortalPatternCaseEMansion } from '@/shared/util/func-get-display-portal-pattern-isp-withdrawal';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { leaveFontRedAndBrTag } from '@/shared/util/func-leave-font-red-and-br-tag';
import McloudCommonComponent from '../../components/mcloud-common.vue';
import SubscriptionCommonComponent from './subscription-common.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { isUnder18 } from '@/shared/util/func-is-under-18';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { isEmptyBirthdate } from '@/shared/util/func-is-empty-birthdate';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import TsunaguOnlineShop from '@/shared/components/tnos/tsunagu-online-shop.vue';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';

export default defineComponent({
  name: 'portal-service-component-e-mansion',
  components: {
    LoadingComponent,
    FrequentlyAskedQuestions,
    McloudCommonComponent,
    TsunaguOnlineShop,
    SubscriptionCommonComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    // ISP会員ステータス
    ispMemberStatus: string;
    // portal表示パターン
    displayPortalPattern: string;
    // Portalの表示パターン定数
    DISPLAY_PORTAL_PATTERN: typeof DISPLAY_PORTAL_PATTERN.E_MANSION;
    // e-mansion物件基本情報
    property: EMansionProperty | null;
    // e-mansion契約基本情報
    customer: EMansionCustomer | null;
    // Connectix提供物件かどうか
    isProvidedConnectix: boolean;
    // Connectix契約済みかどうか
    isContractedConnectix: boolean;
    // ConnectixLPのURL
    connectixLpUrl: string;
    // e-mansion サイト URL
    eMansionUrl: string;
    // e-mansion サイト MY URL
    eMansionMyUrl: string;
    // お知らせ用メッセージ
    emInfoMessages: Array<EmInfoMessage>;
    // scroll-hint モジュール
    ScrollHint: any;
    // 8桁APIDE
    apid: string;
    // ランダムな10桁の値
    random10Digit: string;
    // mounted() 実行中かどうか
    isMounting: boolean;
    //会員種別
    planType: SERVICE_PLAN_TYPE | undefined;
    //メール会員かどうか
    isMailPlan: boolean;
    //共用部無線LANのバナーを表示するか
    isCommonAreaWifi: boolean;
    //タブホのバナーを表示するか
    isTabho: boolean;
    // Mcloudと連携しているかどうか
    isLinkedToMcloud: boolean;
    // Mcloud未連携時にリンクを表示するかどうか
    isDisplayNotLinkedMcloud: boolean;
    // TTP物件か否か
    isTtp: boolean;
    // e-mansion 新規会員登録リンクがクリックされた後に表示するメッセージを表示するかどうか
    displayMessageWhenClickedEMansionRegisterLink: boolean;
    // e-mansion 新規会員登録リンクがクリックされた後に表示するメッセージの中で、e-mansion未連携時のメッセージを表示するかどうか
    displayMessageWhenNotLinkToEMansion: boolean;
    // e-mansion会員専用トップページへのリンクをクリック後の処理を実行中かどうか
    isExecutingClickedLinkToEMansion: boolean;
    /** Portasサブスク商材の購入導線を表示するかどうか */
    displayPortasSubscriptionPurchaseLead: boolean;
  } {
    return {
      member: null,
      memberStatus: null,
      ispMemberStatus: '',
      displayPortalPattern: DISPLAY_PORTAL_PATTERN.E_MANSION.NORMAL,
      DISPLAY_PORTAL_PATTERN: DISPLAY_PORTAL_PATTERN.E_MANSION,
      property: null,
      customer: null,
      isProvidedConnectix: false,
      isContractedConnectix: false,
      connectixLpUrl: process.env.VUE_APP_E_MANSION_LP_URL,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      emInfoMessages: [],
      ScrollHint: require('../../../shared/libraries/scroll-hint/scroll-hint'),
      apid: '',
      random10Digit: '',
      isMounting: true,
      planType: undefined,
      isMailPlan: false,
      isCommonAreaWifi: false,
      isTabho: false,
      isLinkedToMcloud: false,
      isDisplayNotLinkedMcloud: true,
      isTtp: false,
      displayMessageWhenClickedEMansionRegisterLink: false,
      displayMessageWhenNotLinkToEMansion: false,
      isExecutingClickedLinkToEMansion: false,
      displayPortasSubscriptionPurchaseLead: true,
    };
  },
  emits: ['updateLeftMenuLogoFromPortalServiceEMansion'],
  async mounted(): Promise<void> {
    try {
      this.member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // Mcloud連携の判定
      if (this.member?.primaryKeyMcloud) {
        this.isLinkedToMcloud = true;
      } else if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        // Portas退会申し込み中 の場合Mcloud登録リンクは表示しない
        this.isDisplayNotLinkedMcloud = false;
      }

      const property: Property | null = this.$store.getters['propertyStore/property'];

      if (property?.apartmentId) {
        // 物件基本情報APIのレスポンスを使う処理
        this.processEMansionPropertyApiResponse();
        if (this.member.primaryKeyMye) {
          // 契約基本情報APIのレスポンスを使う処理
          this.processEMansionCustomerApiResponse();
        }
      }
      //会員種別を取得
      this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      this.isMailPlan = this.isMailPlanByServicePlanType(this.planType);
    } catch (error) {
      throw error;
    }

    this.$nextTick(() => {
      this.jsScrollable();
    });

    if (this.property?.IN_APID) {
      this.apid = this.property?.IN_APID;
    }
    this.random10Digit = this.createRd();

    // ISP会員ステータスが退会済みログイン不可となるとき
    if (this.memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      if (!this.member.primaryKeyMye) {
        this.displayPortalPattern = DISPLAY_PORTAL_PATTERN.E_MANSION.NONE;
      }
    }

    // ISP会員ステータスが退会済みログイン不可となるとき
    if (this.memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.displayPortasSubscriptionPurchaseLead = false;
      if (!this.member.primaryKeyMye) {
        this.displayPortalPattern = DISPLAY_PORTAL_PATTERN.E_MANSION.NONE;
      }
    }
    this.isMounting = false;
  },
  methods: {
    /**
     * 物件基本情報を使う処理
     */
    async processEMansionPropertyApiResponse(): Promise<void> {
      const eMansionProperty: EMansionProperty | null = this.$store.getters['eMansionCommonStore/property'];

      if (eMansionProperty instanceof EMansionProperty) {
        this.property = eMansionProperty;
        if (eMansionProperty.em_info_message !== null) {
          this.emInfoMessages = eMansionProperty.em_info_message.map((message) => {
            message.message_label = leaveFontRedAndBrTag(message.message_label);
            return message;
          });
        }

        // Connectix提供物件かどうか
        if (eMansionProperty.op.sdwan.flag === '1') {
          this.isProvidedConnectix = true;
        }
        if (eMansionProperty.common_area_wifi && eMansionProperty.common_area_wifi !== '0') {
          this.isCommonAreaWifi = true;
        }
        const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const isBeforeSin = this.isDateBeforeSinDay(processOn, new Date(eMansionProperty.em_sin_time));
        if (eMansionProperty.em_bn.em_bn_id?.includes('bn000173') && !isBeforeSin) {
          this.isTabho = true;
        }

        this.isTtp = this.isPropertyTypeTtp(eMansionProperty.apartment_special_type_flag);
      }
    },
    isDateBeforeSinDay(date: Date, sin: Date): boolean {
      const n = process.env.VUE_APP_E_MANSION_DAYS_TO_START_SERVICE;
      if (!n) {
        throw new Error('process.env.VUE_APP_E_MANSION_DAYS_TO_START_SERVICEがありません');
      }
      const marginedDate = new Date(sin.getTime());
      marginedDate.setDate(marginedDate.getDate() - Number(n));

      return date < marginedDate;
    },
    /**
     * 契約基本情報を使う処理
     */
    processEMansionCustomerApiResponse(): void {
      // e-mansionの契約基本情報をStoreから取得
      const eMansionCustomer: EMansionCustomer | null = this.$store.getters['eMansionCommonStore/customer'];

      if (eMansionCustomer instanceof EMansionCustomer) {
        this.customer = eMansionCustomer;

        // connectix契約済みかどうか
        if (eMansionCustomer.op_connectix_contract_status === '1') {
          this.isContractedConnectix = true;
        }

        this.ispMemberStatus = getIspMemberStatusEMansion(this.memberStatus!, eMansionCustomer);
        this.displayPortalPattern = getDisplayPortalPatternCaseEMansion(this.memberStatus!, this.ispMemberStatus);
      }
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
        case '05':
        case '06':
          kindStr = '障害';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollable() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * 「お住まいのマンション専用ホームページはこちら」のURL
     *  e-mansionとの紐づけ有無でリンク先を変更
     */
    getForMansionUrl(): string {
      const apid = this.property?.IN_APID;
      if (this.member?.primaryKeyMye) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${apid}/`;
      } else {
        return `${this.eMansionUrl}/${apid}`;
      }
    },
    /**
     * 9桁のランダムな数字の文字列を生成
     */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /**
     * e-mansion会員登録セクションの「こちらから」のURL
     */
    getRegisterUrl(): string {
      const apid = this.property?.IN_APID;
      return `${this.eMansionMyUrl}/entry/entry_basic_set.php?apid=${apid}&key_id_portas=${this.member?.portasMemberIdWithEncrypted}&rd=${this.random10Digit}`;
    },
    getServiceType(): string {
      return UA_TYPE.E_MANSION;
    },
    getDisplayConnectixFAQFlag(): boolean {
      if (this.displayPortalPattern !== DISPLAY_PORTAL_PATTERN.E_MANSION.NORMAL) {
        return false;
      }

      // e-mansion対象物件, Connectix提供物件, ISP紐づけ無し, 入会可能物件 のときのみ表示
      if (this.isProvidedConnectix && !this.member?.primaryKeyMye && this.property?.em_adm_flag === '1') {
        return true;
      }

      return false;
    },
    isDisplayFAQComponent() {
      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED || this.memberStatus?.status === MEMBER_STATUS.PROPERTY_LINKED) {
        return true;
      }
      return false;
    },
    isMailPlanByServicePlanType(servicePlanType: SERVICE_PLAN_TYPE | undefined) {
      if (servicePlanType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        return true;
      }
      if (servicePlanType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return true;
      }
      return false;
    },
    isPropertyTypeTtp(apartmentSpecialTypeFlag: string[][]) {
      // TTP物件の場合にTrueと判断
      if (!apartmentSpecialTypeFlag) {
        return false;
      }
      if (!apartmentSpecialTypeFlag[0]) {
        return false;
      }
      const flags = apartmentSpecialTypeFlag[0];
      const FLAG_TRUE = '1';
      return flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.TTP] === FLAG_TRUE;
    },
    isUnder18() {
      if (this.member?.birthdate) {
        return isUnder18(this.member.birthdate);
      }
      return false;
    },
    /**
     * e-mansion 新規会員登録リンク がクリックされた後に表示するメッセージの表示制御
     */
    clickedEMansionRegisterLink() {
      this.displayMessageWhenClickedEMansionRegisterLink = true;
    },
    /**
     * e-mansion 新規会員登録リンク がクリックされた後に表示するメッセージの表示制御の中で、
     * e-mansion 会員専用トップへのリンクがクリックされた後に表示するメッセージの表示制御
     */
    async clickedLinkToEMansion() {
      if (this.isExecutingClickedLinkToEMansion) {
        return;
      }

      this.isExecutingClickedLinkToEMansion = true;

      // e-mansionアカウントテーブルまで更新済みかどうか
      await AuthService.refresh();
      const loginId = await AuthService.getEMansionLoginId();

      if (loginId) {
        const eMansionAccountInfo: EMansionAccountInfo | null = await this.$store.dispatch('eMansionAccountStore/eMansionAccountInfo', loginId);
        if (eMansionAccountInfo?.eMansionAccountInfo.primaryKeyAuth0) {
          // ページ遷移時に beforeEach で取得できるように null にする
          this.$store.commit('memberStore/member', null);
          this.$store.commit('memberStore/memberStatus', null);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion').catch((error: any) => {
            checkRouterError(error);
          });
        } else {
          this.displayMessageWhenNotLinkToEMansion = true;
        }
      } else {
        this.displayMessageWhenNotLinkToEMansion = true;
      }
      this.isExecutingClickedLinkToEMansion = false;
    },
    isEmptyBirthdate() {
      if (this.member?.birthdate) {
        return isEmptyBirthdate(this.member.birthdate);
      }
      return true;
    },
    displayTsunaguOnlineShop(): boolean {
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && !this.member?.primaryKeyTnshop) {
        return false;
      }
      return true;
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにする(下の階層のコンポーネントから伝播されてくる)
     */
    sendUpdateLeftMenuLogoAtPortalServiceEMansion(): void {
      this.$emit('updateLeftMenuLogoFromPortalServiceEMansion', true);
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * displayPortalPatternを更新する
     */
    async updateDisplayPortalPatternAtEMansion(): Promise<void> {
      this.member = this.$store.getters['memberStore/member'];

      await StoreExternalApiResponse.main();
      if (this.member?.primaryKeyMye) {
        this.processEMansionCustomerApiResponse();
      }
    },
  },
});
</script>
