/**
 * Mcloud 共通利用 API : ユーザー情報取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class McloudUserSessionResponseData {
  public data!: McloudUserSessionResponse;
  public included!: McloudUserSessionResponseIncluded[];

  constructor(partial: Partial<McloudUserSessionResponseData>) {
    Object.assign(this, partial);
  }
}

/** Data クラス */
export class McloudUserSessionResponse {
  public id!: string;
  public type!: string;
  public attributes!: McloudUserSessionResponseAttributes;
  public relationships!: McloudUserSessionResponseRelationships;

  constructor(partial: Partial<McloudUserSessionResponse>) {
    Object.assign(this, partial);
  }
}

export class McloudUserSessionResponseAttributes {
  /** ユーザID */
  public id!: number;
  /** マンションID */
  public apartment_id!: number;
  /** 権限ID */
  public role_id!: number;
  /** 権限名 */
  public role_name!: string;
  /** メール */
  public email!: string;
  /** 連絡先 */
  public tel1!: string;
  /** 名前 */
  public name!: string;
  /** 名前（カナ） */
  public kana!: string;
  /** 最終ログイン時刻 */
  public last_login_at!: string;
  /** イメージURL */
  public image_url!: string;
  /** ログインID */
  public login!: string;
  /** 部屋番号 */
  public room_number!: string;
  /** 利用明細機能を使用できるかどうか */
  public use_payment!: boolean;
  public auth0_logout_url!: string;

  constructor(partial: Partial<McloudUserSessionResponseAttributes>) {
    Object.assign(this, partial);
  }
}

export class McloudUserSessionResponseRelationships {
  public apartment!: McloudUserSessionResponseSharedProperty;
  public building!: McloudUserSessionResponseSharedProperty;

  constructor(partial: Partial<McloudUserSessionResponseRelationships>) {
    Object.assign(this, partial);
  }
}

export class McloudUserSessionResponseSharedProperty {
  public data!: McloudUserSessionResponseSharedData;

  constructor(partial: Partial<McloudUserSessionResponseSharedProperty>) {
    Object.assign(this, partial);
  }
}

export class McloudUserSessionResponseSharedData {
  public id!: string;
  public type!: string;

  constructor(partial: Partial<McloudUserSessionResponseSharedData>) {
    Object.assign(this, partial);
  }
}

/** Included クラス */
export class McloudUserSessionResponseIncluded {
  public id!: string;
  public type!: string;
  public attributes!: McloudUserSessionResponseIncludedAttributes;

  constructor(partial: Partial<McloudUserSessionResponseIncluded>) {
    Object.assign(this, partial);
  }
}

export class McloudUserSessionResponseIncludedAttributes {
  public id?: number;
  public name?: string;
  public image_url?: string;
  public use_pos?: boolean;

  constructor(partial: Partial<McloudUserSessionResponseIncludedAttributes>) {
    Object.assign(this, partial);
  }
}
