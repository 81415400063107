<template>
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェックあんしんサービス plus</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ウイルスチェックあんしんサービス Plus</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェックあんしんサービス plus 確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="ウイルスチェックあんしんサービス plus" />ご解約確認</h2>
        <!-- 
        <p>ウイルスチェックあんしんサービス plusの変更お手続きを行います。</p>
        -->

        <ErrorMessagesComponent v-if="errorMessages" :errorMessageTitle="errorMessageTitle" :errorMessages="errorMessages" :isOneSentence="errorMessages.length == 1" />

        <div class="sblc">
          <p>お手続き内容をご確認ください。</p>
          <h3 class="service-h3">お手続き内容</h3>
          <p>「ウイルスチェックあんしんサービス plus」を<font class="red">解約</font>するメールアドレスをご確認ください。</p>
          <table class="table-type2">
            <tbody>
              <tr>
                <th>ご解約対象<br />メールアドレス</th>
                <td>
                  <b>{{ mailAddress }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">ご解約に関する注意事項</h3>
          <p class="red">ご解約の前に、必ずご確認ください。</p>
          <h4 class="service-h4">サービス終了日について</h4>
          <p>1日から20日迄(21日午前0時より翌月扱い)にご解約のお手続きをされた場合、当月の末日をもってウイルスチェックが無効となります。</p>
          <p>21日から月末にご解約のお手続きをされた場合、翌月の末日をもってウイルスチェックが無効となります。</p>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt">ご確認の上、よろしければ「解約」をクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" @click="goBack"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="cancelVirusCheck">解約<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'virus-check-cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    isBusy: boolean;
    errorMessageTitle: string;
    errorMessages: string[];
    ispMemberStatusEMansion: string;
  } {
    return {
      isBusy: false,
      errorMessageTitle: '',
      errorMessages: [],
      ispMemberStatusEMansion: '',
    };
  },
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },

    /**
     * 「解約」のクリックハンドラ
     */
    async cancelVirusCheck(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // ウィルスチェック解約 API を呼び出す
        const eMansionVirusCheckCancelResponseDto = await SpfApiVirusCheckAccessor.cancelVirusCheck(this.mailAddressId);
        if (eMansionVirusCheckCancelResponseDto.message) {
          this.errorMessages = [eMansionVirusCheckCancelResponseDto.message];
          this.isBusy = false;
          window.scrollTo(0, 0);
          return;
        }

        // 処理対象のメールアドレス, サービス終了日を Store に保存する
        this.$store.commit('eMansionMailStore/setSelectedMailAddress', this.mailAddress);
        this.$store.commit('eMansionMailStore/setSelectedCancelOn', new Date(eMansionVirusCheckCancelResponseDto.cancelOn));
        // 完了 (解約) 画面に遷移する
        await this.$router.push('/e-mansion/mail/virus-check-cancel-complete').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },

    /**
     * 「戻る」のクリックハンドラ
     */
    async goBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      await this.$router.push('/e-mansion/mail/usage').catch((error) => {
        checkRouterError(error);
      });
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading

    /** 処理対象のメールアドレスID */
    mailAddressId(): number {
      const mailAddressId = this.$store.getters['eMansionMailStore/selectedMailAddressId'];

      if (!mailAddressId) {
        throw new Error();
      }

      return mailAddressId;
    },

    /** 処理対象のメールアドレス */
    mailAddress(): string {
      const mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];

      if (!mailAddress) {
        throw new Error();
      }

      return mailAddress;
    },
  }, // computed
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}
</style>
