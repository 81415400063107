<template>
  <div>
    <LoadingComponent v-if="isExecutingSearch" />

    <!-- 検索ボックス部分 -->
    <table class="table-type2">
      <tbody>
        <tr class="under-tr">
          <th>お住まいを検索</th>
          <td>
            <p class="mb10">お住まいを入力して、検索ボタンを押してください。</p>
            <label>
              <p class="mb10"><input type="checkbox" v-model="isPerfectMatch" /> 完全一致で検索をする</p>
            </label>
            <input type="text" class="text middle" placeholder="マンション名・ビル名" v-model="searchKey" />
            <button type="button" class="search-button btn btn01 sp-mt10" :disabled="searchKey.trim() === ''" @click="search()">検索</button>
            <p class="att grey mt15">※検索結果が多すぎると表示されない場合があります。建物名を正確にご入力ください。</p>
            <p class="att grey">※建物名を正確に入力しても検索結果が多いため表示できないとなる場合は、「完全一致検索」にチェックをいれて検索してください。</p>
            <p class="att grey">※漢字や英語、カタカナなどの建物名を正しく入力してください。</p>
            <p class="att grey">※アルファベットは「全角」で入力してください。</p>
            <p class="att grey">※スペースや「・」（中黒）の有無に関係なく検索されます。</p>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 初期表示-->
    <template v-if="searchResultCount === -1"> </template>

    <!-- 検索結果が0件: 準正常系エラーのとき, 検索結果が undefined, その他のエラーコードのとき  -->
    <template v-else-if="searchResultCount === 0">
      <table class="table-type2 border-top-none">
        <tbody>
          <tr class="under-tr">
            <td>
              <p class="search-error-text mb15">
                大変申し訳ございません。<br />
                該当の建物がありません。<br />
                建物名の漢字やカナなどをご確認の上、再度ご入力ください。
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <!-- 検索結果の件数が 1件以上101件未満の場合 ただし、検索結果の件数は正の整数 (100件は決め打ち) -->
    <template v-else-if="searchResultCount > 0 && searchResultCount < 101">
      <table class="table-type2 border-top-none">
        <tbody>
          <tr class="under-tr">
            <th>お住まいを選択してください</th>

            <td>
              <p class="mb15">
                下記の建物が検索されました。<br />
                表示された建物をクリックすると「お住まいの建物」として表示されます。
              </p>
              <table class="search-result">
                <tr v-for="property in propetyList" v-bind:key="property.apartmentId">
                  <td>
                    <input type="radio" name="お住まい" :id="property.apartmentId" :value="property.apartmentId" @click="clickSearchResult(property)" />
                  </td>
                  <td>
                    <label :for="property.apartmentId">
                      <p>{{ property.apartmentNameDisplay }}</p>
                      <p>{{ property.apartmentAddress }}</p>
                    </label>
                    <!-- UCOM対象物件の場合-->
                    <span v-if="isPropertyUcom(property.uaType)" class="ico ico04">UCOM光 レジデンス</span>
                    <!-- e-mansion対象物件の場合-->
                    <span v-if="isPropertyEmansion(property.uaType)" class="ico ico03">e-mansion</span>
                    <!-- five-a対象物件の場合 -->
                    <template v-if="getOemLabel(property.uaType, property.oemType).length > 0">
                      <span class="ico ico04">{{ getOemLabel(property.uaType, property.oemType) }}</span>
                    </template>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <!-- 準正常系エラーの場合: 取得件数オーバー -->
    <template v-else-if="searchResultCount >= 101">
      <table class="table-type2 border-top-none">
        <tbody>
          <tr class="under-tr">
            <td>
              <p class="search-error-text mb15">
                大変申し訳ございません。<br />
                検索結果が多いため表示できません。<br />
                建物名の漢字やカナなどをご確認の上、再度ご入力ください。
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<style lang="scss" scoped>
table.search-result {
  display: block;

  & tr {
    display: flex;
    border: none;

    & td {
      display: block;
      padding: 0;
      padding: 16px 0;

      &:first-child {
        padding-right: 10px;
      }

      & input[type='radio'] {
        top: 4px;
      }

      & label {
        display: block;
        margin: 0;

        & p {
          line-height: 1.6;

          &:first-child {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.under-tr {
  border-bottom: none;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { ExternalSearchPropertiesPropertyInfoDto } from '@/shared/classes/external-api/search-properties/external-search-properties-property-info-dto';
import { ExternalSearchPropertiesSearchQuasiNormalResponseDto } from '@/shared/classes/external-api/search-properties/external-search-properties-quasi-normal-response-dto';
import { ExternalSearchPropertiesRequestDto } from '@/shared/classes/external-api/search-properties/external-search-properties-request-dto';
import { ExternalSearchPropertiesSearchSuccessResponseDto } from '@/shared/classes/external-api/search-properties/external-search-properties-search-success-response-dto';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { INPUT_PROPERTY_FORM_INITAL_STATE } from '@/shared/const/residence-registration';
import { ISP_TYPE_REGEXP, UA_TYPE, UaTypeValue } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { InputPropertyForm, SearchPropertyForm } from '@/store/platform/platform-edit-store';

export default defineComponent({
  name: 'residence-registration-search',
  components: {
    LoadingComponent,
  },
  props: {
    registerValue: {
      type: String,
      default: 'do-not-register',
    },
    /**
     * platformEditStore の inputPropertyForm.selectedProperty を nullにする
     */
    handleClearSelectedProperty: {
      type: Function,
      required: true,
    },
    /**
     * ResidenceRegistrationController.setHasDisplayProperty() を実行する
     * ResidenceRegistrationDisplay を表示する・表示しないを管理する
     */
    handleHasDisplayProperty: {
      type: Function,
      required: true,
    },
    /**
     * ResidenceRegistrationController.updateDisplayProperty() を実行する
     * ResidenceRegistrationDisplay に表示する物件を更新する
     */
    handleUpdateDisplayProperty: {
      type: Function,
      required: true,
    },
  },
  data(): {
    isPerfectMatch: boolean;
    searchKey: string;
    isExecutingSearch: boolean;
    propetyList: ExternalSearchPropertiesPropertyInfoDto[];
    inputPropertyFormInitalState: InputPropertyForm;
    searchResultCount: number;
    searchApiErrorMessage: string;
    oemMaster: Array<GetOemsMasterResponse>;
  } {
    return {
      isPerfectMatch: false,
      searchKey: '',
      isExecutingSearch: false,
      propetyList: [],
      inputPropertyFormInitalState: INPUT_PROPERTY_FORM_INITAL_STATE,
      searchResultCount: -1,
      searchApiErrorMessage: '',
      oemMaster: [],
    };
  },
  /**
   * 1. /platform/entry/confirm から戻ってきたとき、ストアに保存されている 検索条件 を反映する
   * 2. /platform/entry/confirm から戻ってきたとき、ストアに保存されている 検索結果 を反映する
   */
  mounted(): void {
    this.oemMaster = this.$store.getters['oemsStore/oems'];

    // 検索条件反映
    const searchPropertyForm: SearchPropertyForm | null = this.$store.getters['platformEditStore/searchPropertyForm'];

    if (searchPropertyForm) {
      this.isPerfectMatch = searchPropertyForm.isPerfectMatch;
      this.searchKey = searchPropertyForm.searchKey;
    }

    // 検索結果反映
    const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];

    if (inputPropertyForm?.selectedProperty) {
      this.propetyList = inputPropertyForm?.properties;
      this.searchResultCount = this.propetyList.length;
      this.$nextTick(() => {
        if (inputPropertyForm?.selectedProperty) {
          const element = document.getElementById(inputPropertyForm.selectedProperty.apartmentId) as HTMLInputElement;
          element.checked = true; // <input id="apartmentId"> に対応する ラジオボタンにチェックを付ける
          this.clickSearchResult(inputPropertyForm.selectedProperty);
        }
      });
    }
  },
  methods: {
    async search(): Promise<void> {
      if (this.isExecutingSearch) {
        return;
      }

      this.searchApiErrorMessage = '';
      this.isExecutingSearch = true;
      this.removeCheckedFromRadioButton();

      if (this.searchKey.trim() === '') {
        this.isExecutingSearch = false;
        return;
      }

      try {
        const requestDto: ExternalSearchPropertiesRequestDto = new ExternalSearchPropertiesRequestDto({
          apartmentName: this.searchKey,
          perfectMatchFlag: this.isPerfectMatch ? '1' : '0',
        });

        const result: ExternalSearchPropertiesSearchSuccessResponseDto | ExternalSearchPropertiesSearchQuasiNormalResponseDto | undefined =
          await SpfApiService.externalSearchProperties(requestDto);

        // undefined の場合
        if (!result) {
          this.propetyList = [];
          this.searchResultCount = 0;
          this.updateStore([]);

          // 準正常系の場合
        } else if (result instanceof ExternalSearchPropertiesSearchQuasiNormalResponseDto) {
          this.propetyList = [];

          if (result.code === 'APIA001') {
            // APIA001 物件検索 取得件数 0件
            this.searchResultCount = 0;
          } else if (result.code === 'APIA002') {
            // APIA002 物件検索 取得件数オーバー
            // 101 は えいやで決め打ちした値
            this.searchResultCount = 101;
          } else {
            this.searchResultCount = 0;
          }

          this.updateStore([]);

          // 正常系の場合
        } else if (result instanceof ExternalSearchPropertiesSearchSuccessResponseDto) {
          if (result.propertyInfoList.length === 0) {
            this.propetyList = [];
            this.searchResultCount = this.propetyList.length;
            this.updateStore([]);
          } else {
            this.propetyList = result.propertyInfoList;
            this.searchResultCount = this.propetyList.length;
            this.updateStore(result.propertyInfoList);
          }

          this.saveSearchCondition();
        }
      } catch (e) {
        throw e;
      } finally {
        this.handleClearSelectedProperty();
        this.handleHasDisplayProperty(false);
      }
      this.isExecutingSearch = false;
    },
    /**
     * 検索結果をストアに保存する
     * platformEditStore の inputPropertyForm.properties に保存される
     */
    updateStore(value: ExternalSearchPropertiesPropertyInfoDto[]) {
      let inputPropertyFormValue: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (!inputPropertyFormValue) {
        inputPropertyFormValue = this.inputPropertyFormInitalState;
      }
      inputPropertyFormValue.properties = value;
      this.$store.commit('platformEditStore/inputPropertyForm', inputPropertyFormValue);
    },
    /**
     * 検索結果の一覧の中からラジオボタンをクリックされた物件をストアに保存する
     *
     * 検索結果の一覧表示のラジオボタンをクリックしたとき発火
     * platformEditStore の inputPropertyForm.selectedProperty にクリックした物件を保存する
     */
    clickSearchResult(property: ExternalSearchPropertiesPropertyInfoDto) {
      let inputPropertyFormValue: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (!inputPropertyFormValue) {
        inputPropertyFormValue = this.inputPropertyFormInitalState;
      }
      inputPropertyFormValue.selectedProperty = property;
      this.$store.commit('platformEditStore/inputPropertyForm', inputPropertyFormValue);
      this.handleHasDisplayProperty(true);
      this.handleUpdateDisplayProperty();
      this.$emit('memberEdit', true);
    },
    /**
     * 検索結果一覧の中のチェックのついたラジオボタンからチェックを外す
     */
    removeCheckedFromRadioButton() {
      const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];

      if (inputPropertyForm?.selectedProperty) {
        const element = document.getElementById(inputPropertyForm.selectedProperty.apartmentId) as HTMLInputElement;
        element.checked = false; // <input id="apartmentId"> に対応する ラジオボタンのチェックを外す
      }
    },
    /**
     * 検索情報を platformEditState に保存する（入力ページに戻ってきた際の検索条件再現に使用）
     */
    saveSearchCondition() {
      const searchPropertyForm = {
        isPerfectMatch: this.isPerfectMatch,
        searchKey: this.searchKey,
      };
      this.$store.commit('platformEditStore/searchPropertyForm', searchPropertyForm);
    },
    isPropertyEmansion(uaType?: UaTypeValue | null, ispType?: string | null) {
      return uaType === UA_TYPE.E_MANSION && (!ispType || !ISP_TYPE_REGEXP.MCLOUD.test(ispType));
    },
    isPropertyUcom(uaType?: UaTypeValue): boolean {
      return uaType === UA_TYPE.UCOM;
    },
    getOemLabel(uaType: string, oemType: string | undefined): string {
      if (uaType !== UA_TYPE.FIVE_A) {
        return '';
      }

      const filtered = this.oemMaster.filter((item) => item.oemType === oemType);

      if (filtered.length > 1) {
        // oem_typeが同じレコードが複数存在する場合、 start_at が一番大きいレコード(最新のレコード) を採用する
        const latestOem = filtered.reduce((acc, current) => {
          if (acc.startAt < current.startAt) {
            return current;
          }
          return acc;
        });
        return latestOem.label;
      } else if (filtered.length === 1) {
        return filtered[0].label;
      }

      return '';
    },
  },
});
</script>

<style lang="scss" scoped>
.border-top-none {
  border-top: none;
}

.search-result {
  & span.ico {
    display: inline-block;
    margin-right: 10px;
    width: 75px;
    font-size: 10px;
    color: #ffffff;
    text-align: center;
    border-radius: 3px;

    &.ico03 {
      background-color: #b6d93e;
    }

    &.ico04 {
      width: 115px;
      background-color: #1eb9ee;
    }

    &.ico05 {
      background-color: green;
    }
  }
}

table.search-result {
  td {
    padding-bottom: 16px;
  }
}

.search-error-text {
  padding: 10px;
  background-color: #ebecf177;
}
</style>
