import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM Webスペース容量 State */
const ucomWebSpaceState: {
  paymentStatus: string | undefined; //お支払情報登録有無
  ucomAccountMailId: string | undefined; // UCOM_アカウントメールID
  accountName: string | undefined; // UCOM_アカウント名
  mailAddress: string | undefined; // メールアドレス
  homepageAddress: string | undefined; // ホームページアドレス
  webSpaceCapacity: string | undefined; // 現在のWebスペース容量
  changeCapacityList: [] | undefined; // 追加/縮小容量リスト
  changeCapacityLabel: string | undefined; // 追加/縮小容量(Label)
  changeCapacityValue: string | undefined; // 追加/縮小容量(Value)
  unitPrice: number | undefined; // 月額利用料
  webSpaceMaxCapacity: string | undefined; // Webスペース最大容量
  applicationDate: string | undefined; // 申し込み日
  serviceDescription: string | undefined; // サービス説明
  notifyMailAddress: string | undefined; // 通知先メールアドレス
} = {
  paymentStatus: undefined,
  ucomAccountMailId: undefined,
  accountName: undefined,
  mailAddress: undefined,
  homepageAddress: undefined,
  webSpaceCapacity: undefined,
  changeCapacityList: undefined,
  changeCapacityLabel: undefined,
  changeCapacityValue: undefined,
  unitPrice: undefined,
  webSpaceMaxCapacity: undefined,
  applicationDate: undefined,
  serviceDescription: undefined,
  notifyMailAddress: undefined,
};

type UcomWebSpaceState = typeof ucomWebSpaceState;

/** UCOM Webスペース容量 State */
export const ucomWebSpaceStore: Module<UcomWebSpaceState, RootState> = {
  namespaced: true,
  state: ucomWebSpaceState,
  actions: {},
  mutations: {
    paymentStatus: (state, value: string) => (state.paymentStatus = value),
    ucomAccountMailId: (state, value: string) => (state.ucomAccountMailId = value),
    accountName: (state, value: string) => (state.accountName = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    homepageAddress: (state, value: string) => (state.homepageAddress = value),
    webSpaceCapacity: (state, value: string) => (state.webSpaceCapacity = value),
    changeCapacityList: (state, value: []) => (state.changeCapacityList = value),
    changeCapacityLabel: (state, value: string) => (state.changeCapacityLabel = value),
    changeCapacityValue: (state, value: string) => (state.changeCapacityValue = value),
    unitPrice: (state, value: number) => (state.unitPrice = value),
    webSpaceMaxCapacity: (state, value: string) => (state.webSpaceMaxCapacity = value),
    applicationDate: (state, value: string) => (state.applicationDate = value),
    serviceDescription: (state, value: string) => (state.serviceDescription = value),
    notifyMailAddress: (state, value: string) => (state.notifyMailAddress = value),
  },
  getters: {
    paymentStatus: (state): string | undefined => state.paymentStatus,
    ucomAccountMailId: (state): string | undefined => state.ucomAccountMailId,
    accountName: (state): string | undefined => state.accountName,
    mailAddress: (state): string | undefined => state.mailAddress,
    homepageAddress: (state): string | undefined => state.homepageAddress,
    webSpaceCapacity: (state): string | undefined => state.webSpaceCapacity,
    changeCapacityList: (state): [] | undefined => state.changeCapacityList,
    changeCapacityLabel: (state): string | undefined => state.changeCapacityLabel,
    changeCapacityValue: (state): string | undefined => state.changeCapacityValue,
    unitPrice: (state): number | undefined => state.unitPrice,
    webSpaceMaxCapacity: (state): string | undefined => state.webSpaceMaxCapacity,
    applicationDate: (state): string | undefined => state.applicationDate,
    serviceDescription: (state): string | undefined => state.serviceDescription,
    notifyMailAddress: (state): string | undefined => state.notifyMailAddress,
  },
};
