import { Module } from 'vuex';
import { RootState } from './index';

/** 会員情報 State */
const maintenanceState: {
  html: string | null;
} = {
  html: null,
};

/** State の型 */
type MaintenanceState = typeof maintenanceState;

/** 会員情報 Store */
export const maintenanceStore: Module<MaintenanceState, RootState> = {
  namespaced: true,
  state: maintenanceState,
  mutations: {
    maintenance: (state, value: string) => (state.html = value),
  },
  getters: {
    html: (state): string | null => state.html,
  },
  actions: {
    maintenance(context, html: string) {
      context.commit('maintenance', html);
      return html;
    }
  }
};