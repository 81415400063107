<template>
  <div class="during-registration-process">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>宅内工事日選択</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>宅内工事日選択入力</li>
        <li>宅内工事日選択確認</li>
        <li>宅内工事日選択完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <div class="blc">
          <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="宅内工事日選択 完了" />宅内工事日選択 完了</h2>
        </div>
        <p class="p-bottom">
          宅内工事のお申し込みを受け付けました。
          <br />
          宅内工事の調整結果を、担当部署より順次ご連絡いたしますので、今しばらくお待ちください。<br />
          <span class="red">※平日9時から17時半の間にご連絡いたします。留守番電話にメッセージを録音する場合もございますので、ご確認ください。</span>
        </p>
        <div class="btn-area">
          <div class="my-button">
            <button class="btn btn01 bs" @click="onTopPage()">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-bottom {
  margin-bottom: 110px;
}

.red {
  color: #ef3333;
  font-family: 'Noto Sans JP', sans-serif;
}

@media (max-width: 720px) {
  .p-bottom {
    margin-bottom: 80px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
export default defineComponent({
  name: 'select-construction-date-complete',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
  }),
  methods: {
    async onTopPage() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
