<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>機器レンタルサービスお申し込み 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">

      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>機器レンタルサービスお申し込み 確認</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:機器レンタルサービスお申し込み 確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="機器レンタルサービス お申し込み" />機器レンタルサービス お申し込み</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンをクリックしてください。</p>
        <!-- エラーメッセージ表示部 -->
        <div class="error-messages-component" v-if="errorMessageTitle && formerErrMsg && laterErrMsg">
          <div class="err">
            <div v-if="errorMessageTitle">
              <p class="red" v-html="errorMessageTitle"></p>
            </div>
            <div v-if="formerErrMsg && laterErrMsg">
              <ul class="ul-basic01 is-isp-linking-style margin-top-0">
                <li>{{ formerErrMsg }}<a :href="buildingSiteUlr" target="_blank" class="link">こちらから</a>{{ laterErrMsg }}</li>
              </ul>
            </div>
          </div>
        </div>
        <error-messages-component v-bind:errorMessages="messageList" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <table class="table-type2">
            <tr>
              <th class="va-middle">利用機器</th>
              <td>{{ bbrName }}</td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o">{{ bbrPrice }} 円</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>
                <span class="o" v-if="applyDate">{{ applyDate }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <p>本サービスのお申し込みをいただくことにより無線LANルータを1台お貸し出しいたします。</p>
                <ul>
                  <li>1台のみお申し込みいただけます。</li>
                  <li>機器送付先はUCOM光 レジデンスご契約住所に限ります。</li>
                  <li>本サービスのお申し込みは、当社インターネット回線が開通していることが条件となります。</li>
                  <li>お届けする機器については、在庫状況により同等の別機種に変更となる場合がございます。</li>
                </ul>
                <br>
                <p>機器レンタルサービス契約条項については<a :href="termsUrl" target="_blank" class="link">こちらから</a>ご確認ください。</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc:機器レンタルサービスお申し込み 確認 -->

      <!-- blc: ボタンエリア -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn04 bs" @click="goBack()">戻る<i class="material-icons link link-icon">west</i></button>
          <button class="btn btn01 bs" @click="goNext()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc: ボタンエリア -->
    </div>
    <!-- /contents -->
  </div>
  <!-- main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Member } from '@/shared/classes/spf-api/member';
import { SpfApiRentalServiceAccessor } from '@/infra/accessor/spf/ucom/spf-api-rental-service-accessor';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { RENTAL_BBR_PATHES } from '@/shared/const/ucom/ucom-rental-bbr-pathes';
import { RENTAL_BBR_LABELS } from '@/shared/const/ucom/ucom-rental-bbr-labels';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { UcomRentalServiceUpdateInfo } from '@/shared/classes/spf-api/model/ucom/ucom-rental-service-update-info';

export default defineComponent({
  name: 'confirmRentalService',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // APIを含む処理を実行中か
    isLoading: false,
    // 契約条項PDF URL
    termsUrl: process.env.VUE_APP_RENTAL_BBR_TERMS_URL,
    // 申し込み日
    applyDate: '',
    // エラーメッセージリスト
    messageList: [] as string[],
    // エラーメッセージ タイトル
    errorMessageTitle: '',
    // エラーメッセージ構成テキスト
    formerErrMsg: '',
    laterErrMsg: '',

    // 建物サイトURL
    buildingSiteUlr: process.env.VUE_APP_UCOM_URL + RENTAL_BBR_PATHES.BUILDING_SITE,
    // 機器名称
    bbrName: RENTAL_BBR_LABELS.BBR_NAME,
    // 機器利用料
    bbrPrice: RENTAL_BBR_LABELS.BBR_PRICE
  }),
  async mounted () {
    // 申し込み日を設定
    const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const date = now.getDate().toString().padStart(2, '0');
    this.applyDate = year + '/' + month + '/' + date;
  },
  methods: {
    // 戻るボタン押下時
    goBack() {
      if (this.isLoading) {
        return;
      }
      this.$router.push('/ucom/rental-service/input').catch((error) => {
        checkRouterError(error);
      });
    },
    // 申し込むボタン押下時
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // 申し込みが初回かどうかを判定
      // 会員情報を取得
      const member: Member = this.$store.getters['memberStore/member'];
      // 契約基本情報取得API(外部 UCOM)を実行
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      // エラー時はエラー画面へ
      if (!(customer instanceof UcomCustomerResponse)) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
      // 結果から、申し込みが初回かどうかを判定
      if (!customer.is_possible_rental_bbr_apply) {
        // 既に申し込み済の場合、画面上部にエラーメッセージを表示
        this.errorMessageTitle = '機器レンタルサービスをお申し込み済です。';
        this.formerErrMsg = 'レンタル機器の未着、その他のお問い合わせは';
        this.laterErrMsg = 'ご確認ください。';
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      // 申し込みが初回により申込可能の場合
      // レンタルBBR申し込みAPI(内部)を実行
      try {
        const ucomRentalServiceUpdateInfo: string | UcomRentalServiceUpdateInfo = await SpfApiRentalServiceAccessor.updateUcomRentalService(
          member.id,
          this.applyDate,
        );
        if (!(ucomRentalServiceUpdateInfo instanceof UcomRentalServiceUpdateInfo)) {
          this.errorMessageTitle = '時間を置いて再度お申し込みください。';
          this.messageList = ['只今、UCOM光 レジデンス会員登録のお手続き中のため、お申し込みいただけません。\nお客様情報の反映まで1時間～半日かかる可能性がございます。\n恐れ入りますがお時間を空けて再度お試しいただきますようお願いいたします。'];
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        
        // 完了画面に受け渡す情報を、storeにセットする
        // 申し込み日
        this.$store.commit('ucomRentalServiceStore/applyDate', this.applyDate);
        // 完了メール送信先メールアドレス
        this.$store.commit('ucomRentalServiceStore/mailAddress', ucomRentalServiceUpdateInfo.mailAddress);
        this.isLoading = false;
        this.$router.push('/ucom/rental-service/complete').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    },
  },
});
</script>