import {Module} from 'vuex';
import {RootState} from '../index';
import {EMansionErrorDisplayInfo} from "@/shared/classes/spf-api/mail/model/account/e-mansion-error-display-info";
import {SpfApiServerCommonUtilAccessor} from "@/infra/accessor/spf/common/spf-api-server-common-util-accessor";

/** e-mansion アカウント連携 State */
const eMansionErrorDisplayInfoState: {
    eMansionErrorDisplayInfoList: Array<EMansionErrorDisplayInfo> | null;
} = {
    /** e-mansion Account 情報*/
    eMansionErrorDisplayInfoList: null,
};

/** State の型 */
type eMansionErrorDisplayInfoState = typeof eMansionErrorDisplayInfoState;

/** e-mansion Account共通系 Store */
export const eMansionErrorDisplayInfoStore: Module<eMansionErrorDisplayInfoState, RootState> = {
    namespaced: true,
    state: eMansionErrorDisplayInfoState,

    mutations: {
        eMansionErrorDisplayInfoList: (state, value: Array<EMansionErrorDisplayInfo>) => (state.eMansionErrorDisplayInfoList = value),
    },
    getters: {
        eMansionErrorDisplayInfoList: (state): Array<EMansionErrorDisplayInfo> | null => state.eMansionErrorDisplayInfoList,
    },
    actions: {
        async eMansionErrorDisplayInfoList(context, loginId: string) {
            // Api Call & commit
            context.commit('eMansionErrorDisplayInfoList', await SpfApiServerCommonUtilAccessor.getEMansionErrorDisplayInfo());
            return context.getters['eMansionErrorDisplayInfoList'];
        },
    },
};