<template>
  <div>
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールアドレス登録／変更</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>メールアドレス登録／変更</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールアドレス登録／変更 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="メールアドレス登録／変更" />メールアドレス登録／変更</h2>
        <p>登録内容をご確認いただき、問題ない場合は、「登録」ボタンを押してください。</p>
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h3 class="service-h3">メールアドレス登録変更 確認</h3>
        <table class="table-type2">
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">アカウントID</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.accountName }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">利用メール</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.displayMailAddressType }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">メールアドレス</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.mailAddress }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">お申し込み日</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.registerSubscriptionOn }}</span>
            </td>
          </tr>
          <tr v-if="displayData.mailAddressType === mailAddressType.UCOM">
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p class="caution-title"><b>■サービスについて</b></p>
              <p>UCOM光の提供するメールアドレスでは、1GBのメールボックスをご利用いただくことができ<br />有料で10GBに容量を増設することができます。</p>
              <p>また、Webスペース/メール転送サービス/ウイルスチェックサービスをご利用いただくことができます。</p>
              <ul>
                <li>お申し込み後のキャンセルは行えません。</li>
                <li>お申し込み後、すぐにご利用いただけます。</li>
                <li>利用料金は購入翌月から発生いたします。</li>
                <li>
                  メールアドレスはアカウントIDに付随し、単体での解約手続きは行えません。<br />
                  アカウントIDを残したままメールアドレスを解約されたい場合は、<br />
                  「他社のメールアドレスを利用する」設定にご変更ください。
                </li>
                <li>メールアドレスの変更に費用は発生しませんが、同月内に、取得・解約・取得を繰り返された場合でも<br />メールサービス購入毎の費用が発生いたします。</li>
                <li>当月末日までにマイページにて解約されたものに対し、当月での解約を行います。</li>
                <li>解約時は、解約月の当月分の料金が発生いたします。</li>
                <li>利用開始および解約時の日割り計算は行いません。</li>
              </ul>
              <p><font color="blue">▶</font><a :href="`${ucomUrl}`" class="url" target="UCOM">メールアドレス変更時の注意事項</a></p>
            </td>
          </tr>
          <tr v-if="displayData.mailAddressType === mailAddressType.OTHER">
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p class="caution-title"><b>■サービスについて</b></p>
              <p>
                セキュリティオプション等、アカウントIDへのメールアドレスの設定が必要で、<br />
                UCOM光のメールアドレスが不要なお客様については、既にご利用頂いているフリーメールや携帯電話のメールアドレス、他社のメールアドレスを設定いただくことができます。
              </p>
              <p>受信の文字数に制限があるアドレスの場合、必要情報が確認できない場合がありますのでご注意ください。</p>
              <p class="margin-vertical-12"><b>■メールアドレス変更時の注意事項</b></p>
              <ul>
                <li>UCOM光メールアドレスから他社のメールアドレスへ変更された場合、今まで登録されていたメール、Webスペースのご利用ができなくなります。</li>
                <li>その他注意事項は、「UCOM光の提供するメールアドレスを取得する」の「■メールアドレス変更時の注意事項」もあわせてご覧ください。</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <!-- /blc:メールアドレス登録／変更 -->

      <!-- sblc -->
      <div class="sblc">
        <div class="btn-area">
          <button class="btn btn04 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="onNext()">登録<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /sblc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.caution-title {
  margin-bottom: 12px;
}

.margin-vertical-12 {
  margin: 12px 0;
}

.url {
  text-decoration: underline;
}
</style>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiAccountOptionChangeAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-option-change-accessor';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'ucom-mail-address-register-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    isLoading: false,
    /** ボタンダブルクリック防止用 */
    isSubmitting: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    mailAddressType: {
      UCOM: '1',
      OTHER: '2',
    },
    memberId: '',
    ucomAccountId: '',
    /** 画面表示用のデータ */
    displayData: {
      accountName: '',
      /** ご利用メール */
      mailAddressType: '',
      displayMailAddressType: '',
      mailAddress: '',
      registerSubscriptionOn: '',
      isChange: '',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_UPDATE_MAIL_CAUTION_URL,
  }),
  async mounted(): Promise<void> {
    this.isLoading = true;

    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      // エラー画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    //Portas会員IDを取得
    this.$data.memberId = this.$store.getters['memberStore/member'].id;

    // Storeから情報を取得する
    this.$data.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.$data.displayData.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
    this.$data.displayData.displayMailAddressType = this.$store.getters['ucomAccountOptionStore/displayMailAddressType'];
    this.$data.displayData.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
    this.$data.displayData.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
    this.$data.displayData.isChange = this.$store.getters['ucomAccountOptionStore/isChange'];

    // 戻るボタン押下時にstoreのaccountNameに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
    this.$store.commit('ucomAccountOptionStore/accountName', undefined);

    // リロードによりストアが初期化されていたら入力画面に飛ばす
    if (
      !this.$data.ucomAccountId ||
      !this.$data.displayData.accountName ||
      !this.$data.displayData.displayMailAddressType ||
      !this.$data.displayData.mailAddress ||
      !this.$data.displayData.mailAddressType ||
      !this.$data.displayData.isChange
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-option-change/mail-input').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    // 画面表示用のデータを設定する
    const formatDate = 'YYYY/MM/DD';
    this.$data.displayData.registerSubscriptionOn = dayjs(new Date()).format(formatDate);
    this.isLoading = false;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) return;

      // ボタン押下中とする
      this.isSubmitting = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        this.$data.errorMessages = [];
        const subscriptionOn = this.formatDate(await SpfApiServerCommonUtilAccessor.getServerTimeNow());
        // 登録API呼出
        if (this.$data.displayData.isChange === UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT) {
          const registerMessage: string | undefined = await SpfApiAccountOptionChangeAccessor.registerMailAddress(
            Number(this.$data.memberId),
            Number(this.$data.ucomAccountId),
            Number(this.$data.displayData.mailAddressType),
            this.$data.displayData.mailAddress,
            subscriptionOn
          );
          if (registerMessage) {
            this.$data.errorMessages.push(registerMessage);
          }
        }
        // 更新API呼出
        if (this.$data.displayData.isChange === UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE) {
          const updateMessage = await SpfApiAccountOptionChangeAccessor.updateMailAddress(
            Number(this.$data.ucomAccountId),
            Number(this.$data.displayData.mailAddressType),
            this.$data.displayData.mailAddress,
            subscriptionOn
          );
          if (updateMessage) {
            this.$data.errorMessages.push(updateMessage);
          }
        }
        if (this.$data.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          this.showErrorMessage();
          return;
        }

        // 画面表示値をStoreに保存する
        this.$store.commit('ucomAccountOptionStore/ucomAccountId', this.$data.ucomAccountId);
        this.$store.commit('ucomAccountOptionStore/accountName', this.$data.displayData.accountName);
        this.$store.commit('ucomAccountOptionStore/displayMailAddressType', this.$data.displayData.displayMailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddressType', this.$data.displayData.mailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddress', this.$data.displayData.mailAddress);
        this.$store.commit('ucomAccountOptionStore/registerSubscriptionOn', this.$data.displayData.registerSubscriptionOn);
        this.$store.commit('ucomAccountOptionStore/isChange', this.$data.displayData.isChange);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/account-option-change/mail-completed').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/error').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },
    formatDate(date: Date): string {
      const y = date.getFullYear();
      const m = ('00' + (date.getMonth() + 1)).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    async onBack() {
      this.$store.commit('ucomAccountOptionStore/mailAddress', this.$data.displayData.mailAddress);
      this.$store.commit('ucomAccountOptionStore/accountName', this.$data.displayData.accountName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$router.push('/ucom/account-option-change/mail-input').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
});
</script>
