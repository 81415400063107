import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

/**
 * クレジットカードの有効期限が切れているかどうかを返す
 * タイムゾーン アジア / 東京
 *
 * @param {string} month
 * @param {string} year
 * @return {boolean} クレジットカードの有効期限が切れていたら true を返す
 */
export function isCreditCardExpired(month: string, year: string): boolean {
  const currentDate = dayjs(new Date()).tz();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();

  const cardExpireDate = dayjs(`${year}/${month}`).tz();
  const cardExpireYear = cardExpireDate.year();
  const cardExpireMonth = cardExpireDate.month();

  const isExpired = currentYear > cardExpireYear || (currentYear === cardExpireYear && currentMonth > cardExpireMonth);
  return isExpired;
}
