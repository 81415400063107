import { Module } from 'vuex';
import { RootState } from '../index';
import { FiveAConnectixFee, FiveAEntryInputForm } from '../../pages/five-a/entry/classes/entry-input-form';

/** five-a お申し込み State */
const fiveAEntryState: {
  entryInputForm: FiveAEntryInputForm | null;
  fiveAConnectixFee: FiveAConnectixFee | null;
} = {
  /** five-a お申し込み 入力フォーム */
  entryInputForm: null,
  fiveAConnectixFee: null
};

/** State の型 */
type FiveAEntryState = typeof fiveAEntryState;

/** five-a お申し込み Store */
export const fiveAEntryStore: Module<FiveAEntryState, RootState> = {
  namespaced: true,
  state: fiveAEntryState,
  mutations: {
    entryInputForm: (state, value: FiveAEntryInputForm) => (state.entryInputForm = value),
    fiveAConnectixFee: (state, value: FiveAConnectixFee) => (state.fiveAConnectixFee = value)
  },
  getters: {
    entryInputForm: (state): FiveAEntryInputForm | null => state.entryInputForm,
    fiveAConnectixFee: (state): FiveAConnectixFee | null => state.fiveAConnectixFee
  }
};
