import { SubscriptionDetailsArray, SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import { BATCH_ID } from '@/shared/const/batch-ids';
import { CONTENT_PROVIDER_ID } from '@/shared/const/service-type';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { SpfApiService } from '../api/spf-api-service';
dayjs.extend(minMax);

/**
 * 未払い請求に関係する処理を行うサービスクラス
 */
export class UnpaidBillingService {
  /**
   * 引数で渡されてきた会員について、請求処理が未実施の契約に紐づく商品名を取得する
   * @param memberId 会員 ID
   * @param [includeNoCancelDate=true] true: 解約日が入っていない契約を含める
   * @return 請求処理が未実施の契約に紐づく商品名(ただし、解約日が入っていない契約を除く) string[]
   * @return 請求処理が未実施の契約に紐づく商品名(解約日が入っていない契約を含む) string[]
   */
  public static async getUnpaidBillingProductName(memberId: number, includeNoCancelDate = true): Promise<string[]> {
    // ユーザーに紐づく、解約済を含む全ての契約を取得する
    const subscriptionList = await SpfApiService.getSubscriptionList(memberId);
    const contractList = subscriptionList.contracts;

    // 商品契約実績が1件も存在しない場合は請求処理が未実施の契約も存在しない
    if (contractList.length === 0) {
      return [];
    }

    const batchSituation = await SpfApiService.findBatchSituationByBatchId(BATCH_ID['BS01-002']);
    if (!batchSituation.value1) {
      throw new Error('請求結果記録バッチ バッチ実行状況テーブル.設定値1 が未定義');
    }

    const filtered = contractList.filter((contract) => {
      // 解約日が入っていないときは除く
      if (!contract.cancelDate) {
        return false;
      }

      /**
       * U-NEXTの場合、解約日を含む月の3ヶ月後の請求結果記録バッチが実行済みで、支払方法削除可能とする
       * 例:
       * - U-NEXTの契約の解約日:2024/02/29
       * - 請求結果記録バッチのバッチ状況テーブル.設定値1:
       *   - 202402　→　削除不可
       *   - 202403　→　削除不可
       *   - 202404　→　削除不可
       *   - 202405　→　削除可能
       *   - 202406　→　削除可能
       */
      if (contract.contents.contentProviderId === CONTENT_PROVIDER_ID.UNEXT) {
        return dayjs(contract.cancelDate).add(2, 'month').isAfter(dayjs(batchSituation.value1).startOf('month'));
      }

      /**
       * 解約日 < バッチ状況テーブル.設定値1 を 1日にした日付 を満たすとき、支払いがすべて終わっている
       * 2024/01/31 < 2024/02/01 は 支払いがすべて終わっている
       */
      if (dayjs(contract.cancelDate) < dayjs(batchSituation.value1).startOf('month')) {
        return false;
      }
      return true;
    });

    // 解約日が入っていない契約を含めるとき
    if (includeNoCancelDate) {
      const hasNoCancelDate = contractList.filter((contract) => contract.cancelDate === '' || contract.cancelDate === undefined || contract.cancelDate === null);
      return [...filtered, ...hasNoCancelDate].map((contract) => contract.products.productsName ?? '');
    }

    return filtered.map((contract) => contract.products.productsName ?? '');
  }

  /**
   * 引数で渡されてきたサブスクリプション情報について、請求処理が未実施のものを取得する
   * @param subscriptionList サブスクリプション情報
   * @return 請求処理が未実施のサブスクリプション情報 SubscriptionDetailsDto[]
   */
  public static async filterUnpaidBillingSubscription(subscriptionList: SubscriptionDetailsArray): Promise<SubscriptionDetailsDto[]> {
    const batchSituation = await SpfApiService.findBatchSituationByBatchId(BATCH_ID['BS01-002']);
    if (!batchSituation.value1) {
      throw new Error('請求結果記録バッチ バッチ実行状況テーブル.設定値1 が未定義');
    }

    const filtered = subscriptionList.contracts.filter((subscription) => {
      // 解約日が入っていないときは除く
      if (!subscription.cancelDate) {
        return false;
      }

      /**
       * 解約日 < バッチ状況テーブル.設定値1 を 1日にした日付 を満たすとき、支払いがすべて終わっている
       * 2024/01/31 < 2024/02/01 は 支払いがすべて終わっている
       */
      if (dayjs(subscription.cancelDate) < dayjs(batchSituation.value1).startOf('month')) {
        return false;
      }
      return true;
    });

    return filtered;
  }
}
