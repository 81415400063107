<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ご利用状況" />ご利用状況</h2>
        <p v-if="isContracted" class="red"><b> お申し込みいただいております。 </b></p>
        <p v-if="isInWithdrawalApplication" class="red"><b> ご解約を受付中です。 </b></p>
        <p v-if="isInContractApplication" class="red"><b>お申し込み受付中です</b></p>
        <div class="err" v-if="errorMessage">
          <p class="red">{{ errorMessageTitle }}</p>
          <ul class="ul-basic01">
            <li>
              {{ errorMessage }}
            </li>
          </ul>
        </div>

        <div class="sblc" v-if="!errorMessage">
          <h3 class="service-h3">ホームページ設定情報</h3>
          <div class="sblc">
            <table class="table-type1">
              <tr>
                <th class="va-middle">
                  <p>FTPアカウント</p>
                </th>
                <td>
                  <span class="red"
                    ><b>{{ displayData?.ftpAccount }}</b></span
                  >
                </td>
              </tr>
              <tr>
                <th class="va-middle">
                  <p>FTPパスワード</p>
                </th>
                <td>
                  <span class="red"
                    ><b>{{ ftpPassword }}</b></span
                  >
                </td>
              </tr>
              <tr>
                <th class="va-middle">
                  <p>FTPサーバー名</p>
                </th>
                <td>{{ displayData?.ftpServerName }}</td>
              </tr>
              <tr>
                <th class="va-middle">
                  <p>ファイル転送先</p>
                </th>
                <td>{{ displayData?.fileDest }}</td>
              </tr>
              <tr>
                <th class="va-middle">
                  <p>ホームページのURL</p>
                </th>
                <td>{{ displayData?.homepageUrl }}</td>
              </tr>
              <tr v-if="serviceEndDate">
                <th class="va-middle">
                  <p>サービス終了日</p>
                </th>
                <td>{{ serviceEndDate }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="blc" v-if="isContracted || isInContractApplication">
        <h3 class="service-h3">ご解約</h3>
        <p>ホームページを解約します。</p>
        <div class="sblc">
          <div class="sblc">
            <table class="table-type2">
              <tr>
                <th class="va-middle">
                  <h3 class="service-h3">ご解約に関する注意事項</h3>
                  <ul class="ul-basic01">
                    <li>1日～20日までに解約のお手続きをされた場合、当月末日をもってホームページがご利用いただけなくなります。</li>
                    <li>21日～月末までに解約のお手続きをされた場合、翌月末日をもってホームページがご利用いただけなくなります。</li>
                    <li>アップロードされているファイルは全て削除されます。</li>
                    <li>
                      ホームページ容量を追加でご利用いただいている場合は、ホームページサービス終了と同時にホームページ容量も削除されます。ホームページ容量追加分利用料は、1日～20日までにホームページの解約お手続きをされた場合は当月末まで、21日～月末までにホームページの解約お手続きをされた場合は翌月末までお支払いただきます。
                    </li>
                  </ul>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="blc" v-if="isContracted || isInContractApplication">
        <p>解約される場合は「解約」をクリックしてください。確認画面へ進みます。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goCancel()">解約<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { EMansionHomepageCancelInitialDisplayRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-request';
import { MYE_REGISTER_STATUS } from '@/shared/const/e-mansion/mye-register-status';
import { HomepageData } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';

export default defineComponent({
  name: 'homepage-usage-cancel',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    errorMessage: '',
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    /** 画面に表示するホームページ情報 */
    displayData: null as HomepageData | null,
    ftpPassword: '',
    /**登録申込中かどうかのフラグ */
    isInContractApplication: false,
    /** 登録済みかどうかのフラグ */
    isContracted: false,
    /** 解約申請中かどうかのフラグ */
    isInWithdrawalApplication: false,
    serviceEndDate: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.isLoading = true;

      const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // AE05-003.ホームページ：ご利用状況／ご解約_初期表示API呼び出し
      const response = await SpfApiHomepageAccessor.callHomepageCancelInitialDisplay(
        new EMansionHomepageCancelInitialDisplayRequest({
          _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
          _date: now,
        })
      );

      this.errorMessage = response.homepageOpenedMessage ?? '';
      // エラーメッセージがあれば表示して終了とする
      if (response.homepageOpenedMessage) {
        this.isLoading = false;
        return;
      }

      // 表示するホームページ情報
      this.displayData = response.data;
      // パスワードは解読して表示
      this.ftpPassword = ObfuscationService.decode(response.data.ftpPassword);
      // サービス終了日は整形して表示
      const serviceEndDate = response.data.serviceEndDate ? new Date(response.data.serviceEndDate!) : undefined;
      this.serviceEndDate = serviceEndDate ? serviceEndDate.toLocaleDateString() : '';
      // 登録申込中かどうかのフラグ
      if (Number(response.registerStatus) === MYE_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        this.isInContractApplication = true;
      }
      // 登録済みかどうかのフラグ
      if (Number(response.registerStatus) === MYE_REGISTER_STATUS.CONTRACTED.id) {
        this.isContracted = true;
      }
      // 解約申請中かどうかのフラグ
      if (Number(response.registerStatus) === MYE_REGISTER_STATUS.IN_WITHDRAWAL_APPLICATION.id) {
        this.isInWithdrawalApplication = true;
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // e-mansion_ホームページIDを次画面用に保持
      this.$store.commit('eMansionHomepageOpenStore/eMansionHomepageId', this.displayData?.eMansionHomepageId);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage/cancel-confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
