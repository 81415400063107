<template>
  <div class="display-on-sp-tsunagu-online-product-html">
    <iframe :srcdoc="srcHtml" :width="myWidth" :height="myHeight" />
  </div>
</template>

<style lang="scss" scoped>
.display-on-sp-tsunagu-online-product-html {
  & iframe {
    margin-top: 56px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { PostMessageResponseData, TsunaguOnlineProductUrlService } from '@/shared/services/tnos/tsunagu-online-product-url-service';
import { TsunaguOnlineProductHtmlDisplayOnSpService } from '@/shared/services/tnos/tsunagu-online-product-html-display-on-sp-service';
import { TSUNAGU_ONLINE_POST_MESSAGE_RESPONSE_DATA_TYPE as POST_MESSAGE_RESPONSE_DATA_TYPE } from '@/shared/const/tnos/tsunagu-online-post-message-response-data-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UserScreenTransitionInfo } from '@/shared/classes/user/user-screen-transition';

/**
 * スマホ用につなぐオンラインショップの商品を別タブ表示するときに使うコンポーネント
 */
export default defineComponent({
  name: 'display-on-sp-tsunagu-online-product-html',
  data: () => ({
    srcHtml: '',
    myWidth: window.innerWidth,
    myHeight: window.innerHeight
  }),
  async mounted(): Promise<void> {
    const query = this.$route.query;
    const productId = query.productId as string;

    /**
     * スマホ・タブレットでスクレイピング処理した商品ページを別タブ表示するとき、会員情報が取得できないことがある
     * そのため、ログイン状態を確認して、会員情報を取得し、商品ページを表示する
     */
    const history: UserScreenTransitionInfo[] = this.$store.getters['userScreenTransitionStore/history'];
    if (!this.$store.getters['memberStore/member']) {
      const token = await AuthService.getAuth0AccessToken().catch(() => {
        // await AuthService.isLoggedIn() が true のとき実行するようエラーが出るが無視する
        // 本来ログイン状態の確認には、await AuthService.isLoggedIn() を使うが、
        // Safariで window.open(url, 'blank') により、本ページを表示する関係で、期待通りに動作しない様子
        // そのため、 getAuth0AccessToken() を使っている
      });

      if (token && history.length === 1) {
        await this.$store.dispatch('memberStore/member');
      }
    }

    if (productId) {
      // ex. https://www.tnshop.jp/item/LB1T1B44230613.html)
      const myUrl = `${process.env.VUE_APP_TNOS_BASE_URL}/item/${productId}.html`;
      this.$store.commit('tsunaguOnlineProductHtmlDisplayOnSpStore/recentlyTransitionUrl', myUrl);

      const dto = await TsunaguOnlineProductHtmlDisplayOnSpService.fetchHtml(myUrl);
      this.srcHtml = dto?.formattedHtml ? dto.formattedHtml : '';

      this.$nextTick(() => {
        // フッターを固定表示するためクラス付与
        const footer = document.querySelector('.my-footer');
        if (footer) {
          footer.classList.add('display-on-sp-tsunagu-online-product-html');
        }
        this.myHeight = this.getIframeHeight();
      });

      /**
       * スマホ表示のとき、iframe内の「買い物カゴに入れる」が押下されたとき実行される
       * postMessageにより送信されたデータを処理する
       */
      window.addEventListener('message', this.handleMessageFromIframe);
    }
  },
  methods: {
    /**
     * iframeの高さを算出する
     * 固定表示している header と footer の高さ を除いた領域で iframe が表示されるようにする
     */
    getIframeHeight(): number {
      const headerHeight = document.querySelector('.header-info')?.clientHeight;
      const footerHeight = document.querySelector('.my-footer')?.clientHeight;

      if (headerHeight && footerHeight) {
        return window.innerHeight - (headerHeight + footerHeight);
      } else {
        throw new Error('div.header-infoの高さ または div.my-footerの高さ の取得ができませんでした');
      }
    },
    /**
     * スマホ表示のとき、iframe内の「買い物カゴに入れる」が押下されたとき実行される
     * postMessageにより送信されたデータを処理する
     */
    async handleMessageFromIframe(response: MessageEvent): Promise<void> {
      // 別のメッセージイベントが来る時があり、JSON.parseでエラーになるとき用の例外処理
      try {
        const postMessageResponseData = JSON.parse(response.data) as PostMessageResponseData;

        if (postMessageResponseData.type === POST_MESSAGE_RESPONSE_DATA_TYPE.ADDED_CART_ITEM_INFO) {
          const myUrl = this.$store.getters['tsunaguOnlineProductHtmlDisplayOnSpStore/recentlyTransitionUrl'];
          const ssoUrl = TsunaguOnlineProductUrlService.buildUrlAddToCart(myUrl, JSON.parse(response.data));
          window.location.href = ssoUrl;
        } else if (postMessageResponseData.type === POST_MESSAGE_RESPONSE_DATA_TYPE.URL) {
          const url = postMessageResponseData.url;

          if (url) {
            const queryParameter = TsunaguOnlineProductUrlService.buildQueryParametersDisplayOnSp(url);
            window.location.href = `${process.env.VUE_APP_BASE_URL}/tnos?${queryParameter}`;
          }
        }
      } catch (e) {
        // console.log(response);
        return;
      }
    }
  },
  destroyed() {
    this.$nextTick(() => {
      const footer = document.querySelector('.my-footer');
      if (footer) {
        footer.classList.remove('display-on-sp-tsunagu-online-product-html');
      }
    });
  }
});
</script>
