/**
 * ベリトランスへのリクエストのステータスを管理する
 */
interface VeritransRequestStatus {
  failureCount: number;
  datalist: LastFailedAt;
}

/**
 * 最後に失敗した時間を管理
 */
interface LastFailedAt {
  data: string;
  expire: number;
}

/**
 * ベリトランスへのリクエストに回数制限を設ける(ARN_QA-936)
 *
 * 入力間違いにより3回トークン取得に失敗した場合、その後リクエストを送ることができない
 * 最後にリクエストを送ってから24時間経過後、失敗回数をリセットする
 *
 * 共通処理
 * |                | 1 | 2 | 3 |
 * | has not passed | o | o | x |
 * | has passed     | o | o | o |
 */
export class VeritransRequestStatusService {
  private static COUNT = 'count';
  private static DATALIST = 'datalist';

  public static canSendPostRequest(): boolean {
    const veritransRequestStatus = VeritransRequestStatusService.getItem();

    if (!veritransRequestStatus) {
      return true;
    }

    // 最後に失敗した時刻から24時間経過しているとき
    if (veritransRequestStatus.datalist.expire + 86400 < this.unixTimeNow()) {
      veritransRequestStatus.failureCount = 0;
      veritransRequestStatus.datalist.expire = this.unixTimeNow();
      VeritransRequestStatusService.setItem(veritransRequestStatus);
    } else {
      if (veritransRequestStatus.failureCount >= 3) {
        return false;
      }
    }
    return true;
  }

  public static addFailureCount(): void {
    const veritransRequestStatus = VeritransRequestStatusService.getItem();
    if (veritransRequestStatus) {
      veritransRequestStatus.failureCount = veritransRequestStatus.failureCount + 1;
      veritransRequestStatus.datalist.expire = this.unixTimeNow();

      VeritransRequestStatusService.setItem(veritransRequestStatus);
      return;
    }

    VeritransRequestStatusService.setInitialStatus();
  }

  public static setFailureCount0(): void {
    const veritransRequestStatus = VeritransRequestStatusService.getItem();

    if (veritransRequestStatus) {
      veritransRequestStatus.failureCount = 0;
      VeritransRequestStatusService.setItem(veritransRequestStatus);
    }
  }

  private static getItem(): VeritransRequestStatus | undefined {
    const jsonFailureCount = localStorage.getItem(this.COUNT);
    const jsonDatalist = localStorage.getItem(this.DATALIST);

    if (jsonFailureCount && jsonDatalist) {
      const veritransRequestStatus: VeritransRequestStatus = {
        failureCount: Number(jsonFailureCount),
        datalist: JSON.parse(jsonDatalist),
      };

      return veritransRequestStatus;
    }

    return undefined;
  }

  private static setItem(item: VeritransRequestStatus): void {
    const failureCount = item.failureCount.toString();
    const jsonDatalist = JSON.stringify(item.datalist);

    localStorage.setItem(this.COUNT, failureCount);
    localStorage.setItem(this.DATALIST, jsonDatalist);
  }

  private static setInitialStatus(): void {
    const veritransRequestStatus: VeritransRequestStatus = {
      failureCount: 1,
      datalist: {
        data: 'limit',
        expire: this.unixTimeNow(),
      },
    };

    VeritransRequestStatusService.setItem(veritransRequestStatus);
  }

  private static unixTimeNow(): number {
    // 現在のUNIXTIMEを10桁で返す。
    return Math.floor(Date.now() / 1000);
  }
}
