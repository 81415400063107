import { Module } from 'vuex';
import { RootState } from './index';

/** 解約対象Connectix有無 State */
const hasConnectixSubjectToCancelState: {
  hasConnectix: boolean | null;
} = {
  hasConnectix: null,
};

/** State の型 */
type hasConnectixSubjectToCancelState = typeof hasConnectixSubjectToCancelState;

/** 解約対象Connectix有無 Store */
export const hasConnectixSubjectToCancelStore: Module<hasConnectixSubjectToCancelState, RootState> = {
  namespaced: true,
  state: hasConnectixSubjectToCancelState,
  mutations: {
    hasConnectix: (state, value: boolean) => (state.hasConnectix = value),
  },
  getters: {
    hasConnectix: (state): boolean | null => state.hasConnectix,
  },
};
