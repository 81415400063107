import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { UcomAccountInfoForDelete } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-info-for-delete';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiAccountInfoForDeleteAccessor {
  /**
   * アカウント情報取得
   * @param accountId ucom_アカウントID
   * @param date
   * @return アカウント情報
   */
  public static async getAccountInfo(ucomAccountId: string, date: string): Promise<UcomAccountInfoForDelete> {
    const response = await api
      .get<any>(`/account/UCOM/account-sakujo/${ucomAccountId}`, {
        params: {
          date,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return this.convertAccountInfoForDeleteDto(response.data);
  }

  public static async deleteAccountInfo(ucomAccountId: string): Promise<UcomAccountInfoForDelete> {
    // axios delete() は 第2引数でデータを渡すシグネチャーが存在しない
    const response = await api
      .delete<any>(`/account/UCOM/account`, {
        data: {
          id: ucomAccountId,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return this.convertAccountInfoForDeleteDto(response.data);
  }

  private static async convertAccountInfoForDeleteDto(response: UcomAccountInfoForDelete | undefined): Promise<UcomAccountInfoForDelete> {
    if (!response) {
      throw new Error('アカウント情報無し');
    }
    return new UcomAccountInfoForDelete({
      _accountName: response.accountName, //アカウント名
      _fullName: response.fullName, //氏名
      _mailAddress: response.mailAddress, //メールアドレス
      _accountAuthorization: response.accountAuthorization, //アカウント種別
      _mailAddressType: response.mailAddressType, //メールアドレス種別(削除実施時はなし)
      _isTargetLoginUser: response.isTargetLoginUser,
    });
  }
}
