import { Module } from 'vuex';
import { RootState } from '../index';

import { EMansionEntryInputForm } from '../../pages/e-mansion/entry/classes/entry-input-form';

/** e-mansion  お申し込み State */
const eMansionEntryState: {
  entryInputForm: EMansionEntryInputForm | null;
} = {
  /** e-mansion  お申し込み 入力フォーム */
  entryInputForm: null,
};

/** State の型 */
type EMansionEntryState = typeof eMansionEntryState;

/** e-mansion  お申し込み Store */
export const eMansionEntryStore: Module<EMansionEntryState, RootState> = {
  namespaced: true,
  state: eMansionEntryState,
  actions: {
  },
  mutations: {
    entryInputForm: (state, value: EMansionEntryInputForm) => (state.entryInputForm = value),
  },
  getters: {
    entryInputForm: (state): EMansionEntryInputForm | null => state.entryInputForm,
  }
};
