export class subAccountAddInfo {
  readonly _mailAddress: string | undefined;
  public get mailAddress(): string | undefined {
    return this._mailAddress;
  }
  readonly _mailPassword: string | undefined;
  public get mailPassword(): string | undefined {
    return this._mailPassword;
  }
  readonly _popServer: string | undefined;
  public get popServer(): string | undefined {
    return this._popServer;
  }
  readonly _smtpServer: string | undefined;
  public get smtpServer(): string | undefined {
    return this._smtpServer;
  }
  readonly _imapServer: string | undefined;
  public get imapServer(): string | undefined {
    return this._imapServer;
  }
  readonly _mailSubdomain: string | undefined;
  public get mailSubdomain(): string|undefined{
    return this._mailSubdomain;
  }
  readonly _resultMessage: string[] | undefined;
  public get resultMessage(): string[] | undefined {
    return this._resultMessage;
  }
  constructor(partial: Partial<subAccountAddInfo>) {
    Object.assign(this, partial);
  }
}
