import { UCOM_PAYMENT_STATUS } from '@/shared/const/ucom/ucom-payment-status';

/** UCOMメールボックス容量 入力(ユーザー) */
export class UcomMailboxCapacityUserInfo {
  readonly _isRegisteredPayment!: UCOM_PAYMENT_STATUS; // お支払情報登録状態
  readonly _accountName!: string; // アカウント名
  readonly _mailAddress!: string; // メールアドレス
  readonly _mailBoxCapacity!: number; // メールボックス容量

  constructor(isRegisteredPayment: UCOM_PAYMENT_STATUS, accountName: string, mailAddress: string, mailBoxCapacity: number) {
    this._isRegisteredPayment = isRegisteredPayment;
    this._accountName = accountName;
    this._mailAddress = mailAddress;
    this._mailBoxCapacity = mailBoxCapacity;
  }

  get isRegisteredPayment(): UCOM_PAYMENT_STATUS {
    return this._isRegisteredPayment;
  }
  get accountName(): string {
    return this._accountName;
  }
  get mailAddress(): string {
    return this._mailAddress;
  }
  get mailBoxCapacity(): number {
    return this._mailBoxCapacity;
  }
}
