import { ProductIds } from '@/shared/classes/spf-api/portas-subscription/const';

/** 契約中 契約処理中 の商品 */
export class DisplayContract {
  /** コンテンツプロバイダーID */
  contentProviderId!: number;

  /** 商品ID */
  productsId!: ProductIds;

  /** 商品名 */
  productName?: string;

  /** 商品説明 */
  description?: string;

  /** ダウンロードURL */
  downloadUrl?: string;

  /** ライセンスキー */
  ticket?: string;

  constructor(partial: Partial<DisplayContract>) {
    Object.assign(this, partial);
  }
}
