<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メインメールアドレス</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion/main-mail-address/list">メインメールアドレス</router-link>
        </li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:アカウントID登録・変更-->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID登録・変更" />登録・変更お申し込み受付完了</h2>
        <p class="red">お申し込みを受け付けました。</p>
        <p>お手続き内容をメールにてお送りいたしました。</p>
      </div>
      <table class="table-type1">
        <tr>
          <th class="va-middle">
            <p>新しいメールアドレス</p>
          </th>
          <td>{{ newMailAddressAddDomain }}</td>
        </tr>
      </table>

      <div class="blc">
        <ul class="ul-basic01">
          <!--					<li>お申し込み手続き完了後、すぐにご利用いただけます。</li>
-->
          <li>ご提供状況は「ご利用状況確認」をクリックしてご確認ください。</li>
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」前日までにお申し込みいただくと、鍵のお引渡し日からご利用可能となります。</li>
        </ul>
      </div>

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goList()">ご利用状況確認<i class="material-icons link link-icon">east</i></button>
          <button class="btn btn01 bs mt-sp-1" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>
<style>
@media only screen and (max-width: 767px) {
  button.mt-sp-1 {
    margin: 10px 5px 0 5px;
  }
}
</style>

<script lang="ts">
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    newMailAddressAddDomain: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //Storeからメールアドレスを取得
      this.newMailAddressAddDomain = this.$store.getters['eMansionMainMailAddressStore/newMailAddressAddDomain'];
      if (!this.newMailAddressAddDomain) {
        // 現在のメールアドレスを取得(共通情報より取得)
        const accountInfo: EMansionAccountInfo = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
        this.newMailAddressAddDomain = accountInfo.eMansionMailAddressInfo?.mailAddress ?? '';
      }

      //Storeをクリアする
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', null);
      this.$store.commit('eMansionMainMailAddressStore/newMailAddress', null);
      this.$store.commit('eMansionMainMailAddressStore/newMailAddressAddDomain', null);

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/main-mail-address/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
