import { Module } from 'vuex';
import { RootState } from '../index';

import { EMansionLinkingInput } from '@/shared/classes/auth-manegement/e-mansion-linking-input';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';

/** e-mansion アカウント連携 State */
const eMansionLinkingState: {
  eMansionLinkingInput: EMansionLinkingInput | null;
} = {
  eMansionLinkingInput: null
};

/** State の型 */
type EMansionLinkingState = typeof eMansionLinkingState;

/** e-mansion 共通系 Store */
export const eMansionLinkingStore: Module<EMansionLinkingState, RootState> = {
  namespaced: true,
  state: eMansionLinkingState,
  actions: {
    /**
     * アカウント連携 actions
     * @param context
     * @return EMansionLinkingInput アカウント情報
     */
    async updateLinking(context, eMansionLinkingInput: EMansionLinkingInput): Promise<undefined | AuthManegementSharedErrorResponse> {
      context.commit('updateLinking', eMansionLinkingInput);
      // API コールする
      return await AuthManagementApiService.linkingToEMansion(eMansionLinkingInput);
    }
  },
  mutations: {
    updateLinking: (state, value: EMansionLinkingInput) => (state.eMansionLinkingInput = value)
  },
  getters: {
    updateLinking: (state): EMansionLinkingInput | null => state.eMansionLinkingInput
  }
};
