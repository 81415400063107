import store from '@/store';
import { ApiFrontError } from '../../shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '../../shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '../../shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '../../shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '../../shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '../../shared/classes/external-api/e-mansion/shared-error-response';
import { FiveACustomerQueryDto } from '../../shared/classes/external-api/five-a/customer-dto';
import { FiveACustomer } from '../../shared/classes/external-api/five-a/customer-response';
import { FiveAProperty } from '../../shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '../../shared/classes/external-api/five-a/shared-error-response';
import { UcomCustomerResponse } from '../../shared/classes/external-api/ucom/customer-response';
import { UcomPropertyResponse } from '../../shared/classes/external-api/ucom/property-response';
import { UcomSharedErrorResponse } from '../../shared/classes/external-api/ucom/shared-error-response';
import { Member } from '../../shared/classes/spf-api/member';
import { Property } from '../../shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '../../shared/const/error/error-info';
import { UA_TYPE } from '../../shared/const/service-type';

/**
 * 会員.物件ID に対応する物件について、次の情報を取得してstoreへ保存する
 *
 * 1. 物件基本情報
 * 2. 契約基本情報
 */
export class StoreExternalApiResponse {
  public static async main(): Promise<void> {
    const member: Member | null = store.getters['memberStore/member'];
    const property: Property | null = store.getters['propertyStore/property'];
    if (property?.uaType === UA_TYPE.E_MANSION) {
      if (property.apartmentId) {
        await this.storePropertyApiResponseEmansion(property.apartmentId);
        if (member?.primaryKeyMye) {
          await this.storeCustomerApiResponseEmansion(property.apartmentId, member.primaryKeyMye);
        }
      }
    } else if (property?.uaType === UA_TYPE.UCOM) {
      if (property.buildingId) {
        await this.storePropertyApiResponseUcom(property.buildingId);
      }

      if (member?.primaryKeyUcom) {
        await this.storeCustomerApiResponseUcom(member.primaryKeyUcom);
      }
    } else if (property?.uaType === UA_TYPE.FIVE_A) {
      if (property.apartmentId) {
        await this.storePropertyApiResponseFiveA(property.apartmentId);
        if (member?.primaryKeyMye) {
          await this.storeCustomerApiResponseFiveA(property.apartmentId, member.primaryKeyMye);
        }
      }
    }
  }

  /**
   * e-mansionのとき、物件基本情報取得APIを実行しstoreする
   */
  private static async storePropertyApiResponseEmansion(apartmentId: string): Promise<void> {
    const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await store.dispatch('eMansionCommonStore/property', {
      uaType: UA_TYPE.E_MANSION,
      apartmentId: apartmentId,
    });

    if (eMansionProperty instanceof EMansionSharedErrorResponse) {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
    }
  }

  /**
   * e-mansionのとき、契約基本情報取得APIを実行しstoreする
   */
  private static async storeCustomerApiResponseEmansion(apartmentId: string, primaryKeyMye: string): Promise<void> {
    const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      ua_type: UA_TYPE.E_MANSION,
      apartment_id: apartmentId,
    });

    // e-mansionの契約基本情報をStoreから取得
    const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await store.dispatch('eMansionCommonStore/customer', {
      memberId: primaryKeyMye,
      query: findCustomerByIdQueryDto,
    });

    if (eMansionCustomer instanceof EMansionSharedErrorResponse) {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
    }
  }

  /**
   * ucomのとき、物件基本情報取得APIを実行しstoreする
   */
  private static async storePropertyApiResponseUcom(buildingId: number): Promise<void> {
    const ucomProperty: UcomPropertyResponse | UcomSharedErrorResponse = await store.dispatch('ucomCommonStore/property', buildingId);

    if (ucomProperty instanceof UcomSharedErrorResponse) {
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
    }
  }

  /**
   * ucomのとき、契約基本情報を使う処理
   */
  private static async storeCustomerApiResponseUcom(primaryKeyUcom: string): Promise<void> {
    const ucomCustomer: UcomCustomerResponse | UcomSharedErrorResponse = await store.dispatch('ucomCommonStore/customer', primaryKeyUcom);

    if (ucomCustomer instanceof UcomSharedErrorResponse) {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
    }
  }

  /**
   * Five.Aのとき、Connectix提供物件かどうかを取得する
   */
  private static async storePropertyApiResponseFiveA(apartmentId: string): Promise<void> {
    const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/property', {
      uaType: UA_TYPE.FIVE_A,
      propertyId: apartmentId,
    });

    if (fiveAProperty instanceof FiveASharedErrorResponse) {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
    }
  }

  /**
   * Five.Aのとき、契約基本情報を使う処理
   */
  private static async storeCustomerApiResponseFiveA(apartmentId: string, primaryKeyMye: string): Promise<void> {
    const fiveACustomerQueryDto = new FiveACustomerQueryDto({
      ua_type: UA_TYPE.FIVE_A,
      apartment_id: apartmentId,
    });

    // Five.Aの契約基本情報をStoreから取得
    const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/customer', {
      memberId: primaryKeyMye,
      query: fiveACustomerQueryDto,
    });

    if (fiveACustomer instanceof FiveASharedErrorResponse) {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
    }
  }
}
