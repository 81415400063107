<template>
  <div>
    <!-- クレジットカードトークンが生成されていないとき -->
    <div v-if="!isCreditCardTokenCreated">
      <p class="mb15"><b>ご利用可能なクレジットカードの種類</b></p>
      <img src="../../images/ucom/crecitcard.png" alt="ご利用いただけるカードの種類" class="crecitcard" />
      <p>Visa（ビザ）、Mastercard（マスターカード）、JCB（ジェーシービー）、American Express（アメリカン・エキスプレス）、Diners Club（ダイナースクラブ）</p>
      <h4>クレジットカード情報の入力</h4>
      <p>登録したいクレジットカード情報を入力してください。</p>
      <p>複数回入力エラーを繰り返した場合、一定期間ご入力を受け付けられなくなります。</p>
      <p>お手元にクレジットカードをご用意のうえ、正確にご入力ください。</p>
      <p>
        クレジットカード会社での本人認証のため、ご利用のブラウザのCookieを許可していただき<br />
        トラッキング防止が有効になっている場合には、無効に変更をお願いいたします。<br />
        設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
      </p>
      <table class="table-type2">
        <tr>
          <td><b>カード番号（半角数字）</b><span class="req">必須</span></td>
          <td>
            <p class="mb10">半角数字で1マスに4桁ずつ、左詰めでご入力ください</p>
            <input type="text" v-model.trim="form.number1" @input="v$.form.number1.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number2" @input="v$.form.number2.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number3" @input="v$.form.number3.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number4" @input="v$.form.number4.$touch()" class="text short" pattern="\d{2,4}" />
          </td>
        </tr>
        <tr>
          <td>
            <b>セキュリティコード<br />（半角数字）</b><span class="req">必須</span>
          </td>
          <td>
            <p class="mb10"></p>
            <div class="mt10 security-code">
              <input type="text" class="text short" v-model.trim="form.securityCode" />
              <span class="tooltip">
                ?<span class="description-right">
                  セキュリティコードとはクレジットカード番号とは別にカード裏面署名欄に記載された3桁の番号です。American Expressは表面に4桁で記載されていることもあります。</span
                >
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td><b>カード期限</b><span class="req">必須</span></td>
          <td>
            <p class="mb10">クレジットカードに記載の年月をご入力ください</p>
            <select v-model="form.expiryMonth" class="form-select">
              <option value="">--</option>
              <option v-for="n in 12" :key="n" :value="String(n).padStart(2, '0')">
                {{ String(n).padStart(2, '0') }}
              </option>
            </select>
            月&emsp;
            <select v-model="form.expiryYear" class="form-select">
              <option value="">--</option>
              <option :value="String(currentYear).slice(-2)">{{ String(currentYear).slice(-2) }}</option>
              <option v-for="n in 9" :key="n" :value="String(n + currentYear).slice(-2)">
                {{ String(n + currentYear).slice(-2) }}
              </option>
            </select>
            年（西暦下2桁）
          </td>
        </tr>
        <tr>
          <td>
            <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
          </td>
          <td>
            <div class="mt10">
              <input type="text" class="text card-name" @change="inputEventConvertToUpper" v-model.trim="form.cardholderName" />
            </div>
          </td>
        </tr>
      </table>

      <ErrorMessagesComponent v-if="errorMessages.length > 0" :error-message-title="errorMessageTitle" :error-messages="errorMessages" />
      <div class="btn-area">
        <button class="btn-height btn btn01 bs" v-on:click="fetchCreditCardToken">クレジットカード情報を送信<i class="material-icons link link-icon">east</i></button>
      </div>

      <div class="border-grey bg-grey pd20 mt40">
        <p>
          上記Portasサービスへのお申し込みを行う場合、ご入力いただいたクレジットカード情報が有効に利用できるか、ご確認させていただきますので、情報入力後「クレジットカード情報を送信」のボタンを押してください。
        </p>
        <p>なお、Portas新規会員登録とサービス申し込みが確定するまで、当該入力情報は登録されません。</p>
        <p>クレジットカード情報は、サービス料金決済の目的で利用します。</p>
        <p>Portas会員登録及びサービスお申し込みの完了後、入力情報は会員情報として管理されます。</p>

        <p>ご入力いただいたクレジットカード情報は、当社で保持せず直接「株式会社DGフィナンシャルテクノロジー」へ送信され、管理されます。</p>
        <p>※登録のタイミングによっては、前月分のご請求から変更後のクレジットカードでのお支払いとなることがございます。</p>
        <p>&emsp;予めご了承ください。</p>
        <p>
          ※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。
        </p>
      </div>
    </div>

    <!-- クレジットカードトークン生成に成功した後 -->
    <div v-if="isCreditCardTokenCreated" class="mt20">
      <p class="red"><b>ご入力いただいたクレジットカード情報が株式会社DGフィナンシャルテクノロジーに送信されました。</b></p>
      <p class="red"><b>画面下の「Portas新規会員登録とお申し込みの確定」ボタンをクリックしてください。</b></p>

      <div class="border-grey bg-grey pd20 mt40">
        <p class="red"><b>※「クレジットカード情報を送信」ボタンをクリック後、15分以内にお申し込みを完了させてください。</b></p>
        <p>上記Portasサービスへのお申し込みを行う場合、ご入力いただいたクレジットカード情報が有効に利用できるか、ご確認させていただきますので、</p>
        <p>情報入力後「クレジットカード情報を送信」のボタンを押してください。</p>
        <p>なお、Portas新規会員登録とサービス申し込みが確定するまで、当該入力情報は登録されません。</p>
        <p>クレジットカード情報は、サービス料金決済の目的で利用します。</p>
        <p>Portas会員登録及びサービスお申し込みの完了後、入力情報は会員情報として管理されます。</p>
        <p>ご入力いただいたクレジットカード情報は、当社で保持せず直接「株式会社DGフィナンシャルテクノロジー」へ送信され、管理されます。</p>
        <p>※登録のタイミングによっては、前月分のご請求から変更後のクレジットカードでのお支払いとなることがございます。</p>
        <p>
          ※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。
        </p>
      </div>
    </div>
    <div class="border-grey bg-grey pd20 mt40">
      <p>
        ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-height {
  height: 50px;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

img.crecitcard {
  max-width: 640px;
  width: 100%;
}

button[disabled] {
  pointer-events: none;
}

.tooltip {
  background: #666666;
  margin: 5px;
  font-size: 12px;
  border-radius: 100%;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  left: 155px;
}

.security-code {
  display: flex;
  position: relative;
  align-items: center;
}

.description-right {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translateY(-50%);
  padding: 8px;
  border-radius: 10px;
  background-color: #666;
  font-size: 12px;
  color: #fff;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  transition: 0.5s all;
}

.tooltip:hover .description-right {
  left: 100%;
  visibility: visible;
  opacity: 0.8;
}

.card-name {
  width: 100%;
  max-width: 360px;
}

@media screen and (max-width: 767px) {
  .tooltip {
    left: 85px;
  }
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, numeric, required } from '@vuelidate/validators';
import axios from 'axios';
import { defineComponent } from 'vue';

import { VeritransCreditCardTokenRequest } from '../classes/veritrans-credit-card-token-request';
import { VeritransApiService } from '../services/api/veritrans-api-service';
import { VeritransErrorService } from '../services/veritrans/veritrans-error-service';
import { VeritransRequestStatusService } from '../services/veritrans/veritrans-request-status-service';
import { processYearNumber } from '../util/func-process-date';
import { inputEventConvertToUpper } from '../util/inputEventConvertToUpper';

import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';

/** Veritrans クレジットカード情報入力コンポーネント(カード名義人記入欄付き) */
export default defineComponent({
  name: 'veritrans-credit-card-component-at-platform-entry-confirm',
  components: {
    ErrorMessagesComponent,
  },
  props: {
    /**
     * 数を増やすことで再描画できるようにする
     * 再描画する仕組みを設ける関係で設置している(数を数えるために設置しているわけではない)
     */
    resetCounter: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_PORTAS,
    /** 入力値 */
    form: {
      /** クレジットカード番号1 */
      number1: '',
      /** クレジットカード番号2 */
      number2: '',
      /** クレジットカード番号3 */
      number3: '',
      /** クレジットカード番号4 */
      number4: '',
      /** クレジットカード有効期限月 (ゼロパディング2桁) */
      expiryMonth: '',
      /** クレジットカード有効期限年 (西暦下2桁) */
      expiryYear: '',
      /** クレジットカード名義人 */
      cardholderName: '',
      /** セキュリティコード */
      securityCode: '',
    },
    /** 西暦の入力欄を組み立てるための現在年 */
    currentYear: 0,
    /** fetchCreditCardToken()実行中かどうか */
    isExecutingFetchCreditCardToken: false,
    /** クレジットカード情報送信用トークンが作成されているか */
    isCreditCardTokenCreated: false,
    /** エラーメッセージタイトル */
    errorMessageTitle: 'お支払方法の登録が完了できませんでした。',
    /** エラーメッセージ */
    errorMessages: new Array<string>(),
  }),
  validations: {
    form: {
      number1: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number2: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number3: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number4: {
        required,
        numeric,
        minLength: minLength(2),
        maxLength: maxLength(4),
      },
      expiryMonth: {
        required,
      },
      expiryYear: {
        required,
      },
      cardholderName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      securityCode: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4),
      },
    },
  },
  emits: ['cardTokenForRegister', 'cardTokenFor3dSecureAuthorize'],
  setup: () => ({ v$: useVuelidate() }),
  mounted(): void {
    // computed() では西暦が正しく取得できない・mounted() で処理する必要あり
    this.currentYear = processYearNumber();
  },
  methods: {
    /**カード番号バリデーション */
    validateCardNumberInput(): boolean {
      if (this.v$.form.number1?.maxLength.$invalid || this.v$.form.number1?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number2?.maxLength.$invalid || this.v$.form.number2?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number3?.maxLength.$invalid || this.v$.form.number3?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number4?.maxLength.$invalid || this.v$.form.number4?.minLength.$invalid) {
        return false;
      }
      return true;
    },
    /**
     * /4gtoken にリクエストを送信して、クレジットカードトークンを取得する
     */
    async fetchCreditCardToken(): Promise<void> {
      if (this.isExecutingFetchCreditCardToken) {
        return;
      }

      this.isExecutingFetchCreditCardToken = true;
      this.errorMessages = [];

      // 回数制限に引っかかっていないか確認
      if (!VeritransRequestStatusService.canSendPostRequest()) {
        this.errorMessages.push(
          [
            '入力上限回数に達しましたのでロックされました。しばらくしてから再度ご登録ください。',
            'なお、弊社ではセキュリティ上、ロックを解除することはできませんので、ご了承ください。',
          ].join('<br>')
        );
        this.isExecutingFetchCreditCardToken = false;
        return;
      }

      // 3Dセキュアサービス未契約の場合、VeriTransのトークン作成APIはカード名義人が未指定でもエラーを返さない。
      // このため、カード名義人が空欄の場合はVeriTransへのカード情報送信を行わず、Portasフロント側でエラーを返して回数制限をカウントする。
      if (!this.form.cardholderName) {
        VeritransRequestStatusService.addFailureCount();
        this.errorMessages.push('カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        this.isExecutingFetchCreditCardToken = false;
        return;
      }
      if (!this.validateCardNumberInput()) {
        VeritransRequestStatusService.addFailureCount();
        this.errorMessages.push('カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        this.isExecutingFetchCreditCardToken = false;
        return;
      }
      if (this.form.cardholderName.length < 2 || this.form.cardholderName.length > 45) {
        VeritransRequestStatusService.addFailureCount();
        this.errorMessages.push('カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        this.isExecutingFetchCreditCardToken = false;
        return;
      }

      try {
        // API をコールしてトークンを取得する
        const tokenRequest = new VeritransCreditCardTokenRequest({
          card_number: `${this.form.number1}${this.form.number2}${this.form.number3}${this.form.number4}`,
          card_expire: `${this.form.expiryMonth}/${this.form.expiryYear}`,
          token_api_key: this.apiTokenKey,
          security_code: this.form.securityCode,
          cardholder_name: this.form.cardholderName,
          lang: 'ja',
        });

        // クレジット登録用と３Dセキュア認証用で二つのトークンが必要
        const regist_response = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const auth_response = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const regist_token = regist_response.token;
        const auth_token = auth_response.token;
        const regist_token_expire_date = regist_response.token_expire_date;
        const auth_token_expire_date = auth_response.token_expire_date;
        // 親コンポーネントに 4gtoken のレスポンスを送る
        this.$emit('cardTokenForRegister', regist_token, regist_token_expire_date);
        this.$emit('cardTokenFor3dSecureAuthorize', auth_token, auth_token_expire_date);
        // 失敗回数カウントを0回にする
        VeritransRequestStatusService.setFailureCount0();

        this.isCreditCardTokenCreated = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // エラーレスポンスに応じた、表示するエラーメッセージの場合分け
        if (axios.isAxiosError(error)) {
          const status: number = error.response?.status ? error.response?.status : -1;
          const errorCode: string = error.response?.data.code;

          if (VeritransErrorService.isErrorDueToInputValue(errorCode)) {
            VeritransRequestStatusService.addFailureCount();
            this.errorMessages.push('カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
          } else if (VeritransErrorService.isErrorDueToConfig(errorCode)) {
            const errorCodeInNumericFormat = VeritransErrorService.getErrorCodeInNumericFormat(errorCode);

            this.errorMessages.push(
              [
                '処理が正常に完了できませんでした。しばらく時間をおいてから再度お試しください。',
                '再度お試しいただいても正常に完了しない場合、大変お手数ですがサポートセンターまでエラー番号をお知らせください。',
                '<エラー番号: ' + errorCodeInNumericFormat + '>',
              ].join('</br>')
            );

            // 通信エラーのとき
          } else if (status >= 500) {
            this.errorMessages.push('クレジットカード情報の送信に失敗しました。');
          }
        } else {
          throw error;
        }
      }
      this.isExecutingFetchCreditCardToken = false;
    },
    inputEventConvertToUpper(event: Event) {
      this.form.cardholderName = inputEventConvertToUpper(event);
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computedForm(): any {
      return JSON.parse(JSON.stringify(this.form));
    },
  },
  watch: {
    computedForm: {
      handler: function (newVal, oldVal) {
        if (newVal.number1 && !newVal.number1.match(/^\d{1,4}$/)) {
          this.form.number1 = oldVal.number1;
        }
        if (newVal.number2 && !newVal.number2.match(/^\d{1,4}$/)) {
          this.form.number2 = oldVal.number2;
        }
        if (newVal.number3 && !newVal.number3.match(/^\d{1,4}$/)) {
          this.form.number3 = oldVal.number3;
        }
        if (newVal.number4 && !newVal.number4.match(/^\d{1,4}$/)) {
          this.form.number4 = oldVal.number4;
        }
        if (newVal.cardholderName && !newVal.cardholderName.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.form.cardholderName = oldVal.cardholderName;
        }
        // セキュリティコードは3桁・4桁の半角英数字に制限
        if (newVal.securityCode && !newVal.securityCode.match(/^\d{1,4}$/)) {
          this.form.securityCode = oldVal.securityCode;
        }
      },
      deep: true,
    },
  },
});
</script>
