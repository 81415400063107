<template>
  <div class="my-page-member-edit">
    <LoadingComponent v-if="isMounting || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portas会員情報変更</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li>会員情報変更</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">会員情報変更 入力</li>
        <li>会員情報変更 確認</li>
        <li>会員情報変更 完了</li>
      </ul>

      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages !== ''" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="会員情報変更" />Portas会員情報変更</h2>
        <div class="notice-border">
          <p>2024年8月22日に「Portasにおける個人情報の取扱いについて」を変更いたしました。最新の利用規約については、下記をご確認ください。</p>
          <ul>
            <li><a href="/platform/terms" target="terms-privacy-policy-and-commerce" class="notice">Portas利用規約</a></li>
            <li><a href="/platform/privacy-policy" target="terms-privacy-policy-and-commercey" class="notice">Portasにおける個人情報の取り扱いについて</a></li>
          </ul>
        </div>
        <!-- e-mansion -->
        <template v-if="isPropertyUaTypeEmansion && (memberStatus.status === memberStatusType.PROPERTY_LINKED || memberStatus.status === memberStatusType.IN_CANCEL_APPLICATION)">
          <!-- e-mansion通常 -->
          <div v-if="!isCanceled">
            <!-- Mcloud有 -->
            <div v-if="isLinkedToMcloud">
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「e-mansion」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、e-mansionにご登録のメールアドレスを変更したい場合は「e-mansion会員専用トップページ」よりお手続きください。<br />「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「e-mansion」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、e-mansionにご登録のメールアドレスを変更したい場合は「e-mansion会員専用トップページ」よりお手続きください。<br />「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
            </div>
            <!-- Mcloud無 -->
            <div v-else>
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「e-mansion」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、e-mansionにご登録のメールアドレスを変更したい場合は「e-mansion会員専用トップページ」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「e-mansion」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、e-mansionにご登録のメールアドレスを変更したい場合は「e-mansion会員専用トップページ」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
            </div>
          </div>
          <!-- e-mansion退会済み -->
          <div v-else>
            <!-- Mcloud有 -->
            <div v-if="isLinkedToMcloud">
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
            </div>
            <!-- Mcloud無 -->
            <div v-else>
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">Portasの会員情報を変更します。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。</p>
              </div>
            </div>
          </div>
        </template>
        <!-- ucom -->
        <template v-else-if="isPropertyUaTypeUcom && (memberStatus.status === memberStatusType.PROPERTY_LINKED || memberStatus.status === memberStatusType.IN_CANCEL_APPLICATION)">
          <!-- Mcloud有 -->
          <div v-if="isLinkedToMcloud">
            <!-- Tnshop有 -->
            <div v-if="isLinkedToTnshop">
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「UCOM光 レジデンス」「Mcloud」の会員情報には反映されません。各サービスサイトよりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
            <!-- Tnshop無 -->
            <div v-else>
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「UCOM光 レジデンス」「Mcloud」の会員情報には反映されません。各サービスサイトよりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
          </div>
          <!-- Mcloud無 -->
          <div v-else>
            <!-- Tnshop有 -->
            <div v-if="isLinkedToTnshop">
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「UCOM光 レジデンス」の会員情報には反映されません。「UCOM光 レジデンス 会員専用トップページ」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
            <!-- Tnshop無 -->
            <div v-else>
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「UCOM光 レジデンス」の会員情報には反映されません。「UCOM光 レジデンス 会員専用トップページ」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
          </div>
        </template>
        <!-- FiveA -->
        <template v-else-if="isPropertyUaTypeFiveA && (memberStatus.status === memberStatusType.PROPERTY_LINKED || memberStatus.status === memberStatusType.IN_CANCEL_APPLICATION)">
          <!-- FiveA通常 -->
          <div v-if="!isCanceled">
            <!-- Mcloud有 -->
            <div v-if="isLinkedToMcloud">
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「Five.A」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、Five.Aにご登録のメールアドレスを変更したい場合は「Five.A会員専用トップページ」よりお手続きください。<br />「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「Five.A」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、Five.Aにご登録のメールアドレスを変更したい場合は「Five.A会員専用トップページ」よりお手続きください。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
            </div>
            <!-- Mcloud無 -->
            <div v-else>
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「Five.A」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、Five.Aにご登録のメールアドレスを変更したい場合は「Five.A会員専用トップページ」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。お名前・フリガナを変更された場合は「Five.A」の会員情報も変更されます。メールアドレスはPortasからのご連絡先メールアドレスとなりますので、Five.Aにご登録のメールアドレスを変更したい場合は「Five.A会員専用トップページ」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
            </div>
          </div>
          <!-- FiveA退会済み -->
          <div v-else>
            <!-- Mcloud有 -->
            <div v-if="isLinkedToMcloud">
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
            </div>
            <!-- Mcloud無 -->
            <div v-else>
              <!-- Tnshop有 -->
              <div v-if="isLinkedToTnshop">
                <p class="att red mt10 delete-indent">
                  Portasの会員情報を変更します。「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
                </p>
              </div>
              <!-- Tnshop無 -->
              <div v-else>
                <p class="att red mt10 delete-indent">Portasの会員情報を変更します。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。</p>
              </div>
            </div>
          </div>
        </template>
        <!-- GameWith光 -->
        <template v-else-if="isLinkedToGameWithH">
          <div>
            <!-- Tnshop有 -->
            <div v-if="isLinkedToTnshop">
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「つなぐネットオンラインショップ」の会員情報およびGameWithへご登録いただいたお名前とメールアドレスは変更されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
            <!-- Tnshop無 -->
            <div v-else>
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。GameWithへご登録いただいたお名前とメールアドレスは変更されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
          </div>
        </template>
        <!-- isp紐づけ必要・なし -->
        <template
          v-else-if="
            memberStatus.status === memberStatusType.ACCOUNT_REGISTERED ||
            memberStatus.status === memberStatusType.ACCOUNT_REGISTERED_NO_PROPERTY ||
            memberStatus.status === memberStatusType.IN_CANCEL_APPLICATION
          "
        >
          <!-- Mcloud有 -->
          <div v-if="isLinkedToMcloud">
            <!-- Tnshop有 -->
            <div v-if="isLinkedToTnshop">
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
            <!-- Tnshop無 -->
            <div v-else>
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「Mcloud」の会員情報には反映されません。「Mcloud」よりお手続きください。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
          </div>
          <!-- Mcloud無 -->
          <div v-else>
            <!-- Tnshop有 -->
            <div v-if="isLinkedToTnshop">
              <p class="att red mt10 delete-indent">
                Portasの会員情報を変更します。「つなぐネットオンラインショップ」の会員情報には反映されません。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。
              </p>
            </div>
            <!-- Tnshop無 -->
            <div v-else>
              <p class="att red mt10 delete-indent">Portasの会員情報を変更します。<br />Portasサービスお申し込みをご希望の場合はPortasサービス契約住所の登録が必要です。</p>
            </div>
          </div>
        </template>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">お名前（全角）<span class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="姓" maxlength="28" v-model="form.firstName" />&emsp;<input
                  type="text"
                  class="text middle sp-mt10"
                  placeholder="名"
                  maxlength="28"
                  v-model="form.givenName"
                />
              </td>
            </tr>
            <tr>
              <th class="va-middle">フリガナ（全角）<span class="req">必須</span></th>
              <td>
                <input type="text" class="text middle" placeholder="セイ" maxlength="28" v-model="form.firstNameKatakana" />&emsp;<input
                  type="text"
                  class="text middle sp-mt10"
                  placeholder="メイ"
                  maxlength="28"
                  v-model="form.givenNameKatakana"
                />
              </td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス（半角英数）<span class="req">必須</span></th>
              <td>
                <input
                  type="text"
                  class="text middle"
                  placeholder="tsunagu@tsunagunet.com"
                  pattern="^([a-zA-Z0-9])+([a-zA-Z0-9\._\-])*@[0-9A-Za-z]+([\-\.][0-9A-Za-z]+)*\.[a-zA-Z]{2,}$"
                  v-model="form.emailAddress"
                />
              </td>
            </tr>
            <tr v-if="member && member.phoneNumber">
              <th class="va-middle">電話番号（半角数字）</th>
              <td>
                <input type="text" class="text short" placeholder="03" v-model="form.phoneNumber1" />　-　<input
                  type="text"
                  class="text short"
                  placeholder="1234"
                  v-model="form.phoneNumber2"
                />　-　<input type="text" class="text short" placeholder="5678" v-model="form.phoneNumber3" />
                <p class="att grey mt10">※電話番号を変更してもPortas（ポルタス）ログイン用のSMS電話番号は変更できません。</p>
              </td>
            </tr>
            <tr v-if="member && member.birthdate">
              <th>生年月日（変更不可）<span class="req">必須</span></th>
              <td>
                {{ form.birthYear }}年{{ form.birthMonth }}月{{ form.birthDay }}日
                <p class="att red mt10">※生年月日は一度登録すると後から変更はできません。</p>
              </td>
            </tr>
            <tr v-else>
              <th>生年月日（半角数字）<span class="req">必須</span></th>
              <td>
                <input type="text" class="text short" placeholder="西暦" maxlength="4" v-model="form.birthYear" />
                年&emsp;
                <select name="month" class="form-select" v-model="form.birthMonth">
                  <option v-for="n in 12" :key="n" v-bind:value="n">{{ n }}</option>
                </select>
                月&emsp;
                <select name="year" class="form-select" v-model="form.birthDay">
                  <option v-for="n in 31" :key="n" v-bind:value="n">{{ n }}</option>
                </select>
                日
                <p class="att red mt10">※生年月日は一度登録すると後から変更はできません。</p>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- 物件情報登録済みの場合 -->
        <template v-if="property && property.id && !isLinkedToGameWithH">
          <table class="table-type2 property-table">
            <tbody>
              <tr class="billing-address-tr">
                <th>お住まいの建物</th>
                <td>
                  <p class="font18px mb10">
                    <b>{{ property.dispApartmentName }}</b>
                  </p>
                  <p>〒{{ property.zipcode }} {{ property.address1 }}</p>
                  <p class="att red mt10">※お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。</p>
                  <p class="att red mt10">※お住まいの建物は登録を変更することができません。</p>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <!-- 物件情報が登録されていない場合 -->
        <template v-else>
          <div v-if="!isLinkedToGameWithH">
            <ResidenceRegistrationController ref="residenceRegistrationController" :handle-is-after-register="changeIsAfterRegister" @clickUpdateRadios="updateRadios" />
          </div>
        </template>

        <!-- Portasサービス契約住所を登録済みの場合 -->
        <template v-if="member && member.zipcode && member.address">
          <table class="table-type2">
            <tbody>
              <tr>
                <th>Portasサービス契約住所</th>
                <td>
                  <p>〒{{ form.zipcode1 }}-{{ form.zipcode2 }}</p>
                  <p>{{ form.address }}</p>
                  <p>{{ form.apartmentBlockName }}</p>
                  <p>{{ form.roomNumber }}</p>
                  <p class="att red mt10"></p>
                  <div class="red">
                    <p class="att">
                      ※登録した住所は、Portasマイページでの修正ができません。修正をご希望の場合は、お手数ですが、<a
                        href="https://support.ucom.ne.jp/contact/portas/"
                        target="_blank"
                        class="toRed-line"
                        >Portasお問い合わせフォーム</a
                      >からご連絡ください。
                    </p>
                    <p class="att">
                      ※ご契約サービスに関するご案内やご請求時の不備等においてメールでご連絡が行えない場合、書面を発送する場合がございますのであらかじめご了承ください。
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <!-- Portasサービス契約住所 -->
        <template v-else>
          <table class="table-type2">
            <tbody>
              <tr class="billing-address-tr">
                <th class="va-middle">Portasサービス契約住所</th>
                <td class="billing-address-td"></td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">郵便番号（半角数字）</th>
                <td class="billing-address-td">
                  <input type="text" class="text short" placeholder="000" maxlength="3" v-model="form.zipcode1" />
                  <span> - </span>
                  <input type="text" class="text short" placeholder="0000" maxlength="4" v-model="form.zipcode2" />
                  <button @click="searchAddressByZipcode()" class="apartment-search-button">住所検索</button>
                </td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">住所（全角）</th>
                <td class="billing-address-td">
                  <input type="text" class="text large" placeholder="都道府県　市区町村　番地" maxlength="30" v-model="form.address" />
                </td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">建物名（全角）</th>
                <td class="billing-address-td">
                  <input type="text" class="text large" placeholder="マンション名・ビル名" maxlength="30" v-model="form.apartmentBlockName" />
                </td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">棟・部屋番号（全角）</th>
                <td class="billing-address-td">
                  <input type="text" class="text middle" placeholder="棟・部屋番号" maxlength="29" v-model="form.roomNumber" />
                </td>
              </tr>
              <tr class="billing-address-tr"></tr>
              <th></th>
              <td class="billing-address-td">
                <div class="red">
                  <p class="att">※Portasサービスお申し込みをご希望の場合、郵便番号・住所は必須入力項目です。</p>
                  <p class="att">
                    ※登録した住所は、Portasマイページでの修正ができません。修正をご希望の場合は、お手数ですが、<a
                      href="https://support.ucom.ne.jp/contact/portas/"
                      target="_blank"
                      class="toRed-line"
                      >Portasお問い合わせフォーム</a
                    >からご連絡ください。
                  </p>
                  <p class="att">
                    ※ご契約サービスに関するご案内やご請求時の不備等においてメールでご連絡が行えない場合、書面を発送する場合がございますのであらかじめご了承ください。
                  </p>
                </div>
              </td>
            </tbody>
          </table>
        </template>
      </div>

      <div class="blc">
        <p class="form-btn-txt">利用規約、変更内容をご確認の上、よろしければ「次へ」ボタンを押してください。</p>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()">戻る<i class="material-icons link link-icon">west</i></button>

          <!-- 既に会員に物件IDが紐づいている場合 -->
          <template v-if="existPropertyIdTiedToMember">
            <button class="btn btn01 bs" @click="onNextWithRegisteredProperty()">次へ<i class="material-icons link link-icon">east</i></button>
          </template>

          <!-- 「該当の建物にお住まいでない方」 にチェックがついている場合 -->
          <template v-else-if="isAfterRegister">
            <button class="btn btn01 bs" @click="onNextWithoutProperty()">次へ<i class="material-icons link link-icon">east</i></button>
          </template>

          <!-- 「お住まいの建物を登録する」 にチェックがついている場合 -->
          <template v-else-if="!isAfterRegister">
            <button class="btn btn01 bs" @click="onNext()">次へ<i class="material-icons link link-icon">east</i></button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.search-button {
  position: relative;
  top: 2px;
  left: 2px;
}

.apartment-search-button {
  appearance: none;
  border: 0;
  border-radius: 2px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #cf1225;
  padding: 10px 25px;
  line-height: 14px;
  font-size: 14px;
  margin: 0 5px;
  top: 2px;
  box-sizing: border-box;
  position: relative;
}

.search-error-text {
  background-color: #ebecf177;
  padding: 10px;
  width: 580px;
}

.select-property {
  display: flex;
}

.property-name {
  padding-left: 5px;
}

.property-info {
  padding-left: 18px;
}

.radio {
  position: relative;
  top: 5px;
}

.service-cards {
  padding-left: 18px;
  color: white;
}

.e-mansion {
  background-color: yellowgreen;
  padding: 2px 14px;
  border-radius: 5px;
}

.mcloud {
  background-color: green;
  padding: 2px 25px;
  border-radius: 5px;
}

.mcloudArea {
  margin-left: 10px;
}

.error-input {
  background-color: #ed7d89;
}

.provider {
  margin-top: 5px;
}

.none:last-child {
  display: none;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

.delete-indent {
  text-indent: 0em;
}

.notice {
  color: #cf1225;
  text-decoration: underline;
}

.billing-address-td {
  vertical-align: middle;
  padding: 0px;
}

.billing-address-tr {
  border-bottom: none;
}

table.property-table {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: none;
}

.notice-border {
  border-bottom: 1px solid #d3d3d3;
}

.toRed-line {
  color: #cf1225;
  text-decoration: underline;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, numeric, required } from '@vuelidate/validators';
import parsePhoneNumber from 'libphonenumber-js';
import { defineComponent } from 'vue';

import { FiveACustomerQueryDto } from '../../../shared/classes/external-api/five-a/customer-dto';
import { PlatformEditInputForm } from '../../../shared/classes/platform/edit-input-form';
import { Property } from '../../../shared/classes/spf-api/property';

import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ResidenceRegistrationController from '@/shared/components/platform/residence-registration-controller.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { FORMAT_DATE } from '@/shared/const/format';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { UA_TYPE, UaTypeValue } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convertKanaAndAscii } from '@/shared/util/change-to-full-width-characters';
import { isValidMonthAndDatePairs } from '@/shared/util/func-is-valid-month-and-date-pairs';
import { processDate } from '@/shared/util/func-process-date';
import { isPlatformDependentCharacters } from '@/shared/util/input-validator';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { emailAddressIllegalChar, emailAddressIllegalCharMark, emailAddressMaxLength, fullKanaAndBlank, fullWidth, fullWidthNoNum, hasNoBlank } from '@/shared/util/validators';
import { InputPropertyForm } from '@/store/platform/platform-edit-store';

/** dataオブジェクトの型  */
type DataType = {
  form: PlatformEditInputForm; // 会員情報変更フォーム
  property: Property | null;
  displayProperty: string; // 物件の表示用文字列
  searchKey: string; // 名称検索フォーム
  isAfterRegister: boolean; //後で登録するか
  isSearchedProperties: boolean; //検索したか
  isPerfectMatch: boolean; //完全一致検索か
  errorMessages: string[];
  errorMessageTitle: string;
  eMansionName: string;
  isSubmitting: boolean; //ボタン押下対応
  isMounting: boolean; // vueのmounted実行中
  isPropertyUaTypeEmansion: boolean;
  isPropertyUaTypeUcom: boolean;
  isPropertyUaTypeFiveA: boolean;
  member: Member | null;
  memberStatus: MemberStatus | null;
  memberStatusType: typeof MEMBER_STATUS;
  existPropertyIdTiedToMember: boolean;
  isLinkedToMcloud: boolean; // Mcloudと連携しているかどうか
  isLinkedToTnshop: boolean; // Tnshopと連携しているかどうか
  isLinkedToGameWithH: boolean; //GameWith光と連携しているか。
  isCanceled: boolean; //ISP退会済み（退会日（quit_date）<現在日時）か
  isSearchAddressByZipcodeExecuting: boolean; //郵便番号による住所検索実行中かどうか
};

/** MemberEdit コンポーネント */
export default defineComponent({
  name: 'my-page-member-edit',
  components: {
    ErrorMessagesComponent,
    ResidenceRegistrationController,
    LoadingComponent,
  },
  data(): DataType {
    return {
      form: {
        firstName: '',
        givenName: '',
        firstNameKatakana: '',
        givenNameKatakana: '',
        emailAddress: '',
        phoneNumber1: '',
        phoneNumber2: '',
        phoneNumber3: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        zipcode1: '',
        zipcode2: '',
        address: '',
        apartmentBlockName: '',
        roomNumber: '',
      },
      property: null,
      displayProperty: '',
      searchKey: '',
      isAfterRegister: true,
      isSearchedProperties: false,
      isPerfectMatch: false,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      eMansionName: '',
      isSubmitting: false,
      isMounting: true,
      isPropertyUaTypeEmansion: false,
      isPropertyUaTypeUcom: false,
      isPropertyUaTypeFiveA: false,
      member: null,
      memberStatus: {} as MemberStatus,
      memberStatusType: MEMBER_STATUS,
      existPropertyIdTiedToMember: false,
      isLinkedToMcloud: false,
      isLinkedToTnshop: false,
      isLinkedToGameWithH: false,
      isCanceled: false,
      isSearchAddressByZipcodeExecuting: false,
    };
  },
  validations: {
    form: {
      // 姓 + '空白' + 名 が 30文字以内
      firstName: { required, fullWidthNoNum, maxLength: maxLength(28), hasNoBlank },
      givenName: { required, fullWidthNoNum, maxLength: maxLength(28), hasNoBlank },
      // 姓 カタカナ + '空白' + 名 カタカナ が 30文字以内
      firstNameKatakana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      givenNameKatakana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      emailAddress: { required, emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark },
      phoneNumber1: { required, numeric },
      phoneNumber2: { required, numeric },
      phoneNumber3: { required, numeric },
      birthYear: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
      birthMonth: { required },
      birthDay: { required },
      address: { maxLength: maxLength(30), fullWidth },
      apartmentBlockName: { maxLength: maxLength(30), fullWidth },
      roomNumber: { maxLength: maxLength(29), fullWidth },
    },
    inputBuildingData: {
      roomNumber: {
        required,
        numeric,
        maxLength: maxLength(8),
      },
    },
    selectedProperty: { required },
  },
  setup: () => ({ v$: useVuelidate() }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      window.scrollTo(0, 0);

      /** ポルタス会員情報を初期値として表示する */
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (member) {
        this.form.firstName = member.firstName;
        this.form.givenName = member.givenName;
        this.form.firstNameKatakana = member.firstNameKatakana;
        this.form.givenNameKatakana = member.givenNameKatakana;
        this.form.emailAddress = member.emailAddress;
        if (member.phoneNumber) {
          this.form.phoneNumber1 = member.phoneNumber.split('-')[0];
          this.form.phoneNumber2 = member.phoneNumber.split('-')[1];
          this.form.phoneNumber3 = member.phoneNumber.split('-')[2];
        }
        if (member.birthdate) {
          if (member.birthdate === '0000-00-00') {
            member.birthdate = null;
          } else {
            this.form.birthYear = member.birthdate.split('-')[0];
            this.form.birthMonth = member.birthdate.split('-')[1];
            this.form.birthDay = member.birthdate.split('-')[2];
          }
        }
        if (member.address) {
          this.form.address = member.address;
        }
        if (member.apartmentBlockName != undefined && member.apartmentBlockName != null) {
          this.form.apartmentBlockName = member.apartmentBlockName;
        }
        if (member.roomNumber != null && member.roomNumber != undefined) {
          this.form.roomNumber = member.roomNumber;
        }
        if (member.zipcode) {
          this.form.zipcode1 = member.zipcode.slice(0, 3);
          this.form.zipcode2 = member.zipcode.slice(4, 8);
        }

        this.member = member;

        /** 会員情報に紐づく物件があれば初期値として表示する */
        if (member.propertyId) {
          this.property = this.$store.getters['propertyStore/property'];
          this.existPropertyIdTiedToMember = true;

          //紐づくISPがe-mansionの場合、ISP退会済みか確認する
          if (this.property?.uaType === UA_TYPE.E_MANSION) {
            if (member.primaryKeyMye) {
              this.isPropertyUaTypeEmansion = true;
            }
            if (await this.isCanceledEmansion()) {
              this.isCanceled = true;
            }
          } else if (this.property?.uaType === UA_TYPE.UCOM) {
            if (member.primaryKeyUcom) {
              this.isPropertyUaTypeUcom = true;
            }
          } else if (this.property?.uaType === UA_TYPE.FIVE_A) {
            //紐づくISPがFive.Aの場合、ISP退会済みか確認する
            if (member.primaryKeyMye) {
              this.isPropertyUaTypeFiveA = true;
            }
            if (await this.isCanceledFiveA()) {
              this.isCanceled = true;
            }
          }

          if (!this.property) {
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
          }
        }
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // Mcloud連携の判定
      if (this.member?.primaryKeyMcloud) {
        this.isLinkedToMcloud = true;
      }
      // Tnshop連携の判定
      if (this.member?.primaryKeyTnshop) {
        this.isLinkedToTnshop = true;
      }

      // GameWith光連携の判定
      if (this.member?.primaryKeyGW) {
        this.isLinkedToGameWithH = true;
      }

      // 確認画面から戻った際にstoreに会員情報が残っていれば表示する
      // vue-routerのbeforeEnterにて、ストアの値保持する/しないの処理
      const editForm = this.$store.getters['platformEditStore/editInputForm'];
      if (editForm) {
        this.form = editForm;
      }
      const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (inputPropertyForm) {
        this.isAfterRegister = inputPropertyForm.isAfterRegister;
      }

      this.memberStatus = this.$store.getters['memberStore/memberStatus'];

      this.isMounting = false;
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }
  },
  methods: {
    async searchAddressByZipcode() {
      if (this.isSearchAddressByZipcodeExecuting) {
        return;
      }
      this.isSearchAddressByZipcodeExecuting = true;

      const zipCode = `${this.form.zipcode1}-${this.form.zipcode2}`;
      this.errorMessages = [];

      const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;

      // 形式チェック
      if (!ZIP_CODE_REGEX.test(zipCode)) {
        this.errorMessages.push('郵便番号が正しくありません。');
      }

      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isSearchAddressByZipcodeExecuting = false;
        return;
      }

      try {
        const resultList = await SpfApiService.searchAddresses(zipCode);
        if (resultList.length > 0) {
          const result = resultList[0];
          this.form.address = `${result.address1}${result.address2}${result.address3}`;
        } else {
          this.errorMessages.push('郵便番号が正しくありません。');
          window.scrollTo(0, 0);
        }
      } catch (e) {
        // エラーのときは何もしない
      }
      this.isSearchAddressByZipcodeExecuting = false;
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    /** 既に会員に物件IDが紐づいている場合 */
    async onNextWithRegisteredProperty(): Promise<void> {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      try {
        this.validateForm();

        if (this.errorMessages.length !== 0) {
          this.showErrorMessage();
          return;
        }

        this.$store.commit('platformEditStore/editInputForm', this.form);
        this.$store.commit('platformEditStore/property', this.property);

        if (this.form.address != undefined) {
          this.form.address = convertKanaAndAscii(this.form.address);
          if (this.v$.form.address?.fullWidth.$invalid) {
            this.errorMessages.push('住所は全角30文字以内で入力してください。');
          }
        }
        if (this.form.apartmentBlockName != undefined) {
          this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
          if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
            this.errorMessages.push('建物名は全角30文字以内で入力してください。');
          }
        }
        if (this.form.roomNumber != undefined) {
          this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
          if (this.v$.form.roomNumber?.fullWidth.$invalid) {
            this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
          }
        }

        if (this.errorMessages.length !== 0) {
          this.showErrorMessage();
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/my-page/member-confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (e) {
        throw e;
      }
    },
    /**「該当の建物にお住まいでない方」 にチェックがついている場合 */
    async onNextWithoutProperty(): Promise<void> {
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中とする
      this.isSubmitting = true;

      try {
        this.validateForm();

        if (this.errorMessages.length !== 0) {
          this.showErrorMessage();
          return;
        }

        this.$store.commit('platformEditStore/editInputForm', this.form);
        this.$store.commit('platformEditStore/inputPropertyForm', null);
        this.$store.commit('platformEditStore/myPageProperty', null);
        // GameWith光の会員の場合は下記のメソッドをスルーする。
        if (!this.isLinkedToGameWithH) {
          this.setStoreOfIsAfterRegister(true);
        }

        if (this.form.address != undefined) {
          this.form.address = convertKanaAndAscii(this.form.address);
          if (this.v$.form.address?.fullWidth.$invalid) {
            this.errorMessages.push('住所は全角30文字以内で入力してください。');
          }
        }
        if (this.form.apartmentBlockName != undefined) {
          this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
          if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
            this.errorMessages.push('建物名は全角30文字以内で入力してください。');
          }
        }
        if (this.form.roomNumber != undefined) {
          this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
          if (this.v$.form.roomNumber?.fullWidth.$invalid) {
            this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
          }
        }

        if (this.errorMessages.length !== 0) {
          this.showErrorMessage();
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/my-page/member-confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (e) {
        throw e;
      }
    },
    /**
     * store に 該当の建物にお住まいでない方 の状態を保存する
     */
    setStoreOfIsAfterRegister(value: boolean): void {
      this.$nextTick(() => {
        const residenceRegistrationController = this.$refs.residenceRegistrationController as InstanceType<typeof ResidenceRegistrationController>;
        residenceRegistrationController.updateStoreOfIsAfterResiter(value);
      });
    },
    /**「お住まいの建物を登録する」 にチェックがついている場合 */
    async onNext(): Promise<void> {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      try {
        this.validateForm();

        const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];

        if (!inputPropertyForm?.selectedProperty) {
          this.errorMessages.push('お住まいを選択してください。');
        }

        if (this.errorMessages.length !== 0) {
          this.showErrorMessage();
          return;
        }

        if (this.form.address != undefined) {
          this.form.address = convertKanaAndAscii(this.form.address);
          if (this.v$.form.address?.fullWidth.$invalid) {
            this.errorMessages.push('住所は全角30文字以内で入力してください。');
          }
        }
        if (this.form.apartmentBlockName != undefined) {
          this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
          if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
            this.errorMessages.push('建物名は全角30文字以内で入力してください。');
          }
        }
        if (this.form.roomNumber != undefined) {
          this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
          if (this.v$.form.roomNumber?.fullWidth.$invalid) {
            this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
          }
        }

        if (this.errorMessages.length !== 0) {
          this.showErrorMessage();
          return;
        }
        this.$store.commit('platformEditStore/editInputForm', this.form);

        if (inputPropertyForm?.selectedProperty) {
          this.$store.commit('platformEditStore/myPageProperty', inputPropertyForm.selectedProperty);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/my-page/member-confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (e) {
        throw e;
      }
    },
    async onBack(): Promise<void> {
      this.isMounting = true;

      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      /** 遷移元パスをストアから呼び出す */
      const path = this.$store.getters['platformEditStore/beforePath'];
      if (
        path === '/ucom/entry/input' ||
        path === '/e-mansion/entry/input' ||
        path === '/e-mansion/entry/confirm' ||
        path === '/5a/entry/input' ||
        path === '/5a/entry/confirm' ||
        path === '/platform/contacts'
      ) {
        await this.$router
          .push(path)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } else {
        await this.$router
          .push('/platform/my-page')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    changeIsAfterRegister(value: boolean) {
      this.isAfterRegister = value;
    },
    updateRadios(value: boolean) {
      const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (
        inputPropertyForm?.selectedProperty != null &&
        inputPropertyForm?.selectedProperty.apartmentAddress != undefined &&
        member != null &&
        (!member.zipcode || !member.address)
      ) {
        if (inputPropertyForm?.selectedProperty.zipcode && inputPropertyForm?.selectedProperty.zipcode.includes('-')) {
          const zipcodeSplitByHyphen = inputPropertyForm?.selectedProperty.zipcode.split('-');
          this.form.zipcode1 = zipcodeSplitByHyphen[0];
          this.form.zipcode2 = zipcodeSplitByHyphen[1];
        } else {
          this.form.zipcode1 = '';
          this.form.zipcode2 = '';
        }
        this.form.address = inputPropertyForm?.selectedProperty.apartmentAddress;
        this.form.apartmentBlockName = inputPropertyForm?.selectedProperty.apartmentName;
      }
    },
    validateForm() {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // 氏名欄チェック
      if (this.v$.form.firstName?.required.$invalid || this.v$.form.givenName?.required.$invalid) {
        this.errorMessages.push(`お名前を入力してください。`);
      } else if (isPlatformDependentCharacters(this.form.firstName!) || isPlatformDependentCharacters(this.form.givenName!)) {
        this.errorMessages.push('恐れ入りますが、お名前に機種依存文字はご登録いただけません。');
      } else if (this.v$.form.firstName?.fullWidthNoNum.$invalid || this.v$.form.givenName?.fullWidthNoNum.$invalid) {
        this.errorMessages.push('お名前の形式が正しくありません。');
      } else if (this.v$.form.firstName?.hasNoBlank.$invalid || this.v$.form.givenName?.hasNoBlank.$invalid) {
        this.errorMessages.push('お名前に空白を含めないようにしてください。');
      }
      if (this.isOver31Characters()) {
        this.errorMessages.push('お名前は姓と名合わせて全角29文字以内で入力してください。');
      }
      // フリガナ欄チェック
      if (this.v$.form.firstNameKatakana?.required.$invalid || this.v$.form.givenNameKatakana?.required.$invalid) {
        this.errorMessages.push(`フリガナを入力してください。`);
      } else if (this.v$.form.firstNameKatakana?.fullKanaAndBlank.$invalid || this.v$.form.givenNameKatakana?.fullKanaAndBlank.$invalid) {
        // フリガナが全角カタカナで入力されていない時
        this.errorMessages.push(`フリガナの形式が正しくありません。`);
      }
      if (this.isOver31CharactersKatakana()) {
        this.errorMessages.push('フリガナはセイとメイ合わせて全角29文字以内で入力してください。');
      }
      // メールアドレスチェック
      if (this.v$.form.emailAddress?.required.$invalid) {
        this.errorMessages.push(`メールアドレスを入力してください。`);
      } else if (this.v$.form.emailAddress?.emailAddressMaxLength.$invalid) {
        this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
      } else if (this.v$.form.emailAddress?.emailAddressIllegalChar.$invalid) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      } else if (this.v$.form.emailAddress?.emailAddressIllegalCharMark.$invalid) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      }
      // // 郵便番号
      const myformCode = `${this.form.zipcode1}-${this.form.zipcode2}`;
      const ZIP_CODE_REGEX = /^(|[0-9]{3}-[0-9]{4})$/;
      // // 形式チェック
      if (this.form.zipcode1 || this.form.zipcode2) {
        if (!ZIP_CODE_REGEX.test(myformCode) || this.form.zipcode1 === '' || this.form.zipcode2 === '') {
          this.errorMessages.push('郵便番号が正しくありません。');
        }
      }
      // 住所
      if (this.v$.form.address?.maxLength.$invalid) {
        this.errorMessages.push('住所は全角30文字以内で入力してください。');
      }
      // 建物名
      if (this.v$.form.apartmentBlockName?.maxLength.$invalid) {
        this.errorMessages.push('建物名は全角30文字以内で入力してください。');
      }
      // 棟・部屋番号
      if (this.v$.form.roomNumber?.maxLength.$invalid) {
        this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
      }
      // 電話番号欄チェック
      if (this.form.phoneNumber1 || this.form.phoneNumber2 || this.form.phoneNumber3) {
        if (this.v$.form.phoneNumber1?.required.$invalid || this.v$.form.phoneNumber2?.required.$invalid || this.v$.form.phoneNumber3?.required.$invalid) {
          this.errorMessages.push(`電話番号を入力してください。`);
        } else if (this.v$.form.phoneNumber1?.numeric.$invalid || this.v$.form.phoneNumber2?.numeric.$invalid || this.v$.form.phoneNumber3?.numeric.$invalid) {
          this.errorMessages.push(`電話番号の形式が正しくありません。`);
        } else {
          const phoneNumber = `${this.form.phoneNumber1}-${this.form.phoneNumber2}-${this.form.phoneNumber3}`;

          try {
            if (!parsePhoneNumber(phoneNumber, 'JP')?.isValid()) {
              this.errorMessages.push('電話番号の形式が正しくありません。');
            }
          } catch (e) {
            this.errorMessages.push('電話番号の形式が正しくありません。');
          }
        }
      }

      const checkedBirthDate = new Date('01/01/1753');
      let year = Number(this.form.birthYear!);
      let month = Number(this.form.birthMonth!);
      let day = Number(this.form.birthDay!);

      let getUserBirthday = new Date(Date.UTC(year, month - 1, day));

      if (this.v$.form.birthYear?.required.$invalid || this.v$.form.birthMonth?.required.$invalid || this.v$.form.birthDay?.required.$invalid) {
        this.errorMessages.push('生年月日を入力してください。');
      } else if (this.v$.form.birthYear?.minLength.$invalid || this.v$.form.birthYear?.maxLength.$invalid || this.v$.form.birthYear?.numeric.$invalid) {
        this.errorMessages.push('生年月日の形式が正しくありません。');
      } else if (!isValidMonthAndDatePairs(Number(this.form.birthYear), Number(this.form.birthMonth), Number(this.form.birthDay))) {
        this.errorMessages.push('生年月日の形式が正しくありません。');
      } //UCOM側のバリデーション仕様(1753年1月1日以降を許容する) に合わせる。
      else if (checkedBirthDate > getUserBirthday) {
        this.errorMessages.push('生年月日に正しい情報を入力してください。');
      } else {
        const birthDate = new Date(this.form.birthYear + '-' + this.form.birthMonth + '-' + this.form.birthDay);
        if (birthDate > new Date()) {
          this.errorMessages.push('生年月日の形式が正しくありません。');
        }
      }
    },

    isOver31Characters(): boolean {
      const array = [this.form.firstName, '　', this.form.givenName];
      return array.join('').length >= 31;
    },
    isOver31CharactersKatakana(): boolean {
      const array = [this.form.firstNameKatakana, '　', this.form.givenNameKatakana];
      return array.join('').length >= 31;
    },
    /**
     * 退会済みかどうか調べる(e-mansion用)
     * 1. e-mansionと紐づけがない場合: false     *
     * 2. e-mansionと紐づけあり かつ e-mansion契約基本情報取得APIの結果の退会日(quit_data) < 現在日付 の場合: true
     * 上記以外の場合: false
     */
    async isCanceledEmansion(): Promise<boolean | Error> {
      const emansionMemberId = this.member?.primaryKeyMye;
      const uaType: UaTypeValue | undefined = this.property?.uaType;
      const propertyId: string | undefined = this.property?.apartmentId;
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        apartment_id: propertyId,
      });

      // eslint-disable-next-line no-useless-catch
      try {
        // ISP未連携の場合 (members.primaryKeyMye=null)、false
        if (!emansionMemberId) {
          return true;
        }

        // 契約基本情報取得
        const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
          memberId: emansionMemberId,
          query: findCustomerByIdQueryDto,
        });

        if (customer instanceof EMansionCustomer) {
          if (customer.quit_date) {
            const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH); // yyyy/mm/dd

            if (customer.quit_date < formattedToday) {
              return true;
            }
          }
        }
      } catch (error) {
        throw error;
      }

      return false;
    },
    /*** 退会済みかどうか調べる(Five.A用)
     * 1. Five.Aと紐づけがない場合: fasle      *
     * 2. Five.Aと紐づけあり かつ Five.A契約基本情報取得APIの結果の退会日(quit_data) < 現在日付 の場合: true
     */
    async isCanceledFiveA(): Promise<boolean | Error> {
      const fiveAMemberId = this.member?.primaryKeyMye;
      const uaType: UaTypeValue | undefined = this.property?.uaType;
      const propertyId: string | undefined = this.property?.apartmentId;

      const fiveACustomerQueryDto = new FiveACustomerQueryDto({
        ua_type: uaType,
        apartment_id: propertyId,
      });

      // eslint-disable-next-line no-useless-catch
      try {
        // ISP未連携の場合 (members.primaryKeyMye=null)、false
        if (!fiveAMemberId) {
          return true;
        }

        // 契約基本情報をStoreから取得
        const customer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
          memberId: fiveAMemberId,
          query: fiveACustomerQueryDto,
        });

        if (customer instanceof FiveACustomer) {
          if (customer.quit_date) {
            const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH); // yyyy/mm/dd

            if (customer.quit_date < formattedToday) {
              return true;
            }
          }
        }
      } catch (error) {
        throw error;
      }

      return false;
    },
  },
});
</script>
