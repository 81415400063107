import { ProductIds } from './const';
import { ContentsDto } from './contents-dto';

/** APIから受け取るサブスクリプション一覧 */
export class SubscriptionDetailsArray {
  public contracts!: SubscriptionDetailsDto[];

  /** コンストラクタ */
  constructor(partial: Partial<SubscriptionDetailsArray>) {
    Object.assign(this, partial);
  }
}

/** サブスクリプション詳細DTO */
export class SubscriptionDetailsDto {
  /** 契約 ID */
  public contractId!: number;

  /** コンテンツプロバイダー ID */
  public contentProviderId!: number;

  /** 商品ID */
  public productsId!: ProductIds;

  /** 会員 ID */
  public memberId!: number;

  /** 申込日時 */
  public requestDateTime!: Date;

  /** 契約日 */
  public contractStartDate?: Date;

  /** 課金開始日 */
  public billingStartDate?: Date;

  /** 解約申込日時 */
  public cancelApplicationDate?: Date;

  /** 解約日 */
  public cancelDate?: string;

  /** 商品 */
  public products!: ProductsAtSubscriptionDetailsDto;

  /** 支払方法 */
  public paymentMethods!: PaymentMethodsAtAtSubscriptionDetailsDto;

  /** コンテンツ */
  public contents!: ContentsDto;

  /** コンストラクタ */
  constructor(partial: Partial<SubscriptionDetailsDto>) {
    Object.assign(this, partial);
  }
}

/** 商品 */
export class ProductsAtSubscriptionDetailsDto {
  /** 商品名 */
  public productsName?: string;

  /** 説明 */
  public description?: string;

  /** 規約 */
  public terms?: string;

  /** 提供開始日 */
  public startDate?: Date;

  /** 提供終了日 */
  public endDate?: Date;

  /** 商品価格 */
  public productPrice!: Array<ProductPriceAtSubscriptionDetailsDto>;

  /** 商品無課金定義 */
  public productNoCharge?: Array<ProductNoChargeAtSubscriptionDetailsDto>;

  constructor(partial: Partial<ProductsAtSubscriptionDetailsDto>) {
    Object.assign(this, partial);
  }
}

/** 商品価格 */
export class ProductPriceAtSubscriptionDetailsDto {
  /** 商品価格No */
  public productPriceNo!: number;

  /** 購入種別 */
  public priceType!: string;

  /** 価格(税抜き) */
  public priceTaxExcluded!: number;

  /** 提供開始日 */
  public startDate!: Date;

  /** 提供終了日 */
  public endDate?: Date;

  constructor(partial: Partial<ProductPriceAtSubscriptionDetailsDto>) {
    Object.assign(this, partial);
  }
}

/** 商品無課金定義 */
export class ProductNoChargeAtSubscriptionDetailsDto {
  /** 商品無課金No */
  public productNoChargeNo!: number;

  /** 無課金期間 */
  public noChargePeriod!: number;

  /** CP負担フラグ */
  public chargeFlag?: string;

  /** 優先度 */
  public priority?: number;

  /** 提供開始日 */
  public startDate?: Date;

  /** 提供終了日 */
  public endDate?: Date;

  constructor(partial: Partial<ProductNoChargeAtSubscriptionDetailsDto>) {
    Object.assign(this, partial);
  }
}

/** 支払方法 */
export class PaymentMethodsAtAtSubscriptionDetailsDto {
  /** VeriTransカードID */
  public veritransCardId!: string;

  /** 削除日時 */
  public deleteDate?: Date;

  constructor(partial: Partial<PaymentMethodsAtAtSubscriptionDetailsDto>) {
    Object.assign(this, partial);
  }
}
