import { UcomAccount } from './ucom-account';
import { UcomMailAddress } from './ucom-mail-address';

export class UcomAccountInfo {
  readonly _accountInfo!: UcomAccount;
  readonly _mailInfo?: UcomMailAddress | undefined;
  /** メールパック購入可否 */
  readonly _isEmailPackPurchaseEnabled: boolean;

  constructor(ucomAccount: UcomAccount, ucomMailAddress: UcomMailAddress | undefined, isEmailPackPurchaseEnabled: boolean) {
    this._accountInfo = ucomAccount;
    this._mailInfo = ucomMailAddress;
    this._isEmailPackPurchaseEnabled = isEmailPackPurchaseEnabled;
  }

  get accountInfo(): UcomAccount {
    return this._accountInfo;
  }

  get mailInfo(): UcomMailAddress | undefined {
    return this._mailInfo;
  }

  get hasMailInfo(): boolean {
    return this._mailInfo !== undefined;
  }

  get isEmailPackPurchaseEnabled(): boolean {
    return this._isEmailPackPurchaseEnabled;
  }
}
