/** 使用するバリデーションの定義 */
import { helpers } from '@vuelidate/validators';

/** 正規表現を用いたチェック定義：全角文字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const fullWidth = helpers.regex(/^[^ -~｡-ﾟ]+$/);
/** 正規表現を用いたチェック定義：全角文字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const fullWidthNoNum = helpers.regex(/^[^ -~｡-ﾟ０-９]+$/);
/** 正規表現を用いたチェック定義：半角英数字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const halfWidth = helpers.regex(/^[0-9a-zA-Z]*$/);
/** 正規表現を用いたチェック定義：UCOM電話番号用(数字2～4文字)-(2～4文字)-(4文字)（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const phoneStyle = helpers.regex(/^0\d{1,4}-\d{1,4}-\d{4}$/);
/** 正規表現を用いたチェック定義：全角文字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const fullKana = helpers.regex(/^[ァ-ンヴー]*$/);
export const fullKanaAndBlank = helpers.regex(/^[ァ-ヴー　]+$/); // ARN-970 にて、正規表現を統一
/** 正規表現を用いたチェック定義：メールアドレス（API側のバリデーションと統一するため追加）（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
// export const emailAddress = helpers.regex('emailAddress', /^([a-zA-Z0-9_+-])+([a-zA-Z0-9._+-])*@([a-zA-Z0-9._+-])+.([a-zA-Z0-9])+$/);
export const emailAddressMaxLength = helpers.regex(/^.{1,48}$/); // ARN-975 にて、修正依頼あり
export const emailAddressIllegalChar = helpers.regex(/^[^\.\%\-][^%]*@[0-9A-Za-z]+([\-\.][0-9A-Za-z]+)*\.[a-zA-Z]{2,}$/);
export const emailAddressIllegalCharMark = helpers.regex(/^[\w.\!\"\#\$\%\&\'\(\)\*\+\-\.\/\:\;\<\=\>\?\[\]\^\_\`\{\|\}\~]+@[^@\s\,\\]+$/);
export const emailAddressForGamewith = helpers.regex(
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
);
/** 正規表現を用いたチェック定義：e-mansionアカウント名用（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可）*/
export const emansionAccountName = helpers.regex(/^([a-z]{1})(?=.*[_-])[a-zA-Z0-9_-]{1,10}([a-z0-9]{1})$/);
export const hasNoBlank = helpers.regex(/^[^\s]+$/);
/**郵便番号 */
export const postalCodeStyle = helpers.regex(/^\d{3}-\d{4}$/);
/** UCOM用 部屋番号 */
export const roomNumberAllowHyphen = helpers.regex(/^[a-zA-Z0-9\-]+$/);
