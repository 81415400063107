<template>
  <div>
    <!-- 利用規約を表示する部分 -->
    <template v-if="termsOfService">
      <section v-for="(section, sectionKey) in termsOfService" :key="section.title" class="c-section">
        <h4 class="underlayer-h4">({{ section.title }})</h4>
        <ol class="c-order-list">
          <template v-for="(paragraph, paragraphKey) in section.paragraphList">
            <!-- ucomの規約にて、提供企業名を出しわけるための分岐 -->
            <template v-if="sectionKey === 'No1' && paragraphKey === 0">
              <li :key="paragraph.text">
                <p>
                  {{ section.index }} Connectixサービス利用規約（以下「本規約」といいます。）は、{{
                    serviceSupplyCompanyName
                  }}（以下「当社」といいます。）が提供する本サービスの利用に関し適用されるものとします。
                </p>
              </li>
            </template>

            <template v-else-if="paragraphKey === 0">
              <li :key="paragraph.text">
                <p>{{ section.index }} {{ paragraph.text }}</p>

                <template v-if="paragraph.subParagraphList">
                  <ol class="c-order-list add-padding-left">
                    <li v-for="(item, subParagraphKey) in paragraph.subParagraphList" :key="item">
                      <p>({{ subParagraphKey + 1 }}) {{ item }}</p>
                    </li>
                  </ol>
                </template>
              </li>
            </template>

            <template v-else>
              <li :key="paragraph.text">
                <template v-if="paragraph.subText">
                  <p>{{ paragraphKey + 1 }} {{ paragraph.text }}<br />{{ paragraph.subText }}</p>
                </template>

                <template v-else>
                  <p>{{ paragraphKey + 1 }} {{ paragraph.text }}</p>
                </template>

                <template v-if="paragraph.subParagraphList">
                  <ol class="c-order-list add-padding-left">
                    <li v-for="(item, subParagraphKey) in paragraph.subParagraphList" :key="item">
                      <p>({{ subParagraphKey + 1 }}) {{ item }}</p>
                    </li>
                  </ol>
                </template>
              </li>
            </template>
          </template>
        </ol>

        <template v-if="section.wordAndDefinitionList">
          <table class="c-table">
            <thead>
              <tr>
                <th class="is-base-width">用語</th>
                <th>用語の意味</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(wordAndDefinition, wordAndDefinitionKey) in section.wordAndDefinitionList" :key="wordAndDefinition.word">
                <td>{{ wordAndDefinitionKey + 1 }} {{ wordAndDefinition.word }}</td>
                <td>
                  {{ wordAndDefinition.definition }}
                  <template v-if="wordAndDefinition.exampleList">
                    <ol class="c-example-list">
                      <li v-for="example in wordAndDefinition.exampleList" :key="example">
                        {{ example }}
                      </li>
                    </ol>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </section>
    </template>

    <!-- 附則を表示する部分 -->
    <template v-if="supplementaryProvisions">
      <template v-for="element in supplementaryProvisions">
        <template v-if="element.itemList">
          <h3 :key="element.id" class="underlayer-h2 mt30">附則</h3>

          <template v-for="(item, itemListKey) in element.itemList" :key="item.title">
            <section>
              <h4 class="underlayer-h4 is-supplementary-provisions-title">({{ item.title }})</h4>
              <p>{{ itemListKey + 1 }} {{ item.text }}</p>
            </section>
          </template>
        </template>
      </template>
    </template>

    <!-- 料金表を表示する部分 -->
    <template v-if="priceList">
      <h3 class="underlayer-h2 mt30">料金表</h3>
      <section v-for="(section, sectionKey) in priceList.sectionList" :key="section.title">
        <!-- section.title -->
        <template v-if="section.title && section.priceTable">
          <h4 class="underlayer-h4 is-price-list-title">{{ sectionKey + 1 }} ({{ section.title }})</h4>
        </template>
        <template v-else-if="section.title">
          <h4 class="underlayer-h4 is-price-list-title">({{ section.title }})</h4>
        </template>

        <template v-if="section.text">
          <p class="has-text-indent">{{ sectionKey + 1 }} {{ section.text }}</p>
        </template>

        <template v-if="section.subParagraphList">
          <ol class="c-order-list add-padding-left">
            <li v-for="(item, itemKey) in section.subParagraphList" :key="item">
              <p>({{ itemKey + 1 }}) {{ item }}</p>
            </li>
          </ol>
        </template>

        <template v-if="section.priceTable">
          <table class="c-table">
            <thead>
              <tr>
                <th v-for="item in section.priceTable.headerItemList" :key="item">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="item in section.priceTable.bodyItemList" :key="item">
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </section>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.c-section {
  margin-top: 15px;
}

.c-order-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  &.add-padding-left {
    padding-left: 16px;
    & li {
      & p {
        padding-left: 20px;
        text-indent: -21px;
      }
    }
  }
  & li {
    & p {
      padding-left: 1em;
      text-indent: -1em;
    }
  }
}

.c-example-list {
  list-style: none;
  margin: 0;
  margin-top: 4px;
  padding: 0;
}

.c-table {
  width: 100%;
  border-collapse: collapse;
  & th {
    padding: 8px 4px;
    border: solid 1px #d3d3d3;
    text-align: left;
    font-weight: 400;
    &.is-base-width {
      width: 20%;
      min-width: 88px;
    }
  }
  & td {
    padding: 8px 4px;
    border: solid 1px #d3d3d3;
    &:first-child {
      padding-left: 20px;
      text-indent: -12px;
    }
  }
}

h4 {
  &.is-price-list-title {
    margin-top: 15px;
  }
  &.is-supplementary-provisions-title {
    margin-top: 8px;
  }
}

p {
  &.has-text-indent {
    padding-left: 1em;
    text-indent: -1em;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { ConnectixTermsOfService, PriceList, SupplementaryProvisions } from '../../terms-of-service/connectix-terms-of-service.entity';

export default defineComponent({
  name: 'display-connectix-terms-of-service',
  props: {
    termsOfService: {
      type: Object as () => ConnectixTermsOfService
    },
    // 附則
    supplementaryProvisions: {
      type: Object as () => SupplementaryProvisions
    },
    priceList: {
      type: Object as () => PriceList
    },
    serviceSupplyCompanyName: {
      type: String
    }
  }
});
</script>
