<template>
  <div class="login-callback"></div>
</template>

<style>
.login-callback {
  opacity: 0;
  animation: fade-in 0.5s ease 3s 1 normal;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script lang="ts">
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { TRANSITION_STORAGE } from '@/shared/const/service-type';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { SubAccountRoutingService } from '@/shared/services/route/sub-account-routing-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { GenericError } from '@auth0/auth0-spa-js';
import { defineComponent } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { AuthService } from '../shared/services/auth/auth-service';

/** ログイン後コールバック 画面 */
export default defineComponent({
  name: 'login-callback',
  data: () => ({
    rawLocation: null as any,
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
    // ISP(Five.A)会員ステータス
    ispMemberStatusFiveA: '',
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: '',
  }),
  /** コンポーネント生成時の処理 */
  async mounted(): Promise<RouteLocationNormalized | void> {
    console.log('login-callback');
    try {
      // キャストが効かないので無理やりanyで解決する
      const rawLocation: any = await AuthService.handleRedirectCallback();
      console.log('ローカルストレージ' + JSON.stringify(JSON.parse(localStorage.getItem(TRANSITION_STORAGE) || 'null')));
      console.log('rawLocation.query' + JSON.stringify(rawLocation.query));
      console.log('rawLocation' + JSON.stringify(rawLocation));
      if (rawLocation.query && Object.keys(rawLocation.query).length > 0) {
        console.log('rawLocation');
        this.$data.rawLocation = rawLocation.query;
      } else {
        console.log('ローカルストレージ');
        const storedLocation = JSON.parse(localStorage.getItem(TRANSITION_STORAGE) || 'null');
        this.$data.rawLocation = storedLocation;
      }
      console.log(this.$data.rawLocation);
      const isSubAccountLinK = this.$data.rawLocation?.subAccount ?? false;
      console.log('追加アカウント連携' + isSubAccountLinK);

      // この段階ではクエリパラメータ、Auth0のトークンの何れかだけが判断材料となる
      // ※ servicePlanTypeはまだ情報が確立する前の為
      if (isSubAccountLinK || (await AccountUtilService.isSubAccount())) {
        await this.$router
          .push({
            path: await SubAccountRoutingService.getNextPathOnCallback(this.$data.rawLocation?.from),
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          });
        return;
      }

      // 会員ストアから会員ステータス情報を取得する
      // meta.isPublic: true なので、beforeEach内で PortasDBCacheWithStore.main() が実行されない
      const memberStatus: MemberStatus = await this.$store.dispatch('memberStore/memberStatus');

      // 会員ステータス無しの場合はメールを入力してもらう
      if (!memberStatus) {
        await this.$router
          .push({
            path: '/platform/entry/input-email',
            query: { from: this.$data.rawLocation?.from, apid: this.$data.rawLocation?.apid },
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          });
        return;
      }

      const status = memberStatus.status;

      /** 会員ステータス0or1/platform/entry/input-emeilに遷移 */
      if (status == MEMBER_STATUS.ACCOUNT_CREATED || status == MEMBER_STATUS.MAIL_SENT) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/entry/input-email').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 会員ステータス6or8の場合 共通エラー画面に遷移
      if (status === MEMBER_STATUS.ACCOUNT_DELETED || status === MEMBER_STATUS.ACCOUNT_RESET) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.MEMBER_STATUS_DELETED_OR_RESET);
      }

      // 会員ステータス2の場合 /platform/entry/inputに遷移
      if (status === MEMBER_STATUS.MAIL_VALIDATED) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/entry/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
    } catch (error: any) {
      if (error.response?.status === 503 && error.response.data) {
        // 握りつぶす
        return;
      }

      const util = require('util');

      /**
       * auth0Client.handleRedirectCallback() の処理の結果、次のエラーが起きたときの処理
       *
       * 1. auth0-spa-js から 「Error: Invalid state」 がスルーされたとき
       *
       * 2. auth0-spa-js が POST: oauth/token に HTTPリクエストを送ってエラーレスポンスを受け取ったとき
       *    試したところ、400 Bad Request で 次のようなエラーレスポンスが返却された
       *    (上記は、login-callback?code=... の ?code=... 以降の文字列が不適切ですエラー)
       *
       *  {
       *   "error": "invalid_request",
       *   "error_description": "Missing required parameter: code"
       *  }
       */
      if (util.inspect(error) === '[Error: Invalid state]') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/login-forwarding').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (error instanceof GenericError) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      throw error;
    }
  },
  // methods: {
  //   /**
  //    * Portas会員ステータスが7のとき、e-mansion会員専用トップに遷移してokかどうか判定する
  //    */
  //   async canTransitionToIspMemberTopEMansion(apartmentId: string, eMansionMemberId: string): Promise<boolean> {

  //     const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
  //       ua_type: UA_TYPE.E_MANSION,
  //       apartment_id: apartmentId
  //     });

  //     const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
  //       memberId: eMansionMemberId,
  //       query: findCustomerByIdQueryDto
  //     });

  //     if (eMansionCustomer instanceof EMansionCustomer) {

  //       // ISP会員ステータス取得
  //       const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
  //       this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

  //       /**
  //        * ISP(e-mansion)会員ステータスが次の4つの場合、e-mansion会員専用トップに遷移してok
  //        * (ただし、Portas会員ステータスが7のとき)
  //        *
  //        * 1. ISP(e-mansion)会員ステータス: 通常会員
  //        * 2. ISP(e-mansion)会員ステータス: 退会申し込み
  //        * 3. ISP(e-mansion)会員ステータス: 移転退会
  //        * 4. ISP(e-mansion)会員ステータス: 退会済みログイン可能
  //        */
  //       if (this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.MEMBER
  //           || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION
  //           || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL
  //           || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK) {
  //         return true;
  //       }
  //     } else {
  //       throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
  //     }
  //     return false;
  //   },
  //   /**
  //    * Portas会員ステータスが7のとき、Five.A会員専用トップに遷移してokかどうか判定する
  //    */
  //   async canTransitionToIspMemberTopFiveA(apartmentId: string, fiveAMemberId: string): Promise<boolean> {

  //     const fiveACustomerQueryDto = new FiveACustomerQueryDto({
  //       ua_type: UA_TYPE.FIVE_A,
  //       apartment_id: apartmentId
  //     });

  //     const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
  //       memberId: fiveAMemberId,
  //       query: fiveACustomerQueryDto
  //     });

  //     if (fiveACustomer instanceof FiveACustomer) {

  //       // ISP会員ステータス取得
  //       const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
  //       this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

  //       /**
  //        * ISP(Five.A)会員ステータスが次の3つの場合、Five.A会員専用トップに遷移してok
  //        * (ただし、Portas会員ステータスが7のとき)
  //        *
  //        * 1. ISP(Five.A)会員ステータス: 通常会員
  //        * 2. ISP(Five.A)会員ステータス: 退会申し込み
  //        * 3. ISP(Five.A)会員ステータス: 退会済みログイン可能
  //        */
  //       if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.MEMBER
  //           || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION
  //           || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
  //         return true;
  //       }
  //     } else {
  //       // データ不整合エラー
  //       throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
  //     }
  //     return false;
  //   },
  //   /**
  //    * Portas会員ステータスが7のとき、ucom会員専用トップに遷移してokかどうか判定する
  //    */
  //   async canTransitionToIspMemberTopUcom(ucomMemberId: string): Promise<boolean> {

  //     const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', ucomMemberId);

  //     if (customer instanceof UcomCustomerResponse) {

  //       // ISP会員ステータス取得
  //       const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
  //       this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

  //       /**
  //        * ISP(ucom)会員ステータスが次の3つの場合、ucom会員専用トップに遷移してok
  //        * (ただし、Portas会員ステータスが7のとき)
  //        *
  //        * 1. ISP(ucom)会員ステータス: 通常会員
  //        * 2. ISP(ucom)会員ステータス: 退会申し込み
  //        * 3. ISP(ucom)会員ステータス: 退会済みログイン可能
  //        */
  //       if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.MEMBER
  //           || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION
  //           || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
  //         return true;
  //       }
  //     } else if (customer instanceof UcomSharedErrorResponse) {
  //       throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
  //     }
  //     return false;
  //   }
  // }
});
</script>