<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページお申し込み" />お申し込み</h2>
        ホームページのお申し込み手続きを行います。
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <ul class="ul-basic01">
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」の前日までにお申し込みいただくと、鍵のお引渡し日からご利用いただけます。</li>
        </ul>

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">ご希望URL</h3>
            <p>「~(チルダ)」の後に、お好きな文字列を入力してください。</p>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th><b>ご希望URL</b><span class="req">必須</span></th>
                  <td>
                    <p class="red mb15">半角英数小文字、-（ハイフン）、_（アンダーバー）の組み合わせで「3～12文字以内」でご指定ください。</p>
                    <ul class="ul-basic01">
                      <li>先頭の1文字は英字（a～z）のみ指定できます。</li>
                      <li>数字のみのアカウントは作成できません。</li>
                      <li>-（ハイフン）や_（アンダーバー）で終わるアカウントは作成できません。</li>
                      <li>.（ピリオド）,（カンマ）/（スラッシュ）&nbsp;（スペース）と、大文字（Aなど）は利用できません。</li>
                    </ul>
                    http://www.**.em-net.ne.jp/~ <input type="text" v-model="requestUrl" class="text short" />
                  </td>
                </tr>
              </tbody>
            </table>
            <p>※ホームページURL（http://www.<span class="red">**</span>.em-net.ne.jp）の「<span class="red">**</span>」部分は、自動的に決まります。</p>
          </div>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt" style="text-align: center">「申し込む」をクリックしてください。確認画面へ進みます。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goNext()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'homepage-application',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    requestUrl: '',
    URL_MINIMUM_LEN: 3,
    URL_MAX_LEN: 12,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.isLoading = false;
      this.requestUrl = await this.$store.getters['eMansionHomepageOpenStore/requestUrl'];
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorMessages = [];

      /** バリデーション処理 */
      // 空欄
      if (!this.requestUrl) {
        this.errorMessages.push('「ご希望URL」を入力してください。');
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }
      // 長さ
      if (!(await this.isValidUrlLength())) {
        this.errorMessages.push('「ご希望URL」は、3文字以上12文字以内で入力してください。');
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }

      try {
        // AE05-001 ホームページ：お申し込み_入力チェックAPIの呼び出し
        const response = await SpfApiHomepageAccessor.callApplicationInputCheck(this.requestUrl);

        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }

        // URLを設定
        this.$store.commit('eMansionHomepageOpenStore/requestUrl', response.requestUrl);
        this.isLoading = false;
        // ホームページ：お申し込み確認画面に遷移する
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return await this.$router.push('/e-mansion/homepage/application-confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
      }
    },
    /**
     * ご希望URLの長さバリデーション
     * @param requestUrl ご希望URL
     * @return true: 有効なURL or false: 2文字以下か13文字以上
     */
    async isValidUrlLength() {
      const length = this.requestUrl.length;
      /** 2文字以下の場合 or 13文字以上の場合 */
      if (length < this.URL_MINIMUM_LEN) {
        return false;
      }

      return this.URL_MAX_LEN >= length;
    },
  },
});
</script>
