<template>
  <div>
    <template v-if="!isOneSentence && errorMessages.length > 0">
      <div class="error-messages-component">
        <div class="err">
          <div v-if="errorMessageTitle">
            <p class="red" v-html="errorMessageTitle"></p>
          </div>
          <div v-for="(errorMessage, index) in errorMessages" :key="errorMessage">
            <ul class="ul-basic01" :class="{ 'is-isp-linking-style': isIspLinkingStyle, 'margin-top-0': isIspLinkingStyle && index === 1 }">
              <li v-html="errorMessage"></li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isOneSentence && errorMessages.length > 0">
      <div class="error-messages-component">
        <div class="err">
          <p class="red">{{ errorMessages[0] }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>

.is-isp-linking-style {
  margin-top: 16px;
  padding-left: 0;
  list-style: none;
  &.margin-top-0 {
    margin-top: 0;
  }
}

</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** エラーメッセージ表示エリアコンポーネント */
export default defineComponent({
  name: 'error-messages-component',
  // 親コンポーネントから受け取るデータ
  props: {
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: {
      type: String,
      default: ''
    },
    /** 表示したいエラーメッセージを格納した配列 */
    errorMessages: {
      type: Array,
      default: Array
    },
    isOneSentence: {
      type: Boolean,
      default: false
    },
    isIspLinkingStyle: {
      type: Boolean,
      default: false
    }
  }
});
</script>
