<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>アカウントID 登録・変更　完了</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>アカウントID　登録・変更　完了</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOMアカウントID登録変更" />アカウントID　登録・変更　完了</h2>
        <div v-if="!isMailAddressRegistration">
          <p>
            お申し込みいただきました内容につきまして手続きが完了しました。<br />
            引き続き、<a @click.prevent href="" v-on:click="mailAddressRegisterScreenMove()"
              ><font color="blue"><u>「こちら」</u></font></a
            >よりメールアドレスの登録を行ってください。
          </p>
        </div>
      </div>
      <!-- /blc -->

      <div class="sblc">
        <h3 class="service-h3">お申し込み内容</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">氏名</th>
              <td>（姓）{{ familyName }}　（名）{{ firstName }}</td>
            </tr>
            <tr>
              <th class="va-middle">氏名カナ</th>
              <td>（姓カナ）{{ familyNameKana }}　（名カナ）{{ firstNameKana }}</td>
            </tr>
            <tr>
              <th class="va-middle">ユーザーネーム</th>
              <td>{{ userName }}</td>
            </tr>
            <tr>
              <th class="va-middle">パスワード</th>
              <td>{{ password }}</td>
            </tr>
            <tr>
              <th class="va-middle">アカウント権限種別</th>
              <td>{{ accountAuthorization }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="onUcomTop()">
            UCOM光 レジデンス会員専用トップ
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'account-management-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
    UNREGISTERED: '未登録',
    isChange: UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT,

    /** 各種パラメータ */
    ucomAccountId: '', // UCOM_アカウントID
    accountName: '', // アカウントID
    familyName: '', // 姓
    firstName: '', // 名
    familyNameKana: '', // 姓カナ
    firstNameKana: '', // 名カナ
    userName: '', // ユーザーネーム
    password: '', // パスワード
    accountAuthorization: '', // 表示用UCOM_アカウント権限
    isMailAddressRegistration: '', // メールアドレス登録有無
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      // URL直叩きの場合の画面遷移
      const ucomAccountId = await this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      if (!ucomAccountId) {
        await this.$router.push('/ucom/account-management/list').catch((error) => {
          checkRouterError(error);
        });

        return;
      }

      // 前画面(アカウントID管理：登録・変更 確認)からの情報をStoreから取得
      this.setDataByStore();

      // store内のデータをクリア
      this.deleteStore();

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });

      return;
    }
  },

  methods: {
    // storeからデータをセット
    setDataByStore(): void {
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      this.$data.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.$data.familyName = this.$store.getters['ucomAccountManagementInputStore/familyName'];
      this.$data.firstName = this.$store.getters['ucomAccountManagementInputStore/firstName'];
      this.$data.familyNameKana = this.$store.getters['ucomAccountManagementInputStore/familyNameKana'];
      this.$data.firstNameKana = this.$store.getters['ucomAccountManagementInputStore/firstNameKana'];
      this.$data.userName = this.$store.getters['ucomAccountManagementInputStore/userName'];
      this.$data.password = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.$data.accountAuthorization = this.$store.getters['ucomAccountManagementInputStore/accountAuthorization'];
      this.$data.isMailAddressRegistration = this.$store.getters['ucomAccountManagementInputStore/isMailAddressRegistration'];
    },

    // store内のデータをクリア
    deleteStore(): void {
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', undefined);
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/familyName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/firstName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/familyNameKana', undefined);
      this.$store.commit('ucomAccountManagementInputStore/firstNameKana', undefined);
      this.$store.commit('ucomAccountManagementInputStore/userName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/password', undefined);
      this.$store.commit('ucomAccountManagementInputStore/accountAuthorization', undefined);
      this.$store.commit('ucomAccountManagementInputStore/isMailAddressRegistration', undefined);
      this.$store.commit('ucomAccountManagementInputStore/currentMailAddress', undefined);
      this.$store.commit('ucomAccountManagementInputStore/mailAddress', undefined);
      this.$store.commit('ucomAccountManagementInputStore/isChange', undefined);
    },

    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });

      this.$data.isLoading = false;
    },

    // アカウントIDオプション変更：メールアドレス登録・変更 入力画面に遷移
    async mailAddressRegisterScreenMove() {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 次画面に データを渡す
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', this.$data.ucomAccountId);
      this.$store.commit('ucomAccountManagementInputStore/currentMailAddress', this.$data.UNREGISTERED);
      this.$store.commit('ucomAccountManagementInputStore/mailAddress', '');
      this.$store.commit('ucomAccountManagementInputStore/isChange', this.$data.isChange);
      this.$store.commit('ucomAccountManagementInputStore/accountName', this.$data.accountName);

      await this.$router.push('/ucom/account-option-change/mail-input').catch((error) => {
        checkRouterError(error);
      });

      this.$data.isLoading = false;
    },
  },
});
</script>
