<template>
  <section class="portas-service-price-list-and-notes">
    <h4 class="my-title">合計金額</h4>

    <!-- 単品商品のとき -->
    <template v-if="displayPattern === DISPLAY_PATTERN.PORTAS_SERVICE_SINGLE && isMounted">
      <div class="table-scroll js-scrollable">
        <table class="my-price-list">
          <thead>
            <tr>
              <th>ご注文のPortasサービス</th>
              <th>数量</th>
              <th>月額通常料金</th>
              <th>【参考】月額セット料金</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in productNameAndPriceList" :key="item.productName">
              <td v-html="item.productName"></td>
              <td>1</td>
              <td>{{ formatPrice(item.regularPrice) }}円</td>
              <td>{{ formatPrice(item.discountPrice) }}円</td>
            </tr>
            <tr>
              <td>合計</td>
              <td>{{ productNameAndPriceList.length }}</td>
              <!-- 単品商品のときは、月額通常価格を表示する -->
              <td>{{ formatPrice(productNameAndPriceList[0].regularPrice) }}円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <!-- 複数商品のとき -->
    <template v-else-if="displayPattern === DISPLAY_PATTERN.PORTAS_SERVICE_SET && isMounted">
      <div class="table-scroll js-scrollable">
        <table class="my-price-list">
          <thead>
            <tr>
              <th>ご注文のPortasサービス</th>
              <th>数量</th>
              <th>月額セット料金</th>
              <th>【参考】月額通常料金</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in productNameAndPriceList" :key="item.productName">
              <td v-html="item.productName"></td>
              <td>1</td>
              <td>{{ formatPrice(item.discountPrice) }}円</td>
              <td>{{ formatPrice(item.regularPrice) }}円</td>
            </tr>
            <tr>
              <td>合計</td>
              <td>{{ productNameAndPriceList.length }}</td>
              <td>{{ formatPrice(totalDiscountPrice) }}円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <!-- U-NEXT単品のとき  -->
    <template v-else-if="displayPattern === DISPLAY_PATTERN.U_NEXT_SINGLE && isMounted">
      <div class="table-scroll js-scrollable">
        <table class="my-price-list">
          <thead>
            <tr>
              <th>ご注文のPortasサービス</th>
              <th>数量</th>
              <th>月額通常料金</th>
              <th>【参考】月額セット料金</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ uNextProductNameAndPrice.productName }}</td>
              <td>1</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceBeforeDiscount) }}円</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceBeforeDiscount) }}円</td>
            </tr>
            <tr>
              <td>{{ uNextProductNameAndPrice.productNameDiscount }}</td>
              <td>0</td>
              <td>0円</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceDiscountAmount) }}円</td>
            </tr>
            <tr>
              <td>合計</td>
              <td>1</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceBeforeDiscount) }}円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <!-- U-NEXT for Portas のとき  -->
    <template v-else-if="displayPattern === DISPLAY_PATTERN.U_NEXT_FOR_CONNECTIX && isMounted">
      <div class="table-scroll js-scrollable">
        <table class="my-price-list">
          <thead>
            <tr>
              <th>ご注文のPortasサービス</th>
              <th>数量</th>
              <th>月額セット料金</th>
              <th>【参考】月額通常料金</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ uNextProductNameAndPrice.productName }}</td>
              <td>1</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceBeforeDiscount) }}円</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceBeforeDiscount) }}円</td>
            </tr>
            <tr>
              <td>{{ uNextProductNameAndPrice.productNameDiscount }}</td>
              <td>1</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceDiscountAmount) }}円</td>
              <td>0円</td>
            </tr>
            <tr>
              <td>合計</td>
              <td>2</td>
              <td>{{ formatPrice(uNextProductNameAndPrice.priceBeforeDiscount + uNextProductNameAndPrice.priceDiscountAmount) }}円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <!-- ノートン + U-NEXT のとき  -->
    <template v-else-if="displayPattern === DISPLAY_PATTERN.U_NEXT_AND_NORTON && isMounted">
      <div class="table-scroll js-scrollable">
        <table class="my-price-list">
          <thead>
            <tr>
              <th>ご注文のPortasサービス</th>
              <th>数量</th>
              <th>月額セット料金</th>
              <th>【参考】月額通常料金</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in productNameAndPriceList" :key="item.productName">
              <td v-html="item.productName"></td>
              <td>1</td>
              <td>{{ formatPrice(item.discountPrice) }}円</td>
              <td>{{ formatPrice(item.regularPrice) }}円</td>
            </tr>
            <tr>
              <td>合計</td>
              <td>{{ productNameAndPriceList.length }}</td>
              <td>{{ formatPrice(totalDiscountPrice) }}円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <!-- 価格表の下の注意書き -->
    <div class="my-notes">
      <section class="my-notes-section">
        <div class="my-title">
          <h5>※1 記載の金額はすべて税込金額です。</h5>
        </div>
        <ul class="my-notes-list">
          <li class="my-notes-list__item">月末時点のお申し込み状況および消費税の計算上、実際の請求額と異なる場合があります。</li>
          <li class="my-notes-list__item">初めてお申し込みされた方は、サービス毎に所定の無料期間が適用されます。</li>
        </ul>
      </section>

      <section class="my-notes-section">
        <div class="my-title">
          <h5>※2 月額セット料金の適用条件：ご利用月末時点で当社指定のPortasサービスを複数ご契約されている場合のみ適用されます。</h5>
        </div>
        <ul class="my-notes-list">
          <li class="my-notes-list__item">Portasサービスが1点のみのご契約となった場合、月額セット料金はその翌月より適用されず、月額通常料金が適用されます。</li>
        </ul>
      </section>

      <section class="my-notes-section">
        <div class="my-title">
          <h5>※3 アルテリア・ネットワークス株式会社または株式会社つなぐネットコミュニケーションズが提供するPortasサービスをU-NEXTとセットでご契約いただいた場合</h5>
        </div>
        <ul class="my-notes-list">
          <li class="my-notes-list__item">
            アルテリア・ネットワークス株式会社からの特典として月額220円分を契約者に付与し、これを債権譲渡を受けたU-NEXTの料金、またはPortasサービスの料金から毎月相殺したうえで、契約者様に請求いたします。
          </li>
          <li class="my-notes-list__item">なお、Portasサービスの料金から相殺することなく、月額220円の特典のみを契約者様にお支払いすることはいたしません。</li>
          <li class="my-notes-list__item">
            また、契約者様において、料金の未払い等、契約に違反する行為が生じた場合、アリテリア・ネットワークス株式会社の判断により、当該特典が適用されない場合がございます。
          </li>
        </ul>
      </section>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section.portas-service-price-list-and-notes {
  margin-top: 24px;
  & h4.my-title {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
}
div.table-scroll {
  margin-top: 24px;
  padding-bottom: 16px;
}

table.my-price-list {
  table-layout: fixed;
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  & thead {
    background-color: #cf1225;
    color: #fff;
    & tr {
      & th {
        border: 1px solid #333;
        padding: 8px 24px;
        text-align: center;
        &:first-child {
          width: 224px;
        }
        &:nth-child(2) {
          width: 88px;
        }
        &:nth-child(3) {
          width: 152px;
        }
        &:nth-child(4) {
          width: 232px;
          background-color: #888;
        }
      }
    }
  }
  & tbody {
    tr {
      & td {
        border: 1px solid #333;
        padding: 8px;
        text-align: right;
        &:first-child {
          text-align: left;
        }
        &:nth-child(4) {
          background-color: #efefef;
        }
      }
      &:last-child {
        background-color: #fcc;
        & td:first-child {
          text-align: right;
        }
      }
    }
  }
}

div.my-notes {
  margin-top: 24px;
  & section.my-notes-section {
    margin-bottom: 24px;
    & div.my-title {
      & h5 {
        margin: 0;
        font-size: 13px;
        font-weight: 400;
      }
    }
    & ul.my-notes-list {
      margin: 0;
      padding: 0;
      margin: 8px 0;
      padding-left: 16px;
      list-style: none;
      font-size: 13px;
      & li.my-notes-list__item {
        letter-spacing: 0.4px;
        padding-bottom: 4px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1064px) {
  div.table-scroll {
    padding-bottom: 0;
  }
}
</style>

<script lang="ts">
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { defineComponent } from 'vue';

import { ProductsDtoDetail } from '@/shared/classes/spf-api/portas-subscription/products-1-dto';
import { ProductsDto } from '@/shared/classes/spf-api/portas-subscription/products-dto';
import { FORMAT_DATE } from '@/shared/const/format';
import { U_NEXT_TAX_INCLUDED_PRICE } from '@/shared/const/portas-subscription/u-next-price';
import { PRODUCT_PRICE_TYPE as PRICE_TYPE, UNEXT_CONTENTS_ID } from '@/shared/const/service-type';
import { processDate } from '@/shared/util/func-process-date';
import store from '@/store';

const DISPLAY_PRICE_LIST_PATTERN = {
  PORTAS_SERVICE_SINGLE: 'PORTAS_SERVICE_SINGLE',
  PORTAS_SERVICE_SET: 'PORTAS_SERVICE_SET',
  U_NEXT_SINGLE: 'U_NEXT_SINGLE',
  U_NEXT_FOR_CONNECTIX: 'U_NEXT_FOR_CONNECTIX',
  U_NEXT_AND_NORTON: 'U_NEXT_AND_NORTON',
} as const;

const today = processDate();

/**
 * 有効な商品価格を取得する
 */
const getValidProductPriceDto = (productsDtoDetail: ProductsDtoDetail[], priceType: string) => {
  const maxProductPriceDto = productsDtoDetail
    // 商品価格.購入種別 で 絞り込み
    .filter((dto) => dto.priceType === priceType)
    .filter((dto) => {
      // 商品価格.適用開始日 <= 現在日付 かつ 商品価格.適用終了日 IS NULL
      if (!dto.endDate) {
        if (dayjs(dto.startDate).format(FORMAT_DATE.YYYYMMDD_WITH_HYPHEN) <= today) {
          return true;
        }

        // 商品価格.適用開始日 <= 現在日付 かつ Not(商品価格.適用終了日 < 現在日付)
      } else {
        if (dayjs(dto.startDate).format(FORMAT_DATE.YYYYMMDD_WITH_HYPHEN) <= today && !(dayjs(dto.endDate).format(FORMAT_DATE.YYYYMMDD_WITH_HYPHEN) < today)) {
          return true;
        }
      }

      return false;
    })
    // 商品価格.商品価格No が 最大のものを返却する
    .reduce((acc, current) => (acc.productPriceNo < current.productPriceNo ? current : acc), {
      productPriceNo: -1,
    } as ProductsDtoDetail);

  return maxProductPriceDto;
};

/**
 * 税込み価格を算出する
 */
const calcTaxIncludedPrice = (dto: ProductsDtoDetail, tax: string) => {
  // (税抜金額 / 100) * (100 + 税率)
  const calculated = new Decimal(dto.priceTaxExcluded.toString()).dividedBy('100').times(new Decimal('100').add(tax));
  return calculated.trunc().toNumber();
};

/**
 * セット価格の合計金額を算出する
 */
const calcTotalDiscountPrice = (list: ProductNameAndPrice[]) => {
  const totalDiscountPrice = list.reduce((acc, current) => {
    return acc + current.discountPrice;
  }, 0);

  return totalDiscountPrice;
};

type ProductNameAndPrice = Readonly<{
  productName: string;
  regularPrice: number;
  discountPrice: number;
}>;

type UNextProductNameAndPrice = Readonly<{
  productName: string;
  productNameDiscount: string;
  priceBeforeDiscount: number;
  priceDiscountAmount: number;
}>;

const uNextProductNameAndPrice: UNextProductNameAndPrice = {
  productName: 'U-NEXT',
  productNameDiscount: 'Portasセット値引き',
  priceBeforeDiscount: U_NEXT_TAX_INCLUDED_PRICE.BEFORE_DISCOUNT,
  priceDiscountAmount: U_NEXT_TAX_INCLUDED_PRICE.DISCOUNT_AMOUNT,
};

export default defineComponent({
  name: 'portas-service-price-list-and-notes',
  props: {
    /** this.productsDtoList[0].terms = 'aaaa'; のようにする変更できるので、やらないこと */
    productsDtoList: {
      type: Array as () => ProductsDto[],
      required: true,
    },
    tryToSignUpForConnectix: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.displayPattern = this.getDisplayPattern();
    this.tax = await store.dispatch('taxStore/taxRate');
    this.makeData();
    this.isMounted = true;

    this.$nextTick(() => {
      this.jsScrollable();
    });
  },
  data: () => ({
    productNameAndPriceList: new Array<ProductNameAndPrice>(),
    totalDiscountPrice: 0,
    tax: '',
    uNextProductNameAndPrice: uNextProductNameAndPrice,
    displayPattern: '',
    DISPLAY_PATTERN: DISPLAY_PRICE_LIST_PATTERN,
    isMounted: false,
    ScrollHint: require('../../../../shared/libraries/scroll-hint/scroll-hint'),
  }),
  methods: {
    makeData() {
      this.productNameAndPriceList = this.productsDtoList.map((productsDto) => {
        const regularPriceDto = getValidProductPriceDto(productsDto.productPrice, PRICE_TYPE.REGULAR_PRICE);
        const discountPriceDto = getValidProductPriceDto(productsDto.productPrice, PRICE_TYPE.DISCOUNTED_PRICE);
        return {
          productName: productsDto.productsName,
          regularPrice: calcTaxIncludedPrice(regularPriceDto, this.tax),
          discountPrice: calcTaxIncludedPrice(discountPriceDto, this.tax),
        } as ProductNameAndPrice;
      });

      this.totalDiscountPrice = calcTotalDiscountPrice(this.productNameAndPriceList);
    },
    formatPrice(price: number) {
      const formatter = new Intl.NumberFormat('ja-JP');
      return formatter.format(price);
    },
    getDisplayPattern() {
      const filteredUNext = this.productsDtoList.filter((dto) => dto.contentsId === UNEXT_CONTENTS_ID.MONTHLY_PLAN);
      const filteredUNextANDNorton = this.productsDtoList.filter((dto) => [1, 4].includes(dto.contentsId));

      const containsUNext = filteredUNextANDNorton.some((dto) => dto.contentsId === 1);
      const containsNorton = filteredUNextANDNorton.some((dto) => dto.contentsId === 4);

      if (this.tryToSignUpForConnectix) {
        if (filteredUNext.length === 1) {
          return DISPLAY_PRICE_LIST_PATTERN.U_NEXT_FOR_CONNECTIX;
        } else if (filteredUNext.length === 0 && this.productsDtoList.length > 0) {
          return DISPLAY_PRICE_LIST_PATTERN.PORTAS_SERVICE_SET;
        }
      } else if (containsUNext && containsNorton) {
        return DISPLAY_PRICE_LIST_PATTERN.U_NEXT_AND_NORTON;
      } else if (filteredUNext.length === 1) {
        return DISPLAY_PRICE_LIST_PATTERN.U_NEXT_SINGLE;
      } else if (this.productsDtoList.length === 1) {
        return DISPLAY_PRICE_LIST_PATTERN.PORTAS_SERVICE_SINGLE;
      } else if (this.productsDtoList.length > 1) {
        return DISPLAY_PRICE_LIST_PATTERN.PORTAS_SERVICE_SET;
      }

      return '';
    },
    jsScrollable() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
  },
});
</script>
