import { Module } from 'vuex';
import { RootState } from './index';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { InvoiceHistoryListDto } from '@/shared/classes/spf-api/invoice-history-list-dto';

/** 請求履歴一覧取得 State */
const invoiceHistoryListDtoState: {
  InvoiceHistoryListDto: InvoiceHistoryListDto[] | null;
} = {
  InvoiceHistoryListDto: null,
};

/** State の型 */
type InvoiceHistoryListDtoState = typeof invoiceHistoryListDtoState;

/** 請求履歴一覧取得 Store */
export const invoiceHistoryListDtoStore: Module<InvoiceHistoryListDtoState, RootState> = {
  namespaced: true,
  state: invoiceHistoryListDtoState,
  mutations: {
    invoiceHistoryListDto: (state, value: InvoiceHistoryListDto[]) => (state.InvoiceHistoryListDto = value),
  },
  getters: {
    invoiceHistoryListDto: (state): InvoiceHistoryListDto[] | null => state.InvoiceHistoryListDto,
  },
  actions: {
    async invoiceHistoryListDto(context, params: { memberId: number; targetMonth: number }) {
      // APIコール
      const invoiceHistoryResult = await SpfApiService.getInvoiceHistoryList(params.memberId, params.targetMonth);
      // 値をコミットする
      context.commit('invoiceHistoryListDto', invoiceHistoryResult);
      const IfterinvoiceHistoryListDto = context.getters['invoiceHistoryListDto'];
      return IfterinvoiceHistoryListDto;
    },
  },
};
