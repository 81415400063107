import { Module } from 'vuex';

import { RootState } from './index';

/** 残工事の有無 State */
const remainingWorkState: {
  remainingWork: string | null;
  apid: string | null;
  buildingId: string | null;
  RoomNumber: string | null;
} = {
  remainingWork: null,
  apid: null,
  buildingId: null,
  RoomNumber: null,
};

/** State の型 */
type RemainingWorkState = typeof remainingWorkState;

/** 残工事の有無 Store */
export const remainingWorkStore: Module<RemainingWorkState, RootState> = {
  namespaced: true,
  state: remainingWorkState,
  mutations: {
    remainingWork: (state, value: string) => (state.remainingWork = value),
    apid: (state, value: string) => (state.apid = value),
    buildingId: (state, value: string) => (state.buildingId = value),
    RoomNumber: (state, value: string) => (state.RoomNumber = value),
  },
  getters: {
    remainingWork: (state): string | null => state.remainingWork,
    apid: (state): string | null => state.apid,
    buildingId: (state): string | null => state.buildingId,
    RoomNumber: (state): string | null => state.RoomNumber,
  },
};
