import { UaTypeValue } from "@/shared/const/service-type";

/** five-a お申し込み 入力フォーム */
export class FiveAEntryInputForm {
  public uaType!: UaTypeValue; // five-a の uaType = '2'
  public propertyId!: string;
  public buildingId!: string;
  public roomNumber!: string;
  public nameSurname!: string;
  public nameFirstName!: string;
  public kanaSurname!: string;
  public kanaFirstName!: string;
  public birthday!: string;
  public phoneNumber!: string;
  public emergencyPhoneNumber?: string;
  public emailAddress!: string;
  public scheduledMoveInOn?: string;
  public destinationKind!: string;
  public destinationZipcode!: string;
  public destinationAddress1!: string;
  public destinationAddress2?: string;
  public destinationTelephone!: string;
  public desiredAccount!: string;
  public creditCardToken?: string;
  public tokenExpireDate?: string;
  public electronicdelivery!: string;
  public courseId!: string;
  public password!: string;
  public sdwan!: string;

  constructor(partial: Partial<FiveAEntryInputForm>) {
    Object.assign(this, partial);
  }
}

/** five-a お申し込み Connectix 料金 */
export class FiveAConnectixFee {
  /** Connectix 利用金額 */
  public connectixUsageFee!: string;
  /** Connectix キャンペーン利用金額 */
  public connectixUsageFeeCp!: string;

  constructor(partial: Partial<FiveAConnectixFee>) {
    Object.assign(this, partial);
  }
}
