<template>
  <div class="ucom-connectix-terms">
    <LoadingComponent v-if="isMounting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み（規約）</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス Connectix お申し込み</li>
        <li>規約</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:Connectix サービス規約 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix サービス規約" />Connectix&thinsp;サービス規約</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" v-bind:isOneSentence="isErrorMessageOneSentence" />
        <div class="terms-box pd15 mb15 border-grey">
          <h3 class="underlayer-h2">Connectixサービス規約（UCOM光&thinsp;レジデンス&emsp;オプションサービス）</h3>

          <DisplayConnectixTermsOfService
            :termsOfService="termsOfServiceUcomData.termsOfService"
            :supplementaryProvisions="termsOfServiceUcomData.supplementaryProvisions"
            :priceList="termsOfServiceUcomData.priceList"
            :serviceSupplyCompanyName="serviceSupplyCompanyName"
          />
        </div>
        <label><input type="checkbox" v-model="isAgreeTerms" /><b>&thinsp;Connectixサービス規約に同意する</b></label>
      </div>
      <div class="blc">
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" type="button" v-on:click="onApplication()" :class="buttonColorSet" :disabled="!activeApplyButton">
            規約に同意してお申し込み<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}

.underlayer-main h1 {
  padding: 10px 0 10px 160px;
  display: inline;
  background-image: url('../../../images/logo-ucom.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}

.connectix-campaign-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.connectix-campaign {
  padding: 15px 0px 20px 0px;
  margin-bottom: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** Entity UCOM 共通利用 API : 契約基本情報取得 : レスポンス */
import { UcomCustomerResponse } from '../../../shared/classes/external-api/ucom/customer-response';
/** Entity UCOM 共通利用 API : 物件基本情報取得 : レスポンス */
import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import DisplayConnectixTermsOfService from '@/shared/components/connectix/display-terms-of-service.vue';
import { CONNECTIX_TERMS_OF_SERVICE_UCOM_DATA } from '@/shared/terms-of-service/connectix-terms-of-service-ucom-data';

/** UCOM光レジデンス Connectixお申し込み サービス規約画面 */
export default defineComponent({
  name: 'ucom-connectix-terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
    DisplayConnectixTermsOfService,
  },
  data: () => ({
    /** サービス利用規約に同意するチェック、お申し込みボタン 表示/非表示 */
    availableApply: true,
    /** サービス利用規約に同意する */
    isAgreeTerms: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、お申し込み手続きを進めることができません。',
    /** UCOM 物件 ID */
    ucomPropertyId: '',
    /** UCOM 会員 ID */
    ucomMemberId: '',
    // vueのmounted実行中かどうか
    isMounting: true,
    // Connectixサービスイン前エラー用に1文エラーにするかどうか
    isErrorMessageOneSentence: false,
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: '',
    // 利用規約のデータ
    termsOfServiceUcomData: CONNECTIX_TERMS_OF_SERVICE_UCOM_DATA,
    // 規約に表示するサービス提供企業名
    serviceSupplyCompanyName: '株式会社つなぐネットコミュニケーションズ',
    // ボタン押下中かどうか
    isSubmitting: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // 入力値を保持しているストアの削除
    this.$store.commit('ucomConnectixEntryStore/entryInputForm', null);

    // UCOM の会員であるかの条件を確認
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    // 会員情報をStoreから取得
    const member: Member = this.$store.getters['memberStore/member'];
    // 会員情報（member テーブル）の primary_key_ucom が null の場合は UCOM  会員ではないと判断
    if (!member.primaryKeyUcom) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    /** UCOM 物件基本情報を取得 */
    try {
      // Auth0ユーザのapp_metadataからUCOMの物件IDを取得する
      this.ucomPropertyId = await AuthService.getUcomPropertyId();

      const property: Promise<UcomPropertyResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/property', this.ucomPropertyId);
      if (property instanceof UcomPropertyResponse) {
        // 正常ケースの場合
        this.serviceSupplyCompanyName = this.getServiceSupplyCompanyName(property.service_provid_former.service_provid_former_id);

        let start_date = new Date(property.connectix_start_date);
        let today = new Date();
        // Connectixの提供開始日が未来日付であるかチェック
        if (today < start_date) {
          // 利用規約同意チェックとお申し込みボタン使用不可
          this.availableApply = false;
          this.isErrorMessageOneSentence = true;
          this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0);
          return;
        }
      } else if (property instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }

    /** UCOM 契約基本情報を取得 */
    try {
      this.isMounting = true;

      // UCOM のメンバー ID をStoreから取得する
      this.ucomMemberId = await AuthService.getUcomMemberId();
      const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.ucomMemberId);

      // 正常ケースの場合
      if (customer instanceof UcomCustomerResponse) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

        /**
         * ISP(ucom)会員ステータスが次の場合、エラーメッセージを表示する
         *
         * ・ ISP(ucom)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.isErrorMessageOneSentence = true;
          this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0);
          return;
        }

        // Connectixにお申し込み済みかどうかのチェック
        if (customer.connectix_status) {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.errorMessages.push('お客様は既にConnectixを契約しています。');
          window.scrollTo(0, 0); // エラーメッセージを見せるために画面最上部にスクロール
          return;
        }
        if (!customer.connectix_offer) {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0); // エラーメッセージを見せるために画面最上部にスクロール
          return;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }
  },
  methods: {
    /** 「規約に同意してお申し込み ➡」ボタン押下時 : お申し込み画面に遷移する */
    async onApplication() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージを初期化
      this.errorMessageTitle = '';
      this.errorMessages = [];
      this.isErrorMessageOneSentence = false;

      // ISP(ucom)会員ステータスが退会済みログイン可能のとき、エラーメッセージを表示させる
      if (!(await this.canApply())) {
        this.isErrorMessageOneSentence = true;
        this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        window.scrollTo(0, 0);
        this.isSubmitting = false;
        return;
      }

      // 「サービス利用規約に同意する」がチェックされている場合はお申し込み画面に遷移する。
      if (this.isAgreeTerms) {
        await this.$router
          .push('/connectix/ucom/apply')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = false;
        this.errorMessages.push('「Connectix サービス規約に同意する」にチェックを入れてください。');
      }
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/ucom')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async canApply() {
      try {
        this.ucomMemberId = await AuthService.getUcomMemberId();
        const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.ucomMemberId);

        if (customer instanceof UcomCustomerResponse) {
          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

          /**
           * ISP(ucom)会員ステータスが次の場合、Connectix申し込み不可と判定する
           *
           * ・ ISP(ucom)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
            return false;
          }
        } else if (customer instanceof UcomSharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
        }
      } catch (e) {
        throw e;
      }
      return true;
    },
    getServiceSupplyCompanyName(serviceProvidFormerId: string): string {
      if (serviceProvidFormerId === '1') {
        return '株式会社つなぐネットコミュニケーションズ';
      } else if (serviceProvidFormerId === '2') {
        return 'アルテリア・ネットワークス株式会社';
      }
      return '株式会社つなぐネットコミュニケーションズ';
    },
  },
  computed: {
    buttonColorSet() {
      if (this.availableApply && this.isAgreeTerms) {
        return 'btn btn01 bs';
      }
      return 'btn btn04 bs';
    },
    activeApplyButton() {
      if (this.availableApply && this.isAgreeTerms) {
        return true;
      }
      return false;
    },
  },
});
</script>
