<template>
  <div class="link-service">
    <LoadingComponent v-if="isDisplayLoading || isExecutingNavigateToLocation" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Connectix お申し込み</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc -->
      <div class="blc">
        <ErrorMessagesComponent v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <!-- blc:Five.A Connectixお申し込み -->
      <div class="blc">
        <p>お客様がお住まいの建物はFive.Aのご提供物件です。</p>
        <div class="sblc">
          <h3 class="service-h3">Connectixお申し込み</h3>
          <p>UCOM光 レジデンス Five.Aのオプションサービス「Connectix」をご希望の場合は、お申し込みボタンをクリックしてください</p>
          <div class="btn-area">
            <button type="button" v-bind:class="buttonColorSet" v-bind:disabled="!isMounted" @click="navigateToLocation('/5a/entry/terms')">
              Connectix お申し込みへ<i class="material-icons link link-icon">east</i>
            </button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- contents -->
  </div>
</template>

<style lang="scss" scoped>
/* form-btn-txt
---------------------------------------------------- */
.form-btn-txt {
  text-align: center;
}

/* terms-box
---------------------------------------------------- */
.terms-box {
  height: 240px;
  overflow: auto;
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}
</style>

<script lang="ts">
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

/** LinkService コンポーネント */
export default defineComponent({
  name: 'link-service',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件情報 */
    fiveAProperty: null as FiveAProperty | null,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** 読み込み完了 */
    isMounted: false,
    // mounted() 終わるまで、loading-componentを表示
    isDisplayLoading: true,
    // navigateToLocation() 実行中かどうか
    isExecutingNavigateToLocation: false,
    availableApply: false,
  }),
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isMounted,
        'btn btn01 bs': this.isMounted,
      };
    },
  },
  async mounted() {
    // エラーメッセージ格納配列初期化
    this.errorMessages = [];

    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      const member: Member = this.$store.getters['memberStore/member'];

      // 以下物件情報のダミーデータ
      const property: Property = this.$store.getters['propertyStore/property'];
      // Portas会員でない、または顧客情報にFive.A物件情報が紐付けられていない場合はエラー画面へ
      if (!member.id || !member.propertyId || property.uaType !== UA_TYPE.FIVE_A) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/error').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 物件情報取得処理
      const params = { propertyId: property.apartmentId, uaType: property.uaType };
      const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);

      if (fiveAProperty instanceof FiveASharedErrorResponse) {
        // エラー画面に遷移
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/error').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.$store.commit('fiveACommonStore/property', fiveAProperty);
      this.fiveAProperty = fiveAProperty;

      // サービスイン日のチェック
      // 物件情報取得APIの「サービスイン日（em_sin_time）」が未来日付の場合、エラーメッセージを表示して申し込めないようにする
      if (this.isPrevServiceInDay(this.fiveAProperty.em_sin_time)) {
        // 規約と通知に同意するチェックとお申し込みボタン使用不可
        this.availableApply = false;
        // エラーメッセージを設定し、画面最上部にスクロール
        this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        window.scrollTo(0, 0);
        return;
      }
    } catch (e) {
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    } finally {
      this.isDisplayLoading = false;
    }
    // 読み込み完了までボタンを非活性化
    this.isMounted = true;
  },
  methods: {
    // サービスイン日が未来日か否かを判断する
    // 形式は、em_sin_time: '20220406 00:00:00'
    isPrevServiceInDay(serviceInDate: string) {
      const today = new Date();
      const serviceInDay = new Date(parseInt(serviceInDate.slice(0, 4), 10), parseInt(serviceInDate.slice(4, 6), 10) - 1, parseInt(serviceInDate.slice(6, 8), 10));
      return today < serviceInDay;
    },
    async navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      await this.$router
        .push(path)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isExecutingNavigateToLocation = false;
        });
    },
  },
});
</script>
