<template>
  <div class="remaining-work-external-room-number-input">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>宅内工事確認 部屋番号入力</h1>
    </main>

    <div class="contents">
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li class="stay">入力</li>
        <li>確認結果</li>
      </ul>
      <!-- /application-flow -->
      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <p class="p-bold">お住まいのお部屋番号を入力してください。</p>
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">部屋番号<span class="req">必須</span></th>
              <td>
                <div v-if="uaType === '2'">
                  <input type="text" class="text short" placeholder="101" v-model="roomNumberFiveA" />
                  <p class="room-unit">号室</p>
                </div>
                <div v-else-if="uaType === '3'">
                  <input type="text" class="text short" placeholder="101" v-model="roomNumberUcom" />
                  <p class="room-unit">号室</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onNext" :class="isButtonActive ? 'btn btn01 bs' : 'btn btn05 bs'" :disabled="!isButtonActive">
            次へ進む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.application-flow {
  padding: 0;
}

.p-bold {
  font-weight: bold;
  margin-bottom: 30px;
}

.room-unit {
  display: inline;
  margin-left: 5px;
}

.btn-area {
  margin: 100px auto 0;
  text-align: center;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { RemainingWorkConfirmDto } from '@/shared/classes/spf-api/remaining-work-confirm-dto';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { convert8DigitIdTo6digitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { halfWidth, roomNumberAllowHyphen } from '@/shared/util/validators';

export default defineComponent({
  name: 'external-room-number-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    roomNumberFiveA: '', // Five.A 部屋番号
    roomNumberUcom: '', // UCOM 部屋番号
    isLoading: false, // ローディング
    apid: '', // apid
    buildingId: null as string | null, // 遷移元から取得した物件ID
    uaType: null as string | null, // 遷移元のUA種別
    transitionSourceServiceId: null as string | null, // 物件ID
    remainingWorkResult: {} as RemainingWorkConfirmDto, // 残工事の有無
    isLogin: false, // ログイン状態
    isButtonActive: true, // ボタン押下
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
  }),
  /** バリデーション定義 */
  validations: {
    roomNumberFiveA: {
      required, //必須
      halfWidth, // 半角英数字
      maxLength: maxLength(8), // 8文字以内
    },
    roomNumberUcom: {
      required, //必須
      roomNumberAllowHyphen, // 半角英数字記号(-)
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    this.isLoading = true;
    /** 遷移元サービス物件 ID をクエリパラメータから取得 */
    if (!this.$route.query.apid) {
      this.errorMessages.push('物件情報を取得できませんでした。');
      this.isButtonActive = false;
      window.scrollTo(0, 0);
      this.isLoading = false;
      return;
    }

    this.apid = this.$route.query.apid as string;

    if (!(this.apid.length === 8 || this.apid.length === 13)) {
      this.errorMessages.push('物件情報を取得できませんでした。');
      this.isButtonActive = false;
      window.scrollTo(0, 0);
      this.isLoading = false;
      return;
    } else if (this.apid.length === 8) {
      // APID → apartment_idに変換
      this.transitionSourceServiceId = convert8DigitIdTo6digitId(String(this.apid));
      // apartment_id が6桁でない場合
      if (String(this.transitionSourceServiceId.length) !== '6') {
        this.errorMessages.push('物件情報を取得できませんでした。');
        this.isButtonActive = false;
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      this.uaType = UA_TYPE.FIVE_A;
    } else if (this.apid.length === 13) {
      this.uaType = UA_TYPE.UCOM;
    }
    this.isLoading = false;
  },
  methods: {
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorMessages = [];
      // 部屋番号バリデーションチェック
      if (this.uaType === UA_TYPE.FIVE_A && this.v$.roomNumberFiveA.$invalid) {
        // 必須チェック
        if (this.v$.roomNumberFiveA.required.$invalid) {
          this.errorMessages.push(`お部屋番号を入力してください。`);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        if (this.v$.roomNumberFiveA.halfWidth.$invalid || this.v$.roomNumberFiveA.maxLength.$invalid) {
          this.errorMessages.push(`お部屋番号を半角英数字で入力してください。`);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } else if (this.uaType === UA_TYPE.UCOM && this.v$.roomNumberUcom.$invalid) {
        // 必須チェック
        if (this.v$.roomNumberUcom.required.$invalid) {
          this.errorMessages.push(`お部屋番号を入力してください。`);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        if (this.v$.roomNumberUcom.roomNumberAllowHyphen.$invalid) {
          this.errorMessages.push(`お部屋番号を半角英数字記号(-)で入力してください。`);
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      }

      try {
        // 残工事有無確認・受付ステータス更新 APIを実行する
        // 遷移元がFive.Aだった場合
        if (this.transitionSourceServiceId && this.uaType === UA_TYPE.FIVE_A) {
          this.remainingWorkResult = await SpfApiService.remainingWorkConfirm(this.uaType, this.transitionSourceServiceId, this.roomNumberFiveA, this.isLogin);
          if (this.remainingWorkResult.isRemainingWork == null) {
            this.errorMessages.push('システムエラーが発生いたしました。お手数ではございますが、時間をおいて再度お試しください。');
            window.scrollTo(0, 0);
            this.isLoading = false;
            return;
          }
          // 遷移元がUCOMだった場合
        } else if (this.uaType === UA_TYPE.UCOM) {
          this.remainingWorkResult = await SpfApiService.remainingWorkConfirm(this.uaType, this.apid, this.roomNumberUcom, this.isLogin);
          if (this.remainingWorkResult.isRemainingWork == null) {
            this.errorMessages.push('システムエラーが発生いたしました。お手数ではございますが、時間をおいて再度お試しください。');
            window.scrollTo(0, 0);
            this.isLoading = false;
            return;
          }
        } else {
          this.errorMessages.push('物件情報を取得できませんでした。');
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } catch (error: any) {
        const status: number = error.response?.status;
        if (status === 400) {
          this.errorMessages.push('物件情報を取得できませんでした。');
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        } else {
          // 共通エラーに遷移
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }

      // 残工事の有無, apid, buildingId をストアに保存
      this.$store.commit('remainingWorkStore/remainingWork', this.remainingWorkResult.isRemainingWork);
      if (this.uaType === UA_TYPE.FIVE_A) {
        this.$store.commit('remainingWorkStore/apid', this.apid);
      } else if (this.uaType === UA_TYPE.UCOM) {
        this.$store.commit('remainingWorkStore/buildingId', this.apid);
      }

      //画面遷移処理
      await this.$router.push('/remaining-work/external/result').catch((e: any) => {
        // URL変更予定
        checkRouterError(e);
      });
      this.isLoading = false;
    },
  },
});
</script>
