import { ConnectixTermsOfService, PriceList, SupplementaryProvisions } from './connectix-terms-of-service.entity';

const CONNECTIX_TERMS_OF_SERVICE_DATA: ConnectixTermsOfService = {
  No1: {
    index: '第1条',
    title: '規約の適用',
    paragraphList: [
      {
        text: 'Connectixサービス利用規約（以下「本規約」といいます。）は、株式会社つなぐネットコミュニケーションズ（以下「当社」といいます。）が提供する本サービスの利用に関し適用されるものとします。'
      },
      {
        text: '本サービスに関し、本規約に定める内容と当社が別途定める個別規定の内容が異なる場合は、当該個別規定の内容が適用されるものとします。'
      }
    ]
  },
  No2: {
    index: '第2条',
    title: '規約の変更',
    paragraphList: [
      {
        text: '当社は、当社所定の方法にて通知または公表することにより、本規約の全部または一部を変更することができるものとします。この場合、変更後の規約が適用されるものとします。'
      }
    ]
  },
  No3: {
    index: '第3条',
    title: '定義',
    paragraphList: [
      {
        text: '本規約においては、次の用語はそれぞれ次の意味で使用します。'
      }
    ],
    wordAndDefinitionList: [
      {
        word: '本サービス',
        definition:
          '当社が対象物件において提供するマンション全戸一括型インターネット接続サービスの利用において、加入契約の対象となる集合住宅の専有部分、賃貸住戸部分その他特定の共用部分における通信を、他の集合住宅の専有部分および賃貸住戸部分における通信と比べて優先させるサービス'
      },
      {
        word: '対象物件',
        definition: 'マンション全戸一括型インターネット接続サービスの提供を受けている集合住宅のうち、当社の指定する集合住宅'
      },
      {
        word: 'マンション全戸一括型インターネット接続サービス',
        definition: '集合住宅全戸を対象として一括して電気通信役務に係る契約を締結し、提供を受けることができるインターネット接続サービスであり、以下のものを指す。',
        exampleList: ['（ア） e-mansionインターネットサービス(当社が提供するインターネット接続サービス)', '（イ） 当社が指定する協定事業者が提供するインターネット接続サービス']
      },
      {
        word: '加入契約',
        definition: '本サービスが提供されている集合住宅の区分所有者、賃借人その他の利用権を有する居住者、または入居者が、本サービスの利用に関して当社と締結する契約'
      },
      {
        word: '契約者',
        definition: '当社と加入契約を締結している者'
      },
      {
        word: '利用者',
        definition: '本サービスを利用する者'
      },
      {
        word: '個別規定',
        definition: '本サービスの利用に関して、当社が別途定める規定'
      },
      {
        word: '個人情報',
        definition:
          '個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述、または個人別につけられた番号、記号その他の符号、画像もしくは音声によって当該個人を容易に識別できるもの（当該情報のみでは識別できないが、他の情報と容易に照合することができ、それにより当該個人を識別できるものを含みます。）'
      }
    ]
  },
  No4: {
    index: '第4条',
    title: '本サービスの内容',
    paragraphList: [
      {
        text: '本サービスの内容は、料金表に規定する通りとします。'
      }
    ]
  },
  No5: {
    index: '第5条',
    title: '加入契約の単位',
    paragraphList: [
      {
        text: '当社は、対象物件の専有部分、賃貸住戸部分その他特定の共用部分ごとに1つの加入契約を締結します。'
      },
      {
        text: '加入契約は、対象物件の専有部分、賃貸住戸部分その他特定の共用部分のみを対象とし、対象物件以外の住戸を対象として締結することはできません。'
      }
    ]
  },
  No6: {
    index: '第6条',
    title: '加入契約の申し込み',
    paragraphList: [
      {
        text: '加入契約の申し込みは、当社指定の会員登録後、本規約を承諾の上、当社所定の方法により行うものとします。'
      }
    ]
  },
  No7: {
    index: '第7条',
    title: '加入契約申し込みの承諾',
    paragraphList: [
      {
        text: '当社は、加入契約の申し込みがあった場合は、受け付けた順序に従って承諾し、当該申し込みを承諾するときは、当社所定の方法により加入契約申し込み者に通知します。ただし、当社の業務の遂行上支障があるときは、この限りではありません。'
      },
      {
        text: '当社は、次の各号の場合には、加入契約の申し込みを承諾しないことがあります。',
        subParagraphList: [
          '加入契約の申し込みの際に虚偽の事実を通知したことが判明したとき。',
          '加入契約申し込み者が、本サービスの料金その他債務の支払を現に怠り、または怠る恐れがあると当社が判断したとき。',
          '第24条（契約者の義務）の規定に違反する恐れがあるとき。',
          '当社の電気通信サービスにおいて、過去に不正使用等により契約の解除または利用を停止されている事が判明した場合。',
          '加入契約申し込み者が暴力団、暴力団員、暴力団関係者、暴力団関係団体、関係者、その他反社会的勢力(以下、「暴力団等」といいます)、公共の福祉に反する活動を行う団体、およびその行為者である場合、または、反社会的勢力であったと判明した場合。',
          'その他当社の業務の遂行上著しい支障があるとき、または支障を及ぼす恐れがあると当社が判断したとき。'
        ]
      },
      {
        text: '当社は、前項の規定により、加入契約の申し込みを承諾しないときは、加入契約申し込み者にその理由等を当社所定の方法で通知します。'
      }
    ]
  },
  No8: {
    index: '第8条',
    title: '契約内容の変更等',
    paragraphList: [
      {
        text: '契約者は、その氏名または連絡先、住所等に変更があった場合は、そのことを速やかに当社所定の方法により、当社に届け出ていただきます。'
      },
      {
        text: '前項の届出があったときは、当社は、その届出のあった事実を証明する書類を提示していただくことがあります。'
      },
      {
        text: '当社は、第1項の変更の届出があった場合は、第7条（加入契約申し込みの承諾）の規定に準じて取り扱います。'
      },
      {
        text: '当社は契約者に対し、契約者の契約内容、第1項の届出内容を確認させていただく場合があります。'
      },
      {
        text: '第1項の届出において、契約者が本サービスを提供している集合住宅から退去、転居し、住所を変更する場合、加入契約は終了するものとします。なお、加入契約の終了時においては、第10条（契約者が行う加入契約の解除）に定める手続きが必要となります。'
      }
    ]
  },
  No9: {
    index: '第9条',
    title: '利用権の譲渡',
    paragraphList: [
      {
        text: '利用権（契約者が、加入契約に基づいて本サービスの提供を受ける権利をいいます。以下同じとします。）の譲渡は、当社所定の方法により請求するものとし、当社の承認を受けなければ、その効力を生じません。'
      },
      {
        text: '当社は、前項の請求があったときには、第7条（加入契約申し込みの承諾）の規定に準じて取り扱います。'
      },
      {
        text: '利用権の譲渡があったときは、譲受人は、契約者の有していた一切の権利および義務を承継します。'
      }
    ]
  },
  No10: {
    index: '第10条',
    title: '契約者が行う加入契約の解除',
    paragraphList: [
      {
        text: '契約者は、加入契約を解除しようとするときは、当社に当社所定の方法により通知していただきます。当社は、契約者からの解除の通知を受領した場合、加入契約を解除し、本サービスの提供を終了します。'
      },
      {
        text: '前項の加入契約の解除の通知があった場合は、当社は第7条（加入契約申し込みの承諾）の規定に準じて取り扱います。'
      }
    ]
  },
  No11: {
    index: '第11条',
    title: '当社が行う加入契約の解除',
    paragraphList: [
      {
        text: '当社は、契約者が本サービスの料金の支払いを遅延した場合、その他本規約の定めに違反し、相当の期間をもって催告を受けたにもかかわらず、違反を解消しなかった場合、加入契約を解除することがあります。'
      },
      {
        text: '当社は、契約者が以下の事由に該当した場合に加入契約を解除することができます。',
        subParagraphList: [
          '契約者が、暴力団等、公共の福祉に反する活動を行う団体、およびその行為者である場合、または、反社会的勢力であったと判明した場合。',
          '契約者自ら、または第三者を利用して、当社の業務を妨害した場合、または、妨害するおそれのある行為をした場合。',
          '契約者自ら、または第三者を利用して、当社に対して、暴力的行為、詐術、脅迫的言辞を用いるなどした場合。',
          '契約者自ら、または第三者を利用して、当社の名誉、信用等を毀損し、又は、毀損するおそれのある行為をした場合。',
          '契約者自ら、または第三者を利用して、自身や、その関係者が暴力団等である旨を当社に認知させるおそれのある言動、態様をした場合。'
        ]
      },
      {
        text: '当社は、対象インターネット接続サービスが解除された場合、加入契約を解除します。'
      },
      {
        text: '当社は、契約者が第8条（契約内容等の変更）1項に定める届出を怠り、同条第5項に定める事由に該当することが判明した場合、予め契約者に通知のうえ、加入契約を解除します。ただし、契約者への通知が困難な場合、および同一の専有部分および賃貸住戸部分につき加入契約が重複して成立し、先行する加入契約を解除すべき合理的理由がある場合、当社は何ら通知を要せず加入契約を解除することができます。'
      },
      {
        text: '当社は、技術上その他の理由で本サービスを提供することが著しく困難になった場合は、その加入契約を解除することがあります。'
      }
    ]
  },
  No12: {
    index: '第12条',
    title: 'マンション全戸一括型インターネット接続サービス',
    paragraphList: [
      {
        text: '本サービスの利用に際して、その前提となるマンション全戸一括型インターネット接続サービスによる通信環境、または当該サービスに係る設備の利用に関連する各種条件（利用停止、利用中止、利用の制限その他契約者の義務など）は、マンション全戸一括型インターネット接続サービスに適用される契約約款、利用規約その他契約の内容が適用されます。'
      }
    ]
  },
  No13: {
    index: '第13条',
    title: '契約者設備等の準備および接続',
    paragraphList: [
      {
        text: '契約者は、通信機器、ソフトウェア、その他これらに付随して必要となる全ての機器（以下「契約者設備等」といいます。）の準備、設置、接続、設定および保守その他本サービスを利用するために必要な準備を、自己の費用と責任において行うものとします。'
      },
      {
        text: '契約者は、自己の費用と責任により、当社または関係官庁等が提供する情報に基づき、自己の利用環境に応じ、コンピュータ・ウィルスの感染、不正アクセスおよび情報漏洩の防止等セキュリティを保持するものとします。不正アクセスの防止については、総務省が規定する「不正アクセス行為の禁止等に関する法律 (平成十一年八月十三日法律第百二十八号)」に準拠して警察庁等が提案している防御措置および予防策等を参考に行うものとします。'
      },
      {
        text: '当社は、第１項に規定する契約者設備等の接続を、次の場合を除き承諾します。',
        subParagraphList: [
          'その接続が本サービスおよび対象インターネット接続用通信設備に障害を与えると当社が判断したとき。',
          'その接続が本サービスを利用する他の契約者に迷惑を及ぼすと当社が判断したとき。'
        ]
      }
    ]
  },
  No14: {
    index: '第14条',
    title: '利用制限',
    paragraphList: [
      {
        text: '当社は、天災、事変その他の非常事態が発生し、または発生するおそれがある場合で必要と認めたときは、それらの予防もしくは救援、交通、通信、電力供給の確保および秩序の維持のために必要な事項を内容とする通信および公共の利益のため緊急を要する事項を内容とする通信を優先的に取り扱うため、本サービスの利用を制限することがあります。'
      },
      {
        text: '当社は、契約者または当該契約者以外の本サービスの利用者の通信回線において、当社の電気通信設備において取り扱う通信に比し、過大と認められる通信が発生した場合や、当社の電気通信設備の容量を逼迫させる、もしくは逼迫させる恐れを生じさせた場合、または、他の契約者、利用者における通信の品質と効率を低下させる利用を行ったと当社が認めた場合、本サービスによって利用できる通信の帯域制限および通信の停止を行うことがあります。'
      }
    ]
  },
  No15: {
    index: '第15条',
    title: '利用中止',
    paragraphList: [
      {
        text: '当社は、次の場合には、当該契約者に係る本サービスの利用を中止することがあります。',
        subParagraphList: [
          '本サービスに係る電気通信設備の保守上または工事上やむを得ない場合。',
          'その他、マンション全戸一括型インターネット接続サービスに係る設備上一時的な利用の中止が必要と判断された場合。'
        ]
      },
      {
        text: '当社は、前項の規定により、本サービスの利用を中止するときは、原則としてそのことを契約者に通知します。ただし、緊急やむを得ない場合は、この限りではありません。'
      }
    ]
  },
  No16: {
    index: '第16条',
    title: '料金',
    paragraphList: [
      {
        text: '当社が提供する本サービスの料金は、別途定める料金表に規定するところによります。'
      }
    ]
  },
  No17: {
    index: '第17条',
    title: '料金等の支払義務',
    paragraphList: [
      {
        text: '契約者は、別途定める料金表に規定する料金の支払いを要します。'
      }
    ]
  },
  No18: {
    index: '第18条',
    title: '料金の支払方法',
    paragraphList: [
      {
        text: '契約者は、別途定める料金表の規定に基づく料金を、次の各号に定める方法により支払いを行うものとします。',
        subParagraphList: ['当社が定めるクレジット会社のクレジットカードによる支払い。', 'その他当社が定める支払い方法。']
      },
      {
        text: '契約者は、第16条（料金）に定める料金の請求及び回収業務を、当社または当社が料金回収業務を委託する事業者が行うことに同意いただきます。'
      }
    ]
  },
  No19: {
    index: '第19条',
    title: '債権の譲渡',
    paragraphList: [
      {
        text: '当社は、別途定める料金表に規定する料金その他の債務に係る債権の全部または一部を第三者に譲渡することがあります。'
      },
      {
        text: '当社は、前項の規定により債権の譲渡を行う場合は、あらかじめ当社所定の方法によりその契約者に対して通知します。'
      }
    ]
  },
  No20: {
    index: '第20条',
    title: '割増金および遅延損害金',
    paragraphList: [
      {
        text: '契約者は、料金その他の債務の支払いを不法に免れた場合は、その免れた額のほか、その免れた額（消費税相当額を加算しない額とします。）の2倍に相当する額に消費税相当額を加算した額を割増金として支払っていただきます。'
      },
      {
        text: '契約者は、料金その他の債務（遅延損害金を除きます。）について支払い期日を経過してもなお支払いがない場合には、支払い期日の翌日から支払いの日の前日までの日数について、年14.5%の割合で計算して得た額を遅延損害金として支払っていただきます。',
        subText: 'ただし、支払い期日の翌日から起算して10日以内に支払いがあった場合は、この限りでありません。'
      }
    ]
  },
  No21: {
    index: '第21条',
    title: '損害賠償',
    paragraphList: [
      {
        text: '当社は、本サービスを提供すべき場合において、当社の責めに帰すべき理由によりその提供をしなかったときは、本サービスが全く利用できない状態（本サービスの利用に関し著しい支障が生じ、全く利用できない状態と同程度になる場合を含みます。）にあることを当社が知った時刻から起算して、24時間以上その状態が連続したときに限り、その契約者の損害を賠償します。'
      },
      {
        text: '前項の場合において、当社は、本サービスが全く利用できない状態にあることを当社が知った時刻（契約者が居住する集合住宅の取り決め等により、本サービスの復旧が24時間以内に実施できない場合は、本サービスの復旧が可能となった時刻）以後その状態が連続した時間（24時間の倍数である部分に限ります。）について、24時間ごとに日数を計算し、その日数に対応する基本利用料の合計額を発生した損害とみなし、その額を上限として賠償するものとします。なお、当該賠償については、基本利用料からの減額にて応じます。'
      },
      {
        text: '当社の故意又は重大な過失により本サービスの提供をしなかったときは、前2項の規定は適用しません。'
      },
      {
        text: '天災、事変その他の不可抗力により、当社が本サービスを提供できなかったときは、当社はその損害について一切の責任を負わないものとします。'
      },
      {
        text: '前3項の規定にかかわらず、損害賠償の取り扱いについて、別途定める料金表および個別規定に特段の定めがある場合は、その定めるところによります。'
      },
      {
        text: '第1項、第2項の規定に基づき行う賠償は、本サービスの復旧から3ヶ月以内に契約者からの請求があった場合に限り行います。'
      }
    ]
  },
  No22: {
    index: '第22条',
    title: '免責',
    paragraphList: [
      {
        text: '当社は、本サービスに関して、本規約に特段の定めがある場合を除き、通信品質、通信速度、可用性、有用性その他契約者における本サービスの利用目的の達成について、一切保証しません。'
      },
      {
        text: '当社は、契約者が本サービスを利用することにより得た情報またはソフトウェア等については、その完全性、正確性、有用性または適法性を保証しません。'
      },
      {
        text: '当社は、契約者が本サービスを利用するにあたり、契約者が利用するコンピュータ機器、通信機器の動作、機能、設定等については保証しません。'
      },
      {
        text: '当社は、契約者が本サービスを利用することにより他人との間で生じた紛争等に関して、一切責任を負わないものとします。'
      },
      {
        text: '当社は、本サービスの利用に際して、契約者が本サービスを利用するために接続する端末設備について、その設定、環境が本サービスに適合することを保証するものではなく、当該端末設備について何ら設定の変更、更新、購入を要さないことを保証するものではありません。'
      }
    ]
  },
  No23: {
    index: '第23条',
    title: '契約者への通知',
    paragraphList: [
      {
        text: '当社は、電子メールによる送信、ホームページへの掲載その他当社が適当であると判断する方法により、契約者に随時必要な事項を通知するものとします。'
      }
    ]
  },
  No24: {
    index: '第24条',
    title: '契約者の義務',
    paragraphList: [
      {
        text: '契約者は、次の各号に定める事項を遵守していただきます。',
        subParagraphList: [
          '本サービスの利用にあたって本邦内外の法令等の定めに反しないこと。',
          '本サービスの利用とその利用によりなされた全ての行為（契約者本人による利用および行為とみなされる第三者の利用や行為ならびに契約者が設定したプライベート機能を利用して、第三者が行う情報の発信を含みます。）とその結果について管理責任を負うこと。'
        ]
      },
      {
        text: '本サービスの利用にあたって次の行為を行わないこと。',
        subParagraphList: [
          '当社もしくは他人の知的財産所有権（特許権、実用新案、著作権、意匠権、商標権等）その他の権利を侵害する行為または侵害する恐れのある行為（著作権侵害防止のための技術的保護手段を回避するための情報、機器またはソフトウェア等を流通させる行為を含みます。）',
          '他人の財産、プライバシーもしくは肖像権を侵害する行為または侵害する恐れのある行為。',
          '他人を差別若しくは誹謗中傷し、またはその名誉もしくは信用を毀損する行為。',
          '詐欺、業務妨害等の犯罪行為またはこれを誘発もしくは扇動する行為。',
          '違法な薬物、銃器、毒物または爆発物等の禁制品の製造、販売または入手に係る情報を送信または表示する行為。',
          '賭博、業務妨害等の犯罪の手段として利用し、または犯罪を助長もしくは誘発する恐れのある情報を送信または表示する行為。',
          'わいせつ、児童ポルノもしくは児童虐待にあたる画像、映像、音声もしくは文章等を送信または表示する行為またはこれらを収録した媒体を販売もしくはその送信、表示および販売を想起させる広告を表示または送信する行為。',
          'ストーカー行為等の規制等に関する法律（平成十二年法律第八十一号）に違反する行為。',
          '無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為。',
          'インターネット異性紹介事業を利用して児童を誘引する行為の規制等に関する法律（平成十五年六月十三日法律第八十三号）に違反する行為。',
          '本サービスにより利用しうる情報を改ざんし、または消去する行為。',
          '他人になりすまして本サービスを利用する行為（偽装するためにメールヘッダ等の部分に細工を行う行為を含みます。）',
          '有害なコンピュータプログラム等を送信し、または他人が受信可能な状態のまま放置する行為。',
          '公職選挙法（昭和二十五年四月十五日法律第百号）に違反する行為。',
          '他人に対し、無断で、広告・宣伝もしくは勧誘等の電子メールまたは他人が嫌悪感を抱くもしくはその恐れのある電子メールを送信する行為。',
          '他人の電子メールの受信を妨害する行為。',
          '連鎖的な電子メールの転送を依頼または依頼に応じて転送する行為。',
          '特定電子メールの送信の適正化等に関する法律（平成十四年四月十七日法律第二十六号）に違反する行為。',
          '他社の設備または当社通信設備（当社が各種インターネットサービスを提供するために用意する通信設備、電子計算機、その他の機器およびソフトウェアをいい、以下同様とします。）に無権限でアクセスし、または大量のメール若しくはメッセージ送信等により、その利用若しくは運営に支障を与える行為（与える恐れのある行為を含みます。）',
          '他社の設備または当社通信設備のアクセス制御機能を解除または回避するための情報、機器、ソフトウェア等を流通させる行為。',
          '本人の同意を得ることなく、または詐欺的な手段（フィッシング詐欺およびこれに類する手段を含みます。）により他人の個人情報を取得する行為。',
          '特定商取引に関する法律（昭和五十一年六月四日法律第五十七号）に基づく表示義務を怠り、契約意思の無い操作の結果にも関わらず契約したかのように誤認させる行為。',
          '法令に基づき監督官庁等への届出または許認可の取得等の手続きが義務づけられている場合に、当該手続きを履行せずに本サービスを利用する行為。',
          '上記各号のいずれかに該当する行為（当該行為を他者が行っている場合を含みます。）が行われている契約回線上のウェブサイトあるいは契約回線上のウェブサイト以外のサイトに、直接または間接に、接続させるに至る一切の行為。（例えば、上記の各ウェブサイトにリンクをはる行為。）',
          '上記各号のほか法令（法律、政令などをいいます。）に違反する内容の文字による記述ないし情報を送信または表示する行為。',
          '上記各号に該当する行為であると認定しえなくとも、行為の実質・態様・全体的印象などを当社において総合的に判断した結果、それらの行為に準ずるもの、あるいはそれらの行為に類似するものであると当社が認めた行為。',
          'その他、公序良俗に違反し、または他人の権利を著しく侵害すると当社が判断した行為。'
        ]
      },
      {
        text: '契約者は、自身による本サービスの利用およびこれに伴う行為に関して、問い合わせまたはクレーム等が通知された場合および紛争が発生した場合は、自己の責任と費用によりこれらを処理解決するものとします。'
      },
      {
        text: '契約者は、第三者の行為に対する請求、要望、疑問またはクレームがある場合は、当該第三者に対し、直接その旨を通知するものとし、その結果については、自己の責任と費用により処理解決するものとします。'
      },
      {
        text: '契約者は、自身による本サービスの利用およびこれに伴う行為に起因して、当社または第三者に対して損害を与えた場合（契約者が、本規約上の義務を履行しないことにより当社もしくは第三者が損害を被った場合を含みます。）、自己の責任と費用によりその損害を賠償するものとします。'
      }
    ]
  },
  No25: {
    index: '第25条',
    title: '通信の秘密の保護',
    paragraphList: [
      {
        text: '当社は、本サービスの提供に伴い取り扱う通信の秘密を電気通信事業法第4条に基づき保護し、本サービスの円滑な提供を確保するために必要な範囲でのみ利用または保存します。'
      }
    ]
  },
  No26: {
    index: '第26条',
    title: '個人情報等の取り扱い',
    paragraphList: [
      {
        text: '当社は、個人情報等（本サービスの提供に関連して知り得た契約者の個人情報であって、氏名、住所、電話番号、ＦＡＸ番号、メールアドレス、契約者が利用するサービスの契約情報を含み、前条に規定する通信の秘密に該当しない情報をいいます。以下同じとします。）を、本サービスの提供に利用するほか、別途当社が個人情報保護方針として定める利用目的（以下「利用目的」といいます。）に記載の範囲で利用します。'
      },
      {
        text: '当社は、利用目的のほか、次の場合に限り、個人情報等を第三者に提供いたします。なお、本条に定める範囲以外への個人情報等の提供により、個人情報の保護に関する法律（平成一五年五月三十日法律第五十七号）の定めに基づく第三者への提供の停止の請求を受けた場合には、当社は同法の定めに従い誠実に対応します。',
        subParagraphList: [
          '本人の同意を得て個人情報等を利用するとき。',
          '一般社団法人日本ネットワークインフォメーションセンター（以下「ＪＰＮＩＣ」といいます。）および株式会社日本レジストリサービス（以下「ＪＰＲＳ」といいます。）の規則等に基づき個人情報等を利用するとき。'
        ]
      },
      {
        text: '前2項の規定にかかわらず、当社は、刑事訴訟法（昭和二十三年七月十日法律第百三十一号）、特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律(平成十三年十一月三十日法律第百三十七号)、その他の法令に基づき、当該法令に定められた範囲にて、個人情報等を利用または提供することがあります。'
      }
    ]
  },
  No27: {
    index: '第27条',
    title: '合意管轄',
    paragraphList: [
      {
        text: '当社は、契約者と当社との間で本規約に関して訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。'
      }
    ]
  },
  No28: {
    index: '第28条',
    title: '閲覧',
    paragraphList: [
      {
        text: '本規約において、当社が別に定めることとしている事項（個別規定を含みます。）については、当社は閲覧に供します。'
      }
    ]
  }
};

const SUPPLEMENTARY_PROVISIONS_DATA: SupplementaryProvisions = {
  No1: {
    id: 1,
    itemList: [
      {
        title: '実施期日',
        text: '本規約は、2021年8月2日から実施します。'
      }
    ]
  },
  No2: {
    id: 2,
    itemList: [
      {
        title: '実施期日',
        text: '本規約は、2022年2月15日から実施します。'
      },
      {
        title: '加入契約の単位',
        text: '第5条（加入契約の単位）を変更しました。'
      }
    ]
  },
  No3: {
    id: 3,
    itemList: [
      {
        title: '実施期日',
        text: '本規約は、2023年4月4日から実施します。'
      },
      {
        title: '料金表',
        text: '7（本サービスの料金）を変更しました。'
      }
    ]
  }
};

const PRICE_LIST_DATA: PriceList = {
  sectionList: [
    {
      title: '料金の計算方法',
      text: '当社は、本規約に規定する基本利用料は、本サービスの提供開始が歴月２０日までに行われた場合には、提供開始月の翌月初日から、歴月２１日以降に本サービスが提供開始された場合には、提供開始月の翌々月の初日から、以降の期間について課金します。'
    },
    {
      text: '当社は、本規約に規定する基本利用料は、前項に定める起算日から以下の期間について課金します。',
      subParagraphList: [
        '本サービスに関するwebページにより加入契約の解除手続を行い、本サービスの解除が暦月の２０日までに行われた場合は、当該暦月の末日までの期間を、毎暦月の２１日から月末に加入契約の解除が行われた場合は、当該暦月の翌月末日までの期間を対象として、基本利用料を契約者に請求します。',
        '当社指定の書面により加入契約の解除手続を行い、毎暦月の１５日までに当該書面を郵送し解除した場合は、当該暦月の末日までの期間を、毎暦月の１６日から月末に郵送し解除した場合は、当該暦月の翌月末日までの期間を対象として、基本利用料を契約者に請求します。'
      ]
    },
    {
      text: '当社は、本サービスの利用料金等を利用日数について日割しません。'
    },
    {
      text: '当社は、料金その他の計算において、その計算結果に1円未満の端数が生じた場合は、その端数を切り捨てます。'
    },
    {
      text: '当社は、料金その他の計算方法を変更する場合があります。この場合、第23条（契約者への通知）に基づき通知するものとします。'
    },
    {
      title: '消費税相当額の加算',
      text: '本規約により料金その他の債務の支払を要するとされている額は、料金表に定めるものとします。なお、消費税の税率に変動があった場合には変動後の税率を適用し加算します。'
    },
    {
      title: '本サービスの料金',
      priceTable: {
        headerItemList: ['項目', '単位', '料金（税込）'],
        bodyItemList: ['Connectix', '1の加入契約につき月額', '980円']
      }
    },
    {
      title: '問い合わせ窓口',
      text: '本サービスに係る問い合わせ窓口は、当社所定の方法にて、通知または公表する電話番号にて受付致します。'
    }
  ]
};

export const CONNECTIX_TERMS_OF_SERVICE_E_MANSION_DATA = {
  termsOfService: CONNECTIX_TERMS_OF_SERVICE_DATA,
  supplementaryProvisions: SUPPLEMENTARY_PROVISIONS_DATA,
  priceList: PRICE_LIST_DATA
};
