<template>
  <div>
    <div class="modal micromodal-slide" id="modal-1" aria-hidden="true">
      <div class="modal__overlay" tabindex="-1" data-micromodal-close @click="onCloseModal">
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <main class="modal__content" id="modal-1-content">
            <iframe :srcdoc="srcHtml" width="780" height="600" />
          </main>
        </div>
        <span class="modal__close-button"></span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}
.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2003;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    background-color: #fff;
    max-height: 100vh;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #00449e;
    box-sizing: border-box;
  }
  &__content {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
  }
  &__close-button {
    $mySize: 50px;
    position: absolute;
    display: block;
    top: 32px;
    right: 32px;
    width: $mySize;
    height: $mySize;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: $mySize;
      background: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: $mySize;
      background: #fff;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}
.micromodal-slide.is-open {
  display: block;
}
.micromodal-slide[aria-hidden='false'] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden='false'] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden='true'] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden='true'] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
</style>

<script lang="ts">
import { TSUNAGU_ONLINE_POST_MESSAGE_RESPONSE_DATA_TYPE as POST_MESSAGE_RESPONSE_DATA_TYPE } from '@/shared/const/tnos/tsunagu-online-post-message-response-data-type';
import { PostMessageResponseData, TsunaguOnlineProductUrlService } from '@/shared/services/tnos/tsunagu-online-product-url-service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'modal-display-tsunagu-online-product',
  props: {
    srcHtml: {
      type: String,
      default: ''
    },
    handleShowModal: {
      type: Function,
      required: true
    },
    passProductUrl: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    micromodal: require('../../libraries/micromodal/micromodal.min.js'),
    currentPosition: 0
  }),
  mounted(): void {
    this.$nextTick(() => {
      this.micromodal.init({
        disableScroll: true
      });
    });

    /**
     * PC表示のとき、iframe内の「買い物カゴに入れる」が押下されたとき実行される
     * postMessageにより送信されたデータを処理する
     */
    window.addEventListener('message', this.handleMessageFromIframe);
  },
  methods: {
    openModal(): void {
      this.micromodal.show('modal-1');
    },
    /**
     * ・ モーダルを閉じる処理
     * ・ モーダルコンポーネントを非表示にする
     * ・ mountで付与したeventListenerを削除する
     */
    closeModal(): void {
      this.currentPosition = window.scrollY;
      this.micromodal.close('modal-1');
      this.handleShowModal(false);
      window.scrollTo({ top: this.currentPosition });
      this.$nextTick(() => {
        window.removeEventListener('message', this.handleMessageFromIframe);
      });
    },
    onCloseModal(event: Event): void {
      const target = event.target as HTMLElement;

      if (target.hasAttribute('data-micromodal-close')) {
        this.closeModal();
      } else if (target.classList.contains('modal__close-button')) {
        this.closeModal();
      }
    },
    /**
     * PC表示のとき、iframe内の「買い物カゴに入れる」が押下されたとき実行される
     * ・ sso用のURLを組み立てる
     * ・ モーダルを閉じる
     */
    handleMessageFromIframe(response: MessageEvent): void {
      // 別のメッセージイベントが来る時があり、JSON.parseでエラーになるとき用の例外処理
      try {
        const postMessageResponseData = JSON.parse(response.data) as PostMessageResponseData;

        if (postMessageResponseData.type === POST_MESSAGE_RESPONSE_DATA_TYPE.ADDED_CART_ITEM_INFO) {
          const myUrl = this.$store.getters['tsunaguOnlineProductHtmlDisplayOnPcStore/recentlyTransitionUrl'];
          const ssoUrl = TsunaguOnlineProductUrlService.buildUrlAddToCart(myUrl, JSON.parse(response.data));
          this.closeModal();
          window.open(ssoUrl, '_blank');
        } else if (postMessageResponseData.type === POST_MESSAGE_RESPONSE_DATA_TYPE.URL) {
          this.closeModal();
          this.passProductUrl(postMessageResponseData.url);
        }
      } catch (e) {
        // console.log(response);
        return;
      }
    }
  }
});
</script>
