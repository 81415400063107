/** ホームページ：ご利用状況／ご解約_初期表示API リクエスト */
export class EMansionHomepageCancelInitialDisplayRequest {
  readonly _eMansionAccountId!: string | undefined;
  readonly _date!: Date | undefined;

  /** コンストラクタ */
  constructor(partial: Partial<EMansionHomepageCancelInitialDisplayRequest>) {
    Object.assign(this, partial);
  }

  public get eMansionAccountId(): string | undefined {
    return this._eMansionAccountId;
  }

  public get date(): Date | undefined {
    return this._date;
  }
}
