<template>
  <div class="e-mansion-connectix-apply">
    <LoadingComponent v-if="isOnApplicationExecuting" />
    <main class="underlayer-main">
      <h1>Connectix サービスお申し込み</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>e-mansion Connectixお申し込み</li>
        <li>サービスお申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:Connectix お申し込み -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix&nbsp;お申し込み</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p>お客様のお部屋番号を入力してください。</p>
          <table class="table-type2">
            <tr>
              <th>マンション名</th>
              <td>{{ mansionName }}</td>
            </tr>
            <!-- 顧客の物件に棟名が存在する場合、棟名のプルダウンを表示する -->
            <tr v-if="buildingNameList.length > 0">
              <th class="va-middle">棟名</th>
              <td>
                <select name="example" class="form-select" v-model="inputData.buildingId">
                  <option value="0">--</option>
                  <option v-for="buildingName in buildingNameList" :key="buildingName.key" :value="buildingName.key">{{ buildingName.value }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="va-middle">部屋番号（半角英数字）</th>
              <!-- 契約基本情報取得APIに部屋番号が入っていない場合はデフォルト表示は空白とする -->
              <td>
                <input type="text" class="text short" v-model="inputData.roomNumber" @input="v$.inputData.roomNumber.$touch()" placeholder="101" />
              </td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込みサービス内容</h3>
          <table class="table-type2">
            <tr>
              <th>サービス名</th>
              <td>Connectix</td>
            </tr>
            <tr>
              <th>料金（月額）</th>
              <td>
                <div v-if="connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て新税率に基づく税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          <p class="att grey">※お申し込み後のキャンセルは行えません。</p>
          <p class="att grey">※お申し込み完了後、数分程度でサービスが適用されます。</p>
          <p class="att grey">※ご利用料金は、お申し込みが1日から20日までの場合翌月分より、21日から月末までの場合翌々月分より発生いたします。</p>
          <p class="att grey">※ご利用開始および解約時の日割り計算は行いません。</p>
          <p class="att grey">
            ※ベストエフォートサービスのため、通信速度を保障するものではございません。<br />本サービスに使用する設備に過大な負荷を生じさせる状況が確認された場合、通信速度及び通信の制限を行う場合があります。
          </p>
          <p class="att red mt15">※サービス適用状況については、お申し込み完了後、ご利用状況確認画面からご確認ください。</p>
        </div>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <p class="form-btn-txt">「お申し込み」ボタンをクリックしてください。確認ページへ進みます。</p>
        <div class="btn-area">
          <button class="btn-height btn btn01 bs" type="button" v-on:click="onApply()">お申し込み<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* underlayer-main
---------------------------------------------------- */
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo-emansion.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** 使用するバリデーションの定義 */
import { required, maxLength } from '@vuelidate/validators';
import { halfWidth } from '@/shared/util/validators';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** Entity e-mansion 共通利用 API : 契約基本情報取得 : レスポンス */
import { EMansionCustomer } from '../../../shared/classes/external-api/e-mansion/customer-response';
/** Entity UCOM Connectix API : Connectix お申し込み可能な部屋番号一覧 : レスポンス */
import { EMansionConnectixPropertyRooms, EMansionConnectixPropertyRoomsResponseResult } from '../e-mansion/classes/external-api/property-rooms-response';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { EMansionConnectixErrorResponse } from '../e-mansion/classes/external-api/connectix-error-response';
/** Entity UCOM Connectix お申し込み 入力フォーム */
import { EMansionConnectixEntryInputForm } from '../e-mansion/classes/entry-input-form';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionConnectixRoomsRequest } from '@/shared/classes/external-api/e-mansion/connectix-room-request';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Property } from '@/shared/classes/spf-api/property';
import { EMansionProperty } from '../../../shared/classes/external-api/e-mansion/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { ISP_MEMBER_STATUS, UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import useVuelidate from '@vuelidate/core';

/** Apply コンポーネント */
export default defineComponent({
  name: 'e-mansion-connectix-apply',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** マンション名 */
    mansionName: '',
    /** Connectix利用金額 */
    connectixUsageFee: '' as string,
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '' as string,
    /** 画面入力項目 */
    inputData: {
      /** 部屋番号 */
      roomNumber: '',
      /** 棟ID */
      buildingId: '',
    },
    /** 棟名 */
    buildingName: '',
    /** 物件基本情報の棟名。棟名選択欄の初期値 */
    selectedBuilding: '',
    /** 棟名表示用配列 */
    buildingNameList: new Array<{
      key: string;
      value: string;
    }>(),
    /** 物件基本情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** 物件ID */
    eMansionPropertyId: '',
    /** 部屋番号一覧 */
    propertyRooms: new Array<EMansionConnectixPropertyRooms>(),
    /** エラーの発生有無 部屋番号 */
    isInvalidRoomNumber: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    isLoading: true, // 読み込み状態,最初はロード中
    customer: {} as EMansionCustomer,
    member: null as Member | null,
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
    // onApplication()実行中かどうか
    isOnApplicationExecuting: false,
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      /** 部屋番号 */
      roomNumber: {
        required,
        halfWidth,
        maxLength: maxLength(8),
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();

      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        await this.$router.push('/platform')
        .catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // エラー判定の初期化
      this.isInvalidRoomNumber = false;

      /** 契約基本情報をStoreから取得
       * e-mansion 契約基本情報の取得でもっとシンプルにできる処理があれば変更
       */
      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      const eMansionMemberId = member.primaryKeyMye ?? '';
      this.member = member;

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const propertyId: string | undefined = property.apartmentId;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // 契約基本情報取得 API 用のクエリ
      // const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      //   ua_type: uaType,
      //   apartment_id: propertyId,
      // });

      // const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
      //   memberId: eMansionMemberId,
      //   query: findCustomerByIdQueryDto,
      // });
      const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 正常ケースの場合
        // 物件ID
        this.eMansionPropertyId = customer.property_id;
        this.customer = customer;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 物件基本情報をStoreから取得
      // const params = {
      //   // propertyId: this.eMansionPropertyId,
      //   apartmentId: property.apartmentId,
      //   uaType: UA_TYPE.E_MANSION,
      // };
      // const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', params);
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.eMansionProperty = eMansionProperty;
        // 物件名
        this.mansionName = this.eMansionProperty.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = this.eMansionProperty.op.sdwan.fee;
        this.connectixUsageFeeCp = this.eMansionProperty.op.sdwan.fee_cp;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }

      // 部屋番号一覧をStoreから取得
      const eMansionConnectixRoomsRequest: EMansionConnectixRoomsRequest = {
        propertyId: this.eMansionPropertyId,
        uaType: UA_TYPE.E_MANSION,
      };
      const propertyRooms: EMansionConnectixPropertyRoomsResponseResult | EMansionConnectixErrorResponse = await this.$store.dispatch(
        'eMansionConnectixEntryStore/propertyRooms',
        eMansionConnectixRoomsRequest
      );
      if (propertyRooms instanceof EMansionConnectixPropertyRoomsResponseResult) {
        // 部屋番号情報を管理していない物件はAPIのroom配列を空でレスポンスされるので、その場合はエラーメッセージを表示する
        if (propertyRooms.result.rooms.length === 0) {
          this.errorMessages.push('物件の部屋番号情報が取得できませんでした。');
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        this.propertyRooms = propertyRooms.result.rooms;
        for (const propertyRoom of this.propertyRooms) {
          const findPropertyArray = {
            key: propertyRoom.building_id,
            value: propertyRoom.building_name,
          };
          // building_id === '0000' の場合は棟名なし（API 定義）
          if (!this.buildingNameList.some((b: { key: string; value: string }) => b.key === propertyRoom.building_id) || propertyRoom.building_id !== '0000') {
            this.buildingNameList.push(findPropertyArray);
          }
        }

        // 重複削除
        const uniqueBuildingNameList = Array.from(new Map(this.buildingNameList.map((ele: { key: string; value: string }) => [ele.key, ele])).values());
        this.buildingNameList = uniqueBuildingNameList;
        // 棟名無し('0000')削除
        this.buildingNameList = Array.from(
          this.buildingNameList.filter((room: { key: string; value: string }) => {
            return room.key !== '0000';
          })
        );
      } else if (propertyRooms instanceof EMansionConnectixErrorResponse) {
        // エラーケースの場合
        // API側から返されたエラーメッセージを表示
        this.errorMessages = propertyRooms.errorInfo;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }

      // 確認画面から戻って来た場合は、事前に入力されていた情報を再現する
      const inputApply = this.$store.getters['eMansionConnectixEntryStore/entryInputForm'];
      if (inputApply != null) {
        this.inputData.roomNumber = inputApply.roomNumber;
        this.inputData.buildingId = inputApply.buildingId;
      } else if (this.eMansionProperty.em_bl_name) {
        // 棟名欄に初期値
        this.inputData.buildingId =
          this.buildingNameList.find((building: { key: string; value: string }) => {
            return building.value === customer.building_name;
          })?.key ?? '';
      }

      // 部屋番号
      if (!this.inputData.roomNumber) {
        if (customer.room_number) {
          this.inputData.roomNumber = customer.room_number;
        } else {
          this.inputData.roomNumber = '';
        }
      }
      this.isLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      throw error;
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isOnApplicationExecuting = false;
    },
    /** 「お申し込み ➡」ボタン押下時 お申し込み可能な部屋かチェック後、確認画面へ遷移。 */
    async onApply() {
      if (this.isOnApplicationExecuting) {
        return;
      }

      this.isOnApplicationExecuting = true;

      try {
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];
        // エラー判定の初期化
        this.isInvalidRoomNumber = false;

        if (await this.isInCancelApplication()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/connectix/e-mansion/terms').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        const ERROR_MESSAGE = '棟名、部屋番号をご確認の上、正しく入力してください。';
        const ERROR_MESSAGE_NO_BUILDING = '部屋番号をご確認の上、正しく入力してください。';
        const ERROR_MESSAGE_CANNOT_APPLY_CONNECTIX = 'インターネット接続サービス提供開始時にお申し込みください。';

        // 棟名の入力チェック
        if (this.buildingNameList.length) {
          for (const selectFindPropertyRoom of this.buildingNameList) {
            if (selectFindPropertyRoom.key == this.inputData.buildingId) {
              this.buildingName = selectFindPropertyRoom.value;
              break;
            } else {
              this.buildingName = '';
            }
          }
          if (this.buildingName == null || this.buildingName == '') {
            this.errorMessages.push(ERROR_MESSAGE);
            this.showErrorMessage();
            return;
          }
        }

        // 部屋番号の入力チェック
        if (this.v$.inputData.$invalid) {
          if (this.v$.inputData.roomNumber?.$invalid) {
            this.isInvalidRoomNumber = true;
            // 必須チェック
            // 部屋番号を管理している物件のみ存在チェック
            if (this.propertyRooms.length && this.v$.inputData.roomNumber?.required.$invalid) {
              if (this.buildingName) {
                this.errorMessages.push(ERROR_MESSAGE);
              } else {
                this.errorMessages.push(ERROR_MESSAGE_NO_BUILDING);
              }
              this.showErrorMessage();
              return;
            }
            // 形式・桁数チェック
            if (this.v$.inputData.roomNumber?.halfWidth.$invalid || this.v$.inputData.roomNumber?.maxLength.$invalid) {
              if (this.buildingName) {
                this.errorMessages.push(ERROR_MESSAGE);
              } else {
                this.errorMessages.push(ERROR_MESSAGE_NO_BUILDING);
              }
              this.showErrorMessage();
              return;
            }
          } else {
            this.isInvalidRoomNumber = false;
          }
        }

        const propertyRooms = this.propertyRooms;
        // お申し込み可能な部屋の中に自部屋があるかチェック
        const applyRooms = propertyRooms.find((propertyRoom: EMansionConnectixPropertyRooms) => {
          return (propertyRoom.building_id === this.inputData.buildingId || propertyRoom.building_id === '0000') && propertyRoom.room_number === this.inputData.roomNumber;
        });
        if (applyRooms === undefined) {
          // 入力した部屋がお申し込み可能な部屋ではなかった場合はエラー
          if (this.buildingName) {
            this.errorMessages.push(ERROR_MESSAGE);
          } else {
            this.errorMessages.push(ERROR_MESSAGE_NO_BUILDING);
          }
          // エラーメッセージを見せるために画面最上部にスクロール
          this.showErrorMessage();
          return;
        } else {
          if (applyRooms.apply_connectix_id === '1') {
            this.errorMessages.push(ERROR_MESSAGE_CANNOT_APPLY_CONNECTIX);
            this.showErrorMessage();
            return;
          }
        }

        // 0000の場合undefinedのはずなので設定する
        if (!this.inputData.buildingId) {
          this.inputData.buildingId = applyRooms.building_id;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw error;
      }
      // 入力情報をストアに保存
      const inputData = new EMansionConnectixEntryInputForm({
        // 部屋番号
        roomNumber: this.inputData.roomNumber.trim(),
        // 棟ID
        buildingId: this.inputData.buildingId,
        // 棟名表示用
        buildingName: this.buildingName,
      });

      this.$store.commit('eMansionConnectixEntryStore/entryInputForm', inputData);

      await this.$router
        .push('/connectix/e-mansion/confirm')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isOnApplicationExecuting = false;
        });
    },
    async isInCancelApplication() {
      const property: Property = this.$store.getters['propertyStore/property'];

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = this.member?.primaryKeyMye ?? '';
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
