<template>
  <div class="payment-method-delete-confirm">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portasサービス お支払い方法</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li><router-link to="/platform/my-page/payment-method/list">Portasサービス お支払い方法</router-link></li>
        <li>お支払い方法の削除</li>
      </ul>

      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li class="stay">お支払い方法の削除</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="お支払い方法の削除" />お支払い方法の削除</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>

      <div class="blc">
        <p>Portas（ポルタス）に登録されているクレジットカード情報を削除します。</p>

        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">カード番号</th>
              <td>{{ cardNumber }}</td>
            </tr>
            <tr>
              <th class="va-middle">カード期限</th>
              <td>{{ cardExpiredMonth }}月 ／ {{ cardExpiredYear }}年 <b v-if="isCardExpired" class="card-expired">有効期限切れ</b>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()"><i
              class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs" type="button" :disabled="isDisabledButton" v-on:click="onApply()">削除<i
              class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}

.card-expired {
  color: red;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { Member } from '@/shared/classes/spf-api/member';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UnpaidBillingService } from '@/shared/services/portas-subscription/unpaid-billing-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AxiosError } from 'axios';

type SplittedCardExpireDate = {
  cardExpireMonth: string;
  cardExpireYear: string;
};

export default defineComponent({
  name: 'payment-methods-delete-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    errorMessageTitle: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** ボタン押下判定 */
    isSubmitting: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    cardCompany: '',
    cardNumber: '',
    cardExpire: '',
    /** 削除対象クレジットカードの有効期限(月) */
    cardExpiredMonth: '',
    /** 削除対象クレジットカードの有効期限(年) */
    cardExpiredYear: '',
    cardholderName: '',
    isCardExpired: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    const member: Member = this.$store.getters['memberStore/member'];

    const deleteRequestedPaymentMethodNo: number = this.$store.getters['paymentMethodStore/deleteRequestedPaymentMethodNo'];

    const deleteRequestedPaymentMethodDetail: PaymentMethodDetailResponse = await this.getPaymentMethodDetail(member.id, deleteRequestedPaymentMethodNo);

    this.cardNumber = this.addHyphenToCardNumber(deleteRequestedPaymentMethodDetail.cardNumber);
    this.cardExpire = deleteRequestedPaymentMethodDetail.cardExpire;

    const splittedCardExpireDate = this.splitCardExpireDate(this.cardExpire);
    this.cardExpiredMonth = splittedCardExpireDate.cardExpireMonth;
    this.cardExpiredYear = String(processYearNumber()).slice(0, 2) + splittedCardExpireDate.cardExpireYear;

    if (
      isCreditCardExpired(
        deleteRequestedPaymentMethodDetail.cardExpire.slice(0, 2),
        String(processYearNumber()).slice(0, 2) + deleteRequestedPaymentMethodDetail.cardExpire.slice(3, 5)
      )
    ) {
      this.isCardExpired = true;
    }

    if (deleteRequestedPaymentMethodDetail.cardholderName === undefined) {
      this.cardholderName = '未登録';
    } else {
      this.cardholderName = deleteRequestedPaymentMethodDetail.cardholderName;
    }
    this.cardCompany = deleteRequestedPaymentMethodDetail.cardCompany;

    const unpaidBillingProductNameList = await UnpaidBillingService.getUnpaidBillingProductName(member.id);

    // 請求処理が未実施の契約が存在する場合はエラーメッセージを表示する
    if (unpaidBillingProductNameList.length !== 0) {
      this.errorMessageTitle = 'Portas（ポルタス）に登録されているクレジットカード情報を削除することはできません';
      this.errorMessages.push(
        '登録されているクレジットカードにて、ご契約中のサービス、またはお支払いが完了していないサービスがあります。<br>サービス解約、お支払い完了後、あらためてクレジットカード情報の削除を行ってください。'
      );
    }

    this.isLoading = false;
  },
  methods: {
    /** 「削除 ➡」ボタン押下時 : お支払い方法 削除APIを実行し、お支払い方法削除 > 完了画面に遷移する */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      const member: Member = this.$store.getters['memberStore/member'];

      const unpaidBillingProductNameList = await UnpaidBillingService.getUnpaidBillingProductName(member.id);

      // 請求処理が未実施の契約が存在する場合はエラーメッセージを表示する
      if (unpaidBillingProductNameList.length !== 0) {
        this.errorMessageTitle = 'Portas（ポルタス）に登録されているクレジットカード情報を削除することはできません';
        this.errorMessages.push(
          '登録されているクレジットカードにて、ご契約中のサービス、またはお支払いが完了していないサービスがあります。<br>サービス解約、お支払い完了後、あらためてクレジットカード情報の削除を行ってください。'
        );
        this.isSubmitting = false;
        window.scrollTo(0, 0);
        return;
      }

      const deleteRequestedPaymentMethodNo: number = this.$store.getters['paymentMethodStore/deleteRequestedPaymentMethodNo'];

      await this.deletePaymentMethod(member.id, deleteRequestedPaymentMethodNo);
      if (this.errorMessages.length !== 0) {
        this.isSubmitting = false;
        window.scrollTo(0, 0);
        return;
      }

      // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
      this.$store.commit('memberStore/member', null);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/payment-method/delete-completed').catch((error: any) => {
        checkRouterError(error);
      });
      // ボタン押下解除
      this.isSubmitting = false;
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page/payment-method/list')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    /*
     * MM/YY 形式の文字列で渡されたカード有効期限を、月と年の情報に分割して返却する
     */
    splitCardExpireDate(cardExpire: string): SplittedCardExpireDate {
      const splittedCardExpireDateArray = cardExpire.split('/');
      const splittedCardExpireDate = {
        cardExpireMonth: splittedCardExpireDateArray[0],
        cardExpireYear: splittedCardExpireDateArray[1],
      };
      return splittedCardExpireDate;
    },
    /** お支払い方法詳細取得 API実行 */
    async getPaymentMethodDetail(memberId: number, paymentMethodNo: number): Promise<PaymentMethodDetailResponse> {
      const response = await SpfApiService.getPaymentMethodDetail(memberId, paymentMethodNo);
      return response;
    },
    /** お支払い方法削除 API実行 */
    async deletePaymentMethod(memberId: number, paymentMethodNo: number): Promise<undefined | AxiosError> {
      try {
        await SpfApiService.deletePaymentMethod(memberId, paymentMethodNo);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // TODO: お支払い方法削除 API設計書の仕様に応じて要変更
        if (error.isAxiosError && error.response.status === 400 && error.response.data.errors.some((value: string) => value === 'this payment method cannot be deleted')) {
          this.errorMessageTitle = 'Portas（ポルタス）に登録されているクレジットカード情報を削除することはできません';
          this.errorMessages.push(
            '登録されているクレジットカードにて、ご契約中のサービス、またはお支払いが完了していないサービスがあります。<br>サービス解約、お支払い完了後、あらためてクレジットカード情報の削除を行ってください。'
          );
          return;
        } else {
          throw error;
        }
      }
    },
  },
  computed: {
    isDisabledButton(): boolean {
      return this.errorMessages.length !== 0;
    },
  },
});
</script>
