<template>
  <div class="five-a-connectix-confirm">
    <LoadingComponent v-if="!isMounted || isExecutingOnApply" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み内容の確認</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>お申し込み内容の確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>サービスお申し込み</li>
        <li class="stay">お申し込み内容の確認</li>
        <li>お申し込み完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix お申し込み内容の確認</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p class="red">ご指定のお部屋番号に本サービスを適用します。</p>
          <table class="table-type2">
            <tr>
              <th>建物名</th>
              <td>{{ propertyName }}</td>
            </tr>
            <tr>
              <th>部屋番号</th>
              <td>{{ roomNumber }}</td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th>料金（月額）</th>
              <td>
                <div v-if="connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          <p class="att grey">※本サービスはベストエフォートの為、時間帯や状況などによって異なることがあり、保証されるものではございません。</p>
        </div>
        <div>
          <h3 class="service-h3">お支払い方法</h3>
          <div v-if="noDisplayCreditCardForm">
            <p>ご登録のお支払い方法により、お支払いいただきます。</p>
          </div>
          <div v-else>
            <credit-card-component
              v-if="isMounted"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
            />
          </div>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」をクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
        <div class="btn-area">
          <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" type="button" :disabled="isDisabledButton" @click="onApply()">お申し込みを確定する<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}
.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}
div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FiveACustomer } from '../../../shared/classes/external-api/five-a/customer-response';
import { Property } from '@/shared/classes/spf-api/property';
import { AuthService } from '@/shared/services/auth/auth-service';
import { Member } from '@/shared/classes/spf-api/member';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveAConnectixErrorResponse } from './classes/external-api/connectix-error-response';
import { FiveAConnectixNewConnectixRequest } from './classes/external-api/new-connectix-request';
import { FiveAConnectixExternalApiService } from '@/shared/services/external-api/connectix/five-a/five-a-connectix-external-api-service';
import { UaTypeValue, UA_TYPE, SERVICE_TYPE, ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { SpfApiService } from '@/shared/services/api/spf-api-service';

/** Confirm コンポーネント */
export default defineComponent({
  name: 'five-a-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件名 */
    propertyName: '',
    /** 部屋番号 */
    roomNumber: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '',
    /** 会員ID */
    memberId: '' as string,
    /** roomsで取得したbuilding_id(棟ID) */
    buildingId: '' as string,
    /** 物件ID */
    propertyId: '',
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** onApply() 実行中かどうか */
    isExecutingOnApply: false,
    /** ISP(Five.A)会員ステータス */
    ispMemberStatusFiveA: '',
    /** ユーザーがPortasからカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではFive.A固定) */
    ispName: 'Five.A',
    /** Five.Aから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** Five.Aから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** ボタン押下中かどうか */
    isSubmitting: false,
  }),
  /** ログインしていない状態で遷移した場合は「総合トップ」画面にリダイレクトする */
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      // five.A のメンバー ID
      this.memberId = member.primaryKeyMye ?? '';
      this.buildingId = member.buildingNumber ?? '';

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      if (!property) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      const uaType: UaTypeValue | undefined = property.uaType;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // 契約基本情報をStoreから取得
      const customer: FiveACustomer | FiveASharedErrorResponse = this.$store.getters['fiveACommonStore/customer'];
      if (customer instanceof FiveACustomer) {
        // 物件ID
        this.propertyId = customer.property_id;

        // 登録している支払方法が以下のいずれかの場合、クレジットカードコンポーネントを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO);
      }

      // 物件基本情報を取得
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = this.$store.getters['fiveACommonStore/property'];
      if (propertyInfo instanceof FiveAProperty) {
        // 物件名
        this.propertyName = propertyInfo.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = propertyInfo.op.sdwan.fee;
        this.connectixUsageFeeCp = propertyInfo.op.sdwan.fee_cp;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO);
      }
      // 入力画面で入力した情報をStoreから取得
      const inputApply = this.$store.getters['fiveAConnectixEntryStore/entryInputForm'];
      if (!inputApply) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      // 部屋番号
      this.roomNumber = inputApply.roomNumber;

      if (customer instanceof FiveACustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    async onApply() {
      if (this.isExecutingOnApply) {
        return;
      }
      this.isExecutingOnApply = true;

      // ISP(Five.A)側で退会申し込み中のとき、/connectix/5a/terms に遷移してエラーメッセージを表示
      if (await this.isInCancelApplication()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/connectix/5a/terms').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // Connectixお申し込みAPIを実行
        const request = new FiveAConnectixNewConnectixRequest({
          /** UA種別 */
          ua_type: UA_TYPE.FIVE_A,
          /** 会員ID */
          member_id: this.memberId,
          /** 物件ID */
          property_id: this.propertyId,
          /** 部屋番号 */
          room_number: this.roomNumber,
        });

        /** roomsで取得したbuilding_id(棟ID) */
        if (this.buildingId) request.building_id = this.buildingId;
        /** クレジットカードトークン */
        if (this.creditCardAccessToken) request.credit_card_token = this.creditCardAccessToken;
        /** トークン有効期限 */
        if (this.creditTokenExpireDate) request.token_expire_date = this.creditTokenExpireDate;
        /** カード会員 ID */
        if (this.isAgreedCopyCardToIsp && this.portasVeriTransId) request.card_account_id = this.portasVeriTransId;

        const requestBody: FiveAConnectixNewConnectixRequest = request;
        const resultCreateConnectix = await FiveAConnectixExternalApiService.createConnectix(requestBody);

        if (resultCreateConnectix instanceof FiveAConnectixErrorResponse) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONNECTIX);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/connectix/5a/completed').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (error) {
        throw error;
      }
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/connectix/5a/apply')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async isInCancelApplication() {
      try {
        const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = this.$store.getters['fiveACommonStore/customer'];

        if (fiveACustomer instanceof FiveACustomer) {
          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

          /**
           * ISP(Five.A)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(Five.A)会員ステータス: 退会申し込み
           * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO);
        }
      } catch (e) {
        throw e;
      }
      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.noDisplayCreditCardForm) {
        return false;
      }
      if (this.maskedCardNumberFromIsp && this.cardExpiredFromIsp) {
        return false;
      }
      if (!this.isExpiredPortasCreditCard && this.isAgreedCopyCardToIsp) {
        return false;
      }
      return this.creditCardAccessToken === '';
    },
  },
});
</script>
