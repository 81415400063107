/**契約ステータス */
export const CONTRACT_STATUS = {
  APPLICATION_NOT_OPEN: '未開通',
  APPLICATION_CANCELED: 'キャンセル',
  OPENED: '開通済',
  IN_USE: '契約中',
  SUSPENSION_OF_USE: '利用停止',
  ABOLITION: '廃止',
  CANCELED: '解約',
} as const;
export type PLAN_FIX = typeof CONTRACT_STATUS[keyof typeof CONTRACT_STATUS];
