<template>
  <div id="vue-tsunagu-online-shop" class="p-tsunagu-online-shop-container">
    <!-- ヘッダー表示領域 -->
    <div class="p-tsunagu-online-shop-header">
      <h2 class="p-tsunagu-online-shop-header__title" :class="{ 'is-top': currentPath === '/' }">つなぐネットオンラインショップ</h2>
      <!-- つなぐオンラインショップアカウント未連携のとき -->
      <template v-if="!member || (member && !member.primaryKeyTnshop)">
        <div class="p-tsunagu-online-shop-header__image">
          <img src="../../../images/sogo/tnshop.png" />
        </div>
      </template>

      <!-- つなぐオンラインショップアカウント連携済みのとき、ロゴ画像をリンクに -->
      <template v-else-if="member && member.primaryKeyTnshop">
        <div class="p-tsunagu-online-shop-header__image">
          <a :href="getUrlLoginToTsunaguOnlineShopWithSso()" target="tsunagu">
            <img src="../../../images/sogo/tnshop.png" />
          </a>
        </div>
      </template>
      <div class="p-tsunagu-online-shop-header__description">
        <p>
          「つなぐネットオンラインショップ」は、マンション向けインターネット接続サービス「UCOM光
          レジデンス」「e-mansion」「Five.A」を提供する株式会社つなぐネットコミュニケーションズ（アルテリアグループ）が運営する通販サイトです。<br />
          <b>インターネットで暮らしを、もっと豊かに快適に。</b>をテーマに、インターネット活用やマンションライフにお役立ていただけるアイテムやサービスをお届けします。
        </p>
        <br />
        <div class="campaign-qa-tsunagu">
          「つなぐネットオンラインショップ」の商品や購入方法については、こちらからお問い合わせください。<br />
          <div class="c-link-list-topqa">
            <div class="c-link-list-outer">
              <ul class="c-link-list">
                <li class="c-link-list__item">
                  <a :href="`${tnosBaseUrl}/apply.html?id=APPLY1`" target="tsunagu">つなぐネットオンラインショップお問い合わせ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="service-news border-grey">
      <ul>
        <template v-if="this.$route.path === '/platform'">
          <li class="list-none" id="information">
            <span class="noticelist">お知らせ</span>
            <b>2023/03/01 </b>
            <a :href="getUrlTsunaguOnlineShopInformation()" target="tsunagu">お知らせの内容</a>
          </li>
          <li class="list-none" id="campaign">
            <span class="noticelist">キャンペーン</span>
            <b>2023/03/01 </b>
            <a :href="getUrlTsunaguOnlineShopCampaign()" target="tsunagu">キャンペーンの内容</a>
          </li>
        </template>
        <template v-else>
          <li class="list-none" id="information">
            <span class="noticelist">お知らせ</span>
            <b>2023/03/01 </b>
            お知らせの内容
          </li>
          <li class="list-none" id="campaign">
            <span class="noticelist">キャンペーン</span>
            <b>2023/03/01 </b>
            キャンペーンの内容
          </li>
        </template>
      </ul>
    </div> -->

    <!-- 「つなぐネットオンラインショップ」新規登録に関係する領域: つなぐオンラインショップ未連携のとき -->
    <template v-if="member && !member.primaryKeyTnshop">
      <div class="p-tsunagu-online-shop-registration-container">
        <section class="p-tsunagu-online-shop-registration">
          <template v-if="!displayMessageWhenClickedTsunaguRegistrationButton">
            <div class="p-tsunagu-online-shop-header__description2">「つなぐネットオンラインショップ」商品購入前に会員登録を行ってください。</div>
            <div class="p-tsunagu-online-shop-registration__button">
              <button class="btn btn01 bs" @click="openNewTabTsunaguOnlineMemberRegistrationPage()">「つなぐネットオンラインショップ」新規登録</button>
            </div>
          </template>

          <!-- 「つなぐネットオンラインショップ」新規登録 ボタンを押下した後表示される領域 -->
          <template v-if="displayMessageWhenClickedTsunaguRegistrationButton">
            <div class="p-tsunagu-online-shop-registration__notification">
              <!-- 「このページを更新する」を押下した後、アカウント未連携のとき表示される領域 -->
              <template v-if="displayMessageWhenNotLinkToTsunagu">
                <div class="when-account-is-not-linked">
                  <p class="red">「つなぐネットオンラインショップ」新規登録が完了しておりません。</p>
                  <p class="red">少し時間をおいてから、再度このページを更新してください。</p>
                </div>
              </template>

              <div class="my-description">
                <p>「つなぐネットオンラインショップ」会員登録後、このページを更新してください。</p>
                <p>「つなぐネットオンラインショップ」連携済みのPortasログイン後トップページへ移動します。</p>
                <p><a class="link" @click.prevent="findOutAlreadyLinkedToTnshop">このページを更新する</a></p>
              </div>
            </div>
          </template>
        </section>
      </div>
    </template>

    <!-- 商品をグリッドレイアウトで表示する領域 -->
    <div id="goods">
      <div class="p-tsunagu-online-shop-product-list-container">
        <div class="p-tsunagu-online-shop-product-list">
          <template v-for="product in filteredProductList" :key="product.url">
            <div class="p-tsunagu-online-shop-product-list__item">
              <div class="p-tsunagu-online-shop-product">
                <div class="p-tsunagu-online-shop-product__image">
                  <a :href="product.url" @click.prevent="displayTsunaguOnlineProductHtmlWrapper">
                    <img class="img_align" :src="product.imgSrc" />
                  </a>
                </div>
                <div class="p-tsunagu-online-shop-product__description">
                  <span class="category">{{ product.category }}</span>
                  <p class="product-name">
                    <a :href="product.url" @click.prevent="displayTsunaguOnlineProductHtmlWrapper">{{ product.productName }}</a>
                  </p>
                  <p class="price">{{ convertPriceForDisplay(product) }}<span class="tax-description">（税込）</span></p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- 商品をカルーセルで表示する領域 -->
      <div class="p-tsunagu-online-shop-product-swiper-container">
        <swiper
          :loop="true"
          :space-between="40"
          :initial-slide="1"
          :navigation="{
            nextEl: '.tsunagu-online-shop-swiper-button-next',
            prevEl: '.tsunagu-online-shop-swiper-button-prev',
          }"
          :modules="SwiperModules"
        >
          <swiper-slide v-for="product in filteredProductList" :key="product.url">
            <div class="p-tsunagu-online-shop-product">
              <div class="p-tsunagu-online-shop-product__image">
                <a class="my-link-in-swiper" :href="product.url">
                  <img :src="product.imgSrc" class="img_align" />
                </a>
              </div>
              <div class="p-tsunagu-online-shop-product__description">
                <span class="category">{{ product.category }}</span>
                <p class="product-name">
                  <a class="my-link-in-swiper" :href="product.url">{{ product.productName }}</a>
                </p>
                <p class="price">{{ convertPriceForDisplay(product) }}<span class="tax-description">（税込）</span></p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="tsunagu-online-shop-swiper-button-prev swiper-button-prev"></div>
        <div class="tsunagu-online-shop-swiper-button-next swiper-button-next"></div>
      </div>
    </div>

    <!-- モーダル表示に関係する領域 -->
    <template v-if="showModal">
      <ModalDisplayTsunaguOnlineProduct :srcHtml="mySrcHtml" :handle-show-modal="setShowModal" :passProductUrl="handlePassedProductUrl" ref="modalDisplayTsunaguOnlineProduct" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.img_align {
  object-fit: contain;
  width: 300px;
  height: 200px;
}
.p-tsunagu-online-shop-header {
  position: relative;
  &__image {
    position: absolute;
    width: 160px;
    & img {
      width: 100%;
    }
  }
  &__description {
    padding-left: 176px;
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
  &__description2 {
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
  &__title {
    display: inline-block;
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.6;
    border-bottom: 3px solid #0097e0;
    &.is-top {
      font-size: 20px;
      border-bottom-color: #cf1225;
    }
  }
}

div.c-link-list-topqa {
  margin-top: 0px;
  & div.c-link-list-outer {
    & ul.c-link-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      & li.c-link-list__item {
        letter-spacing: 0.8px;
        &:before {
          font-family: 'Material Icons';
          content: '\e409';
          position: relative;
          top: 3px;
          font-size: 16px;
        }
        & a {
          text-decoration: underline;
          color: inherit;
        }
      }
    }
  }
}

.campaign-qa-tsunagu {
  background-color: #efefef;
  width: fit-content;
  padding: 10px 20px;
}

.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;
}

.service-news ul {
  padding: 0;
}

.service-news ul li {
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #cf1225;
  position: relative;
}

.service-news ul li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 6px solid transparent;
  border-left: 10px solid #cf1225;
}

.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

.p-tsunagu-online-shop-banner {
  position: relative;
  &__image {
    position: absolute;
    margin-top: 6px;
    width: 160px;
    & img {
      width: 100%;
    }
  }
  &__text {
    height: 40px;
    margin-top: 25px;
    padding-left: 176px;
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
}

.p-tsunagu-online-shop-registration-container {
  margin-top: 16px;
}

.p-tsunagu-online-shop-registration {
  &__button {
    margin-top: 16px;
  }
  &__notification {
    & div.when-account-is-not-linked {
      padding: 16px;
      margin-top: 24px;
      border: 1px solid #cf1225;
    }
    & div.my-description {
      margin-top: 16px;
      line-height: 1.6;
      & a {
        cursor: pointer;
      }
    }
  }
}

.p-tsunagu-online-shop-product-list-container {
  display: none;
  margin-top: 16px;
}

.p-tsunagu-online-shop-product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 32px 32px;
}

.p-tsunagu-online-shop-product {
  &__image {
    width: 200px;
    margin: 0 auto;
    & img {
      width: 100%;
      box-shadow: 3px 3px 5px #cfcfcf;
    }
  }
  &__description {
    margin-top: 16px;
    padding: 0 8px;
    & span.category {
      display: inline-block;
      padding: 2px 6px;
      color: #fff;
      background-color: #333;
      border-radius: 8px;
    }
    & p.product-name {
      margin-top: 8px;
      font-weight: 800;
    }
    & p.price {
      margin-top: 8px;
      text-align: left;
      & span.tax-description {
        font-size: 10px;
      }
    }
  }
}

.p-tsunagu-online-shop-product-swiper-container {
  position: relative;
  margin-top: 40px;
  display: block;
  padding-top: 0px;
}

.tsunagu-online-shop-swiper-button-prev {
  top: 34%;
  background-color: #a9a9a9;
  background-image: url('../../../images/prev.png');
}

.tsunagu-online-shop-swiper-button-next {
  top: 34%;
  background-color: #a9a9a9;
  background-image: url('../../../images/next.png');
}

@media screen and (min-width: 500px) {
  .p-tsunagu-online-shop-product-list-container {
    display: block;
    margin-top: 40px;
  }
  .p-tsunagu-online-shop-product-swiper-container {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .p-tsunagu-online-shop-banner {
    &__text {
      margin-top: 20px;
      margin-bottom: 5px;
      padding-left: 176px;
      line-height: 1;
      letter-spacing: 0.8px;
    }
  }

  .p-tsunagu-online-shop-header {
    &__image {
      position: static;
      width: 160px;
    }
    &__description {
      padding-left: 0px;
      margin-top: 16px;
    }
    &__title {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .p-tsunagu-online-shop-header {
    &__title {
      &.is-top {
        font-size: 24px;
      }
    }
  }
}
</style>

<script lang="ts">
import MobileDetect from 'mobile-detect';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { defineComponent } from 'vue';

import { Member } from '@/shared/classes/spf-api/member';
import { TsunaguOnlineProduct } from '@/shared/classes/tnos/tsunagu-online-product';
import ModalDisplayTsunaguOnlineProduct from '@/shared/components/tnos/modal-display-tsunagu-online-product.vue';
import { TSUNAGU_ONLINE_PRODUCT_LIST as PRODUCT_LIST } from '@/shared/const/tnos/tsunagu-online-product-list';
import { AuthService } from '@/shared/services/auth/auth-service';
import { TsunaguOnlineProductDisplayService } from '@/shared/services/tnos/tsunagu-online-product-display-service';
import { TsunaguOnlineProductHtmlDisplay, TsunaguOnlineProductHtmlDisplayService } from '@/shared/services/tnos/tsunagu-online-product-html-display-service';
import { TsunaguOnlineProductUrlService } from '@/shared/services/tnos/tsunagu-online-product-url-service';
import '@/styles/swiper/my-pagination.scss';
import 'swiper/css';
import 'swiper/css/pagination';

export default defineComponent({
  name: 'tsunagu-online-shop',
  components: {
    Swiper,
    SwiperSlide,
    ModalDisplayTsunaguOnlineProduct,
  },
  setup: () => ({ SwiperModules: [Navigation] }),
  data: () => ({
    productList: PRODUCT_LIST,
    filteredProductList: [] as TsunaguOnlineProduct[] | undefined,
    // user-agent が モバイルかどうか
    isUserAgentMobile: false,
    showModal: false,
    mySrcHtml: '',
    // つなぐオンラインショップ新規会員登録ボタンをクリックしたかどうか
    displayMessageWhenClickedTsunaguRegistrationButton: false,
    // つなぐオンラインショップアカウント連携済みかどうか
    displayMessageWhenNotLinkToTsunagu: false,
    tnosBaseUrl: process.env.VUE_APP_TNOS_BASE_URL,
    member: null as Member | null,
    /** 表示されている画面のパス */
    currentPath: '',
  }),
  emits: ['updateDisplayPortalPatternFromTsunaguOnlineShop', 'updateLeftMenuLogoFromTsunaguOnlineShop'],
  async mounted(): Promise<void> {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);

    if (mobileDetect.mobile()) {
      this.isUserAgentMobile = true;
    }

    this.filteredProductList = await TsunaguOnlineProductDisplayService.getDisplayProductList();
    this.member = this.$store.getters['memberStore/member'];

    this.currentPath = this.$route.path;
    this.$nextTick(() => {
      // vueの @click.prevent で イベント付与できないときがあるがための対応
      [...document.getElementsByClassName('my-link-in-swiper')].map((element) => {
        return element.addEventListener('click', this.displayTsunaguOnlineProductHtmlWrapper);
      });
    });
  },
  unmounted(): void {
    this.$nextTick(() => {
      [...document.getElementsByClassName('my-link-in-swiper')].map((element) => {
        return element.removeEventListener('click', this.displayTsunaguOnlineProductHtmlWrapper);
      });
    });
  },
  methods: {
    convertPriceForDisplay(product: TsunaguOnlineProduct): string {
      return TsunaguOnlineProductDisplayService.convertPriceForDisplay(product);
    },
    /**
     * 「つなぐネットオンラインショップ」の新規会員登録をする画面に遷移するURL
     * 暗号化したPortas会員IDをクエリパラメータとして渡す
     */
    openNewTabTsunaguOnlineMemberRegistrationPage(): void {
      const url = `${process.env.VUE_APP_TNOS_BASE_URL}/custom/member_kiyaku.html?portas_member_id=${this.member?.portasMemberIdWithEncrypted}`;
      window.open(url, 'tsunagu');
      this.displayMessageWhenClickedTsunaguRegistrationButton = true;
    },
    /**
     * 「つなぐネットオンラインショップ」にSSOするためのURL
     */
    getUrlLoginToTsunaguOnlineShopWithSso(): string {
      return TsunaguOnlineProductUrlService.buildLoginUrlWithSso();
    },
    /**
     *  「お知らせ」のバナーに関するURL
     */
    getUrlTsunaguOnlineShopInformation(): string {
      if (this.member && !this.member.primaryKeyTnshop) {
        return `${process.env.VUE_APP_TNOS_BASE_URL}/topics_detail.html?info_id=${process.env.VUE_APP_TNOS_INFORMATION_ID}`;
      }
      const encoded = encodeURIComponent(`topics_detail.html?info_id=${process.env.VUE_APP_TNOS_INFORMATION_ID}`);
      const reencoded = encodeURIComponent(encoded);
      return `${process.env.VUE_APP_TNOS_BASE_URL}/ext/ssologin.html?redirectTo=${reencoded}`;
    },
    /**
     * 「つなぐネットオンラインショップ」のバナーに関するURL
     */
    getUrlTsunaguOnlineShopCampaign(): string {
      if (!this.member || (this.member && !this.member.primaryKeyTnshop)) {
        return `${process.env.VUE_APP_TNOS_BASE_URL}/topics_detail.html?info_id=${process.env.VUE_APP_TNOS_CAMPAIGN_ID}`;
      }
      const encoded = encodeURIComponent(`topics_detail.html?info_id=${process.env.VUE_APP_TNOS_CAMPAIGN_ID}`);
      const reencoded = encodeURIComponent(encoded);
      return `${process.env.VUE_APP_TNOS_BASE_URL}/ext/ssologin.html?redirectTo=${reencoded}`;
    },
    /**
     * 商品のリンクが押下されたときのイベントを処理する
     */
    async displayTsunaguOnlineProductHtmlWrapper(e: Event): Promise<void> {
      const tsunaguOnlineProductHtmlDisplay = await TsunaguOnlineProductHtmlDisplayService.makeWrapper(e);
      this.displayTsunaguOnlineProductHtml(tsunaguOnlineProductHtmlDisplay);
    },
    /**
     * 1. スマホのとき: スマホのときスクレイピング表示をする用のページへ遷移する
     * 2. PCのとき: スクレイピング処理をしたHTMLを表示する
     */
    displayTsunaguOnlineProductHtml(tsunaguOnlineProductHtmlDisplay: TsunaguOnlineProductHtmlDisplay | undefined): void {
      if (tsunaguOnlineProductHtmlDisplay) {
        if (tsunaguOnlineProductHtmlDisplay.isUserAgentMobile) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          window.open(tsunaguOnlineProductHtmlDisplay.spUrl!, '_blank');
        } else {
          this.showModal = true;
          this.mySrcHtml = tsunaguOnlineProductHtmlDisplay.srcHtml ? tsunaguOnlineProductHtmlDisplay.srcHtml : '';

          // モーダル表示するコンポーネントのdom操作できるようになったら、モーダル表示ONにする
          this.$nextTick(() => {
            const modalDisplayTsunaguOnlineProduct = this.$refs.modalDisplayTsunaguOnlineProduct as InstanceType<typeof ModalDisplayTsunaguOnlineProduct>;
            if (modalDisplayTsunaguOnlineProduct) {
              modalDisplayTsunaguOnlineProduct.openModal();
            }
          });
        }
      }
    },
    setShowModal(value: boolean) {
      this.showModal = value;
    },
    async findOutAlreadyLinkedToTnshop(): Promise<void> {
      this.member = await this.$store.dispatch('memberStore/member');

      // つなぐアカウント未連携のとき
      if (this.member && !this.member.primaryKeyTnshop) {
        this.displayMessageWhenNotLinkToTsunagu = true;
      } else {
        await AuthService.refresh();
      }
      this.sendUpdateLeftMenuLogoAt();
      // 親コンポーネントの portal-service-各ISP にて displayPortalPattern が更新されるように
      this.$emit('updateDisplayPortalPatternFromTsunaguOnlineShop', true);
    },
    /**
     * スクレイピング処理後に表示しているページ内にて、
     * 別商品へのリンクが押下されたとき、リンクが押下された商品が表示されるようURLを適切なメソッドに渡す
     */
    async handlePassedProductUrl(url?: string): Promise<void> {
      if (url) {
        const tsunaguOnlineProductHtmlDisplay = await TsunaguOnlineProductHtmlDisplayService.make(url);
        this.displayTsunaguOnlineProductHtml(tsunaguOnlineProductHtmlDisplay);
      }
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにイベントを送る
     */
    sendUpdateLeftMenuLogoAt(): void {
      this.$emit('updateLeftMenuLogoFromTsunaguOnlineShop', true);
    },
  },
});
</script>
