/** Platform 支払方法登録 API送信用 */
export class PlatformPaymentMethodRegisterRequest {
  /** クレジットカードトークン */
  public readonly creditCardToken!: string;
  /** クレジットカードトークン有効期限 */
  public readonly tokenExpireDate!: string;

  constructor(fields: Required<PlatformPaymentMethodRegisterRequest>) {
    Object.assign(this, fields);
  }
}
