import { PROPERTY_SEARCH_TYPE, UaTypeValue } from '../const/service-type';
import { SearchPropertyDetail, SearchResultOver } from '../classes/spf-api/property';
import { SpfApiService } from '../services/api/spf-api-service';
import { DataInconsistencyFrontError } from '../classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '../const/error/error-info';

/**
 * PortasDB の 物件テーブル.物件ID を1件だけ取得する
 *
 * 利用シーン
 * 1. e-mansionの物件検索API (/apartment/properties) の取得結果.apartmentId (AA0146のように6桁のID) を引数にとる
 * 2. 会員ステータス.遷移元サービス物件ID を引数にとる
 *    - ただし、会員ステータス.遷移元サービス物件ID は AA0146のように6桁のID かつ 会員ステータス.遷移元サービス が Five.A)
 *
 * 該当する 物件テーブル.物件ID が1件だけ見つかる想定なので、それ以外の場合はありえないエラーとする
 */
export async function getPropertyIdOnPortasDB(apartmentId: string, uaType: UaTypeValue): Promise<number> {
  try {
    const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
      PROPERTY_SEARCH_TYPE.E_MANSION, // apartmentId が 必ず6桁の物件IDのため、 PROPERTY_SEARCH_TYPE.E_MANSION で固定
      apartmentId,
      false
    );
    if (propertyList instanceof SearchResultOver) {
      // 検索結果が11件以上でありえないエラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    } else {
      if (propertyList.length === 0 || propertyList.length >= 2) {
        // ありえないエラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }
      if (propertyList[0].uaType !== uaType) {
        // ありえないエラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }
      return propertyList[0].id;
    }
  } catch (e) {
    throw e;
  }
}
