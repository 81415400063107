/** Webスペース容量 入力(ユーザ) */
export class UcomWebSpaceUserInfo {
  readonly _paymentStatus: boolean; // お支払情報登録有無
  readonly _accountName: string; // UCOM_アカウント名
  readonly _mailAddress: string; // メールアドレス
  readonly _homepageAddress: string; // ホームページアドレス
  readonly _webSpaceCapacity: string; // 現在のWebスペース容量

  constructor(paymentStatus: boolean, accountName: string, mailAddress: string, homepageAddress: string, webSpaceCapacity: string) {
    this._paymentStatus = paymentStatus;
    this._accountName = accountName;
    this._mailAddress = mailAddress;
    this._homepageAddress = homepageAddress;
    this._webSpaceCapacity = webSpaceCapacity;
  }

  get paymentStatus(): boolean {
    return this._paymentStatus;
  }
  get accountName(): string {
    return this._accountName;
  }
  get mailAddress(): string {
    return this._mailAddress;
  }
  get homepageAddress(): string {
    return this._homepageAddress;
  }
  get webSpaceCapacity(): string {
    return this._webSpaceCapacity;
  }
}
