import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { PERMISSION_TYPE } from '@/shared/services/permission/permission-type';

/**
 * メール統合 共通利用 API : アイテム別認可マスタ取得 : レスポンス
 *
 */
export class ItemPermissionMaster {
  /** サービスID */
  readonly _serviceId!: string;
  /** サービス種別 */
  readonly _serviceType!: string;
  /** 画面ID */
  readonly _screenId!: string;
  /** アイテムID */
  readonly _itemId!: string;
  /** 会員種別 */
  readonly _planType!: SERVICE_PLAN_TYPE;
  /** パーミッション */
  readonly _permission!: PERMISSION_TYPE;

  constructor(partial: Partial<ItemPermissionMaster>) {
    Object.assign(this, partial);
  }

  /** サービスID */
  get serviceId(): string {
    return this._serviceId;
  }
  /** サービス種別 */
  get serviceType(): string {
    return this._serviceType;
  }
  /** 画面ID */
  get screenId(): string {
    return this._screenId;
  }
  /** アイテムID */
  get itemId(): string {
    return this._itemId;
  }
  /** 会員種別 */
  get planType(): SERVICE_PLAN_TYPE {
    return this._planType;
  }
  /** パーミッション */
  get permission(): PERMISSION_TYPE {
    return this._permission;
  }
}
