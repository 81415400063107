<template>
  <div class="payment-method-input">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portasサービス お支払い方法</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li><router-link to="/platform/my-page/payment-method/list">Portasサービス お支払い方法</router-link></li>
        <li>お支払い方法の登録</li>
      </ul>

      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li class="stay">お支払い方法の登録</li>
        <li>登録完了</li>
      </ul>

      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="お支払い方法の登録" />お支払い方法の登録</h2>
          <credit-card-component
            v-if="isMounted"
            :key="resetCounter"
            :reset-counter="resetCounter"
            v-bind:isNeedAuthorization="false"
            v-bind:apiTokenKey="apiTokenKey"
            v-bind:successMessage="successMessage"
            v-bind:maskedCardNumberOnPortas="maskedCardNumberOnPortas"
            v-bind:cardholderNameOnPortas="cardholderNameOnPortas"
            v-bind:cardExpiredOnPortas="cardExpiredOnPortas"
            v-bind:externalVeriTransId="externalVeriTransId"
            v-bind:ispName="ispName"
            v-bind:ispProviderCompanyName="ispProviderCompanyName"
            v-on:cardTokenForRegister="getCardTokenForRegister"
            v-on:cardTokenExpireDateForRegister="getCardTokenExpireDateForRegister"
            v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
            v-on:onPostCreditCardError="executeCreditCardError"
            v-on:change="isAgreedCopyCardFromIsp = $event"
            v-on:radioValueRequiringCardCopy="getRadioValueRequiringCardCopy"
          />
        </div>
      </div>

      <div v-if="!(externalVeriTransId && radioValueRequiringCardCopy !== 'no')" class="btn-area">
        <input id="isAgreedPrivacyPolicy" v-model="isAgreedPrivacyPolicy" type="checkbox" name="isAgreedPrivacyPolicy" />
        <label for="isAgreedPrivacyPolicy"> クレジットカード番号および本人認証に必要な情報の提供に同意します。</label>
      </div>

      <div class="sblc">
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs" type="button" :disabled="isDisabledButton" v-on:click="onApply()">登録<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { PaymentMethodCopyResponse } from '@/shared/classes/platform/payment-method-copy-response';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { default as CreditCardComponent } from '@/shared/components/veritrans-credit-card-component-can-copy-from-isp.vue';
import { SERVICE_NAME, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionVeriTransService } from '@/shared/services/e-mansion/e-mansion-veritrans-service';
import { FiveAVeriTransService } from '@/shared/services/five-a/five-a-veritrans-service';
import { UcomVeriTransService } from '@/shared/services/ucom/ucom-veritrans-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';

interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}

export default defineComponent({
  name: 'payment-methods-input',
  components: {
    ErrorMessagesComponent,
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカード登録用カードトークン */
    cardTokenForRegister: '',
    /** クレジットカード登録用カードトークン有効期限 */
    cardTokenExpireDateForRegister: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_PORTAS,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「登録」ボタンを押してください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** ユーザーが連携先ISPからカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardFromIsp: false,
    /** 連携先ISPからVeriTrans会員IDが取得できた場合は値を登録する */
    externalVeriTransId: '',
    /** Portasに支払方法が登録されている場合はマスク化されたクレジットカード番号を登録する */
    maskedCardNumberOnPortas: '',
    /** Portasに支払方法が登録されている場合のカード保有者名 */
    cardholderNameOnPortas: ('' as string) || undefined,
    /** Portasに支払方法が登録されている場合はカードの有効期限を登録する */
    cardExpiredOnPortas: '',
    /** 連携先ISPの提供元会社名を登録する。空文字の場合は"株式会社つなぐネットコミュニケーションズ"が表示される */
    ispProviderCompanyName: '',
    /** ログイン中のPortasユーザーが連携中の外部ISP名を登録する */
    ispName: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** ISPへ登録済みのクレジットカードをコピーするラジオボタン選択状態 */
    radioValueRequiringCardCopy: '',
    isFailedExecuteRegisterPaymentMethod: false,
    resetCounter: 0,
  }),

  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      // エラーメッセージストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    const member: Member = this.$store.getters['memberStore/member'];
    const property: Property | null = this.$store.getters['propertyStore/property'];

    if (member.primaryKeyMye) {
      if (property?.uaType === UA_TYPE.E_MANSION) {
        this.ispName = SERVICE_NAME.E_MANSION;
      } else if (property?.uaType === UA_TYPE.FIVE_A) {
        this.ispName = SERVICE_NAME.FIVE_A;
      }
    } else if (member.primaryKeyUcom) {
      this.ispName = SERVICE_NAME.UCOM;
      this.processPropertyApiResponse();
    }

    const paymentMethodOnPortas = await this.getPaymentMethodInfoList(member.id);

    // Portas上にクレジットカード情報が登録されている場合
    if (paymentMethodOnPortas) {
      this.maskedCardNumberOnPortas = paymentMethodOnPortas.cardNumber;
      this.cardExpiredOnPortas = paymentMethodOnPortas.cardExpire;
      this.cardholderNameOnPortas = paymentMethodOnPortas.cardholderName;
      // e-mansionに登録されたVeriTransカード情報をコピー可能にする
    } else if (this.ispName === SERVICE_NAME.E_MANSION) {
      this.externalVeriTransId = EMansionVeriTransService.getVeriTransCardIdOnEMansion();

      // fiveAに登録されたVeriTransカード情報をコピー可能にする
    } else if (this.ispName === SERVICE_NAME.FIVE_A) {
      this.externalVeriTransId = await FiveAVeriTransService.getVeriTransCardIdOnFiveA();

      // UCOMに登録されたVeriTransカード情報をコピー可能にする
    } else if (this.ispName === SERVICE_NAME.UCOM) {
      this.externalVeriTransId = await UcomVeriTransService.getVeriTransCardIdOnUcom();
    }

    this.isLoading = false;
    this.isMounted = true;
    return;
  },
  methods: {
    /** 「お支払い方法を保存 ➡」ボタン押下時 */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      if (
        this.cardTokenForRegister === '' &&
        this.cardTokenFor3dSecureAuthorize === '' &&
        !(this.maskedCardNumberOnPortas && this.cardExpiredOnPortas) &&
        !this.isAgreedCopyCardFromIsp
      ) {
        // APIキーが発行されていない、かつPortas上に登録された支払方法が存在しない、かつ連携先ISPからカードをコピーして登録しない場合はエラー
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        this.isSubmitting = false;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }

      const member: Member = this.$store.getters['memberStore/member'];

      // ISPに登録済みのクレジットカード情報をコピーすることに同意しているとき
      if (this.isAgreedCopyCardFromIsp) {
        let uaTypeValue = '';

        if (this.ispName === SERVICE_NAME.E_MANSION) {
          uaTypeValue = UA_TYPE.E_MANSION;
        } else if (this.ispName === SERVICE_NAME.FIVE_A) {
          uaTypeValue = UA_TYPE.FIVE_A;
        } else if (this.ispName === SERVICE_NAME.UCOM) {
          uaTypeValue = UA_TYPE.UCOM;
        }

        await this.copyPaymentMethodFromIsp(member.id, uaTypeValue, this.externalVeriTransId);

        // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
        this.$store.commit('memberStore/member', null);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/my-page/payment-method/input-completed').catch((error: any) => {
          checkRouterError(error);
        });

        // ボタン押下解除
        this.isSubmitting = false;

        // 画面に入力したクレジットカード情報を登録するとき
      } else {
        const uuidForTemporarySavingApplicationData = uuidv4();

        /**  申込内容一時保存API へ渡す申込データ */
        const paymentMethodRegisterApplicationData = {
          creditCardAccessToken: this.cardTokenForRegister,
          creditTokenExpireDate: this.cardTokenExpireDateForRegister,
        };

        const paymentMethodRegisterApplicationDataJson = JSON.stringify(paymentMethodRegisterApplicationData);

        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodRegisterApplicationDataJson,
          subsequentProcess: 'payment_methods_input',
        });

        try {
          await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        } catch {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
          this.isSubmitting = false;

          // 「お支払い方法を保存」ボタンを非活性にする
          this.cardTokenForRegister = '';
          this.cardTokenFor3dSecureAuthorize = '';

          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
          uuid: uuidForTemporarySavingApplicationData,
          creditCardToken: this.cardTokenFor3dSecureAuthorize,
        });

        // VeriTrans_本人認証(トークン使用)API の実行
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);

          // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
          this.$store.commit('memberStore/member', null);

          // 作成されたトークン情報を削除して「登録」ボタンを非活性化する
          this.cardTokenForRegister = '';
          this.cardTokenFor3dSecureAuthorize = '';

          // エラーメッセージ格納配列初期化
          this.errorMessages = [];

          // ボタン押下解除
          this.isSubmitting = false;

          location.href = authStartURL;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認のいただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
            );
            // エラーになったとき、クレジットカードコンポーネントを再描画する

            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();
            // 「お支払い方法を保存」ボタンを非活性にする
            this.cardTokenForRegister = '';
            this.cardTokenExpireDateForRegister = '';
            this.cardTokenFor3dSecureAuthorize = '';
            //チェックボックス非表示＆チェックを外す
            this.isAgreedPrivacyPolicy = false;
            this.radioValueRequiringCardCopy = '';

            this.isFailedExecuteRegisterPaymentMethod = true;
          } else {
            throw error;
          }
          this.isSubmitting = false;

          // 作成されたトークン情報を削除して「登録」ボタンを非活性化する
          this.cardTokenForRegister = '';
          this.cardTokenFor3dSecureAuthorize = '';

          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
      }
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page/payment-method/list')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** クレジットカード登録用クレジットカードトークンを受け取る */
    getCardTokenForRegister(cardTokenForRegister: string) {
      this.cardTokenForRegister = cardTokenForRegister;
    },
    /** クレジットカード登録用クレジットカードトークン有効期限を受け取る */
    getCardTokenExpireDateForRegister(cardTokenExpireDateForRegister: string) {
      this.cardTokenExpireDateForRegister = cardTokenExpireDateForRegister;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    getRadioValueRequiringCardCopy(radioValueRequiringCardCopy: string) {
      this.radioValueRequiringCardCopy = radioValueRequiringCardCopy;
    },
    /**
     * ISP提供元企業名を判別するため、UCOMの物件基本情報を取得する処理
     */
    processPropertyApiResponse(): void {
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];

      if (ucomProperty instanceof UcomPropertyResponse) {
        this.ispProviderCompanyName = ucomProperty.service_provid_former.service_provid_former_name;
      }
    },
    /** 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得する */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay | null> {
      const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
      let paymentMethodInfo: PaymentMethodInfoForDisplay | null = null;

      for (const eachPaymentMethod of paymentMethodList) {
        const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
          ...eachPaymentMethod,
          isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
        };
        if (!(eachPaymentMethod.deleteDate || eachPaymentMethodForDisplay.isExpired)) {
          paymentMethodInfo = eachPaymentMethodForDisplay;
          return paymentMethodInfo;
        }
      }
      return paymentMethodInfo;
    },
    /**VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /**支払方法コピー API実行 */
    async copyPaymentMethodFromIsp(id: number, uaType: string, originalAccountId: string): Promise<PaymentMethodCopyResponse> {
      return await SpfApiService.copyPaymentMethodFromIsp(id, uaType, originalAccountId);
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    /** VeriTransの３Dセキュア本人認証（トークン使用） API の実行 */
    async create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest: Create3dSecureAuthStartInfoWithCardTokenRequest): Promise<string> {
      return await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
    },
  },
  computed: {
    isDisabledButton(): boolean {
      // ユーザーがカード情報を画面に入力し、支払方法の登録を実施する場合
      if (this.cardTokenForRegister && this.cardTokenFor3dSecureAuthorize && this.isAgreedPrivacyPolicy) {
        return false;
        // ユーザーがカード情報をコピーする場合
      } else if (this.isAgreedCopyCardFromIsp && !(this.maskedCardNumberOnPortas && this.cardExpiredOnPortas)) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // クレジットカードトークンの生成に成功したとき、画面上に表示されているエラーメッセージを削除して非表示にする
    cardTokenForRegister: {
      handler(newValue) {
        if (newValue) {
          this.errorMessages = [];
        }
      },
    },
  },
});
</script>
