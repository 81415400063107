<template>
  <div class="products-entry-completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Portasサービス</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Portasサービス</li>
        <li>Portasサービス お申し込み完了</li>
      </ul>
      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li>お申し込み内容のご確認</li>
        <li class="stay">お申し込み完了</li>
      </ul>
      <div class="blc">
        <div v-if="isProductSet">
          <h3 class="service-h3">Portasサービスのお申し込み完了</h3>
          <p><span v-html="productName"></span>セットのお申し込みが完了しました。</p>
          <p class="toBold">ダウンロードURL・ライセンスキー等は<router-link to="/platform/my-page" class="toRed-line">Portasマイページ</router-link>にてご確認ください。</p>
        </div>
        <div v-else-if="productId == getProductIds().NortonSecurity || productId == getProductIds().internetSagiWallForMultiDevice">
          <h3 class="service-h3">Portasサービスのお申し込み完了</h3>
          <p><span v-html="productName"></span>のお申し込みが完了しました。</p>
          <p class="toBold">ダウンロードURL・ライセンスキー等は<router-link to="/platform/my-page" class="toRed-line">Portasマイページ</router-link>にてご確認ください。</p>
        </div>
        <div v-else>
          <h3 class="service-h3">Portasサービスのお申し込み完了</h3>
          <p><span v-html="productName"></span>のお申し込みが完了しました。</p>
        </div>
      </div>
      <div class="blc">
        <div v-if="connectixUrl">
          <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="Connectixお申し込み" />Connectixお申し込み</h2>
          <p>以下のボタンより、引き続き、Connectixのお申し込みを進めてください。</p>
          <div class="btn-area">
            <button class="btn btn01 bs" v-on:click="onConnectix()">
              Connectixお申し込みへ
              <i class="material-icons link link-icon">east</i>
            </button>
          </div>
        </div>
        <div v-else>
          <div class="btn-area">
            <router-link to="/" custom v-slot="{ navigate }">
              <button class="btn btn01 bs" @click="navigate">トップページ<i class="material-icons link link-icon">east</i></button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('@/images/main.png');
}

.toBold {
  font-weight: bold;
}

.toRed-line {
  color: #cf1225;
  text-decoration: underline;
}

.connectix-btn-css {
  background-color: white;
  border: 1px solid red;
  color: black;
}

.margin-left {
  margin-left: 1em;
}

.table-adjustment-css {
  border-collapse: separate;
  border: none;
  font-weight: normal;
}

.table-type2 th {
  font-weight: normal;
}

button.btn04:disabled {
  opacity: 0.5;
}

.service-h4 {
  font-size: 16px;
  padding-left: 15px;
  border-left: 3px solid #cf1225;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { ProductIds } from '@/shared/classes/spf-api/portas-subscription/const';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Index コンポーネント */
export default defineComponent({
  name: 'products-entry-completed',
  components: { LoadingComponent },
  data: () => ({
    isLoading: false, // ローディング
    /** 商品がセット商品かどうか */
    isProductSet: false,
    /** 購入商品名 */
    productName: '',
    /** 購入商品ID */
    productId: '',
    /** Connectix商品フラグ */
    isConnectix: false,
    /** Connectixお申し込みURL */
    connectixUrl: '',
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    this.$data.isLoading = true;
    // サービス契約申込ストアの商品名配列を取得し購入商品名を組み立てる
    const productNameList = this.$store.getters['platformProductEntryStore/productNameArray'];
    const productIdList = this.$store.getters['platformProductEntryStore/productIdArray'];
    if (productNameList.length > 1) {
      this.isProductSet = true;
      this.productName = productNameList.join(' ＋ ');
    } else {
      this.productName = productNameList[0];
      this.productId = productIdList[0];
    }
    // サービス契約申込ストアのConnectixの契約要否を取得
    this.isConnectix = this.$store.getters['platformProductEntryStore/isConnectix'];
    if (this.isConnectix) {
      // サービス契約申込ストアのISP名を取得
      const ispName = this.$store.getters['platformProductEntryStore/ispName'];
      if (ispName === 'e-mansion') {
        this.connectixUrl = '/connectix/e-mansion/terms';
      } else if (ispName === 'Five.A') {
        this.connectixUrl = '/connectix/5a/terms';
      } else if (ispName === 'UCOM光 レジデンス') {
        this.connectixUrl = '/connectix/ucom/terms';
      }
    }
    // コンポーネント内で platformProductsEntryStore のデータをクリアする
    this.$store.getters['platformProductEntryStore/clearPlatformProductsEntryStore'];
    this.$data.isLoading = false;
    return;
  },
  methods: {
    async onConnectix(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push(this.connectixUrl).catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**
     * template 内でも使用したいため ProductIds を取得する
     */
    getProductIds() {
      return ProductIds;
    },
  },
});
</script>
