import { createApp } from 'vue';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';

import router from './router';
import store from './store';

// 以下service内でstoreを使用する際に必要な措置
import { injectStore } from './shared/services/external-api/base-external-api-service';
injectStore(store);

import App from './app.vue';
import { VueErrorHandler } from './handler/error/vue-error-handler';
import { UnexpectedErrorHandler } from './handler/error/unexpected-error-handler';
import { UnhandledredrejectionErrorHandler } from './handler/error/unhandledrejection-error-handler';
import { addDevtools } from 'vuex4-devtools-helper';
import { DataDogRealUserMonitoring } from './shared/classes/data-dog/data-dog-real-user-monitoring';

// 本番環境とステージング環境のみDataDog用の設定をONにする
const dataDogRealUserMonitoring = new DataDogRealUserMonitoring();
dataDogRealUserMonitoring.init();

const vm = createApp({
  ...App
});

vm.use(VueScrollTo);
vm.use(router);
vm.use(store);
addDevtools(vm, store);
vm.use(VueGtag, {
  config: {
    id: 'GTM-NX8QNQQ'
  }
});

vm.config.errorHandler = async (error: unknown, vm, info: string) => {
  // 呼び出し先にお任せする
  await VueErrorHandler.handle(error as Error, info);
};

window.addEventListener('error', async (event) => {
  // 呼び出し先にお任せする
  await UnexpectedErrorHandler.handle(event.error);
});

window.addEventListener('unhandledrejection', async (event) => {
  // 呼び出し先にお任せする
  await UnhandledredrejectionErrorHandler.handle(event.reason as Error);
});

vm.mount('#app');
export default vm;
