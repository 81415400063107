<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Webスペース容量変更 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>Webスペース容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Webスペース容量変更" />Webスペース容量変更&emsp;確認</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <h3 class="service-h3">お申し込み内容</h3>
      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">ホームページアドレス</th>
              <td>{{ homepageAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">現在の容量</th>
              <td>{{ webSpaceCapacity }}.0MB</td>
            </tr>
            <tr>
              <th class="va-middle">追加／縮小容量</th>
              <td>
                <b>{{ displayAfterChangeWebSpaceCapacityLabel }}</b>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>10.0MB 毎に月額{{ unitPrice }}円（税込）<br />最大{{ webSpaceMaxCapacity }}.0MBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ applicationDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td v-html="serviceDescription"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <p class="form-btn-txt">「申し込む」を押してください。完了画面へ進みます。</p>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="onBack()">
            <i class="material-icons link link-icon">west</i>
            戻る
          </button>
          <button class="btn btn01 bs" v-on:click="onApply()">
            申し込む
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiWebSpaceAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-accessor';
import { UcomWebSpaceCapacityUpdate } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-capacity-update';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'web-space-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    /** COM_アカウントメールID */
    ucomAccountMailId: '',
    /** アカウントID (=アカウントID(表示用)) */
    accountName: '',
    /** メールアドレス */
    mailAddress: '',
    /** ホームページアドレス */
    homepageAddress: '',
    /** 現在のWebスペース容量 */
    webSpaceCapacity: '',
    /** 追加/縮小するWebスペース容量リスト */
    changeCapacityList: [],
    /** Webスペース最大容量 */
    webSpaceMaxCapacity: '',
    /** 追加/縮小するWebスペース容量のLabel */
    afterChangeWebSpaceCapacityLabel: '',
    /** 表示用追加/縮小するWebスペース容量のLabel */
    displayAfterChangeWebSpaceCapacityLabel: '',
    /** 追加/縮小するWebスペース容量のValue Hidden */
    afterChangeWebSpaceCapacityValue: '',
    /** 月額利用料 */
    unitPrice: 0,
    /** お支払情報登録有無 */
    paymentStatus: true,
    /** お申し込み日 */
    applicationDate: '',
    /** サービス説明 */
    serviceDescription: '',
    /** エラーメッセージ */
    errorMessages: new Array<string>(),
    /** ローディング */
    isLoading: false,
    /** エラーメッセージ部タイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
  }),
  async mounted() {
    this.isLoading = true;

    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      // UCOM_アカウントメールIDをStoreから取得
      this.ucomAccountMailId = this.$store.getters['ucomWebSpaceStore/ucomAccountMailId'];

      // 戻るボタン押下時にstoreのucomAccountMailIdに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', undefined);

      // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
      if (!this.ucomAccountMailId) {
        await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // Storeからデータを取得しセット
      this.paymentStatus = this.$store.getters['ucomWebSpaceStore/paymentStatus'];
      this.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
      this.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
      this.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
      this.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
      this.changeCapacityList = this.$store.getters['ucomWebSpaceStore/changeCapacityList'];
      this.afterChangeWebSpaceCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
      this.afterChangeWebSpaceCapacityValue = this.$store.getters['ucomWebSpaceStore/changeCapacityValue'];
      this.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
      this.webSpaceMaxCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceMaxCapacity'];
      this.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
      this.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];

      // 追加/縮小容量を表示用に加工
      if (String(this.afterChangeWebSpaceCapacityValue.toString().charAt(0)) === '-') {
        this.displayAfterChangeWebSpaceCapacityLabel = this.afterChangeWebSpaceCapacityValue + 'MB';
      }
      if (String(this.afterChangeWebSpaceCapacityValue.toString().charAt(0)) !== '-') {
        this.displayAfterChangeWebSpaceCapacityLabel = '+' + this.afterChangeWebSpaceCapacityValue + 'MB';
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },

    // 「戻る」ボタン押下時の処理
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.$store.commit('ucomWebSpaceStore/paymentStatus', this.paymentStatus);
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', this.ucomAccountMailId);
      this.$store.commit('ucomWebSpaceStore/accountName', this.accountName);
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', this.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', this.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityList', this.changeCapacityList);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', this.afterChangeWebSpaceCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', this.afterChangeWebSpaceCapacityValue);
      this.$store.commit('ucomWebSpaceStore/unitPrice', this.unitPrice);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', this.webSpaceMaxCapacity);
      this.$store.commit('ucomWebSpaceStore/applicationDate', this.applicationDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', this.serviceDescription);

      await this.$router.push('/ucom/web-space/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },

    // 「申し込む」ボタン押下時の処理
    async onApply(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // 会員IDの取得
        const memberId = this.$store.getters['memberStore/member'].id;

        // 現在日付の取得
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const targetDate = this.formatDate(now);

        const response = await SpfApiWebSpaceAccessor.updateUcomWebSpaceCapacity(
          memberId,
          this.ucomAccountMailId,
          this.afterChangeWebSpaceCapacityLabel,
          this.afterChangeWebSpaceCapacityValue,
          targetDate
        );

        if (response.errorMessages) {
          this.errorMessages = [];
          this.errorMessages.push(response.errorMessages);
          // エラーメッセージを見せるため画面最上部にスクロール
          await this.scrollPageTop();
          this.isLoading = false;
          return;
        }

        const accountName = response.accountName;
        const unitPrice = response.price;
        const processingDate = response.subscriptionDate;

        // 確認更新API レスポンスをstoreに保存
        this.saveStore(response, accountName, unitPrice, processingDate);

        // 完了画面へ遷移
        await this.$router.push('/ucom/web-space/complete').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
      } catch (e) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    },

    // storeにデータを保存
    saveStore(response: UcomWebSpaceCapacityUpdate, accountName: string, unitPrice: string, processingDate: string): void {
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomWebSpaceStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomWebSpaceStore/accountName', accountName);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', response.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', response.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/unitPrice', unitPrice);
      this.$store.commit('ucomWebSpaceStore/applicationDate', processingDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', response.serviceDescription);
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    // ページトップへスクロール
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
</script>
