export const INPUT_PROPERTY_FORM_INITAL_STATE = {
  properties: [],
  selectedProperty: null,
  inputBuildingData: {
    buildingId: '',
    buildingName: '',
    roomNumber: ''
  },
  buildings: [],
  roomsData: [],
  isAfterRegister: false,
  eMansionSpecialPropertyId: null,
  eMansionSpecialPropertyName: null
};
