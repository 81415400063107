export class InitUcomCustomerNoUser {
  private readonly _memberId!: number;
  private readonly _customerNo!: number;

  constructor(memberId: number, customerNo: number) {
    this._memberId = memberId;
    this._customerNo = customerNo;
  }

  get memberId(): number {
    return this._memberId;
  }

  get customerNo(): number {
    return this._customerNo;
  }
}
