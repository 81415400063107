import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';

/**
 * e-mansion 支払情報登録状態
 */
export class EMansionPaymentStatusInfo {
  readonly _status!: E_MANSION_PAYMENT_STATUS;

  constructor(partial: Partial<EMansionPaymentStatusInfo>) {
    Object.assign(this, partial);
  }

  get status(): E_MANSION_PAYMENT_STATUS {
    return this._status;
  }
}
