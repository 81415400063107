<template>
  <div class="member-confirm">
    <LoadingComponent v-if="isSubmitting" />
    <main class="underlayer-main">
      <h1>Portas会員情報変更 確認</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li><router-link to="/platform/my-page/member-edit">会員情報変更</router-link></li>
        <li>確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>会員情報変更 入力</li>
        <li class="stay">会員情報変更 確認</li>
        <li>会員情報変更 完了</li>
      </ul>

      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages !== ''" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="会員情報変更 確認" />Portas会員情報変更 確認</h2>
        <table class="table-type2 billing-table">
          <tbody v-if="form">
            <tr>
              <th class="va-middle">お名前</th>
              <td>{{ form.firstName }} {{ form.givenName }}</td>
            </tr>
            <tr>
              <th class="va-middle">フリガナ</th>
              <td>{{ form.firstNameKatakana }} {{ form.givenNameKatakana }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ form.emailAddress }}</td>
            </tr>
            <tr v-if="form.phoneNumber1 && form.phoneNumber2 && form.phoneNumber3">
              <th class="va-middle">電話番号</th>
              <td>{{ form.phoneNumber1 }}-{{ form.phoneNumber2 }}-{{ form.phoneNumber3 }}</td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>
                {{ form.birthYear }}年{{ form.birthMonth }}月{{ form.birthDay }}日
                <p class="att red mt10">※生年月日は一度登録すると後から変更はできません。</p>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- GameWith光の場合は下記を表示しない条件を追加 -->
        <table class="table-type2 property-table" v-if="property && !isLinkedToGameWithH">
          <tbody>
            <tr>
              <th>お住まいの建物</th>
              <td>
                <p class="font18px mb10">
                  <b>{{ property.dispApartmentName }}</b>
                </p>
                <p>〒{{ property.zipcode }} {{ property.address1 }}</p>
                <p class="att red mt10">※お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。</p>
                <p class="att red mt10">※お住まいの建物は登録を変更することができません。</p>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- GameWith光の場合は下記を表示しない条件を追加 -->
        <table class="table-type2 property-table" v-if="myPageProperty && !isLinkedToGameWithH">
          <tbody>
            <tr>
              <th>お住まいの建物</th>
              <td>
                <p class="font18px mb10">
                  <b>{{ myPageProperty.apartmentNameDisplay }}</b>
                </p>
                <p>{{ myPageProperty.apartmentAddress }}</p>
                <p class="att red mt10">※お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。</p>
                <p class="att red mt10">※お住まいの建物は登録を変更することができません。</p>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Portasサービス契約住所を登録済みの場合 -->
        <table class="table-type2 property-table" v-if="form?.address || (form?.zipcode1 && form?.zipcode2) || form?.apartmentBlockName || form?.roomNumber">
          <tbody>
            <tr>
              <th>Portasサービス契約住所</th>
              <td>
                <p v-if="form?.zipcode1 && form?.zipcode2">〒{{ form.zipcode1 }}-{{ form.zipcode2 }}</p>
                <p>{{ form.address }}</p>
                <p>{{ form.apartmentBlockName }}</p>
                <p>{{ form.roomNumber }}</p>
                <p class="att red mt10"></p>
                <div class="red">
                  <p class="att">※Portasサービスお申し込みをご希望の場合、郵便番号・住所は必須入力項目です。</p>
                  <p class="att">
                    ※登録した住所は、Portasマイページでの修正ができません。修正をご希望の場合は、お手数ですが、<a
                      href="https://support.ucom.ne.jp/contact/portas/"
                      target="_blank"
                      class="toRed-line"
                      >Portasお問い合わせフォーム</a
                    >からご連絡ください。
                  </p>
                  <p class="att">
                    ※ご契約サービスに関するご案内やご請求時の不備等においてメールでご連絡が行えない場合、書面を発送する場合がございますのであらかじめご了承ください。
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="blc">
        <p class="form-btn-txt">変更内容をご確認の上、よろしければ「変更」ボタンを押してください。</p>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="onNext()">変更<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

table.billing-table {
  margin-bottom: 0px;
}

table.property-table {
  border-top: none;
}

.toRed-line {
  color: #cf1225;
  text-decoration: underline;
}
</style>

<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';

import { PlatformEditInputForm } from '../../../shared/classes/platform/edit-input-form';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { ExternalSearchPropertiesPropertyInfoDto } from '@/shared/classes/external-api/search-properties/external-search-properties-property-info-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property, SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { PROPERTY_SEARCH_TYPE, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionSharedExternalApiService } from '@/shared/services/external-api/e-mansion-shared-external-api-service';
import { FiveASharedExternalApiService } from '@/shared/services/external-api/five-a-shared-external-api-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { InputPropertyForm } from '@/store/platform/platform-edit-store';

type DataType = {
  form: PlatformEditInputForm | null;
  property: Property | null;
  myPageProperty: ExternalSearchPropertiesPropertyInfoDto | null;
  inputPropertyForm: InputPropertyForm | null;
  resultMember: Member | null;
  resultMemberStatus: MemberStatus | null;
  errorMessages: string[];
  errorMessageTitle: string;
  isSubmitting: boolean;
  isPropertyUaTypeEmansion: boolean;
  isPropertyUaTypeUcom: boolean;
  isPropertyUaTypeFiveA: boolean;
  isLinkedToGameWithH: boolean;
  member: Member | null;
};

/** MemberConfirm コンポーネント */
export default defineComponent({
  name: 'member-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): DataType {
    return {
      form: null,
      property: null,
      myPageProperty: null,
      inputPropertyForm: null,
      resultMember: null,
      resultMemberStatus: null,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      isSubmitting: false,
      isPropertyUaTypeEmansion: false,
      isPropertyUaTypeUcom: false,
      isPropertyUaTypeFiveA: false,
      isLinkedToGameWithH: false,
      member: null,
    };
  },
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    /** 会員情報変更>入力画面の入力内容を取得する */
    this.form = this.$store.getters['platformEditStore/editInputForm'];

    // 会員情報入力フォーム内容が初期化されてる、もしくは会員情報変更＞入力画面以外からの遷移なら入力画面に遷移する
    if (!(this.form && this.$store.getters['platformEditStore/beforePath'] === '/platform/my-page/member-edit')) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    /** ポルタス会員情報を取得する */
    this.member = this.$store.getters['memberStore/member'];

    /** 既に会員に物件IDが紐づいている状態で遷移してきた場合 */
    this.property = this.$store.getters['platformEditStore/property'];

    // 「お住まいの建物を登録する」 にチェックを付けて、物件を選択して遷移したきた場合
    if (!this.property) {
      this.myPageProperty = this.$store.getters['platformEditStore/myPageProperty'];
    } else {
      // 既に会員に物件IDが紐づいている状態で遷移してきた場合はISPを判別する
      if (this.property?.uaType === UA_TYPE.E_MANSION && this.member?.primaryKeyMye) {
        this.isPropertyUaTypeEmansion = true;
      } else if (this.property?.uaType === UA_TYPE.UCOM && this.member?.primaryKeyUcom) {
        this.isPropertyUaTypeUcom = true;
      } else if (this.member?.primaryKeyGW) {
        this.isLinkedToGameWithH = true;
      } else if (this.property?.uaType === UA_TYPE.FIVE_A && this.member?.primaryKeyMye) {
        this.isPropertyUaTypeFiveA = true;
      }
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    async onNext(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      if (this.form) {
        try {
          /**
           * 1. 既に会員に物件IDが紐づいている状態で遷移してきた場合: this.property.id
           * 1. 「お住まいの建物を登録する」 にチェックを付けて、物件を選択して遷移してきた場合: propertiesテーブルから取得した物件ID
           * 1. 「該当の建物にお住まいでない方」 にチェックを付けて、遷移してきた場合: undefined
           */
          let propertyIdOnPortasDB: number | undefined = undefined;

          if (this.property) {
            propertyIdOnPortasDB = this.property.id;
          } else if (this.myPageProperty) {
            propertyIdOnPortasDB = await this.getPropertyIdOnPortasDB(this.myPageProperty);
          } else if (this.member?.primaryKeyGW) {
            this.isLinkedToGameWithH = true;
          } else {
            propertyIdOnPortasDB = undefined;
          }

          const memberBase: Member | null = this.$store.getters['memberStore/member'];

          if (memberBase) {
            let memberDto: Omit<Member, 'id'> = {
              propertyId: propertyIdOnPortasDB,
              firstName: this.form.firstName,
              givenName: this.form.givenName,
              firstNameKatakana: this.form.firstNameKatakana,
              givenNameKatakana: this.form.givenNameKatakana,
              emailAddress: this.form.emailAddress,
              phoneNumber:
                this.form.phoneNumber1 && this.form.phoneNumber2 && this.form.phoneNumber3 ? `${this.form.phoneNumber1}-${this.form.phoneNumber2}-${this.form.phoneNumber3}` : '',
              birthdate:
                this.form.birthYear && this.form.birthMonth && this.form.birthDay
                  ? `${this.form.birthYear}-${('00' + this.form.birthMonth).slice(-2)}-${('00' + this.form.birthDay).slice(-2)}`
                  : null,
              memberStatus: 0,
              address: this.form.address,
              apartmentBlockName: this.form.apartmentBlockName,
              roomNumber: this.form.roomNumber,
            };
            if (!this.form.zipcode1 || !this.form.zipcode2) {
              memberDto.zipcode = '';
            } else {
              memberDto.zipcode = `${this.form.zipcode1}-${this.form.zipcode2}`;
            }

            //会員情報を更新する
            await this.$store.dispatch('memberStore/updateMember', { memberId: memberBase.id, memberDto: memberDto });

            //「お住まいの建物を登録する」 にチェックを付けて、物件を選択して遷移したきた場合
            //会員ステータスを更新する
            if (this.myPageProperty) {
              const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
              const sub = await AuthService.getSub();

              memberStatus.status = MEMBER_STATUS.ACCOUNT_REGISTERED;
              await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: sub, memberStatus });
            }

            //ISP紐づけ済みかつISPが Five.A/e-mansion で退会13ヶ月未満(quit_date＜現在日付)以外の場合、
            //Mye側の会員情報更新処理を実施する
            if (this.isPropertyUaTypeEmansion || this.isPropertyUaTypeFiveA) {
              const portasMemberId = this.member?.id.toString();

              //更新処理実行
              if (portasMemberId) {
                if (this.isPropertyUaTypeEmansion) {
                  await EMansionSharedExternalApiService.updateCustomerName(portasMemberId).catch((e) => {
                    //送ったリクエストがPortasサーバーに届かなかったとき、フロントエラーロギングAPIを使ってログ出力
                    if (axios.isAxiosError(e)) {
                      if (!e.response) {
                        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.UPDATE_CUSTOMER_NAME);
                      }
                    }
                  });
                } else {
                  await FiveASharedExternalApiService.updateCustomerName(portasMemberId).catch((e) => {
                    //送ったリクエストがPortasサーバーに届かなかったとき、フロントエラーロギングAPIを使ってログ出力
                    if (axios.isAxiosError(e)) {
                      if (!e.response) {
                        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.UPDATE_CUSTOMER_NAME);
                      }
                    }
                  });
                }
              }
            }

            // 更新したのでストアの情報を空(null)にする
            this.$store.commit('platformEditStore/inputPropertyForm', null);
            this.$store.commit('platformEditStore/myPageProperty', null);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const status: number = error.response?.status;

          // メアド重複
          if (status === 409) {
            this.$data.errorMessages = ['ご入力いただいたメールアドレスは既に登録されています。'];
            this.showErrorMessage();
            return;
          }
          throw error;
        }
      }
      /** 会員情報を変更し、会員情報完了画面に遷移する */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/member-completed').catch((error: any) => {
        checkRouterError(error);
      });
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page/member-edit')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**
     * 次の2つの値が一致する PortasDB の 物件テーブル.id を取得する
     *
     * ・ 物件検索API (/apartment/properties) の取得結果.apartmentId (AA0146のように6桁のID)
     * ・ PortasDB の 物件テーブル.apartment_id
     *
     * 該当する 物件テーブル.id が存在しない場合は、ありえないエラーとする
     */
    async getPropertyIdOnPortasDB(selectedProperty: ExternalSearchPropertiesPropertyInfoDto): Promise<number | undefined> {
      try {
        const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
          PROPERTY_SEARCH_TYPE.E_MANSION, // apartmentId が 必ず6桁の物件IDのため、 PROPERTY_SEARCH_TYPE.E_MANSION で固定
          selectedProperty.apartmentId,
          false
        );

        if (propertyList instanceof SearchResultOver) {
          // 検索結果が11件以上でありえないエラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
        } else {
          if (propertyList.length === 0 || propertyList.length >= 2) {
            // ありえないエラー
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
          }
          if (propertyList[0].uaType !== selectedProperty.uaType) {
            // ありえないエラー
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
          }
          return propertyList[0].id;
        }
      } catch (e) {
        throw e;
      }
      return undefined;
    },
  },
});
</script>
